import React, { useState } from "react";
import { postApi } from "../modules/AmplifyServices";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import {
    genpass,
    sendPasswordManagementEmail
} from "../modules/MessagingService";
import * as Sentry from "@sentry/react";
import { Button, Typography, Box, Snackbar } from "@mui/material";
import { auditUserResetPw } from "../modules/Audit";

const useStyles = makeStyles({
    customDialogButton: {
        height: "2.25rem",
        boxShadow: "none",
        minWidth: "5.625rem",
        alignItems: "center",
        lineHeight: "inherit",
        "&:hover": {
            boxShadow: "none"
        }
    },
    customDialogButtonText: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        fontSize: "0.875rem",
        fontWeight: 500,
        fontFamily: "Roboto",
        paddingTop: "0.1rem"
    }
});

const PasswordManagementModal = ({
    showManagePasswordDialog,
    passwordManagementType,
    setPasswordManagementType,
    setShowManagePasswordDialog,
    showResetPasswordSpinner,
    userData,
    loggedInUser,
    emailAddress
}) => {
    const classes = useStyles();
    const [passwordManagementInProgess, setPasswordManagementInProgress] =
        useState(false);

    const userRoleInfo = {
        CLIENT: {
            label: "Client"
        },
        PROVIDER: {
            label: "Provider"
        },
        COMPANY_ADMIN_PROVIDER: {
            label: "Admin"
        }
    };

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    /**
     * Funtion to reset a users password.
     */
    async function resetPassword() {
        setPasswordManagementInProgress(true);
        try {
            const result = await postApi("changeuserrole", "/resetpwd", {
                body: {
                    username: userData.userId,
                    userEmail: emailAddress
                }
            });
            if (result && result.success) {
                try {
                    await sendPasswordManagementEmail(
                        "",
                        passwordManagementType,
                        userData,
                        loggedInUser.company
                    );
                    setSnackMsg("Password was reset succesfully.");
                    setMsgOpen(true);

                    await auditUserResetPw(
                        loggedInUser,
                        userData.userId,
                        passwordManagementType
                    );
                } catch (e) {
                    setSnackMsg(
                        "Password was reset succesfully. But there was an error while sending email to the user."
                    );
                    setMsgOpen(true);
                }
            } else {
                setSnackMsg("Password reset was unsuccesful.");
                setMsgOpen(true);
            }
        } catch (e) {
            Sentry.captureException(e);
            setSnackMsg("There was an error while reseting the password.");
            setMsgOpen(true);
        }
        setShowManagePasswordDialog(false);
        setPasswordManagementType();
        setPasswordManagementInProgress(false);
    }

    /**
     * Function to resend a users temporary password.
     */
    async function resendTempPassword() {
        setPasswordManagementInProgress(true);
        try {
            const temppas = genpass();
            const result = await postApi("changeuserrole", "/resendpwd", {
                body: {
                    username: userData.userId,
                    temppas
                }
            });
            if (result && result.success) {
                try {
                    await sendPasswordManagementEmail(
                        temppas,
                        passwordManagementType,
                        userData,
                        loggedInUser.company
                    );
                    setSnackMsg("Temporary password was set succesfully.");
                    setMsgOpen(true);

                    await auditUserResetPw(
                        loggedInUser,
                        userData.userId,
                        passwordManagementType
                    );
                } catch (e) {
                    setSnackMsg(
                        "Temporary password was set succesfully. But there was an error while sending email to the user."
                    );
                    setMsgOpen(true);
                }
            } else {
                setSnackMsg(
                    "Setting temporary password reset was unsuccesful."
                );
                setMsgOpen(true);
            }
        } catch (e) {
            Sentry.captureException(e);
            setSnackMsg(
                "There was an error while setting the temporary password."
            );
            setMsgOpen(true);
        }
        setPasswordManagementInProgress(false);
        setPasswordManagementType();
        setShowManagePasswordDialog(false);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setMsgOpen(false);
                }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <SmallSizedModal
                open={showManagePasswordDialog}
                onClose={() => {
                    setPasswordManagementType();
                    setShowManagePasswordDialog(false);
                }}
                headingText={`${
                    userData?.userRole && userRoleInfo[userData.userRole].label
                } password`}
            >
                {showResetPasswordSpinner && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <CircularProgress size={36} />
                    </Box>
                )}
                <Box>
                    {"RESET" === passwordManagementType && (
                        <Typography
                            variant="body2"
                            id="alert-dialog-description"
                        >
                            A confirmation code will be sent via email to
                            {` ${userData.firstName}`}.
                        </Typography>
                    )}
                    {"SET-TEMP-PWD" === passwordManagementType && (
                        <Typography
                            variant="body2"
                            id="alert-dialog-description"
                        >
                            A temporary password will be sent via email to
                            {` ${userData.firstName}`}.
                        </Typography>
                    )}
                </Box>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1.25rem"
                    }}
                >
                    {!showResetPasswordSpinner && (
                        <Button
                            sx={{
                                borderColor: "primary.main"
                            }}
                            className={classes.customDialogButton}
                            onClick={() => {
                                setPasswordManagementType();
                                setShowManagePasswordDialog(false);
                            }}
                            variant="outlined"
                        >
                            <div className={classes.customDialogButtonText}>
                                Cancel
                            </div>
                        </Button>
                    )}
                    {"RESET" === passwordManagementType && (
                        <Button
                            className={classes.customDialogButton}
                            variant="contained"
                            onClick={async () => await resetPassword()}
                            color="primary"
                            disabled={passwordManagementInProgess}
                        >
                            Reset Password
                            {passwordManagementInProgess && (
                                <CircularProgress
                                    size={18}
                                    sx={{
                                        position: "absolute"
                                    }}
                                />
                            )}
                        </Button>
                    )}
                    {"SET-TEMP-PWD" === passwordManagementType && (
                        <Button
                            className={classes.customDialogButton}
                            variant="contained"
                            onClick={async () => await resendTempPassword()}
                            color="primary"
                            disabled={passwordManagementInProgess}
                        >
                            Resend Temporary Password
                            {passwordManagementInProgess && (
                                <CircularProgress
                                    size={18}
                                    sx={{
                                        position: "absolute"
                                    }}
                                />
                            )}
                        </Button>
                    )}
                </Box>
            </SmallSizedModal>
        </>
    );
};

export default PasswordManagementModal;
