import React, { Fragment } from "react";
import {
    Typography,
    TextField,
    Grid,
    Paper,
    Button,
    InputAdornment,
    CircularProgress,
    Link
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CopyrightIcon from "@mui/icons-material/Copyright";

/* styles used in footer */
const useStyles = makeStyles({
    footer: {
        justifyContent: "space-between",
        padding: "1rem 4rem"
    },
    footertext: {
        alignItems: "center",
        gap: "2px",
        justifyContent: "center",

        "& *": {
            fontFamily: "Poppins, sans-serif"
        }
    },
    mbtext: {
        fontSize: "14px",
        color: "#4f5c65"
    }
});

const Footer = (props) => {
    const classes = useStyles();

    return (
        <Fragment>
            <footer>
                <Grid container className={classes.footer}>
                    <Grid container className={classes.footertext}>
                        <CopyrightIcon
                            style={{ height: "15px", width: "14px" }}
                        />
                        <Typography className={classes.mbtext}>
                            MarketBox Inc. {new Date().getFullYear()}
                        </Typography>
                    </Grid>
                    {/* <Link>
                info@gomarketbox.com
            </Link> */}
                </Grid>
            </footer>
        </Fragment>
    );
};

export default Footer;
