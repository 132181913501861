import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import Typography from "@mui/material/Typography";
import { useStyles } from "../styles/MainContainerStyles";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { CustomerTable } from "../components/CustomerTable";
import { getUserFromCache } from "../user/UserCommon";
// prettier-ignore
import { Paper, Table, TableHead, TableRow, TableBody, TableCell, TableFooter, TablePagination, Tooltip, IconButton, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Snackbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Edit } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { ClientCreditCardUpdateForm } from "../components/stripe/ClientCreditCardUpdateForm";
import { execReadBySortkey } from "../modules/DBService";
import { searchByName, userByCompany } from "../queries/ListBookingsQueries";
import CustomAutocomplete from "../utils/UI/CustomAutocomplete";
import ClientPackageAddEditModal from "./ClientPackageAddEditModal";
import ClientUserInfoModal from "../components/ClientUserInfoModal";

const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
const CustomerForm = (props) => {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [showClientInfoDialog, setShowClientInfoDialog] = useState(false);
    const [showCreditCardDialog, setShowCreditCardDialog] = useState(false);
    const [showPackageInfoDialog, setShowPackageInfoDialog] = useState(false);
    const [openUpdateCCDialog, setOpenUpdateCCDialog] = useState(false);
    const [updateCCClientId, setUpdateCCClientId] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [values] = useState({
        active: true,
        searchTerms: ""
    });
    const [clientInfoDialogMode, setClientInfoDialogMode] = useState("EDIT");
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [clientActive, setClientActive] = useState("");
    const [clientRegistered, setClientRegistered] = useState("");
    const [clientId, setClientId] = useState("");
    const [clientContactConsent, setClientContactConsent] = useState(false);
    const [clientUserId, setClientUserId] = useState();
    const [clientUserRole, setClientUserRole] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientFirstName, setClientFirstName] = useState("");
    const [clientLastName, setClientLastName] = useState("");
    const [clientEmailAddress, setClientEmailAddress] = useState("");
    const [clientAddress, setClientAddress] = useState("");
    const [clientStreet, setClientStreet] = useState("");
    const [clientCity, setClientCity] = useState("");
    const [clientState, setClientState] = useState("");
    const [clientCountry, setClientCountry] = useState("");
    const [clientPostalCode, setClientPostalCode] = useState("");
    const [clientLongitude, setClientLongitude] = useState("");
    const [clientLatitude, setClientLatitude] = useState("");
    const [clientHomePhone, setClientHomePhone] = useState("");
    const [clientWorkPhone, setClientWorkPhone] = useState("");
    const [clientMobilePhone, setClientMobilePhone] = useState("");
    const [clientNotes, setClientNotes] = useState();
    const [clientPhonePref, setClientPhonePref] = useState("");
    const [clientDefaultPartialCC, setClientDefaultPartialCC] = useState("");
    const [lastLogin, setLastLogin] = useState();
    const [loginCount, setLoginCount] = useState(0);
    const [clientPackageName, setClientPackageName] = useState("");
    const [packageInitialCredits, setPackageInitialCredits] = useState("");
    const [packageUsedCredits, setPackageUsedCredits] = useState("");
    const [packageStatus, setPackageStatus] = useState("");
    const [packageCreatedAt, setPackageCreatedAt] = useState("");
    const [packageServiceDesc, setPackageServiceDesc] = useState("");
    const [packageId, setPackageId] = useState("");
    const [userId, setUserId] = useState("");
    const [clientForPackageDialog, setClientForPackageDialog] = useState();
    const [user, setUser] = useState({});
    const [role, setrole] = useState();
    const [isGuestOnlyCheckout, setIsGuestOnlyCheckout] = useState(false);
    const [focusNotes, setFocusNotes] = useState(false);

    const fileInput = React.createRef();
    const [importDialog, setImportDialog] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [nextTokenn, setNextTokenn] = useState(null);

    const [endOfList, setEndOfList] = useState(false);

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [searching, setSearching] = useState(false);

    const [clientPackageModal, setClientPackageModal] = useState(false);
    const [clientPackageMode, setClientPackageMode] = useState("Add");
    const [packageIdForModal, setPackageIdForModal] = useState("");
    const [clientPackageForModal, setClientPackageForModal] = useState(null);

    // Throttle function to delay the search query execution
    const throttleSearch = (callback, delay) => {
        let timerId;

        return (...args) => {
            if (timerId) {
                clearTimeout(timerId);
            }

            timerId = setTimeout(() => {
                callback(...args);
                timerId = null;
            }, delay);
        };
    };

    const resetStatesForPackageModal = () => {
        setClientPackageMode("Add");
        setPackageIdForModal("");
        setClientPackageForModal(null);
    };

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    const closeClientPackageModal = () => {
        setClientPackageModal(false);
    };

    const changeClientPackageMode = (val) => {
        if (val === "Add") {
            setClientPackageMode("Add");
        }

        if (val === "Edit") {
            setClientPackageMode("Edit");
        }
    };

    useEffect(() => {
        _fetchCustomersList();
        checkGuestOnlyCheckout();
    }, []);

    useEffect(() => {
        console.log("Filtered Options", filteredOptions);
    }, [filteredOptions]);

    function sortList(customerList) {
        // Sort the customer list by last name
        customerList.sort((a, b) => {
            // Extract last names
            const lastNameA = a?.user?.lastname?.toLowerCase();
            const lastNameB = b?.user?.lastname?.toLowerCase();

            // If last names are different, sort by last name
            if (lastNameA && lastNameB) {
                if (lastNameA !== lastNameB) {
                    return lastNameA.localeCompare(lastNameB);
                } else {
                    // If last names are the same, sort by first name
                    const firstNameA = a.user.firstname.toLowerCase();
                    const firstNameB = b.user.firstname.toLowerCase();
                    return firstNameA.localeCompare(firstNameB);
                }
            }
        });
        return customerList;
    }

    async function checkGuestOnlyCheckout() {
        const currentUser = getUserFromCache();
        const companyId = currentUser.company.id;
        const publicResult = await graphql(
            graphqlOperation(queries.publicsiteByCompany, {
                companyId: companyId
            })
        );
        if (
            !!publicResult &&
            !!publicResult.data &&
            !!publicResult.data.publicsiteByCompany.items
        ) {
            if (publicResult.data.publicsiteByCompany.items.length > 0) {
                let { StyleCustomization } =
                    publicResult.data.publicsiteByCompany.items[0];
                StyleCustomization = StyleCustomization
                    ? JSON.parse(StyleCustomization)
                    : null;
                const checkoutOption =
                    StyleCustomization?.bookingFlowCustomizations
                        ?.checkoutOption;
                if ("GUEST_ONLY" === checkoutOption) {
                    setIsGuestOnlyCheckout(true);
                }
            }
        }
    }

    const _fetchCustomersList = async () => {
        setShowLoading(true);
        const limit = process.env.REACT_APP_LISTLIMIT;
        const loggedInUser = getUserFromCache();
        setUser(loggedInUser);
        setrole(loggedInUser.role);
        const companyid = loggedInUser.companyId;

        let customersList = [];
        let nextToken = null;
        do {
            let result = await graphql(
                graphqlOperation(queries.clientByCompany, {
                    companyId: companyid,
                    limit: 50 //limit,
                    //nextToken
                })
            );
            if (result && result.data && result.data.clientByCompany) {
                customersList.push(...result.data.clientByCompany.items);
                // nextToken = null;
                if (result?.data?.clientByCompany?.nextToken) {
                    setNextTokenn(result.data.clientByCompany.nextToken);
                } else {
                    setNextTokenn(null);

                    setEndOfList(true);
                }
            }
        } while (nextToken);

        if (customersList && customersList.length) {
            for (let cli of customersList) {
                if (cli.ClientInfo) {
                    cli.stats = JSON.parse(cli.ClientInfo);
                } else {
                    cli.stats = {
                        order_30days: 0,
                        orders_ytd: 0,
                        sales_30days: 0,
                        sales_ytd: 0
                    };
                }
            }
        }
        customersList = sortList(customersList);

        if (customersList && customersList.length && values.searchTerms) {
            setRows(
                customersList.filter((item) =>
                    item.user
                        ? (item.user.firstname &&
                              item.user.firstname
                                  .toLowerCase()
                                  .includes(
                                      values.searchTerms.toLowerCase()
                                  )) ||
                          (item.user.lastname &&
                              item.user.lastname
                                  .toLowerCase()
                                  .includes(
                                      values.searchTerms.toLowerCase()
                                  )) ||
                          (item.user.emailaddress &&
                              item.user.emailaddress
                                  .toLowerCase()
                                  .includes(values.searchTerms.toLowerCase()))
                        : false
                )
            );
        } else {
            setRows(customersList);
        }
        setShowLoading(false);
    };

    function isEmail(input) {
        return input && input.includes("@");
    }

    const handleClearInput = async () => {
        setFilteredOptions([]);
        setSelectedClient(null);
        setSearching(false);
        await _fetchCustomersList();
    };

    const searchUsers = async (str) => {
        console.log("Arguments", str, user);
        setSearching(true);
        let usersByEmail, usersByFirstName;
        try {
            if (str && str.length >= 1) {
                if (isEmail(str)) {
                    usersByEmail = await execReadBySortkey({
                        opname: "userByCompany",
                        op: userByCompany,
                        id: {
                            companyId: user.companyId
                        },
                        skey: {
                            roleEmailaddress: {
                                beginsWith: {
                                    role: "CLIENT",
                                    emailaddress: str
                                }
                            }
                        }
                    });

                    if (usersByEmail && usersByEmail.items) {
                        setFilteredOptions([...usersByEmail.items]);
                    }
                } else {
                    usersByFirstName = await execReadBySortkey({
                        opname: "searchByName",
                        op: searchByName,
                        id: {
                            companyId: user.companyId
                        },

                        skey: {
                            lc_name: {
                                beginsWith: str.toLowerCase()
                            }
                        },
                        filter: {
                            role: { eq: "CLIENT" }
                        }
                    });

                    if (usersByFirstName && usersByFirstName.items) {
                        setFilteredOptions([...usersByFirstName.items]);
                    }
                }
            }
        } catch (error) {
            console.log("not able to search user", error);
        }
    };

    const getClientByUserId = async (id) => {
        let userById;
        try {
            userById = await graphql(
                graphqlOperation(queries.clientByUserId, {
                    userId: id,
                    filter: { companyId: { eq: user.companyId } }
                })
            );

            //modify the data for the table
            if (userById?.data?.clientByUserId?.items[0]) {
                if (userById.data.clientByUserId.items[0].ClientInfo) {
                    userById = {
                        ...userById.data.clientByUserId.items[0],
                        stats: JSON.parse(
                            userById.data.clientByUserId.items[0].ClientInfo
                        )
                    };
                } else {
                    userById = {
                        ...userById.data.clientByUserId.items[0],
                        stats: {
                            order_30days: 0,
                            orders_ytd: 0,
                            sales_30days: 0,
                            sales_ytd: 0
                        }
                    };
                }

                // let stats = {stats: }
            }
            setRows([{ ...userById }]);
        } catch (error) {
            console.log("Not able to get client by user id", error);
        }
    };

    const _handleCustomerChange = async (val) => {
        const inputValue = val; //event.target.value;
        // setValues({ searchTerms: event.target.value });
        if (inputValue && inputValue.length === 0) {
            handleClearInput();
        } else {
            // searchUsers(inputValue);
            throttleSearch(searchUsers(inputValue, 1000));
        }
    };

    const loadNextResults = async () => {
        setShowLoading(true);
        const companyid = getUserFromCache().companyId;
        if (nextTokenn) {
            let result = await graphql(
                graphqlOperation(queries.clientByCompany, {
                    companyId: companyid,
                    limit: 50,
                    nextToken: nextTokenn // Pass the nextToken from the state
                })
            );

            // Update the nextToken for future requests
            if (result?.data?.clientByCompany?.nextToken) {
                setNextTokenn(result.data.clientByCompany.nextToken);

                if (
                    result.data.clientByCompany.items &&
                    result.data.clientByCompany.items.length > 0
                ) {
                    for (let cli of result.data.clientByCompany.items) {
                        if (cli.ClientInfo) {
                            cli.stats = JSON.parse(cli.ClientInfo);
                        } else {
                            cli.stats = {
                                order_30days: 0,
                                orders_ytd: 0,
                                sales_30days: 0,
                                sales_ytd: 0
                            };
                        }
                    }
                }

                // add the result to rows
                setRows((prevState) =>
                    sortList([
                        ...prevState,
                        ...result.data.clientByCompany.items
                    ])
                );
                if (result) {
                    setShowLoading(false);
                }
            } else {
                if (result?.data?.clientByCompany?.items.length > 0) {
                    for (let cli of result.data.clientByCompany.items) {
                        if (cli.ClientInfo) {
                            cli.stats = JSON.parse(cli.ClientInfo);
                        } else {
                            cli.stats = {
                                order_30days: 0,
                                orders_ytd: 0,
                                sales_30days: 0,
                                sales_ytd: 0
                            };
                        }
                    }

                    setRows((prevState) =>
                        sortList([
                            ...prevState,
                            ...result.data.clientByCompany.items
                        ])
                    );
                }
                setNextTokenn(null);
                setEndOfList(true);
                setShowLoading(false);
            }
        }
    };

    //Delete
    const _deleteClient = async (id) => {
        const input = {
            id: id
        };
        const deleteClientData = await graphql(
            graphqlOperation(mutations.deleteClient, { input })
        );
        if (deleteClientData.errors && deleteClientData.errors.length > 0) {
            setSnackMsg("Something went wrong, while deleting customer.");
            setMsgOpen(true);
        } else {
            _fetchCustomersList();
            setDeleteDialog(false);
            setSelected([]);
            actions.setPage("CustomerForm");
        }
    };

    function handleClientPackageEditClick(id, clientForPackageDialog) {
        // actions.setMode("Edit");
        //actions.setPage("ClientPackageAddEditForm");
        //actions.setId(id);
        setPackageIdForModal(id);
        setClientPackageModal(true);
        changeClientPackageMode("Edit");
        setClientPackageForModal(clientForPackageDialog);
    }

    function handleClientPackageAddClick(id, clientForPackageDialog) {
        // actions.setMode("Add");
        // actions.setPage("ClientPackageAddEditForm");
        // actions.setId(id);
        setPackageIdForModal(id);
        setClientPackageModal(true);
        changeClientPackageMode("Add");
        setClientPackageForModal(clientForPackageDialog);
        // actions.setClientForPackageDialog(clientForPackageDialog);
    }

    const _handleMsgClose = (e) => {};

    //Delete Dialog
    const _handleDeleteDialogDecline = () => {
        setDeleteDialog(false);
    };

    const _handleDeleteDialogAccept = () => {
        // proceed to delete customers

        selected.map((item) => {
            _deleteClient(item);
        });

        setSnackMsg("The selected customer(s) have been successfully deleted.");
        setMsgOpen(true);
        setDeleteDialog(false);
        // clear table... cannot do refresh right away or we will get stale data ()
        setRows([]);
        setSelected([]);
    };

    function showUpdateCCDialog(item) {
        const loggedInUser = getUserFromCache();

        if (loggedInUser.company.collectpayment) {
            setUpdateCCClientId(item.id);
            setOpenUpdateCCDialog(true);
        } else {
            setShowCreditCardDialog(true);
        }
    }
    function showClientDialog(item, mode) {
        try {
            if (mode === "ADD") {
                setClientInfoDialogMode("ADD");
                setClientActive(true);
                setClientRegistered("");
                setClientId("");
                setClientName("");
                setClientContactConsent(true);
                setClientUserId();
                setClientUserRole("");
                setClientFirstName("");
                setClientLastName("");
                setClientEmailAddress("");
                setClientAddress("");
                setClientStreet("");
                setClientCity("");
                setClientState("");
                setClientCountry("");
                setClientPostalCode("");
                setClientLongitude("");
                setClientLatitude("");
                setClientHomePhone("");
                setClientWorkPhone("");
                setClientMobilePhone("");
                setClientPhonePref("");
                setClientNotes("");
                setClientDefaultPartialCC("");
                setLastLogin(null);
                setLoginCount(0);
            } else if (item.user) {
                setClientInfoDialogMode("EDIT");
                setClientActive(item.user.active);
                setClientRegistered(item.user.registered);
                setClientId(item.id);
                const name = item.user.lastname
                    ? item.user.firstname + " " + item.user.lastname
                    : "";
                setClientName(name);
                setClientContactConsent(item.user.contactconsent);
                setClientUserId(item.userId);
                setClientUserRole(item.user.role);
                setClientFirstName(item.user.firstname);
                setClientLastName(item.user.lastname);
                setClientEmailAddress(item.user.emailaddress);
                setClientAddress(item.user.addressoneline);
                setClientStreet(item.user.street);
                setClientCity(item.user.city);
                setClientState(item.user.state);
                setClientCountry(item.user.country);
                setClientPostalCode(item.user.postalcode);
                setClientLongitude(item.user.longitude);
                setClientLatitude(item.user.latitude);
                setClientHomePhone(item.user.homephone);
                setClientWorkPhone(item.user.workphone);
                setClientMobilePhone(item.user.mobilephone);
                setClientPhonePref(item.user.phonepref);
                setClientNotes(item.Notes ? JSON.parse(item.Notes) : "");
                setClientDefaultPartialCC(
                    item.defaultpartialcc ? item.defaultpartialcc : ""
                );
                if (item.user.LoginInfo) {
                    const loginObj = JSON.parse(item.user.LoginInfo);
                    setLastLogin(loginObj.lastLoginDate);
                    setLoginCount(loginObj.loginCount);
                } else {
                    setLastLogin(null);
                    setLoginCount(0);
                }
            }
            setShowClientInfoDialog(true);
        } catch {
            console.log(
                "ERROR: Unable to open clientInfoDialog, no user was found"
            );
        }
    }

    // creates arrays for the information of the client package
    let clientService = [];
    let initialCredits = [];
    let usedCredits = [];
    let pkgStatus = [];
    let pkgStartDate = [];
    let pkgServiceDesc = [];
    let pkgId = [];

    // function to display the dialog information
    async function showPackageDialog(item) {
        const loggedInUser = getUserFromCache();
        console.log("item in showPackageDialog", item);

        if (item.user) {
            const name = item.user.lastname
                ? item.user.firstname + " " + item.user.lastname
                : "";
            // retreives package data from the clientPackageByUser query
            const packageData = await graphql(
                graphqlOperation(queries.clientPackageByUser, {
                    userId: item.user.id,
                    limit: 100
                })
            );

            let clientPackageLen =
                packageData.data.clientPackageByUser.items.length;
            let index = 0;

            // places all values into their respective package arrays
            for (var i = 0; i < clientPackageLen; i++) {
                if (
                    packageData.data.clientPackageByUser.items[i].status !=
                        null &&
                    packageData.data.clientPackageByUser.items[i].status ==
                        "paid" &&
                    packageData.data.clientPackageByUser.items[i].servicetype !=
                        null &&
                    packageData.data.clientPackageByUser.items[i].servicetype
                        .companyId == loggedInUser.companyId
                ) {
                    if (
                        packageData.data.clientPackageByUser.items[i].id != null
                    ) {
                        pkgId[index] =
                            packageData.data.clientPackageByUser.items[i].id;
                    }

                    if (
                        packageData.data.clientPackageByUser.items[i]
                            .servicetype == null
                    ) {
                        clientService[index] = "N/A";
                    } else {
                        clientService[index] =
                            packageData.data.clientPackageByUser.items[
                                i
                            ].servicetype.name;
                    }

                    if (
                        packageData.data.clientPackageByUser.items[i]
                            .servicetype.desc == null
                    ) {
                        pkgServiceDesc[index] = "N/A";
                    } else {
                        pkgServiceDesc[index] =
                            packageData.data.clientPackageByUser.items[
                                i
                            ].servicetype.desc;
                    }

                    if (
                        packageData.data.clientPackageByUser.items[i]
                            .createdAt == null
                    ) {
                        pkgStartDate[index] = "N/A";
                    } else {
                        pkgStartDate[index] = moment(
                            packageData.data.clientPackageByUser.items[i]
                                .createdAt
                        ).format("YYYY-MM-DD hh:mm A");
                    }

                    const initialQ =
                        packageData.data.clientPackageByUser.items[i]
                            .initialQuantity;
                    const usedQ =
                        packageData.data.clientPackageByUser.items[i]
                            .usedQuantity;
                    if (initialQ == null && usedQ == null) {
                        initialCredits[index] = "N/A";
                        usedCredits[index] = "N/A";
                    } else {
                        initialCredits[index] = initialQ;
                        usedCredits[index] = usedQ;
                    }

                    pkgStatus[index] =
                        packageData.data.clientPackageByUser.items[i].status;
                    index += 1;
                }
            }

            setClientName(name);
            setUserId(item.user);
            setClientForPackageDialog(item);
            clientPackageLen == 0
                ? setClientPackageName([])
                : setClientPackageName(clientService);
            clientPackageLen == 0
                ? setPackageInitialCredits([])
                : setPackageInitialCredits(initialCredits);
            clientPackageLen == 0
                ? setPackageUsedCredits([])
                : setPackageUsedCredits(usedCredits);
            clientPackageLen == 0
                ? setPackageStatus([])
                : setPackageStatus(pkgStatus);
            clientPackageLen == 0
                ? setPackageCreatedAt([])
                : setPackageCreatedAt(pkgStartDate);
            clientPackageLen == 0
                ? setPackageServiceDesc([])
                : setPackageServiceDesc(pkgServiceDesc);
            clientPackageLen == 0 ? setPackageId([]) : setPackageId(pkgId);
        }
        if (!showPackageInfoDialog) {
            setShowPackageInfoDialog(true);
        }
    }

    // remakes the package information arrays for future use
    if (clientPackageName.length > 0) {
        for (var i = 0; i < clientPackageName.length; i++) {
            clientService[i] = clientPackageName[i];
            initialCredits[i] = packageInitialCredits[i];
            usedCredits[i] = packageUsedCredits[i];
            pkgStatus[i] = packageStatus[i];
            pkgStartDate[i] = packageCreatedAt[i];
            pkgServiceDesc[i] = packageServiceDesc[i];
            pkgId[i] = packageId[i];
        }
    } else {
        clientService[0] = "There are no packages for this customer";
        initialCredits[0] = "N/A";
        usedCredits[0] = "N/A";
        pkgStatus[0] = "N/A";
        pkgStartDate[0] = "N/A";
        pkgServiceDesc[0] = "N/A";
    }

    // converts each individual array into an array of objects (easier to be viewed in a table format)
    let packageInfo = [];
    for (var i = 0; i < clientService.length; i++) {
        packageInfo.push({
            id: pkgId[i],
            name: clientService[i],
            desc: pkgServiceDesc[i],
            initialCreds: initialCredits[i],
            usedCreds: usedCredits[i],
            status: pkgStatus[i],
            createdAt: pkgStartDate[i]
        });
    }
    function compare(date1, date2) {
        const firstDate = date1.createdAt;
        const secondDate = date2.createdAt;

        let comparison = 0;
        if (firstDate > secondDate) {
            comparison = 1;
        } else if (firstDate < secondDate) {
            comparison = -1;
        }
        return comparison * -1;
    }

    packageInfo.sort(compare);

    function handleImportDialogAccept() {
        setImportDialog(false);
        fileInput.current.click();
    }

    const handleCloseClientInfoDialog = () => {
        setShowClientInfoDialog(false);
        setTimeout(() => {
            setFocusNotes(false);
        }, 500);
    };

    const handleSaveClientUpdates = (id, clientUpdatesObj) => {
        //function to update the Notes for a customer item
        const indexToUpdate = rows.findIndex((row) => row.id === id);

        if (indexToUpdate !== -1) {
            const updatedRow = { ...rows[indexToUpdate] };
            updatedRow.user.active = clientUpdatesObj.active;
            updatedRow.user.firstname = clientUpdatesObj.firstName;
            updatedRow.user.lastname = clientUpdatesObj.lastName;
            updatedRow.user.emailaddress = clientUpdatesObj.emailAddress;
            updatedRow.user.mobilephone = clientUpdatesObj.phoneNumber;
            if (clientUpdatesObj.defaultPartialCC) {
                updatedRow.defaultpartialcc = clientUpdatesObj.defaultPartialCC;
            }
            if (clientUpdatesObj.address) {
                updatedRow.user.addressoneline = clientUpdatesObj.address;
                updatedRow.user.street =
                    clientUpdatesObj.addressAttributes.street;
                updatedRow.user.city = clientUpdatesObj.addressAttributes.city;
                updatedRow.user.state =
                    clientUpdatesObj.addressAttributes.state;
                updatedRow.user.country =
                    clientUpdatesObj.addressAttributes.country;
                updatedRow.user.postalcode =
                    clientUpdatesObj.addressAttributes.postalcode;
                updatedRow.user.longitude =
                    clientUpdatesObj.addressAttributes.longitude;
                updatedRow.user.latitude =
                    clientUpdatesObj.addressAttributes.latitude;
            }
            if (clientUpdatesObj.newNotes) {
                updatedRow.Notes = JSON.stringify(clientUpdatesObj.newNotes);
            }
            if (clientUpdatesObj["consent"] !== "undefined") {
                updatedRow.user.contactconsent = clientUpdatesObj.consent;
            }

            rows[indexToUpdate] = updatedRow;
        } else {
            console.log("Row with ID not found:", id);
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={_handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <ClientCreditCardUpdateForm
                show={openUpdateCCDialog}
                clientId={updateCCClientId}
                onClose={() => {
                    setOpenUpdateCCDialog(false);
                    setUpdateCCClientId(null);
                }}
                updatedBySelf={false}
            />
            <DeleteDialog
                title="customers"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <Dialog title="import" open={importDialog}>
                <DialogTitle id="import-booking-dialog-title">
                    Import client credits into{" "}
                    {user && user.company
                        ? user.company.name + "?"
                        : "your company?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Before you import any client credits, make sure that:
                        <br></br>
                        1. You have imported Client users (from the "Users"
                        page)<br></br>
                        2. You added all packages for your company (from the
                        "Packages" page)<br></br>
                        3. You added all services for your company (from the
                        "Services" page)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setImportDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={handleImportDialogAccept}
                    >
                        Import
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <ClientUserInfoModal
                isOpen={showClientInfoDialog}
                onClose={handleCloseClientInfoDialog}
                loggedInUser={user}
                onSave={handleSaveClientUpdates}
                mode={clientInfoDialogMode}
                isGuestOnly={isGuestOnlyCheckout}
                focusNotes={focusNotes}
                clientUser={{
                    id: clientId,
                    active: clientActive,
                    registered: clientRegistered,
                    userId: clientUserId,
                    userRole: clientUserRole,
                    firstName: clientFirstName,
                    lastName: clientLastName,
                    emailAddress: clientEmailAddress,
                    phone: clientMobilePhone,
                    clientNotes: clientNotes,
                    contactConsent: clientContactConsent,
                    defaultPartialCC: clientDefaultPartialCC,
                    addressOneLine: clientAddress,
                    street: clientStreet,
                    city: clientCity,
                    state: clientState,
                    country: clientCountry,
                    postalcode: clientPostalCode,
                    longitude: clientLongitude,
                    latitude: clientLatitude
                }}
            />
            <Dialog
                open={showCreditCardDialog}
                fullWidth={true}
                aria-labelledby="show-client-dialog-title"
                aria-describedby="show-client-dialog-description"
            >
                <DialogTitle
                    id="show-client-dialog-title"
                    sx={{ fontSize: "24px", fontWeight: 400 }}
                >
                    This feature is currently disabled on your account
                </DialogTitle>
                <DialogContent dividers style={{ padding: 22 }}>
                    <DialogContentText id="show-client-dialog-description">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    To turn it on: Go to Company Settings{" "}
                                    {"\u2192"} Turn on 'Company Collects
                                    Payments'
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    You will then need to connect your Stripe
                                    account to MarketBox
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={0}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        setShowCreditCardDialog(false)
                                    }
                                    color="primary"
                                >
                                    Okay
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={showPackageInfoDialog}
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="show-order-dialog-title"
                aria-describedby="show-order-dialog-description"
            >
                <DialogTitle id="show-order-dialog-title">
                    <Grid
                        item
                        xs={6}
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Package Information for {clientName}
                        &nbsp; &nbsp;
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                handleClientPackageAddClick(
                                    userId,
                                    clientForPackageDialog
                                )
                            }
                            // startIcon={<AddIcon className={classes.leftIcon} />}
                        >
                            Add Package
                        </Button>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Purchased Service Type
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Service Description
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Purchased On
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Initial Credits Bought
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Used Credits
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {packageInfo
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((item, index) => {
                                        return (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPading
                                                    }
                                                >
                                                    {item.name}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPading
                                                    }
                                                >
                                                    {item.desc}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPading
                                                    }
                                                >
                                                    {item.createdAt}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPading
                                                    }
                                                >
                                                    {item.status}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.customCellPading
                                                    }
                                                >
                                                    {item.initialCreds}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.customCellPading
                                                    }
                                                >
                                                    {item.usedCreds}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.actionsCellWidth
                                                    }
                                                >
                                                    <Tooltip title="Edit">
                                                        <IconButton
                                                            aria-label="Edit"
                                                            onClick={() =>
                                                                handleClientPackageEditClick(
                                                                    item.id,
                                                                    clientForPackageDialog
                                                                )
                                                            }
                                                            size="large"
                                                        >
                                                            <Edit
                                                                className={
                                                                    classes.pointer
                                                                }
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {(clientService = [])}
                                {(initialCredits = [])}
                                {(usedCredits = [])}
                                {(pkgStatus = [])}
                                {(pkgServiceDesc = [])}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={12}
                                        count={packageInfo.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page"
                                            },
                                            native: true
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowPackageInfoDialog(false);
                            setPage(0);
                            setRowsPerPage(5);
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FormHeading title={"Clients"} classes={classes} />
            <Grid
                sx={{
                    marginTop: "22px",
                    justifyContent: "space-between"
                }}
                container
                alignItems="center"
            >
                <CustomAutocomplete
                    options={filteredOptions}
                    onInputChange={_handleCustomerChange}
                    onSelect={getClientByUserId}
                    onClear={handleClearInput}
                />

                <Button
                    variant="contained"
                    sx={{ minWidth: "90px", gap: "4px" }}
                    onClick={() => {
                        showClientDialog(null, "ADD");
                    }}
                >
                    <AddIcon className={classes.leftIcon} />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            position: "relative",
                            top: "0.5px"
                        }}
                    >
                        Add Client
                    </Typography>
                </Button>
            </Grid>
            <Grid sx={{ marginTop: "16px" }} container>
                <Grid item xs={12}>
                    <CustomerTable
                        rows={rows}
                        selectedRow={(selected) => setSelected(selected)}
                        showClientDialog={showClientDialog}
                        setFocusNotes={(value) => setFocusNotes(value)}
                        onCloseClientInfoDialog={handleCloseClientInfoDialog}
                        showPackageDialog={showPackageDialog}
                        showUpdateCCDialog={showUpdateCCDialog}
                        loggedInUser={user}
                        showPurchasedPkgButton={
                            user?.company?.singleApptOnly
                                ? !user?.company?.singleApptOnly
                                : true
                        }
                        loadMore={loadNextResults}
                        endOfList={endOfList}
                        searching={searching}
                    />
                </Grid>
            </Grid>
            <ClientPackageAddEditModal
                open={clientPackageModal}
                originPath={"CustomerForm"}
                closeModal={closeClientPackageModal}
                clientPackageMode={clientPackageMode}
                packageId={packageIdForModal}
                clientPackageForModal={clientPackageForModal}
                showPackageDialog={showPackageDialog}
                resetStates={resetStatesForPackageModal}
            />
        </>
    );
};

export default CustomerForm;
