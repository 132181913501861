import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
import { Dialog, DialogContent, TextField } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { TimeSelectorTheme } from "../styles/TimeSelectorTheme";
import moment from "moment";
export const TimeSelector = ({
    initialDateTime,
    open,
    onClose,
    onSelectTime
}) => {
    const [selectedDateTime, setSelectedDateTime] = useState();
    if (!moment(initialDateTime).isSame(selectedDateTime, "day")) {
        setSelectedDateTime(initialDateTime);
    }

    const theme = useTheme();

    return (
        <>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={customLocale}
            >
                <ThemeProvider theme={TimeSelectorTheme(theme)}>
                    <Dialog open={open} onClose={onClose}>
                        <DialogContent>
                            <StaticTimePicker
                                displayStaticWrapperAs="mobile"
                                value={selectedDateTime}
                                toolbarTitle={""}
                                minutesStep={5}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                                onChange={(newDateTime) => {
                                    setSelectedDateTime(newDateTime);
                                }}
                                onAccept={(newDateTime) => {
                                    setSelectedDateTime(newDateTime);
                                    onSelectTime(newDateTime);
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: []
                                    }
                                }}
                                closeOnSelect={true}
                            />
                        </DialogContent>
                    </Dialog>
                </ThemeProvider>
            </LocalizationProvider>
        </>
    );
};
