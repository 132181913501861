import React, { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TextField, IconButton } from "@mui/material";
import moment from "moment";
export const DateSelector = ({
    handleDateChange = () => {},
    initialDate,
    lookAheadDays = 7
}) => {
    const [selectedDate, setSelectedDate] = useState(initialDate);
    if (initialDate !== selectedDate) setSelectedDate(initialDate);

    function calculateDateRangeDisplay() {
        if (selectedDate) {
            let fromDateMoment = moment(selectedDate);
            let toDateMoment = moment(selectedDate).add(
                lookAheadDays - 1,
                "days"
            );
            if (fromDateMoment.month() === toDateMoment.month()) {
                return `${fromDateMoment.format("MMM D")}–${toDateMoment.format("D")}`;
            } else {
                return `${moment(fromDateMoment).format("MMM D")} – ${toDateMoment.format("MMM D")}`;
            }
        }
    }
    const dateRangeDisplay = calculateDateRangeDisplay();

    return (
        <>
            <IconButton
                onClick={() => {
                    const olderDate = moment(selectedDate)
                        .startOf("day")
                        .toDate();
                    olderDate.setDate(olderDate.getDate() - lookAheadDays);
                    setSelectedDate(olderDate);
                    handleDateChange(olderDate);
                }}
                size="small"
                sx={{ padding: "4px" }}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={customLocale}
            >
                <MobileDatePicker
                    views={["year", "month", "day"]}
                    closeOnSelect={false}
                    sx={{
                        position: "relative"
                    }}
                    minDateMessage={"Please select a start date."}
                    value={selectedDate}
                    onChange={(newValue) => {
                        const startOfDayDate = moment(newValue)
                            .startOf("day")
                            .toDate();
                        setSelectedDate(startOfDayDate);
                        handleDateChange(startOfDayDate);
                    }}
                    //onClose={() => handleDateChange(selectedDate)}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                size="small"
                                sx={{
                                    width: "132px",
                                    margin: "8px 0px",
                                    fontSize: "14px"
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    value: dateRangeDisplay,
                                    sx: { fontSize: 14 }
                                }}
                            />
                        </>
                    )}
                    PopperProps={{
                        disablePortal: true,
                        placement: "bottom-end"
                    }}
                />
            </LocalizationProvider>
            <IconButton
                onClick={() => {
                    const futureDate = moment(selectedDate)
                        .startOf("day")
                        .toDate();
                    futureDate.setDate(futureDate.getDate() + lookAheadDays);
                    setSelectedDate(futureDate);
                    handleDateChange(futureDate);
                }}
                size="small"
                sx={{ padding: "4px" }}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
        </>
    );
};
