import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import {
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    IconButton,
    Table,
    Checkbox,
    TableBody,
    TableFooter,
    Grid
} from "@mui/material";
import { ScheduleDataTable } from "./ScheduleDataTable";
import { ScheduleColumns } from "../utils/Constants";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from "../context/StoreContext";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
//{classes, page, rowsPerPage, columns, rows, multiSelectable, selected, selectAll, handleSelectAllClick, handleRowClick, handleEditClick, handleViewClick}) => {

const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
export const ScheduleTable = (props) => {
    const { actions, state } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const { rows, selectedRow } = props;

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const _handleEditClick = (id) => {
        actions.setMode("Edit");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
        actions.setProvider({
            ...state.provider
        });
    };

    async function handleDeactivateSchedule(sc) {
        console.log("handleDeactivateSchedule", sc);
        let res = await props.handleDeactivateSchedule(sc);
    }

    // const _handleTimeClick = (id) => {
    //     actions.setMode("AddTb");
    //     actions.setPage("ProviderScheduleAddEditForm");
    //     actions.setId(id);
    //     actions.setProvider({
    //         ...state.provider
    //     });
    // };
    return (
        <>
            <div>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <ScheduleDataTable
                            classes={classes}
                            columns={ScheduleColumns}
                            rows={rows}
                            multiSelectable={true}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={selected}
                            selectAll={selectAll}
                            handleEditClick={_handleEditClick}
                            handleDeactivate={handleDeactivateSchedule}
                            handleDecline={props.handleDecline}
                            hideView={true}
                            showActive={false}
                            showSelect={false}
                        />
                    </div>
                    <TableFooter>
                        <TableRow style={{ float: "right" }}>
                            <TablePagination
                                rowsPerPageOptions={[20, 40]}
                                colSpan={6}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "Rows per page"
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Paper>
            </div>
        </>
    );
};
