/**
 * Utility function to filter a list of location objects based on a selected service
 * @param {*} selectedServiceLocations
 * @param {*} companyLocations
 * @returns
 */
function filterLocations(selectedServiceLocations, companyLocations) {
    let filteredLocations = companyLocations.filter((location) =>
        selectedServiceLocations.includes(location.id)
    );
    return filteredLocations;
}

/**
 * Function to manage the filteredCompanyLocations state. This function will determine
 * the available locations based on the service selected
 * @param {Object} service - the selected service to filter by
 * @param {Array<Object>} companyLocations - list of locations that will be filtered based on the selected service
 * @param {Function} updateFunction - the function that will control the components respective state. For example, a setState function could be passed such as 'setFilteredLocations'
 * @returns filteredLocations || null (if no filter is required, and all companyLocations are valid)
 */
function manageFilteredLocationsByService(
    service,
    companyLocations,
    updateFunction
) {
    try {
        let filteredLocations;
        if (service && service.locations) {
            let serviceLocations = JSON.parse(service.locations);

            //The 'My Location' option in the companyLocations has an id of 0 so we add that to the list of locations by default
            serviceLocations = [...serviceLocations, "0"];

            filteredLocations = filterLocations(
                serviceLocations,
                companyLocations
            );
            updateFunction(filterLocations(serviceLocations, companyLocations));
        } else {
            filteredLocations = null;
            updateFunction();
        }
        return filteredLocations;
    } catch (e) {
        console.log("Error: Unable to filter locations by service", e);
    }
}

/**
 * Function to manage the filteredServices state. This function will determine
 * the available services based on the location selected.
 * @param {Object} location - the selected location to filter by
 * @param {Object<Array>} servicesList - list of services that will be filtered based on the selected location
 * @param {Function} updateFunction - the function that will control the components respective state. For example, a setState function could be passed such as 'setFilteredServices'
 * @returns updatedServicesList

 */
function manageFilteredServicesByLocation(
    location,
    servicesList,
    updateFunction
) {
    try {
        const updatedServicesList = servicesList.map((item) => {
            const service = { ...item };

            if (!service.locations) {
                return service; //Keep the service object in the list if it has no locations
            } else {
                let parsedLocations = JSON.parse(service.locations);
                //The 'My Location' option in the companyLocations has an id of 0 so we add that to the list of locations by default
                parsedLocations = [...parsedLocations, "0"];
                if (!parsedLocations.includes(location.id)) {
                    service.filteredByLocation = true; //Add new 'filteredByLocation' attribute used for disabled styling on services
                }
                return service;
            }
        });

        updateFunction(updatedServicesList);
        return updatedServicesList;
    } catch (e) {
        console.log("Error: Unable to filter services by location", e);
    }
}

export {
    filterLocations,
    manageFilteredLocationsByService,
    manageFilteredServicesByLocation
};
