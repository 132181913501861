//add company subdomain and id here which you wish to white-label

export const whiteLabelledCompanies = [
    {
        subdomain: "register.aquamobileswim.com",
        id: "c455bbc3-5147-4c37-9019-bc35eb2c26eb"
    },

    {
        subdomain: "travel.swimlessonswithmary.com",
        id: "d84cc46d-70d8-4d00-a396-1a20c6d485bb"
    },
    {
        subdomain: "april21.gomarketbox.com",
        id: "2eca4de9-c805-482a-9f1f-78568ac02a0d"
    }
];
