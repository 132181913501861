import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import TopPanel from "../layout/TopPanel";
import BottomPanel from "../layout/BottomPanel";
import GoogleFontLoader from "../../../components/GoogleFontLoader";

const OnboardingLandingPage = (props) => {
    console.log("OnboardingLandingPage Loaded");

    return (
        <Fragment>
            <GoogleFontLoader
                fonts={[{ font: "Poppins", weights: [400, 700, 900] }]}
            />
            {/* main grid that holds top panel and bottom panel */}
            <Grid container style={{ maxWidth: "1460px" }}>
                <TopPanel
                    renderActivateAccountForm={props.renderActivateAccountForm}
                    width={props.width}
                    freeTrial={props.freeTrial}
                />
                {props.width > 900 && (
                    <BottomPanel freeTrial={props.freeTrial} />
                )}
            </Grid>
        </Fragment>
    );
};

export default OnboardingLandingPage;
