import React, { useState, useEffect } from "react";
import { useStyles } from "../styles/MainContainerStyles";
import { Typography, Grid } from "@mui/material";
import { execRead } from "../modules/DBService";
import * as queries from "../graphql/queries";
// prettier-ignore
import "../dashboard/assets/css/bootstrap.min.css";
import "../dashboard/assets/css/icons.css";
import "../dashboard/assets/css/style.css";
import { getCurrencySymbol } from "../utils";
import CurrencyFormat from "react-currency-format";
// prettier-ignore
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { getUserFromCache } from "../user/UserCommon";
function MasterDashBoardForm(props) {
    const classes = useStyles();
    const [ordersToday, setOrdersToday] = useState(0);
    const [ordersMTD, setOrdersMTD] = useState(0);
    const [ordersYTD, setOrdersYTD] = useState(0);
    const [salesToday, setSalesToday] = useState(0);
    const [salesMTD, setSalesMTD] = useState(0);
    const [salesYTD, setSalesYTD] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [salesData, setSalesData] = useState();

    useEffect(() => {
        getCompanyData();
    }, []);

    // refresh every 30 seconds
    useEffect(() => {
        setInterval(() => {
            console.log("Refreshing company data.");
            getCompanyData();
        }, 30000);
    }, []);

    // compare function for desc sort on sales_ytd
    function compare(a, b) {
        if (a.sales_ytd > b.sales_ytd) {
            return -1;
        }
        if (a.sales_ytd < b.sales_ytd) {
            return 1;
        }
        return 0;
    }

    async function getCompanyData() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser) {
            const companies = await execRead({
                opname: "listCompanys",
                op: queries.listCompanys,
                limit: process.env.REACT_APP_LISTLIMIT
            });

            let companySalesData = [];
            let ordersToday = 0;
            let ordersMTD = 0;
            let ordersYTD = 0;
            let salesToday = 0;
            let salesMTD = 0;
            let salesYTD = 0;
            if (companies && companies.items) {
                for (let items of companies.items) {
                    const dashboardInfo = JSON.parse(items.DashboardInfo);
                    // exclude companies with no sales or test company Frasier Crane Therapy Practice
                    if (
                        dashboardInfo != null &&
                        dashboardInfo.sales_ytd > 0 &&
                        items.name != "Frasier Crane Therapy Practice"
                    ) {
                        ordersToday += dashboardInfo.orders_today;
                        ordersMTD += dashboardInfo.orders_mtd;
                        ordersYTD += dashboardInfo.orders_ytd;
                        salesToday += dashboardInfo.sales_today;
                        salesMTD += dashboardInfo.sales_mtd;
                        salesYTD += dashboardInfo.sales_ytd;
                        const comp = {
                            name: items.name,
                            "Sales MTD": dashboardInfo.sales_mtd.toFixed(2),
                            "Sales YTD": dashboardInfo.sales_ytd.toFixed(2),
                            sales_ytd: dashboardInfo.sales_ytd
                        };
                        companySalesData.push(comp);
                    }
                }
                setOrdersToday(ordersToday);
                setOrdersMTD(ordersMTD);
                setOrdersYTD(ordersYTD);
                setSalesToday(salesToday);
                setSalesMTD(salesMTD);
                setSalesYTD(salesYTD);
                // sort array by sales YTD
                companySalesData.sort(compare);
                setSalesData(companySalesData);
            }
        }
    }

    function getCurrentMonth() {
        let d = new Date();
        let month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        return month[d.getMonth()];
    }

    function getCurrentYear() {
        let d = new Date();
        return d.getFullYear();
    }

    const divStyle1 = {
        height: "242px"
    };

    const divStyle2 = {
        height: "280px"
    };

    return (
        <>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Typography className={classes.title} variant="h4" noWrap>
                        Master Dashboard &nbsp;
                    </Typography>
                </Grid>
            </Grid>
            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-4 col-sm-6">
                            <div className="card-box widget-box-one text-center">
                                <div className="wigdet-one-content">
                                    <p
                                        className="m-0 text-uppercase font-600 font-secondary text-overflow"
                                        title="Sales"
                                    >
                                        Sales today
                                    </p>
                                    <h4 className="text-danger">
                                        {
                                            <CurrencyFormat
                                                value={salesToday}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={getCurrencySymbol()}
                                                decimalScale={0}
                                                decimalSeparator=""
                                            />
                                        }
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end col --> */}
                        <div className="col-xl-2 col-lg-4 col-sm-6">
                            <div className="card-box widget-box-one text-center">
                                <div className="wigdet-one-content">
                                    <p
                                        className="m-0 text-uppercase font-600 font-secondary text-overflow"
                                        title="User Today"
                                    >
                                        Orders Today
                                    </p>
                                    <h4 className="text-dark">{ordersToday}</h4>
                                </div>
                            </div>
                        </div>{" "}
                        {/* <!-- end col --> */}
                        <div className="col-xl-2 col-lg-4 col-sm-6">
                            <div className="card-box widget-box-one text-center">
                                <div className="wigdet-one-content">
                                    <p
                                        className="m-0 text-uppercase font-600 font-secondary text-overflow"
                                        title="User This Month"
                                    >
                                        {getCurrentMonth()} Sales
                                    </p>
                                    <h4 className="text-success">
                                        {
                                            <CurrencyFormat
                                                value={salesMTD}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={getCurrencySymbol()}
                                                decimalScale={0}
                                                decimalSeparator=""
                                            />
                                        }
                                    </h4>
                                </div>
                            </div>
                        </div>{" "}
                        {/* <!-- end col --> */}
                        <div className="col-xl-2 col-lg-4 col-sm-6">
                            <div className="card-box widget-box-one text-center">
                                <div className="wigdet-one-content">
                                    <p
                                        className="m-0 text-uppercase font-600 font-secondary text-overflow"
                                        title="Request Per Minute"
                                    >
                                        {getCurrentMonth()} Orders
                                    </p>
                                    <h4 className="text-warning">
                                        {ordersMTD}
                                    </h4>
                                </div>
                            </div>
                        </div>{" "}
                        {/* <!-- end col --> */}
                        <div className="col-xl-2 col-lg-4 col-sm-6">
                            <div className="card-box widget-box-one text-center">
                                <div className="wigdet-one-content">
                                    <p
                                        className="m-0 text-uppercase font-600 font-secondary text-overflow"
                                        title="New Downloads"
                                    >
                                        {getCurrentYear()} Sales
                                    </p>
                                    <h4 className="text-danger">
                                        {
                                            <CurrencyFormat
                                                value={salesYTD}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={getCurrencySymbol()}
                                                decimalScale={0}
                                                decimalSeparator=""
                                            />
                                        }
                                    </h4>
                                </div>
                            </div>
                        </div>{" "}
                        {/* <!-- end col --> */}
                        <div className="col-xl-2 col-lg-4 col-sm-6">
                            <div className="card-box widget-box-one text-center">
                                <div className="wigdet-one-content">
                                    <p
                                        className="m-0 text-uppercase font-600 font-secondary text-overflow"
                                        title="Total Users"
                                    >
                                        {getCurrentYear()} Orders
                                    </p>
                                    <h4 className="text-primary">
                                        {ordersYTD}
                                    </h4>
                                </div>
                            </div>
                        </div>{" "}
                        {/* <!-- end col --> */}
                    </div>
                    {/* <!-- end row --> */}
                    <div className="row">
                        {/* <div className="col-xl-2 col-lg-2 col-sm-2">
                        </div> */}
                    </div>
                    {/* <!-- end col --> */}
                </div>
                {/* <!-- End container-fluid --> */}
            </div>
            {/* <!-- End wrapper -->  */}
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Typography className={classes.title} variant="h5" noWrap>
                        Sales by Company
                    </Typography>
                </Grid>
            </Grid>
            <BarChart
                layout="vertical"
                width={1000}
                height={800}
                data={salesData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 50,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, Math.round(salesYTD)]} />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Sales MTD" fill="#8884d8" />
                <Bar dataKey="Sales YTD" fill="#82ca9d" />
            </BarChart>
        </>
    );
}

export default MasterDashBoardForm;
