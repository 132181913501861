import React from "react";
import { Button, Typography, Link } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddPromoCodeModal from "./AddPromoCodeModal";

function NoPromoCodes(props) {
    return (
        <div
            style={{
                height: "260px",
                padding: "1rem",
                paddingLeft: "50px",
                display: "flex",
                gap: "1rem",
                width: "100%",
                alignItems: "center",
                background: "#ffffff",
                borderRadius: "4px",
                flexWrap: "wrap",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "430px"
                }}
            >
                <Typography variant="h6">
                    Create promo codes to promote your services
                </Typography>
                <Typography
                    variant="body2"
                    style={{ color: "rgba(0,0,0,0.7)" }}
                >
                    These promo codes can be redeemed by <br />
                    your clients through your Booking Flow.
                </Typography>
                <Typography
                    variant="body2"
                    style={{ color: "rgba(0,0,0,0.7)" }}
                >
                    Want to learn more about promo codes? <br />
                    Check out our help center to{" "}
                    <Link
                        href="https://www.gomarketbox.com/help-articles/promo-codes"
                        underline="none"
                        target="_blank"
                        sx={{
                            cursor: "pointer",
                            color: "primary.main",
                            fontSize: "14px",
                            fontFamily: "Roboto"
                        }}
                    >
                        learn more
                    </Link>
                </Typography>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => props.newPromoCodeHandler()}
                >
                    New Promo Code
                </Button>
                {/* <AddPromoCodeModal
                    openAddPromoModal={props.openAddPromoModal}
                    closeAddPromoModal={props.closeAddPromoModal}
                /> */}
            </div>
        </div>
    );
}

export default NoPromoCodes;
