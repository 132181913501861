import React, { useReducer } from "react";
import UserInputContext from "./userInputContext";
import UserInputReducer from "./userInputReducer";
import {
    SET_USER_INPUT_STATE,
    //SET_ACTIVESTEP,
    SET_HUBSPOT_CONTACT
} from "../types";
import voucher_codes from "voucher-code-generator";
function genSessionId() {
    return voucher_codes.generate({
        length: 8,
        count: 1,
        charset: voucher_codes.charset("alphanumeric")
    })[0];
}
const UserInputState = (props) => {
    const query = new URLSearchParams(window.location.search);
    let initialState = {
        sessionId: query.get("session_id")
            ? query.get("session_id")
            : genSessionId(),
        /* locationPath:
      props.location && props.location.pathname ? props.location.pathname : "",
    activeStep:
      props.location && props.location.pathname === "/thank-you" ? 3 : 0, */
        userInputs: {
            firstNameInput: "",
            lastNameInput: "",
            companyNameInput: "",
            emailAddress: "",
            phoneNumber: "",
            cleanPhone: "",
            numProvidersSelected: { value: 1, label: " 1 " },
            desiredSubdomain: "",
            subdomainEnding: {
                value: ".gomarketbox.com",
                label: ".gomarketbox.com"
            },
            userInputtedConfirmationCode: "",
            verificationCode: "",
            codeMatches: false,
            passedCaptcha: false,
            sentVerificationCode: false,
            emailAddressError: false,
            phoneNumberError: false,
            subdomainError: false,
            companyExists: false,
            companyId: "",
            temppas: "",
            companiesArr: null
        },
        hubspotContact: null
    };

    /* if (props.location && props.location.pathname === "/thank-you") {
    const stateFromLocalStorageJson = localStorage.getItem("userInputContext");
    console.log("stateFromLocalStorageJson", stateFromLocalStorageJson);
    if (stateFromLocalStorageJson) {
      try {
        const stateFromLocalStorage = JSON.parse(stateFromLocalStorageJson);
        initialState.userInputs = stateFromLocalStorage.userInputs;
        initialState.sessionId = stateFromLocalStorage.sessionId;
      } catch (e) {
        console.log(e);
      }
    }
  } */

    const [state, dispatch] = useReducer(UserInputReducer, initialState);
    function setUserState(userState) {
        dispatch({ type: SET_USER_INPUT_STATE, payload: userState });
        console.log("userState = " + JSON.stringify(userState));
    }

    /*  const setActiveStep = (step) => {
    dispatch({
      type: SET_ACTIVESTEP,
      payload: step,
    });
  }; */

    const setHubspotContact = (hubspotContact) => {
        dispatch({
            type: SET_HUBSPOT_CONTACT,
            payload: hubspotContact
        });
    };

    return (
        <UserInputContext.Provider
            value={{
                setUserState,
                //setActiveStep,
                setHubspotContact,
                //activeStep: state.activeStep,
                userInputs: state.userInputs,
                sessionId: state.sessionId,
                //locationPath: state.locationPath,
                hubspotContact: state.hubspotContact
            }}
        >
            {props.children}
        </UserInputContext.Provider>
    );
};

export default UserInputState;
