import React, { Fragment } from "react";
import classes from "./styles/CheckoutLandingPage.module.css";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import CheckoutLandingPage from "./components/pages/CheckoutLandingPage";
import "./CheckoutApp.css";
import UserInputState from "./context/userInput/userInputState";
import { useWindowSize } from "react-use";

function CheckoutApp(props) {
    let { width } = useWindowSize();

    return (
        <Fragment>
            <UserInputState>
                <Navbar width={width} />
                <main className={classes.main}>
                    <CheckoutLandingPage
                        monthlyLookupKey={props.monthlyLookupKey}
                        annualLookupKey={props.annualLookupKey}
                        planType={props.planType}
                        width={width}
                        reactivate={props.reactivate ? true : false}
                    />
                </main>
            </UserInputState>
            <Footer />
        </Fragment>
    );
}

export default CheckoutApp;
