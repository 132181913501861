import React, { useContext } from "react";
import {
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    IconButton,
    TableBody,
    Button,
    TableContainer,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { clientByUserId } from "../queries/ListBookingsQueries";
import { StoreContext } from "../context/StoreContext";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useStyles } from "./PromotionsTable";
import { MoreHoriz } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { userHasAdminRole } from "../user/UserCommon";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { mbxUserAtom } from "../atoms/atoms";
import { useAtom } from "jotai";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
}));

const BookingTableTheme = {
    display: "inline-table",
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

function DropDown(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        //setSelectedOrder('');
    };
    return (
        <div>
            <MoreHoriz
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    /*  props.handleThreeDotsClick(
                            e
                           
                        ); */
                }}
            />

            <Menu
                PaperProps={{
                    style: {
                        width: 150,
                        border: "1px solid #e5e5e5",
                        boxShadow: "1px 1px #e5e5e5"
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleThreeDotsClose}
            >
                {props.role !== "CLIENT" && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "#0087EE" }}
                        onClick={() => {
                            props.handleReqAccepted(props.row);
                            handleThreeDotsClose();
                        }}
                    >
                        Accept
                    </MenuItem>
                )}

                {props.role !== "CLIENT" && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                        onClick={() => {
                            props.handleReqDeclined(props.row);
                            handleThreeDotsClose();
                        }}
                    >
                        Decline
                    </MenuItem>
                )}

                {userHasAdminRole() && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "#FF681D" }}
                        onClick={() => {
                            props.handleReqDetails(props.row);

                            handleThreeDotsClose();
                        }}
                    >
                        Details
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}
const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className="MuiTablePagination-actions">
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
        </div>
    );
}
export const BookingReqListTable = (props) => {
    const [mbxUser] = useAtom(mbxUserAtom);
    const { actions, state } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("bookingsTable")
    );
    const { rows, selectedRow } = props;

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("bookingsTable")) {
            setTableRowsSession("bookingsTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const _handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    };
    const _handleRowClick = (event, id) => {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    };

    const _handleEditClick = (id) => {
        actions.setMode("Edit");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleViewClick = (id) => {
        actions.setMode("View");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleTimeClick = (id, { locationName, serviceName }) => {
        console.log("locationName", id);
        actions.setPage("TimeBlockForm");
        actions.setId(id);
        actions.setProvider({
            ...state.provider,
            locationName,
            service: serviceName,
            scheduleId: id
        });
    };

    //Function to retrieve client data using a users id
    const getClientData = async (user) => {
        try {
            let clientData = await graphql(
                graphqlOperation(clientByUserId, {
                    userId: user.id
                })
            );
            if (clientData && clientData.data.clientByUserId.items) {
                clientData = clientData.data.clientByUserId.items[0];
                clientData.user = user;
                /* console.log("this is the client profile: ", clientData); */
                return clientData;
            }
        } catch (e) {
            console.log(
                "ERROR: unable to retrieve client data using userId ",
                user.id,
                e
            );
        }
    };

    return (
        <>
            {/* New Pending Tab Table */}
            {props.tab === "pending" && (
                <>
                    {/* <h4>Pending Tab Table</h4> */}
                    <TableContainer sx={BookingTableTheme}>
                        <TableHead>
                            <TableRow>
                                {/* Future Tab With All Headers*/}
                                <TableCell>Booking Type</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Location</TableCell>

                                {props.role !== "PROVIDER" && (
                                    <TableCell>Provider</TableCell>
                                )}
                                {props.role !== "CLIENT" && (
                                    <TableCell>Client</TableCell>
                                )}
                                <TableCell sx={{ minWidth: "100px" }}>
                                    <>
                                        <Typography>No. of Bookings</Typography>
                                        <Tooltip title="You can hover over the number of bookings, to view the booking dates and times.">
                                            <HelpOutlineIcon fontSize="small" />
                                        </Tooltip>
                                    </>
                                </TableCell>
                                <TableCell sx={{ minWidth: "125px" }}>
                                    Alternate Providers
                                </TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {props.rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                {row.bookingType
                                                    ? row.bookingType
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {row.serviceAndPackage
                                                    ? row.serviceAndPackage
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {row.bookingLoc
                                                    ? row.bookingLoc
                                                    : "N/A"}
                                            </TableCell>

                                            {props.role !== "PROVIDER" && (
                                                <TableCell>
                                                    <Typography
                                                        sx={{
                                                            "&:hover": {
                                                                cursor:
                                                                    row?.data
                                                                        .provider
                                                                        ?.permalink &&
                                                                    "pointer",
                                                                textDecoration:
                                                                    row?.data
                                                                        .provider
                                                                        ?.permalink &&
                                                                    "underline"
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                row.data
                                                                    .provider
                                                                    .permalink
                                                            ) {
                                                                window.open(
                                                                    `https://${mbxUser.company.subdomain}/provider/${row.data.provider.permalink}`,
                                                                    "_blank"
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {row.providerDetails ||
                                                            "N/A"}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {props.role !== "CLIENT" && (
                                                <TableCell>
                                                    <span
                                                        onMouseEnter={(e) => {
                                                            e.target.style.textDecoration =
                                                                "underline";
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.target.style.textDecoration =
                                                                "none";
                                                        }}
                                                        onClick={async () => {
                                                            /* console.log(
                                                                "row from the table",
                                                                row
                                                            ); */

                                                            props.showClientDialog(
                                                                await getClientData(
                                                                    row.data
                                                                        .user
                                                                )
                                                            );
                                                        }}
                                                        style={{
                                                            fontFamily:
                                                                "Roboto",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {row.clientDetails
                                                            ? row.clientDetails
                                                            : "N/A"}
                                                    </span>
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                {row.numberOfBookings &&
                                                row.numberOfBookings > 0 ? (
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit">
                                                                    Booking
                                                                    Times
                                                                </Typography>
                                                                <div>
                                                                    {row.bookingTimes.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                style={{
                                                                                    padding:
                                                                                        "2px 0px 2px 0px"
                                                                                }}
                                                                            >
                                                                                {item.replace(
                                                                                    //The replace function is used to remove the GMT offset
                                                                                    /\(.*?\)/,
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <p
                                                            style={{
                                                                cursor: "pointer",
                                                                margin: 0
                                                            }}
                                                        >
                                                            {
                                                                row.numberOfBookings
                                                            }
                                                        </p>
                                                    </HtmlTooltip>
                                                ) : (
                                                    "N/A"
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.tryOtherProviders
                                                    ? `${row.tryOtherProviders} (${row.numberOfOthers})`
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {row.createdDesc
                                                    ? row.createdDesc
                                                    : "N/A"}
                                            </TableCell>
                                            {props.role !== "CLIENT" && (
                                                <TableCell>
                                                    <DropDown
                                                        row={row}
                                                        role={props.role}
                                                        handleReqAccepted={
                                                            props.handleReqAccepted
                                                        }
                                                        handleReqDeclined={
                                                            props.handleReqDeclined
                                                        }
                                                        handleReqDetails={
                                                            props.handleReqDetails
                                                        }
                                                    />
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </TableContainer>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        {props.rows.length !== 0 && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={props.handleLoadMore}
                                disabled={props.endOfList}
                            >
                                {props.endOfList
                                    ? "No More Rows To Load"
                                    : "Load More"}
                            </Button>
                        )}

                        <TablePagination
                            sx={{
                                marginLeft: "auto",
                                "& .MuiToolbar-root": {
                                    alignItems: "center",
                                    "& .MuiInputBase-root": {
                                        fontWeight: 700
                                    },
                                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                        {
                                            color: "rgba(0,0,0,0.5)",
                                            marginBottom: 0
                                        },
                                    "& .MuiTablePagination-actions": {
                                        display: "flex",
                                        gap: "0.5rem",
                                        "& button": {
                                            border: "1px solid rgba(0,0,0,0.2)",
                                            borderRadius: "4px"
                                        },

                                        "& button:hover": {
                                            backgroundColor: "primary.light"
                                        }
                                    }
                                }
                            }}
                            component={"div"}
                            rowsPerPageOptions={[5, 10, 25]}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </>
            )}
        </>
    );
};
