import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    MenuItem,
    Rating,
    Snackbar,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Tooltip,
    Fade
} from "@mui/material";
import CurrencyFormat from "react-currency-format";
import ChatIcon from "@mui/icons-material/Chat";
import { Link } from "react-router-dom";
import { getCurrencySymbol, getCurrencySymbolFromParam } from "../utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { clientByUserId } from "../queries/ListBookingsQueries";
import * as queries from "../graphql/queries";
import { MoreHoriz } from "@mui/icons-material";
import {
    getUserFromCache,
    userCanCancelAppt,
    userHasAdminRole,
    userHasProviderRole,
    userHasClientRole
} from "../user/UserCommon";
import ProviderRescheduleModal from "./RescheduleModal";
import ClientRescheduleModal from "./ClientReschedulingModal";
import { StoreContext } from "../context/StoreContext";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { mbxUserAtom } from "../atoms/atoms";
import { useAtom } from "jotai";

const BookingTableTheme = {
    display: "inline-table",
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

function DropDown(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        //setSelectedOrder('');
    };

    const showRescheduleOption = () => {
        const loggedInUser = getUserFromCache();

        if (loggedInUser?.role === "PROVIDER") {
            if (
                loggedInUser?.company?.providerCanReschedule &&
                props.row.status === "SCHEDULED"
            ) {
                //make this true to show reschedule option
                return true;
            }
        }

        if (loggedInUser?.role === "CLIENT") {
            if (
                loggedInUser?.company?.clientCanReschedule &&
                props.row.status === "SCHEDULED"
            ) {
                //make this true to show reschedule option
                return true;
            }
        }
        //make it false to hide
        return false;
    };
    return (
        <div>
            <MoreHoriz
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);

                    /*  props.handleThreeDotsClick(
                        e
                       
                    ); */
                }}
            />

            <Menu
                PaperProps={{
                    style: {
                        width: 150,
                        border: "1px solid #e5e5e5",
                        boxShadow: "1px 1px #e5e5e5"
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleThreeDotsClose}
            >
                {props.row.status === "SCHEDULED" && !userHasClientRole() && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "#0087EE" }}
                        onClick={() => {
                            props.handleArrived(props.row);
                            handleThreeDotsClose();
                        }}
                    >
                        Arrived
                    </MenuItem>
                )}
                {showRescheduleOption() && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "#FF681D" }}
                        onClick={() => {
                            //const loggedInUser = getUserFromCache();
                            props.getSelectedRow(props.row.id);
                            /* if(loggedInUser.role === 'PROVIDER'){
                                props.setProviderRescheduleModal(true)
                            } */
                            props.openRescheduleModal();
                            handleThreeDotsClose();
                            // console.log("LoggedInUser", loggedInUser);
                        }}
                    >
                        Reschedule
                    </MenuItem>
                )}
                {(props.row.status === "ARRIVED" ||
                    props.row.status === "COMPLETED") &&
                    !userHasClientRole() && (
                        <MenuItem
                            sx={{ fontWeight: 700, color: "#0087EE" }}
                            onClick={() => {
                                props.handleAddCharge(props.row);
                                handleThreeDotsClose();
                            }}
                        >
                            Add Charge
                        </MenuItem>
                    )}
                {props.row.status === "ARRIVED" && !userHasClientRole() && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                        onClick={() => {
                            props.handleCompleted(props.row);
                            handleThreeDotsClose();
                        }}
                    >
                        Completed
                    </MenuItem>
                )}
                {props.row.status === "SCHEDULED" && userCanCancelAppt() && (
                    <MenuItem
                        sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                        onClick={() => {
                            props.handleCancel(props.row);
                            //props.refreshTable();
                            handleThreeDotsClose();
                        }}
                    >
                        Cancel
                    </MenuItem>
                )}
                {userHasAdminRole() &&
                    !(
                        props.row.status === "COMPLETED" ||
                        props.row.status === "CANCELLED"
                    ) && (
                        <MenuItem
                            sx={{ fontWeight: 700, color: "#8B0000" }}
                            onClick={() => {
                                props.handleOverride(props.row);
                                handleThreeDotsClose();
                            }}
                        >
                            Edit
                        </MenuItem>
                    )}
            </Menu>
        </div>
    );
}

function BookingsFutureTabTable(props) {
    const [mbxUser] = useAtom(mbxUserAtom);
    const { state, actions } = useContext(StoreContext);
    //Virtual Link States
    const [openVMDialog, setOpenVMDialog] = useState(false);
    const [serviceDesc, setServiceDesc] = useState("");
    const [serviceProv, setServiceProv] = useState("");
    const [startDate, setStartDate] = useState("");
    const [serviceLoc, setServiceLoc] = useState("");

    //snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");

    //Table Pagination States
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("bookingsTable")
    );

    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [providerReScheduleModal, setProviderRescheduleModal] =
        useState(false);
    const [clientRescheduleModal, setClientRescheduleModal] = useState(false);

    //const [canProviderReschedule, setCanProviderReschedule] =  useState(false)
    useEffect(() => {
        const loggedInUser = getUserFromCache();
        console.log(
            "LoggedInUser | Booking Future Tab",
            loggedInUser,
            userHasAdminRole(),
            userHasProviderRole()
        );
        if (loggedInUser.role === "PROVIDER") {
            setProviderRescheduleModal(true);
        }
        /* if(loggedInUser?.providerCanReschedule){
            setCanProviderReschedule(loggedInUser.providerCanReschedule)
        } */

        console.log("Future Tab Data", props.rows);
    }, [props.rows]);

    const loggedInUser = getUserFromCache();

    //rescheduleModal - provider , clientRescheduleModal - client
    const openRescheduleModal = () => {
        if (loggedInUser?.role === "PROVIDER") {
            setRescheduleModal(true);
        }

        if (loggedInUser?.role === "CLIENT") {
            setClientRescheduleModal(true);
        }
    };

    const closeRescheduleModal = () => {
        if (loggedInUser?.role === "PROVIDER") {
            setRescheduleModal(false);
        }

        if (loggedInUser?.role === "CLIENT") {
            setClientRescheduleModal(false);
        }
    };

    const openSnackbar = (msg) => {
        setSnackMsg(msg);
        setMsgOpen(true);
    };

    const getSelectedRow = (id) => {
        let filter = props?.rows.filter((item) => id === item.id);
        console.log("Selected Row", filter[0]);
        setSelectedRowData(filter[0]);
        return filter[0];
    };

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("bookingsTable")) {
            setTableRowsSession("bookingsTable", event.target.value);
        }

        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    //Function to retrieve client data using a userId
    const getOrderData = async (orderId) => {
        try {
            let orderData = await graphql(
                graphqlOperation(queries.getOrder, {
                    id: orderId
                })
            );
            if (orderData && orderData.data.getOrder) {
                orderData = orderData.data.getOrder;
                if (orderData.Notes) {
                    orderData.Notes = orderData.Notes
                        ? JSON.parse(orderData.Notes)
                        : null;
                }
                /* console.log("this is the order data: ", orderData); */
                return orderData;
            } else {
                console.log("ERROR: No order found for order ID ", orderId);
            }
        } catch (e) {
            console.log(
                "ERROR: unable to retrieve order data using orderId ",
                orderId,
                e
            );
        }
    };

    //Function to retrieve client data using a userId
    const getClientData = async (userId, user) => {
        try {
            let clientData = await graphql(
                graphqlOperation(clientByUserId, {
                    userId: userId
                })
            );
            if (clientData && clientData.data.clientByUserId.items) {
                clientData = clientData.data.clientByUserId.items[0];
                clientData.user = user;
                /* console.log("this is the client profile: ", clientData); */
                return clientData;
            }
        } catch (e) {
            console.log(
                "ERROR: unable to retrieve client data using userId ",
                user.id,
                e
            );
        }
    };

    const handleThreeDotsClick = (event) => {
        //setSelectedProvider(providerId.toString());
        //setAnchorEl(event.currentTarget);
    };

    const handleProviderPermalinkClick = (permalink) => {
        const subdomain = mbxUser.company.subdomain;
        if (permalink) {
            window.open(`https://${subdomain}/provider/${permalink}`, "_blank");
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={5000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={openVMDialog}
                onClose={() => setOpenVMDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Would you like to join this meeting room?
                </DialogTitle>
                <DialogContent>
                    <div>
                        <span>Service Description:</span> {serviceDesc}
                        <br />
                        <span>Provider:</span> {serviceProv}
                        <br />
                        <span>Starting Date:</span> {startDate}
                        <br />
                        <span>Location: </span>
                        <Link target="_blank" href={serviceLoc}>
                            {serviceLoc}
                        </Link>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenVMDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            window.open(`${serviceLoc}`, "_blank");
                            setOpenVMDialog(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Join
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer sx={BookingTableTheme}>
                <TableHead>
                    <TableRow>
                        {/*headers &&
                                headers.length > 0 &&
                                headers.map((item, index) => {
                                    return <TableCell>{item.title}</TableCell>;
                                })*/}
                        {/* Future Tab With All Headers*/}
                        <TableCell>Description</TableCell>
                        <TableCell>Booking Location</TableCell>
                        {props.role !== "CLIENT" && (
                            <TableCell>Client</TableCell>
                        )}
                        {props.role !== "PROVIDER" && (
                            <TableCell>Provider</TableCell>
                        )}
                        <TableCell sx={{ minWidth: "140px" }}>
                            Start Date
                        </TableCell>
                        <TableCell sx={{ minWidth: "125px" }}>
                            Date Created
                        </TableCell>
                        <TableCell>Status</TableCell>
                        {props.role !== "PROVIDER" && (
                            <TableCell>Initial Charge</TableCell>
                        )}
                        {props.role !== "PROVIDER" && (
                            <TableCell>Additional Charges</TableCell>
                        )}
                        {props.role !== "PROVIDER" && (
                            <TableCell>Ratings</TableCell>
                        )}
                        {props.role !== "CLIENT" && (
                            <TableCell sx={{ minWidth: "80px" }}>
                                Notes
                            </TableCell>
                        )}
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.rows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                            return (
                                <TableRow key={row.id}>
                                    {/*headers &&
                                        headers.length > 0 &&
                                        headers.map((item, index) => {
                                            return (
                                                <TableCell>
                                                    {row[item.name]}
                                                </TableCell>
                                            );
                                        })*/}
                                    <TableCell>{row.desc}</TableCell>
                                    <TableCell>
                                        {row.location &&
                                        row.location.includes(
                                            "Virtual Appointment"
                                        ) ? (
                                            <Typography
                                                sx={{
                                                    color: "primary.main",
                                                    cursor: "pointer"
                                                }}
                                                style={{ fontWeight: 700 }}
                                                onClick={() => {
                                                    let vmLink = row.provider
                                                        .vmlink
                                                        ? row.provider.vmlink
                                                        : "N/A";
                                                    setServiceDesc(row.desc);
                                                    setServiceProv(
                                                        row.providerName
                                                    );
                                                    setStartDate(
                                                        row.startDateDesc
                                                    );
                                                    setServiceLoc(vmLink);
                                                    setOpenVMDialog(true);
                                                }}
                                            >
                                                {row.location}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    color: "primary.main",
                                                    cursor: "pointer"
                                                }}
                                                style={{ fontWeight: 700 }}
                                                onClick={() => {
                                                    window.open(
                                                        `https://www.google.com/maps/search/?api=1&query=${row.location.replaceAll(
                                                            " ",
                                                            "+"
                                                        )}`,
                                                        "_blank"
                                                    );
                                                }}
                                            >
                                                {row.location
                                                    ? row.location
                                                    : "N/A"}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    {props.role !== "CLIENT" && (
                                        <TableCell>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.5rem"
                                                }}
                                            >
                                                <span
                                                    onMouseEnter={(e) => {
                                                        e.target.style.textDecoration =
                                                            "underline";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.textDecoration =
                                                            "none";
                                                    }}
                                                    onClick={async () => {
                                                        /* console.log(
                                                            "row from the table",
                                                            row
                                                        ); */
                                                        props.showClientDialog(
                                                            row.client
                                                        );
                                                    }}
                                                    style={{
                                                        fontFamily: "Roboto",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    {row.clientName}
                                                </span>
                                                <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        cursor: "pointer"
                                                    }}
                                                    style={{ fontWeight: 600 }}
                                                    onClick={() =>
                                                        window.open(
                                                            `tel:${props.checkingPhoneNumber(
                                                                row
                                                            )}`
                                                        )
                                                    }
                                                >
                                                    {props.checkingPhoneNumber(
                                                        row
                                                    )}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    )}
                                    {props.role !== "PROVIDER" && (
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    "&:hover": {
                                                        cursor:
                                                            row?.provider
                                                                ?.permalink &&
                                                            "pointer",
                                                        textDecoration:
                                                            row?.provider
                                                                ?.permalink &&
                                                            "underline"
                                                    }
                                                }}
                                                onClick={() =>
                                                    handleProviderPermalinkClick(
                                                        row.provider.permalink
                                                    )
                                                }
                                            >
                                                {row.providerName || "N/A"}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {row.startDateDesc.includes("(")
                                            ? row.startDateDesc.split("(")[0]
                                            : row.startDateDesc
                                              ? row.startDateDesc
                                              : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {row.dateCreated
                                            ? row.dateCreated
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                maxWidth: "80px",
                                                backgroundColor:
                                                    row.status === "SCHEDULED"
                                                        ? "primary.light"
                                                        : row.status ===
                                                            "COMPLETED"
                                                          ? "success.light"
                                                          : row.status ===
                                                              "CANCELLED"
                                                            ? "error.light"
                                                            : "rgba(0,0,0,0.1)",
                                                color:
                                                    row.status === "SCHEDULED"
                                                        ? "primary.dark"
                                                        : row.status ===
                                                            "COMPLETED"
                                                          ? "success.dark"
                                                          : row.status ===
                                                              "CANCELLED"
                                                            ? "rgba(255,255,255,0.8)"
                                                            : "rgba(0,0,0,0.5)",
                                                textTransform: "uppercase",
                                                padding: "2px",
                                                borderRadius: "4px",
                                                textAlign: "center",
                                                margin:
                                                    row.status.length > 0
                                                        ? "0 auto"
                                                        : "0"
                                            }}
                                            style={{
                                                fontSize: "0.75rem",
                                                fontWeight: 600
                                            }}
                                        >
                                            {row.status
                                                ? row.status === "NOSHOW"
                                                    ? "NO-SHOW"
                                                    : row.status
                                                : "N/A"}
                                        </Typography>
                                    </TableCell>
                                    {props.role !== "PROVIDER" && (
                                        <TableCell>
                                            <CurrencyFormat
                                                value={row.initialCharge}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={
                                                    row.currencyValue
                                                        ? getCurrencySymbolFromParam(
                                                              row.currencyValue
                                                          )
                                                        : getCurrencySymbol()
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </TableCell>
                                    )}
                                    {props.role !== "PROVIDER" && (
                                        <TableCell>
                                            {row.additionalCharges === 0 ? (
                                                "None"
                                            ) : (
                                                <CurrencyFormat
                                                    value={
                                                        row.additionalCharges
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={
                                                        row.currencyValue
                                                            ? getCurrencySymbolFromParam(
                                                                  row.currencyValue
                                                              )
                                                            : getCurrencySymbol()
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            )}
                                        </TableCell>
                                    )}
                                    {props.role !== "PROVIDER" && (
                                        <TableCell>
                                            {row.ratingstars <= 0 ||
                                            row.ratingstars === ""
                                                ? "No Rating(s)"
                                                : props.showRatingStars() && (
                                                      <Rating
                                                          value={
                                                              row.ratingstars
                                                          }
                                                          precision={0.5}
                                                          size="small"
                                                          readOnly
                                                      />
                                                  )}
                                        </TableCell>
                                    )}
                                    {props.role !== "CLIENT" && (
                                        <TableCell>
                                            <div
                                                onClick={() => {
                                                    props.showNoteDialog(row);
                                                }}
                                            >
                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{
                                                                whiteSpace:
                                                                    "pre-line"
                                                            }}
                                                        >
                                                            {row.order.Notes &&
                                                            JSON.parse(
                                                                row.order.Notes
                                                            )[0].notes
                                                                ? JSON.parse(
                                                                      row.order
                                                                          .Notes
                                                                  )[0].notes
                                                                : !row.order
                                                                        .Notes &&
                                                                    row.Notes &&
                                                                    row.Notes[0]
                                                                        .notes
                                                                  ? row.Notes[0]
                                                                        .notes
                                                                  : "Click here to add notes"}
                                                        </div>
                                                    }
                                                    placement="right"
                                                    arrow
                                                    TransitionComponent={Fade}
                                                >
                                                    <ChatIcon
                                                        style={{
                                                            //first checks for order Notes, then checks for booking Notes, since order Notes may not exist
                                                            color:
                                                                row.order
                                                                    .Notes &&
                                                                JSON.parse(
                                                                    row.order
                                                                        .Notes
                                                                )[0].notes
                                                                    ? row
                                                                          .company
                                                                          .primaryColor
                                                                    : !row.order
                                                                            .Notes &&
                                                                        row.Notes &&
                                                                        row
                                                                            .Notes[0]
                                                                            .notes
                                                                      ? row
                                                                            .company
                                                                            .primaryColor
                                                                      : "#c5c5c5",
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {!(
                                            row.status === "CANCELLED" ||
                                            (row.status === "NOSHOW" &&
                                                !userHasAdminRole()) ||
                                            (row.status === "ARRIVED" &&
                                                userHasClientRole())
                                        ) && (
                                            <>
                                                <DropDown
                                                    row={row}
                                                    handleArrived={
                                                        props.handleArrived
                                                    }
                                                    handleAddCharge={
                                                        props.handleAddCharge
                                                    }
                                                    handleCompleted={
                                                        props.handleCompleted
                                                    }
                                                    handleCancel={
                                                        props.handleCancel
                                                    }
                                                    handleOverride={
                                                        props.handleOverride
                                                    }
                                                    openRescheduleModal={
                                                        openRescheduleModal
                                                    }
                                                    closeRescheduleModal={
                                                        closeRescheduleModal
                                                    }
                                                    rescheduleModal={
                                                        rescheduleModal
                                                    }
                                                    getSelectedRow={
                                                        getSelectedRow
                                                    }
                                                    refreshTable={
                                                        props.refreshTable
                                                    }
                                                />
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TableContainer>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                {props.rows.length !== 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleLoadMore}
                        disabled={props.endOfList}
                    >
                        {props.endOfList ? "No More Rows To Load" : "Load More"}
                    </Button>
                )}

                <TablePagination
                    sx={{
                        marginLeft: "auto",
                        "& .MuiToolbar-root": {
                            alignItems: "center",
                            "& .MuiInputBase-root": {
                                fontWeight: 700
                            },
                            "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                {
                                    color: "rgba(0,0,0,0.5)",
                                    marginBottom: 0
                                },
                            "& .MuiTablePagination-actions": {
                                display: "flex",
                                gap: "0.5rem",
                                "& button": {
                                    border: "1px solid rgba(0,0,0,0.2)",
                                    borderRadius: "4px"
                                },

                                "& button:hover": {
                                    backgroundColor: "primary.light"
                                }
                            }
                        }
                    }}
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={props.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

            {loggedInUser?.role === "PROVIDER" && (
                <ProviderRescheduleModal
                    rescheduleModal={rescheduleModal}
                    openRescheduleModal={openRescheduleModal}
                    closeRescheduleModal={closeRescheduleModal}
                    selectedRowData={selectedRowData}
                    openSnackbar={openSnackbar}
                    refreshTable={props.refreshTable}
                />
            )}
            {loggedInUser?.role === "CLIENT" && (
                <ClientRescheduleModal
                    rescheduleModal={clientRescheduleModal}
                    openRescheduleModal={openRescheduleModal}
                    closeRescheduleModal={closeRescheduleModal}
                    selectedRowData={selectedRowData}
                    openSnackbar={openSnackbar}
                    refreshTable={props.refreshTable}
                />
            )}
        </>
    );
}

export default BookingsFutureTabTable;
