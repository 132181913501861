import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { useToolbarStyles } from "../styles/TableToolbarStyles";
import clsx from "clsx";
// prettier-ignore
import { Paper, Grid, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Toolbar, Button, Tooltip, IconButton } from "@mui/material";
// prettier-ignore
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Snackbar, OutlinedInput } from "@mui/material";
import PackageTable from "../components/PackageTable";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { getUserFromCache } from "../user/UserCommon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { auditPackagesDelete } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { packageByCompany } from "../queries/CustomQueries";

import { useNavigate, Outlet } from "react-router-dom";

function PackageForm(props) {
    const navigate = useNavigate();

    const { actions } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    //for type
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    //active pending switch
    const [active, setActive] = React.useState(true);
    const [selected, setSelected] = React.useState([]);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    const [rows, setRows] = React.useState([]);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [serviceTypeData, setServiceTypeData] = useState({});
    const [user, setUser] = useState({});
    const limit = process.env.REACT_APP_LISTLIMIT;

    // add button click
    function handlePackageAddClick() {
        actions.setMode("Add");
        navigate("create");
    }
    const [values, setValues] = React.useState({
        type: "",
        searchTerm: ""
    });

    // get current user
    const authuser = getUserFromCache();

    let filter = {
        and: [{ active: { ne: !active } }, { deleted: { ne: true } }]
    };

    const fetchServiceTypes = async () => {
        const authuser = getUserFromCache();

        const result = await graphql(
            graphqlOperation(queries.serviceTypeByCompany, {
                companyId: authuser.company.id,
                limit: limit,
                filter: {
                    and: [
                        { deleted: { ne: true } },
                        { active: { ne: false } },
                        { isVisible: { ne: false } }
                    ]
                }
            })
        );
        setServiceTypeData(result.data.serviceTypeByCompany.items);
    };

    useEffect(() => {
        setUser(authuser);
        setLabelWidth(inputLabel.current.offsetWidth);
        fetchServiceTypes();
        fetchPackagesList(filter);
    }, []);

    async function fetchPackagesList(newFilter) {
        let listPackagesData = await execReadByPK({
            opname: "packageByCompany",
            op: packageByCompany,
            id: { companyId: authuser.company.id },
            filter: newFilter,
            sortDirection: "DESC"
        });

        // sort packages
        listPackagesData.items.sort((p1, p2) => {
            const psq1 = p1.servicetype.name.toUpperCase();
            const psq2 = p2.servicetype.name.toUpperCase();
            if (psq1 === psq2) {
                return p1.quantity - p2.quantity;
            }
            if (psq1 < psq2) return -1;
            if (psq1 > psq2) return 1;
            return 0;
        });
        setRows(listPackagesData.items);
    }

    function handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    const tabChanged = (event) => {
        // change status
        setActive(!active);
        // refresh
        filter = {
            and: [
                {
                    deleted: {
                        ne: true
                    }
                }
            ]
        };

        fetchPackagesList(filter);
    };

    const handlePackageChange = (event) => {
        const { name, value } = event.target;
        let newFilter = {};
        console.log("name", name, name === "type");
        if (!!value) {
            if (name === "type") {
                newFilter = {
                    ...filter,
                    and: [
                        { servicetypeId: { eq: value } },
                        { deleted: { ne: true } },
                        { active: { ne: !active } }
                    ]
                };
            } else {
                newFilter = {
                    and: [
                        { desc: { contains: value } },
                        { deleted: { ne: true } },
                        { active: { ne: !active } }
                    ]
                };
            }
        } else {
            newFilter = {
                and: [{ active: { ne: !active } }, { deleted: { ne: true } }]
            };
        }
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));
        fetchPackagesList(newFilter);
    };

    const _deleteRecord = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deletePackage(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    const deletePackage = async (id) => {
        const input = {
            id: id,
            deleted: true
        };
        try {
            let deletePackage = await graphql(
                graphqlOperation(mutations.updatePackage, { input })
            );
            await auditPackagesDelete(
                authuser,
                deletePackage.data.updatePackage
            );
            if (!!deletePackage.data && !!deletePackage.data.updatePackage) {
                console.log("in filter");
                const latFilter = {
                    and: [
                        { active: { ne: !active } },
                        { deleted: { ne: true } }
                    ]
                };
                fetchPackagesList(latFilter);
                setDeleteDialog(false);
                setSelected([]);
                // actions.setPage("PackageForm");
            } else {
                setSnackMsg("Something went wrong, while deketing package.");
                setMsgOpen(true);
            }
        } catch (err) {
            console.log("in catch");
            setSnackMsg("Something went wrong, while deketing package.");
            setMsgOpen(true);
        }
    };
    const EnhancedTableToolbar = (props) => {
        const classesToolbar = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classesToolbar.root, {
                    [classesToolbar.highlight]: numSelected > 0
                })}
            >
                <div className={classesToolbar.title}>
                    {numSelected > 0 && (
                        <Typography variant="h6" id="tableTitle">
                            {selected.length} selected
                        </Typography>
                    )}
                </div>
                <div className={classesToolbar.spacer} />
                <div className={classesToolbar.actions}>
                    {numSelected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="Delete"
                                onClick={() => setDeleteDialog(true)}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        ""
                    )}
                </div>
            </Toolbar>
        );
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={deleteDialog}
                //onClose={handleDeleteDialogDecline}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you wish to delete the selected packages?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete all the selected packages. They
                        will no longer be available on the system.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogDecline} color="primary">
                        Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={_deleteRecord}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ToggleButtonGroup
                            size="small"
                            value={active}
                            exclusive
                            onChange={tabChanged}
                        >
                            <ToggleButton key={1} value={true}>
                                Active
                            </ToggleButton>
                            <ToggleButton key={2} value={false}>
                                Inactive
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="search"
                            name="desc"
                            label="Search by name"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handlePackageChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl
                            variant="outlined"
                            className={classes.fullWidth}
                            margin="normal"
                        >
                            <InputLabel
                                ref={inputLabel}
                                htmlFor="outlined-age-simple"
                                id="package-services-select"
                            >
                                Service
                            </InputLabel>
                            <Select
                                value={values.type}
                                label="Service"
                                name="type"
                                onChange={handlePackageChange}
                                input={
                                    <OutlinedInput
                                        label="Service"
                                        id="package-services-select"
                                        labelWidth={labelWidth}
                                        name="promotiontype"
                                    />
                                }
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {serviceTypeData &&
                                    serviceTypeData.length > 0 &&
                                    serviceTypeData.map((item, i) => {
                                        return (
                                            <MenuItem value={item.id} key={i}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs={12} align="right">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePackageAddClick}
                        >
                            <AddIcon className={classes.leftIcon} />
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div>
                            <EnhancedTableToolbar
                                numSelected={selected.length}
                            />
                            <PackageTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                fetchPackagesList={fetchPackagesList}
                                listPackagesData={rows.filter((item) => {
                                    return item.active === active;
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default PackageForm;
