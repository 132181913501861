import React, { useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        minWidth: "100%",
        fontFamily: "Poppins, sans-serif",
        display: "flex",
        justifyContent: "center"
    },
    selectField: {
        "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.23)"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.67)"
        },
        "&.MuiInputLabel-root.Mui-focused": {
            color: "black"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        },
        width: "166px",
        [theme.breakpoints.down("sm")]: {
            width: "155px"
        },
        maxWidth: "166px",
        height: "46px",
        fontFamily: "Poppins, sans-serif"
    },
    input: {
        display: "flex",
        justifyContent: "center"
    }
}));

const BillingPeriodSelector = (props) => {
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);
    const [selectedValue, setSelectedValue] = React.useState(
        query.get("bp") === "M8tD4"
            ? props.annualLookupKey
            : props.monthlyLookupKey
    );

    useEffect(() => {
        if (props.reactivate) {
            setSelectedValue(props.plan);
        }
    }, [props.plan, props.reactivate]);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        props.onPlanUpdate(event.target.value);
    };

    return (
        <FormControl>
            <Select
                className={classes.selectField}
                label=""
                id="dropdown-select"
                value={selectedValue}
                onChange={handleChange}
                inputProps={{
                    className: classes.input
                }}
            >
                <MenuItem
                    value={props.monthlyLookupKey}
                    className={classes.menuItem}
                >
                    Monthly
                </MenuItem>
                <MenuItem
                    value={props.annualLookupKey}
                    className={classes.menuItem}
                >
                    Annually
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default BillingPeriodSelector;
