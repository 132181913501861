import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
import clsx from "clsx";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
//import { useFetchGraphQL } from '../hooks/useFetchGraphQL';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
//import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";

import { serviceAddStyles } from "../styles/ServiceAddStyles";
import { getUserFromCache } from "../user/UserCommon";
import { execRead } from "../modules/DBService";

import { auditSkillsCreate, auditSkillsUpdate } from "../modules/Audit";

export const SkillsAddEditForm = (props) => {
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    const componentClasses = serviceAddStyles();
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const { state, dispatch, actions } = useContext(StoreContext);
    const [snackMsg, setSnackMsg] = useState();

    // fields
    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [importance, setImportance] = useState(0);
    const [active, setActive] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [oldInfo, setOldInfo] = useState([]);

    const [disableSave, setDisableSave] = useState(false);

    const limit = process.env.REACT_APP_LISTLIMIT;

    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleChangeImportance = (e) => {
        if (
            Number.isInteger(Number.parseInt(e.target.value)) &&
            Number.parseInt(e.target.value) >= 0
        ) {
            setImportance(e.target.value);
        } else {
            setImportance("");
        }
    };

    let newInfo;
    async function handleSaveSkillType() {
        setDisableSave(true);
        // validate
        if (!name || name === "") {
            setSnackMsg("Please enter a name for the skill.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }
        // checking to see if anything changed
        if (isEdit) {
            newInfo = [
                {
                    oldName: oldInfo[0].name,
                    oldActive: oldInfo[0].active,
                    name: "No Change",
                    active: "No Change"
                }
            ];

            if (name != oldInfo[0].name) {
                newInfo.map((item) => {
                    item.name = name;
                });
            }

            if (active != oldInfo[0].active) {
                newInfo.map((item) => {
                    item.active = active;
                });
            }
        }
        // save skill data
        if (!!importance || !!name) {
            await saveSkillData({});
        }
    }

    function handleCancel() {
        reset();
        actions.setPage("SkillsForm");
    }
    function reset() {
        // reset fields
        console.log("in reset values");
        setName("");
        setImportance("");
        setActive("true");
        setId(0);
        setIsEdit(false);
    }

    const validateSkill = async () => {
        // check for duplicate skill by name
        const listSkills = ` query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
    ) {
      listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          active
        }
        nextToken
      }
    }
  `;

        const filter = {
            and: [
                { companyId: { eq: getUserFromCache().company.id } },
                { deleted: { ne: true } }
            ]
        };

        const skills = await execRead({
            opname: "listSkills",
            op: listSkills,
            filter: filter,
            limit: limit
        });

        const duplicate = skills.items.filter(
            (s) =>
                s.name.trim().toUpperCase() === name.trim().toUpperCase() &&
                s.id !== id
        )[0];

        if (duplicate) {
            console.log("duplicate: ", duplicate);
            return { isDuplicate: true, active: duplicate.active };
        } else return { isDuplicate: false };
    };

    async function saveSkillData(SkillIds, skillIds) {
        const authuser = getUserFromCache();
        async function saveGraphQLSkill() {
            console.log("value of active: " + active);
            let input = {
                name: name,
                importance: importance,
                active: active,
                companyId: getUserFromCache().company.id
            };
            console.log("SkillInput:", input);
            if (isEdit) {
                input.id = id;
                const updatedSkill = await graphql(
                    graphqlOperation(mutations.updateSkill, { input })
                );

                await auditSkillsUpdate(
                    authuser,
                    updatedSkill.data.updateSkill,
                    newInfo
                );
            } else {
                const newSkill = await graphql(
                    graphqlOperation(mutations.createSkill, { input })
                );

                await auditSkillsCreate(authuser, newSkill.data.createSkill);
            }
        }

        try {
            const foundSkill = await validateSkill();
            if (foundSkill && foundSkill.isDuplicate) {
                if (foundSkill.active)
                    setSnackMsg(
                        `An active skill with the name "${name}" already exists.`
                    );
                else
                    setSnackMsg(
                        `An inactive skill with the name "${name}" already exists.`
                    );

                setMsgOpen(true);
                setDisableSave(false);
            } else {
                await saveGraphQLSkill();
                setSnackMsg("Skill successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    reset();
                    actions.setPage("SkillsForm");
                }, 2000);
            }
        } catch (err) {
            console.error(`Error saving skill data`, err);
            setDisableSave(false);
        }
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    async function getSkillData() {
        try {
            const skillId = state.id;
            //setEditMode(true);
            const input = { id: skillId };
            // get user data from cache
            // const authuser = Cache.getItem('user');
            // setLoggedInUser(authuser);

            const result = await graphql(
                graphqlOperation(queries.getSkill, input)
            );

            const skillData = result.data.getSkill;
            console.log("getSkillType", result);
            if (!(!!result.errors && result.errors.length > 0)) {
                const { id, name, active, importance } = result.data.getSkill;
                console.log("result", result.data.getSkill);
                setId(id);
                setName(name);
                setImportance(importance);
                setActive(active);

                oldInfo.push({
                    name: name,
                    active: active
                });
            }
        } catch (err) {
            console.error(`Error fetching skill type `, err);
        }
    }
    // load provider data if in edit mode
    useEffect(() => {
        const authuser = getUserFromCache();
        console.log("in useEffect:" + state.mode);
        setIsEdit(state.mode === "Edit");
        setIsView(state.mode === "View");
        if (state.mode === "Edit" || state.mode === "View") {
            getSkillData();
        }
    }, []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography className={classes.title} variant="h4" noWrap>
                Skill \ {state.mode}
            </Typography>
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Name"
                            id="skill-name"
                            value={name}
                            className={clsx(
                                componentClasses.longTextField,
                                componentClasses.margin
                            )}
                            margin="dense"
                            onChange={handleChangeName}
                        />
                    </Grid>
                </Grid>
                {/*<Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            label="Importance"
                            id="skill-importance"
                            type="number"
                            value={importance}
                            className={clsx(
                                componentClasses.margin,
                                componentClasses.textField
                            )}
                            margin="dense"
                            onChange={handleChangeImportance}
                        />
                    </Grid>
                            </Grid>*/}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={active}
                                onChange={(event) =>
                                    setActive(event.target.checked)
                                }
                                value="active"
                                color="primary"
                                inputProps={{
                                    "aria-label": "primary checkbox"
                                }}
                            />
                        }
                        className={clsx(
                            componentClasses.margin,
                            componentClasses.textField
                        )}
                        label="Active"
                    />
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={10}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={componentClasses.button}
                                onClick={handleSaveSkillType}
                                disabled={isView || disableSave}
                            >
                                Save
                                {disableSave && (
                                    <CircularProgress
                                        size={24}
                                        className={classesnew.buttonProgress}
                                    />
                                )}
                            </Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={componentClasses.button}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
