import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, Snackbar, IconButton, Tooltip, Button, TableContainer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import EditIcon from "@mui/icons-material/Create";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

const tableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        backgroundColor: "white",
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};
/* const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    },
    pointer: {
        cursor: "pointer"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    width200: {
        minWidth: "200px"
    },
    width150: {
        minWidth: "150px"
    }
}));
 */
function TablePaginationActions(props) {
    // const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};
export default function UsersTable({
    listUsersData,
    fetchUsers,
    selectedRow,
    tabValue,
    allow,
    managePassword,
    page,
    setPage
}) {
    const { state, actions } = useContext(StoreContext);
    //const classes = useStyles();
    // const [order, setOrder] = useState('asc');
    const [promotionId, setPromotionId] = useState(0);
    // const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    // const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        getTableRowsSession("usersTable")
    );
    const [rows, setRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [deleteDialog, setDeleteDialog] = useState(false);

    const [endDialog, setEndDialog] = useState(false);
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [active, setActive] = useState();
    // function handleRequestSort(event, property) {
    //     const isDesc = orderBy === property && order === 'desc';
    //     setOrder(isDesc ? 'asc' : 'desc');
    //     setOrderBy(property);
    // }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = listUsersData.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }
    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("usersTable")) {
            setTableRowsSession("usersTable", event.target.value);
        }
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    function handleDeleteProviders(id, event) {
        setPromotionId(id);
        setDeleteDialog(true);
    }

    function handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    function handleEditClick(event, rowId) {
        actions.setMode("Edit");
        actions.setPage("UsersEditViewForm");
        actions.setId(rowId);
    }

    function handleViewClick(event, rowId) {
        actions.setMode("View");
        actions.setPage("UsersEditViewForm");
        actions.setId(rowId);
    }

    const deleteUser = async (id) => {
        const input = {
            id: id
        };
        try {
            let deleteUser = await graphql(
                graphqlOperation(mutations.deleteUser, { input })
            );
            if (!!deleteUser.error && typeof deleteUser.error === "object") {
                setSnackMsg("Something went wrong, while deleting the user.");
                setMsgOpen(true);
            } else {
                fetchUsers();
                setEndDialog(false);
                setDeleteDialog(false);
                actions.setPage("PromotionForm");
            }
        } catch (err) {
            setSnackMsg("Something went wrong, while deleting the user.");
            setMsgOpen(true);
        }
    };

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const _handleUpdateStatus = async (e, id) => {
        setActive(e.target.checked);
        const input = {
            active: e.target.checked,
            id: id
        };
        let res = await graphql(
            graphqlOperation(mutations.updateUser, { input })
        );
        if (!!res && !!res.data) {
            fetchUsers();
            selectedRow([]);
        }
    };
    // const listUsersData = props
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={deleteDialog}
                //onClose={handleDeleteDialogDecline}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you wish to delete the selected users?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete the selected users. They will no
                        longer be available on the system and all their bookings
                        will be cancelled.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogDecline} color="primary">
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        autoFocus
                        onClick={() => deleteUser(promotionId)}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <TableContainer>
                    <Table sx={tableTheme}>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectAll}
                                        onClick={handleSelectAllClick}
                                    />
                                </TableCell> */}

                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">
                                    Email Address
                                </TableCell>
                                <TableCell align="left">Address</TableCell>
                                <TableCell align="left">Registered</TableCell>
                                <TableCell align="left">Status</TableCell>

                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listUsersData &&
                                listUsersData.length > 0 &&
                                listUsersData
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((item, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                hover
                                                onClick={(event) =>
                                                    handleRowClick(
                                                        event,
                                                        item.id
                                                    )
                                                }
                                            >
                                                {/*<TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={
                                                            selected.indexOf(
                                                                item.id
                                                            ) !== -1 ||
                                                            selectAll
                                                        }
                                                    />
                                                </TableCell> */}
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="left"
                                                >
                                                    {item.firstname}&nbsp;
                                                    {item.lastname}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.emailaddress}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.addressoneline}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.registered === true
                                                        ? "Yes"
                                                        : "No"}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.status
                                                        ? `${item.status.charAt(
                                                              0
                                                          )}${item.status
                                                              .substr(1)
                                                              .toLowerCase()}`
                                                        : "N/A"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Edit">
                                                        <EditIcon
                                                            onClick={(event) =>
                                                                handleEditClick(
                                                                    event,
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                    {item.registered !==
                                                    false ? (
                                                        <Tooltip title="Manage Password">
                                                            <VpnKeyIcon
                                                                onClick={async () =>
                                                                    await managePassword(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        ""
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                        <TableFooter>
                            <TableRow></TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "center"
                    }}
                >
                    <TablePagination
                        sx={{
                            marginLeft: "auto",
                            "& .MuiToolbar-root": {
                                alignItems: "center",
                                "& .MuiInputBase-root": {
                                    fontWeight: 700
                                },
                                "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                    {
                                        color: "rgba(0,0,0,0.5)",
                                        marginBottom: 0
                                    },
                                "& .MuiTablePagination-actions": {
                                    display: "flex",
                                    gap: "0.5rem",
                                    "& button": {
                                        border: "1px solid rgba(0,0,0,0.2)",
                                        borderRadius: "4px"
                                    },

                                    "& button:hover": {
                                        backgroundColor: "primary.light"
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component={"div"}
                        count={listUsersData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    );
}
