import React, { useEffect, useState } from "react";
import {
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Checkbox,
    ListItemText,
    Grid
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

const selectFieldStyling = {
    backgroundColor: "white",
    border: "2px solid #d4d4d4",
    borderRadius: "4px",
    "&:hover": {
        backgroundColor: "white"
    },

    "&.Mui-focused": {
        backgroundColor: "white",
        "& .MuiSelect-select": {
            backgroundColor: "white"
        }
    }
};

const LocationsDropdown = ({
    fromDashboard,
    inputLabelRef,
    initialLocations,
    companyLocationData,
    setLocations
}) => {
    const [selectedLocations, setSelectedLocations] = useState(
        initialLocations ? initialLocations : []
    );

    useEffect(() => {
        setSelectedLocations(initialLocations ? initialLocations : []);
    }, [initialLocations]);

    /**
     * Utility function to handle 'locations' change
     */
    const handleSelectLocationChange = (event) => {
        //locationsList has all locations that are currently selected, plus the selected (clicked on) location as the last item
        const locationsList = event.target.value;
        const selectedLocation = locationsList[locationsList.length - 1];

        setSelectedLocations((prevSelectedLocations) => {
            //First, we check if the selected location has already been selected
            const isSelected = prevSelectedLocations.some(
                (location) => location.id === selectedLocation.id
            );

            if (isSelected) {
                //if it has already been selected, remove the location
                if (prevSelectedLocations.length === 1) {
                    //This check is made to ensure the user cannot leave the 'locations' dropdown empty
                    return [...prevSelectedLocations];
                }
                if (setLocations) {
                    setLocations(
                        prevSelectedLocations.filter(
                            (location) => location.id !== selectedLocation.id
                        )
                    );
                }
                return prevSelectedLocations.filter(
                    (location) => location.id !== selectedLocation.id
                );
            } else {
                //if it has not been not selected, add the location
                const locationToAdd = companyLocationData.find(
                    (location) => location.id === selectedLocation.id
                );
                if (setLocations) {
                    setLocations([...prevSelectedLocations, locationToAdd]);
                }
                return [...prevSelectedLocations, locationToAdd];
            }
        });
    };

    return (
        <Grid
            container
            item
            xs={fromDashboard() ? 12 : 5}
            sx={{ marginTop: "1rem" }}
        >
            <FormControl variant="filled" fullWidth>
                <InputLabel ref={inputLabelRef} id="location-service">
                    Locations
                </InputLabel>
                <Select
                    name="location"
                    id="locations-service"
                    label="Locations"
                    variant="filled"
                    fullWidth
                    multiple
                    disableUnderline
                    IconComponent={ArrowDropDown}
                    value={selectedLocations}
                    onChange={(e) => handleSelectLocationChange(e)}
                    sx={{
                        ...selectFieldStyling,
                        "& .MuiSelect-icon": {
                            color: "disabled.main"
                        }
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300
                            }
                        }
                    }}
                    renderValue={(selected) =>
                        selected.length === companyLocationData.length
                            ? "All locations"
                            : selected
                                  .map((item) => item.locationname)
                                  .join(", ")
                    }
                >
                    {companyLocationData &&
                        companyLocationData.length > 0 &&
                        companyLocationData.map((item, i) => {
                            return (
                                <MenuItem
                                    value={{
                                        id: item.id,
                                        locationname: item.locationname
                                    }}
                                    key={item.id}
                                >
                                    <Checkbox
                                        checked={selectedLocations.some(
                                            (location) =>
                                                location.id === item.id
                                        )}
                                    />
                                    <ListItemText primary={item.locationname} />
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </Grid>
    );
};

export default LocationsDropdown;
