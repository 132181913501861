// ************************************
// Common billing routines
// ************************************
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    postApi,
    putApi,
    getJsonApi
} from "../modules/AmplifyServices";

async function chargeCreditCard(
    stripecustomerid,
    amount,
    customerCurrency,
    receiptemailaddress,
    credit,
    prevChargeId,
    companyId,
    stripeAccount,
    stripeConnectEnabled
) {
    const logger = new ConsoleLogger("chargeCreditCard");
    const result = await postApi("stripechargecard", "/stripechargecard", {
        body: {
            amount,
            customerCurrency,
            stripecustomerid,
            receiptemailaddress,
            credit,
            prevcharge: prevChargeId,
            companyId,
            stripeAccount: stripeAccount,
            stripeConnectEnabled: stripeConnectEnabled
        }
    });

    logger.warn("result from stripechargecard = ");
    logger.warn(result);

    return result;
}

async function checkCardExpiration({
    stripecustomerid,
    companyId,
    clientEmailAddress,
    stripeAccount,
    stripeConnectEnabled
}) {
    const result = await postApi("stripechargecard", "/checkcardexpiration", {
        body: {
            stripecustomerid,
            clientEmailAddress,
            companyId,
            stripeAccount,
            stripeConnectEnabled
        }
    });
    return result;
}

export { chargeCreditCard, checkCardExpiration };
