import React, { useState } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { mbxUserAtom } from "../../atoms/atoms";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { getUrl } from "aws-amplify/storage";
export const UserCard = ({
    combinedName,
    email,
    phone,
    photoUrl,
    permalink
}) => {
    const [s3Link, setS3Link] = useState("");
    const [mbxUser] = useAtom(mbxUserAtom);
    useEffect(() => {
        const getS3Url = async () => {
            if (photoUrl) {
                const data = await getUrl({ key: photoUrl });
                setS3Link(data?.url);
            }
        };
        getS3Url();
    }, [photoUrl]);
    return (
        <Box
            // TODO: move styles to the theme
            sx={{
                minWidth: "350px",
                display: "flex",
                gap: "1.2rem",
                alignItems: "center"
            }}
        >
            <Avatar
                variant="rounded"
                src={s3Link}
                sx={{
                    width: 50,
                    height: 50,
                    borderRadius: "50%"
                }}
                alt="User"
            />
            <Stack>
                <Typography
                    sx={{
                        fontWeight: 500,
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                            textDecoration: permalink && "underline"
                        }
                    }}
                    variant="condensed"
                    onClick={() => {
                        let URL = mbxUser.company.subdomain;
                        if (permalink) {
                            window.open(
                                `https://${URL}/provider/${permalink}`,
                                "_blank"
                            );
                        }
                    }}
                >
                    {combinedName}
                </Typography>
                <Typography variant="condensed">{email}</Typography>
                <Typography variant="condensed">{phone}</Typography>
            </Stack>
        </Box>
    );
};
