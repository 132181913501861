// this module contains tax related functions

// function to determine tax rate
// hardcoded for now
const getServiceTaxRate = (countryCode, provstateCode) => {
    const countryCodeUpper = countryCode.toUpperCase();
    const provstateCodeUpper = provstateCode.toUpperCase();

    // zero for Austrailia and New Zealand
    if (countryCodeUpper === "AU" || countryCodeUpper === "NZ") {
        return 0;
    }

    if (countryCodeUpper === "GB") {
        return 0;
    }
    //Don't know what it is
    if (countryCodeUpper === "JM") {
        return 0;
    }
    //Don't know what it is
    if (countryCodeUpper === "BB") {
        return 0;
    }

    // zero for USA
    if (countryCodeUpper === "US") {
        return 0;
    }

    // Canada, determine HST based on province
    if (countryCodeUpper === "CA") {
        if (provstateCodeUpper === "AB" || provstateCodeUpper === "NU") {
            return 5;
        }
        if (provstateCodeUpper === "BC" || provstateCodeUpper === "MB") {
            return 12;
        }
        if (
            provstateCodeUpper === "NB" ||
            provstateCodeUpper === "NL" ||
            provstateCodeUpper === "NS" ||
            provstateCodeUpper === "NT" ||
            provstateCodeUpper === "PE"
        ) {
            return 15;
        }
        if (provstateCodeUpper === "ON") {
            return 13;
        }
        if (provstateCodeUpper === "QC") {
            return 14.975;
        }
        if (provstateCodeUpper === "SK") {
            return 11;
        }
    }

    // if not found, return 0 by default
    return 0;
};

const getCountryCode = (countryName) => {
    countryName = countryName.toUpperCase();
    if (countryName === "UNITED STATES") return "US";
    if (countryName === "CANADA") return "CA";

    if (countryName === "JAMAICA") return "JM";
    if (countryName === "BARBADOS") return "BB";

    if (countryName === "AUSTRALIA") return "AU";

    if (countryName === "NEW ZEALAND") return "NZ";

    return "CA";
};

export { getCountryCode, getServiceTaxRate };
