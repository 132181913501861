import React, { useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Box, Button, Paper, Grid, FormControl, Typography, Snackbar, Switch, FormControlLabel, InputLabel, Select, MenuItem } from "@mui/material";
// prettier-ignore
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { getUserFromCache } from "../user/UserCommon";
// prettier-ignore
//mport { MuiPickersUtilsProvider, DatePicker, TimePicker, DateTimePicker } from "material-ui-pickers"; //commented as this file is not used
import moment from "moment";

export default function CompanyReminderAddEditForm(props) {
    const { state, dispatch, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    //fields
    const [reminderDays, setReminderDays] = useState(1);
    const [reminderHours, setReminderHours] = useState(0);
    const [reminderMinutes, setReminderMinutes] = useState(0);
    const [emailSubjectText, setEmailSubjectText] = useState("");
    const [emailHtmlFile, setEmailHtmlFile] = useState("");

    const [smsText, setSmsText] = useState("");
    const [smsCallerId, setSmsCallerId] = useState("");
    const [priority, setPriority] = useState(1);
    const [maxSendTime, setMaxSendTime] = useState(
        new Date("2019-01-01T22:00:00")
    );
    const [isEdit, setisEdit] = useState(false);
    const [active, setActive] = useState(true);
    const [reminderType, setReminderType] = useState("SMS");
    const [recipientType, setRecipientType] = useState("CLIENT");

    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    const logger = new ConsoleLogger("CompanyReminderAddEditForm");
    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    const companyId = loggedInUser.company.id;
    useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth);
        if (state.mode === "Edit" || state.mode === "View") {
            getCompanyReminderData();
        }
    }, []);

    async function getCompanyReminderData() {
        const companyId = state.id;
        if (!!companyId) {
            setisEdit(true);
            const input = { id: companyId };
            const result = await graphql(
                graphqlOperation(queries.getCompanyReminder, input)
            );
            if (!(!!result.errors && result.errors.length > 0)) {
                const {
                    reminderType,
                    recipientType,
                    reminderDays,
                    reminderHours,
                    reminderMinutes,
                    emailSubjectText,
                    emailHTMLFile,
                    smsText,
                    smsCallerId,
                    maxSendTime,
                    priority,
                    active
                } = result.data.getCompanyReminder;
                // console.log("result", result.data.getCompa1);
                setReminderDays(reminderDays);
                setReminderHours(reminderHours);
                setReminderMinutes(reminderMinutes);
                setReminderType(reminderType);
                setRecipientType(recipientType);
                setEmailSubjectText(emailSubjectText);
                setEmailHtmlFile(emailHTMLFile);
                setSmsCallerId(smsCallerId);
                setSmsText(smsText);
                setMaxSendTime(
                    new Date(
                        moment().format("YYYY"),
                        moment().format("MM"),
                        moment().format("DD"),
                        maxSendTime.split(":")[0],
                        maxSendTime.split(":")[1]
                    )
                );
                setPriority(priority);
                setActive(active);
            }
        }
    }

    // cancel and go back to PromotionForm
    function handleCancelCompanyForm() {
        actions.setPage("CompanyReminderForm");
    }

    async function addCompanyReminder() {
        try {
            let input = {
                reminderType: reminderType,
                reminderMinutes: reminderMinutes,
                reminderHours: reminderHours,
                reminderDays: reminderDays,
                maxSendTime: moment(maxSendTime).format("HH:mm"),
                priority: priority,
                active: active,
                recipientType: recipientType,
                companyId: companyId,
                companyReminderCompanyId: companyId
            };

            if (reminderType === "SMS") {
                input = {
                    ...input,
                    smsText: smsText,
                    smsCallerId: smsCallerId
                };
            }

            if (reminderType === "EMAIL") {
                input = {
                    ...input,
                    emailSubjectText: emailSubjectText,
                    emailHTMLFile: emailHtmlFile
                };
            }

            let response;
            if (isEdit) {
                input.id = state.id;
                logger.debug(
                    "updateCompanyReminder. input = " + JSON.stringify(input)
                );

                response = await graphql(
                    graphqlOperation(mutations.updateCompanyReminder, { input })
                );
            } else {
                logger.debug(
                    "createCompanyReminder. input = " + JSON.stringify(input)
                );
                response = await graphql(
                    graphqlOperation(mutations.createCompanyReminder, { input })
                );
            }

            if (
                response && response.data && isEdit
                    ? response.data.updateCompanyReminder
                    : response.data.createCompanyReminder
            ) {
                // let companyId;
                // let locRes;
                // if (isEdit) {
                //   companyId = response.data.updateCompany.id;
                // } else {
                //   companyId = response.data.createCompany;
                // }

                setSnackMsg("Company successfully saved.");
                setMsgOpen(true);
                reset();
                actions.setPage("CompanyReminderForm");
            } else {
                setSnackMsg("Unable to save the company reminder information.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error("Add Company error => ", error);
        }
    }

    async function handleSaveCompanyReminder() {
        // validate
        if (reminderType === "") {
            setSnackMsg("Please enter a value for reminder type.");
            setMsgOpen(true);
            return;
        }
        // if (reminderMinutes > 0) {
        //   setSnackMsg("Please enter a value for reminder minutes.");
        //   setMsgOpen(true);
        //   return;
        // }
        // if (reminderHours > 0) {
        //   setSnackMsg("Please enter a value for reminder hours.");
        //   setMsgOpen(true);
        //   return;
        // }
        // if (reminderDays > 0) {
        //   setSnackMsg("Please enter a value for reminder days.");
        //   setMsgOpen(true);
        //   return;
        // }
        if (reminderType === "EMAIL" && emailSubjectText === "") {
            setSnackMsg("Please enter email subject text");
            setMsgOpen(true);
            return;
        }
        if (reminderType === "EMAIL" && emailHtmlFile === "") {
            setSnackMsg("Please enter a value for email html.");
            setMsgOpen(true);
            return;
        }
        if (reminderType === "SMS" && smsText === "") {
            setSnackMsg("Please enter a value for sms text");
            setMsgOpen(true);
            return;
        }
        // this is optional
        //if (smsCallerId === "") {
        //  setSnackMsg("Please enter a value for sms caller id.");
        //  setMsgOpen(true);
        //  return;
        //}
        if (!!reminderType && !!recipientType) {
            addCompanyReminder();
        }
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    function reset() {
        // reset fi1ds
        setReminderHours("");
        setEmailHtmlFile("");
        setPriority("");
        setMaxSendTime("");
        setReminderType();
    }

    const checkNegetiveValue = (value) => {
        if (value >= 0) {
            return true;
        } else {
            return false;
        }
    };
    // handle name ch1ge
    const handleReminderDaysChange = (e) => {
        if (checkNegetiveValue(e.target.value)) {
            setReminderDays(e.target.value);
        }
    };
    const handleChangeReminderHours = (e) => {
        if (checkNegetiveValue(e.target.value)) {
            setReminderHours(e.target.value);
        }
    };

    const handleChangeReminderMinutes = (e) => {
        if (checkNegetiveValue(e.target.value)) {
            setReminderMinutes(e.target.value);
        }
    };

    function DisplayEmailFields() {
        if (reminderType === "EMAIL") {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <TextField
                                    id="emailSubjectText"
                                    multiline
                                    maxRows="2"
                                    label="Email Subject Text"
                                    className={classes.textField}
                                    value={emailSubjectText}
                                    //inputProps={{ tabIndex: "1" }}
                                    onChange={(e) =>
                                        setEmailSubjectText(e.target.value)
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <TextField
                                    id="emailHtmlFile"
                                    label="Email HTML File"
                                    className={classes.textField}
                                    value={emailHtmlFile}
                                    //inputProps={{ tabIndex: "1" }}
                                    onChange={(e) =>
                                        setEmailHtmlFile(e.target.value)
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            );
        }
    }

    function DisplaySMSFields() {
        if (reminderType === "SMS") {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <TextField
                                    id="smsText"
                                    multiline
                                    maxRows="4"
                                    label="SMS Text"
                                    className={classes.textField}
                                    value={smsText}
                                    //inputProps={{ tabIndex: "2" }}
                                    onChange={(e) => setSmsText(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <TextField
                                    id="smsCallerId"
                                    label="SMS Caller Id"
                                    className={classes.textField}
                                    value={smsCallerId}
                                    //inputProps={{ tabIndex: "2" }}
                                    onChange={(e) =>
                                        setSmsCallerId(e.target.value)
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            );
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography className={classes.title} variant="h4" noWrap>
                Reminder \ {state.mode}
            </Typography>

            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <FormControl style={{ minWidth: 120 }}>
                            <InputLabel htmlFor="reminderType">
                                Reminder Type
                            </InputLabel>
                            <Select
                                onChange={(e) =>
                                    setReminderType(e.target.value)
                                }
                                value={reminderType}
                                inputProps={{
                                    name: "type",
                                    id: "reminderType"
                                }}
                                className={classes.fullwidth}
                            >
                                <MenuItem value={"SMS"}>SMS</MenuItem>
                                <MenuItem value={"EMAIL"}>EMAIL</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl style={{ minWidth: 120 }}>
                            <InputLabel htmlFor="reminderType">
                                Recipient Type
                            </InputLabel>
                            <Select
                                onChange={(e) =>
                                    setRecipientType(e.target.value)
                                }
                                value={recipientType}
                                inputProps={{
                                    name: "type",
                                    id: "recipientType"
                                }}
                                className={classes.fullwidth}
                            >
                                <MenuItem value={"CLIENT"}>CLIENT</MenuItem>
                                <MenuItem value={"PROVIDER"}>PROVIDER</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography component="p">
                            How long before an appointment should the reminder
                            be sent?
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="reminderDays"
                                label="Days"
                                className={classes.textField}
                                value={reminderDays}
                                type="number"
                                // inputProps={{ step: "1", min: "0" }}
                                onChange={handleReminderDaysChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="reminderHours"
                                label="Hours"
                                className={classes.textField}
                                value={reminderHours}
                                type="number"
                                // inputProps={{ step: "1", min: "0" }}
                                onChange={handleChangeReminderHours}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="reminderMinutes"
                                label="Minutes"
                                className={classes.textField}
                                value={reminderMinutes}
                                type="number"
                                // inputProps={{ step: "1", min: "0" }}
                                onChange={handleChangeReminderMinutes}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {DisplayEmailFields()}
                {DisplaySMSFields()}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="pickers">
                                <TimePicker
                                    //autoOk
                                    label="Max Send Time"
                                    //format="hh:MM"
                                    minDateMessage={
                                        "Please select a max send datetime."
                                    }
                                    value={maxSendTime}
                                    onChange={(e) => setMaxSendTime(e)}
                                />
                            </div>
                        </MuiPickersUtilsProvider> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="priority"
                                label="Priority (1-100)"
                                className={classes.textField}
                                value={priority}
                                type="number"
                                inputProps={{ min: "1", max: "100", step: "1" }}
                                onChange={(e) => setPriority(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControlLabel
                            margin="normal"
                            className={classesnew.marginBottom}
                            control={
                                <Switch
                                    checked={active}
                                    onChange={(event) =>
                                        setActive(event.target.checked)
                                    }
                                    value="active"
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={5}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveCompanyReminder}
                                disabled={state.mode === "View"}
                            >
                                Save
                            </Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelCompanyForm}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
