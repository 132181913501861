export const searchByLastName = /* GraphQL */ `
    query SearchByLastName(
        $companyId: String
        $lc_ln: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        searchByLastName(
            companyId: $companyId
            lc_ln: $lc_ln
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                username
                emailaddress
                registered
                firstname
                lastname
                homephone
                workphone
                mobilephone
                phonepref
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                role
                companyId
                providerId
                active
                deleted
                contactconsent
                contactconsentdatetime
                LoginInfo
                SalesInfo
                PNInfo
                lc_name
                lc_emailaddress
                lc_ln
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const searchByName = /* GraphQL */ `
    query SearchByName(
        $companyId: String
        $lc_name: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        searchByName(
            companyId: $companyId
            lc_name: $lc_name
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                username
                emailaddress
                registered
                firstname
                lastname
                homephone
                workphone
                mobilephone
                phonepref
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                role
                companyId
                providerId
                active
                deleted
                contactconsent
                contactconsentdatetime
                LoginInfo
                SalesInfo
                PNInfo
                lc_name
                lc_emailaddress
                lc_ln
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const userByCompany = /* GraphQL */ `
    query UserByCompany(
        $companyId: String
        $roleEmailaddress: ModelUserByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userByCompany(
            companyId: $companyId
            roleEmailaddress: $roleEmailaddress
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                username
                emailaddress
                registered
                firstname
                lastname
                homephone
                workphone
                mobilephone
                phonepref
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                role
                companyId
                providerId
                active
                deleted
                contactconsent
                contactconsentdatetime
                LoginInfo
                SalesInfo
                PNInfo
                lc_name
                lc_emailaddress
                lc_ln
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const usersForExport = /* GraphQL */ `
    query UserByCompany(
        $companyId: String
        $roleEmailaddress: ModelUserByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userByCompany(
            companyId: $companyId
            roleEmailaddress: $roleEmailaddress
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                emailaddress
                registered
                firstname
                lastname
                homephone
                workphone
                mobilephone
                phonepref
                addressoneline
                street
                city
                state
                country
                postalcode
                role
                active
                deleted
                LoginInfo
                createdAt
                contactconsent
            }
            nextToken
        }
    }
`;
export const usersForTable = /* GraphQL */ `
    query UserByCompany(
        $companyId: String
        $roleEmailaddress: ModelUserByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userByCompany(
            companyId: $companyId
            roleEmailaddress: $roleEmailaddress
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                emailaddress
                registered
                firstname
                lastname
                homephone
                workphone
                mobilephone
                phonepref
                role
                active
                LoginInfo
                createdAt
                contactconsent
                deleted
                providerId
                provider {
                    id
                    permalink
                    pictures3key
                    timezone
                    bio
                    vmlink
                    maxTravelTime
                    offersVirtualServices
                    offersMobileServices
                    skills {
                        items {
                            id
                            skill {
                                id
                                name
                            }
                        }
                    }
                    servicetypes {
                        items {
                            id
                            servicetype {
                                id
                                name
                            }
                        }
                    }
                    locations {
                        items {
                            id
                            name
                            desc
                            addressoneline
                            street
                            city
                            state
                            country
                            postalcode
                            longitude
                            latitude
                            timezone
                            maxtraveltype
                            traveldistance
                            traveldistanceunit
                            travelregions
                            active
                            deleted
                        }
                    }
                }
                company {
                    travelTime
                    id
                }
            }
            nextToken
        }
    }
`;

export const getMbxUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            emailaddress
            registered
            firstname
            lastname
            homephone
            workphone
            mobilephone
            phonepref
            addressoneline
            street
            city
            state
            country
            postalcode
            longitude
            latitude
            role
            companyId
            providerId
            active
            deleted
            contactconsent
            contactconsentdatetime
            LoginInfo
            SalesInfo
            PNInfo
            lc_name
            lc_emailaddress
            lc_ln
            createdAt
            updatedAt
            company {
                id
                name
                contactname
                emailaddress
                currency
                currencyBasedOnLocation
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                clientcanselectprovider
                active
                offersRemoteServices
                offersOnPremiseServices
                offersVirtualServices
                providerMustAcceptAppt
                useAnonymousPermalink
                multipleServices
                multipleQty
                ApptAcceptanceFlowConfig
                collectpayment
                subdomain
                tagline
                logoUrl
                billingMode
                iframeURL
                primaryColor
                addServiceFee
                serviceFeeType
                serviceFeeAmount
                taxrate
                travelTime
                bookingIntervalMinutes
                countrycode3166alpha2
                publishableStripeKey
                displayOnlyLogo
                sendratingTipMsg
                ratingTipTextMsg
                offersPackagesOnly
                forceRepeatingPackages
                offersForeverAppt
                singleApptOnly
                virtualMeetingConfig
                DashboardInfo
                BccLists
                maskeddomain
                replyemailaddress
                homepage
                clientnotesheading
                hideRatingsFromClients
                subscriptionLevel
                cancelPolicyMinsBefore
                bookingIncrement
                providerCanCancelAppt
                noProviderMsg
                displayProviderRatios
                PaymentSettings
                stripeConnectEnabled
                stripeAccount
                setupCompleted
                clientCanReschedule
                providerCanReschedule
                ProvAgnoFlowConfig
                SuggestionConfig
                createdAt
                updatedAt
            }
            provider {
                id
                firstname
                lc_fn
                lastname
                lc_ln
                emailaddress
                phone
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                timezone
                bio
                pictures3key
                maxtraveltype
                companyId
                numberofschedules
                offersMobileServices
                active
                deleted
                ratingstarsavg
                numberofratings
                offersVirtualServices
                virtualMeetingUserId
                vmlink
                permalink
                AcceptanceRatios
                userId
                maxTravelTime
                createdAt
                updatedAt
                user {
                    id
                    username
                    emailaddress
                    registered
                    firstname
                    lastname
                    homephone
                    workphone
                    mobilephone
                    phonepref
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    longitude
                    latitude
                    role
                    companyId
                    providerId
                    active
                    deleted
                    contactconsent
                    contactconsentdatetime
                    LoginInfo
                    SalesInfo
                    PNInfo
                    lc_name
                    lc_emailaddress
                    lc_ln
                    createdAt
                    updatedAt
                }
                company {
                    id
                    name
                    contactname
                    emailaddress
                    currency
                    currencyBasedOnLocation
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    longitude
                    latitude
                    clientcanselectprovider
                    active
                    offersRemoteServices
                    offersOnPremiseServices
                    offersVirtualServices
                    providerMustAcceptAppt
                    useAnonymousPermalink
                    multipleServices
                    multipleQty
                    ApptAcceptanceFlowConfig
                    collectpayment
                    subdomain
                    tagline
                    logoUrl
                    billingMode
                    iframeURL
                    primaryColor
                    addServiceFee
                    serviceFeeType
                    serviceFeeAmount
                    taxrate
                    travelTime
                    bookingIntervalMinutes
                    countrycode3166alpha2
                    publishableStripeKey
                    displayOnlyLogo
                    sendratingTipMsg
                    ratingTipTextMsg
                    offersPackagesOnly
                    forceRepeatingPackages
                    offersForeverAppt
                    singleApptOnly
                    virtualMeetingConfig
                    DashboardInfo
                    BccLists
                    maskeddomain
                    replyemailaddress
                    homepage
                    clientnotesheading
                    hideRatingsFromClients
                    subscriptionLevel
                    cancelPolicyMinsBefore
                    bookingIncrement
                    providerCanCancelAppt
                    noProviderMsg
                    displayProviderRatios
                    PaymentSettings
                    stripeConnectEnabled
                    stripeAccount
                    setupCompleted
                    clientCanReschedule
                    providerCanReschedule
                    ProvAgnoFlowConfig
                    SuggestionConfig
                    createdAt
                    updatedAt
                }
                skills {
                    items {
                        id
                        skill {
                            id
                            name
                        }
                    }
                }
                servicetypes {
                    items {
                        id
                        servicetype {
                            id
                            name
                        }
                    }
                }
                locations {
                    items {
                        id
                        name
                        desc
                        addressoneline
                        street
                        city
                        state
                        country
                        postalcode
                        longitude
                        latitude
                        timezone
                        maxtraveltype
                        traveldistance
                        traveldistanceunit
                        travelregions
                        active
                        deleted
                    }
                }
            }
        }
    }
`;
