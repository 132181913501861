export const schedulesByCompany = /* GraphQL */ `
    query SchedulesByCompany(
        $companyId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByCompany(
            companyId: $companyId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locationId
                servicetypeId
                active
                internal
                slotStartStep
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
            }
            nextToken
        }
    }
`;
export const schedulesByCompanyForCount = /* GraphQL */ `
    query SchedulesByCompany(
        $companyId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByCompany(
            companyId: $companyId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                scheduleinfo
                providerId
                active
            }
            nextToken
        }
    }
`;
export const timeblocksByCompanyFor = /* GraphQL */ `
    query SchedulesByCompany(
        $companyId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByCompany(
            companyId: $companyId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                endDate
                updatedAt
                active
            }
            nextToken
        }
    }
`;
export const bookedByCompany = /* GraphQL */ `
    query SchedulesByCompany(
        $companyId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByCompany(
            companyId: $companyId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
            }
            nextToken
        }
    }
`;

export const timeblockByCompany = /* GraphQL */ `
    query SchedulesByCompany(
        $companyId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByCompany(
            companyId: $companyId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
            }
            nextToken
        }
    }
`;

export const schedulesByLocation = /* GraphQL */ `
    query SchedulesByLocation(
        $locationId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByLocation(
            locationId: $locationId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locationId
                servicetypeId
                active
                internal
                slotStartStep
                instructions
                postBookingInstructions
            }
            nextToken
        }
    }
`;

export const listAllScheduleItemsByLocation = /* GraphQL */ `
    query SchedulesByLocation(
        $locationId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByLocation(
            locationId: $locationId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locationId
                servicetypeId
                active
                internal
                slotStartStep
                instructions
                postBookingInstructions
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
            }
            nextToken
        }
    }
`;

export const timeblocksByLocation = /* GraphQL */ `
    query SchedulesByLocation(
        $locationId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByLocation(
            locationId: $locationId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
            }
            nextToken
        }
    }
`;

export const listSchedules = /* GraphQL */ `
    query ListScheduleTimeblocks(
        $id: ID
        $scheduleinfo: ModelStringKeyConditionInput
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listScheduleTimeblocks(
            id: $id
            scheduleinfo: $scheduleinfo
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locationId
                servicetypeId
                active
                internal
                slotStartStep
                instructions
                postBookingInstructions
            }
            nextToken
        }
    }
`;
export const listProviderSchedules = /* GraphQL */ `
    query ListProviderSchedules(
        $id: ID
        $scheduleinfo: ModelStringKeyConditionInput
        $filter: ModelProviderScheduleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listProviderSchedules(
            id: $id
            scheduleinfo: $scheduleinfo
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locations
                services
                active
                startDate
                sdtutc
                tz
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
                deleted
                rrule
                latitude
                longitude
                notes
                createdAt
                updatedAt
                provider {
                    id
                    firstname
                    lc_fn
                    lastname
                    lc_ln
                    emailaddress
                    phone
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    longitude
                    latitude
                    timezone
                    bio
                    pictures3key
                    maxtraveltype
                    companyId
                    numberofschedules
                    offersMobileServices
                    active
                    deleted
                    ratingstarsavg
                    numberofratings
                    offersVirtualServices
                    virtualMeetingUserId
                    vmlink
                    permalink
                    AcceptanceRatios
                    userId
                    maxTravelTime
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;

export const listAllScheduleItems = /* GraphQL */ `
    query ListScheduleTimeblocks(
        $id: ID
        $scheduleinfo: ModelStringKeyConditionInput
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listScheduleTimeblocks(
            id: $id
            scheduleinfo: $scheduleinfo
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locationId
                servicetypeId
                active
                internal
                slotStartStep
                instructions
                postBookingInstructions
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
                sdtutc
                tz
            }
            nextToken
        }
    }
`;

export const listTimeblocks = /* GraphQL */ `
    query ListScheduleTimeblocks(
        $id: ID
        $scheduleinfo: ModelStringKeyConditionInput
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listScheduleTimeblocks(
            id: $id
            scheduleinfo: $scheduleinfo
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                scheduleinfo
                startDate
                endDate
                startTime
                endTime
                type
                status
                weeksToRepeat
                weekDays
                active
                sdtutc
                tz
            }
            nextToken
        }
    }
`;

export const providerServiceTypeQuery = /* GraphQL */ `
    query GetProvider($id: ID!) {
        getProvider(id: $id) {
            servicetypes {
                items {
                    servicetype {
                        id
                        name
                    }
                }
                nextToken
            }
        }
    }
`;

export const providerDataForScheduleQuery = /* GraphQL */ `
    query GetProvider($id: ID!) {
        getProvider(id: $id) {
            servicetypes {
                items {
                    servicetype {
                        id
                        name
                        deleted
                        locations
                    }
                }
                nextToken
            }
            locations {
                items {
                    id
                    name
                    maxtraveltype
                    traveldistance
                    traveldistanceunit
                    deleted
                }
                nextToken
            }
        }
    }
`;

export const listCompanyLocations = /* GraphQL */ `
    query ListCompanyLocations(
        $filter: ModelCompanyLocationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCompanyLocations(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                companyId
                locationname
                locationdesc
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                timezone
                phone
                virtual
                active
                deleted
            }
            nextToken
        }
    }
`;
export const schedulesByCompanyCondensed = /* GraphQL */ `
    query SchedulesByCompany(
        $companyId: String
        $scheduleinfo: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        schedulesByCompany(
            companyId: $companyId
            scheduleinfo: $scheduleinfo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                active
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    active
                }
            }
            nextToken
        }
    }
`;
export const schedulesByCompanyForTable = /* GraphQL */ `
    query ListScheduleTimeblocks(
        $id: ID
        $sortDirection: ModelSortDirection
        $filter: ModelScheduleTimeblockFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listScheduleTimeblocks(
            id: $id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                scheduleinfo
                companyId
                providerId
                locationId
                servicetypeId
                active
                internal
                slotStartStep
                startDate
                endDate
                startTime
                endTime
                type
                status
                tz
                weeksToRepeat
                weekDays
                sdtutc
                provider {
                    id
                }
            }
            nextToken
        }
    }
`;
