import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { Paper, Grid, Button, Snackbar, Typography } from "@mui/material";
import * as mutations from "../graphql/mutations";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { TimeBlockTable } from "../components/TimeBlockTable";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { _getTimeblocks, SCHEDPKSKSPLITAT } from "../modules/ScheduleService";
import { getUserFromCache } from "../user/UserCommon";

export const TimeBlockForm = (props) => {
    const { actions, state } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    //Selected rows
    const [selected, setSelected] = useState([]);

    //Search Values
    const [values, setValues] = useState({
        type: "",
        name: ""
    });

    let filter = {};

    useEffect(() => {
        _getTimeBlockList();
    }, []);

    // Get
    const _getTimeBlockList = async (filter) => {
        const loggedInUser = getUserFromCache();
        const companyId = loggedInUser.companyId;

        console.log("Getting timeblocks for display");
        let TimeBlockList = await _getTimeblocks({
            companyId,
            providerId: state.provider.id,
            scheduleId: state.provider.scheduleId,
            type: "AVAILABLE"
        });
        console.log("TimeBlockList:", TimeBlockList);
        if (TimeBlockList) {
            if (TimeBlockList.length > 0) {
                let arr = TimeBlockList.filter((item) => {
                    item.timeRange = `${withAMPM(item.startTime)} - ${withAMPM(
                        item.endTime
                    )}`;
                    let repeat = "Every ";
                    const weekDaysArr =
                        !!item.weekDays && item.weekDays.split("");
                    const weeks = [
                        "Sun",
                        "Mon",
                        "Tue",
                        "Wed",
                        "Thu",
                        "Fri",
                        "Sat"
                    ];

                    // sort array before displaying
                    weekDaysArr.sort();

                    for (const we of weekDaysArr) {
                        repeat = repeat + weeks[we] + " ";
                    }
                    item.repeat = repeat;
                    return item;
                });
                console.log("TimeBlockList", arr);
            }
            setRows(TimeBlockList);
        }
    };

    function withAMPM(t) {
        if (!t) return "";
        const hm = t.split(":");
        let hh = Number.parseInt(hm[0]);
        let hr = hh < 13 ? hh : hh - 12;
        let hrstr = hr < 10 ? `0${hr}` : `${hr}`;
        let ampm = hh < 12 ? "AM" : "PM";
        return `${hrstr}:${hm[1]} ${ampm}`;
    }
    const _handleTimeBlockAdd = () => {
        actions.setMode("Add");
        actions.setPage("TimeBlockProviders");
    };

    const _handleTimeBlockCancel = () => {
        actions.setId(state.provider.id);
        actions.setMode("Edit");
        actions.setPage("ProviderSchedule");
    };

    const _handleDeleteDialogDecline = () => {
        setDeleteDialog(false);
    };

    const deleteTimeBlock = async (id) => {
        const pksk = id.split(SCHEDPKSKSPLITAT);
        const input = {
            id: pksk[0],
            scheduleinfo: pksk[1]
        };
        console.log("in delete time block");
        const deleteTimeData = await graphql(
            graphqlOperation(mutations.deleteScheduleTimeblock, { input })
        );
        if (deleteTimeData.errors && deleteTimeData.errors.length > 0) {
            setSnackMsg("Unable to delete the time block.");
            setMsgOpen(true);
        } else {
            _getTimeBlockList();
            setDeleteDialog(false);
            setSelected([]);
            actions.setPage("TimeBlockForm");
        }
    };

    // const deleteTimeBlock = async id => {
    //   const input = {
    //     id: id
    //   };
    //   console.log("in delete region");
    //   const deleteTimeData = await graphql(
    //     graphqlOperation(mutations.deleteTimeblock, { input })
    //   );
    //   if (deleteTimeData.errors && deleteTimeData.errors.length > 0) {
    //     setSnackMsg("Something went wrong, while deleting TimeBlock.");
    //     setMsgOpen(true);
    //   } else {
    //     _getTimeBlockList();
    //     setDeleteDialog(false);
    //     setSelected([]);
    //     actions.setPage("TimeBlockForm");
    //   }
    // };

    const _handleDeleteDialogAccept = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteTimeBlock(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    EnhancedToolbar: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <FormHeading
                title={`Available times for ${state.provider.name}`}
                classes={classes}
            />
            <DeleteDialog
                title="Time block"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <Paper rounded="true" className={classes.root}>
                <Typography className={classes.title} variant="h6" noWrap>
                    Availability of {state.provider.name}
                    {state.provider.locationName
                        ? " at " + state.provider.locationName
                        : ""}
                </Typography>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleTimeBlockAdd}
                        >
                            {/* <AddIcon className={classes.leftIcon} /> */}
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <TimeBlockTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                rows={rows}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleTimeBlockCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
