import React, { useEffect, useState } from "react";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Paper, Grid, TextField, InputAdornment } from "@mui/material";
import { AdditionalChargesTable } from "../components/AdditionalChargesTable";
import * as queries from "../graphql/queries";
import SearchIcon from "@mui/icons-material/Search";
import { userHasAdminRole } from "../user/UserCommon";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";

const dtFormat = new Intl.DateTimeFormat("en", {
    timeStyle: "long",
    dateStyle: "long"
});

function AdditionalChargesForm(props) {
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [addCharges, setAddCharges] = useState([]);
    useEffect(() => {
        const loggedInUser = getUserFromCache();
        if (userHasAdminRole()) getAddChargesList();
    }, []);

    // Get
    const getAddChargesList = async () => {
        const user = getUserFromCache();
        let addChargesList = await execReadByPK({
            opname: "additionalChargesByCompany",
            op: queries.additionalChargesByCompany,
            id: { companyId: user.companyId },
            sortDirection: "DESC"
        });
        // let addChargesList = await graphql(
        //     graphqlOperation(queries.additionalChargesByCompany, {
        //         companyId: user.companyId
        //     })
        // );
        if (addChargesList && addChargesList.items) {
            addChargesList.items.map((item) => {
                item.createdBy =
                    item.creator && item.creator.firstname
                        ? item.creator.firstname + " " + item.creator.lastname
                        : "N/A";
                // item.providerName =
                //   item.provider && item.provider.firstname
                //     ? `${item.provider.firstname} ${item.provider.lastname}`
                //     : "";
                // item.clientName = `${user.firstname} ${user.lastname}`;
                item.dateString = `${dtFormat.format(
                    new Date(item.createdAt)
                )}`;
                item.orderNum = item.order ? item.order.orderNo : "";
                return item;
            });
            setAddCharges(addChargesList.items);
            setRows(addChargesList.items);
        }
    };

    const _handleSearchChange = ({ target: { value } }) => {
        const loggedInUser = getUserFromCache();
        const companyid = loggedInUser.companyId;
        let arr = [];
        if (!!value) {
            const lowerCaseValue = value.toLowerCase();
            addCharges.map((item) => {
                if (
                    item.note
                        .toString()
                        .toLowerCase()
                        .includes(lowerCaseValue) ||
                    item.amount
                        .toString()
                        .toLowerCase()
                        .includes(lowerCaseValue) ||
                    item.desc.toString().toLowerCase().includes(lowerCaseValue)
                ) {
                    arr.push(item);
                }
            });
            setRows(arr);
        } else setRows(addCharges);
    };
    return (
        <>
            <FormHeading title={"Additional Charges"} classes={classes} />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={12} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            id="search"
                            label="Search by amount, desc or notes"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={_handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <AdditionalChargesTable rows={rows} />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
export default AdditionalChargesForm;
