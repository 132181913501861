import { alpha } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },

    title: {
        display: "block",
        fontSize: "24px",
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 30
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        },
        grow: {
            flexGrow: 1
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    paper: {
        padding: 2
    },
    root: {
        padding: theme.spacing(3, 2),
        [theme.breakpoints.up("md")]: {
            minWidth: "348px",
            overflowX: "auto"
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "32px"
        }
    },
    tabs: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "32px"
        }
    },
    bigAvatar: {
        margin: "0px auto",
        width: 60,
        height: 60,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up("sm")]: {
            width: 100,
            height: 100
        }
    },
    noLabelselectmargin: {
        marginTop: "15px",
        marginLeft: "5px"
    },
    rating1: {
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: 200
        },
        display: "flex",
        alignItems: "center"
    },
    textBox: {
        marginTop: "-16px",
        marginBottom: "-8px",
        [theme.breakpoints.up("sm")]: {
            maxWidth: "400px",
            margin: "-16px auto -8px"
        },
        [theme.breakpoints.up("md")]: {
            margin: "-0px auto -0px"
        }
    },
    rateProvider: {
        marginTop: "-5px"
    },
    reteBtn: {
        color: "#000",
        border: "1px solid #e4e4e5"
    },
    circleBox: {
        width: 50,
        height: 50,
        borderRadius: "50%",
        margin: "0 10px",
        display: "inline-flex",
        border: "1px solid #d5d5d6",
        justifyContent: "center",
        alignItems: "center"
    },
    amount: {
        backgroundColor: "#35af94"
    },
    btnBlack: {
        backgroundColor: "#000",
        color: "#fff",
        width: "100%",
        "&:hover": {
            backgroundColor: "#000",
            color: "#fff",
            opacity: "0.9"
        }
    },
    textinput: {
        border: "1px solid #000"
    },
    topHeader: {
        backgroundColor: "#000",
        color: "#fff",
        padding: "6px",
        margin: "-16px -8px 8px -8px",
        fontSize: "14px",
        [theme.breakpoints.up("sm")]: {
            margin: "-16px 0px 15px -0px",
            padding: "6px 15px"
        }
    },
    MuiOutlinedInputRoot: {
        border: "0"
    },
    vmLinkFormControl: {
        [theme.breakpoints.up("md")]: {
            minWidth: "300px"
        }
    },
    vmLink: {
        [theme.breakpoints.up("md")]: {
            minWidth: "348px"
        }
    },
    vmLinkLabel: {
        width: "95%"
    },
    customDialogButton: {
        height: "2.25rem",
        boxShadow: "none",
        minWidth: "5.625rem",
        alignItems: "center",
        lineHeight: "inherit",
        "&:hover": {
            boxShadow: "none"
        }
    },
    customDialogButtonText: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        fontSize: "0.875rem",
        fontWeight: 500,
        fontFamily: "Roboto",
        paddingTop: "0.1rem"
    }
}));
