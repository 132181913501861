import { graphql, graphqlOperation } from "../../modules/AmplifyServices";
import {
    getUserFromCache,
    userHasAdminRole,
    userHasProviderRole
} from "../../user/UserCommon";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import * as queries from "../../graphql/queries";

import moment from "moment-timezone";

export const getTimezone = async () => {
    let user = getUserFromCache();
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (userHasAdminRole()) {
        let tz = await graphql(
            graphqlOperation(queries.getRefData, {
                refType: `TIMEZONE|${user.company.id}`,
                refName: "timezone"
            })
        );

        if (tz && tz?.data?.getRefData) {
            console.log("Timezone refValue", tz);
            if (timeZone !== tz.data.getRefData.refValue) {
                timeZone = tz.data.getRefData.refValue;
            }
        } /*  else {
            let newTimezone = await graphql(
                graphqlOperation(mutations.createRefData, {
                    input: {
                        refType: `TIMEZONE|${user.company.id}`,
                        refName: "timezone",
                        refValue: timeZone
                    }
                })
            );
        } */

        // return "America/Toronto";
        return timeZone;
    }

    if (userHasProviderRole()) {
        if (user?.provider?.timezone) {
            if (timeZone !== user.provider.timezone) {
                timeZone = user.provider.timezone;
            }
        }
        return timeZone;
    }
};

export const getMasterTimezone = async () => {
    let user = getUserFromCache();

    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let tz = await graphql(
        graphqlOperation(queries.getRefData, {
            refType: `TIMEZONE|${user.company.id}`,
            refName: "timezone"
        })
    );

    if (tz && tz?.data?.getRefData) {
        return tz.data.getRefData.refValue;
    } /* else {
        let newTimezone = await graphql(
            graphqlOperation(mutations.createRefData, {
                input: {
                    refType: `TIMEZONE|${user.company.id}`,
                    refName: "timezone",
                    refValue: timeZone
                }
            })
        );
    } */

    //return "America/Toronto";
    return timeZone;
};

//code which has worked except australian timezone

/*
     // Convert the input date to a UTC-based Moment
    const utcMoment = moment.utc(d);

    // Shift that moment to the desired timezone
    const desiredMoment = utcMoment.clone().tz(tz);

    // Construct a new local Date object using the components of the shifted moment
    const adjustedDate = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        desiredMoment.hour(),
        desiredMoment.minute(),
        desiredMoment.second()
    );
    console.log(
        "Original Date:",
        d,
        "Timezone:",
        tz,
        "Converted Date:",
        adjustedDate
    );

    return adjustedDate;

*/

export const timezoneConvert = (d, tz) => {
    // Take the date provided and assume it's in the local time zone
    const localMoment = moment(d);

    // Convert this date to the desired timezone
    const desiredMoment = localMoment.tz(tz);
    console.log("Desired Moment", desiredMoment);

    // Convert the desired Moment back to a JavaScript Date for easy use
    const adjustedDate = new Date(
        desiredMoment.year(),
        desiredMoment.month(),
        desiredMoment.date(),
        desiredMoment.hour(),
        desiredMoment.minute(),
        desiredMoment.second()
    );

    console.log(
        "Original Date:",
        d,
        "Timezone:",
        tz,
        "Converted Date:",
        adjustedDate
    );

    return adjustedDate;
    //let currDate = moment(d);

    //new Date(d);

    //let converted_date = moment(currDate).tz(tz);

    /*  console.log(
        "Current Date , Converted Date",
        currDate.format("YYYY-MM-DD HH:mm:ss Z"),
        "::",
        converted_date.format("YYYY-MM-DD HH:mm:ss Z")
    );
 */
    /* new Date(
        currDate.toLocaleString("en-US", { timeZone: tz })
    ); */
    /* 
    console.log(
        "Current Date , Converted Date",
        currDate,
        "::",
        converted_date
    ); */

    // return converted_date.format("YYYY-MM-DD HH:mm:ss Z");
};

export const convertHourToTimeZone = (startHour, tz) => {
    let timeZone = tz; //"America/Vancouver";
    let currDate = new Date();
    currDate.setHours(startHour);

    let d = new Date(timezoneConvert(currDate, timeZone));
    console.log("returned value timezoneconvert", d);
    return d.getHours();
};

//functions using moment timezones
export const convertTimezoneRange = (start, end, locTz, userTz) => {
    // start and end are in browser timezone
    // locTz is the location(schedule) timezone
    // usetTz is the provider's specified timezone
    console.log("convertTimezoneRange", start, end, locTz, userTz);
    const startDateScheduleTz = zonedTimeToUtc(
        moment(start).format("YYYY-MM-DDTHH:mm:00"),
        locTz
    );
    const endDateScheduleTz = zonedTimeToUtc(
        moment(end).format("YYYY-MM-DDTHH:mm:00"),
        locTz
    );

    const startDateUserTz = utcToZonedTime(startDateScheduleTz, userTz);
    const endDateUserTz = utcToZonedTime(endDateScheduleTz, userTz);

    console.log(
        "convertTimezoneRange",
        startDateScheduleTz,
        "end",
        endDateScheduleTz
    );
    console.log("convertTimezoneRange", startDateUserTz, "end", endDateUserTz);

    return [startDateUserTz, endDateUserTz];
};

export const timezoneConversion = (sDate, locTz, userTz) => {
    const sDate_userTz = zonedTimeToUtc(
        moment(sDate).format("YYYY-MM-DDTHH:mm:00"),
        userTz
    );

    const convert_locTz = utcToZonedTime(sDate_userTz, locTz);

    console.log("Timezone Conversion", sDate_userTz, convert_locTz);

    return convert_locTz;
};

export const timezoneConversionUserToLoc = (sDate, userTz, locTz) => {
    const sDate_locTz = zonedTimeToUtc(
        moment(sDate).format("YYYY-MM-DDTHH:mm:00"),
        userTz
    );

    const convert_locTz = utcToZonedTime(sDate_locTz, locTz);

    return convert_locTz;
};
