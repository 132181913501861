import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export const DialogTheme = ({ palette }) =>
    createTheme({
        palette,
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        position: "absolute"
                    },
                    paperWidthLg: {
                        width: "750px",
                        maxWidth: "750px"
                    },
                    paperWidthMd: {
                        width: "650px",
                        maxWidth: "650px"
                    },
                    paperWidthSm: {
                        width: "450px",
                        maxWidth: "450px"
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: "0px 36px 36px 36px",
                        overflowX: "hidden",
                        overflowY: "auto"
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "36px"
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        width: "100%"
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: "14px",
                        transform: "translate(14px, 9px) scale(1)",
                        "&.Mui-focused": {
                            top: "2px"
                        },
                        "&.MuiInputLabel-shrink": {
                            top: "2px"
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        padding: "7.95px 14px",
                        fontSize: "14px"
                    }
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        height: "36px"
                    },
                    option: {
                        fontSize: "14px"
                    },
                    popupIndicator: {
                        marginRight: "-4px"
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        padding: "0px"
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: "14px",
                        display: "block",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        variant: "h4",
                        padding: "36px 36px 0px 36px",
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "24px"
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontSize: "14px"
                    },
                    h3: {
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "38px",
                        color: "black"
                    },
                    h4: {
                        fontSize: "34px"
                    },
                    helperText: {
                        fontSize: "0.75rem",
                        fontWeight: "normal",
                        color: palette.helperText.main,
                        fontStyle: "italic",
                        fontFamily: ["Roboto", "sans-serif"].join(",")
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginBottom: "0px",
                        height: "36px"
                    },
                    label: {
                        fontSize: "14px"
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        height: "36px",
                        minWidth: "90px",
                        lineHeight: "28px",
                        "&.Mui-disabled": {
                            color: "white"
                        }
                    },
                    text: {
                        display: "flex",
                        minWidth: "64px",
                        lineHeight: "16px",
                        padding: "0px",
                        alignItems: "center",
                        height: "24px"
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        borderRadius: "16px",
                        height: "auto",
                        width: "180px",
                        fontWeight: 700,
                        backgroundColor: grey[200],
                        color: grey[700],
                        "&:hover": {
                            backgroundColor: palette.primary.medium
                        },
                        "&:focus": {
                            backgroundColor: palette.primary.main,
                            color: "white",
                            "& .MuiTypography-helperText": {
                                color: "white"
                            }
                        },

                        label: {
                            display: "block",
                            whiteSpace: "normal"
                        }
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        padding: "18px"
                    }
                }
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        boxShadow: "none"
                    }
                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #D3DAE3"
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "16px 8px",
                        color: "#6B6B6C"
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&.MuiTableRow-hover:hover": {
                            backgroundColor: palette.primary.light,
                            cursor: "pointer"
                        }
                    }
                }
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        maxHeight: "350px",
                        maxWidth: "678px"
                    }
                }
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        flexDirection: "column",
                        gap: "18px",
                        padding: "18px"
                    }
                }
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: "0px 18px 18px 18px"
                    }
                }
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "18px"
                    }
                }
            }
        }
    });
