import makeStyles from "@mui/styles/makeStyles";
import chroma from "chroma-js";

const drawerWidthAdmin = 97;
const drawerWidth = 200;

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginRight: "36px",
        marginLeft: "36px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    },
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1
    },
    drawerPaperAdmin: {
        top: 64,
        position: "fixed",
        whiteSpace: "nowrap",
        width: drawerWidthAdmin,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        background: "#ffffff",
        border: "none",
        overflow: "visible",
        boxShadow: '3px 0px 10px -3px rgba(0, 0, 0, 0.3)'
    },

    drawerPaper: {
        top: 70,
        position: "fixed",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        background: "#ffffff",
        border: "none"
    },

    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(6.3)
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingLeft: 200,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4)
    },
    containeropen: {
        /* paddingLeft: 150,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4) */
        padding: "80px 0px 32px 0px"
        /*  marginLeft: "36px" */
    },
    containerclose: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    },
    fixedHeight: {
        height: 240
    },
    radioStyle: {
        paddingLeft: theme.spacing(2)
    },
    paperStyle: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    companyavatar: {
        margin: 10,
        width: 60,
        height: 60
    },
    dialogPaper: {
        minHeight: "80vh",
        minWidth: "80vh"
    },
    clientNotesColumn: {
        color: "#bababa"
    },
    customerNameText: {
        textDecoration: "none",
        cursor: "pointer",
        fontFamily: "Roboto",
        width: "fit-content"
    },
    customerFormSubtitle: {
        color: "black"
    },
    customTooltip: {
        backgroundColor: "red",
        color: "white",
        fontSize: "18px"
    }
}));
