import React, { Fragment, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField } from "@mui/material";
function DateRangePicker(props) {
    const [fromDate, setFromDate] = useState(props.from);
    const [toDate, setToDate] = useState(props.to);

    function handleFromDate(fd) {
        setFromDate(fd);
        const to = new Date(fd.getTime() + 86400000 * 2);
        setToDate(to);
        props.setFrom(fd);
        props.setTo(to);
    }
    function handleToDate(td) {
        setToDate(td);
        props.setTo(td);
    }
    return (
        <Fragment>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={customLocale}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <DatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            label="From"
                            value={fromDate}
                            onChange={handleFromDate}
                            renderInput={(params) => (
                                <TextField
                                    style={{ maxWidth: "200px" }}
                                    {...params}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            label="To"
                            value={toDate}
                            onChange={handleToDate}
                            renderInput={(params) => (
                                <TextField
                                    style={{ maxWidth: "200px" }}
                                    {...params}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Fragment>
    );
}

export default DateRangePicker;
