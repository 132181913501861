import React from "react";
import { Typography, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CurrencyFormat from "react-currency-format";

const useStyles = makeStyles({
    cardWidget: {
        padding: "1rem",
        borderRadius: "8px",
        boxShadow: "none",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)"
    },
    currencyFormat: {
        fontFamily: "Roboto",
        fontWeight: 900,
        fontSize: "36px",
        marginBottom: "0px"
    },
    cardSubheadingBox: {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center"
    },
    cardSubheadingText: {
        color: "rgba(0, 0, 0, 0.75)",
        fontSize: "14px",
        fontFamily: "Roboto"
    }
});

const DashboardCard = ({ value, title, icon, prefix }) => {
    const classes = useStyles();
    return (
        <Card className={classes.cardWidget}>
            <CardContent>
                <Typography>
                    <CurrencyFormat
                        className={classes.currencyFormat}
                        value={value}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={prefix}
                        decimalScale={0}
                        decimalSeparator=""
                    />
                </Typography>
                <div className={classes.cardSubheadingBox}>
                    {icon}
                    <Typography
                        variant="body1"
                        className={classes.cardSubheadingText}
                        title={title}
                    >
                        {title}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default DashboardCard;
