/* This common file will store any misc functions that 
deal with client user types */

/**
 * Determines the total number of orders, and last order date for
 * a list of clients
 *
 * @param {Array.<orders>} orders - An array of orders
 * @param {Array.<clients>} clients - An array of client
 * @returns {Array} a list. Each element in the list has the clientId, total number of orders, and last order date
 */
function computeTotalOrdersAndLastOrder(orders, clients) {
    const ordersByClientId = {};
    orders.forEach((order) => {
        const clientId = order.clientId;

        // Increment the order count for the client
        if (clientId in ordersByClientId) {
            ordersByClientId[clientId].orderCount++;
        } else {
            ordersByClientId[clientId] = {
                orderCount: 1,
                lastOrderDate: order.createdAt // Set the lastOrderDate to the order's createdAt date
            };
        }
    });

    return ordersByClientId;
}

export { computeTotalOrdersAndLastOrder };
