import React from 'react';
import { useToolbarStyles } from '../../styles/TableToolbarStyles';
import { Typography, Tooltip, Toolbar, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import clsx from "clsx";

export const EnhancedToolbar = props => {
    const classesToolbar = useToolbarStyles();
    const { numSelected, doNotDisplay } = props;

    // if we do not want to display the toolbar, we return nothing
    if (doNotDisplay) return (
        <Toolbar>
        </Toolbar>
    );

    return (
        <Toolbar
        className={clsx(classesToolbar.root, {
            [classesToolbar.highlight]: numSelected > 0
        })}
        >
        <div className={classesToolbar.title}>
            {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
            </Typography>
            ) : (
            ""
            )}
        </div>
        <div className={classesToolbar.spacer} />
        <div className={classesToolbar.actions}>
            {numSelected > 0 ? (
            <Tooltip title={props.decline === true ? 'Decline' : "Delete"}>
                <IconButton
                    aria-label={props.decline === true ? 'Decline' : "Delete"}
                    onClick={props.handleDelete}
                    size="large">
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            ) : (
            ""
            )}
        </div>
        </Toolbar>
    );
}