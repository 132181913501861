export const tableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        backgroundColor: "white",
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};
