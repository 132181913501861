import React, { useState } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import { UsersTableHead } from "./UserTableHead";
import { UserRow } from "./UserRow";

export const UsersTable = ({ users = [] }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    return (
        <TableContainer sx={{ minWidth: "1000px" }}>
            <Table>
                <UsersTableHead />
                <TableBody>
                    {users.map((user) => (
                        <UserRow
                            key={user.id}
                            userData={user}
                            id={user.id}
                            handleSelect={setSelectedRows}
                            selected={selectedRows}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
