import React, { useContext } from "react";
import { StoreContext } from "../context/StoreContext";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Paper, Grid, TextField, FormControl, InputAdornment, Toolbar, Switch, Button, FormControlLabel, Tooltip, IconButton } from "@mui/material";
// prettier-ignore
import { Snackbar } from "@mui/material";
import PropTypes from "prop-types";
import { getUserFromCache } from "../user/UserCommon";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { useToolbarStyles } from "../styles/TableToolbarStyles";
import clsx from "clsx";
import CompaniesTable from "../components/CompaniesTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { auditCompanyDelete } from "../modules/Audit";
import { execRead } from "../modules/DBService";
import { subscriptionLevelConst } from "../utils/Constants";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
function CompanyForm(props) {
    //select
    const [values, setValues] = React.useState({
        active: true,
        searchTerms: ""
    });
    //active pending switch
    const [active, setActive] = React.useState(true);
    const [selected, setSelected] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [filteredRows, setFilteredRows] = React.useState([]);

    const [nameFilter, setNameFilter] = React.useState("");
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [subLevel, setSubLevel] = React.useState([]);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    let filter = {
        active: {
            eq: true
        }
    };

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth);
        fetchCompanyList();
    }, []);

    const loggedInUser = getUserFromCache();

    async function fetchCompanyList() {
        let listCompanyData = await execRead({
            opname: "listCompanys",
            op: queries.listCompanys,
            filter: filter
        });
        let data = listCompanyData.items.map((item) =>
            item.subscriptionLevel
                ? item
                : { ...item, subscriptionLevel: "None" }
        );
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setRows(data);
        console.log(data);
        console.log(subscriptionLevelConst);
    }

    async function filterCompanyList(filter) {
        let listCompanyData = await execRead({
            opname: "listCompanys",
            op: queries.listCompanys,
            filter: filter ? filter : ""
        });
        listCompanyData.items.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setRows(listCompanyData.items);
        return listCompanyData.items;
    }

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    const deleteCompany = async (id) => {
        const input = {
            id: id
        };
        try {
            let deleteCompany = await graphql(
                graphqlOperation(mutations.deleteCompany, { input })
            );

            await auditCompanyDelete(
                loggedInUser,
                deleteCompany.data.deleteCompany
            );
            if (!!deleteCompany.error && deleteCompany.error.length > 0) {
                setSnackMsg("Something went wrong, while deleting company.");
                setMsgOpen(true);
            } else {
                fetchCompanyList();
                setDeleteDialog(false);
                setSelected([]);
                actions.setPage("CompanyForm");
            }
        } catch (err) {
            setSnackMsg("Something went wrong, while deleting company.");
            setMsgOpen(true);
        }
    };
    const _handleDeleteDialogAccept = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteCompany(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    //function handleInputChange(event) {
    //  const { name, value } = event.target;
    //  setValues(oldValues => ({
    //    ...oldValues,
    //    [name]: value,
    //  }));
    //  actions.setContextCompanyId(value)
    //}

    const handleCompanyChange = async (event) => {
        let value = nameFilter.toLowerCase();
        filter = {
            ...filter,
            active: {
                eq: active
            }
        };
        if (event.target.type != "checkbox") {
            setNameFilter(event.target.value);
            value = event.target.value.toLowerCase();
        } else {
            filter = {
                ...filter,
                active: {
                    eq: event.target.checked
                }
            };
        }
        console.log("This is the filter", filter);
        let listCompanyData = await filterCompanyList(filter);

        let filteredData = listCompanyData.filter((item) => {
            return (
                item.name.toLowerCase().match(new RegExp(value, "g")) ||
                (item.subscriptionLevel &&
                    item.subscriptionLevel
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                        .match(new RegExp(value, "g")))
            );
        });

        filteredData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setFilteredRows(filteredData);
    };

    const { actions } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    function handleCompanyAddClick() {
        actions.setMode("Add");
        actions.setPage("CompanyAddEditForm");
    }

    const EnhancedTableToolbar = (props) => {
        const classesToolbar = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classesToolbar.root, {
                    [classesToolbar.highlight]: numSelected > 0
                })}
            >
                <div className={classesToolbar.title}>
                    {numSelected > 0 ? (
                        <Typography variant="h6" id="tableTitle">
                            {selected.length} selected
                        </Typography>
                    ) : (
                        ""
                    )}
                </div>
                <div className={classesToolbar.spacer} />
                <div className={classesToolbar.actions}>
                    {numSelected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="Delete"
                                onClick={() => setDeleteDialog(true)}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        ""
                    )}
                </div>
            </Toolbar>
        );
    };

    //console.log("*******fetchCompanyList**********");
    //console.log(rows);
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="companies"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <FormHeading title={"Companies"} classes={classes} />
            <Paper
                style={{ minWidth: "300px", overflowX: "auto" }}
                rounded="true"
                className={classes.root}
            >
                <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <TextField
                            id="search"
                            name="searchTerms"
                            label="Filter by name or subscription level"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={nameFilter}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleCompanyChange}
                        />
                    </Grid>

                    <Grid item xs={3} className={classes.flexDisplay}>
                        <FormControlLabel
                            margin="normal"
                            control={
                                <Switch
                                    checked={active}
                                    onChange={(event) => {
                                        setActive(event.target.checked);
                                        handleCompanyChange(event);
                                    }}
                                    value="active"
                                    color="primary"
                                    name="active"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs={4}></Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2} alignItems="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCompanyAddClick}
                        >
                            <AddIcon className={classes.leftIcon} />
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Grid
                                item
                                xs={2}
                                className={classes.disablepadding}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.fullWidth}
                                >
                                    {/* }
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    List Actions
                  </InputLabel>
                  <Select
                    value={values.action}
                    //onChange={handleInputChange}
                    name="action"
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="action"
                        id="outlined-age-simple"
                      />
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {rows &&
                      rows.length > 0 &&
                      rows.map((item, i) => {
                        return (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                    { */}
                                </FormControl>
                            </Grid>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <CompaniesTable
                                companiList={
                                    nameFilter.trim() === ""
                                        ? rows
                                        : filteredRows
                                }
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default CompanyForm;
