import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import CategoryAddEditForm from "./CategoryAddEditForm";

const addCategoryPortal = document.getElementById("add-category-portal");
//const returnPath = "DashboardForm"

function CategoryAddModal(props) {
    return ReactDOM.createPortal(
        <Dialog open={props.open} fullWidth={true} maxWidth={"xs"}>
            <DialogContent>
                <CategoryAddEditForm
                    originPath={props.originPath}
                    close={props.close}
                    updateCategoryValue={props.updateCategoryValue}
                />
            </DialogContent>
        </Dialog>,
        addCategoryPortal
    );
}

export default CategoryAddModal;
