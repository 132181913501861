import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import CompanyLocationAddEditForm from "./CompanyLocationAddEditForm";

const setUpLocation = document.getElementById("setup-location");

function ServiceLocationModal(props) {
    return ReactDOM.createPortal(
        <Dialog
            sx={{ padding: "1.5rem" }}
            open={props.open}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogContent>
                <CompanyLocationAddEditForm
                    open={props.open}
                    path={props.path}
                    compId={props.compId}
                    closeServiceLocationPortal={
                        props.closeServiceLocationPortal
                    }
                    returnTo={props.returnTo}
                    companyDataDashboard={props.companyDataDashboard}
                    doneTodoHandler={props.doneTodoHandler}
                />
            </DialogContent>
        </Dialog>,
        setUpLocation
    );
}

export default ServiceLocationModal;
