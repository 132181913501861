import React, { useContext } from "react";
import Table from "@mui/material/Table";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import { StoreContext } from "../context/StoreContext";
import {
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter,
    Snackbar,
    IconButton,
    Tooltip,
    Button
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Edit } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { useNavigate } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData("Spring Sale", "April 31 2019", "Precentage", "", ""),
    createData("Easter Monday", "April 31 2019", "Precentage", "", ""),
    createData("April Sale", "April 31 2019", "Precentage", "", "")
];

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    },
    pointer: {
        cursor: "pointer"
    },
    descCellWidth: {
        width: "200px"
    },
    actionsCellWidth: {
        width: "130px",
        padding: "0 5px 0 0"
    },
    customHeadPadding: {
        padding: "0 5px 0 5px"
    },
    customCellPading: {
        padding: "0px 5px 0 5px"
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};
export default function PromotionsTable({
    listPackagesData,
    fetchPackageList,
    selectedRow,
    tabValue
}) {
    const navigate = useNavigate();
    const { state, actions } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [promotionId, setPromotionId] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("packagesTable")
    );
    const [rows, setRows] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    const [deleteDialog, setDeleteDialog] = React.useState(false);

    const [endDialog, setEndDialog] = React.useState(false);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = listPackagesData.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
            selectedRow([]);
        }
        setSelected([]);
    }
    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("packagesTable")) {
            setTableRowsSession("packagesTable", event.target.value);
        }
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    function handleDeleteProviders(id, event) {
        setPromotionId(id);
        setDeleteDialog(true);
    }

    function handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    function handleEndPromotion(id, e) {
        setPromotionId(id);
        setEndDialog(true);
    }

    function handleEndDialogDecline() {
        setEndDialog(false);
    }
    function handlePackageEditClick(id) {
        actions.setMode("Edit");
        navigate("edit");
        actions.setId(id);
    }

    const handleViewPackage = (id) => {
        actions.setMode("View");
        actions.setPage("PackageAddEditForm");
        actions.setId(id);
    };

    const deletePackage = async (id) => {
        const input = {
            id: id
        };
        try {
            let deletePackage = await graphql(
                graphqlOperation(mutations.deletePackage, { input })
            );
            if (
                !!deletePackage.error &&
                typeof deletePackage.error === "object"
            ) {
                setSnackMsg("Something went wrong, while deleting package.");
                setMsgOpen(true);
            } else {
                fetchPackageList();
                setEndDialog(false);
                setDeleteDialog(false);
            }
        } catch (err) {
            setSnackMsg("Something went wrong, while deleting package.");
            setMsgOpen(true);
        }
    };

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    // const listPackagesData = props
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={deleteDialog}
                //onClose={handleDeleteDialogDecline}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you wish to delete the selected providers?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete the selected providers. They
                        will no longer be available on the system and all their
                        bookings will be cancelled.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogDecline} color="primary">
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        autoFocus
                        onClick={() => deletePackage(promotionId)}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={endDialog}
                //onClose={handleDeleteDialogDecline}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ending a promotion will set the end date to today.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEndDialogDecline} color="primary">
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        autoFocus
                        onClick={() => deletePackage(promotionId)}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectAll}
                                            onClick={handleSelectAllClick}
                                        />
                                    </TableCell>

                                    {/* <TableCell align="left" className={classes.descCellWidth}>
                  Description
                </TableCell> */}
                                    {/* <TableCell align="left" className={classes.customHeadPadding}>
                  Company
                </TableCell> */}
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Service type
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Description
                                    </TableCell>
                                    {/* <TableCell align="left" className={classes.customHeadPadding}>
                  Quantity
                </TableCell> */}
                                    {/* <TableCell align="left">Price</TableCell> */}
                                    <TableCell
                                        align="center"
                                        className={classes.customHeadPadding}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listPackagesData &&
                                    listPackagesData.length > 0 &&
                                    listPackagesData
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((item, i) => {
                                            return (
                                                <TableRow
                                                    key={item.id}
                                                    hover
                                                    onClick={(event) =>
                                                        handleRowClick(
                                                            event,
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    item.id
                                                                ) !== -1 ||
                                                                selectAll
                                                            }
                                                        />
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                          {item.desc}
                        </TableCell> */}
                                                    {/* <TableCell
                          align="left"
                          className={classes.customCellPading}>
                          {item.company && item.company.name}
                        </TableCell> */}
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            classes.customCellPading
                                                        }
                                                    >
                                                        {item.servicetype &&
                                                            item.servicetype
                                                                .name}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            classes.customCellPading
                                                        }
                                                    >
                                                        {item.desc}
                                                    </TableCell>
                                                    {/* <TableCell align="left">{item.quantity}</TableCell> */}
                                                    {/* <TableCell align="left">
                          <CurrencyFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={getCurrencySymbol()}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </TableCell> */}

                                                    <TableCell
                                                        align="center"
                                                        className={
                                                            classes.actionsCellWidth
                                                        }
                                                    >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                aria-label="Edit"
                                                                onClick={() =>
                                                                    handlePackageEditClick(
                                                                        item.id
                                                                    )
                                                                }
                                                                size="large"
                                                            >
                                                                <Edit
                                                                    className={
                                                                        classes.pointer
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={6}
                                        count={listPackagesData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page"
                                            },
                                            native: true
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div>
        </>
    );
}
