import React, { Fragment } from "react";
import { Typography, Grid } from "@mui/material";
import RightPanel from "./RightPanel.js";
import LeftPanel from "./LeftPanel.js";
import { makeStyles } from "@mui/styles";

/* styles used in top panel */
const useStyles = makeStyles({
    panel_mobile: {
        justifyContent: "center",
        flexDirection: "column-reverse",
        alignItems: "center",
        gap: "2rem"
    }
});

const TopPanel = (props) => {
    console.log("TopPanel Loaded");
    const classes = useStyles();

    return (
        <Fragment>
            {/* this will be the grid that holds the top panel */}
            <Grid
                container
                className={props.width < 900 ? classes.panel_mobile : ""}
            >
                <LeftPanel width={props.width} freeTrial={props.freeTrial} />
                <RightPanel
                    renderActivateAccountForm={props.renderActivateAccountForm}
                    width={props.width}
                    freeTrial={props.freeTrial}
                />
            </Grid>
        </Fragment>
    );
};

export default TopPanel;
