import React, { useEffect, useState } from "react";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Paper, TextField } from "@mui/material";
import {
    Grid,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    Snackbar
} from "@mui/material";
import { Autocomplete } from "@mui/material";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { PotentialSalesTable } from "../components/PotentialSalesTable";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import DateRangePicker from "../utils/CommonComonents/DateRangePicker";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CSVLink } from "react-csv";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";

function PotentialSalesForm(props) {
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [servicesMap, setServicesMap] = useState({});
    const [skillsMap, setSkillsMap] = useState({});
    const [servicesOptions, setServicesOptions] = useState([]);
    const [skillsOptions, setSkillsOptions] = useState(["All"]);
    const [searchComplete, setSearchComplete] = useState(false);
    // const [todOptions, setTodOptions] = useState([
    //     "All",
    //     "Morning (before 12pm)",
    //     "Afternoon (after 12pm)",
    //     "Evening (after 5pm)"
    // ]);
    const [prospectsData, setProspectsData] = useState([]);

    const [selectedServices, setSelectedServices] = useState(["All"]);
    const [selectedSkills, setSelectedSkills] = useState(["All"]);
    const [selectedTod, setSelectedTod] = useState([true, false, false, false]);

    const [requestStartDate, setRequestStartDate] = useState("");
    const [requestEndDate, setRequestEndDate] = useState("");
    const [bookingStartDate, setBookingStartDate] = useState("");
    const [bookingEndDate, setBookingEndDate] = useState("");

    const [showBookingDateRange, setShowBookingDateRange] = useState(false);
    const [showRequestDateRange, setShowRequestDateRange] = useState(false);
    const [showTodDialog, setShowTodDialog] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState(false);
    const [exportReadySales, setExportReadySales] = useState([]);
    const csvHeaders = [
        { label: "ID", key: "ID" },
        { label: "Request Date", key: "REQUESTDATE" },
        { label: "Client Name", key: "CLIENTNAME" },
        { label: "Client Phone", key: "CLIENTPHONE" },
        { label: "Email Address", key: "EMAILADDRESS" },
        { label: "Booking Address", key: "BOOKINGADDRESS" },
        { label: "Booking Street", key: "BOOKINGSTREET" },
        { label: "Booking City", key: "BOOKINGCITY" },
        { label: "Booking State", key: "BOOKINGSTATE" },
        { label: "Booking Country", key: "BOOKINGCOUNTRY" },
        { label: "Booking Start Date", key: "BOOKINGSTARTDATE" },
        { label: "Booking End Date", key: "BOOKINGENDDATE" },
        { label: "Service", key: "SERVICE" },
        { label: "Skills", key: "SKILLS" },
        { label: "Time Of Day", key: "TIMEOFDAY" },
        { label: "Message", key: "MESSAGE" }
    ];

    useEffect(() => {
        setShowLoading(true);
        getServicesListMap();
        getSkillsListMap();
        getMappedProspectsDataList();
    }, []);

    //ONLY USE THIS useEffect when getMappedProspectsDataList needs to re-run after anny dependenies change
    //for now any updates on servicesMap and skillsMap this useEffect will run
    useEffect(() => {
        getMappedProspectsDataList();
    }, [servicesMap, skillsMap]);

    function getFormattedDate(dateString) {
        return moment(dateString).format("MMM DD, YYYY HH:mm a");
    }
    function getFormattedDateExports(dateString) {
        return moment(dateString).format("MM/DD/YYYY hh:mm A");
    }
    const handleMsgClose = (e, value) => {
        setMsgOpen(false);
    };
    const handleSelectService = (e, value) => {
        if (value.includes("All")) {
            if (value[value.length - 1] === "All") {
                setSelectedServices(["All"]);
            } else if (value[0] === "All") {
                value.splice(0, 1);
                setSelectedServices(value);
            }
        } else if (value) {
            setSelectedServices(value);
        } else setSelectedServices([]);
    };

    const handleSelectSkill = (e, value) => {
        if (value.includes("All")) {
            if (value[value.length - 1] === "All") {
                setSelectedSkills(["All"]);
            } else if (value[0] === "All") {
                value.splice(0, 1);
                setSelectedSkills(value);
            }
        } else if (value) {
            setSelectedSkills(value);
        } else setSelectedSkills([]);
    };

    const saveTod = (i) => {
        if (i === 0) {
            setSelectedTod([true, false, false, false]);
        } else {
            const newTod = [...selectedTod];
            newTod[0] = false;
            newTod[i] = !newTod[i];
            setSelectedTod(newTod);
        }
    };

    const handleGenerateReport = async () => {
        getMappedProspectsDataList();
    };
    const getMappedProspectsDataList = async () => {
        setShowLoading(true);
        //setServicesMap();
        //setSkillsMap();

        const user = getUserFromCache();
        try {
            //console.log("I am in Try Block");
            const servicesListMap = { ...servicesMap };
            const skillsListMap = { ...skillsMap };
            let prospectsDataList;
            if (prospectsData.length > 0) {
                //console.log("I am in if Block");
                //console.log(prospectsData);
                prospectsDataList = [...prospectsData];
                //console.log(prospectsData);

                if (prospectsDataList.length > 0) {
                    prospectsDataList.map((item) => {
                        if (item.SearchParamsInfo) {
                            const parsedSearchParamsInfo = JSON.parse(
                                item.SearchParamsInfo
                            );
                            item.parsedSearchParamsInfo =
                                parsedSearchParamsInfo;
                            item.bookingStartDateString = getFormattedDate(
                                item.parsedSearchParamsInfo.bookingStartDate
                            );
                            item.bookingStartDateExports =
                                getFormattedDateExports(
                                    item.parsedSearchParamsInfo.bookingStartDate
                                );
                            item.bookingEndDateString = getFormattedDate(
                                item.parsedSearchParamsInfo.bookingEndDate
                            );
                            item.bookingEndDateExports =
                                getFormattedDateExports(
                                    item.parsedSearchParamsInfo.bookingEndDate
                                );

                            let servicesString = "";
                            //console.log(servicesListMap);
                            if (
                                item.parsedSearchParamsInfo.services?.length >
                                    0 &&
                                Object.keys(servicesListMap).length > 0
                            ) {
                                for (
                                    let serviceIndex = 0;
                                    serviceIndex <
                                    item.parsedSearchParamsInfo.services
                                        ?.length;
                                    serviceIndex++
                                ) {
                                    let searchServiceID =
                                        item.parsedSearchParamsInfo.services[
                                            serviceIndex
                                        ];
                                    //console.log("***********SERVICE ID*****************");
                                    if (
                                        servicesListMap.hasOwnProperty(
                                            searchServiceID
                                        ) &&
                                        servicesListMap[searchServiceID].active
                                    ) {
                                        servicesString += `${servicesListMap[searchServiceID].name} `;
                                    } else {
                                        servicesString +=
                                            "Requested Service has been deleted ";
                                    }
                                }
                            } else {
                                //console.log("No Service Requested");
                                servicesString = "No Service Requested ";
                            }

                            item.serviceString = servicesString;

                            ///service string ends here

                            ///skill string starts here
                            let skillsString = "";
                            for (
                                let index = 0;
                                index <
                                item.parsedSearchParamsInfo.skills.length;
                                index++
                            ) {
                                if (
                                    item.parsedSearchParamsInfo.skills.length >
                                        0 &&
                                    Object.keys(skillsListMap).length > 0
                                ) {
                                    let searchSkillID =
                                        item.parsedSearchParamsInfo.skills[
                                            index
                                        ];

                                    if (
                                        skillsListMap.hasOwnProperty(
                                            searchSkillID
                                        ) &&
                                        skillsListMap[searchSkillID].active
                                    ) {
                                        skillsString += `${skillsListMap[searchSkillID].name} `;
                                    } else {
                                        skillsString +=
                                            "Requested Skill has been deleted ";
                                    }
                                } else {
                                    skillsString = "None ";
                                }
                            }
                            item.skillsString = skillsString;

                            ///skill string ends here
                            item.todString =
                                item.parsedSearchParamsInfo.timeOfDay;
                            item.todValueArray = [
                                item.todString.includes("Any Time of the Day"),
                                item.todString.includes(
                                    "Morning (before 12pm)"
                                ),
                                item.todString.includes(
                                    "Afternoon (after 12pm)"
                                ),
                                item.todString.includes("Evening (after 5pm)")
                            ];
                            item.bookingStartDate = new Date(
                                item.parsedSearchParamsInfo.bookingStartDate
                            );
                            item.bookingEndDate = new Date(
                                item.parsedSearchParamsInfo.bookingEndDate
                            );
                        } else {
                            item.skillsString = "N/A";
                            item.todString = "N/A";
                            item.serviceString = "N/A";
                            item.bookingStartDateString = "N/A";
                            item.bookingEndDateString = "N/A";
                            item.bookingStartDate = "N/A";
                            item.bookingEndDate = "N/A";
                        }
                        item.requestDate = new Date(item.createdAt);
                        item.clientName = `${item.firstname} ${item.lastname}`;
                        return item;
                    });
                    //prospectsDataList = prospectsDataListResult.items;
                    //console.log("parsed prospects list" + prospectsDataList);
                    setProspectsData(prospectsDataList);
                    setSearchComplete(true);
                } else {
                    setExportReadySales([]);
                    setProspectsData([]);
                    setSearchComplete(false);
                    setSnackMsg("No data found.");
                    setMsgOpen(true);
                    setShowLoading(false);
                    return;
                }
            } else {
                //console.log("I am in Else Block");
                const prospectsDataListResult = await execReadByPK({
                    opname: "listProspects",
                    op: queries.listProspects,
                    id: { companyId: user.companyId },
                    sortDirection: "DESC"
                });

                // const prospectsDataListResult = await graphql(
                //     graphqlOperation(queries.listProspects, {
                //         companyId: user.companyId,
                //         limit: process.env.REACT_APP_LISTLIMIT,
                //         sortDirection: "DESC"
                //     })
                // );
                /*console.log(
                    "++++++++++++++++++++++++++++prospectsDataList result = ",
                    JSON.parse(JSON.stringify(prospectsDataListResult))
                );*/

                /**console.log( "**********************Prospects data****************");

                console.log(JSON.parse(JSON.stringify(prospectsDataListResult)));

                console.log("**********************Services List Map****************" );

                console.log(servicesListMap);
                console.log(servicesMap);*/

                if (prospectsDataListResult && prospectsDataListResult.items) {
                    prospectsDataListResult.items.map((item) => {
                        if (item.SearchParamsInfo) {
                            const parsedSearchParamsInfo = JSON.parse(
                                item.SearchParamsInfo
                            );
                            item.parsedSearchParamsInfo =
                                parsedSearchParamsInfo;
                            item.bookingStartDateString = getFormattedDate(
                                item.parsedSearchParamsInfo.bookingStartDate
                            );
                            item.bookingStartDateExports =
                                getFormattedDateExports(
                                    item.parsedSearchParamsInfo.bookingStartDate
                                );
                            item.bookingEndDateString = getFormattedDate(
                                item.parsedSearchParamsInfo.bookingEndDate
                            );
                            item.bookingEndDateExports =
                                getFormattedDateExports(
                                    item.parsedSearchParamsInfo.bookingEndDate
                                );
                            ///service string begins here
                            let servicesString = "";
                            console.log(servicesListMap);
                            if (
                                item.parsedSearchParamsInfo.services?.length >
                                    0 &&
                                Object.keys(servicesListMap).length > 0
                            ) {
                                for (
                                    let serviceIndex = 0;
                                    serviceIndex <
                                    item.parsedSearchParamsInfo.services
                                        ?.length;
                                    serviceIndex++
                                ) {
                                    let searchServiceID =
                                        item.parsedSearchParamsInfo.services[
                                            serviceIndex
                                        ];
                                    // console.log("***********SERVICE ID*****************");
                                    if (
                                        servicesListMap.hasOwnProperty(
                                            searchServiceID
                                        ) &&
                                        servicesListMap[searchServiceID].active
                                    ) {
                                        servicesString += `${servicesListMap[searchServiceID].name}, `;
                                    } else {
                                        servicesString +=
                                            "Requested Service has been deleted ";
                                    }
                                }
                            } else {
                                //console.log("No Service Requested");
                                servicesString = "No Service Requested ";
                            }

                            item.serviceString = servicesString;

                            ///service string ends here
                            let skillsString = "";
                            for (
                                let index = 0;
                                index <
                                item.parsedSearchParamsInfo.skills.length;
                                index++
                            ) {
                                if (
                                    item.parsedSearchParamsInfo.skills.length >
                                        0 &&
                                    Object.keys(skillsListMap).length > 0
                                ) {
                                    let searchSkillID =
                                        item.parsedSearchParamsInfo.skills[
                                            index
                                        ];

                                    if (
                                        skillsListMap.hasOwnProperty(
                                            searchSkillID
                                        ) &&
                                        skillsListMap[searchSkillID].active
                                    ) {
                                        skillsString += `${skillsListMap[searchSkillID].name} `;
                                    } else {
                                        skillsString +=
                                            "Requested Skill has been deleted ";
                                    }
                                } else {
                                    console.log("None");
                                    skillsString = "None ";
                                }
                            }
                            item.skillsString = skillsString;

                            item.todString =
                                item.parsedSearchParamsInfo.timeOfDay;
                            item.todValueArray = [
                                item.todString.includes("Any Time of the Day"),
                                item.todString.includes(
                                    "Morning (before 12pm)"
                                ),
                                item.todString.includes(
                                    "Afternoon (after 12pm)"
                                ),
                                item.todString.includes("Evening (after 5pm)")
                            ];
                            item.bookingStartDate = new Date(
                                item.parsedSearchParamsInfo.bookingStartDate
                            );
                            item.bookingEndDate = new Date(
                                item.parsedSearchParamsInfo.bookingEndDate
                            );
                        } else {
                            item.skillsString = "N/A";
                            item.todString = "N/A";
                            item.serviceString = "N/A";
                            item.bookingStartDateString = "N/A";
                            item.bookingEndDateString = "N/A";
                            item.bookingStartDate = "N/A";
                            item.bookingEndDate = "N/A";
                        }
                        item.requestDate = new Date(item.createdAt);
                        item.clientName = `${item.firstname} ${item.lastname}`;
                        return item;
                    });
                    prospectsDataList = prospectsDataListResult.items;
                    //console.log("parsed prospects list" + prospectsDataList);
                    setProspectsData(prospectsDataList);
                    setSearchComplete(true);
                } else {
                    setExportReadySales([]);
                    setProspectsData([]);
                    setSearchComplete(false);
                    setSnackMsg("No data found.");
                    setMsgOpen(true);
                    setShowLoading(false);
                    return;
                }
            }
            function entriesExist(arr1, arr2) {
                const map = {};
                arr2.map((item) => {
                    map[item] = 1;
                });
                let entriesMatch = false;
                arr1.forEach((item) => {
                    if (map[item.id]) {
                        entriesMatch = true;
                    }
                });
                return entriesMatch;
            }

            // check if skills or services array is empty. If empty, then set the array to [All].
            let skills = selectedSkills;
            if (skills.length === 0) {
                skills = ["All"];
                setSelectedSkills(["All"]);
            }
            let services = selectedServices;
            if (services.length === 0) {
                services = ["All"];
                setSelectedServices(["All"]);
            }

            // ensure that at least one of the selected services and skills exist for each entry
            let filteredProspectsData = prospectsDataList.filter((item) => {
                return (
                    (services
                        ? services[0] == "All" ||
                          (item.parsedSearchParamsInfo &&
                              entriesExist(
                                  services,
                                  item.parsedSearchParamsInfo.services
                              ))
                        : false) &&
                    (skills
                        ? skills[0] == "All" ||
                          (item.parsedSearchParamsInfo &&
                              entriesExist(
                                  skills,
                                  item.parsedSearchParamsInfo.skills
                              ))
                        : false) &&
                    (selectedTod
                        ? selectedTod[0] ||
                          (item.todValueArray
                              ? (selectedTod[1] && item.todValueArray[1]) ||
                                (selectedTod[2] && item.todValueArray[2]) ||
                                (selectedTod[3] && item.todValueArray[3])
                              : false)
                        : false) &&
                    (!requestStartDate ||
                        !requestEndDate ||
                        (item.requestDate > requestStartDate &&
                            item.requestDate < requestEndDate)) &&
                    (!bookingStartDate ||
                        !bookingEndDate ||
                        (item.bookingStartDate < bookingEndDate &&
                            item.bookingEndDate > bookingStartDate))
                );
            });
            if (filteredProspectsData.length == 0) {
                setSnackMsg("No data found.");
                setMsgOpen(true);
            }
            setRows(filteredProspectsData);
            console.log("just a test", filteredProspectsData);
            const csvProspectsDataList = [...filteredProspectsData];
            const csvData = csvProspectsDataList.map((item) => {
                const {
                    prospectId: ID,
                    requestDate: REQUESTDATE,
                    clientName: CLIENTNAME,
                    phone: CLIENTPHONE,
                    emailaddress: EMAILADDRESS,
                    addressoneline: BOOKINGADDRESS,
                    street: BOOKINGSTREET,
                    city: BOOKINGCITY,
                    state: BOOKINGSTATE,
                    country: BOOKINGCOUNTRY,
                    bookingStartDateExports: BOOKINGSTARTDATE,
                    bookingEndDateExports: BOOKINGENDDATE,
                    serviceString: SERVICE,
                    skillsString: SKILLS,
                    todString: TIMEOFDAY,
                    message: MESSAGE
                } = item;
                const newItem = {
                    ID,
                    REQUESTDATE,
                    CLIENTNAME,
                    CLIENTPHONE,
                    EMAILADDRESS,
                    BOOKINGADDRESS,
                    BOOKINGSTREET,
                    BOOKINGCITY,
                    BOOKINGSTATE,
                    BOOKINGCOUNTRY,
                    BOOKINGSTARTDATE,
                    BOOKINGENDDATE,
                    SERVICE,
                    SKILLS,
                    TIMEOFDAY,
                    MESSAGE
                };
                return newItem;
            });
            setExportReadySales(csvData);
        } catch (error) {
            console.log("Error encountered", error);
        }
        setShowLoading(false);
    };

    const getServicesListMap = async () => {
        const authuser = getUserFromCache();
        const result = await graphql(
            graphqlOperation(queries.serviceTypeByCompany, {
                companyId: authuser.company.id,
                filter: { deleted: { ne: true }, isVisible: { ne: false } },
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );

        //console.log("getServicesList result = ", result);
        const servicesMap = {};

        if (
            result.data &&
            result.data.serviceTypeByCompany &&
            result.data.serviceTypeByCompany.items
        ) {
            const serviceOptionsArray = ["All"];
            serviceOptionsArray.push(...result.data.serviceTypeByCompany.items);
            setServicesOptions(serviceOptionsArray);
            const servicesMap = {};
            result.data.serviceTypeByCompany.items.map((item) => {
                servicesMap[item.id] = item;
            });
            setServicesMap(servicesMap);
            //console.log("*******************SERVICES MAP***************");
            // console.log(servicesMap);
            // console.log(typeof servicesMap);
            return servicesMap;
        } else {
            setServicesOptions([]);
            setServicesMap(servicesMap);
            //console.log("*******************SERVICES MAP***************");
            //console.log(servicesMap);
            return servicesMap;
        }
    };

    const getSkillsListMap = async () => {
        const user = getUserFromCache();

        const result = await execReadByPK({
            opname: "skillByCompany",
            op: queries.skillByCompany,
            id: { companyId: user.company.id },
            filter: {
                deleted: { ne: true }
            },
            sortDirection: "DESC"
        });

        // const result = await graphql(
        //     graphqlOperation(queries.listSkills, {
        //         filter: {
        //             and: [
        //                 { deleted: { ne: true } },
        //                 {
        //                     companyId: {
        //                         eq: user && user.company ? user.company.id : ""
        //                     }
        //                 }
        //             ]
        //         },
        //         limit: process.env.REACT_APP_LISTLIMIT
        //     })
        // );
        // console.log("getSkillsList result = ", result);
        const skillsMap = {};
        if (result && result.items) {
            const totalSkillsOptions = ["All"];
            totalSkillsOptions.push(...result.items);
            setSkillsOptions(totalSkillsOptions);
            result.items.map((item) => {
                skillsMap[item.id] = item;
            });
            setSkillsMap(skillsMap);
            setShowLoading(false);
            //console.log("*******************Skills MAP***************");
            //console.log(skillsMap);
            return skillsMap;
        } else {
            setSkillsOptions([]);
            setSkillsMap(skillsMap);
            setShowLoading(false);
            //console.log("*******************Skills MAP***************");
            //console.log(skillsMap);
            return skillsMap;
        }
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <FormHeading title={"Waitlist"} classes={classes} />
            <Paper rounded="true" className={classes.root}>
                <Dialog
                    open={showBookingDateRange}
                    onClose={() => {
                        setShowBookingDateRange(false);
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <DialogContentText>
                            Choose a date range for the booking:
                        </DialogContentText>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <DateRangePicker
                                    setFrom={setBookingStartDate}
                                    setTo={setBookingEndDate}
                                    from={bookingStartDate}
                                    to={bookingEndDate}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setBookingStartDate("");
                                            setBookingEndDate("");
                                            setShowBookingDateRange(false);
                                        }}
                                        color="primary"
                                        size="small"
                                    >
                                        Any
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setShowBookingDateRange(false);
                            }}
                            color="primary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                setShowBookingDateRange(false);
                            }}
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showRequestDateRange}
                    onClose={() => {
                        setShowRequestDateRange(false);
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <DialogContentText>
                            Choose a date range for the request:
                        </DialogContentText>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <DateRangePicker
                                    setFrom={setRequestStartDate}
                                    setTo={setRequestEndDate}
                                    from={requestStartDate}
                                    to={requestEndDate}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setRequestStartDate("");
                                            setRequestEndDate("");
                                            setShowRequestDateRange(false);
                                        }}
                                        color="primary"
                                        size="small"
                                    >
                                        Any
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setShowRequestDateRange(false);
                            }}
                            color="primary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                setShowRequestDateRange(false);
                            }}
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showTodDialog}
                    fullWidth
                    onClose={() => {
                        setShowTodDialog(false);
                    }}
                    aria-labelledby="select-tod"
                >
                    <DialogTitle
                        id="select-tod"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Requested Time of Day
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Filter by the requested time of day:
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <div>
                                    <MenuItem
                                        value={0}
                                        key={0}
                                        selected={selectedTod[0]}
                                        component="div"
                                        onClick={() => {
                                            saveTod(0);
                                        }}
                                    >
                                        Any time of the day
                                    </MenuItem>
                                    <MenuItem
                                        value={1}
                                        key={1}
                                        selected={selectedTod[1]}
                                        component="div"
                                        onClick={() => {
                                            saveTod(1);
                                        }}
                                    >
                                        Morning (before 12pm)
                                    </MenuItem>
                                    <MenuItem
                                        value={2}
                                        key={2}
                                        selected={selectedTod[2]}
                                        component="div"
                                        onClick={() => {
                                            saveTod(2);
                                        }}
                                    >
                                        Afternoon (after 12pm)
                                    </MenuItem>
                                    <MenuItem
                                        value={3}
                                        key={3}
                                        selected={selectedTod[3]}
                                        component="div"
                                        onClick={() => {
                                            saveTod(3);
                                        }}
                                    >
                                        Evening (after 5pm)
                                    </MenuItem>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setShowTodDialog(false);
                            }}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Autocomplete
                                    multiple
                                    defaultValue={["All"]}
                                    value={selectedServices}
                                    loading={props.loading}
                                    loadingText="Loading..."
                                    noOptionsText="No Services Found"
                                    options={servicesOptions}
                                    className={classes.formControl}
                                    getOptionLabel={(option) => {
                                        return option == "All"
                                            ? "All"
                                            : option.name;
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            {...params}
                                            label="Select Service"
                                            InputProps={{
                                                ...params.InputProps
                                            }}
                                            InputLabelProps={{
                                                ...params.InputLabelProps
                                            }}
                                            fullWidth
                                        />
                                    )}
                                    onChange={handleSelectService}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                if (
                                                    !bookingStartDate ||
                                                    !bookingEndDate
                                                ) {
                                                    setBookingStartDate(
                                                        new Date(
                                                            new Date().getFullYear(),
                                                            0,
                                                            1
                                                        )
                                                    );
                                                    setBookingEndDate(
                                                        moment()
                                                            .add(1, "M")
                                                            .toDate()
                                                    );
                                                }
                                                setShowBookingDateRange(true);
                                            }}
                                            startIcon={<DateRangeIcon />}
                                        >
                                            {bookingStartDate && bookingEndDate
                                                ? `Booking Date Range: ${moment(
                                                      bookingStartDate
                                                  ).format("MMM DD YYYY")}
                                to 
                                ${moment(bookingEndDate).format("MMM DD YYYY")}`
                                                : "Booking Date Range: Any Date"}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleGenerateReport}
                                            startIcon={<SearchIcon />}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Autocomplete
                                    multiple
                                    defaultValue={["All"]}
                                    value={selectedSkills}
                                    loading={props.loading}
                                    loadingText="Loading..."
                                    noOptionsText="No Skills Found"
                                    options={skillsOptions}
                                    className={classes.formControl}
                                    getOptionLabel={(option) => {
                                        return option == "All"
                                            ? "All"
                                            : option && option.name
                                              ? option.name
                                              : "";
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            {...params}
                                            label="Select Skill"
                                            InputProps={{
                                                ...params.InputProps
                                            }}
                                            InputLabelProps={{
                                                ...params.InputLabelProps
                                            }}
                                            fullWidth
                                        />
                                    )}
                                    onChange={handleSelectSkill}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                if (
                                                    !requestEndDate ||
                                                    !requestStartDate
                                                ) {
                                                    setRequestStartDate(
                                                        new Date(
                                                            new Date().getFullYear(),
                                                            0,
                                                            1
                                                        )
                                                    );
                                                    setRequestEndDate(
                                                        moment()
                                                            .add(1, "M")
                                                            .toDate()
                                                    );
                                                }
                                                setShowRequestDateRange(true);
                                            }}
                                            startIcon={<DateRangeIcon />}
                                        >
                                            {requestStartDate && requestEndDate
                                                ? `Request Date Range: ${moment(
                                                      requestStartDate
                                                  ).format("MMM DD YYYY")}
                                to 
                                ${moment(requestEndDate).format("MMM DD YYYY")}`
                                                : "Request Date Range: Any Date"}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setShowTodDialog(true);
                                            }}
                                        >
                                            Time of Day
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={2} align="right">
                            {exportReadySales && exportReadySales.length > 0 ? (
                                <CSVLink
                                    data={exportReadySales}
                                    headers={csvHeaders}
                                    filename={`PotentialSales_${moment(
                                        Date.now()
                                    ).format("YYYY-MM-DD")}.csv`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetAppIcon />}
                                    >
                                        Export CSV
                                    </Button>
                                </CSVLink>
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {<PotentialSalesTable rows={rows} />}
                        {/*searchComplete && <PotentialSalesTable rows={rows} />*/}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
export default PotentialSalesForm;
