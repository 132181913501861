import { Cache } from "aws-amplify/utils";
// prettier-ignore
import { blue, brown, cyan, orange, purple, pink, red, teal, yellow } from "@mui/material/colors";

export function getPrimaryColor() {
    const primarycolor = Cache.getItem("primarycolor");
    if (primarycolor) {
        switch (primarycolor) {
            case "primary":
                return null;
            case "blue":
                return blue;
            case "brown":
                return brown;
            case "cyan":
                return cyan;
            case "orange":
                return orange;
            case "pink":
                return pink;
            case "purple":
                return purple;
            case "red":
                return red;
            case "teal":
                return teal;
            case "yellow":
                return yellow;
            default:
                return purple;
        }
    } else {
        return null;
    }
}
