import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import {
    Snackbar,
    TextField,
    InputAdornment,
    Grid,
    Tabs,
    Tab,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Button,
    Tooltip
} from "@mui/material";
import { CustomTooltip } from "../styles/CustomMuiStyles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import EditIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    subscribe
} from "../modules/AmplifyServices";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../styles/MainContainerStyles";
import { serviceStyles } from "../styles/ServiceFormStyles";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import CurrencyFormat from "react-currency-format";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { logSystemError } from "../modules/SystemErrorService";
import { Spinner } from "../utils/CommonComponents/Spinner";
import { getCurrencySymbol } from "../utils";
import { Autocomplete } from "@mui/material";
import { auditServiceDelete } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { serviceTypeCategoryByCompany } from "../queries/CustomQueries";
import { getUserFromCache } from "../user/UserCommon";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";

import { useNavigate, useLocation, Outlet } from "react-router-dom";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};
function ServiceForm(props) {
    const navigate = useNavigate();

    const { state, dispatch, actions } = useContext(StoreContext);

    const classes = useStyles();
    const serviceClasses = serviceStyles();
    const [statusFilter, setStatusFilter] = useState("Enabled");
    const inputLabel = React.useRef(null);
    const [serviceTypeList, setServiceTypeList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("servicesTable")
    );
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");
    const [searchService, setSearchService] = React.useState("");
    const [searchCategoryList, setSearchCategoryList] = React.useState([]);
    const [serviceCategoriesList, setServiceCategoriesList] = React.useState(
        []
    );
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const logger = new ConsoleLogger("ServiceForm");
    const [user, setUser] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const limit = process.env.REACT_APP_LISTLIMIT;

    //Add Service Button Disabling State
    const [activeBtn, setActiveBtn] = useState(true);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("servicesTable")) {
            setTableRowsSession("servicesTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const handleStatusChange = (event, status) => {
        if (status != null) {
            console.log("handleStatusChange", status);
            setStatusFilter(status);
        }
    };

    async function refreshServices() {
        // refresh services
        console.log("REFRESHING");
        const authuser = getUserFromCache();
        const result = await graphql(
            graphqlOperation(queries.serviceTypeByCompany, {
                companyId: authuser.company.id,
                limit: process.env.REACT_APP_LISTLIMIT,
                filter: {
                    and: [
                        { deleted: { ne: true } },
                        { isVisible: { ne: false } }
                    ]
                }
            })
        );
        setServiceTypeList(result.data.serviceTypeByCompany.items);
        console.log("Services List", result.data.serviceTypeByCompany.items);
    }

    useEffect(() => {
        // store user
        setUser(getUserFromCache());
        const serviceTypeSub = subscribe(
            subscriptions.onCreateServiceType,
            refreshServices
        );
        const serviceTypeUpdateSub = subscribe(
            subscriptions.onUpdateServiceType,
            refreshServices
        );

        const loadServiceTypes = async () => {
            // get user data from cache
            _getServiceType();
            _getCategoryType();
        };

        loadServiceTypes();

        const cleanup = () => {
            // Stop receiving data updates from the subscription
            serviceTypeSub.unsubscribe();
            serviceTypeUpdateSub.unsubscribe();
        };
        return cleanup;
    }, []);

    useEffect(() => {
        _getServiceType(searchService, searchCategoryList);
    }, [searchService, searchCategoryList]);

    const _getServiceType = async (searchService, searchCategoryList) => {
        const authuser = getUserFromCache();
        const result = await graphql(
            graphqlOperation(queries.serviceTypeByCompany, {
                companyId: authuser.company.id,
                filter: { deleted: { ne: true }, isVisible: { ne: false } },
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );
        console.log("_getServiceType result = " + JSON.stringify(result));

        //transforming result into the json abject, you can uncomment the commented line below to check

        const m_result = JSON.parse(JSON.stringify(result));
        // return value
        let serviceList;
        // check to see if any items exist in the query
        if (!!result.data.serviceTypeByCompany.items) {
            // user has inputted a category filter but not a service filter
            if (
                searchCategoryList &&
                searchCategoryList.length > 0 &&
                !searchService
            ) {
                // go through every item in the services array, and check to see if its category
                // matches any one of the categories the user has inputted
                serviceList = result.data.serviceTypeByCompany.items.filter(
                    (item) => {
                        return searchCategoryList.some((element) => {
                            if (element == item.categoryName) return true;
                            else return false;
                        });
                    }
                );
            }
            // user has inputted a service filter but not a category filter
            else if (
                searchService &&
                (!searchCategoryList || searchCategoryList.length == 0)
            ) {
                serviceList = result.data.serviceTypeByCompany.items.filter(
                    (item) => {
                        return (
                            item.name &&
                            item.name
                                .toString()
                                .toLowerCase()
                                .includes(searchService.toLowerCase())
                        );
                    }
                );
            }
            // user has inputted both service and a category filter
            else if (
                searchCategoryList &&
                searchService &&
                searchCategoryList.length != 0
            ) {
                serviceList = result.data.serviceTypeByCompany.items.filter(
                    (item) => {
                        return (
                            item.name &&
                            item.name
                                .toString()
                                .toLowerCase()
                                .includes(searchService.toLowerCase()) &&
                            searchCategoryList.some((element) => {
                                if (element == item.categoryName) return true;
                                else return false;
                            })
                        );
                    }
                );
            } else {
                serviceList = result.data.serviceTypeByCompany.items;
            }
            console.log("Services List ln 331", serviceList);
            setServiceTypeList(serviceList);
        } else {
            setServiceTypeList([]);
        }
    };

    const _getCategoryType = async () => {
        const authuser = getUserFromCache();
        const categoryResult = await execReadByPK({
            opname: "serviceTypeCategoryByCompany",
            op: serviceTypeCategoryByCompany,
            id: { companyId: authuser.company.id },
            filter: {
                deleted: { ne: true }
            },
            sortDirection: "DESC"
        });

        if (!!categoryResult && !!categoryResult.items) {
            setServiceCategoriesList(
                categoryResult.items.map((item) => item.name)
            );
            if (categoryResult.items.length) setActiveBtn(false);
        } else setServiceCategoriesList([]);
    };
    function handleServiceAddClick() {
        actions.setMode("Add");
        navigate("create");
    }
    function handleEditClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("Edit");
        navigate("edit");
        actions.setId(rowId);
    }
    function handleViewClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("View");
        actions.setPage("ServiceAdd");
        actions.setId(rowId);
    }

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    function _handleDeleteDialogAccept() {
        selected.map((item) => {
            deleteService(item);
        });

        setSnackMsg("The selected service(s) have been successfully deleted.");
        setMsgOpen(true);
        setDeleteDialog(false);
        // clear table... cannot do refresh right away or we will get stale data ()
        const empty = [];
        setSelected(empty);
    }

    function deleteService(serviceId) {
        async function deleteGraphQLServices(serviceId) {
            const loggedInUser = getUserFromCache();
            const service = serviceTypeList.filter((s) => s.id === serviceId);
            if (!service || service.length === 0) {
                setSnackMsg("We are not able to find this service.");
                setMsgOpen(true);
            } else {
                const input = {
                    id: service[0].id,
                    deleted: true,
                    categoryName: service[0].categoryName,
                    name: service[0].name
                };

                const updateService = await graphql(
                    graphqlOperation(mutations.updateServiceType, { input })
                );

                await auditServiceDelete(
                    loggedInUser,
                    updateService.data.updateServiceType
                );

                if (
                    updateService &&
                    updateService.data &&
                    updateService.data.updateServiceType
                ) {
                    _getServiceType();
                }
            }
        }
        deleteGraphQLServices(serviceId);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = serviceTypeList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }

    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    const handleServiceSearch = ({ target: { value } }) => {
        setSearchService(value);
    };
    const handleCategorySearch = (e, value) => {
        setSearchCategoryList(value.map((item) => item));
    };

    const fileInput = React.createRef();

    const handleReadCSV = async (res) => {
        setShowLoading(true);
        try {
            logger.debug(res);
            let filter, result, categoryId, resultCategory;
            if (!!res) {
                let services = [];
                let row = "";
                for (let i = 1; i < res.data.length; i++) {
                    if (res.data[0][6] === "category") {
                        // find category id from desc if it exists
                        let categoryName = res.data[i][6];
                        filter = {
                            and: [
                                { companyId: { eq: user.company.id } },
                                { name: { eq: categoryName } }
                            ]
                        };

                        resultCategory = await graphql(
                            graphqlOperation(queries.listServiceTypeCategorys, {
                                filter,
                                limit
                            })
                        );
                        if (
                            resultCategory.data.listServiceTypeCategorys &&
                            resultCategory.data.listServiceTypeCategorys.items
                                .length > 0
                        ) {
                            categoryId =
                                resultCategory.data.listServiceTypeCategorys
                                    .items[0].id;
                        } else {
                            // add category
                            let categoryinput = {
                                name: categoryName,
                                desc: categoryName,
                                serviceTypeCategoryCompanyId: user.company.id,
                                companyId: user.company.id
                            };

                            result = await graphql(
                                graphqlOperation(
                                    mutations.createServiceTypeCategory,
                                    {
                                        input: categoryinput
                                    }
                                )
                            );
                            categoryId =
                                result.data.createServiceTypeCategory.id;
                        }
                        services = services.concat({
                            [res.data[0][0]]: res.data[i][0],
                            [res.data[0][1]]: res.data[i][1],
                            [res.data[0][2]]: res.data[i][2],
                            [res.data[0][3]]: res.data[i][3],
                            [res.data[0][4]]: res.data[i][4],
                            [res.data[0][5]]: res.data[i][5],
                            companyId: user.company.id,
                            serviceTypeCompanyId: user.company.id,
                            serviceTypeCategoryId: categoryId,
                            categoryName
                        });
                    } else {
                        services = services.concat({
                            [res.data[0][0]]: res.data[i][0],
                            [res.data[0][1]]: res.data[i][1],
                            [res.data[0][2]]: res.data[i][2],
                            [res.data[0][3]]: res.data[i][3],
                            [res.data[0][4]]: res.data[i][4],
                            [res.data[0][5]]: res.data[i][5],
                            companyId: user.company.id,
                            serviceTypeCompanyId: user.company.id
                        });
                    }
                }
                await services.map(async (input) => {
                    const data = await graphql(
                        graphqlOperation(mutations.createServiceType, { input })
                    );
                    if (!!data && !!data.createServiceType) {
                        res = res.concat(data);
                    }
                });
                // check if csv number of rows (less one for header) equals inputs processed
                if (res.data.length - 1 === services.length) {
                    setSnackMsg(`${services.length} services imported`);
                    setMsgOpen(true);
                    refreshServices();
                } else if (res.length > 0) {
                    setSnackMsg(
                        `${res.length} services imported and ${
                            services.length - res.length
                        } left.`
                    );
                    setMsgOpen(true);
                    refreshServices();
                } else {
                    setSnackMsg(`Unable to import services.`);
                    setMsgOpen(true);
                }
            }
        } catch (err) {
            logger.error(
                "*** an error occurred. The error was: " + err.message
            );
            const errorresult = await logSystemError(
                user.company.id,
                "ERROR",
                "Error importing services on ServiceForm.",
                0,
                err
            );
            logger.debug(errorresult);
            setSnackMsg(
                "Unable to import services. The error was: " + err.message
            );
            setMsgOpen(true);
            return;
        }
        setShowLoading(false);
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    const handleImportOffer = () => {
        fileInput.current.click();
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            {showLoading && <Spinner />}    
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DeleteDialog
                        title="services"
                        open={deleteDialog}
                        onDecline={_handleDeleteDialogDecline}
                        onConfirm={_handleDeleteDialogAccept}
                    />

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sx={{ marginTop: "22px" }}>
                            <ToggleButtonGroup
                                size="small"
                                value={statusFilter}
                                exclusive
                                onChange={handleStatusChange}
                            >
                                <ToggleButton key={1} value="Enabled">
                                    Active
                                </ToggleButton>
                                <ToggleButton key={2} value="Disabled">
                                    Inactive
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                loading={props.loading}
                                loadingText="Loading..."
                                noOptionsText="No Categories Found"
                                options={serviceCategoriesList}
                                className={classes.formControl}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        {...params}
                                        label="Filter by Category"
                                        InputProps={{
                                            ...params.InputProps
                                        }}
                                        InputLabelProps={{
                                            ...params.InputLabelProps
                                        }}
                                        fullWidth
                                    />
                                )}
                                onChange={handleCategorySearch}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomTooltip
                                title={
                                    activeBtn ? "Please create a category" : ""
                                }
                                placement="top"
                            >
                                <span>
                                    {/*
                                    1.button element has to be wrapped with span in order to fire tooltip
                                    2. added margin to buttons so they dont squish together and remove the space "&nbsp"
                                */}
                                    <Button
                                        disabled={activeBtn}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleServiceAddClick}
                                        style={{ margin: "0.2rem" }}
                                    >
                                        Add Services
                                    </Button>
                                </span>
                            </CustomTooltip>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textAlign: "right",
                                    margin: "0.2rem"
                                }}
                                onClick={() => {
                                    navigate("categories")
                                    actions.setMode("Edit");
                                }}
                            >
                                Add Categories
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textAlign: "right",
                                    margin: "0.2rem"
                                }}
                                onClick={() => {
                                    navigate("order");
                                    actions.setMode("Edit");
                                }}
                            >
                                View Display Order
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="search"
                                label="Search by Service name"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                onChange={handleServiceSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <Table
                                size="small"
                                className={classes.table}
                                style={{ overflowX: "auto" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectAll}
                                                onClick={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Name</TableCell>
                                        {/*<TableCell align='left'>Type</TableCell>*/}
                                        <TableCell align="left">
                                            Service Behavior
                                        </TableCell>
                                        <TableCell align="center">
                                            Length (Minutes)
                                        </TableCell>
                                        <TableCell align="center">
                                            Tax Exempt
                                        </TableCell>
                                        <TableCell align="right">
                                            Price
                                        </TableCell>
                                        <TableCell align="center">
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {serviceTypeList
                                        .filter(
                                            (row) =>
                                                !!row.active ==
                                                (statusFilter === "Enabled")
                                        )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    key={row.name}
                                                    hover
                                                    onClick={(event) =>
                                                        handleRowClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    row.id
                                                                ) !== -1
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell scope="row">
                                                        {row.category
                                                            ? row.category.name
                                                            : ""}
                                                    </TableCell>
                                                    <TableCell scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    {/*<TableCell align='left'>{row.servicetype}</TableCell>*/}
                                                    <TableCell align="left">
                                                        {!!row.behavior
                                                            ? /* row.behavior
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            row.behavior
                                                                .slice(1)
                                                                .toLowerCase() */
                                                            row.behavior ===
                                                            "EXTERNAL"
                                                                ? "Public"
                                                                : row.behavior ===
                                                                    "INTERNAL"
                                                                ? "Private"
                                                                : row.behavior ===
                                                                    "MANUAL"
                                                                    ? "Contact us form"
                                                                    : "Public"
                                                            : "External"}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.minutes}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.taxexempt
                                                            ? "Yes"
                                                            : "No"}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <CurrencyFormat
                                                            value={row.price}
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={getCurrencySymbol()}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title="Edit">
                                                            <EditIcon
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleEditClick(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={6}
                                            count={
                                                serviceTypeList.filter(
                                                    (row) =>
                                                        !!row.active ==
                                                        (statusFilter ===
                                                            "Enabled")
                                                ).length
                                            }
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label":
                                                        "Rows per page"
                                                },
                                                native: true
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={
                                                handleChangeRowsPerPage
                                            }
                                            ActionsComponent={
                                                TablePaginationActions
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ServiceForm;
