import _ from "lodash";
// Helper function to check date range overlap
const isDateRangeOverlap = (start1, end1, start2, end2) => {
    return start1 <= end2 && end1 >= start2;
};
// Helper function to check time range overlap
const isTimeRangeOverlap = (start1, end1, start2, end2) => {
    return start1 < end2 && end1 > start2;
};
// Helper function to generate occurrences for the next 2 months
export const generateOccurrences = (
    startDate,
    endDate,
    startTime,
    endTime,
    weeksToRepeat,
    days
) => {
    let occurrences = [];
    let currentDate = new Date(startDate);
    let endDateLimit = new Date(endDate);
    // Set the endDateLimit to be 12 months from the startDate if it exceeds the endDate
    const maxEndDateLimit = new Date(startDate);
    maxEndDateLimit.setMonth(maxEndDateLimit.getMonth() + 12);
    endDateLimit =
        endDateLimit > maxEndDateLimit ? maxEndDateLimit : endDateLimit;
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);
    while (currentDate <= endDateLimit) {
        const currentDay = (currentDate.getDay() + 6) % 7; //Adjust to match Monday = 0, Sunday = 6
        // Check if the current day is valid
        if (days.includes(currentDay)) {
            const occurrenceStart = new Date(currentDate);
            occurrenceStart.setHours(startHours, startMinutes);
            const occurrenceEnd = new Date(currentDate);
            occurrenceEnd.setHours(endHours, endMinutes);
            // Add occurrence if it is within the endDate
            if (occurrenceEnd <= endDateLimit) {
                occurrences.push([occurrenceStart, occurrenceEnd]);
            }
        }
        currentDate.setDate(currentDate.getDate() + 1);
        if (weeksToRepeat > 1 && currentDate.getDay() === 0) {
            currentDate.setDate(
                currentDate.getDate() + 7 * (weeksToRepeat - 1)
            );
        }
    }
    return occurrences;
};
/**
 * Checks if a new schedule overlaps with any existing schedules.
 *
 * The function verifies if the provided new schedule:
 * 1. Overlaps in date range with any existing schedule.
 * 2. Overlaps in time range with any existing schedule.
 * 3. Matches the days of the week and weeks to repeat with any existing schedule.
 * 4. Determines if there is a true overlap by generating occurrences for both new and existing schedules
 *    and checking if any of them match within the next 2 months.
 * 5. Overlaps in services with any existing schedule.
 *
 * @param {string} newStartDate - The start date of the new schedule in "YYYY-MM-DD" format.
 * @param {string} newEndDate - The end date of the new schedule in "YYYY-MM-DD" format.
 * @param {string} newStartTime - The start time of the new schedule in "HH:MM" format.
 * @param {string} newEndTime - The end time of the new schedule in "HH:MM" format.
 * @param {number} weeksToRepeat - The interval in weeks at which the new schedule repeats.
 * @param {number[]} days - An array of integers representing the days of the week (0 for Monday, 6 for Sunday)
 *                           that the new schedule is active.
 * @param {string[]} newServices An array of serviceIds representing the services selected for the new Schedule
 * @param {Array} providerSchedules - An array of existing schedules
 *
 * @returns {boolean} - Returns `true` if there is an overlap between the new and existing schedules;
 *                      otherwise, returns `false`.
 */
export function checkScheduleOverlap(
    newStartDate,
    newEndDate,
    newStartTime,
    newEndTime,
    weeksToRepeat,
    days,
    newServices,
    providerSchedules
) {
    const [newStartYear, newStartMonth, newStartDay] = newStartDate
        .split("-")
        .map(Number);
    const [newEndYear, newEndMonth, newEndDay] = newEndDate
        .split("-")
        .map(Number);
    const [newStartHours, newStartMinutes] = newStartTime
        .split(":")
        .map(Number);
    const [newEndHours, newEndMinutes] = newEndTime.split(":").map(Number);
    const newStartDateTime = new Date(
        newStartYear,
        newStartMonth - 1,
        newStartDay,
        newStartHours,
        newStartMinutes
    );
    const newEndDateTime = new Date(
        newEndYear,
        newEndMonth - 1,
        newEndDay,
        newEndHours,
        newEndMinutes
    );
    // Generate occurrences for the new schedule
    const newOccurrences = generateOccurrences(
        newStartDateTime,
        newEndDateTime,
        newStartTime,
        newEndTime,
        weeksToRepeat,
        days
    );
    let overlapDetails = [];
    for (const sc of providerSchedules) {
        for (const sb of sc.SB) {
            const [sbStartYear, sbStartMonth, sbStartDay] = sb.startDate
                .split("-")
                .map(Number);
            const [sbEndYear, sbEndMonth, sbEndDay] = sb.endDate
                .split("-")
                .map(Number);
            const [sbStartHours, sbStartMinutes] = sb.startTime
                .split(":")
                .map(Number);
            const [sbEndHours, sbEndMinutes] = sb.endTime
                .split(":")
                .map(Number);
            const sbStartDateTime = new Date(
                sbStartYear,
                sbStartMonth - 1,
                sbStartDay,
                sbStartHours,
                sbStartMinutes
            );
            const sbEndDateTime = new Date(
                sbEndYear,
                sbEndMonth - 1,
                sbEndDay,
                sbEndHours,
                sbEndMinutes
            );
            const servicesOverlap = newServices.some((newServiceId) =>
                JSON.parse(sb.services).includes(newServiceId)
            );
            // Check date range overlap
            if (
                isDateRangeOverlap(
                    newStartDateTime,
                    newEndDateTime,
                    sbStartDateTime,
                    sbEndDateTime
                )
            ) {
                overlapDetails.push();
                // Check time range overlap
                if (
                    isTimeRangeOverlap(
                        newStartDateTime,
                        newEndDateTime,
                        sbStartDateTime,
                        sbEndDateTime
                    )
                ) {
                    const sbDays = sb.weekDays.split("").map(Number);
                    const sbWeeksToRepeat = sb.weeksToRepeat;
                    if (_.intersection(days, sbDays).length > 0) {
                        //Generate occurrences for the SB (existing schedule)
                        const sbOccurrences = generateOccurrences(
                            sbStartDateTime,
                            sbEndDateTime,
                            sb.startTime,
                            sb.endTime,
                            sbWeeksToRepeat,
                            sbDays
                        );
                        //Check if any occurrences overlap
                        for (const newOccurrence of newOccurrences) {
                            for (const sbOccurrence of sbOccurrences) {
                                if (
                                    isDateRangeOverlap(
                                        newOccurrence[0],
                                        newOccurrence[1],
                                        sbOccurrence[0],
                                        sbOccurrence[1]
                                    )
                                ) {
                                    if (servicesOverlap) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return false;
}

/**
 * Function to check if any of the timeBlock startTime-endTime ranges overlap
 * @param {*} timeBlocks
 * @returns true if overlap found - false otherwise
 */
export const checkTimeBlockOverlap = (timeBlocks) => {
    for (let i = 0; i < timeBlocks.length; i++) {
        for (let j = i + 1; j < timeBlocks.length; j++) {
            const block1 = timeBlocks[i];
            const block2 = timeBlocks[j];

            // Convert startTime and endTime to Date objects for comparison
            const block1Start = new Date(`1970-01-01T${block1.startTime}:00Z`);
            const block1End = new Date(`1970-01-01T${block1.endTime}:00Z`);
            const block2Start = new Date(`1970-01-01T${block2.startTime}:00Z`);
            const block2End = new Date(`1970-01-01T${block2.endTime}:00Z`);

            // Check if the time blocks overlap
            if (block1Start < block2End && block1End > block2Start) {
                return true; // Overlap found
            }
        }
    }
    return false; // No overlap found
};
