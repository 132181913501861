import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import SearchIcon from "@mui/icons-material/Search";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import clsx from "clsx";
// prettier-ignore
import { Grid, Tabs, Tab, Typography, TextField, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button, Box, Alert } from "@mui/material";
// prettier-ignore
import { Popover, CircularProgress, Checkbox, FormControlLabel, RadioGroup, Radio, Switch } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    postApi,
    putApi,
    getJsonApi
} from "../modules/AmplifyServices";
import { Cache } from "aws-amplify/utils";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { BookingListTable } from "../components/BookingListTable";
import { BookingReqListTable } from "../components/BookingReqListTable";
import CustomerInfoDialog from "../components/CustomerInfoDialog";
import { UserLookup } from "../components/UserLookup";
import { ProviderLookup } from "../components/ProviderLookup";
import { LocationSelector } from "../components/LocationSelector";
import { ClientAddressInput } from "../components/ClientAddressInput";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClearIcon from "@mui/icons-material/Clear";
import { CustomTooltip, AmountInputField } from "../styles/CustomMuiStyles";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import { getUserFromCache, userHasProviderOnlyRole } from "../user/UserCommon";
import {
    cancelBooking,
    saveNoshowBooking,
    getBookingTime,
    getTimeblockEndTime,
    getBookingReqDetails,
    handleCancelNoRefund
} from "../modules/BookingService";
import {
    getCompanyAdmins,
    USERROLE,
    userHasClientRole,
    userHasAdminRole,
    userHasProviderRole
} from "../user/UserCommon";
import { providersForOverride } from "../queries/ProviderFormQueries";
import {
    bookingByCompany,
    clientByUserId,
    bookingByClientFilter,
    bookingByProviderFilter
} from "../queries/ListBookingsQueries";
import * as mutations from "../graphql/mutations";
import { sendSms, getShort } from "../modules/MessagingService";
import { chargeCreditCard } from "../billing/BillingCommon";
import { logSystemError } from "../modules/SystemErrorService";
import { isBlockedTime } from "../modules/CheckAvailability";
import { SmallSpinner } from "../utils/CommonComponents/Spinner";
import * as queries from "../graphql/queries";
import * as scheduleQueries from "../modules/ScheduleQueries";
import {
    execReadBySortkey,
    execReadBySortkeyFromToken
} from "../modules/DBService";
import DateRangePicker from "../utils/CommonComonents/DateRangePicker";
import ExportDateRangePicker from "../utils/CommonComonents/ExportDateRangePicker";
import { CSVLink } from "react-csv";
import withStyles from "@mui/styles/withStyles";
import Backdrop from "@mui/material/Backdrop";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
    createTimeblock,
    createProviderScheduleBK,
    _getSchedules,
    SCHEDPKSKSPLITAT
} from "../modules/ScheduleService";
import { getBookingDateDescription } from "../modules/TimeService";
import { useWindowSize } from "react-use";
import * as Sentry from "@sentry/react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { utcToZonedTime, format } from "date-fns-tz";
import { getFullDateDisplayWithGMTOffset } from "../modules/TimeService";
import { getStripeAmount } from "../modules/AdhocBookingService";
import { execReadByPK } from "../modules/DBService";
import {
    auditAcceptBooking,
    auditDeclineBooking,
    auditBookingAddChrg,
    auditBookingState,
    auditOverrideBooking
} from "../modules/Audit";
import { getUserRole } from "../user/UserCommon";
//import DatePicker from "@mui/lab/DatePicker";
//import TimePicker from "@mui/lab/TimePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { checkAvailabilityForOverride } from "../modules/CheckAvailability";
import BookingNotesDialog from "../components/BookingNotesDialog";
import RecurringBookingSelector from "../components/RecurringBookingSelector";
import { generateRRULE } from "../modules/RecurringBookingsUtilityFunctions";
import { mbxUserAtom } from "../atoms/atoms";
import { useAtom } from "jotai";
import {
    extractLocationIdFromScheduleinfo,
    getOrCreateProviderLocation
} from "../utils/providerLocationUtils";
import {
    getProviderLocation,
    getCompanyLocation
} from "../modules/ReschedulingFunctions";
import { getTimezoneIdByGeoLoc } from "../modules/TimeService";

const autocompleteService = { current: null };
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function removeDuplicatesFromArray(arr) {
    const uniqueArray = [];

    arr.forEach((obj) => {
        const value = obj["id"];
        const exists = uniqueArray.some(
            (uniqueObj) => uniqueObj["id"] === value
        );

        if (!exists) {
            uniqueArray.push(obj);
        }
    });

    return uniqueArray;
}

const BRDetailsDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, backgroundColor, ...other } = props;
    return (
        <DialogTitle
            className={classes.root}
            {...other}
            style={{
                backgroundColor: backgroundColor,
                color: "#fff"
            }}
        >
            <Typography variant="h6" align="center">
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    style={{ color: "#fff" }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

function TabContainer(props) {
    return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const LIMIT_COUNT = 50;

export const BookingsListForm = (props) => {
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);
    const [bookingStatusChange, setBookingStatusChange] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [bookingStatus, setBookingStatus] = useState({
        arrived: false,
        cancelled: false,
        completed: false,
        scheduled: false
    });
    const { actions, state } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [futureRows, setFutureRows] = useState([]);
    const [pastRows, setPastRows] = useState([]);
    const [exportFutureRows, setExportFutureRows] = useState([]);
    const [exportPastRows, setExportPastRows] = useState([]);
    const [exportDateFilteredRows, setExportDateFilteredRows] = useState([]);
    const [filteredFutureRows, setFilteredFutureRows] = useState([]);
    const [filteredPastRows, setFilteredPastRows] = useState([]);
    const [filteredFutureRowsExports, setFilteredFutureRowsExports] = useState(
        []
    );
    const [filteredPastRowsExports, setFilteredPastRowsExports] = useState([]);
    const [bookingReqRows, setBookingReqRows] = useState([]);
    const [filteredBookingReqRows, setFilteredBookingReqRows] = useState([]);
    const [nonpendingBookingReqRows, setNonpendingBookingReqRows] = useState(
        []
    );
    const [
        filteredNonpendingBookingReqRows,
        setFilteredNonpendingBookingReqRows
    ] = useState([]);
    const [role, setRole] = useState();
    const { width } = useWindowSize();
    const [isMobile, setIsMobile] = useState(false);

    const [dateFilter, setDateFilter] = useState(false);
    const [prevTab, setPrevTab] = useState(0);
    const [displayRefundedDialogue, setDisplayRefundedDialogue] =
        useState(false);
    const [refundedContinueClicked, setRefundedContinueClicked] =
        useState(false);
    const [refundedCancelClicked, setRefundedCancelClicked] = useState(false);
    const [cancelOnlySelectedBooking, setCancelOnlySelectedBooking] =
        useState();
    const [displayPartialRefundDialog, setDisplayPartialRefundDialog] =
        useState(false);
    const [remainingRefund, setRemainingRefund] = useState();
    const [partialRefundLoading, setPartialRefundLoading] = useState(false);

    //client dialog state
    const [clientId, setClientId] = useState("");
    const [clientContactConsent, setClientContactConsent] = useState(false);
    const [clientUserId, setClientUserId] = useState();
    const [clientUserRole, setClientUserRole] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientFirstName, setClientFirstName] = useState("");
    const [clientLastName, setClientLastName] = useState("");
    const [clientEmailAddress, setClientEmailAddress] = useState("");
    const [clientMobilePhone, setClientMobilePhone] = useState("");
    const [clientNotes, setClientNotes] = useState();
    const [showClientInfoDialog, setShowClientInfoDialog] = useState(false);

    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //Selected rows
    const [selected, setSelected] = useState([]);
    const [selectedReq, setSelectedReq] = useState([]);

    const [tab, setTab] = useState(state.bookingsTab ? state.bookingsTab : 0);
    const [comapnyAdmins, setCompanyAdmins] = useState();
    const [bookingData, setBookingdata] = useState();
    const [bookingReqData, setBookingReqData] = useState();

    const [cancelModal, setCancelModal] = useState(false);
    const [cancelBookingReqModal, setCancelBookingReqModal] = useState(false);
    const [acceptedModal, setAcceptedModal] = useState(false);
    const [declinedModal, setDeclinedModal] = useState(false);

    const [arrivedModal, setArrivedModal] = useState(false);
    const [completedModal, setCompletedModal] = useState(false);
    const [addChargeModal, setAddChargeModal] = useState(false);
    const [noShowModal, setNoShowModal] = useState(false);
    const [overrideModal, setOverrideModal] = useState(false);
    const [clientFilter, setClientFilter] = useState([]);
    const [providerFilter, setProviderFilter] = useState([]);
    const [desc, setDesc] = useState();
    const [amount, setAmount] = useState();
    const [note, setNote] = useState();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [serviceTypeOption, setServiceTypeOption] = useState(0);
    const [serviceTypeData, setServiceTypeData] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showBookingReqSpinner, setShowBookingReqSpinner] = useState(false);
    const [showCancelBookingSpinner, setShowCancelBookingSpinner] =
        useState(false);
    const [showOverrideBookingSpinner, setShowOverrideBookingSpinner] =
        useState(false);
    const [providersListloading, setProvidersListloading] = useState(false);

    const [comlpetedClicked, setCompletedClicked] = useState(false);
    const [bookingReqActionClicked, setBookingReqActionClicked] =
        useState(false);
    const [cancelBookingClicked, setCancelBookingClicked] = useState(false);
    const [overrideBookingClicked, setOverrideBookingClicked] = useState(false);
    const [overrideStatusClicked, setOverrideStatusClicked] = useState(false);
    const [cancelRepeating, setCancelRepeating] = useState(false);
    const [repeatingTimeoutExceeded, setRepeatingTimeoutExceeded] =
        useState(false);
    const [dateRangeChange, setDateRangeChange] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [selectedFromDate, setSelectFromDate] = useState(new Date());
    const [selectedToDate, setSelectToDate] = useState(new Date());
    const [addChgLoading, setAddChgLoading] = useState(false);
    const [minDate] = useState(new Date(new Date().getTime() - 31536000000));
    const [maxDate] = useState(new Date(new Date().getTime() + 31536000000));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [providers, setProviders] = useState([]);
    const [selectProvider, setSelectProvider] = useState(null);
    const [providerSchedules, setProviderSchedules] = useState([]);
    const [providerScheduleId, setProviderScheduleId] = useState();
    const [user, setUser] = useState({});
    const [importDialog, setImportDialog] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [featureDisabled, setShowFeatureDisabled] = useState(false);
    const [showYesCancelLoading, setShowYesCancelLoading] = useState(false);
    const [showWaiveCancelLoading, setShowWaiveCancelLoading] = useState(false);
    const [unavailableTimeBlocks, setUnavailableTimeBlocks] = useState([]);
    const [errorType, setErrorType] = useState("error");
    const [errorMessage, setErrorMessage] = useState("");

    // query
    const [token, setToken] = useState(null);
    const [futureToken, setFutureToken] = useState(null);
    const [pastToken, setPastToken] = useState(null);
    const [nonPendingToken, setNonPendingToken] = useState(null);
    const [futureSearchToken, setFutureSearchToken] = useState(null);
    const [pastSearchToken, setPastSearchToken] = useState(null);
    const [nonPendingSearchToken, setnonPendingSearchToken] = useState(null);
    const [futureSearchCache, setFutureSearchCache] = useState([]);
    const [futureSearchCacheExports, setFutureSearchCacheExports] = useState(
        []
    );
    const [pastSearchCache, setPastSearchCache] = useState([]);
    const [futureCache, setFutureCache] = useState([]);
    const [pastCache, setPastCache] = useState([]);
    const [searching, setSearching] = useState(false);
    const [futureEndList, setFutureEndList] = useState(false);
    const [pastEndList, setPastEndList] = useState(false);
    const [nonPendingEndList, setNonPendingEndList] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [bookingReqDataForDetails, setBookingReqDataForDetails] = useState();
    const [brLogs, setBRLogs] = useState([]);
    const [exportReadyBookings, setExportReadyBookings] = useState();
    const [hideRatingsFromClients, setHideRatingsFromClients] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [paymentFailedMessage, setPaymentFailedMessage] = useState("");
    const [clientIds, setClientIds] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedProvider, setSelectedProvider] = useState();
    const [showExport, setShowExport] = useState(false);
    const [preparingExportData, setPreparingExportData] = useState(false);
    const [showExportSpinner, setShowExportSpinner] = useState(false);
    const [reloadByTab, setReloadByTab] = useState(false);
    const [updateResults, setUpdateResults] = useState(false);
    const [exportFromDate, setExportFromDate] = useState(new Date());
    const [checkAvailabilityFails, setCheckAvailabilityFails] = useState(false);
    const [exportToDate, setExportToDate] = useState(
        new Date(Date.now() + 86400000 * 15)
    );
    const [repeatingCancelTimeoutId, setRepeatingCancelTimeoutId] = useState();
    const [refreshRows, setRefreshRows] = useState(false);
    const [showAddr, setShowAddr] = useState(false);
    const [locationChanged, setLocationChanged] = useState(false);
    const [location, setLocation] = useState();
    const [locId, setLocId] = useState("");
    const [place_id, setPlaceId] = useState();
    const [address, setAddress] = useState();

    //recurring booking override states
    const [rrule, setRrule] = useState(null);
    const [parsedRrule, setParsedRrule] = useState(null);
    const [originalRrule, setOriginalRrule] = useState(null);
    const [rruleList, setRruleList] = useState(null);
    const [rruleLabel, setRruleLabel] = useState(null);
    const [rruleChanged, setRruleChanged] = useState(false);
    const [xoccurChanged, setXoccurChanged] = useState(false);
    const [isRecurrenceShown, setIsRecurrenceShown] = useState(false);
    const [
        recurringBookingAllOrSelectedOverride,
        setRecurringBookingAllOrSelectedOverride
    ] = useState(false);

    const [providerChanged, setProviderChanged] = useState(false);
    const fileInput = React.createRef();
    const csvHeaders = [
        { label: "ID", key: "ID" },
        { label: "Order number", key: "ORDERNUMBER" },
        { label: "Date created", key: "DATECREATED" },
        { label: "Date updated", key: "DATEUPDATED" },
        { label: "Start date", key: "STARTDATE" },
        { label: "Service name", key: "SERVICENAME" },
        { label: "Duration", key: "DURATION" },
        { label: "Booking address", key: "BOOKINGADDRESS" },
        { label: "Booking state/province", key: "BOOKINGPROVSTATE" },
        { label: "Booking zip/postal code", key: "BOOKINGPOSTALCODE" },
        { label: "Booking country", key: "BOOKINGCOUNTRY" },
        { label: "Booked by", key: "ADMINEMAIL" },
        { label: "Status", key: "STATUS" },
        { label: "Cancelled by", key: "CANCELLEDBY" },
        { label: "Initial charge", key: "INITIALCHARGE" },
        { label: "Additional charge", key: "ADDITIONALCHARGE" },
        { label: "Provider tip", key: "PROVIDERTIP" },
        { label: "Booking rating", key: "RATING" },
        { label: "Booking notes", key: "NOTES" },
        { label: "Client name", key: "CLIENTNAME" },
        { label: "Client first name", key: "CLIENTFIRSTNAME" },
        { label: "Client last name", key: "CLIENTLASTNAME" },
        { label: "Client email", key: "CLIENTEMAIL" },
        { label: "Provider name", key: "PROVIDERNAME" },
        { label: "Provider first name", key: "PROVIDERFIRSTNAME" },
        { label: "Provider last name", key: "PROVIDERLASTNAME" },
        { label: "Provider email", key: "PROVIDEREMAIL" }
    ];

    //Note Dialog States
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [noteDialogCreatedBy, setNoteDialogCreatedBy] = useState("");
    const [noteDialogDateCreated, setNoteDialogDateCreated] = useState("");
    const [noteDialogContent, setNoteDialogContent] = useState("");
    const [rowBookingId, setRowBookingId] = useState("");
    const [noteClientName, setNoteClientName] = useState("");
    const [noteServiceName, setNoteServiceName] = useState("");
    const [noteBookingStartDate, setNoteBookingStartDate] = useState("");
    const [noteOrderNumber, setNoteOrderNumber] = useState("");
    const [showCancelledBookings, setShowCancelledBookings] = useState(false);

    const APP_ID_HERE = process.env.REACT_APP_GEOCODER_APP_ID;
    const APP_CODE_HERE = process.env.REACT_APP_GEOCODER_CODE;
    const limit = process.env.REACT_APP_LISTLIMIT;

    const logger = new ConsoleLogger("BookingListForm");

    //Search Values
    const [values, setValues] = useState({
        type: "",
        name: ""
    });

    // style for CSVReader component
    const parserStyle = {
        display: "none"
    };
    // config for CSVReader component
    const parserOptions = {
        skipEmptyLines: true,
        header: true
    };

    useEffect(() => {
        const authuser = getUserFromCache();
        setUser(authuser);
        if (width <= 600) {
            setIsMobile(true);
        }
        // setLabelWidth(inputLabel.current.offsetWidth);//commented because ServiceTypeOption dropdown for search is commented.
        async function fetchServiceTypes() {
            const result = await graphql(
                graphqlOperation(queries.serviceTypeByCompany, {
                    companyId: authuser.company.id,
                    limit: process.env.REACT_APP_LISTLIMIT,
                    filter: {
                        and: [
                            { deleted: { ne: true } },
                            { active: { ne: false } },
                            { isVisible: { ne: false } }
                        ]
                    }
                })
            );
            setServiceTypeData(result.data.serviceTypeByCompany.items);
        }
        fetchServiceTypes();
        _getFutureBookingsList(true);
        _getPastBookingsList(true);
    }, []);

    useEffect(() => {
        async function fetchFutureAndPast() {
            console.log("fetchnNoFilterFunction ran");
            await _getFutureBookingsList();
            await _getPastBookingsList();
            setRefreshRows(false);
            setShowLoading(false);
        }

        async function fetchFilteredFutureAndPast() {
            console.log("fetchFilterFunction ran");
            await _getFilteredFutureRows();
            await _getFilteredPastRows();
            // setSearching(false);
            setRefreshRows(false);
            setShowLoading(false);
        }
        if (reloadByTab) {
            if (tab === 0 || tab === 1) {
                fetchFutureAndPast();
            }
        }

        if (refreshRows) {
            console.log("Refresh Rows Trueee");
            if (!showLoading) {
                setShowLoading(true);
            }

            if (searching) {
                fetchFilteredFutureAndPast();
            } else {
                fetchFutureAndPast();
            }
        }

        /*  if (refreshRows) {
            console.log("Refresh Rows True");
            console.log(
                "Rows are refreshed now ",
                searching,
                filteredFutureRows,
                futureRows
            );
            if (!showLoading) {
                setShowLoading(true);
            }
            fetchFutureAndPast();
        } */
        setReloadByTab(false);
    }, [reloadByTab, refreshRows]);

    useEffect(() => {
        console.log("User Selected", selectedUser);
        console.log("Selected Provider", selectedProvider);
        if (!selectedUser && !selectedProvider) {
            if (searching) {
                setSearching(false);
            }
        }
    }, [selectedUser, selectedProvider]);

    useEffect(() => {
        if (updateResults) {
            handleSearchBookings();
            setUpdateResults(false);
        }
    }, [updateResults]);

    /* useEffect is called when the reschduleModal Dialogue opens and provider schedule is changed in override modal*/
    useEffect(() => {
        async function getUnavailableTimeblocks() {
            try {
                /* const providerScheduleId = makeProviderScheduled(
                    props.selectedRowData.timeblockid
                ); */
                const pksk =
                    !!providerScheduleId &&
                    providerScheduleId.split(SCHEDPKSKSPLITAT);
                const unavailableTimeblocksList = await execReadBySortkey({
                    opname: "listProviderSchedules",
                    op: scheduleQueries.listProviderSchedules,
                    id: { id: pksk[0] },
                    skey: { scheduleinfo: { beginsWith: "BUT" } },
                    filter: { active: { ne: false } },
                    limit: 500
                });
                /* console.log(
                    "this is the unavailable timeblocks from use effect",
                    unavailableTimeblocksList
                ); */
                setUnavailableTimeBlocks(unavailableTimeblocksList.items);
            } catch (e) {
                console.log("error in getting ref data", e);
            }
        }
        /* console.log("useEffect in effect omar"); */
        if (overrideModal && providerScheduleId) {
            getUnavailableTimeblocks();
        }
    }, [overrideModal, providerScheduleId]);

    // useEffect(() => {
    //     if (tab === 0) {
    //         if (dateRangeChange)
    //             setExportReadyBookings(
    //                 csvData({ items: exportDateFilteredRows })
    //             );
    //         else if (searching && !dateRangeChange)
    //             setExportReadyBookings(
    //                 csvData({ items: filteredFutureRowsExports })
    //             );
    //         else setExportReadyBookings(csvData({ items: exportFutureRows }));
    //     } else if (tab === 1) {
    //         if (dateRangeChange)
    //             setExportReadyBookings(
    //                 csvData({ items: exportDateFilteredRows })
    //             );
    //         else if (searching && !dateRangeChange)
    //             setExportReadyBookings(
    //                 csvData({ items: filteredPastRowsExports })
    //             );
    //         else setExportReadyBookings(csvData({ items: exportPastRows }));
    //     } else if (tab === 2) {
    //         if (searching || dateRangeChange)
    //             setExportReadyBookings(
    //                 csvDataPendingExpired({ items: filteredBookingReqRows })
    //             );
    //         else
    //             setExportReadyBookings(
    //                 csvDataPendingExpired({ items: bookingReqRows })
    //             );
    //     } else if (tab === 3) {
    //         if (searching || dateRangeChange)
    //             setExportReadyBookings(
    //                 csvDataPendingExpired({
    //                     items: filteredNonpendingBookingReqRows
    //                 })
    //             );
    //         else
    //             setExportReadyBookings(
    //                 csvDataPendingExpired({ items: nonpendingBookingReqRows })
    //             );
    //     }
    // }, [
    //     tab,
    //     exportFutureRows,
    //     exportPastRows,
    //     bookingReqRows,
    //     nonpendingBookingReqRows,
    //     exportDateFilteredRows
    // ]);

    useEffect(() => {
        async function fetch() {
            await readBookingRequests();
        }
        async function fetchNonpending() {
            await readNonpendingBookingRequests();
        }
        if (tab === 2) {
            fetch();
        }
        if (tab === 3) {
            fetchNonpending();
        }
    }, [tab]);

    useEffect(() => {
        const loggedInUser = getUserFromCache();
        setRole(loggedInUser.role);
        setHideRatingsFromClients(loggedInUser.company.hideRatingsFromClients);
    }, []);

    useEffect(() => {
        //  console.log("bookingReqDataForDetails", bookingReqDataForDetails);
        async function getDetails() {
            setShowBookingReqSpinner(true);

            try {
                let logs = await getBookingReqDetails(bookingReqDataForDetails);
                setBRLogs(logs);
                setShowBookingReqSpinner(false);
            } catch (e) {
                setShowBookingReqSpinner(false);
            }
        }
        if (bookingReqDataForDetails) getDetails();
    }, [bookingReqDataForDetails]);

    // useEffect(() => {
    //     if (tab === 2 || tab === 3) {
    //         filterBookingReqs();
    //     }
    // }, [serviceTypeOption, dateRangeChange]);

    // useEffect(() => {
    //     if (dateFilter && (tab === 0 || tab === 1)) {
    //         // console.log("this if statement passes");
    //         exportDateFilter();
    //         setDateFilter(false);
    //     }
    // }, [dateFilter]);

    //checking data for all tabs
    useEffect(() => {
        console.log("**Data for all tabs - BookingListForm**");
        console.log(futureRows);
        console.log(pastRows);
        console.log(bookingReqRows);
        console.log(nonpendingBookingReqRows);
    });

    useEffect(() => {
        refreshTable();
    }, [showCancelledBookings]);

    const refreshTable = () => {
        console.log("Table Refreshed");
        setRefreshRows(true);
    };

    const delay = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    async function handleSearchBookings() {
        resetFilterResults();
        setSearching(true);
        if (tab === 2 || tab === 3) {
            let clientIds;
            const loggedInUser = getUserFromCache();
            if (selectedUser) {
                clientIds = await getClientByUserId(
                    selectedUser.id,
                    loggedInUser.companyId
                );
            }
            if (tab === 3) {
                filterNonPendingBookingReqs(true);
            } else {
                filterBookingReqs(clientIds);
            }
        } else {
            await _getFilteredFutureRows(true);
            await _getFilteredPastRows(true);
            // refreshTable();
        }
    }

    function filterBookingReqs(clientIds) {
        let brs = tab === 2 ? bookingReqRows : nonpendingBookingReqRows;
        if (brs && brs.length) {
            let filtered = JSON.parse(JSON.stringify(brs));
            if (dateRangeChange)
                filtered = filtered.filter((item) => {
                    return (
                        new Date(item.createdAt).getTime() <
                            selectedToDate.getTime() &&
                        new Date(item.createdAt).getTime() >
                            selectedFromDate.getTime()
                    );
                });

            if (serviceTypeOption && serviceTypeOption !== 0) {
                filtered = filtered.filter(
                    (item) => item.data.serviceType.id === serviceTypeOption
                );
            }
            if (clientIds) {
                for (let ci of clientIds) {
                    filtered = filtered.filter(
                        (item) => item.data.client.id === ci
                    );
                }
            }

            if (selectedProvider) {
                filtered = filtered.filter(
                    (item) => item.data.origProvider.id === selectedProvider.id
                );
            }

            if (filtered && filtered.length) {
                if (tab === 2) {
                    setFilteredBookingReqRows(filtered);
                    setExportReadyBookings(
                        csvDataPendingExpired({ items: filtered })
                    );
                } else {
                    setFilteredNonpendingBookingReqRows(filtered);
                    setExportReadyBookings(
                        csvDataPendingExpired({ items: filtered })
                    );
                }
            } else {
                if (tab === 2) setFilteredBookingReqRows([]);
                else setFilteredNonpendingBookingReqRows([]);
            }
            // if (!clientFilter.length && !providerFilter.length) {
            //     setSearching(false);
            //     if (tab === 2) readBookingRequests();
            //     else readNonpendingBookingRequests();
            // }
        }

        function byNameEmail(person, lcf) {
            if (lcf) {
                let pfn = person.firstname
                    ? person.firstname.toLowerCase()
                    : "";
                let pln = person.lastname ? person.lastname.toLowerCase() : "";
                let pem = person.emailaddress
                    ? person.emailaddress.toLowerCase()
                    : "";
                if (lcf.length === 2) {
                    return pfn.includes(lcf[0]) && pln.includes(lcf[1]);
                }
                if (lcf.length === 1) {
                    return (
                        pfn.includes(lcf[0]) ||
                        pln.includes(lcf[0]) ||
                        pem.includes(lcf[0])
                    );
                }
                return false;
            }
            return false;
        }
    }

    async function filterNonPendingBookingReqs(isNewSearch) {
        setNonPendingEndList(false);
        setShowLoading(true);
        let clientIds;
        const authuser = getUserFromCache();
        if (selectedUser) {
            clientIds = await getClientByUserId(
                selectedUser.id,
                authuser.companyId
            );
        }
        let filteredNonPendingList = isNewSearch
            ? []
            : filteredNonpendingBookingReqRows;
        let nextToken = isNewSearch ? null : nonPendingSearchToken;
        let newList = [];

        let PK;
        let userREQs = [];
        PK = `UNSUCCESSFUL-C-${authuser.company.id}`;
        let recordType = {
            between: [
                `${moment(selectedFromDate).format("YYYY-MM-DDT00:00:00Z")}`,
                `${moment(selectedToDate).format("YYYY-MM-DDT23:59:59Z")}`
            ]
        };
        while (newList.length < 50) {
            let nonPendingResp = await graphql(
                graphqlOperation(queries.listBookingRequests, {
                    id: PK,
                    recordType: dateRangeChange ? recordType : null,
                    limit: 50,
                    sortDirection: "DESC",
                    nextToken
                })
            );
            if (
                nonPendingResp &&
                nonPendingResp.data &&
                nonPendingResp.data.listBookingRequests
            ) {
                userREQs.push(...nonPendingResp.data.listBookingRequests.items);
                if (nonPendingResp.data.listBookingRequests.nextToken) {
                    nextToken =
                        nonPendingResp.data.listBookingRequests.nextToken;
                } else {
                    nextToken = null;
                }
            } else nextToken = null;

            if (userREQs.length) {
                userREQs = userREQs.sort((a, b) => {
                    const t1 = new Date(a.createdAt).getTime();
                    const t2 = new Date(b.createdAt).getTime();
                    return t2 - t1;
                });
            }

            const REQs = [];

            for (let cReq of userREQs) {
                try {
                    let data = JSON.parse(cReq.data);
                    cReq.data = data.REQ.data;
                    cReq.id = data.REQ.id;
                    cReq.recordType = data.REQ.recordType;
                    cReq.providerDetails = prepareProviderDetails(cReq.data);
                    cReq.clientDetails = prepareClientDetails(cReq.data);
                    cReq.bookingType = prepareBookingTypeDesc(cReq.data.sdt);
                    cReq.serviceAndPackage = cReq.data.serviceType.name;
                    cReq.bookingLoc = cReq.data.bookingAddress.addrOneLine;
                    cReq.tryOtherProviders = cReq.data.tryOtherProviders
                        ? "Yes"
                        : "No";
                    cReq.numberOfOthers = countOtherProviders(cReq.data);

                    let bookingTimesInfo = prepareBookingDateTimeDisplay(
                        cReq.data
                    );
                    cReq.bookingTimes = bookingTimesInfo.bookingTimes;
                    cReq.numberOfBookings = bookingTimesInfo.numberOfBookings;
                    cReq.createdDesc = calculateBookingReqExpiry(cReq);
                    REQs.push(cReq);
                } catch (e) {
                    setShowLoading(false);
                    console.log(e);
                }
            }

            if (REQs && REQs.length) {
                let filtered = REQs;

                if (serviceTypeOption && serviceTypeOption !== 0) {
                    filtered = filtered.filter(
                        (item) => item.data.serviceType.id === serviceTypeOption
                    );
                }
                if (clientIds) {
                    for (let ci of clientIds) {
                        filtered = filtered.filter(
                            (item) => item.data.client.id === ci
                        );
                    }
                }

                if (selectedProvider) {
                    filtered = filtered.filter(
                        (item) =>
                            item.data.origProvider.id === selectedProvider.id
                    );
                }
                newList.push(...filtered);
            }

            if (!nextToken) {
                setNonPendingEndList(true);
                setShowLoading(false);
                setnonPendingSearchToken(nextToken);
                break;
            }
            setnonPendingSearchToken(nextToken);
        }
        console.log("exiting loop while");

        if (newList && newList.length) {
            setFilteredNonpendingBookingReqRows([
                ...filteredNonPendingList,
                ...newList
            ]);
            setExportReadyBookings(
                csvDataPendingExpired({
                    items: [...filteredNonPendingList, ...newList]
                })
            );
        } else {
            setFilteredNonpendingBookingReqRows([]);
        }

        setShowLoading(false);
    }
    async function getScheduleLocationTz(providerScheduleId) {
        //get loc id from scheduleId
        const pksk = providerScheduleId.split(SCHEDPKSKSPLITAT);
        let skparts = pksk[1].split("|");
        let locationId = skparts[1].slice(3);
        try {
            let input = { id: locationId };
            if (skparts[1].slice(0, 2) === "PL") {
                const result = await graphql(
                    graphqlOperation(
                        `query GetProviderLocation($id: ID!) {
              getProviderLocation(id: $id) {
                timezone
                addressoneline
                latitude
                longitude
              }
            }
          `,
                        input
                    )
                );
                return {
                    ...result.data.getProviderLocation,
                    locationType: "PL"
                };
            } else {
                const result = await graphql(
                    graphqlOperation(
                        `query GetCompanyLocation($id: ID!) {
              getCompanyLocation(id: $id) {
                timezone
                addressoneline
                latitude
                longitude
              }
            }
          `,
                        input
                    )
                );
                return {
                    ...result.data.getCompanyLocation,
                    locationType: "CL"
                };
            }
        } catch (e) {
            console.log("error getting tz of schedule location");
            return {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locationType:
                    "PL" /*default as we don't change address for PL */
            };
        }
    }

    function countOtherProviders(brData) {
        let numberOfOthers = 0;
        if (
            brData &&
            brData.dir &&
            brData.dir.displayed &&
            brData.dir.displayed.length
        ) {
            numberOfOthers += brData.dir.displayed.length;
        }
        if (
            brData &&
            brData.dir &&
            brData.dir.notdisplayed &&
            brData.dir.notdisplayed.length
        ) {
            numberOfOthers += brData.dir.notdisplayed.length;
        }
        return numberOfOthers;
    }

    async function readBookingRequests() {
        setShowLoading(true);
        const authuser = getUserFromCache();
        let clientId = await Cache.getItem("ClientId");
        if (!clientId) {
            const foundClientIds = await getClientByUserId(
                authuser.id,
                authuser.companyId
            );
            clientId = foundClientIds[0];
        }
        console.log("clientId", clientId);
        console.log("role", role);
        console.log("authuser", authuser);
        let PK; //
        let userREQs = [];
        let status;
        if (role === USERROLE.PROVIDER) {
            PK = `AWAITING-P-${authuser.providerId}`;
            // status = [{ status: { eq: "AWAITING" } }];
        } else if (userHasClientRole()) {
            //show client the requests he/she created
            PK = `CL-${clientId}`;
            status = [
                { status: { eq: "REQUESTED" } },
                { status: { eq: "SCHEDULED" } }
            ];
        } else {
            PK = `AWAITING-C-${authuser.company.id}`;
            //status = [{ status: { eq: "AWAITING" } }];
        }

        let nextToken = null;

        do {
            let result = await graphql(
                graphqlOperation(queries.listBookingRequests, {
                    id: PK,
                    limit: process.env.REACT_APP_LISTLIMIT,
                    nextToken
                })
            );
            if (result && result.data && result.data.listBookingRequests) {
                userREQs.push(...result.data.listBookingRequests.items);
                if (result.data.listBookingRequests.nextToken) {
                    nextToken = result.data.listBookingRequests.nextToken;
                } else {
                    nextToken = null;
                }
            } else nextToken = null;
        } while (nextToken);

        if (userREQs.length) {
            userREQs = userREQs.sort((a, b) => {
                const t1 = new Date(a.createdAt).getTime();
                const t2 = new Date(b.createdAt).getTime();
                return t2 - t1;
            });
        }
        //console.log("userREQs", userREQs);

        const REQs = [];

        if (userHasClientRole()) {
            for (let cReq of userREQs) {
                if (
                    cReq.status === "REQUESTED" ||
                    cReq.status === "BROADCASTED" ||
                    cReq.status === "BROADCAST_SCHEDULED"
                ) {
                    cReq.data = JSON.parse(cReq.data);
                    cReq.bookingType = prepareBookingTypeDesc(cReq.data.sdt);
                    cReq.serviceAndPackage = cReq.data.serviceType.name;
                    cReq.providerDetails = prepareProviderDetails(cReq.data);
                    cReq.clientDetails = prepareClientDetails(cReq.data);
                    cReq.bookingLoc = cReq.data.bookingAddress.addrOneLine;
                    cReq.tryOtherProviders = cReq.data.tryOtherProviders
                        ? "Yes"
                        : "No";
                    cReq.numberOfOthers = countOtherProviders(cReq.data);
                    // cReq.bookingTimes = prepareBookingDateTimeDisplay(
                    //     cReq.data
                    // );
                    let bookingTimesInfo = prepareBookingDateTimeDisplay(
                        cReq.data
                    );
                    cReq.bookingTimes = bookingTimesInfo.bookingTimes;
                    cReq.numberOfBookings = bookingTimesInfo.numberOfBookings;
                    cReq.createdDesc = calculateBookingReqExpiry(cReq);
                    REQs.push(cReq);
                }
            }
        } else {
            for (let cReq of userREQs) {
                const pksk = cReq.recordType.split("|");
                const req = await graphql(
                    graphqlOperation(queries.getBookingRequest, {
                        id: pksk[0],
                        recordType: `${pksk[1]}|${pksk[2]}`
                    })
                );
                if (req.data.getBookingRequest) {
                    if (
                        req.data.getBookingRequest.status === "REQUESTED" ||
                        req.data.getBookingRequest.status === "BROADCASTED" ||
                        req.data.getBookingRequest.status ===
                            "BROADCAST_SCHEDULED"
                    ) {
                        req.data.getBookingRequest.data = JSON.parse(
                            req.data.getBookingRequest.data
                        );
                        req.data.getBookingRequest.providerDetails =
                            prepareProviderDetails(
                                req.data.getBookingRequest.data
                            );
                        req.data.getBookingRequest.clientDetails =
                            prepareClientDetails(
                                req.data.getBookingRequest.data
                            );

                        req.data.getBookingRequest.bookingType =
                            prepareBookingTypeDesc(
                                req.data.getBookingRequest.data.sdt
                            );
                        req.data.getBookingRequest.serviceAndPackage =
                            req.data.getBookingRequest.data.serviceType.name;
                        req.data.getBookingRequest.bookingLoc =
                            req.data.getBookingRequest.data.bookingAddress.addrOneLine;
                        req.data.getBookingRequest.tryOtherProviders = req.data
                            .getBookingRequest.data.tryOtherProviders
                            ? "Yes"
                            : "No";
                        req.data.getBookingRequest.numberOfOthers =
                            countOtherProviders(
                                req.data.getBookingRequest.data
                            );

                        let bookingTimesInfo = prepareBookingDateTimeDisplay(
                            req.data.getBookingRequest.data
                        );
                        req.data.getBookingRequest.bookingTimes =
                            bookingTimesInfo.bookingTimes;
                        req.data.getBookingRequest.numberOfBookings =
                            bookingTimesInfo.numberOfBookings;

                        req.data.getBookingRequest.createdDesc =
                            calculateBookingReqExpiry(
                                req.data.getBookingRequest
                            );
                        REQs.push(req.data.getBookingRequest);
                    }
                }
            }
        }

        setBookingReqRows(REQs);
        setShowLoading(false);
    }

    async function readNonpendingBookingRequests(loadMore) {
        setShowLoading(true);
        if (userHasAdminRole()) {
            const authuser = getUserFromCache();
            let PK;
            let userREQs = [];
            PK = `UNSUCCESSFUL-C-${authuser.company.id}`;
            let nextToken = null;

            let result = await graphql(
                graphqlOperation(queries.listBookingRequests, {
                    id: PK,
                    limit: process.env.REACT_APP_LISTLIMIT,
                    sortDirection: "DESC",
                    nextToken: loadMore ? nonPendingToken : null,
                    limit: LIMIT_COUNT
                })
            );
            if (result && result.data && result.data.listBookingRequests) {
                userREQs.push(...result.data.listBookingRequests.items);
                if (result.data.listBookingRequests.nextToken) {
                    nextToken = result.data.listBookingRequests.nextToken;
                } else {
                    nextToken = null;
                }
            } else nextToken = null;

            // Set nonPendingEndListHave to true if we have fetched all the nonPendingBookings
            if (!nextToken) {
                setNonPendingEndList(true);
            } else setNonPendingEndList(false);
            setNonPendingToken(nextToken);

            if (userREQs.length) {
                userREQs = userREQs.sort((a, b) => {
                    const t1 = new Date(a.createdAt).getTime();
                    const t2 = new Date(b.createdAt).getTime();
                    return t2 - t1;
                });
            }

            const REQs = [];

            for (let cReq of userREQs) {
                try {
                    let data = JSON.parse(cReq.data);
                    cReq.data = data.REQ.data;
                    cReq.id = data.REQ.id;
                    cReq.recordType = data.REQ.recordType;
                    cReq.providerDetails = prepareProviderDetails(cReq.data);
                    cReq.clientDetails = prepareClientDetails(cReq.data);
                    cReq.bookingType = prepareBookingTypeDesc(cReq.data.sdt);
                    cReq.serviceAndPackage = cReq.data.serviceType.name;
                    cReq.bookingLoc = cReq.data.bookingAddress.addrOneLine;
                    cReq.tryOtherProviders = cReq.data.tryOtherProviders
                        ? "Yes"
                        : "No";
                    cReq.numberOfOthers = countOtherProviders(cReq.data);

                    let bookingTimesInfo = prepareBookingDateTimeDisplay(
                        cReq.data
                    );
                    cReq.bookingTimes = bookingTimesInfo.bookingTimes;
                    cReq.numberOfBookings = bookingTimesInfo.numberOfBookings;
                    cReq.createdDesc = calculateBookingReqExpiry(cReq);
                    if ("ACCEPTED" === cReq.status)
                        cReq.status = "ACCEPTED with Error";
                    REQs.push(cReq);
                } catch (e) {
                    console.log(e);
                }
            }
            setNonpendingBookingReqRows([...nonpendingBookingReqRows, ...REQs]);
        } else setNonpendingBookingReqRows([]);
        setShowLoading(false);
    }

    function prepareBookingTypeDesc(sdt) {
        if (sdt === "forever") {
            return "Regular";
        } else if (sdt === "package") {
            return "Package";
        } else if (sdt === "single") {
            return "Single";
        }
    }
    function prepareProviderDetails(bookingReqData) {
        let providerName = "";
        providerName = `${bookingReqData.origProvider.firstname} ${bookingReqData.origProvider.lastname}`;
        return providerName;
    }
    function prepareClientDetails(bookingReqData) {
        let clientNameRmail = "";
        clientNameRmail = `${bookingReqData.user.firstname} ${bookingReqData.user.lastname} (${bookingReqData.user.emailaddress})`;
        return clientNameRmail;
    }

    function calculateBookingReqExpiry(bookingReq) {
        let mCreated = moment(bookingReq.createdAt);
        return `${moment.duration(moment().diff(mCreated)).humanize()} ago`;
    }

    function handleSelectChange(event) {
        setServiceTypeOption(event.target.value);
        if (event.target.value === 0 && searching) setSearching(false);
    }

    const handleClickOpenDateRange = () => {
        setShowDateRange(true);
    };

    const handleCloseDateRange = () => {
        setShowDateRange(false);
    };

    const handleClearDateRange = (isClear) => {
        setSelectFromDate(new Date());
        setSelectToDate(new Date());
        setDateRangeChange(false);
        setDateFilter(false);
        if (!isClear) {
            _getFutureBookingsList();
            _getPastBookingsList();
        }
    };

    const handleApplyDateRange = () => {
        setShowDateRange(false);
        setDateRangeChange(true);
        setPrevTab(tab);
        setDateFilter(true);
        //if (tab === 2 || tab === 3) filterBookingReqs();
    };

    // Get

    // A way to export all the bookings with the date range that the user filters
    // const exportDateFilter = async () => {
    //     const dateExportFilter = _findBooking(
    //         tab === 0 ? exportFutureRows : exportPastRows,
    //         tab,
    //         clientFilter,
    //         providerFilter,
    //         serviceTypeOption,
    //         dateRangeChange,
    //         selectedFromDate,
    //         selectedToDate
    //     );
    //     setExportDateFilteredRows(dateExportFilter);
    // };

    async function checkIsDuringBlockedTime(selectedDateTime) {
        //format of selectedDateTime must be like this "Fri Aug 04 2023 10:00:10 GMT-0400"
        console.log("checkIsDuringBlockedTime called", selectedDateTime);
        const selectedDate = new Date(selectedDateTime)
            .toISOString()
            .split("T")[0];
        const selectedTime = new Date(selectedDateTime).toLocaleTimeString(
            "en-US",
            {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit"
            }
        );
        let isDuringBlockedTime = await isBlockedTime(
            unavailableTimeBlocks,
            selectedDate,
            selectedTime
        );
        console.log("value from isDuringBlockedTime", isDuringBlockedTime);
        if (isDuringBlockedTime) {
            setErrorType("warning");
            setErrorMessage(
                "The date/time you selected is during the providers blocked time"
            );
            setCheckAvailabilityFails(true);
        }
    }

    async function prepareExportData() {
        const loggedInUser = getUserFromCache();

        let startdateTimeblockid = {
            between: [
                {
                    startdate: `${moment(exportFromDate).format(
                        "YYYY-MM-DDT00:00:00Z"
                    )}`
                },
                {
                    startdate: `${moment(exportToDate).format(
                        "YYYY-MM-DDT23:59:59Z"
                    )}`
                }
            ]
        };

        let sortDirection = tab === 0 ? null : "DESC";
        const bookingsForExport = await execReadBySortkey({
            opname: "bookingByCompany",
            op: bookingByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                startdateTimeblockid
            },
            sortDirection
        });
        if (bookingsForExport.items && bookingsForExport.items.length > 0) {
            if (serviceTypeOption) {
                bookingsForExport.items = bookingsForExport.items.filter(
                    (item) => item.servicetype.id === serviceTypeOption
                );
            }

            setExportReadyBookings(csvData({ items: bookingsForExport.items }));
            return;
        } else {
            setMsgOpen(true);
            setSnackMsg(
                "No bookings were found in the selected date range. Please adjust the date range and try again."
            );
        }
        setExportReadyBookings([]);
    }

    const _getPastBookingsList = async (loadMore) => {
        const loggedInUser = getUserFromCache();
        console.log("loggedInUser", loggedInUser);
        const limit = process.env.REACT_APP_LISTLIMIT;
        let operation = {
            opname: "bookingByCompany",
            op: bookingByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                startdateTimeblockid: {
                    between: [
                        {
                            startdate: `${moment()
                                .subtract(100, "years")
                                .format()}`
                        },
                        {
                            startdate: `${moment().format()}`
                        }
                    ]
                }
            },
            limit: LIMIT_COUNT,
            sortDirection: "DESC"
        };
        if (loggedInUser.role === "PROVIDER") {
            operation.opname = "bookingByProvider";
            operation.op = bookingByProviderFilter;
            let newProvId;
            if (loggedInUser.provider) newProvId = loggedInUser.provider.id;
            else newProvId = loggedInUser.providerId;
            operation.id = {
                providerId: newProvId
            };
            if (!showCancelledBookings) {
                operation.filter = {
                    and: [{ status: { ne: "CANCELLED" } }]
                };
            }
        } else if (loggedInUser.role === "CLIENT") {
            // need to find client id based on user
            let clientId = await Cache.getItem("ClientId");
            if (!clientId) {
                const foundClientIds = await getClientByUserId(
                    loggedInUser.id,
                    loggedInUser.companyId
                );
                clientId = foundClientIds[0];
            }
            operation.opname = "bookingByClient";
            operation.op = bookingByClientFilter;
            operation.id = {
                clientId: clientId
            };
            if (!showCancelledBookings) {
                operation.filter = {
                    and: [{ status: { ne: "CANCELLED" } }]
                };
            }
        }
        const pastResp = await execReadBySortkeyFromToken(
            operation,
            loadMore ? pastToken : null,
            loadMore ? pastCache : []
        );

        setShowLoading(true);

        if (!pastResp.token) {
            setPastEndList(true);
        } else setPastEndList(false);
        console.log("pastResp.token", pastResp.token);
        setPastToken(pastResp.token);
        if (pastResp.items && pastResp.items.length > 0) {
            pastResp.items.map(async (item) => {
                enrichBookingData(item);
            });
            setPastRows(pastResp.items);
            setPastCache(pastResp.items);
        }
        setRole(loggedInUser.role);
        const companyAdminsList = await getCompanyAdmins();
        setCompanyAdmins(companyAdminsList);
        setShowLoading(false);
    };

    const _getFutureBookingsList = async (loadMore) => {
        const loggedInUser = getUserFromCache();
        console.log("loggedInUser", loggedInUser);
        const limit = process.env.REACT_APP_LISTLIMIT;

        let operation = {
            opname: "bookingByCompany",
            op: bookingByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                startdateTimeblockid: {
                    between: [
                        {
                            startdate: `${moment().format()}`
                        },
                        {
                            startdate: `${moment().add(100, "years").format()}`
                        }
                    ]
                }
            },
            limit: LIMIT_COUNT
        };
        if (loggedInUser.role === "PROVIDER") {
            operation.opname = "bookingByProvider";
            operation.op = bookingByProviderFilter;
            let newProvId;
            if (loggedInUser.provider) newProvId = loggedInUser.provider.id;
            else newProvId = loggedInUser.providerId;
            operation.id = {
                providerId: newProvId
            };
            if (!showCancelledBookings) {
                operation.filter = {
                    and: [{ status: { ne: "CANCELLED" } }]
                };
            }
        } else if (loggedInUser.role === "CLIENT") {
            // need to find client id based on user
            let clientId = await Cache.getItem("ClientId");
            if (!clientId) {
                const foundClientIds = await getClientByUserId(
                    loggedInUser.id,
                    loggedInUser.companyId
                );
                clientId = foundClientIds[0];
            }
            operation.opname = "bookingByClient";
            operation.op = bookingByClientFilter;
            operation.id = {
                clientId: clientId
            };
            if (!showCancelledBookings) {
                operation.filter = {
                    and: [{ status: { ne: "CANCELLED" } }]
                };
            }
        }

        const futureResp = await execReadBySortkeyFromToken(
            operation,
            loadMore ? futureToken : null,
            loadMore ? futureCache : []
        );

        setShowLoading(true);

        if (!futureResp.token) {
            setFutureEndList(true);
        } else setFutureEndList(false);

        setFutureToken(futureResp.token);

        if (futureResp.items && futureResp.items.length > 0) {
            futureResp.items.map((item) => {
                enrichBookingData(item);
            });
            setFutureRows(futureResp.items);
            setFutureCache(futureResp.items);
        }

        setRole(loggedInUser.role);
        const companyAdminsList = await getCompanyAdmins();
        setCompanyAdmins(companyAdminsList);
        setShowLoading(false);
    };

    function enrichBookingData(item) {
        let bookingAddress = JSON.parse(item.order.bookingAddress);
        let TimeDisplayInfo = JSON.parse(item.TimeDisplayInfo);
        console.log("item.Notes omar", item.Notes);
        try {
            item.Notes =
                typeof item.Notes === "string" && item.Notes.length > 0
                    ? JSON.parse(item.Notes)
                    : item.Notes
                      ? item.Notes
                      : "";
        } catch (e) {
            item.Notes = "";
            console.log("item.Notes", item.Notes, e);
        }

        item.note = item.Notes ? item.Notes[0].notes : "";
        item.serviceName = item.servicetype.name;
        item.startDateDesc = TimeDisplayInfo
            ? getFullDateDisplayWithGMTOffset(TimeDisplayInfo)
            : format(
                  utcToZonedTime(
                      item.startdate,
                      item.timezone ? item.timezone : "America/Toronto"
                  ),
                  "MMM dd, yyyy h:mm a zzz",
                  {
                      timeZone: item.timezone
                          ? item.timezone
                          : "America/Toronto"
                  }
              );
        item.bookingDate = moment(item.startdate).format("YYYY-MM-DD");
        item.bookingTime = moment(item.startdate).format("hh:mm A");
        item.orderNumber = item.order.orderNo;
        item.dateCreated = moment(item.createdAt).format("YYYY-MM-DD hh:mm A");
        item.duration = item.minutes;
        item.bookingAddressoneLine = bookingAddress
            ? bookingAddress.addrOneLine
            : "";
        item.bookingState = bookingAddress ? bookingAddress.state : "";
        item.bookingPostalCode = bookingAddress
            ? bookingAddress.postalCode
            : "";
        item.bookingCountry = bookingAddress ? bookingAddress.country : "";
        item.adminEmail =
            item.client.user.role === "MARKETBOX_ADMIN" ||
            item.client.user.role === "COMPANY_ADMIN" ||
            item.client.user.role === "COMPANY_ADMIN_PROVIDER"
                ? item.client.user.emailaddress
                : "";
        item.initialCharge = item.order.total;
        item.additionalCharges = item.totalAddChg ? item.totalAddChg : 0;
        item.clientName = `${
            item.client && item.client.user ? item.client.user.firstname : ""
        } ${item.client && item.client.user ? item.client.user.lastname : ""}`;
        item.clientFirstName = `${
            item.client && item.client.user ? item.client.user.firstname : ""
        }`;
        item.clientLastName = `${
            item.client && item.client.user ? item.client.user.lastname : ""
        }`;
        item.clientEmail =
            item.client && item.client.user && item.client.user.emailaddress
                ? item.client.user.emailaddress
                : "N/A";
        item.clientMobilePhone =
            item.client && item.client.user && item.client.user.mobilephone
                ? item.client.user.mobilephone
                : "N/A";
        item.clientHomePhone =
            item.client && item.client.user && item.client.user.homephone
                ? item.client.user.homephone
                : "N/A";
        item.clientWorkPhone =
            item.client && item.client.user && item.client.user.workphone
                ? item.client.user.workphone
                : "N/A";
        item.clientPhone =
            item.client.user.phonepref == "MOBILE"
                ? "Mobile: " + item.clientMobilePhone
                : item.client.user.phonepref == "HOME"
                  ? "Home: " + item.clientHomePhone
                  : item.client.user.phonepref == "WORK"
                    ? "Work: " + item.clientWorkPhone
                    : "Number Missing";
        item.providerName =
            item.provider &&
            `${item.provider.firstname} ${item.provider.lastname}`;
        item.providerFirstName = item.provider ? item.provider.firstname : "";
        item.providerLastName = item.provider ? item.provider.lastname : "";
        item.providerEmail = item.provider.emailaddress;
        item.cancelledBy
            ? (item.cancelledByEmail = item.cancelledBy.emailaddress)
            : (item.cancelledByEmail = " ");
        if (item.cancelledByEmail == item.clientEmail) {
            item.cancelledByEmail = "CLIENT";
        }
        if (item.cancelledByEmail == item.providerEmail) {
            item.cancelledByEmail = "PROVIDER";
        }
        item.ratingstars = item.rating ? item.rating.ratingstars : "";
        item.providerTip = item.totalAddChg;
        return item;
    }

    async function getClientByUserId(userId, companyId) {
        console.log("getClientByUserId was called:", userId, companyId);
        let clientIds = [];
        //write get clients query
        const clientProfile = await graphql(
            graphqlOperation(clientByUserId, {
                userId: userId,
                filter: { companyId: { eq: companyId } }
            })
        );
        if (
            clientProfile &&
            clientProfile.data.clientByUserId.items &&
            clientProfile.data.clientByUserId.items.length
        ) {
            for (let ci of clientProfile.data.clientByUserId.items) {
                clientIds.push(ci.id);
            }
        }
        return clientIds;
    }

    async function prepareFilter(loggedInUser, clientIds) {
        let filter;
        if (loggedInUser.role === "PROVIDER") {
            let newProvId;
            if (loggedInUser.provider) newProvId = loggedInUser.provider.id;
            else newProvId = loggedInUser.providerId;
            filter = {
                providerId: { eq: newProvId }
            };
            if (!showCancelledBookings) {
                filter = {
                    ...filter,
                    and: [{ status: { ne: "CANCELLED" } }]
                };
            }
        } else if (loggedInUser.role === "CLIENT") {
            // need to find client id based on user
            let clientId = await Cache.getItem("ClientId");
            if (!clientId) {
                const foundClientIds = await getClientByUserId(
                    loggedInUser.id,
                    loggedInUser.companyId
                );
                clientId = foundClientIds[0];
            }
            filter = {
                clientId: { eq: clientId }
            };
            if (!showCancelledBookings) {
                filter = {
                    ...filter,
                    and: [{ status: { ne: "CANCELLED" } }]
                };
            }
        }
        if (selectedProvider && selectedUser && clientIds) {
            if (filter) filter.clientId = { eq: clientIds[0] };
            else
                filter = {
                    clientId: { eq: clientIds[0] }
                };
        }
        if (bookingStatusChange) {
            let or = [];
            for (let prop in bookingStatus) {
                if (bookingStatus[prop])
                    or.push({ status: { eq: `${prop.toUpperCase()}` } });
            }
            filter = {
                ...filter,
                or: [...or]
            };
        }

        return filter;
    }

    function prepareDateRange(isPast) {
        let startdateTimeblockid;
        if (!isPast) {
            startdateTimeblockid = {
                between: [
                    {
                        startdate: `${moment().format("YYYY-MM-DDT00:00:00Z")}`
                    },
                    {
                        startdate: `${moment()
                            .add(100, "years")
                            .format("YYYY-MM-DDT23:59:59Z")}`
                    }
                ]
            };
            if (dateRangeChange) {
                startdateTimeblockid = {
                    between: [
                        {
                            startdate:
                                moment().format("YYYY-MM-DDTHH:mm:ssZ") >
                                moment(selectedFromDate).format(
                                    "YYYY-MM-DDT00:00:00Z"
                                )
                                    ? `${moment().format(
                                          "YYYY-MM-DDTHH:mm:ssZ"
                                      )}`
                                    : `${moment(selectedFromDate).format(
                                          "YYYY-MM-DDT00:00:00Z"
                                      )}`
                        },
                        {
                            startdate: `${moment(selectedToDate).format(
                                "YYYY-MM-DDT23:59:59Z"
                            )}`
                        }
                    ]
                };
            }
        } else {
            startdateTimeblockid = {
                between: [
                    {
                        startdate: `${moment()
                            .subtract(100, "years")
                            .format("YYYY-MM-DDT00:00:00Z")}`
                    },
                    {
                        startdate: `${moment().format("YYYY-MM-DDT23:59:59Z")}`
                    }
                ]
            };
            if (dateRangeChange) {
                startdateTimeblockid = {
                    between: [
                        {
                            startdate: `${moment(selectedFromDate).format(
                                "YYYY-MM-DDT00:00:00Z"
                            )}`
                        },
                        {
                            startdate:
                                moment().format("YYYY-MM-DDTHH:mm:ssZ") >
                                moment(selectedToDate).format(
                                    "YYYY-MM-DDT23:59:59Z"
                                )
                                    ? `${moment(selectedToDate).format(
                                          "YYYY-MM-DDT23:59:59Z"
                                      )}`
                                    : `${moment().format(
                                          "YYYY-MM-DDTHH:mm:ssZ"
                                      )}`
                        }
                    ]
                };
            }
        }
        return startdateTimeblockid;
    }

    async function prepareFilterOperation(loggedInUser, isPast, clientIds) {
        // let clientIds;
        // if (selectedUser) {
        //     clientIds = await getClientByUserId(
        //         selectedUser.id,
        //         loggedInUser.companyId
        //     );
        // }

        let filter = await prepareFilter(loggedInUser, clientIds);
        let startdateTimeblockid = prepareDateRange(isPast);
        let op = {
            opname: "bookingByCompany",
            op: bookingByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                startdateTimeblockid
            },
            filter: filter,
            limit:
                bookingStatusChange && !bookingStatus.scheduled
                    ? 11000
                    : LIMIT_COUNT
        };
        if (selectedUser && clientIds && !selectedProvider) {
            op.opname = "bookingByClient";
            op.op = bookingByClientFilter;
            op.id = {
                clientId: clientIds[0]
            };
        } else if (selectedProvider || (selectedProvider && selectedUser)) {
            op.opname = "bookingByProvider";
            op.op = bookingByProviderFilter;
            op.id = {
                providerId: selectedProvider.id
            };
        }
        return op;
    }

    const _getFilteredFutureRows = async (newSearch) => {
        setShowLoading(true);
        const currDate = new Date();
        const currDateTime = currDate.setUTCHours(0, 0, 0, 0);
        const higherBoundDateRange = selectedToDate.getTime();
        if (higherBoundDateRange < currDateTime) {
            if (tab === 0) {
                setSnackMsg("No Results Found For Future Bookings.");
                setMsgOpen(true);
            }
            setShowLoading(false);
            setFutureEndList(true);
            return;
        }
        const loggedInUser = getUserFromCache();
        let clientIds;
        if (selectedUser) {
            clientIds = await getClientByUserId(
                selectedUser.id,
                loggedInUser.companyId
            );

            if (!clientIds || clientIds.length === 0) {
                setSnackMsg(
                    "Selected client is not valid. Please clear the client's name or email you entered."
                );
                setMsgOpen(true);
                setShowLoading(false);
                return;
            }
        }

        let filteredFutureList = newSearch ? [] : futureSearchCache;
        let nextToken = newSearch ? null : futureSearchToken;
        let newList = newSearch ? [] : filteredFutureRows;
        let isEndOfList = false;

        // let filter = await prepareFilter(loggedInUser);
        // let startdateTimeblockid = prepareDateRange(false);
        let operation = await prepareFilterOperation(
            loggedInUser,
            false,
            clientIds
        );
        while (filteredFutureList.length <= 50) {
            const futureResp = await execReadBySortkeyFromToken(
                operation,
                nextToken,
                []
            );
            console.log("futureREsp _getFilteredFutureRows", futureResp);
            nextToken = futureResp.token;

            if (futureResp.items && futureResp.items.length > 0) {
                if (serviceTypeOption) {
                    futureResp.items = futureResp.items.filter(
                        (item) => item.servicetype.id === serviceTypeOption
                    );
                }
                if (dateRangeChange) {
                    let currentTime = Date.now();
                    console.log("what is dateRangeChange", dateRangeChange);
                    futureResp.items = futureResp.items.filter(
                        (item) =>
                            new Date(item.startdate).getTime() > currentTime
                    );
                }
                futureResp.items.map((item) => {
                    enrichBookingData(item);
                });
                filteredFutureList.push(...futureResp.items);
                console.log("futureResp after filteration", filteredFutureList);
            }

            if (!futureResp.token) {
                setFutureEndList(true);
                isEndOfList = true;
                break;
            } else setFutureEndList(false);
        }

        //filter the future list
        let currentDateTime = new Date();

        filteredFutureList = [
            ...filteredFutureList.filter(
                (item) => new Date(item.startdate) > currentDateTime
            )
        ];
        if (isEndOfList) {
            console.log(
                "futureResp new list push endOfLIst True",
                filteredFutureList
            );
            console.log(
                "futureResp new list push endOfLIst True newList before push",
                newList
            );
            newList.push(...filteredFutureList);
            //check for any duplication
            newList = removeDuplicatesFromArray(newList);
            setFutureSearchToken(nextToken);
            setFutureSearchCache(filteredFutureList.slice(50));
            console.log(
                "futureResp new list push endOfLIst True newList",
                newList
            );
            setFilteredFutureRows(newList);
            setRefreshRows(true);
        } else {
            console.log(
                "futureResp new list push endOfLIst false",
                filteredFutureList
            );
            newList.push(...filteredFutureList.slice(0, 50));
            //check for any duplication
            newList = removeDuplicatesFromArray(newList);
            setFutureSearchToken(nextToken);
            setFutureSearchCache(filteredFutureList.slice(50));
            setFilteredFutureRows(newList);
            setRefreshRows(true);
        }
        console.log("New List _getFilteredFuturRows", newList);
        console.log("Future List without filter", futureRows);
        setShowLoading(false);
    };

    const _getFilteredPastRows = async (isNewSearch) => {
        setShowLoading(true);
        const currDate = new Date();
        const currDateTime = currDate.setUTCHours(23, 59, 59, 59);
        const lowerBoundDateRange = selectedFromDate.getTime();
        if (lowerBoundDateRange > currDateTime) {
            if (tab === 1) {
                setSnackMsg("No Results Found For Past Bookings.");
                setMsgOpen(true);
            }
            setShowLoading(false);
            setPastEndList(true);
            return;
        }
        const loggedInUser = getUserFromCache();
        let clientIds;
        if (selectedUser) {
            clientIds = await getClientByUserId(
                selectedUser.id,
                loggedInUser.companyId
            );

            if (!clientIds || clientIds.length === 0) {
                setSnackMsg("Selected client is not valid.");
                setMsgOpen(true);
                setShowLoading(false);
                return;
            }
        }

        let filteredPastList = isNewSearch ? [] : pastSearchCache;
        let nextToken = isNewSearch ? null : pastSearchToken;
        let newList = isNewSearch ? [] : filteredPastRows;
        let isEndOfList = false;

        //  let filter = await prepareFilter(loggedInUser);
        // let startdateTimeblockid = prepareDateRange(true);
        let operation = await prepareFilterOperation(
            loggedInUser,
            true,
            clientIds
        );
        operation.sortDirection = "DESC";
        while (filteredPastList.length <= 50) {
            const pastResp = await execReadBySortkeyFromToken(
                operation,
                nextToken,
                []
            );

            nextToken = pastResp.token;

            if (pastResp.items && pastResp.items.length > 0) {
                if (serviceTypeOption) {
                    pastResp.items = pastResp.items.filter(
                        (item) => item.servicetype.id === serviceTypeOption
                    );
                }
                if (dateRangeChange) {
                    let currentTime = Date.now();
                    pastResp.items = pastResp.items.filter(
                        (item) =>
                            new Date(item.startdate).getTime() <= currentTime
                    );
                }
                pastResp.items.map((item) => {
                    enrichBookingData(item);
                });
                filteredPastList.push(...pastResp.items);
            }

            if (!pastResp.token) {
                setPastEndList(true);
                isEndOfList = true;
                break;
            }
        }

        //filter the past list
        let currentDateTime = new Date();

        filteredPastList = [
            ...filteredPastList.filter(
                (item) => new Date(item.startdate) <= currentDateTime
            )
        ];

        if (isEndOfList) {
            newList.push(...filteredPastList);
            //check for any duplication
            newList = removeDuplicatesFromArray(newList);
            setPastSearchToken(nextToken);
            setPastSearchCache(filteredPastList.slice(50));
            setFilteredPastRows(newList);
            setRefreshRows(true);
        } else {
            newList.push(...filteredPastList.slice(0, 50));
            //check for any duplication
            newList = removeDuplicatesFromArray(newList);
            setPastSearchToken(nextToken);
            setPastSearchCache(filteredPastList.slice(50));
            setFilteredPastRows(newList);
            setRefreshRows(true);
        }
        console.log("New List _getFilteredPastRows", newList);
        setShowLoading(false);
    };

    const _handleSearchTermChange = (event) => {
        const { name, value } = event.target;
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));

        let filter = {
            ...filter,
            name: { contains: value }
        };
        console.log("_handleSearchTermChange", _handleSearchTermChange);
    };

    const handleTabChange = (e, tabNum) => {
        setTab(tabNum);
        if (prevTab != tabNum) {
            setPrevTab(tabNum);
        }
    };

    const clearBookingStatus = () => {
        setBookingStatusChange(false);
        setBookingStatus({
            arrived: false,
            cancelled: false,
            completed: false,
            scheduled: false
        });
    };

    const cancelBookingPath = async (
        data,
        cancellationTimeExceeded,
        adminSkipCharge
    ) => {
        console.log("refund at the start", data);
        if (data.orderType === "SINGLE") {
            let clientChargesOfOrder = await graphql(
                graphqlOperation(queries.clientChargeByOrder, {
                    orderId: data.orderId
                })
            );
            const clientCharge =
                clientChargesOfOrder.data.clientChargeByOrder.items[0];
            if (clientCharge) {
                let refund_left = clientCharge.amount;

                for (
                    let i = 1;
                    i <
                    clientChargesOfOrder.data.clientChargeByOrder.items.length;
                    i++
                ) {
                    let clientRefund =
                        clientChargesOfOrder.data.clientChargeByOrder.items[i];
                    if (
                        clientCharge.stripechargeid ===
                        clientRefund.stripe_refund_charge
                    ) {
                        refund_left -= clientRefund.amount_refunded;
                    }
                }
                console.log("refund left", refund_left);
                if (refund_left != clientCharge.amount && refund_left != 0) {
                    setRemainingRefund(refund_left);
                    setDisplayPartialRefundDialog(true);
                } else {
                    cancelPath(data, cancellationTimeExceeded, adminSkipCharge);
                }
            } else cancelPath(data, cancellationTimeExceeded, adminSkipCharge);
        } else {
            cancelPath(data, cancellationTimeExceeded, adminSkipCharge);
        }
    };

    //From: User clicks Yes (or Yes but waive charge) in Cancel Dialogue
    //Does: Handles identifying which cancellation method to apply to particular case.
    //Returns: n/a
    const cancelPath = async (
        data,
        cancellationTimeExceeded,
        adminSkipCharge
    ) => {
        setCancelOnlySelectedBooking(false);
        const orderData = await graphql(
            graphqlOperation(queries.getOrder, { id: data.order.id })
        );
        data = { ...data, order: orderData.data.getOrder };
        console.log("asdf current bookingData: ", data);

        if (data.order.type === "SINGLE" || data.order.type === "PACKAGE") {
            //adminSkipCharges isn't used, but for adminSkipCharge to be true, waive charge was clicked and cancellationTimeExceeded is false.
            console.log(
                "asdf cancelling a single or package type (Already paid)"
            );
            await handleCancellation(data, cancellationTimeExceeded); // cancel single appt as normal
        } else if (data.order.type === "ONGOING") {
            setCancelRepeating(true); // open dialog to let user select if they want to cancel single/repeating appts
            setRepeatingTimeoutExceeded(
                adminSkipCharge ? false : cancellationTimeExceeded
            );
            // Display the dialog for only 30 seconds, otherwise the user can abuse this dialog to cancel the appointment
            // at any time they want, and the cancellation period will not be enforced
            const timeoutId = setTimeout(function () {
                setCancelRepeating(false);
                setRepeatingTimeoutExceeded(false);
                setShowYesCancelLoading(false);
                setShowWaiveCancelLoading(false);
            }, 30000);
            setRepeatingCancelTimeoutId(timeoutId);
        }
    };

    //From: User selecting to cancel selected booking specifically
    //      Activated as cancellation path for Single or Package order type
    //Does: Handles UI changes and calls _handleCancel on booking
    //Returns: n/a
    const handleCancellation = async (data, cancellationTimeExceeded) => {
        setShowCancelBookingSpinner(true);
        setCancelBookingClicked(true);
        try {
            console.log("asdf handleCancellation data", data);
            //This function gets called for cancelling one appt from an ongoing appts AND single / package appts
            //Currently no difference in handling, but may be relevant for later changes?
            if (cancellationTimeExceeded && data.orderType == "ONGOING") {
                await _handleCancel(data, true, cancellationTimeExceeded);
            } else {
                await _handleCancel(data, true, cancellationTimeExceeded);
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log(e);
        }
        setCancelModal(false);
        setCancelRepeating(false);
        setRepeatingTimeoutExceeded(false);
        setShowCancelBookingSpinner(false);
        setShowYesCancelLoading(false);
        setShowWaiveCancelLoading(false);
        setCancelBookingClicked(false);
        if (updateResults) {
            //setTimeout(handleSearchBookings);
            await delay(3000);
            await handleSearchBookings();
        }

        //handleSearchBookings();
    };
    const bookingByOrder = `query BookingByOrder(
        $orderId: String
        $startdateTimeblockid: ModelBookingByOrderCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingByOrder(
        orderId: $orderId
        startdateTimeblockid: $startdateTimeblockid
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        ) {
        items {
            id
            desc
            orderId
            providerId
            clientId
            startdate
            minutes
            totalAddChg
            location
            companyId
            status
            timeblockid
            cancelledAt
            noshowAt
            virtualMeetingInfo
            orderType
            isVirtual
            createdAt
            updatedAt
            cancelledBy {
            id
            username
            emailaddress
            firstname
            lastname
            }
            company {
            id
            name
            contactname
            emailaddress
            replyemailaddress
            }
            provider {
            id
            firstname
            lastname
            emailaddress
            phone
            }
            servicetype {
            id
            name
            desc
            categoryId
            categoryName
            minutes
            price
            active
            deleted
            }
            client {
            id
            userId
            }
            order {
            id
            }
        }
        nextToken
        }
    }
    `;

    //From: User selects to cancel all future bookings
    //Does: Collects related bookings, attempts to cancel all (with _handleCancel)
    //Returns: n/a
    const _handleCancelRepeating = async (data, timeoutExceeded) => {
        console.log("asdf running _handleCancelRepeating");
        setCancelOnlySelectedBooking(false);
        // cancel all repeating appts
        setShowCancelBookingSpinner(true);
        clearTimeout(repeatingCancelTimeoutId);
        try {
            // use order id to find all repeating appts that occur on or after the selected booking start date
            let appts = await execReadBySortkey({
                opname: "bookingByOrder",
                op: bookingByOrder,
                id: { orderId: data.order.id },
                skey: {
                    startdateTimeblockid: { ge: { startdate: data.startdate } }
                },
                filter: { status: { eq: "SCHEDULED" } }
            });

            appts = appts.items;

            // Now we iterate through each booking instance. IF the booking instance is the first instance of the
            // scheduled bookings, then we will pass whether they missed the cancellation periood for only that first instance.

            // In other words, we will only process the cancellation charge for the first scheduled booking of repeated appointments
            for (let i = 0; i < appts.length; i++) {
                console.log("asdf running individual appointment cancellation");
                let booking = appts[i];
                booking = { ...booking, client: data.client }; // add client user info to booking object
                if (i == 0) {
                    const initialCancelResults = await _handleCancel(
                        booking,
                        false,
                        timeoutExceeded
                    );
                    if (!initialCancelResults.success) {
                        //Failed to cancel first booking. Therefore, stop.
                        setSnackMsg(initialCancelResults.message);
                        setMsgOpen(true);
                        return;
                    }
                } else {
                    await _handleCancel(booking, false, false); // cancel each booking in the repeating appointments
                }
            }

            const datakey = `O-${data.order.id}`;

            const input = {
                id: data.company.id,
                datakey: datakey,
                active: false
            };
            // flag for no future bookings
            const res = await graphql(
                graphqlOperation(mutations.updateRepeatApptCreation, { input })
            );
            const companyId = data.company.id;
            const companySubdomain = await getCompanySubdomain(companyId);
            console.log("updateRepeatApptCreation: ", res);
            let booking_date = "";
            if (data.TimeDisplayInfo) {
                let tdinfo = JSON.parse(data.TimeDisplayInfo);
                booking_date = `${tdinfo.dt_long_disp} at ${
                    tdinfo.tm_st_disp
                } ${tdinfo.tz_abbr_disp ? tdinfo.tz_abbr_disp : ""}`;
            } else {
                booking_date = moment(data.startdate).format(
                    "MMM DD, YYYY [at] h:mm a"
                );
            }
            console.log("asdf about to being try statement");
            try {
                const clientLastInitial =
                    data.client.user.lastname.charAt(0) + ".";
                const providerLastInitial =
                    data.provider.lastname.charAt(0) + ".";
                //Remove user last inital, since it is at the end of sentance already.
                const userLastInitial = user.lastname.charAt(0);
                const bookingUrl = companySubdomain;
                console.log("asdf bookingUrl is ", bookingUrl);
                const dataObj = {
                    client_firstname: data.client.user.firstname,
                    servicetype_name: data.servicetype.name,
                    booking_date,
                    provider_firstname: data.provider.firstname,
                    provider_lastnameinitial: providerLastInitial,
                    user_firstname: user.firstname,
                    user_lastnameinitial: userLastInitial,
                    company_email: data.company.emailaddress,
                    company_name: data.company.name,
                    booking_url: bookingUrl,
                    isRegisteredUser: data.client.user.registered
                };

                const companyEmail =
                    data.company.replyemailaddress !== ""
                        ? data.company.replyemailaddress
                        : data.company.emailaddress;
                const ccAddresses = [];
                ccAddresses.push(data.provider.emailaddress);
                if (comapnyAdmins.length > 0) {
                    for (const companyAdmin of comapnyAdmins) {
                        ccAddresses.push(companyAdmin.emailaddress);
                    }
                }
                console.log("asdf sending email");
                const result = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName: "appt-cancellation-ongoing.html",
                            subject: `Appointment cancellation notification - ${data.company.name} Client ${data.client.user.firstname} ${clientLastInitial}`,
                            body: null,
                            toAddresses: [data.client.user.emailaddress],
                            bccAddresses: ccAddresses,
                            replyTo: companyEmail,
                            companyName: data.company.name,
                            companyId: data.company.id,
                            company: data.company,
                            dataObj: dataObj,
                            companyLogoUrl: data.company.logoUrl,
                            companyColor: data.company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL,
                            bookingUrl: bookingUrl
                        }
                    }
                );
                console.log("asdf email sent");
                setSnackMsg(
                    "Your appointments have been cancelled successfully"
                );
                setMsgOpen(true);
                setCancelModal(false);
                setCancelRepeating(false);
                setRepeatingTimeoutExceeded(false);
                data.status = "CANCELLED";
            } catch (e) {
                Sentry.captureException(e);
                console.log("error while sending cancellation notification", e);
            }
        } catch (err) {
            Sentry.captureException(err);
            console.log(err);
        }
        setCancelRepeating(false);
        setShowCancelBookingSpinner(false);
        setShowYesCancelLoading(false);
        setShowWaiveCancelLoading(false);
        // _getFutureBookingsList();
        // _getPastBookingsList();
        //handleSearchBookings();
    };

    //From: _handleCancelRepeating, handleCancellation - is lowest level of cancelling path.
    //Does: Calls cancelBooking from BookingService to cancel specific booking, sends email
    //Returns:  JSON with success status and message
    const _handleCancel = async (
        data,
        sendEmailNotification,
        cancellationTimeExceeded
    ) => {
        try {
            setShowSpinner(true);

            // sendEmailNotification = false;
            const user = getUserFromCache();
            console.log("asdf _handleCancel bookingData: ", data);
            console.log(
                "missed cancellation period: ",
                cancellationTimeExceeded
            );
            data.cancelledAt = new Date();
            data.cancelledBy = user.id;
            console.log("asdf cancelBooking");
            const cancelResp = await cancelBooking(
                data,
                cancellationTimeExceeded
            );
            console.log("send email notification:", sendEmailNotification);
            console.log("asdf done with cancelBooking, resp is:", cancelResp);

            await auditBookingState(user, data, "CANCEL");

            if (cancelResp.success && sendEmailNotification) {
                let booking_date = "";
                if (data.TimeDisplayInfo) {
                    let tdinfo = JSON.parse(data.TimeDisplayInfo);
                    booking_date = `${tdinfo.dt_long_disp} at ${
                        tdinfo.tm_st_disp
                    } ${tdinfo.tz_abbr_disp ? tdinfo.tz_abbr_disp : ""}`;
                } else {
                    booking_date = moment(data.startdate).format(
                        "MMM DD, YYYY [at] h:mm a"
                    );
                }

                const bookingUrl = await getCompanySubdomain(data.company.id);
                const clientLastInitial =
                    data.client.user.lastname.charAt(0) + ".";
                const providerLastInitial =
                    data.provider.lastname.charAt(0) + ".";
                const userLastInitial = user.lastname.charAt(0) + ".";
                const dataObj = {
                    client_firstname: data.client.user.firstname,
                    servicetype_name: data.servicetype.name,
                    booking_date,
                    provider_firstname: data.provider.firstname,
                    provider_lastnameinitial: providerLastInitial,
                    user_firstname: user.firstname,
                    user_lastnameinitial: userLastInitial,
                    company_email: data.company.emailaddress,
                    company_name: data.company.name,
                    bookingUrl: bookingUrl,
                    isRegisteredUser: data.client.user.registered
                };

                const companyEmail =
                    data.company.replyemailaddress !== ""
                        ? data.company.replyemailaddress
                        : data.company.emailaddress;
                const ccAddresses = [];
                ccAddresses.push(data.provider.emailaddress);
                if (comapnyAdmins.length > 0) {
                    for (const companyAdmin of comapnyAdmins) {
                        ccAddresses.push(companyAdmin.emailaddress);
                    }
                }
                const result = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName: "appt-cancellation-single.html",
                            subject: `Appointment cancellation notification - ${data.company.name} Client ${data.client.user.firstname} ${clientLastInitial}`,
                            body: null,
                            toAddresses: [data.client.user.emailaddress],
                            bccAddresses: ccAddresses,
                            replyTo: companyEmail,
                            companyName: data.company.name,
                            companyId: data.company.id,
                            dataObj: dataObj,
                            companyLogoUrl: data.company.logoUrl,
                            companyColor: data.company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL,
                            bookingUrl: bookingUrl
                        }
                    }
                );
                console.log("asdf result email send is: ", result);
                setSnackMsg(cancelResp.messages.join(" "));
                setMsgOpen(true);
                setShowSpinner(false);
                data.status = "CANCELLED";
                console.log("asdf getting data", data);
                if (user.role === "PROVIDER" || user.role === "CLIENT") {
                    setTimeout(() => {
                        actions.setPage("BookingsListForm");
                    }, 2000);
                } else {
                    await delay(3000);
                    await handleSearchBookings();
                }
                refreshTable();
                setCancelModal(false);
                return {
                    success: true,
                    message: "Successfully cancelled."
                };
            } else if (
                cancelResp &&
                !cancelResp.success &&
                cancelResp.refunded
            ) {
                //Case where refunded already!
                console.log("asdf refund already applied!");
                handleOpenRefundedDialogue();
                return {
                    success: false,
                    message:
                        "Failed to cancel booking. Refund has already been applied."
                };
            } else if (!cancelResp.success) {
                console.log("asdf failed!");
                return {
                    success: false,
                    message:
                        "Failed to cancel booking. Please try updating the client's credit card or manually cancelling individual bookings."
                };
            } else {
                console.log("cancel successful, do not send email. ");
                return {
                    success: true,
                    message:
                        "Case where cancel response was successful, but do not send email."
                };
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log("Unexpected error! Error is:", e);
            console.log(e);
            return {
                success: false,
                message: "Error attempting to cancel booking: " + e
            };
        }
    };

    const _handleNoShow = async (data) => {
        const user = getUserFromCache();
        // console.log("_handleNoShow", data);
        data.noshowAt = new Date();
        data.noshowBy = user.id;
        let booking_date = "";
        if (data.TimeDisplayInfo) {
            let tdinfo = JSON.parse(data.TimeDisplayInfo);
            booking_date = `${tdinfo.dt_long_disp} at ${tdinfo.tm_st_disp} ${
                tdinfo.tz_abbr_disp ? tdinfo.tz_abbr_disp : ""
            }`;
        } else
            booking_date = moment(data.startdate).format(
                "MMM DD, YYYY [at] h:mm a"
            );
        const dataObj = {
            client_firstname: data.client.user.firstname,
            client_lastname: data.client.user.lastname,
            servicetype_name: data.servicetype.name,
            booking_date,
            provider_firstname: data.provider.firstname,
            provider_lastname: data.provider.lastname,
            company_email: data.company.emailaddress,
            company_name: data.company.name,
            isRegisteredUser: true
        };
        if (comapnyAdmins.length > 0) {
            let toAddresses = [];
            for (const companyAdmin of comapnyAdmins) {
                toAddresses.push(companyAdmin.emailaddress);
            }
            const result = await postApi(
                "sendtwilioemail",
                "/sendtwilioemailtemplate",
                {
                    body: {
                        templateName: "appt-noshow.html",
                        subject: `Provider ${data.provider.firstname} ${data.provider.lastname} did not attend their booking on ${booking_date}`,
                        body: null,
                        toAddresses: toAddresses,
                        ccAddresses: [],
                        replyTo: data.company.replyemailaddress
                            ? data.company.replyemailaddress
                            : data.company.emailaddress,
                        companyName: data.company.name,
                        companyId: data.company.id,
                        dataObj: dataObj,
                        companyLogoUrl: data.company.logoUrl,
                        companyColor: data.company.primaryColor,
                        authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                    }
                }
            );
            let updatedBooking = await saveNoshowBooking(data);
            data.status = updatedBooking.status; //update status in the local copy

            await auditBookingState(user, data, "NO SHOW");
        }
        setNoShowModal(false);
        _getFutureBookingsList();
        _getPastBookingsList();
    };
    const handleOverrideStatus = async (booking) => {
        setOverrideStatusClicked(true);
        try {
            const bookingInput = {
                id: booking.id,
                status: "SCHEDULED",
                timeblockid: booking.timeblockid,
                startdate: booking.startdate
            };
            const result = await graphql(
                graphqlOperation(mutations.updateBooking, {
                    input: bookingInput
                })
            );
            if (
                result &&
                result.data &&
                result.data.updateBooking &&
                result.data.updateBooking.status === "SCHEDULED"
            ) {
                setSnackMsg("Booking status was changed successfully.");
                setMsgOpen(true);
                _getFutureBookingsList();
                _getPastBookingsList();
            } else {
                setSnackMsg("Booking status could not be changed.");
                setMsgOpen(true);
            }
        } catch (e) {
            console.log("Error while overriding booking status");
        }
        setOverrideStatusClicked(false);
        setOverrideModal(false);
        setRecurringBookingAllOrSelectedOverride(false);
    };

    const getSlotData = async () => {};

    function resetOverrideLocationStates() {
        setShowAddr(false);
        setLocationChanged(false);
        setLocation();
        setLocId("");
        setPlaceId();
        setAddress();
        setOriginalRrule(null);
        setRrule(null);
        setParsedRrule(null);
        setRruleLabel(null);
        setRruleChanged(false);
        setXoccurChanged(false);
        setIsRecurrenceShown(false);
    }

    function saveAddress(addr) {
        let {
            city,
            prov,
            country,
            countryShortName,
            postalCode,
            addressOneLine,
            coords,
            route,
            street_number
        } = addr;
        setAddress(addr);
    }

    function savePlaceId(placeId) {
        if (placeId === null) {
            setPlaceId(null);
        } else {
            setPlaceId(placeId);
        }
    }

    function saveLocation(cl) {
        setLocation(cl);
        setLocationChanged(cl ? true : false);
        setLocId(cl ? cl.id : "");
        if (cl && cl.id == "0") {
            setAddress(null);
        }
    }

    const handleOverride = async (data) => {
        console.log(data);

        setRecurringBookingAllOrSelectedOverride(false);
        const user = getUserFromCache();
        setShowOverrideBookingSpinner(true);
        setOverrideBookingClicked(true);
        try {
            // Delete old timeblocks and create new ones
            const timeblock = data.timeblockid.split("::");
            const input = {
                id: timeblock[0],
                scheduleinfo: timeblock[1]
            };

            //if the provider is same and booking date and time is same, no need to check availability
            let skipavailabilitycheck = false;
            if (
                providerScheduleId &&
                providerScheduleId.includes(data.providerId)
            ) {
                const currentdatetime = new Date(data.startdate);
                const selecteddatetime = new Date(selectedDate);
                if (currentdatetime && selecteddatetime) {
                    if (
                        currentdatetime.valueOf() === selecteddatetime.valueOf()
                    )
                        skipavailabilitycheck = true;
                }
            }
            const startDate = moment(selectedDate).format("YYYY-MM-DD");
            const startTime = moment(selectedDate).format("HH:mm");
            const endTime = moment(selectedDate)
                .add(data.minutes, "minutes")
                .format("HH:mm");
            let locationId;
            let latitude;
            let longitude;
            let locationTz;
            if (location && location.id === "0" && address) {
                const providerLocation = await getOrCreateProviderLocation({
                    address,
                    provider: selectProvider || data.provider
                });
                ({
                    id: locationId,
                    latitude,
                    longitude,
                    timezone: locationTz
                } = providerLocation);
                locationId = `PL-${locationId}`;
            } else if (location?.id) {
                ({
                    id: locationId,
                    latitude,
                    longitude,
                    timezone: locationTz
                } = location);
                locationId = `CL-${locationId}`;
            }

            if (!skipavailabilitycheck) {
                let isBookedTimeAvailable = await checkAvailabilityForOverride({
                    companyId: mbxUser.company.id,
                    providerId: selectProvider
                        ? selectProvider.id
                        : data.provider.id,
                    locationTz,
                    startdate: startDate,
                    startTime,
                    endTime,
                    overriddenbookedslot: input,
                    bookingIncrement: data.company?.bookingIncrement
                        ? data.company?.bookingIncrement
                        : 15 //does not have impact of checking availability but for the sake of consistency with the other similar functions.
                });

                if (!isBookedTimeAvailable) {
                    // setSnackMsg("Selected booking time is not available.");
                    // setMsgOpen(true);
                    setErrorType("error");
                    setErrorMessage("Selected booking time is not available");
                    setCheckAvailabilityFails(true);
                    setShowOverrideBookingSpinner(false);
                    setOverrideBookingClicked(false);
                    return;
                }
            }

            const {
                data: { deleteProviderSchedule: deletedBkRecord }
            } = await graphql(
                graphqlOperation(mutations.deleteProviderSchedule, { input })
            );
            if (!location) {
                ({ latitude, longitude, tz: locationTz } = deletedBkRecord);
                locationId = JSON.parse(deletedBkRecord.locations)[0];
            }

            let dateInfo = await getBookingDateDescription(
                selectedDate,
                locationTz,
                data.minutes
            );
            let bookedtimeblockData = {
                companyId: data.companyId,
                startDate: startDate,
                endDate: startDate,
                startTime,
                endTime,
                type: "BOOKED",
                providerId: selectProvider
                    ? selectProvider.id
                    : data.provider.id,
                status: "CONFIRMED",
                tz: locationTz,
                sdtutc: dateInfo.dtstamp_str,
                locationId,
                latitude,
                longitude
            };
            const newTb = await createProviderScheduleBK(bookedtimeblockData);

            // Update booking
            const bookingInput = {
                id: data.id,
                startdate: dateInfo.dtstamp_str, // moment(selectedDate).format(),
                bookingProviderId: selectProvider
                    ? selectProvider.id
                    : data.provider.id,
                providerId: selectProvider
                    ? selectProvider.id
                    : data.provider.id,
                timeblockid: newTb.id,
                timezone: locationTz,
                TimeDisplayInfo: JSON.stringify(dateInfo),
                status: "SCHEDULED"
            };
            if (
                // the location was changed to a physical company location
                locationChanged &&
                location &&
                location.virtual !== undefined &&
                !location.virtual &&
                location.id !== "0"
            ) {
                bookingInput.location = location.addressoneline;
                bookingInput.isVirtual = false;
            } else if (
                // the location was changed to the customers location
                locationChanged &&
                location &&
                location.id === "0" &&
                address
            ) {
                bookingInput.location = address.addressOneLine;
                bookingInput.isVirtual = false;
            } else if (
                // the location was changed to a virtual company location
                locationChanged &&
                location &&
                location.virtual &&
                location.id !== "0"
            ) {
                bookingInput.location = "Virtual Appointment";
                bookingInput.isVirtual = true;
            }
            const result = await graphql(
                graphqlOperation(mutations.updateBooking, {
                    input: bookingInput
                })
            );

            console.log("what is diff", data, result.data.updateBooking);
            console.log(
                "Updated Booking = " + JSON.stringify(result, undefined, 4)
            );

            await auditOverrideBooking(
                user,
                data,
                result.data.updateBooking,
                "OVERRIDE"
            );

            let old_booking_datetime = "";
            if (data.TimeDisplayInfo) {
                let old_dateInfo = JSON.parse(data.TimeDisplayInfo);
                old_booking_datetime = `${old_dateInfo.dt_full_disp}`;
            } else {
                old_booking_datetime = moment(data.startdate).format(
                    "MMM DD, YYYY [at] h:mm a"
                );
            }

            // Send Emails
            if (result && result.data) {
                const dataObj = {
                    client_firstname: data.client.user.firstname,
                    client_lastname: data.client.user.lastname,
                    servicetype_name: data.servicetype.name,
                    old_booking_datetime,
                    changedto_booking_datetime: `${dateInfo.dt_long_disp}`,
                    changedto_booking_time: `${dateInfo.tm_st_disp}`,
                    changedto_provider_firstname: selectProvider
                        ? selectProvider.firstname
                        : data.provider.firstname,
                    changedto_provider_lastname: selectProvider
                        ? selectProvider.lastname
                        : data.provider.lastname,
                    changedto_booking_location: bookingInput.location
                        ? bookingInput.location
                        : data.location,
                    booking_service: data.serviceName,
                    company_email: data.company.emailaddress,
                    company_name: data.company.name,
                    isRegisteredUser: data.client.user.registered
                };
                let ccAddresses = [data.provider.emailaddress];
                if (
                    selectProvider &&
                    selectProvider.emailaddress !== data.provider.emailaddress
                ) {
                    ccAddresses = ccAddresses.concat([
                        selectProvider.emailaddress
                    ]);
                }
                const result = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName: "appt-change.html",
                            subject: `${data.client.user.firstname}, your upcoming appointment has changed.`,
                            body: null,
                            toAddresses: [data.client.user.emailaddress],
                            ccAddresses: ccAddresses,
                            replyTo: data.company.replyemailaddress
                                ? data.company.replyemailaddress
                                : data.company.emailaddress,
                            companyName: data.company.name,
                            companyId: data.company.id,
                            dataObj: dataObj,
                            companyLogoUrl: data.company.logoUrl,
                            companyColor: data.company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                        }
                    }
                );
            }
            setSnackMsg("Booking changed successfully");
            setMsgOpen(true);
            resetOverrideLocationStates();
            setTimeout(() => {
                actions.setPage("BookingsListForm");
            }, 2000);
        } catch (e) {
            Sentry.captureException(e);
            setSnackMsg("Error: Unable to override booking");
            setMsgOpen(true);
            resetOverrideLocationStates();
            console.log("error unable to override", e);
        }
        // This code below is only reachable when an error is caught.
        setSelectProvider(null);
        setProviderSchedules([]);
        setProviderScheduleId(null);
        setOverrideModal(false);
        setRecurringBookingAllOrSelectedOverride(false);
        setShowOverrideBookingSpinner(false);
        setOverrideBookingClicked(false);
        _getFutureBookingsList();
        _getPastBookingsList();
    };

    const handleArrived = async (data) => {
        // arrived at appointment so set booking status to "ARRIVED"
        const user = getUserFromCache();
        const input = {
            id: data.id,
            timeblockid: data.timeblockid,
            startdate: data.startdate,
            status: "ARRIVED"
        };

        try {
            const result = await graphql(
                graphqlOperation(mutations.updateBooking, { input })
            );

            if (result && result.data) {
                await auditBookingState(user, data, "ARRIVED");
                logger.debug("*** result = " + JSON.stringify(result));
                setArrivedModal(false);
                setSnackMsg("Booking arrival recorded.");
                setMsgOpen(true);
                // refresh data
                if (searching) {
                    data.status = result.data.updateBooking.status; //ARRIVED
                } else {
                    _getFutureBookingsList();
                    _getPastBookingsList();
                }

                return;
            }
        } catch (err) {
            Sentry.captureException(err);
            logger.error(
                "*** an error occurred. The error was: " + JSON.stringify(err)
            );
            setSnackMsg("Unable to record booking arrival.");
            setMsgOpen(true);
            return;
        }
    };

    const handleAddCharge = async (data) => {
        const loggedInUser = getUserFromCache();

        if (loggedInUser.company.collectpayment) {
            setAddChgLoading(true);
            // add record to additionalcharge table
            const user = getUserFromCache();
            logger.debug("*** data = " + JSON.stringify(data));
            //return;

            if (!amount || amount < 0) {
                setSnackMsg(
                    "Please enter a positive dollar amount for the additional charge."
                );
                setMsgOpen(true);
                setAddChgLoading(false);
                return;
            }

            if (!desc || desc.length == 0) {
                setSnackMsg(
                    "Please enter a description for the additional charge."
                );
                setMsgOpen(true);
                setAddChgLoading(false);
                return;
            }

            if (!note || note.length == 0) {
                setSnackMsg("Please enter a note for the additional charge.");
                setMsgOpen(true);
                setAddChgLoading(false);
                return;
            }

            // NOW WE DO THE FOLLOWING:
            // 1. Create Additional Charge entry
            // 2. Create Billing Transaction entry
            // 3. Create Stripe payment
            // 4. Create ClientCharge entry
            // 5. Send SMS to client to notify of additional charge
            // 6. Send Email to client notifying charge, BCC Admin

            try {
                // Commenting out the tax since it should not be calculated when adding an additional charge
                // const taxrate = data.order.taxrate ? data.order.taxrate : 13; // default to 13% if tax rate not stored on Order (old data)
                // const tax = Number.parseFloat(amount * (taxrate / 100));

                const currency = data.order.currency; // data.company.currency.toString();
                const totalAmt = Number.parseFloat(amount).toFixed(2);

                /* #region Create Additional Charge entry */
                let input = {
                    amount: totalAmt,
                    desc: desc,
                    note: note,
                    bookingId: data.id,
                    orderId: data.order.id,
                    additionalChargeOrderId: data.order.id,
                    additionalChargeUserId: user.id,
                    additionalChargeCompanyId: data.company.id,
                    companyId: data.company.id,
                    additionalChargeClientId: data.client.id,
                    clientId: data.client.id,
                    additionalChargeCreatorId: user.id,
                    creatorId: user.id
                };

                let result = await graphql(
                    graphqlOperation(mutations.createAdditionalCharge, {
                        input
                    })
                );

                /* #endregion */

                if (!(result && result.data)) {
                    throw (
                        "Error calling createAdditionalCharge. Result was: " +
                        JSON.stringify(result)
                    );
                }

                logger.debug(
                    "*** createAdditionalCharge result = " +
                        JSON.stringify(result)
                );

                /* #region Update Booking entry */

                const totalAddChg =
                    Number(data.totalAddChg || 0) + Number(totalAmt);
                input = {
                    id: data.id,
                    timeblockid: data.timeblockid,
                    startdate: data.startdate,
                    totalAddChg
                };
                result = await graphql(
                    graphqlOperation(mutations.updateBooking, { input })
                );

                console.log("what is diff", data, result.data.updateBooking);
                await auditBookingAddChrg(
                    user,
                    data,
                    result.data.updateBooking,
                    "ADD CHARGE"
                );

                /* #endregion */

                if (!(result && result.data)) {
                    throw (
                        "Error calling updateBooking. Result was: " +
                        JSON.stringify(result)
                    );
                }

                logger.debug(
                    "*** updateBooking result = " + JSON.stringify(result)
                );

                /* #region Create Billing Transaction entry */

                input = {
                    billingTransactionClientId: data.client.id,
                    clientId: data.client.id,
                    amount: totalAmt,
                    billingTransactionCreatorId: user.id,
                    creatorId: user.id,
                    billingTransactionCompanyId: user.company.id,
                    companyId: user.company.id,
                    desc: desc,
                    credit: false
                };

                if (data.order) {
                    input = {
                        ...input,
                        billingTransactionOrderId: data.order.id,
                        orderId: data.order.id
                    };
                }

                result = await graphql(
                    graphqlOperation(mutations.createBillingTransaction, {
                        input
                    })
                );

                if (!(result && result.data)) {
                    throw (
                        "Error calling createBillingTransaction. Result was: " +
                        JSON.stringify(result)
                    );
                }

                /* #endregion */

                logger.debug(
                    "*** createBillingTransaction result = " +
                        JSON.stringify(result)
                );
                const billingTransactionId =
                    result.data.createBillingTransaction.id;
                const emailaddress =
                    data.client.user && data.client.user.emailaddress
                        ? data.client.user.emailaddress
                        : "stripe@gomarketbox.com";

                /*********************************/
                /* #region Create Stripe payment */
                /*********************************/

                // now charge via Stripe
                const stripe_result = await chargeCreditCard(
                    data.client.stripeCustomerId,
                    getStripeAmount(totalAmt), // * 100, // stripe integer value includes cents
                    currency,
                    emailaddress,
                    false,
                    0,
                    data.company.id,
                    data.company.stripeAccount,
                    data.company.stripeConnectEnabled
                );
                logger.debug("stripe_result = ");
                logger.debug(stripe_result);

                if (!stripe_result) {
                    throw (
                        "Error calling chargeCreditCard. Result was: " +
                        JSON.stringify(stripe_result)
                    );
                }

                /* #endregion */

                /*************************************/
                /* #region Create ClientCharge entry */
                /*************************************/
                // now we can save the data to the ClientCharge table
                const dateNow = new Date();
                const options = { dateStyle: "long" };
                const dateNowString =
                    dateNow.toLocaleDateString("en-US", options) + // use en-US for now
                    " " +
                    dateNow.toLocaleTimeString();

                input = {
                    clientId: data.client.id,
                    clientChargeClientId: data.client.id,
                    companyId: data.company.id,
                    description:
                        "Additional Charge Billing Transaction created on " +
                        dateNowString,
                    status: "created",
                    stripechargeid: stripe_result.charge.id,
                    stripepaymentmethod: stripe_result.charge.payment_method,
                    stripe_payment_method_details:
                        stripe_result.charge.stripe_payment_method_details,
                    balance_transaction:
                        stripe_result.charge.balance_transaction,
                    stripe_refund_charge: stripe_result.charge.id,
                    stripe_status: stripe_result.charge.status,
                    clientChargeBillingtransactionId: billingTransactionId,
                    billingtransactionId: billingTransactionId,
                    amount: totalAmt,
                    amount_refunded: 0,
                    currency: currency ? currency : data.company.currency
                };
                if (data.order) {
                    input = {
                        ...input,
                        clientChargeOrderId: data.order.id,
                        orderId: data.order.id
                    };
                }

                result = await graphql(
                    graphqlOperation(mutations.createClientCharge, { input })
                );
                if (!(result && result.data)) {
                    throw (
                        "Error calling createClientCharge. Result was: " +
                        JSON.stringify(result)
                    );
                }

                /* #endregion */

                /***************************************************************/
                /* #region Send Email to client to notify of additional charge */
                /***************************************************************/

                // now send email notification to client of charge
                // BCC Admins to know the charge went through well.

                //get relevant Admin accounts
                const companyName = data.company.name;
                const companyEmail = data.company.emailaddress;
                const companyAdminsList = await getCompanyAdmins(
                    data.company.id
                );
                let companyAdminsEmailList = [];
                for (let companyAdmin of companyAdminsList) {
                    companyAdminsEmailList.push(companyAdmin.emailaddress);
                }
                const companyAdmins = companyAdminsEmailList;

                //prepare constants
                const clientFirstName = data.client.user.firstname;
                const clientLastName = data.client.user.lastname;
                const clientLastInitial = clientLastName.charAt(0);
                const orderNumber = data.order.orderNo;
                let credit = false;
                const useCredit = credit ? "refund" : "charge";
                const companyPhone = data.company.company_phone
                    ? data.company.company_phone
                    : "";
                const service_product = desc ? " due to " + desc : "";
                //prepare data obj to replace tokens
                const dataObj = {
                    company_name: companyName,
                    company_email: companyEmail,
                    company_phone: companyPhone,
                    client_firstname: clientFirstName,
                    client_lastNameInitial: clientLastInitial,
                    client_lastName: clientLastName,
                    dollar: totalAmt,
                    credit: useCredit,
                    order_no: orderNumber
                        ? " associated with Order Number: " + orderNumber
                        : "",
                    service_product: service_product,
                    notes: note,
                    isRegisteredUser: data.client.user.registered
                };

                //send email from template - Need clarification on Token and Template expectations
                //From here, refund will always be associated with an order.
                let emailResult = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName:
                                "client-transaction-notification.html",
                            subject: `${clientFirstName} ${clientLastName} your ${companyName} account has received a ${useCredit}.`,
                            body: null,
                            toAddresses: [data.client.user.emailaddress],
                            bccAddresses: companyAdmins,
                            replyTo: data.company.replyemailaddress,
                            companyName: data.company.name,
                            companyId: data.company.id,
                            dataObj: dataObj,
                            companyLogoUrl: data.company.logoUrl,
                            companyColor: data.company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                        }
                    }
                );
                console.log("asdf email result", emailResult);

                // close modal, display snack message
                setAddChargeModal(false);
                setAmount(null);
                setDesc(null);
                setNote(null);
                setAddChgLoading(false);
                setSnackMsg("Additional charge recorded.");
                setMsgOpen(true);

                // refresh data
                _getFutureBookingsList();
                _getPastBookingsList();
                return;
            } catch (err) {
                Sentry.captureException(err);
                logger.error(
                    "*** an error occurred. The error was: " + err.message
                );
                logger.error(err);
                const errorresult = await logSystemError(
                    user.company.id,
                    "ERROR",
                    "Saving Additional Charge on BookingListForm",
                    0,
                    err
                );
                logger.debug(errorresult);
                setSnackMsg("Unable to record additional charge.");
                setMsgOpen(true);
                setAddChgLoading(false);
                return;
            }
        } else {
            setShowFeatureDisabled(true);
        }
    };

    const getPublicAccessToken = async () => {
        // create a PublicAccessToken record and return
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 48);
        const input = {
            role: "TIPPAGE",
            expiryDate: expiryDate
        };

        const result = await graphql(
            graphqlOperation(mutations.createPublicAccessToken, { input })
        );

        const publicAccessToken = result.data.createPublicAccessToken.id;
        return publicAccessToken;
    };

    // const getPaymentStatus = async (data, caller) => {
    //     //Data is bookingData
    //     console.log("asdf payment status running", data);
    //     console.log("asdf calling request to do:", caller)

    const handleCompleted = async (data) => {
        // NOW WE DO THE FOLLOWING:
        // 1. Completed appointment so set booking status to "COMPLETED"
        // 2. Update company statistics
        // 3. Send SMS to client to request rating/tip

        setCompletedClicked(true);
        setShowSpinner(true);
        const user = getUserFromCache();
        console.log("asdf user: ", user);
        console.log("asdf data", data);

        //I hypothesize there is an issue related to getting Provider Amina's User / user Information from cache?
        try {
            // Set booking status to "COMPLETED"
            /* #region Set booking status to "COMPLETED" */

            let result;
            try {
                //Processing Booking status change.
                result = await putApi("bookingapi", "/booking", {
                    body: {
                        bookingId: data.id,
                        timeblockid: data.timeblockid,
                        startdate: data.startdate,
                        orderId: data.order.id,
                        status: "COMPLETED",
                        actionUser: user
                    }
                });
                console.log("asdf result:", result);

                await auditBookingState(user, data, "COMPLETED");

                //Handling error messaging on failure.
                if (result.data.success === false) {
                    let snackMessageText =
                        result.data.message +
                        " Booking could not be completed successfully.";
                    if (
                        result.data.type &&
                        result.data.type === "PAYMENT_FAILED"
                    ) {
                        snackMessageText +=
                            " Please update client payment information.";
                    }
                    setCompletedModal(false);
                    setSnackMsg(snackMessageText);
                    setMsgOpen(true);
                    _getFutureBookingsList();
                    _getPastBookingsList();
                    setCompletedClicked(false);
                    return;
                }
            } catch (e) {
                Sentry.captureException(e);
                setCompletedModal(false);
                setSnackMsg(
                    "Unexpected error while updating booking status. Booking could not be completed successfully."
                );
                setMsgOpen(true);
                _getFutureBookingsList();
                _getPastBookingsList();
                setCompletedClicked(false);
                return;
            }

            //If result and additional charge, update booking statistics.
            if (
                result &&
                result.data &&
                result.data.success &&
                result.data.repeatApptChargeTotal
            ) {
                let bookingTotalcharge = result.data.repeatApptChargeTotal;
                //Update stats
                try {
                    let companyStats = await getCompanyStats(data.company.id);
                    if (companyStats) {
                        companyStats.sales_today = companyStats.sales_today
                            ? companyStats.sales_today + bookingTotalcharge
                            : bookingTotalcharge;
                        companyStats.sales_mtd = companyStats.sales_mtd
                            ? companyStats.sales_mtd + bookingTotalcharge
                            : bookingTotalcharge;
                        companyStats.sales_ytd = companyStats.sales_ytd
                            ? companyStats.sales_ytd + bookingTotalcharge
                            : bookingTotalcharge;
                        const result = await updateCompanyDashboadInfo(
                            data.company.id,
                            companyStats
                        );
                    }
                } catch (e) {
                    //Error here should not stop process, unlike error with booking.
                    Sentry.captureException(e);
                    console.log("Error updating stats:", e);
                    setSnackMsg(
                        "Unexpected error while updating company statistics. Booking status update unaffected."
                    );
                    setMsgOpen(true);
                }
            }

            //Check payment (redundant verification of payment - Status update will not complete sans payment)
            if (result && result.data && !result.data.success) {
                if (result.data.type === "PAYMENT_FAILED") {
                    setPaymentFailed(true);
                    let stripeMessage = result.data.message
                        ? result.data.message
                        : "";
                    let ccNumMsg = result.data.defaultpartialcc
                        ? "Last 4 digits of the credit card: " +
                          result.data.defaultpartialcc
                        : "";
                    setPaymentFailedMessage(
                        `${stripeMessage ? stripeMessage : ""} ${
                            ccNumMsg ? ccNumMsg : ""
                        }`
                    );
                    setShowSpinner(false);
                    //Payment failed therefore do not continue with the SMS message
                    setSnackMsg(
                        "Booking could not be completed successfully. Please update client payment information."
                    );
                    setMsgOpen(true);
                    return;
                } else {
                    setCompletedModal(false);
                    setSnackMsg(
                        "Booking could not be completed successfully. Please update client payment information."
                    );
                    setMsgOpen(true);
                    _getFutureBookingsList();
                    _getPastBookingsList();
                    setCompletedClicked(false);
                }
                return;
            }

            if (result && result.data && result.data.success) {
                //Everything was handled succesfully. Send SMS
                if (user.company.sendratingTipMsg) {
                    console.log("asdf sending rating tip logic");
                    let smsText = user.company.ratingTipTextMsg;
                    let bookingId = data.id;
                    // create PublicAccessToken
                    let publicAccesstoken = await getPublicAccessToken();
                    console.log("asdf pre-link creation");
                    let shortUrl;
                    try {
                        const link =
                            "https://" +
                            user.company.subdomain +
                            "/?p=tippage&b=" +
                            bookingId +
                            "&t=" +
                            publicAccesstoken;
                        logger.debug("link = " + link);
                        console.log("asdf post link creation", link);

                        //get shortUrl
                        shortUrl = await getShort(link);
                    } catch (e) {
                        // setSnackMsg(
                        //     "Error creating shortUrl link. Client completion message not sent. Rest assured, the transaction has been processed and booking status has been updated."
                        // );
                        // setMsgOpen(true);
                        // console.log("error :", e);
                        Sentry.captureException(e);

                        setCompletedModal(false);
                        _getFutureBookingsList();
                        _getPastBookingsList();
                        setCompletedClicked(false);
                        return;
                    }

                    // replace tokens
                    while (smsText.includes("[provider-fullname]")) {
                        smsText = smsText.replace(
                            "[provider-fullname]",
                            data.provider.firstname +
                                " " +
                                data.provider.lastname
                        );
                    }

                    while (smsText.includes("[provider-first-name]")) {
                        smsText = smsText.replace(
                            "[provider-first-name]",
                            data.provider.firstname
                        );
                    }

                    while (smsText.includes("[provider_lastinitial]")) {
                        smsText = smsText.replace(
                            "[provider_lastinitial]",
                            data.provider.lastname.charAt(0) + "."
                        );
                    }

                    while (smsText.includes("[provider_lastname]")) {
                        smsText = smsText.replace(
                            "[provider_lastname]",
                            data.provider.lastname
                        );
                    }

                    while (smsText.includes("[provider_name]")) {
                        smsText = smsText.replace(
                            "[provider_name]",
                            data.provider.firstname +
                                " " +
                                data.provider.lastname
                        );
                    }

                    while (smsText.includes("[client_firstname]")) {
                        smsText = smsText.replace(
                            "[client_firstname]",
                            data.client.user.firstname
                        );
                    }

                    while (smsText.includes("[client_lastname]")) {
                        smsText = smsText.replace(
                            "[client_lastname]",
                            data.client.user.lastname
                        );
                    }

                    while (smsText.includes("[client_firstinitial]")) {
                        smsText = smsText.replace(
                            "[client_firstinitial]",
                            data.client.user.lastname.charAt(0) + "."
                        );
                    }
                    while (smsText.includes("[client_name]")) {
                        smsText = smsText.replace(
                            "[client_name]",
                            data.client.user.firstname +
                                " " +
                                data.client.user.lastname
                        );
                    }

                    while (smsText.includes("[service]")) {
                        smsText = smsText.replace(
                            "[service]",
                            data.serviceName
                        );
                    }

                    while (smsText.includes("[booking_short_date]")) {
                        const bookingDate = new Date(data.bookingDate);
                        const options = {
                            month: "short",
                            day: "numeric"
                        };
                        const bookingShortDate = bookingDate.toLocaleDateString(
                            "en-US",
                            options
                        );
                        smsText = smsText.replace(
                            "[booking_short_date]",
                            bookingShortDate
                        );
                    }

                    smsText = smsText.replace("[link]", shortUrl);
                    logger.debug("smsText = " + JSON.stringify(smsText));

                    console.log("asdf post sms text replacement");
                    // now send sms
                    const to = data.client.user.mobilephone;
                    let countryCodeForSms = "CA";
                    try {
                        countryCodeForSms = await getCountryCodeForSms(
                            data.order.id,
                            data.company.countrycode3166alpha2
                        );
                    } catch (e) {
                        setSnackMsg(
                            "Error getting country code for client rating sms"
                        );
                        setMsgOpen(true);
                        console.log("error getting country code for sms", e);
                        Sentry.captureException(e);
                    }
                    console.log("asdf post get country code SMS");
                    try {
                        const smsresult = await sendSms(
                            to,
                            smsText,
                            countryCodeForSms
                        );
                        logger.debug(
                            "smsresult = " + JSON.stringify(smsresult)
                        );
                    } catch (e) {
                        setSnackMsg("Error sending client rating SMS");
                        setMsgOpen(true);
                        console.log("error sending sms", e);
                        Sentry.captureException(e);
                    }
                    console.log("asdf post send SMS");
                }
                setShowSpinner(false);

                setSnackMsg("Booking completion recorded successfully!");
                logger.debug("*** result = " + JSON.stringify(result));
                setCompletedModal(false);
                setMsgOpen(true);
                // refresh data
                if (searching) {
                    data.status = "COMPLETED"; //local copy of booking in filtered list marked completed as well to avoid refreshing from the backend
                } else {
                    _getFutureBookingsList();
                    _getPastBookingsList();
                }

                setCompletedClicked(false);
                return;
            }
        } catch (err) {
            Sentry.captureException(err);
            setCompletedClicked(false);
            setShowSpinner(false);
            logger.error(
                "*** an error occurred. The error was: " + err.message
            );
            const errorresult = await logSystemError(
                user.company.id,
                "ERROR",
                "Setting booking statue to COMPLETE on BookingListForm",
                0,
                err
            );
            logger.debug(errorresult);
            setSnackMsg(
                "An unexpected error has occurred. Booking completion could not be updated. Please try again."
            );
            setMsgOpen(true);
            return;
        }
    };

    async function getCompanySubdomain(companyId) {
        const result = await graphql(
            graphqlOperation(
                `query GetCompany($id: ID!) {
                    getCompany(id: $id) {
                        id
                        name
                        subdomain
                    }
                }`,
                { id: companyId }
            )
        );
        if (result.data.getCompany && result.data.getCompany.subdomain) {
            return result.data.getCompany.subdomain;
        }
    }
    async function getCompanyStats(companyId) {
        const result = await graphql(
            graphqlOperation(
                `query GetCompany($id: ID!) {
                    getCompany(id: $id) {
                        id
                        name
                        DashboardInfo
                    }
                }
                `,
                { id: companyId }
            )
        );
        if (result.data.getCompany && result.data.getCompany.DashboardInfo) {
            return JSON.parse(result.data.getCompany.DashboardInfo);
        }
        return;
    }

    async function updateCompanyDashboadInfo(companyId, dashboardInfo) {
        const result = await graphql(
            graphqlOperation(
                `mutation UpdateCompany($input: UpdateCompanyInput!) {
              updateCompany(input: $input) {
                id
                name
                DashboardInfo
              }
            }`,
                {
                    input: {
                        id: companyId,
                        DashboardInfo: JSON.stringify(dashboardInfo)
                    }
                }
            )
        );
        return result.data.updateCompany;
    }

    async function getCountryCodeForSms(orderId, comapnyLevelCountryCode) {
        let result = await graphql(
            graphqlOperation(queries.getOrder, { id: orderId })
        );
        if (result.data.getOrder && result.data.getOrder.bookingAddress) {
            let addr = JSON.parse(result.data.getOrder.bookingAddress);
            return addr.countryCode;
        } else {
            if (comapnyLevelCountryCode)
                return comapnyLevelCountryCode.toUpperCase();
            else return "CA";
        }
    }

    async function handleAdminDeclination() {
        const user = getUserFromCache();
        if (userHasAdminRole()) {
            const result = await putApi("twiliosmsin", "/bookingreq", {
                body: {
                    bookingReqId: bookingReqData.id,
                    bookingReqRecordType: bookingReqData.recordType,
                    actionUser: user,
                    tostatus: "DECLINED",
                    actionOnBehalf: true //admin
                }
            });
            console.log("bookingreq result", result);

            await auditDeclineBooking(user, bookingReqData, "DECLINED");
            setSnackMsg(result.message);
            setMsgOpen(true);
            //handle result: BIG TODO
        } else {
            setSnackMsg(
                "Only a provider may accept or decline the booking request."
            );
            setMsgOpen(true);
        }
    }

    const setProvider = async (e, value) => {
        setShowSpinner(true);
        if (value) {
            setProviderScheduleId(null);
            const providerSelected = providers.filter((item) => {
                return item.id === value.id;
            });
            setSelectProvider(providerSelected[0]);
            const selectedProvSchedules = await getProviderSchedules(
                providerSelected[0],
                bookingData
            );
            setCheckAvailabilityFails(false);
            setShowSpinner(false);
            console.log("Selected Provider Schedule", selectedProvSchedules);
        } else {
            setSelectProvider(null);
            setProviderScheduleId(null);
            setShowSpinner(false);
        }
    };

    const getProviderSchedules = async (provider, bookingData) => {
        const schedules = await _getSchedules({
            companyId: bookingData.companyId,
            providerId: provider.id
        });
        setProviderSchedules(schedules);
        const bookingScheduleId = makeProviderScheduled(
            bookingData.timeblockid
        );
        if (
            schedules &&
            schedules.length &&
            schedules.map((s) => s.id).includes(bookingScheduleId)
        ) {
            setProviderScheduleId(bookingScheduleId);
        } else setProviderScheduleId(null);
    };

    const fetchProviders = async (data) => {
        setProvidersListloading(true);
        let providersList = await execReadBySortkey({
            opname: "providerByCompany",
            op: providersForOverride,
            id: {
                companyId: data.companyId
            },
            filter: {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            }
        });
        //  providersList = providersList.data.providerByCompany.items;
        if (
            providersList &&
            !providersList.error &&
            providersList.items &&
            providersList.items.length
        ) {
            // providersList.map((item) => {
            //     item.title = `${item.firstname} ${item.lastname} (${item.emailaddress})`;
            // });
            providersList.items.forEach((item) => {
                item.title = `${item.firstname} ${item.lastname} (${item.emailaddress})`;
            });
            setProvidersListloading(false);
            setProviders(providersList.items);
        }
    };

    const fetchRecurringInfo = async (data) => {
        try {
            //First, fetch the repeatApptCreationRecord
            let result = await graphql(
                graphqlOperation(queries.getRepeatApptCreation, {
                    id: data.companyId,
                    datakey: `O-${data.order.id}`
                })
            );

            let repeatApptRecord = result.data.getRepeatApptCreation;
            repeatApptRecord.extented = JSON.parse(
                result.data.getRepeatApptCreation.extented
            );

            //Second, check for an rrule, if there is none then construct one
            //an rrule will exist for any repeatApptCreation record created after MBX-36 was released... unless there was an error ofcourse
            //we'll need to construct an rrule for any recurring booking created before the release of MBX-36
            let rruleString;
            if (!repeatApptRecord?.extented?.rrule) {
                //constructRRuleFromRepeatApprCreation will setRrule , which is then passed to RecurringBookingSelector
                rruleString = await constructRRuleFromRepeatApptCreation(
                    repeatApptRecord,
                    data.bookingDate
                );
            } else {
                rruleString = await getRelevantRRule(
                    data.bookingDate,
                    repeatApptRecord.extented.rrule
                );
                setRruleList(repeatApptRecord.extented.rrule);
                setRrule(rruleString);
                setParsedRrule(parseRRuleForSelector(rruleString));
                setOriginalRrule(rruleString);
            }

            //Converts the rruleString into a "user-friendly", easy to read label
            //it also will setRruleLabel
            const rruleLabel = constructRruleLabel(rruleString, data.startdate);
            setRruleLabel(rruleLabel);
        } catch (e) {
            console.log("Error fetching recurringApptCreation record", e);
        }
    };

    async function constructRRuleFromRepeatApptCreation(
        repeatApptRecord,
        bookingStartDate
    ) {
        const repeatCount = repeatApptRecord.repeatCount;
        let repeatUnit = repeatApptRecord.repeatUnit;
        const daysOfWeek = repeatApptRecord.extented.daysOfWeek;

        let selectedDays;
        const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
        if (daysOfWeek && daysOfWeek.length > 0) {
            selectedDays = days.filter((day, index) => daysOfWeek[index]);
        } else {
            let dateString = bookingStartDate;
            const date = moment(dateString, "YYYY-MM-DD");
            const dayOfWeek = date.day();
            selectedDays = [days[dayOfWeek]];
        }
        if (repeatUnit === "weeks") {
            repeatUnit = "WEEKLY";
        } else if (repeatUnit === "months") {
            repeatUnit = "MONTHLY";
        } else if (repeatUnit === "years") {
            repeatUnit = "YEARLY";
        }
        // Construct the RRULE string
        let rruleString = `FREQ=${repeatUnit.toUpperCase()};`;
        if (repeatCount) {
            rruleString += `INTERVAL=${repeatCount};`;
        }

        if (selectedDays.length > 0) {
            rruleString += `BYDAY=${selectedDays.join(",")}`;
        }

        const rruleList = [rruleString];
        setRruleList(rruleList);
        setRrule(rruleString);
        setParsedRrule(parseRRuleForSelector(rruleString));
        setOriginalRrule(rruleString);
        return rruleString;
    }

    /**
     * Converts an rruleString into a user-friendly sentence for easy comprehension.
     *
     * @param {string} rruleString - The input rruleString to be converted, e.g., "FREQ=MONTHLY;INTERVAL=1;BYDAY=WEEK;UNTIL=2024-03-31".
     * @param {string} [bookingDate] - Optional booking date in the format "YYYY-MM-DD".
     *
     * @returns {string} A user-friendly sentence describing the recurrence rule.
     * For example, "Occurs on the last Friday of every month, until 2024-03-31"
     */
    function constructRruleLabel(rruleString, bookingDate, newRrule) {
        try {
            // Parse the rruleString into an object
            const rruleObject = parseRruleString(rruleString);

            // Extract relevant information
            const freq = rruleObject.FREQ || "";
            const interval = rruleObject.INTERVAL || 1;
            const byDay = rruleObject.BYDAY || "";
            let until = rruleObject.UNTIL || "";
            const xOccur = rruleObject.XOCCUR || "";

            const inputDate = new Date(
                selectedDate ? selectedDate : bookingData.startdate
            );

            const options = {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            };
            const startingDate = inputDate.toLocaleDateString("en-US", options);
            if (until) {
                until = new Date(`${until}T00:00:00`).toLocaleDateString(
                    "en-US",
                    options
                );
            }
            //until = new Date(until);
            // Build the user-friendly label
            let label = `Occurs`;
            //BYDAY
            if (byDay && freq == "WEEKLY") {
                label += ` on ${formatByDay(byDay)}`;
            } else if (byDay == "NUMBER" && freq == "MONTHLY") {
                const date = bookingDate
                    ? new Date(bookingDate)
                    : new Date(selectedDate);
                const dayOfMonth = date.getDate();
                label += ` on day ${dayOfMonth}`;
            } else if (byDay == "WEEK" && freq == "MONTHLY") {
                const date = bookingDate
                    ? new Date(bookingDate)
                    : new Date(selectedDate);
                const dayOfWeek = date.toLocaleDateString("en-US", {
                    weekday: "long"
                });
                const weekOccurence = Math.floor((date.getDate() - 1) / 7) + 1;
                const occurenceLabels = [
                    "first",
                    "second",
                    "third",
                    "fourth",
                    "last"
                ];

                label += ` on the ${
                    occurenceLabels[weekOccurence - 1]
                } ${dayOfWeek}`;
            } else if (freq == "YEARLY") {
                const date = new Date(selectedDate);
                const dayOfWeek = date.toLocaleDateString("en-US", {
                    weekday: "long"
                });
                label += ` on the same day`;
            }
            //FREQ/INTERVAL
            if (freq == "WEEKLY") {
                if (interval > 1) {
                    label += ` every ${interval} weeks`;
                } else {
                    label += ` every week`;
                }
            } else if (freq == "MONTHLY") {
                if (interval > 1) {
                    label += ` every ${interval} months`;
                } else {
                    label += ` of every month`;
                }
            } else if (freq == "YEARY") {
                if (interval > 1) {
                    label += ` every ${interval} years`;
                } else {
                    label += ` every year`;
                }
            }
            if (rruleChanged || newRrule) {
                label += ` starting ${startingDate}`;
            }
            //XOCCUR/UNTILL
            /* if (xOccur && rruleChanged && !xoccurChanged) {
            label += `, for all remaining sessions`;
        } else */ if (xOccur) {
                label += `, for a total of ${xOccur} sessions`;
            } else if (until) {
                label += `, until ${until}`;
            }

            //setRruleLabel(label);
            return label;
        } catch (e) {
            console.log("error constructing rrule label", e);
        }
    }

    /**
     * Retrieves the first rrule from the rrule list that pertains to the given selected date.
     *
     * @param {string} selectedDate - The selected date in 'YYYY-MM-DD' format.
     * @param {Array<string>} rruleList - List of rrules in string format.
     * @returns {string|null} The first relevant rrule, or null if none match the criteria.
     */
    async function getRelevantRRule(selectedDate, rruleList) {
        for (const rrule of rruleList) {
            const untilMatch = /UNTIL=([0-9-]+)|$/.exec(rrule);
            const untilDate = untilMatch ? untilMatch[1] : null;

            if (!untilDate || untilDate >= selectedDate) {
                return rrule;
            }
        }

        // Return null if no matching rule is found
        return null;
    }

    // Helper function to parse rruleString into an object
    function parseRruleString(rruleString) {
        const rruleObject = {};
        const parts = rruleString.split(";");
        parts.forEach((part) => {
            const [key, value] = part.split("=");
            rruleObject[key] = value;
        });
        return rruleObject;
    }

    // Helper function to format BYDAY field
    function formatByDay(byDay) {
        const days = byDay.split(",");
        const formattedDays = days.map(getFullDayString);
        return formattedDays.join(", ");
    }

    function getFullDayString(day) {
        const dayMap = {
            MO: "Monday",
            TU: "Tuesday",
            WE: "Wednesday",
            TH: "Thursday",
            FR: "Friday",
            SA: "Saturday",
            SU: "Sunday"
        };

        return dayMap[day] || day; // If the day is not found in the map, return the original value
    }

    function toggleShowRecurrence() {
        setIsRecurrenceShown(!isRecurrenceShown);
    }

    const handleClose = () => {
        setRecurringBookingAllOrSelectedOverride(false);
    };

    function parseRRuleForSelector(rrule) {
        const components = rrule.split(";");
        const parsedRule = {
            frequency: "",
            interval: 1,
            days: [],
            until: null,
            xoccur: null
        };

        components.forEach((component) => {
            const [key, value] = component.split("=");
            switch (key) {
                case "FREQ":
                    if (value == "WEEKLY") {
                        parsedRule.frequency = "weeks";
                    } else if (value == "MONTHLY") {
                        parsedRule.frequency = "months";
                    } else if (value == "YEARLY") {
                        parsedRule.frequency = "years";
                    }
                    break;
                case "INTERVAL":
                    parsedRule.interval = parseInt(value, 10);
                    break;
                case "BYDAY":
                    if (value) {
                        if (value === "NUMBER" || value === "WEEK") {
                            parsedRule.days = value;
                            break;
                        }
                        const daysArray = value.split(",");
                        daysArray.forEach((day) => {
                            parsedRule.days.push(convertDayToNumber(day));
                        });
                        //sort parsedRule.days in ascending order
                        parsedRule.days.sort((a, b) => a - b);
                    }
                    break;
                case "UNTIL":
                    parsedRule.until = value;
                    break;
                case "XOCCUR":
                    parsedRule.xoccur = value;
                    break;
                default:
                    // Handle other RRULE components if necessary
                    break;
            }
        });

        return parsedRule;
    }

    function convertDayToNumber(day) {
        const dayMappings = {
            MO: 0,
            TU: 1,
            WE: 2,
            TH: 3,
            FR: 4,
            SA: 5,
            SU: 6
        };
        return dayMappings[day];
    }

    function displayListProviders() {
        return (
            <Autocomplete
                style={{ margin: "8px 8px 8px 0px" }}
                id="select-user"
                loadingText="Loading..."
                loading={providersListloading}
                options={providers}
                getOptionLabel={(option) => option.title}
                variant="outlined"
                disabled={overrideStatusClicked || overrideBookingClicked}
                value={selectProvider}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Please select a provider"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            classes: {
                                outlined: classes.outlined,
                                focused: classes.outlined
                            }
                        }}
                        variant="outlined"
                        fullWidth
                    />
                )}
                onChange={setProvider}
            />
        );
    }
    async function handleReqUpdate(tostatus) {
        const user = getUserFromCache();
        if (role === USERROLE.PROVIDER) {
            const result = await putApi("twiliosmsin", "/bookingreq", {
                body: {
                    providerId: user.providerId,
                    bookingReqId: bookingReqData.id,
                    bookingReqRecordType: bookingReqData.recordType,
                    actionUser: user,
                    tostatus,
                    actionOnBehalf: false
                }
            });
            console.log("bookingreq result", result);
            console.log("what is it", bookingReqData, user);
            await auditAcceptBooking(user, bookingReqData, tostatus);
            setSnackMsg(result.message);
            setMsgOpen(true);
            //handle result: BIG TODO
        } else {
            setSnackMsg(
                "Only a provider may accept or decline the booking request."
            );
            setMsgOpen(true);
        }
    }
    function handleAdminAcceptance(brdata) {
        actions.setBookingRequest(brdata);
        actions.setPage("ManageBookingReq");
    }
    async function handleReqAcceptance() {
        setShowBookingReqSpinner(true);
        try {
            setBookingReqActionClicked(true);
            await handleReqUpdate("ACCEPTED");
            setAcceptedModal(false);
            setBookingReqActionClicked(false);

            await readBookingRequests();
        } catch (e) {
            Sentry.captureException(e);
            setBookingReqActionClicked(false);

            setAcceptedModal(false);
            await readBookingRequests();
        }
        setShowBookingReqSpinner(false);
    }

    async function handleReqDeclination() {
        // for declining bookings
        setShowBookingReqSpinner(true);
        try {
            setBookingReqActionClicked(true);
            if (userHasProviderRole()) await handleReqUpdate("DECLINED");
            if (userHasAdminRole()) await handleAdminDeclination();

            setDeclinedModal(false);
            setBookingReqActionClicked(false);

            await readBookingRequests();
        } catch (e) {
            Sentry.captureException(e);
            setBookingReqActionClicked(false);

            setDeclinedModal(false);
            await readBookingRequests();
        }
        setShowBookingReqSpinner(false);
    }

    // function _handleClientSearchChange({ target: { value } }) {
    //     if (tab === 0 || tab === 1) {
    //         setFilteredFutureRows([]);
    //         setFilteredPastRows([]);
    //         setFilteredPastRowsExports([]);
    //         setFilteredFutureRowsExports([]);
    //         setFutureSearchCache([]);
    //         setFutureSearchCacheExports([]);
    //         setPastSearchCache([]);
    //         setFutureSearchToken(null);
    //         setPastSearchToken(null);
    //     }
    //     if (value) {
    //         const parts = value.trim().split(" ");
    //         if (parts) {
    //             setClientFilter(parts);
    //         }
    //         setSearching(true);
    //     } else {
    //         setClientFilter([]);
    //     }
    // }

    // function _handleProviderSearchChange({ target: { value } }) {
    //     if (tab === 0 || tab === 1) {
    //         setFilteredFutureRows([]);
    //         setFilteredPastRows([]);
    //         setFilteredPastRowsExports([]);
    //         setFilteredFutureRowsExports([]);
    //         setFutureSearchCache([]);
    //         setFutureSearchCacheExports([]);
    //         setPastSearchCache([]);
    //         setFutureSearchToken(null);
    //         setPastSearchToken(null);
    //     }
    //     if (value) {
    //         const parts = value.trim().split(" ");
    //         if (parts) {
    //             setProviderFilter(parts);
    //         }
    //         setSearching(true);
    //     } else {
    //         setProviderFilter([]);
    //     }
    // }

    const isCancellationPolicyTimeoutExceeded = (bookingData) => {
        const appntDate = new Date(Date.parse(bookingData.startdate));
        const curDate = new Date();
        const diffMs = appntDate - curDate;
        const diffMins = Math.floor(diffMs / 60000); // minutes
        // If the company has a policy, then we will return the result of that policy
        // If the company has a cancel policy of 0 minutes, this means that the clients
        // can cancel at any time any time before the appnt without penalty.
        // The cancel policy will always be >= 0.
        let result = false;
        if (!isNaN(user.company.cancelPolicyMinsBefore)) {
            result = diffMins < user.company.cancelPolicyMinsBefore;
        }
        console.log("is timeout exceeded?", result);
        return result;
    };

    const isCancellationPolicyTimeoutExceededForRepeating = async (data) => {
        // use order id to find all repeating appts
        let appts = await execReadBySortkey({
            opname: "bookingByOrder",
            op: bookingByOrder,
            id: { orderId: data.order.id },
            skey: {
                startdateTimeblockid: { ge: { startdate: data.startdate } }
            },
            filter: { status: { eq: "SCHEDULED" } }
        });

        appts = appts.items;

        console.log("repeating appts found: ", appts);

        // Now we iterate through each booking instance. IF the booking instance is the first instance of the
        // scheduled bookings, then we will pass whether they missed the cancellation periood for only that first instance.
        if (appts[0]) {
            let booking = appts[0];
            booking = { ...booking, client: data.client }; // add client user info to booking object
            return isCancellationPolicyTimeoutExceeded(booking);
        } else return false;
    };
    const csvData = (bookingList) => {
        try {
            let rows = [];
            if (bookingList.items && bookingList.items.length > 0) {
                bookingList.items.map(async (item) => {
                    enrichBookingData(item);
                });
                rows = bookingList.items;
            }
            const csvData = rows.map((item) => {
                //Determines if the booking was cancelled by the client or provider
                const {
                    id: ID,
                    serviceName: SERVICENAME,
                    startdate: STARTDATE,
                    duration: DURATION,
                    adminEmail: ADMINEMAIL,
                    status: STATUS,
                    cancelledByEmail: CANCELLEDBY,
                    initialCharge: INITIALCHARGE,
                    additionalCharges: ADDITIONALCHARGE,
                    orderNumber: ORDERNUMBER,
                    dateCreated: DATECREATED,
                    updatedAt: DATEUPDATED,
                    bookingAddressoneLine: BOOKINGADDRESS,
                    bookingState: BOOKINGPROVSTATE,
                    bookingPostalCode: BOOKINGPOSTALCODE,
                    bookingCountry: BOOKINGCOUNTRY,
                    clientName: CLIENTNAME,
                    clientFirstName: CLIENTFIRSTNAME,
                    clientLastName: CLIENTLASTNAME,
                    clientEmail: CLIENTEMAIL,
                    providerName: PROVIDERNAME,
                    providerFirstName: PROVIDERFIRSTNAME,
                    providerLastName: PROVIDERLASTNAME,
                    providerEmail: PROVIDEREMAIL,
                    ratingstars: RATING,
                    providerTip: PROVIDERTIP,
                    note: NOTES
                } = item;

                const newItem = {
                    ID,
                    SERVICENAME,
                    STARTDATE: moment(STARTDATE).format("YYYY-MM-DD hh:mm A"),
                    DURATION,
                    BOOKINGADDRESS,
                    BOOKINGPROVSTATE,
                    BOOKINGPOSTALCODE,
                    BOOKINGCOUNTRY,
                    ADMINEMAIL: ADMINEMAIL == "" ? "client" : ADMINEMAIL,
                    STATUS,
                    CANCELLEDBY,
                    INITIALCHARGE: Number(INITIALCHARGE)?.toFixed(2),
                    ADDITIONALCHARGE: Number(ADDITIONALCHARGE)?.toFixed(2),
                    ORDERNUMBER,
                    DATECREATED,
                    DATEUPDATED:
                        moment(DATEUPDATED).format("YYYY-MM-DD hh:mm A"),
                    CLIENTNAME,
                    CLIENTFIRSTNAME,
                    CLIENTLASTNAME,
                    CLIENTEMAIL,
                    PROVIDERNAME,
                    PROVIDERFIRSTNAME,
                    PROVIDERLASTNAME,
                    PROVIDEREMAIL,
                    RATING,
                    PROVIDERTIP,
                    NOTES
                };
                return newItem;
            });
            if (csvData && csvData.length) return csvData;
        } catch (e) {
            console.log(e);
        }
    };

    const getClientData = async (email) => {
        const filter = {
            and: [{ active: { ne: false } }, { deleted: { ne: true } }]
        };

        let result = await graphql(
            graphqlOperation(queries.userByCompany, {
                companyId: user.company.id,
                roleEmailaddress: {
                    eq: {
                        role: `CLIENT`,
                        emailaddress: email.trim()
                    }
                },
                filter
            })
        );

        // let result = await graphql(
        //     graphqlOperation(queries.listUsers, { filter, limit })
        // );
        const _user = !!result ? result.data.userByCompany.items[0] : null;

        console.log("user: ", _user);

        result = await graphql(
            graphqlOperation(queries.clientByUserId, { userId: _user.id })
        );
        let client = !!result ? result.data.clientByUserId.items[0] : null;

        console.log("clientByUserId: ", client);

        // create new client
        if (!client) {
            const clientData = {
                userId: _user.id,
                companyId: user.company.id,
                clientUserId: _user.id,
                clientCompanyId: user.company.id,
                accountbalance: 0.0
            };

            result = await graphql(
                graphqlOperation(mutations.createClient, { input: clientData })
            );
            client = result.data.createClient;

            console.log("client created: ", client);
        }

        console.log("client: ", client);

        return client;
    };

    const csvDataPendingExpired = (bookingList) => {
        try {
            let rows = [];
            if (bookingList.items && bookingList.items.length > 0) {
                bookingList.items.map(async (item) => {
                    let bookingAddress = item.data.bookingAddress;
                    item.serviceName = item.data.serviceType.name;
                    item.lastUpdated = moment(item.updatedAt).format(
                        "YYYY-MM-DD hh:mm A"
                    );
                    item.serviceDuration = item.data.serviceType.minutes;
                    item.bookingAddress = bookingAddress
                        ? bookingAddress.addrOneLine
                        : "";
                    item.bookingState = bookingAddress
                        ? bookingAddress.state
                        : "";
                    item.bookingPostalCode = bookingAddress
                        ? bookingAddress.postalCode
                        : "";
                    item.bookingCountry = bookingAddress
                        ? bookingAddress.country
                        : "";
                    item.initialCharge = item.data.osd.subtotal;
                    item.finalCharge = item.data.osd.total;
                    item.createdAt = moment(item.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    );
                    item.alternateProviders =
                        item.tryOtherProviders +
                        " (" +
                        item.numberOfOthers +
                        ")";
                    item.clientName = `${
                        item.data.client && item.data.client.user
                            ? item.data.client.user.firstname
                            : ""
                    } ${
                        item.data.client && item.data.client.user
                            ? item.data.client.user.lastname
                            : ""
                    }`;
                    item.clientFirstName = `${
                        item.data.client && item.data.client.user
                            ? item.data.client.user.firstname
                            : ""
                    }`;
                    item.clientLastName = `${
                        item.data.client && item.data.client.user
                            ? item.data.client.user.lastname
                            : ""
                    }`;
                    item.clientEmail =
                        item.data.client &&
                        item.data.client.user &&
                        item.data.client.user.emailaddress
                            ? item.data.client.user.emailaddress
                            : "N/A";
                    item.clientMobilePhone =
                        item.data.client &&
                        item.data.client.user &&
                        item.data.client.user.mobilephone
                            ? item.data.client.user.mobilephone
                            : "N/A";
                    item.clientHomePhone =
                        item.data.client &&
                        item.data.client.user &&
                        item.data.client.user.homephone
                            ? item.data.client.user.homephone
                            : "N/A";
                    item.clientWorkPhone =
                        item.data.client &&
                        item.data.client.user &&
                        item.data.client.user.workphone
                            ? item.data.client.user.workphone
                            : "N/A";
                    item.providerName =
                        item.data.provider &&
                        `${item.data.provider.firstname} ${item.data.provider.lastname}`;
                    item.providerFirstName = item.data.provider
                        ? item.data.provider.firstname
                        : "";
                    item.providerLastName = item.data.provider
                        ? item.data.provider.lastname
                        : "";
                    item.providerEmail = item.data.provider.emailaddress;
                });
                rows = bookingList.items;
            }
            const csvDataPendingExpired = rows.map((item) => {
                const {
                    id: ID,
                    serviceName: SERVICENAME,
                    lastUpdated: LASTUPDATED,
                    serviceDuration: DURATION,
                    bookingAddress: BOOKINGADDRESS,
                    bookingState: BOOKINGPROVSTATE,
                    bookingPostalCode: BOOKINGPOSTALCODE,
                    bookingCountry: BOOKINGCOUNTRY,
                    bookingType: BOOKINGTYPE,
                    status: STATUS,
                    initialCharge: INITIALPRICE,
                    finalCharge: FINALPRICE,
                    numberOfBookings: NUMBEROFBOOKINGS,
                    createdAt: CREATEDAT,
                    clientName: CLIENTNAME,
                    clientFirstName: CLIENTFIRSTNAME,
                    clientLastName: CLIENTLASTNAME,
                    clientEmail: CLIENTEMAIL,
                    clientMobilePhone: CLIENTMOBILEPHONE,
                    clientHomePhone: CLIENTHOMEPHONE,
                    clientWorkPhone: CLIENTWORKPHONE,
                    providerName: PROVIDERNAME,
                    providerFirstName: PROVIDERFIRSTNAME,
                    providerLastName: PROVIDERLASTNAME,
                    providerEmail: PROVIDEREMAIL
                } = item;
                const newItem = {
                    ID,
                    SERVICENAME,
                    LASTUPDATED,
                    DURATION,
                    BOOKINGADDRESS,
                    BOOKINGPROVSTATE,
                    BOOKINGPOSTALCODE,
                    BOOKINGCOUNTRY,
                    BOOKINGTYPE,
                    STATUS,
                    INITIALPRICE,
                    FINALPRICE,
                    NUMBEROFBOOKINGS,
                    CREATEDAT,
                    CLIENTNAME,
                    CLIENTFIRSTNAME,
                    CLIENTLASTNAME,
                    CLIENTEMAIL,
                    CLIENTMOBILEPHONE,
                    CLIENTHOMEPHONE,
                    CLIENTWORKPHONE,
                    PROVIDERNAME,
                    PROVIDERFIRSTNAME,
                    PROVIDERLASTNAME,
                    PROVIDEREMAIL
                };
                return newItem;
            });
            if (csvDataPendingExpired && csvDataPendingExpired.length)
                return csvDataPendingExpired;
        } catch (e) {
            console.log("error", e);
        }
    };

    const getProviderData = async (email) => {
        const filter = {
            and: [
                { active: { ne: false } },
                { deleted: { ne: true } },
                { emailaddress: { eq: email.trim() } }
            ]
        };

        const result = await execReadByPK({
            opname: "providerByCompany",
            op: queries.providerByCompany,
            id: {
                companyId: user.company.id
            },
            filter: filter
        });

        // const result = await graphql(
        //     graphqlOperation(queries.listProviders, { filter, limit })
        // );
        const provider = !!result ? result.items[0] : null;
        console.log("provider: ", provider);

        return provider;
    };

    const checkTimeblock = async (startTime, endTime, bkgstart, service) => {
        let duration = service.minutes;
        let partsStart = bkgstart.trim().split(":");
        let hourint = Number.parseInt(partsStart[0]);
        let minsint = Number.parseInt(partsStart[1]);

        let starttime = moment().hour(hourint).minute(minsint).format("HH:mm");
        let endtime = moment()
            .hour(hourint)
            .minute(minsint)
            .add(duration, "minutes")
            .format("HH:mm");

        if (starttime < startTime || starttime > endTime || endtime > endTime) {
            console.log(`invalid appointment from ${starttime}-${endtime}`);
            return true;
        } else return false;
    };

    // this function retrieves location info from geocoder api
    const getLocationData = async (booking) => {
        let params = {
            app_id: APP_ID_HERE,
            app_code: APP_CODE_HERE,
            searchtext: `${booking.address} ${booking.state} ${booking.zipcode} ${booking.country}`
        };

        try {
            const response = await axios.get(
                "https://geocoder.api.here.com/6.2/geocode.json",
                { params: params }
            );
            const view = response.data.Response.View;
            if (view.length > 0 && view[0].Result.length > 0) {
                return view[0].Result[0].Location;
            }
        } catch (err) {
            Sentry.captureException(err);
            console.log(`Couldn't find address ${params.searchtext}`, err);
        }
    };

    const handleCreateTimeblock = async (booking) => {
        // Create Timeblock
        if (booking) {
            const tbendtime = getTimeblockEndTime(booking.service, booking);
            const bookedtimeblockData = {
                companyId: booking.companyId,
                startDate: moment(booking.date, [
                    "MM-DD-YYYY",
                    "DD-MM-YYYY"
                ]).format("YYYY-MM-DD"),
                startTime: booking.time,
                endTime: tbendtime,
                type: "BOOKED",
                scheduleId: booking.key,
                status: "CONFIRMED"
            };
            const tb = await createTimeblock(bookedtimeblockData);
            console.log("timeblock created: ", tb);

            return !!tb ? tb : null;
        }
    };

    const handleCreateOrderBooking = async (booking) => {
        let parts = booking.timeblock.startTime.split(":");
        let hours12 =
            Number.parseInt(parts[0]) > 12
                ? Number.parseInt(parts[0]) - 12
                : Number.parseInt(parts[0]);
        let ampm = Number.parseInt(parts[0]) > 12 ? "PM" : "AM";
        let time12 = `${hours12.toString().replace(/^0+/, "")}:${
            parts[1]
        } ${ampm}`;

        let order = {};
        let result;

        if (!booking.order) {
            const orderNo = await getNewOrderNo(booking.companyId);

            //Create Order
            const orderData = {
                bookingAddress: JSON.stringify(booking.bookingAddress),
                desc: `Booking for ${booking.service.name} on ${moment(
                    booking.timeblock.startDate
                ).format("MMM DD, YYYY")} at ${time12} `,
                type: "SINGLE",
                companyId: booking.companyId,
                providerId: booking.provider.id,
                orderProviderId: booking.provider.id,
                status: "PAID",
                orderCompanyId: booking.companyId,
                clientId: booking.client.id,
                orderClientId: booking.client.id,
                orderNo: orderNo
            };
            console.log("create order input:", orderData);

            result = await graphql(
                graphqlOperation(mutations.createOrder, { input: orderData })
            );
            order = result && result.data.createOrder;
            console.log("order created:", order);

            if (result && result.error) {
                return {
                    error: result.error
                };
            }
        } else {
            order = { id: booking.order.id };
        }

        //Create Booking
        const bookingData = {
            desc: booking.service.name,
            startdate: moment(
                `${booking.timeblock.startDate}T${getBookingTime(
                    booking.timeblock.startTime
                )}:00`
            ).format(),
            minutes: booking.service.minutes,
            companyId: booking.companyId,
            bookingOrderId: order.id,
            orderId: order.id,
            orderType: "SINGLE",
            isVirtual: false,
            bookingServicetypeId: booking.service.id,
            bookingProviderId: booking.provider.id,
            bookingClientId: booking.client.id,
            bookingCompanyId: booking.companyId,
            timeblockid: booking.timeblock.id,
            providerId: booking.provider.id,
            clientId: booking.client.id,
            status: booking.status.trim().toUpperCase(),
            location: booking.location
        };
        console.log("create booking input:", bookingData);

        result = await graphql(
            graphqlOperation(mutations.createBooking, { input: bookingData })
        );
        const newbooking = result && result.data.createBooking;
        console.log("booking created", newbooking);

        if (!!order && !!newbooking) {
            return {
                success: true,
                order,
                booking: newbooking
            };
        } else {
            return {
                success: false
            };
        }
    };

    async function getNewOrderNo(companyId) {
        const ordNoResp = await getJsonApi("bookingapi", "/id", {
            queryParams: {
                companyId,
                idName: "ORDERNO"
            }
        });
        if (ordNoResp && ordNoResp.success) return ordNoResp.id;
        else {
            return Number(new String(Date.now()).slice(-8));
        }
    }

    const handleCreateBooking = async (booking, schedules, orders) => {
        // filter all timeblocks in order to find specific provider timeblock (for imports, this will end up being the default SC record that was created)
        let schedule = schedules.items.filter(
            (s) => s.providerId === booking.provider.id
        );
        schedule = schedule[0];
        console.log("Provider schedule found: ", schedule);
        // take provider timeblock composite key and use to create a BK record
        const compositeKey = `C-${booking.companyId}|P-${schedule.providerId}::${schedule.scheduleinfo}`;

        let order = orders.items.filter(
            (o) => o.clientId === booking.client.id
        );
        order = order[0];
        console.log("Client order found: ", order);
        // create BK record
        booking = {
            ...booking,
            key: compositeKey
        };
        console.log("current booking object: ", booking);
        const createTimeblockResult = await handleCreateTimeblock(booking);

        booking = {
            ...booking,
            timeblock: createTimeblockResult,
            order: order
        };
        console.log("current booking object: ", booking);

        const bookingResult = await handleCreateOrderBooking(booking);

        return bookingResult;
    };

    const handleReadCSV = async (res) => {
        let bookingsAdded = [],
            missingData = false,
            noClient = false,
            noProvider = false,
            noService = false,
            noTimeblock = false;
        const startTime = moment().hour(8).minute(0).format("HH:mm");
        const endTime = moment().hour(22).minute(0).format("HH:mm"); // default 8am - 10pm
        try {
            logger.debug("Parsing Complete: ", res);
            if (!!res) {
                const companyId = user.company.id;
                // call to get all timeblocks for a company
                const schedules = await execReadBySortkey({
                    opname: "schedulesByCompany",
                    op: scheduleQueries.schedulesByCompanyForCount,
                    id: { companyId },
                    skey: { scheduleinfo: { beginsWith: "SC|" } },
                    filter: { active: { ne: false } },
                    limit: limit
                });
                console.log("company timeblocks: ", schedules);

                const listOrd = `query OrdersByCompanyOrderNo(
                    $companyId: String
                    $orderNo: ModelIntKeyConditionInput
                    $sortDirection: ModelSortDirection
                    $filter: ModelOrderFilterInput
                    $limit: Int
                    $nextToken: String
                  ) {
                    ordersByCompanyOrderNo(
                      companyId: $companyId
                      orderNo: $orderNo
                      sortDirection: $sortDirection
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                    ) {
                        items {
                            id
                        companyId
                        clientId
                        }
                        nextToken
                  }
                }
                `;

                const orders = await execReadByPK({
                    opname: "ordersByCompanyOrderNo",
                    op: listOrd,
                    id: { companyId: companyId },
                    limit: limit
                });
                console.log("company orders: ", orders);

                for (let booking of res.data) {
                    // for each booking,
                    if (
                        !booking.clientemail ||
                        !booking.provideremail ||
                        !booking.bookingdate ||
                        !booking.servicetype ||
                        !booking.starttime
                    ) {
                        missingData = true;
                    } else {
                        setShowLoading(true);

                        //get client data
                        const client = await getClientData(booking.clientemail);
                        //get provider data
                        const provider = await getProviderData(
                            booking.provideremail
                        );
                        // get service data
                        let service = serviceTypeData.filter(
                            (s) => s.name === booking.servicetype.trim()
                        );
                        service = service[0];

                        // check if booking falls within provider default schedule
                        let parts = booking.starttime.trim().split(":");
                        let hour =
                            Number.parseInt(parts[0]) < 10
                                ? `0${Number.parseInt(parts[0])}`
                                : `${Number.parseInt(parts[0])}`;
                        let mins = parts[1];

                        if (!!service) {
                            noTimeblock = await checkTimeblock(
                                startTime,
                                endTime,
                                booking.starttime,
                                service
                            );
                        }

                        if (!client) {
                            noClient = true;
                        } else if (!provider) {
                            noProvider = true;
                        } else if (!service) {
                            noService = true;
                        } else if (!noTimeblock) {
                            // get location data
                            const location = !!booking.address
                                ? await getLocationData(booking)
                                : null;
                            let street = !!location
                                ? `${
                                      !!location.Address.HouseNumber
                                          ? location.Address.HouseNumber
                                          : ""
                                  } ${
                                      location.Address.Street &&
                                      location.Address.Street
                                  } ${
                                      location.Address.suite
                                          ? location.Address.suite
                                          : ""
                                  }`
                                : null;
                            let addressoneline = !!location
                                ? `${street} ${location.Address.City} ${location.Address.State} ${location.Address.PostalCode}`
                                : null;
                            let bookingAddress = {
                                addrOneLine: addressoneline,
                                state: location.Address.State,
                                postalCode: location.Address.PostalCode,
                                country: booking.country
                            };
                            booking = {
                                ...booking,
                                bookingAddress,
                                location: addressoneline,
                                client: client,
                                provider: provider,
                                service: service,
                                companyId: user.company.id,
                                time: `${hour}:${mins}`,
                                date: booking.bookingdate.trim(),
                                company: provider.company
                            };

                            console.log("Current booking object: ", booking);

                            const bookingResult = await handleCreateBooking(
                                booking,
                                schedules,
                                orders
                            );
                            console.log(
                                "booking process complete",
                                bookingResult
                            );
                            if (bookingResult.success === true)
                                bookingsAdded.push(bookingResult.booking);
                        }
                    }
                }

                //refresh booking list
                _getFutureBookingsList();
                _getPastBookingsList();

                if (res.data.length === bookingsAdded.length) {
                    let message =
                        bookingsAdded.length === 0 || bookingsAdded.length > 1
                            ? `${bookingsAdded.length} bookings were imported. You can view the imported bookings below.`
                            : `${bookingsAdded.length} booking was imported. You can view the imported booking below.`;
                    setSnackMsg(message);
                    setMsgOpen(true);
                } else if (res.data.length > bookingsAdded.length) {
                    setSnackMsg(
                        `${bookingsAdded.length} bookings were imported out of ${res.data.length}. You can view the imported bookings below.`
                    );
                    setMsgOpen(true);
                }
                if (!!missingData) {
                    setSnackMsg(
                        "Missing booking data. Unable to import booking."
                    );
                    setMsgOpen(true);
                } else if (!!noClient) {
                    setSnackMsg(
                        "Could not find client. Unable to import booking."
                    );
                    setMsgOpen(true);
                } else if (!!noProvider) {
                    setSnackMsg(
                        "Could not find provider. Unable to import booking."
                    );
                    setMsgOpen(true);
                } else if (!!noService) {
                    setSnackMsg(
                        "Could not find service type. Unable to import booking."
                    );
                    setMsgOpen(true);
                } else if (!!noTimeblock) {
                    setSnackMsg(
                        "Booking time is outside of provider schedule. Unable to import booking."
                    );
                    setMsgOpen(true);
                }
            }
        } catch (err) {
            Sentry.captureException(err);
            logger.error(
                "*** an error occurred. The error was: " + err.message
            );
            setSnackMsg(
                "Unable to import bookings. The error was: " + err.message
            );
            setMsgOpen(true);
            setShowLoading(false);
            return;
        }
        setShowLoading(false);
    };

    //From: User clicks continue on Dialogue to continue cancellation
    //Does: Cancels booking but does not attempt to apply refund
    //Returns: N/a
    async function handleContinueCancellation(data) {
        console.log("asdf handleContinueCancellation");
        setShowCancelBookingSpinner(true);
        setCancelBookingClicked(true);
        setRefundedContinueClicked(true);
        //How to know if cancel ongoing or single?
        console.log("asdf booking data", data);
        const user = getUserFromCache();
        data.cancelledAt = new Date();
        data.cancelledBy = user.id;

        try {
            let appts = await execReadBySortkey({
                opname: "bookingByOrder",
                op: bookingByOrder,
                id: { orderId: data.order.id },
                skey: {
                    startdateTimeblockid: { ge: { startdate: data.startdate } }
                },
                filter: { status: { eq: "SCHEDULED" } }
            });

            appts = appts.items;
            console.log("asdf appts");
            //Appts approach is used in order to be able to handle repeating cancellation. Check logs to see if valid.

            console.log(
                "asdf cancelOnlySelectedBooking",
                cancelOnlySelectedBooking
            );
            if (appts.length === 1 || cancelOnlySelectedBooking) {
                //There is only one appointment - cancellation single process
                const result = await handleCancelNoRefund(appts[0]);
                if (!result.success) {
                    console.log(
                        "asdf Error cancelling ongoing bookings without refund!"
                    );
                    setSnackMsg(
                        "Error cancelling refunded ongoing bookings! " +
                            result.messages
                    );
                    setMsgOpen(true);
                    return;
                }
                setCancelOnlySelectedBooking(false);
                //Send email for single cancellation
            } else {
                //Multiple cancellations process
                try {
                    for (let i = 0; i < appts.length; i++) {
                        const result = await handleCancelNoRefund(appts[i]);
                        if (!result.success) {
                            console.log(
                                "asdf Error cancelling ongoing bookings without refund!"
                            );
                            setSnackMsg(
                                "Error cancelling refunded ongoing bookings! " +
                                    result.messages
                            );
                            setMsgOpen(true);
                            return;
                        }
                    }
                } catch (e) {
                    console.log("asdf error cancelling ongoing bookings");
                    setSnackMsg(
                        "Unexpected error cancelling refunded ongoing bookings!"
                    );
                    setMsgOpen(true);
                }
                try {
                    const datakey = `O-${data.order.id}`;

                    const input = {
                        id: data.company.id,
                        datakey: datakey,
                        active: false
                    };
                    // flag for no future bookings
                    const res = await graphql(
                        graphqlOperation(mutations.updateRepeatApptCreation, {
                            input
                        })
                    );
                } catch (e) {
                    console.log("asdf error updating for no future bookings!");
                    setSnackMsg(
                        "Unexpected error occured while attempting to cancel future booking creation for refunded bookings!"
                    );
                    setMsgOpen(true);
                }
            }
            //Send email process
            try {
                //Process for sending email for multiple cancellations
                let booking_date = "";
                if (data.TimeDisplayInfo) {
                    let tdinfo = JSON.parse(data.TimeDisplayInfo);
                    booking_date = `${tdinfo.dt_long_disp} at ${
                        tdinfo.tm_st_disp
                    } ${tdinfo.tz_abbr_disp ? tdinfo.tz_abbr_disp : ""}`;
                } else {
                    booking_date = moment(data.startdate).format(
                        "MMM DD, YYYY [at] h:mm a"
                    );
                }

                const companyId = data.company.id;
                const companySubdomain = await getCompanySubdomain(companyId);
                const bookingUrl = companySubdomain;
                console.log("asdf bookingUrl is ", bookingUrl);
                const clientEmail = data.client.user.emailaddress;
                const clientFirstName = data.client.user.firstname;
                const serviceType = data.servicetype.name;
                const clientLastInitial =
                    data.client.user.lastname.charAt(0) + ".";
                const providerFirstName = data.provider.firstname;
                const providerLastInitial =
                    data.provider.lastname.charAt(0) + ".";
                const userFirstname = user.firstname;
                const userLastInitial = user.lastname.charAt(0);
                const companyName = data.company.name;
                const company = data.company;
                const companyEmail = data.company.emailaddress;
                const companyLogo = data.company.logoUrl;
                const companyColour = data.company.primaryColor;
                const dataObj = {
                    client_firstname: clientFirstName,
                    servicetype_name: serviceType,
                    booking_date,
                    provider_firstname: providerFirstName,
                    provider_lastnameinitial: providerLastInitial,
                    user_firstname: userFirstname,
                    user_lastnameinitial: userLastInitial,
                    company_email: companyEmail,
                    company_name: companyName,
                    booking_url: bookingUrl,
                    isRegisteredUser: data.client.user.registered
                };

                const bccAddresses = [];
                bccAddresses.push(data.provider.emailaddress);
                if (comapnyAdmins.length > 0) {
                    for (const companyAdmin of comapnyAdmins) {
                        bccAddresses.push(companyAdmin.emailaddress);
                    }
                }
                console.log("asdf sending email");

                if (appts.length === 1) {
                    //Email for single appointment cancellation
                    const result = await postApi(
                        "sendtwilioemail",
                        "/sendtwilioemailtemplate",
                        {
                            body: {
                                templateName: "appt-cancellation-single.html",
                                subject: `Appointment cancellation notification - ${companyName} Client ${clientFirstName} ${clientLastInitial}`,
                                body: null,
                                toAddresses: [clientEmail],
                                bccAddresses: bccAddresses,
                                replyTo: companyEmail,
                                companyName: companyName,
                                companyId: companyId,
                                dataObj: dataObj,
                                companyLogoUrl: companyLogo,
                                companyColor: companyColour,
                                authSiteUrl:
                                    process.env.REACT_APP_AUTH_SITE_URL,
                                bookingUrl: bookingUrl,
                                isRegisteredUser: data.client.user.registered
                            }
                        }
                    );
                    console.log(
                        "asdf cancel single no refunded result email send is: ",
                        result
                    );
                } else {
                    //Email for ongoing appointment cancellation
                    const result = await postApi(
                        "sendtwilioemail",
                        "/sendtwilioemailtemplate",
                        {
                            body: {
                                templateName: "appt-cancellation-ongoing.html",
                                subject: `Appointment cancellation notification - ${companyName} Client ${clientFirstName} ${clientLastInitial}`,
                                body: null,
                                toAddresses: [clientEmail],
                                bccAddresses: bccAddresses,
                                replyTo: companyEmail,
                                companyName: companyName,
                                companyId: companyId,
                                company: company,
                                dataObj: dataObj,
                                companyLogoUrl: companyLogo,
                                companyColor: companyColour,
                                authSiteUrl:
                                    process.env.REACT_APP_AUTH_SITE_URL,
                                bookingUrl: bookingUrl
                            }
                        }
                    );
                    console.log(
                        "asdf email sent from refunded ongoing cancellations",
                        result
                    );
                }
            } catch (e) {
                console.log(
                    "asdf error sending email for refunded ongoing bookings!"
                );
                setSnackMsg(
                    "Unexpected error occured while sending client Cancellation Notification for refunded ongoing bookings!"
                );
            }
            setShowCancelBookingSpinner(false);
            setCancelOnlySelectedBooking(false);
            setRefundedContinueClicked(false);
            setSnackMsg("Your appointments have been cancelled successfully");
            setMsgOpen(true);
            setDisplayRefundedDialogue(false);
            // _getFutureBookingsList();
            // _getPastBookingsList();
            // handleSearchBookings();
            setCancelBookingClicked(false);
        } catch (e) {
            setRefundedContinueClicked(false);
            console.log("asdf error: ", e);
            setSnackMsg("Unexpected error has occured! Error is: ", e);
            setMsgOpen(true);
            setCancelBookingClicked(false);
        }
    }

    function handleCancelCancellation() {
        setRefundedCancelClicked(true);
        setDisplayRefundedDialogue(false);
    }
    function handleOpenRefundedDialogue() {
        //Close previous dialogue (for ongoing)
        setCancelModal(false);
        setCancelRepeating(false);
        setRepeatingTimeoutExceeded(false);
        setShowCancelBookingSpinner(false);

        setRefundedCancelClicked(false);
        setRefundedContinueClicked(false);
        setDisplayRefundedDialogue(true);
    }

    const handleOnError = (err) => {
        console.log(err);
    };

    const handleImportOffer = () => {
        setImportDialog(true);
    };

    function handleImportDialogAccept() {
        setImportDialog(false);
        fileInput.current.click();
    }

    function handleProviderScheduleChange(event) {
        setProviderScheduleId(event.target.value);
        console.log("this changes");
    }

    function makeProviderScheduled(bookingDataTimeblockid) {
        const timeblock = bookingDataTimeblockid.split("::");
        let scheduleinfoParts = timeblock[1].split("|");
        return `${timeblock[0]}::${scheduleinfoParts[2]}|${scheduleinfoParts[3]}|${scheduleinfoParts[4]}`;
    }

    function handleBRDetailsClose() {
        setDetailsModal(false);
    }

    function handleNoteDialog(row /* , rowOrder, rowBooking */) {
        /* if (row.Notes[0]) { */

        /* const result = await graphql(
                graphqlOperation(queries.ordersByCompanyOrderNo, {
                    companyId: row.companyId,
                    orderNo: { eq: parseInt(row.orderNumber) }
                    filter: input.filter,
                    limit: 5
                })
            );
            console.log("omar this is the result dude", result); */

        /* const orderDetails = result.data.ordersByCompanyOrderNo.items[0];
            await setNoteDialogContent(orderDetails.clientnotes);
            await setNoteDialogCreatedBy(row.clientName);
            await setNoteDialogDateCreated(dateCreated.format("lll"));
            await setShowNoteDialog(true); */
        /* await getOrderData(row.id); */
        if (row?.order?.Notes) {
            console.log("YES Notes");
            const noteDialogContent = JSON.parse(row.order.Notes);
            setNoteDialogContent(noteDialogContent[0]);
        } else if (row?.Notes) {
            console.log("no order Notes, yes booking Notes");
            setNoteDialogContent({
                createdAt: row.createdAt,
                createdBy:
                    row.client.user.firstname + " " + row.client.user.lastname,
                updatedAt: row.createdAt,
                updatedBy:
                    row.client.user.firstname + " " + row.client.user.lastname,
                notes: row.Notes[0].notes
            });
        } else {
            console.log("no Notes, no clientnotes");
            setNoteDialogContent("");
        }
        setNoteClientName(
            row.client.user.firstname + " " + row.client.user.lastname
        );
        setNoteServiceName(row.desc);
        setNoteBookingStartDate(
            row.startDateDesc.replace(/\s+\(GMT[^)]+\)/, "")
        );
        setNoteOrderNumber(row.order.id);
        setRowBookingId(row.id);
        /* await setNoteDialogDateCreated(
            row.Notes ? row.Notes[0].createdAt : null;
        ); */
        setShowNoteDialog(true);
        /* } else {
            setSnackMsg("No notes found for this booking");
            setMsgOpen(true);
            return;
        } */
    }

    const handleCloseBookingNotesDialog = () => {
        setShowNoteDialog(false);
    };

    function onRruleChange(newRrule) {
        if (newRrule !== rrule) {
            setRruleChanged(true);
        }
        setRrule(newRrule);
        setParsedRrule(parseRRuleForSelector(newRrule));

        const rruleLabel = constructRruleLabel(
            newRrule,
            null,
            newRrule !== rrule
        );
        setRruleLabel(rruleLabel);
        //bookingState.rrule = rrule;
    }

    function onXoccurChange(value) {
        setXoccurChanged(value);
        setRruleChanged(true);

        /* const rruleLabel = constructRruleLabel(rrule);
        setRruleLabel(rruleLabel); */
    }

    async function handleOverrideRecurringBookings(bookingState) {
        setRecurringBookingAllOrSelectedOverride(false);
        setShowOverrideBookingSpinner(true);
        setOverrideBookingClicked(true);
        const startDate = moment(selectedDate).format("YYYY-MM-DD");
        const startTime = moment(selectedDate).format("HH:mm");
        const endTime = moment(selectedDate)
            .add(bookingState.minutes, "minutes")
            .format("HH:mm");
        let pksk = bookingState.timeblockid.split(SCHEDPKSKSPLITAT);
        let latitude,
            longitude,
            isVirtual =
                bookingState.isVirtual !== undefined && bookingState.isVirtual,
            timezone = bookingState.timezone,
            bookingLocation = bookingState.location;
        let locationIdWithAbbreviation = extractLocationIdFromScheduleinfo(
            pksk[1]
        );
        let isRemoteLocation = locationIdWithAbbreviation.startsWith("PL-")
            ? true
            : false;
        let locationId = locationIdWithAbbreviation.slice(3);

        console.log("this is location", location, address);
        if (
            // the location was changed to a physical company location
            locationChanged &&
            location &&
            location.virtual !== undefined &&
            !location.virtual &&
            location.id !== "0"
        ) {
            console.log("override 1", location);
            bookingLocation = location.addressoneline;
            isVirtual = false;
            timezone = location.timezone;
            latitude = location.latitude;
            longitude = location.longitude;
            locationId = location.id;
        } else if (
            // the location was changed to the customers location
            locationChanged &&
            location &&
            location.id === "0" &&
            address
        ) {
            console.log("override 2", location, address);
            const providerLocation = await getOrCreateProviderLocation({
                address,
                provider: selectProvider
                    ? selectProvider
                    : bookingState.provider
            });
            bookingLocation = address.addressOneLine;
            isVirtual = false;
            timezone = await getTimezoneIdByGeoLoc({
                lat: address.coords.lat,
                lng: address.coords.lng
            });
            latitude = address.coords.lat;
            longitude = address.coords.lng;
            locationId = providerLocation;
        } else if (
            // the location was changed to a virtual company location
            locationChanged &&
            location &&
            location.virtual &&
            location.id !== "0"
        ) {
            console.log("override 3", location);
            bookingLocation = "Virtual Appointment";
            isVirtual = true;
            timezone = location.timezone;
            latitude = location.latitude;
            longitude = location.longitude;
            locationId = location.id;
        } else {
            const locationData = isRemoteLocation
                ? await getProviderLocation(locationId)
                : await getCompanyLocation(locationId);
            latitude = locationData.latitude;
            longitude = locationData.longitude;
        }

        let dateInfo = await getBookingDateDescription(
            selectedDate,
            timezone,
            bookingState.minutes
        );

        try {
            let body = {
                companyId: bookingState.companyId,
                orderId: bookingState.orderId,
                selectedSlot: {
                    scheduleId: pksk[1],
                    dateInfo: dateInfo,
                    slot12: dateInfo.tm_st_disp,
                    label: startTime,
                    tz: timezone
                },
                bookingData: {
                    location: bookingLocation,
                    locationId,
                    latitude,
                    longitude,
                    serviceType: bookingState.servicetype,
                    client: {
                        firstName: bookingState.client.user.firstname,
                        lastName: bookingState.client.user.lastname,
                        id: bookingState.client.id
                    },
                    provider: {
                        firstName: selectProvider
                            ? selectProvider.firstname
                            : bookingState.provider.firstname,
                        lastName: selectProvider
                            ? selectProvider.lastname
                            : bookingState.provider.lastname,
                        id: selectProvider
                            ? selectProvider.id
                            : bookingState.provider.id
                    },
                    isVirtual,
                    isRemoteLocation,
                    rrule: rrule,
                    rruleList: rruleList,
                    rruleChanged: rruleChanged,
                    xoccurChanged: xoccurChanged
                },
                originalBooking: {
                    id: bookingState.id,
                    startDate: bookingState.bookingDate
                }
            };
            if (bookingState.clientNotes) {
                body.bookingData.Notes = JSON.stringify([
                    {
                        createdBy:
                            bookingState.bookedByUser.firstname +
                            " " +
                            bookingState.bookedByUser.lastname,
                        createdAt: new Date(),
                        notes: bookingState.clientNotes
                    }
                ]);
            }
            //const body = { message: "hello from console :)", bookingData };
            try {
                let recurBookingsResponse = await postApi(
                    "recurringBookingsApi",
                    "/edit-recurring-bookings",
                    {
                        body
                    }
                );
                console.log(
                    "recurringbookigsAPI response data",
                    recurBookingsResponse
                );
            } catch (e) {
                console.log("recurringBookingsAPI response timed out", e);
                setTimeout(() => {
                    // Your code to be executed after the delay
                    console.log("Continuing after 15 seconds");
                }, 15000); // 15 seconds in milliseconds
            }
            setSnackMsg("Booking changed successfully");
            setMsgOpen(true);
            setTimeout(() => {
                setIsRecurrenceShown(false);
                resetOverrideLocationStates();
                actions.setPage("BookingsListForm");
            }, 2000);

            //return recurBookingsResponse.response;
        } catch (e) {
            console.log("error while creating recurring bookings", e);
        }
        setSelectProvider(null);
        setProviderSchedules([]);
        setProviderScheduleId(null);
        setOverrideModal(false);
        setRecurringBookingAllOrSelectedOverride(false);
        setShowOverrideBookingSpinner(false);
        setOverrideBookingClicked(false);
    }

    function handleShowClientDialog(item) {
        console.log("in showClientDialog, item = " + JSON.stringify(item));
        try {
            if (item) {
                setClientId(item.id);
                const name = item.user.lastname
                    ? item.user.firstname + " " + item.user.lastname
                    : "";
                setClientName(name);
                setClientContactConsent(item.user.contactconsent);
                setClientUserId(item.userId);
                setClientUserRole(item.user.role);
                setClientFirstName(item.user.firstname);
                setClientLastName(item.user.lastname);
                setClientEmailAddress(item.user.emailaddress);
                setClientMobilePhone(item.user.mobilephone);
                setClientNotes(item.Notes ? JSON.parse(item.Notes) : "");
            }
            setShowClientInfoDialog(true);
        } catch {
            console.log(
                "ERROR: Unable to open clientInfoDialog, no user was found"
            );
        }
    }

    const handleCloseClientInfoDialog = () => {
        setShowClientInfoDialog(false);
    };

    const handleSaveNotes = (id, newNotes) => {
        console.log("Notes saved");
        //function to update the Notes for a booking item, for a specific list of bookings
        try {
            let fRows = searching ? filteredFutureRows : futureRows;
            let pRows = searching ? filteredPastRows : pastRows;

            console.log("here is fRows", fRows);
            console.log("here is pRows", pRows);
            //const indexToUpdate = fRows.findIndex((row) => row.order.id === id);
            // Update the first matching row
            //const updatedRow = { ...fRows[indexToUpdate] };
            //if (indexToUpdate !== -1) {
            //updatedRow.order.Notes = newNotes;
            //fRows[indexToUpdate] = updatedRow;

            //console.log("Updated row:", updatedRow);

            // Loop through the rest of the future and past rows to find and update any additional matching rows
            fRows.forEach((row, index) => {
                if (/* index !== indexToUpdate &&  */ row.order.id === id) {
                    const updatedRow = { ...row };
                    updatedRow.order.Notes = newNotes;
                    fRows[index] = updatedRow;
                    console.log("Updated row:", updatedRow);
                }
            });
            pRows.forEach((row, index) => {
                if (/* index !== indexToUpdate &&  */ row.order.id === id) {
                    const updatedRow = { ...row };
                    updatedRow.order.Notes = newNotes;
                    pRows[index] = updatedRow;
                    console.log("Updated row:", updatedRow);
                }
            });
            //} else {
            console.log("Row with ID not found:", id);
            //}
        } catch (e) {
            console.log(
                "ERROR: Unable to update the customer notes within the state",
                e
            );
        }
    };

    const handleSaveClientUpdates = (id, clientUpdatesObj) => {
        console.log("Notes saved");
        //function to update the Notes for a booking item, for a specific list of bookings
        try {
            let fRows = searching ? filteredFutureRows : futureRows;
            let pRows = searching ? filteredPastRows : pastRows;

            console.log("here is fRows", fRows);
            console.log("here is pRows", pRows);
            //const indexToUpdate = fRows.findIndex((row) => row.order.id === id);
            // Update the first matching row
            //const updatedRow = { ...fRows[indexToUpdate] };
            //if (indexToUpdate !== -1) {
            //updatedRow.order.Notes = newNotes;
            //fRows[indexToUpdate] = updatedRow;

            //console.log("Updated row:", updatedRow);

            // Loop through the rest of the future and past rows to find and update any additional matching rows
            fRows.forEach((row, index) => {
                if (row.client.id === id) {
                    const updatedRow = { ...row };
                    if (clientUpdatesObj.newNotes?.length)
                        updatedRow.client.Notes = clientUpdatesObj.newNotes;
                    if (clientUpdatesObj["consent"] !== "undefined")
                        updatedRow.client.user.contactconsent =
                            clientUpdatesObj.consent;

                    fRows[index] = updatedRow;
                    console.log("Updated row:", updatedRow);
                }
            });
            pRows.forEach((row, index) => {
                if (row.client.id === id) {
                    const updatedRow = { ...row };
                    if (clientUpdatesObj.newNotes?.length)
                        updatedRow.client.Notes = clientUpdatesObj.newNotes;
                    if (clientUpdatesObj["consent"] !== "undefined")
                        updatedRow.client.user.contactconsent =
                            clientUpdatesObj.consent;
                    pRows[index] = updatedRow;
                    console.log("Updated row:", updatedRow);
                }
            });
            //} else {
            //console.log("Row with ID not found:", id);
            //}
        } catch (e) {
            console.log(
                "ERROR: Unable to update the customer notes within the state",
                e
            );
        }
    };

    function resetFilterResults() {
        setFilteredFutureRows([]);
        setFilteredPastRows([]);
        setFilteredPastRowsExports([]);
        setFilteredFutureRowsExports([]);
        setFutureSearchCache([]);
        setFutureSearchCacheExports([]);
        setPastSearchCache([]);
        setFutureSearchToken(null);
        setPastSearchToken(null);
        setnonPendingSearchToken(null);
    }

    //setCancelOnlySelectedBooking is set true so the no refund case knows to cancel only one.
    //In order to be accurate, all other paths must also specific they are false (to properly reset to correct state)
    //This affects _handleCancelRepeating and cancelPath
    function selectedBookingRepeatedCancellationTimeoutExceeded() {
        setCancelOnlySelectedBooking(true);
        const timeoutExceeded =
            isCancellationPolicyTimeoutExceeded(bookingData);
        handleCancellation(bookingData, timeoutExceeded);
    }

    function selectedBookingRepeatedCancellationTimeoutNotExceeded() {
        setCancelOnlySelectedBooking(true);
        const timeoutExceeded = false;
        handleCancellation(bookingData, timeoutExceeded);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={5000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={displayRefundedDialogue}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`A refund has already been applied to this booking!`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`A refund has already been processed for this booking and another cannot be applied. ` +
                            `Click continue to continue the cancellation process - no additional refund will be applied.`}
                    </DialogContentText>

                    {showCancelBookingSpinner && <SmallSpinner />}

                    <DialogActions>
                        <Button
                            onClick={() => handleCancelCancellation()}
                            color="primary"
                            disabled={refundedContinueClicked}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() =>
                                handleContinueCancellation(bookingData)
                            }
                            color="primary"
                            autoFocus
                            disabled={refundedCancelClicked}
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {/* For Partial Refund */}
            <Dialog
                open={displayPartialRefundDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`A Partial refund has already been applied to this booking!`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span style={{ fontWeight: "750" }}>
                            Remaining Amount: $
                            {`${remainingRefund?.toFixed(2)}`}
                        </span>
                        <br></br>
                        {`A partial refund has already been processed for this booking. ` +
                            `Click continue to continue the cancellation process - the remaining amount will be refunded.`}
                    </DialogContentText>

                    {showCancelBookingSpinner && <SmallSpinner />}

                    <DialogActions>
                        <Button
                            onClick={() => {
                                setDisplayPartialRefundDialog(false);
                            }}
                            color="primary"
                            disabled={partialRefundLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => {
                                const timeoutExceeded = false;
                                setPartialRefundLoading(true);
                                await cancelPath(
                                    bookingData,
                                    timeoutExceeded,
                                    false
                                );
                                setPartialRefundLoading(false);
                                setDisplayPartialRefundDialog(false);
                            }}
                            color="primary"
                            autoFocus
                            disabled={partialRefundLoading}
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {/*Relevant for the cancel button (single)*/}
            <Dialog
                open={cancelModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* This if statement might be an issue if the company specifies a long cancellation policy that is say, 
            you must cancel 10 days before the booking and the user decides to cancel a repeating booking that is not the first upcoming booking. This will be a clarity issue as the user will be told that they will get charged*/}
                {bookingData &&
                    isCancellationPolicyTimeoutExceeded(bookingData) && (
                        <>
                            <DialogTitle id="alert-dialog-title">
                                {`Are you sure you wish to cancel the booking?`}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {userHasAdminRole()
                                        ? `WARNING: Customer is past the cancellation period and will be charged, even if you cancel. If this is part of a repeating booking, customer will only be charged for this booking. Are you sure you want to cancel?`
                                        : `WARNING: You are past the cancellation period and will be charged despite cancellation. 
                                If you have selected a repeating booking, you will only be charged for the first future booking.`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => setCancelModal(false)}
                                    color="primary"
                                    disabled={cancelBookingClicked}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => {
                                        const timeoutExceeded = true;
                                        cancelPath(
                                            bookingData,
                                            timeoutExceeded,
                                            false
                                        );
                                        setShowYesCancelLoading(true);
                                    }}
                                    color="primary"
                                    autoFocus
                                    disabled={
                                        showYesCancelLoading ||
                                        showWaiveCancelLoading
                                    }
                                >
                                    Yes
                                    {showYesCancelLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                                {userHasAdminRole() && (
                                    <Button
                                        onClick={() => {
                                            const timeoutExceeded = false;
                                            cancelBookingPath(
                                                bookingData,
                                                timeoutExceeded,
                                                true
                                            );
                                            setShowWaiveCancelLoading(true);
                                        }}
                                        color="primary"
                                        autoFocus
                                        disabled={
                                            showYesCancelLoading ||
                                            showWaiveCancelLoading
                                        }
                                    >
                                        Yes, but Waive Charge
                                        {showWaiveCancelLoading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                )}
                            </DialogActions>
                        </>
                    )}
                {bookingData &&
                    !isCancellationPolicyTimeoutExceeded(bookingData) && (
                        <>
                            <DialogTitle id="alert-dialog-title">
                                {`Are you sure you wish to cancel the booking?`}
                            </DialogTitle>
                            <DialogActions>
                                <Button
                                    onClick={() => setCancelModal(false)}
                                    color="primary"
                                    disabled={cancelBookingClicked}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => {
                                        const timeoutExceeded = false;
                                        cancelBookingPath(
                                            bookingData,
                                            timeoutExceeded,
                                            false
                                        );
                                        setShowYesCancelLoading(true);
                                    }}
                                    color="primary"
                                    autoFocus
                                    disabled={showYesCancelLoading}
                                >
                                    Yes
                                    {showYesCancelLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </>
                    )}
            </Dialog>

            {/* This is relevant for the cancel button (repeating and single) */}
            <Dialog
                open={cancelRepeating}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* This if statement will be true when the selected repeating appointment has missed the period to cancel*/}
                {bookingData && repeatingTimeoutExceeded && (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {`This booking is part of a set of repeating appointments.`}
                        </DialogTitle>
                        {showCancelBookingSpinner && <SmallSpinner />}

                        <DialogContent>
                            <DialogContentText>
                                {`WARNING: You have missed the cancellation period for this booking and will not be given a refund for the selected booking. 
                           Would you like to cancel just the selected booking or all future bookings including the selected booking? 
                            If you cancel all future bookings, you will only be charged for the selected booking.`}
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setCancelModal(false);
                                    setCancelRepeating(false);
                                    setRepeatingTimeoutExceeded(false);
                                    setShowYesCancelLoading(false);
                                    setShowWaiveCancelLoading(false);
                                }}
                                color="primary"
                                disabled={cancelBookingClicked}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    selectedBookingRepeatedCancellationTimeoutExceeded()
                                }
                                color="primary"
                                autoFocus
                            >
                                Selected Booking
                            </Button>
                            <Button
                                onClick={() => {
                                    const timeoutExceeded = true;
                                    _handleCancelRepeating(
                                        bookingData,
                                        timeoutExceeded
                                    );
                                }}
                                color="primary"
                                autoFocus
                            >
                                All Future Bookings
                            </Button>
                        </DialogActions>
                    </>
                )}
                {bookingData && !repeatingTimeoutExceeded && (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {`This booking is part of a set of repeating appointments.`}
                        </DialogTitle>
                        {showCancelBookingSpinner && <SmallSpinner />}

                        <DialogContent>
                            <DialogContentText>
                                {`Would you like to cancel just the selected booking or all future bookings including the selected booking? You will not be charged for cancellation.`}
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setCancelModal(false);
                                    setCancelRepeating(false);
                                    setRepeatingTimeoutExceeded(false);
                                    setShowYesCancelLoading(false);
                                    setShowWaiveCancelLoading(false);
                                }}
                                color="primary"
                                disabled={cancelBookingClicked}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    selectedBookingRepeatedCancellationTimeoutNotExceeded()
                                }
                                color="primary"
                                autoFocus
                            >
                                Selected Booking
                            </Button>
                            <Button
                                onClick={() => {
                                    const timeoutExceeded = false;
                                    _handleCancelRepeating(
                                        bookingData,
                                        timeoutExceeded
                                    );
                                }}
                                color="primary"
                                autoFocus
                            >
                                All Future Bookings
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Dialog
                open={noShowModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure you wish to set the record the booking as a 'NO SHOW'?`}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setNoShowModal(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => _handleNoShow(bookingData)}
                        color="primary"
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={acceptedModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure you wish to accept this booking request?`}
                </DialogTitle>
                {showBookingReqSpinner && <SmallSpinner />}
                <DialogActions>
                    <Button
                        onClick={() => setAcceptedModal(false)}
                        color="primary"
                        disabled={bookingReqActionClicked}
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => handleReqAcceptance()}
                        color="primary"
                        autoFocus
                        disabled={bookingReqActionClicked}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={declinedModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {userHasProviderRole()
                        ? `Are you sure you wish to decline this booking request?`
                        : `Are you sure you wish to decline (cancel) this booking request?`}
                </DialogTitle>
                {showBookingReqSpinner && <SmallSpinner />}
                <DialogActions>
                    <Button
                        onClick={() => setDeclinedModal(false)}
                        color="primary"
                        disabled={bookingReqActionClicked}
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => handleReqDeclination()}
                        color="primary"
                        autoFocus
                        disabled={bookingReqActionClicked}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={detailsModal}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="details-dialog-title"
                aria-describedby="details-dialog-description"
            >
                <BRDetailsDialogTitle
                    id="details-dialog-title"
                    onClose={handleBRDetailsClose}
                    backgroundColor={mbxUser.company.primaryColor}
                >
                    Booking Request Communications
                </BRDetailsDialogTitle>
                <DialogContent>
                    {showBookingReqSpinner && <SmallSpinner />}
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        style={{ marginRight: "16px", marginTop: "8px" }}
                    >
                        <Grid item xs={4} md={3} justifyContent="center">
                            <Typography
                                variant="h6"
                                noWrap
                                style={{
                                    backgroundColor:
                                        mbxUser?.company?.primaryColor,
                                    color: "#fff"
                                }}
                                align="center"
                            >
                                Date
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={9} justifyContent="center">
                            <Typography
                                variant="h6"
                                noWrap
                                style={{
                                    backgroundColor:
                                        mbxUser?.company?.primaryColor,
                                    color: "#fff"
                                }}
                                align="center"
                            >
                                Details
                            </Typography>
                        </Grid>
                        {brLogs && (
                            <>
                                {brLogs.map((l) => (
                                    <>
                                        <Grid
                                            item
                                            xs={4}
                                            md={3}
                                            justifyContent="center"
                                        >
                                            <Typography
                                                variant="body2"
                                                noWrap
                                                align="center"
                                            >
                                                {new Date(
                                                    l.createdAt
                                                ).toLocaleDateString(
                                                    undefined,
                                                    {
                                                        weekday: "short",
                                                        month: "short",
                                                        day: "numeric",
                                                        year: "numeric",
                                                        hour: "numeric",
                                                        minute: "2-digit"
                                                    }
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} md={9}>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                {l.data.msg}
                                            </Typography>
                                            {l.data.type &&
                                                l.data.type.startsWith(
                                                    "SMS"
                                                ) && (
                                                    <Typography variant="body2">
                                                        {l.data.text}
                                                    </Typography>
                                                )}
                                        </Grid>
                                    </>
                                ))}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDetailsModal(false)}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={arrivedModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {`Do you wish to record the arrival for this booking?`}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setArrivedModal(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleArrived(bookingData)}
                        color="primary"
                        autoFocus
                    >
                        Record as Arrived
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addChargeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-dialog-title">
                    Additional Charge for the booking
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you have an additional charge for the booking such as
                        an extra service provided or a product sold to the
                        client, you can enter the details on this form.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Box mt={4} ml={2}>
                            <AmountInputField
                                error={amount === ""}
                                value={amount}
                                setValue={setAmount}
                            ></AmountInputField>
                        </Box>
                        <Box mt={4} ml={1}>
                            <CustomTooltip
                                placement="right"
                                title="The system will not add tax for additional charges. Please enter the full amount including tax if applicable"
                            >
                                <IconButton size="large">
                                    <HelpOutlineIcon />
                                </IconButton>
                            </CustomTooltip>
                        </Box>
                    </Grid>
                    <TextField
                        error={desc === ""}
                        required
                        margin="dense"
                        id="desc"
                        value={desc}
                        onChange={(event) => setDesc(event.target.value)}
                        label="Service or Product"
                        fullWidth
                        helperText="Please enter a product or service"
                    />
                    <TextField
                        margin="dense"
                        required
                        error={note === ""}
                        id="notes"
                        label="Notes"
                        value={note}
                        onChange={(event) => setNote(event.target.value)}
                        maxRows="5"
                        rows="5"
                        multiline
                        fullWidth
                        helperText="Please enter details regarding the additional charge"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setAddChargeModal(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleAddCharge(bookingData)}
                        color="primary"
                        autoFocus
                        disabled={addChgLoading}
                    >
                        Record Additional Charge
                        {addChgLoading && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={featureDisabled}
                fullWidth={true}
                aria-labelledby="show-client-dialog-title"
                aria-describedby="show-client-dialog-description"
            >
                <DialogTitle
                    id="show-client-dialog-title"
                    sx={{ fontSize: "24px", fontWeight: 400 }}
                >
                    This feature is currently disabled on your account
                </DialogTitle>
                <DialogContent dividers style={{ padding: 22 }}>
                    <DialogContentText id="show-client-dialog-description">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    To turn it on: Go to Company Settings{" "}
                                    {"\u2192"} Turn on 'Company Collects
                                    Payments'
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    You will then need to connect your Stripe
                                    account to MarketBox
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={0}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        setShowFeatureDisabled(false)
                                    }
                                    color="primary"
                                >
                                    Okay
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* This is relevant for the completed button */}

            <Dialog
                open={completedModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {!paymentFailed && (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {`Do you wish to record the completion for this booking?`}
                        </DialogTitle>
                        {completedModal &&
                            bookingData.clientMobilePhone == "N/A" && (
                                <DialogContent>
                                    <DialogContentText>
                                        <b>Note: </b> This client does not have
                                        a mobile phone, so they will not receive
                                        an SMS to give a tip/rating for the
                                        provider
                                    </DialogContentText>
                                </DialogContent>
                            )}
                        {showSpinner && <SmallSpinner />}
                        <DialogActions>
                            <Button
                                onClick={() => setCompletedModal(false)}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleCompleted(bookingData)}
                                color="primary"
                                autoFocus
                                disabled={comlpetedClicked}
                            >
                                Record as Completed
                            </Button>
                        </DialogActions>
                    </>
                )}
                {paymentFailed && (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {`Payment processing failed. Please update Client's payment information. ${paymentFailedMessage}`}
                        </DialogTitle>
                        {showSpinner && <SmallSpinner />}
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setCompletedModal(false);
                                    setPaymentFailed(false);
                                    setPaymentFailedMessage("");
                                    setCompletedClicked(false);
                                    _getFutureBookingsList();
                                    _getPastBookingsList();
                                }}
                                color="primary"
                            >
                                Okay
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <Dialog
                open={showDateRange}
                onClose={handleCloseDateRange}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <DialogContentText sx={{ mb: "0.5rem" }}>
                        Choose a date range:
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <DateRangePicker
                                setFrom={setSelectFromDate}
                                setTo={setSelectToDate}
                                from={selectedFromDate}
                                to={selectedToDate}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <DialogActions>
                                <Button
                                    onClick={() => handleClearDateRange(false)}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                >
                                    TODAY
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDateRange}
                        color="primary"
                        variant="contained"
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleApplyDateRange}
                        color="primary"
                        variant="contained"
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog title="import" open={importDialog}>
                <DialogTitle id="import-booking-dialog-title">
                    Import booking(s) into{" "}
                    {user && user.company
                        ? user.company.name + "?"
                        : "your company?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Before you import any bookings, make sure that:<br></br>
                        1. You have already imported Client users (from the
                        "Users" page)
                        <br></br>
                        2. You have already imported Provider users (from the
                        "Users" page)
                        <br></br>
                        3. You have added all services for your company (from
                        the "Services" page)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setImportDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={handleImportDialogAccept}
                    >
                        Import
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Dialog
                open={overrideModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    Edit Booking
                    <CustomTooltip
                        placement="right"
                        title="Editing a booking on the system bypasses normal booking checks to allow administrators maximum flexibility. Please check that the new provider has an open schedule slot (availability) and is willing to travel to the booking location before using the edit function to change the details of a booking."
                    >
                        <IconButton size="large">
                            <HelpOutlineIcon />
                        </IconButton>
                    </CustomTooltip>
                </DialogTitle>
                <DialogContent sx={{ paddingRight: "10px" }}>
                    {bookingData && bookingData.status === "ARRIVED" && (
                        <>
                            <DialogContentText>
                                <b>
                                    Current status of this booking is 'ARRIVED'.
                                </b>
                            </DialogContentText>
                            <Button
                                onClick={() =>
                                    handleOverrideStatus(bookingData)
                                }
                                variant="contained"
                                color="primary"
                                autoFocus
                                disabled={
                                    overrideStatusClicked ||
                                    overrideBookingClicked
                                }
                                style={{ margin: 16 }}
                            >
                                Reset booking status to scheduled
                            </Button>
                        </>
                    )}
                    <DialogContentText>
                        <b>Change provider</b>
                    </DialogContentText>
                    {displayListProviders()}

                    <Grid container sx={{ marginBottom: "8px" }}>
                        <DialogContentText>
                            <b>Change location</b>
                        </DialogContentText>
                        <LocationSelector
                            setLocation={saveLocation}
                            setShowAddr={setShowAddr}
                            setLocationChanged={setLocationChanged}
                            setPlaceId={savePlaceId}
                            overrideModalDisplay={true}
                            autoSelect={false}
                            locationId={location && location.id}
                            initialLocation={
                                bookingData ? bookingData.location : null
                            }
                        />
                        {showAddr && (
                            <ClientAddressInput
                                setAddress={saveAddress}
                                setPlaceId={savePlaceId}
                                address={address}
                                autocompleteService={autocompleteService}
                                overrideModalDisplay={true}
                                addressOneLine={
                                    bookingData?.location
                                        ? bookingData.location
                                        : ""
                                }
                            />
                        )}
                    </Grid>

                    <DialogContentText sx={{ marginBottom: "8px" }}>
                        <b>Change booking date/time</b>
                    </DialogContentText>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={customLocale}
                    >
                        <MobileDateTimePicker
                            label="Booking date/time"
                            value={selectedDate}
                            onChange={(event) => {
                                console.log("Event from override", event);
                                if (
                                    new Date(selectedDate).getDay() !==
                                        new Date(event).getDay() &&
                                    bookingData?.orderType === "ONGOING"
                                ) {
                                    setRruleChanged(true);
                                    let newRrule = generateRRULE(
                                        parsedRrule.frequency,
                                        parsedRrule.interval
                                            ? parsedRrule.interval
                                            : null,
                                        [(new Date(event).getDay() + 6) % 7],
                                        parsedRrule.until
                                            ? new Date(
                                                  `${parsedRrule.until}T14:00:00Z`
                                              )
                                            : null,
                                        parsedRrule.xoccur
                                    );
                                    setRrule(newRrule);
                                    setParsedRrule(
                                        parseRRuleForSelector(newRrule)
                                    );
                                }
                                setSelectedDate(event);
                                setCheckAvailabilityFails(false);
                                checkIsDuringBlockedTime(event);
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={
                                overrideStatusClicked || overrideBookingClicked
                            }
                            renderInput={(params) => (
                                <TextField
                                    style={{ maxWidth: "200px" }}
                                    {...params}
                                    /* error={checkAvailabilityFails}
                                    helperText={
                                        checkAvailabilityFails
                                            ? "Selected booking time is not available"
                                            : ""
                                    } */
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <CustomTooltip
                        placement="right"
                        title="Overriding a booking on the system bypasses normal booking checks to allow administrators maximum flexibility. Please check that the new provider has an open schedule slot (availability) and is willing to travel to the booking location before using the override function to change the details of a booking."
                    >
                        <IconButton size="large">
                            <HelpOutlineIcon />
                        </IconButton>
                    </CustomTooltip>
                    {checkAvailabilityFails && (
                        <Alert
                            severity={errorType}
                            sx={{
                                padding: "0px 8px 0px 12px",
                                marginTop: "14px",
                                "& .MuiAlert-icon": {
                                    alignSelf: "center"
                                }
                            }}
                            variant="outlined"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    fontSize: "12px"
                                }}
                            >
                                {errorMessage}
                            </Box>
                        </Alert>
                    )}
                    {rruleLabel && (
                        <Grid
                            container
                            sx={{
                                flexDirection: "column",
                                padding: "8px 0px",
                                gap: "8px"
                            }}
                        >
                            <Typography>{rruleLabel}</Typography>
                            <Grid
                                className="toggle-container"
                                onClick={toggleShowRecurrence}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    gap: "4px"
                                }}
                            >
                                <IconButton
                                    sx={{
                                        padding: "0px",
                                        color: "primary.main"
                                    }}
                                >
                                    <KeyboardArrowDownIcon
                                        sx={{
                                            transform: `rotate(${
                                                isRecurrenceShown
                                                    ? "180deg"
                                                    : "0deg"
                                            })`,
                                            transition:
                                                "transform 0.1s ease-in-out"
                                        }}
                                    />
                                </IconButton>
                                <Typography
                                    id="toggleText"
                                    sx={{ color: "primary.main" }}
                                >
                                    {isRecurrenceShown
                                        ? "Hide recurrence"
                                        : "Change recurrence"}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {isRecurrenceShown && (
                        <RecurringBookingSelector
                            setRrule={onRruleChange} //optional
                            setXoccurChanged={onXoccurChange} //optional
                            rrule={rrule} //optional
                            selectedDate={
                                //required
                                selectedDate
                                    ? selectedDate
                                    : bookingData.startdate
                            }
                            numberSelector={
                                parsedRrule.interval
                                    ? parsedRrule.interval
                                    : null
                            } //optional
                            chronologicalSelector={parsedRrule.frequency} //optional
                            selectedDays={
                                //optional
                                parsedRrule.days === "NUMBER" ||
                                parsedRrule.days === "WEEK"
                                    ? null
                                    : parsedRrule.days
                            }
                            selectedMonthOption={
                                //optional
                                parsedRrule.days === "NUMBER" ||
                                parsedRrule.days === "WEEK"
                                    ? parsedRrule.days
                                    : null
                            }
                            endDate={
                                parsedRrule.until
                                    ? new Date(`${parsedRrule.until}T14:00:00Z`)
                                    : null
                            } //optional
                            occurrences={parsedRrule.xoccur} //optional
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (checkAvailabilityFails)
                                setCheckAvailabilityFails(false);
                            setOverrideModal(false);
                            setRecurringBookingAllOrSelectedOverride(false);
                            resetOverrideLocationStates();
                        }}
                        color="primary"
                        disabled={
                            overrideStatusClicked || overrideBookingClicked
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (
                                bookingData.orderType === "ONGOING" &&
                                rrule !== originalRrule &&
                                isRecurrenceShown
                            ) {
                                handleOverrideRecurringBookings(bookingData);
                            } else if (bookingData.orderType === "ONGOING") {
                                setRecurringBookingAllOrSelectedOverride(true);
                            } else {
                                handleOverride(bookingData);
                            }
                        }}
                        variant="contained"
                        color="primary"
                        autoFocus
                        disabled={
                            overrideStatusClicked || overrideBookingClicked
                        }
                    >
                        Save
                        {showOverrideBookingSpinner && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>

                    {/* <Button onClick={getSlotData}>Get Slots Data</Button> */}
                </DialogActions>
            </Dialog>

            <Dialog
                open={recurringBookingAllOrSelectedOverride}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    This booking is part of a recurring bookings set
                </DialogTitle>
                <DialogContent sx={{ paddingRight: "10px" }}>
                    <Typography>
                        Would you like to override all upcoming bookings, or
                        just the selected booking?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleOverride(bookingData)}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Selected booking
                    </Button>
                    <Button
                        onClick={() =>
                            handleOverrideRecurringBookings(bookingData)
                        }
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        All upcoming bookings
                    </Button>

                    {/* <Button onClick={getSlotData}>Get Slots Data</Button> */}
                </DialogActions>
            </Dialog>

            <Dialog
                open={showNoteDialog}
                onClose={handleCloseBookingNotesDialog}
                fullWidth={true}
            >
                <BookingNotesDialog
                    onClose={handleCloseBookingNotesDialog}
                    noteDialogCreatedBy={noteDialogCreatedBy}
                    noteDialogDateCreated={noteDialogDateCreated}
                    noteDialogContent={noteDialogContent}
                    rowBookingId={rowBookingId}
                    noteClientName={noteClientName}
                    noteServiceName={noteServiceName}
                    noteBookingStartDate={noteBookingStartDate}
                    noteOrderNumber={noteOrderNumber}
                    onSaveNotes={handleSaveNotes}
                    loggedInUser={user}
                />
            </Dialog>

            <Dialog
                open={showClientInfoDialog}
                onClose={handleCloseClientInfoDialog}
            >
                <CustomerInfoDialog
                    open={showClientInfoDialog}
                    onClose={handleCloseClientInfoDialog}
                    clientId={clientId}
                    clientUserId={clientUserId}
                    clientUserRole={clientUserRole}
                    clientFirstName={clientFirstName}
                    clientLastName={clientLastName}
                    clientEmailAddress={clientEmailAddress}
                    clientMobilePhone={clientMobilePhone}
                    clientNotes={clientNotes}
                    clientContactConsent={clientContactConsent}
                    rows={rows}
                    onSave={handleSaveClientUpdates}
                    loggedInUser={user}
                />
            </Dialog>

            <Dialog
                open={showExport}
                fullWidth={true}
                maxWidth={"sm"}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <div
                        style={{
                            /* padding: "0rem 1rem", */
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "24px", fontWeight: 400 }}
                            variant="h4"
                        >
                            Export bookings
                        </Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={() => {
                                setExportReadyBookings([]);
                                setShowExport(false);
                                setPreparingExportData(false);
                            }}
                            style={{
                                color: "primary"
                            }}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers style={{ padding: "0rem 1rem" }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "16px" }}
                        >
                            {`Please select a date range and click 'Prepare Export'.`}
                        </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ExportDateRangePicker
                                setFrom={setExportFromDate}
                                setTo={setExportToDate}
                                from={exportFromDate}
                                to={exportToDate}
                                style={{ margin: "8px" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={preparingExportData}
                                onClick={async () => {
                                    setExportReadyBookings([]);
                                    setShowExportSpinner(true);
                                    setPreparingExportData(true);
                                    await prepareExportData();
                                    setPreparingExportData(false);
                                    setShowExportSpinner(false);
                                }}
                                style={{ marginTop: "24px" }}
                            >
                                Prepare Export
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {preparingExportData && (
                                <Typography variant="body1">
                                    {`We are preparing your bookings for export.`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {showExportSpinner && <SmallSpinner />}
                        </Grid>
                        {exportReadyBookings &&
                            exportReadyBookings.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {`Please click the download button below to download your bookings.`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CSVLink
                                            data={exportReadyBookings}
                                            headers={csvHeaders}
                                            filename={`bookings_${moment(
                                                Date.now()
                                            ).format("YYYY-MM-DD")}.csv`}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<GetAppIcon />}
                                            >
                                                Download
                                            </Button>
                                        </CSVLink>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </DialogContent>
            </Dialog>
            <FormHeading
                title="Bookings"
                classes={classes.title}
                isMobile={isMobile}
            />

            {/*  <Button
                onClick={async (e) => {
                    let data = await getTimezone();

                    const vancouverStart = new Date(2023, 9, 25, 9, 0, 0);
                    const vancouverEnd = new Date(2023, 9, 25, 17, 0, 0);

                    console.log(
                        "For Vancouver:",
                        convertTimezoneRange(
                            vancouverStart,
                            vancouverEnd,
                            "America/Vancouver",
                            "America/Toronto"
                        )
                    );
                   //  console.log("Timezone Data", data);

                   // const testDate = new Date(2023, 9, 25, 9, 0, 0);

                  //  console.log("Original Date:", testDate);
                  //  console.log(
                   ///     "Melbourne Time:",
                        timezoneConvert(testDate, "Australia/Melbourne")
                   // );

                    //timezoneConvert(
                       //             new Date(),
                                  //</>  "America/Vancouver"
                              //  ); 

                    //  let start = convertHourToTimeZone(8);
                    //  let end = convertHourToTimeZone(23);

                    //  console.log("Converted times", start, end);
                }}
            >
                Timezone data
            </Button>
 */}
            <Grid container spacing={2} alignItems="center">
                {role && role !== USERROLE.CLIENT && (
                    <Grid item xs={12} sm={3}>
                        {/* <TextField
                            id="search"
                            label="Search by client name or email"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={_handleClientSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        /> */}
                        <UserLookup
                            companyId={user && user.company.id}
                            setSelectedUser={(u) => {
                                setSelectedUser(u);
                                if (!u) {
                                    setUpdateResults(true);
                                }
                            }}
                        />
                    </Grid>
                )}
                {role && role !== USERROLE.PROVIDER && (
                    <Grid item xs={12} sm={3}>
                        {/* <TextField
                            id="search"
                            label="Search by provider name or email"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={_handleProviderSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />*/}
                        <ProviderLookup
                            companyId={user && user.company.id}
                            setSelectedProvider={(prov) => {
                                setSelectedProvider(prov);
                                if (!prov) {
                                    setUpdateResults(true);
                                }
                            }}
                        />
                    </Grid>
                )}
                {/*  <Grid item xs={12} sm={3}>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel
                            ref={inputLabel}
                            id="outlined-service-simple-label"
                        >
                            Services
                        </InputLabel>
                        <Select
                            labelId="outlined-service-simple-label"
                            id="outlined-service-simple"
                            className={classes.formControl}
                            value={serviceTypeOption}
                            onChange={handleSelectChange}
                            labelWidth={labelWidth}
                        >
                            <MenuItem key={0} value={0}>
                                All Services
                            </MenuItem>
                            {serviceTypeData &&
                                serviceTypeData.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>*/}
                <Grid item xs={12} md={12} lg={6}>
                    {!(
                        getUserRole() === "CLIENT" ||
                        getUserRole() === "PROVIDER"
                    ) && (
                        <>
                            <Button
                                id="demo-positioned-button"
                                color="primary"
                                className={clsx(
                                    classes.underline,
                                    classes.responsiveStatus
                                )}
                                onClick={(event) => {
                                    setIsStatusOpen(true);
                                    setAnchorEl(event.currentTarget);
                                }}
                                endIcon={
                                    isStatusOpen ? (
                                        <KeyboardArrowUpIcon
                                            style={{
                                                color: "black",
                                                fontSize: "large"
                                            }}
                                        />
                                    ) : (
                                        <KeyboardArrowDownIcon
                                            style={{
                                                color: "black",
                                                fontSize: "large"
                                            }}
                                        />
                                    )
                                }
                            >
                                {bookingStatusChange &&
                                    `(${
                                        0 +
                                        (bookingStatus.arrived ? 1 : 0) +
                                        (bookingStatus.completed ? 1 : 0) +
                                        (bookingStatus.scheduled ? 1 : 0) +
                                        (bookingStatus.cancelled ? 1 : 0)
                                    }) `}
                                BOOKING STATUS
                            </Button>
                            {bookingStatusChange && (
                                <Button
                                    onClick={() => {
                                        clearBookingStatus();
                                        setUpdateResults(true);
                                    }}
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        minWidth: 13
                                    }}
                                >
                                    <ClearIcon style={{ fontSize: "large" }} />
                                </Button>
                            )}
                            <Popover
                                open={isStatusOpen}
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                                onClose={() => {
                                    setIsStatusOpen(false);
                                    if (
                                        bookingStatus.arrived ||
                                        bookingStatus.scheduled ||
                                        bookingStatus.cancelled ||
                                        bookingStatus.completed
                                    )
                                        setBookingStatusChange(true);
                                    else setBookingStatusChange(false);
                                }}
                            >
                                <FormControlLabel
                                    style={{
                                        display: "block",
                                        marginLeft: "5px",
                                        marginTop: "8px"
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={bookingStatus.arrived}
                                            onClick={() =>
                                                setBookingStatus(
                                                    (prevState) => ({
                                                        ...bookingStatus,
                                                        arrived:
                                                            !prevState.arrived
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label="Arrived"
                                />
                                <FormControlLabel
                                    style={{
                                        display: "block",
                                        marginLeft: "5px"
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={bookingStatus.cancelled}
                                            onClick={() =>
                                                setBookingStatus(
                                                    (prevState) => ({
                                                        ...bookingStatus,
                                                        cancelled:
                                                            !bookingStatus.cancelled
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label="Cancelled"
                                />
                                <FormControlLabel
                                    style={{
                                        display: "block",
                                        marginLeft: "5px"
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={bookingStatus.completed}
                                            onClick={() =>
                                                setBookingStatus(
                                                    (prevState) => ({
                                                        ...bookingStatus,
                                                        completed:
                                                            !bookingStatus.completed
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label="Completed"
                                />
                                <FormControlLabel
                                    style={{
                                        display: "block",
                                        marginLeft: "5px"
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={bookingStatus.scheduled}
                                            onClick={() =>
                                                setBookingStatus(
                                                    (prevState) => ({
                                                        ...bookingStatus,
                                                        scheduled:
                                                            !bookingStatus.scheduled
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label="Scheduled"
                                />
                            </Popover>
                        </>
                    )}
                    <Button
                        color="primary"
                        onClick={handleClickOpenDateRange}
                        className={clsx(
                            classes.underline,
                            classes.responsiveDate
                        )}
                    >
                        {dateRangeChange
                            ? `${moment(selectedFromDate).format(
                                  "MMM DD"
                              )} to ${moment(selectedToDate).format("MMM DD")}`
                            : "Start Date"}
                    </Button>
                    {dateRangeChange && (
                        <Button
                            onClick={() => {
                                handleClearDateRange(true);
                                setUpdateResults(true);
                            }}
                            style={{ margin: 0, padding: 0, minWidth: 13 }}
                        >
                            <ClearIcon style={{ fontSize: "large" }} />
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => await handleSearchBookings()}
                        startIcon={<SearchIcon />}
                        style={{ marginLeft: "14px" }}
                    >
                        Search
                    </Button>
                    <div style={{ display: "inline-block" }}>
                        <CustomTooltip
                            placement="right"
                            title="Click the Search button to submit your search. All name/email searches are CASE SENSITIVE. To search by email, enter the full email address. If you are unable to find a user, please verify your search is formatted as it was entered on the user’s profile."
                        >
                            <IconButton style={{ padding: "6px" }} size="large">
                                <HelpOutlineIcon />
                            </IconButton>
                        </CustomTooltip>
                    </div>
                    {(getUserRole() === "CLIENT" ||
                        userHasProviderOnlyRole()) && (
                        <FormControlLabel
                            style={{ marginLeft: 8 }}
                            control={
                                <Switch
                                    checked={showCancelledBookings}
                                    onChange={(event) =>
                                        setShowCancelledBookings(
                                            event.target.checked
                                        )
                                    }
                                    value="showCancelledBookings"
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Show cancelled bookings"
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item>
                    {userHasAdminRole() && (
                        <Button
                            onClick={() => setShowExport(true)}
                            style={{ marginRight: "16px" }}
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon />}
                        >
                            Export CSV
                        </Button>
                    )}
                    {/* {userHasMarketboxAdminRole() &&
                        !companyIsSoleOperator() && (
                            <>
                                <CSVReader
                                    onFileLoaded={handleReadCSV}
                                    inputRef={fileInput}
                                    style={parserStyle}
                                    onError={handleOnError}
                                    configOptions={parserOptions}
                                ></CSVReader>
                                <Button
                                    style={{ marginRight: "16px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleImportOffer}
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Import
                                </Button>
                            </>
                        )} */}
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <div className={classes.root}>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            className={classes.tabsunderline}
                        >
                            <Tab label="Future" />
                            <Tab label="Past" />
                            {getUserFromCache()?.company
                                .providerMustAcceptAppt && (
                                <Tab
                                    label="Pending"
                                    disabled={
                                        !getUserFromCache()?.company
                                            .providerMustAcceptAppt
                                    }
                                />
                            )}
                            {userHasAdminRole() &&
                                getUserFromCache()?.company
                                    .providerMustAcceptAppt && (
                                    <Tab
                                        label="Declined/Expired"
                                        disabled={
                                            !getUserFromCache()?.company
                                                .providerMustAcceptAppt
                                        }
                                    />
                                )}
                        </Tabs>
                        {tab === 0 && (
                            <TabContainer>
                                <BookingListTable
                                    selectedRow={(selected) =>
                                        setSelected(selected)
                                    }
                                    rows={
                                        searching
                                            ? filteredFutureRows
                                            : futureRows
                                    }
                                    role={role}
                                    hideRatingsFromClients={
                                        hideRatingsFromClients
                                    }
                                    tab={"future"}
                                    showClientDialog={handleShowClientDialog}
                                    showNoteDialog={handleNoteDialog}
                                    refreshTable={refreshTable}
                                    handleCancel={(data) => {
                                        setCancelModal(true);
                                        setTimeout(function () {
                                            setCancelModal(false);
                                            setShowYesCancelLoading(false);
                                            setShowWaiveCancelLoading(false);
                                            setRefreshRows(true);
                                        }, 30000);
                                        setBookingdata(data);
                                    }}
                                    handleNoShow={(data) => {
                                        setNoShowModal(true);
                                        setBookingdata(data);
                                    }}
                                    handleArrived={(data) => {
                                        setArrivedModal(true);
                                        setBookingdata(data);
                                    }}
                                    handleCompleted={(data) => {
                                        setCompletedModal(true);
                                        setBookingdata(data);
                                    }}
                                    handleAddCharge={(data) => {
                                        if (
                                            getUserFromCache()?.company
                                                .collectpayment
                                        ) {
                                            setAddChargeModal(true);
                                            setBookingdata(data);
                                        } else {
                                            setShowFeatureDisabled(true);
                                        }
                                    }}
                                    handleOverride={(data) => {
                                        setOverrideModal(true);
                                        setBookingdata(data);
                                        if (data.TimeDisplayInfo) {
                                            let dtInfo = JSON.parse(
                                                data.TimeDisplayInfo
                                            );
                                            setSelectedDate(
                                                dtInfo.dtstamp_str.slice(
                                                    0,
                                                    dtInfo.dtstamp_str.length -
                                                        6
                                                )
                                            );
                                        } else setSelectedDate(data.startdate);
                                        setSelectProvider({
                                            ...data.provider,
                                            title: `${data.provider.firstname} ${data.provider.lastname} (${data.provider.emailaddress})`
                                        });
                                        getProviderSchedules(
                                            data.provider,
                                            data
                                        );
                                        fetchProviders(data);
                                        if (data.orderType === "ONGOING") {
                                            fetchRecurringInfo(data);
                                        }
                                    }}
                                    handleLoadMore={async () => {
                                        if (searching) {
                                            await _getFilteredFutureRows(false);
                                        } else {
                                            _getFutureBookingsList(true);
                                        }
                                    }}
                                    endOfList={futureEndList}
                                />
                            </TabContainer>
                        )}
                        {tab === 1 && (
                            <TabContainer>
                                <BookingListTable
                                    selectedRow={(selected) =>
                                        setSelected(selected)
                                    }
                                    rows={
                                        searching ? filteredPastRows : pastRows
                                    }
                                    tab={"past"}
                                    showClientDialog={handleShowClientDialog}
                                    showNoteDialog={handleNoteDialog}
                                    refreshTable={refreshTable}
                                    role={role}
                                    hideRatingsFromClients={
                                        hideRatingsFromClients
                                    }
                                    handleCancel={(data) => {
                                        setCancelModal(true);
                                        setBookingdata(data);
                                        setTimeout(function () {
                                            setCancelModal(false);
                                            setShowYesCancelLoading(false);
                                            setShowWaiveCancelLoading(false);
                                        }, 30000);
                                    }}
                                    handleNoShow={(data) => {
                                        setNoShowModal(true);
                                        setBookingdata(data);
                                    }}
                                    handleArrived={(data) => {
                                        setArrivedModal(true);
                                        setBookingdata(data);
                                    }}
                                    handleCompleted={(data) => {
                                        setCompletedModal(true);
                                        setBookingdata(data);
                                    }}
                                    handleAddCharge={(data) => {
                                        if (
                                            getUserFromCache()?.company
                                                .collectpayment
                                        ) {
                                            setAddChargeModal(true);
                                            setBookingdata(data);
                                        } else {
                                            setShowFeatureDisabled(true);
                                        }
                                    }}
                                    handleOverride={(data) => {
                                        setOverrideModal(true);
                                        setBookingdata(data);
                                        setSelectedDate(data.startdate);
                                        setSelectProvider({
                                            ...data.provider,
                                            title: `${data.provider.firstname} ${data.provider.lastname} (${data.provider.emailaddress})`
                                        });
                                        getProviderSchedules(
                                            data.provider,
                                            data
                                        );
                                        fetchProviders(data);
                                        if (data.orderType === "ONGOING") {
                                            fetchRecurringInfo(data);
                                        }
                                    }}
                                    handleLoadMore={async () => {
                                        if (searching) {
                                            await _getFilteredPastRows(false);
                                        } else {
                                            _getPastBookingsList(true);
                                        }
                                    }}
                                    endOfList={pastEndList}
                                />
                            </TabContainer>
                        )}
                        {tab === 2 && (
                            <TabContainer>
                                <BookingReqListTable
                                    selectedRow={(selectedReq) =>
                                        setSelectedReq(selectedReq)
                                    }
                                    rows={
                                        searching ||
                                        serviceTypeOption !== 0 ||
                                        dateRangeChange
                                            ? filteredBookingReqRows
                                            : bookingReqRows
                                    }
                                    tab={"pending"}
                                    showClientDialog={handleShowClientDialog}
                                    role={role}
                                    handleReqCancel={(data) => {
                                        setCancelBookingReqModal(true);
                                        setBookingReqData(data);
                                    }}
                                    handleReqAccepted={(data) => {
                                        if (userHasAdminRole()) {
                                            setBookingReqData(data);
                                            handleAdminAcceptance(data);
                                        } else {
                                            setAcceptedModal(true);
                                            setBookingReqData(data);
                                        }
                                    }}
                                    handleReqDeclined={(data) => {
                                        setDeclinedModal(true);
                                        setBookingReqData(data);
                                    }}
                                    handleReqDetails={(data) => {
                                        if (
                                            bookingReqDataForDetails &&
                                            data &&
                                            data.id !==
                                                bookingReqDataForDetails.id
                                        )
                                            setBRLogs([]);
                                        setDetailsModal(true);
                                        setBookingReqDataForDetails(data);
                                    }}
                                />
                            </TabContainer>
                        )}
                        {tab === 3 && userHasAdminRole() && (
                            <TabContainer>
                                <BookingListTable
                                    selectedRow={(selectedReq) => {}}
                                    rows={
                                        searching
                                            ? filteredNonpendingBookingReqRows
                                            : nonpendingBookingReqRows
                                    }
                                    tab={"nonpending"}
                                    showClientDialog={handleShowClientDialog}
                                    role={role}
                                    handleReqDetails={(data) => {
                                        if (
                                            bookingReqDataForDetails &&
                                            data &&
                                            data.id !==
                                                bookingReqDataForDetails.id
                                        )
                                            setBRLogs([]);
                                        setDetailsModal(true);
                                        setBookingReqDataForDetails(data);
                                    }}
                                    handleReqAccepted={(data) => {}}
                                    handleReqDeclined={(data) => {}}
                                    handleLoadMore={async () => {
                                        if (searching) {
                                            await filterNonPendingBookingReqs(
                                                false,
                                                false
                                            );
                                        } else {
                                            readNonpendingBookingRequests(true);
                                        }
                                    }}
                                    endOfList={nonPendingEndList}
                                />
                            </TabContainer>
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

function getApptDateTimeForDisplay(datetime, tz) {
    //  console.log("getApptDateTimeForDisplay datetime", datetime, tz);
    return format(utcToZonedTime(datetime, tz), "MMM dd, yyyy h:mm a zzz", {
        timeZone: tz ? tz : "America/Toronto"
    });
    // return moment(datetime).format("MMM DD h:mm a");
}

export function prepareBookingDateTimeDisplay(bookingReqData) {
    //console.log(bookingReqData);
    // console.log(JSON.stringify(bookingReqData));
    let apptInfoForSms = bookingReqData.selectedslot.dateInfo
        ? getFullDateDisplayWithGMTOffset(bookingReqData.selectedslot.dateInfo)
        : "";
    if (!apptInfoForSms)
        apptInfoForSms = getApptDateTimeForDisplay(
            bookingReqData.selectedslot.bkDateTimeWithTzOffset
                ? bookingReqData.selectedslot.bkDateTimeWithTzOffset
                : bookingReqData.selectedslot.datetime,
            bookingReqData.selectedslot.tz
        );
    if (
        bookingReqData.repeatingAppointment &&
        bookingReqData.repeatingApptList &&
        bookingReqData.repeatingApptList.length > 0
    ) {
        let bookingLines = [];

        let numOfBookings = 1 + bookingReqData.repeatingApptList.length;

        bookingLines.push(apptInfoForSms);
        for (let adt of bookingReqData.repeatingApptList) {
            if (adt.dateInfo)
                bookingLines.push(
                    getFullDateDisplayWithGMTOffset(adt.dateInfo)
                );
            else if (adt.bkDateTimeWithTzOffset)
                bookingLines.push(
                    getApptDateTimeForDisplay(
                        adt.bkDateTimeWithTzOffset,
                        bookingReqData.selectedslot.tz
                    )
                );
            else
                bookingLines.push(
                    getApptDateTimeForDisplay(
                        adt,
                        bookingReqData.selectedslot.tz
                    )
                );
        }
        return {
            numberOfBookings: numOfBookings,
            bookingTimes: bookingLines
        };
        //return `Starting from ${apptInfoForSms.apptDateStr} at ${apptInfoForSms.apptTimeStr}.`;
    } else {
        return { numberOfBookings: 1, bookingTimes: [apptInfoForSms] };
    }
}
