import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Box, Button, Paper, Grid, FormControl, Typography, Snackbar, InputLabel, Select, MenuItem, Switch, FormControlLabel, Dialog, Tooltip } from "@mui/material";
// prettier-ignore
import { DialogTitle, DialogActions, CircularProgress, Backdrop } from "@mui/material";
import { useStyles } from "../styles/UserEditViewFormStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../modules/AmplifyServices";
import { getCurrentUser } from "aws-amplify/auth";
import { Cache } from "aws-amplify/utils";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { userByCompany } from "../queries/UserQueries";
import validator from "validator";
import { validatePhoneNumber } from "../onboarding/modules/ValidityCheck";
import { AddressEntryTextBox } from "../components/address/AddressEntryTextBox";
import {
    getUserRole,
    userHasAdminRole,
    userHasClientRole,
    userHasProviderRole,
    userHasMarketboxAdminRole,
    userHasCompanyAdminRole
} from "../user/UserCommon";
import MuiPhoneNumber from "material-ui-phone-number";
import moment from "moment";
import { SmallSpinner } from "../utils/CommonComponents/Spinner";
import { getCognitoRole } from "../user/UserCommon";
import { useTheme } from "@mui/material/styles";
import { auditUserUpdate } from "../modules/Audit";
import { getUserFromCache } from "../user/UserCommon";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";

const autocompleteService = { current: null };

export default function UsersEditViewForm(props) {
    const logger = new ConsoleLogger("UsersEditViewForm");

    const { state, dispatch, actions } = useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    //fields
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [firstname, setFirstName] = useState("");
    const [emailaddress, setEmailAddress] = useState("");
    const [originalemailaddress, setOriginalEmailAddress] = useState("");
    const [lastname, setLastName] = useState("");
    const [homephone, setHomePhone] = useState("");
    const [workphone, setWorkPhone] = useState("");
    const [mobilephone, setMobilePhone] = useState("");
    const [prefphonetype, setPrefPhoneType] = useState("MOBILE");

    const [cognitoUserData, setCognitoUserData] = useState();
    const [providerData, setProviderData] = useState();
    const [addressOneLine, setAddressOneLine] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [userstate, setUserState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [longitude, setLongitude] = useState(0);
    const [latitude, setLatitude] = useState(0);
    const [role, setRole] = useState("");
    const [oldRole, setOldRole] = useState();
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const [createdAt, setCreatedAt] = useState("");

    const [company, setCompany] = useState("");
    const [updatedAt, setUpdatedAt] = useState("");
    const [locationId, SetLocationId] = useState("");
    const [active, setActive] = useState(true);
    const [registered, setRegistered] = useState(true);
    const [selectedProvider, setselectedProvider] = useState(true);
    const [checked, SetChecked] = useState(false);
    const [mode, setMode] = useState();
    const [userMode, setUserMode] = useState(false);
    const [consent, setConsent] = useState(true);
    const [rows, setRows] = React.useState([]);
    const [companyId, setCompanyId] = useState("");
    const [canContinue, setCanContinue] = useState(false);
    const [providerId, setProviderId] = useState("");
    const [lastLogin, setLastLogin] = useState();
    const [loginCount, setLoginCount] = useState(0);
    const [providerChangeModal, setProviderChangeModal] = useState(false);
    const [emailChangeWarningModal, setEmailChangeWarningModal] =
        useState(false);
    const [createdProviderId, setCreatedProviderId] = useState(null);
    const [providerChange, setProviderChange] = useState(false);
    const [saveInProgess, setSaveInProgress] = useState(false);
    const [providerProfileCreated, setProviderProfileCreated] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const classes = useStyles();
    const theme = useTheme();
    const classesnew = usePromotionsStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const showCompanySelect = userHasMarketboxAdminRole() ? true : false;
    const [isloading, setIsloading] = useState(true);

    const loggedInUser = getUserFromCache();

    const [oldInfo, setOldInfo] = useState([]);
    //Fetching user info from cache.

    useEffect(() => {
        // if the source="user", set mode to edit
        const fetchData = async () => {
            if (props.source === "user") {
                setMode("Edit");
                setUserMode(true);
                await getUserData();
            }
            if (
                state.mode === "Edit" ||
                state.mode === "View" ||
                state.mode === "Client"
            ) {
                setMode("Edit");
                await getUserData();
            }
            if (showCompanySelect) await fetchCompanies();
            setIsloading(false);
        };
        fetchData();
    }, []);

    async function getUserData() {
        try {
            setIsloading(true);
            let userId;
            if (props.source === "user" || state.mode === "Client") {
                const user = await getCurrentUser();
                userId = user.username;
            } else {
                // editing another user from user list
                userId = state.id;
            }
            console.log("In getUserData", userId);
            setUserId(userId);
            if (!!userId) {
                const input = { id: userId };
                const result = await graphql(
                    graphqlOperation(queries.getUser, input)
                );
                console.log("getUserData", result);
                if (result.data.getUser) {
                    const {
                        firstname,
                        lastname,
                        username,
                        phonepref,
                        emailaddress,
                        homephone,
                        workphone,
                        mobilephone,
                        addressoneline,
                        street,
                        city,
                        state,
                        country,
                        postalcode,
                        longitude,
                        latitude,
                        active,
                        role,
                        contactconsent,
                        companyId,
                        providerId,
                        LoginInfo,
                        registered,
                        createdAt
                    } = result.data.getUser;
                    console.log("result", result.data.getUser);
                    setName(username);
                    setFirstName(firstname);
                    setLastName(lastname);
                    setEmailAddress(emailaddress);
                    setOriginalEmailAddress(emailaddress);
                    setAddressOneLine(addressoneline ? addressoneline : "");
                    setStreet(street);
                    setCity(city);
                    setCountry(country);
                    setAddressState(state);
                    setPostalCode(postalcode);
                    setLongitude(longitude);
                    setLatitude(latitude);
                    setHomePhone(homephone);
                    setWorkPhone(workphone);
                    setMobilePhone(mobilephone);
                    setPrefPhoneType(phonepref);
                    setCreatedAt(createdAt);
                    if (result.data.getUser.company) {
                        setCompany(result.data.getUser.company);
                    }
                    setRole(role);
                    setOldRole(role);
                    setActive(active);
                    setRegistered(registered);
                    setConsent(contactconsent ? contactconsent : false);
                    if (companyId) {
                        setCompanyId(companyId);
                    }
                    if (providerId && providerId != "") {
                        setProviderId(providerId);
                    }
                    if (LoginInfo) {
                        const loginObj = JSON.parse(LoginInfo);
                        setLastLogin(loginObj.lastLoginDate);
                        setLoginCount(loginObj.loginCount);
                    }

                    oldInfo.push({
                        firstname: firstname,
                        lastname: lastname,
                        address: addressoneline,
                        homephone: homephone,
                        mobilephone: mobilephone,
                        workphone: workphone,
                        prefphonetype: prefphonetype,
                        role: role
                    });

                    console.log("what is it", createdAt);
                }
            }
            setIsloading(false);
        } catch (e) {
            setIsloading(false);
        }
    }

    const getProvider = async (email) => {
        // check if provider already exists in database
        const filter = {
            and: [
                { deleted: { ne: true } },
                { emailaddress: { eq: email.trim().toLowerCase() } }
            ]
        };

        const result = await graphql(
            graphqlOperation(queries.providerByCompany, {
                companyId,
                filter,
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );
        console.log("Result-USERSEDITFORM");
        console.log(result);
        if (!!result && result.data.providerByCompany.items.length > 0) {
            // if duplicate found, return provider ID
            return result.data.providerByCompany.items[0].id;
        } else {
            return null;
        }
    };

    async function handleProviderChange() {
        const existingProviderId = await getProvider(emailaddress); // see if there's an existing provider
        if (!existingProviderId) {
            // no match, create provider
            setProviderChangeModal(true);
        } else {
            setProviderProfileCreated(true);
            await postApi("changeuserrole", "/changeuserrole", {
                body: { username: name, groupName: "Provider", action: "ADD" }
            });
        }
        return existingProviderId;
    }

    function validatePhone(phone, type) {
        let cleanPhone = phone.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-"); // strip brackets or dash
        let isValidPhone = validatePhoneNumber(cleanPhone);

        //check for AUS phone numbers
        if (!isValidPhone) {
            if (cleanPhone.startsWith("+61"))
                isValidPhone =
                    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/.test(
                        cleanPhone
                    );
        }

        if (isValidPhone) {
            return cleanPhone;
        } else {
            setSnackMsg("Please specifiy a valid " + type + " phone number.");
            setMsgOpen(true);
            setSaveInProgress(false);
            return null;
        }
    }

    /**
     * Function to check if a new emailaddres is already being used.
     * Note, this function only checks if the email is being used by a user within the same company with the same role
     */
    async function isUniqueEmailAddress(emailaddress) {
        let usersByCompany = await graphql(
            graphqlOperation(userByCompany, {
                companyId: company.id,
                roleEmailaddress: {
                    beginsWith: {
                        role: role,
                        emailaddress: emailaddress
                    }
                }
            })
        );

        //if the usersByCompany data length is greater than 0, then this is not a unique email
        return !(
            usersByCompany.data.userByCompany.items &&
            usersByCompany.data.userByCompany.items.length > 0
        );
    }

    // continue or cancel depending on mode
    function handleContinueCancel() {
        const user = getUserFromCache();
        console.log("asdf user is", user);
        if (
            user &&
            user.role === "COMPANY_ADMIN" &&
            user.company.subscriptionLevel.startsWith("FREE_")
        ) {
            actions.setId(user.company.id);
            actions.setMode("Edit");
            actions.setPage("CompanyAddEditForm");
        } else if (userHasProviderRole()) {
            // if provider, go back to provider profile page
            actions.setPage("ProviderAddEdit");
        } else if (userMode || userHasClientRole()) {
            // return to dashboard
            actions.setPage("BookingsListForm");
        } else {
            // return to user list
            actions.setPage("UsersForm");
        }
    }

    const fetchCompanies = async () => {
        const limit = process.env.REACT_APP_LISTLIMIT;
        const filter = {
            and: [{ active: { ne: false } }]
        };

        let listCompanies = await graphql(
            graphqlOperation(queries.listCompanys, { filter, limit })
        );
        if (listCompanies?.data?.listCompanys?.items) {
            listCompanies.data.listCompanys.items.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.localeCompare(b.name, "en", { usge: "sort" });
                } else return 0;
            });
            setRows(listCompanies.data.listCompanys.items);
        }
    };

    async function handleSaveUsers() {
        let newInfo = [
            {
                firstname: "No Change",
                lastname: "No Change",
                address: "No Change",
                homephone: "No Change",
                mobilephone: "No Change",
                workphone: "No Change",
                prefphonetype: "No Change",
                role: "No Change",
                emailaddress: "No Change"
            }
        ];
        setEmailChangeWarningModal(false);
        setSaveInProgress(true);
        let cognitoUser;
        let providerData;

        //check if the email was changed
        if (emailaddress !== originalemailaddress) {
            //1. update newInfo for the audit record
            newInfo.map((item) => {
                item.emailaddress = emailaddress;
            });

            //2. check if the emailaddress entered is a unique email address
            let isUniqueEmail = await isUniqueEmailAddress(emailaddress);
            if (!isUniqueEmail) {
                setSnackMsg(
                    "This email is already in use. Please try a different email."
                );
                setMsgOpen(true);
                setSaveInProgress(false);
                return;
            }

            //3. get the users cognito record (this function with setCognitoUserData if there is any)
            cognitoUser = await getCognitoUser(userId);

            //4. if the role is PROVIDER or COMPANY_ADMIN_PROVIDER then get provider data as we will need to update the email their aswell
            //this function will setProviderData provided that it exists
            if (role === "PROVIDER" || role === "COMPANY_ADMIN_PROVIDER") {
                providerData = await getProviderData();
            }
        }

        if (firstname === "") {
            setSnackMsg("Please enter a value for first name.");
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        if (firstname != firstname.trim()) {
            setSnackMsg(
                "Please ensure that there are no spaces before or after the first name"
            );
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        if (lastname === "") {
            setSnackMsg("Please enter a value for last name.");
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        if (lastname != lastname.trim()) {
            setSnackMsg(
                "Please ensure that there are no spaces before or after the last name"
            );
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        if (emailaddress === "") {
            setSnackMsg("Please enter a value for email address.");
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        if (!validator.isEmail(emailaddress)) {
            setSnackMsg("Please specifiy a valid email address.");
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        let userHomePhone, userWorkPhone, userMobilePhone;
        if (homephone) {
            userHomePhone = homephone;
        } else {
            userHomePhone = "";
        }

        if (workphone) {
            userWorkPhone = workphone;
        } else {
            userWorkPhone = "";
        }

        if (mobilephone) {
            userMobilePhone = mobilephone;
        } else {
            userMobilePhone = "";
        }

        if (
            userHomePhone === "" &&
            userWorkPhone === "" &&
            userMobilePhone === ""
        ) {
            setSnackMsg(
                "Please enter a value for at least one of work/home/mobile phone."
            );
            setMsgOpen(true);
            setSaveInProgress(false);
            return;
        }

        let returnPhone;
        //if preffered phone type is Home: validate the phone number in the Home number field
        if (prefphonetype == "HOME") {
            // validate home phone
            returnPhone = validatePhone(userHomePhone, "home");
            if (returnPhone) {
                userHomePhone = returnPhone;
            } else {
                return;
            }
        }
        //if preffered phone type is Work: validate the phone number in the Work number field
        if (prefphonetype == "WORK") {
            // validate work phone
            returnPhone = validatePhone(userWorkPhone, "work");
            if (returnPhone) {
                userWorkPhone = returnPhone;
            } else {
                return;
            }
        }
        //if preffered phone type is Mobile: validate the phone number in the Mobile number field
        if (prefphonetype == "MOBILE") {
            // validate mobile phone
            returnPhone = validatePhone(userMobilePhone, "mobile");
            if (returnPhone) {
                userMobilePhone = returnPhone;
            } else {
                return;
            }
        }
        /* if (role !== "CLIENT")
            if (addressOneLine === null || addressOneLine === "") {
                setSnackMsg("Please enter a value for address.");
                setMsgOpen(true);
                return;
            } */

        let newProviderId = null;
        if (providerChange && !providerProfileCreated) {
            newProviderId = await handleProviderChange();
            if (!newProviderId) {
                setSaveInProgress(false);
                return;
            }
        }

        // setting changed values for audit log
        if (firstname != oldInfo[0].firstname) {
            newInfo.map((item) => {
                item.firstname = firstname;
            });
        }

        if (lastname != oldInfo[0].lastname) {
            newInfo.map((item) => {
                item.lastname = lastname;
            });
        }

        if (addressOneLine != oldInfo[0].address) {
            newInfo.map((item) => {
                item.address = addressOneLine;
            });
        }

        if (homephone != oldInfo[0].homephone) {
            newInfo.map((item) => {
                item.homephone = homephone;
            });
        }

        if (mobilephone != oldInfo[0].mobilephone) {
            newInfo.map((item) => {
                item.mobilephone = mobilephone;
            });
        }

        if (workphone != oldInfo[0].workphone) {
            newInfo.map((item) => {
                item.workphone = workphone;
            });
        }
        if (prefphonetype != oldInfo[0].prefphonetype) {
            newInfo.map((item) => {
                item.prefphonetype = prefphonetype;
            });
        }

        if (role != oldInfo[0].role) {
            newInfo.map((item) => {
                item.role = role;
            });
        }

        //updateUser will also update the cognito record if the userRole or email was changed
        await updateUser(
            userHomePhone,
            userWorkPhone,
            userMobilePhone,
            newProviderId,
            newInfo,
            cognitoUser
        );
        //if the user is a provider or admin-provider, than also need to update the Provider record email (if the email was changed)
        if (
            (role === "PROVIDER" || role === "COMPANY_ADMIN_PROVIDER") &&
            providerData &&
            emailaddress !== originalemailaddress
        ) {
            await updateEmailProvider(providerData);
        }

        setSnackMsg("User successfully saved.");
        setMsgOpen(true);
        //window.alert(userMode);
        if (userMode) {
            if (getUserRole() == "CLIENT") {
                setSnackMsg("User successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    actions.setPage("BookingsListForm");
                }, 1000);
            } else if (getUserRole() == "PROVIDER") {
                setSnackMsg("User successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    actions.setPage("BookingsListForm");
                }, 1000);
                actions.setPage("BookingsListForm");
            } else {
                if (company && company.active) {
                    setSnackMsg("User successfully saved.");
                    setMsgOpen(true);
                    setTimeout(() => {
                        actions.setPage("DashboardForm");
                    }, 1000);
                } else {
                    if (company && !company.active) {
                        //company is inactive, so send admin to company settings
                        //this will be case for self-signup companies and steady state companies inactivated for whatever reason
                        setSnackMsg("User successfully saved.");
                        setMsgOpen(true);
                        actions.setId(company.id);
                        actions.setMode("Edit");
                        setTimeout(() => {
                            actions.setPage("CompanyAddEditForm");
                        }, 1000);
                    }
                }
            }
        } else {
            if (getUserRole() == "CLIENT") {
                setSnackMsg("User successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    actions.setPage("BookingsListForm");
                }, 1000);
            } else if (getUserRole() == "PROVIDER") {
                setSnackMsg("User successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    actions.setPage("BookingsListForm");
                }, 1000);
                actions.setPage("BookingsListForm");
            } else {
                setTimeout(() => {
                    actions.setPage("UsersForm");
                }, 1000);
            }
        }
    }

    async function getCognitoUser(id) {
        const result = await getJsonApi("changeuserrole", "/getuser", {
            queryParams: {
                username: id
            }
        });

        if (result && result.data?.UserAttributes) {
            setCognitoUserData(result.data.UserAttributes);
            return result.data.UserAttributes;
        }
    }

    async function getProviderData() {
        let providersByCompany = await graphql(
            graphqlOperation(queries.getProvider, {
                id: providerId
            })
        );

        setProviderData(providersByCompany.data.getProvider);
        return providersByCompany.data.getProvider;
    }

    const updateEmailProvider = async (providerData) => {
        const input = {
            id: providerData.id,
            companyId: providerData.company.id,
            firstname: providerData.firstname,
            lastname: providerData.lastname,
            createdAt: providerData.createdAt,
            emailaddress: emailaddress
        };

        const updateProvider = await graphql(
            graphqlOperation(mutations.updateProvider, { input })
        );
    };

    async function updateUser(
        userHomePhone,
        userWorkPhone,
        userMobilePhone,
        newProviderId,
        newInfo,
        cognitoUser
    ) {
        setSaveInProgress(true);
        try {
            let input = {
                lastname: lastname,
                firstname: firstname,
                username: name,
                emailaddress: emailaddress,
                addressoneline: addressOneLine,
                street: street,
                city: city,
                state: addressState,
                country: country,
                postalcode: postalCode,
                longitude: longitude,
                latitude: latitude,
                active: active,
                phonepref: prefphonetype,
                role: role
                // contactconsent: consent,
                // contactconsentdatetime: consentDateTime
            };
            if (role === "CLIENT") {
                input.contactconsent = consent;
                input.contactconsentdatetime = new Date().toISOString();
            }
            // only update if it has a value
            if (userHomePhone != "")
                input = { ...input, homephone: userHomePhone };
            if (userWorkPhone != "")
                input = { ...input, workphone: userWorkPhone };
            if (userMobilePhone != "")
                input = { ...input, mobilephone: userMobilePhone };

            // add company if applicable
            if (showCompanySelect && companyId) {
                input = { ...input, companyId: companyId };
                input = { ...input, userCompanyId: companyId };
            }

            // only update if it has a value
            newProviderId = createdProviderId
                ? createdProviderId
                : newProviderId;
            if (newProviderId) input = { ...input, providerId: newProviderId };
            if (loginCount === 0) {
                input = {
                    ...input,
                    LoginInfo: JSON.stringify({
                        loginCount: 1,
                        lastLoginDate: new Date()
                    })
                };
            }
            let response;
            if (mode === "Edit") {
                input.id = userId;
                response = await graphql(
                    graphqlOperation(mutations.updateUser, { input })
                );
                if (!userMode) {
                    await auditUserUpdate(
                        loggedInUser,
                        response.data.updateUser,
                        newInfo,
                        "UPDATE"
                    );
                }
            } else {
                // leave this code for now but an ADD will never happen, user is added only using registeruser in App.js during login
                response = await graphql(
                    graphqlOperation(mutations.createUser, { input })
                );
            }

            // console.log("just a test", response);

            if (response && response.data) {
                if (mode === "Edit" && role !== oldRole) {
                    //update role change in cognito
                    await postApi("changeuserrole", "/changeuserrole", {
                        body: {
                            username: name,
                            groupName: getCognitoRole(role),
                            action: "ADD"
                        }
                    });
                    await postApi("changeuserrole", "/changeuserrole", {
                        body: {
                            username: name,
                            groupName: getCognitoRole(oldRole),
                            action: "REMOVE"
                        }
                    });
                    setOldRole(role); //otherwise ADD/REMOVE action will be repeated if the user clicks on save again
                }
                if (
                    mode === "Edit" &&
                    emailaddress !== originalemailaddress &&
                    cognitoUser
                ) {
                    //update email in cognito
                    const cognitoUpdateResult = await postApi(
                        "changeuserrole",
                        "/updateemail",
                        {
                            body: {
                                username: cognitoUser[0].Value,
                                newEmail: emailaddress
                            }
                        }
                    );
                }
                setSnackMsg("User successfully saved.");
                setCanContinue(true);
                setMsgOpen(true);
                // if user editing themselves, save back to cache
                if (userMode) {
                    setTimeout(async () => {
                        await Cache.setItem("user", response.data.updateUser);
                        localStorage.setItem(
                            "loggedInMbUser",
                            JSON.stringify(response.data.updateUser)
                        );

                        actions.setPage("DashboardForm");
                    }, 1000);
                }
                //return to page
            } else {
                setSnackMsg("Unable to save your changes.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error(
                "An error occurred updating the user. The error was: ",
                error
            );
        }
        setSaveInProgress(false);
    }

    const handleCreateProviderProfile = async () => {
        setShowSpinner(true);
        const input = {
            firstname,
            lastname,
            emailaddress,
            phone: mobilephone
                ? mobilephone
                : workphone
                  ? workphone
                  : homephone,
            active,
            deleted: false,
            providerCompanyId: companyId,
            companyId,
            createdAt: new Date()
        };
        let newProvider = await graphql(
            graphqlOperation(mutations.createProvider, { input })
        );
        setCreatedProviderId(newProvider.data.createProvider.id);
        await postApi("changeuserrole", "/changeuserrole", {
            body: { username: name, groupName: "Provider", action: "ADD" }
        });
        setProviderChangeModal(false);
        setProviderProfileCreated(true);
        setShowSpinner(false);
    };

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    const handleHomePhoneChange = (value) => {
        setHomePhone(value);
    };

    const handleWorkPhoneChange = (value) => {
        setWorkPhone(value);
    };

    const handleMobilePhoneChange = (value) => {
        setMobilePhone(value);
    };

    const handlePrefPhoneTypeChange = (e) => {
        setPrefPhoneType(e.target.value);
    };

    // handle first name change
    const handleChangeFirstName = (e) => {
        setFirstName(e.target.value);
    };
    // handle last name change
    const handleChangeLastName = (e) => {
        setLastName(e.target.value);
    };
    // handle company change
    const handleChangeCompany = (e) => {
        setCompanyId(e.target.value);
        logger.debug("Setting Company Id = ");
        logger.debug(e.target.value);
    };

    // handle EmailAddress change
    const handleChangeEmailAddress = (e) => {
        setEmailAddress(e.target.value);
    };

    // handle role change
    const handleChangeRole = (e) => {
        setRole(e.target.value);
        if (
            e.target.value === "PROVIDER" ||
            e.target.value === "COMPANY_ADMIN_PROVIDER"
        ) {
            setProviderChange(true);
        } else {
            setProviderChange(false);
        }
    };

    /**
     * function to convert a user's role to a lower cased label
     * Example: 'PROVIDER' -> 'provider' , COMPANY_ADMIN_PROVIDER -> 'admin'
     * @param {string} role
     */
    function convertRoleToLabel(role) {
        if (role === "PROVIDER") {
            return "provider";
        } else if (role === "CLIENT") {
            return "client";
        } else {
            return "admin";
        }
    }

    function handleAddressDialogClose() {
        setAddressDialogOpen(false);
    }

    // hide active element if user is editing themselves
    function DisplayActiveRegisteredElements() {
        return (
            <div>
                {userMode ||
                !(
                    userHasAdminRole() ||
                    userHasMarketboxAdminRole() ||
                    userHasCompanyAdminRole()
                ) ? (
                    ""
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControlLabel
                                margin="normal"
                                className={classesnew.marginBottom}
                                control={
                                    <Switch
                                        checked={active}
                                        onChange={(event) =>
                                            setActive(event.target.checked)
                                        }
                                        value="active"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "primary checkbox"
                                        }}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                margin="normal"
                                className={classesnew.marginBottom}
                                control={
                                    <Switch
                                        checked={registered}
                                        value="active"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "primary checkbox"
                                        }}
                                    />
                                }
                                label="Registered"
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }

    // hide role element if user is editing themselves
    function DisplayRoleElement() {
        return (
            <div>
                {userMode ? (
                    ""
                ) : (
                    <Grid container style={{ marginBottom: "1rem" }}>
                        <Grid item xs={5}>
                            <FormControl
                                //variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel htmlFor="userrole">
                                    User Role
                                </InputLabel>
                                <Select
                                    labelId="userrole"
                                    label="User Role"
                                    className={classes.formControl}
                                    value={role}
                                    onChange={handleChangeRole}
                                    inputProps={{
                                        name: "prefphone",
                                        id: "prephone"
                                    }}
                                >
                                    <MenuItem value={"CLIENT"}>Client</MenuItem>
                                    {userHasAdminRole() ||
                                    userHasProviderRole() ? (
                                        <MenuItem value={"PROVIDER"}>
                                            Provider
                                        </MenuItem>
                                    ) : (
                                        ""
                                    )}
                                    {userHasAdminRole() ? (
                                        <MenuItem value={"COMPANY_ADMIN"}>
                                            Company Admin
                                        </MenuItem>
                                    ) : (
                                        ""
                                    )}{" "}
                                    {userHasAdminRole() ? (
                                        <MenuItem
                                            value={"COMPANY_ADMIN_PROVIDER"}
                                        >
                                            Company Admin Provider
                                        </MenuItem>
                                    ) : (
                                        ""
                                    )}
                                    {userHasMarketboxAdminRole() ? (
                                        <MenuItem value={"MARKETBOX_ADMIN"}>
                                            MarketBox Admin
                                        </MenuItem>
                                    ) : (
                                        ""
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }

    // hide cancel element if user is editing themselves (ie. after registration)
    function DisplayCancelElement() {
        return (
            <div>
                {userMode ? (
                    ""
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinueCancel}
                    >
                        Cancel
                    </Button>
                )}
                ;
            </div>
        );
    }

    function DisplayContinueCancelButton() {
        if (userMode) {
            return "";
        } else {
            return (
                <Button
                    style={{ marginLeft: "1rem" }}
                    variant="contained"
                    color="primary"
                    disabled={!canContinue}
                    onClick={handleContinueCancel}
                >
                    {userMode ? "Continue" : "Cancel"}
                </Button>
            );
        }
    }

    function DisplayCompanyElement() {
        return showCompanySelect ? (
            <FormControl
                //variant="outlined"
                className={classes.formControl}
                fullWidth
            >
                <InputLabel htmlFor="company-type">
                    Assign Company to User
                </InputLabel>
                <Select
                    labelId="company-type"
                    label="Assign Company to User"
                    value={companyId}
                    onChange={handleChangeCompany}
                    className={classes.formControl}
                    inputProps={{
                        name: "type",
                        id: "company-type"
                    }}
                >
                    {rows &&
                        rows.length > 0 &&
                        rows.map((item, i) => {
                            return (
                                <MenuItem
                                    value={item.id}
                                    key={`${item.id}${item.i}`}
                                >
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        ) : (
            ""
        );
    }

    function handleAddressDialogSave(
        addressOneLine,
        street,
        city,
        state,
        postalCode,
        country,
        lat,
        lon,
        checked
    ) {
        //   console.log("street", street)
        setAddressDialogOpen(false);
        setAddressOneLine(addressOneLine);
        setStreet(street);
        setCity(city);
        setAddressState(state);
        setPostalCode(postalCode);
        setCountry(country);
        setLongitude(lon);
        setLatitude(lat);
        SetChecked(checked);
    }

    function handleAddressFocus() {
        if (!addressDialogOpen) {
            setAddressDialogOpen(true);
        }
    }

    if (isloading) {
        return (
            <Backdrop
                style={{
                    zIndex: theme.zIndex.drawer + 1,
                    color: "#fff"
                }}
                open={true}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog open={providerChangeModal}>
                <DialogTitle>
                    No provider profile was found for this user. Would you like
                    to create a provider profile for them?
                </DialogTitle>
                {showSpinner && <SmallSpinner />}
                <DialogActions>
                    <Button
                        onClick={() => {
                            actions.setPage("UsersForm");
                        }}
                        color="primary"
                        disabled={showSpinner}
                    >
                        No
                    </Button>
                    <Button
                        onClick={handleCreateProviderProfile}
                        color="primary"
                        autoFocus
                        disabled={showSpinner}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SmallSizedModal
                open={emailChangeWarningModal}
                onClose={() => {
                    setEmailChangeWarningModal(false);
                }}
                headingIcon={WarningTriangleIcon}
                headingText={"Change email address"}
            >
                <Box>
                    <Typography variant="body2">
                        Changing this {`${convertRoleToLabel(role)}'s`} email
                        address will update their unique identifier in the
                        system and will affect all scheduled email notifications
                        to this person. Are you sure you wish to continue?
                    </Typography>
                </Box>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1.25rem"
                    }}
                >
                    <Button
                        sx={{
                            height: "2.25rem",
                            boxShadow: "none",
                            minWidth: "5.625rem",
                            alignItems: "center",
                            borderColor: "primary.main",
                            lineHeight: "inherit",

                            //paddingTop: "0.55rem",

                            "&:hover": {
                                boxShadow: "none"
                            }
                        }}
                        onClick={() => {
                            setEmailChangeWarningModal(false);
                        }}
                        variant="outlined"
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                fontFamily: "Roboto",
                                paddingTop: "0.1rem"
                            }}
                        >
                            Cancel
                        </div>
                        {/*  Cancel */}
                    </Button>
                    <Button
                        sx={{
                            height: "2.25rem",
                            boxShadow: "none",
                            minWidth: "5.625rem",
                            alignItems: "center",
                            lineHeight: "inherit",
                            //  paddingTop: "0.6rem",
                            "&:hover": {
                                boxShadow: "none"
                            }
                        }}
                        variant="contained"
                        onClick={handleSaveUsers}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                fontFamily: "Roboto",
                                paddingTop: "0.1rem"
                            }}
                        >
                            Continue
                        </div>

                        {/* Continue */}
                    </Button>
                </Box>
            </SmallSizedModal>
            <Typography
                className={classes.title}
                sx={{ fontSize: "24px", fontWeight: 400, marginBottom: "20px" }}
                variant="h4"
                noWrap
            >
                {props.source === "user"
                    ? "Welcome! Please enter your address to complete your profile."
                    : state.mode === "Client"
                      ? "Edit My Profile"
                      : "Users \\ " + mode}
            </Typography>
            <Paper rounded="true" style={{ padding: "1.5rem" }}>
                <Grid style={{ marginBottom: "1rem" }} container>
                    <Grid item xs={12} sm={5}>
                        <FormControl
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <Tooltip
                                title={
                                    userHasAdminRole()
                                        ? ""
                                        : state.mode === "Edit" ||
                                            state.mode === "Client"
                                          ? "Email address cannot be changed"
                                          : ""
                                }
                                placement="right"
                            >
                                <TextField
                                    id="email"
                                    label="Email Address"
                                    disabled={
                                        userHasAdminRole()
                                            ? false
                                            : state.mode === "Edit" ||
                                              state.mode === "Client"
                                    }
                                    className={classes.textField}
                                    value={emailaddress}
                                    onChange={handleChangeEmailAddress}
                                />
                            </Tooltip>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{ gap: "1rem" }}>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <FormControl
                            fullWidth
                            required
                            className={classes.formControl}
                        >
                            <Tooltip
                                title={
                                    providerId === ""
                                        ? ""
                                        : "This user is a provider. Please update on the provider page"
                                }
                                placement="bottom-end"
                            >
                                <TextField
                                    id="firstname"
                                    label="First Name"
                                    disabled={providerId !== ""}
                                    className={classes.textField}
                                    value={firstname}
                                    onChange={handleChangeFirstName}
                                />
                            </Tooltip>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <FormControl
                            fullWidth
                            required
                            className={classes.formControl}
                        >
                            <Tooltip
                                title={
                                    providerId === ""
                                        ? ""
                                        : "This user is a provider. Please update on the provider page"
                                }
                                placement="bottom-end"
                            >
                                <TextField
                                    id="lastname"
                                    label="Last Name"
                                    disabled={providerId !== ""}
                                    className={classes.textField}
                                    value={lastname}
                                    onChange={handleChangeLastName}
                                />
                            </Tooltip>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <AddressEntryTextBox
                            setAddress={handleAddressDialogSave}
                            autocompleteService={autocompleteService}
                            addressOneLine={addressOneLine}
                            marginAbove={true}
                        />
                    </Grid>
                </Grid>

                <Grid style={{ marginBottom: "1rem" }} container>
                    <Grid item xs={12} sm={5}>
                        {DisplayCompanyElement()}
                    </Grid>
                </Grid>

                <Grid style={{ marginBottom: "1rem" }} container>
                    <Grid item xs={12} sm={5}>
                        <Tooltip
                            title={
                                providerId === ""
                                    ? ""
                                    : "This user is a provider. Please update on the provider page"
                            }
                            placement="right"
                        >
                            <FormControl style={{ width: "85%" }}>
                                <MuiPhoneNumber
                                    variant="outlined"
                                    id="mobilephone"
                                    label="Phone Number"
                                    disabled={providerId !== ""}
                                    defaultCountry={
                                        company && company.countrycode3166alpha2
                                            ? company.countrycode3166alpha2
                                            : "ca"
                                    }
                                    value={mobilephone}
                                    onChange={handleMobilePhoneChange}
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>
                </Grid>

                {/* <Grid style={{ marginBottom: "1rem" }} container>
                    <Grid item xs={12} sm={5}>
                        <FormControl style={{ width: "85%" }}>
                            <MuiPhoneNumber
                                variant="outlined"
                                id="homephone"
                                label="Home Phone"
                                inputExtraProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true
                                }}
                                defaultCountry={
                                    company && company.countrycode3166alpha2
                                        ? company.countrycode3166alpha2
                                        : "ca"
                                }
                                value={homephone}
                                onChange={handleHomePhoneChange}
                            />
                        </FormControl>
                    </Grid>
                            </Grid>*/}

                {/* <Grid style={{ marginBottom: "1rem" }} container>
                    <Grid item xs={12} sm={5}>
                        <FormControl style={{ width: "85%" }}>
                            <MuiPhoneNumber
                                variant="outlined"
                                id="workphone"
                                label="Work Phone"
                                defaultCountry={
                                    company && company.countrycode3166alpha2
                                        ? company.countrycode3166alpha2
                                        : "ca"
                                }
                                value={workphone}
                                onChange={handleWorkPhoneChange}
                            />
                        </FormControl>
                    </Grid>
                            </Grid>*/}
                {/*  <Grid container style={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="prephone">
                                Preferred Phone Type
                            </InputLabel>
                            <Select
                                labelId="prephone"
                                label="Preferred Phone Type"
                                className={classes.formControl}
                                value={
                                    providerId === "" ? prefphonetype : "MOBILE"
                                }
                                disabled={providerId !== ""}
                                onChange={handlePrefPhoneTypeChange}
                                inputProps={{
                                    name: "prefphone",
                                    id: "prephone"
                                }}
                            >
                                <MenuItem value={"HOME"}>Home</MenuItem>
                                <MenuItem value={"WORK"}>Work</MenuItem>
                                <MenuItem value={"MOBILE"}>Mobile</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> */}
                {DisplayRoleElement()}
                <Grid container style={{ marginBottom: "1rem" }}>
                    <Grid item>
                        <Typography variant="body1">
                            User was created on:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Box ml={1}>
                            <Typography variant="body1">
                                {moment(createdAt).format(
                                    "MMM DD, YYYY [at] h:mm a"
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "1rem" }}>
                    <Grid item xs={6} sm={2}>
                        <Typography variant="body1">Last Login:</Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Box ml={6}>
                            <Typography variant="body1">
                                {lastLogin
                                    ? moment(lastLogin).format(
                                          "MMM DD, YYYY [at] h:mm a"
                                      )
                                    : "Never"}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "1rem" }}>
                    <Grid item xs={6} sm={2}>
                        <Typography variant="body1">Total Logins:</Typography>
                    </Grid>
                    <Grid item xs={6} sm={1}>
                        <Box ml={6}>
                            <Typography variant="body1">
                                {loginCount}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                {DisplayActiveRegisteredElements()}
                {role === "CLIENT" && (
                    <Grid container style={{ marginBottom: "2rem" }}>
                        <Grid item xs={12} sm={7}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={consent}
                                        onChange={(event) =>
                                            setConsent(event.target.checked)
                                        }
                                        value="consent"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "primary checkbox"
                                        }}
                                    />
                                }
                                label="Send me appointment reminder emails"
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid container style={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={8}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (emailaddress !== originalemailaddress) {
                                    setEmailChangeWarningModal(true);
                                } else {
                                    handleSaveUsers();
                                }
                            }}
                            disabled={state.mode === "View" || saveInProgess}
                        >
                            Save
                            {saveInProgess && (
                                <CircularProgress
                                    size={24}
                                    className={classesnew.buttonProgress}
                                />
                            )}
                        </Button>

                        {DisplayContinueCancelButton()}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
