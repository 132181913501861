import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
// prettier-ignore
import { Paper, Grid, FormControl } from "@mui/material";
// prettier-ignore
import { TextField, Button, Snackbar, Avatar } from "@mui/material";
import { useStyles } from "../styles/ProviderAddEditStyles";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import uuid from "uuid/v4";

//import { config } from "../config";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { uploadData, getUrl } from "aws-amplify/storage";

export const ProviderAddiotionalPhotosAddEdit = (props) => {
    const { state, dispatch, actions } = useContext(StoreContext);
    const classes = useStyles();
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [s3ObjectKey, setS3ObjectKey] = useState("");
    const [additionalValues, setAdditionalValues] = useState({});
    const [additionalImage, setAdditionalImage] = useState("");
    const [disable, setDisable] = useState(state.mode === "View");

    useEffect(() => {
        if (state.mode !== "Add") {
            fetchPhotos();
        }
    }, []);

    const fetchPhotos = async () => {
        const input = {
            id: state.id
        };
        try {
            const photosData = await graphql(
                graphqlOperation(queries.getProviderPhotos, input)
            );
            if (
                !!photosData &&
                !!photosData.data &&
                !!photosData.data.getProviderPhotos
            ) {
                const data = {
                    name: photosData.data.getProviderPhotos.name,
                    desc: photosData.data.getProviderPhotos.desc,
                    s3Images: [
                        { s3key: photosData.data.getProviderPhotos.s3key }
                    ]
                };
                const image = await getUrl({
                    key: photosData.data.getProviderPhotos.s3key
                });
                setAdditionalImage({ s3key: image?.url });
                setAdditionalValues(data);
            }
            console.log("data", photosData);
        } catch (err) {
            console.error("err => ", err);
        }
    };

    const handleChangeAdditional = async ({
        target: { name, id, value, files }
    }) => {
        let s3Images = [];

        if (id === "picture") {
            setDisable(true);
            const [image] = files || [];
            setAdditionalImage({
                file: image,
                s3key: URL.createObjectURL(image)
            });
            // let key = await handleUploadImage(files[item]);
            // Object.keys(files).map(async item => {
            let key = await handleUploadImage(image);
            if (key) {
                s3Images = s3Images.concat({ s3key: key });
            }
            //   if (s3Images.length === Object.keys(files).length) {
            //     setAdditionalValues(oldValues => ({
            //       ...oldValues,
            //       s3Images
            //     }));
            setAdditionalValues((oldValues) => ({
                ...oldValues,
                s3Images
            }));
            setDisable(false);
        } else {
            setAdditionalValues((oldValues) => ({
                ...oldValues,
                [id]: value
            }));
        }
    };

    const handleUploadImage = async (image) => {
        const { name: fileName, type: mimeType } = image;
        const key = `${uuid()}-${fileName}`;
        let s3Key;
        if (image) {
            // store file
            const result = await uploadData({
                key,
                data: image,
                options: {
                    contentType: mimeType
                }
            }).result;
            s3Key = result.key;
        }
        return s3Key;
    };

    function handleSaveProvider() {
        // validate
        if (!!additionalValues) {
            if (!additionalValues.name) {
                setSnackMsg("Please enter name");
                setMsgOpen(true);
                return;
            }
            if (!additionalValues.desc) {
                setSnackMsg("Please enter description");
                setMsgOpen(true);
                return;
            }
            if (
                !!additionalValues.s3Images &&
                additionalValues.s3Images.length > 0
            ) {
                if (!additionalValues.s3Images[0].s3key) {
                    setSnackMsg("Please upload a picture");
                    setMsgOpen(true);
                    return;
                }
            } else {
                setSnackMsg("Please enter image");
                setMsgOpen(true);
                return;
            }

            if (
                !!additionalValues.name &&
                !!additionalValues.desc &&
                !!additionalValues &&
                !!additionalValues.s3Images &&
                additionalValues.s3Images.length > 0
            ) {
                saveProviderData();
            }
        } else {
            setSnackMsg("Please enter values");
            setMsgOpen(true);
            return;
        }

        // save provider
        // saveProviderData();
    }

    async function saveProviderData() {
        const input = {
            name: additionalValues.name,
            desc: additionalValues.desc,
            s3key: additionalValues.s3Images[0].s3key,
            providerId: state.provider.id,
            providerPhotosProviderId: state.provider.id
        };

        try {
            if (state.mode == "Edit") {
                input.id = state.id;
                const providerData = await graphql(
                    graphqlOperation(mutations.updateProviderPhotos, { input })
                );
                if (
                    !!providerData &&
                    !!providerData.data &&
                    !!providerData.data.updateProviderPhotos
                ) {
                    setSnackMsg("Provider photos updated successfully");
                    setMsgOpen(true);
                    actions.setPage("ProviderAdditionalPhotos");
                } else {
                    setSnackMsg("Something Went Wrong!");
                    setMsgOpen(true);
                }
            } else {
                const providerData = await graphql(
                    graphqlOperation(mutations.createProviderPhotos, { input })
                );
                if (
                    !!providerData &&
                    !!providerData.data &&
                    !!providerData.data.createProviderPhotos
                ) {
                    setSnackMsg("Provider photos added successfully");
                    setMsgOpen(true);
                    actions.setPage("ProviderAdditionalPhotos");
                } else {
                    setSnackMsg("Something Went Wrong!");
                    setMsgOpen(true);
                }
            }
        } catch (err) {
            console.error("err => ", err);
            setSnackMsg("Something Went Wrong!");
            setMsgOpen(true);
        }
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="name"
                                label="Name"
                                className={classes.textField}
                                value={additionalValues.name}
                                onChange={handleChangeAdditional}
                                margin="normal"
                                inputProps={{ tabIndex: "1" }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="desc"
                                label="Description"
                                className={classes.textField}
                                value={additionalValues.desc}
                                onChange={handleChangeAdditional}
                                margin="normal"
                                inputProps={{ tabIndex: "1" }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <input
                                id="picture"
                                label="Please select a provider picture"
                                type="file"
                                style={{ margin: "10px 0px" }}
                                className={classes.textField}
                                onChange={handleChangeAdditional}
                                margin="normal"
                                disabled={state.mode === "View"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <Avatar
                            alt="Profile Picture"
                            src={additionalImage && additionalImage.s3key}
                            className={classes.bigAvatar}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            component="span"
                            m={1}
                            variant="contained"
                            color="primary"
                            onClick={handleSaveProvider}
                            disabled={disable}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
