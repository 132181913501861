import { postApi, getJsonApi } from "../modules/AmplifyServices";

/**
 * Function to return the cognito user record for a user, using their user id.
 * @param {*} id
 * @returns
 */
async function getCognitoUser(id) {
    const result = await getJsonApi("changeuserrole", "/getuser", {
        queryParams: {
            username: id
        }
    });

    if (result && result.data?.UserAttributes) {
        return result.data.UserAttributes;
    }
}

/**
 * Function to update a cognito user details. Note, currently this function will only update the users email.
 * @param {*} cognitoUser
 * @param {*} emailAddress
 */
async function updateCognitoUser(cognitoUser, emailAddress) {
    try {
        await postApi("changeuserrole", "/updateemail", {
            body: {
                username: cognitoUser[0].Value,
                newEmail: emailAddress
            }
        });
    } catch (e) {
        console.log("ERROR: Unable to update user cognito record", e);
    }
}

export { getCognitoUser, updateCognitoUser };
