import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    Grid,
    Typography,
    Box,
    MenuItem
} from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { DialogTheme } from "../styles/DialogTheme";
import { DatePickerTheme } from "../styles/DatePickerTheme";
import moment from "moment";
import { getTimeSuggestions } from "../modules/ScheduleService";
import { DateSelector } from "../components/DateSelector";
import { SuggestedDay, SuggestedTime } from "../components/SuggestedTime";
import {
    DEFAULT_NUMBER_OF_LOOKAHEAD_DAYS,
    MAX_NUMBER_OF_SUGGESTED_DAYS_TO_DISPLAY,
    MAX_NUMBER_OF_SUGGESTED_SLOTS_TO_DISPLAY
} from "../utils/Constants";
import { timezoneConversionUserToLoc } from "../utils/CalendarFunctions/timezoneFunctions";
import FakeCircularProgress from "../components/FakeCircularProgress";
import { TimeSelector } from "../components/TimeSelector";

const radioButtonStyle = {
    width: "198px",
    height: "40px",
    padding: "2px",
    gap: "2px",
    marginRight: "2px"
};

const radioGroupStyle = {
    width: "396px",
    height: "40px"
};

const SuggestedTimesDialog = ({
    open,
    handleClose,
    bookingGeoLocation,
    providerSchedule,
    selectedStartDate,
    company,
    service,
    handleSelectedSlot,
    handleSelectedFullDayWithTime,
    handleSelectedCustomDateTime,
    masterTimeZone,
    bookingLocationTimeZone
}) => {
    const [lookAheadDays, setLookAheadDays] = useState(
        DEFAULT_NUMBER_OF_LOOKAHEAD_DAYS
    );
    const [lookAheadStartDate, setLookAheadStartDate] = useState();
    const [sortOption, setSortOption] = useState("nearestDate");
    const [selectedSuggestedSlot, setSelectedSuggestedSlot] = useState();
    const [selectedDay, setSelectedDay] = useState();
    const [suggestions, setSuggestions] = useState();
    const [fetchedDays, setFetchedDays] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const [openTimeSelector, setOpenTimeSelector] = useState(false);
    const [selectedFullDayWithTime, setSelectedFullDayWithTime] = useState();
    const [
        selectedFullDayWithTimeForDisplay,
        setSelectedFullDayWithTimeForDisplay
    ] = useState();

    useEffect(() => {
        function uniqueSuggestions(fetchedSuggestions) {
            if (suggestions && fetchedSuggestions) {
                const uniqueSls = uniqueSlots([
                    ...suggestions.slots,
                    ...fetchedSuggestions.slots
                ]);
                const uniqueFDs = uniqueFullDays([
                    ...suggestions.fullDays,
                    ...fetchedSuggestions.fullDays
                ]);

                return {
                    slots: uniqueSls,
                    fullDays: uniqueFDs
                };
            } else return fetchedSuggestions;
        }
        function getFetchDataDateRange() {
            const startDate = lookAheadStartDate
                ? lookAheadStartDate
                : selectedStartDate
                  ? selectedStartDate
                  : new Date();
            const fetchDataRange = {};
            for (let lookAhead = 0; lookAhead < lookAheadDays; lookAhead++) {
                const dayMom = moment(startDate).add(lookAhead, "days");

                if (!fetchedDays.has(dayMom.format("YYYY-MM-DD"))) {
                    fetchDataRange.startDate = dayMom.toDate();
                    fetchDataRange.lookAhead = lookAheadDays - lookAhead;
                    break;
                }
            }
            return fetchDataRange;
        }
        async function getSuggestions() {
            try {
                setLoading(true);
                setSelectedSuggestedSlot();
                const fetechDataDateRange = getFetchDataDateRange();
                if (!lookAheadStartDate)
                    setLookAheadStartDate(selectedStartDate);
                if (fetechDataDateRange.startDate) {
                    const fetchedSuggestions = await getTimeSuggestions(
                        bookingGeoLocation,
                        providerSchedule,
                        fetechDataDateRange.lookAhead,
                        fetechDataDateRange.startDate,
                        MAX_NUMBER_OF_SUGGESTED_SLOTS_TO_DISPLAY,
                        MAX_NUMBER_OF_SUGGESTED_DAYS_TO_DISPLAY,
                        company,
                        service,
                        bookingLocationTimeZone
                    );

                    setSuggestions(uniqueSuggestions(fetchedSuggestions));
                    for (
                        let lookAhead = 0;
                        lookAhead < fetechDataDateRange.lookAhead;
                        lookAhead++
                    ) {
                        fetchedDays.add(
                            moment(fetechDataDateRange.startDate)
                                .add(lookAhead, "days")
                                .format("YYYY-MM-DD")
                        );
                    }
                }
            } catch (err) {
                console.log("getSuggestions error", err);
            } finally {
                setLoading(false);
            }
        }
        if (open && lookAheadDays) getSuggestions();
    }, [
        lookAheadStartDate,
        lookAheadDays,
        service,
        company,
        providerSchedule,
        bookingGeoLocation,
        open,
        selectedStartDate,
        fetchedDays,
        suggestions
    ]);

    useEffect(() => {
        if (!open) {
            setFetchedDays(new Set());
            setSuggestions();
            setSelectedSuggestedSlot();
            setSelectedDay();
            setLookAheadDays(7);
            setLookAheadStartDate();
            setSelectedFullDayWithTime();
            setSelectedFullDayWithTimeForDisplay();
        }
    }, [open]);

    const uniqueFullDays = (arr) => {
        const seenDates = new Set();
        return arr.filter((obj) => {
            if (seenDates.has(obj.date)) {
                return false;
            } else {
                seenDates.add(obj.date);
                return true;
            }
        });
    };

    const uniqueSlots = (arr) => {
        const seenDates = new Set();
        return arr.filter((obj) => {
            if (seenDates.has(obj.clusteredReadableSlot.datetime)) {
                return false;
            } else {
                seenDates.add(obj.clusteredReadableSlot.datetime);
                return true;
            }
        });
    };

    function isSelectedSlot(sl) {
        return moment(selectedSuggestedSlot?.datetime).isSame(
            moment(sl.clusteredReadableSlot.datetime)
        );
    }

    function isSelectedDay(fullDay) {
        if (moment(fullDay?.date).isSame(moment(selectedDay?.date))) {
            return {
                isSelectedDay: true,
                fullDaySelectedTime: selectedFullDayWithTimeForDisplay
            };
        }
        return { isSelectedDay: false };
    }
    async function handleConfirm() {
        if (selectedSuggestedSlot) handleSelectedSlot(selectedSuggestedSlot);
        if (selectedDay) handleSelectedFullDayWithTime(selectedFullDayWithTime);
        handleClose();
    }

    function sortSuggestedSlots(sortMethod) {
        if (suggestions?.slots?.length) {
            if (sortMethod === "leastTravelTime") {
                const sortedSlots = suggestions.slots.sort((slot1, slot2) => {
                    if (
                        slot1.adjascentBookingTravelTime >
                        slot2.adjascentBookingTravelTime
                    )
                        return 1;
                    else if (
                        slot1.adjascentBookingTravelTime <
                        slot2.adjascentBookingTravelTime
                    )
                        return -1;
                    else return 0;
                });
                suggestions.slots = sortedSlots;
                setSuggestions(suggestions);
            } else {
                const sortedSlots = suggestions.slots.sort((slot1, slot2) => {
                    const slot1DateTime = new Date(
                        slot1.clusteredReadableSlot?.datetime
                    ).valueOf();
                    const slot2DateTime = new Date(
                        slot2.clusteredReadableSlot?.datetime
                    ).valueOf();
                    if (slot1DateTime > slot2DateTime) return 1;
                    else if (slot1DateTime < slot2DateTime) return -1;
                    else return 0;
                });
                suggestions.slots = sortedSlots;
                setSuggestions(suggestions);
            }
        }
    }

    function displayOnlyWithinDateRange(day) {
        const startDateMom = moment(lookAheadStartDate).startOf("day");
        const endDateMom = moment(lookAheadStartDate)
            .add(lookAheadDays - 1, "days")
            .startOf("day");
        const momDay = moment(day.date);
        return (
            momDay.isBetween(startDateMom, endDateMom) ||
            momDay.isSame(startDateMom) ||
            momDay.isSame(endDateMom)
        );
    }
    function onSuggestedSlotSelection(slot) {
        setSelectedSuggestedSlot(slot.clusteredReadableSlot);
        setSelectedDay();
    }
    function onSuggestedDaySelection(fullDay) {
        setSelectedDay(fullDay);
        setSelectedSuggestedSlot();
        setOpenTimeSelector(true);
    }

    function handleTimeSelectedForFullDay(newFullDayWithTime) {
        if (masterTimeZone !== bookingLocationTimeZone) {
            const dateOnly = new Date(
                moment
                    .tz(
                        {
                            year: newFullDayWithTime.getFullYear(),
                            month: newFullDayWithTime.getMonth(),
                            date: newFullDayWithTime.getDate()
                        },
                        bookingLocationTimeZone
                    )
                    .format()
            );

            const bookingDateTimeAtBookingLocationForDisplay = new Date(
                moment
                    .tz(dateOnly.toISOString(), bookingLocationTimeZone)
                    .format()
            );
            bookingDateTimeAtBookingLocationForDisplay.setHours(
                newFullDayWithTime.getHours()
            );
            bookingDateTimeAtBookingLocationForDisplay.setMinutes(
                newFullDayWithTime.getMinutes()
            );

            setSelectedFullDayWithTimeForDisplay(
                bookingDateTimeAtBookingLocationForDisplay
            );

            const bookingDateTimeAtBookingLocation = new Date(
                moment
                    .tz(dateOnly.toISOString(), bookingLocationTimeZone)
                    .format()
            );
            bookingDateTimeAtBookingLocation.setHours(
                bookingDateTimeAtBookingLocation.getHours() +
                    newFullDayWithTime.getHours()
            );
            bookingDateTimeAtBookingLocation.setMinutes(
                bookingDateTimeAtBookingLocation.getMinutes() +
                    newFullDayWithTime.getMinutes()
            );
            setSelectedFullDayWithTime(bookingDateTimeAtBookingLocation);
        } else {
            setSelectedFullDayWithTime(newFullDayWithTime);
            setSelectedFullDayWithTimeForDisplay(newFullDayWithTime);
        }
        setOpenTimeSelector(false);
    }

    function getBookingDateForDisplay() {
        if (masterTimeZone !== bookingLocationTimeZone) {
            const dateTimeAtBookingLocation = timezoneConversionUserToLoc(
                selectedStartDate,
                masterTimeZone,
                bookingLocationTimeZone
            );
            return moment(dateTimeAtBookingLocation).format(
                "ddd, MMM D – h:mm A"
            );
        } else return moment(selectedStartDate).format("ddd, MMM D – h:mm A");
    }
    function getTimeZoneName(timeZoneId) {
        // Get the current date and time in the specified time zone
        const options = {
            timeZone: timeZoneId,
            timeZoneName: "long" // You can use 'long' for full name
        };

        // Format the time using Intl.DateTimeFormat to include the time zone name
        const formatter = new Intl.DateTimeFormat("en-US", options);
        const parts = formatter.formatToParts(new Date());
        // Extract the time zone name from the formatted parts
        const timeZoneName = parts.find(
            (part) => part.type === "timeZoneName"
        ).value;

        return timeZoneName;
    }

    function showTimeZoneMessage() {
        if (masterTimeZone !== bookingLocationTimeZone) {
            const timeZoneName = getTimeZoneName(bookingLocationTimeZone);
            return (
                <Box mb={2} display="flex" alignItems="center">
                    <Typography
                        sx={{
                            fontSize: "14px",
                            marginRight: "4px",
                            fontWeight: 400
                        }}
                    >
                        Times shown in {timeZoneName}
                    </Typography>
                </Box>
            );
        } else return <></>;
    }

    function closeTimeSelector() {
        setOpenTimeSelector(false);
    }

    const theme = useTheme();

    return (
        <ThemeProvider theme={DialogTheme(theme)}>
            <Dialog
                sx={{ top: "36px", bottom: "36px" }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Suggested times</DialogTitle>
                <DialogContent
                    sx={{ padding: "36px 36px 0px 36px !important" }}
                >
                    <TimeSelector
                        initialDateTime={selectedDay?.date}
                        open={openTimeSelector}
                        onSelectTime={handleTimeSelectedForFullDay}
                        onClose={closeTimeSelector}
                    />
                    <Box
                        mb={2}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "36px !important"
                        }}
                    >
                        <Typography sx={{ marginRight: "9px" }}>
                            Look ahead
                        </Typography>
                        <TextField
                            value={lookAheadDays}
                            select
                            defaultValue={DEFAULT_NUMBER_OF_LOOKAHEAD_DAYS}
                            onChange={(e) => setLookAheadDays(e.target.value)}
                            sx={{
                                width: "60px",
                                marginRight: "9px" // Hide the up-down arrows
                            }}
                            size="small"
                        >
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                        </TextField>
                        <Typography>days</Typography>
                        <ThemeProvider theme={DatePickerTheme(theme)}>
                            <DateSelector
                                initialDate={lookAheadStartDate}
                                handleDateChange={setLookAheadStartDate}
                                lookAheadDays={lookAheadDays}
                            />
                        </ThemeProvider>
                    </Box>
                    {loading && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 300
                            }}
                        >
                            <FakeCircularProgress
                                initialValue={20}
                                incrementBy={5}
                                updateDurationMiliseconds={400}
                                spinnerSize={40}
                                message="Calculating optimized times"
                            />
                        </Box>
                    )}
                    {!loading && (
                        <>
                            {suggestions?.slots?.filter(
                                displayOnlyWithinDateRange
                            ).length > 0 && (
                                <Box mb={2} sx={radioGroupStyle}>
                                    <RadioGroup
                                        row
                                        value={sortOption}
                                        onChange={(e) => {
                                            setSortOption(e.target.value);
                                            sortSuggestedSlots(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value="nearestDate"
                                            control={<Radio />}
                                            label="Sort by nearest date"
                                            sx={radioButtonStyle}
                                        />
                                        <FormControlLabel
                                            value="leastTravelTime"
                                            control={<Radio />}
                                            label="Sort by least travel time"
                                            sx={radioButtonStyle}
                                        />
                                    </RadioGroup>
                                </Box>
                            )}
                            {showTimeZoneMessage()}
                            {selectedStartDate && (
                                <>
                                    <Box
                                        mb={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: "4px"
                                            }}
                                        >
                                            Current selection:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: 700
                                            }}
                                        >
                                            {getBookingDateForDisplay()}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            <Grid
                                container
                                columnSpacing={2.25}
                                rowSpacing={2.25}
                                mb={2}
                                justifyContent={"space-between"}
                            >
                                {suggestions?.slots
                                    ?.filter(displayOnlyWithinDateRange)
                                    .map((sl, idx) => {
                                        let displayDateTime = new Date(sl.date);
                                        displayDateTime.setHours(
                                            sl.clusteredReadableSlot.hr
                                        );
                                        displayDateTime.setMinutes(
                                            sl.clusteredReadableSlot.mins
                                        );
                                        sl.displayDateTime = new Date(
                                            displayDateTime
                                        );
                                        return (
                                            <Grid item xs={6} key={idx}>
                                                <SuggestedTime
                                                    isSelected={isSelectedSlot(
                                                        sl
                                                    )}
                                                    suggestedTime={
                                                        displayDateTime
                                                    }
                                                    travelTime={
                                                        sl.adjascentBookingTravelTime
                                                    }
                                                    onSelect={
                                                        onSuggestedSlotSelection
                                                    }
                                                    slot={sl}
                                                />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                            {suggestions?.slots?.filter(
                                displayOnlyWithinDateRange
                            ).length &&
                            suggestions?.fullDays?.filter(
                                displayOnlyWithinDateRange
                            ).length ? (
                                <Typography mb={2}>
                                    OR book at any time of day on these days:
                                </Typography>
                            ) : !suggestions?.slots?.filter(
                                  displayOnlyWithinDateRange
                              ).length &&
                              suggestions?.fullDays?.filter(
                                  displayOnlyWithinDateRange
                              ).length ? (
                                <Typography mb={2}>
                                    Book at any time of day on these days
                                </Typography>
                            ) : (
                                <></>
                            )}
                            <Grid
                                container
                                columnSpacing={2.25}
                                rowSpacing={2.25}
                                mb={2}
                                justifyContent={"space-between"}
                            >
                                {suggestions?.fullDays
                                    ?.filter(displayOnlyWithinDateRange)
                                    .map((fd, index) => (
                                        <Grid item xs={6} key={index}>
                                            <SuggestedDay
                                                isSelected={isSelectedDay(fd)}
                                                suggestedDay={fd.date}
                                                onSelect={
                                                    onSuggestedDaySelection
                                                }
                                                fullDay={fd}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                            <Button
                                sx={{
                                    alignSelf: "flex-start", // Left-align the button
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "unset",
                                        color: theme.palette.primary.dark
                                    }
                                }}
                                onClick={() => {
                                    handleSelectedCustomDateTime();
                                    handleClose();
                                }}
                            >
                                Select custom date and time
                            </Button>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        item
                        gap={"18px"}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            disabled={!selectedSuggestedSlot && !selectedDay}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default SuggestedTimesDialog;
