import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import { execReadByPK, execReadBySortkey } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";
import { CSVLink } from "react-csv";
// prettier-ignore
import {
    IconButton, Grid, TextField, LinearProgress, CircularProgress, Button, Dialog, DialogContent, DialogTitle, Snackbar, ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import {
    bookingByCompany,
    listBookingStatuss
} from "../queries/ListBookingsQueries";

const textFieldStyling = {
    "& .MuiFilledInput-root": {
        background: "white",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "4px",
        width: "200px"
    },
    "& .MuiFilledInput-root:hover": {
        background: "white",
        border: "1px solid rgba(0,0,0,0.5)",
        borderRadius: "4px"
    },
    "& .MuiFilledInput-root.Mui-focused": {
        background: "white",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "4px"
    }
};
const ExportPayrollList = () => {
    const loggedInUser = getUserFromCache();
    const [exportDialog, setExportDialog] = useState();
    const [exportData, setExportData] = useState();
    const [exportDownloadReady, setExportDownloadReady] = useState(false);
    const [prepareExportData, setPrepareExportData] = useState(false);
    const [linearProgressValue, setLinearProgressValue] = useState(0);
    const [exportFromDate, setExportFromDate] = useState(new Date());
    const [exportToDate, setExportToDate] = useState(new Date());
    const [minToDate, setMinToDate] = useState(new Date());
    const [companyLocations, setCompanyLocations] = useState();
    const useCompletedBookings =
        moment(exportFromDate).format("YYYY-MM-DDT00:00:00Z") >
        "2023-10-03T23:59:59.820Z";
    const [requestsList, setRequestsList] = useState();
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const csvHeaders = loggedInUser?.company.providerMustAcceptAppt
        ? [
              { label: "Provider first name", key: "provider_firstname" },
              { label: "Provider last name", key: "provider_lastname" },
              { label: "Provider email", key: "provider_email" },
              { label: "Service name", key: "service_name" },
              { label: "Service duration (mins)", key: "service_duration" },
              { label: "Service location", key: "service_location" },
              { label: "City", key: "service_location_city" },
              { label: "State", key: "service_location_state" },
              { label: "Country", key: "service_location_country" },
              { label: "Total sessions", key: "total_bookings" },
              { label: "Completed sessions", key: "completed_bookings" },
              { label: "Completed hours", key: "completed_hours" },
              { label: "Cancelled sessions", key: "total_cancelled" },
              { label: "No-show sessions", key: "total_noshows" },
              { label: "Average rating", key: "average_rating" },
              { label: "Total ratings", key: "total_ratings" },
              { label: "Acceptance rate as 1st provider", key: "acc_rate1" },
              { label: "Acceptance rate as 2nd provider", key: "acc_rate2" },
              {
                  label: "Response time as 1st provider (mins)",
                  key: "resp_time1"
              },
              {
                  label: "Response time as 2nd provider (mins)",
                  key: "resp_time2"
              }
          ]
        : [
              { label: "Provider first name", key: "provider_firstname" },
              { label: "Provider last name", key: "provider_lastname" },
              { label: "Provider email", key: "provider_email" },
              { label: "Service name", key: "service_name" },
              { label: "Service duration (mins)", key: "service_duration" },
              { label: "Service location", key: "service_location" },
              { label: "City", key: "service_location_city" },
              { label: "State", key: "service_location_state" },
              { label: "Country", key: "service_location_country" },
              { label: "Total sessions", key: "total_bookings" },
              { label: "Completed sessions", key: "completed_bookings" },
              { label: "Completed hours", key: "completed_hours" },
              { label: "Cancelled sessions", key: "total_cancelled" },
              { label: "No-show sessions", key: "total_noshows" },
              { label: "Average rating", key: "average_rating" },
              { label: "Total ratings", key: "total_ratings" }
          ];

    async function handleExportData() {
        setPrepareExportData(true);

        let startdateTimeblockid = {
            between: [
                {
                    startdate: `${moment(exportFromDate).format(
                        "YYYY-MM-DDT00:00:00Z"
                    )}`
                },
                {
                    startdate: `${moment(exportToDate).format(
                        "YYYY-MM-DDT23:59:59Z"
                    )}`
                }
            ]
        };

        const bookingsForExport = await execReadBySortkey({
            opname: "bookingByCompany",
            op: bookingByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                startdateTimeblockid
            }
        });
        setLinearProgressValue(15);
        if (bookingsForExport.items && bookingsForExport.items.length > 0) {
            prepareCSVData(bookingsForExport.items);
        } else {
            prepareCSVData(null);
        }
    }

    async function prepareCSVData(bookingsForExport) {
        // Step 1: Initialize data structures
        const exportData = {};
        const providerList = [];

        // Step 2: Retrieve the companies locations
        const listCompanyLocations = await execReadByPK({
            opname: "companyLocationByCompany",
            op: queries.companyLocationByCompany,
            id: {
                companyId: loggedInUser.companyId
            }
        });

        if (bookingsForExport && bookingsForExport.length > 0) {
            // Step 3: Iterate through the filtered bookings and organize data
            for (const booking of bookingsForExport) {
                // Add the provider to the provider list, if not already in the list
                if (!providerList.includes(booking.provider.id)) {
                    providerList.push(booking.provider.id);
                }

                const locationId = getBookingLocationIdFromTimeBlockId(
                    booking.timeblockid
                );
                const locationName =
                    locationId === "customer-location"
                        ? "Client Address"
                        : getLocationNameFromId(
                              locationId,
                              listCompanyLocations.items
                          );

                const locationCity =
                    booking.location === "Virtual Appointment"
                        ? getLocationCityFromId(
                              locationId,
                              listCompanyLocations.items
                          )
                        : getLocationCityFromAddress(booking.location);

                const locationState =
                    booking.location === "Virtual Appointment"
                        ? getLocationStateFromId(
                              locationId,
                              listCompanyLocations.items
                          )
                        : getLocationStateFromAddress(booking.location);

                const locationCountry =
                    booking.location === "Virtual Appointment"
                        ? getLocationCountryFromId(
                              locationId,
                              listCompanyLocations.items
                          )
                        : getLocationCountryFromAddress(booking.location);

                // Create a unique key for each provider, service, location, and city combination
                const key = `${booking.provider.id}-${booking.servicetype.id}-${locationId}-${locationCity}-${locationState}-${locationCountry}`;

                // Check if the combination already exists in the export data
                if (exportData[key]) {
                    exportData[key].total_bookings += 1;
                    if (booking.rating) {
                        exportData[key].rating_sum +=
                            booking.rating.ratingstars;
                        exportData[key].total_ratings += 1;
                        exportData[key].average_rating = (
                            exportData[key].rating_sum /
                            exportData[key].total_ratings
                        ).toFixed(2);
                    }
                    if (
                        booking.status == "COMPLETED" &&
                        !useCompletedBookings
                    ) {
                        exportData[key].completed_bookings += 1;
                        exportData[key].completed_hours += booking.minutes / 60;
                    }
                    if (booking.status == "NOSHOW") {
                        exportData[key].total_noshows += 1;
                    }
                    if (booking.status == "CANCELLED") {
                        exportData[key].total_cancelled += 1;
                    }
                } else {
                    // If not, create a new entry for the provider and service

                    exportData[key] = {
                        provider_id: booking.provider.id,
                        provider_firstname: booking.provider.firstname,
                        provider_lastname: booking.provider.lastname,
                        provider_email: booking.provider.emailaddress,
                        service_name: booking.servicetype.name,
                        service_duration: booking.servicetype.minutes,
                        service_location: locationName,
                        service_location_id: locationId,
                        service_location_city: locationCity,
                        service_location_state: locationState,
                        service_location_country: locationCountry,
                        total_bookings: 1,
                        completed_bookings:
                            booking.status == "COMPLETED" &&
                            !useCompletedBookings
                                ? 1
                                : 0,
                        completed_hours:
                            booking.status == "COMPLETED" &&
                            !useCompletedBookings
                                ? booking.minutes / 60
                                : 0,
                        total_noshows: booking.status == "NOSHOW" ? 1 : 0,
                        total_cancelled: booking.status == "CANCELLED" ? 1 : 0,
                        acc_rate1_total: 0,
                        acc_rate1_accepted: 0,
                        acc_rate1: null,
                        acc_rate2_total: 0,
                        acc_rate2_accepted: 0,
                        acc_rate2: null,
                        resp_time1_sum: 0,
                        resp_time1: null,
                        resp_time2_sum: 0,
                        resp_time2: null,
                        rating_sum: booking.rating?.ratingstars
                            ? booking.rating.ratingstars
                            : null,
                        average_rating: booking.rating?.ratingstars
                            ? booking.rating.ratingstars
                            : null,
                        total_ratings: booking.rating ? 1 : 0
                    };
                }
                setLinearProgressValue(75);
            }

            const bookingRequests = await getBookingRequests(providerList);

            if (bookingRequests && bookingRequests.length > 0) {
                // Step 4: Iterate through the booking requests and add acceptance ratio and response time to the exportData
                for (const br of bookingRequests) {
                    const locationId = getBookingLocationIdFromTimeBlockId(
                        br.data.selectedScheduleId
                    );

                    const locationCity =
                        br.data.address === "Virtual Appointment"
                            ? getLocationCityFromId(
                                  locationId,
                                  listCompanyLocations.items
                              )
                            : getLocationCityFromAddress(br.data.address);
                    const locationState =
                        br.data.address === "Virtual Appointment"
                            ? getLocationStateFromId(
                                  locationId,
                                  listCompanyLocations.items
                              )
                            : getLocationStateFromAddress(br.data.address);
                    const locationCountry =
                        br.data.address === "Virtual Appointment"
                            ? getLocationCountryFromId(
                                  locationId,
                                  listCompanyLocations.items
                              )
                            : getLocationCountryFromAddress(br.data.address);

                    // Create a unique key for each provider and service combination
                    const key = `${br.providerId}-${br.data.serviceType.id}-${locationId}-${locationCity}-${locationState}-${locationCountry}`;

                    // Check if the key combination exists in the export data, if it doesnt then add it
                    if (!exportData[key]) {
                        exportData[key] = await getZeroExportRowFromBR(
                            br,
                            br.providerId,
                            listCompanyLocations.items,
                            locationCity,
                            locationState,
                            locationCountry
                        );
                    }

                    // Check if the provider is the original provider
                    // Update the correct acceptance rate
                    /* if (!exportData[key]) { */
                    if (!br.accepted) {
                        exportData[key].acc_rate1_total =
                            exportData[key].acc_rate1_total + 1;
                    } else if (br.providerId == br.acceptedBy) {
                        exportData[key].acc_rate1_total =
                            exportData[key].acc_rate1_total + 1;
                        if (br.accepted) {
                            exportData[key].acc_rate1_accepted =
                                exportData[key].acc_rate1_accepted + 1;
                            exportData[key].resp_time1_sum = br.data
                                ?.replyResponseTimeMins
                                ? exportData[key].resp_time1_sum +
                                  br.data.replyResponseTimeMins
                                : exportData[key].resp_time1_sum;
                            if (
                                exportData[key].resp_time1_sum &&
                                exportData[key].resp_time1_sum > 0
                            ) {
                                exportData[key].resp_time1 = (
                                    exportData[key].resp_time1_sum /
                                    exportData[key].acc_rate1_total
                                ).toFixed(2);
                            } else if (exportData[key].resp_time1_sum == 0) {
                                exportData[key].resp_time1 = 0.5;
                            }
                        }
                    } else {
                        // Booking was accepted by a secondary provider
                        exportData[key].acc_rate1_total =
                            exportData[key].acc_rate1_total + 1;

                        const secKey = `${br.acceptedBy}-${br.data.serviceType.id}-${locationId}`;

                        if (!exportData[secKey]) {
                            exportData[secKey] = await getZeroExportRowFromBR(
                                br,
                                br.acceptedBy,
                                listCompanyLocations.items,
                                locationCity,
                                locationState,
                                locationCountry
                            );
                        }

                        /* if (
                        // Check if the secondary provider is already in export list, and therefore has bookings within the date range
                        exportData[secKey]
                    ) { */
                        exportData[secKey].acc_rate2_total =
                            exportData[secKey].acc_rate2_total + 1;
                        if (br.accepted) {
                            exportData[secKey].acc_rate2_accepted =
                                exportData[secKey].acc_rate2_accepted + 1;
                            exportData[secKey].resp_time2_sum = br.data
                                ?.replyResponseTimeMins
                                ? exportData[secKey].resp_time2_sum +
                                  br.data.replyResponseTimeMins
                                : exportData[secKey].resp_time2_sum;
                            if (
                                exportData[secKey].resp_time2_sum &&
                                exportData[secKey].resp_time2_sum > 0
                            ) {
                                exportData[secKey].resp_time2 = (
                                    exportData[secKey].resp_time2_sum /
                                    exportData[secKey].acc_rate2_total
                                ).toFixed(2);
                            } else if (exportData[secKey].resp_time2_sum == 0) {
                                exportData[secKey].resp_time2 = 0.5;
                            }
                        }
                        if (exportData[secKey].acc_rate2_total > 0) {
                            exportData[secKey].acc_rate2 = `${(
                                (exportData[secKey].acc_rate2_accepted /
                                    exportData[secKey].acc_rate2_total) *
                                100
                            ).toFixed(2)}%`;
                        }
                        /* } else {
                        // The secondary provider has no bookings within date range, create a new row with 0 bookings
                        const secondaryProvRow = await getSecondaryProviderRow(
                            br
                        );
                        exportData[secKey] = secondaryProvRow;
                    } */
                    }

                    if (exportData[key].acc_rate1_total > 0) {
                        exportData[key].acc_rate1 = `${(
                            (exportData[key].acc_rate1_accepted /
                                exportData[key].acc_rate1_total) *
                            100
                        ).toFixed(2)}%`;
                    }
                    if (exportData[key].acc_rate2_total > 0) {
                        exportData[key].acc_rate2 = `${(
                            (exportData[key].acc_rate2_accepted /
                                exportData[key].acc_rate2_total) *
                            100
                        ).toFixed(2)}%`;
                    }
                }
            }
        }

        // Step 5: (only if the date range is after "2023-10-04T00:00:00.820Z")
        // Iterate through the completed booking list add completed bookings/hours to exportData
        if (useCompletedBookings) {
            const completedBookings =
                await getCompletedBookingsWithinDateRange();

            if (!completedBookings && !bookingsForExport) {
                {
                    setLinearProgressValue(0);
                    setPrepareExportData(false);
                    setExportDownloadReady(false);
                    setSnackMsg(
                        "No data found within this date range, please adjust the dates and try again."
                    );
                    setMsgOpen(true);
                }
            }

            if (completedBookings) {
                for (const cb of completedBookings) {
                    const locationId = getBookingLocationIdFromTimeBlockId(
                        cb.booking.timeblockid
                    );

                    const locationCity =
                        cb.booking.location === "Virtual Appointment"
                            ? getLocationCityFromId(
                                  locationId,
                                  listCompanyLocations.items
                              )
                            : getLocationCityFromAddress(cb.booking.location);

                    const locationState =
                        cb.booking.location === "Virtual Appointment"
                            ? getLocationStateFromId(
                                  locationId,
                                  listCompanyLocations.items
                              )
                            : getLocationStateFromAddress(cb.booking.location);

                    const locationCountry =
                        cb.booking.location === "Virtual Appointment"
                            ? getLocationCountryFromId(
                                  locationId,
                                  listCompanyLocations.items
                              )
                            : getLocationCountryFromAddress(
                                  cb.booking.location
                              );

                    // Create a unique key for each provider and service combination
                    const key = `${cb.booking.providerId}-${cb.booking.servicetype.id}-${locationId}-${locationCity}-${locationState}-${locationCountry}`;

                    // Check if the key combination exists in the export data, if it doesn't than add it
                    if (!exportData[key]) {
                        exportData[key] = await getZeroExportRowFromCB(
                            cb,
                            listCompanyLocations.items,
                            locationCity,
                            locationState,
                            locationCountry
                        );
                    }

                    exportData[key].completed_bookings += 1;
                    exportData[key].completed_hours += cb.booking.minutes / 60;
                }
            }
        } else if (!bookingsForExport) {
            {
                setLinearProgressValue(0);
                setPrepareExportData(false);
                setExportDownloadReady(false);
                setSnackMsg(
                    "No data found within this date range, please adjust the dates and try again."
                );
                setMsgOpen(true);
            }
        }

        const exportRows = Object.values(exportData);

        // Step 5: Sort the exportData array
        exportRows.sort((a, b) => {
            // If provider_firstname is the same, sort by provider_lastname (A->Z)
            const lastNameComparison = a.provider_lastname.localeCompare(
                b.provider_lastname
            );
            if (lastNameComparison !== 0) {
                return lastNameComparison;
            }

            // Sort by provider_firstname (A->Z)
            const firstNameComparison = a.provider_firstname.localeCompare(
                b.provider_firstname
            );
            if (firstNameComparison !== 0) {
                return firstNameComparison;
            }

            // If provider_lastname is the same, sort by service_name (A->Z)
            const serviceNameComparison = a.service_name.localeCompare(
                b.service_name
            );
            if (serviceNameComparison !== 0) {
                return serviceNameComparison;
            }

            // If service_name is the same, sort by completed_hours (Descending)
            return b.completed_hours - a.completed_hours;
        });

        if (exportRows && exportRows.length) {
            setExportData(exportRows);
            setExportDownloadReady(true);
            setLinearProgressValue(100);
        }
    }

    function getBookingLocationIdFromTimeBlockId(timeBlockId) {
        //if the timeBlockId contains 'PL', then the booking is at the customers (providers) location
        if (timeBlockId.includes("PL-")) {
            return "customer-location";
        }

        //if the timeBlockId contains 'CL', then the booking is at a company location, or online location
        if (timeBlockId.includes("CL-")) {
            //split the timeBlockId by '|' character
            const blocks = timeBlockId.split("|");

            //find the block containing 'CL-'
            for (const block of blocks) {
                if (block.includes("CL-")) {
                    //take the locationId after 'CL-' up to the first '|'
                    const locationId = block.split("CL-")[1].split("|")[0];
                    return locationId;
                }
            }
        }

        //return null if no location is PL or CL is not found
        return null;
    }

    function getLocationNameFromId(locationId, locationsList) {
        const foundLocation = locationsList.find(
            (location) => location.id === locationId
        );
        if (foundLocation) {
            return foundLocation.locationname;
        }
        return "";
    }

    function getLocationCityFromId(locationId, locationsList) {
        const foundLocation = locationsList.find(
            (location) => location.id === locationId
        );
        if (foundLocation) {
            return foundLocation.city;
        }
        return "";
    }

    function getLocationStateFromId(locationId, locationsList) {
        const foundLocation = locationsList.find(
            (location) => location.id === locationId
        );
        if (foundLocation) {
            return foundLocation.state;
        }
        return "";
    }

    function getLocationCountryFromId(locationId, locationsList) {
        const foundLocation = locationsList.find(
            (location) => location.id === locationId
        );
        if (foundLocation) {
            return foundLocation.country;
        }
        return "";
    }

    //returns the city portion of an address, assuming the city is after 2nd last or 3rd last in the address (e.g 1111 Davis Dr, Newmarket, ON L3Y 8X2, Canada)
    function getLocationCityFromAddress(address) {
        // Split the address string by commas
        const addressParts = address.split(",");
        const lastPart = addressParts[addressParts.length - 1].trim();
        const statePostalCode =
            addressParts[Math.max(0, addressParts.length - 2)].trim();
        const statePostalCodeParts = statePostalCode.split(" ");
        if (addressParts.length > 2) {
            if (
                lastPart === "Australia" ||
                lastPart === "AUS" ||
                lastPart === "United Kingdom" ||
                lastPart === "UK"
            ) {
                return statePostalCodeParts.slice(0, -2).join(" ");
            } else {
                return addressParts[
                    Math.max(0, addressParts.length - 3)
                ].trim();
            }
        } else {
            return address;
        }
    }

    //returns the state portion of an address string (e.g 1111 Davis Dr, Newmarket, ON L3Y 8X2, Canada)
    function getLocationStateFromAddress(address) {
        // Split the address string by commas
        const addressParts = address.split(",");
        const country = addressParts[addressParts.length - 1].trim();
        const statePostalCode =
            addressParts[Math.max(0, addressParts.length - 2)].trim();
        const statePostalCodeParts = statePostalCode.split(" ");
        if (country === "Australia" || country === "AUS") {
            return statePostalCodeParts[statePostalCodeParts.length - 2];
        } else if (country === "United Kingdom" || country === "UK") {
            return "";
        } else if (country === "Canada" || country === "CAN") {
            return statePostalCodeParts.slice(0, -2).join(" ");
        } else {
            return statePostalCodeParts.slice(0, -1).join(" ");
        }
    }

    //returns the country portion of an address string (e.g 1111 Davis Dr, Newmarket, ON L3Y 8X2, Canada)
    function getLocationCountryFromAddress(address) {
        // Split the address string by commas
        const addressParts = address.split(",");
        const country = addressParts[addressParts.length - 1].trim();
        return country;
    }

    async function getBookingRequests(providerIds) {
        let userREQs = [];
        let nextToken = null;

        for (const id of providerIds) {
            do {
                let result = await graphql(
                    graphqlOperation(queries.listBookingRequests, {
                        id: `P-${id}`,
                        limit: process.env.REACT_APP_LISTLIMIT,
                        filter: {
                            createdAt: {
                                between: [exportFromDate, exportToDate]
                            }
                        },
                        nextToken
                    })
                );
                if (result && result.data && result.data.listBookingRequests) {
                    userREQs.push(...result.data.listBookingRequests.items);
                    if (result.data.listBookingRequests.nextToken) {
                        nextToken = result.data.listBookingRequests.nextToken;
                    } else {
                        nextToken = null;
                    }
                } else nextToken = null;
            } while (nextToken);
        }

        if (userREQs.length) {
            userREQs = userREQs.sort((a, b) => {
                const t1 = new Date(a.createdAt).getTime();
                const t2 = new Date(b.createdAt).getTime();
                return t2 - t1;
            });
        }
        //setRequestsList(userREQs);

        const REQs = [];

        for (let cReq of userREQs) {
            cReq.data = JSON.parse(cReq.data);
            const pksk = cReq.recordType.split("|");
            let req = await graphql(
                graphqlOperation(queries.getBookingRequest, {
                    id: pksk[0],
                    recordType: `${pksk[1]}|${pksk[2]}`
                })
            );
            if (req && req.data.getBookingRequest) {
                req = req.data.getBookingRequest;
                req.data = JSON.parse(req.data);
                req.data.replyResponseTimeMins = cReq.data
                    ?.replyResponseTimeMins
                    ? cReq.data.replyResponseTimeMins
                    : null;
                REQs.push(req);
            }
        }
        return REQs;
    }

    async function getCompletedBookingsWithinDateRange() {
        let result = await graphql(
            graphqlOperation(listBookingStatuss, {
                companyId: loggedInUser.companyId,
                limit: process.env.REACT_APP_LISTLIMIT,

                status: {
                    between: [
                        `COMPLETED|${moment(exportFromDate).format(
                            "YYYY-MM-DDT00:00:00Z"
                        )}`,
                        `COMPLETED|${moment(exportToDate).format(
                            "YYYY-MM-DDT23:59:59Z"
                        )}`
                    ]
                }
            })
        );
        if (
            result &&
            result.data.listBookingStatuss &&
            result.data.listBookingStatuss.items.length
        ) {
            return result.data.listBookingStatuss.items;
        } else {
            return null;
        }
    }

    // Returns an exportRow for the original provider with all 0 values, given a Booking Request object
    async function getZeroExportRowFromBR(
        bookingRequest,
        provId,
        companyLocations,
        locationCity,
        locationState,
        locationCountry
    ) {
        let provData = null;

        if (bookingRequest.providerId !== provId) {
            const req = await graphql(
                graphqlOperation(queries.getProvider, {
                    id: bookingRequest.acceptedBy
                })
            );

            provData = req.data.getProvider;
        } else {
            provData = bookingRequest.data.provider;
        }

        const locationId = getBookingLocationIdFromTimeBlockId(
            bookingRequest.data.selectedScheduleId
        );
        const locationName =
            locationId === "customer-location"
                ? "Client Address"
                : getLocationNameFromId(locationId, companyLocations);

        const row = {
            provider_id: provData.id,
            provider_firstname: provData.firstname,
            provider_lastname: provData.lastname,
            provider_email: provData.emailaddress,
            service_name: bookingRequest.data.serviceType.name,
            service_duration: bookingRequest.data.serviceType.minutes,
            service_location: locationName,
            service_location_id: locationId,
            service_location_city: locationCity,
            service_location_state: locationState,
            service_location_country: locationCountry,
            total_bookings: 0,
            completed_bookings: 0,
            completed_hours: 0,
            total_noshows: 0,
            total_cancelled: 0,
            acc_rate1_total: 0,
            acc_rate1_accepted: 0,
            acc_rate1: null,
            acc_rate2_total: 0,
            acc_rate2_accepted: 0,
            acc_rate2: null,
            resp_time1_sum: 0,
            resp_time1: null,
            resp_time2_sum: 0,
            resp_time2: null,
            rating_sum: null,
            average_rating: null,
            total_ratings: 0
        };
        return row;
    }

    // Returns an exportRow for the providerId with all 0 values, given a Completed Booking object
    async function getZeroExportRowFromCB(
        completedBooking,
        companyLocations,
        locationCity,
        locationState,
        locationCountry
    ) {
        let provData = null;

        const req = await graphql(
            graphqlOperation(queries.getProvider, {
                id: completedBooking.booking.providerId
            })
        );

        provData = req.data.getProvider;

        const locationId = getBookingLocationIdFromTimeBlockId(
            completedBooking.booking.timeblockid
        );
        const locationName =
            locationId === "customer-location"
                ? "Client Address"
                : getLocationNameFromId(locationId, companyLocations);

        const row = {
            provider_id: provData.id,
            provider_firstname: provData.firstname,
            provider_lastname: provData.lastname,
            provider_email: provData.emailaddress,
            service_name: completedBooking.booking.desc,
            service_duration: completedBooking.booking.minutes,
            service_location: locationName,
            service_location_id: locationId,
            service_location_city: locationCity,
            service_location_state: locationState,
            service_location_country: locationCountry,
            total_bookings: 0,
            completed_bookings: 0,
            completed_hours: 0,
            total_noshows: 0,
            total_cancelled: 0,
            acc_rate1_total: 0,
            acc_rate1_accepted: 0,
            acc_rate1: null,
            acc_rate2_total: 0,
            acc_rate2_accepted: 0,
            acc_rate2: null,
            resp_time1_sum: 0,
            resp_time1: null,
            resp_time2_sum: 0,
            resp_time2: null,
            rating_sum: null,
            average_rating: null,
            total_ratings: 0
        };
        return row;
    }

    function handleExportClick() {
        setExportDialog(true);
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={exportDialog}
                fullWidth={true}
                onClose={() => {
                    setExportDialog(false);
                    setPrepareExportData(false);
                    setExportDownloadReady(false);
                    setMinToDate(new Date());
                    setExportToDate(new Date());
                    setExportFromDate(new Date());
                    setLinearProgressValue(0);
                    setExportData();
                }}
                PaperProps={{
                    sx: { width: "600px" }
                }}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Export provider performance & payroll data
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setExportDialog(false);
                            setPrepareExportData(false);
                            setExportDownloadReady(false);
                            setMinToDate(new Date());
                            setExportToDate(new Date());
                            setExportFromDate(new Date());
                            setLinearProgressValue(0);
                            setExportData();
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{ padding: "18px 24px 18px 24px" }}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={customLocale}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                gutterBottom
                                style={{ marginBottom: "6px" }}
                            >
                                {"Date range:"}
                            </Typography>
                            <Grid container sx={{ gap: "12px" }}>
                                <DesktopDatePicker
                                    value={exportFromDate}
                                    label="From"
                                    inputFormat="MM/dd/yyyy"
                                    disabled={
                                        !exportDownloadReady &&
                                        prepareExportData
                                    }
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={textFieldStyling}
                                            variant="filled"
                                            {...params}
                                        />
                                    )}
                                    onChange={(newValue) => {
                                        setLinearProgressValue(0);
                                        setPrepareExportData(false);
                                        setMinToDate(newValue);
                                        setExportFromDate(newValue);
                                        if (newValue > exportToDate) {
                                            setExportToDate(newValue);
                                        }
                                    }}
                                />
                                <DesktopDatePicker
                                    minDate={exportFromDate}
                                    value={exportToDate}
                                    label="To"
                                    inputFormat="MM/dd/yyyy"
                                    disabled={
                                        !exportDownloadReady &&
                                        prepareExportData
                                    }
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={textFieldStyling}
                                            variant="filled"
                                            {...params}
                                        />
                                    )}
                                    onChange={(newValue) => {
                                        setLinearProgressValue(0);
                                        setPrepareExportData(false);
                                        setExportToDate(newValue);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                setExportDownloadReady(false);
                                await handleExportData();
                            }}
                            disabled={prepareExportData}
                            style={{ marginTop: "18px" }}
                        >
                            Prepare Export
                        </Button>
                    </Grid>
                    {prepareExportData && (
                        <Grid
                            container
                            style={{
                                flexDirection: "column",
                                marginTop: "24px",
                                gap: "16px"
                            }}
                        >
                            {!exportDownloadReady && (
                                <CircularProgress
                                    color="primary"
                                    size={24}
                                    style={{ alignSelf: "center" }}
                                />
                            )}
                            <LinearProgress
                                color="primary"
                                variant="buffer"
                                valueBuffer={linearProgressValue}
                                value={linearProgressValue}
                            />

                            {!exportDownloadReady && (
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        color: "rgb(111 111 111 / 87%)",
                                        fontStyle: "italic",
                                        textAlign: "center"
                                    }}
                                >
                                    This may take a while...
                                </Typography>
                            )}
                        </Grid>
                    )}
                    {exportDownloadReady && (
                        <>
                            <br></br>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {`Please click the download button below to download all provider performance and payroll data.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CSVLink
                                    data={exportData}
                                    headers={csvHeaders}
                                    filename={`Provider data ${moment(
                                        exportFromDate
                                    ).format("YYYY-MM-DD")} to ${moment(
                                        exportToDate
                                    ).format("YYYY-MM-DD")}.csv`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetAppIcon />}
                                    >
                                        Download
                                    </Button>
                                </CSVLink>
                            </Grid>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <ListItem button onClick={() => handleExportClick()}>
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Provider performance & payroll" />
            </ListItem>
        </>
    );
};

export default ExportPayrollList;
