import { useState, useMemo } from "react";
import throttle from "lodash/throttle";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { searchUsers } from "../modules/Users";
import { useAtom } from "jotai";
import { companyIdAtom } from "../atoms/atoms";
import { clientByUserId } from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
export const ClientSelector = ({
    setClient,
    disableElements,
    defaultValue
}) => {
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [companyId] = useAtom(companyIdAtom);
    const fetch = useMemo(
        () => throttle(async (value) => searchUsers(value, companyId), 200),
        [companyId]
    );
    const clientsDisplay = clients.map(
        ({ emailaddress = "", firstname = "", lastname = "", id }) => ({
            title: `${firstname} ${lastname} (${emailaddress})`,
            id
        })
    );
    const handleChangeClient = async (e, value) => {
        //value is null when autocomplete is cleared
        if (value) {
            const { id: userId } = value;
            try {
                const { data, errors } = await graphql(
                    graphqlOperation(clientByUserId, { userId: userId })
                );
                if (errors) {
                    throw errors;
                }
                setClient(data.clientByUserId.items[0]);
            } catch (e) {
                console.log("Error while retrieving Client Data", e);
            }
        } else {
            setClient();
            return;
        }
    };
    const handler = async ({ target: { value } }) => {
        const users =
            typeof value === "string" && value !== "" ? await fetch(value) : [];
        setSearchTerm(value);
        setClients(users);
    };
    return (
        <>
            <Autocomplete
                style={{
                    width: "100%",
                    margin: "8px 0px"
                }}
                noOptionsText={
                    searchTerm ? "No Clients Found" : "Type to search"
                }
                options={clientsDisplay}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        required
                        label="Client"
                        onChange={handler}
                    />
                )}
                onChange={handleChangeClient}
                disabled={disableElements}
                defaultValue={defaultValue ? defaultValue : null}
            />
        </>
    );
};
