import React, { useCallback, useEffect, useRef, useState } from "react";
// prettier-ignore
import { Grid, Typography, Snackbar, Button, FormControl, InputLabel, Select, MenuItem, Box, CircularProgress, Backdrop, Menu, useTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SchedulerTheme } from "./SchedulerTheme";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { ViewState } from "@devexpress/dx-react-scheduler";
// prettier-ignore
import { Scheduler, WeekView, Appointments, Toolbar, ViewSwitcher, DayView, DateNavigator, TodayButton, AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "clsx";
import AccessTime from "@mui/icons-material/AccessTime";
import Room from "@mui/icons-material/Room";
import NotesIcon from "@mui/icons-material/Notes";
import ClientPerson from "@mui/icons-material/Person";
import Service from "@mui/icons-material/GroupWork";
import AddIcon from "@mui/icons-material/Add";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import {
    getBookingsList,
    _getAvailability
} from "./../modules/ScheduleService";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { getUnavailabilityOfProviderScheduleList } from "./../modules/ScheduleService";
import { getProviderExtended } from "../queries/ProviderAddEditQueries";
import {
    getUserFromCache,
    userHasAdminRole,
    userHasProviderOnlyRole
} from "../user/UserCommon";

import { RRule } from "rrule";
import { execReadByPK } from "../modules/DBService";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import AdhocBookingFlow from "./AdhocBookingFlow";
import { useAtom } from "jotai";
import { DialogTheme } from "../styles/DialogTheme";

import {
    customizeDate,
    getCurrentTimeAdjustedToNextHour
} from "../utils/SchedulerFunctions/DateNavigatorFormatter";

import moment from "moment-timezone";

import {
    convertTimezoneRange,
    getMasterTimezone,
    getTimezone,
    timezoneConversionUserToLoc
} from "../utils/CalendarFunctions/timezoneFunctions";
import { getTimezoneIdByGeoLoc } from "../modules/TimeService";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";
import BlockedTimeModal from "../user/components/BlockedTimeModal";
import { openBlockedTimeDialogAtom } from "../atoms/blockedTime";
import { mbxUserAtom, isMbxUserProvider } from "../atoms/atoms";

const bookedTimeColor = "#0087EE";
const timeOptions = {
    timeStyle: "short"
};

const styles = ({ theme }) => ({
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    content: {
        opacity: 0.8
    },
    container: {
        width: "100%",
        lineHeight: 1.2,
        height: "100%",
        display: "none"
    }
});
const useTooltipContentStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(3, 1),
        paddingTop: 0,
        backgroundColor: theme.palette.background.paper,
        boxSizing: "border-box",
        width: "400px",
        marginTop: "8px"
    },
    contentContainer: {
        paddingBottom: theme.spacing(1.5)
    },
    text: {
        ...theme.typography.body2,
        display: "inline-block"
    },
    title: {
        ...theme.typography.h6,
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightBold,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal"
    },
    icon: {
        verticalAlign: "middle"
    },
    contentItemIcon: {
        textAlign: "center"
    },
    grayIcon: {
        color: theme.palette.action.active
    },
    colorfulContent: {
        color: ({ color }) => color
    },
    lens: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        verticalAlign: "super"
    },
    textCenter: {
        textAlign: "center"
    },
    dateAndTitle: {
        lineHeight: 1.1
    },
    titleContainer: {
        paddingBottom: theme.spacing(2)
    },
    container: {
        paddingBottom: theme.spacing(1.5)
    }
}));

const checkForAvailabilityAndBlocked = (
    rule,
    block_sDate,
    data_sDate,
    data_eDate
) => {
    if (!rule.options.byweekday) {
        if (rule.options.freq === 2) {
            if (block_sDate >= data_sDate && block_sDate < data_eDate) {
                return true;
            } else {
                return false;
            }
        }

        if (rule.options.freq === 3) {
            const until = new Date(rule.options.until);
            const a_hours = new Date(data_sDate).getHours();
            const a_mins = new Date(data_sDate).getMinutes();

            const b_hours = new Date(data_eDate).getHours();
            const b_mins = new Date(data_eDate).getMinutes();

            const block_top = new Date(block_sDate);
            block_top.setHours(Number(a_hours), Number(a_mins));

            let block_bottom = new Date(block_sDate);
            block_bottom.setHours(Number(b_hours), Number(b_mins));

            if (b_hours < a_hours) {
                const startOfDay = new Date(block_sDate);
                startOfDay.setHours(0, 0, 0);
                const endOfDay = new Date(block_sDate);
                endOfDay.setDate(startOfDay.getDate() + 1);
                endOfDay.setHours(0, 0, 0);

                if (
                    block_sDate < until &&
                    ((block_sDate >= startOfDay &&
                        block_sDate < block_bottom) ||
                        (block_sDate >= block_top && block_sDate < endOfDay))
                ) {
                    return true;
                } else return false;
            }

            if (
                block_sDate < until &&
                block_sDate >= data_sDate &&
                block_sDate >= block_top &&
                block_sDate < block_bottom
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    if (rule.options.byweekday) {
        if (block_sDate < data_sDate) {
            return false;
        }

        const until = new Date(rule.options.until);
        const daysAvail = rule.options.byweekday
            .map((num) => (num === 7 ? 0 : num + 1))
            .join(",");

        const a_hours = new Date(data_sDate).getHours();
        const a_mins = new Date(data_sDate).getMinutes();

        const b_hours = new Date(data_eDate).getHours();
        const b_mins = new Date(data_eDate).getMinutes();

        const block_top = new Date(block_sDate);
        block_top.setHours(Number(a_hours), Number(a_mins));

        let block_bottom = new Date(block_sDate);
        block_bottom.setHours(Number(b_hours), Number(b_mins));

        let isDayAvail = daysAvail.includes(
            block_sDate.getDay() === 0 ? 7 : block_sDate.getDay()
        );

        if (b_hours < a_hours) {
            const startOfDay = new Date(block_sDate);
            startOfDay.setHours(0, 0, 0);
            const endOfDay = new Date(block_sDate);
            endOfDay.setDate(startOfDay.getDate() + 1);
            endOfDay.setHours(0, 0, 0);

            if (
                isDayAvail &&
                block_sDate < until &&
                ((block_sDate >= startOfDay && block_sDate < block_bottom) ||
                    (block_sDate >= block_top && block_sDate < endOfDay))
            ) {
                return true;
            } else return false;
        }

        if (
            isDayAvail &&
            block_sDate < until &&
            block_top <= block_sDate &&
            block_sDate < block_bottom
        ) {
            return true;
        } else {
            return false;
        }
    }
};

const checkForPending = (block_sDate, data_sDate, data_eDate) => {
    if (block_sDate >= data_sDate && block_sDate < data_eDate) {
        return true;
    }

    return false;
};

const checkForBooking = (block_sDate, data_sDate, data_eDate) => {
    if (block_sDate >= data_sDate && block_sDate < data_eDate) {
        return true;
    }

    return false;
};

const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

const Appointment = ({
    children,
    style,
    changeDateTimeCellProps,
    blockModalModeHandler,
    openBlockModalHandler,
    onClick,
    ...restProps
}) => {
    let background;
    if (
        !restProps.data.servicetype.id &&
        restProps.data.servicetype.name === "Pending Booking"
    ) {
        background = "rgba(0, 135, 238, 0.5)"; //pendingTimeColor;
    }

    if (
        !restProps.data.servicetype.id &&
        restProps.data.servicetype.name === "Blocked"
    ) {
        background = "rgba(0,0,0,0.2)";
    }

    if (restProps.data.servicetype.id && restProps.data.servicetype.name) {
        background = bookedTimeColor;
    }

    style = {
        backgroundColor: background,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor:
            restProps.data.servicetype.name === "Blocked"
                ? "#A9A9A9"
                : "#ffffff",
        borderRadius: "10px",
        bottom: localStorage.displayCalendarAs === "Week" ? "2px" : "0px",
        right: "2px",

        backgroundImage:
            restProps.data.servicetype.name === "Blocked"
                ? "repeating-linear-gradient(135deg, transparent, transparent 5px, rgba(255,255,255,0.95) 5px, rgba(255,255,255,0.95) 10px)"
                : restProps.data.servicetype.id
                  ? bookedTimeColor
                  : "unset"
    };

    return (
        <Appointments.Appointment
            {...restProps}
            style={style}
            onClick={(e) => {
                if (restProps.data.servicetype.name === "Blocked") {
                    console.log("checking something", restProps.data);

                    changeDateTimeCellProps(
                        restProps.data.startDate,
                        restProps.data.providerId,
                        restProps.data.locationId
                    );
                    blockModalModeHandler("Edit", restProps.data.id);
                    openBlockModalHandler();
                    //restProps.data.id
                } else {
                    onClick(e);
                }
            }}
        >
            {children}
        </Appointments.Appointment>
    );
};

const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(({
    classes,
    data,
    ...restProps
}) => {
    return (
        <Appointments.AppointmentContent {...restProps} data={data}>
            <Box display={"flex"} flexDirection={"column"}>
                <Typography
                    fontWeight={500}
                    fontSize={"0.7rem"}
                    color={
                        data.servicetype.name === "Blocked"
                            ? "#000000"
                            : "#ffffff"
                    }
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {`${data.clientName ? data.clientName : ""}`}
                </Typography>

                <Typography
                    fontWeight={700}
                    fontSize={"0.7rem"}
                    color={
                        data.servicetype.name === "Blocked"
                            ? "#000000"
                            : "#ffffff"
                    }
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {`${data.startDate.toLocaleTimeString(
                        "en-US",
                        timeOptions
                    )} - ${data.endDate.toLocaleTimeString(
                        "en-US",
                        timeOptions
                    )}`}
                </Typography>

                <Typography
                    fontSize={"0.7rem"}
                    fontWeight={700}
                    color={
                        data.servicetype.name === "Blocked"
                            ? "#000000"
                            : "#ffffff"
                    }
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {data.servicetype && data.servicetype.name === "Blocked"
                        ? "Time Block"
                        : data.servicetype
                          ? data.servicetype.name
                          : ""}
                </Typography>

                <Typography
                    fontWeight={500}
                    fontSize={"0.7rem"}
                    color={
                        data.servicetype.name === "Blocked"
                            ? "#000000"
                            : "#ffffff"
                    }
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {`${data.location ? data.location : ""}`}
                </Typography>
            </Box>
        </Appointments.AppointmentContent>
    );
});

const AppointmentTooltipHeader = withStyles(styles, {
    name: "Header"
})(({ children, appointmentData, classes, ...restProps }) => {
    return (
        <AppointmentTooltip.Header
            {...restProps}
            style={{
                backgroundColor: appointmentData.company.primaryColor
            }}
            appointmentData={appointmentData}
        ></AppointmentTooltip.Header>
    );
});

const AppointmentTooltipContent = ({
    appointmentData,
    formatDate,
    children
}) => {
    const [showFullText, setShowFullText] = useState(false);
    const classes = useTooltipContentStyles({
        color: appointmentData.company.primaryColor
    });
    const handleFullTextToggle = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div className={classes.content}>
            {appointmentData.clientName && (
                <Grid
                    container
                    alignItems="center"
                    className={classes.contentContainer}
                >
                    <Grid item xs={2} className={classes.textCenter}>
                        <ClientPerson className={classes.colorfulContent} />
                    </Grid>
                    <Grid item xs={10}>
                        <span>
                            {appointmentData.clientName
                                ? appointmentData.clientName
                                : ""}
                        </span>
                    </Grid>{" "}
                </Grid>
            )}

            <Grid
                container
                alignItems="center"
                className={classes.contentContainer}
            >
                <Grid item xs={2} className={classes.textCenter}>
                    <AccessTime className={classes.colorfulContent} />
                </Grid>
                <Grid item xs={10}>
                    <div className={classes.text}>
                        {appointmentData.startDate.toLocaleTimeString(
                            "en-US",
                            timeOptions
                        ) === "11:00 AM" &&
                        appointmentData.endDate.toLocaleTimeString(
                            "en-US",
                            timeOptions
                        ) === "12:00 PM"
                            ? `${appointmentData.startDate.toLocaleTimeString("en-US", timeOptions)} - 
                            ${appointmentData.endDate.toLocaleTimeString("en-US", timeOptions)}`
                            : `${appointmentData.startDate.toLocaleTimeString("en-US", timeOptions).replace(/AM|PM/, "")} -
                            ${appointmentData.endDate.toLocaleTimeString("en-US", timeOptions).replace(/AM|PM/, "")}`}
                    </div>
                </Grid>{" "}
            </Grid>

            <Grid
                container
                alignItems="flex-start"
                className={classes.titleContainer}
            >
                <Grid item xs={2} className={classes.textCenter}>
                    <Service className={classes.colorfulContent} />
                </Grid>
                <Grid item xs={10}>
                    <div>
                        <div
                            className={classNames(
                                classes.title,
                                classes.dateAndTitle
                            )}
                        >
                            {appointmentData.servicetype
                                ? appointmentData.servicetype.name
                                : ""}
                        </div>
                        <div
                            className={classNames(
                                classes.text,
                                classes.dateAndTitle
                            )}
                        >
                            {formatDate(appointmentData.startDate, {
                                dateStyle: "full"
                            })}
                        </div>
                    </div>
                </Grid>
            </Grid>

            {appointmentData.location && (
                <Grid
                    container
                    alignItems="center"
                    className={classes.contentContainer}
                >
                    <Grid item xs={2} className={classes.textCenter}>
                        <Room className={classes.colorfulContent} />
                    </Grid>
                    <Grid item xs={10}>
                        <span>{appointmentData.location}</span>
                    </Grid>
                </Grid>
            )}

            <Grid
                container
                alignItems="center"
                className={classes.contentContainer}
                style={{ alignItems: "flex-start" }}
            >
                <Grid item xs={2} className={classes.textCenter}>
                    <NotesIcon className={classes.colorfulContent} />
                </Grid>
                <Grid item xs={10}>
                    {appointmentData?.order?.Notes ? (
                        <span
                            style={{
                                whiteSpace: "pre-line"
                            }}
                        >
                            {
                                //checking if notes length is greater than 93 characters
                                JSON.parse(appointmentData.order.Notes)[0].notes
                                    .length > 93 ? (
                                    <>
                                        {showFullText ? (
                                            <>
                                                {
                                                    JSON.parse(
                                                        appointmentData.order
                                                            .Notes
                                                    )[0].notes
                                                }
                                                <Button
                                                    onClick={
                                                        handleFullTextToggle
                                                    }
                                                    style={{
                                                        textTransform:
                                                            "lowercase"
                                                    }}
                                                >
                                                    ... less
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {JSON.parse(
                                                    appointmentData.order.Notes
                                                )[0].notes.slice(0, 94)}
                                                <Button
                                                    onClick={
                                                        handleFullTextToggle
                                                    }
                                                    style={{
                                                        textTransform:
                                                            "lowercase"
                                                    }}
                                                >
                                                    ... more
                                                </Button>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    // if notes length is less then 93, then dont show more/less buttons
                                    <>
                                        {
                                            JSON.parse(
                                                appointmentData.order.Notes
                                            )[0].notes
                                        }
                                    </>
                                )
                            }
                        </span>
                    ) : appointmentData?.Notes ? ( //this section checks to see if initial booking notes exist.
                        <span //the appointmentData.order.Notes is only populated after editing a note once
                            style={{
                                whiteSpace: "pre-line"
                            }}
                        >
                            {
                                //checking if notes length is greater than 93 characters
                                JSON.parse(appointmentData.Notes)[0].notes
                                    .length > 93 ? (
                                    <>
                                        {showFullText ? (
                                            <>
                                                {
                                                    JSON.parse(
                                                        appointmentData.Notes
                                                    )[0].notes
                                                }
                                                <Button
                                                    onClick={
                                                        handleFullTextToggle
                                                    }
                                                    style={{
                                                        textTransform:
                                                            "lowercase"
                                                    }}
                                                >
                                                    ... less
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {JSON.parse(
                                                    appointmentData.Notes
                                                )[0].notes.slice(0, 94)}
                                                <Button
                                                    onClick={
                                                        handleFullTextToggle
                                                    }
                                                    style={{
                                                        textTransform:
                                                            "lowercase"
                                                    }}
                                                >
                                                    ... more
                                                </Button>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    // if notes length is less then 93, then dont show more/less buttons
                                    <>
                                        {
                                            JSON.parse(appointmentData.Notes)[0]
                                                .notes
                                        }
                                    </>
                                )
                            }
                        </span>
                    ) : (
                        <span>No notes</span>
                    )}
                </Grid>

                {appointmentData?.servicetype?.name === "Blocked" && (
                    <Grid container item xs={12} justifyContent={"flex-end"}>
                        <Button
                            variant={"contained"}
                            sx={{ textTransform: "uppercase" }}
                            onClick={() => console.log("edit mode")}
                        >
                            Edit
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

const CustomTimeTableCell = ({
    startDate,
    endDate,
    timeTableData,
    openDialog,
    provider,
    settingCellProps,
    fromPath,
    openBlockModalHandler,
    blockModalModeHandler,
    openWarningModal,
    closeWarningModal,
    showWarningModal,
    locationId,
    ...otherProps
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    let isAvailable = false;

    let style = {
        backgroundColor: "rgba(0,0,0,0.2)",
        cursor: "pointer"
    }; // default

    if (timeTableData.availability && timeTableData.availability.length > 0) {
        for (const ruleObj of timeTableData.availability) {
            const rule = RRule.fromString(ruleObj.rRule);
            if (
                checkForAvailabilityAndBlocked(
                    rule,
                    startDate,
                    ruleObj.startDate,
                    ruleObj.endDate
                )
            ) {
                isAvailable = true;
                style = {
                    backgroundColor: isHovered
                        ? "rgba(0, 135, 238,0.5)"
                        : "white",
                    cursor: "pointer"
                };
                break;
            }
        }
    }

    const checkForBlocked = () => {
        let isBlocked = false;

        if (timeTableData.blockedData && timeTableData.blockedData.length > 0) {
            for (const ruleObj of timeTableData.blockedData) {
                if (ruleObj.servicetype.name === "Blocked") {
                    const rule = RRule.fromString(ruleObj.rRule);

                    if (
                        checkForAvailabilityAndBlocked(
                            rule,
                            startDate,
                            ruleObj.startDate,
                            ruleObj.endDate
                        )
                    ) {
                        isBlocked = true;
                        break;
                    }
                }
            }
        }
        console.log("ISBLOCKEDORWHAT", isBlocked);
        return isBlocked;
    };

    const checkForBookings = () => {
        let isBooked = false;
        if (timeTableData.bookedData && timeTableData.bookedData.length > 0) {
            for (const booking of timeTableData.bookedData) {
                if (booking.servicetype.id) {
                    if (
                        startDate >= new Date(booking.startDate) &&
                        endDate <= new Date(booking.endDate)
                    ) {
                        isBooked = true;
                        break;
                    }
                }

                if (booking.servicetype.name === "Pending Booking") {
                    if (
                        checkForPending(
                            startDate,
                            booking.startDate,
                            booking.endDate
                        )
                    ) {
                        isBooked = true;
                        break;
                    }
                }
            }
        }

        return isBooked;
    };

    const openModal = () => {
        if (provider === undefined) {
            settingCellProps({
                startDate,
                endDate,
                timeTableData,
                openDialog,
                provider,
                locationId,

                ...otherProps
            });
            // setShowSnackMsg(true);
        } else if (provider) {
            settingCellProps({
                startDate,
                endDate,
                timeTableData,
                openDialog,
                provider,
                locationId,
                ...otherProps
            });
            // console.log("CellProps Provider", provider);
            openDialog();
        }
    };

    const cellClickHandler = (e) => {
        //  console.log("HandleClickHandler from provider schedule", fromPath);
        // && !checkForBookings()
        if (isAvailable && !checkForBlocked() && !checkForBookings()) {
            openModal();
        }

        if (
            !isAvailable &&
            !checkForBlocked() &&
            !checkForBookings() &&
            userHasAdminRole()
        ) {
            console.log("Handle Click on TimeTableCell cellClickHandler");
            settingCellProps({
                startDate,
                endDate,
                timeTableData,
                openDialog,
                provider,
                locationId,
                ...otherProps
            });
            openWarningModal();
        }
    };

    const addAppointmentHandler = (e) => {
        if (isAvailable) {
            openModal();
            handleClose();
        }
    };

    const addBlockTimeHandler = () => {
        if (isAvailable) {
            settingCellProps({
                startDate
            });
            blockModalModeHandler("Add");
            openBlockModalHandler();
            handleClose();
        }
    };

    const handleClick = (event) => {
        if (isAvailable && !checkForBlocked() && !checkForBookings()) {
            if (userHasProviderOnlyRole()) {
                addBlockTimeHandler();
            }

            if (userHasAdminRole()) {
                setAnchorEl(event.currentTarget);
            }
        }
        if (
            !isAvailable &&
            !checkForBlocked() &&
            !checkForBookings() &&
            userHasAdminRole()
        ) {
            console.log("Handle Click on TimeTableCell");
            settingCellProps({
                startDate,
                endDate,
                timeTableData,
                openDialog,
                provider,
                locationId,
                ...otherProps
            });
            openWarningModal();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <WeekView.TimeTableCell
                onClick={
                    fromPath === "ProviderSchedule"
                        ? handleClick
                        : cellClickHandler
                }
                onMouseEnter={() =>
                    setIsHovered(
                        isAvailable && !checkForBlocked() && !checkForBookings()
                            ? true
                            : false
                    )
                }
                onMouseLeave={() => setIsHovered(false)}
                style={style}
                {...otherProps}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: 130,
                        padding: 1
                    }
                }}
            >
                {/*userHasAdminRole() &&*/}
                {userHasAdminRole() && (
                    <MenuItem
                        sx={{ fontWeight: 600, color: "rgba(0,0,0,0.8)" }}
                        onClick={addAppointmentHandler}
                    >
                        Appointment
                    </MenuItem>
                )}

                {(userHasAdminRole() || userHasProviderOnlyRole()) && (
                    <MenuItem
                        sx={{ fontWeight: 600 }}
                        onClick={addBlockTimeHandler}
                    >
                        Block time
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

//prettier-ignore
const CustomFlexibleSpace = ({
    setSelectedLocationId,
    setCellDuration,
    cellDuration,
    locations,
    locationId,
    showLoc,
    showBookBtn,
    openLoading,
    closeLoading,
    displayListProviders,
    theme
}) => (
    <Box
        className="customFlexibleSpace"
        sx={{
            display: "flex",
            alignItems: "center",
            padding: "0px 18px",
            minWidth: localStorage?.displayCalendarAs === "day" ? "calc(100% - 545px)" : "calc(100% - 566px)",
            [theme.breakpoints.down('smallDesktop')]: {
                minWidth: localStorage?.displayCalendarAs === "day" ? "calc(100% - 377px)" : "calc(100% - 398px)"
            }
        }}
    >
        <FormControl
            sx={{
                display: "flex",
                gap: "4px",
                marginRight: "18px",
                minWidth: "100px",
                maxWidth: "100px"
            }}
        >
            <InputLabel id="resolution">Resolution</InputLabel>
            <Select
                label="Resolution"
                labelId="cell-duration"
                value={cellDuration}
                sx={{
                    "&.MuiInputLabel-root": {
                        display: "none"
                    },
                    "& fieldset": {
                        "& legend": {
                            width: "unset"
                        }
                    }
                }}
                onChange={(e) => {
                    setCellDuration(e);
                }}
            >
                <MenuItem value={60} key={"60"} sx={{ fontSize: "14px" }}>
                    60 mins
                </MenuItem>
                <MenuItem value={30} key={"30"} sx={{ fontSize: "14px" }}>
                    30 mins
                </MenuItem>
                <MenuItem value={15} key={"15"} sx={{ fontSize: "14px" }}>
                    15 mins
                </MenuItem>
            </Select>
        </FormControl>
        {/* Coverage Areas and Providers */}
        <Grid
            item
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                width: "100%"
            }}
        >
            <FormControl
                sx={{
                    width: displayListProviders.length !== 0 ? "50%" : "100%"
                }}
            >
                <InputLabel id="coverage-area">Coverage area</InputLabel>
                {/* Other toolbar content */}
                <Select
                    disabled={!showLoc}
                    labelId="coverage-area"
                    value={locationId}
                    label="Coverage area"
                    onChange={(e) => {
                        setSelectedLocationId(e);
                    }}
                >
                    {showLoc &&
                        locations.map((location, idx) => (
                            <MenuItem
                                value={location.id}
                                key={
                                    location && location.id ? location.id : idx
                                }
                            >
                                {location && location.maxtraveltype
                                    ? location.name
                                    : location.locationname}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            {displayListProviders.length !== 0 && (
                <FormControl
                    sx={{
                        width: "50%"
                    }}
                >
                    {displayListProviders("Provider")}
                </FormControl>
            )}
        </Grid>
    </Box>
);

const CustomToolbarRoot = ({ children, ...restProps }) => {
    return (
        <Toolbar.Root
            {...restProps}
            style={{
                ...restProps.style,
                padding: 0,
                alignItems: "center",
                paddingBottom: "1rem"
                /* justifyContent: "space-between" */
            }}
        >
            {children}
        </Toolbar.Root>
    );
};

const CustomDateNavigator = () => {
    // Your custom label logic
    const customLabel = "Your Custom Label";

    return <DateNavigator navigationLabel={() => customLabel} />;
};

export const AvailabilityDisplay = (props) => {
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    const displayEmptyScheduler = props?.displayEmptyScheduler
        ? props.displayEmptyScheduler
        : () => null;
    const displayListProviders = props?.displayListProviders
        ? props.displayListProviders
        : () => [];
    const [currentDate, setCurrentDate] = useState(props.currentDate);
    const [viewName, setViewName] = useState("Week");
    const [viewType, setViewType] = useState("schedule");
    const [locations, setLocations] = useState([]);
    const [selectedLoc, setSelectedLoc] = useState(null);
    const [locationId, setLocationId] = useState(null);
    const [providers, setProviders] = useState([]);
    const [provider, setProvider] = useState();
    const [availability, setAvailability] = useState([]);
    const [availabilityData, setAvailabilityData] = useState([]);
    const [filteredAvilData, setFilteredAvailData] = useState([]);
    const [filteredBlockData, setFilteredBlockData] = useState([]);
    const [bookedData, setBookedData] = useState([]);
    const [filteredbookedData, setFilteredBookedData] = useState([]);
    const [blockedData, setBlockedData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [filteredPendingData, setFilteredPendingData] = useState([]);
    const [resources, setResources] = useState([]);
    const [startWeekDate, setStartWeekDate] = useState(
        startOfWeek(new Date(), { weekStartsOn: 1 })
    );
    const [endWeekDate, setEndWeekDate] = useState(
        endOfWeek(new Date(), { weekStartsOn: 1 })
    );
    const [startMonthDate, setStartMonthDate] = useState();
    const [endMonthDate, setEndMonthDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [fullPageLoad, setFullPageLoad] = useState(true);
    const [openAddBookingDialog, setOpenAddBookingDialog] = useState(false);
    const [bookingCompleted, setBookingCompleted] = useState(false);
    const [showSnackMsg, setShowSnackMsg] = useState(false);
    const [clickedCellProps, setClickedCellProps] = useState();
    const [timeTableData, setTimeTableData] = useState([]);
    const [openBlockModal, setOpenBlockModal] = useState(false);
    const [availabilityError, setAvailabilityError] = useState(false);
    const [blockModalMode, setBlockModalMode] = useState("Add");
    const [blockModalId, setBlockModalId] = useState(null);
    const [showLocDrop, setShowLocDrop] = useState(true);
    const [showBookBtn, setShowBookBtn] = useState(true);
    const [cellDuration, setCellDuration] = useState(60);
    const [hasCellDurationValue, setHasCellDurationValue] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [masterTimezone, setMasterTimezone] = useState(null);
    const [refreshPage, setRefreshPage] = useState(false);
    const [openBlockedTimeDialog, setBlockedTimeDialog] = useAtom(
        openBlockedTimeDialogAtom
    );
    const [mbxUser] = useAtom(mbxUserAtom);
    const [isUserProvider] = useAtom(isMbxUserProvider);
    const theme = useTheme();

    const schedulerRef = useRef(null);

    const [grouping] = useState([
        {
            resourceName: "providerId"
        },
        {
            resourceName: "locationId"
        }
    ]);

    const openWarningModal = () => {
        setShowWarningModal(true);
    };

    const closeWarningModal = () => {
        setShowWarningModal(false);
    };
    const loggedInUser = getUserFromCache();
    if (!hasCellDurationValue) {
        getCellDurationForUser(loggedInUser.id);
    }

    async function getCellDurationForUser(userId) {
        try {
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `calendercellduration|${userId}`,
                    refName: "data-calendercellduration"
                })
            );

            if (exists.data.getRefData && exists.data.getRefData.refValue) {
                setCellDuration(parseInt(exists.data.getRefData.refValue, 10));
                setHasCellDurationValue(true);
            } else {
                setHasCellDurationValue(false);
            }
        } catch (error) {
            console.error("Error in getCellDurationForUser:", error);
            return false;
        }
    }

    function changeViewName(viewName) {
        setViewName(viewName);
    }

    function changeViewHandler(e) {
        setViewName(e.target.value);
    }

    const LocationIdChangeHandler = async (e) => {
        setIsLoading((prevState) => !prevState);
        console.log("Location event", e);
        setLocationId(e.target.value);

        setSelectedLoc(
            (prev) => locations.filter((item) => item.id === e.target.value)[0]
        );

        // Start loading
        try {
            await getLocationsData(e.target.value);
        } finally {
            setIsLoading((prevState) => !prevState);
        }
    };
    const cellDurationChangeHandler = async (e) => {
        setCellDuration(e.target.value);
        try {
            if (hasCellDurationValue) {
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `calendercellduration|${loggedInUser.id}`,
                            refName: "data-calendercellduration",
                            refValue: e.target.value
                        }
                    })
                );
            } else {
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `calendercellduration|${loggedInUser.id}`,
                            refName: "data-calendercellduration",
                            refValue: e.target.value
                        }
                    })
                );
                setHasCellDurationValue(true);
            }
        } catch (error) {
            console.error("Error in chanfing cellDurationForUser:", error);
        }
    };
    function setWeekDates(date) {
        setStartWeekDate(startOfWeek(date), { weekStartsOn: 1 });
        setEndWeekDate(endOfWeek(date), { weekStartsOn: 1 });
    }
    function setMonthDates(date) {
        setStartMonthDate(startOfMonth(date));
        setEndMonthDate(endOfMonth(date));
    }
    function currentDateChanged(curDate) {
        setCurrentDate(curDate);
        props.setCurrentDate(curDate);
        setIsLoading(true);
        if (viewName === "Week" || viewName === "Day") setWeekDates(curDate);
    }

    function dateNavigated(cd) {
        console.log("cd---", cd);
    }

    const fetchBlockedTimes = useCallback(
        async (startDate, endDate, locations) => {
            const companyId = loggedInUser.companyId;
            const company = loggedInUser.company;
            setIsLoading(true);
            const aProvider = await graphql(
                graphqlOperation(getProviderExtended, {
                    id: isUserProvider
                        ? mbxUser.provider.id
                        : props.selectedProviders[0].id
                })
            );
            setProvider(aProvider.data.getProvider);
            let unavblocks = await getUnavailabilityOfProviderScheduleList({
                companyId: companyId,
                provider: aProvider.data.getProvider,
                startDate: startDate,
                endDate: endDate
            });
            const totalBlocks = [];
            locations.forEach((location) => {
                unavblocks.forEach((unavblock) => {
                    const newBlock = { ...unavblock };
                    newBlock.locationId = location.id;
                    newBlock.id = newBlock.id + location.id;
                    newBlock.blocked = true;
                    newBlock.company = company;
                    totalBlocks.push(newBlock);
                });
            });
            // console.log("total blocks", totalBlocks);
            return totalBlocks;
        },
        [props.selectedProviders]
    );

    const fetchBookings = useCallback(async (startDate, endDate, locations) => {
        let bkgs = await getBookingsList(
            isUserProvider
                ? mbxUser.provider.id
                : props.selectedProviders[0].id,
            startDate,
            endDate,
            props.selectedProviders[0].timezone
        );
        const totalBlocks = [];
        bkgs.map((item) => {
            const timeBlockIdArray = item.timeblockid.split("|");
            const locationIdArr = timeBlockIdArray.filter(
                (item) => item.startsWith("CL-") || item.startsWith("PL-")
            );
            item.locationId = locationIdArr[0].substring(3);
            totalBlocks.push(item);
            // Here, we create a blocked booking for all other locations other than the original booking location
            locations.map((curLocation) => {
                if (curLocation.id != item.locationId) {
                    const blockedBooking = { ...item };
                    blockedBooking.id = blockedBooking.id + curLocation.id;
                    blockedBooking.locationId = curLocation.id;
                    blockedBooking.blocked = true;
                    totalBlocks.push(blockedBooking);
                }
            });
        });
        return totalBlocks;
    }, []);

    const fetchAvailability = useCallback(
        async (startDate, endDate) => {
            const companyId = loggedInUser.companyId;
            const company = loggedInUser.company;

            let tz = props?.selectedProviders?.[0]?.timezone;

            let massterTz = await getMasterTimezone();
            setMasterTimezone(massterTz);

            const filter = {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            };

            let providerLocations = await execReadByPK({
                opname: "locationByProvider",
                op: queries.locationByProvider,
                id: {
                    providerId: isUserProvider
                        ? mbxUser.provider.id
                        : props.selectedProviders[0].id
                },
                filter,
                sortDirection: "DESC"
            });

            let companyLocations = await execReadByPK({
                opname: "companyLocationByCompany",
                op: queries.companyLocationByCompany,
                id: {
                    companyId: companyId
                },
                filter: filter
            });

            let ava = await _getAvailability({
                companyId,
                providerArr: isUserProvider
                    ? [mbxUser.provider]
                    : props.selectedProviders
            });

            if (ava && ava.availability && ava.availability.length) {
                setProviders(ava.providers);
                setLocations(ava.locations);

                //fetch provider
                const aProvider = await graphql(
                    graphqlOperation(getProviderExtended, {
                        id: isUserProvider
                            ? mbxUser.provider.id
                            : props.selectedProviders[0].id
                    })
                );
                const providerTz = aProvider.data.getProvider.timezone;

                const blockedTimes = await fetchBlockedTimes(
                    startDate,
                    endDate,
                    ava.locations
                );

                let blockedAvail = blockedTimes.filter(
                    (item) => item.servicetype.name === "Blocked"
                );

                let pendingBookings = blockedTimes.filter(
                    (item) => item.servicetype.name === "Pending Booking"
                );

                const bkgs = await fetchBookings(
                    startDate,
                    endDate,
                    ava.locations
                );

                ava.availability.map((item) => {
                    item.servicetype = { name: "Availability" };
                    item.company = company;
                });

                const listCompanyData = [
                    ...companyLocations.items,
                    ...providerLocations.items
                ];

                const locationsData = ava.availability.map(
                    (item) => item.locationId
                );

                const filteredLocations = listCompanyData.filter(
                    (item) => locationsData.includes(item.id) && item
                );

                setLocations(filteredLocations);

                setAvailabilityData(ava.availability);
                setBlockedData(blockedAvail);
                setPendingData(pendingBookings);
                setBookedData(bkgs);

                if (!locationId) {
                    if (
                        filteredLocations[0].id &&
                        filteredLocations[0].id.length > 0
                    ) {
                        setLocationId(filteredLocations[0].id);
                        setSelectedLoc(filteredLocations[0]);
                        let filteredAvail = ava.availability.filter(
                            (item) =>
                                item.locationId === filteredLocations[0].id
                        );

                        setFilteredAvailData(filteredAvail);

                        let filteredBlock = blockedAvail.filter(
                            (item) =>
                                item.locationId === filteredLocations[0].id
                        );

                        if (providerTz !== tz) {
                            filteredBlock = filteredBlock.map((item) => {
                                let s_date = timezoneConversionUserToLoc(
                                    item.startDate,
                                    providerTz,
                                    tz
                                );
                                let e_date = timezoneConversionUserToLoc(
                                    item.endDate,
                                    providerTz,
                                    tz
                                );
                                return {
                                    ...item,
                                    startDate: s_date,
                                    endDate: e_date
                                };
                            });
                        }

                        console.log(
                            "Filter Blocked Available Times",
                            filteredBlock
                        );
                        setFilteredBlockData(filteredBlock);

                        let filteredbookedDataa = bkgs.filter(
                            (item) =>
                                item.locationId === filteredLocations[0].id
                        );

                        if (
                            filteredbookedDataa &&
                            filteredbookedDataa.length > 0
                        ) {
                            filteredbookedDataa = filteredbookedDataa.map(
                                (item) => {
                                    if (item.timezone === tz) {
                                        return item;
                                    }

                                    if (item.timezone !== tz) {
                                        // Convert the start date
                                        let s_date =
                                            timezoneConversionUserToLoc(
                                                moment(
                                                    item.startDTStr,
                                                    "MMM DD, YYYY hh:mm A"
                                                ).format("YYYY-MM-DDTHH:mm:00"), // Ensure start date is formatted consistently
                                                item.timezone,
                                                tz
                                            );

                                        // Convert the end date
                                        let e_date =
                                            timezoneConversionUserToLoc(
                                                moment(
                                                    item.endDTStr,
                                                    "MMM DD, YYYY hh:mm A"
                                                ).format("YYYY-MM-DDTHH:mm:00"), // Ensure end date is formatted consistently
                                                item.timezone,
                                                tz
                                            );

                                        // Return the item with updated dates
                                        return {
                                            ...item,
                                            startDate: s_date,
                                            endDate: e_date
                                        };
                                    }
                                }
                            );
                        }

                        let filterPending = pendingBookings.filter(
                            (item) =>
                                item.locationId === filteredLocations[0].id
                        );

                        if (providerTz !== tz) {
                            filterPending = filterPending.map((item) => {
                                let s_date = timezoneConversionUserToLoc(
                                    item.startDate,
                                    providerTz,
                                    tz
                                );
                                let e_date = timezoneConversionUserToLoc(
                                    item.endDate,
                                    providerTz,
                                    tz
                                );
                                return {
                                    ...item,
                                    startDate: s_date,
                                    endDate: e_date
                                };
                            });
                        }

                        console.log(
                            "Bookings data before after filter if ",
                            filteredbookedDataa
                        );

                        console.log(
                            "Bookings data pending before after filter if ",
                            filterPending
                        );
                        console.log(
                            "Bookings data block before after filter if ",
                            filteredBlock
                        );
                        setFilteredBookedData([
                            ...filteredbookedDataa,
                            ...filterPending,
                            ...filteredBlock
                        ]);
                        setFilteredPendingData(filterPending);

                        let dataObj = {
                            availability: [...filteredAvail],
                            bookedData: [
                                ...filteredbookedDataa,
                                ...filterPending
                            ],
                            blockedData: [...filteredBlock]
                        };
                        setTimeTableData(dataObj);
                    }
                } else {
                    setIsLoading(true);

                    let selectedLocation = filteredLocations.filter(
                        (item) => item.id === locationId
                    );
                    let filteredAvail = ava.availability.filter(
                        (item) => item.locationId === locationId
                    );

                    setSelectedLoc(selectedLocation[0]);

                    setFilteredAvailData(filteredAvail);

                    let filteredBlock = blockedAvail.filter(
                        (item) => item.locationId === locationId
                    );

                    if (providerTz !== tz) {
                        filteredBlock = filteredBlock.map((item) => {
                            let s_date = timezoneConversionUserToLoc(
                                item.startDate,
                                providerTz,
                                tz
                            );
                            let e_date = timezoneConversionUserToLoc(
                                item.endDate,
                                providerTz,
                                tz
                            );
                            return {
                                ...item,
                                startDate: s_date,
                                endDate: e_date
                            };
                        });
                    }

                    setFilteredBlockData(filteredBlock);
                    let filteredbookedDataa = bkgs.filter(
                        (item) => item.locationId === locationId
                    );

                    if (filteredbookedDataa && filteredbookedData.length > 0) {
                        filteredbookedDataa = filteredbookedDataa.map(
                            (item) => {
                                if (item.timezone === tz) {
                                    return item;
                                }

                                if (item.timezone !== tz) {
                                    // Convert the start date
                                    let s_date = timezoneConversionUserToLoc(
                                        moment(
                                            item.startDTStr,
                                            "MMM DD, YYYY hh:mm A"
                                        ).format("YYYY-MM-DDTHH:mm:00"), // Ensure start date is formatted consistently
                                        item.timezone,
                                        tz
                                    );

                                    // Convert the end date
                                    let e_date = timezoneConversionUserToLoc(
                                        moment(
                                            item.endDTStr,
                                            "MMM DD, YYYY hh:mm A"
                                        ).format("YYYY-MM-DDTHH:mm:00"), // Ensure end date is formatted consistently
                                        item.timezone,
                                        tz
                                    );

                                    /* console.log(
                                        "Filtered booked dataa Formatted Date",
                                        s_date,
                                        e_date
                                    ); */

                                    // Return the item with updated dates
                                    return {
                                        ...item,
                                        startDate: s_date,
                                        endDate: e_date
                                    };
                                }
                            }
                        );
                    }

                    //setFilteredBookedData(filteredbookedData);

                    let filterPending = pendingBookings.filter(
                        (item) => item.locationId === locationId
                    );

                    if (providerTz !== tz) {
                        filterPending = filterPending.map((item) => {
                            let s_date = timezoneConversionUserToLoc(
                                item.startDate,
                                providerTz,
                                tz
                            );
                            let e_date = timezoneConversionUserToLoc(
                                item.endDate,
                                providerTz,
                                tz
                            );
                            return {
                                ...item,
                                startDate: s_date,
                                endDate: e_date
                            };
                        });
                    }

                    console.log(
                        "Bookings data before after filter else ",
                        filteredbookedDataa,
                        filterPending,
                        filteredBlock
                    );
                    setFilteredBookedData([
                        ...filteredbookedDataa,
                        ...filterPending,
                        ...filteredBlock
                    ]);
                    setFilteredPendingData(filterPending);

                    let dataObj = {
                        availability: [...filteredAvail],
                        bookedData: [...filteredbookedDataa, ...filterPending],
                        blockedData: [...filteredBlock]
                    };
                    console.log("data object withLocationId", dataObj);

                    setTimeTableData(dataObj);
                }
                const totalBlocks = [...blockedTimes, ...bkgs];
                setAvailability(totalBlocks);
                setIsLoading(false);
                setFullPageLoad(false);
            } else {
                let compLocations = companyLocations.items.map((item) => ({
                    id: item.id,
                    text: item.locationname
                }));
                console.log("Comp Location no availability", compLocations);
                try {
                    setShowLocDrop(false);
                    setShowBookBtn(false);
                    setAvailabilityError(true);
                    let bkgs = await fetchBookings(
                        startDate,
                        endDate,
                        compLocations
                    );
                    console.log("Bookings when no availability", bkgs);
                    if (bkgs && bkgs.length > 0) {
                        bkgs = bkgs.filter((item) => {
                            if (!item.id.includes(item.locationId)) {
                                if (item.timezone === tz) {
                                    return item;
                                }

                                if (item.timezone !== tz) {
                                    // Convert the start date
                                    let s_date = timezoneConversionUserToLoc(
                                        moment(
                                            item.startDTStr,
                                            "MMM DD, YYYY hh:mm A"
                                        ).format("YYYY-MM-DDTHH:mm:00"), // Ensure start date is formatted consistently
                                        item.timezone,
                                        tz
                                    );

                                    // Convert the end date
                                    let e_date = timezoneConversionUserToLoc(
                                        moment(
                                            item.endDTStr,
                                            "MMM DD, YYYY hh:mm A"
                                        ).format("YYYY-MM-DDTHH:mm:00"), // Ensure end date is formatted consistently
                                        item.timezone,
                                        tz
                                    );

                                    // Return the item with updated dates
                                    return {
                                        ...item,
                                        startDate: s_date,
                                        endDate: e_date
                                    };
                                }
                            }
                        });

                        console.log(
                            "Bookings when no availability if block",
                            bkgs
                        );
                        setFilteredBookedData(bkgs);
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.log("No Availability Error Fetching", error);
                } finally {
                    setFullPageLoad(false);
                }
                if (userHasAdminRole()) {
                    setSnackMsg(
                        "Currently there is no availability set up for the selected provider."
                    );
                    setMsgOpen(true);
                }
            }
        },
        [locationId]
    );

    useEffect(() => {
        console.log("Logged in user availabilitydisplay", loggedInUser);
        console.count("counting useeffect", props);
        console.log("Location ID Changed filtred  locations", locationId);

        let startDate =
            viewName === "Week"
                ? startOfWeek(props.currentDate, { weekStartsOn: 1 })
                : currentDate;

        let endDate =
            viewName === "Week"
                ? endOfWeek(props.currentDate, { weekStartsOn: 1 })
                : getNextDayDate(currentDate);

        if (startDate && endDate && !bookingCompleted && !refreshPage) {
            fetchAvailability(startDate, endDate);
        }

        if (bookingCompleted) {
            setBookingCompleted(false);
        }

        if (refreshPage) {
            setRefreshPage((prevState) => false);
        }
    }, [
        props.currentDate,
        viewName,
        startWeekDate,
        endWeekDate,
        bookingCompleted,
        refreshPage
    ]);

    const fetchExtendProvider = async (id) => {
        const aProvider = await graphql(
            graphqlOperation(getProviderExtended, {
                id: id
            })
        );
        setProvider(aProvider.data.getProvider);
        console.log("Setting Provider Object", aProvider.data.getProvider);
    };

    useEffect(() => {
        console.log("Props from availDis", props.selectedProviders);
        if (
            props.selectedProviders &&
            props.selectedProviders[0] &&
            props.selectedProviders[0].id
        ) {
            fetchExtendProvider(props.selectedProviders[0].id);
        }
    }, [props.selectedProviders]);

    const getLocationsData = async (locId) => {
        //locationId && locationId.length > 0
        if (locId && locId.length > 0) {
            const filteredLocation = locations.filter(
                (item) => item.id === locId
            );
            setLocationId(locId);
            let filteredAvail = availabilityData.filter(
                (item) => item.locationId === locId
            );

            let tz = props?.selectedProviders?.[0]?.timezone;

            let filteredBlock = blockedData.filter(
                (item) => item.locationId === locId
            );

            if (tz && provider.timezone !== tz) {
                console.log(
                    "timezone conversion Filtered Block Conversion data",
                    props.selectedProviders,
                    provider,
                    tz
                );
                filteredBlock = filteredBlock.map((item) => {
                    let s_date = timezoneConversionUserToLoc(
                        item.startDate,
                        provider.timezone,
                        tz
                    );
                    let e_date = timezoneConversionUserToLoc(
                        item.endDate,
                        provider.timezone,
                        tz
                    );
                    return {
                        ...item,
                        startDate: s_date,
                        endDate: e_date
                    };
                });
            }
            let filteredbookedDataa = bookedData.filter(
                (item) => item.locationId === locId
            );

            if (filteredbookedDataa && filteredbookedData.length > 0) {
                filteredbookedDataa = filteredbookedDataa.map((item) => {
                    if (item.timezone === tz) {
                        return item;
                    }

                    if (item.timezone !== tz) {
                        // Convert the start date
                        let s_date = timezoneConversionUserToLoc(
                            moment(
                                item.startDTStr,
                                "MMM DD, YYYY hh:mm A"
                            ).format("YYYY-MM-DDTHH:mm:00"), // Ensure start date is formatted consistently
                            item.timezone,
                            tz
                        );

                        // Convert the end date
                        let e_date = timezoneConversionUserToLoc(
                            moment(
                                item.endDTStr,
                                "MMM DD, YYYY hh:mm A"
                            ).format("YYYY-MM-DDTHH:mm:00"), // Ensure end date is formatted consistently
                            item.timezone,
                            tz
                        );

                        // Return the item with updated dates
                        return {
                            ...item,
                            startDate: s_date,
                            endDate: e_date
                        };
                    }
                });
            }

            // setFi

            console.log("Location ID useEffect", locId);
            console.log("Location ID useEffect bookings", filteredbookedDataa);
            //setFilteredBookedData(filteredbookedData);

            let filterPending = pendingData.filter(
                (item) => item.locationId === locId
            );

            // setFi
            setFilteredBookedData([
                ...filteredbookedDataa,
                ...filterPending,
                ...filteredBlock
            ]);
            //setFilteredPendingData(filterPending);

            let dataObj = {
                availability: [...filteredAvail],
                bookedData: [
                    ...filteredbookedDataa,
                    ...filterPending,
                    ...filteredBlock
                ],
                blockedData: [...filteredBlock]
            };
            setTimeTableData(dataObj);
            setRefreshPage(false);
        }
    };

    function getNextDayDate(date) {
        let nd = new Date(date);
        nd.setDate(nd.getDate() + 1);
        return nd;
    }
    const handleAdhocBookingFlowDialogOpen = () => {
        setOpenAddBookingDialog(true);
    };
    const handleAdminAdhocBookingFlowDialogOpen = () => {
        const currentTime = getCurrentTimeAdjustedToNextHour();
        const endTime = new Date(currentTime).setMinutes(
            currentTime.getMinutes() + cellDuration
        );
        setClickedCellProps((prevState) => ({
            ...prevState,
            startDate: currentTime,
            endDate: endTime,
            providerId: provider ? provider.id : null,
            locationId: locationId ? locationId : null
        }));

        setOpenAddBookingDialog(true);
    };
    const handleAdhocBookingFlowDialogClose = () => {
        setOpenAddBookingDialog(false);
    };

    const continueWarningHandler = () => {
        closeWarningModal();
        handleAdhocBookingFlowDialogOpen();
    };

    function handleMsgClose(event, reason) {
        setShowSnackMsg(false);
    }

    const settingCellProps = (obj) => {
        setClickedCellProps(obj);
    };

    //eDate, prov
    const changeDateTimeCellProps = (sDate, providerId, locationId) => {
        setClickedCellProps((prevState) => ({
            ...prevState,
            startDate: sDate,
            providerId: providerId ? providerId : null,
            locationId: locationId ? locationId : null
        }));
    };

    const openLoading = () => {
        setIsLoading(true);
    };

    const closeLoading = () => {
        setIsLoading(false);
    };
    const refreshHandler = () => {
        setRefreshPage(true);
    };

    const openBlockModalHandler = () => {
        setOpenBlockModal(true);
        setBlockedTimeDialog(true);
    };

    const closeBlockModalHandler = (str) => {
        if (str === "done") {
            refreshHandler();
        }
        setOpenBlockModal(false);
    };

    const blockModalModeHandler = (str, str1) => {
        setBlockModalMode(str);

        if (str1 && str1.length > 0) {
            setBlockModalId(str1);
        }
    };

    const timeTableCellComponent = useCallback(
        (cellProps) => {
            return (
                <CustomTimeTableCell
                    {...cellProps}
                    provider={provider}
                    timeTableData={timeTableData}
                    openDialog={handleAdhocBookingFlowDialogOpen}
                    closeDialog={handleAdhocBookingFlowDialogClose}
                    settingCellProps={settingCellProps}
                    fromPath={props.fromPath}
                    openBlockModalHandler={openBlockModalHandler}
                    blockModalModeHandler={blockModalModeHandler}
                    openWarningModal={openWarningModal}
                    closeWarningModal={closeWarningModal}
                    showWarningModal={showWarningModal}
                    locationId={locationId}
                />
            );
        },
        [timeTableData]
    );

    const CustomOpenButton = ({ onVisibilityToggle, text }) => {
        let customText = customizeDate(text);
        return (
            <Button
                variant={"outlined"}
                onClick={onVisibilityToggle}
                sx={{
                    marginTop: "2px",
                    "&:hover": { backgroundColor: "white" }
                }}
            >
                {customText}
            </Button>
        );
    };

    const CustomSwitcher = ({
        currentView,
        onChange,
        availableViews,
        openLoading,
        ...restProps
    }) => {
        return (
            <ThemeProvider theme={SchedulerTheme({ palette: themePalette })}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <FormControl>
                        <InputLabel id="view-switcher">View</InputLabel>
                        {/* Other toolbar content */}
                        <Select
                            sx={{
                                display: "flex",
                                gap: "4px",
                                minWidth: "80px",
                                maxWidth: "80px"
                            }}
                            label="View"
                            labelId="view-switcher"
                            value={currentView.name}
                            onChange={async (event) => {
                                setIsLoading(true);
                                if (event.target.value === "Day") {
                                    localStorage.setItem(
                                        "displayCalendarAs",
                                        event.target.value
                                    );

                                    try {
                                        await getLocationsData(locationId);
                                    } finally {
                                        onChange(event.target.value);
                                    }
                                    setIsLoading(false);
                                }

                                if (event.target.value === "Week") {
                                    localStorage.setItem(
                                        "displayCalendarAs",
                                        event.target.value
                                    );
                                    try {
                                        await getLocationsData(locationId);
                                    } finally {
                                        onChange(event.target.value);
                                    }
                                    setIsLoading(false);
                                }
                            }}
                        >
                            {availableViews.map((view) => (
                                <MenuItem key={view.name} value={view.name}>
                                    {view.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {userHasAdminRole() && (
                        <Button
                            variant="contained"
                            onClick={handleAdminAdhocBookingFlowDialogOpen}
                            sx={{
                                maxHeight: "36px",
                                marginLeft: "18px",
                                paddingRight: "18px",
                                paddingLeft: "16px",
                                gap: "4px",
                                width: "150px",
                                /* Media query to hide the button for screens narrower than 1449px */
                                "@media screen and (max-width: 1449px)": {
                                    display: "none" // Hide the button */
                                }
                            }}
                        >
                            <AddIcon
                                sx={{
                                    color: "white",
                                    width: "18px",
                                    height: "18px"
                                }}
                            />
                            <Typography
                                sx={{
                                    marginTop: "2px",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    color: "white"
                                }}
                            >
                                Add Booking
                            </Typography>
                        </Button>
                    )}
                </Box>
            </ThemeProvider>
        );
    };

    const getBlockedTimes = async (
        startDate,
        endDate,

        providerId,
        companyId
    ) => {
        const aProvider = await graphql(
            graphqlOperation(getProviderExtended, {
                id: providerId
            })
        );
        setProvider(aProvider.data.getProvider);
        let unavblocks = await getUnavailabilityOfProviderScheduleList({
            companyId: companyId,
            provider: aProvider.data.getProvider,
            startDate: startDate,
            endDate: endDate
        });

        return unavblocks;
    };

    const getBookings = async (
        startDate,
        endDate,
        providerId,
        providerTimezone
    ) => {
        let bookings = await getBookingsList(
            providerId,
            startDate,
            endDate,
            providerTimezone
        );

        return bookings;
    };

    const availOrblocked = async (providerId, locId, sDate, eDate) => {
        const filteredProvider = props.selectingProviderHandler(providerId);
        if (locId === "0") return;

        setAvailabilityError(false);
        const compId = loggedInUser.companyId;
        let availabilityObj = await _getAvailability({
            companyId: compId,
            providerArr: [filteredProvider]
        });

        let bookingsObj = await getBookings(
            sDate,
            eDate,
            filteredProvider.id,
            filteredProvider.timezone
        );

        let blockedObj = await getBlockedTimes(
            sDate,
            eDate,
            providerId,
            compId
        );

        if (
            availabilityObj &&
            availabilityObj.availability &&
            availabilityObj.availability.length > 0
        ) {
            let filtered_avail = availabilityObj.availability.filter(
                (item) => item.locationId === locId
            );

            let filter_location = locations.filter((item) => item.id === locId);

            if (filtered_avail.length <= 0 || filter_location.length <= 0) {
                setAvailabilityError(true);
            }

            const userTz = filteredProvider.timezone;

            if (masterTimezone !== filteredProvider.timezone) {
                blockedObj = blockedObj.map((item) => ({
                    ...item,
                    startDate: timezoneConversionUserToLoc(
                        item.startDate,
                        filteredProvider.timezone,
                        masterTimezone
                    ),
                    endDate: timezoneConversionUserToLoc(
                        item.endDate,
                        filteredProvider.timezone,
                        masterTimezone
                    )
                }));

                console.log(
                    "availorblocked converter to master timezone",
                    blockedObj
                );
            }

            if (
                filter_location.length > 0 &&
                masterTimezone !== filter_location[0].timezone
            ) {
                const locTz =
                    locId && locId === "0"
                        ? userTz
                        : filter_location[0].timezone;

                filtered_avail = filtered_avail.map((item) => ({
                    ...item,
                    startDate: timezoneConversionUserToLoc(
                        item.startDate,
                        locTz,
                        masterTimezone
                    ),
                    endDate: timezoneConversionUserToLoc(
                        item.endDate,
                        locTz,
                        masterTimezone
                    )
                }));

                bookingsObj = bookingsObj.map((item) => ({
                    ...item,
                    startDate:
                        item.timezone !== masterTimezone
                            ? timezoneConversionUserToLoc(
                                  item.startDate,
                                  item.timezone,
                                  masterTimezone
                              )
                            : item.startDate,
                    endDate:
                        item.timezone !== masterTimezone
                            ? timezoneConversionUserToLoc(
                                  item.endDate,
                                  item.timezone,
                                  masterTimezone
                              )
                            : item.endDate
                }));

                console.log(
                    "availorblocked converter to master timezone",
                    filtered_avail,
                    bookingsObj
                );
            }

            let sDate_tz = sDate;

            let isAvailable = false;

            for (const item of filtered_avail) {
                const rule = RRule.fromString(item.rRule);
                if (
                    checkForAvailabilityAndBlocked(
                        rule,
                        sDate_tz,
                        item.startDate,
                        item.endDate
                    )
                ) {
                    isAvailable = true;
                    console.log(" availOrBlocked IsAvailable", isAvailable);
                    // dayAvailable = true;

                    break;
                }
            }

            if (!isAvailable) {
                console.log(
                    "availOrBlocked is not available after availability check",
                    isAvailable
                );
                setAvailabilityError(true);
                return;
            }

            //now checking if there is a block time exists
            //if there is no blocks, means length of array is zero, then check for bookings.
            let isBlocked = false;

            let isBooked = false;

            if (isAvailable && blockedObj && blockedObj.length > 0) {
                for (const item of blockedObj) {
                    if (item.servicetype.name === "Pending Booking") {
                        if (
                            checkForPending(
                                sDate_tz,
                                item.startDate,
                                item.endDate
                            )
                        ) {
                            isBlocked = true;
                            console.log(
                                "availOrBlocked isAvailable and isPending",
                                isBlocked
                            );
                            // dayAvailable = true;
                            break;
                        }
                    }
                    if (item.servicetype.name === "Blocked") {
                        const rule = RRule.fromString(item.rRule);
                        if (
                            checkForAvailabilityAndBlocked(
                                rule,
                                sDate_tz,
                                item.startDate,
                                item.endDate
                            )
                        ) {
                            isBlocked = true;

                            //dayAvailable = false;
                            break;
                        }
                    }
                }

                if (isBlocked) {
                    console.log(
                        "availOrBlocked isAvailable and isBlocked",
                        isBlocked
                    );
                    setAvailabilityError(true);
                    return;
                }

                if (!isBlocked && bookingsObj && bookingsObj.length > 0) {
                    //if spot is available and not blocked, now check if there is any booking
                    console.log("availOrBlocked isAvailable and not blocked");
                    for (const item of bookingsObj) {
                        if (
                            checkForBooking(
                                sDate_tz,
                                item.startDate,
                                item.endDate
                            )
                        ) {
                            isBooked = true;
                            console.log(
                                "availOrBlocked isAvailable and not Blocked and isBooked",
                                isBooked
                            );
                            break;
                        }
                    }

                    if (isBooked) {
                        console.log(
                            "availOrBlocked isAvailable and not Blocked and isBooked if",
                            isBooked
                        );
                        setAvailabilityError(true);
                        return;
                    }

                    if (!isBooked) {
                        console.log(
                            "availOrBlocked isAvailable and not Blocked and not Booked"
                        );
                    }
                }
            } else {
                //this else is if there is no block
                console.log("availOrBlocked isAvailable but no blocked times");

                if (bookingsObj && bookingsObj.length > 0) {
                    //if spot is available and not blocked, now check if there is any booking
                    for (const item of bookingsObj) {
                        if (
                            checkForBooking(
                                sDate_tz,
                                item.startDate,
                                item.endDate
                            )
                        ) {
                            isBooked = true;
                            console.log(
                                "availOrBlocked isAvailable and no Block spots and isBooked",
                                isBooked
                            );
                            break;
                        }
                    }

                    if (isBooked) {
                        console.log(
                            "availOrBlocked isAvailable and no Block spots and isBooked if",
                            isBooked
                        );
                        setAvailabilityError(true);
                        return;
                    }
                }
            }
        } else {
            setAvailabilityError(true);
        }
    };

    function roundToNearestHalfHour(currentDate) {
        const adjustedDate = new Date(currentDate);

        const minutes = adjustedDate.getMinutes();

        const minutesToNextHalfHour = 30 - (minutes % 30);

        adjustedDate.setMinutes(minutes + minutesToNextHalfHour, 0);

        return adjustedDate;
    }

    const setTravelzoneTimezone = async (id, address) => {
        if (
            id === "0" &&
            address &&
            address.coords &&
            address.coords.lat &&
            address.coords.lng
        ) {
            let userTz = await getTimezoneIdByGeoLoc({
                lat: address.coords.lat,
                lng: address.coords.lng
            });
            return userTz;
        }
    };

    const resetAvailabilityError = () => {
        setAvailabilityError(false);
    };
    const themePalette = useTheme().palette;
    const classes = usePromotionsStyles();

    return (
        <>
            {!fullPageLoad ? (
                <>
                    <ThemeProvider
                        theme={SchedulerTheme({ palette: themePalette })}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <FormHeading title={"Schedule"} classes={classes} />
                            {userHasAdminRole() && (
                                <Button
                                    variant="contained"
                                    onClick={
                                        handleAdminAdhocBookingFlowDialogOpen
                                    }
                                    sx={{
                                        display: "none", // Hide the button by default
                                        paddingRight: "18px",
                                        paddingLeft: "16px",
                                        maxHeight: "36px",
                                        gap: "4px",
                                        minWidth: "150px",
                                        /* Media query to show the button for screens narrower than 1449px */
                                        "@media screen and (max-width: 1449px)":
                                            {
                                                display: "flex", // Display the button */
                                                position: "relative",
                                                alignSelf: "flex-end"
                                            }
                                    }}
                                >
                                    <AddIcon
                                        sx={{
                                            color: "white",
                                            width: "18px",
                                            height: "18px"
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: "2px",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            color: "white"
                                        }}
                                    >
                                        Add Booking
                                    </Typography>
                                </Button>
                            )}
                        </Grid>
                    </ThemeProvider>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                        open={msgOpen}
                        autoHideDuration={3000}
                        onClose={() => setMsgOpen(false)}
                        ContentProps={{
                            "aria-describedby": "message-id"
                        }}
                        message={<span id="message-id">{snackMsg}</span>}
                    />
                    <Box display={"flex"} justifyContent={"center"}>
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1
                            }}
                            open={isLoading || refreshPage}
                        >
                            <CircularProgress />
                        </Backdrop>
                    </Box>
                    <SmallSizedModal
                        open={showWarningModal}
                        onClose={closeWarningModal}
                        headingIcon={WarningTriangleIcon}
                        headingText={"No availability"}
                    >
                        <Box>
                            <Typography variant="body2">
                                The selected provider does not have availability
                                at this time. As an admin, you can override
                                this. Do you wish to continue?
                            </Typography>
                        </Box>
                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "1.25rem"
                            }}
                        >
                            <Button
                                sx={{
                                    height: "2.25rem",
                                    boxShadow: "none",
                                    minWidth: "5.625rem",
                                    alignItems: "center",
                                    borderColor: "primary.main",
                                    lineHeight: "inherit",
                                    "&:hover": {
                                        boxShadow: "none"
                                    }
                                }}
                                onClick={closeWarningModal}
                                variant="outlined"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: "0.875rem",
                                        fontWeight: 500,
                                        fontFamily: "Roboto",
                                        paddingTop: "0.1rem"
                                    }}
                                >
                                    Cancel
                                </div>
                            </Button>
                            <Button
                                sx={{
                                    height: "2.25rem",
                                    boxShadow: "none",
                                    minWidth: "5.625rem",
                                    alignItems: "center",
                                    lineHeight: "inherit",
                                    "&:hover": {
                                        boxShadow: "none"
                                    }
                                }}
                                variant="contained"
                                onClick={continueWarningHandler}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: "0.875rem",
                                        fontWeight: 500,
                                        fontFamily: "Roboto",
                                        paddingTop: "0.1rem"
                                    }}
                                >
                                    Continue
                                </div>
                            </Button>
                        </Box>
                    </SmallSizedModal>
                    <AdhocBookingFlow
                        show={openAddBookingDialog}
                        showSnackMessage={showSnackMsg}
                        handleClose={handleAdhocBookingFlowDialogClose}
                        handleMessageClose={handleMsgClose}
                        clickedCellProps={clickedCellProps}
                        provider={provider}
                        company={props.company}
                        setBookingCompleted={setBookingCompleted}
                        changeDateTimeCellProps={changeDateTimeCellProps}
                        providersList={props.providersList}
                        selectingProviderHandler={availOrblocked}
                        selectedProvider={
                            props?.selectedProviders
                                ? props.selectedProviders
                                : null
                        }
                        availabilityError={availabilityError}
                        resetAvailabilityError={resetAvailabilityError}
                        selectedLoc={
                            selectedLoc
                                ? selectedLoc.maxtraveltype
                                    ? {
                                          id: "0",
                                          addressoneline: "Client address",
                                          locationname: "Client Location"
                                      }
                                    : selectedLoc
                                : selectedLoc
                        }
                        setTravelzoneTimezone={setTravelzoneTimezone}
                        masterTimezone={masterTimezone}
                        roundToNearestHalfHour={roundToNearestHalfHour}
                    />

                    {props.fromPath === "ProviderSchedule" && (
                        <ThemeProvider theme={DialogTheme(theme)}>
                            <BlockedTimeModal
                                open={openBlockedTimeDialog}
                                onClose={() => {
                                    setBlockedTimeDialog(false);
                                }}
                                startDateInitial={clickedCellProps?.startDate}
                                endDateInitial={clickedCellProps?.startDate}
                            />
                        </ThemeProvider>
                    )}
                    <ThemeProvider
                        theme={SchedulerTheme({ palette: themePalette })}
                    >
                        <Scheduler
                            ref={schedulerRef}
                            data={filteredbookedData}
                            bookingCompleted={bookingCompleted}
                            firstDayOfWeek={1}
                        >
                            <ViewState
                                defaultCurrentDate={currentDate}
                                defaultCurrentViewName="Week"
                                currentViewName={
                                    localStorage?.displayCalendarAs &&
                                    props?.selectedProviders
                                        ? localStorage.displayCalendarAs
                                        : "Week"
                                }
                                onCurrentViewNameChange={changeViewName}
                                onCurrentDateChange={currentDateChanged}
                            />
                            <Toolbar
                                flexibleSpaceComponent={() => (
                                    <CustomFlexibleSpace
                                        theme={SchedulerTheme({
                                            palette: themePalette
                                        })}
                                        setSelectedLocationId={
                                            LocationIdChangeHandler
                                        }
                                        setCellDuration={
                                            cellDurationChangeHandler
                                        }
                                        cellDuration={cellDuration}
                                        locations={locations}
                                        locationId={locationId}
                                        openLoading={openLoading}
                                        closeLoading={closeLoading}
                                        showLoc={showLocDrop}
                                        showBookBtn={showBookBtn}
                                        displayListProviders={
                                            displayListProviders
                                        }
                                    />
                                )}
                            />
                            <WeekView
                                provider={provider}
                                cellDuration={cellDuration}
                                displayName="Week"
                                startDayHour={0}
                                endDayHour={24}
                                timeTableCellComponent={timeTableCellComponent}
                            />
                            <DayView
                                provider={provider}
                                cellDuration={cellDuration}
                                startDayHour={0}
                                endDayHour={24}
                                timeTableCellComponent={timeTableCellComponent}
                            />
                            <DateNavigator
                                onNavigate={dateNavigated}
                                openButtonComponent={CustomOpenButton}
                            />
                            <TodayButton />
                            <ViewSwitcher
                                switcherComponent={CustomSwitcher}
                                openLoading={openLoading}
                            />
                            <Appointments
                                appointmentComponent={(props) => (
                                    <Appointment
                                        {...props}
                                        changeDateTimeCellProps={
                                            changeDateTimeCellProps
                                        }
                                        blockModalModeHandler={
                                            blockModalModeHandler
                                        }
                                        openBlockModalHandler={
                                            openBlockModalHandler
                                        }
                                        blockModalId={blockModalId}
                                    />
                                )}
                                appointmentContentComponent={AppointmentContent}
                            ></Appointments>
                            {
                                <AppointmentTooltip
                                    headerComponent={AppointmentTooltipHeader}
                                    contentComponent={AppointmentTooltipContent}
                                    showCloseButton
                                ></AppointmentTooltip>
                            }
                        </Scheduler>
                    </ThemeProvider>
                    <AdhocBookingFlow
                        show={openAddBookingDialog}
                        showSnackMessage={showSnackMsg}
                        handleClose={handleAdhocBookingFlowDialogClose}
                        handleMessageClose={handleMsgClose}
                        clickedCellProps={clickedCellProps}
                        provider={provider}
                        company={props.company}
                        setBookingCompleted={setBookingCompleted}
                        changeDateTimeCellProps={changeDateTimeCellProps}
                        providersList={props.providersList}
                        selectingProviderHandler={availOrblocked}
                        selectedProvider={
                            props?.selectedProviders
                                ? props.selectedProviders
                                : null
                        }
                        availabilityError={availabilityError}
                        resetAvailabilityError={resetAvailabilityError}
                        selectedLoc={
                            selectedLoc
                                ? selectedLoc.maxtraveltype
                                    ? {
                                          id: "0",
                                          addressoneline: "Client address",
                                          locationname: "Client Location"
                                      }
                                    : selectedLoc
                                : selectedLoc
                        }
                        setTravelzoneTimezone={setTravelzoneTimezone}
                        masterTimezone={masterTimezone}
                        roundToNearestHalfHour={roundToNearestHalfHour}
                    />
                </>
            ) : (
                <>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={fullPageLoad}
                    >
                        <CircularProgress />
                    </Backdrop>
                    <ThemeProvider theme={SchedulerTheme}>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <FormHeading title={"Schedule"} classes={classes} />
                            {userHasAdminRole() && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        display: "none", // Hide the button by default
                                        paddingRight: "18px",
                                        paddingLeft: "16px",
                                        maxHeight: "36px",
                                        gap: "4px",
                                        minWidth: "150px",
                                        /* Media query to show the button for screens narrower than 1449px */
                                        "@media screen and (max-width: 1449px)":
                                            {
                                                display: "flex", // Display the button */
                                                position: "relative",
                                                alignSelf: "flex-end"
                                            }
                                    }}
                                >
                                    <AddIcon
                                        sx={{
                                            color: "white",
                                            width: "18px",
                                            height: "18px"
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: "2px",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            color: "white"
                                        }}
                                    >
                                        Add Booking
                                    </Typography>
                                </Button>
                            )}
                        </Grid>
                    </ThemeProvider>
                    {displayEmptyScheduler()}
                </>
            )}
        </>
    );
};
