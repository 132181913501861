import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import StripeChargeCard from "./StripeChargeCard";

const StripeChargeCardContainer = (props) => {
    const [stripeObject, setStripeObject] = useState(null);

    useEffect(() => {
        const fetchStripeObject = async () => {
            if (props.company?.stripeConnectEnabled) {
                //For Connected Account
                const res = await loadStripe(
                    process.env.REACT_APP_STRIPE_MB_PUB_KEY,
                    {
                        stripeAccount: props.company.stripeAccount
                    }
                );
                setStripeObject(res);
            } else {
                //For Not Connected Account
                const res = await loadStripe(
                    props.company.publishableStripeKey
                );
                setStripeObject(res);
            }
        };
        fetchStripeObject();
    }, []);

    // If no Stripe object, do not render the Stripe Element.
    if (!stripeObject) {
        return <p>Loading...</p>;
    }
    return (
        <Elements stripe={stripeObject}>
            <div>
                <StripeChargeCard
                    bookingState={props.bookingState}
                    numOfAppts={props.numOfAppts}
                    company={props.company}
                    companyId={props.companyId}
                    setBookingCompleted={props.setBookingCompleted}
                    disableElementsHandler={props.disableElementsHandler}
                />
            </div>
        </Elements>
    );
};

export default StripeChargeCardContainer;
