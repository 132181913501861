import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import {
    Button,
    Grid,
    TableRow,
    TablePagination,
    IconButton,
    Table,
    TableFooter,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    Typography,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
    Rating
} from "@mui/material";
import { DataTableWrapper } from "../utils/CommonComonents/DataTableWrapper";
import {
    AdminBookingListColumns,
    ClientBookingListColumns,
    ProviderBookingListColumnsMobile,
    ProviderBookingListColumnsDesktop,
    ProviderBookingListColumnsMobilePending,
    ProviderBookingListColumnsMobileFuture,
    ProviderBookingListColumnsDesktopFuture
} from "../utils/Constants";
import { StoreContext } from "../context/StoreContext";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useStyles } from "./PromotionsTable";
import { useWindowSize } from "react-use";
import { userHasClientRole } from "../user/UserCommon";
import { NonpendingBRListColumns } from "../utils/Constants";

import CurrencyFormat from "react-currency-format";
import { getCurrencySymbol, getCurrencySymbolFromParam } from "../utils";
import BookingsFutureTabTable from "./BookingsFutureTabTable";
import BookingsPastTabTable from "./BookingPastTabTable";
import BookingDeclineExpiredTabTable from "./BookingDeclineExpiredTabTable";

const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

const BookingTableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className="MuiTablePagination-actions">
            {/*    <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton> */}
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            {/* <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton> */}
        </div>
    );
}

export const BookingListTable = (props) => {
    const { actions, state } = useContext(StoreContext);
    const { width, height } = useWindowSize();
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { rows, selectedRow } = props;
    const [headers, setHeaders] = React.useState([]);

    //Virtual Link States
    const [openVMDialog, setOpenVMDialog] = useState(false);
    const [serviceDesc, setServiceDesc] = useState("");
    const [serviceProv, setServiceProv] = useState("");
    const [startDate, setStartDate] = useState("");
    const [serviceLoc, setServiceLoc] = useState("");

    const columns = (role) => {
        switch (role) {
            case "CLIENT":
                return checkRatingsColumn(ClientBookingListColumns);
            case "PROVIDER":
                return providerTabColumns();
            case "MARKETBOX_ADMIN":
                return AdminBookingListColumns;
            case "COMPANY_ADMIN":
                return AdminBookingListColumns;
            case "COMPANY_ADMIN_PROVIDER":
                return AdminBookingListColumns;
        }
    };

    React.useEffect(() => {
        if (page * rowsPerPage >= rows.length) {
            setPage(0);
        }
    }, [rows.length]);

    /* React.useEffect(() => {
        if (props.tab === "nonpending") {
            setHeaders(NonpendingBRListColumns);
        } else {
            setHeaders(columns(props.role));
        }

        console.log("*Headers*");
        console.log(headers);
    }, [headers]); */

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const _handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    };
    const _handleRowClick = (event, id) => {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    };

    const _handleEditClick = (id) => {
        actions.setMode("Edit");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleViewClick = (id) => {
        actions.setMode("View");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleTimeClick = (id, { locationName, serviceName }) => {
        console.log("locationName", id);
        actions.setPage("TimeBlockForm");
        actions.setId(id);
        actions.setProvider({
            ...state.provider,
            locationName,
            service: serviceName,
            scheduleId: id
        });
    };

    const providerTabColumns = () => {
        if (width <= 600) {
            if (props.tab === "pending") {
                return ProviderBookingListColumnsMobilePending;
            } else if (props.tab === "future") {
                return ProviderBookingListColumnsMobileFuture;
            } else {
                return ProviderBookingListColumnsMobile;
            }
        } else {
            if (props.tab === "future") {
                return ProviderBookingListColumnsDesktopFuture;
            } else {
                return ProviderBookingListColumnsDesktop;
            }
        }
    };

    function checkRatingsColumn(cols) {
        if (showRatingStars()) return cols;
        else {
            return cols.filter((col) => col.name !== "ratingstars");
        }
    }
    const showRatingStars = () => {
        if (userHasClientRole() && props.hideRatingsFromClients) {
            return false;
        }
        return true;
    };

    const checkingPhoneNumber = (row) => {
        let phoneNumber;
        if (
            !row.clientMobilePhone.includes("N/A") &&
            row.clientMobilePhone.length >= 4
        ) {
            phoneNumber = row.clientMobilePhone;
        } else {
            if (
                !row.clientWorkPhone.includes("N/A") &&
                row.clientWorkPhone.length >= 4
            ) {
                phoneNumber = row.clientWorkPhone;
            } else {
                if (
                    !row.clientHomePhone.includes("N/A") &&
                    row.clientHomePhone.length >= 4
                ) {
                    phoneNumber = row.clientHomePhone;
                } else {
                    phoneNumber = "";
                }
            }
        }
        return phoneNumber;
    };
    return (
        <>
            {/* <div className={classes.root}>
                <div className={classes.tableWrapper}>
                    <DataTableWrapper
                        classes={classes}
                        columns={
                            props.tab === "nonpending"
                                ? NonpendingBRListColumns
                                : columns(props.role)
                        }
                        rows={rows}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        selected={selected}
                        selectAll={selectAll}
                        handleEditClick={_handleEditClick}
                        handleRowClick={_handleRowClick}
                        handleSelectAllClick={_handleSelectAllClick}
                        handleViewClick={_handleViewClick}
                        handleTimeClick={_handleTimeClick}
                        handleDecline={props.handleDecline}
                        showTime={false}
                        showAction={false}
                        showActive={false}
                        showSelect={false}
                        showHelpIcon={false}
                        showRatingStars={showRatingStars()}
                        showButtons={true}
                        tab={props.tab}
                        role={props.role}
                        handleCancel={props.handleCancel}
                        handleNoShow={props.handleNoShow}
                        handleArrived={props.handleArrived}
                        handleCompleted={props.handleCompleted}
                        handleAddCharge={props.handleAddCharge}
                        handleOverride={props.handleOverride}
                        handleReqDetails={props.handleReqDetails}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap"
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleLoadMore}
                        disabled={props.endOfList}
                    >
                        {props.endOfList
                            ? "No More Rows To Load"
                            : "Load More Rows"}
                    </Button>

                    <TablePagination
                        sx={{
                            border: "none",
                            marginLeft: "auto",
                            "& .MuiToolbar-root": {
                                alignItems: "center",
                                "& .MuiInputBase-root": {
                                    fontWeight: 700
                                },
                                "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                    {
                                        color: "rgba(0,0,0,0.5)",
                                        marginBottom: 0
                                    },
                                "& .MuiTablePagination-actions": {
                                    display: "flex",
                                    gap: "0.5rem",
                                    "& button": {
                                        border: "1px solid rgba(0,0,0,0.2)",
                                        borderRadius: "4px"
                                    },

                                    "& button:hover": {
                                        backgroundColor: "primary.light"
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                "aria-label": "Rows per page"
                            },
                            native: true
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </div> */}

            {/* Bookings Declined/Expired Tab Table */}
            <div style={{ overflow: "auto" }}>
                {/* Future Tab Table */}
                {props.tab === "nonpending" && (
                    <>
                        {/* <h4>Declined/Expired Tab Table</h4> */}
                        {
                            <BookingDeclineExpiredTabTable
                                role={props.role}
                                rows={rows}
                                handleLoadMore={props.handleLoadMore}
                                endOfList={props.endOfList}
                                handleReqDetails={props.handleReqDetails}
                                showClientDialog={props.showClientDialog}
                            />
                        }
                    </>
                )}
            </div>

            {/* Bookings Past Tab Table */}
            <div style={{ overflow: "auto" }}>
                {/* Past Tab Table */}
                {props.tab === "past" && (
                    <>
                        {/* <h4>Past Tab Table</h4> */}
                        <BookingsPastTabTable
                            role={props.role}
                            rows={rows}
                            checkingPhoneNumber={checkingPhoneNumber}
                            showRatingStars={showRatingStars}
                            handleLoadMore={props.handleLoadMore}
                            endOfList={props.endOfList}
                            handleArrived={props.handleArrived}
                            handleAddCharge={props.handleAddCharge}
                            handleCompleted={props.handleCompleted}
                            handleNoShow={props.handleNoShow}
                            handleOverride={props.handleOverride}
                            refreshTable={props.refreshTable}
                            showClientDialog={props.showClientDialog}
                            showNoteDialog={props.showNoteDialog}
                        />
                    </>
                )}
            </div>

            {/* Bookings Future Tab Table */}
            <div style={{ overflow: "auto" }}>
                {/* Future Tab Table */}
                {props.tab === "future" && (
                    <>
                        {/* <h4>Future Tab Table</h4> */}
                        <BookingsFutureTabTable
                            role={props.role}
                            rows={rows}
                            checkingPhoneNumber={checkingPhoneNumber}
                            showRatingStars={showRatingStars}
                            handleLoadMore={props.handleLoadMore}
                            endOfList={props.endOfList}
                            handleArrived={props.handleArrived}
                            handleAddCharge={props.handleAddCharge}
                            handleCompleted={props.handleCompleted}
                            handleCancel={props.handleCancel}
                            handleOverride={props.handleOverride}
                            refreshTable={props.refreshTable}
                            showClientDialog={props.showClientDialog}
                            showNoteDialog={props.showNoteDialog}
                        />
                    </>
                )}
            </div>
        </>
    );
};
