import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Switch,
    Stack,
    Card,
    CardActions,
    CardContent
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { enUS } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DateFrequencySelector from "../../components/DateFrequencySelector";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    blockedTimeFormDataAtom,
    recordIdAtom,
    scheduleInfoAtom
} from "../../atoms/blockedTime";
import { userPageSnackbarAtom } from "../../atoms/usersTable";
import { useAtom } from "jotai";
import {
    mbxUserAtom,
    conformationDialogOpenAtom,
    conformationDialogContentAtom,
    confirmationFunctionAtom
} from "../../atoms/atoms";
import { selectedProviderIdAtom } from "../../atoms/usersTable";
import {
    postApiWithStatusCode,
    putApiWithStatusCode,
    delApiWithStatus
} from "../../modules/AmplifyServices";
import moment from "moment";
import { checkTimeBlockOverlap } from "../../utils/scheduleUtils";

const BlockedTimeModal = ({
    open,
    onClose,
    buId,
    startDateInitial,
    endDateInitial
}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle
                variant="h3"
                sx={{
                    padding: "36px 36px 0px 36px"
                }}
            >
                Add blocked time
            </DialogTitle>
            {open && (
                <BlockedTimeForm
                    handleCancel={onClose}
                    startDateInitial={startDateInitial}
                    endDateInitial={endDateInitial}
                />
            )}
        </Dialog>
    );
};

export default BlockedTimeModal;

const BlockedTimeForm = ({
    handleCancel,
    handleSave,
    startDateInitial,
    endDateInitial
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recordId] = useAtom(recordIdAtom);
    const [scheduleInfoConstructor] = useAtom(scheduleInfoAtom);
    const [formData] = useAtom(blockedTimeFormDataAtom);
    const [, setConformationDialogContent] = useAtom(
        conformationDialogContentAtom
    );
    const [, setConfirmationFunction] = useAtom(confirmationFunctionAtom);
    const [, setConformationDialogOpen] = useAtom(conformationDialogOpenAtom);

    const [, setUserPageSnackbar] = useAtom(userPageSnackbarAtom);
    const timeRangeConstructor = () => {
        const startTime = new Date().setHours(9, 0, 0, 0);
        const endTime = new Date().setHours(17, 0, 0, 0);
        return {
            id: recordId,
            scheduleinfo: scheduleInfoConstructor(),
            startTime,
            endTime
        };
    };
    const {
        buId,
        timeBlocks = [timeRangeConstructor()],
        notes = "",
        weekDays = startDateInitial
            ? moment(startDateInitial).subtract(1, "days").format("d")
            : moment().subtract(1, "days").format("d"),
        weeksToRepeat = 1,
        startDate = startDateInitial || new Date(),
        endDate = endDateInitial || new Date().setDate(new Date().getDate() + 1)
    } = formData || {};
    const [{ companyId }] = useAtom(mbxUserAtom);
    const [providerId] = useAtom(selectedProviderIdAtom);

    const form = useForm({
        defaultValues: {
            days: {
                weekDays: weekDays
                    .split("")
                    .map((stringNumber) => parseInt(stringNumber)),
                weeksToRepeat
            },
            startDate,
            endDate,
            timeBlocks,
            notes,
            blockAllDay: false
        }
    });
    const { handleSubmit, setValue, getValues, control, formState } = form;
    const { isDirty } = formState;
    const {
        fields: timeBlockFields,
        append,
        remove
    } = useFieldArray({
        control,
        name: "timeBlocks"
    });

    const onSubmit = async ({
        days: { weekDays, weeksToRepeat },
        startDate,
        endDate,
        timeBlocks,
        ...formData
    }) => {
        if (checkTimeBlockOverlap(timeBlocks)) {
            setUserPageSnackbar({
                open: true,
                message: `The selected times overlap. Please choose non-overlapping time ranges`,
                severity: "error"
            });
            return;
        }
        setIsSubmitting(true);
        try {
            //append additional data for API use
            const postData = {
                ...formData,
                weeksToRepeat,
                weekDays: weekDays.join(""),
                companyId,
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
                timeBlocks: timeBlocks.map(({ startTime, endTime }) => ({
                    startTime: moment(startTime).format("HH:mm"),
                    endTime: moment(endTime).format("HH:mm")
                })),
                providerId
            };
            if (!buId) {
                //create route
                const { statusCode, body: newRecord } =
                    await postApiWithStatusCode(
                        "userManagementService",
                        "/user/blocked-time",
                        {
                            body: postData
                        }
                    );
                if (statusCode >= 200 && statusCode < 300) {
                    handleCancel();
                    setUserPageSnackbar({
                        open: true,
                        message: "Blocked Time has been successfully created",
                        severity: "success"
                    });
                } else {
                    setUserPageSnackbar({
                        open: true,
                        message:
                            "Unexpected error: The blocked time was not created",
                        severity: "success"
                    });
                    throw new Error(
                        `Failed to create new user profile. Error: ${newRecord}`
                    );
                }
            } else {
                //edit route
                const { statusCode, body: updatedRecord } =
                    await putApiWithStatusCode(
                        "userManagementService",
                        `/user/blocked-time/BU|${buId}`,
                        {
                            body: postData
                        }
                    );

                if (updatedRecord && statusCode >= 200 && statusCode < 300) {
                    // reset();
                    handleCancel();
                    setUserPageSnackbar({
                        open: true,
                        message: "Blocked Time has been successfully updated",
                        severity: "success"
                    });
                } else {
                    setUserPageSnackbar({
                        open: true,
                        message:
                            "Unexpected error: The blocked time was not updated",
                        severity: "success"
                    });
                    throw new Error(
                        `Failed to update user profile. Error: ${updatedRecord}`
                    );
                }
            }
        } catch (error) {
            console.log("Error submitting form data:", error);
            setIsSubmitting(false);
        }
    };

    const handleDelete = async (successCallBack) => {
        const { statusCode } = await delApiWithStatus(
            "userManagementService",
            `/user/blocked-time/BU|${buId}`,
            { queryParams: { companyId, providerId } }
        );
        if (statusCode === 200) {
            handleCancel();
            typeof successCallBack === "function" && successCallBack();
        } else {
            console.log(statusCode);
        }
    };

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={{
                        ...enUS,
                        options: { ...enUS.options, weekStartsOn: 1 }
                    }}
                >
                    <Grid container spacing={2.25} sx={{ marginTop: "0px" }}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({
                                    field: {
                                        onChange: onChangeFormHook,
                                        value: startDate
                                    }
                                }) => (
                                    <DatePicker
                                        autoFocus
                                        id="start-date"
                                        name="startDate"
                                        size="small"
                                        label="Start date"
                                        value={startDate}
                                        fullWidth
                                        inputFormat="MMMM d, yyyy"
                                        onChange={(newValue) => {
                                            onChangeFormHook(newValue);
                                            newValue > getValues("endDate") &&
                                                setValue("endDate", newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                sx={{
                                                    "& fieldset": {
                                                        "& legend": {
                                                            width: "54px"
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({
                                    field: {
                                        onChange: onChangeFormHook,
                                        value: endDate
                                    }
                                }) => (
                                    <DatePicker
                                        id="end-date"
                                        name="endDate"
                                        size="small"
                                        label="End date"
                                        value={endDate}
                                        minDate={getValues("startDate")}
                                        fullWidth
                                        inputFormat="MMMM d, yyyy"
                                        onChange={onChangeFormHook}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                sx={{
                                                    "& fieldset": {
                                                        "& legend": {
                                                            width: "50px"
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                alignItems: "center",
                                gap: "9px",
                                justifyContent: "space-between"
                            }}
                        >
                            <Controller
                                name="days"
                                control={control}
                                render={({
                                    field: {
                                        onChange,
                                        value: { weekDays, weeksToRepeat }
                                    }
                                }) => (
                                    <DateFrequencySelector
                                        handleNumberSelectorChange={(val) =>
                                            onChange({
                                                weeksToRepeat: val,
                                                weekDays
                                            })
                                        }
                                        handleDayButtonClick={(val) =>
                                            onChange({
                                                weeksToRepeat,
                                                weekDays: weekDays.includes(val)
                                                    ? weekDays.filter(
                                                          (element) =>
                                                              element !== val
                                                      )
                                                    : [...weekDays, val]
                                            })
                                        }
                                        selectedDays={weekDays}
                                        selectedNumber={weeksToRepeat}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="blockAllDay"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange: onChangeFormHook,
                                                value: blockAllDay
                                            }
                                        }) => (
                                            <Switch
                                                name="all-day"
                                                value={blockAllDay}
                                                onChange={({
                                                    target: { checked }
                                                }) => {
                                                    onChangeFormHook(checked);
                                                    setValue("timeBlocks", [
                                                        {
                                                            startTime:
                                                                new Date().setHours(
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                ),
                                                            endTime:
                                                                new Date().setHours(
                                                                    23,
                                                                    55,
                                                                    0,
                                                                    0
                                                                )
                                                        }
                                                    ]);
                                                }}
                                            />
                                        )}
                                    />
                                }
                                label="All day"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Card variant="outlined" sx={{ width: "100%" }}>
                                <CardContent>
                                    {timeBlockFields.map((item, index) => (
                                        <Stack
                                            key={item.scheduleinfo}
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Controller
                                                name={`timeBlocks.${index}.startTime`}
                                                control={control}
                                                render={({ field }) => {
                                                    const {
                                                        onChange:
                                                            onChangeFormHook,
                                                        value: startTime
                                                    } = field;
                                                    return (
                                                        <TimePicker
                                                            id={`start-time-${index}`}
                                                            name={`timeBlocks.${index}.startTime`}
                                                            size="small"
                                                            label="Start time"
                                                            value={startTime}
                                                            minutesStep={5}
                                                            fullWidth
                                                            onChange={
                                                                onChangeFormHook
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    sx={{
                                                                        "& fieldset":
                                                                            {
                                                                                "& legend":
                                                                                    {
                                                                                        width: "55px"
                                                                                    }
                                                                            }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    );
                                                }}
                                            />

                                            <Controller
                                                name={`timeBlocks.${index}.endTime`}
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange:
                                                            onChangeFormHook,
                                                        value: endTime
                                                    }
                                                }) => (
                                                    <TimePicker
                                                        id="end-time"
                                                        name={`timeBlocks.${index}.endTime`}
                                                        size="small"
                                                        label="End time"
                                                        value={endTime}
                                                        minutesStep={5}
                                                        fullWidth
                                                        onChange={
                                                            onChangeFormHook
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                sx={{
                                                                    "& fieldset":
                                                                        {
                                                                            "& legend":
                                                                                {
                                                                                    width: "50px"
                                                                                }
                                                                        }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                            {index > 0 && (
                                                <DeleteIcon
                                                    type="button"
                                                    onClick={() => {
                                                        timeBlockFields.length >
                                                            1 && remove(index);
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    {!getValues("blockAllDay") && (
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            onClick={() => {
                                                const startTime =
                                                    new Date().setHours(
                                                        9,
                                                        0,
                                                        0,
                                                        0
                                                    );
                                                const endTime =
                                                    new Date().setHours(
                                                        17,
                                                        0,
                                                        0,
                                                        0
                                                    );
                                                return append({
                                                    id: recordId,
                                                    scheduleinfo:
                                                        scheduleInfoConstructor(),
                                                    startTime,
                                                    endTime
                                                });
                                            }}
                                        >
                                            + Add Times
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="notes"
                                control={control}
                                render={({
                                    field: {
                                        onChange: onChangeFormHook,
                                        value: notes
                                    }
                                }) => (
                                    <TextField
                                        id="notes"
                                        name="notes"
                                        label="Notes"
                                        size="small"
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        rows={3}
                                        sx={{
                                            "& fieldset": {
                                                "& legend": {
                                                    width: "36px"
                                                }
                                            }
                                        }}
                                        value={notes}
                                        onChange={onChangeFormHook}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions sx={{ display: "flex", gap: "10px" }}>
                {buId && (
                    <Button
                        onClick={() => {
                            setConfirmationFunction(() =>
                                handleDelete(() =>
                                    setConformationDialogOpen(false)
                                )
                            );
                            setConformationDialogContent(
                                "Are you sure you want to delete this Blocked Time"
                            );
                            setConformationDialogOpen(true);
                        }}
                        variant="text"
                        color="error"
                    >
                        Delete blocked time
                    </Button>
                )}
                <Button onClick={handleCancel} variant="outlined">
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isDirty}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </form>
    );
};
