import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Slider,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import React from "react";
import uploadImage from "../../images/BFSettings-ImageUpload.svg";
import InfoIcon from "@mui/icons-material/Info";
import bookingFlowLogo from "../../images/bookingFlow-logo.jpeg";
import bookingFlowButton from "../../images/bookingFlow-button.jpeg";
import bookingFlowHeadingOne from "../../images/bookingFlow-headingone.jpeg";
import bookingFlowHeadingTwo from "../../images/bookingFlow-headingtwo.jpeg";
import bookingFlowHeadingThree from "../../images/bookingFlow-headingthree.jpeg";
import ImageTooltip from "../../utils/UI/ImageTooltip";

const fontPixels = () => {
    // 0 to 100 step 10
    const start = 2;
    const end = 70;
    const step = 2;
    const arrayLength = Math.floor((end - start) / step) + 1;
    const range = [...Array(arrayLength).keys()].map((x) => x * step + start);
    return range;
};
const fontSizes = fontPixels();

function BrandingTab({
    styles,
    brandingStates,
    brandingStatesChangeHandler,
    domainStates,
    domainStatesChangeHandler,
    fontNames,
    fromDashboard
}) {
    const smallerScreen = useMediaQuery("(min-width:500px)");
    return (
        <>
            {/* Logo DIV */}
            <Box sx={styles.container}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={styles.topRow}>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    alignItems: "center"
                                }}
                            >
                                <Typography sx={styles.heading}>
                                    Display company logo
                                </Typography>
                                <Switch
                                    checked={brandingStates.showLogo}
                                    onChange={brandingStatesChangeHandler}
                                    name="showLogo"
                                />

                                <ImageTooltip
                                    content={
                                        <img
                                            src={bookingFlowLogo}
                                            alt="Booking flow logo"
                                            width={400}
                                        />
                                    }
                                >
                                    <InfoIcon
                                        sx={{
                                            cursor: "pointer",

                                            height: "1.5rem",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    />
                                </ImageTooltip>
                            </Box>
                        </Box>
                        {brandingStates.showLogo && (
                            <Box
                                sx={{
                                    ...styles.subContainer,
                                    marginTop: "1rem"
                                }}
                            >
                                <img
                                    width={80}
                                    height={80}
                                    src={
                                        !!brandingStates.logoURL
                                            ? brandingStates.logoURL
                                            : uploadImage
                                    }
                                    alt="Upload"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        let url =
                                            domainStates?.homepageURL?.length >
                                            0
                                                ? `https://${domainStates.homepageURL}`
                                                : "https://www.gomarketbox.com/";
                                        window.open(url, "_blank");
                                    }}
                                />
                                <Box
                                    sx={{
                                        ...styles.container,
                                        gap: "0.5rem",
                                        justifyContent: "center"
                                    }}
                                >
                                    {/* <Typography sx={styles.subHeading}>
                                        Company Logo
                                    </Typography> */}

                                    <Typography sx={styles.subHeadingInfo}>
                                        Image will be shown in the booking flow
                                        for your company
                                        <span>
                                            <Tooltip
                                                title="Accepted formats JPG, GIF, PNG, HEIC or WEBP up to 100KB."
                                                placement="bottom-end"
                                            >
                                                <InfoIcon
                                                    sx={{
                                                        marginLeft: "0.5rem",
                                                        height: "1.5rem"
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        id="logoURL"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={brandingStatesChangeHandler}
                                        name="logoURL"
                                    />
                                    <label htmlFor="logoURL">
                                        <FormControl required>
                                            <Button
                                                sx={{
                                                    padding: 0,
                                                    //backgroundColor: "white",
                                                    ...styles.uploadLinks,

                                                    textTransform: "unset",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "#f4f4f4"
                                                    }
                                                }}
                                                variant="text"
                                                color="primary"
                                                component="span"
                                            >
                                                Upload new image
                                            </Button>
                                        </FormControl>
                                    </label>
                                </Box>
                            </Box>
                        )}
                    </Grid>

                    {brandingStates.showLogo && (
                        <Grid sx={{ marginTop: "0.5rem" }} item xs={12} sm={5}>
                            <Box
                                sx={{
                                    ...styles.container,
                                    gap: "0.5rem",
                                    marginTop: "1rem"
                                }}
                            >
                                <Typography sx={styles.heading}>
                                    Clickable logo URL{" "}
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    sx={styles.textField}
                                    InputProps={{
                                        shrink: false,
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment
                                                sx={{
                                                    color: "rgba(0,0,0,0.47)",
                                                    fontFamily: "Roboto"
                                                }}
                                                position="start"
                                            >
                                                <span
                                                    style={{
                                                        color: "rgba(0,0,0,0.47)",
                                                        fontFamily: "Roboto"
                                                    }}
                                                >
                                                    https://
                                                </span>
                                            </InputAdornment>
                                        )
                                    }}
                                    label="Homepage URL"
                                    name={"homepageURL"}
                                    value={
                                        domainStates?.homepageURL?.length > 0
                                            ? domainStates.homepageURL
                                            : ""
                                    }
                                    onChange={domainStatesChangeHandler}
                                />

                                <Typography
                                    sx={{
                                        color: "rgba(0,0,0,0.6)",
                                        fontSize: "0.75rem",
                                        marginLeft: "0.3rem"
                                    }}
                                >
                                    Make your logo clickable to a webpage of
                                    your choice
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Divider sx={{ width: "100%", margin: "1.5rem 0px" }} />

            {/* Header Starts Here */}
            <Grid container item xs={12}>
                <Box sx={styles.container}>
                    <Box sx={{ ...styles.topRow }}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center"
                            }}
                        >
                            <Typography sx={styles.heading}>
                                Display background image
                            </Typography>
                            <Switch
                                checked={brandingStates.showHeader}
                                onChange={brandingStatesChangeHandler}
                                name={"showHeader"}
                            />
                            <Tooltip
                                title="This is the background image visible at the top of your booking page."
                                placement="right"
                            >
                                <InfoIcon
                                    sx={{
                                        color: "rgb(113 113 113 / 87%)",

                                        height: "1.5rem"
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    </Box>

                    {brandingStates.showHeader && (
                        <>
                            <Box sx={styles.subContainer}>
                                <div
                                    style={{
                                        position: "relative",
                                        display: "inline-block"
                                    }}
                                >
                                    <img
                                        width={!smallerScreen ? 150 : 300}
                                        height={!smallerScreen ? 80 : 175}
                                        src={
                                            !!brandingStates.backgroundURL
                                                ? brandingStates.backgroundURL
                                                : uploadImage
                                        }
                                        alt="Upload"
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor:
                                                brandingStates.backgroundColor,
                                            opacity:
                                                1 -
                                                brandingStates.headerTransparency // Adjust the opacity as desired
                                        }}
                                    />
                                </div>

                                <Box
                                    sx={{
                                        ...styles.container,
                                        gap: "0.5rem"
                                    }}
                                >
                                    <Typography sx={styles.subHeadingInfo}>
                                        This will be the background image for
                                        the first page of your booking flow
                                    </Typography>

                                    <input
                                        accept="image/*"
                                        id="backgroundURL"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={brandingStatesChangeHandler}
                                        name="backgroundURL"
                                    />
                                    <label htmlFor="backgroundURL">
                                        <FormControl required>
                                            <Button
                                                sx={{
                                                    padding: 0,

                                                    ...styles.uploadLinks,
                                                    textTransform: "unset",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "#f4f4f4"
                                                    }
                                                }}
                                                variant="text"
                                                color="primary"
                                                component="span"
                                            >
                                                Upload new image
                                            </Button>
                                        </FormControl>
                                    </label>
                                </Box>
                            </Box>
                            <Box>
                                <Grid
                                    container
                                    sx={{
                                        gap: "1rem",
                                        alignContent: "baseline",
                                        marginTop: "0.5rem"
                                    }}
                                >
                                    {!fromDashboard() && (
                                        <Grid item xs={12} sm={4}>
                                            <Box
                                                sx={{
                                                    ...styles.container,
                                                    gap: "0.5rem"
                                                }}
                                            >
                                                <Typography sx={styles.heading}>
                                                    Background color
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    sx={{
                                                        ...styles.textField,
                                                        "& input[type='color']":
                                                            {
                                                                paddingRight:
                                                                    "calc(90% - 2.5rem)"
                                                            }
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    label="Background Color"
                                                    type="color"
                                                    value={
                                                        brandingStates.backgroundColor
                                                    }
                                                    name="backgroundColor"
                                                    onChange={
                                                        brandingStatesChangeHandler
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={0.1} />
                                    {!fromDashboard() && (
                                        <Grid item xs={12} sm={6}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem"
                                                }}
                                            >
                                                {brandingStates.showHeader && (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            gap: "0.5rem"
                                                            //marginTop: "1rem"
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={styles.heading}
                                                        >
                                                            {`Transparency: ${
                                                                brandingStates.headerTransparency *
                                                                100
                                                            }%`}
                                                            <span
                                                                style={{
                                                                    color: "rgba(0,0,0,0.5)",
                                                                    fontFamily:
                                                                        "Roboto"
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title="
                            This controls the transparency of the background 
                            color over your background image"
                                                                    placement="right"
                                                                >
                                                                    <InfoIcon
                                                                        sx={{
                                                                            marginLeft:
                                                                                "0.5rem",
                                                                            height: "1.5rem",
                                                                            color: "rgba(0,0,0,0.5)"
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </span>
                                                        </Typography>
                                                        <Slider
                                                            value={
                                                                brandingStates.headerTransparency
                                                            }
                                                            onChange={
                                                                brandingStatesChangeHandler
                                                            }
                                                            step={0.1}
                                                            max={1}
                                                            min={0}
                                                            name={
                                                                "headerTransparency"
                                                            }
                                                            marks
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </>
                    )}
                </Box>
            </Grid>

            <Divider sx={{ width: "100%", margin: "1.5rem 0px" }} />
            <Box
                sx={{
                    ...styles.topRow,
                    marginTop: "1rem",
                    marginBottom: "0.875rem"
                }}
            >
                <Typography sx={styles.heading}>Text settings</Typography>
            </Box>
            <Grid container sx={{ gap: "1rem" }}>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            ...styles.container,
                            gap: "0.5rem"
                        }}
                    >
                        <Typography
                            sx={{ ...styles.heading, lineHeight: "1.6rem" }}
                        >
                            Font type
                        </Typography>
                        <Select
                            id="header-text"
                            sx={styles.selectField}
                            label="Font Type"
                            variant="filled"
                            disableUnderline
                            fullWidth
                            value={brandingStates.headerFontType}
                            name={"headerFontType"}
                            onChange={brandingStatesChangeHandler}
                            InputProps={{ shrink: false }}
                        >
                            {fontNames.map((name) => (
                                <MenuItem key={name} value={name.toString()}>
                                    <span style={{ fontFamily: name }}>
                                        {name}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            ...styles.container,
                            gap: "0.5rem"
                        }}
                    >
                        <Typography
                            sx={{ ...styles.heading, lineHeight: "1.6rem" }}
                        >
                            Font color
                        </Typography>
                        <TextField
                            fullWidth
                            variant="filled"
                            sx={styles.textField}
                            InputProps={{
                                disableUnderline: true
                            }}
                            label="Font Color"
                            type="color"
                            value={brandingStates.headerFontColor}
                            name="headerFontColor"
                            onChange={brandingStatesChangeHandler}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            ...styles.container,
                            gap: "0.5rem"
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Button color
                            </Typography>
                            <ImageTooltip
                                content={
                                    <img
                                        src={bookingFlowButton}
                                        width={250}
                                        alt={"Button Color"}
                                    />
                                }
                            >
                                <InfoIcon
                                    sx={{
                                        cursor: "pointer",

                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </ImageTooltip>
                        </Box>

                        <TextField
                            fullWidth
                            variant="filled"
                            sx={{
                                ...styles.textField
                            }}
                            InputProps={{
                                disableUnderline: true
                            }}
                            label={"Button color"}
                            type="color"
                            value={brandingStates.buttonColor}
                            name="buttonColor"
                            onChange={brandingStatesChangeHandler}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            ...styles.container,
                            gap: "0.5rem"
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Button text color
                            </Typography>
                        </Box>

                        <TextField
                            fullWidth
                            variant="filled"
                            sx={{
                                ...styles.textField
                            }}
                            InputProps={{
                                disableUnderline: true
                            }}
                            label={"Button text color"}
                            type="color"
                            value={brandingStates.buttonTextColor}
                            name="buttonTextColor"
                            onChange={brandingStatesChangeHandler}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    marginTop: "1.5rem"
                }}
            >
                <Typography
                    sx={{
                        ...styles.heading,
                        marginTop: "1rem",
                        marginBottom: "0.875rem"
                    }}
                >
                    Display heading 1
                </Typography>
                <Switch
                    name={"showHeadingOne"}
                    checked={brandingStates.showHeadingOne}
                    onChange={brandingStatesChangeHandler}
                />
                <ImageTooltip
                    content={
                        <img
                            src={bookingFlowHeadingOne}
                            alt="Booking Flow Heading 1"
                            width={400}
                        />
                    }
                >
                    <InfoIcon
                        sx={{
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            height: "1.5rem",
                            color: "rgba(0,0,0,0.5)"
                        }}
                    />
                </ImageTooltip>
            </Box>

            {brandingStates.showHeadingOne && (
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                ...styles.container,
                                gap: "0.5rem"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Heading 1
                            </Typography>
                            <TextField
                                variant="filled"
                                sx={{
                                    ...styles.textField,
                                    width: "calc(100% - 1rem)",
                                    "& input": {
                                        fontFamily:
                                            brandingStates.headerFontType
                                    }
                                }}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                label="Heading 1"
                                id="heading-1"
                                name={"companyName"}
                                value={brandingStates.companyName}
                                onChange={brandingStatesChangeHandler}
                                onClick={() => {
                                    const heading1 =
                                        document.getElementById("heading-1");
                                    heading1.focus();
                                    heading1.select();
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box
                            sx={{
                                ...styles.container,
                                gap: "0.5rem"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Font size
                            </Typography>

                            <Select
                                id="company-font-size"
                                sx={{
                                    ...styles.selectField,
                                    minWidth: "200px"
                                }}
                                label="Font Size"
                                variant="filled"
                                disableUnderline
                                fullWidth
                                value={+brandingStates.companyNameFontSize}
                                name={"companyNameFontSize"}
                                onChange={brandingStatesChangeHandler}
                                InputProps={{ shrink: false }}
                            >
                                {fontSizes.map((size) => (
                                    <MenuItem
                                        key={size}
                                        value={+size}
                                    >{`${size}px`}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Box
                sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    marginTop: "1rem"
                }}
            >
                <Typography
                    sx={{
                        ...styles.heading,
                        marginTop: "1rem",
                        marginBottom: "0.875rem"
                    }}
                >
                    Display heading 2
                </Typography>
                <Switch
                    name={"showHeadingTwo"}
                    checked={brandingStates.showHeadingTwo}
                    onChange={brandingStatesChangeHandler}
                />
                <ImageTooltip
                    content={
                        <img
                            src={bookingFlowHeadingTwo}
                            alt="Booking Flow Heading 2"
                            width={400}
                        />
                    }
                >
                    <InfoIcon
                        sx={{
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            height: "1.5rem",
                            color: "rgba(0,0,0,0.5)"
                        }}
                    />
                </ImageTooltip>
            </Box>

            {brandingStates.showHeadingTwo && (
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                ...styles.container,
                                gap: "0.5rem"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Heading 2
                            </Typography>
                            <TextField
                                fullWidth
                                variant="filled"
                                sx={{
                                    ...styles.textField,
                                    width: "calc(100% - 1rem)",
                                    "& input": {
                                        fontFamily:
                                            brandingStates.headerFontType
                                    }
                                }}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                label="Heading 2"
                                name={"companyTagline"}
                                id="heading-2"
                                value={brandingStates.companyTagline}
                                onChange={brandingStatesChangeHandler}
                                onClick={() => {
                                    const heading2 =
                                        document.getElementById("heading-2");
                                    heading2.focus();
                                    heading2.select();
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box
                            sx={{
                                ...styles.container,
                                gap: "0.5rem"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Font size
                            </Typography>

                            <Select
                                id="company-font-size"
                                sx={{
                                    ...styles.selectField,
                                    minWidth: "200px"
                                }}
                                label="Font Size"
                                variant="filled"
                                disableUnderline
                                fullWidth
                                name={"companyTaglineFontSize"}
                                value={+brandingStates.companyTaglineFontSize}
                                onChange={brandingStatesChangeHandler}
                                InputProps={{ shrink: false }}
                            >
                                {fontSizes.map((size) => (
                                    <MenuItem
                                        key={size}
                                        value={+size}
                                    >{`${size}px`}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            )}

            {
                <Box
                    sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                        marginTop: "1rem"
                    }}
                >
                    <Typography
                        sx={{
                            ...styles.heading,
                            marginTop: "1rem",
                            marginBottom: "0.875rem"
                        }}
                    >
                        Display heading 3
                    </Typography>
                    <Switch
                        name={"showHeadingThree"}
                        checked={brandingStates.showHeadingThree}
                        onChange={brandingStatesChangeHandler}
                    />
                    <ImageTooltip
                        content={
                            <img
                                src={bookingFlowHeadingThree}
                                alt="Booking Flow Heading 3"
                                width={400}
                            />
                        }
                    >
                        <InfoIcon
                            sx={{
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                                height: "1.5rem",
                                color: "rgba(0,0,0,0.5)"
                            }}
                        />
                    </ImageTooltip>
                </Box>
            }

            {brandingStates.showHeadingThree && (
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                ...styles.container,
                                gap: "0.5rem"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Heading 3
                            </Typography>
                            <TextField
                                fullWidth
                                variant="filled"
                                sx={{
                                    ...styles.textField,
                                    width: "calc(100% - 1rem)",
                                    "& input": {
                                        fontFamily:
                                            brandingStates.headerFontType
                                    }
                                }}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                label="Heading 3"
                                id="heading-3"
                                name={"companyDescription"}
                                value={brandingStates.companyDescription}
                                onChange={brandingStatesChangeHandler}
                                onClick={() => {
                                    const heading3 =
                                        document.getElementById("heading-3");
                                    heading3.focus();
                                    heading3.select();
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box
                            sx={{
                                ...styles.container,
                                gap: "0.5rem"
                            }}
                        >
                            <Typography
                                sx={{ ...styles.heading, lineHeight: "1.5rem" }}
                            >
                                Font size
                            </Typography>
                            <Select
                                id="company-font-size"
                                sx={{
                                    ...styles.selectField,
                                    minWidth: "200px"
                                }}
                                label="Font Size"
                                variant="filled"
                                disableUnderline
                                fullWidth
                                name={"companyDescriptionFontSize"}
                                InputProps={{ shrink: false }}
                                value={
                                    +brandingStates.companyDescriptionFontSize
                                }
                                onChange={brandingStatesChangeHandler}
                            >
                                {fontSizes.map((size) => (
                                    <MenuItem
                                        key={size}
                                        value={+size}
                                    >{`${size}px`}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default BrandingTab;
