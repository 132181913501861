import React, { useState, useEffect } from "react";
import { signOut } from "aws-amplify/auth";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../styles/MainNavbarStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as mutations from "../graphql/mutations";
import { execRead } from "../modules/DBService";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

import {
    FormControl,
    InputLabel,
    Select,
    Grid,
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from "@mui/material";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { Cache } from "aws-amplify/utils";
import { listCompanys } from "../queries/CustomQueries";
import {
    getUserFromCache,
    userHasMarketboxAdminRole,
    userHasCompanyAdminRole,
    userHasClientRole,
    userHasProviderOnlyRole,
    userHasAdminRole
} from "../user/UserCommon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function MainNavBar(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const logger = new ConsoleLogger("MainNavBar");
    const user = getUserFromCache();
    const smallScreen = useMediaQuery("(max-width:960px)");
    const [rows, setRows] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [navMenuEl, setNavMenuEl] = useState(null);
    const [userRole, setUserRole] = useState("");
    const showChangeCompany = userHasMarketboxAdminRole() ? true : false;
    const [changeCompanyDialog, setChangeCompanyDialog] = useState(false);
    const ROLE = userHasMarketboxAdminRole()
        ? "MBAdmin"
        : userHasCompanyAdminRole()
          ? "CompanyAdmin"
          : userHasProviderOnlyRole()
            ? "Provider"
            : userHasClientRole()
              ? "Client"
              : "";

    useEffect(() => {
        let userRole;
        if (ROLE === "MBAdmin") {
            userRole = "MB Admin";
        } else if (ROLE === "CompanyAdmin") {
            userRole = "Admin";
        } else if (ROLE === "Provider") {
            userRole = "Provider";
        } else if (ROLE === "Client") {
            userRole = "Client";
        }
        setUserRole(userRole);
    }, []);

    function getCompanyName() {
        const loggedInUser = getUserFromCache();
        logger.debug("loggedInUser = ");
        logger.debug(loggedInUser);
        let companyname;

        if (
            loggedInUser &&
            loggedInUser.company &&
            !loggedInUser.company.displayOnlyLogo
        ) {
            companyname = loggedInUser.company.name;
        }
        return companyname;
    }

    function getCompanyLogoUrl() {
        const loggedInUser = getUserFromCache();
        let logoUrl;
        if (loggedInUser && loggedInUser.company) {
            logoUrl = loggedInUser.company.logoUrl;
        } else {
            logger.debug("WARNING: NO COMPANY LOGO FOUND IN NAVBAR.");
            logoUrl = "";
        }
        return logoUrl;
    }

    async function handleSignout() {
        try {
            await Cache.setItem("user", null);
            localStorage.setItem("loggedInCongnitoUser", "");
            localStorage.setItem("loggedInMbUser", "");
            localStorage.removeItem("appState");
            sessionStorage.setItem("isRedirectedToComapny", "");
            await signOut();

            navigate("/");
            window.location.reload();
        } catch (err) {
            logger.error("Error signing out user", err);
        }
    }

    useEffect(() => {
        // if the source="user", set mode to edit

        const fetchData = async () => {
            if (showChangeCompany) await fetchCompanies();
        };

        fetchData();
    }, []);

    const fetchCompanies = async () => {
        let listCompanyData = await execRead({
            opname: "listCompanys",
            op: listCompanys,
            filter: {
                active: {
                    eq: true
                }
            }
        });

        if (listCompanyData?.items) {
            listCompanyData.items.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.localeCompare(b.name, "en", { usge: "sort" });
                } else return 0;
            });
            setRows(listCompanyData.items);
        }
    };

    function handleChangeCompany() {
        if (!changeCompanyDialog) {
            setChangeCompanyDialog(true);
        }
    }

    const handleChangeSelectedCompany = (event) => {
        setSelectedCompany(event.target.value);
    };

    async function updateUserCompany() {
        const loggedInUser = getUserFromCache();
        console.log("selected company: ", selectedCompany);
        console.log("selected company logged in user: ", loggedInUser);

        try {
            if (selectedCompany && selectedCompany !== loggedInUser.companyId) {
                let input = {
                    id: loggedInUser.id,
                    companyId: selectedCompany,
                    userCompanyId: selectedCompany
                };

                await graphql(
                    graphqlOperation(mutations.updateUser, { input })
                );

                handleSignout();
            }
        } catch (error) {
            console.log("error updating the user company: ", error);
        }
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    const navButtonOpen = (event) => {
        setNavMenuEl(event.currentTarget);
    };

    const navButtonClose = () => {
        setNavMenuEl(null);
    };

    // hide role element if user is editing themselves
    function DisplayCompanyLogo() {
        const logo = getCompanyLogoUrl();
        const loggedInUser = getUserFromCache();

        if (
            loggedInUser &&
            loggedInUser.company &&
            !loggedInUser.company.displayOnlyLogo
        ) {
            return (
                <div>
                    {logo ? (
                        <div style={{ paddingRight: "5px" }}>
                            <a
                                target="_blank"
                                href={
                                    loggedInUser.company.homepage
                                        ? loggedInUser.company.homepage
                                        : process.env.REACT_APP_AUTH_SITE_URL
                                }
                                rel="noreferrer"
                            >
                                <Avatar
                                    alt={getCompanyName()}
                                    src={getCompanyLogoUrl()}
                                    className={classes.companyavatar}
                                />
                            </a>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    {logo ? (
                        <div>
                            <a
                                target="_blank"
                                href={
                                    loggedInUser.company.homepage
                                        ? loggedInUser.company.homepage
                                        : process.env.REACT_APP_AUTH_SITE_URL
                                }
                                rel="noreferrer"
                            >
                                <img src={logo} height="50" />
                            </a>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        }
    }

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar className={classes.appBarShadow} position="fixed">
                <Toolbar>
                    {smallScreen && (
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={props.handleDrawerAction}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {DisplayCompanyLogo()}

                    <div>
                        <span className="app-user">
                            <Typography
                                className={classes.title}
                                variant="h6"
                                noWrap
                                sx={{ color: "white" }}
                            >
                                {getCompanyName()}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                        </span>
                    </div>
                    <div
                        className={classes.grow}
                        style={{
                            visibility: "hidden"
                        }}
                    ></div>
                    <div>
                        <Button
                            style={{ textTransform: "capitalize" }}
                            variant="contained"
                            color="primary"
                            className={classes.navbarButton}
                            onClick={navButtonOpen}
                            aria-controls="nav-menu"
                            aria-haspopup="true"
                        >
                            {smallScreen ? (
                                <span>
                                    <AccountCircleIcon />
                                    <KeyboardArrowDownIcon />
                                </span>
                            ) : (
                                <Typography>
                                    {"Hello, "}
                                    <span
                                        style={{
                                            fontFamily: "Roboto",
                                            fontWeight: 700
                                        }}
                                    >
                                        {user && user.firstname
                                            ? user.firstname +
                                              " " +
                                              user.lastname
                                            : user && user.emailaddress
                                              ? user && user.emailaddress
                                              : ""}
                                    </span>

                                    <KeyboardArrowDownIcon />
                                </Typography>
                            )}
                        </Button>
                        <Menu
                            id="nav-menu"
                            anchorEl={navMenuEl}
                            open={Boolean(navMenuEl)}
                            onClose={navButtonClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            PaperProps={{
                                style: {
                                    width: 200
                                }
                            }}
                        >
                            {smallScreen && (
                                <MenuItem onClick={navButtonClose}>
                                    <Typography>
                                        {"Hello, "}
                                        <span
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: 700
                                            }}
                                        >
                                            {user && user.firstname
                                                ? user.firstname +
                                                  " " +
                                                  user.lastname
                                                : user && user.emailaddress
                                                  ? user && user.emailaddress
                                                  : ""}
                                        </span>
                                    </Typography>
                                </MenuItem>
                            )}
                            <MenuItem
                                className={classes.noHoverEffect}
                                onClick={navButtonClose}
                            >
                                {userRole}
                            </MenuItem>
                            {showChangeCompany && (
                                <MenuItem onClick={handleChangeCompany}>
                                    Change company
                                </MenuItem>
                            )}
                            {userHasAdminRole() && (
                                <MenuItem
                                    onClick={() => {
                                        window
                                            .open(
                                                "https://www.gomarketbox.com/help",
                                                "_blank"
                                            )
                                            .focus();
                                        navButtonClose();
                                    }}
                                >
                                    Help Center
                                </MenuItem>
                            )}
                            <MenuItem onClick={handleSignout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}

            <Dialog
                open={changeCompanyDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    <Grid
                        container
                        direction="row-reverse"
                        justify="space-between"
                        alignItems="center"
                    >
                        <CloseIcon
                            onClick={() => setChangeCompanyDialog(false)}
                        ></CloseIcon>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content"
                        }}
                    >
                        <FormControl sx={{ mt: 2, minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-label">
                                Select Company
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="company"
                                label="Select Company"
                                onChange={handleChangeSelectedCompany}
                                className={classes.formControl}
                                inputProps={{
                                    name: "type",
                                    id: "company-type"
                                }}
                            >
                                {rows &&
                                    rows.length > 0 &&
                                    rows.map((item, i) => {
                                        return (
                                            <MenuItem
                                                value={item.id}
                                                key={`${item.id}${item.i}`}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            onClick={updateUserCompany}
                            color="primary"
                            autoFocus
                        >
                            Change company
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}
