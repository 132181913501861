import React from "react";
import ProviderForm from "../forms/ProviderForm";
import MasterDashboardForm from "../forms/MasterDashboardForm";
import DashboardForm from "../forms/DashboardForm";
import ProviderAddEdit from "../forms/ProviderAddEdit";
import CustomerForm from "../forms/CustomerForm";
import CustomerAddEditForm from "../forms/CustomerAddEditForm";
import OrderForm from "../forms/OrderForm";
import BillingForm from "../forms/BillingForm";
import ServiceForm from "../forms/ServiceForm";
import ServiceAdd from "../forms/ServiceAdd";
import ReportForm from "../forms/ReportForm";
import CompanyForm from "../forms/CompanyForm";
import AdminForm from "../forms/AdminForm";
import IntegrationForm from "../forms/IntegrationForm";
import UpdateEmailForm from "../forms/UpdateEmailForm";
import PackageForm from "../forms/PackageForm";
import PromotionAddForm from "../forms/PromotionAddForm";
import CompanyAddEditForm from "../forms/CompaniesAddEditForm";
import PackageAddEditForm from "../forms/PackageAddEditForm";
import ClientPackageAddEditForm from "../forms/ClientPackageAddEditForm";
import UsersForm from "../forms/UsersForm";
import UsersEditViewForm from "../forms/UsersEditViewForm";
import ClientBillingInfo from "../forms/ClientBillingInfo";
import RegionAddEditForm from "../forms/RegionAddEditForm";
import ProviderReview from "../forms/ProviderReview";
import { RegionForm } from "../forms/RegionForm";
import { ProviderScheduleDevEx } from "../forms/ProviderScheduleDevEx";
import ColorForm from "../forms/ColorForm";
import { CompanyLocationForm } from "../forms/CompanyLocationForm";
import CompanyLocationAddEditForm from "../forms/CompanyLocationAddEditForm";
import { TimeBlockProviders } from "../forms/TimeBlockProviders";
import MiscBillingTransaction from "../billing/MiscBillingTransaction";
import { ProviderSchedule } from "../forms/ProviderSchedule";
import ProviderScheduleAddEditForm from "../forms/ProviderScheduleAddEditForm";
import { TimeBlockForm } from "../forms/TimeBlockForm";
import CategoryAddEditForm from "../forms/CategoryAddEditForm";
import { ProviderReviewForm } from "../forms/ProviderReviewForm";
import { BookingsListForm } from "../forms/BookingsListForm";
import EnvtForm from "../forms/EnvtForm";
import { ProviderBlockTime } from "../forms/ProviderBlockTime";
import { BlockTimeAddEdit } from "../forms/BlockTimeAddEdit";
import { ProviderDirectory } from "../forms/ProviderDirectory";
import { SkillsForm } from "../forms/SkillsForm";
import { SkillsAddEditForm } from "../forms/SkillsAddEditForm";
import CompanyReminderForm from "../forms/CompanyReminderForm";
import CompanyReminderAddEditForm from "../forms/CompanyReminderAddEditForm";
import { ProviderLocationForm } from "../forms/ProviderLocationForm";
import { ProviderLocationAddEditForm } from "../forms/ProviderLocationAddEdit";
import { ProviderAddiotionalPhotos } from "../forms/ProviderAdditionalPhotos";
import { ProviderAddiotionalPhotosAddEdit } from "../forms/ProviderAdditionalPhotosAddEdit";
import AdditionalChargesForm from "../forms/AdditionalChargesForm";
import ManageBookingRequest from "../forms/ManageBookingReq";
import ManageServiceCategoryForm from "../forms/ManageServiceCategoryForm";
import ManageServiceOrder from "../components/serviceorder/ManageServiceOrder";
import PotentialSalesForm from "../forms/PotentialSalesForm";
import AuditRecordsForm from "../forms/AuditRecordsForm";
import { getUserFromCache } from "../user/UserCommon";
import PromoCodes from "./promoCodes/PromoCodes";
import BookingFlowSettings from "../forms/BookingFlowSettings/BookingFlowSettings";

export default function PageController({ newPage }) {
    const user = getUserFromCache();
    // check if we have all the valid information for user... if not, direct to user page
    const validUser = user && user.firstname && user.lastname;
    if (!validUser) {
        return <UsersEditViewForm source="user" />;
    }

    // redirect if client from Dashboard to BookingListForm
    if (
        user &&
        user.role === "CLIENT" &&
        (newPage === "DashboardForm" || newPage === "CompanyAddEditForm")
    ) {
        return <BookingsListForm />;
    }

    // redirect if client from Dashboard to BookingListForm
    if (
        user &&
        user.role === "PROVIDER" &&
        (newPage === "DashboardForm" || newPage === "CompanyAddEditForm")
    ) {
        return <BookingsListForm />;
    }

    switch (newPage) {
        case "ProviderForm":
            return <ProviderForm />;
        case "MasterDashboardForm":
            return <MasterDashboardForm />;
        case "DashboardForm":
            return <DashboardForm />;
        case "ProviderAddEdit":
            return <ProviderAddEdit />;
        case "ProviderAdditionalPhotos":
            return <ProviderAddiotionalPhotos />;
        case "ProviderAdditionalPhotosAddEdit":
            return <ProviderAddiotionalPhotosAddEdit />;
        case "AuditRecordsForm":
            return <AuditRecordsForm />;
        case "CustomerForm":
            return <CustomerForm />;
        case "CustomerAddEditForm":
            return <CustomerAddEditForm />;
        case "ClientPackageAddEditForm":
            return <ClientPackageAddEditForm />;
        case "SkillsAddEditForm":
            return <SkillsAddEditForm />;
        case "SkillsForm":
            return <SkillsForm />;
        case "OrderForm":
            return <OrderForm />;
        case "BillingForm":
            return <BillingForm />;
        case "ServiceForm":
            return <ServiceForm />;
        case "CategoryAddEditForm":
            return <CategoryAddEditForm />;
        case "ServiceAdd":
            return <ServiceAdd />;
        case "PromotionForm":
            return <PromoCodes company={user.company} user={user} />;
        case "ReportForm":
            return <ReportForm />;
        case "CompanyForm":
            return <CompanyForm />;
        case "CompanyReminderForm":
            return <CompanyReminderForm />;
        case "CompanyReminderAddEditForm":
            return <CompanyReminderAddEditForm />;
        case "AdminForm":
            return <AdminForm />;
        case "IntegrationForm":
            return <IntegrationForm />;
        case "UpdateEmailForm":
            return <UpdateEmailForm company={user.company} />;
        case "PackageForm":
            return <PackageForm />;
        case "PromotionAddForm":
            return <PromotionAddForm />;
        case "CompanyAddEditForm":
            return <CompanyAddEditForm />;
        case "CompanyLocationForm":
            return <CompanyLocationForm />;
        case "CompanyLocationAddEditForm":
            return <CompanyLocationAddEditForm />;
        case "ProviderLocationForm":
            return <ProviderLocationForm />;
        case "ProviderLocationAddEditForm":
            return <ProviderLocationAddEditForm />;
        case "PackageAddEditForm":
            return <PackageAddEditForm />;
        case "UsersForm":
            return <UsersForm />;
        case "UsersEditViewForm":
            return <UsersEditViewForm />;
        case "RegionForm":
            return <RegionForm />;
        case "RegionAddEditForm":
            return <RegionAddEditForm />;
        case "ProviderReview":
            return <ProviderReview />;
        case "ColorForm":
            return <ColorForm />;
        case "TimeBlockProviders":
            return <TimeBlockProviders />;
        case "TimeBlockForm":
            return <TimeBlockForm />;
        case "ManageServiceCategoryForm":
            return <ManageServiceCategoryForm />;
        case "ManageServiceOrder":
            return <ManageServiceOrder />;
        case "MiscBillingTransaction":
            return <MiscBillingTransaction />;
        case "ProviderSchedule":
            return <ProviderSchedule company={user.company} />;
        case "ProviderScheduleAddEditForm":
            return <ProviderScheduleAddEditForm />;
        case "ProviderReviewForm":
            return <ProviderReviewForm />;
        case "BookingsListForm":
            return <BookingsListForm />;
        case "ProviderDirectory":
            return <ProviderDirectory />;
        case "EnvtForm":
            return <EnvtForm />;
        case "ProviderBlockTime":
            return <ProviderBlockTime />;
        case "BlockTimeAddEdit":
            return <BlockTimeAddEdit />;
        case "AdditionalChargesForm":
            return <AdditionalChargesForm />;
        case "ManageBookingReq":
            return <ManageBookingRequest />;
        case "ProviderScheduleDevEx":
            return <ProviderScheduleDevEx company={user.company} />;
        case "PotentialSalesForm":
            return <PotentialSalesForm />;
        case "ClientBillingInfo":
            return <ClientBillingInfo />;
        case "BFSettings":
            return <BookingFlowSettings compId={user.company.id} />;

        default:
            return "NO FORM IN newPage";
    }
}
