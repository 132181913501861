import React, { Fragment } from "react";
import FreeTrialForm from "../forms/FreeTrialForm.js";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

/* styles used in right panel */
const useStyles = makeStyles({
    panel: {
        width: "45%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "24px"
    },
    panel_mobile: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "6px"
    }
});

const RightPanel = (props) => {
    console.log("RightPanel Loaded");

    const classes = useStyles();

    return (
        <Fragment>
            {/*Right Panel Grid*/}
            <Grid
                item
                className={
                    props.width > 900 ? classes.panel : classes.panel_mobile
                }
            >
                <FreeTrialForm
                    renderActivateAccountForm={props.renderActivateAccountForm}
                    width={props.width}
                    freeTrial={props.freeTrial}
                />
            </Grid>
        </Fragment>
    );
};

export default RightPanel;
