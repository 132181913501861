import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Button, Paper, Grid, Typography, Snackbar, FormControlLabel, CircularProgress } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
// prettier-ignore
import { useStyles } from "../styles/ManageBookingReqStyles";
import { putApi } from "../modules/AmplifyServices";
import * as availability from "../modules/Availability";
import { useTheme } from "@mui/material/styles";
import { auditAcceptBookingOnBehalf } from "../modules/Audit";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { getUserFromCache } from "../user/UserCommon";

export default function ManageBookingRequest(props) {
    const { state, dispatch, actions } = useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [providerId, setProviderId] = useState("");
    const [providerInfo, setProviderInfo] = useState();
    const [displayedProviders, setDisplayedProviders] = useState([]);
    const [acceptBtnClicked, setAcceptBtnClicked] = useState(false);
    const theme = useTheme();
    const classesnew = usePromotionsStyles();
    const classes = useStyles();

    const dtFormat = new Intl.DateTimeFormat("en", {
        timeStyle: "short",
        dateStyle: "short"
    });

    useEffect(() => {
        let br = state.bookingRequest;
        setProviderId(br.data.origProvider.id);
        let editeddisplayed = br.data.dir.displayed;

        if (
            JSON.stringify(editeddisplayed).includes(
                JSON.stringify(br.data.origProvider.id)
            )
        ) {
            setDisplayedProviders(br.data.dir.displayed);
        } else {
            editeddisplayed.unshift(br.data.origProvider);
            setDisplayedProviders(editeddisplayed);
        }
    }, []);

    function handleMsgClose() {}

    function handleProviderChange(event) {
        setProviderId(event.target.value);
    }

    async function acceptOnBehalfOfProvider(providerType) {
        const user = getUserFromCache();
        //if provider type is "matched", get P record from br table
        //if P record exists, schedule is assumed to be available
        //if P record does not exist, ensure the avalability of the first slot
        //call /bookingreq api to accept
        // if br status is changed to accepted in the background in the meanwhile, the acceptance won't go through
        setAcceptBtnClicked(true);
        let success = false;
        let providerInfo;
        if (providerType === "matched") {
            providerInfo = getSelectedProviderInfoFromBr();
        } else {
            providerInfo = await readSelectedProviderInfo();
        }
        if (!providerInfo) {
            setSnackMsg("Provider information is not found.");
            setMsgOpen(true);
            setAcceptBtnClicked(false);
            return;
        } else {
            const isProvAvail =
                await checkIfProviderIsAvailableForFirstSlot(providerInfo);
            if (isProvAvail) {
                const result = await putApi("twiliosmsin", "/bookingreq", {
                    body: {
                        providerId: providerId,
                        bookingReqId: state.bookingRequest.id,
                        bookingReqRecordType: state.bookingRequest.recordType,
                        actionUser: user,
                        tostatus: "ACCEPTED",
                        actionOnBehalf: true,
                        providerInfo
                    }
                });
                setTimeout(() => {
                    // Your code to be executed after the delay
                    console.log("Continuing after 15 seconds");
                }, 15000); // 15 seconds in milliseconds
                console.log("bookingreq result", result);
                console.log("what is it", state.bookingRequest, providerInfo);

                await auditAcceptBookingOnBehalf(
                    user,
                    state.bookingRequest,
                    providerInfo,
                    "ACCEPTED"
                );

                if (success) {
                    setSnackMsg("Booking Accepted!");
                }
                setMsgOpen(true);

                setSnackMsg(result.message);
                setMsgOpen(true);
                setAcceptBtnClicked(false);
                goBack();
                return;
            } else {
                setSnackMsg("Provider is not available.");
                setMsgOpen(true);
                setAcceptBtnClicked(false);
                return;
            }
        }
    }

    async function checkIfProviderIsAvailableForFirstSlot(prov) {
        if (prov.id === state.bookingRequest.data.origProvider.id) {
            return true; //
        }
        if (
            state.bookingRequest.data.broadcastedProvs &&
            state.bookingRequest.data.broadcastedProvs.length > 0
        ) {
            let isBroadcasted = false;
            for (let bp of state.bookingRequest.data.broadcastedProvs) {
                if (bp === prov.id) {
                    isBroadcasted = true;
                    break;
                }
            }
            if (isBroadcasted) return true;
        }
        try {
            //check availability
            let isAv = await availability.checkAvailability(
                prov.schedule.id,
                prov.schedule.scheduleinfo,
                new Date(state.bookingRequest.data.selectedslot.date),
                state.bookingRequest.data.selectedslot.label,
                state.bookingRequest.data.serviceType.minutes
            );
            console.log("isAv", isAv);
            if (isAv) return true;
        } catch (e) {
            setSnackMsg("Unable to check availability of the provider.");
            setMsgOpen(true);
            return false;
        }
        return false;
    }

    function getSelectedProviderInfoFromBr() {
        let prov;
        if (state.bookingRequest.data.dir.displayed) {
            prov = state.bookingRequest.data.dir.displayed.find(
                (p) => p.id === providerId
            );
        }
        if (!prov) {
            if (state.bookingRequest.data.dir.notdisplayed) {
                prov = state.bookingRequest.data.dir.notdisplayed.find(
                    (p) => p.id === providerId
                );
            }
        }
        return prov;
    }

    async function readSelectedProviderInfo() {}

    function getNumberOfAppts() {
        let numOfAppts = 1;
        if (
            state.bookingRequest.data.repeatingAppointment &&
            state.bookingRequest.data.repeatingApptList &&
            state.bookingRequest.data.repeatingApptList.length > 0
        ) {
            numOfAppts += state.bookingRequest.data.repeatingApptList.length;
        }
        return numOfAppts;
    }

    function goBack() {
        actions.setPage("BookingsListForm");
        actions.setBookingsTab(2);
    }

    function displayAppts() {
        if (state.bookingRequest.data.sdt === "single") {
            return (
                <TextField
                    fullWidth
                    id="standard-read-only-input-1"
                    label="Booking date/time"
                    defaultValue={dtFormat.format(
                        new Date(state.bookingRequest.data.selectedslot.date)
                    )}
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        readOnly: true
                    }}
                />
            );
        } else {
            return (
                <TextField
                    select
                    fullWidth
                    id="standard-read-only-input-2"
                    label="Booking date/time"
                    value={dtFormat.format(
                        new Date(state.bookingRequest.data.selectedslot.date)
                    )}
                    defaultValue={dtFormat.format(
                        new Date(state.bookingRequest.data.selectedslot.date)
                    )}
                    variant="outlined"
                    margin="dense"
                    SelectProps={{
                        native: true
                    }}
                >
                    {
                        <option
                            key={state.bookingRequest.data.selectedslot.date}
                            value={dtFormat.format(
                                new Date(
                                    state.bookingRequest.data.selectedslot.date
                                )
                            )}
                        >
                            {dtFormat.format(
                                new Date(
                                    state.bookingRequest.data.selectedslot.date
                                )
                            )}
                        </option>
                    }
                    {state.bookingRequest.data.repeatingApptList &&
                        state.bookingRequest.data.repeatingApptList.map((a) => (
                            <option
                                key={a}
                                value={new Date(a).toLocaleDateString("en-US", {
                                    weekday: "long",
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric"
                                })}
                            >
                                {new Date(a).toLocaleDateString("en-US", {
                                    weekday: "long",
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric"
                                })}
                            </option>
                        ))}
                </TextField>
            );
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography className={classes.title} variant="h5" noWrap>
                Manage Booking Request
            </Typography>
            <Paper rounded="true" className={classes.root}>
                <Typography variant="h6" noWrap style={{ margin: "8px" }}>
                    Booking details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-3"
                            label="Created on"
                            defaultValue={dtFormat.format(
                                new Date(state.bookingRequest.createdAt)
                            )}
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-4"
                            label="Booking type"
                            defaultValue={state.bookingRequest.data.sdt}
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-5"
                            label="Booking address"
                            defaultValue={
                                state.bookingRequest.data.bookingAddress
                                    .addrOneLine
                            }
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-6"
                            label="Number of appointments"
                            defaultValue={getNumberOfAppts()}
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {displayAppts()}
                    </Grid>
                </Grid>
                <Typography variant="h6" noWrap style={{ margin: "8px" }}>
                    Client details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-7"
                            label="Name"
                            defaultValue={`${state.bookingRequest.data.client.user.firstname} ${state.bookingRequest.data.client.user.lastname}`}
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-9"
                            label="Email"
                            defaultValue={
                                state.bookingRequest.data.client.user
                                    .emailaddress
                            }
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-10"
                            label="Mobile"
                            defaultValue={
                                state.bookingRequest.data.client.user
                                    .mobilephone
                            }
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-11"
                            label="Home"
                            defaultValue={
                                state.bookingRequest.data.client.user.homephone
                            }
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="standard-read-only-input-12"
                            label="Work"
                            defaultValue={
                                state.bookingRequest.data.client.user.workphone
                            }
                            InputProps={{
                                readOnly: true
                            }}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6" noWrap style={{ margin: "8px" }}>
                    Select provider
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Typography
                            variant="body1"
                            noWrap
                            style={{ margin: "8px" }}
                        >
                            Select provider matching client's booking request
                        </Typography>
                        <RadioGroup
                            aria-label="selectProvider"
                            name="selectProvider"
                            value={providerId}
                            onChange={handleProviderChange}
                        >
                            {displayedProviders &&
                                displayedProviders.map((dp) => (
                                    <FormControlLabel
                                        key={dp.id}
                                        value={dp.id}
                                        control={<Radio color="primary" />}
                                        label={`${dp.firstname} ${dp.lastname} (${dp.emailaddress})`}
                                    />
                                ))}
                        </RadioGroup>
                    </Grid>
                    {/*<Grid item xs={12} md={2}>
                        {" "}
                        OR{" "}
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Box
                            border={1}
                            borderRadius={16}
                            style={{ margin: "16px", padding: "8px" }}
                        >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Search providers
                                </FormLabel>
                            </FormControl>
                        </Box>
                                        </Grid>*/}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                maxWidth: "120px",
                                minWidth: "120px",
                                marginRight: 3
                            }}
                            onClick={() => acceptOnBehalfOfProvider("matched")}
                            disabled={acceptBtnClicked}
                        >
                            ACCEPT
                            {acceptBtnClicked && (
                                <CircularProgress
                                    size={24}
                                    className={classesnew.buttonProgress}
                                />
                            )}
                        </Button>
                    </Grid>{" "}
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                maxWidth: "120px",
                                minWidth: "120px",
                                marginRight: 3
                            }}
                            onClick={() => goBack()}
                            disabled={acceptBtnClicked}
                        >
                            CANCEL
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
