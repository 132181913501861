import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        minWidth: "100%",
        fontFamily: "Poppins, sans-serif",
        display: "flex",
        justifyContent: "center"
    },
    selectField: {
        "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.23)"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.67)"
        },
        "&.MuiInputLabel-root.Mui-focused": {
            color: "black"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        },
        width: "166px",
        [theme.breakpoints.down("sm")]: {
            width: "155px"
        },
        maxWidth: "166px",
        height: "46px",
        fontFamily: "Poppins, sans-serif"
    },
    input: {
        display: "flex",
        justifyContent: "center"
    }
}));

const SubscriptionPlanSelector = (props) => {
    const classes = useStyles();
    const [selectedPlan, setSelectedPlan] = React.useState("professional");

    const handleChange = (event) => {
        setSelectedPlan(event.target.value);
        props.onSubscriptionPlanUpdate(event.target.value);
    };

    return (
        <FormControl>
            <Select
                className={classes.selectField}
                label=""
                id="dropdown-select"
                value={selectedPlan}
                onChange={handleChange}
                inputProps={{
                    className: classes.input
                }}
            >
                <MenuItem value={"basic"} className={classes.menuItem}>
                    Starter
                </MenuItem>
                <MenuItem value={"professional"} className={classes.menuItem}>
                    Professional
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default SubscriptionPlanSelector;
