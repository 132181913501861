import React, { useContext, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Paper, Grid, TextField, FormControl, InputAdornment, Toolbar, Switch, Button, FormControlLabel, Tooltip, IconButton } from "@mui/material";
// prettier-ignore
import { Snackbar } from "@mui/material";
import PropTypes from "prop-types";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { useToolbarStyles } from "../styles/TableToolbarStyles";
import clsx from "clsx";
import CompanyReminderTable from "../components/CompanyReminderTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

function CompanyReminderForm(props) {
    //select
    const [values, setValues] = useState({
        active: true,
        searchTerms: ""
    });
    //active pending switch
    const [active, setActive] = React.useState(true);
    const [selected, setSelected] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const [deleteDialog, setDeleteDialog] = React.useState(false);

    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    let filter = {
        active: {
            eq: true
        }
    };

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth);
        fetchReminderList();
    }, []);

    async function fetchReminderList() {
        const listCompanyData = await graphql(
            graphqlOperation(queries.listCompanyReminders, { filter })
        );
        setRows(listCompanyData.data.listCompanyReminders.items);
    }

    async function filterReminderList(filter) {
        const listCompanyData = await graphql(
            graphqlOperation(
                queries.listCompanyReminders,
                filter ? { filter } : ""
            )
        );
        setRows(listCompanyData.data.listCompanyReminders.items);
    }

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    const deleteReminder = async (id) => {
        const input = {
            id: id
        };
        try {
            let deleteReminder = await graphql(
                graphqlOperation(mutations.deleteCompanyReminder, { input })
            );
            if (!!deleteReminder.error && deleteReminder.error.length > 0) {
                setSnackMsg(
                    "Something went wrong, while deleting company reminder."
                );
                setMsgOpen(true);
            } else {
                fetchReminderList();
                setDeleteDialog(false);
                setSelected([]);
                actions.setPage("CompanyReminderForm");
            }
        } catch (err) {
            setSnackMsg(
                "Something went wrong, while deleting company reminder."
            );
            setMsgOpen(true);
        }
    };
    const _handleDeleteDialogAccept = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteReminder(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    const handleCompanyChange = (event) => {
        const { name, value } = event.target;
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));
        let activeValue = active,
            searchValue = values.searchTerms;
        if (event.target.name === "active") {
            console.log("checked", event.target);
            activeValue = event.target.checked;
        } else if (event.target.value) {
            searchValue = event.target.value;
        }
        if (!!searchValue) {
            filter = {
                ...filter,
                active: {
                    eq: activeValue
                },
                or: [
                    {
                        name: { contains: searchValue }
                    }
                ]
            };
        } else {
            filter = {
                ...filter,
                active: {
                    eq: activeValue
                }
            };
        }

        filterReminderList(filter);
    };

    const { actions } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    function handleCompanyAddClick() {
        actions.setMode("Add");
        actions.setPage("CompanyReminderAddEditForm");
    }

    const EnhancedTableToolbar = (props) => {
        const classesToolbar = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classesToolbar.root, {
                    [classesToolbar.highlight]: numSelected > 0
                })}
            >
                <div className={classesToolbar.title}>
                    {numSelected > 0 ? (
                        <Typography variant="h6" id="tableTitle">
                            {selected.length} selected
                        </Typography>
                    ) : (
                        ""
                    )}
                </div>
                <div className={classesToolbar.spacer} />
                <div className={classesToolbar.actions}>
                    {numSelected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="Delete"
                                onClick={() => setDeleteDialog(true)}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        ""
                    )}
                </div>
            </Toolbar>
        );
    };

    console.log("fetchReminderList", rows);
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="companies reminders"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <FormHeading title={"Reminders"} classes={classes} />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <TextField
                            id="search"
                            name="searchTerms"
                            label="Search by name"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={values.searchTerms}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleCompanyChange}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.flexDisplay}>
                        <FormControlLabel
                            margin="normal"
                            control={
                                <Switch
                                    checked={active}
                                    onChange={(event) => {
                                        setActive(event.target.checked);
                                        handleCompanyChange(event);
                                    }}
                                    value="active"
                                    color="primary"
                                    name="active"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCompanyAddClick}
                        >
                            <AddIcon className={classes.leftIcon} />
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Grid
                                item
                                xs={2}
                                className={classes.disablepadding}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.fullWidth}
                                >
                                    {/* }
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    List Actions
                  </InputLabel>
                  <Select
                    value={values.action}
                    //onChange={handleInputChange}
                    name="action"
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="action"
                        id="outlined-age-simple"
                      />
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {rows &&
                      rows.length > 0 &&
                      rows.map((item, i) => {
                        return (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                    { */}
                                </FormControl>
                            </Grid>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <CompanyReminderTable
                                rows={rows}
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default CompanyReminderForm;
