import React, { useState } from "react";
import Typography from "@mui/material/Typography";
// prettier-ignore
import { Card, CardContent, Box, Drawer, Tooltip, ClickAwayListener, Grid, Button, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useWindowSize } from "react-use";
import { useAtom } from "jotai";
import { mbxUserAtom, integrationRefDataAtom } from "../../atoms/atoms";

function IntegrationSetupPanel(props) {
    const [revealSecretKey, setRevealSecretKey] = useState(false);
    const [secretIsCopied, setSecretIsCopied] = useState(false);
    const [clientIdIsCopied, setClientIdIsCopied] = useState(false);
    let { width } = useWindowSize();
    const [integrationRefData, setIntegrationRefData] = useAtom(
        integrationRefDataAtom
    );
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);

    async function handleRevealSecretKey() {
        if (integrationRefData && integrationRefData.secretkey) {
            setRevealSecretKey(true);
        }
    }

    const handleSecretTextClick = () => {
        navigator.clipboard
            .writeText(integrationRefData?.secretkey)
            .then(() => {
                setSecretIsCopied(true);
            })
            .catch((error) => {
                console.error("Failed to copy text:", error);
            });
    };

    const handleClientIdTextClick = () => {
        navigator.clipboard
            .writeText(mbxUser?.companyId)
            .then(() => {
                setClientIdIsCopied(true);
            })
            .catch((error) => {
                console.error("Failed to copy text:", error);
            });
    };

    // Load Zapier template JS
    const script = document.createElement("script");
    script.type = "module";
    script.src =
        "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    document.head.appendChild(script);

    // Load Zapier template CSS
    const stylesheet = document.createElement("link");
    stylesheet.rel = "stylesheet";
    stylesheet.href =
        "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    document.head.appendChild(stylesheet);

    return (
        <div>
            <Drawer
                anchor="right"
                open={props.open}
                onClose={props.handlePanelOpenClose}
                ModalProps={{
                    hideBackdrop: true,
                    container: document.body,
                    disableScrollLock: true
                }}
                PaperProps={{
                    sx: {
                        marginTop: "64px",
                        height: "calc(100% - 64px)"
                    }
                }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        props.handlePanelOpenClose();
                        setRevealSecretKey(false);
                        setSecretIsCopied(false);
                        setClientIdIsCopied(false);
                    }}
                >
                    <Box
                        sx={{
                            width: width > 690 ? 520 : 320,
                            padding: "16px 33px 33px",
                            height: "100%"
                        }}
                    >
                        <Grid
                            container
                            onClick={() => {
                                props.handlePanelOpenClose();
                                setRevealSecretKey(false);
                                setSecretIsCopied(false);
                                setClientIdIsCopied(false);
                            }}
                            sx={{
                                marginBottom: "10px",
                                alignItems: "center",
                                gap: "4px",
                                cursor: "pointer"
                            }}
                        >
                            <ArrowBackIosNewIcon sx={{ fontSize: "0.8rem" }} />
                            <Typography sx={{ fontSize: "14px" }}>
                                Back
                            </Typography>
                        </Grid>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                            {props.panelTitle}
                        </Typography>
                        <Grid container style={{ gap: "8px" }}>
                            <Typography
                                variant="body2"
                                color="black"
                                component="p"
                            >
                                {props.panelText}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="black"
                                component="p"
                            >
                                To use MarketBox with {props.panelTitle}:
                            </Typography>

                            <List
                                style={{
                                    paddingTop: "6px",
                                    paddingBottom: "16px"
                                }}
                            >
                                <ListItem
                                    sx={{
                                        padding: "0px"
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: "28px",
                                            fontSize: "14px",
                                            fontFamily:
                                                "'Roboto','Helvetica','Arial','sans-serif'"
                                        }}
                                    >
                                        {"1.)"}
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            sx: { fontSize: "14px" }
                                        }}
                                    >
                                        {props.instructionStepOne || (
                                            <>
                                                Visit{" "}
                                                <a
                                                    href="https://zapier.com/app/dashboard?utm_adgroup=brand_trademark_zapier&gclid=CjwKCAjwge2iBhBBEiwAfXDBRyxOPwDy4lWK1jcSJnfU3GzCkAp2q056wfKxtuWlE7sqJM4BQtIE5hoCOasQAvD_BwE&utm_medium=cpc&utm_term=zapier&gad=1&utm_content=_pcrid_607316261975_pkw_zapier_pmt_p_pdv_c_slid__pgrid_136156401017_ptaid_aud-1638132504601%3Akwd-44231641810_&utm_source=google&utm_campaign=gaw-gbl-nua-search-brand-remarketing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Zapier
                                                </a>
                                                , create a new zap and search
                                                MarketBox in Step 1
                                            </>
                                        )}
                                    </ListItemText>
                                </ListItem>
                                <ListItem
                                    sx={{
                                        padding: "0px"
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: "28px",
                                            fontSize: "14px",
                                            fontFamily:
                                                "'Roboto','Helvetica','Arial','sans-serif'"
                                        }}
                                    >
                                        {"2.)"}
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            sx: { fontSize: "14px" }
                                        }}
                                    >
                                        {props.instructionStepTwo || (
                                            <>
                                                Connect your MarketBox account
                                                by entering your Secret Key and
                                                Client ID below:
                                            </>
                                        )}
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>

                        {props.setupSection ? (
                            props.setupSection
                        ) : (
                            <Card
                                sx={{
                                    backgroundColor: "#f4f4f4",
                                    marginBottom: "16px"
                                }}
                            >
                                <CardContent style={{ padding: "12px" }}>
                                    <Grid container>
                                        <Typography
                                            variant="body2"
                                            color="black"
                                            component="p"
                                        >
                                            Secret Key:
                                        </Typography>
                                        {revealSecretKey ? (
                                            <Tooltip
                                                arrow
                                                placement="right"
                                                title={
                                                    secretIsCopied
                                                        ? "Copied!"
                                                        : "Copy"
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="black"
                                                    component="p"
                                                    sx={{
                                                        marginLeft: "4px",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={
                                                        handleSecretTextClick
                                                    }
                                                >
                                                    {integrationRefData?.secretkey ||
                                                        ""}
                                                </Typography>
                                            </Tooltip>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#ffffff",
                                                    padding: "0px 12px",
                                                    borderRadius: "2px",
                                                    marginLeft: "8px",
                                                    color: "black",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "#f8f8f8"
                                                    },
                                                    borderStyle: "solid",
                                                    borderWidth: "thin",
                                                    borderColor: "darkgray"
                                                }}
                                                disableElevation
                                                onClick={handleRevealSecretKey}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        textTransform:
                                                            "math-auto",
                                                        fontWeight: "500"
                                                    }}
                                                >
                                                    Reveal secret key
                                                </Typography>
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid
                                        container
                                        sx={{ alignItems: "baseline" }}
                                    >
                                        <Typography
                                            variant="body2"
                                            color="black"
                                            component="p"
                                            style={{ marginTop: "6px" }}
                                        >
                                            Client ID:
                                        </Typography>
                                        <Tooltip
                                            arrow
                                            placement="right"
                                            title={
                                                clientIdIsCopied
                                                    ? "Copied!"
                                                    : "Copy"
                                            }
                                        >
                                            <Typography
                                                variant="body2"
                                                color="black"
                                                component="p"
                                                sx={{
                                                    marginLeft: "4px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={
                                                    handleClientIdTextClick
                                                }
                                            >
                                                {mbxUser.companyId}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                        {props.templateIds && (
                            <>
                                <List
                                    style={{
                                        maxHeight: "55%",
                                        overflow: "auto"
                                    }}
                                >
                                    {props.templateIds.map((id) => (
                                        <ListItem
                                            key={id}
                                            disablePadding
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <zapier-zap-templates
                                                theme="light"
                                                ids={id}
                                                limit={5}
                                                link-target="new-tab"
                                                presentation="row"
                                                use-this-zap="show"
                                                style={{ width: "100%" }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                                <br />
                            </>
                        )}
                    </Box>
                </ClickAwayListener>
            </Drawer>
        </div>
    );
}

export default IntegrationSetupPanel;
