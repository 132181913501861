import React from "react";
import {
    Checkbox,
    Grid,
    FormLabel,
    FormGroup,
    Switch,
    FormControlLabel,
    TextField
} from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export const TimeBlockProviders = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="pickers">
                            <DatePicker
                                label="Start Date"
                                format="MMM dd, yyyy"
                                minDateMessage={"Please select a start date."}
                                value={props.dateValues.startDate}
                                onChange={(e) =>
                                    props._handleDateChange("startDate", e)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.checkEndDate}
                                onChange={props.handleEndDateChange}
                                value={props.checkEndDate}
                            />
                        }
                        label="Add End Date"
                    />
                    {props.checkEndDate && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="pickers">
                                <DatePicker
                                    label="End Date"
                                    format="MMM dd, yyyy"
                                    // minDate={props.dateValues.startDate}
                                    minDate={props.dateValues.startDate - 1}
                                    minDateMessage={
                                        "Please select an end date greater than or equal to the start date."
                                    }
                                    value={props.dateValues.endDate}
                                    onChange={(e) =>
                                        props._handleDateChange("endDate", e)
                                    }
                                    renderInput={(props) => (
                                        <TextField {...props} />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} className={classes.iconPadding}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                // checked={checksta  te.allday}
                                onChange={props.handleAllDayChange}
                                value="allday"
                            />
                        }
                        label="All Day"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className="classes.root">
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="pickers">
                            <TimePicker
                                label="Start Time"
                                format="hh:mm a"
                                minDateMessage={"Please select a start time."}
                                value={props.timeValues.startTime}
                                disabled={props.allDayState}
                                onChange={(e) =>
                                    props._handleTimeChange("startTime", e)
                                }
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="pickers">
                            <TimePicker
                                label="End Time"
                                format="hh:mm a"
                                disabled={props.allDayState}
                                minDateMessage={
                                    "Please select an end time greater than start time."
                                }
                                value={props.timeValues.endTime}
                                onChange={(e) =>
                                    props._handleTimeChange("endTime", e)
                                }
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormLabel component="legend">Repeat On</FormLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(0)}
                                    onChange={props.handleChange(0)}
                                    value={0}
                                />
                            }
                            label="S"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(1)}
                                    onChange={props.handleChange(1)}
                                    value={1}
                                />
                            }
                            label="M"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(2)}
                                    onChange={props.handleChange(2)}
                                    value={2}
                                />
                            }
                            label="T"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(3)}
                                    onChange={props.handleChange(3)}
                                    value={3}
                                />
                            }
                            label="W"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(4)}
                                    onChange={props.handleChange(4)}
                                    value={4}
                                />
                            }
                            label="T"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(5)}
                                    onChange={props.handleChange(5)}
                                    value={5}
                                />
                            }
                            label="F"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkstate.includes(6)}
                                    onChange={props.handleChange(6)}
                                    value={6}
                                />
                            }
                            label="S"
                            disabled={props.checkAllstate}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkAllstate}
                                    onChange={props.handleCheckAllStateChange(
                                        "fullweek"
                                    )}
                                    value="fullweek"
                                />
                            }
                            label="All"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={props.tbactive}
                            onChange={(event) =>
                                props.setTbActive(event.target.checked)
                            }
                            value="active"
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    }
                    label="Active"
                />
            </Grid>
        </>
    );
};
