import { atom } from "jotai";
import { allUsersAtom, allSchedulesAtom } from "./atoms";
import moment from "moment";
import { WEEK_DAYS_ABBREVIATED } from "../utils/Constants";
import { tableDateFormat, twentyFourHourTimeToAmPm } from "../utils";
import _ from "lodash";
export const ROLE_OPTIONS = {
    ALL: "all",
    ADMINS: "admins",
    PROVIDERS: "providers"
};
export const STATUS_OPTIONS = {
    ALL: "all",
    ACTIVE: "active",
    INACTIVE: "inactive"
};
export const AVAILABILITY_OPTIONS = {
    ALL: "all",
    ACTIVE_SCHEDULE: "hasActiveSchedule",
    NO_ACTIVE_SCHEDULE: "noActiveSchedule"
};

const MIN_SEARCH_LENGTH = 3;

const PHONE_PREF_MOBILE = "MOBILE";
const PHONE_PREF_WORK = "WORK";
const PHONE_PREF_HOME = "HOME";

const tableConfigBaseAtom = atom({
    searchTerm: "",
    statusFilter: STATUS_OPTIONS.ALL,
    roleFilter: ROLE_OPTIONS.ALL,
    availabilityFilter: AVAILABILITY_OPTIONS.ALL,
    sort: { sortBy: null, direction: null }
});

export const tableConfigAtom = atom(
    (get) => get(tableConfigBaseAtom),
    (_get, set, newTableConfig) => {
        set(tableConfigBaseAtom, newTableConfig);
        set(setPageAtom, 0);
    }
);

export const paginationAtom = atom({ page: 0, perPage: 25 });
export const setPageAtom = atom(null, (_get, set, page) => {
    set(paginationAtom, (prev) => ({ ...prev, page }));
});

export const setPerPageAtom = atom(null, (_get, set, perPage) => {
    set(paginationAtom, (prev) => ({ ...prev, perPage }));
});
export const userModalTabValue = atom(0);
export const selectedUserIdAtom = atom(null);
export const selectedAvailabilityScIdAtom = atom(null);
export const openAvailabilityDialogAtom = atom(false);
export const selectedUserProviderLocationsAtom = atom(
    (get) => get(selectedProviderAtom)?.locations?.items
);
export const selectedUserServicesAtom = atom((get) =>
    _.map(get(selectedProviderAtom)?.servicetypes?.items, "servicetype")
);
export const openUserDialogAtom = atom(false);
export const userPageSnackbarAtom = atom({
    open: false,
    message: "",
    severity: "success"
});

export const formattedUsersAtom = atom([]);
export const formatUsersAtom = atom((get) => {
    const blocks = get(allSchedulesAtom);
    const users = get(allUsersAtom);
    const usersWithAvail = users?.map((user) => ({
        ...user,
        ...{
            availability: blocks?.filter(
                ({ providerId, scheduleinfo }) =>
                    providerId === user.providerId &&
                    scheduleinfo.startsWith("SC")
            )
        }
    }));
    return formatUsersHelper(usersWithAvail);
});
const formatUsersHelper = (payload) => {
    return payload.map(
        ({
            id,
            emailaddress: email,
            firstname: firstName,
            lastname: lastName,
            availability = [],
            provider,
            mobilephone = "",
            workphone = "",
            homephone = "",
            phonepref,
            active,
            role,
            LoginInfo,
            createdAt,
            tz
        }) => {
            const {
                skills: skillsFromApi,
                servicetypes,
                pictures3key,
                permalink,
                locations: { items: providerLocations = [] } = {}
            } = provider || {};
            const phones = {
                [PHONE_PREF_MOBILE]: mobilephone,
                [PHONE_PREF_WORK]: workphone,
                [PHONE_PREF_HOME]: homephone
            };
            const locationLabel = (availabilityLocation) => {
                const locationIdentifier = availabilityLocation.slice(0, 2);
                const locationId = availabilityLocation.slice(
                    3,
                    availabilityLocation.length
                );
                let label = "";
                switch (locationIdentifier) {
                    case "GL":
                        label = "Travel Zone";
                        break;
                    case "PL":
                        label =
                            providerLocations.find(
                                ({ id }) => id === locationId
                            )?.name || "Provider Location";
                        break;
                    case "CL":
                        // TODO: Change to location name when company data is in atomic state
                        label = "Company Location";
                        break;
                    default:
                        break;
                }
                return label;
            };
            const { items: skills = [] } = skillsFromApi || {}; // because of null
            const { items: services = [] } = servicetypes || {}; // because of null
            let activeSchedule = availability.find(
                ({
                    active,
                    startTime,
                    endTime,
                    startDate,
                    endDate,
                    weekDays = "",
                    tz
                }) => {
                    const now = moment();
                    const dayTimeString = `${now.hour()}:${now.minute()}`;
                    // does it have an active schedule
                    // TODO: make a tz based calculation
                    return (
                        active &&
                        moment(endDate).diff(now) > 0 &&
                        moment(startDate).diff(now) <= 0 &&
                        weekDays.indexOf(now.isoWeekday()) !== -1 &&
                        dayTimeString > startTime &&
                        dayTimeString < endTime
                    );
                }
            );
            let location, locationsParsed;
            if (activeSchedule?.locations) {
                try {
                    locationsParsed = JSON.parse(activeSchedule.locations);
                } catch {
                    console.log("Unable to parse locations");
                }
                location =
                    locationsParsed?.length > 1
                        ? `${locationsParsed.length} locations`
                        : locationsParsed?.length === 1
                          ? locationLabel(locationsParsed[0])
                          : "";
            }
            activeSchedule =
                activeSchedule !== undefined
                    ? {
                          ...activeSchedule,
                          location,
                          endDate: tableDateFormat(activeSchedule.endDate),
                          startDate: tableDateFormat(activeSchedule.startDate),
                          endTime: twentyFourHourTimeToAmPm(
                              activeSchedule.endTime
                          ),
                          startTime: twentyFourHourTimeToAmPm(
                              activeSchedule.startTime
                          ),
                          weekDays: activeSchedule.weekDays
                              .split("")
                              .sort()
                              .map((numDay) => WEEK_DAYS_ABBREVIATED[numDay])
                              .join(", ")
                      }
                    : undefined;
            let lastLoginDate = "";
            try {
                lastLoginDate = JSON.parse(LoginInfo)?.lastLoginDate;
            } catch (e) {
                console.log(e);
            }

            return {
                id,
                email,
                firstName,
                lastName,
                availability,
                skills,
                services,
                permalink,
                activeSchedule,
                phone: phones[phonepref],
                role,
                active,
                lastLoginDate,
                createdAt,
                photoUrl: pictures3key
            };
        }
    );
};

export const visibleRows = atom((get) => {
    const {
        searchTerm,
        roleFilter,
        statusFilter,
        availabilityFilter,
        sort: { sortBy, direction }
    } = get(tableConfigAtom);
    // Filtering
    let filtered = [];
    const allFormattedUsers = get(formatUsersAtom);
    filtered =
        searchTerm && searchTerm.length > MIN_SEARCH_LENGTH
            ? allFormattedUsers.filter(
                  ({ firstName = "", lastName = "", email = "" }) =>
                      firstName
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1 ||
                      lastName
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1 ||
                      email.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                          -1
              )
            : allFormattedUsers;

    filtered =
        filtered.length && roleFilter !== ROLE_OPTIONS.ALL
            ? filtered.filter(({ role }) =>
                  roleFilter === ROLE_OPTIONS.ADMINS
                      ? role === "COMPANY_ADMIN_PROVIDER"
                      : role === "PROVIDER"
              )
            : filtered;

    filtered =
        filtered.length && statusFilter !== STATUS_OPTIONS.ALL
            ? filtered.filter(({ active }) =>
                  statusFilter === "active" ? active : !active
              )
            : filtered;
    filtered =
        filtered.length && availabilityFilter !== AVAILABILITY_OPTIONS.ALL
            ? filtered.filter(({ activeSchedule }) =>
                  availabilityFilter === AVAILABILITY_OPTIONS.ACTIVE_SCHEDULE
                      ? activeSchedule
                      : !activeSchedule
              )
            : filtered;
    //Ordering
    let sorted = [];
    switch (sortBy) {
        // All the columns that can be directly compared
        case "role":
        case "active": // type corrosion is bad but so convenient (true > false === true)
        case "services":
        case "skills":
            sorted = filtered.sort(
                (
                    { [sortBy]: directlyComparableValueA },
                    { [sortBy]: directlyComparableValueB }
                ) =>
                    direction === "ASC"
                        ? directlyComparableValueA >= directlyComparableValueB
                            ? 1
                            : -1
                        : directlyComparableValueA <= directlyComparableValueB
                          ? 1
                          : -1
            );
            break;
        // Date comparison columns
        case "createdAt":
        case "lastLoginDate":
            sorted = filtered.sort(
                (
                    { [sortBy]: dateStringValueA },
                    { [sortBy]: dateStringValueB }
                ) => {
                    const dateDiff = moment(dateStringValueA).diff(
                        moment(dateStringValueB)
                    );
                    return direction === "ASC"
                        ? dateDiff > 0
                            ? 1
                            : -1
                        : dateDiff < 0
                          ? 1
                          : -1;
                }
            );
            break;
        case "user":
            sorted = filtered.sort(
                (
                    { firstName: firstNameA, lastName: lastNameA },
                    { firstName: firstNameB, lastName: lastNameB }
                ) =>
                    direction === "ASC"
                        ? `${firstNameA} ${lastNameA}` >
                          `${firstNameB} ${lastNameB}`
                            ? 1
                            : -1
                        : `${firstNameA} ${lastNameA}` <
                            `${firstNameB} ${lastNameB}`
                          ? 1
                          : -1
            );
            break;
        // has activeSchedule > no activeSchedule, if both unavailable sort by number of schedules
        case "availability":
            sorted = filtered.sort(
                (
                    {
                        availability: availabilityA,
                        activeSchedule: activeScheduleA
                    },
                    {
                        availability: availabilityB,
                        activeSchedule: activeScheduleB
                    }
                ) =>
                    direction === "ASC"
                        ? activeScheduleA === undefined &&
                          activeScheduleB === undefined
                            ? availabilityA > availabilityB
                                ? 1
                                : -1
                            : activeScheduleA !== undefined
                              ? 1
                              : -1
                        : activeScheduleA === undefined &&
                            activeScheduleB === undefined
                          ? availabilityA > availabilityB
                              ? -1
                              : 1
                          : activeScheduleA !== undefined
                            ? -1
                            : 1
            );
            break;
        default:
            sorted = filtered;
    }
    return sorted;
});
export const visibleRowsPaginated = atom((get) => {
    const { page, perPage } = get(paginationAtom);
    const visible = get(visibleRows);
    return visible.slice(perPage * page, perPage * (page + 1));
});
export const selectedUserAtom = atom((get) => {
    const allUsers = get(allUsersAtom);
    const selectedUserId = get(selectedUserIdAtom);
    if (selectedUserId === null) {
        return null;
    }
    return allUsers.find((user) => user.id === selectedUserId);
});
export const selectedProviderAtom = atom(
    (get) => get(selectedUserAtom)?.provider
);
export const selectedProviderIdAtom = atom(
    (get) => get(selectedUserAtom)?.providerId
);

export const selectedUserSchedulesAtom = atom((get) => {
    const allUsersSchedules = get(allSchedulesAtom);
    const selectedUser = get(selectedUserAtom);

    if (!selectedUser) {
        return null;
    }

    const userSchedules = allUsersSchedules.filter(
        (schedule) => schedule.providerId === selectedUser?.provider?.id
    );
    //Filter SC records and initialize them with empty arrays for SB, SL, SR
    const scheduleMap = userSchedules
        .filter((schedule) => schedule.scheduleinfo.startsWith("SC|"))
        .reduce((acc, schedule) => {
            acc[schedule.scheduleinfo] = {
                ...schedule,
                SB: [],
                SL: [],
                SR: []
            };
            return acc;
        }, {});

    //Attach SB, SL, SR records to their corresponding SC records
    userSchedules.forEach((schedule) => {
        const { scheduleinfo } = schedule;
        const parts = scheduleinfo.split("|");
        const type = parts[0];
        const scIndex = parts.indexOf("SC");
        if (scIndex !== -1) {
            const scId = `SC|${parts[scIndex + 1]}`;
            if (["SB", "SL", "SR"].includes(type)) {
                if (scheduleMap[scId]) {
                    scheduleMap[scId][type].push(schedule);
                } else {
                    //initialize the map if it doesn't exist
                    scheduleMap[scId] = { [type]: [schedule] };
                }
            }
        }
    });

    //Convert the map values to an array of SC records with their associated SB, SL, SR records
    const organizedSchedules = Object.values(scheduleMap);
    return organizedSchedules;
});

export const selectedScheduleAtom = atom((get) => {
    const selectedUserSchedules = get(selectedUserSchedulesAtom);
    const selectedAvailabilityScId = get(selectedAvailabilityScIdAtom);
    const selectedProviderLocations = get(selectedUserProviderLocationsAtom);

    if (!selectedUserSchedules || !selectedAvailabilityScId) {
        return null;
    }
    let selectedSchedule =
        selectedUserSchedules.find(
            (schedule) => schedule.scheduleinfo === selectedAvailabilityScId
        ) || null;

    if (selectedSchedule && selectedProviderLocations) {
        const scheduleTravelZone = selectedProviderLocations.find(
            (providerLocation) => {
                const providerLocationId = providerLocation.id;
                return selectedSchedule.SL.some((record) =>
                    record.scheduleinfo.includes(`PL-${providerLocationId}`)
                );
            }
        );
        selectedSchedule = {
            ...selectedSchedule,
            travelZone: scheduleTravelZone
        };
    }
    return selectedSchedule;
});
