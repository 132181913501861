import React, { useContext, useState } from "react";
import { ConsoleLogger } from "aws-amplify/utils";
import { Cache } from "aws-amplify/utils";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Typography, Grid, Box, Button, RadioGroup, Radio, FormControl, FormControlLabel } from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";
//import ColorPicker from "material-ui-color-picker";
//import { createMuiTheme } from "@mui/material/styles";

const styles = (theme) => ({
    root: {
        display: "flex"
    },
    formControl: {
        margin: theme.spacing.unit * 3
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    }
});

function ColorForm(props) {
    const classes = useStyles();
    const { state, dispatch, actions } = useContext(StoreContext);
    const [color, setColor] = useState();

    const logger = new ConsoleLogger("ColorForm");

    function handleClick() {
        logger.debug("Color set to " + color);
        setColor(color);
        Cache.setItem("primarycolor", color);
        // force reload of color
        window.location.reload();
    }

    function handleCancel() {
        // cancel and go back to providerform
        actions.setPage("AdminForm");
    }

    return (
        <>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Typography className={classes.title} variant="h4" noWrap>
                        Company Color Theme
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Typography variant="body1" noWrap>
                        Please select a primary color for the company theme:
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <FormControl
                        component="fieldset"
                        className={classes.formControl}
                    >
                        <RadioGroup
                            aria-label="Color"
                            name="color"
                            className={classes.group}
                            value={color}
                            onChange={(event) => setColor(event.target.value)}
                        >
                            <FormControlLabel
                                value="primary"
                                control={<Radio />}
                                label="primary"
                            />
                            <FormControlLabel
                                value="blue"
                                control={<Radio />}
                                label="blue"
                            />
                            <FormControlLabel
                                value="brown"
                                control={<Radio />}
                                label="brown"
                            />
                            <FormControlLabel
                                value="cyan"
                                control={<Radio />}
                                label="cyan"
                            />
                            <FormControlLabel
                                value="orange"
                                control={<Radio />}
                                label="orange"
                            />
                            <FormControlLabel
                                value="pink"
                                control={<Radio />}
                                label="pink"
                            />
                            <FormControlLabel
                                value="purple"
                                control={<Radio />}
                                label="purple"
                            />
                            <FormControlLabel
                                value="red"
                                control={<Radio />}
                                label="red"
                            />
                            <FormControlLabel
                                value="teal"
                                control={<Radio />}
                                label="teal"
                            />
                            <FormControlLabel
                                value="yellow"
                                control={<Radio />}
                                label="yellow"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        Save
                    </Button>
                    <Box component="span" m={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ColorForm;
