import React, { useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, IconButton, Tooltip, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ReminderIcon from "@mui/icons-material/AccessAlarm";
import EditIcon from "@mui/icons-material/Create";
import { subscriptionLevelConst } from "../utils/Constants";

import { _formatDate } from "../utils";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    },
    pointer: {
        cursor: "pointer"
    },
    actionsCellWidth: {
        width: "250px"
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};
export default function CompaniesTable({
    companiList,
    fetchPromotionList,
    selectedRow,
    tabValue
}) {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [promotionId, setPromotionId] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [rows, setRows] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    const [deleteDialog, setDeleteDialog] = React.useState(false);

    const [endDialog, setEndDialog] = React.useState(false);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    useEffect(() => {
        setPage(0);
    }, [companiList]);

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = companiList.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }
    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    //function handleDeleteProviders(id, event) {
    //  setPromotionId(id);
    //  setDeleteDialog(true);
    //}

    function handleEndDialogDecline() {
        setEndDialog(false);
    }
    function handleCompanyEditClick(event, id) {
        actions.setMode("Edit");
        actions.setPage("CompanyAddEditForm");
        actions.setId(id);
    }

    function handleViewCompany(event, id) {
        actions.setMode("View");
        actions.setPage("CompanyAddEditForm");
        actions.setId(id);
    }

    function handleReminders(event, id) {
        actions.setMode("Edit");
        actions.setPage("CompanyReminderForm");
        actions.setId(id);
        localStorage.setItem("companyId", id);
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    // const companiList = props

    const subscriptionLevelHandler = (level) => {
        subscriptionLevelConst.filter((item) =>
            item.value === level ? item.label : ""
        );
    };
    return (
        <>
            <div className={classes.root}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectAll}
                                    onClick={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Contact name</TableCell>
                            <TableCell align="left">Email Address</TableCell>
                            {/*<TableCell align="left">Address</TableCell>*/}
                            <TableCell align="left">
                                Subscription Level
                            </TableCell>
                            <TableCell align="left">Date Created</TableCell>

                            <TableCell
                                align="left"
                                className={classes.actionsCellWidth}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companiList &&
                            companiList.length > 0 &&
                            companiList
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map(
                                    (
                                        {
                                            name,
                                            contactname,
                                            emailaddress,
                                            id,
                                            addressoneline,
                                            subscriptionLevel,
                                            createdAt
                                        },
                                        i
                                    ) => {
                                        return (
                                            <TableRow
                                                key={id}
                                                hover
                                                onClick={(event) =>
                                                    handleRowClick(event, id)
                                                }
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={
                                                            selected.indexOf(
                                                                id
                                                            ) !== -1 ||
                                                            selectAll
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                        maxWidth: "162px",
                                                        whiteSpace: "normal",
                                                        wordWrap: "break-word"
                                                    }}
                                                >
                                                    {name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {contactname}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {emailaddress}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textTransform:
                                                            "capitalize"
                                                    }}
                                                    align="left"
                                                >
                                                    {subscriptionLevel
                                                        ? subscriptionLevelConst.find(
                                                              (sub) =>
                                                                  sub.value ===
                                                                  subscriptionLevel
                                                          ).label
                                                        : ""}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {_formatDate(createdAt)}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Tooltip title="Edit">
                                                        <EditIcon
                                                            onClick={(event) =>
                                                                handleCompanyEditClick(
                                                                    event,
                                                                    id
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Reminders">
                                                        <ReminderIcon
                                                            aria-label="Reminders"
                                                            onClick={(event) =>
                                                                handleReminders(
                                                                    event,
                                                                    id
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={companiList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "Rows per page"
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </>
    );
}
