import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import ServiceAdd from "./ServiceAdd";

const setupService = document.getElementById("setup-service");

function ServiceAddModal(props) {
    /* useEffect(() => {
        // console.log("Provider Id in Service ADD Modal", props.providerInfo);
    }, [props.providerInfo]); */
    const smallerScreen = useMediaQuery("(max-width:600px)");
    return ReactDOM.createPortal(
        <Dialog
            sx={{ padding: "1.5rem" }}
            open={props.open}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
                style: {
                    maxWidth: !smallerScreen ? "42%" : "100%",
                    maxHeight: "100%"
                }
            }}
        >
            <DialogContent>
                <ServiceAdd
                    originPath={props.originPath}
                    close={props.close}
                    companyName={props.companyName}
                    doneTodoHandler={props.doneTodoHandler}
                />
            </DialogContent>
        </Dialog>,
        setupService
    );
}

export default ServiceAddModal;
