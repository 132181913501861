import React, { Fragment } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UsersCounter from "../custom/UsersCounter.js";
import BillingPeriodSelector from "../custom/BillingPeriodSelector.js";
import SubscriptionPlanSelector from "./SubscriptionPlanSelector.js";

/* styles used in OrderSummaryCalculator */
const useStyles = makeStyles((theme) => ({
    selector_rows: {
        width: "100%",
        padding: "0px 42px",
        [theme.breakpoints.down("sm")]: {
            padding: "0px 20px"
        },
        flexDirection: "column"
    },
    selector_rows_mob: {
        width: "100%",
        padding: "0px 20px",
        flexDirection: "column"
    },
    heading_row: {
        alignItems: "center",
        justifyContent: "space-between"
    },
    subheading_row: {
        alignItems: "center",
        justifyContent: "space-between"
    },
    heading_text: {
        fontSize: "20px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px"
        },
        color: "rgb(0,0,0,0.87)",
        fontFamily: "Poppins, sans-serif"
    },
    heading_text_mob: {
        fontSize: "16px",
        color: "rgb(0,0,0,0.87)",
        fontFamily: "Poppins, sans-serif"
    },
    subheading_text: {
        fontSize: "14px",
        color: "rgb(0,0,0,0.87)",
        fontFamily: "Poppins, sans-serif"
    },
    subheading_text2: {
        fontSize: "18px",
        color: "rgb(0,0,0,0.87)",
        fontFamily: "Poppins, sans-serif"
    },
    divider: {
        margin: "18px 0px"
    }
}));

const OrderSummaryCalculator = (props) => {
    const basicPrice = 39;
    const professionalPrice = 49;

    const classes = useStyles();

    return (
        <Fragment>
            {/*OrderSummaryCalculator Grid*/}
            <Grid container className={classes.selector_rows}>
                {props.reactivate && (
                    <Grid container className={classes.heading_row}>
                        <Typography className={classes.heading_text}>
                            Subscription plan
                        </Typography>
                        <SubscriptionPlanSelector
                            onSubscriptionPlanUpdate={
                                props.onSubscriptionPlanUpdate
                            }
                            width={props.width}
                        />
                    </Grid>
                )}
                <Grid container className={classes.heading_row}>
                    <Typography className={classes.heading_text}>
                        Number of users
                    </Typography>
                    <UsersCounter
                        onQuantityUpdate={props.onQuantityUpdate}
                        width={props.width}
                    />
                </Grid>
                <Grid container className={classes.heading_row}>
                    <Typography className={classes.heading_text}>
                        Billing period
                    </Typography>
                    <BillingPeriodSelector
                        onPlanUpdate={props.onPlanUpdate}
                        monthlyLookupKey={props.monthlyLookupKey}
                        annualLookupKey={props.annualLookupKey}
                        plan={props.plan}
                        reactivate={props.reactivate ? true : false}
                        width={props.width}
                    />
                </Grid>

                <Divider className={classes.divider} />

                <Grid container className={classes.subheading_row}>
                    <Grid
                        container
                        style={{ width: props.width > 450 ? "40%" : "50% " }}
                    >
                        <Typography
                            className={classes.subheading_text}
                            style={{ marginRight: "8px" }}
                        >
                            Number of users:
                        </Typography>
                        <Typography className={classes.subheading_text}>
                            {props.quantity}
                        </Typography>
                    </Grid>
                    {props.planType === "professional" ? (
                        <Typography className={classes.subheading_text}>
                            {props.plan.includes("monthly")
                                ? `$${(
                                      professionalPrice * props.quantity
                                  ).toFixed(2)}`
                                : `$${(
                                      professionalPrice *
                                      props.quantity *
                                      12
                                  ).toFixed(2)}`}
                        </Typography>
                    ) : (
                        <Typography className={classes.subheading_text}>
                            {props.plan.includes("monthly")
                                ? `$${(basicPrice * props.quantity).toFixed(2)}`
                                : `$${(
                                      basicPrice *
                                      props.quantity *
                                      12
                                  ).toFixed(2)}`}
                        </Typography>
                    )}
                </Grid>

                {props.plan.includes("annual") && (
                    <Grid container className={classes.subheading_row}>
                        <Typography className={classes.subheading_text}>
                            Discount on annual
                        </Typography>
                        <Typography className={classes.subheading_text}>
                            {props.planType == "basic"
                                ? `$-${(basicPrice * props.quantity).toFixed(
                                      2
                                  )}`
                                : `$-${(
                                      professionalPrice * props.quantity
                                  ).toFixed(2)}`}
                        </Typography>
                    </Grid>
                )}

                <Divider className={classes.divider} />

                <Grid container className={classes.subheading_row}>
                    <Typography
                        className={
                            props.width > 450
                                ? classes.heading_text
                                : classes.heading_text_mob
                        }
                    >
                        Total
                    </Typography>
                    {props.planType === "professional" ? (
                        <Typography className={classes.subheading_text2}>
                            {props.plan.includes("monthly")
                                ? `$${(
                                      professionalPrice * props.quantity
                                  ).toFixed(2)} / per month`
                                : `$${(
                                      professionalPrice * props.quantity * 12 -
                                      professionalPrice * props.quantity
                                  ).toFixed(2)} / per year`}
                        </Typography>
                    ) : (
                        <Typography className={classes.subheading_text2}>
                            {props.plan.includes("monthly")
                                ? `$${(basicPrice * props.quantity).toFixed(
                                      2
                                  )} / per month`
                                : `$${(
                                      basicPrice * props.quantity * 12 -
                                      basicPrice * props.quantity
                                  ).toFixed(2)} / per year`}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default OrderSummaryCalculator;
