import React, { useState } from "react";
import { getUserFromCache } from "../../user/UserCommon";
import { graphql, graphqlOperation } from "../../modules/AmplifyServices";
import { usersForExport } from "../../queries/UserQueries";
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Backdrop
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { CSVLink } from "react-csv";
import { GetApp } from "@mui/icons-material";

const limit = process.env.REACT_APP_LISTLIMIT;

const csvHeaders = [
    { label: "ID", key: "ID" },
    { label: "Created At", key: "CREATEDAT" },
    { label: "Registered", key: "REGISTERED" },
    { label: "Email", key: "EMAIL" },
    { label: "First Name", key: "FIRSTNAME" },
    { label: "Last Name", key: "LASTNAME" },
    { label: "Home Phone", key: "HOMEPHONE" },
    { label: "Work Phone", key: "WORKPHONE" },
    { label: "Mobile Phone", key: "MOBILEPHONE" },
    { label: "Phone Preference", key: "PHONEPREFERENCE" },
    { label: "Address", key: "ADDRESS" },
    { label: "City", key: "CITY" },
    { label: "State", key: "STATE" },
    { label: "Country", key: "COUNTRY" },
    { label: "Postal Code", key: "POSTALCODE" },
    { label: "Status", key: "STATUS" },
    { label: "Role", key: "ROLE" },
    { label: "Contact Me Opt-in", key: "CONTACTCONSENT" },
    { label: "Last Login Date", key: "LASTLOGINDATE" },
    { label: "Login Count", key: "LOGINCOUNT" }
];

function UserExport(props) {
    const [selectedRole, setSelectedRole] = useState("ALL");
    const [readyToExport, setReadyToExport] = useState(false);
    const [exportData, setExportData] = useState([]);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const resetStates = () => {
        setReadyToExport(false);
        setExportData([]);
        setError(false);
        setErrorMsg("");
    };

    const roleChangeHandler = (e) => {
        resetStates();
        const { value } = e.target;
        console.log("Selected Role", value);

        setSelectedRole(value);
    };

    const getAllRoles = async (user) => {
        let nextToken = null;
        try {
            let response = await graphql(
                graphqlOperation(usersForExport, {
                    companyId: user.company.id,
                    filter:
                        user.role !== "MARKETBOX_ADMIN"
                            ? { role: { ne: "MARKETBOX_ADMIN" } }
                            : null,

                    limit,
                    nextToken
                })
            );
            if (response && response?.data?.userByCompany?.items?.length > 0) {
                console.log("getAllRoles response", response);
                computeDataForCSV(response.data.userByCompany.items);
            } else {
                console.log("No reponse from getAllRoles");
                setLoading(false);
                setError(true);
                setErrorMsg("No data exists for the selected role");
            }
        } catch (error) {
            console.log("Error in exporting users by role getAllRoles", error);
        }
    };

    const getSelectedRole = async (user) => {
        let nextToken = null;

        let filters;

        if (selectedRole === "PROVIDER") {
            filters = [
                { role: { eq: selectedRole } },
                { role: { eq: "COMPANY_ADMIN_PROVIDER" } }
            ];
        }

        if (selectedRole === "COMPANY_ADMIN") {
            filters = [
                { role: { eq: selectedRole } },
                { role: { eq: "COMPANY_ADMIN_PROVIDER" } }
            ];
        }

        if (selectedRole === "CLIENT") {
            filters = [{ role: { eq: "CLIENT" } }];
        }

        try {
            let response = await graphql(
                graphqlOperation(usersForExport, {
                    companyId: user.company.id,
                    filter: {
                        or: filters
                    },
                    limit,
                    nextToken
                })
            );
            if (response && response?.data?.userByCompany?.items?.length > 0) {
                console.log("getSelectedRole response", response);
                computeDataForCSV(response.data.userByCompany.items);
            } else {
                console.log("No reponse from getSelectedRole");
                setLoading(false);
                setError(true);
                setErrorMsg("No data exists for the selected role");
            }
        } catch (error) {
            console.log(
                "Error in exporting users by role getSelectedRole",
                error
            );
        }
    };

    const exportByRole = async () => {
        setLoading(true);
        const user = await getUserFromCache();
        if (user && user.company) {
            if (selectedRole === "ALL") {
                await getAllRoles(user);
            } else {
                await getSelectedRole(user);
            }
        } else {
            setLoading(false);
            console.log("Failed to fetch the user information exportByRole");
        }
    };

    const computeDataForCSV = (data) => {
        let rows = [];
        if (data && data.length > 0) {
            data.map((item) => {
                item.email = item.emailaddress ? item.emailaddress : "";
                item.registeredStatus = item.registered ? "TRUE" : "FALSE";
                item.fn = item.firstname ? item.firstname : "";
                item.ln = item.lastname ? item.lastname : "";
                item.hphone = item.homephone ? item.homephone : "";
                item.wphone = item.workphone ? item.workphone : "";
                item.mphone = item.mobilephone ? item.mobilephone : "";
                item.phonepreference = item.phonepref ? item.phonepref : "";
                item.address = item.addressoneline ? item.addressoneline : "";
                item.city = item.city ? item.city : "";
                item.state = item.state ? item.state : "";
                item.country = item.country ? item.country : "";
                item.postalcode = item.postalcode ? item.postalcode : "";
                item.status =
                    (item.active && item.LoginInfo) || item.registered === false
                        ? "ACTIVE"
                        : !item.active
                          ? "INACTIVE"
                          : "PENDING";
                item.roleValue = item.role ? item.role : "";
                item.createdAtValue = moment(item.createdAt).format(
                    "YYYY-MM-DD hh:mm A"
                );
                rows.push(item);
            });
        }

        const csvData = rows.map((item) => {
            const loginInfo = JSON.parse(item.LoginInfo);

            const {
                id: ID,
                createdAtValue: CREATEDAT,
                registeredStatus: REGISTERED,
                fn: FIRSTNAME,
                ln: LASTNAME,
                email: EMAIL,
                hphone: HOMEPHONE,
                wphone: WORKPHONE,
                mphone: MOBILEPHONE,
                phonepreference: PHONEPREFERENCE,
                address: ADDRESS,
                city: CITY,
                state: STATE,
                country: COUNTRY,
                postalcode: POSTALCODE,
                status: STATUS,
                roleValue: ROLE,
                contactconsent: CONTACTCONSENT
            } = item;
            const newItem = {
                ID,
                CREATEDAT,
                REGISTERED,
                EMAIL,
                FIRSTNAME,
                LASTNAME,
                HOMEPHONE,
                WORKPHONE,
                MOBILEPHONE,
                PHONEPREFERENCE,
                ADDRESS,
                CITY,
                STATE,
                COUNTRY,
                POSTALCODE,
                STATUS,
                ROLE,
                CONTACTCONSENT,
                LASTLOGINDATE: loginInfo?.lastLoginDate,
                LOGINCOUNT: loginInfo?.loginCount
            };
            return newItem;
        });

        if (csvData && csvData.length) {
            setExportData(csvData);
            setReadyToExport(true);
            setLoading(false);
        }
    };

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Backdrop open={loading} />
            <Box display={"flex"} gap={2} width={"500px"} alignItems={"center"}>
                <FormControl sx={{ maxWidth: "250px", minWidth: "250px" }}>
                    <InputLabel id="export-role">Select role</InputLabel>
                    <Select
                        labelId="export-role"
                        id="export-role"
                        value={selectedRole}
                        label="Select role"
                        onChange={roleChangeHandler}
                        disabled={loading}
                    >
                        <MenuItem value={"ALL"}>All</MenuItem>
                        <MenuItem value={"CLIENT"}>Client</MenuItem>
                        <MenuItem value={"PROVIDER"}>Provider</MenuItem>
                        <MenuItem value={"COMPANY_ADMIN"}>Admin</MenuItem>
                        {/* <MenuItem value={"COMPANY_ADMIN_PROVIDER"}>
                            Company admin provider
                        </MenuItem> */}
                    </Select>
                </FormControl>
                {!readyToExport && exportData.length <= 0 ? (
                    <Box>
                        <Button
                            sx={{
                                maxWidth: "200px",
                                minWidth: "200px",
                                textTransform: "capitalize"
                            }}
                            variant="contained"
                            color="primary"
                            onClick={exportByRole}
                            disabled={loading}
                        >
                            Prepare
                        </Button>
                    </Box>
                ) : (
                    <CSVLink
                        data={exportData}
                        headers={csvHeaders}
                        filename={`users_${moment(Date.now()).format(
                            "YYYY-MM-DD"
                        )}.csv`}
                    >
                        <Button
                            sx={{
                                maxWidth: "200px",
                                minWidth: "200px",
                                textTransform: "capitalize"
                            }}
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            startIcon={
                                <GetApp
                                    style={{
                                        margin: "0.2rem",
                                        fontSize: "1.2rem"
                                    }}
                                />
                            }
                        >
                            Export CSV
                        </Button>
                    </CSVLink>
                )}
            </Box>
            {error && (
                <Typography color={"error.main"} variant={"caption"}>
                    {errorMsg}
                </Typography>
            )}
        </Box>
    );
}

export default UserExport;
