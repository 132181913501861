import React, { Fragment, useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import bulletone from "../../assets/bullet-one.svg";
import bullettwo from "../../assets/bullet-two.svg";
import bulletthree from "../../assets/bullet-three.svg";
import { makeStyles } from "@mui/styles";

/* styles used in left panel */
const useStyles = makeStyles({
    panel: {
        width: "55%",
        padding: "24px 34px 0px 64px",
        display: "flex",
        flexDirection: "column",
        gap: "26px",
        "& *": {
            fontFamily: "Poppins, sans-serif"
        }
    },
    panel_mobile: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "26px",
        alignItems: "center",
        //background: "#0087eec4",
        padding: "18px 0px",
        "& *": {
            fontFamily: "Poppins, sans-serif"
        }
    },
    heading: {
        fontSize: "38px",

        fontWeight: "bold",
        color: "#134A55",
        lineHeight: "48px"
    },
    subheading: {
        color: "#134A55",

        fontSize: "18px"
    },
    subheading_mobile: {
        //color: "white",

        fontSize: "18px"
    },
    bullet: {
        flexWrap: "nowrap",
        alignItems: "center"
    },
    bullet_mobile: {
        flexWrap: "nowrap",
        alignItems: "center",
        margin: "0px 28px",
        gap: "10px",
        width: "80%",
        justifyContent: "flex-start"
    },
    bulletimg: {
        height: "77px",
        maxWidth: "90px",
        paddingRight: "24px"
    },
    bulletimg_mobile: {
        height: "40px",
        paddingRight: "12px"
    },
    bullettxt: {
        color: "#134A55",

        fontSize: "16px",
        paddingRight: "24px"
    },
    bullettxt_mobile: {
        //color: "white",

        fontSize: "13px",
        paddingRight: "24px"
    }
});

const LeftPanel = (props) => {
    console.log("LeftPanel Loaded");

    const classes = useStyles();

    useEffect(() => {
        console.log("This is the width from leftPanel->", props.width);
    }, [props.width]);

    return (
        <Fragment>
            {/*Left Panel Grid*/}
            <Grid
                item
                className={
                    props.width > 900 ? classes.panel : classes.panel_mobile
                }
            >
                {/* Heading text Grid */}
                <Grid
                    container
                    style={{
                        gap: "26px",
                        width: props.width < 900 ? "80%" : "",
                        justifyContent: props.width < 900 ? "center" : ""
                    }}
                >
                    {props.width > 900 &&
                        (props.freeTrial ? (
                            <Typography className={classes.heading}>
                                The #1 scheduling software for managing in-home
                                and mobile appointments
                            </Typography>
                        ) : (
                            <Typography className={classes.heading}>
                                Scheduling software designed for managing
                                in-home & mobile appointments
                            </Typography>
                        ))}

                    {props.freeTrial ? (
                        <Typography
                            className={
                                props.width < 900
                                    ? classes.subheading_mobile
                                    : classes.subdheading
                            }
                        >
                            Discover everything MarketBox has to offer, free for
                            10 days.
                        </Typography>
                    ) : (
                        <Typography
                            className={
                                props.width < 900
                                    ? classes.subheading_mobile
                                    : classes.subdheading
                            }
                        >
                            Join the growing list of businesses being powered by
                            MarketBox
                        </Typography>
                    )}
                </Grid>
                {/* Bullet list Grid */}
                <Grid
                    container
                    style={{
                        gap: "16px",
                        justifyContent: props.width < 900 ? "center" : ""
                    }}
                >
                    <Grid
                        container
                        className={
                            props.width < 900
                                ? classes.bullet_mobile
                                : classes.bullet
                        }
                    >
                        {/* {props.width < 900 ? (
                            <CheckOutlinedIcon
                                className={classes.bulletimg_mobile}
                            />
                        ) : ( */}
                        <img
                            src={bulletone}
                            className={
                                props.width < 900
                                    ? classes.bulletimg_mobile
                                    : classes.bulletimg
                            }
                            alt="MarketBox Onboarding"
                        />

                        {props.width < 900 ? (
                            <Typography className={classes.bullettxt_mobile}>
                                Capture bookings online 24/7
                            </Typography>
                        ) : (
                            <Typography className={classes.bullettxt}>
                                Let clients book and pay for appointments online
                                and capture sales 24 hours a day
                            </Typography>
                        )}
                    </Grid>

                    <Grid
                        container
                        className={
                            props.width < 900
                                ? classes.bullet_mobile
                                : classes.bullet
                        }
                    >
                        {/* {props.width < 900 ? (
                            <CheckOutlinedIcon
                                className={classes.bulletimg_mobile}
                            />
                        ) : ( */}
                        <img
                            src={bullettwo}
                            className={
                                props.width < 900
                                    ? classes.bulletimg_mobile
                                    : classes.bulletimg
                            }
                            alt="MarketBox Onboarding"
                        />

                        {props.width < 900 ? (
                            <Typography className={classes.bullettxt_mobile}>
                                Coordinate provider schedules
                            </Typography>
                        ) : (
                            <Typography className={classes.bullettxt}>
                                Coordinate provider schedules, minimize travel
                                time, and take control of when and where your
                                team operates
                            </Typography>
                        )}
                    </Grid>

                    <Grid
                        container
                        className={
                            props.width < 900
                                ? classes.bullet_mobile
                                : classes.bullet
                        }
                    >
                        {/* {props.width < 900 ? (
                            <CheckOutlinedIcon
                                className={classes.bulletimg_mobile}
                            />
                        ) : ( */}
                        <img
                            src={bulletthree}
                            className={
                                props.width < 900
                                    ? classes.bulletimg_mobile
                                    : classes.bulletimg
                            }
                            alt="MarketBox Onboarding"
                        />

                        {props.width < 900 ? (
                            <Typography className={classes.bullettxt_mobile}>
                                Process payments & automate admin
                            </Typography>
                        ) : (
                            <Typography className={classes.bullettxt}>
                                Use integrations to simplify payroll, automate
                                admin, streamline marketing, and more without
                                adding to your to-do list
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default LeftPanel;
