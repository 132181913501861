import React, { useState, useEffect } from "react";
// prettier-ignore
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl, TextField, Typography, FormControlLabel, Snackbar, RadioGroup, Radio, InputLabel, Select, MenuItem, Alert, Switch, InputAdornment, IconButton, Tooltip, Link } from "@mui/material";
import { ClientSelector } from "../components/ClientSelector";
import { ServiceSelector } from "../components/ServiceSelector";
import { LocationSelector } from "../components/LocationSelector";
import { MoneyAmountInput } from "../components/MoneyAmountInput";
import StripeChargeCardContainer from "../components/stripe/StripeChargeCardContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ClientAddressInput } from "../components/ClientAddressInput";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
// prettier-ignore
import {
    getPrice, getCurrency, totalRegionalPrice, createOsd, getPaidPackages,
    completeBookingWithoutCharge,
    createNewUiSession,
    getCurrencySymbol
} from "../modules/AdhocBookingService";
import { getBookingDateDescription } from "../modules/TimeService";
import { getTimezoneIdByGeoLoc } from "../modules/TimeService";
import {
    getScheduleInfo,
    getScheduleTimeblockId,
    SCHEDPKSKSPLITAT
} from "../modules/ScheduleService";
import { _getSchedules } from "../modules/ScheduleService";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { locationByProvider } from "../queries/AdhocBookingQueries";
import { createProviderLocation } from "../graphql/mutations";
import { checkCardExpiration } from "../billing/BillingCommon";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserFromCache } from "../user/UserCommon";
import {
    LocalizationProvider,
    MobileDateTimePicker
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import {
    getMasterTimezone,
    timezoneConversion
} from "../utils/CalendarFunctions/timezoneFunctions";
import RecurringBookingSelector from "../components/RecurringBookingSelector";
import moment from "moment";
import {
    manageFilteredLocationsByService,
    manageFilteredServicesByLocation
} from "../modules/FilterServicesAndLocations";
import { getTravelTimeValidity } from "../modules/ScheduleService";

import SuggestedTimesDialog from "./SuggestedTimesDialog";
import isFeatureGated, {
    isOpenForClusters
} from "../modules/FeatureGatingModule";
import { FEATURE_NAME_CLUSTERING } from "../utils/Constants";
import { getCurrentTimeAdjustedToNextHour } from "../utils/SchedulerFunctions/DateNavigatorFormatter";
import { isAddressInTravelZone } from "../utils/CalendarFunctions/geoFunctions";
const autocompleteService = { current: null };
const payTypeStyling = (str, tp) => {
    if (str === tp) {
        return {
            backgroundColor: "primary.light",
            borderColor: "primary.dark",
            borderWidth: "1px",
            width: "100%",
            borderStyle: "solid",
            borderRadius: "8px",
            padding: "0.5rem 1rem",
            marginBottom: "1rem"
        };
    }
    return {
        backgroundColor: "white",
        borderColor: "rgba(0,0,0,0.23)",
        borderWidth: "1px",
        width: "100%",
        borderStyle: "solid",
        borderRadius: "8px",
        padding: "0.5rem 1rem",
        marginBottom: "1rem"
    };
};

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

function AdhocBookingFlow(props) {
    const theme = useTheme();
    const classesnew = usePromotionsStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [bookingState, setBookingState] = useState({
        numServices: 1,
        serviceQty1: 1,
        serviceType1: "",
        serviceType: "",
        daysOfWeek: [], //default-not used
        dayCount: 1, //default-not used
        dayType: "weeks", //default-not used
        repeatingAppointment: false, //default-not used
        repeatingApptList: [], //default-not used,
        manualBooking: true
    });
    const daysAbbr = [
        { id: 0, title: "MO" },
        { id: 1, title: "TU" },
        { id: 2, title: "WE" },
        { id: 3, title: "TH" },
        { id: 4, title: "FR" },
        { id: 5, title: "SA" },
        { id: 6, title: "SU" }
    ];
    const [client, setClient] = useState(null);
    const [serviceType, setServiceType] = useState(null);
    const [serviceTypes, setServiceTypes] = useState(null);
    const [companyLocations, setCompanyLocations] = useState(null);
    const [showAddr, setShowAddr] = useState(false);
    const [address, setAddress] = useState();
    const [place_id, setPlaceId] = useState();
    const [location, setLocation] = useState();
    const [locTzDate, setLocTzDate] = useState(
        moment().format("MM/DD/YYYY, h:mm A")
    );
    const [showLocDate, setShowLocDate] = useState(false);
    const [clientNotes, setClientNotes] = useState("");
    const [selectedSlot, setSelectedSlot] = useState();
    const [prepareBookingData, setPrepareBookingData] = useState(false);
    const [showStipeForm, setShowStripeForm] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [bookingCompleted, setBookingCompleted] = useState({
        success: false
    });
    const [bookZeroCostServiceClicked, setBookZeroCostServiceClicked] =
        useState(false);
    const [servicePrice, setServicePrice] = useState();
    const [serviceFee, setServiceFee] = useState();
    const [currencyPrefix, setCurrencyPrefix] = useState();
    const [cbuTotal, setCbuTotal] = useState(0);
    const [rrule, setRrule] = useState(
        props?.clickedCellProps?.startDate
            ? `FREQ=WEEKLY;INTERVAL=1;BYDAY=${
                  daysAbbr[(props.clickedCellProps.startDate.getDay() + 6) % 7]
                      .title
              }`
            : `FREQ=WEEKLY;INTERVAL=1;BYDAY=${
                  daysAbbr[(new Date().getDay() + 6) % 7].title
              }`
    );
    const [upcomingBookingsDateTimeList, setUpcomingBookingsDateTimeList] =
        useState([]);
    const [upcomingBookings12hrTime, setUpcomingBookings12hrTime] =
        useState("");
    const [isRecurringBooking, setIsRecurringBooking] = useState(false);
    // credit states
    const [clientPackages, setClientPackages] = useState();
    const [clientPackage, setClientPackage] = useState();
    const [useClientPkgId, setUseClientPkgId] = useState(null);
    const [bookPkgCreditClicked, setBookPkgCreditClicked] = useState(false);
    const [disableElements, setDisableElements] = useState(false);

    //filtered services & locations state
    const [filteredServices, setFilteredServices] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);

    //credit state ends

    const [payType, setPayType] = useState("payCC");
    const [showPayOptions, setShowPayOptions] = useState(false);

    //hiding previous grids
    const [hidePrevGrids, setHidePrevGrids] = useState(false);
    const [scheduleId, setScheduleId] = useState(); // used by clustering modal
    const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
    const [openDateTimePickerView, setOpenDateTimePickerView] = useState("day");
    const [bookingDateTime, setBookingDateTime] = useState();
    const [currentBookingDateTime, setCurrentBookingDateTime] = useState();
    const [openSuggestedTimesDialog, setOpenSuggestedTimesDialog] =
        useState(false);
    const [travelTimeValidationError, setTravelTimeValidationError] =
        useState();
    const [travelTimeWarningMessage, setTravelTimeWarningMessage] = useState();
    const [bookingLocationTimeZone, setBookingLocationTimezone] = useState();
    const handleCloseSuggestedTimesDialog = () => {
        setOpenSuggestedTimesDialog(false);
    };
    const clusteringIsEnabled =
        isOpenForClusters(props.company?.id) &&
        !isFeatureGated(
            props.company.subscriptionLevel,
            FEATURE_NAME_CLUSTERING
        );

    const globalUser = getUserFromCache();
    const loaded = React.useRef(false);
    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            const url =
                "https://maps.googleapis.com/maps/api/js?key=" +
                process.env.REACT_APP_GOOGLE_MAP_KEY +
                "&libraries=places";
            loadScript(url, document.querySelector("head"), "google-maps");
        }

        loaded.current = true;
    }

    if (currentBookingDateTime !== props?.clickedCellProps?.startDate) {
        setBookingDateTime(props?.clickedCellProps?.startDate);
        setCurrentBookingDateTime(props?.clickedCellProps?.startDate);
    }
    //this use effect it used to set the rrule when the manual booking form opens up for the first time
    useEffect(() => {
        if (props.show == true) {
            setRrule(
                props?.clickedCellProps?.startDate
                    ? `FREQ=WEEKLY;INTERVAL=1;BYDAY=${
                          daysAbbr[
                              (props.clickedCellProps.startDate.getDay() + 6) %
                                  7
                          ].title
                      }`
                    : `FREQ=WEEKLY;INTERVAL=1;BYDAY=${
                          daysAbbr[(new Date().getDay() + 6) % 7].title
                      }`
            );
        }
    }, [props.show]);

    function resetFormState() {
        setBookingState({
            numServices: 1,
            serviceQty1: 1,
            daysOfWeek: [], //default-not used
            dayCount: 1, //default-not used
            dayType: "weeks", //default-not used
            repeatingAppointment: false, //default-not used
            repeatingApptList: [] //default-not used
        });
        setClient(null);
        setServiceType(null);
        setShowAddr(null);
        setAddress(null);
        setPlaceId(null);
        setLocation(null);
        setClientNotes("");
        setSelectedSlot(null);
        setPrepareBookingData(false);
        setShowStripeForm(false);
        setShowLoading(false);
        setBookingCompleted({ success: false });
        setLocTzDate(moment().format("MM/DD/YYYY, h:mm A"));
        setShowLocDate(false);
        setIsRecurringBooking(false);
        setRrule(null);
        //credit states
        setClientPackages(null);
        setClientPackage(null);
        setUseClientPkgId(null);
        setBookPkgCreditClicked(false);
        //credit states
        setBookZeroCostServiceClicked(false);
        setPayType("payCC");
        setShowPayOptions(false);
        setDisableElements(false);
        setFilteredLocations([]);
        setFilteredServices([]);
        setBookingDateTime();
        setScheduleId();
        setOpenDateTimePickerView("day");
        setCurrentBookingDateTime();
        setTravelTimeValidationError();
        setTravelTimeWarningMessage();
        setBookingLocationTimezone();
    }
    useEffect(() => {
        async function doTravelTimeValidityAnalysis() {
            if (props.clickedCellProps?.startDate) {
                const travelTimeValidity = await getTravelTimeValidity({
                    bookingDateTime: props.clickedCellProps?.startDate, //bookingDate is string - coming over web
                    bookingGeoLoc:
                        location?.id === "0"
                            ? address.coords
                            : {
                                  lat: location.latitude,
                                  lng: location.longitude
                              },
                    provider: props.provider,
                    company: props.company,
                    service: serviceType
                });
                if (travelTimeValidity?.previousBooking?.travelTimeAnalysis) {
                    setTravelTimeValidationError(
                        travelTimeValidity.previousBooking.travelTimeAnalysis
                    );
                    setTravelTimeWarningMessage(
                        travelTimeValidity.previousBooking
                            .travelTimeWarningMessage
                    );
                } else if (
                    travelTimeValidity?.nextBooking?.travelTimeAnalysis
                ) {
                    setTravelTimeValidationError(
                        travelTimeValidity.nextBooking.travelTimeAnalysis
                    );
                    setTravelTimeWarningMessage(
                        travelTimeValidity.nextBooking.travelTimeWarningMessage
                    );
                } else {
                    setTravelTimeValidationError();
                    setTravelTimeWarningMessage();
                }
            }
        }
        if (client && serviceType && location) {
            if (location.id === "0") {
                if (place_id && address) {
                    setPrepareBookingData(true);
                    if (clusteringIsEnabled) doTravelTimeValidityAnalysis();
                }
            } else {
                setPrepareBookingData(true);
                if (clusteringIsEnabled) doTravelTimeValidityAnalysis();
            }
        }
    }, [
        location,
        place_id,
        address,
        serviceType,
        client,
        payType,
        props.clickedCellProps
    ]);

    useEffect(() => {
        const setupModal = async () => {
            if (
                props.selectedLoc &&
                props.clickedCellProps &&
                props.clickedCellProps.startDate
            ) {
                const loggedInUser = getUserFromCache();
                let sDate = props?.clickedCellProps?.startDate
                    ? props.clickedCellProps.startDate
                    : new Date();

                //if provider timezone is undefined, get master timezone
                let userTz;
                if (!props?.selectedProvider[0]?.timezone) {
                    userTz = await getMasterTimezone();
                } else {
                    userTz = props?.selectedProvider[0]?.timezone
                        ? props.selectedProvider[0].timezone
                        : loggedInUser.provider.timezone;
                }

                if (!location) {
                    if (props.selectedLoc.id === "0") {
                        setShowAddr(true);
                        let d = timezoneConversion(sDate, userTz, userTz);

                        setLocTzDate(moment(d).format("MM/DD/YYYY, h:mm A"));
                        setLocation(props.selectedLoc);
                        setBookingLocationTimezone(userTz);
                    } else {
                        let d = timezoneConversion(
                            sDate,
                            props.selectedLoc.timezone,
                            props?.selectedProvider[0]?.timezone
                                ? props.selectedProvider[0].timezone
                                : loggedInUser?.provider?.timezone
                        );
                        setLocTzDate(moment(d).format("MM/DD/YYYY, h:mm A"));
                        setLocation(props.selectedLoc);
                        setBookingLocationTimezone(props.selectedLoc.timezone);

                        if (
                            props.selectedLoc.timezone !==
                            props?.selectedProvider[0]?.timezone
                                ? props.selectedProvider[0].timezone
                                : loggedInUser?.provider?.timezone
                        ) {
                            setShowLocDate(true);
                        } else {
                            setShowLocDate(false);
                        }
                    }
                } else {
                    if (location.id === "0") {
                        setShowAddr(true);

                        if (
                            address &&
                            address.coords &&
                            address.coords.lat &&
                            address.coords.lng
                        ) {
                            userTz = await getTimezoneIdByGeoLoc({
                                lat: address.coords.lat,
                                lng: address.coords.lng
                            });
                        }

                        if (userTz && address) {
                            let d = timezoneConversion(
                                sDate,
                                userTz,
                                props?.selectedProvider[0]?.timezone
                                    ? props.selectedProvider[0].timezone
                                    : loggedInUser?.provider?.timezone
                            );

                            setLocTzDate(
                                moment(d).format("MM/DD/YYYY, h:mm A")
                            );
                            if (
                                userTz !== props?.selectedProvider[0]?.timezone
                                    ? props.selectedProvider[0].timezone
                                    : loggedInUser?.provider?.timezone
                            ) {
                                setShowLocDate(true);
                            } else {
                                setShowLocDate(false);
                            }
                            setBookingLocationTimezone(userTz);
                        }
                    } else {
                        let d = timezoneConversion(
                            sDate,
                            location.timezone,
                            props?.selectedProvider[0]?.timezone
                                ? props.selectedProvider[0].timezone
                                : loggedInUser?.provider?.timezone
                        );
                        setLocTzDate(moment(d).format("MM/DD/YYYY, h:mm A"));

                        let cl = { ...location };

                        if (cl && cl.id !== "0") {
                            setBookingState((prevState) => ({
                                ...prevState,
                                location: cl.virtual
                                    ? "Virtual Appointment"
                                    : cl.addressoneline,
                                address: cl.virtual
                                    ? "Virtual Appointment"
                                    : cl.addressoneline,
                                locationId: cl.id,
                                isVirtual: cl.virtual,
                                isRemoteLocation: false,
                                appointmentLocation: "local",

                                country: cl.country ? cl.country.trim() : null,
                                countryShort: cl.countrycode3166alpha2
                                    ? cl.countrycode3166alpha2.trim()
                                    : null,
                                province: cl.state ? cl.state.trim() : null,
                                postalCode: cl.postalcode
                                    ? cl.postalcode.trim()
                                    : null
                            }));
                        }
                        if (
                            location.timezone !==
                            props?.selectedProvider[0]?.timezone
                                ? props.selectedProvider[0].timezone
                                : loggedInUser?.provider?.timezone
                        ) {
                            setShowLocDate(true);
                        } else {
                            setShowLocDate(false);
                        }
                        setBookingLocationTimezone(location.timezone);
                    }
                }
            }
        };

        if (
            props.provider &&
            props.selectedLoc &&
            props.clickedCellProps &&
            props.clickedCellProps.startDate
        ) {
            setupModal();
        }
    }, [props, location, place_id, address]);

    useEffect(() => {
        if (bookingCompleted && bookingCompleted.success) {
            handleClose();
        }
    }, [bookingCompleted]);

    useEffect(() => {
        async function prepareBookingDataAndCharges() {
            if ((location || place_id) && serviceType && client && locTzDate) {
                setShowLoading(true);
                await decideServicePrice();
                let schedules = await _getSchedules({
                    providerId: props.provider.id,
                    companyId: props.provider.companyId
                });
                let selectedScheduleId = null;
                if (schedules && schedules.length) {
                    for (let sch of schedules) {
                        if (sch.locationId === location.id) {
                            for (let service of sch.selectedServices) {
                                if (service.servicetypeId === serviceType.id) {
                                    selectedScheduleId = sch.id; //composite
                                }
                            }
                        }
                    }
                }
                let timezone;
                await determineScheduleIdForClustering();
                if (!selectedScheduleId) {
                    //create scheduleinfo based on location
                    let adhoc_sch_scheduleinfo;
                    if (location.id === "0") {
                        //remote location
                        //find provider locations that match the city and state
                        // if no match found, create a deleted/inactive provider location
                        const providerLoc = await getOrCreateProviderLocation();
                        if (providerLoc && providerLoc.id) {
                            bookingState.locationId = providerLoc.id;
                        }
                        if (schedules && schedules.length) {
                            for (let sch of schedules) {
                                if (sch.locationId === providerLoc.id) {
                                    for (let service of sch.selectedServices) {
                                        if (
                                            service.servicetypeId ===
                                            serviceType.id
                                        ) {
                                            selectedScheduleId = sch.id; //composite
                                        }
                                    }
                                }
                            }
                        }

                        timezone = providerLoc.timezone;
                        if (!selectedScheduleId)
                            adhoc_sch_scheduleinfo = getScheduleInfo();
                    } else {
                        timezone = location.timezone;
                        adhoc_sch_scheduleinfo = getScheduleInfo();
                    }

                    if (!selectedScheduleId) {
                        let adhoc_sch_pk = getScheduleTimeblockId(
                            props.provider.companyId,
                            props.provider.id
                        );
                        selectedScheduleId = `${adhoc_sch_pk}${SCHEDPKSKSPLITAT}${adhoc_sch_scheduleinfo}`;
                    }
                } else timezone = location.timezone;
                let slot = { ...selectedSlot, scheduleId: selectedScheduleId };

                let date_tz = props.selectedLoc
                    ? locTzDate
                    : props?.clickedCellProps?.startDate
                      ? props.clickedCellProps.startDate
                      : new Date();
                const sDate = new Date(moment(date_tz).toDate());
                let dateInfo = await getBookingDateDescription(
                    sDate,
                    timezone,
                    serviceType ? serviceType.minutes : 30
                );
                slot = {
                    ...slot,
                    dateInfo,
                    date: sDate,
                    datetime: sDate,
                    tz: timezone,
                    slot12: dateInfo.tm_st_disp,
                    label: getLabel(dateInfo)
                };
                bookingState.selectedScheduleId = selectedScheduleId;
                bookingState.selectedslot = slot;
                bookingState.bookingTz = timezone;
                bookingState.provider = props.provider;
                bookingState.acknowledgementDateTime = new Date();

                let clientPackage = await getPaidPackages({
                    userid: client.user.id,
                    servicetypeid: serviceType.id
                });

                if (clientPackage && clientPackage[0]) {
                    bookingState.clientNotes = clientNotes;

                    setClientPackages(clientPackage);
                    setSelectedSlot(slot);
                    setBookingState({
                        ...bookingState,
                        client
                    });
                    if (!bookingState.repeatingAppointment) {
                        setShowPayOptions(true);
                    }
                }
                if (payType === "payPackage") {
                    bookingState.clientpackage = clientPackage[0];
                } else {
                    bookingState.clientpackage = null;
                }
                bookingState.sdt =
                    payType === "payPackage"
                        ? "package"
                        : bookingState.repeatingAppointment
                          ? "forever"
                          : "single";
                bookingState.clientNotes = clientNotes;
                bookingState.bookedByUser = globalUser;
                try {
                    bookingState.uiSessionId = await createNewUiSession({
                        eventName: "NEW:AdminAdhoc",
                        companyId: props.company.id
                    });
                } catch (e) {
                    console.log("error creating UISession entry");
                }
                if (bookingState.repeatingAppointment) {
                    createOsd(bookingState, props.company, true, 0, 0);
                    setCbuTotal(bookingState.cbu.total);
                } else {
                    createOsd(bookingState, props.company, false, null, null);
                    setCbuTotal(bookingState.cbu.total);
                }

                setServiceFee(bookingState.osd.rawServiceFee);
                setServicePrice(bookingState.osd.rows[0].rawPrice);
                setCurrencyPrefix(getCurrencySymbol(bookingState));
                try {
                    if (client.stripeCustomerId) {
                        let cardExpiration = await checkCardExpiration({
                            stripecustomerid: client.stripeCustomerId,
                            companyId: client.companyId,
                            clientEmailAddress: client.user.emailaddress,
                            stripeAccount: props.company.stripeAccount,
                            stripeConnectEnabled:
                                props.company.stripeConnectEnabled
                        });
                        if (cardExpiration && cardExpiration.cardExpired) {
                            bookingState.cardExpired = true;
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
                setSelectedSlot(slot);
                setBookingState({
                    ...bookingState,
                    client
                });
                if (
                    bookingState.cbu?.total === 0 ||
                    props?.company?.collectpayment === false
                )
                    setShowStripeForm(false);
                else setShowStripeForm(true);
                setShowLoading(false);
            }
            setPrepareBookingData(false);
        }
        prepareBookingDataAndCharges();
    }, [prepareBookingData]); //TODO: do we need clientChange, serviceTypeChanged and locationChanged instead???

    useEffect(() => {
        if (clientPackages && clientPackages.length > 0) {
            updateUseCredit(clientPackages[0].id, clientPackages[0]);
        }
    }, [clientPackages]);

    useEffect(() => {
        if (cbuTotal > 0 && props?.company?.collectpayment)
            setShowStripeForm(true);
        else setShowStripeForm(false);
    }, [cbuTotal]);
    function serviceFeeAndServicePriceZero() {
        return parseFloat(serviceFee) === 0 && parseFloat(servicePrice) === 0;
    }

    const changingLocationHandler = (locId) => {
        if (address) setAddress(null);
        if (showLocDate) setShowLocDate(false);
        props.selectingProviderHandler(
            props.selectedProvider[0].id,
            locId,
            props?.clickedCellProps?.startDate
                ? props.clickedCellProps.startDate
                : new Date(),
            props?.clickedCellProps?.endDate
                ? props.clickedCellProps.endDate
                : new Date()
        );
    };
    async function bookPkgCredit() {
        setBookPkgCreditClicked(true);
        bookingState.useClientPkgId = useClientPkgId;
        bookingState.clientpackage = clientPackage;
        createOsd(bookingState, props.company);

        try {
            bookingState.uiSessionId = await createNewUiSession({
                eventName: "NEW:AdminAdhoc",
                companyId: props.company.id
            });
        } catch (e) {
            console.log("error creating UISession entry");
        }
        try {
            let result = await completeBookingWithoutCharge(
                bookingState,
                props.company,
                "PKG_CREDIT"
            );
            setBookingCompleted(result);
            resetFormState();
        } catch (e) {
            console.log(e);
        }
    }
    async function bookZeroCostService() {
        setBookZeroCostServiceClicked(true);
        createOsd(bookingState, props.company, true, servicePrice, serviceFee);

        try {
            bookingState.uiSessionId = await createNewUiSession({
                eventName: "NEW:AdminAdhoc",
                companyId: props.company.id
            });
        } catch (e) {
            console.log("error creating UISession entry");
        }
        try {
            let result = await completeBookingWithoutCharge(
                bookingState,
                props.company,
                "ZERO_COST_SERVICE"
            );
            setBookingCompleted(result);
        } catch (e) {
            console.log(e);
        }
    }
    function getLabel(dateInfo) {
        if (dateInfo && dateInfo.dtstamp_str) {
            return dateInfo.dtstamp_str.slice(11, 16);
        }
    }
    async function getOrCreateProviderLocation() {
        let addrtz;

        try {
            addrtz = await getTimezoneIdByGeoLoc({
                lat: address.coords.lat,
                lng: address.coords.lng
            });
        } catch (err) {
            console.log(
                "could not get time zone from geo loc - addEditCompanyLocation"
            );
        }

        const locations = await graphql(
            graphqlOperation(locationByProvider, {
                providerId: props.provider.id,
                limit: 50
            })
        );

        let addressCity = address.city.toLowerCase();
        let addressState = address.prov.toLowerCase();
        let foundLocation = null;
        let providerLocations = locations.data.locationByProvider.items;
        for (let pl of providerLocations) {
            let plCity = pl.city.toLowerCase();
            let plState = pl.state.toLowerCase();
            if (plCity === addressCity && plState === addressState) {
                foundLocation = pl;
                break;
            }
            //OR
            if (addressIsNearProviderLocation(address, pl, addrtz)) {
                foundLocation = pl;
                break;
            }
        }

        if (foundLocation) return foundLocation;
        else {
            //create a new provider location
            let newProviderLocation = await createNewProviderLocation(
                address,
                props.provider,
                addrtz
            );
            return newProviderLocation;
        }
    }

    async function getScheduleMatchingProviderLocation(schedules) {
        const locations = await graphql(
            graphqlOperation(locationByProvider, {
                providerId: props.provider.id,
                limit: 50
            })
        );
        let providerLocations = locations.data.locationByProvider.items;
        for (let pl of providerLocations) {
            for (let sch of schedules) {
                if (
                    pl.active !== false &&
                    sch.active !== false &&
                    sch.locationId === pl.id
                ) {
                    // not matching service. As long as schedule matches location, we are good.
                    const addressInTraveZone = isAddressInTravelZone(
                        address.coords,
                        pl
                    );
                    if (addressInTraveZone) return sch.id;
                }
            }
        }
        return null;
    }

    function addressIsNearProviderLocation(addr, pl, addrtz) {
        if (addrtz && pl.timezone) {
            if (
                addrtz.trim().toLowerCase() === pl.timezone.trim().toLowerCase()
            ) {
                const bookLat = Number(addr.latitude || addr.coords.lat);
                const bookLng = Number(addr.longitude || addr.coords.lng);
                console.log("addressIsNearProviderLocation ", bookLat, bookLng);
                const plLat = Number(pl.latitude);
                const plLng = Number(pl.longitude);
                const lat = plLat - bookLat;
                const lng = plLng - bookLng;
                const dLat = (lat * Math.PI) / 180;
                const dLng = (lng * Math.PI) / 180;
                const bookLatRad = (bookLat * Math.PI) / 180;
                const plLatRad = (plLat * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(bookLatRad) *
                        Math.cos(plLatRad) *
                        Math.sin(dLng / 2) *
                        Math.sin(dLng / 2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                let d = 6371e3 * c;
                if (d < 50000) return true;
                //within 50km, then it is near an existing provider location
                else return false;
            }
        }
        return false;
    }

    async function createNewProviderLocation(address, provider, addrtz) {
        try {
            const loggedinUser = getUserFromCache();
            const companyId = loggedinUser.companyId;
            let input = {
                name: `${address.city}, ${address.prov}`,
                desc: `${address.city}, ${address.prov}`,
                addressoneline: address.addressoneline,
                street: address.addressoneline,
                city: address.city,
                state: address.prov,
                country: address.country,
                postalcode: address.postalCode,
                longitude: address.coords.longitude,
                latitude: address.coords.latitude,
                companyId: companyId,
                traveldistance: 50,
                traveldistanceunit: "km",
                maxtraveltype: "MAX_DISTANCE",
                travelregions: JSON.stringify([]),
                providerId: provider.id,
                providerLocationProviderId: provider.id,
                providerLocationCompanyId: companyId,
                timezone: addrtz,
                active: false,
                deleted: true
            };
            let response = await graphql(
                graphqlOperation(createProviderLocation, {
                    input
                })
            );

            if (
                response &&
                response.data &&
                response.data.createProviderLocation
            ) {
                return response.data.createProviderLocation;
            } else {
            }
        } catch (error) {
            console.error("Add Provider location error => ", error);
        }
    }

    async function decideServicePrice() {
        let regionalServicePrice1 = await getPrice(bookingState, serviceType);
        let currency = await getCurrency(bookingState);
        bookingState.regionalServicePrice1 = regionalServicePrice1;
        bookingState.currency = currency;
        let regionalServicePrice = totalRegionalPrice(bookingState);
        bookingState.regionalServicePrice = regionalServicePrice;
    }

    function saveServiceType(service) {
        let filteredLocations;
        filteredLocations = manageFilteredLocationsByService(
            service,
            companyLocations,
            setFilteredLocations
        );
        if (
            filteredLocations &&
            filteredLocations.length &&
            filteredLocations.every((obj) => obj.id !== location?.id)
        ) {
            saveLocation(filteredLocations[0]);
        }
        setServiceType(service);
        let localBookingState = bookingState;
        localBookingState.serviceType = service;
        localBookingState.serviceType1 = service;
        setBookingState(localBookingState);
    }
    async function determineScheduleIdForClustering() {
        let selectedScheduleId = null;
        if (location.id !== "0" || place_id) {
            const selectedProviderId = props.provider.id;
            let schedules = await _getSchedules({
                providerId: selectedProviderId,
                companyId: props.company.id
            });
            if (location.id !== "0" && schedules && schedules.length) {
                for (let schedule of schedules) {
                    if (
                        schedule.active !== false &&
                        schedule.locationId === location.id
                    ) {
                        selectedScheduleId = schedule.id;
                    }
                }
            }
            if (!selectedScheduleId && location.id === "0") {
                //remote location
                selectedScheduleId =
                    await getScheduleMatchingProviderLocation(schedules);
            }
            setScheduleId(selectedScheduleId); // should be null or a value
        }
    }
    function saveClient(client) {
        if (client && client.user) {
            //addressoneline appears in the email notification
            client.user.addressoneline = client.user.addressoneline
                ? client.user.addressoneline
                : "";
            setClient(client);
            setBookingState({
                ...bookingState,
                client,
                isNewClient: false,
                bookingOnBehalf: true,
                company: globalUser.company
            });
        }
    }

    function saveLocation(cl) {
        manageFilteredServicesByLocation(cl, serviceTypes, setFilteredServices);
        setLocation(cl);
        if (cl && cl.id !== "0") {
            bookingState.location = cl.virtual
                ? "Virtual Appointment"
                : cl.addressoneline;
            bookingState.address = cl.virtual
                ? "Virtual Appointment"
                : cl.addressoneline;
            bookingState.locationId = cl.id;
            bookingState.isVirtual = cl.virtual;
            bookingState.isRemoteLocation = false;
            bookingState.appointmentLocation = "local";

            bookingState.country = cl.country ? cl.country.trim() : null;
            bookingState.countryShort = cl.countrycode3166alpha2
                ? cl.countrycode3166alpha2.trim()
                : null;
            bookingState.province = cl.state ? cl.state.trim() : null;
            bookingState.postalCode = cl.postalcode
                ? cl.postalcode.trim()
                : null;
        } else setAddress(null);
    }

    function saveAddress(addr) {
        let {
            city,
            prov,
            country,
            countryShortName,
            postalCode,
            addressOneLine,
            coords,
            route,
            street_number
        } = addr;
        bookingState.location = addressOneLine;
        bookingState.address = addressOneLine;
        bookingState.street = addressOneLine.split(",")[0];
        bookingState.city = city;
        bookingState.isRemoteLocation = true;
        bookingState.appointmentLocation = "remote";
        bookingState.route = route;
        bookingState.street_number = street_number;
        bookingState.postalCode = postalCode;
        // only needed for tax calc for remote location, regional pricing calculations
        bookingState.remoteAddressState = prov ? prov.trim() : null;
        bookingState.remoteAddressCountry = country ? country.trim() : null;
        bookingState.remoteAddressCountryShort = countryShortName
            ? countryShortName.trim()
            : null; // country code
        bookingState.remoteAddressPostalCode = postalCode
            ? postalCode.trim()
            : null;
        bookingState.remoteAddressCoordinates = coords;
        setAddress(addr);
    }

    function savePlaceId(placeId) {
        if (placeId === null) {
            setPlaceId(null);
        } else {
            setPlaceId(placeId);
        }
        setBookingState({ ...bookingState, place_id: placeId });
    }

    function handleClearPlaceId() {
        setPlaceId(null);
        setBookingState({ ...bookingState, place_id: null });
    }

    function handleClose() {
        resetFormState();
        props.resetAvailabilityError();
        props.handleClose();
        props.setBookingCompleted(
            bookingCompleted ? bookingCompleted.success : false
        );
    }

    function onRruleChange(rrule) {
        setRrule(rrule);
        let bookingsDateTimeList = prepareBookingsDateTimeList(
            props?.clickedCellProps?.startDate
                ? moment(new Date(props.clickedCellProps.startDate)).format(
                      "YYYY-MM-DDTHH:mm:ssZ"
                  )
                : bookingState.selectedslot.dateInfo.dtstamp_str,
            parseRRule(rrule).until,
            parseRRule(rrule),
            rrule
        );
        setUpcomingBookingsDateTimeList(bookingsDateTimeList);
        setUpcomingBookings12hrTime(
            moment(bookingsDateTimeList[0].dtstamp_str).format("h:mm A")
        );
        bookingState.repeatingApptList = bookingsDateTimeList;
        bookingState.rrule = rrule;
    }
    function handleRecurringBookingChange(event) {
        bookingState.repeatingAppointment = event.target.checked;
        setIsRecurringBooking(event.target.checked);
        setPayType("payCC");

        if (event.target.checked === false) {
            if (bookingState?.osd) {
                createOsd(bookingState, props.company, false, null, null);
                setCbuTotal(bookingState.cbu.total);
                setServicePrice(
                    bookingState?.serviceType
                        ? bookingState.serviceType.price
                        : bookingState.osd.rows[0].rawPrice
                );
                setServiceFee(
                    bookingState?.company?.addServiceFee
                        ? bookingState.company.serviceFeeAmount
                        : bookingState.osd.servicefee
                );
            }
            bookingState.rrule = null;
            bookingState.sdt = "single";
            bookingState.repeatingApptList = [];
            if (clientPackages) {
                setShowPayOptions(true);
            }
        } else {
            if (bookingState?.osd) {
                createOsd(bookingState, props.company, true, 0, 0);
                setCbuTotal(bookingState.cbu.total);
                setServicePrice(0);
                setServiceFee(0);
            }
            bookingState.rrule = rrule;
            bookingState.sdt = "forever";
            if (clientPackages) {
                setShowPayOptions(false);
            }
        }
    }

    function handleDateTimeChange(dateTimeValue) {
        let newDate = new Date(dateTimeValue);
        if (location) {
            let d;
            if (props.selectedLoc) {
                d = timezoneConversion(
                    newDate,
                    props.selectedLoc.timezone,
                    props.masterTimezone
                );
            } else {
                d = timezoneConversion(
                    newDate,
                    props.masterTimezone,
                    props.masterTimezone
                );
            }

            setLocTzDate(moment(d).format("MM/DD/YYYY, h:mm A"));
            props.changeDateTimeCellProps(newDate);

            if (props.selectedProvider && props.selectedProvider[0]) {
                props.selectingProviderHandler(
                    props.selectedProvider[0].id,
                    location.id,
                    newDate,
                    props?.clickedCellProps?.endDate
                        ? props.clickedCellProps.endDate
                        : new Date()
                );
            }
        } else {
            props.changeDateTimeCellProps(newDate);
        }
    }

    //Returns a list of all booking date/time information for all bookings that
    //need to be made according to the initialDate, untilDate and ruleComponents
    function prepareBookingsDateTimeList(
        initialDateTime,
        untilDate,
        ruleComponents,
        rrule
    ) {
        const bookings = [];
        const [initialDate, initialFullTime] = initialDateTime.split("T");
        let currentDate = initialDate;

        if (
            ruleComponents.frequency === "MONTHLY" &&
            ruleComponents.days === "WEEK" &&
            isLastOccurence(currentDate)
        ) {
            ruleComponents.lastOccurence = true;
        }
        const nextDate = calculateNextDate(initialDate, ruleComponents);

        //Prepares the 'bookings' array with a 'bookingObject's for each booking that will take place from the currentDate to the untilDate
        while (bookings.length < 10) {
            if (untilDate && currentDate > untilDate) {
                bookingState.wdDisplay = null;
                return bookings;
            }
            const currDate = new Date(currentDate);
            const dt_long_disp = moment(currentDate).format(
                "dddd, MMMM DD, YYYY"
            ); // Output: "Wednesday, November 22, 2023"
            const partial_dt_full_disp =
                moment(currentDate).format("ddd, MMM D, YYYY"); // Output: "Wed, Nov 22, 2023"
            const partial_en_slot_disp =
                moment(currentDate).format("ddd, MMM D"); // Output: "Wed, Nov 22"

            const bookingObject = {
                dt_disp: currentDate, //'2023-11-29',
                dtstamp_str: `${currentDate}T${initialFullTime}`, //'2023-11-29T18:30:00-05:00',
                dt_long_disp: dt_long_disp, //'Wednesday, November 22, 2023'
                partial_dt_full_disp: partial_dt_full_disp, //'Wed, Nov 22, 2023'
                partial_en_slot_disp: partial_en_slot_disp //'Wed, Nov 22'
            };

            bookings.push(bookingObject);

            //If there is a set number of x occurences, break from the while loop and return the bookings once the x number of occurences is reached
            if (
                ruleComponents.xoccur &&
                bookings.length == ruleComponents.xoccur
            ) {
                bookingState.wdDisplay = null;
                return bookings;
            }

            // Update currentDate for the next iteration based on the rule components
            currentDate = calculateNextDate(currentDate, ruleComponents);
        }

        if (bookings.length == 10) {
            bookingState.wdDisplay = getWdDisplay(
                rrule,
                props?.clickedCellProps?.startDate
                    ? props.clickedCellProps.startDate
                    : bookingState.selectedslot.dateInfo.dtstamp_str
            );
        }

        return bookings;
    }

    //returns true , if the date is the 5th and last occurence in a month
    function isLastOccurence(dateInput) {
        const date = new Date(dateInput);
        const currentOccurrence = Math.floor((date.getDate() - 1) / 7) + 1;
        return currentOccurrence === 5;
    }

    function calculateNextDate(currentDate, ruleComponents) {
        const { frequency, interval, days } = ruleComponents;
        let nextDate = moment(currentDate);

        // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        // Then convert it to MB calendar week setting (0 = Monday, 1 = Tuesday, ..., 6 = Sunday)
        let currentDay = nextDate.day();
        currentDay = (currentDay + 6) % 7;

        if (frequency === "WEEKLY") {
            let daysToAdd;
            if (days.length > 1) {
                let closestValidDay = days.find((day) => day > currentDay);
                if (!closestValidDay) {
                    closestValidDay = days[0];
                    daysToAdd =
                        7 - (currentDay - closestValidDay) + (interval - 1) * 7;
                } else {
                    daysToAdd = closestValidDay - currentDay;
                }
            } else {
                daysToAdd = 7 * interval;
            }

            nextDate.add(daysToAdd, "days");
        } else if (frequency === "MONTHLY") {
            if (days === "WEEK") {
                const currentOccurrence =
                    Math.floor((nextDate.date() - 1) / 7) + 1;

                nextDate.add(interval, "months");

                if (ruleComponents.lastOccurence) {
                    nextDate.add(1, "months").date(0);

                    while ((nextDate.day() + 6) % 7 !== currentDay) {
                        nextDate.subtract(1, "days");
                    }
                    return nextDate.format("YYYY-MM-DD");
                } else if (currentOccurrence > 1) {
                    nextDate.date(7 * (currentOccurrence - 1));
                } else {
                    nextDate.date(1);
                }

                while (
                    (nextDate.day() + 6) % 7 !== currentDay ||
                    Math.floor((nextDate.date() - 1) / 7) + 1 !==
                        currentOccurrence
                ) {
                    nextDate.add(1, "days");
                }
            } else if (days === "NUMBER") {
                let tempNextDate;
                const dayOfMonth = nextDate.date();
                let nextMonthDay;
                let count = 1;

                while (dayOfMonth !== nextMonthDay) {
                    tempNextDate = nextDate
                        .clone()
                        .add(interval * count, "months");
                    nextMonthDay = tempNextDate.date();

                    if (dayOfMonth == nextMonthDay) {
                        nextDate = tempNextDate;
                        break;
                    }
                    count++;
                }
            }
        } else if (frequency === "YEARLY") {
            nextDate.add(interval, "years");
        }

        return nextDate.format("YYYY-MM-DD");
    }

    /**
     * Converts an rruleString into a user-friendly sentence for easy comprehension.
     *
     * @param {string} rruleString - The input rruleString to be converted, e.g., "FREQ=MONTHLY;INTERVAL=1;BYDAY=WEEK;UNTIL=2024-03-31".
     * @param {string} [bookingDate] - Optional booking date in the format "YYYY-MM-DD".
     *
     * @returns {string} A user-friendly sentence describing the recurrence rule.
     *
     */
    function getWdDisplay(rruleString, bookingDate) {
        // Parse the rruleString into an object
        const rruleObject = parseRruleString(rruleString);

        // Extract relevant information
        const freq = rruleObject.FREQ || "";
        const interval = rruleObject.INTERVAL || 1;
        const byDay = rruleObject.BYDAY || "";

        //until = new Date(until);
        // Build the user-friendly label
        let label = ``;
        //BYDAY
        if (byDay && freq === "WEEKLY") {
            label += ` ${formatByDay(byDay)}, every ${interval} week${
                interval > 1 ? `s` : ""
            }`;
        } else if (byDay === "NUMBER" && freq === "MONTHLY") {
            label += ` ${interval} month${
                interval > 1 ? `s` : ""
            }, on the same day`;
        } else if (byDay === "WEEK" && freq === "MONTHLY") {
            const date = bookingDate
                ? new Date(bookingDate)
                : new Date(
                      props?.clickedCellProps?.startDate
                          ? props.clickedCellProps.startDate
                          : bookingState.selectedslot.dateInfo.dtstamp_str
                  );

            const dayOfWeek = date.toLocaleDateString("en-US", {
                weekday: "long"
            });
            const weekOccurence = Math.floor((date.getDate() - 1) / 7) + 1;
            const occurenceLabels = [
                "first",
                "second",
                "third",
                "fourth",
                "last"
            ];

            label += ` ${
                occurenceLabels[weekOccurence - 1]
            } ${dayOfWeek} of every ${interval} month${
                interval > 1 ? `s` : ""
            }`;
        } else if (freq === "YEARLY") {
            label += ` ${interval} year${
                interval > 1 ? `s` : ""
            } on the same day`;
        }

        return label;
    }

    function parseRRule(rrule) {
        const components = rrule.split(";");
        const parsedRule = {
            frequency: "",
            interval: 1,
            days: [],
            until: null,
            xoccur: null
        };

        components.forEach((component) => {
            const [key, value] = component.split("=");
            switch (key) {
                case "FREQ":
                    parsedRule.frequency = value;
                    break;
                case "INTERVAL":
                    parsedRule.interval = parseInt(value, 10);
                    break;
                case "BYDAY":
                    if (value) {
                        if (value === "NUMBER" || value === "WEEK") {
                            parsedRule.days = value;
                            break;
                        }
                        const daysArray = value.split(",");
                        daysArray.forEach((day) => {
                            parsedRule.days.push(convertDayToNumber(day));
                        });
                        //sort parsedRule.days in ascending order
                        parsedRule.days.sort((a, b) => a - b);
                    }
                    break;
                case "UNTIL":
                    parsedRule.until = value;
                    break;
                case "XOCCUR":
                    parsedRule.xoccur = value;
                    break;
                default:
                    // Handle other RRULE components if necessary
                    break;
            }
        });

        return parsedRule;
    }

    // Helper function to parse rruleString into an object
    function parseRruleString(rruleString) {
        const rruleObject = {};
        const parts = rruleString.split(";");
        parts.forEach((part) => {
            const [key, value] = part.split("=");
            rruleObject[key] = value;
        });
        return rruleObject;
    }

    // Helper function to format BYDAY field
    function formatByDay(byDay) {
        const days = byDay.split(",");
        const formattedDays = days.map(getFullDayString);
        return formattedDays.join(", ");
    }

    function convertDayToNumber(day) {
        const dayMappings = {
            MO: 0,
            TU: 1,
            WE: 2,
            TH: 3,
            FR: 4,
            SA: 5,
            SU: 6
        };
        return dayMappings[day];
    }

    function getFullDayString(day) {
        const dayMap = {
            MO: "Monday",
            TU: "Tuesday",
            WE: "Wednesday",
            TH: "Thursday",
            FR: "Friday",
            SA: "Saturday",
            SU: "Sunday"
        };

        return dayMap[day] || day; // If the day is not found in the map, return the original value
    }

    //credit state
    function updateUseCredit(pkgid, cp) {
        setUseClientPkgId(pkgid);
        setClientPackage(cp);
    }

    const disableElementsHandler = () => {
        setDisableElements(true);
    };

    function handleSelectedCustomDateTime() {
        setBookingDateTime(new Date());
        setOpenDateTimePickerView("day");
        setOpenDateTimePicker(true);
    }

    function handleSelectedFullDayWithTime(selectedSuggestedFullDayWithTime) {
        handleDateTimeChange(selectedSuggestedFullDayWithTime);
    }
    function handleSelectedSlot(selectedSuggestedSlot) {
        handleDateTimeChange(selectedSuggestedSlot.datetime);
    }
    async function handleOpenDateTimePicker() {
        if (clusteringIsEnabled) {
            if (scheduleId) {
                setOpenSuggestedTimesDialog(true);
            } else setOpenDateTimePicker(true);
        } else setOpenDateTimePicker(true);
    }

    function displayClientnotes() {
        return (
            <Grid
                container
                spacing={2}
                style={{ marginBottom: "16px", marginTop: "0px" }}
            >
                <Grid container item xs={12}>
                    <TextField
                        id="client-notes"
                        label="Client notes"
                        multiline
                        rows={3}
                        onChange={(e) => {
                            setClientNotes(e.target.value);
                            bookingState.clientNotes = e.target.value;
                        }}
                        variant="outlined"
                        style={{
                            width: "100%",
                            marginLeft: "8px 0px"
                        }}
                        disabled={disableElements}
                    />
                </Grid>

                {props.availabilityError && (
                    <Grid item xs={12}>
                        <Alert
                            sx={{
                                color: "#d32f2f",
                                backgroundColor: "rgb(237 202 202) !important",
                                "& .MuiAlert-message": {
                                    fontWeight: 600
                                }
                            }}
                            severity="error"
                        >
                            This provider does not have availability on the
                            selected date and time. Please check with them
                            directly to ensure that they are definitely able to
                            take this booking.
                        </Alert>
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={props.showSnackMessage}
                autoHideDuration={3000}
                onClose={props.handleMessageClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={"Please select a provider"}
            />
            <SuggestedTimesDialog
                open={openSuggestedTimesDialog}
                handleClose={handleCloseSuggestedTimesDialog}
                handleSelectedFullDayWithTime={handleSelectedFullDayWithTime}
                handleSelectedSlot={handleSelectedSlot}
                handleSelectedCustomDateTime={handleSelectedCustomDateTime}
                bookingGeoLocation={
                    location && location.latitude && location.longitude
                        ? { lat: location.latitude, lng: location.longitude }
                        : address
                          ? address.coords
                          : null
                }
                providerSchedule={scheduleId}
                selectedStartDate={props.clickedCellProps?.startDate}
                company={props.company}
                service={serviceType}
                masterTimeZone={props.masterTimezone}
                bookingLocationTimeZone={bookingLocationTimeZone}
            />
            <Dialog open={props.show} maxWidth="md" onClose={handleClose}>
                <DialogTitle
                    sx={{
                        fontSize: "24px",
                        fontWeight: 400,
                        padding: "20px 36px"
                    }}
                >
                    New Manual Booking for{" "}
                    {props.provider && props.provider.firstname}
                </DialogTitle>
                <DialogContent
                    style={{
                        height: fullScreen ? "80vh" : "80vh",
                        padding: "16px 36px"
                    }}
                    dividers
                >
                    <Backdrop
                        style={{
                            zIndex: theme.zIndex.drawer + 1,
                            color: "#fff"
                        }}
                        open={showLoading}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <b> Note:</b>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The Manual Booking option allows an
                                administrator to book any time or location for{" "}
                                {props.provider && props.provider.firstname} and
                                does not enforce schedules. Please ensure that{" "}
                                {props.provider && props.provider.firstname} has
                                consented to work the time and location
                                selected.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={12}>
                            <ClientSelector
                                setClient={saveClient}
                                disableElements={disableElements}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={6}>
                            <ServiceSelector
                                setServiceType={saveServiceType}
                                setServiceTypes={setServiceTypes}
                                disableElements={disableElements}
                                filteredServices={filteredServices}
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <LocationSelector
                                setLocation={saveLocation}
                                setShowAddr={setShowAddr}
                                setLocationChanged={() => {}}
                                setPlaceId={savePlaceId}
                                locationId={location && location.id}
                                disableElements={disableElements}
                                filteredLocations={filteredLocations}
                                setCompanyLocations={setCompanyLocations}
                                selectingProviderHandler={
                                    props.selectingProviderHandler
                                }
                                changingLocationHandler={
                                    changingLocationHandler
                                }
                                xs={12}
                                fullWidth={true}
                                margin={"8px 0px"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid container item xs={12}>
                            {showAddr && (
                                <ClientAddressInput
                                    setAddress={saveAddress}
                                    setPlaceId={savePlaceId}
                                    address={address}
                                    autocompleteService={autocompleteService}
                                    disableElements={disableElements}
                                    formWidth={"100%"}
                                    formMargin={"8px 0px"}
                                    formMarginLeft={"0px"}
                                    onClear={handleClearPlaceId}
                                    addressOneLine={
                                        client?.user?.addressoneline
                                            ? client.user.addressoneline
                                            : ""
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} style={{ paddingTop: "4px" }}>
                        <FormControlLabel
                            margin="normal"
                            control={
                                <Switch
                                    onChange={handleRecurringBookingChange}
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Recurring booking"
                        />
                        {isRecurringBooking && (
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    paddingLeft: "8px"
                                }}
                            >
                                {/* Left Part */}
                                <Grid container item xs={6}>
                                    <RecurringBookingSelector
                                        setRrule={onRruleChange} //optional
                                        selectedDate={
                                            //required
                                            props?.clickedCellProps?.startDate
                                                ? props.clickedCellProps
                                                      .startDate
                                                : bookingState.selectedslot
                                                      ?.dateInfo?.dtstamp_str
                                        }
                                        defaultIndefinitely={true} //optional
                                        endDate={
                                            //optional, here we set the endDate to 3 months ahead of selected date
                                            props?.clickedCellProps?.startDate
                                                ? new Date(
                                                      props?.clickedCellProps?.startDate
                                                  ).setMonth(
                                                      new Date(
                                                          props?.clickedCellProps?.startDate
                                                      ).getMonth() + 3
                                                  )
                                                : new Date(
                                                      bookingState?.selectedslot?.dateInfo?.dtstamp_str
                                                  ).setMonth(
                                                      new Date(
                                                          bookingState?.selectedslot?.dateInfo?.dtstamp_str
                                                      ).getMonth() + 3
                                                  )
                                        }
                                        occurrences={
                                            //optional
                                            13
                                        }
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={6}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                        marginBottom: "16px"
                                    }}
                                >
                                    <Typography sx={{ fontWeight: "500" }}>
                                        Here are the upcoming bookings:
                                    </Typography>
                                    <div>
                                        {upcomingBookingsDateTimeList.map(
                                            (booking, index) => (
                                                <Typography key={index}>
                                                    {`${booking.dt_long_disp} - ${upcomingBookings12hrTime}`}
                                                </Typography>
                                            )
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid container item xs={6}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    margin: "8px 0px"
                                }}
                                disabled={disableElements}
                            >
                                <InputLabel id="provider">
                                    Select provider
                                </InputLabel>
                                <Select
                                    labelId="provider"
                                    id="provider"
                                    value={
                                        props.selectedProvider &&
                                        props?.selectedProvider[0]?.id
                                            ? props.selectedProvider[0].id
                                            : null
                                    }
                                    label="Select provider"
                                    onChange={(e) => {
                                        props.selectingProviderHandler(
                                            e.target.value,
                                            location?.id || null,
                                            props?.clickedCellProps
                                                ?.startDate || new Date(),
                                            props?.clickedCellProps?.endDate ||
                                                new Date()
                                        );
                                    }}
                                >
                                    {props.providersList &&
                                        props.providersList.length > 0 &&
                                        props.providersList.map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {`${item.firstname} ${item.lastname}`}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={6} flexDirection={"column"}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={customLocale}
                            >
                                <MobileDateTimePicker
                                    minutesStep={5}
                                    open={openDateTimePicker}
                                    onClose={() => {
                                        setOpenDateTimePicker(false);
                                        if (openDateTimePickerView === "hours")
                                            setOpenDateTimePickerView("day");
                                    }}
                                    views={[
                                        "year",
                                        "month",
                                        "day",
                                        "hours",
                                        "minutes"
                                    ]}
                                    sx={{
                                        position: "relative",
                                        width: "100%",
                                        margin: "8px 0px"
                                    }}
                                    label="Booking date and time"
                                    format="L h:mm A"
                                    minDateMessage={
                                        "Please select a start date."
                                    }
                                    value={
                                        bookingDateTime
                                            ? bookingDateTime
                                            : getCurrentTimeAdjustedToNextHour()
                                    }
                                    onChange={(newValue) => {
                                        handleDateTimeChange(newValue);
                                    }}
                                    onOpen={async () =>
                                        await handleOpenDateTimePicker()
                                    }
                                    openTo={openDateTimePickerView}
                                    renderInput={(params) => (
                                        <TextField
                                            error={
                                                travelTimeValidationError &&
                                                scheduleId
                                            }
                                            fullWidth
                                            sx={{
                                                width: "100%",
                                                margin: "8px 0px"
                                            }}
                                            {...params}
                                            InputProps={
                                                travelTimeValidationError &&
                                                scheduleId
                                                    ? {
                                                          endAdornment: (
                                                              <InputAdornment position="end">
                                                                  <Tooltip
                                                                      title={
                                                                          <>
                                                                              <Typography variant="body2">
                                                                                  {
                                                                                      travelTimeWarningMessage
                                                                                  }
                                                                              </Typography>
                                                                              <Typography variant="body2">
                                                                                  Click
                                                                                  to
                                                                                  optimize
                                                                              </Typography>
                                                                          </>
                                                                      }
                                                                      arrow
                                                                  >
                                                                      <IconButton
                                                                          edge="end"
                                                                          sx={{
                                                                              color: "warning.main"
                                                                          }}
                                                                      >
                                                                          <WarningAmberRoundedIcon
                                                                              sx={{
                                                                                  fontSize:
                                                                                      "32px"
                                                                              }}
                                                                          />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </InputAdornment>
                                                          )
                                                      }
                                                    : {}
                                            }
                                        />
                                    )}
                                    PopperProps={{
                                        disablePortal: true,
                                        placement: "bottom-end"
                                    }}
                                    disabled={disableElements}
                                />
                            </LocalizationProvider>
                            {showLocDate && (
                                <Typography
                                    sx={{
                                        marginLeft: "0.5rem",
                                        color: "rgba(0,0,0,0.6)"
                                    }}
                                >
                                    {`${locTzDate} at ${
                                        address && address.city && location
                                            ? address.city
                                            : location && location?.locationname
                                              ? location.locationname
                                              : ""
                                    }`}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                    {displayClientnotes()}

                    {/* New Payment Options - With Credit Left or Current Credit Card */}

                    {showPayOptions && (
                        <Grid
                            container
                            flexDirection={"column"}
                            lg={8}
                            md={8}
                            sm={12}
                            sx={{ paddingLeft: "8px" }}
                        >
                            <Grid xs={12} sx={{ marginBottom: "1rem" }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Roboto",
                                        fontSize: "1rem"
                                    }}
                                >
                                    Payment Methods
                                </Typography>
                            </Grid>
                            <FormControl>
                                <RadioGroup
                                    value={payType ? payType : ""}
                                    onChange={(e) => {
                                        setPayType(e.target.value);
                                    }}
                                >
                                    <Grid
                                        container
                                        alignItems={"center"}
                                        sx={payTypeStyling("payCC", payType)}
                                    >
                                        <FormControlLabel
                                            value="payCC"
                                            control={
                                                <Radio
                                                    disabled={disableElements}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: "1rem"
                                                    }}
                                                >
                                                    {`Pay with client's credit card`}

                                                    {bookingState?.client
                                                        ?.defaultpartialcc &&
                                                        ` (ending with **** ${bookingState?.client?.defaultpartialcc})`}
                                                </Typography>
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        sx={payTypeStyling(
                                            "payPackage",
                                            payType
                                        )}
                                    >
                                        <FormControlLabel
                                            value="payPackage"
                                            control={
                                                <Radio
                                                    disabled={disableElements}
                                                />
                                            }
                                            label={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "1rem"
                                                        }}
                                                    >
                                                        Use Client's existing
                                                        package credits
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "0.75rem",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        {`${
                                                            clientPackages[0]
                                                                ?.initialQuantity -
                                                            clientPackages[0]
                                                                ?.usedQuantity
                                                        } of ${
                                                            clientPackages[0]
                                                                ?.initialQuantity
                                                        } credits remaining`}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>

                            {hidePrevGrids &&
                                clientPackages &&
                                clientPackages.length > 0 &&
                                clientPackages.map((cep) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        justify="center"
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                style={{ margin: "8px" }}
                                                onClick={() =>
                                                    updateUseCredit(cep.id, cep)
                                                }
                                                startIcon={
                                                    useClientPkgId ===
                                                    cep.id ? (
                                                        <CheckOutlinedIcon />
                                                    ) : null
                                                }
                                            >
                                                {`Previously purchased package of ${
                                                    cep.initialQuantity
                                                } sessions - ${
                                                    cep.initialQuantity -
                                                    cep.usedQuantity
                                                } of ${
                                                    cep.initialQuantity
                                                } remaining`}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                    {bookingState &&
                        bookingState.osd &&
                        (location.id !== "0" || place_id !== null) && (
                            <Grid container spacing={1} alignItems="center">
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{
                                            marginLeft: "8px"
                                        }}
                                    >
                                        <b>Order Summary</b>
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ marginLeft: "8px" }}
                                    >
                                        {!(payType === "payPackage") ? (
                                            <b>
                                                {bookingState.osd.rows[0].qty}
                                                {" x "}
                                                {
                                                    bookingState.osd.rows[0]
                                                        .service
                                                }
                                            </b>
                                        ) : (
                                            <b>
                                                {`${bookingState?.serviceQty1} x ${bookingState?.serviceType.name}`}
                                            </b>
                                        )}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                    {!(payType === "payPackage") ? (
                                        <MoneyAmountInput
                                            setAmount={(value) => {
                                                setServicePrice(value);
                                                createOsd(
                                                    bookingState,
                                                    props.company,
                                                    true,
                                                    value,
                                                    serviceFee
                                                );
                                                setCbuTotal(
                                                    bookingState.cbu.total
                                                );
                                            }}
                                            prefix={currencyPrefix}
                                            amount={
                                                bookingState.osd.rows[0]
                                                    .rawPrice
                                            }
                                            disabled={
                                                bookingState.repeatingAppointment
                                            }
                                            name="servicePrice"
                                        />
                                    ) : (
                                        <Typography
                                            variant="body1"
                                            style={{ marginLeft: "8px" }}
                                        >
                                            {bookingState.osd.rows[0].price}
                                        </Typography>
                                    )}
                                </Grid>
                                {(bookingState?.appointmentLocation === "remote"
                                    ? bookingState?.remoteAddressCountryShort?.toUpperCase()
                                    : bookingState?.countryShort?.toUpperCase()) ===
                                "CA" ? (
                                    <>
                                        <ServiceFeeRow
                                            {...{
                                                company: props.company,
                                                setServiceFee,
                                                payType,
                                                bookingState,
                                                servicePrice,
                                                currencyPrefix,
                                                setCbuTotal
                                            }}
                                        />
                                        <TaxRow bookingState={bookingState} />
                                    </>
                                ) : (
                                    <>
                                        <TaxRow bookingState={bookingState} />
                                        <ServiceFeeRow
                                            {...{
                                                company: props.company,
                                                setServiceFee,
                                                payType,
                                                bookingState,
                                                servicePrice,
                                                currencyPrefix,
                                                setCbuTotal
                                            }}
                                        />
                                    </>
                                )}
                                <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ marginLeft: "8px" }}
                                    >
                                        <b> Total</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                    <Typography
                                        variant="body1"
                                        style={{ marginLeft: "8px" }}
                                    >
                                        {bookingState.osd.total}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    {/*  */}
                    {serviceType &&
                        client &&
                        (location?.id !== "0" || place_id !== null) &&
                        (props?.company?.collectpayment === false ||
                            serviceFeeAndServicePriceZero()) &&
                        payType !== "payPackage" && (
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        style={{ margin: "8px" }}
                                        disabled={bookZeroCostServiceClicked}
                                        onClick={async () =>
                                            await bookZeroCostService()
                                        }
                                    >
                                        Book
                                        {bookZeroCostServiceClicked && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classesnew.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    {showStipeForm &&
                        client &&
                        (location.id !== "0" || place_id !== null) && (
                            <StripeChargeCardContainer
                                bookingState={{
                                    cbu: {}, //TODO: remove this of better solution
                                    ...bookingState
                                }}
                                numOfAppts={1}
                                company={globalUser.company}
                                companyId={globalUser.company.id}
                                setBookingCompleted={setBookingCompleted}
                                disableElementsHandler={disableElementsHandler}
                            />
                        )}
                    {/* start of comment */}

                    {bookingState.clientpackage && payType === "payPackage" && (
                        <Grid
                            container
                            sx={{ width: "100%" }}
                            justifyContent={"center"}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                style={{ margin: "8px" }}
                                disabled={
                                    !useClientPkgId || bookPkgCreditClicked
                                }
                                onClick={async () => await bookPkgCredit()}
                            >
                                Book
                                {(!useClientPkgId || bookPkgCreditClicked) && (
                                    <CircularProgress
                                        size={24}
                                        className={classesnew.buttonProgress}
                                    />
                                )}
                            </Button>
                        </Grid>
                    )}
                    {clientPackages &&
                        clientPackages.length > 0 &&
                        payType === "payPackage" &&
                        hidePrevGrids && (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body1"
                                            style={{ marginLeft: "8px" }}
                                        >
                                            Client has following packages with
                                            credits. Please select a client
                                            package to book.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            style={{ marginLeft: "8px" }}
                                        >
                                            1 credit will be booked on the
                                            selected client package.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {clientPackages &&
                                    clientPackages.length &&
                                    clientPackages.map((cep) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            justify="center"
                                        >
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    style={{ margin: "8px" }}
                                                    onClick={() =>
                                                        updateUseCredit(
                                                            cep.id,
                                                            cep
                                                        )
                                                    }
                                                    startIcon={
                                                        useClientPkgId ===
                                                        cep.id ? (
                                                            <CheckOutlinedIcon />
                                                        ) : null
                                                    }
                                                >
                                                    {`Previously purchased package of ${
                                                        cep.initialQuantity
                                                    } sessions - ${
                                                        cep.initialQuantity -
                                                        cep.usedQuantity
                                                    } of ${
                                                        cep.initialQuantity
                                                    } remaining`}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ))}
                                {hidePrevGrids && (
                                    <Grid
                                        container
                                        spacing={2}
                                        justify="center"
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                style={{ margin: "8px" }}
                                                disabled={
                                                    !useClientPkgId ||
                                                    bookPkgCreditClicked
                                                }
                                                onClick={async () =>
                                                    await bookPkgCredit()
                                                }
                                            >
                                                Book package credit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}

                    {/* End of comment */}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="medium"
                        style={{ minHeight: "30px", maxHeight: "30px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        disabled={disableElements}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AdhocBookingFlow;

const ServiceFeeRow = ({
    company,
    payType,
    bookingState,
    currencyPrefix,
    servicePrice,
    setCbuTotal,
    setServiceFee
}) =>
    company?.addServiceFee ? (
        <>
            <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography
                    variant="body1"
                    style={{
                        marginLeft: "8px"
                    }}
                >
                    <b> Service Fee</b>
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "left" }}>
                {!(payType === "payPackage") ? (
                    <MoneyAmountInput
                        setAmount={(value) => {
                            setServiceFee(value);
                            createOsd(
                                bookingState,
                                company,
                                true,
                                servicePrice,
                                value
                            );
                            setCbuTotal(bookingState.cbu.total);
                        }}
                        prefix={currencyPrefix}
                        amount={bookingState.osd.rawServiceFee}
                        name="serviceFee"
                    />
                ) : (
                    <Typography
                        variant="body1"
                        style={{
                            marginLeft: "8px"
                        }}
                    >
                        {bookingState.osd.servicefee}
                    </Typography>
                )}
            </Grid>
        </>
    ) : null;

const TaxRow = ({ bookingState }) => (
    <>
        <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body1" style={{ marginLeft: "8px" }}>
                <b> Tax ({bookingState.osd.taxrate}%)</b>
            </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "left" }}>
            <Typography variant="body1" style={{ marginLeft: "8px" }}>
                {bookingState.osd.taxamount}
            </Typography>
        </Grid>
    </>
);
