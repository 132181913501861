import makeStyles from "@mui/styles/makeStyles";

export const useCompanyStyles = makeStyles((theme) => ({
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    paper: {
        padding: 2
    },
    root: {
        padding: theme.spacing(3, 2)
    },
    paperTable: {
        padding: theme.spacing(3, 2),
        [theme.breakpoints.down("sm")]: {
            marginLeft: "32px"
        }
    },
    fullWidth: {
        width: "100%"
    },
    Tabsheader: {
        boxShadow: "none",
        background: "transparent"
    },
    marginBottom: {
        marginBottom: "20px"
    },
    marginBottomNone: {
        marginBottom: "0px"
    },
    marginFeatureGateCustom: {
        width: "100%"
    },
    dateField: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    tabsunderline: {
        borderBottom: "1px solid #ccc"
    },
    flexDisplay: {
        display: "flex",
        alignItems: "center"
    },
    actionsCellWidth: {
        width: "150px"
    },
    cardScrollContent: {
        maxHeight: "230px",
        minHeight: "230px",
        overflow: "auto",
        marginBottom: "15px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));
