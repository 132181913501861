import React, { useState } from "react";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";

function DeletePromoCodeModal(props) {
    const [openModal, setOpenModal] = useState(props.open);

    const terminatePromoHandler = async () => {
        //this is where the promoData object will be created
        await props.terminatePromoHandler(props.promo);
        props.resetState(); // resets all states
        props.closeAddPromoModal(); //closes AddPromoCodeModal
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        props.handleResetDeleteModal(false);
    };

    return (
        <Dialog
            open={openModal}
            onClose={() => {
                handleCloseModal();
            }}
            maxWidth={"xs"}
        >
            <Grid
                container
                sx={{
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column"
                }}
            >
                <DialogTitle sx={{ paddingBottom: "8px" }}>
                    {"Delete Promo Code?"}
                </DialogTitle>

                <DialogContent sx={{ paddingBottom: "0px", paddingTop: "0px" }}>
                    {" "}
                    Are you sure you want to delete the promo code "
                    <b>{props.promoCodeName}</b>"
                </DialogContent>
                <DialogContent sx={{ paddingBottom: "0px", paddingTop: "8px" }}>
                    {" "}
                    You cannot undo this action.{" "}
                </DialogContent>
                <DialogContent
                    sx={{
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        padding: "20px"
                    }}
                >
                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{ marginRight: "10px" }}
                        onClick={() => handleCloseModal()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        sx={{ marginLeft: "10px" }}
                        endIcon={<DeleteIcon />}
                        onClick={async () => {
                            await terminatePromoHandler();
                        }}
                    >
                        Delete
                    </Button>
                </DialogContent>
            </Grid>
        </Dialog>
    );
}

export default DeletePromoCodeModal;
