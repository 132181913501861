import { graphql, graphqlOperation, postApi, putApi } from "./AmplifyServices";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { execWrite } from "./DBService";
import { chargeCreditCard } from "../billing/BillingCommon";
import { getUserFromCache } from "../user/UserCommon";
const decrementPackageCredit = async (clientpackage) => {
    const updCliPkg = `mutation UpdateClientPackage($input: UpdateClientPackageInput!) {
    updateClientPackage(input: $input) {
      id
    }
  }
  `;

    return await execWrite({
        opname: "updateClientPackage",
        op: updCliPkg,
        input: {
            id: clientpackage.id,
            usedQuantity: clientpackage.usedQuantity - 1,
            servicetypeId: clientpackage.servicetypeId,
            packageId: clientpackage.packageId,
            createdAt: clientpackage.createdAt
        }
    });
};

function getTimeblockEndTime(servicetype, booking) {
    const endtime = new Date();
    const parts = booking.time.split(":");
    endtime.setHours(Number.parseInt(parts[0]));
    endtime.setMinutes(servicetype.minutes + Number.parseInt(parts[1]));
    return `${
        endtime.getHours() < 10 ? "0" + endtime.getHours() : endtime.getHours()
    }:${
        endtime.getMinutes() < 10
            ? "0" + endtime.getMinutes()
            : endtime.getMinutes()
    }`;
}

function getBookingTimeOfAppt(apptDatetime) {
    return `${
        apptDatetime.getHours() < 10
            ? "0" + apptDatetime.getHours()
            : apptDatetime.getHours()
    }:${
        apptDatetime.getMinutes() < 10
            ? "0" + apptDatetime.getMinutes()
            : apptDatetime.getMinutes()
    }`;
}

function getBookingTime(t) {
    //this is not sustainable
    //we need to use UTC timestamps everywhere
    const parts = t.split(":");
    const AMPM = parts[1].split(" ");
    const hourpart = Number.parseInt(parts[0]);
    const minstr = AMPM[0];
    const hour = AMPM[1] === "PM" ? (hourpart % 12) + 12 : hourpart;
    const hourstr = `${hour < 10 ? "0" : ""}${hour}`;
    return `${hourstr}:${minstr}`;
}

const updateClientBalanceTo = async (clientId, balance) => {
    const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
    }
  }`;
    return await execWrite({
        opname: "updateClient",
        op: updateClient,
        input: {
            id: clientId,
            accountbalance: balance
        }
    });
};

//From: _handleCancel in BookingsListForm (+ potentially others)
//Does: Handles refund, updates booking status, returns
//Returns: JSON with success status and message
const cancelBooking = async (booking, cancellationTimeExceeded) => {
    console.log("asdf bookingService's cancelBooking", booking);
    let messages = [];
    try {
        let free = false;
        if (
            booking.initialCharge === 0 &&
            (!booking.totalAddChg || booking.totalAddChg === 0) &&
            (!booking.additionalCharges || booking.additionalCharges === 0)
        ) {
            free = true;
            console.log("asdf it is free, no need to find charge.");
        }

        const updBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
        updateBooking(input: $input) {
          id
          status
          timeblockid
          providerId
          clientId
          client {
            id
            stripeCustomerId
            user {
              emailaddress
            }
          }
          companyId
          order {
            id
            status
            type
            clientpackage {
              id
              servicetypeId
              createdAt
              packageId
              initialQuantity
              usedQuantity
              status
              active            
            }
          }
        }
      }`;

        const bookingTimeblockId = booking.timeblockid;

        //If exists, Do logic for refund.
        if (booking) {
            // We only process the refund if the order type is single, and if the cancellation period has not been missed.
            // We only decrement credits used if the order type is package, and if the cancellation period has not been missed.
            // We charge the client for the cancelled order if the the order type is ongoing and the cancellation period has been missed.

            //refund if order type single. decrement credit if order type package
            if (
                booking.orderType === "SINGLE" &&
                !cancellationTimeExceeded &&
                !free
            ) {
                // 1. get clientcharge by order
                // 2. createbillingtransaction
                // 3. apply refund
                // 4. update refund charge id in clientcharge table

                let clientChargesOfOrder = await graphql(
                    graphqlOperation(queries.clientChargeByOrder, {
                        orderId: booking.orderId
                    })
                );
                console.log("asdf client charges", clientChargesOfOrder);
                if (
                    clientChargesOfOrder.data.clientChargeByOrder &&
                    clientChargesOfOrder.data.clientChargeByOrder.items &&
                    clientChargesOfOrder.data.clientChargeByOrder.items.length >
                        0
                ) {
                    //SINGLE type must have only one clientcharge record
                    const clientCharge =
                        clientChargesOfOrder.data.clientChargeByOrder.items[0];
                    if (
                        clientCharge.stripepaymentmethod.startsWith("card_") &&
                        clientCharge.amount > 0
                    ) {
                        console.log("asdf client has paid, give refund");

                        // Claculate the amount to refund to the client
                        let refund_left = clientCharge.amount;
                        for (
                            let i = 1;
                            i <
                            clientChargesOfOrder.data.clientChargeByOrder.items
                                .length;
                            i++
                        ) {
                            let clientRefund =
                                clientChargesOfOrder.data.clientChargeByOrder
                                    .items[i];
                            if (
                                clientCharge.stripechargeid ===
                                clientRefund.stripe_refund_charge
                            ) {
                                refund_left -= clientRefund.amount_refunded;
                            }
                        }

                        //TODO: do we need to match client's current card with stripepaymentmethod?
                        //create billing transaction
                        let input = {
                            billingTransactionClientId: booking.clientId,
                            clientId: booking.clientId,
                            amount: refund_left.toFixed(2),
                            billingTransactionCreatorId: booking.cancelledBy,
                            creatorId: booking.cancelledBy,
                            billingTransactionCompanyId: booking.companyId,
                            companyId: booking.companyId,
                            desc: "Refund single booking cancellation",
                            credit: true,
                            billingTransactionOrderId: booking.orderId,
                            orderId: booking.orderId
                        };

                        const result = await graphql(
                            graphqlOperation(
                                mutations.createBillingTransaction,
                                { input }
                            )
                        );
                        console.log("asdf charge result", result);
                        if (result && result.data) {
                            console.log("*** asdf result = ", result);
                            const billingTransactionId =
                                result.data.createBillingTransaction.id;
                            // data saved correctly, now update Stripe to actually charge client.
                            const stripe_result = await chargeCreditCard(
                                booking.client.stripeCustomerId,
                                getStripeAmount(
                                    refund_left == 0
                                        ? clientCharge.amount
                                        : refund_left
                                ),
                                clientCharge.currency,
                                booking.client.user.emailaddress,
                                true,
                                clientCharge.stripechargeid,
                                booking.companyId,
                                booking.company.stripeAccount,
                                booking.company.stripeConnectEnabled
                            );
                            console.log("asdf stripe_result = ", stripe_result);

                            //If stripe returns failure from already processed
                            if (
                                stripe_result &&
                                stripe_result.result &&
                                stripe_result.result.raw &&
                                stripe_result.result.raw.code &&
                                stripe_result.result.raw.code ===
                                    "charge_already_refunded"
                            ) {
                                console.log("asdf ALREADY REFUNDED!");
                                messages.push(
                                    "This charge has already been refunded!"
                                );
                                return {
                                    success: false,
                                    refunded: true,
                                    messages
                                };
                            }

                            // now we can save the data to the ClientCharge table
                            const dateNow = new Date();
                            const options = { dateStyle: "long" };
                            const dateNowString =
                                dateNow.toLocaleDateString("en-US", options) + // use en-US for now
                                " " +
                                dateNow.toLocaleTimeString();
                            input = {
                                clientId: booking.client.id,
                                clientChargeClientId: booking.client.id,
                                companyId: booking.companyId,
                                description:
                                    "Refund of single booking on " +
                                    dateNowString,
                                status: "created",
                                stripechargeid: stripe_result.refund.id,
                                stripepaymentmethod: "refund",
                                stripe_payment_method_details: "refund",
                                balance_transaction:
                                    stripe_result.refund.balance_transaction,
                                stripe_refund_charge:
                                    stripe_result.refund.charge,
                                stripe_status: stripe_result.refund.status,
                                clientChargeBillingtransactionId:
                                    billingTransactionId,
                                billingtransactionId: billingTransactionId,
                                amount: 0,
                                amount_refunded: refund_left.toFixed(2),
                                currency: clientCharge.currency,
                                clientChargeOrderId: booking.orderId,
                                orderId: booking.orderId
                            };

                            await graphql(
                                graphqlOperation(mutations.createClientCharge, {
                                    input
                                })
                            );
                            messages.push("Refund processed successfully.");
                            console.log("asdf refund processed successfully!");
                        } else {
                            messages.push("Error: Could not process refund.");
                            console.log("asdf could not process refund!");

                            //Error processing refund; create message that could not process refund
                            return {
                                success: false,
                                messages
                            };
                        }
                    }
                } else {
                    if (booking.company?.collectpayment) {
                        //Previous payment charge not found is the error to display.
                        messages.push(
                            "Error: previous payment charge is not found."
                        );
                        return {
                            success: false,
                            messages
                        };
                    }
                }
            } else if (
                booking.orderType === "PACKAGE" &&
                !cancellationTimeExceeded
            ) {
                console.log("package and not exceeded time");
                // Refund credit to package.
                if (
                    booking.order.clientpackage &&
                    "paid" === booking.order.clientpackage.status &&
                    true === booking.order.clientpackage.active &&
                    booking.order.clientpackage.usedQuantity > 0
                ) {
                    //update clientpackage to decrement
                    const resp = await decrementPackageCredit(
                        booking.order.clientpackage
                    );
                    if (resp) {
                        //Refund for package type went successfully message
                        console.log("decremented package used credits", resp);
                        messages.push("Added credit back to the package.");
                    }
                }
            } else if (booking.orderType === "ONGOING" && !free) {
                console.log("asdf ongoing");
                // process a refund for the initial charge if the user cancels within the cancellation period
                console.log(
                    "inside ongoing order",
                    booking,
                    cancellationTimeExceeded
                );

                const isBookingPaid = await checkIfBookingIsPaid(booking);
                console.log("is booking paid?", isBookingPaid);

                if (!cancellationTimeExceeded && isBookingPaid) {
                    // 1. get clientcharge by order
                    // 2. createbillingtransaction
                    // 3. apply refund
                    // 4. update refund charge id in clientcharge table
                    const clientChargesOfOrder = await graphql(
                        graphqlOperation(queries.clientChargeByOrder, {
                            orderId: booking.orderId
                        })
                    );
                    console.log(
                        "refunding a repeating appointment",
                        clientChargesOfOrder
                    );
                    if (
                        clientChargesOfOrder.data.clientChargeByOrder &&
                        clientChargesOfOrder.data.clientChargeByOrder.items &&
                        clientChargesOfOrder.data.clientChargeByOrder.items
                            .length > 0
                    ) {
                        // We refund only the first charge
                        const clientCharge =
                            clientChargesOfOrder.data.clientChargeByOrder
                                .items[0];
                        if (
                            clientCharge.stripepaymentmethod.startsWith(
                                "card_"
                            ) &&
                            clientCharge.amount > 0
                        ) {
                            let input = {
                                billingTransactionClientId: booking.clientId,
                                clientId: booking.clientId,
                                amount: clientCharge.amount,
                                billingTransactionCreatorId:
                                    booking.cancelledBy,
                                creatorId: booking.cancelledBy,
                                billingTransactionCompanyId: booking.companyId,
                                companyId: booking.companyId,
                                desc: "Refund first repeated booking cancellation",
                                credit: true,
                                billingTransactionOrderId: booking.orderId,
                                orderId: booking.orderId
                            };

                            const result = await graphql(
                                graphqlOperation(
                                    mutations.createBillingTransaction,
                                    { input }
                                )
                            );

                            if (result && result.data) {
                                console.log(
                                    "*** asdf result = " +
                                        JSON.stringify(result)
                                );
                                const billingTransactionId =
                                    result.data.createBillingTransaction.id;
                                // data saved correctly, now update Stripe
                                const stripe_result = await chargeCreditCard(
                                    booking.client.stripeCustomerId,
                                    getStripeAmount(clientCharge.amount),
                                    clientCharge.currency,
                                    booking.client.user.emailaddress,
                                    true,
                                    clientCharge.stripechargeid,
                                    booking.companyId,
                                    booking.company.stripeAccount,
                                    booking.company.stripeConnectEnabled
                                );
                                console.log(
                                    "asdf stripe_result = ",
                                    stripe_result
                                );

                                //If stripe returns failure from already processed
                                if (
                                    stripe_result &&
                                    stripe_result.result &&
                                    stripe_result.result.raw &&
                                    stripe_result.result.raw.code &&
                                    stripe_result.result.raw.code ===
                                        "charge_already_refunded"
                                ) {
                                    console.log("asdf ALREADY REFUNDED!");
                                    messages.push(
                                        "This charge has already been refunded!"
                                    );
                                    return {
                                        success: false,
                                        refunded: true,
                                        messages
                                    };
                                }

                                // now we can save the data to the ClientCharge table
                                const dateNow = new Date();
                                const options = { dateStyle: "long" };
                                const dateNowString =
                                    dateNow.toLocaleDateString(
                                        "en-US",
                                        options
                                    ) + // use en-US for now
                                    " " +
                                    dateNow.toLocaleTimeString();
                                input = {
                                    clientId: booking.client.id,
                                    clientChargeClientId: booking.client.id,
                                    companyId: booking.companyId,
                                    description:
                                        "Refund of single repeated booking on " +
                                        dateNowString,
                                    status: "created",
                                    stripechargeid: stripe_result.refund.id,
                                    stripepaymentmethod: "refund",
                                    stripe_payment_method_details: "refund",
                                    balance_transaction:
                                        stripe_result.refund
                                            .balance_transaction,
                                    stripe_refund_charge:
                                        stripe_result.refund.charge,
                                    stripe_status: stripe_result.refund.status,
                                    clientChargeBillingtransactionId:
                                        billingTransactionId,
                                    billingtransactionId: billingTransactionId,
                                    amount: 0,
                                    amount_refunded: clientCharge.amount,
                                    currency: clientCharge.currency,
                                    clientChargeOrderId: booking.orderId,
                                    orderId: booking.orderId
                                };

                                await graphql(
                                    graphqlOperation(
                                        mutations.createClientCharge,
                                        {
                                            input
                                        }
                                    )
                                );
                                //Ongoing type appointment, processed succesfully message.
                                messages.push("Refund processed successfully.");
                            } else {
                                //Ongoing type appointment, processing failed.
                                messages.push(
                                    "Error: Could not process refund."
                                );
                                return {
                                    success: false,
                                    messages
                                };
                            }
                        }
                    } else {
                        if (booking.company?.collectpayment) {
                            //Ongoing type appointment, payment charge not found for repeated
                            messages.push(
                                "Error: first payment charge is not found for repeated booking."
                            );
                            return {
                                success: false,
                                messages
                            };
                        }
                    }
                } else if (cancellationTimeExceeded) {
                    // charge the client for the cancelled repeated booking by rewriting the status of the booking
                    // Set booking status to "CHARGEFORCANCELLATION"
                    console.log(
                        "Charging the client for cancelling repeated booking",
                        booking
                    );

                    const user = getUserFromCache();

                    // const body = {
                    //     body: {
                    //         bookingId: booking.id,
                    //         timeblockid: booking.timeblockid,
                    //         startdate: booking.startdate,
                    //         orderId: booking.order.id,
                    //         status: "CHARGEFORCANCELLATION",
                    //         actionUser: user
                    //     }
                    // };
                    // console.log("booking charge body", body);

                    //This processes the booking's completion, which makes sense right now but changes to booking may ruin this
                    const result = await putApi("bookingapi", "/booking", {
                        body: {
                            bookingId: booking.id,
                            timeblockid: booking.timeblockid,
                            startdate: booking.startdate,
                            orderId: booking.order.id,
                            status: "CANCELLED",
                            actionUser: user
                        }
                    });
                    console.log(
                        "Cancel repeating bkgresp: " + JSON.stringify(result)
                    );
                    messages.push(
                        "Cancellation charge processed successfully."
                    );

                    //Do not return this yet,  need to cancel the current booking and cancel scheduletimeblock
                    // return {
                    //     success: true,
                    //     result: result,
                    //     messages
                    // };
                } else {
                    //ongoing order is not paid, and not free therefore no need to reverse a transaction when cancelling.
                }
            }
        }
        console.log("asdf payment/refund action succeeded!");

        //Realisitically, all of below is identical to handleCancelNoRefund code.
        //Consider removing below and instead calling handleCancelNoRefund

        //If you've reached here, then the payment/refund action succeeded
        //Therefore, now you can continue and cancel the booking.
        const bkgresp = await execWrite({
            opname: "updateBooking",
            op: updBooking,
            input: {
                id: booking.id,
                status: "CANCELLED",
                timeblockid: booking.timeblockid,
                startdate: booking.startdate,
                cancelledAt: booking.cancelledAt,
                bookingCancelledById: booking.cancelledBy
            }
        });
        console.log("asdf Cancel bkgresp: ", bkgresp);
        if (bkgresp && bkgresp.status === "CANCELLED") {
            messages.push("Booking is cancelled successfully.");
            console.log("asdf booking is cancelled succesfully!");
        } else {
            return {
                success: false,
                messages: ["Booking could not be cancelled."]
            };
        }

        //cancel zoom meeting if virtual
        try {
            if (booking.isVirtual && booking.virtualMeetingInfo) {
                const vminfo = JSON.parse(booking.virtualMeetingInfo);
                if (vminfo && vminfo.id) {
                    try {
                        const result = await postApi(
                            "virtualmeeting",
                            "/meetings",
                            {
                                body: {
                                    vendor: "zoom",
                                    action: "cancel_meeting",
                                    data: {
                                        companyId: bkgresp.companyId,
                                        id: vminfo.id
                                    }
                                }
                            }
                        );
                        if (result)
                            messages.push(
                                " Your virtual meeting is cancelled."
                            );
                    } catch (e) {
                        console.log("error while deleting zoom meeting", e);
                    }
                }
            }
        } catch (e) {
            console.log("error while deleting zoom meeting", e);
        }

        console.log("asdf booking timeblock update");
        if (bookingTimeblockId) {
            //do cancel scheduletimeblock
            const tbpksk = bkgresp.timeblockid.split("::");
            const updatetb = `mutation UpdateProviderSchedule($input: UpdateProviderScheduleInput!) {
            updateProviderSchedule(input: $input) {
            id
            scheduleinfo
            status
          }
        }`;
            const tbcancelresp = await execWrite({
                opname: "updateProviderSchedule",
                op: updatetb,
                input: {
                    id: tbpksk[0],
                    scheduleinfo: tbpksk[1],
                    status: "CANCELLED"
                }
            });
            if (tbcancelresp) {
                console.log("asdf tbcancelresp", tbcancelresp);
            }
        }

        return {
            success: true,
            result: bkgresp,
            messages
        };
    } catch (e) {
        console.log("asdf unexpected error while cancelling booking: ", e);
        return {
            success: false,
            messages: messages.concat([
                "An unexpected error occurred while cancelling booking! " + e
            ])
        };
    }
};

//From: handleContinueCancellation
//Does: Cancels a specific booking but does not attempt to apply refund / charges
//Returns: JSON with success status and messages
async function handleCancelNoRefund(booking) {
    let messages = [];
    try {
        const updBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
                updateBooking(input: $input) {
                  id
                  status
                  timeblockid
                  providerId
                  clientId
                  client {
                    id
                    stripeCustomerId
                    user {
                      emailaddress
                    }
                  }
                  companyId
                  order {
                    id
                    status
                    type
                    clientpackage {
                      id
                      servicetypeId
                      createdAt
                      packageId
                      initialQuantity
                      usedQuantity
                      status
                      active            
                    }
                  }
                }
              }`;

        const bookingTimeblockId = booking.timeblockid;

        const bkgresp = await execWrite({
            opname: "updateBooking",
            op: updBooking,
            input: {
                id: booking.id,
                status: "CANCELLED",
                timeblockid: booking.timeblockid,
                startdate: booking.startdate,
                cancelledAt: booking.cancelledAt,
                bookingCancelledById: booking.cancelledBy
            }
        });
        console.log("asdf Cancel bkgresp: ", bkgresp);

        if (bkgresp && bkgresp.status === "CANCELLED") {
            messages.push("Booking is cancelled successfully.");
            console.log("asdf booking is cancelled succesfully!");
        } else {
            return {
                success: false,
                messages: ["Refunded booking could not be cancelled."]
            };
        }

        //cancel zoom meeting if virtual
        try {
            if (booking.isVirtual && booking.virtualMeetingInfo) {
                const vminfo = JSON.parse(booking.virtualMeetingInfo);
                if (vminfo && vminfo.id) {
                    try {
                        const result = await postApi(
                            "virtualmeeting",
                            "/meetings",
                            {
                                body: {
                                    vendor: "zoom",
                                    action: "cancel_meeting",
                                    data: {
                                        companyId: bkgresp.companyId,
                                        id: vminfo.id
                                    }
                                }
                            }
                        );
                        if (result)
                            messages.push(
                                " Your virtual meeting is cancelled."
                            );
                    } catch (e) {
                        console.log(
                            "Unexpected error while deleting zoom meeting for refunded booking",
                            e
                        );
                    }
                }
            }
        } catch (e) {
            console.log(
                "Unexpected error while deleting zoom meeting for generic refunded booking",
                e
            );
        }

        console.log("asdf booking timeblock update");
        if (bookingTimeblockId) {
            //do cancel scheduletimeblock
            const tbpksk = bkgresp.timeblockid.split("::");
            const updatetb = `mutation UpdateProviderSchedule($input: UpdateProviderScheduleInput!) {
                updateProviderSchedule(input: $input) {
                id
                scheduleinfo
                status
                }
            }`;
            const tbcancelresp = await execWrite({
                opname: "updateProviderSchedule",
                op: updatetb,
                input: {
                    id: tbpksk[0],
                    scheduleinfo: tbpksk[1],
                    status: "CANCELLED"
                }
            });
            if (tbcancelresp) {
                console.log("asdf tbcancelresp", tbcancelresp);
            }
        }
        return {
            success: true,
            result: bkgresp,
            messages
        };
    } catch (e) {
        console.log("asdf unexpected error occurred!");
    }
}

function getStripeAmount(num) {
    let num_check = (parseFloat(num) * 100).toString();
    const decimal_pos = num_check.indexOf(".");
    if (decimal_pos === -1) {
        return parseInt(num_check);
    } else {
        const rounded_num = num_check.charAt(decimal_pos + 1);
        if (parseInt(rounded_num) >= 5) {
            return parseInt(num_check) + 1;
        } else if (parseInt(rounded_num) < 5) {
            return parseInt(num_check);
        }
    }
}

const saveNoshowBooking = async (booking) => {
    const updBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    id
    status
  }
}`;
    return await execWrite({
        opname: "updateBooking",
        op: updBooking,
        input: {
            id: booking.id,
            status: "NOSHOW",
            timeblockid: booking.timeblockid,
            startdate: booking.startdate,
            noshowAt: booking.noshowAt,
            bookingNoshowById: booking.noshowBy
        }
    });
};

async function getBookingReqDetails(br) {
    console.log(JSON.stringify(br.data));
    //get Logs
    const logs = await graphql(
        graphqlOperation(queries.listBookingRequests, {
            id: `${br.id}|${br.recordType}`,
            recordType: { beginsWith: "LOG|" },
            sortDirection: "DESC"
        })
    );
    if (logs && logs.data.listBookingRequests) {
        for (let l of logs.data.listBookingRequests.items) {
            l.data = JSON.parse(l.data);
        }
        return logs.data.listBookingRequests.items;
    } else return [];

    //BR_STATUS_CHANGE, SCHEDULE_REQUEST, SCHEDULE_BROADCAST, SMS_conactType, REPLY_SMS, BR_MGMT

    //get original provider contact
    // const origProvContact = await graphql(
    //     graphqlOperation(queries.getBookingRequest, {
    //         id: `P-${br.providerId}`,
    //         recordType: `${br.id}|${br.recordType}`
    //     })
    // );

    // if (origProvContact && origProvContact.data.getBookingRequest) {
    //     console.log(JSON.stringify(origProvContact.data.getBookingRequest));
    // }
    // //get broadcasted provs contacts
    // if (br.data.broadcastedProvs && br.data.broadcastedProvs.length) {
    //     for (let provId of br.data.broadcastedProvs) {
    //         let brdcasProvContact = await graphql(
    //             graphqlOperation(queries.getBookingRequest, {
    //                 id: `P-${provId}`,
    //                 recordType: `${br.id}|${br.recordType}`
    //             })
    //         );

    //         if (brdcasProvContact && brdcasProvContact.data.getBookingRequest) {
    //             console.log(
    //                 JSON.stringify(brdcasProvContact.data.getBookingRequest)
    //             );
    //         }
    //     }
    // }
}
async function checkIfBookingIsPaid(bookingData) {
    let yesno = false;
    const input = {
        orderId: bookingData.order.id,
        bookingId: bookingData.id
    };
    const resp = await graphql(
        graphqlOperation(queries.getRepeatApptCharge, input)
    );
    console.log("getRepeatApptCharge resp", resp);

    if (resp.data && resp.data.getRepeatApptCharge) {
        console.log("repeatApptCharge", resp.data.getRepeatApptCharge);
        if (resp.data.getRepeatApptCharge.bookingId === bookingData.id) {
            yesno = true;
        }
    }
    return yesno;
}
export {
    cancelBooking,
    saveNoshowBooking,
    updateClientBalanceTo,
    getTimeblockEndTime,
    getBookingTime,
    getBookingReqDetails,
    handleCancelNoRefund
};
