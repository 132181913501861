import { graphql, graphqlOperation } from "../../modules/AmplifyServices";

async function execWrite(query) {
    try {
        const { opname, op, input } = query;
        const resp = await graphql(graphqlOperation(op, { input: input }));
        if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
            return {
                ...resp.data[opname]
            };
        } else {
            return {
                error: "something went wrong please try again later"
            };
        }
    } catch (error) {
        return {
            error
        };
    }
}

async function execRead(query) {
    try {
        const { opname, op, filter, limit } = query;
        let resp = null;
        let nextToken = null;
        const items = [];
        do {
            resp = await graphql(
                graphqlOperation(op, {
                    filter,
                    ...(limit
                        ? { limit }
                        : { limit: process.env.REACT_APP_LISTLIMIT }),
                    nextToken
                })
            );
            if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
                //TODO: check if hasOwnProperty should be used or [opname]
                items.push(...resp.data[opname].items);
                nextToken = resp.data[opname].nextToken;
            } else {
                return {
                    error: "something went wrong please try again later"
                };
            }
        } while (nextToken);
        return {
            items
        };
    } catch (error) {
        return {
            error
        };
    }
}

async function execReadBySortkey(query) {
    try {
        const { opname, op, id, skey, filter, limit, sortDirection } = query;
        let resp = null;
        let nextToken = null;
        const items = [];
        do {
            console.log("nexttoken:" + nextToken);
            resp = await graphql(
                graphqlOperation(op, {
                    ...id,
                    ...skey,
                    filter,
                    ...(limit
                        ? { limit }
                        : { limit: process.env.REACT_APP_LISTLIMIT }),
                    ...(sortDirection
                        ? { sortDirection }
                        : { sortDirection: "ASC" }),
                    nextToken
                })
            );
            console.log(resp);
            if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
                //TODO: check if hasOwnProperty should be used or [opname]
                items.push(...resp.data[opname].items);
                nextToken = resp.data[opname].nextToken;
            } else {
                return {
                    error: "something went wrong please try again later"
                };
            }
        } while (nextToken);
        return {
            items
        };
    } catch (error) {
        return {
            error
        };
    }
}

async function execReadByPK(query) {
    try {
        const { opname, op, id, filter, limit, sortDirection } = query;
        let resp = null;
        let nextToken = null;
        const items = [];
        do {
            console.log("nexttoken:" + nextToken);
            resp = await graphql(
                graphqlOperation(op, {
                    ...id,
                    filter,
                    ...(limit
                        ? { limit }
                        : { limit: process.env.REACT_APP_LISTLIMIT }),
                    ...(sortDirection
                        ? { sortDirection }
                        : { sortDirection: "ASC" }),
                    nextToken
                })
            );
            if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
                //TODO: check if hasOwnProperty should be used or [opname]
                items.push(...resp.data[opname].items);
                nextToken = resp.data[opname].nextToken;
            } else {
                return {
                    error: "something went wrong please try again later"
                };
            }
        } while (nextToken);
        return {
            items
        };
    } catch (error) {
        return {
            error
        };
    }
}

async function execReadByPKFromToken(
    query,
    token,
    cachedResults,
    maxRespPerQuery
) {
    // Returned items is cachedResults and resp added
    try {
        const { opname, op, id, filter, limit, sortDirection } = query;
        let resp = null;
        let nextToken = token;
        let items = [];
        let cachedResponse = cachedResults;

        do {
            console.log("nexttoken:" + nextToken);

            if (items.length <= maxRespPerQuery) {
                resp = await graphql(
                    graphqlOperation(op, {
                        ...id,
                        filter,
                        ...(limit
                            ? { limit }
                            : { limit: process.env.REACT_APP_LISTLIMIT }),
                        ...(sortDirection
                            ? { sortDirection }
                            : { sortDirection: "ASC" }),
                        nextToken
                    })
                );
                if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
                    items.push(...cachedResponse);
                    items.push(...resp.data[opname].items);
                    nextToken = resp.data[opname].nextToken;
                    cachedResponse = [];
                } else {
                    return {
                        error: "something went wrong please try again later"
                    };
                }
            } else {
                break;
            }
        } while (nextToken && cachedResponse.length === 0);

        if (items.length > maxRespPerQuery) {
            cachedResponse = items.slice(maxRespPerQuery);
            items = items.slice(0, maxRespPerQuery);
        }
        // CachedResponse is
        return {
            items,
            token: nextToken,
            cachedResponse
        };
    } catch (error) {
        return {
            error
        };
    }
}

async function execReadBySortkeyFromToken(query, token, cachedResults) {
    try {
        const { opname, op, id, skey, filter, limit, sortDirection } = query;
        let resp = null;
        let nextToken = token;
        let items = [];
        let cachedResponse = cachedResults;
        do {
            console.log("nexttoken:" + nextToken);

            resp = await graphql(
                graphqlOperation(op, {
                    ...id,
                    ...skey,
                    filter,
                    ...(limit
                        ? { limit }
                        : { limit: process.env.REACT_APP_LISTLIMIT }),
                    ...(sortDirection
                        ? { sortDirection }
                        : { sortDirection: "ASC" }),
                    nextToken
                })
            );
            console.log("resp check", resp);
            if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
                items.push(...cachedResponse);
                //TODO: check if hasOwnProperty should be used or [opname]
                items.push(...resp.data[opname].items);
                nextToken = resp.data[opname].nextToken;
            } else {
                return {
                    error: "something went wrong please try again later"
                };
            }
        } while (nextToken && !items);
        return {
            items,
            token: nextToken
        };
    } catch (error) {
        return {
            error
        };
    }
}

async function execReadBySortkeyFromTokenLimited(
    query,
    token,
    cachedResults,
    maxRespPerQuery
) {
    try {
        const { opname, op, id, skey, filter, limit, sortDirection } = query;
        let resp = null;
        let nextToken = token;
        let items = [];
        let cachedResponse = cachedResults;
        do {
            console.log("nexttoken:" + nextToken);

            if (items.length <= maxRespPerQuery) {
                resp = await graphql(
                    graphqlOperation(op, {
                        ...id,
                        ...skey,
                        filter,
                        ...(limit
                            ? { limit }
                            : { limit: process.env.REACT_APP_LISTLIMIT }),
                        ...(sortDirection
                            ? { sortDirection }
                            : { sortDirection: "ASC" }),
                        nextToken
                    })
                );
                if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
                    //TODO: check if hasOwnProperty should be used or [opname]
                    nextToken = resp.data[opname].nextToken;
                    items.push(...resp.data[opname].items);
                    items.push(...cachedResponse);
                } else {
                    return {
                        error: "something went wrong please try again later"
                    };
                }
            } else {
                break;
            }
        } while (nextToken);

        if (items.length > maxRespPerQuery) {
            cachedResponse = items.slice(maxRespPerQuery);
            items = items.slice(0, maxRespPerQuery);
        }
        return {
            items,
            token: nextToken,
            cachedResponse
        };
    } catch (error) {
        return {
            error
        };
    }
}

export {
    execRead,
    execWrite,
    execReadBySortkey,
    execReadByPK,
    execReadByPKFromToken,
    execReadBySortkeyFromToken,
    execReadBySortkeyFromTokenLimited
};
