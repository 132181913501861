import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../modules/AmplifyServices";
import { uploadData, getUrl } from "aws-amplify/storage";
import { CustomTooltip, CustomBasicTooltip } from "../styles/CustomMuiStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    Paper,
    Grid,
    Tab,
    FormControl,
    FormControlLabel,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment
} from "@mui/material";
import {
    TextField,
    Box,
    Button,
    Snackbar,
    Input,
    InputLabel,
    Tooltip,
    MenuItem,
    Select,
    Switch,
    Chip,
    Avatar
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { userByCompany } from "../queries/UserQueries";
import { useStyles } from "../styles/ProviderAddEditStyles";
import withStyles from "@mui/styles/withStyles";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import Rating from "@mui/material/Rating";
import {
    getProviderExtended,
    providerByPermalink
} from "../queries/ProviderAddEditQueries";
import AddressForm from "../components/address/AddressForm";
import validator from "validator";
import { validatePhoneNumber } from "../onboarding/modules/ValidityCheck";
import uuid from "uuid/v4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    getCompanyAdmins,
    userHasAdminRole,
    userHasProviderOnlyRole
} from "../user/UserCommon";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiPhoneNumber from "material-ui-phone-number";
import { doTheseServicesHaveSchedules } from "../modules/ScheduleService";
import {
    auditProviderUpdate,
    auditProviderCreate,
    auditUserUpdate
} from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import imageCompression from "browser-image-compression";
import { TimezoneList } from "../components/TimezoneList";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getUserFromCache, updateProvider } from "../user/UserCommon";
import voucher_codes from "voucher-code-generator";
import { auditUserCreate } from "../modules/Audit";
import * as Sentry from "@sentry/react";
import PasswordManagementModal from "../components/PasswordManagmentModal";
import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import { getCognitoUser, updateCognitoUser } from "../user/CognitoUserCommon";
import { sendRegistrationEmail } from "../modules/MessagingService";
import { useNavigate } from "react-router-dom";
import { OUTLET_PAGE } from "../context/reducers";

const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function ProviderAddEdit(props) {
    const navigate = useNavigate();
    const { state, dispatch, actions } = useContext(StoreContext);
    const [serviceTypeData, setServiceTypeData] = useState();
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const classes = useStyles();
    const logger = new ConsoleLogger("ProviderAddEdit");
    // tabs
    const [tabValue, setTabValue] = useState("0");

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    // fields
    const [id, setId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [originalEmailAddress, setOriginalEmailAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [services, setServices] = useState([]);
    const [skillsData, setSkillsData] = useState([]);
    const [oldServices, setOldServices] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [skills, setSkills] = useState([]);
    const [oldSkills, setOldSkills] = useState([]);
    const [travelDistance, setTravelDistance] = useState(25);
    const [travelDistanceUnit, setTravelDistanceUnit] = useState("km");
    const [blockHolidays, setBlockHolidays] = useState(false);
    const [blockWeekends, setBlockWeekends] = useState(false);
    const [active, setActive] = useState(true);
    const [userActive, setUserActive] = useState(true);
    const [role, setRole] = useState(state.mode === "Add" ? "PROVIDER" : "");
    const [addressOneLine, setAddressOneLine] = useState("");
    const [addressStreet, setAddressStreet] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [addressPostal, setAddressPostal] = useState("");
    const [addressCountry, setAddressCountry] = useState("");
    const [longitude, setLongitude] = useState(0);
    const [latitude, setLatitude] = useState(0);
    const [bio, setBio] = useState("");
    const [file, updateFile] = useState(null);
    const [createdAt, setCreatedAt] = useState();
    const [pictureUrl, setPictureUrl] = useState("");
    const [s3ObjectKey, setS3ObjectKey] = useState("");
    const [additionalValues, setAdditionalValues] = useState({});
    const [additionalImage, setAdditionalImage] = useState("");
    const [maxtraveltype, setMaxtraveltype] = useState("DRAW_TRAVEL_REGION");
    const [origMaxtraveltype, setOrigMaxtraveltype] = useState();
    const [showTravelZoneChangeMessage, setShowTravelChangeMessage] =
        useState(false);
    const [offersVirtualServices, setOffersVirtualServices] = useState(false);
    const [vmlink, setVmlink] = useState("");
    const [providerTimezone, setProviderTimezone] = useState(BROWSER_TZ);
    const [disableSave, setDisableSave] = useState(false);
    const [permalink, setPermalink] = useState("");
    const [oldInfo, setOldInfo] = useState([]);
    const [emailChangeWarningModal, setEmailChangeWarningModal] =
        useState(false);
    //Additional Photos
    const [additionalDialog, setAdditionalDialog] = useState(false);
    const [disable, setDisable] = useState(false);
    const [carousel, setCrousel] = useState([]);
    const [ratingstarsavg, setRatingstarsavg] = useState(0);
    const [numberofratings, setNumberOfRatings] = useState(0);
    const [showServiceValidation, setShowServiceValidation] = useState(false);
    const [serviceValidationMessage, setServiceValidationMessage] =
        useState("");
    const [serviceValidationData, setServiceValidationData] = useState({});
    const phoneuser = getUserFromCache();
    const phoneusercompany = phoneuser.company;
    // Acceptance History
    const [totalBookings, setTotalBookings] = useState("");
    const [priBookings, setPriBookings] = useState("");
    const [secBookings, setSecBookings] = useState("");
    const [totalAcceptRate, setTotalAcceptRate] = useState("");
    const [priAcceptRate, setPriAcceptRate] = useState("");
    const [secAcceptRate, setSecAcceptRate] = useState("");
    const [priAcceptTime, setPriAcceptTime] = useState("");
    const [priExactTime, setPriExactTime] = useState("");
    const [secAcceptTime, setSecAcceptTime] = useState("");
    const [secExactTime, setSecExactTime] = useState("");
    const [displayExactTime, setDisplayExactTime] = useState(false);
    const [searchedFor, setSearchedFor] = useState(false);
    const [contactConsent, setContactConsent] = useState(false);
    const [contactConsentOrig, setContactConsentOrig] = useState();
    const [providerUserRec, setProviderUserRec] = useState();
    const classesnew = usePromotionsStyles();

    //Password management states
    const [passwordManagementType, setPasswordManagementType] = useState();
    const [showManagePasswordDialog, setShowManagePasswordDialog] =
        useState(false);
    const [showResetPasswordSpinner, setShowResetPasswordSpinner] =
        useState(false);

    // formatting for services
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    useEffect(() => {
        async function getProviderUserRecord() {
            try {
                const loggedInUser = getUserFromCache();

                const result = await graphql(
                    graphqlOperation(queries.userByProviderId, {
                        companyId: loggedInUser.company.id,
                        providerId: {
                            eq: id
                        }
                    })
                );
                if (result && result.data.userByProviderId?.items?.length) {
                    const providerUser = result.data.userByProviderId.items[0];
                    setContactConsent(
                        providerUser.contactconsent
                            ? providerUser.contactconsent
                            : false
                    );
                    setContactConsentOrig(
                        providerUser.contactconsent
                            ? providerUser.contactconsent
                            : false
                    );
                    setUserActive(providerUser.active);
                    setRole(providerUser.role);
                    setProviderUserRec({ ...providerUser });
                } else {
                    setRole("PROVIDER");
                }
            } catch (e) {
                console.log("error while getting provider contact consent");
            }
        }
        if (id) getProviderUserRecord();
    }, [id]);

    function isEqual(array1, array2) {
        if (array1.length != array2.length) {
            return false;
        } else {
            array1.sort();
            array2.sort();
            for (var i = 0; i < array1.length; i++) {
                if (array1[i] != array2[i]) return false;
            }
            return true;
        }
    }

    function throwEditSaveError(errorText, pageNum) {
        //Numerated beginning at 1 in input, but counting starts at 0. Incredibly tragic. Massive blunder.
        let num = pageNum - 1;

        setSnackMsg("Error: " + errorText);
        setMsgOpen(true);
        setTabValue(num);
        setTabValue(String(num));
        setDisableSave(false);
    }

    // controller to load dropdowns and populate provider if needed
    async function loadForm() {
        // populate list of skills and service types
        const lists = await getCompanyData();
        // get provider data if not in Add mode
        if (state.mode === "Edit" || state.mode === "View") {
            if (state.contextCompanyId) {
                getAcceptanceData();
            }
            getProviderData(lists);
        }
    }

    // data to populate list of service types and skills
    async function getCompanyData() {
        const loggedInUser = getUserFromCache();
        const limit = process.env.REACT_APP_LISTLIMIT;

        let filter = {
            and: [
                { active: { ne: false } },
                { deleted: { ne: true } },
                { companyId: { eq: loggedInUser.company.id } }
            ]
        };

        const result = await graphql(
            graphqlOperation(queries.serviceTypeByCompany, {
                companyId: loggedInUser.company.id,
                filter: {
                    deleted: { ne: true },
                    active: { ne: false },
                    isVisible: { ne: false }
                },
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );
        // sort services
        result.data.serviceTypeByCompany.items.sort((p1, p2) => {
            const psq1 = p1.name.toUpperCase();
            const psq2 = p2.name.toUpperCase();
            if (psq1 < psq2) return -1;
            if (psq1 > psq2) return 1;
            return 0;
        });
        const listofServiceTypes = result.data.serviceTypeByCompany.items;
        setServiceTypeData(listofServiceTypes);

        // get skills data from cache
        // const skillsResult = await graphql(
        //     graphqlOperation(queries.listSkills, { filter, limit })
        // );
        const skillsResult = await execReadByPK({
            opname: "skillByCompany",
            op: queries.skillByCompany,
            id: { companyId: loggedInUser.company.id },
            filter: {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            },
            sortDirection: "DESC"
        });

        // sort skills
        skillsResult.items.sort((p1, p2) => {
            const psq1 = p1.name.toUpperCase();
            const psq2 = p2.name.toUpperCase();
            if (psq1 < psq2) return -1;
            if (psq1 > psq2) return 1;
            return 0;
        });
        const listofSkills = skillsResult.items;
        setSkillsData(listofSkills);
        const lists = { listofServiceTypes, listofSkills };
        return lists;
    }

    async function getProviderData(lists) {
        try {
            let listofServiceTypes = lists.listofServiceTypes;
            let listofSkills = lists.listofSkills;
            const loggedInUser = getUserFromCache();
            let providerId = "";

            logger.debug("*** state = " + JSON.stringify(state));
            // check if this is a provider editing their own profile
            if (userHasProviderOnlyRole()) {
                providerId = loggedInUser.providerId;
            } else {
                providerId = state.id;
            }
            const input = { id: providerId };
            const limit = process.env.REACT_APP_LISTLIMIT;
            let result = {};

            const authuser = getUserFromCache();
            setLoggedInUser(authuser);
            _getProviderPhotos();
            result = await graphql(
                // need to use custom query to get servicetypes and skills
                graphqlOperation(getProviderExtended, input)
            );
            const providerData = result.data.getProvider;
            logger.debug(
                "result for providerData = " + JSON.stringify(providerData)
            );

            // populate form state
            if (providerData) {
                setId(providerData.id);
                setPermalink(
                    providerData.permalink ? providerData.permalink : ""
                );
                setFirstName(providerData.firstname);
                setLastName(providerData.lastname);
                setEmail(providerData.emailaddress);
                setOriginalEmailAddress(providerData.emailaddress);
                setPhone(providerData.phone);
                setAddressOneLine(providerData.addressoneline);
                setAddressStreet(providerData.street);
                setAddressCity(providerData.city);
                setAddressState(providerData.state);
                setAddressCountry(providerData.country);
                setAddressPostal(providerData.postalcode);
                setLongitude(providerData.longitude);
                setLatitude(providerData.latitude);
                setBio(providerData.bio);
                setRatingstarsavg(providerData.ratingstarsavg);
                setNumberOfRatings(providerData.numberofratings);
                setOffersVirtualServices(providerData.offersVirtualServices);
                setVmlink(providerData.vmlink);
                // pictureurl
                setTravelDistance(providerData.traveldistance);
                setTravelDistanceUnit(providerData.traveldistanceunit);
                if (providerData.maxtraveltype) {
                    setMaxtraveltype(providerData.maxtraveltype);
                    setOrigMaxtraveltype(providerData.maxtraveltype);
                }

                // company
                setBlockHolidays(providerData.blockHolidays);
                setBlockWeekends(providerData.blockweekends);
                setActive(providerData.active);
                setS3ObjectKey(providerData.pictures3key);
                setCreatedAt(providerData.createdAt);
                // servicetypes
                const servicetypes = listofServiceTypes;
                const stItems = providerData.servicetypes.items;
                setOldServices(stItems);
                let i, j;
                let newServices = [];
                for (i = 0; i < stItems.length; i++) {
                    for (j = 0; j < servicetypes.length; j++) {
                        if (servicetypes[j].id === stItems[i].servicetype.id) {
                            // match so add
                            newServices.push(servicetypes[j].name);
                        }
                    }
                }
                setServices(newServices);

                // skills
                const sks = listofSkills;
                const skItems = providerData.skills.items;
                setOldSkills(skItems);
                let newSkills = [];
                for (i = 0; i < skItems.length; i++) {
                    for (j = 0; j < sks.length; j++) {
                        if (sks[j].id === skItems[i].skill.id) {
                            newSkills.push(sks[j].name);
                        }
                    }
                }
                setSkills(newSkills);

                oldInfo.push({
                    firstname: providerData.firstname,
                    lastname: providerData.lastname,
                    phone: providerData.phone,
                    services: newServices,
                    skills: newSkills,
                    travelType: providerData.maxtraveltype,
                    active: providerData.active,
                    virtualServices: providerData.offersVirtualServices,
                    bio: providerData.bio
                });
                if (providerData.timezone)
                    setProviderTimezone(providerData.timezone);
            }

            // get accessible url for provider picture
            const picresult = await getUrl({ key: providerData.pictures3key });
            setPictureUrl(picresult?.url);
        } catch (err) {
            logger.error(`Error fetching provider `, err);
        }
    }

    async function getAcceptanceData() {
        const loggedInUser = getUserFromCache();
        let providerId = "";

        logger.debug("*** state = " + JSON.stringify(state));
        // check if this is a provider editing their own profile
        if (userHasProviderOnlyRole()) {
            providerId = loggedInUser.providerId;
        } else {
            providerId = state.id;
        }
        const input = { id: providerId };

        const result = await graphql(
            graphqlOperation(queries.getProvider, input)
        );

        const acceptanceData = result.data.getProvider;
        if (acceptanceData) {
            const acceptanceInfo = JSON.parse(
                result.data.getProvider.AcceptanceRatios
            );
            if (acceptanceInfo) {
                let offers = await totalOffers(acceptanceInfo.counts);
                setTotalBookings(offers + " Bookings Offered");
                setPriBookings(
                    acceptanceInfo.counts.offered.pri + " Bookings Offered"
                );
                setSecBookings(
                    acceptanceInfo.counts.offered.sec + " Bookings Offered"
                );

                let totalRate = await getResponseRate(acceptanceInfo, "TOTAL");
                let priRate = await getResponseRate(acceptanceInfo, "PRIMARY");
                let secRate = await getResponseRate(
                    acceptanceInfo,
                    "SECONDARY"
                );
                setTotalAcceptRate(totalRate + "%");
                setPriAcceptRate(priRate + "%");
                setSecAcceptRate(secRate + "%");

                let priTime = await getResponseTime(acceptanceInfo, "PRIMARY");
                let priExact = await getExactTime(acceptanceInfo, "PRIMARY");
                let secTime = await getResponseTime(
                    acceptanceInfo,
                    "SECONDARY"
                );
                let secExact = await getExactTime(acceptanceInfo, "SECONDARY");
                setPriAcceptTime(priTime);
                setPriExactTime(priExact);
                setSecAcceptTime(secTime);
                setSecExactTime(secExact);
            } else {
                setTotalBookings("No Bookings Offered");
                setPriBookings("No Bookings Offered");
                setSecBookings("No Bookings Offered");
                setTotalAcceptRate("N/A");
                setPriAcceptRate("N/A");
                setSecAcceptRate("N/A");
                setPriAcceptTime("N/A");
                setPriExactTime("N/A");
                setSecAcceptTime("N/A");
                setSecExactTime("N/A");
            }
        }
    }

    function totalOffers(offers) {
        let secOffers = offers.offered.sec;
        let priOffers = offers.offered.pri;
        return secOffers + priOffers;
    }

    function getResponseRate(info, status) {
        if (info.avg) {
            if (status === "TOTAL" && info.avg.all_rep_acceptance_ratio != -1) {
                return info.avg.all_rep_acceptance_ratio;
            } else if (
                status === "PRIMARY" &&
                info.avg.pri_rep_acceptance_ratio != -1
            ) {
                return info.avg.pri_rep_acceptance_ratio;
            } else if (
                status === "SECONDARY" &&
                info.avg.sec_rep_acceptance_ratio != -1
            ) {
                return info.avg.sec_rep_acceptance_ratio;
            } else {
                return 0;
            }
        }
    }

    function getResponseTime(info, status) {
        if (info.avg) {
            if (info.avg.pri_rep_mins !== -1 || info.avg.sec_rep_mins !== -1) {
                let mins =
                    status === "PRIMARY"
                        ? info.avg.pri_rep_mins
                        : info.avg.sec_rep_mins;
                if (mins === -1)
                    return `No response time as a ${status.toLowerCase()} provider`;
                if (mins <= 30) return "A few minutes";
                if (mins > 30 && mins <= 60) return "Within an hour";
                let hours = Math.floor(mins / 60);
                if (hours < 3) return "A couple of hours";
                if (hours >= 3) return `${hours} hours`;
                if (hours > 24) {
                    let days = Math.floor(hours / 24);
                    return `${days} days`;
                }
            } else {
                return `No response time as a ${status.toLowerCase()} provider`;
            }
        }
    }

    function getExactTime(info, status) {
        if (info.avg) {
            if (info.avg.pri_rep_mins !== -1 || info.avg.sec_rep_mins !== -1) {
                let mins =
                    status === "PRIMARY"
                        ? info.avg.pri_rep_mins
                        : info.avg.sec_rep_mins;
                if (mins === -1)
                    return `No response time as a ${status.toLowerCase()} provider`;
                if (mins <= 60) return `Average ${mins} minute(s)`;
                let hours = Math.floor(mins / 60);
                if (hours <= 24) return `Average ${hours} hour(s)`;
                if (hours > 24) {
                    let days = Math.floor(hours / 24);
                    return `${days} days`;
                }
            } else {
                return `No response time as a ${status.toLowerCase()} provider`;
            }
        }
    }

    const StyledToggleButtonGroup = withStyles((theme) => ({
        grouped: {
            marginTop: 20,
            border: "1px solid"
        }
    }))(ToggleButtonGroup);

    // load provider data if in edit mode
    useEffect(() => {
        if (sessionStorage.getItem("search") === "true") setSearchedFor(true);
        let mounted = true;
        const abortController = new AbortController();
        if (mounted) {
            loadForm();
        }
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    }, []);

    const _getProviderPhotos = async (e) => {
        let input = { id: id };
        let crousalImages = [];
        if (state.mode === "Edit" || state.mode === "View") {
            let providerPhotos = await execReadByPK({
                opname: "providerPhotosByProvider",
                op: queries.providerPhotosByProvider,
                id: { providerId: state.id },
                sortDirection: "DESC"
            });

            if (providerPhotos.items && providerPhotos.items.length > 0) {
                crousalImages = providerPhotos.items;
            } else {
                crousalImages = additionalValues.s3Images;
            }
        } else {
            crousalImages = additionalValues.s3Images;
        }
        if (!!crousalImages && crousalImages.length > 0) {
            const imgs = [];
            for (const crousalImage of crousalImages) {
                if (crousalImage)
                    await getUrl({ key: crousalImage.s3key }).then((img) => {
                        imgs.push(img?.url);
                    });
            }
            logger.debug("inside resolve", imgs);
            setCrousel(imgs);
        }
    };

    const handleChangeFirstName = (e) => {
        setFirstName(e.target.value);
    };

    const handleChangeVmlink = (e) => {
        setVmlink(e.target.value);
    };

    const handleChangeLastName = (e) => {
        setLastName(e.target.value);
    };

    const handleChangeEmail = (e) => {
        let email = e.target.value;
        email = email.trim().toLowerCase();
        setEmail(email);
    };

    const handleChangePhone = (value) => {
        setPhone(value);
    };

    function handleServicesChange(event) {
        setServices(event.target.value);
    }

    const handleChangeRole = (e) => {
        setRole(e.target.value);
    };

    function handleChangeTravelType(event) {
        setMaxtraveltype(event.target.value);
        if (
            event.target.value === "DRAW_TRAVEL_REGION" &&
            "MAX_DISTANCE" === origMaxtraveltype
        ) {
            setShowTravelChangeMessage(true);
        }
    }

    function handleTimezoneChange(event) {
        setProviderTimezone(event.target.value);
    }

    const handleChangeBio = (e) => {
        logger.debug("in handleChangeBio. bio = " + e.target.value);
        setBio(e.target.value);
    };

    let newInfo;
    async function handleSaveProvider() {
        newInfo = [
            {
                name: "No Change",
                phone: "No Change",
                services: "No Change",
                skills: "No Change",
                travelType: "No Change",
                active: "No Change",
                virtualServices: "No Change",
                bio: "No Change",
                pic: "No Change"
            }
        ];
        setEmailChangeWarningModal(false);
        // disable save button
        setDisableSave(true);

        // validate
        if (firstName === "") {
            throwEditSaveError("Please enter a value for first name.", 1);
            return;
        }

        if (firstName != firstName.trim()) {
            throwEditSaveError(
                "Please ensure that there are no spaces before or after the first name.",
                1
            );
            return;
        }

        if (lastName === "") {
            throwEditSaveError("Please enter a value for last name.", 1);
            return;
        }

        if (lastName != lastName.trim()) {
            throwEditSaveError(
                "Please ensure that there are no spaces before or after the last name.",
                1
            );
            return;
        }

        if (email === "") {
            throwEditSaveError("Please enter a value for email.", 1);
            return;
        }

        if (offersVirtualServices && (!vmlink || vmlink == "")) {
            throwEditSaveError(
                "Please enter your virtual services meeting room link",
                1
            );
            return;
        }

        if (!validator.isEmail(email)) {
            throwEditSaveError("Please specifiy a valid email address.", 1);
            return;
        }

        //validate phone number
        let cleanPhone = phone.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-"); // strip brackets or dash
        let isValidPhone = validatePhoneNumber(cleanPhone);

        //check for AUS phone numbers
        if (!isValidPhone) {
            if (cleanPhone.startsWith("+61"))
                isValidPhone =
                    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/.test(
                        cleanPhone
                    );
        }

        if (isValidPhone) {
            setPhone(cleanPhone);
        } else {
            throwEditSaveError("Please specifiy a valid phone number.", 1);
            return;
        }
        // do not check bio or picture for admin role: MBX-601
        const userIsProviderOnly = userHasProviderOnlyRole();
        if (userIsProviderOnly) {
            if (!bio || bio === "") {
                throwEditSaveError(
                    "Please add a bio on the Public Info tab.",
                    2
                );
                return;
            }
        }

        // validate travel type
        if (
            maxtraveltype != "MAX_DISTANCE" &&
            maxtraveltype != "DRAW_TRAVEL_REGION"
        ) {
            throwEditSaveError("Please select a travel type.", 1);
            return;
        }

        // finds the information that has been updated
        if (state.mode === "Edit") {
            if (
                firstName != oldInfo[0].firstname ||
                lastName != oldInfo[0].lastname
            ) {
                newInfo.map((item) => {
                    item.name = firstName + " " + lastName;
                });
            }
            if (phone != oldInfo[0].phone) {
                newInfo.map((item) => {
                    item.phone = phone;
                });
            }
            if (maxtraveltype != oldInfo[0].travelType) {
                if (maxtraveltype == "MAX_DISTANCE") {
                    newInfo.map((item) => {
                        item.travelType = "Circular Zone";
                    });
                } else {
                    newInfo.map((item) => {
                        item.travelType = "Drawn Zone";
                    });
                }
            }
            if (active != oldInfo[0].active) {
                newInfo.map((item) => {
                    item.active = active;
                });
            }
            if (offersVirtualServices != oldInfo[0].virtualServices) {
                newInfo.map((item) => {
                    item.virtualServices = offersVirtualServices;
                });
            }
            if (bio != oldInfo[0].bio) {
                newInfo.map((item) => {
                    item.bio = bio;
                });
            }
            if (file && file !== "") {
                newInfo.map((item) => {
                    item.pic =
                        "Provider has updated picture, please see their page for details.";
                });
            }
        }

        // check we don't have a duplicate email for providers
        if (
            state.mode === "Add" ||
            (userHasAdminRole() && originalEmailAddress !== email)
        ) {
            const provfilter = {
                and: [
                    { emailaddress: { eq: email } },
                    { deleted: { ne: true } }
                ]
            };

            let resultDupProv = await execReadByPK({
                opname: "listProviders",
                op: queries.listProviders,
                filter: provfilter,
                sortDirection: "DESC"
            });

            const listOfDupProviders = resultDupProv.items;

            if (listOfDupProviders.length > 0) {
                // duplicate email
                throwEditSaveError(
                    "The email address entered is already in use by another provider. Please choose a unique email address.",
                    1
                );
                return;
            }
            const admins = await getCompanyAdmins();
            const adminDuplicates = admins.filter(
                (admin) => admin.emailaddress === email
            );
            if (adminDuplicates.length) {
                // duplicate email admin account
                throwEditSaveError(
                    "This email address is already in use as an Admin account. Please use another email address.",
                    1
                );
                return;
            }
        }

        // find id's for providerservicetype
        const serviceTypeIds = [];
        const serviceTypeNames = [];
        logger.debug("services = ");
        logger.debug(services);
        logger.debug("serviceTypeData = ");
        logger.debug(serviceTypeData);
        logger.debug("getting logged in user = ");
        logger.debug(loggedInUser);

        for (var i = 0; i < services.length; i++) {
            logger.debug(services[i]);
            for (var j = 0; j < serviceTypeData.length; j++) {
                if (serviceTypeData[j].name === services[i]) {
                    // match so add
                    logger.debug("pushing service type ");
                    logger.debug(serviceTypeData[j].id);
                    serviceTypeIds.push(serviceTypeData[j].id);
                    serviceTypeNames.push(serviceTypeData[j].name);
                }
            }
        }
        // find id's for providerskill
        const skillIds = [];
        const skillNames = [];
        for (i = 0; i < skills.length; i++) {
            logger.debug(skills[i]);
            for (j = 0; j < skillsData.length; j++) {
                if (skillsData[j].name === skills[i]) {
                    // match so add
                    skillIds.push(skillsData[j].id);
                    skillNames.push(skillsData[j].name);
                }
            }
        }
        if (state.mode === "Edit") {
            // check if services or skills have been changed
            if (!isEqual(oldInfo[0].services, serviceTypeNames)) {
                if (serviceTypeNames.length > oldInfo[0].services.length) {
                    let newServices =
                        serviceTypeNames.length - oldInfo[0].services.length;
                    newInfo.map((item) => {
                        item.services = `${newServices} service(s) have been added, please see provider page for info`;
                    });
                } else if (
                    serviceTypeNames.length < oldInfo[0].services.length
                ) {
                    let newServices =
                        oldInfo[0].services.length - serviceTypeNames.length;
                    newInfo.map((item) => {
                        item.services = `${newServices} service(s) have been removed, please see provider page for info`;
                    });
                } else {
                    newInfo.map((item) => {
                        item.services = `at least one service has been changed, please see provider page for info`;
                    });
                }
            }

            if (!isEqual(oldInfo[0].skills, skillNames)) {
                if (skillNames.length > oldInfo[0].skills.length) {
                    let newSkills =
                        skillNames.length - oldInfo[0].skills.length;
                    newInfo.map((item) => {
                        item.skills = `${newSkills} skill(s) have been added, please see provider page for info`;
                    });
                } else if (skillNames.length < oldInfo[0].skills.length) {
                    let newSkills =
                        oldInfo[0].skills.length - skillNames.length;
                    newInfo.map((item) => {
                        item.skills = `${newSkills} skill(s) have been removed, please see provider page for info`;
                    });
                } else {
                    newInfo.map((item) => {
                        item.services = `at least one skill has been changed, please see provider page for info`;
                    });
                }
            }
            //find and handle removed service type ids
            if (serviceTypeIds) {
                let removedServices = [];
                let removedServicesIds = [];
                for (let os of oldServices) {
                    let foundOs = serviceTypeIds.filter(
                        (sid) => os.servicetype.id === sid
                    );
                    if (
                        foundOs &&
                        foundOs.length === 0 &&
                        !os.servicetype.deleted &&
                        os.servicetype.active
                    ) {
                        removedServices.push(os.servicetype);
                        removedServicesIds.push(os.servicetype.id);
                    }
                }

                if (removedServices.length > 0) {
                    const loggedInUser = getUserFromCache();

                    let servicesWithSchedules =
                        await doTheseServicesHaveSchedules(
                            removedServices,
                            removedServicesIds,
                            loggedInUser.company.id,
                            id
                        );
                    if (servicesWithSchedules && servicesWithSchedules.error) {
                        setSnackMsg(
                            "An error occured while validating your data. Please contact your company administrator."
                        );
                        setMsgOpen(true);
                        setDisableSave(false);
                        return;
                    }
                    if (
                        servicesWithSchedules &&
                        servicesWithSchedules.haveAtLeastOneSchedule
                    ) {
                        let namesOfServices = "";
                        let foundServiceCount = 0;
                        if (
                            servicesWithSchedules.foundScheduleForTheseServices &&
                            servicesWithSchedules.foundScheduleForTheseServices
                                .length > 0
                        ) {
                            foundServiceCount =
                                servicesWithSchedules
                                    .foundScheduleForTheseServices.length;
                            namesOfServices =
                                servicesWithSchedules.foundScheduleForTheseServices.reduce(
                                    (name, s) =>
                                        name ? `${name}, ${s.name}` : s.name,
                                    ""
                                );
                        }
                        setServiceValidationData({
                            foundServiceCount: foundServiceCount,
                            namesOfServices: namesOfServices
                        });

                        setShowServiceValidation(true);

                        return;
                    }
                }
            }
        }
        // save provider
        await saveProviderData(serviceTypeIds, skillIds);
    }

    async function closeServiceValidationDialog() {
        setShowServiceValidation(false);
        setServiceValidationMessage("");
        setServiceValidationData({});
        setDisableSave(false);
        await loadForm();
    }
    function reset() {
        setAdditionalValues((oldValues) => ({
            ...oldValues,
            name: "",
            desc: ""
        }));
        setAdditionalImage({});
    }

    function handleCancelProvider() {
        // cancel and go back to providerform
        sessionStorage.setItem("back", false);
        sessionStorage.setItem("search", false);
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
    }

    function handleReturnToSearch() {
        sessionStorage.setItem("back", true);
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
    }

    const handleBack = () => {
        sessionStorage.setItem("back", true);
        sessionStorage.setItem("search", false);
        actions.setId(state.id);
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
    };

    async function handlePictureChange({ target: { files, file, value } }) {
        const [image] = files || [];
        let output = image;
        if (
            !(
                JSON.stringify(value).toLowerCase().includes(".jpeg") ||
                JSON.stringify(value).toLowerCase().includes(".jpg") ||
                JSON.stringify(value).toLowerCase().includes(".png")
            )
        ) {
            setSnackMsg(
                "Invalid picture format was selected. Please upload a picture file with .jpg, .jpeg or .png extension."
            );
            setMsgOpen(true);
            return;
        }
        if (files && files.length > 0) {
            setSnackMsg("Image is uploading...");
            setMsgOpen(true);
            const size = files[0].size;
            logger.debug("file size = ", size);
            // stop if size > 100k
            if (size > 100000) {
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 800
                };
                while (output.size > 100000) {
                    output = await imageCompression(output, options);
                }
            }
            logger.debug("image", files, output, URL.createObjectURL(output));
            updateFile(output || value);
            setPictureUrl(URL.createObjectURL(output));
            setMsgOpen(false);
        } else {
            updateFile(null);
            setPictureUrl("");
            setS3ObjectKey("");
        }
    }
    // this function determines what the intial part of the permalink should be
    // hence it depends on whether useAnonymousPermalink is true or not.
    function valueOfInitialPermalink(fn, ln, useAnon) {
        let pl;
        if (useAnon) {
            pl = `${fn.trim().toLowerCase().replaceAll(" ", "-").replaceAll()}`;
        } else {
            pl = `${fn.trim().toLowerCase().replaceAll(" ", "-")}-${ln
                .trim()
                .toLowerCase()
                .replaceAll(" ", "-")}`;
        }
        return pl;
    }
    async function determinePermalink(fn, ln, companyId, useAnon) {
        let pl = valueOfInitialPermalink(fn, ln, useAnon);
        const prByPl = await graphql(
            graphqlOperation(providerByPermalink, {
                companyId,
                permalink: { beginsWith: pl }
            })
        );
        if (prByPl && prByPl.data && prByPl.data.providerByPermalink) {
            let numFound = prByPl.data.providerByPermalink.items.length;
            if (numFound === 0 && useAnon !== true) {
                return pl;
            } else {
                let largestValue = 0;
                for (let i = 0; i < numFound; i++) {
                    let curPL =
                        prByPl.data.providerByPermalink.items[i].permalink;
                    let curNum = 0;
                    if (new RegExp(/.*(-\d+)$/).test(curPL)) {
                        let stringInteger = curPL.slice(
                            curPL.lastIndexOf("-") + 1
                        );
                        curNum = parseInt(stringInteger);
                        largestValue =
                            largestValue > curNum ? largestValue : curNum;
                    }
                }
                return `${pl}-${largestValue + 1}`;
            }
        }
        return null;
    }

    /**
     * function to convert a user's role to a lower cased label
     * Example: 'PROVIDER' -> 'provider' , COMPANY_ADMIN_PROVIDER -> 'admin'
     * @param {string} role
     */
    function convertRoleToLabel(role) {
        if (role === "PROVIDER") {
            return "provider";
        } else if (role === "CLIENT") {
            return "client";
        } else {
            return "admin";
        }
    }

    function genpass() {
        return voucher_codes.generate({
            length: 8,
            count: 1,
            charset: voucher_codes.charset("alphanumeric")
        })[0];
    }

    /**
     * Funtion to create a new cognito record & user record.
     * @param {*} temppas
     * @param {*} userData
     * @param {*} newProviderData
     */
    async function handleUserCreation(temppas, userData, newProviderData) {
        try {
            //First create the users cognito record

            const result = await postApi("changeuserrole", "/createuser", {
                body: {
                    username: userData.emailaddress.trim(),
                    email: userData.emailaddress.trim(),
                    group:
                        role === "COMPANY_ADMIN_PROVIDER"
                            ? "CompanyAdminProvider"
                            : "Provider",
                    phone_number: userData.phone,
                    temppas,
                    firstname: userData.firstname,
                    lastname: userData.lastname
                }
            });

            //Second, create the user record and add it too the DB
            let registerUserInput = {
                id: result.newUserCognitoID,
                username: result.newUserCognitoID,
                emailaddress: userData.emailaddress.trim(),
                firstname: userData.firstname,
                lastname: userData.lastname,
                registered: true,
                active: true,
                contactconsent: true,
                contactconsentdatetime: new Date().toISOString(),
                role: role,
                userProviderId: newProviderData.id,
                providerId: newProviderData.id,
                companyId: userData.companyId,
                userCompanyId: userData.companyId,
                mobilephone: userData.phone,
                phonepref: "MOBILE"
            };
            const newUser = await graphql(
                graphqlOperation(mutations.createUser, {
                    input: registerUserInput
                })
            );

            //Third, Audit the new user creation
            await auditUserCreate(loggedInUser, newUser.data.createUser);

            return newUser;
        } catch (error) {
            console.error("Error occurred during user creation:", error);
        }
    }

    async function updateUserRecord() {
        try {
            let providerUser = null;

            //First, check if we have already retrieved the providerUserRec, otherwise check the DB if it exists
            if (!providerUserRec) {
                let usersByCompany = await graphql(
                    graphqlOperation(userByCompany, {
                        companyId: loggedInUser.company.id,
                        roleEmailaddress: {
                            eq: {
                                role: `PROVIDER`,
                                emailaddress: email
                            }
                        }
                    })
                );

                if (
                    Array.isArray(usersByCompany.data.userByCompany.items) &&
                    usersByCompany.data.userByCompany.items.length > 0
                ) {
                    providerUser = usersByCompany.data.userByCompany.items[0];
                }
            } else {
                providerUser = providerUserRec;
            }

            //Second if the User record does exist, carry out the updateUser mutation & user cognito update if email changed
            if (providerUser) {
                let input = {
                    id: providerUser.id,
                    firstname: firstName,
                    lastname: lastName,
                    mobilephone: phone,
                    emailaddress: email,
                    role: role,
                    active: userActive
                };
                if (contactConsentOrig !== contactConsent) {
                    input.contactconsent = contactConsent;
                    input.contactconsentdatetime = new Date().toISOString();
                }

                const updateUser = await graphql(
                    graphqlOperation(mutations.updateUser, { input })
                );

                if (
                    updateUser &&
                    updateUser.data &&
                    updateUser.data.updateUser
                ) {
                    try {
                        //Third, if the update User was successful then audit the user update
                        const loggedInUser = getUserFromCache();
                        await auditUserUpdate(
                            loggedInUser,
                            updateUser.data.updateUser,
                            [
                                {
                                    firstname:
                                        providerUser.firstname !== firstName
                                            ? firstName
                                            : "No Change",
                                    lastname:
                                        providerUser.lastname !== lastName
                                            ? lastName
                                            : "No Change",
                                    address: "No Change",
                                    homephone: "No Change",
                                    mobilephone:
                                        providerUser.mobilephone !== phone
                                            ? phone
                                            : "No Change",
                                    workphone: "No Change",
                                    prefphonetype: "No Change",
                                    role: "No Change",
                                    contactconsent: contactConsent
                                }
                            ]
                        );
                    } catch (e) {
                        console.log("Error while entering userUpdate audit", e);
                    }

                    //Fourth, update the cognito user record, but only if the email changed
                    try {
                        let cognitoUser = await getCognitoUser(providerUser.id);
                        if (email !== originalEmailAddress && cognitoUser) {
                            await updateCognitoUser(cognitoUser, email);
                        }
                    } catch (e) {
                        console.log(
                            "Error while updating coginto user record",
                            e
                        );
                    }
                }
            }
        } catch (e) {
            console.log("ERROR: Unable to update User record", e);
        }
    }

    /**
     * Function to handle a users password reset attempt.
     */
    async function handleResetPasswordClick() {
        setShowManagePasswordDialog(true);
        setShowResetPasswordSpinner(true);
        try {
            const result = await getJsonApi("changeuserrole", "/getuser", {
                queryParams: {
                    username: providerUserRec.id
                }
            });
            if (result && result.success) {
                setShowResetPasswordSpinner(false);
                let userStatus = result.data.UserStatus;
                if (
                    userStatus === "CONFIRMED" ||
                    userStatus === "RESET_REQUIRED"
                ) {
                    //allow reset for these two statuses
                    setPasswordManagementType("RESET");
                } else if (userStatus === "FORCE_CHANGE_PASSWORD") {
                    setPasswordManagementType("SET-TEMP-PWD");
                }
            } else {
                setShowResetPasswordSpinner(false);
                setShowManagePasswordDialog(false);
            }
        } catch (e) {
            setShowResetPasswordSpinner(false);
            setShowManagePasswordDialog(false);
            console.log("Error: Unable to get cognito password data", e);
        }
    }

    async function saveProviderData(serviceTypeIds, skillIds) {
        async function saveGraphQLProviders() {
            let success = false;
            let input = {};
            let s3key;
            if (file) {
                logger.debug("file data", file);
                const { name: fileName, type: mimeType } = file;
                const key = `${uuid()}-${fileName}`;

                // store file
                const result = await uploadData({
                    key,
                    data: file,
                    options: {
                        contentType: mimeType
                    }
                }).result;
                console.log("uploadData", JSON.stringify(result));

                s3key = result.key;
                setS3ObjectKey(s3key);
            }

            const loggedInUser = getUserFromCache();

            if (state.mode === "Edit") {
                input = {
                    id,
                    firstname: firstName,
                    lastname: lastName,
                    emailaddress: email,
                    phone: phone,
                    maxtraveltype: maxtraveltype,
                    active: active,
                    deleted: false, //do not remove this line. elastic search filter requires this field
                    providerCompanyId: loggedInUser.company.id,
                    companyId: loggedInUser.company.id,
                    pictures3key: s3key ? s3key : s3ObjectKey,
                    bio: bio,
                    timezone: providerTimezone,
                    offersVirtualServices,
                    createdAt // needed for index
                };
                let initialPermaLink = valueOfInitialPermalink(
                    firstName,
                    lastName,
                    loggedInUser.company.useAnonymousPermalink
                );
                // if the permalink is empty, if the value of the intial permalink has changed,
                // or if the permalink does not end with a "-" and a number,
                // we must recompute the permalink.
                if (
                    !permalink ||
                    permalink.slice(0, permalink.lastIndexOf("-")) !=
                        initialPermaLink ||
                    (!new RegExp(/.*(-\d+)$/).test(permalink) &&
                        loggedInUser.company.useAnonymousPermalink == true)
                ) {
                    let permaLink = await determinePermalink(
                        firstName,
                        lastName,
                        loggedInUser.company.id,
                        loggedInUser.company.useAnonymousPermalink
                    );
                    input = { ...input, permalink: permaLink };
                }
            } else {
                let permaLink = await determinePermalink(
                    firstName,
                    lastName,
                    loggedInUser.company.id,
                    loggedInUser.company.useAnonymousPermalink
                );
                input = {
                    firstname: firstName,
                    lastname: lastName,
                    emailaddress: email,
                    phone: phone,
                    maxtraveltype: maxtraveltype,
                    active: active,
                    providerCompanyId: loggedInUser.company.id,
                    companyId: loggedInUser.company.id,
                    pictures3key: s3key,
                    bio: bio,
                    timezone: providerTimezone,
                    offersVirtualServices,
                    deleted: false, //do not remove this line. elastic search filter requires this field
                    //virtualMeetingUserId,
                    createdAt: new Date(),
                    permalink: permaLink
                };
            }

            // add virtualMeeting link if set and append https protocol if missing
            if (vmlink) {
                let vmlinkWithProtocol = vmlink;
                if (vmlinkWithProtocol != "") {
                    if (
                        !(
                            vmlinkWithProtocol
                                .toLowerCase()
                                .startsWith("https://") ||
                            vmlinkWithProtocol.startsWith("//")
                        )
                    ) {
                        vmlinkWithProtocol = "https://" + vmlinkWithProtocol;
                    }
                    input = { ...input, vmlink: vmlinkWithProtocol };
                }
            }

            if (!offersVirtualServices) {
                input.vmlink = "";
            }

            let newProviderId;
            logger.debug("input = ");
            logger.debug(input);
            let providerIdForAdditionalInfoSave;
            if (state.mode === "Edit") providerIdForAdditionalInfoSave = id;

            if (state.mode === "Edit") {
                logger.debug("updateProvider input = " + JSON.stringify(input));
                const updateProvider = await graphql(
                    graphqlOperation(mutations.updateProvider, { input })
                );
                if (!!updateProvider.data.updateProvider) {
                    await auditProviderUpdate(
                        loggedInUser,
                        updateProvider.data.updateProvider,
                        newInfo
                    );
                    success = true;
                }
                newProviderId = id;
            } else {
                //Handle new Provider & User creation
                const temppas = genpass();
                logger.debug("createProvider input = " + JSON.stringify(input));
                let newProvider = await graphql(
                    graphqlOperation(mutations.createProvider, { input })
                );
                let newUser = await handleUserCreation(
                    temppas,
                    input,
                    newProvider.data.createProvider
                );

                //send the registration email if the newUser was successfully created
                if (newUser && newUser.data && newUser.data.createUser) {
                    let userData = {
                        firstName: input.firstname,
                        lastName: input.lastname,
                        emailAddress: input.emailaddress,
                        userRole: role
                    };
                    try {
                        await sendRegistrationEmail(
                            temppas,
                            input.phone,
                            userData,
                            loggedInUser.company
                        );
                    } catch (e) {
                        Sentry.captureException(e);
                        console.log(
                            "Error occurred while sending registration email:",
                            e
                        );
                        setSnackMsg("Error sending registration email");
                        setMsgOpen(true);
                    }

                    //update the provider record with the userId
                    await updateProvider(
                        newProvider.data.createProvider,
                        newUser.data.createUser
                    );
                }
                if (newProvider.data.createProvider) {
                    await auditProviderCreate(
                        loggedInUser,
                        newProvider.data.createProvider
                    );
                    success = true;
                    newProviderId = newProvider.data.createProvider.id;
                    setId(newProviderId);
                    providerIdForAdditionalInfoSave = newProviderId;
                }
            }

            // for edit we will clear all the service types and re-add
            // this is not the most efficient way to do it but will be ok for now
            if (state.mode === "Edit") {
                // clear service types
                for (let i = 0; i < oldServices.length; i++) {
                    input = {
                        id: oldServices[i].id
                    };
                    await graphql(
                        graphqlOperation(mutations.deleteProviderServiceType, {
                            input
                        })
                    );
                }
                // clear skills
                logger.debug("deleting old skills");
                for (let i = 0; i < oldSkills.length; i++) {
                    input = {
                        id: oldSkills[i].id
                    };
                    logger.debug(input);
                    await graphql(
                        graphqlOperation(mutations.deleteProviderSkill, {
                            input
                        })
                    );
                }
            }

            // add service types
            for (let i = 0; i < serviceTypeIds.length; i++) {
                input = {
                    providerServiceTypeProviderId: newProviderId,
                    providerServiceTypeServicetypeId: serviceTypeIds[i]
                };
                let newProvST = await graphql(
                    graphqlOperation(mutations.createProviderServiceType, {
                        input
                    })
                );
            }

            // add skills
            for (let i = 0; i < skillIds.length; i++) {
                input = {
                    providerSkillProviderId: newProviderId,
                    providerSkillSkillId: skillIds[i]
                };

                let newProvSkill = await graphql(
                    graphqlOperation(mutations.createProviderSkill, { input })
                );
            }

            await saveAdditionlInfo(providerIdForAdditionalInfoSave);

            //update User record with new input
            if (state.mode === "Edit") {
                await updateUserRecord();
            }
            if (success) {
                if (state.mode === "Edit") {
                    setSnackMsg(
                        "Provider successfully updated. Redirecting to main provider page."
                    );
                } else {
                    setSnackMsg("Provider successfully saved.");
                }
                setMsgOpen(true);

                if (!userHasProviderOnlyRole()) {
                    sessionStorage.setItem("back", false);
                    sessionStorage.setItem("search", false);
                    setTimeout(() => {
                        actions.setPage(OUTLET_PAGE);
                        navigate("/users")
                    }, 500);
                }
                if (userHasProviderOnlyRole()) {
                    setDisableSave(false);
                    actions.setPage("BookingsListForm");
                }
            }
        }

        try {
            await saveGraphQLProviders();
        } catch (err) {
            logger.error(`Error saving provider data`);
            logger.error(err);
            setDisableSave(false);
        }
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    function handleAddressDialogClose() {
        setAddressDialogOpen(false);
    }

    function handleAddressFocus() {
        logger.debug("handleAddressFocus!");
        if (!addressDialogOpen) {
            setAddressDialogOpen(true);
        }
    }

    function handleAddressDialogSave(
        addressOneLine,
        street,
        city,
        state,
        postalCode,
        country,
        lat,
        lon
    ) {
        setAddressDialogOpen(false);
        setAddressOneLine(addressOneLine);
        setAddressStreet(street);
        setAddressCity(city);
        setAddressState(state);
        setAddressPostal(postalCode);
        setAddressCountry(country);
        setLongitude(lon);
        setLatitude(lat);
    }

    function handleTabChange(event, newValue) {
        logger.debug("newValue", id);
        setTabValue(newValue);
        if (newValue === 1) {
            _getProviderPhotos();
        }
    }

    const saveAdditionlInfo = async (id) => {
        const { name, desc, s3Images } = additionalValues;
        let input = {};
        if (!!s3Images && s3Images.length > 0) {
            s3Images.map(async (item) => {
                input.s3key = item.s3key;
                input.name = name;
                input.desc = desc;
                input.providerPhotosProviderId = id;
                input.providerId = id;
                let additionalPhotos = await graphql(
                    graphqlOperation(mutations.createProviderPhotos, { input })
                );
                logger.debug("additionalPhotos", additionalPhotos);
            });
        }
        logger.debug("additional", additionalValues);
    };

    function displayCancelButton() {
        if (!userHasProviderOnlyRole()) {
            return (
                <Box component="span" m={1}>
                    <Tooltip title="Returns back to the main provider page">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelProvider}
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                </Box>
            );
        }
    }

    function displayReturnToSearchButton() {
        if (!userHasProviderOnlyRole()) {
            return (
                <Box component="span" m={1}>
                    <Tooltip title="Returns to the page with the providers matching your search">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReturnToSearch}
                        >
                            Return to Search
                        </Button>
                    </Tooltip>
                </Box>
            );
        }
    }

    function displayBackButton() {
        if (!userHasProviderOnlyRole()) {
            return (
                <Box component="span">
                    <Tooltip title="Returns to only the provider you selected">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Tooltip>
                </Box>
            );
        }
    }

    const handleCopyPermalink = () => {
        setSnackMsg("Permalink copied.");
        setMsgOpen(true);
    };

    logger.debug("===crousel===", carousel);
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            {providerUserRec && (
                <PasswordManagementModal
                    showManagePasswordDialog={showManagePasswordDialog}
                    passwordManagementType={passwordManagementType}
                    setPasswordManagementType={setPasswordManagementType}
                    setShowManagePasswordDialog={setShowManagePasswordDialog}
                    showResetPasswordSpinner={showResetPasswordSpinner}
                    userData={{
                        userId: providerUserRec?.id,
                        firstName: providerUserRec?.firstname,
                        lastName: providerUserRec?.lastname,
                        emailAddress: providerUserRec?.emailaddress,
                        userRole: providerUserRec?.role
                    }}
                    loggedInUser={loggedInUser}
                    emailAddress={providerUserRec?.emailaddress}
                />
            )}

            {/** Email Change Warning Modal **/}
            <SmallSizedModal
                open={emailChangeWarningModal}
                onClose={() => {
                    setEmailChangeWarningModal(false);
                }}
                headingIcon={WarningTriangleIcon}
                headingText={"Change email address"}
            >
                <Box>
                    <Typography variant="body2">
                        Changing this {`${convertRoleToLabel(role)}'s`} email
                        address will update their unique identifier in the
                        system and will affect all scheduled email notifications
                        to this person. Are you sure you wish to continue?
                    </Typography>
                </Box>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1.25rem"
                    }}
                >
                    <Button
                        sx={{
                            borderColor: "primary.main"
                        }}
                        className={classes.customDialogButton}
                        onClick={() => {
                            setEmailChangeWarningModal(false);
                        }}
                        variant="outlined"
                    >
                        <div className={classes.customDialogButtonText}>
                            Cancel
                        </div>
                    </Button>
                    <Button
                        className={classes.customDialogButton}
                        variant="contained"
                        onClick={async () => {
                            await handleSaveProvider();
                        }}
                    >
                        <div className={classes.customDialogButtonText}>
                            Continue
                        </div>
                    </Button>
                </Box>
            </SmallSizedModal>
            <Dialog
                open={addressDialogOpen}
                aria-labelledby="form-dialog-title"
                fullScreen
            >
                <DialogTitle id="form-dialog-title">Address</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <DialogContentText>
                                Please enter an address in the first line 'Full
                                Address'.
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <AddressForm
                                cancel={handleAddressDialogClose}
                                save={handleAddressDialogSave}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions />
            </Dialog>
            <Dialog open={showServiceValidation}>
                <DialogTitle id="alert-dialog-title">
                    Can't remove service
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        There is at least one schedule associated with the
                        {serviceValidationData.foundServiceCount === 1
                            ? " service "
                            : " services "}
                        <b>{serviceValidationData.namesOfServices}</b> you are
                        trying to remove. Please remove each service from each
                        schedule before removing the service from your profile.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={async () =>
                            await closeServiceValidationDialog()
                        }
                        color="primary"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showTravelZoneChangeMessage}>
                <DialogTitle id="alert-dialog-title-travelzone-change">
                    Changed travel zone
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        You are changing your travel zone type from Circular
                        travel zone to Drawn travel zone. Please ensure that you
                        draw at least one travel zone on you locations.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={async () => setShowTravelChangeMessage(false)}
                        color="primary"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography
                className={classes.title}
                sx={{ paddingLeft: "24px" }}
                variant="h4"
                noWrap
            >
                Service Providers \ {state.mode}
            </Typography>

            <TabContext value={tabValue}>
                <TabList
                    sx={{ gap: "1rem", paddingLeft: "24px" }}
                    onChange={handleTabChange}
                >
                    <Tab label="Provider Info" value="0" />
                    <Tab label="Public Info" value="1" />
                    {state.mode === "Edit" && state.contextCompanyId && (
                        <Tab label="Acceptance History" value="2" />
                    )}
                </TabList>
                <TabPanel value="0">
                    <Paper rounded="true" style={{ padding: "1.5rem" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        id="firstname"
                                        label="First Name"
                                        required
                                        size="normal"
                                        value={firstName}
                                        onChange={handleChangeFirstName}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        id="lastname"
                                        label="Last Name"
                                        required
                                        value={lastName}
                                        onChange={handleChangeLastName}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        required
                                        disabled={userHasProviderOnlyRole()}
                                        helperText={
                                            state.mode === "Edit" &&
                                            userHasProviderOnlyRole()
                                                ? "Email address cannot be changed"
                                                : ""
                                        }
                                        value={email}
                                        onChange={handleChangeEmail}
                                        margin="normal"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="normal" required fullWidth>
                                    <MuiPhoneNumber
                                        variant="outlined"
                                        id="phone"
                                        label="Phone"
                                        margin="normal"
                                        defaultCountry={
                                            phoneusercompany &&
                                            phoneusercompany.countrycode3166alpha2
                                                ? phoneusercompany.countrycode3166alpha2
                                                : "ca"
                                        }
                                        required
                                        value={phone}
                                        onChange={handleChangePhone}
                                        autoFormat={false}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {userHasAdminRole() && (
                            <Grid container>
                                <Grid
                                    item
                                    sx={{
                                        paddingRight: "8px",
                                        paddingTop: "24px"
                                    }}
                                    xs={12}
                                    sm={6}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="userrole">
                                            User Role
                                        </InputLabel>
                                        <Select
                                            labelId="userrole"
                                            label="User Role"
                                            value={role}
                                            onChange={handleChangeRole}
                                            inputProps={{
                                                name: "prefphone",
                                                id: "prephone"
                                            }}
                                        >
                                            <MenuItem
                                                key={"PROVIDER"}
                                                value={"PROVIDER"}
                                            >
                                                Provider
                                            </MenuItem>
                                            <MenuItem
                                                key={"COMPANY_ADMIN_PROVIDER"}
                                                value={"COMPANY_ADMIN_PROVIDER"}
                                            >
                                                Admin
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel
                                        shrink={services && services.length > 0}
                                        id="label-select-services"
                                    >
                                        Services
                                    </InputLabel>
                                    <Select
                                        labelId="label-select-services"
                                        multiple
                                        value={services}
                                        onChange={handleServicesChange}
                                        input={
                                            <Input
                                                id="select-services"
                                                multiline
                                            />
                                        }
                                        renderValue={(selected) =>
                                            selected.map((value, index) => (
                                                <Chip
                                                    color="primary"
                                                    size="small"
                                                    key={id + index}
                                                    label={value}
                                                    className={classes.chip}
                                                />
                                            ))
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {serviceTypeData &&
                                            serviceTypeData.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.name}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel
                                        shrink={skills && skills.length > 0}
                                        id="label-select-skills"
                                    >
                                        Skills
                                    </InputLabel>
                                    <Select
                                        labelId="label-select-skills"
                                        multiple
                                        value={skills}
                                        onChange={(event) =>
                                            setSkills(event.target.value)
                                        }
                                        input={
                                            <Input
                                                id="select-skills"
                                                multiline
                                            />
                                        }
                                        renderValue={(selected) =>
                                            selected.map((value) => (
                                                <Chip
                                                    color="primary"
                                                    size="small"
                                                    key={id}
                                                    label={value}
                                                    className={classes.chip}
                                                />
                                            ))
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {skillsData &&
                                            skillsData.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.name}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <FormControl required fullWidth margin="normal">
                                    <InputLabel htmlFor="timezone-select-label">
                                        Timezone
                                        <Tooltip
                                            title="All bookings will display on your calendar in this timezone."
                                            placement="right-end"
                                        >
                                            <HelpIcon />
                                        </Tooltip>
                                    </InputLabel>

                                    <TimezoneList
                                        value={providerTimezone}
                                        onChange={handleTimezoneChange}
                                    />
                                </FormControl>
                            </Grid>
                            {state.mode === "Edit" && (
                                <Grid item xs={12} sm={6}>
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel shrink>
                                            Permalink &nbsp;
                                            <Tooltip
                                                title="This is your unique URL that will take a client directly to your personal booking page."
                                                placement="right-end"
                                            >
                                                <HelpIcon />
                                            </Tooltip>
                                        </InputLabel>
                                        <Input
                                            id="Permalink"
                                            readOnly={true}
                                            className={classes.textField}
                                            value={
                                                loggedInUser &&
                                                loggedInUser.company
                                                    ? `https://${loggedInUser.company.subdomain}/provider/${permalink}`
                                                    : ""
                                            }
                                            inputProps={{
                                                readOnly: true,
                                                type: "text"
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <CopyToClipboard
                                                        text={
                                                            loggedInUser &&
                                                            loggedInUser.company
                                                                ? `https://${loggedInUser.company.subdomain}/provider/${permalink}`
                                                                : ""
                                                        }
                                                    >
                                                        <Button
                                                            color="primary"
                                                            onClick={() =>
                                                                handleCopyPermalink()
                                                            }
                                                        >
                                                            Copy
                                                        </Button>
                                                    </CopyToClipboard>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                        {providerUserRec && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <FormControl fullWidth margin="normal">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={contactConsent}
                                                    onChange={(event) =>
                                                        setContactConsent(
                                                            event.target.checked
                                                        )
                                                    }
                                                    value="contactconsent"
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label":
                                                            "primary checkbox"
                                                    }}
                                                />
                                            }
                                            label="Send me daily agenda email"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth margin="normal">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={active}
                                                onChange={(event) =>
                                                    setActive(
                                                        event.target.checked
                                                    )
                                                }
                                                value="active"
                                                color="primary"
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox"
                                                }}
                                            />
                                        }
                                        label="Active"
                                    />
                                </FormControl>
                                {providerUserRec && userHasAdminRole() && (
                                    <>
                                        <FormControl fullWidth margin="normal">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={userActive}
                                                        onChange={(event) => {
                                                            setUserActive(
                                                                event.target
                                                                    .checked
                                                            );
                                                        }}
                                                        value="userActive"
                                                        color="primary"
                                                        inputProps={{
                                                            "aria-label":
                                                                "primary checkbox"
                                                        }}
                                                    />
                                                }
                                                label="Allow login"
                                            />
                                        </FormControl>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: "16px 0px 36px 0px"
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    lineHeight: "16px",
                                                    padding: "0px"
                                                }}
                                                onClick={
                                                    handleResetPasswordClick
                                                }
                                                variant="text"
                                            >
                                                <Typography
                                                    variant="button"
                                                    sx={{
                                                        textTransform: "none",
                                                        lineHeight: "16px"
                                                    }}
                                                >
                                                    {`Reset password`}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={9} md={7} lg={4}>
                                <FormControl fullWidth margin="normal">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={offersVirtualServices}
                                                onChange={(event) =>
                                                    setOffersVirtualServices(
                                                        event.target.checked
                                                    )
                                                }
                                                value="offersVirtualServices"
                                                color="primary"
                                                name="virtual"
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox"
                                                }}
                                            />
                                        }
                                        label="Offers Virtual Services"
                                    />
                                    &nbsp;
                                    <div
                                        className={classes.vmLink}
                                        style={{
                                            visibility: offersVirtualServices
                                                ? "visible"
                                                : "hidden"
                                        }}
                                    >
                                        <FormControl
                                            style={{ width: "75%" }}
                                            className={
                                                classes.vmLinkFormControl
                                            }
                                        >
                                            <TextField
                                                id="vmlink"
                                                variant="outlined"
                                                label="Paste your personal meeting room link"
                                                className={classes.textField}
                                                value={vmlink}
                                                onChange={handleChangeVmlink}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.vmLinkLabel
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <CustomTooltip
                                            placement="right"
                                            title="Please ensure the link is valid. Clients will receive this link on their order summary when they book a virtual appointment with you"
                                        >
                                            <IconButton size="large">
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </CustomTooltip>
                                    </div>{" "}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={10}>
                            <Grid item xs={12} sm={5}>
                                <Box component="span" m={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            if (
                                                email !==
                                                    originalEmailAddress &&
                                                state.mode !== "Add"
                                            ) {
                                                setEmailChangeWarningModal(
                                                    true
                                                );
                                            } else {
                                                await handleSaveProvider();
                                            }
                                        }}
                                        disabled={
                                            disableSave ||
                                            (state.mode === "View"
                                                ? true
                                                : false)
                                        }
                                    >
                                        Save
                                        {disableSave && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classesnew.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </Box>
                                {displayCancelButton()}
                                &nbsp;
                                {searchedFor && displayReturnToSearchButton()}
                                &nbsp;
                                {state.mode === "Edit" && displayBackButton()}
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>

                <TabPanel value="1">
                    <Paper rounded="true" style={{ padding: "1.5rem" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={10}>
                                <FormControl
                                    margin="normal"
                                    required
                                    fullWidth
                                    className={classes.formControl}
                                >
                                    <TextField
                                        id="bio"
                                        label="Provider Bio"
                                        multiline
                                        rows="10"
                                        defaultValue=""
                                        className={classes.textField}
                                        value={bio}
                                        onChange={handleChangeBio}
                                        margin="normal"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {(state.mode === "View" || state.mode === "Edit") && (
                            <div>
                                <Box
                                    component="fieldset"
                                    mb={3}
                                    borderColor="transparent"
                                >
                                    <Typography component="legend">
                                        Rating
                                    </Typography>
                                    <div className={classes.rating1}>
                                        <Rating
                                            name="hover-side"
                                            value={ratingstarsavg}
                                            readOnly={true}
                                        />
                                        {!!numberofratings && (
                                            <Box ml={2}>
                                                based on {numberofratings}{" "}
                                                review(s)
                                            </Box>
                                        )}
                                    </div>
                                </Box>
                            </div>
                        )}
                        <Grid
                            style={{ marginTop: "1rem" }}
                            container
                            spacing={2}
                        >
                            <input
                                accept="image/*"
                                style={{
                                    display: "none"
                                }}
                                id="contained-button-file-logo"
                                type="file"
                                onChange={handlePictureChange}
                            />
                            <label htmlFor="contained-button-file-logo">
                                <CustomBasicTooltip title="Please select a provider picture">
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        style={{ marginLeft: "1rem" }}
                                        className={classes.formControl}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            size="small"
                                        >
                                            Upload Profile Picture
                                        </Button>
                                    </FormControl>
                                </CustomBasicTooltip>
                            </label>
                            <Avatar
                                alt="Profile Picture"
                                src={pictureUrl}
                                className={classes.bigAvatar}
                            />
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item xs={12} sm={5}>
                                <Box component="span">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            if (
                                                email !==
                                                    originalEmailAddress &&
                                                state.mode !== "Add"
                                            ) {
                                                setEmailChangeWarningModal(
                                                    true
                                                );
                                            } else {
                                                await handleSaveProvider();
                                            }
                                        }}
                                        disabled={
                                            disableSave ||
                                            (state.mode === "View"
                                                ? true
                                                : false)
                                        }
                                    >
                                        Save
                                    </Button>
                                </Box>
                                {displayCancelButton()}

                                {searchedFor && displayReturnToSearchButton()}

                                {state.mode === "Edit" && displayBackButton()}
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>

                <TabPanel value="2">
                    <Paper rounded="true" className={classes.root}>
                        <Typography
                            variant="h4"
                            noWrap
                            style={{ margin: "8px" }}
                        >
                            Acceptance History for {firstName} {lastName}
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            style={{ margin: "8px" }}
                        >
                            Bookings Offered:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-3"
                                    label="Total Bookings Offered"
                                    defaultValue={totalBookings}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-4"
                                    label="Bookings Offered as Primary Provider"
                                    defaultValue={priBookings}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-5"
                                    label="Bookings Offered as Secondary Provider"
                                    defaultValue={secBookings}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                        </Grid>
                        <Typography
                            variant="h6"
                            noWrap
                            style={{ margin: "8px" }}
                        >
                            Acceptance Rates:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-10"
                                    label="Total Acceptance Rate"
                                    defaultValue={totalAcceptRate}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-6"
                                    label="Acceptance Rate as Primary Provider"
                                    defaultValue={priAcceptRate}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-7"
                                    label="Acceptance Rate as Secondary Provider"
                                    defaultValue={secAcceptRate}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                        </Grid>
                        <Typography
                            variant="h6"
                            noWrap
                            style={{ margin: "8px" }}
                        >
                            Average Time to Respond:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-8"
                                    label="Average Time to Respond as Primary Provider"
                                    value={
                                        displayExactTime
                                            ? priExactTime
                                            : priAcceptTime
                                    }
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-read-only-input-9"
                                    label="Average Time to Respond as Secondary Provider"
                                    value={
                                        displayExactTime
                                            ? secExactTime
                                            : secAcceptTime
                                    }
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item>
                                    <Switch
                                        checked={displayExactTime}
                                        onChange={(e) =>
                                            setDisplayExactTime(
                                                e.target.checked
                                            )
                                        }
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "primary checkbox"
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        Display Exact Time &nbsp;
                                        <Tooltip title="Toggle this switch on to see the exact average time for a provider to respond (displays numbers instead of words).">
                                            <IconButton size="large">
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        &nbsp;
                        <Grid container spacing={10}>
                            <Grid item xs={12} sm={5}>
                                <Box component="span">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            if (
                                                email !==
                                                    originalEmailAddress &&
                                                state.mode !== "Add"
                                            ) {
                                                setEmailChangeWarningModal(
                                                    true
                                                );
                                            } else {
                                                await handleSaveProvider();
                                            }
                                        }}
                                        disabled={
                                            disableSave ||
                                            (state.mode === "View"
                                                ? true
                                                : false)
                                        }
                                    >
                                        Save
                                        {disableSave && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classesnew.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </Box>
                                {displayCancelButton()}
                                &nbsp;
                                {searchedFor && displayReturnToSearchButton()}
                                &nbsp;
                                {state.mode === "Edit" && displayBackButton()}
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>
            </TabContext>
        </>
    );
}
