import React, { Fragment, useState } from "react";
import {
    Typography,
    TextField,
    InputAdornment,
    NativeSelect
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { options } from "../../../../onboarding/assets/CountryCodes";

/* styles used in right panel */
const useStyles = makeStyles({
    field: {
        width: "50%"
    },
    field_mobile: {
        width: "100%"
    },
    input: {
        "&:before": {
            borderBottom: "2px solid #E6E6E6"
        },
        /* "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: "2px solid blue"
        }, */
        "&:after": {
            borderBottom: "2px solid black"
        },
        minWidth: "60px",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.67)"
        }
    },
    inputlabel: {
        color: "rgb(0,0,0,0.67)",
        "&.MuiFormLabel-root.Mui-focused": {
            color: "black"
        }
    },
    select: {
        //backgroundColor: "#f2f2f2",
        textAlign: "center",
        borderRadius: "4px 0px 0px 0px"
    }
});

const PhoneNumberField = (props) => {
    const classes = useStyles();
    const [countryCode, setCountryCode] = useState("+1");
    const [phoneNumber, setPhoneNumber] = useState("");

    return (
        <Fragment>
            <TextField
                id="phone-number-input"
                label="Phone number"
                className={
                    props.width < 1115 ? classes.field_mobile : classes.field
                }
                value={props.phoneNumberValue.substring(countryCode.length)}
                disabled={props.checkoutSuccess}
                variant="outlined"
                type="text"
                error={props.phoneNumberError || props.invalidPhoneNumberError}
                helperText={
                    props.invalidPhoneNumberError ? (
                        <p
                            style={{
                                fontSize: "11px",
                                lineHeight: "14px"
                            }}
                        >
                            Invalid phone number
                        </p>
                    ) : (
                        " "
                    )
                }
                /* inputProps={{
                autocomplete: "tel-national"
            }} */
                InputProps={{
                    className: classes.input,
                    startAdornment: (
                        <InputAdornment position="start">
                            <NativeSelect
                                inputProps={{ className: classes.select }}
                                className={classes.input}
                                disabled={props.checkoutSuccess}
                                onChange={(e) => {
                                    props.handlePhoneNumber(
                                        e.target.value + phoneNumber
                                    );
                                    setCountryCode(e.target.value);
                                }}
                            >
                                {options}
                            </NativeSelect>
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    className: classes.inputlabel
                }}
                onChange={(e) => {
                    props.handlePhoneNumber(countryCode + e.target.value);
                    setPhoneNumber(e.target.value);
                }}
            />
        </Fragment>
    );
};

export default PhoneNumberField;
