import React, { useState, useEffect } from "react";
// prettier-ignore
import { TextField } from "@mui/material";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { Autocomplete } from "@mui/material";
import { servicesOfCompany } from "../queries/AdhocBookingQueries";
import { getUserFromCache } from "../user/UserCommon";
export const ServiceSelector = (props) => {
    const [serviceTypes, setServiceTypes] = useState([]);
    const [servicetype, setServiceType] = useState("");
    const globalUser = getUserFromCache();
    const logger = new ConsoleLogger("ServiceSelector");

    useEffect(() => {
        async function fetchServicetypes() {
            await getServiceTypes();
        }
        fetchServicetypes();
    }, []);

    useEffect(() => {
        if (props.filteredServices && props.filteredServices.length > 0) {
            setServiceTypes(props.filteredServices);
        }
    }, [props.filteredServices]);

    const handleChangeServiceType = (e, value) => {
        if (value) {
            setServiceType(value);
            props.setServiceType(value);
        } else {
            setServiceType(null);
            props.setServiceType(null);
        }
    };

    const getServiceTypes = async () => {
        let user = globalUser;

        const filter = {
            and: [
                { active: { ne: false } },
                { deleted: { ne: true } },
                { isVisible: { ne: false } }
            ]
        };
        const response = await graphql(
            graphqlOperation(servicesOfCompany, {
                companyId: user.company.id,
                filter,
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );
        const result = await response.data.serviceTypeByCompany.items;
        setServiceTypes(sortByOrdinal(result));
        if (props.setServiceTypes) {
            props.setServiceTypes(result);
        }
    };

    function sortByOrdinal(services) {
        if (services) {
            return services.sort((s1, s2) => {
                if (s1.category.ordinal > s2.category.ordinal) return 1;
                if (s1.category.ordinal < s2.category.ordinal) return -1;
                if (s1.category.id === s2.category.id) {
                    if (s1.ordinal > s2.ordinal) return 1;
                    if (s1.ordinal < s2.ordinal) return -1;
                    return 0;
                }
                return 0;
            });
        }
        return [];
    }

    function byCategory(service) {
        if (service && service.category) {
            return service.category.name.toUpperCase();
        } else if (service) {
            return service.categoryName.toUpperCase();
        } else return "";
    }
    return (
        <>
            <Autocomplete
                style={{
                    width: "100%",
                    margin: "8px 0px"
                }}
                noOptionsText="No Services Found"
                groupBy={(option) => byCategory(option)}
                options={serviceTypes}
                getOptionLabel={(option) => "  " + option.name}
                getOptionDisabled={(option) => option.filteredByLocation}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        required
                        label="Service"
                    />
                )}
                onChange={handleChangeServiceType}
                disabled={props.disableElements}
            />
        </>
    );
};
