import makeStyles from '@mui/styles/makeStyles';

export const serviceAddStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(2)
  },
  textField: {
    flexBasis: 200,
    width: theme.spacing(32)
  },
  longTextField: {
    flexBasis: 200,
    width: theme.spacing(69)
  },
  menu: {
    width: theme.spacing(32)
  },
  button: {
    margin: theme.spacing(1)
  }
}));
