import { logSystemError } from "../../modules/SystemErrorService";

async function loadData(url, companyId) {
    let response = await fetch(url);
    if (response.ok) {
        let text = await response.text(); // read response body as text
        return text;
    } else {
        await logSystemError(
            companyId,
            "ERROR",
            `status code ${response.status} ${response.statusText} - while loading url ${url}`,
            0,
            {}
        );
        console.log("loadData", response);
        return "Error";
    }
}

export { loadData };
