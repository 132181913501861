export const servicesOfCompany = /* GraphQL */ `
    query ServiceTypeByCompany(
        $companyId: String
        $categoryNameName: ModelServiceTypeByCompanySortedByCategoryNameNameCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelServiceTypeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        serviceTypeByCompany(
            companyId: $companyId
            categoryNameName: $categoryNameName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                desc
                categoryId
                categoryName
                category {
                    id
                    name
                }
                locations
                minutes
                price
                active
                taxexempt
                TaxOverride
                ordinal
                deleted
                companyId
                behavior
            }
            nextToken
        }
    }
`;

export const bundledServicesByCompany = /* GraphQL */ `
    query BundledServicesByCompany(
        $companyId: String
        $includedServices: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelServiceTypeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bundledServicesByCompany(
            companyId: $companyId
            includedServices: $includedServices
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                desc
                categoryId
                categoryName
                category {
                    id
                    name
                }
                minutes
                price
                active
                deleted
                companyId
                s3key
                imagedesc
                offeredremote
                offeredonpremise
                isBundledService
                isVisible
                includedServices
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const locationByProvider = /* GraphQL */ `
    query LocationByProvider(
        $providerId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderLocationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        locationByProvider(
            providerId: $providerId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                providerId
                companyId
                locationpath
                name
                desc
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                timezone
                maxtraveltype
                traveldistance
                traveldistanceunit
                travelregions
                active
                deleted
            }
            nextToken
        }
    }
`;
export const clientByCompany = /* GraphQL */ `
    query ClientByCompany(
        $companyId: String
        $id: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelClientFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientByCompany(
            companyId: $companyId
            id: $id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                userId
                currency
                accountbalance
                stripeCustomerId
                companyId
                defaultpartialcc
                ClientInfo
                user {
                    id
                    username
                    emailaddress
                    registered
                    firstname
                    lastname
                    homephone
                    workphone
                    mobilephone
                    phonepref
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    longitude
                    latitude
                    role
                    companyId
                    active
                    deleted
                }
            }
            nextToken
        }
    }
`;
