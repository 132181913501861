import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter,
    Snackbar,
    IconButton,
    Tooltip,
    Button,
    Checkbox
} from "@mui/material";
import EditIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function showActive(item) {
    const schOrTb = item.id.startsWith("tb") ? "Availability" : "Schedule";
    return (
        <Tooltip
            title={item.active ? `Active ${schOrTb}` : `Inactive ${schOrTb}`}
        >
            <FiberManualRecordIcon
                style={item.active ? { color: "green" } : { color: "red" }}
            />
        </Tooltip>
    );
}

export const ScheduleDataTable = ({
    classes,
    showSelect,
    page,
    rowsPerPage,
    columns,
    rows,
    selected,
    selectAll,
    handleEditClick,
    handleDeactivate
}) => {
    return (
        <Table className={classes.table} size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={2}>
                        Schedule
                    </TableCell>
                    <TableCell align="center" colSpan={5}>
                        Availability
                    </TableCell>
                </TableRow>
                <TableRow>
                    {columns &&
                        columns.length > 0 &&
                        columns.map((column, i) => {
                            return (
                                <TableCell
                                    align={column.align}
                                    className={classes[column.className]}
                                    key={i}
                                >
                                    {column.title}
                                </TableCell>
                            );
                        })}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows &&
                    rows.length > 0 &&
                    rows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((item, i) => {
                            return (
                                <TableRow key={item.id}>
                                    {showSelect !== false && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    !!selected &&
                                                    selected.length > 0
                                                        ? selected.indexOf(
                                                              item.id
                                                          ) !== -1
                                                        : selected ===
                                                              item.id ||
                                                          selectAll
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    {columns &&
                                        columns.length > 0 &&
                                        columns.map((column, indx) => {
                                            return (
                                                <TableCell
                                                    key={indx}
                                                    align={column.align}
                                                    className={
                                                        classes[
                                                            column.className
                                                        ]
                                                    }
                                                >
                                                    {!!item[column.name]
                                                        ? column.name ===
                                                          "active"
                                                            ? showActive(item)
                                                            : item[column.name]
                                                        : column.name ===
                                                            "active"
                                                          ? showActive(item)
                                                          : ""}
                                                </TableCell>
                                            );
                                        })}
                                    {
                                        <TableCell
                                            align="center"
                                            className={classes.actionsCellWidth}
                                        >
                                            {item.id.startsWith("sc") && (
                                                <>
                                                    <Tooltip
                                                        title={"Edit Schedule"}
                                                    >
                                                        <EditIcon
                                                            aria-label="Edit"
                                                            onClick={() =>
                                                                handleEditClick(
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                </>
                                            )}
                                            &nbsp; &nbsp;
                                            {item.id.startsWith("sc") &&
                                                item.showDeactivate && (
                                                    <Tooltip title="Delete Schedule">
                                                        <DeleteIcon
                                                            className={
                                                                classes.pointer
                                                            }
                                                            onClick={() =>
                                                                handleDeactivate(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                )}
                                        </TableCell>
                                    }
                                </TableRow>
                            );
                        })}
            </TableBody>
            <TableFooter></TableFooter>
        </Table>
    );
};
