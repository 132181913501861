import React, { useEffect, useState } from "react";
import { SchedulerTheme } from "./SchedulerTheme";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { ThemeProvider } from "@mui/material/styles";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
    Scheduler,
    Toolbar,
    TodayButton,
    DateNavigator,
    ViewSwitcher,
    DayView,
    WeekView
} from "@devexpress/dx-react-scheduler-material-ui";
import {
    Paper,
    Grid,
    TextField,
    Box,
    Button,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Autocomplete,
    Typography,
    useTheme
} from "@mui/material";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import AddIcon from "@mui/icons-material/Add";
import { AvailabilityDisplay } from "./AvailabilityDisplay";
import { getUserFromCache } from "../user/UserCommon";
import { execReadBySortkey } from "../modules/DBService";
import { getMasterTimezone } from "../utils/CalendarFunctions/timezoneFunctions";
import { userHasAdminRole } from "../user/UserCommon";
import { customizeDate } from "../utils/SchedulerFunctions/DateNavigatorFormatter";
import { useSearchParams } from "react-router-dom";

export const ProviderScheduleDevEx = (props) => {
    const classes = usePromotionsStyles();
    const [providersListloading, setProvidersListloading] = useState(false);
    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cellDuration, setCellDuration] = useState(60);
    const [loggedInUser, setLoggedInUser] = useState();
    const [viewName, setViewName] = useState("Week");
    let [searchParams, setSearchParams] = useSearchParams();
    const providerIdsFromURL = searchParams.get("pIds")
        ? searchParams.get("pIds").split(",")
        : [];

    useEffect(() => {
        const loggedInUser = getUserFromCache();
        setLoggedInUser(loggedInUser);
        const companyId = loggedInUser.companyId;
        async function doWork() {
            let response = await fetchProviders(companyId);
            if (response && providerIdsFromURL && providerIdsFromURL.length) {
                setProvider(
                    null,
                    {
                        id: providerIdsFromURL[0]
                    },
                    response
                );
            }
        }
        doWork();
    }, []);

    useEffect(() => {
        if (selectedProviders && selectedProviders?.id) {
            setShowCalendar(false);
        }
        if (isLoading) {
            setIsLoading(false);
        }
        console.log("Selected providers useeffect", selectedProviders);
    }, [isLoading, selectedProviders, showCalendar]);

    // clear calendar view state on refresh
    useEffect(() => {
        // Remove displayCalendarAs from localStorage when the page refreshes
        window.localStorage.removeItem("displayCalendarAs");
    }, []);

    const fetchProviders = async (companyId) => {
        setProvidersListloading(true);

        let providersList = await execReadBySortkey({
            opname: "providerByCompany",
            op: `query ProviderByCompany(
                $companyId: String
                $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
                $sortDirection: ModelSortDirection
                $filter: ModelProviderFilterInput
                $limit: Int
                $nextToken: String
              ) {
                providerByCompany(
                  companyId: $companyId
                  firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
                  sortDirection: $sortDirection
                  filter: $filter
                  limit: $limit
                  nextToken: $nextToken
                ) {
                  items {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    timezone
                  }
                  nextToken
                }
              }
              `,
            id: {
                companyId: companyId
            },
            filter: {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            }
        });

        if (
            providersList &&
            !providersList.error &&
            providersList.items &&
            providersList.items.length
        ) {
            providersList.items.forEach((item) => {
                item.title = `${item.firstname} ${item.lastname} (${item.emailaddress})`;
            });
            setProvidersListloading(false);
            setProviders(providersList.items);
            return providersList.items;
        }
    };

    function changeViewName(viewName) {
        setViewName(viewName);
    }

    const setProvider = async (e, value, providersParamList) => {
        const providerList = providersParamList
            ? providersParamList
            : providers;

        if (value) {
            const [
                {
                    timezone: providerTz,
                    id,
                    firstname,
                    lastname,
                    emailaddress
                } = {}
            ] = providerList.filter((item) => {
                return item.id === value.id;
            });

            //change providerId in the searchUrl
            searchParams.set("pIds", id);
            setSearchParams(searchParams);

            let masterTz = await getMasterTimezone();
            setSelectedProviders([
                {
                    id,
                    text: firstname,
                    timezone: providerTz || masterTz || "",
                    title: `${firstname} ${lastname} (${emailaddress})`
                }
            ]);
            setShowCalendar(true);
        } else {
            setSelectedProviders(null);
        }
    };

    const selectingProviderHandler = (providerId) => {
        if (providerId) {
            const [{ timezone, firstname, title } = {}] = providers.filter(
                (item) => item.id === providerId
            );
            setSelectedProviders([
                {
                    id: providerId,
                    text: firstname,
                    timezone: timezone,
                    title: title
                }
            ]);
            return {
                id: providerId,
                text: firstname,
                timezone: timezone,
                title: title
            };
        }
    };

    const providerNotSelected = () => {
        return !selectedProviders;
    };

    const displayListProviders = (labelText) => {
        return (
            <Autocomplete
                id="select-user"
                disableClearable
                loadingText="Loading..."
                loading={providersListloading}
                options={providers}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => {
                    return option.title === value.title;
                }}
                variant="outlined"
                value={
                    selectedProviders && selectedProviders.length
                        ? selectedProviders[0]
                        : null
                }
                componentsProps={{
                    paper: {
                        sx: {
                            width: "fit-content"
                        }
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={labelText}
                        InputProps={{
                            ...params.InputProps
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps
                        }}
                        variant="outlined"
                        fullWidth
                        // If provider is not selected, autofocus on providers autocpmplete
                        autoFocus={providerNotSelected()}
                    />
                )}
                onChange={(e, value) => {
                    setIsLoading(true);
                    setProvider(e, value);
                }}
            />
        );
    };

    const CustomOpenButton = ({ text }) => {
        let customText = customizeDate(text);

        return (
            <Button
                variant={"outlined"}
                sx={{
                    marginTop: "2px",
                    "&:hover": { backgroundColor: "white" }
                }}
            >
                {customText}
            </Button>
        );
    };
    //prettier-ignore
    const CustomFlexibleSpace = ({
        theme,
        cellDuration,
        displayListProviders
    }) => (
        <Box
            className="customFlexibleSpace"
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 18px",
                minWidth: localStorage?.displayCalendarAs === "day" ? "calc(100% - 545px)" : "calc(100% - 566px)",
                [theme.breakpoints.down('smallDesktop')]: {
                    minWidth: localStorage?.displayCalendarAs === "day" ? "calc(100% - 377px)" : "calc(100% - 398px)"
                }
            }}
        >
            <FormControl
                sx={{
                    display: "flex",
                    gap: "4px",
                    marginRight: "18px",
                    minWidth: "100px",
                    maxWidth: "100px"
                }}
            >
                <InputLabel id="resolution">Resolution</InputLabel>
                <Select
                    label="Resolution"
                    labelId="cell-duration"
                    value={cellDuration}
                    sx={{
                        "&.MuiInputLabel-root": {
                            display: "none"
                        },
                        "& fieldset": {
                            "& legend": {
                                width: "unset"
                            }
                        }
                    }}
                >
                    <MenuItem value={60} key={"60"} sx={{ fontSize: "14px" }}>
                        60 mins
                    </MenuItem>
                </Select>
            </FormControl>
            {/* Coverage Areas and Providers */}
            <Grid
                item
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "18px",
                    width: "100%"
                }}
            >
                <FormControl
                    sx={{
                        width: "50%"
                    }}
                >
                    <InputLabel id="coverage-area">Coverage area</InputLabel>
                    {/* Other toolbar content */}
                    <Select
                        labelId="coverage-area"
                        label="Coverage area"
                        disabled={true}
                    ></Select>
                </FormControl>
                <FormControl
                    sx={{
                        width: "50%"
                    }}
                >
                    {displayListProviders("Select provider")}
                </FormControl>
            </Grid>
        </Box>
    );

    const CustomSwitcher = ({ currentView, onChange, availableViews }) => {
        return (
            <ThemeProvider theme={SchedulerTheme({ palette: themePalette })}>
                <Box
                    className="customSwitcher"
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <FormControl>
                        <InputLabel id="view-switcher">View</InputLabel>
                        <Select
                            sx={{
                                display: "flex",
                                gap: "4px",
                                minWidth: "80px",
                                maxWidth: "80px"
                            }}
                            label="View"
                            labelId="view-switcher"
                            value={currentView.name}
                            onChange={(event) => {
                                setIsLoading(true);
                                if (event.target.value === "Day") {
                                    localStorage.setItem(
                                        "displayCalendarAs",
                                        event.target.value
                                    );
                                    onChange(event.target.value);
                                    setIsLoading(false);
                                }

                                if (event.target.value === "Week") {
                                    localStorage.setItem(
                                        "displayCalendarAs",
                                        event.target.value
                                    );
                                    onChange(event.target.value);
                                    setIsLoading(false);
                                }
                            }}
                        >
                            {availableViews.map((view) => (
                                <MenuItem key={view.name} value={view.name}>
                                    {view.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {userHasAdminRole() && (
                        <Button
                            disabled={true}
                            variant="contained"
                            sx={{
                                maxHeight: "36px",
                                marginLeft: "18px",
                                paddingRight: "18px",
                                paddingLeft: "16px",
                                gap: "4px",
                                width: "150px",
                                /* Media query to hide the button for screens narrower than 1449px */
                                "@media screen and (max-width: 1449px)": {
                                    display: "none" // Hide the button */
                                }
                            }}
                        >
                            <AddIcon
                                sx={{
                                    color: "white",
                                    width: "18px",
                                    height: "18px"
                                }}
                            />
                            <Typography
                                sx={{
                                    marginTop: "2px",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    color: "white"
                                }}
                            >
                                Add Booking
                            </Typography>
                        </Button>
                    )}
                </Box>
            </ThemeProvider>
        );
    };

    const displayEmptyScheduler = () => {
        return (
            <ThemeProvider theme={SchedulerTheme({ palette: themePalette })}>
                <Scheduler firstDayOfWeek={1}>
                    <ViewState
                        defaultCurrentViewName="Week"
                        currentViewName={
                            localStorage?.displayCalendarAs && selectedProviders
                                ? localStorage.displayCalendarAs
                                : viewName
                        }
                        onCurrentViewNameChange={changeViewName}
                        currentDate={currentDate}
                    />
                    <Toolbar
                        flexibleSpaceComponent={() => (
                            <CustomFlexibleSpace
                                theme={SchedulerTheme({
                                    palette: themePalette
                                })}
                                disabled={true}
                                cellDuration={cellDuration}
                                displayListProviders={displayListProviders}
                            />
                        )}
                    />
                    <DateNavigator openButtonComponent={CustomOpenButton} />
                    <TodayButton />
                    <ViewSwitcher switcherComponent={CustomSwitcher} />
                    <WeekView startDayHour={9} endDayHour={14} />
                    <DayView startDayHour={9} endDayHour={14} />
                </Scheduler>
            </ThemeProvider>
        );
    };

    const themePalette = useTheme().palette;

    return (
        <div id="checking">
            {showCalendar && !isLoading && (
                <Paper
                    rounded="true"
                    className={classes.root}
                    sx={{ padding: "0 !important", boxShadow: "none" }}
                >
                    <AvailabilityDisplay
                        selectedProviders={selectedProviders}
                        company={loggedInUser.company}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                        fromPath={"ProviderScheduleDevEx"}
                        providersList={providers}
                        selectingProviderHandler={selectingProviderHandler}
                        displayListProviders={displayListProviders}
                        displayEmptyScheduler={displayEmptyScheduler}
                    />
                </Paper>
            )}

            {(!showCalendar || isLoading) && (
                <>
                    <ThemeProvider
                        theme={SchedulerTheme({ palette: themePalette })}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <FormHeading title={"Schedule"} classes={classes} />
                            {userHasAdminRole() && (
                                <Button
                                    variant="contained"
                                    disabled={true}
                                    sx={{
                                        display: "none", // Hide the button by default
                                        maxHeight: "36px",
                                        paddingRight: "18px",
                                        paddingLeft: "16px",
                                        gap: "4px",
                                        minWidth: "150px",
                                        /* Media query to show the button for screens narrower than 1449px */
                                        "@media screen and (max-width: 1449px)":
                                            {
                                                display: "flex", // Display the button */
                                                position: "relative",
                                                alignSelf: "flex-end"
                                            }
                                    }}
                                >
                                    <AddIcon
                                        sx={{
                                            color: "white",
                                            width: "18px",
                                            height: "18px"
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: "2px",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            color: "white"
                                        }}
                                    >
                                        Add Booking
                                    </Typography>
                                </Button>
                            )}
                        </Grid>
                    </ThemeProvider>
                    {displayEmptyScheduler()}
                </>
            )}
        </div>
    );
};
