import React from "react";
import {
    Typography,
    Grid,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    Box
} from "@mui/material";

const DateFrequencySelector = ({
    handleNumberSelectorChange,
    handleDayButtonClick,
    selectedDays,
    selectedNumber
}) => {
    const days = [
        { id: 1, title: "M" },
        { id: 2, title: "T" },
        { id: 3, title: "W" },
        { id: 4, title: "T" },
        { id: 5, title: "F" },
        { id: 6, title: "S" },
        { id: 0, title: "S" }
    ];

    const daysStyleFunction = (val) => {
        return {
            width: 36,
            height: 36,
            borderRadius: "50%",
            backgroundColor: val ? "primary.main" : "white",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: val ? "primary.main" : "rgb(0,0,0,0.23)",
            minWidth: 0,
            color: val ? "white" : "primary.main",
            "&:hover": {
                backgroundColor: "primary.main",
                borderColor: "primary.main",
                color: "white"
            }
        };
    };

    return (
        <>
            <Typography>Repeat every</Typography>
            <FormControl>
                <Select
                    id="week-frequency"
                    name="week-frequency"
                    value={selectedNumber}
                    onChange={handleNumberSelectorChange}
                >
                    {Array.from({ length: 9 }, (_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Typography>{`${selectedNumber > 1 ? "weeks" : "week"} on`}</Typography>
            <Grid
                sx={{
                    display: "flex",
                    gap: "12px"
                }}
                item
            >
                {days.map((day) => (
                    <IconButton
                        key={day.id}
                        onClick={() => handleDayButtonClick(day.id)}
                        sx={daysStyleFunction(selectedDays.includes(day.id))}
                    >
                        <Box
                            sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "roboto"
                            }}
                        >
                            {day.title}
                        </Box>
                    </IconButton>
                ))}
            </Grid>
        </>
    );
};

export default DateFrequencySelector;
