import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, CircularProgress } from "@mui/material";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";

// prettier-ignore
import "../dashboard/assets/css/bootstrap.min.css";
import "../dashboard/assets/css/icons.css";
import "../dashboard/assets/css/style.css";
import { getCurrencySymbol } from "../utils";
import {
    getUserFromCache,
    updateCompanyObjectInCachedUser
} from "../user/UserCommon";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { StoreContext } from "../context/StoreContext";
import ServiceLocationModal from "./ServiceLocationModal";
import ServiceAddModal from "./ServiceAddModal";
import BookingFlowSettingsModal from "./BookingFlowSettings/BookingFlowSettingsModal";

import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import { execReadByPK } from "../modules/DBService";
import * as mutations from "../graphql/mutations";
import { _getSchedules } from "../modules/ScheduleService";
import DashboardCard from "../components/DashboardCard";
import { useAtom } from "jotai";
import {
    mbxUserAtom,
    dashboardInfoAtom,
    allUsersAtom,
    allActiveServicesAtom,
    allActiveLocationsAtom,
    updateUserInAllUsersAtom
} from "../atoms/atoms.js";
import { CompanySettingsInitialTabAtom } from "../atoms/bookingFlowCustomization.js";
import {
    openAvailabilityDialogAtom,
    selectedUserIdAtom
} from "../atoms/usersTable.js";
import AvailabilityModal from "../user/components/AvailabilityModal.js";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { DialogTheme } from "../styles/DialogTheme.js";
import { useNavigate } from "react-router-dom";
import { OUTLET_PAGE } from "../context/reducers.js";

function DashBoardForm(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { actions, state } = useContext(StoreContext);
    const [loading, setLoading] = useState(true);
    const [mbxUser] = useAtom(mbxUserAtom);
    const [dashboardInfo] = useAtom(dashboardInfoAtom);
    const [openAvailabilityDialog, setOpenAvailabilityDialog] = useAtom(
        openAvailabilityDialogAtom
    );
    const [, setSelectedUserId] = useAtom(selectedUserIdAtom);
    const [, setAllUsers] = useAtom(allUsersAtom);
    const [, setAllActiveServices] = useAtom(allActiveServicesAtom);
    const [, setAllActiveLocations] = useAtom(allActiveLocationsAtom);
    const [, updateUserInAllUsers] = useAtom(updateUserInAllUsersAtom);
    const [CompanySettingsInitialTab, setCompanySettingsInitialTab] = useAtom(
        CompanySettingsInitialTabAtom
    );

    //to-do list states
    const [showTodo, setShowTodo] = useState(false); //set this to true to show todo list
    const [
        displayProviderAvailabilityForm,
        setDisplayProviderAvailabilityForm
    ] = useState(false);
    const [travelZoneRender, setTravelZoneRender] = useState(false);

    const [displayServiceLocationModal, setDisplayServiceLocationModal] =
        useState(false);

    const [displayServiceAddModal, setDisplayServiceAddModal] = useState(false);
    const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
    const [displayBookingFlowSettings, setDisplayBookingFlowSettings] =
        useState(false);
    const [publicSiteDashboard, setPublicSiteDashboard] = useState(null);

    //states to check if any to-do has been done to strike out
    const [serviceLocExists, setServiceLocExists] = useState(false);
    const [canStillOpen, setCanStillOpen] = useState(true);
    const [serviceExists, setServiceExists] = useState(false);
    const [availabilityExists, setAvailabilityExists] = useState(false);
    const [bfCustomized, setBfCustomized] = useState(false);
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const loggedInUser = getUserFromCache();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const loggedInUser = getUserFromCache();
            if (
                loggedInUser?.role === "COMPANY_ADMIN_PROVIDER" &&
                !loggedInUser?.company?.setupCompleted
            ) {
                try {
                    setAllUsers([mbxUser]);
                    let input = { id: loggedInUser?.companyId };
                    const compData = await graphql(
                        graphqlOperation(queries.getCompany, input)
                    );

                    const publicResult = await fetchPublicSiteData();

                    if (
                        publicResult?.data?.publicsiteByCompany?.items[0]
                            ?.StyleCustomization
                    ) {
                        let parsingCustomizations = JSON.parse(
                            publicResult.data.publicsiteByCompany.items[0]
                                .StyleCustomization
                        );
                        if (
                            parsingCustomizations.bookingFlowCustomizations &&
                            Object.keys(
                                parsingCustomizations.bookingFlowCustomizations
                            ).length > 0 &&
                            parsingCustomizations.bookingFlowCustomizations
                                .initialSetup
                        ) {
                            setBfCustomized(true);
                        }
                    }
                    const compLocations = await fetchServiceLocationsData();

                    //checking for any existing servcies
                    const servicesList = await fetchServicesListData();

                    if (compLocations) {
                        if (
                            compLocations.items.length > 0 ||
                            !compData.data.getCompany.offersOnPremiseServices
                        ) {
                            setAllActiveLocations(compLocations.items);
                            setServiceLocExists(true);
                            setCanStillOpen(false);
                        }
                    }

                    if (
                        servicesList &&
                        servicesList.data.serviceTypeByCompany.items.length > 0
                    ) {
                        setAllActiveServices(
                            servicesList.data.serviceTypeByCompany.items
                        );
                        setServiceExists(true);
                    }

                    const providerAvail = await fetchProviderAvailabilityData();

                    if (providerAvail) {
                        if (providerAvail.length > 0) {
                            setAvailabilityExists(true);
                        }
                    }

                    setLoading(false);
                } catch (e) {
                    console.log(
                        "there was an error provder info dashboard useEffect",
                        e
                    );
                }
            } else {
                if (!mbxUser.role === "COMPANY_ADMIN_PROVIDER") {
                    setShowTodo(false);
                }
                setServiceLocExists(true);
                setServiceExists(true);
                setAvailabilityExists(true);
                setBfCustomized(true);
                setLoading(false);
            }
        })();
    }, []);

    const fetchServiceLocationsData = async () => {
        // const loggedInUser = getUserFromCache();
        const companyId = loggedInUser?.companyId;
        if (loggedInUser?.id) {
            let compLocations;
            try {
                const filter = {
                    and: [{ active: { ne: false } }, { deleted: { ne: true } }]
                };
                compLocations = await execReadByPK({
                    opname: "companyLocationByCompany",
                    op: queries.companyLocationByCompany,
                    id: {
                        companyId: companyId
                    },
                    filter: filter
                });
            } catch (err) {
                console.log(
                    "Dashboard Form - Error in getting company locations"
                );
            }

            return compLocations;
        } else {
            console.log(
                "Dashboard Form - Logged In User Does not exist yet",
                loggedInUser
            );
        }
    };

    const fetchServicesListData = async () => {
        //const loggedInUser = getUserFromCache();
        const companyId = loggedInUser?.companyId;
        if (loggedInUser?.id) {
            let servicesList;
            console.log(
                "Dashboard Form - Logged In User exists | fetchServicesListData ",
                loggedInUser
            );
            try {
                servicesList = await graphql(
                    graphqlOperation(queries.serviceTypeByCompany, {
                        companyId: companyId,
                        filter: {
                            deleted: { ne: true },
                            isVisible: { ne: false }
                        },
                        limit: process.env.REACT_APP_LISTLIMIT
                    })
                );
            } catch (err) {
                console.log(
                    "Dashboard Form | fetchServicesListData - Error in getting Services List"
                );
            }

            return servicesList;
        } else {
            console.log(
                "Dashboard Form | fetchServicesListData - Logged In User Does not exist yet",
                loggedInUser
            );
        }
    };

    const fetchProviderAvailabilityData = async () => {
        //const loggedInUser = getUserFromCache();
        // const companyId = loggedInUser.companyId
        if (loggedInUser?.id) {
            let providerAvailability;
            try {
                providerAvailability = await _getSchedules({
                    companyId: loggedInUser?.companyId,
                    providerId: loggedInUser?.providerId
                });
                console.log(
                    "Dashboard Form fetchProviderAvailabilityData - providerAvailability",
                    providerAvailability
                );
            } catch (err) {
                console.log(
                    "Dashboard Form | fetchProviderAvailabilityData - Error in getting providerAvailability"
                );
            }

            return providerAvailability;
        } else {
            console.log(
                "Dashboard Form | fetchProviderAvailabilityData - Logged In User Does not exist yet",
                loggedInUser
            );
        }
    };

    const fetchPublicSiteData = async () => {
        //const loggedInUser = getUserFromCache();
        const companyId = loggedInUser?.companyId;
        if (loggedInUser?.id) {
            let publicSiteData;
            try {
                publicSiteData = await graphql(
                    graphqlOperation(queries.publicsiteByCompany, {
                        companyId: companyId
                    })
                );
            } catch (err) {
                console.log(
                    "Dashboard Form | fetchPublicSiteData - Error in getting PublicSiteData"
                );
            }

            return publicSiteData;
        } else {
            console.log(
                "Dashboard Form | fetchPublicSiteData - Logged In User Does not exist yet",
                loggedInUser
            );
        }
    };

    const displayTodoList = () => {
        let loggedInUser = getUserFromCache();
        let dismissTodo = getTodoRefData();

        if (loggedInUser?.company?.setupCompleted && dismissTodo) {
            setShowTodo(false);
        } else {
            setShowTodo(true);
        }
    };

    useEffect(() => {
        //all 4 to do are completed ?
        (async () => {
            try {
                if (loggedInUser?.role === "COMPANY_ADMIN_PROVIDER") {
                    let dismissResponse = await graphql(
                        graphqlOperation(queries.getRefData, {
                            refType: `company|${loggedInUser?.company.id}|to-do`,
                            refName: "show-todo"
                        })
                    );
                    let dismissTodoList;
                    //if ref data does not exist - used for existing companies
                    if (!dismissResponse?.data?.getRefData) {
                        dismissTodoList = true;
                    } else {
                        dismissTodoList = await getTodoRefData();
                    }
                    if (
                        serviceExists &&
                        serviceExists &&
                        availabilityExists &&
                        bfCustomized
                    ) {
                        setShowCloseIcon(true);
                        const setupCompleted = await setupCompletedHandler();

                        if (setupCompleted && dismissTodoList) {
                            setShowTodo(false);
                        }
                    } else {
                        if (
                            !loggedInUser?.company.setupCompleted ||
                            !dismissTodoList
                        ) {
                            setShowTodo(true);
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [
        publicSiteDashboard,
        serviceLocExists,
        serviceExists,
        availabilityExists,
        bfCustomized
    ]);

    async function reSaveCompanyInfoForLandingPage(company) {
        try {
            let companyInfo = {};

            for (let prop in company) {
                if (
                    "DashboardInfo" !== prop &&
                    "createdAt" !== prop &&
                    "updatedAt" !== prop
                )
                    companyInfo[prop] = company[prop];
            }
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `domain|${company.subdomain}`,
                    refName: "data-company"
                })
            );
            let refVal = exists.data.getRefData
                ? exists.data.getRefData.refValue
                : null;
            if (refVal)
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
            else
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
        } catch (e) {
            console.log("saving company info to refdata failed");
        }
    }

    const updateTodoRefData = async () => {
        let obj = {
            dismissTodo: true
        };
        let response;

        try {
            response = await graphql(
                graphqlOperation(mutations.updateRefData, {
                    input: {
                        refType: `company|${loggedInUser?.company.id}|to-do`,
                        refName: "show-todo",
                        refValue: "toDoListDisplay",
                        overrideValue: JSON.stringify(obj)
                    }
                })
            );
        } catch (err) {
            console.log(
                "Error while creating refData - dismissTodoHandler",
                err
            );
        }
    };

    const getTodoRefData = async () => {
        let response;
        let value;
        try {
            response = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `company|${loggedInUser?.company.id}|to-do`,
                    refName: "show-todo"
                })
            );
            // return;
        } catch (err) {
            console.log(
                "Error while getting refData - dismissTodoHandler",
                err
            );
        }

        if (response?.data?.getRefData?.overrideValue) {
            let parsing = JSON.parse(response.data.getRefData.overrideValue);
            value = parsing.dismissTodo;
        } else {
            value = false;
        }
        return value;
    };

    const closingTodoList = async () => {
        if (
            serviceLocExists &&
            serviceExists &&
            availabilityExists &&
            bfCustomized &&
            loggedInUser?.company.setupCompleted
        ) {
            await updateTodoRefData();
        }
        setShowTodo(false);
    };
    const setupCompletedHandler = async () => {
        const companyId = loggedInUser?.companyId;

        if (loggedInUser?.company?.setupCompleted) {
            return true;
        } else {
            try {
                let response = await graphql(
                    graphqlOperation(mutations.updateCompany, {
                        input: { id: companyId, setupCompleted: true }
                    })
                );
                await updateCompanyObjectInCachedUser(
                    response?.data?.updateCompany
                );
                if (response) {
                    await reSaveCompanyInfoForLandingPage(
                        response.data.updateCompany
                    );
                    return true;
                }
            } catch (err) {
                console.log("SetUpHandler was not able to update", err);
            }
        }
    };

    function getCurrentMonth() {
        let d = new Date();
        let month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        return month[d.getMonth()];
    }

    function getCurrentYear() {
        let d = new Date();
        return d.getFullYear();
    }

    function openAvailabilityPortal() {
        setSelectedUserId(mbxUser.id);
        setOpenAvailabilityDialog(true);
    }

    function closeAvailabilityPortal() {
        setSelectedUserId(null);
        setOpenAvailabilityDialog(false);
    }

    const openServiceLocationPortal = () => {
        setDisplayServiceLocationModal(true);
    };
    const closeServiceLocationPortal = () => {
        setDisplayServiceLocationModal(false);
    };

    const openServiceAddPortal = () => {
        setDisplayServiceAddModal(true);
    };

    const closeServiceAddPortal = () => {
        setDisplayServiceAddModal(false);
    };

    const openCategoryAddPortal = () => {
        setDisplayCategoryModal(true);
    };

    const closeCategoryAddPortal = () => {
        setDisplayCategoryModal(false);
    };

    const openBookingFlowSettingsModal = () => {
        setDisplayBookingFlowSettings(true);
    };
    const closeBookingFlowSettingsModal = () => {
        setDisplayBookingFlowSettings(false);
    };

    const loadingONHandler = () => {
        setLoading(true);
    };

    const openTravelZonePortal = () => {
        setTravelZoneRender(true);
    };

    const closeTravelZonePortal = () => {
        setTravelZoneRender(false);
    };

    const doneTodoHandler = (val, data) => {
        if (val === "serviceLoc") {
            setServiceLocExists(true);
        }
        if (val === "services") {
            updateUserInAllUsers({
                id: mbxUser.id,
                provider: {
                    ...mbxUser.provider,
                    servicetypes: {
                        items: [data]
                    }
                }
            });
            setServiceExists(true);
        }
        if (val === "availability") {
            setAvailabilityExists(true);
        }
        if (val === "bookingFlow") {
            setBfCustomized(true);
        }
    };

    const loadingOFFHandler = () => {
        setLoading(false);
    };

    const divStyle1 = {
        height: "242px"
    };

    const divStyle2 = {
        height: "280px"
    };
    if (loading) {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <CircularProgress color="primary" />
            </Box>
        );
    } else {
        return (
            <>
                <Grid container spacing={10}>
                    <Grid item xs={10}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontSize: "24px",
                                marginBottom: "10px"
                            }}
                            noWrap
                        >
                            Dashboard
                        </Typography>
                    </Grid>
                </Grid>

                {/* TO LIST BOX */}
                {showTodo && (
                    <Grid
                        container
                        sx={{
                            padding: "1.5rem",
                            gap: "1rem",
                            background: "white",
                            borderRadius: "8px"
                        }}
                        flexDirection={"column"}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: "rgba(0,0,0,0.75)",
                                        fontSize: "18px",
                                        fontWeight: 700
                                    }}
                                >
                                    Welcome to MarketBox!
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                >
                                    {!showCloseIcon
                                        ? "Follow these simple steps below to set up your account and make your first sale on your booking page."
                                        : "Congratulations - you're all set!"}
                                </Typography>
                            </Box>

                            {showCloseIcon && (
                                <Close
                                    onClick={closingTodoList}
                                    sx={{
                                        color: "rgba(0,0,0,0.5)",
                                        cursor: "pointer"
                                    }}
                                />
                            )}
                        </Box>

                        {/* 1st  */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: serviceLocExists
                                    ? "flex-start"
                                    : "center"
                            }}
                        >
                            <LooksOneIcon
                                sx={{
                                    color: serviceLocExists
                                        ? "rgba(0,0,0,0.5)"
                                        : "primary.main",
                                    fontSize: "1.7rem"
                                }}
                                fontSize="lg"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: serviceLocExists
                                            ? "rgba(0,0,0,0.5)"
                                            : "rgba(0,0,0,0.75)",
                                        cursor: !serviceLocExists && "pointer",
                                        textDecoration: serviceLocExists
                                            ? "line-through"
                                            : "underline",
                                        "&:hover": {
                                            fontWeight: !serviceLocExists && 700
                                        }
                                    }}
                                    onClick={() =>
                                        !serviceLocExists && canStillOpen
                                            ? openServiceLocationPortal()
                                            : null
                                    }
                                >
                                    Set up your service locations
                                </Typography>
                                {serviceLocExists && (
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        To add additional locations go to Admin{" "}
                                        {" > "}
                                        <span
                                            style={{
                                                fontFamily: "Roboto",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                actions.setPage(OUTLET_PAGE);
                                                navigate("/services/locations");
                                            }}
                                        >
                                            Locations
                                        </span>
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        {/*2nd*/}

                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: serviceExists
                                    ? "flex-start"
                                    : "center"
                            }}
                        >
                            <LooksTwoIcon
                                sx={{
                                    color: serviceExists
                                        ? "rgba(0,0,0,0.5)"
                                        : "primary.main",
                                    fontSize: "1.7rem"
                                }}
                                fontSize="lg"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: serviceExists
                                            ? "rgba(0,0,0,0.5)"
                                            : "rgba(0,0,0,0.75)",
                                        cursor: !serviceExists && "pointer",
                                        textDecoration: serviceExists
                                            ? "line-through"
                                            : "underline",
                                        "&:hover": {
                                            fontWeight: !serviceExists && 700
                                        }
                                    }}
                                    onClick={() =>
                                        !serviceExists
                                            ? openServiceAddPortal()
                                            : null
                                    }
                                >
                                    Add your services
                                </Typography>
                                {serviceExists && (
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        You’ve set-up your first service! To add
                                        additional services, go to{" "}
                                        <span
                                            style={{
                                                fontFamily: "Roboto",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                actions.setPage(OUTLET_PAGE);
                                                navigate("/services");
                                            }}
                                        >
                                            Services.
                                        </span>
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        {/* 3rd */}

                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: availabilityExists
                                    ? "flex-start"
                                    : "center"
                            }}
                        >
                            <Looks3Icon
                                sx={{
                                    color: availabilityExists
                                        ? "rgba(0,0,0,0.5)"
                                        : "primary.main",
                                    fontSize: "1.7rem"
                                }}
                                fontSize="lg"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: availabilityExists
                                            ? "rgba(0,0,0,0.5)"
                                            : "rgba(0,0,0,0.75)",
                                        cursor:
                                            !availabilityExists && "pointer",
                                        textDecoration: availabilityExists
                                            ? "line-through"
                                            : "underline",
                                        "&:hover": {
                                            fontWeight:
                                                !availabilityExists && 700
                                        }
                                    }}
                                    onClick={() =>
                                        !availabilityExists
                                            ? openAvailabilityPortal()
                                            : null
                                    }
                                >
                                    Add availability
                                </Typography>
                                {availabilityExists && (
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        {`You’ve updated your availability! Invite more service providers to ${
                                            loggedInUser
                                                ? loggedInUser?.company.name
                                                : ""
                                        } in the`}{" "}
                                        <span
                                            style={{
                                                fontFamily: "Roboto",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                actions.setPage(OUTLET_PAGE);
                                                navigate("/users");
                                            }}
                                        >
                                            Users
                                        </span>{" "}
                                        section.
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        {/* 4th */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "flex-start"
                            }}
                        >
                            <Looks4Icon
                                sx={{
                                    color: bfCustomized
                                        ? "rgba(0,0,0,0.5)"
                                        : "primary.main",
                                    fontSize: "1.7rem"
                                }}
                                fontSize="lg"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: bfCustomized
                                            ? "rgba(0,0,0,0.5)"
                                            : "rgba(0,0,0,0.75)",
                                        cursor: !bfCustomized && "pointer",
                                        textDecoration: bfCustomized
                                            ? "line-through"
                                            : "underline",
                                        "&:hover": {
                                            fontWeight: !bfCustomized && 700
                                        }
                                    }}
                                    onClick={() =>
                                        !bfCustomized
                                            ? openBookingFlowSettingsModal()
                                            : null
                                    }
                                >
                                    Customize your booking page
                                </Typography>
                                {bfCustomized && (
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        {`Your bookings page is ready to go! To make any more changes, go to`}{" "}
                                        <span
                                            style={{
                                                fontFamily: "Roboto",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                const user = getUserFromCache();
                                                actions.setMode("Edit");
                                                actions.setId(user?.companyId);
                                                actions.setPage(
                                                    "CompanyAddEditForm"
                                                );
                                                setCompanySettingsInitialTab(3);
                                            }}
                                        >
                                            Booking Flow Customization
                                        </span>{" "}
                                        .
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        {/*  5th */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "flex-start"
                            }}
                        >
                            <Looks5Icon
                                sx={{
                                    color: "primary.main",
                                    fontSize: "1.7rem"
                                }}
                                fontSize="lg"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                >
                                    {`Check your company settings under Admin > `}
                                    <span
                                        style={{
                                            fontFamily: "Roboto",
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            const user = getUserFromCache();
                                            actions.setMode("Edit");
                                            actions.setId(user?.companyId);
                                            navigate("/settings");
                                        }}
                                        onMouseOver={(e) => {
                                            e.target.style.fontWeight = "700";
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.style.fontWeight = "";
                                        }}
                                    >
                                        Settings
                                    </span>
                                    {` and view your online booking page `}
                                    <span
                                        style={{
                                            textDecoration: "underline",
                                            fontFamily: "Roboto",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            let url = `https://${loggedInUser?.company?.subdomain}`;
                                            window.open(url, "_blank").focus();
                                        }}
                                        onMouseOver={(e) => {
                                            e.target.style.fontWeight = "700";
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.style.fontWeight = "";
                                        }}
                                    >
                                        here
                                    </span>
                                </Typography>
                                {
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        {`To add more services, use the side menu.`}
                                    </Typography>
                                }
                            </Box>
                        </Box>
                    </Grid>
                )}

                {/* Dashboard Widget LIST BOX */}
                <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <DashboardCard
                            value={dashboardInfo?.sales_today || 0}
                            title="Sales Today"
                            icon={
                                <AssignmentIcon
                                    sx={{
                                        fontSize: "17px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                />
                            }
                            prefix={getCurrencySymbol()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <DashboardCard
                            value={dashboardInfo?.sales_mtd || 0}
                            title={`${getCurrentMonth()} Sales`}
                            icon={
                                <AssignmentIcon
                                    sx={{
                                        fontSize: "17px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                />
                            }
                            prefix={getCurrencySymbol()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <DashboardCard
                            value={dashboardInfo?.sales_ytd || 0}
                            title={`${getCurrentYear()} Sales`}
                            icon={
                                <AssignmentIcon
                                    sx={{
                                        fontSize: "17px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                />
                            }
                            prefix={getCurrencySymbol()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <DashboardCard
                            value={dashboardInfo?.orders_today || 0}
                            title="Orders Today"
                            icon={
                                <ShoppingCartIcon
                                    sx={{
                                        fontSize: "17px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <DashboardCard
                            value={dashboardInfo?.orders_mtd || 0}
                            title={`${getCurrentMonth()} Orders`}
                            icon={
                                <ShoppingCartIcon
                                    sx={{
                                        fontSize: "17px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <DashboardCard
                            value={dashboardInfo?.orders_ytd || 0}
                            title={`${getCurrentYear()} Orders`}
                            icon={
                                <ShoppingCartIcon
                                    sx={{
                                        fontSize: "17px",
                                        color: "rgba(0,0,0,0.75)"
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                {/* <!-- End wrapper -->  */}
                <ServiceLocationModal
                    open={displayServiceLocationModal}
                    path={"DashboardForm"}
                    compId={loggedInUser?.companyId}
                    closeServiceLocationPortal={closeServiceLocationPortal}
                    companyDataDashboard={loggedInUser?.company}
                    doneTodoHandler={doneTodoHandler}
                />

                <ServiceAddModal
                    open={displayServiceAddModal}
                    originPath={"DashboardForm"}
                    compId={loggedInUser?.companyId}
                    close={closeServiceAddPortal}
                    openCategoryAddPortal={openCategoryAddPortal}
                    closeCategoryAddPortal={closeCategoryAddPortal}
                    companyName={loggedInUser?.company.name}
                    providerInfo={loggedInUser?.provider}
                    doneTodoHandler={doneTodoHandler}
                />

                {openAvailabilityDialog && (
                    <ThemeProvider theme={DialogTheme(theme)}>
                        <AvailabilityModal
                            open={openAvailabilityDialog}
                            onClose={closeAvailabilityPortal}
                            doneTodoHandler={doneTodoHandler}
                        />
                    </ThemeProvider>
                )}

                <BookingFlowSettingsModal
                    open={displayBookingFlowSettings}
                    close={closeBookingFlowSettingsModal}
                    originPath={"DashboardForm"}
                    compId={loggedInUser?.companyId}
                    loadingON={loadingONHandler}
                    loadingOFF={loadingOFFHandler}
                    doneTodoHandler={doneTodoHandler}
                />
            </>
        );
    }
}

export default DashBoardForm;
