import moment from "moment-timezone";

const customizeDate = (inputDate) => {
    let formattedDate = "";
    let parts = inputDate.split(" ");

    // Check for "Month DD - Month DD, YYYY" format
    if (parts.length === 6 && parts[2] === "-" && parts[5].length === 4) {
        let start = moment(parts.slice(0, 2).join(" "), "MMM DD");
        let end = moment(parts.slice(3, 5).join(" "), "MMM DD");
        formattedDate = `${start.format("MMM D")} - ${end.format("MMM D")}`;
    }

    // Check for "DD Month, YYYY" format
    else if (parts.length === 3 && parts[1].endsWith(",")) {
        let date = moment(
            `${parts[0]} ${parts[1].slice(0, -1)} ${parts[2]}`,
            "MMM DD YYYY"
        );
        formattedDate = date.format("MMM D, YYYY");
    }
    // Check for "DD Month, YY - DD Month, YY"
    else if (
        parts.length === 7 &&
        parts[1].endsWith(",") &&
        parts[3] === "-" &&
        parts[5].endsWith(",")
    ) {
        let start = moment(
            `${parts[0]} ${parts[1].slice(0, -1)} ${parts[2]}`,
            "MMM DD YY"
        );
        let end = moment(
            `${parts[4]} ${parts[5].slice(0, -1)} ${parts[6]}`,
            "MMM DD YY"
        );
        formattedDate = `${start.format("MMM D")} - ${end.format("MMM D")}`;
    }

    // Assuming the input is in "DD-DD Month YYYY" format
    else {
        let [startDay, endDay] = parts[0].split("-");
        let date = moment(`${startDay} ${parts[1]} ${parts[2]}`, "DD MMM YYYY");
        formattedDate = `${date.format("MMM")} ${startDay}-${endDay}, ${date.format("YYYY")}`;
    }

    return formattedDate;
};

function getCurrentTimeAdjustedToNextHour() {
    let now = new Date();
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    now.setHours(now.getHours() + 1);
    return now;
}

export { customizeDate, getCurrentTimeAdjustedToNextHour };
