import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Link,
    TableFooter,
    Snackbar,
    IconButton,
    Tooltip,
    Button,
    Checkbox,
    Typography,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText
} from "@mui/material";
import ViewIcon from "@mui/icons-material/DesktopWindows";
import EditIcon from "@mui/icons-material/Create";
import AlarmIcon from "@mui/icons-material/Alarm";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Edit from "@mui/icons-material/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Rating from "@mui/material/Rating";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { getCurrencySymbol, getCurrencySymbolFromParam } from "../index";
import { userHasAdminRole, userCanCancelAppt } from "../../user/UserCommon";
import withStyles from "@mui/styles/withStyles";
import { ContactsOutlined } from "@mui/icons-material";

function getFormattedDate(dateString) {
    return moment(dateString).format("MMM DD, YYYY HH:mm a");
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: "220px",
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        minWidth: "200px"
    }
}))(Tooltip);

const BookingsListFormat = {
    fontSize: 13.5,
    minWidth: "200px"
};

const dateCreatedFormat = {
    fontSize: 13.5,
    minWidth: "75px",
    padding: "1px"
};

export const DataTableWrapper = ({
    classes,
    showRatingStars,
    ratingPage,
    hideView,
    showAction,
    showTime,
    showActive,
    showSelect,
    showReceipt,
    showHelpIcon,
    showRefund,
    page,
    rowsPerPage,
    columns,
    rows,
    selected,
    selectAll,
    handleSelectAllClick,
    handleRowClick,
    handleDecline,
    handleEditClick,
    handleRatingEditClick,
    handleTimeClick,
    handleViewClick,
    handleReceipt,
    handleRefund,
    showButtons,
    tab,
    role,
    handleCancel,
    handleNoShow,
    handleArrived,
    handleCompleted,
    handleAddCharge,
    handleOverride,
    handleReqAccepted,
    handleReqDeclined,
    handleReqDetails
}) => {
    const [openVMDialog, setOpenVMDialog] = useState(false);
    const [serviceDesc, setServiceDesc] = useState("");
    const [serviceProv, setServiceProv] = useState("");
    const [startDate, setStartDate] = useState("");
    const [serviceLoc, setServiceLoc] = useState("");

    const tableTheme = {
        borderCollapse: "separate",
        borderSpacing: "0px 4px",
        //Table Head
        "& .MuiTableHead-root": {
            "& .MuiTableRow-root": {
                "& .MuiTableCell-root": {
                    color: "rgba(0,0,0,0.5)",
                    paddingBottom: "0.5rem",
                    fontWeight: 400
                }
            }
        },

        //Table Body
        "& .MuiTableBody-root": {
            "& .MuiTableRow-root": {
                backgroundColor: "white",
                "&:hover": {
                    backgroundColor: "primary.light"
                },
                "& .MuiTableCell-root": {
                    fontSize: "0.875rem",

                    "& .MuiTypography-root": {
                        fontSize: "0.875rem"
                    }
                }
            },
            "& .MuiTableRow-root td:first-of-type": {
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px"
            },

            "& .MuiTableRow-root td:last-child": {
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px"
            }
        }
    };

    console.log("**DataTable Wrapper - Head**");
    console.log(columns);

    return (
        <Table sx={tableTheme}>
            <Dialog
                open={openVMDialog}
                onClose={() => setOpenVMDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Would you like to join this meeting room?
                </DialogTitle>
                <DialogContentText className={classes.dialogContent}>
                    <div>
                        <span className={classes.dialogLabel}>
                            Service Description:
                        </span>{" "}
                        {serviceDesc}
                        <br />
                        <span className={classes.dialogLabel}>
                            Provider:
                        </span>{" "}
                        {serviceProv}
                        <br />
                        <span className={classes.dialogLabel}>
                            Starting Date:
                        </span>{" "}
                        {startDate}
                        <br />
                        <span className={classes.dialogLabel}>Location: </span>
                        <Link target="_blank" href={serviceLoc}>
                            {serviceLoc}
                        </Link>
                    </div>
                </DialogContentText>
                <DialogActions>
                    <Button
                        onClick={() => setOpenVMDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            window.open(`${serviceLoc}`, "_blank");
                            setOpenVMDialog(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Join
                    </Button>
                </DialogActions>
            </Dialog>
            <TableHead>
                <TableRow>
                    {showSelect !== false && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={selectAll}
                                onClick={handleSelectAllClick}
                            />
                        </TableCell>
                    )}
                    {columns &&
                        columns.length > 0 &&
                        columns.map((column, i) => {
                            return (
                                <TableCell
                                    align={column.align}
                                    className={classes[column.className]}
                                    key={i}
                                    style={
                                        column.name === "addressoneline" ||
                                        column.name === "createdAt" ||
                                        column.name === "providerName"
                                            ? { minWidth: "200px" }
                                            : tab === "future" || tab === "past"
                                            ? column.name === "startDateDesc" ||
                                              column.name === "location"
                                                ? BookingsListFormat
                                                : column.name === "dateCreated"
                                                ? dateCreatedFormat
                                                : {}
                                            : {}
                                    }
                                >
                                    {showHelpIcon &&
                                    column.title == "No. of Bookings" ? (
                                        <div>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                No. of Bookings{" "}
                                            </span>
                                            <Tooltip title="You can hover over the number of bookings, to view the booking dates and times.">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        column.title
                                    )}
                                </TableCell>
                            );
                        })}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows &&
                    rows.length > 0 &&
                    rows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((item, i) => {
                            return (
                                <TableRow
                                    key={`${i}-${item.id}`}
                                    hover
                                    onClick={(event) =>
                                        handleRowClick(event, item.id)
                                    }
                                >
                                    {showSelect !== false && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    !!selected &&
                                                    selected.length > 0
                                                        ? selected.indexOf(
                                                              item.id
                                                          ) !== -1
                                                        : selected ===
                                                              item.id ||
                                                          selectAll
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    {columns &&
                                        columns.length > 0 &&
                                        columns.map((column, indx) => {
                                            return (
                                                <TableCell
                                                    key={indx}
                                                    align={column.align}
                                                    className={
                                                        classes[
                                                            column.className
                                                        ]
                                                    }
                                                    style={
                                                        tab === "future" ||
                                                        tab === "past"
                                                            ? column.name ===
                                                                  "startDateDesc" ||
                                                              column.name ===
                                                                  "location"
                                                                ? BookingsListFormat
                                                                : column.name ===
                                                                  "dateCreated"
                                                                ? dateCreatedFormat
                                                                : {}
                                                            : {}
                                                    }
                                                >
                                                    {showRatingStars &&
                                                    column.name ===
                                                        "ratingstars" &&
                                                    typeof item[column.name] ==
                                                        "number" ? (
                                                        <Rating
                                                            name="hover-side"
                                                            size="small"
                                                            value={
                                                                item[
                                                                    column.name
                                                                ]
                                                            }
                                                            readOnly
                                                            precision={0.5}
                                                        />
                                                    ) : !!item[column.name] ? (
                                                        column.name ===
                                                        "createdAt" ? (
                                                            getFormattedDate(
                                                                item[
                                                                    column.name
                                                                ]
                                                            )
                                                        ) : column.name.includes(
                                                              "amount"
                                                          ) ||
                                                          column.name.includes(
                                                              "total"
                                                          ) ||
                                                          column.name.includes(
                                                              "Charge"
                                                          ) ||
                                                          column.name ==
                                                              "servicechargeamt" ||
                                                          column.name ==
                                                              "taxamt" ? (
                                                            <CurrencyFormat
                                                                value={
                                                                    item[
                                                                        column
                                                                            .name
                                                                    ]
                                                                }
                                                                displayType={
                                                                    "text"
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                prefix={
                                                                    item.currencyValue
                                                                        ? getCurrencySymbolFromParam(
                                                                              item.currencyValue
                                                                          )
                                                                        : getCurrencySymbol()
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                            />
                                                        ) : column.name ===
                                                          "virtual" ? (
                                                            "Yes"
                                                        ) : column.name ===
                                                          "numberOfBookings" ? (
                                                            item[
                                                                "bookingTimes"
                                                            ] &&
                                                            item["bookingTimes"]
                                                                .length && (
                                                                <HtmlTooltip
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Typography color="inherit">
                                                                                Booking
                                                                                Times
                                                                            </Typography>
                                                                            <div>
                                                                                {item[
                                                                                    "bookingTimes"
                                                                                ].map(
                                                                                    (
                                                                                        l
                                                                                    ) => (
                                                                                        <div>
                                                                                            {
                                                                                                l
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {" "}
                                                                        {
                                                                            item[
                                                                                column
                                                                                    .name
                                                                            ]
                                                                        }
                                                                    </span>
                                                                </HtmlTooltip>
                                                            )
                                                        ) : column.name ===
                                                          "tryOtherProviders" ? (
                                                            item[
                                                                "tryOtherProviders"
                                                            ] &&
                                                            `${
                                                                item[
                                                                    column.name
                                                                ]
                                                            } (${
                                                                item[
                                                                    "numberOfOthers"
                                                                ]
                                                            })`
                                                        ) : column.name ===
                                                          "location" ? (
                                                            item[
                                                                column.name
                                                            ].includes(
                                                                "Virtual Appointment"
                                                            ) ? (
                                                                <Button
                                                                    color="primary"
                                                                    disableRipple
                                                                    className={
                                                                        classes.vmButton
                                                                    }
                                                                    onClick={() => {
                                                                        setServiceDesc(
                                                                            item.desc
                                                                        );
                                                                        setServiceProv(
                                                                            item.providerName
                                                                        );
                                                                        setStartDate(
                                                                            item.startDateDesc
                                                                        );
                                                                        setServiceLoc(
                                                                            item
                                                                                .provider
                                                                                .vmlink
                                                                        );
                                                                        setOpenVMDialog(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        item[
                                                                            column
                                                                                .name
                                                                        ]
                                                                    }
                                                                </Button>
                                                            ) : (
                                                                <Link
                                                                    target="_blank"
                                                                    href={`https://www.google.com/maps/search/?api=1&query=${item[
                                                                        column
                                                                            .name
                                                                    ].replaceAll(
                                                                        " ",
                                                                        "+"
                                                                    )}`}
                                                                >
                                                                    {
                                                                        item[
                                                                            column
                                                                                .name
                                                                        ]
                                                                    }
                                                                </Link>
                                                            )
                                                        ) : column.name ===
                                                          "clientPhone" ? (
                                                            <Link
                                                                href={`tel:${item["clientMobilePhone"]}`}
                                                            >
                                                                {
                                                                    item[
                                                                        column
                                                                            .name
                                                                    ]
                                                                }
                                                            </Link>
                                                        ) : (
                                                            item[column.name]
                                                        )
                                                    ) : column.name ===
                                                      "virtual" ? (
                                                        "No"
                                                    ) : (
                                                        "None"
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    {
                                        <TableCell
                                            align="center"
                                            className={classes.actionsCellWidth}
                                            style={
                                                tab === "future" ||
                                                tab === "past"
                                                    ? {
                                                          fontSize: 13.5,

                                                          minWidth: "75px"
                                                      }
                                                    : {}
                                            }
                                        >
                                            {showAction && (
                                                <>
                                                    <Tooltip title="Edit">
                                                        <EditIcon
                                                            aria-label="Edit"
                                                            onClick={() =>
                                                                handleEditClick(
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>{" "}
                                                </>
                                            )}
                                            {showRatingStars && ratingPage && (
                                                <>
                                                    <Tooltip title="Edit">
                                                        <EditIcon
                                                            aria-label="Edit"
                                                            onClick={() =>
                                                                handleRatingEditClick(
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>{" "}
                                                </>
                                            )}
                                            {showTime && (
                                                <Tooltip title="Availability">
                                                    <AlarmIcon
                                                        className={
                                                            classes.pointer
                                                        }
                                                        onClick={() =>
                                                            handleTimeClick(
                                                                item.id,
                                                                item
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            )}
                                            {showActive && (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={
                                                                item.active
                                                            }
                                                            onChange={(e) =>
                                                                handleDecline(
                                                                    item.id,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Active"
                                                />
                                            )}
                                            <div style={{ display: "flex" }}>
                                                {showReceipt === true && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            fontSize: 13.5,
                                                            padding: "1px",
                                                            maxWidth: "90px",
                                                            minWidth: "90px",
                                                            marginRight: 3,
                                                            marginTop: 2
                                                        }}
                                                        onClick={() => {
                                                            console.log(item);
                                                            handleReceipt(item);
                                                        }}
                                                    >
                                                        Receipt
                                                    </Button>
                                                )}
                                                {showRefund === true &&
                                                    role !== "CLIENT" && (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{
                                                                fontSize: 13.5,
                                                                padding: "1px",
                                                                maxWidth:
                                                                    "90px",
                                                                minWidth:
                                                                    "90px",
                                                                marginRight: 3,
                                                                marginTop: 2
                                                            }}
                                                            onClick={() =>
                                                                handleRefund(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            Refund
                                                        </Button>
                                                    )}
                                            </div>
                                            {showButtons === true && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.3rem",
                                                        flexWrap: "wrap"
                                                    }}
                                                >
                                                    {(tab === "future" ||
                                                        tab === "past") &&
                                                        item.status ==
                                                            "SCHEDULED" && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    marginRight: 3
                                                                }}
                                                                onClick={() =>
                                                                    handleArrived(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Arrived
                                                            </Button>
                                                        )}
                                                    {(tab === "future" ||
                                                        tab === "past") &&
                                                        item.status ==
                                                            "ARRIVED" &&
                                                        role !== "CLIENT" && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    marginRight: 3
                                                                }}
                                                                onClick={() =>
                                                                    handleAddCharge(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Add Chrg
                                                            </Button>
                                                        )}
                                                    {(tab === "future" ||
                                                        tab === "past") &&
                                                        item.status ==
                                                            "ARRIVED" && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    marginRight: 3
                                                                }}
                                                                onClick={() =>
                                                                    handleCompleted(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Completed
                                                            </Button>
                                                        )}
                                                    {tab === "future" &&
                                                        item.status ==
                                                            "SCHEDULED" &&
                                                        userCanCancelAppt() && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    marginRight: 3
                                                                }}
                                                                onClick={() =>
                                                                    handleCancel(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Cancel
                                                            </Button>
                                                        )}
                                                    {tab === "past" &&
                                                        role !== "PROVIDER" &&
                                                        item.status ==
                                                            "SCHEDULED" && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    marginRight: 3
                                                                }}
                                                                onClick={() =>
                                                                    handleNoShow(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                No show
                                                            </Button>
                                                        )}
                                                    {tab === "pending" &&
                                                        role !== "CLIENT" && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    margin: 4
                                                                }}
                                                                onClick={() =>
                                                                    handleReqAccepted(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                ACCEPT
                                                            </Button>
                                                        )}
                                                    {tab === "pending" &&
                                                        role !== "CLIENT" && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    margin: 4
                                                                }}
                                                                onClick={() =>
                                                                    handleReqDeclined(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                DECLINE
                                                            </Button>
                                                        )}
                                                    {(tab === "pending" ||
                                                        tab === "nonpending") &&
                                                        userHasAdminRole() && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    margin: 4
                                                                }}
                                                                onClick={() =>
                                                                    handleReqDetails(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Details
                                                            </Button>
                                                        )}
                                                    {tab !== "pending" &&
                                                        tab !== "nonpending" &&
                                                        userHasAdminRole() &&
                                                        !(
                                                            "COMPLETED" ===
                                                                item.status ||
                                                            "CANCELLED" ===
                                                                item.status
                                                        ) && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    fontSize: 13.5,
                                                                    padding:
                                                                        "1px",
                                                                    maxWidth:
                                                                        "90px",
                                                                    minWidth:
                                                                        "90px",
                                                                    marginRight: 3
                                                                }}
                                                                onClick={() =>
                                                                    handleOverride(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                OVERRIDE
                                                            </Button>
                                                        )}
                                                </div>
                                            )}
                                        </TableCell>
                                    }
                                </TableRow>
                            );
                        })}
            </TableBody>
            <TableFooter>
                {/* <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { "aria-label": "Rows per page" },
                            native: true
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow> */}
            </TableFooter>
        </Table>
    );
};
