import { getTimezoneIdByGeoLoc } from "../modules/TimeService";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { locationByProvider } from "../queries/AdhocBookingQueries";
import { createProviderLocation } from "../graphql/mutations";
import { getUserFromCache } from "../user/UserCommon";
const PROVIDER_LOCATION_REGEX = /(PL|CL|GL)-[^|]+/;

function addressIsNearProviderLocation(addr, pl, addrtz) {
    if (addrtz && pl.timezone) {
        if (addrtz.trim().toLowerCase() === pl.timezone.trim().toLowerCase()) {
            const bookLat = Number(addr.latitude || addr.coords.lat);
            const bookLng = Number(addr.longitude || addr.coords.lng);
            const plLat = Number(pl.latitude);
            const plLng = Number(pl.longitude);
            const lat = plLat - bookLat;
            const lng = plLng - bookLng;
            const dLat = (lat * Math.PI) / 180;
            const dLng = (lng * Math.PI) / 180;
            const bookLatRad = (bookLat * Math.PI) / 180;
            const plLatRad = (plLat * Math.PI) / 180;

            let a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(bookLatRad) *
                    Math.cos(plLatRad) *
                    Math.sin(dLng / 2) *
                    Math.sin(dLng / 2);
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            let d = 6371e3 * c;
            if (d < 50000) return true;
            //within 50km, then it is near an existing provider location
            else return false;
        }
    }
    return false;
}

export async function getOrCreateProviderLocation({ address, provider }) {
    let addrtz;

    try {
        addrtz = await getTimezoneIdByGeoLoc({
            lat: address.coords.lat,
            lng: address.coords.lng
        });
    } catch (err) {
        console.log(
            "could not get time zone from geo loc - addEditCompanyLocation"
        );
    }

    const locations = await graphql(
        graphqlOperation(locationByProvider, {
            providerId: provider.id,
            limit: 50
        })
    );

    let addressCity = address.city.toLowerCase();
    let addressState = address.prov.toLowerCase();
    let foundLocation = null;
    let providerLocations = locations.data.locationByProvider.items;
    for (let pl of providerLocations) {
        let plCity = pl.city.toLowerCase();
        let plState = pl.state.toLowerCase();
        if (plCity === addressCity && plState === addressState) {
            foundLocation = pl;
            break;
        }
        //OR
        if (addressIsNearProviderLocation(address, pl, addrtz)) {
            foundLocation = pl;
            break;
        }
    }

    if (foundLocation) return foundLocation;
    else {
        //create a new provider location
        let newProviderLocation = await createNewProviderLocation(
            address,
            provider,
            addrtz
        );
        return newProviderLocation;
    }
}

export async function createNewProviderLocation(address, provider, addrtz) {
    const loggedinUser = getUserFromCache();
    try {
        const companyId = loggedinUser.companyId;
        let input = {
            name: `${address.city}, ${address.prov}`,
            desc: `${address.city}, ${address.prov}`,
            addressoneline: address.addressOneLine,
            street: address.addressOneLine,
            city: address.city,
            state: address.prov,
            country: address.country,
            postalcode: address.postalCode,
            longitude: address.coords.lng,
            latitude: address.coords.lat,
            companyId: companyId,
            traveldistance: 50,
            traveldistanceunit: "km",
            maxtraveltype: "MAX_DISTANCE",
            travelregions: JSON.stringify([]),
            providerId: provider.id,
            providerLocationProviderId: provider.id,
            providerLocationCompanyId: companyId,
            timezone: addrtz,
            active: false,
            deleted: true
        };
        let response = await graphql(
            graphqlOperation(createProviderLocation, {
                input
            })
        );

        if (response && response.data && response.data.createProviderLocation) {
            return response.data.createProviderLocation;
        } else {
        }
    } catch (error) {
        console.error("Add Provider location error => ", error);
    }
}

export const extractLocationIdFromScheduleinfo = (inputString) => {
    const match = inputString.match(PROVIDER_LOCATION_REGEX);
    return match ? match[0] : null;
};
