import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormHelperText,
    Switch,
    FormControlLabel,
    TextField,
    Grid,
    FormControl,
    Select,
    MenuItem,
    RadioGroup,
    InputLabel,
    Radio,
    Card,
    CardContent,
    CardActions,
    Stack
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { enUS } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { ClientAddressInput } from "../../components/ClientAddressInput";
import MapComponent from "../../utils/CommonComonents/MapComponent";
import DateFrequencySelector from "../../components/DateFrequencySelector";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    delApiWithStatus,
    putApiWithStatusCode,
    postApiWithStatusCode
} from "../../modules/AmplifyServices";
import { useAtom } from "jotai";
import {
    selectedUserAtom,
    selectedScheduleAtom,
    userPageSnackbarAtom,
    selectedUserServicesAtom,
    selectedUserSchedulesAtom
} from "../../atoms/usersTable";
import {
    allActiveLocationsAtom,
    allUsersAtom,
    mbxUserAtom,
    updateUserInAllUsersAtom,
    deleteUserInAllUsersAtom,
    conformationDialogOpenAtom,
    conformationDialogContentAtom,
    confirmationFunctionAtom,
    conformationDialogTitleAtom
} from "../../atoms/atoms";
import { recordIdAtom } from "../../atoms/blockedTime";
import { extendGoogleMapsPolygon } from "../../modules/extendGoogleMapsPolygon";
import { prepareLocationPath } from "../../utils";
import { getCountryCode } from "../../utils";
import * as mutations from "../../graphql/mutations";
import { graphqlOperation, graphql } from "../../modules/AmplifyServices";
import {
    checkScheduleOverlap,
    checkTimeBlockOverlap
} from "../../utils/scheduleUtils";
import uuid from "uuid/v4";
import _ from "lodash";

const AvailabilityModal = ({ open, onClose, doneTodoHandler }) => {
    const autocompleteService = { current: null };
    const [mbxUser] = useAtom(mbxUserAtom);
    const [selectedUser] = useAtom(selectedUserAtom);
    const [selectedUserSchedules] = useAtom(selectedUserSchedulesAtom);
    const [selectedSchedule] = useAtom(selectedScheduleAtom);
    const [allActiveLocations] = useAtom(allActiveLocationsAtom);
    const [selectedUserServices] = useAtom(selectedUserServicesAtom);
    const [, setUserPageSnackbar] = useAtom(userPageSnackbarAtom);
    const [, setConfirmationDialogContent] = useAtom(
        conformationDialogContentAtom
    );
    const [, setConfirmationFunction] = useAtom(confirmationFunctionAtom);
    const [, setConfirmationDialogOpen] = useAtom(conformationDialogOpenAtom);
    const [, setConformationDialogTitle] = useAtom(conformationDialogTitleAtom);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openStartDate, setOpenStartDate] = useState(false);
    const [openEndDate, setOpenEndDate] = useState(false);

    const timeRangeConstructor = () => {
        const startTime = new Date().setHours(9, 0, 0, 0);
        const endTime = new Date().setHours(17, 0, 0, 0);
        let block = {
            startTime,
            endTime
        };
        if (selectedSchedule) {
            block = {
                ...block,
                id: `SB|${selectedSchedule.scheduleinfo}|${uuid()}`
            };
        }
        return block;
    };

    const {
        SB = [],
        SL = [],
        SR = [],
        id,
        active = true,
        scheduleinfo = "",
        providerId,
        travelZone = null
    } = selectedSchedule || {};
    const services = selectedSchedule
        ? JSON.parse(selectedSchedule.services)
        : _.map(selectedUserServices, "id");
    const locations = selectedSchedule
        ? JSON.parse(selectedSchedule.locations).map((item) =>
              item.substring(3)
          )
        : _.map(allActiveLocations, "id");
    const timeBlocks = SB.length
        ? SB.map((sb) => {
              return {
                  id: sb.scheduleinfo,
                  startTime: new Date().setHours(
                      ...sb.startTime.split(":").map(Number)
                  ),
                  endTime: new Date().setHours(
                      ...sb.endTime.split(":").map(Number)
                  )
              };
          })
        : [timeRangeConstructor()];

    const form = useForm({
        defaultValues: {
            startDate: SB.length
                ? new Date(
                      ...SB[0].startDate
                          .split("-")
                          .map((part, index) => (index === 1 ? part - 1 : part))
                  )
                : new Date(),
            endDate: SB.length
                ? new Date(
                      ...SB[0].endDate
                          .split("-")
                          .map((part, index) => (index === 1 ? part - 1 : part))
                  )
                : new Date(
                      new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
            numberSelector: {
                selectedDays: SB.length
                    ? SB[0].weekDays.split("").map(Number)
                    : [1, 2, 3, 4, 5],
                selectedNumber: SB.length ? SB[0].weeksToRepeat : 1
            },
            address: travelZone ? travelZone.addressoneline : "",
            addressAttributes: {
                street: travelZone ? travelZone.addressoneline : "",
                city: travelZone ? travelZone.city : "",
                state: travelZone ? travelZone.state : "",
                country: travelZone ? travelZone.country : "",
                postalcode: travelZone ? travelZone.postalcode : "",
                longitude: travelZone ? travelZone.longitude : null,
                latitude: travelZone ? travelZone.latitude : null
            },
            services: services || [],
            includePhysicalVirtualInTravelZone:
                travelZone &&
                SL.some((record) => record.scheduleinfo.includes(`CL-`))
                    ? true
                    : false,
            locations: locations || [],
            polygons: travelZone ? JSON.parse(travelZone.travelregions) : [],
            travelType: travelZone?.maxtraveltype
                ? travelZone.maxtraveltype
                : travelZone
                  ? "MAX_DISTANCE"
                  : selectedSchedule
                    ? "COMPANY_LOCATIONS"
                    : "DRAW_TRAVEL_REGION",
            travelDistance: travelZone ? travelZone.traveldistance : 25,
            travelDistanceUnit: travelZone
                ? travelZone.traveldistanceunit === "km"
                    ? "kilometer"
                    : "mile"
                : "mile",
            timeBlocks
        }
    });
    const {
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        control,
        watch,
        formState
    } = form;
    const { errors, isDirty } = formState;
    const travelType = watch("travelType");
    const travelDistance = watch("travelDistance");
    const travelDistanceUnit = watch("travelDistanceUnit");
    const addressAttributes = watch("addressAttributes");
    const address = watch("address");
    const polygons = watch("polygons");
    const includePhysicalVirtualInTravelZone = watch(
        "includePhysicalVirtualInTravelZone"
    );
    const {
        fields: timeBlockFields,
        append,
        remove
    } = useFieldArray({
        control,
        name: "timeBlocks"
    });

    /* START MAP FUNCTIONS */
    const drawTypeMap = {
        MAX_DISTANCE: 1,
        DRAW_TRAVEL_REGION: 2,
        COMPANY_LOCATIONS: 3
    };

    const checkLocationsInPolygons = (locations, polygons) => {
        //Ensure the Google Maps Polygon prototype is extended
        extendGoogleMapsPolygon();

        if (polygons.length === 0) {
            return locations;
        }

        const result = [];

        locations.forEach((location) => {
            const { latitude: lat, longitude: lng } = location;
            const point = new window.google.maps.LatLng(lat, lng);

            let isInsideAnyPolygon = false;

            for (const polygonPaths of polygons) {
                const polygon = new window.google.maps.Polygon({
                    paths: polygonPaths
                });

                if (polygon.containsLatLng(point)) {
                    isInsideAnyPolygon = true;
                    break;
                }
            }

            if (isInsideAnyPolygon) {
                result.push(location);
            }
        });

        return result;
    };

    const checkLocationsInCircle = (locations, centerPoint, radius) => {
        if (!centerPoint) {
            return locations;
        }

        const result = [];

        locations.forEach((location) => {
            const { latitude: lat, longitude: lng } = location;

            const isInsideCircle = () => {
                var ky = 40000 / 360;
                var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
                var dx = Math.abs(centerPoint.lng - lng) * kx;
                var dy = Math.abs(centerPoint.lat - lat) * ky;
                return Math.sqrt(dx * dx + dy * dy) <= radius;
            };
            if (isInsideCircle()) {
                result.push(location);
            }
        });
        return result;
    };
    /* END MAP FUNCTIONS */

    const getValidLocations = () => {
        switch (travelType) {
            case "DRAW_TRAVEL_REGION":
                if (!includePhysicalVirtualInTravelZone) return [];
                return checkLocationsInPolygons(allActiveLocations, polygons);
            case "MAX_DISTANCE":
                if (!includePhysicalVirtualInTravelZone) return [];
                return address
                    ? checkLocationsInCircle(
                          allActiveLocations,
                          {
                              lat: addressAttributes.latitude,
                              lng: addressAttributes.longitude
                          },
                          travelDistance *
                              (travelDistanceUnit === "kilometer" ? 1 : 1.60934)
                      )
                    : allActiveLocations;
            case "COMPANY_LOCATIONS":
                return allActiveLocations;
            default:
                return allActiveLocations;
        }
    };

    const saveProviderTravelZone = async () => {
        const {
            street = null,
            city = null,
            state = null,
            country = null,
            postalcode = null,
            longitude = null,
            latitude = null
        } = addressAttributes || {};
        try {
            let input = {
                name: `Travel zone`,
                addressoneline: address,
                street,
                city,
                state,
                country,
                postalcode,
                longitude,
                latitude,
                companyId: selectedUser.company.id,
                traveldistance: travelDistance,
                traveldistanceunit:
                    travelDistanceUnit === "kilometer" ? "km" : "mi",
                travelregions: JSON.stringify(polygons),
                providerId: selectedUser.provider.id,
                providerLocationProviderId: selectedUser.provider.id,
                providerLocationCompanyId: selectedUser.company.id,
                locationpath: prepareLocationPath(
                    getCountryCode(country),
                    state,
                    city,
                    postalcode
                ),
                timezone: selectedUser.provider.timezone,
                maxtraveltype: travelType
            };
            let response;
            if (selectedSchedule) {
                input = {
                    ...input,
                    id: selectedSchedule.travelZone.id
                };
                let providerLocation = await graphql(
                    graphqlOperation(mutations.updateProviderLocation, {
                        input
                    })
                );
                response = providerLocation.data.updateProviderLocation;
            } else {
                input.createdAt = new Date();
                let providerLocation = await graphql(
                    graphqlOperation(mutations.createProviderLocation, {
                        input
                    })
                );
                response = providerLocation.data.createProviderLocation;
            }
            return response;
        } catch (error) {
            console.log("Unable to update/add provider travel zone", error);
        }
    };

    const onSubmit = async (data) => {
        const options = { hour: "2-digit", minute: "2-digit", hour12: false };
        const adjustedTimeBlocks = data.timeBlocks.map((timeBlock) => {
            return {
                ...timeBlock,
                startTime: new Date(timeBlock.startTime).toLocaleTimeString(
                    "en-US",
                    options
                ),
                endTime: new Date(timeBlock.endTime).toLocaleTimeString(
                    "en-US",
                    options
                )
            };
        });
        //Ensure the locations array only includes valid locations
        const validLocations = getValidLocations();
        const sanitizedLocations = data.locations.filter((loc) =>
            validLocations.some((validLoc) => validLoc.id === loc)
        );
        let avdata = {
            active: true,
            timezone: selectedUser.provider.timezone,
            companyId: mbxUser.companyId,
            providerId: selectedUser.provider.id,
            weeksToRepeat: data.numberSelector.selectedNumber,
            weekDays: data.numberSelector.selectedDays,
            services: data.services,
            locations: sanitizedLocations.map((loc) => {
                return `CL-${loc}`;
            }),
            timeBlocks: adjustedTimeBlocks,
            startDate: data.startDate.toISOString().split("T")[0],
            endDate: new Date(data.endDate).toISOString().split("T")[0]
        };

        if (checkTimeBlockOverlap(avdata.timeBlocks)) {
            setUserPageSnackbar({
                open: true,
                message: `The selected times overlap. Please choose non-overlapping time ranges`,
                severity: "error"
            });
            return;
        }
        if (selectedUserSchedules?.length) {
            const overlapResult = handleCheckScheduleOverlap(avdata);
            if (overlapResult) {
                setUserPageSnackbar({
                    open: true,
                    message: `Schedule overlaps with existing schedule`,
                    severity: "error"
                });
                return;
            }
        }

        setIsSubmitting(true);
        try {
            if (travelType !== "COMPANY_LOCATIONS") {
                const { id: travelZoneId } = await saveProviderTravelZone();
                avdata = {
                    ...avdata,
                    locations: [...avdata.locations, `PL-${travelZoneId}`]
                };
            }
            if (!selectedSchedule) {
                //create route
                const { statusCode: status, body: newAvailabilityRecord } =
                    await postApiWithStatusCode(
                        "userManagementService",
                        "/user/availability",
                        {
                            body: avdata
                        }
                    );

                if (newAvailabilityRecord && status >= 200 && status < 300) {
                    onSuccess(
                        newAvailabilityRecord,
                        "Availability has been successfully created"
                    );
                } else {
                    onFailure(
                        status,
                        "Unexpected error: The availability was not created"
                    );
                    throw new Error(
                        `Failed to create new availability. Error: ${newAvailabilityRecord.message}`
                    );
                }
            } else {
                //edit route

                const { statusCode: status, body: updatedAvailabilityRecord } =
                    await putApiWithStatusCode(
                        "userManagementService",
                        `/user/availability/${selectedSchedule.scheduleinfo}`,
                        {
                            body: avdata
                        }
                    );

                if (
                    updatedAvailabilityRecord &&
                    status >= 200 &&
                    status < 300
                ) {
                    onSuccess(
                        updatedAvailabilityRecord,
                        "Availability has been successfully updated"
                    );
                } else {
                    onFailure(
                        status,
                        "Unexpected error: The availability was not updated"
                    );
                    throw new Error(
                        `Failed to update availability. Error: ${updatedAvailabilityRecord.message}`
                    );
                }
            }
        } catch (error) {
            console.log("Error submitting form data:", error);
            setIsSubmitting(false);
        }
    };

    function handleCheckScheduleOverlap(data) {
        const schedulesToCheck = selectedSchedule
            ? selectedUserSchedules.filter(
                  (schedule) =>
                      schedule.scheduleinfo !== selectedSchedule.scheduleinfo
              )
            : selectedUserSchedules;

        return data.timeBlocks.some((timeBlock) =>
            checkScheduleOverlap(
                data.startDate,
                data.endDate,
                timeBlock.startTime,
                timeBlock.endTime,
                data.weeksToRepeat,
                data.weekDays,
                data.services,
                schedulesToCheck
            )
        );
    }

    const onSuccess = async (availabilityData, successMessage) => {
        if (!selectedSchedule) {
            //create route
            console.log(
                "schedule create on success",
                availabilityData,
                successMessage
            );
        } else {
            //edit route
            console.log(
                "schedule edit on success",
                availabilityData,
                successMessage
            );
        }
        if (doneTodoHandler) doneTodoHandler("availability");
        setUserPageSnackbar({
            open: true,
            message: successMessage,
            severity: "success"
        });
        setIsSubmitting(false);
        onClose();
    };

    const onFailure = (status, errorMessage) => {
        setUserPageSnackbar({
            open: true,
            message: `${errorMessage} ${status ? `(${status})` : ""}`,
            severity: "error"
        });
    };

    const handleDelete = async (successCallBack) => {
        const { statusCode } = await delApiWithStatus(
            "userManagementService",
            `/user/availability/${selectedSchedule.scheduleinfo}`,
            { queryParams: { companyId: selectedUser.company.id, providerId } }
        );
        if (statusCode >= 200 && statusCode < 300) {
            handleClose();
            typeof successCallBack === "function" && successCallBack();
        } else {
            console.log(statusCode);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle
                variant="h3"
                sx={{
                    padding: "36px 36px 0px 36px"
                }}
            >
                {selectedSchedule ? "Edit availability " : "Add availabiliity"}
            </DialogTitle>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <DialogContent>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={{
                            ...enUS,
                            options: { ...enUS.options, weekStartsOn: 1 }
                        }}
                    >
                        <Grid
                            container
                            spacing={2.25}
                            sx={{ marginTop: "0px" }}
                        >
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value: startDate
                                        }
                                    }) => (
                                        <DatePicker
                                            autoFocus
                                            id="start-date"
                                            name="startDate"
                                            size="small"
                                            label="Start date"
                                            value={startDate}
                                            fullWidth
                                            inputFormat="MMMM d, yyyy"
                                            onChange={(newValue) => {
                                                onChangeFormHook(newValue);
                                                newValue >
                                                    getValues("endDate") &&
                                                    setValue(
                                                        "endDate",
                                                        newValue
                                                    );
                                            }}
                                            open={openStartDate}
                                            onOpen={() =>
                                                setOpenStartDate(true)
                                            }
                                            onClose={() =>
                                                setOpenStartDate(false)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true
                                                    }}
                                                    onClick={(e) =>
                                                        setOpenStartDate(true)
                                                    }
                                                    sx={{
                                                        "& fieldset": {
                                                            "& legend": {
                                                                width: "54px"
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value: endDate
                                        }
                                    }) => (
                                        <DatePicker
                                            id="end-date"
                                            name="endDate"
                                            size="small"
                                            label="End date"
                                            value={endDate}
                                            minDate={getValues("startDate")}
                                            fullWidth
                                            inputFormat="MMMM d, yyyy"
                                            onChange={onChangeFormHook}
                                            open={openEndDate}
                                            onOpen={() => setOpenEndDate(true)}
                                            onClose={() =>
                                                setOpenEndDate(false)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true
                                                    }}
                                                    onClick={(e) =>
                                                        setOpenEndDate(true)
                                                    }
                                                    sx={{
                                                        "& fieldset": {
                                                            "& legend": {
                                                                width: "50px"
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sm={12}
                                sx={{
                                    alignItems: "center",
                                    gap: "9px",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Controller
                                    name="numberSelector"
                                    control={control}
                                    rules={{
                                        required:
                                            "Please select atleast one day to repeat on"
                                    }}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value: {
                                                selectedDays,
                                                selectedNumber
                                            }
                                        }
                                    }) => (
                                        <DateFrequencySelector
                                            handleNumberSelectorChange={({
                                                target: { value }
                                            }) => {
                                                onChangeFormHook({
                                                    selectedDays,
                                                    selectedNumber: value
                                                });
                                            }}
                                            handleDayButtonClick={(day) => {
                                                if (
                                                    selectedDays.length === 1 &&
                                                    selectedDays[0] === day
                                                ) {
                                                    return;
                                                }
                                                const updatedDays =
                                                    selectedDays.includes(day)
                                                        ? selectedDays.filter(
                                                              (d) => d !== day
                                                          )
                                                        : [
                                                              ...selectedDays,
                                                              day
                                                          ];
                                                updatedDays.sort(
                                                    (a, b) => a - b
                                                );
                                                onChangeFormHook({
                                                    selectedDays: updatedDays,
                                                    selectedNumber
                                                });
                                            }}
                                            selectedDays={selectedDays}
                                            selectedNumber={selectedNumber}
                                        />
                                    )}
                                />
                                {!!errors.numberSelector && (
                                    <FormHelperText error>
                                        {errors?.numberSelector.message}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Card variant="outlined" sx={{ width: "100%" }}>
                                    <CardContent>
                                        {timeBlockFields.map((item, index) => (
                                            <Stack key={item.scheduleinfo}>
                                                <Grid item xs={12} sm={6}>
                                                    <Controller
                                                        name={`timeBlocks.${index}.startTime`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            const {
                                                                onChange:
                                                                    onChangeFormHook,
                                                                value: startTime
                                                            } = field;
                                                            return (
                                                                <TimePicker
                                                                    id={`start-time-${index}`}
                                                                    name={`timeBlocks.${index}.startTime`}
                                                                    size="small"
                                                                    label="Start time"
                                                                    value={
                                                                        startTime
                                                                    }
                                                                    minutesStep={
                                                                        5
                                                                    }
                                                                    fullWidth
                                                                    onChange={
                                                                        onChangeFormHook
                                                                    }
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            sx={{
                                                                                "& fieldset":
                                                                                    {
                                                                                        "& legend":
                                                                                            {
                                                                                                width: "55px"
                                                                                            }
                                                                                    }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                >
                                                    <Controller
                                                        name={`timeBlocks.${index}.endTime`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange:
                                                                    onChangeFormHook,
                                                                value: endTime
                                                            }
                                                        }) => (
                                                            <TimePicker
                                                                id="end-time"
                                                                name={`timeBlocks.${index}.endTime`}
                                                                size="small"
                                                                label="End time"
                                                                value={endTime}
                                                                minutesStep={5}
                                                                fullWidth
                                                                onChange={
                                                                    onChangeFormHook
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        fullWidth
                                                                        sx={{
                                                                            "& fieldset":
                                                                                {
                                                                                    "& legend":
                                                                                        {
                                                                                            width: "50px"
                                                                                        }
                                                                                }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    {index > 0 && (
                                                        <DeleteIcon
                                                            type="button"
                                                            onClick={() => {
                                                                timeBlockFields.length >
                                                                    1 &&
                                                                    remove(
                                                                        index
                                                                    );
                                                            }}
                                                        />
                                                    )}
                                                </Grid>
                                            </Stack>
                                        ))}
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            onClick={() => {
                                                return append(
                                                    timeRangeConstructor()
                                                );
                                            }}
                                        >
                                            + Add times
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="travel-type-label"
                                        size="small"
                                    >
                                        Coverage area
                                    </InputLabel>
                                    <Controller
                                        name="travelType"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange: onChangeFormHook,
                                                value: travelType
                                            }
                                        }) => (
                                            <Select
                                                labelId="coverage-area-label"
                                                id="travel-type"
                                                name="travelType"
                                                label="Coverage area"
                                                value={travelType}
                                                sx={{
                                                    "& fieldset": {
                                                        "& legend": {
                                                            width: "75px"
                                                        }
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    onChangeFormHook(
                                                        event.target.value
                                                    );
                                                    setValue(
                                                        "locations",
                                                        _.map(
                                                            allActiveLocations,
                                                            "id"
                                                        )
                                                    );
                                                    clearErrors("polygons");
                                                }}
                                            >
                                                <MenuItem
                                                    value={"DRAW_TRAVEL_REGION"}
                                                >
                                                    Drawn travel zone
                                                </MenuItem>
                                                <MenuItem
                                                    value={"MAX_DISTANCE"}
                                                >
                                                    Circular travel zone
                                                </MenuItem>
                                                <MenuItem
                                                    value={"COMPANY_LOCATIONS"}
                                                >
                                                    Physical Locations
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Controller
                                    name="travelDistance"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value: travelDistance
                                        }
                                    }) => (
                                        <TextField
                                            id="travel-distance"
                                            name="travelDistance"
                                            size="small"
                                            label="Radius"
                                            type="number"
                                            value={travelDistance}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            disabled={
                                                travelType !== "MAX_DISTANCE"
                                            }
                                            sx={{
                                                "& fieldset": {
                                                    "& legend": {
                                                        width: "40px"
                                                    }
                                                }
                                            }}
                                            onChange={(event) =>
                                                onChangeFormHook(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl component="fieldset">
                                    <Controller
                                        name="travelDistanceUnit"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange: onChangeFormHook,
                                                value: travelDistanceUnit
                                            }
                                        }) => (
                                            <RadioGroup
                                                aria-label="travel-distance-unit"
                                                name="travelDistanceUnit"
                                                sx={{ flexDirection: "row" }}
                                                value={travelDistanceUnit}
                                                onChange={(event) =>
                                                    onChangeFormHook(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <FormControlLabel
                                                    value="kilometer"
                                                    control={
                                                        <Radio
                                                            size="small"
                                                            disabled={
                                                                getValues(
                                                                    "travelType"
                                                                ) !==
                                                                "MAX_DISTANCE"
                                                            }
                                                        />
                                                    }
                                                    label="km"
                                                />
                                                <FormControlLabel
                                                    value="mile"
                                                    control={
                                                        <Radio
                                                            size="small"
                                                            disabled={
                                                                getValues(
                                                                    "travelType"
                                                                ) !==
                                                                "MAX_DISTANCE"
                                                            }
                                                        />
                                                    }
                                                    label="mi"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {getValues("travelType") !==
                                "COMPANY_LOCATIONS" && (
                                <Grid item xs={12} sm={12}>
                                    <Controller
                                        name="address"
                                        control={control}
                                        rules={{
                                            required: "Please enter an address",
                                            validate: {
                                                validateAddress: () =>
                                                    travelType ===
                                                    "COMPANY_LOCATIONS"
                                                        ? true
                                                        : address
                                                          ? true
                                                          : false
                                            }
                                        }}
                                        render={({
                                            field: {
                                                onChange: onChangeFormHook,
                                                value: {
                                                    address,
                                                    addressAttributes
                                                }
                                            }
                                        }) => (
                                            <ClientAddressInput
                                                setAddress={(value) => {
                                                    onChangeFormHook(
                                                        value.addressOneLine
                                                    );
                                                    setValue(
                                                        "addressAttributes",
                                                        {
                                                            street: value.route,
                                                            city: value.city,
                                                            state: value.prov,
                                                            country:
                                                                value.country,
                                                            postalcode:
                                                                value.postalCode,
                                                            longitude:
                                                                value.coords
                                                                    .lng,
                                                            latitude:
                                                                value.coords.lat
                                                        }
                                                    );
                                                }}
                                                formWidth={"100%"}
                                                formMargin={"0px"}
                                                formMarginLeft={"0px"}
                                                label={"Address"}
                                                addressOneLine={
                                                    getValues("address")
                                                        ? getValues("address")
                                                        : ""
                                                }
                                                autocompleteService={
                                                    autocompleteService
                                                }
                                                smallSize={true}
                                            />
                                        )}
                                    />
                                    {!!errors.address && (
                                        <FormHelperText
                                            error
                                            sx={{
                                                marginLeft: "14px",
                                                marginRight: "14px"
                                            }}
                                        >
                                            {errors?.address.message}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                sx={{
                                    display:
                                        getValues("travelType") ===
                                        "COMPANY_LOCATIONS"
                                            ? "none"
                                            : ""
                                }}
                            >
                                <Controller
                                    name="includePhysicalVirtualInTravelZone"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value
                                        }
                                    }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    id="include-physical-virtual"
                                                    name="includePhysicalVirtualInTravelZone"
                                                    checked={value}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChangeFormHook(
                                                            e.target.checked
                                                        );
                                                        setValue(
                                                            "locations",
                                                            _.map(
                                                                allActiveLocations,
                                                                "id"
                                                            )
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Include available physical / virtual locations inside travel zone"
                                        />
                                    )}
                                />
                            </Grid>
                            {(travelType === "COMPANY_LOCATIONS" ||
                                includePhysicalVirtualInTravelZone) && (
                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="locations-label"
                                            size="small"
                                        >
                                            Locations
                                        </InputLabel>
                                        <Controller
                                            name="locations"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Please select atleast one location",
                                                validate: {
                                                    validatePolygons: (
                                                        fieldValue
                                                    ) =>
                                                        travelType !==
                                                        "COMPANY_LOCATIONS"
                                                            ? true
                                                            : fieldValue.length >
                                                                0
                                                              ? true
                                                              : false
                                                }
                                            }}
                                            render={({
                                                field: {
                                                    onChange: onChangeFormHook,
                                                    value: locations
                                                }
                                            }) => (
                                                <Select
                                                    labelId="locations-label"
                                                    id="locations"
                                                    name="locations"
                                                    label="Locations"
                                                    multiple
                                                    value={locations}
                                                    sx={{
                                                        "& fieldset": {
                                                            "& legend": {
                                                                width: "55px"
                                                            }
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        onChangeFormHook(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    {getValidLocations().map(
                                                        (location) => (
                                                            <MenuItem
                                                                value={
                                                                    location.id
                                                                }
                                                            >
                                                                {
                                                                    location.locationname
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            )}
                                        />
                                        {!!errors.locations && (
                                            <FormHelperText error>
                                                {errors?.locations.message}
                                            </FormHelperText>
                                        )}
                                        {travelType !== "COMPANY_LOCATIONS" && (
                                            <FormHelperText
                                                component={"span"}
                                                sx={{
                                                    fontStyle: "italic",
                                                    fontSize: "12px",
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                You can be available at any of
                                                the above locations during your
                                                working hours – they will be
                                                treated as any other residential
                                                address for drive time
                                                calculations between bookings.
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="services-label"
                                        size="small"
                                    >
                                        Services
                                    </InputLabel>
                                    <Controller
                                        name="services"
                                        control={control}
                                        rules={{
                                            required:
                                                "Please select atleast one service"
                                        }}
                                        render={({
                                            field: {
                                                onChange: onChangeFormHook,
                                                value: services
                                            }
                                        }) => (
                                            <Select
                                                labelId="services-label"
                                                id="services"
                                                name="services"
                                                label="Services"
                                                multiple
                                                value={services}
                                                sx={{
                                                    "& fieldset": {
                                                        "& legend": {
                                                            width: "49px"
                                                        }
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    onChangeFormHook(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {selectedUserServices.map(
                                                    (service) => (
                                                        <MenuItem
                                                            value={service.id}
                                                        >
                                                            {service.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    />
                                    {!!errors.services && (
                                        <FormHelperText error>
                                            {errors?.services.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {travelType !== "COMPANY_LOCATIONS" && (
                                <Grid item xs={12} sm={12}>
                                    <Controller
                                        name="polygons"
                                        control={control}
                                        rules={{
                                            required:
                                                "Please draw atleast one zone",
                                            validate: {
                                                validatePolygons: () =>
                                                    travelType ===
                                                    "COMPANY_LOCATIONS"
                                                        ? true
                                                        : address &&
                                                            polygons.length > 0
                                                          ? true
                                                          : false
                                            }
                                        }}
                                        render={({
                                            field: {
                                                onChange: onChangeFormHook,
                                                value: polygons
                                            }
                                        }) => (
                                            <MapComponent
                                                fromDashboard={() => {
                                                    return false;
                                                }}
                                                lat={
                                                    addressAttributes?.latitude
                                                }
                                                lng={
                                                    addressAttributes?.longitude
                                                }
                                                location={address}
                                                polygons={polygons}
                                                setPolygons={(polygon) => {
                                                    setValue("polygons", [
                                                        ...polygons,
                                                        polygon
                                                    ]);
                                                    clearErrors("polygons");
                                                }}
                                                undoPolygon={() =>
                                                    setValue(
                                                        "polygons",
                                                        polygons.slice(0, -1)
                                                    )
                                                }
                                                radius={
                                                    travelDistance *
                                                    (travelDistanceUnit ===
                                                    "kilometer"
                                                        ? 1000
                                                        : 1.60934 * 1000)
                                                }
                                                drawType={
                                                    drawTypeMap[travelType]
                                                }
                                                viewType={"Add"}
                                                deletePolygon={() =>
                                                    setValue(
                                                        "polygons",
                                                        polygons.slice(0, -1)
                                                    )
                                                }
                                                mapHeight={"300px"}
                                                mapWidth={"100%"}
                                                hideSaveClose={true}
                                            />
                                        )}
                                    />
                                    {!!errors.polygons &&
                                        travelType === "DRAW_TRAVEL_REGION" && (
                                            <FormHelperText error>
                                                {errors?.polygons.message}
                                            </FormHelperText>
                                        )}
                                </Grid>
                            )}
                        </Grid>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: selectedSchedule
                            ? "space-between"
                            : "flex-end"
                    }}
                >
                    {selectedSchedule && (
                        <Button
                            onClick={() => {
                                setConfirmationFunction(() =>
                                    handleDelete(() =>
                                        setConfirmationDialogOpen(false)
                                    )
                                );
                                setConfirmationDialogContent(
                                    "Are you sure you want to delete this availability"
                                );
                                setConformationDialogTitle(
                                    "Delete availability"
                                );
                                setConfirmationDialogOpen(true);
                            }}
                            variant="text"
                            color="error"
                        >
                            Delete availability
                        </Button>
                    )}
                    <Grid display={"flex"} gap={"18px"}>
                        <Button onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            loading={isSubmitting}
                            disabled={selectedSchedule && !isDirty}
                            type="submit"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AvailabilityModal;
