import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import AddIcon from "@mui/icons-material/Add";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Paper, Grid, Button } from "@mui/material";
// prettier-ignore
import { Snackbar } from "@mui/material";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { CompanyLocationTable } from "../components/CompanyLocationTable";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import {
    getUserFromCache,
    userHasMarketboxAdminRole
} from "../user/UserCommon.js";
import { getUserRole } from "../user/UserCommon";
import { auditCompanyLocDelete } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

export const CompanyLocationForm = (props) => {
    const navigate = useNavigate();

    const [selected, setSelected] = useState([]);
    const [values, setValues] = React.useState({
        // active: true,
        searchTerms: ""
    });
    const [rows, setRows] = useState([]);

    const [deleteDialog, setDeleteDialog] = useState(false);

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const user = getUserFromCache();
    const role = getUserRole();

    useEffect(() => {
        fetchCompanyLocationsList();
    }, []);

    const { actions, state } = useContext(StoreContext);
    const classes = usePromotionsStyles();

    const loggedInUser = getUserFromCache();

    const handleCompanyAddClick = () => {
        // temp bug fix
        localStorage.setItem("companyId", user.company.id);
        if (!!localStorage.getItem("companyId")) {
            actions.setMode("Add");
            let companyId = user.company.id;
            
            actions.setContextCompanyId(companyId);
            navigate("create");
            // actions.setId(Cache.getItem('user').company.id)
        } else {
            setSnackMsg("Please select company from header first.");
            setMsgOpen(true);
        }
    };
    const fetchCompanyLocationsList = async () => {
        let companyId;

        companyId = user.company.id;

        const filter = {
            and: [{ active: { ne: false } }, { deleted: { ne: true } }]
        };

        const listCompanyData = await execReadByPK({
            opname: "companyLocationByCompany",
            op: queries.companyLocationByCompany,
            id: {
                companyId: companyId
            },
            filter: filter
        });

        setRows(listCompanyData.items);
    };

    const _handleDeleteDialogDecline = () => {
        setDeleteDialog(false);
    };

    const _handleCompanyLocationChange = ({ target: { name, value } }) => {
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));
    };

    const deleteCompany = async (id) => {
        const input = {
            id: id,
            deleted: true
        };
        try {
            let deleteCompany = await graphql(
                graphqlOperation(mutations.updateCompanyLocation, { input })
            );
            await auditCompanyLocDelete(
                loggedInUser,
                deleteCompany.data.updateCompanyLocation
            );
            if (!!deleteCompany.error && deleteCompany.error.length > 0) {
                setSnackMsg("Unable to delete company location.");
                setMsgOpen(true);
            } else {
                fetchCompanyLocationsList();
                setDeleteDialog(false);
                setSelected([]);
            }
        } catch (err) {
            setSnackMsg("Unable to delete company location.");
            setMsgOpen(true);
        }
    };
    const _handleDeleteDialogAccept = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteCompany(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    const handleMsgClose = (event, reason) => {
        setMsgOpen(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="Company Locations"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <Paper rounded="true" className={classes.root}>
                {/* <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <TextField
                        id="search"
                        name="searchTerms"
                        label="Search by name"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={values.searchTerms}              
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                            )
                        }}
                        onChange={_handleCompanyLocationChange}
                        />
                    </Grid>
                </Grid> */}
                <Grid container alignItems="center">
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCompanyAddClick}
                        >
                            <AddIcon className={classes.leftIcon} />
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <EnhancedToolbar
                            numSelected={selected.length}
                            handleDelete={() => setDeleteDialog(true)}
                        />
                        <CompanyLocationTable
                            rows={rows}
                            selectedRow={(selected) => setSelected(selected)}
                            companyId={user.company.id}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
