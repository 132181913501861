import React from "react";
import { option, Select } from "@mui/material";
export const TimezoneList = ({ value, onChange }) => {
    return (
        <Select
            label="Timezone"
            native
            labelId="timezone-select-label"
            id="timezone-select"
            required
            value={value}
            onChange={onChange}
        >
            <option value={"America/Adak"}>America/Adak</option>
            <option value={"America/Anchorage"}>America/Anchorage</option>
            <option value={"America/Anguilla"}>America/Anguilla</option>
            <option value={"America/Antigua"}>America/Antigua</option>
            <option value={"America/Araguaina"}>America/Araguaina</option>
            <option value={"America/Argentina/Buenos_Aires"}>
                America/Argentina/Buenos_Aires
            </option>
            <option value={"America/Argentina/Catamarca"}>
                America/Argentina/Catamarca
            </option>
            <option value={"America/Argentina/Cordoba"}>
                America/Argentina/Cordoba
            </option>
            <option value={"America/Argentina/Jujuy"}>
                America/Argentina/Jujuy
            </option>
            <option value={"America/Argentina/La_Rioja"}>
                America/Argentina/La_Rioja
            </option>
            <option value={"America/Argentina/Mendoza"}>
                America/Argentina/Mendoza
            </option>
            <option value={"America/Argentina/Rio_Gallegos"}>
                America/Argentina/Rio_Gallegos
            </option>
            <option value={"America/Argentina/Salta"}>
                America/Argentina/Salta
            </option>
            <option value={"America/Argentina/San_Juan"}>
                America/Argentina/San_Juan
            </option>
            <option value={"America/Argentina/San_Luis"}>
                America/Argentina/San_Luis
            </option>
            <option value={"America/Argentina/Tucuman"}>
                America/Argentina/Tucuman
            </option>
            <option value={"America/Argentina/Ushuaia"}>
                America/Argentina/Ushuaia
            </option>
            <option value={"America/Aruba"}>America/Aruba</option>
            <option value={"America/Asuncion"}>America/Asuncion</option>
            <option value={"America/Atikokan"}>America/Atikokan</option>
            <option value={"America/Bahia"}>America/Bahia</option>
            <option value={"America/Bahia_Banderas"}>
                America/Bahia_Banderas
            </option>
            <option value={"America/Barbados"}>America/Barbados</option>
            <option value={"America/Belem"}>America/Belem</option>
            <option value={"America/Belize"}>America/Belize</option>
            <option value={"America/Blanc-Sablon"}>America/Blanc-Sablon</option>
            <option value={"America/Boa_Vista"}>America/Boa_Vista</option>
            <option value={"America/Bogota"}>America/Bogota</option>
            <option value={"America/Boise"}>America/Boise</option>
            <option value={"America/Cambridge_Bay"}>
                America/Cambridge_Bay
            </option>
            <option value={"America/Campo_Grande"}>America/Campo_Grande</option>
            <option value={"America/Cancun"}>America/Cancun</option>
            <option value={"America/Caracas"}>America/Caracas</option>
            <option value={"America/Cayenne"}>America/Cayenne</option>
            <option value={"America/Cayman"}>America/Cayman</option>
            <option value={"America/Chicago"}>America/Chicago</option>
            <option value={"America/Chihuahua"}>America/Chihuahua</option>
            <option value={"America/Costa_Rica"}>America/Costa_Rica</option>
            <option value={"America/Creston"}>America/Creston</option>
            <option value={"America/Cuiaba"}>America/Cuiaba</option>
            <option value={"America/Curacao"}>America/Curacao</option>
            <option value={"America/Danmarkshavn"}>America/Danmarkshavn</option>
            <option value={"America/Dawson"}>America/Dawson</option>
            <option value={"America/Dawson_Creek"}>America/Dawson_Creek</option>
            <option value={"America/Denver"}>America/Denver</option>
            <option value={"America/Detroit"}>America/Detroit</option>
            <option value={"America/Dominica"}>America/Dominica</option>
            <option value={"America/Edmonton"}>America/Edmonton</option>
            <option value={"America/Eirunepe"}>America/Eirunepe</option>
            <option value={"America/El_Salvador"}>America/El_Salvador</option>
            <option value={"America/Fort_Nelson"}>America/Fort_Nelson</option>
            <option value={"America/Fortaleza"}>America/Fortaleza</option>
            <option value={"America/Glace_Bay"}>America/Glace_Bay</option>
            <option value={"America/Goose_Bay"}>America/Goose_Bay</option>
            <option value={"America/Grand_Turk"}>America/Grand_Turk</option>
            <option value={"America/Grenada"}>America/Grenada</option>
            <option value={"America/Guadeloupe"}>America/Guadeloupe</option>
            <option value={"America/Guatemala"}>America/Guatemala</option>
            <option value={"America/Guayaquil"}>America/Guayaquil</option>
            <option value={"America/Guyana"}>America/Guyana</option>
            <option value={"America/Halifax"}>America/Halifax</option>
            <option value={"America/Havana"}>America/Havana</option>
            <option value={"America/Hermosillo"}>America/Hermosillo</option>
            <option value={"America/Indiana/Indianapolis"}>
                America/Indiana/Indianapolis
            </option>
            <option value={"America/Indiana/Knox"}>America/Indiana/Knox</option>
            <option value={"America/Indiana/Marengo"}>
                America/Indiana/Marengo
            </option>
            <option value={"America/Indiana/Petersburg"}>
                America/Indiana/Petersburg
            </option>
            <option value={"America/Indiana/Tell_City"}>
                America/Indiana/Tell_City
            </option>
            <option value={"America/Indiana/Vevay"}>
                America/Indiana/Vevay
            </option>
            <option value={"America/Indiana/Vincennes"}>
                America/Indiana/Vincennes
            </option>
            <option value={"America/Indiana/Winamac"}>
                America/Indiana/Winamac
            </option>
            <option value={"America/Inuvik"}>America/Inuvik</option>
            <option value={"America/Iqaluit"}>America/Iqaluit</option>
            <option value={"America/Jamaica"}>America/Jamaica</option>
            <option value={"America/Juneau"}>America/Juneau</option>
            <option value={"America/Kentucky/Louisville"}>
                America/Kentucky/Louisville
            </option>
            <option value={"America/Kentucky/Monticello"}>
                America/Kentucky/Monticello
            </option>
            <option value={"America/Kralendijk"}>America/Kralendijk</option>
            <option value={"America/La_Paz"}>America/La_Paz</option>
            <option value={"America/Lima"}>America/Lima</option>
            <option value={"America/Los_Angeles"}>America/Los_Angeles</option>
            <option value={"America/Lower_Princes"}>
                America/Lower_Princes
            </option>
            <option value={"America/Maceio"}>America/Maceio</option>
            <option value={"America/Managua"}>America/Managua</option>
            <option value={"America/Manaus"}>America/Manaus</option>
            <option value={"America/Marigot"}>America/Marigot</option>
            <option value={"America/Martinique"}>America/Martinique</option>
            <option value={"America/Matamoros"}>America/Matamoros</option>
            <option value={"America/Mazatlan"}>America/Mazatlan</option>
            <option value={"America/Menominee"}>America/Menominee</option>
            <option value={"America/Merida"}>America/Merida</option>
            <option value={"America/Metlakatla"}>America/Metlakatla</option>
            <option value={"America/Mexico_City"}>America/Mexico_City</option>
            <option value={"America/Miquelon"}>America/Miquelon</option>
            <option value={"America/Moncton"}>America/Moncton</option>
            <option value={"America/Monterrey"}>America/Monterrey</option>
            <option value={"America/Montevideo"}>America/Montevideo</option>
            <option value={"America/Montserrat"}>America/Montserrat</option>
            <option value={"America/Nassau"}>America/Nassau</option>
            <option value={"America/New_York"}>America/New_York</option>
            <option value={"America/Nipigon"}>America/Nipigon</option>
            <option value={"America/Nome"}>America/Nome</option>
            <option value={"America/Noronha"}>America/Noronha</option>
            <option value={"America/North_Dakota/Beulah"}>
                America/North_Dakota/Beulah
            </option>
            <option value={"America/North_Dakota/Center"}>
                America/North_Dakota/Center
            </option>
            <option value={"America/North_Dakota/New_Salem"}>
                America/North_Dakota/New_Salem
            </option>
            <option value={"America/Nuuk"}>America/Nuuk</option>
            <option value={"America/Ojinaga"}>America/Ojinaga</option>
            <option value={"America/Panama"}>America/Panama</option>
            <option value={"America/Pangnirtung"}>America/Pangnirtung</option>
            <option value={"America/Paramaribo"}>America/Paramaribo</option>
            <option value={"America/Phoenix"}>America/Phoenix</option>
            <option value={"America/Port-au-Prince"}>
                America/Port-au-Prince
            </option>
            <option value={"America/Port_of_Spain"}>
                America/Port_of_Spain
            </option>
            <option value={"America/Porto_Velho"}>America/Porto_Velho</option>
            <option value={"America/Puerto_Rico"}>America/Puerto_Rico</option>
            <option value={"America/Punta_Arenas"}>America/Punta_Arenas</option>
            <option value={"America/Rainy_River"}>America/Rainy_River</option>
            <option value={"America/Rankin_Inlet"}>America/Rankin_Inlet</option>
            <option value={"America/Recife"}>America/Recife</option>
            <option value={"America/Regina"}>America/Regina</option>
            <option value={"America/Resolute"}>America/Resolute</option>
            <option value={"America/Rio_Branco"}>America/Rio_Branco</option>
            <option value={"America/Santarem"}>America/Santarem</option>
            <option value={"America/Santiago"}>America/Santiago</option>
            <option value={"America/Santo_Domingo"}>
                America/Santo_Domingo
            </option>
            <option value={"America/Sao_Paulo"}>America/Sao_Paulo</option>
            <option value={"America/Scoresbysund"}>America/Scoresbysund</option>
            <option value={"America/Sitka"}>America/Sitka</option>
            <option value={"America/St_Barthelemy"}>
                America/St_Barthelemy
            </option>
            <option value={"America/St_Johns"}>America/St_Johns</option>
            <option value={"America/St_Kitts"}>America/St_Kitts</option>
            <option value={"America/St_Lucia"}>America/St_Lucia</option>
            <option value={"America/St_Thomas"}>America/St_Thomas</option>
            <option value={"America/St_Vincent"}>America/St_Vincent</option>
            <option value={"America/Swift_Current"}>
                America/Swift_Current
            </option>
            <option value={"America/Tegucigalpa"}>America/Tegucigalpa</option>
            <option value={"America/Thule"}>America/Thule</option>
            <option value={"America/Thunder_Bay"}>America/Thunder_Bay</option>
            <option value={"America/Tijuana"}>America/Tijuana</option>
            <option value={"America/Toronto"}>America/Toronto</option>
            <option value={"America/Tortola"}>America/Tortola</option>
            <option value={"America/Vancouver"}>America/Vancouver</option>
            <option value={"America/Whitehorse"}>America/Whitehorse</option>
            <option value={"America/Winnipeg"}>America/Winnipeg</option>
            <option value={"America/Yakutat"}>America/Yakutat</option>
            <option value={"America/Yellowknife"}>America/Yellowknife</option>
            <option value={"Atlantic/Azores"}>Atlantic/Azores</option>
            <option value={"Atlantic/Bermuda"}>Atlantic/Bermuda</option>
            <option value={"Atlantic/Canary"}>Atlantic/Canary</option>
            <option value={"Atlantic/Cape_Verde"}>Atlantic/Cape_Verde</option>
            <option value={"Atlantic/Faroe"}>Atlantic/Faroe</option>
            <option value={"Atlantic/Madeira"}>Atlantic/Madeira</option>
            <option value={"Atlantic/Reykjavik"}>Atlantic/Reykjavik</option>
            <option value={"Atlantic/South_Georgia"}>
                Atlantic/South_Georgia
            </option>
            <option value={"Atlantic/St_Helena"}>Atlantic/St_Helena</option>
            <option value={"Atlantic/Stanley"}>Atlantic/Stanley</option>
            <option value={"Australia/Adelaide"}>Australia/Adelaide</option>
            <option value={"Australia/Brisbane"}>Australia/Brisbane</option>
            <option value={"Australia/Broken_Hill"}>
                Australia/Broken_Hill
            </option>
            <option value={"Australia/Currie"}>Australia/Currie</option>
            <option value={"Australia/Darwin"}>Australia/Darwin</option>
            <option value={"Australia/Eucla"}>Australia/Eucla</option>
            <option value={"Australia/Hobart"}>Australia/Hobart</option>
            <option value={"Australia/Lindeman"}>Australia/Lindeman</option>
            <option value={"Australia/Lord_Howe"}>Australia/Lord_Howe</option>
            <option value={"Australia/Melbourne"}>Australia/Melbourne</option>
            <option value={"Australia/Perth"}>Australia/Perth</option>
            <option value={"Australia/Sydney"}>Australia/Sydney</option>
            <option value={"Europe/Amsterdam"}>Europe/Amsterdam</option>
            <option value={"Europe/Andorra"}>Europe/Andorra</option>
            <option value={"Europe/Astrakhan"}>Europe/Astrakhan</option>
            <option value={"Europe/Athens"}>Europe/Athens</option>
            <option value={"Europe/Belgrade"}>Europe/Belgrade</option>
            <option value={"Europe/Berlin"}>Europe/Berlin</option>
            <option value={"Europe/Bratislava"}>Europe/Bratislava</option>
            <option value={"Europe/Brussels"}>Europe/Brussels</option>
            <option value={"Europe/Bucharest"}>Europe/Bucharest</option>
            <option value={"Europe/Budapest"}>Europe/Budapest</option>
            <option value={"Europe/Busingen"}>Europe/Busingen</option>
            <option value={"Europe/Chisinau"}>Europe/Chisinau</option>
            <option value={"Europe/Copenhagen"}>Europe/Copenhagen</option>
            <option value={"Europe/Dublin"}>Europe/Dublin</option>
            <option value={"Europe/Gibraltar"}>Europe/Gibraltar</option>
            <option value={"Europe/Guernsey"}>Europe/Guernsey</option>
            <option value={"Europe/Helsinki"}>Europe/Helsinki</option>
            <option value={"Europe/Isle_of_Man"}>Europe/Isle_of_Man</option>
            <option value={"Europe/Istanbul"}>Europe/Istanbul</option>
            <option value={"Europe/Jersey"}>Europe/Jersey</option>
            <option value={"Europe/Kaliningrad"}>Europe/Kaliningrad</option>
            <option value={"Europe/Kiev"}>Europe/Kiev</option>
            <option value={"Europe/Kirov"}>Europe/Kirov</option>
            <option value={"Europe/Lisbon"}>Europe/Lisbon</option>
            <option value={"Europe/Ljubljana"}>Europe/Ljubljana</option>
            <option value={"Europe/London"}>Europe/London</option>
            <option value={"Europe/Luxembourg"}>Europe/Luxembourg</option>
            <option value={"Europe/Madrid"}>Europe/Madrid</option>
            <option value={"Europe/Malta"}>Europe/Malta</option>
            <option value={"Europe/Mariehamn"}>Europe/Mariehamn</option>
            <option value={"Europe/Minsk"}>Europe/Minsk</option>
            <option value={"Europe/Monaco"}>Europe/Monaco</option>
            <option value={"Europe/Moscow"}>Europe/Moscow</option>
            <option value={"Europe/Oslo"}>Europe/Oslo</option>
            <option value={"Europe/Paris"}>Europe/Paris</option>
            <option value={"Europe/Podgorica"}>Europe/Podgorica</option>
            <option value={"Europe/Prague"}>Europe/Prague</option>
            <option value={"Europe/Riga"}>Europe/Riga</option>
            <option value={"Europe/Rome"}>Europe/Rome</option>
            <option value={"Europe/Samara"}>Europe/Samara</option>
            <option value={"Europe/San_Marino"}>Europe/San_Marino</option>
            <option value={"Europe/Sarajevo"}>Europe/Sarajevo</option>
            <option value={"Europe/Saratov"}>Europe/Saratov</option>
            <option value={"Europe/Simferopol"}>Europe/Simferopol</option>
            <option value={"Europe/Skopje"}>Europe/Skopje</option>
            <option value={"Europe/Sofia"}>Europe/Sofia</option>
            <option value={"Europe/Stockholm"}>Europe/Stockholm</option>
            <option value={"Europe/Tallinn"}>Europe/Tallinn</option>
            <option value={"Europe/Tirane"}>Europe/Tirane</option>
            <option value={"Europe/Ulyanovsk"}>Europe/Ulyanovsk</option>
            <option value={"Europe/Uzhgorod"}>Europe/Uzhgorod</option>
            <option value={"Europe/Vaduz"}>Europe/Vaduz</option>
            <option value={"Europe/Vatican"}>Europe/Vatican</option>
            <option value={"Europe/Vienna"}>Europe/Vienna</option>
            <option value={"Europe/Vilnius"}>Europe/Vilnius</option>
            <option value={"Europe/Volgograd"}>Europe/Volgograd</option>
            <option value={"Europe/Warsaw"}>Europe/Warsaw</option>
            <option value={"Europe/Zagreb"}>Europe/Zagreb</option>
            <option value={"Europe/Zaporozhye"}>Europe/Zaporozhye</option>
            <option value={"Europe/Zurich"}>Europe/Zurich</option>
            <option value={"Pacific/Apia"}>Pacific/Apia</option>
            <option value={"Pacific/Auckland"}>Pacific/Auckland</option>
            <option value={"Pacific/Bougainville"}>Pacific/Bougainville</option>
            <option value={"Pacific/Chatham"}>Pacific/Chatham</option>
            <option value={"Pacific/Chuuk"}>Pacific/Chuuk</option>
            <option value={"Pacific/Easter"}>Pacific/Easter</option>
            <option value={"Pacific/Efate"}>Pacific/Efate</option>
            <option value={"Pacific/Enderbury"}>Pacific/Enderbury</option>
            <option value={"Pacific/Fakaofo"}>Pacific/Fakaofo</option>
            <option value={"Pacific/Fiji"}>Pacific/Fiji</option>
            <option value={"Pacific/Funafuti"}>Pacific/Funafuti</option>
            <option value={"Pacific/Galapagos"}>Pacific/Galapagos</option>
            <option value={"Pacific/Gambier"}>Pacific/Gambier</option>
            <option value={"Pacific/Guadalcanal"}>Pacific/Guadalcanal</option>
            <option value={"Pacific/Guam"}>Pacific/Guam</option>
            <option value={"Pacific/Honolulu"}>Pacific/Honolulu</option>
            <option value={"Pacific/Kiritimati"}>Pacific/Kiritimati</option>
            <option value={"Pacific/Kosrae"}>Pacific/Kosrae</option>
            <option value={"Pacific/Kwajalein"}>Pacific/Kwajalein</option>
            <option value={"Pacific/Majuro"}>Pacific/Majuro</option>
            <option value={"Pacific/Marquesas"}>Pacific/Marquesas</option>
            <option value={"Pacific/Midway"}>Pacific/Midway</option>
            <option value={"Pacific/Nauru"}>Pacific/Nauru</option>
            <option value={"Pacific/Niue"}>Pacific/Niue</option>
            <option value={"Pacific/Norfolk"}>Pacific/Norfolk</option>
            <option value={"Pacific/Noumea"}>Pacific/Noumea</option>
            <option value={"Pacific/Pago_Pago"}>Pacific/Pago_Pago</option>
            <option value={"Pacific/Palau"}>Pacific/Palau</option>
            <option value={"Pacific/Pitcairn"}>Pacific/Pitcairn</option>
            <option value={"Pacific/Pohnpei"}>Pacific/Pohnpei</option>
            <option value={"Pacific/Port_Moresby"}>Pacific/Port_Moresby</option>
            <option value={"Pacific/Rarotonga"}>Pacific/Rarotonga</option>
            <option value={"Pacific/Saipan"}>Pacific/Saipan</option>
            <option value={"Pacific/Tahiti"}>Pacific/Tahiti</option>
            <option value={"Pacific/Tarawa"}>Pacific/Tarawa</option>
            <option value={"Pacific/Tongatapu"}>Pacific/Tongatapu</option>
            <option value={"Pacific/Wake"}>Pacific/Wake</option>
            <option value={"Pacific/Wallis"}>Pacific/Wallis</option>
        </Select>
    );
};
