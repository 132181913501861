import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Services from "./Service";
import { getCategoryItemStyle, getCategoryListStyle } from "./msoStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { graphql, graphqlOperation } from "../../modules/AmplifyServices";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
    updateServiceType,
    updateServiceTypeCategory
} from "../../graphql/mutations";
import { Snackbar, Grid, Button, Paper } from "@mui/material";
import { getUserFromCache } from "../../user/UserCommon";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    instructions: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    headingCategory: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold
    },
    headingService: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold
    }
}));

const serviceTypeByCompany = /* GraphQL */ `
    query ServiceTypeByCompany(
        $companyId: String
        $categoryNameName: ModelServiceTypeByCompanySortedByCategoryNameNameCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelServiceTypeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        serviceTypeByCompany(
            companyId: $companyId
            categoryNameName: $categoryNameName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                categoryName
                minutes
                price
                active
                deleted
                isVisible
                ordinal
                category {
                    id
                    name
                    ordinal
                }
            }
            nextToken
        }
    }
`;

function ManageServiceOrder(props) {
    const navigate = useNavigate();
    const { state, actions } = useContext(StoreContext);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const [existingOrder, setExistingOrder] = useState([]);
    const [loadServices, setLoadServices] = useState(true);
    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        //setAllServices(MsoTestData());
        async function getAllServices(companyId) {
            const result = await graphql(
                graphqlOperation(serviceTypeByCompany, {
                    companyId: companyId,
                    limit: process.env.REACT_APP_LISTLIMIT,
                    filter: {
                        and: [
                            { deleted: { ne: true } },
                            { isVisible: { ne: false } },
                            { active: { ne: false } }
                        ]
                    }
                })
            );
            let allNestedServices = createNestedServices(
                result.data.serviceTypeByCompany.items
            );
            setExistingOrder(allNestedServices);
            setAllServices(allNestedServices);
        }
        if (loadServices) {
            const authuser = getUserFromCache();
            getAllServices(authuser.company.id);
        }
        if (loadServices) setLoadServices(false);
    }, []);

    function createNestedServices(services) {
        let groupByCategory = services.reduce((acc, s, i) => {
            if (s.category && s.category.id) {
                if (!acc[s.category.id])
                    acc[s.category.id] = {
                        id: s.category.id,
                        name: s.category.name,
                        ordinal: s.category.ordinal,
                        services: []
                    };
                acc[s.category.id].services.push({
                    id: s.id,
                    name: s.name,
                    categoryName: s.categoryName,
                    ordinal: s.ordinal
                });
            }

            return acc;
        }, {});

        let nestedCategoryServices = [];
        for (let p in groupByCategory)
            nestedCategoryServices.push(groupByCategory[p]);

        nestedCategoryServices.sort((sc1, sc2) => {
            if (sc1.ordinal > sc2.ordinal) return 1;
            if (sc1.ordinal < sc2.ordinal) return -1;
            return 0;
        });

        for (let sc of nestedCategoryServices) {
            sc.services.sort((s1, s2) => {
                if (s1.ordinal > s2.ordinal) return 1;
                if (s1.ordinal < s2.ordinal) return -1;
                return 0;
            });
        }
        return nestedCategoryServices;
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function onDragEnd(result) {
        const { destination, source, draggableId, type } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        if (type === "CATEGORIES") {
            //console.log(result);
            const alls = reorder(allServices, source.index, destination.index);

            setAllServices(alls);
        } else {
            const services = reorder(
                allServices[parseInt(type, 10)].services,
                source.index,
                destination.index
            );

            const newAllServices = JSON.parse(JSON.stringify(allServices));

            newAllServices[type].services = services;

            setAllServices(newAllServices);
        }
    }
    async function saveNewOrder() {
        setSaveInProgress(true);
        try {
            let changedOrderForCategories = [];
            let changedOrderForServices = [];
            for (let sci = 0; sci < allServices.length; sci++) {
                if (allServices[sci].ordinal !== sci + 1)
                    changedOrderForCategories.push({
                        id: allServices[sci].id,
                        ordinal: sci + 1
                    });
                if (
                    allServices[sci].services &&
                    allServices[sci].services.length
                ) {
                    for (
                        let si = 0;
                        si < allServices[sci].services.length;
                        si++
                    ) {
                        if (allServices[sci].services[si].ordinal !== si + 1)
                            changedOrderForServices.push({
                                id: allServices[sci].services[si].id,
                                ordinal: si + 1,
                                name: allServices[sci].services[si].name,
                                categoryName:
                                    allServices[sci].services[si].categoryName
                            });
                    }
                }
            }
            setSnackMsg(
                "Updating the display order of the services and categories."
            );
            setMsgOpen(true);

            if (changedOrderForCategories.length)
                await updateCategories(changedOrderForCategories);
            if (changedOrderForServices.length)
                await updateServices(changedOrderForServices);
            setLoadServices(true);
            setSnackMsg(
                "Updated the display order of the services and categories."
            );
            setMsgOpen(true);
        } catch (e) {
            console.log(e);
            setSnackMsg(
                "An error has occurred while updating the display order. Please contact Marketbox Administrator."
            );
            setMsgOpen(true);
        }

        setSaveInProgress(false);
    }

    async function updateServices(changedServices) {
        for (let changedService of changedServices) {
            console.log("changed service", JSON.stringify(changedService));
            await graphql(
                graphqlOperation(updateServiceType, {
                    input: changedService
                })
            );
        }
    }

    async function updateCategories(changedCategories) {
        for (let changedCategory of changedCategories) {
            console.log("changed category", JSON.stringify(changedCategory));

            await graphql(
                graphqlOperation(updateServiceTypeCategory, {
                    input: changedCategory
                })
            );
        }
    }

    // return allServices.map((mso) => mso.name);
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Backdrop
                style={{
                    zIndex: theme.zIndex.drawer + 1,
                    color: "#fff"
                }}
                open={saveInProgress}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            <Paper rounded="true" style={{ padding: 8 }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveNewOrder}
                            disabled={saveInProgress}
                        >
                            &nbsp;Save display order
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ textAlign: "right" }}
                            onClick={() => {
                                navigate("..");
                            }}
                            disabled={saveInProgress}
                        >
                            &nbsp;RETURN TO SERVICES
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography
                            className={classes.instructions}
                            style={{ margin: 8 }}
                        >
                            <b>To modify the display order of categories:</b>{" "}
                            drag and drop the category that needs to be moved to
                            the desired position.
                        </Typography>
                        <Typography
                            className={classes.instructions}
                            style={{ margin: 8 }}
                        >
                            <b>
                                To modify the display order of the services
                                within each category:
                            </b>{" "}
                            click on the category. This will open the category
                            and display the services within the given category.
                            Drag and drop services within a category to the
                            desired position.
                        </Typography>

                        <Typography
                            className={classes.instructions}
                            style={{ margin: 8 }}
                        >
                            Once you are happy with the display order, click
                            'SAVE DISPLAY ORDER' button.
                        </Typography>
                        <Typography
                            className={classes.instructions}
                            style={{ margin: 8 }}
                        >
                            You can modify the category for a service on the
                            edit services page. To edit services, click on
                            'RETURN TO SERVICES' button.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                                droppableId="droppable"
                                type="CATEGORIES"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getCategoryListStyle(
                                            snapshot.isDraggingOver,
                                            theme
                                        )}
                                    >
                                        {allServices.map((c, i) => (
                                            <Draggable
                                                key={c.id}
                                                draggableId={c.id}
                                                index={i}
                                            >
                                                {(provided, snapshot) => (
                                                    <Accordion
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getCategoryItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                                .draggableProps
                                                                .style,
                                                            theme
                                                        )}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <ExpandMoreIcon />
                                                            }
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.headingCategory
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "left"
                                                                }}
                                                            >
                                                                {`Category: ${c.name}`}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignContent="flex-start"
                                                                alignItems="flex-start"
                                                                direction="row"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    justifyContent="flex-start"
                                                                    alignContent="flex-start"
                                                                    alignItems="flex-start"
                                                                >
                                                                    <Typography
                                                                        className={
                                                                            classes.heading
                                                                        }
                                                                        style={{
                                                                            textAlign:
                                                                                "left",
                                                                            margin: 8
                                                                        }}
                                                                    >
                                                                        <b>
                                                                            Services:
                                                                        </b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Services
                                                                        categoryNum={
                                                                            i
                                                                        }
                                                                        category={
                                                                            c
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default ManageServiceOrder;
