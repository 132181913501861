import { createTheme } from "@mui/material/styles";

export const TimeSelectorTheme = ({ palette }) =>
    createTheme({
        palette,
        components: {
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: "0px",
                        overflow: "hidden"
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    h3: {
                        fontSize: "36px",
                        fontWeight: "400",
                        lineHeight: "36px",
                        color: "black"
                    }
                }
            }
        }
    });
