import React, { useEffect, useState } from "react";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    Grid,
    TextField,
    Button,
    CircularProgress,
    Typography,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack
} from "@mui/material";
import { getUserFromCache } from "../user/UserCommon";
import { OrderTable } from "../components/OrderTable";
import { graphql, graphqlOperation, postApi } from "../modules/AmplifyServices";
import { Cache } from "aws-amplify/utils";
import { useWindowSize } from "react-use";
import SearchIcon from "@mui/icons-material/Search";
import {
    ordersByCompanyOrderNo,
    ordersByProviderCreatedAt,
    ordersByClientCreatedAt
} from "../queries/ListBookingsQueries";
import {
    execReadBySortkeyFromToken,
    execReadBySortkeyFromTokenLimited
} from "../modules/DBService";
import {
    userHasAdminRole,
    userHasClientRole,
    getCompanyAdmins
} from "../user/UserCommon";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CSVLink } from "react-csv";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { UserLookup } from "../components/UserLookup";
import { ProviderLookup } from "../components/ProviderLookup";
import OrderNumberLookup from "../components/OrderNumberLookup";
import Snackbar from "@mui/material/Snackbar";
import { clientByUserId } from "../graphql/queries";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { chargeCreditCard } from "../billing/BillingCommon";
import validator from "validator";
import { SmallSpinner } from "../utils/CommonComponents/Spinner";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { sendSms } from "../modules/MessagingService";
import { CustomTooltip, AmountInputField } from "../styles/CustomMuiStyles";
import { auditChargesRefunds } from "../modules/Audit";
import { dateFormatter } from "../utils/Common/dateFormatter";
import { Close } from "@mui/icons-material";
import { useContext } from "react";
import { StoreContext } from "../context/StoreContext";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { Link } from "react-router-dom";
import { OUTLET_PAGE } from "../context/reducers";

function OrderForm() {
    const { state, actions } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [role, setRole] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const { width } = useWindowSize();
    const [token, setToken] = useState(null);
    const [cachedResults, setCachedResults] = useState([]);

    //For the refund page
    const [refundDialogue, setRefundDialogue] = useState(false);
    const [featureDisabled, setShowFeatureDisabled] = useState(false);
    const [client, setClient] = useState("");
    const [orderId, setOrderId] = useState("");
    const [theOrder, setOrder] = useState("");
    const [refundClientId, setRefundClientId] = useState("");
    const [allCharges, setAllCharges] = useState([]);
    const [clientCharges, setClientCharges] = useState([]);
    const [prevChargeId, setPrevChargeId] = useState("");
    const [orderDesc, setOrderDesc] = useState("");
    const [refundAmount, setRefundAmount] = useState(0.0);
    const [refundDesc, setRefundDesc] = useState("");
    const [saveClicked, setSaveClicked] = useState(false);
    const classesnew = usePromotionsStyles();

    //Keep filter stuff and regular stuff separate.
    //Index search and iterative are not compatible.
    const [filterToken, setFilterToken] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [filterCache, setFilterCache] = useState([]);
    const [filterOrders, setFilterOrders] = useState([]);

    const [allOrders, setAllOrders] = useState([]);
    const [allOrdersRetreived, setAllOrdersRetreived] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        getTableRowsSession("ordersTable")
    );
    const [endRegularList, setEndRegularList] = useState(false);
    const [endFilteredList, setEndFilteredList] = useState(false);
    const [filteredSearching, setFilteredSearching] = useState(false);

    const [exportReadyOrders, setExportReadyOrders] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const csvLink = React.useRef(null);

    const [company, setCompany] = useState(getUserFromCache()?.company);
    const [user, setUser] = useState(getUserFromCache());
    const [userClientId, setUserClientId] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const [selectedClientId, setSelectedClientId] = useState();
    const [selectedProvider, setSelectedProvider] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedOrderNumber, setSelectedOrderNumber] = useState();
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    const [forClient, setForClient] = useState("");
    const [searchSize, setSearchSize] = useState(4);
    const [displayExportCSVPopup, setDisplayExportCSVPopup] = useState(false);
    const [preparingExportData, setPreparingExportData] = useState(false);
    const [showExportSpinner, setShowExportSpinner] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [exportPossible, setExportPossible] = useState(true);
    const [exportDownloadReady, setExportDownloadReady] = useState(false);
    const [stopLoadingMore, setStopLoadingMore] = useState(false);
    let disableLoadMore =
        (filteredSearching && endFilteredList) ||
        (!filteredSearching && endRegularList);
    const csvHeaders = [
        { label: "ID", key: "ID" },
        { label: "Create Date", key: "CREATEDAT" },
        { label: "Order No", key: "ORDER_NO" },
        { label: "Service Chosen", key: "SERVICECHOSEN" },
        { label: "Status", key: "STATUS" },
        { label: "Client Name", key: "CLIENTNAME" },
        { label: "Booking Address", key: "BOOKINGADDRESS" },
        { label: "Provider Name", key: "PROVIDERNAME" },
        { label: "Credits Left", key: "CREDITSLEFT" },
        { label: "Subtotal", key: "SUBTOTAL" },
        { label: "Service Fee", key: "SERVICEFEE" },
        { label: "Tax", key: "TAX" },
        { label: "Total", key: "TOTAL" },
        { label: "Misc Charges", key: "MISC_CHARGES" },
        { label: "Additional Charges", key: "ADDITIONAL_CHARGES" },
        { label: "Currency", key: "CURRENCY" }
    ];

    //Initial load up - get starting info + display generic Orders
    useEffect(() => {
        async function load() {
            const loggedInUser = getUserFromCache();
            const companyid = loggedInUser.companyId;
            if (width <= 600) {
                setIsMobile(true);
            }
            setRole(loggedInUser.role);
            setUser(loggedInUser);
            setCompany(loggedInUser.company);
            if (userHasClientRole()) {
                setForClient(
                    " for " +
                        loggedInUser.firstname +
                        " " +
                        loggedInUser.lastname
                );
                const currentUserClientId = await getClientByUserId(
                    loggedInUser.id,
                    companyid
                );
                setUserClientId(currentUserClientId[0]);
                setSearchSize(6);
            }

            await _getPartialOrderList(
                token,
                cachedResults,
                allOrders,
                allOrdersRetreived
            );
        }
        load();
    }, []);

    //Handle export
    useEffect(() => {
        if (exportReadyOrders && csvLink && exportReadyOrders.length > 0) {
            let timer1 = setTimeout(() => {
                if (csvLink.current) {
                    csvLink.current.link.click();
                }
                console.log("exporting these orders = ", exportReadyOrders);
            });
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [exportReadyOrders]);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("ordersTable")) {
            setTableRowsSession("ordersTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    //From: User clicking to select client.
    //Does: Takes User ID to find Client Id. Sets selectedClientId to result.
    //Returns: N/A
    async function setClientId(user) {
        if (user) {
            let selectedClientIdArr = await getClientByUserId(
                user.id,
                company.id
            );
            setSelectedClientId(selectedClientIdArr[0]);
        }
    }

    function filterMode() {
        return (
            !!selectedClient ||
            !!selectedProvider ||
            !!selectedStatus ||
            !!selectedOrderNumber
        );
    }

    //From: User clicks "Load More Orders"
    //Does: Loads more orders - filtered or generic.
    //Returns: N/A
    const handleLoadMore = async () => {
        if (filterMode()) {
            setFilteredSearching(true);
            await _getFilteredList(
                selectedClient,
                selectedProvider,
                selectedStatus,
                selectedOrderNumber,
                selectedClientId,
                filterToken,
                filterCache,
                allOrders,
                allOrdersRetreived,
                false
            );
        } else {
            _getPartialOrderList(
                token,
                cachedResults,
                allOrders,
                allOrdersRetreived
            );
        }
    };

    //From: User clicks Export CSV Button
    //Does: Handles logic to display CSV dialogue
    //Returns: N/A
    const _handleExportOrders = () => {
        setShowLoading(true);
        setShowExport(true);
        setDisplayExportCSVPopup(true);
    };

    //Does: Takes in order, as returned from DB. Supplied data for display purposes.
    //Returns: Order item, modified with data for display.
    function supplyData(item) {
        item.companyName =
            item.company && item.company.name ? item.company.name : "";
        item.providerName =
            item.provider && item.provider.firstname
                ? `${item.provider.firstname} ${item.provider.lastname}`
                : "";
        item.clientName = item.client
            ? `${
                  item.client && item.client.user
                      ? item.client.user.firstname
                      : ""
              } ${
                  item.client && item.client.user
                      ? item.client.user.lastname
                      : ""
              }`
            : "";
        item.addressoneline = item.bookingAddress
            ? JSON.parse(item.bookingAddress).addrOneLine
            : "";
        item.credits = item.clientpackage
            ? (
                  item.clientpackage.initialQuantity -
                  item.clientpackage.usedQuantity
              ).toString()
            : "N/A";
        const orderSummary = JSON.parse(
            item.orderSummary ? item.orderSummary : "{}"
        );
        item.miscChargesString = orderSummary.miscBillingTotal
            ? orderSummary.miscBillingTotal
            : "0";
        item.additionalChargesString = orderSummary.additionalChargesTotal
            ? orderSummary.additionalChargesTotal
            : "0";
        item.currencyValue = item.currency;

        return item;
    }

    //From: Initial load or user clicks Load More button with no filter criteria.
    //Does: Gets 50 additional orders with no user filter criteria.
    //Returns: N/A
    const _getPartialOrderList = async (
        token,
        cachedResults,
        allOrdersList,
        allOrdersRetreivedVal
    ) => {
        setShowLoading(true);
        //If all orders retrieved, done.
        if (allOrdersRetreivedVal) {
            setRows(allOrdersList);
            setShowLoading(false);
            return;
        }
        console.log(
            "_getPartialOrderList arguments",
            token,
            cachedResults,
            allOrdersList,
            allOrdersRetreivedVal
        );
        //Steps:
        //1) If more than 50 existing, cached results, no need to do operation. Display these.
        //2) Get 50 results from operation.
        //3) Create array. Add existing, cached results to array. Must be added before new, to maintain order.
        //4) Add newly returned results to the end of array. These new results are continuous with the end of cache.
        //5) Add newly returned cache results to end of array, so that all values known are ordered in an array.
        //6) Take 50 results from this array, and append to the list of orders being displayed.
        //7) Save remaining in cache for useage in later calls.

        const searchToken = token || null;
        const cachedResultsValue = cachedResults || [];
        const allOrdersArray = [...allOrdersList];
        let newList = [...allOrdersList];

        let newDisplayOrders;
        if (cachedResultsValue.length >= 50) {
            console.log(
                "getpartialOrderList - greater than 50",
                cachedResultsValue
            );
            //More than 50 cached results. Sufficient results to display new without search operation.

            console.log("asdf MORE THAN 50 IN CACHE");
            newDisplayOrders = cachedResultsValue.slice(0, 50);
            let remainingCache = cachedResultsValue.slice(50);

            setCachedResults(remainingCache);
            newDisplayOrders.map((item) => supplyData(item));
            newList.push(...newDisplayOrders);
            allOrdersArray.push(...newDisplayOrders);
            setRows(newList);
            setAllOrders(allOrdersArray);
            setShowLoading(false);
            return;
        } else {
            //Operation to get the results desired. Search token to load new from last place visited (from end of cache).
            //Input an empty cache to receive ending cache. Inputting cache with existing orders will ruin sorting order.
            console.log(
                "getpartialOrderList - less than 50",
                cachedResultsValue,
                allOrdersArray
            );

            let operation = await getBasicSearchFunction(
                selectedClient,
                selectedProvider,
                selectedStatus,
                selectedOrderNumber
            );

            console.log("getpartialOrderList - what is operation ?", operation);
            let partialOrderData = await execReadBySortkeyFromTokenLimited(
                operation,
                searchToken,
                [],
                50
            );
            let exisitingCache = cachedResultsValue;
            let returnedCache = partialOrderData.cachedResponse;
            let returnedResults = partialOrderData.items;

            let workingSet = exisitingCache
                .concat(returnedResults)
                .concat(returnedCache);
            newDisplayOrders = workingSet.slice(0, 50);
            let newCache = workingSet.slice(50);

            setToken(partialOrderData.token);
            setCachedResults(newCache);

            let cache = newCache;
            if (newDisplayOrders && newDisplayOrders.length > 0) {
                newDisplayOrders.map((item) => {
                    supplyData(item);
                });
                newList.push(...newDisplayOrders);
                allOrdersArray.push(...newDisplayOrders);
                if (!partialOrderData.token) {
                    cache.map((item) => {
                        supplyData(item);
                    });
                    newList.push(...cache);
                    allOrdersArray.push(...cache);
                    setAllOrdersRetreived(true);
                    setEndRegularList(true);
                }
            }
            setRows(newList);
            setAllOrders(allOrdersArray);
            setShowLoading(false);
            return newList;
        }
    };

    //Does: Comparison function. Takes in order, apply filter.
    //Returns: Boolean indicating if order meets filter criteria.
    function orderMatches(
        order,
        selectedOrderNumber,
        selectedClient,
        selectedProvider,
        selectedStatus,
        selectedClientId
    ) {
        const orderNumMatches = selectedOrderNumber
            ? order.orderNo &&
              order.orderNo.toString().toLowerCase() ===
                  selectedOrderNumber.toLowerCase()
            : true;
        const clientMatches = selectedClient
            ? order.clientId && order.clientId === selectedClientId
            : true;
        const providerMatches = selectedProvider
            ? order.providerId && order.providerId === selectedProvider.id
            : true;
        const statusMatches = selectedStatus
            ? order.status &&
              order.status.toLowerCase() === selectedStatus.toLowerCase()
            : true;

        const matchesSearch =
            orderNumMatches &&
            clientMatches &&
            providerMatches &&
            statusMatches;
        return matchesSearch;
    }

    //From: computeExportCSVData
    //Does: Take in order with values, takes these values and returns item ready for CSV usage.
    //Returns: new Item with values ready for CSV
    function supplyCSVData(item) {
        const {
            id: ID,
            createdAtValue: CREATEDAT,
            orderNo: ORDER_NO,
            orderSummary: ORDERSUMMARY,
            status: STATUS,
            clientName: CLIENTNAME,
            addressoneline: BOOKINGADDRESS,
            providerName: PROVIDERNAME,
            credits: CREDITSLEFT,
            subtotal: SUBTOTAL,
            servicechargeamt: SERVICEFEE,
            taxamt: TAX,
            total: TOTAL,
            miscChargesString: MISC_CHARGES,
            additionalChargesString: ADDITIONAL_CHARGES,
            currencyValue: CURRENCY
        } = item;
        const newItem = {
            ID,
            CREATEDAT,
            ORDER_NO,
            SERVICECHOSEN: JSON.parse(ORDERSUMMARY)?.rows[0]?.service,
            STATUS,
            CLIENTNAME,
            BOOKINGADDRESS,
            PROVIDERNAME,
            CREDITSLEFT,
            SUBTOTAL: Number(SUBTOTAL).toFixed(2),
            SERVICEFEE: Number(SERVICEFEE).toFixed(2),
            TAX: Number(TAX).toFixed(2),
            TOTAL: Number(TOTAL).toFixed(2),
            MISC_CHARGES,
            ADDITIONAL_CHARGES,
            CURRENCY
        };
        return newItem;
    }

    //From: User clicks "Prepare Export" in CSV Dialogue
    //Does: Gets rows for CSV to be exported.
    //Returns: N/A
    async function computeExportCSVData() {
        setPreparingExportData(true);

        let results;
        let rows = [];
        let orders = [];
        if (allOrdersRetreived) {
            results = allOrders;
        } else {
            let token;
            let operation = await getBasicSearchFunction(
                selectedClient,
                selectedProvider,
                selectedStatus,
                selectedOrderNumber
            );
            do {
                let orderData = await execReadBySortkeyFromToken(
                    operation,
                    token,
                    []
                );
                let currentResults = orderData.items;
                token = orderData.token;

                orders = orders.concat(currentResults);
                //If token is null, then got all orders. Otherwise continue getting more.
            } while (token);
        }

        if (orders.length > 0) {
            //Then orders retreived from operation.
            results = orders;
        }
        if (!results || !results.length > 0) {
            setSnackMsg(
                "No results found! Please verify your filter criteria and please verify using the search functionality that such orders exist."
            );
            setMsgOpen(true);
            setShowLoading(false);
            setPreparingExportData(false);
            setExportReadyOrders(null);
            return;
        }

        if (results && results.length > 0) {
            results.map((item) => {
                item = supplyData(item);
                item.createdAtValue = moment(item.createdAt).format(
                    "YYYY-MM-DD hh:mm A"
                );
                rows.push(item);
            });
        }

        rows = rows.filter((item) => {
            return orderMatches(
                item,
                selectedOrderNumber,
                selectedClient,
                selectedProvider,
                selectedStatus,
                selectedClientId
            );
        });

        if (!filterMode()) {
            //If here, all orders are received.
            setRows(rows);
            setAllOrders(rows);
            setAllOrdersRetreived(true);
            setEndRegularList(true);
        } else {
            setEndFilteredList(true);
            setSearchResults(rows);
            setFilteredSearching(true);
        }

        const csvData = rows.map((item) => supplyCSVData(item));
        if (csvData && csvData.length) {
            setExportReadyOrders(csvData);
        } else {
            setExportReadyOrders(null);
        }
        setExportDownloadReady(true);
        setShowLoading(false);
        setPreparingExportData(false);
        return;
    }

    //From: User clicks Search or Load More and has inputted criteria to filter on.
    //Does: Gets 50 more results from DB, that meet client specified criteria.
    //Returns: Search List
    const _getFilteredList = async (
        clientSearch,
        providerSearch,
        statusSearch,
        orderNoSearch,
        selectedClientId,
        token,
        cachedResults,
        allOrders,
        allOrdersRetreived,
        fullRefresh
    ) => {
        //Steps: (Verify if search criteria has changed - done previously)
        //1) If all orders obtained, use these.
        //2) Else, begin with previously obtained orders.
        //3) Add existing cache results.
        //4) Remove items taken from cache from being in cache.
        //5) If 50 orders added from cache, no need to do operation. Lock in changes and return.
        //6) Run operation. Add 50 - num added from cache.
        //7) Add remaining results from operation into cache for next use.
        //8) If all orders obtained, future search operation impossible. Add remaining cache. Disable future searches.

        setShowLoading(true);

        //1) If all order obtained, filter through to display those.
        if (allOrdersRetreived) {
            let newList = allOrders.filter((item) => {
                return orderMatches(
                    item,
                    orderNoSearch,
                    clientSearch,
                    providerSearch,
                    statusSearch,
                    selectedClientId
                );
            });
            setSearchResults(newList);
            console.log("this one", newList);
            setEndFilteredList(true);
            // setEndList(true);
            setShowLoading(false);
            return newList;
        }

        let filteredResponse = [];
        let searchToken = token || null;
        let filterCache = cachedResults || [];
        let newList = [];

        //2) Filter through the obtained orders. Run redundant verification to ensure correctness.
        if (!fullRefresh) {
            newList.push(
                ...filterOrders.filter((item) => {
                    return orderMatches(
                        item,
                        orderNoSearch,
                        clientSearch,
                        providerSearch,
                        statusSearch,
                        selectedClientId
                    );
                })
            );
        }

        //3) Add first 50 from filterCache into newlist. Done through cacheList to know how many added.
        let cacheList = [];
        cacheList.push(
            ...filterCache.filter((item) => {
                return orderMatches(
                    item,
                    orderNoSearch,
                    clientSearch,
                    providerSearch,
                    statusSearch,
                    selectedClientId
                );
            })
        );

        //Push the first 50. 0 - 49.
        let numAdded = 0;
        newList.push(
            ...cacheList.filter((item) => {
                if (numAdded >= 50) {
                    return false;
                } else {
                    item = supplyData(item);
                    numAdded += 1;
                    return true;
                }
            })
        );

        //4) Configure cache.
        //More than 50 - Maintain for later. Enters upcoming if and returns.
        //Less than 50 - clears both caches to be empty for upcoming operation.
        cacheList = cacheList.splice(numAdded);
        filterCache = cacheList;

        //5) More than 50 added from cache. Verify, update variables, return.
        if (numAdded >= 50) {
            //Verification to avoid doubling.
            const idMap = {};
            newList = newList.filter((item) => {
                if (idMap[item.id]) return false;
                idMap[item.id] = 1;
                return true;
            });
            setFilterOrders(newList);
            setSearchResults(newList);
            console.log("this one 2", newList);
            setShowLoading(false);
            return newList;
        }

        while (filteredResponse.length < 50 - numAdded) {
            //6) Get 50 total results. Use filterToken - token from iterative search not compatible.
            //Getting here means filterCache is now empty, and ready to use (store additional results from this operation)
            let operation = await getBasicSearchFunction(
                selectedClient,
                selectedProvider,
                selectedStatus,
                selectedOrderNumber
            );
            let searchableOrderData = await execReadBySortkeyFromTokenLimited(
                operation,
                searchToken,
                filterCache,
                50 - numAdded
            );

            //7) Lock results into queryResults and additional in filterCache.
            let queryResults;
            if (searchableOrderData) {
                queryResults = searchableOrderData.items;
                filterCache = searchableOrderData.cachedResponse;
                searchToken = searchableOrderData.token;
            }

            if (searchableOrderData && searchableOrderData.items) {
                queryResults.map((item) => {
                    supplyData(item);
                });
                filteredResponse.push(
                    ...queryResults.filter((item) => {
                        return orderMatches(
                            item,
                            orderNoSearch,
                            clientSearch,
                            providerSearch,
                            statusSearch,
                            selectedClientId
                        );
                    })
                );
            }
            newList.push(...filteredResponse);
            setFilterOrders(newList);

            //8) No search token --> No future starting place. No more orders.
            if (!searchToken) {
                //Search token for either filter or load more here.
                if (filterCache) {
                    filterCache.map((item) => {
                        supplyData(item);
                    });
                    newList.push(
                        ...filterCache.filter((item) => {
                            return orderMatches(
                                item,
                                orderNoSearch,
                                clientSearch,
                                providerSearch,
                                statusSearch,
                                selectedClientId
                            );
                        })
                    );
                }
                setFilterOrders(newList);
                console.log("this one 3", newList);
                setEndFilteredList(true);
                break;
            }
        }

        setFilterToken(searchToken);
        setFilterCache(filterCache);

        const idMap = {};

        //Verification to avoid doubling.
        newList = newList.filter((item) => {
            if (idMap[item.id]) return false;
            idMap[item.id] = 1;
            return true;
        });
        setSearchResults(newList);
        console.log("this one 4", newList);
        setShowLoading(false);
        return newList;
    };

    //Does: Given user ID and Company ID, returns user's client ID.
    //Returns: client IDS.
    async function getClientByUserId(userId, companyId) {
        let clientIds = [];
        //write get clients query
        const clientProfile = await graphql(
            graphqlOperation(clientByUserId, {
                userId: userId,
                filter: { companyId: { eq: companyId } }
            })
        );
        if (
            clientProfile &&
            clientProfile.data.clientByUserId.items &&
            clientProfile.data.clientByUserId.items.length
        ) {
            for (let ci of clientProfile.data.clientByUserId.items) {
                clientIds.push(ci.id);
            }
        }
        return clientIds;
    }

    //Does: Nothing. Do not remove, necessary for makeFilter - case where no filter inputted.
    //Returns: N/A.
    function pass() {}

    //Does: Creates a filter field to be used for queries based on user inputs, to be used on Graphql Operation
    //Returns: Filter for Graphql Operation
    function makeFilter(
        selectedClient,
        selectedProvider,
        selectedStatus,
        selectedOrderNumber
    ) {
        let filter;
        let isClientFilter;
        if (!userHasAdminRole()) {
            isClientFilter = { clientId: { eq: userClientId } };
        }

        let companyIdFilter = { companyId: { eq: company.id } };

        let providerIdFilter = selectedProvider
            ? { providerId: { eq: selectedProvider.id } }
            : null;

        let statusFilter = selectedStatus
            ? { status: { eq: selectedStatus.toLowerCase() } }
            : null;

        let orderNumFilter = selectedOrderNumber
            ? { orderNo: { eq: selectedOrderNumber } }
            : null;
        let clientIdFilter = selectedClient
            ? { clientId: { eq: selectedClientId } }
            : null;

        let filterFields = [];
        if (selectedOrderNumber) {
            providerIdFilter ? filterFields.push(providerIdFilter) : pass();
            statusFilter ? filterFields.push(statusFilter) : pass();
            clientIdFilter ? filterFields.push(clientIdFilter) : pass();
            isClientFilter ? filterFields.push(isClientFilter) : pass();

            if (filterFields.length > 0) {
                filter = { and: filterFields };
            } else {
                filter = null;
            }
        } else if (selectedClient) {
            //Will use query for selected client. Therefore, add filter for company and other searches.
            providerIdFilter ? filterFields.push(providerIdFilter) : pass();
            statusFilter ? filterFields.push(statusFilter) : pass();
            orderNumFilter ? filterFields.push(orderNumFilter) : pass();
            companyIdFilter ? filterFields.push(companyIdFilter) : pass();
            isClientFilter ? filterFields.push(isClientFilter) : pass();

            if (filterFields.length > 0) {
                filter = { and: filterFields };
            } else {
                filter = null;
            }
        } else if (selectedProvider) {
            statusFilter ? filterFields.push(statusFilter) : pass();
            orderNumFilter ? filterFields.push(orderNumFilter) : pass();
            clientIdFilter ? filterFields.push(clientIdFilter) : pass();
            companyIdFilter ? filterFields.push(companyIdFilter) : pass();
            isClientFilter ? filterFields.push(isClientFilter) : pass();

            if (filterFields.length > 0) {
                filter = { and: filterFields };
            } else {
                filter = null;
            }
        } else {
            //No input by user for filter (or status input - not functional)
            filter = null;

            //Do not filter for clientId if no user filter. With no user filter,
            //clientId filter will be used - cant have filter on primary key.
        }
        return filter;
    }

    //Does: Creates operation to be used in query. Supplies op, opname, id, filter and sortDirection.
    //Returns: Operation ready for use in Graphql Query
    async function getBasicSearchFunction(
        selectedClient,
        selectedProvider,
        selectedStatus,
        selectedOrderNumber
    ) {
        /* NOTE: selectedClient.id is actually the client's USER id. Must be converted to Client Id for later use. */

        let filter = makeFilter(
            selectedClient,
            selectedProvider,
            selectedStatus,
            selectedOrderNumber
        );

        console.log(
            "getBasicSearchFunction argument",
            selectedClient,
            selectedProvider,
            selectedStatus,
            selectedOrderNumber
        );
        console.log("getBasicSearchFunction filter", filter);

        let op;
        if (filter) {
            op = {
                opname: "ordersByCompanyOrderNo",
                op: ordersByCompanyOrderNo,
                id: { companyId: { eq: company.id } },
                filter: filter,
                sortDirection: "DESC"
            };
        } else {
            op = {
                opname: "ordersByCompanyOrderNo",
                op: ordersByCompanyOrderNo,
                id: { companyId: { eq: company.id } },
                sortDirection: "DESC"
            };
        }

        if (selectedOrderNumber) {
            op.opname = "ordersByCompanyOrderNo";
            op.op = ordersByCompanyOrderNo;
            op.id = {
                companyId: company.id,
                orderNo: { eq: parseInt(selectedOrderNumber) }
            };
        } else if (selectedClient) {
            //The entry field is not shown for clients - they cannot search others.
            op.opname = "ordersByClientCreatedAt";
            op.op = ordersByClientCreatedAt;
            op.id = {
                clientId: selectedClientId
            };
        } else if (selectedProvider) {
            op.opname = "ordersByProviderCreatedAt";
            op.op = ordersByProviderCreatedAt;
            op.id = {
                providerId: selectedProvider.id
            };
        } else {
            op.opname = "ordersByCompanyOrderNo";
            op.op = ordersByCompanyOrderNo;
            op.id = {
                companyId: company.id
            };

            if (!userHasAdminRole()) {
                let currClientId = await Cache.getItem("ClientId");
                if (!currClientId) {
                    let loggedInUser = getUserFromCache();
                    const clientIds = await getClientByUserId(
                        loggedInUser.id,
                        company.id
                    );
                    currClientId = clientIds[0];
                }
                op.opname = "ordersByClientCreatedAt";
                op.op = ordersByClientCreatedAt;
                op.id = {
                    clientId: currClientId
                };
            }
        }
        return op;
    }

    //From: User clicked Search button.
    //Does: Handles functionality for searching orders.
    //Returns: N/A
    async function handleSearchOrders() {
        //Validation
        if (selectedClient && !selectedClient.id) {
            setSnackMsg(
                "Invalid client. Please select a client from the client dropdown options."
            );
            setMsgOpen(true);
            return;
        }
        if (selectedClient && selectedClient.id && !selectedClientId) {
            setSnackMsg(
                "Client Id not found. Please select a client from the client dropdown options."
            );
            setMsgOpen(true);
            return;
        }
        if (selectedProvider && !selectedProvider.id) {
            setSnackMsg(
                "Invalid provider. Please select a provider from the provider dropdown options."
            );
            setMsgOpen(true);
            return;
        }
        if (
            selectedStatus !== "OPEN" &&
            selectedStatus !== "STOPPED" &&
            selectedStatus !== "CONFIRMED" &&
            selectedStatus !== "PAID" &&
            selectedStatus !== "UNPAID" &&
            selectedStatus !== "PAIDBYPKGCRE" &&
            selectedStatus
        ) {
            setSnackMsg(
                "Invalid status. Please select a status from the status dropdown options."
            );
            setMsgOpen(true);
            return;
        }
        //selectedOrderNumber is a string of a number (can be converted to int with parseInt)
        //This is in order to allow proper rendering and maintain consistent type.
        if (selectedOrderNumber) {
            if (selectedOrderNumber < 0) {
                setSnackMsg("Please input a positive order number.");
                setMsgOpen(true);
            }
        }
        //Validation passed

        //Note: Verify if user is filtering for a client with selectedClient not selectedClientId. SelectedClientId does not update when filter is cleared.

        setFilteredSearching(false);
        let newList;
        if (!filterMode()) {
            //No search criteria - interpret as load more call.
            _getPartialOrderList(
                token,
                cachedResults,
                allOrders,
                allOrdersRetreived
            );
        } else {
            setFilteredSearching(true);
            newList = await _getFilteredList(
                selectedClient,
                selectedProvider,
                selectedStatus,
                selectedOrderNumber,
                selectedClientId,
                filterToken,
                filterCache,
                allOrders,
                allOrdersRetreived,
                true
            );
        }

        //Load More button only appears if orders do. Therefore, only here, from search, could it be possible to get 0 results.
        //Non-filtering will always show orders. Therefore, only relevant when filtering.
        if (filterMode() && newList.length === 0) {
            setExportPossible(false);
            setSnackMsg("No results found! Please verify search criteria.");
            setMsgOpen(true);
        } else {
            setExportPossible(true);
        }
        setShowLoading(false);
    }

    const handleChangeRefundAmount = (e) => {
        let amount = parseFloat(e.target.value);
        if (
            !isNaN(amount) &&
            Number.isInteger(amount * 100 - Math.floor(amount) * 100)
        ) {
            setRefundAmount(e.target.value);
        } else {
            setRefundAmount(amount.toFixed(2));
        }
    };

    const handleChangeRefundDesc = (e) => {
        setRefundDesc(e.target.value);
    };

    const handleChangePrevCharge = (e) => {
        setPrevChargeId(e.target.value);
    };

    async function getRefundClient(clientId) {
        const result = await graphql(
            graphqlOperation(queries.getClient, {
                id: clientId
            })
        );
        return result.data.getClient;
    }

    async function handleShowRefund(order) {
        console.log("Refund Clicked");
        console.log(order);
        const loggedInUser = getUserFromCache();

        if (loggedInUser.company.collectpayment) {
            console.log("what is it", order);
            console.log(order.desc.split("for"));

            if (order.client && order.client.user) {
                setClient(
                    `${order.client.user.firstname} ${order.client.user.lastname}`
                );
                setRefundClientId(order.client.id);
            }
            setOrder(order);
            setOrderDesc(order.desc);
            setOrderId(order.id);

            const result = await graphql(
                graphqlOperation(queries.clientChargeByOrder, {
                    orderId: order.id
                })
            );
            const clientCharges = result.data.clientChargeByOrder.items;
            let refundable = clientCharges.filter(
                (item) => item.amount_refunded === 0
            );

            console.log("what is c", clientCharges);
            setAllCharges(clientCharges);
            setClientCharges(refundable);
            setRefundDialogue(true);
        } else {
            setShowFeatureDisabled(true);
        }
    }

    function getStripeAmount() {
        let num_check = (parseFloat(refundAmount) * 100).toString();
        const decimal_pos = num_check.indexOf(".");
        if (decimal_pos === -1) {
            return parseInt(num_check);
        } else {
            const rounded_num = num_check.charAt(decimal_pos + 1);
            if (parseInt(rounded_num) >= 5) {
                return parseInt(num_check) + 1;
            } else if (parseInt(rounded_num) < 5) {
                return parseInt(num_check);
            }
        }
    }

    function reset() {
        // clear fields
        setRefundAmount(0.0);
        setPrevChargeId("");
        setRefundDesc("");
    }

    function handleErrorMsg(item) {
        if (!item.refund) {
            //If credit has already been refunded
            if (
                item &&
                item.result &&
                item.result.raw &&
                item.result.raw.code &&
                item.result.raw.code === "charge_already_refunded"
            ) {
                setSnackMsg("Unable to proccess refund. " + item.message + ".");
                setMsgOpen(true);
                setSaveClicked(false);
                return true;
            }
        } else {
            // Failure on refund
            console.log("what is item", item);
            if (item.refund.failure_reason) {
                setSnackMsg(
                    "Refund failed, reason: ",
                    item.refund.failure_reason
                );
                setMsgOpen(true);
                setSaveClicked(false);
                return true;
            }
        }
    }

    async function handleSaveRefund() {
        const loggedInUser = getUserFromCache();
        setFilteredSearching(false);
        setSaveClicked(true);
        // validate all fields are filled in
        if (prevChargeId === "") {
            setSnackMsg(
                "Please select a previous charge to apply the refund to."
            );
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        if (validator.isFloat(refundAmount.toString())) {
            if (refundAmount <= 0) {
                setSnackMsg("Please specify a positive amount for the refund.");
                setMsgOpen(true);
                setSaveClicked(false);
                return;
            }
        } else {
            setSnackMsg("Please specify a valid amount for the refund.");
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        if (!refundDesc || refundDesc === "") {
            setSnackMsg("Please enter a description for the refund.");
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        let clientChargesData;
        for (var i = 0; i < clientCharges.length; i++) {
            if (clientCharges[i].stripechargeid === prevChargeId) {
                clientChargesData = clientCharges[i];
                break;
            }
        }

        let totalCharge = parseFloat(clientChargesData.amount);
        for (var i = 0; i < allCharges.length; i++) {
            if (allCharges[i].stripe_refund_charge === prevChargeId) {
                totalCharge -= parseFloat(allCharges[i].amount_refunded);
            }
        }
        if (
            parseFloat(refundAmount) > totalCharge &&
            totalCharge.toFixed(2) > 0
        ) {
            setSnackMsg(
                `Unable to process refund as it exceeds the previous charge. Amount available to refund: $${totalCharge.toFixed(
                    2
                )}`
            );
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        try {
            const theclient = await getRefundClient(refundClientId);
            console.log("theclient", theclient);

            // Create Billing Transaction
            let input = {
                billingTransactionClientId: refundClientId,
                clientId: refundClientId,
                amount: parseFloat(refundAmount).toFixed(2),
                billingTransactionCreatorId: loggedInUser.id,
                creatorId: loggedInUser.id,
                billingTransactionCompanyId: loggedInUser.companyId,
                companyId: loggedInUser.companyId,
                desc: refundDesc,
                credit: true,
                billingTransactionOrderId: orderId,
                orderId: orderId
            };

            const result = await graphql(
                graphqlOperation(mutations.createBillingTransaction, { input })
            );

            console.log("billing created", result.data);
            if (result && result.data) {
                const billingTransactionId =
                    result.data.createBillingTransaction.id;
                // data saved, now update Stripe
                const stripe_result = await chargeCreditCard(
                    theclient.stripeCustomerId,
                    getStripeAmount(),
                    theOrder.currency,
                    theclient.user.emailaddress,
                    true,
                    prevChargeId,
                    loggedInUser.companyId,
                    theOrder.company.stripeAccount,
                    theOrder.company.stripeConnectEnabled
                );

                console.log("stripe_result", stripe_result);

                if (handleErrorMsg(stripe_result) == true) {
                    return;
                }

                // now we save data to clientCharges table
                const dateNow = new Date();
                const options = { dateStyle: "long" };
                const dateNowString =
                    dateNow.toLocaleDateString("en-US", options) +
                    " " +
                    dateNow.toLocaleTimeString();

                input = {
                    clientId: refundClientId,
                    clientChargeClientId: refundClientId,
                    companyId: loggedInUser.companyId,
                    description: "Refund of order on " + dateNowString,
                    status: "created",
                    stripechargeid: stripe_result.refund.id,
                    stripepaymentmethod: "refund",
                    stripe_payment_method_details: "refund",
                    balance_transaction:
                        stripe_result.refund.balance_transaction,
                    stripe_refund_charge: stripe_result.refund.charge,
                    stripe_status: stripe_result.refund.status,
                    clientChargeBillingtransactionId: billingTransactionId,
                    billingtransactionId: billingTransactionId,
                    amount: 0,
                    amount_refunded: parseFloat(refundAmount).toFixed(2),
                    currency: theOrder.currency,
                    orderId: orderId,
                    clientChargeOrderId: orderId
                };

                const result2 = await graphql(
                    graphqlOperation(mutations.createClientCharge, { input })
                );
                console.log("clientCharge", result2);
                // update the order information
                let newOrderSummary = JSON.parse(
                    theOrder.orderSummary ? theOrder.orderSummary : {}
                );
                let miscBillingTotal = parseFloat(
                    newOrderSummary.miscBillingTotal
                        ? newOrderSummary.miscBillingTotal
                        : "0"
                );
                //miscBillingTotal -= parseFloat(refundAmount).toFixed(2);
                miscBillingTotal = Number.parseFloat(
                    (
                        miscBillingTotal -
                        Number.parseFloat(parseFloat(refundAmount).toFixed(2))
                    ).toFixed(2)
                );
                newOrderSummary.miscBillingTotal = miscBillingTotal;
                newOrderSummary = JSON.stringify(newOrderSummary);
                const updateOrder = await graphql(
                    graphqlOperation(mutations.updateOrder, {
                        input: {
                            id: orderId,
                            orderSummary: newOrderSummary
                        }
                    })
                );

                console.log("updateOrder", updateOrder);

                // send SMS to client regarding the refund
                const totalAmt = Number.parseFloat(refundAmount).toFixed(2);
                const currency = theOrder.currency.toString();
                const to = theclient.user.mobilephone;
                const companyName = loggedInUser.company.name;
                const companyEmail = loggedInUser.company.emailaddress;
                const uriEmail = encodeURIComponent(companyEmail);
                const companycode = loggedInUser.company.countrycode3166alpha2
                    ? "en-" +
                      loggedInUser.company.countrycode3166alpha2.toUpperCase()
                    : "en-CA";
                const total = totalAmt.toLocaleString(companycode, {
                    style: "currency",
                    currency,
                    minimumFractionDigits: 2
                });

                let msg = "Message from " + companyName + ". A refund";
                msg +=
                    " of " +
                    total +
                    " has been made on your order '" +
                    theOrder.desc;
                msg +=
                    "' with the previous charge of '" +
                    clientChargesData.description;
                msg +=
                    "' This refund has the associated description: '" +
                    refundDesc;
                msg +=
                    "'. Please contact " +
                    uriEmail +
                    " if you wish to dispute this refund.";

                let countryCodeForSms = loggedInUser.company
                    .countrycode3166alpha2
                    ? loggedInUser.company.countrycode3166alpha2.toUpperCase()
                    : "CA";

                const smsresult = await sendSms(to, msg, countryCodeForSms);
                console.log("asdf smsresult", smsresult);

                // Handle Sending Email

                // Get Admins
                const companyAdminsList = await getCompanyAdmins(
                    loggedInUser.companyId
                );
                let companyAdminsEmailList = [];
                for (let companyAdmin of companyAdminsList) {
                    companyAdminsEmailList.push(companyAdmin.emailaddress);
                }
                const companyAdmins = companyAdminsEmailList;

                const companyPhone = theclient.company.companyPhone
                    ? theclient.company.companyPhone
                    : "";
                const clientFirstName = theclient.user.firstname;
                const clientLastName = theclient.user.lastname;
                const clientLastNameInitial = clientLastName.charAt(0);
                const clientName = clientFirstName + " " + clientLastName;
                const refundValue = totalAmt;
                const orderNumber = theOrder.orderNo;
                const service_product = `. For the charge ${clientChargesData.description}`;

                const dataObj = {
                    company_name: companyName,
                    company_email: companyEmail,
                    company_phone: companyPhone,
                    client_firstname: clientFirstName,
                    client_lastNameInitial: clientLastNameInitial,
                    client_lastName: clientLastName,
                    dollar: refundValue,
                    credit: "refund",
                    order_no: orderNumber
                        ? " associated with Order Number: " + orderNumber
                        : "",
                    service_product: service_product,
                    notes: refundDesc,
                    isRegisteredUser: theclient.user.registered
                };

                let emres = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName:
                                "client-transaction-notification.html",
                            subject: `${clientName} your ${companyName} account has received a refund.`,
                            body: null,
                            toAddresses: [theclient.user.emailaddress],
                            bccAddresses: companyAdmins,
                            replyTo: loggedInUser.company.replyemailaddress,
                            companyName: loggedInUser.company.name,
                            companyId: loggedInUser.company.id,
                            dataObj: dataObj,
                            companyLogoUrl: loggedInUser.company.logoUrl,
                            companyColor: loggedInUser.company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                        }
                    }
                );
                console.log("email sent", emres);
                if (clientChargesData?.order?.orderReceipt) {
                    clientChargesData.order.orderReceipt = null;
                }
                await auditChargesRefunds(
                    loggedInUser,
                    result.data.createBillingTransaction,
                    clientChargesData
                );

                setSnackMsg("Refund successfully processed.");
                setMsgOpen(true);
                setTimeout(() => {
                    reset();
                }, 500);

                // refresh data
                if (filterMode()) {
                    setFilteredSearching(true);
                    await _getFilteredList(
                        selectedClient,
                        selectedProvider,
                        selectedStatus,
                        selectedOrderNumber,
                        selectedClientId,
                        null,
                        [],
                        [],
                        false,
                        true
                    );
                } else {
                    await _getPartialOrderList(null, [], [], false);
                }
                setRefundDialogue(false);
            } else {
                setSnackMsg("Unable to save your changes.");
                setMsgOpen(true);
            }
        } catch (e) {
            console.log("there was an error", e);
            if (prevChargeId != "") {
                setSnackMsg(
                    "Unable to process as refund exceeds the previous charge."
                );
                setMsgOpen(true);
                setSaveClicked(false);
            } else {
                console.log("asdf generic failure");
                setSnackMsg("Unable to save your changes.");
                setMsgOpen(true);
                console.error(
                    "An error occurred saving the billing transaction. The error was: ",
                    e
                );
            }
        }
        setSaveClicked(false);
    }

    //rows.length === 0 || searchResults.length === 0
    /* if (true) {
        return (
            <FormHeading
                title={"Orders"}
                classes={classes.title}
                isMobile={isMobile}
            />
        );
    } */

    return (
        <>
            <FormHeading
                title={"Orders" + forClient}
                classes={classes.title}
                isMobile={isMobile}
            />
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={5000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            {displayExportCSVPopup && (
                <Dialog
                    open={showExport}
                    fullWidth={true}
                    maxWidth={"xs"}
                    TransitionProps={{
                        onEnter: () => {}
                    }}
                >
                    <DialogTitle>
                        <div>
                            <Typography
                                sx={{ fontSize: "24px", fontWeight: 400 }}
                                variant="h4"
                            >
                                Export Orders
                            </Typography>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={() => {
                                    setStopLoadingMore(true);
                                    setExportReadyOrders([]);
                                    setShowExport(false);
                                    setShowExportSpinner(false);
                                    setPreparingExportData(false);
                                    setExportDownloadReady(false);
                                    setShowLoading(false);
                                }}
                                style={{
                                    position: "absolute",
                                    right: "16px",
                                    top: "8px",
                                    color: "primary"
                                }}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent dividers style={{ padding: 24 }}>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                style={{ marginBottom: "16px" }}
                            >
                                {`Please confirm the following filter criteria:`}
                            </Typography>
                            {!selectedClient &&
                                !selectedProvider &&
                                !selectedStatus &&
                                !selectedOrderNumber && (
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                        style={{ marginBottom: "16px" }}
                                    >
                                        {`All Orders`}
                                    </Typography>
                                )}
                            <ul>
                                {selectedClient && (
                                    <li>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ marginBottom: "16px" }}
                                        >
                                            {`Client Name: ${selectedClient.firstname} ${selectedClient.lastname}`}
                                        </Typography>
                                    </li>
                                )}
                                {selectedProvider && (
                                    <li>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ marginBottom: "16px" }}
                                        >
                                            {`Provider Name: ${selectedProvider.firstname} ${selectedProvider.lastname}`}
                                        </Typography>
                                    </li>
                                )}
                                {selectedStatus && (
                                    <li>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ marginBottom: "16px" }}
                                        >
                                            {`Status: ${selectedStatus}`}
                                        </Typography>
                                    </li>
                                )}
                                {selectedOrderNumber && (
                                    <li>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ marginBottom: "16px" }}
                                        >
                                            {`Order Number: ${selectedOrderNumber}`}
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<GetAppIcon />}
                                onClick={async () => {
                                    setShowExportSpinner(true);
                                    setPreparingExportData(true);

                                    //Logic to export is done here.
                                    await computeExportCSVData();

                                    setPreparingExportData(false);
                                    setShowExportSpinner(false);
                                }}
                                disabled={preparingExportData}
                                style={{ marginTop: "24px" }}
                            >
                                Prepare Export
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {preparingExportData && (
                                <>
                                    <br></br>
                                    <Typography variant="body1">
                                        {`We are preparing your orders for export. This may take a while.`}
                                    </Typography>
                                </>
                            )}
                        </Grid>

                        {showExportSpinner && (
                            <Grid item xs={12}>
                                <SmallSpinner />
                            </Grid>
                        )}
                        {/*&& exportReadyOrders.length > 0 */}
                        {exportDownloadReady && (
                            <>
                                <br></br>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>
                                        {`Please click the download button below to download your orders.`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CSVLink
                                        headers={csvHeaders}
                                        data={exportReadyOrders}
                                        filename={`orders_${moment(
                                            Date.now()
                                        ).format("YYYY-MM-DD")}.csv`}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<GetAppIcon />}
                                        >
                                            Download
                                        </Button>
                                    </CSVLink>
                                </Grid>
                            </>
                        )}
                    </DialogContent>
                </Dialog>
            )}

            <Dialog open={refundDialogue} fullWidth={true} maxWidth="sm">
                <div
                    style={{
                        marginTop: "1.5rem",
                        padding: "0 1.5rem",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "space-between"
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "1.5rem",
                                color: "rgba(0,0,0,0.75)"
                            }}
                        >
                            Apply a Refund
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "0.875rem",
                                color: "rgba(0,0,0,0.75)"
                            }}
                        >
                            {client}'s {orderDesc}
                        </Typography>
                    </div>

                    <Close
                        sx={{
                            width: "2rem",
                            height: "2rem",
                            background: "rgba(0,0,0,0.1)",
                            borderRadius: "50%",
                            cursor: "pointer",
                            color: "rgba(0,0,0,0.5)"
                        }}
                        onClick={() => {
                            setRefundDialogue(false);
                            reset();
                        }}
                    />
                </div>

                <div
                    style={{
                        padding: "0 1.5rem",
                        marginTop: "1.5rem"
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            color: "rgba(0,0,0,0.75)"
                        }}
                    >
                        For Non-Package Orders: <br />
                        If you wish to apply a full refund please use the
                        bookings page to cancel the booking.
                        <br />
                        This will automatically apply a full refund to
                        applicable bookings.
                        <br />
                        Please do not apply a manual refund in this case.
                    </Typography>
                </div>

                <div
                    style={{
                        padding: "0 1.5rem",
                        display: "flex",
                        marginTop: "1.5rem"
                    }}
                >
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "0.75rem",
                                color: "rgba(0,0,0,0.5)"
                            }}
                        >
                            Client
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "1rem",
                                color: "rgba(0,0,0,0.75)"
                            }}
                        >
                            {client}
                        </Typography>
                    </div>

                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "0.75rem",
                                color: "rgba(0,0,0,0.5)"
                            }}
                        >
                            Order Date
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "1rem",
                                color: "rgba(0,0,0,0.75)"
                            }}
                        >
                            {dateFormatter(theOrder.createdAt)}
                        </Typography>
                    </div>
                </div>

                <div
                    style={{
                        padding: "0 1.5rem",
                        marginTop: "1.5rem"
                    }}
                >
                    <FormControl
                        required
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel htmlFor="refund-select">
                            {clientCharges.length > 0
                                ? "Please select a previous charge to apply a refund (mandatory)"
                                : "There are no charges to refund for this order"}
                        </InputLabel>
                        <Select
                            labelId="refund-select"
                            label={
                                clientCharges.length > 0
                                    ? "Please select a previous charge to apply a refund (mandatory)"
                                    : "There are no charges to refund for this order"
                            }
                            value={prevChargeId}
                            onChange={handleChangePrevCharge}
                            name="type"
                            disabled={clientCharges.length === 0}
                        >
                            {clientCharges &&
                                clientCharges.length > 0 &&
                                clientCharges.map((item, i) => {
                                    return (
                                        <MenuItem
                                            value={item.stripechargeid}
                                            key={i}
                                        >
                                            {item.description +
                                                " ($" +
                                                item.amount +
                                                ")"}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </div>

                <div
                    style={{
                        padding: "0 1.5rem",
                        marginTop: "1rem"
                    }}
                >
                    <FormControl
                        required
                        fullWidth
                        className={classes.formControl}
                    >
                        <AmountInputField
                            error={refundAmount === ""}
                            value={refundAmount}
                            setValue={setRefundAmount}
                        ></AmountInputField>
                    </FormControl>
                </div>

                <div
                    style={{
                        padding: "0 1.5rem",
                        marginTop: "1rem"
                    }}
                >
                    <FormControl
                        required
                        fullWidth
                        className={classes.formControl}
                    >
                        <TextField
                            id="desc"
                            label="Description"
                            multiline
                            rows="6"
                            className={classes.textField}
                            value={refundDesc}
                            onChange={handleChangeRefundDesc}
                        />
                    </FormControl>
                </div>

                <div
                    style={{
                        padding: "0 1.5rem",
                        marginTop: "1rem",
                        marginBottom: "1.5rem"
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleSaveRefund}
                        color="primary"
                        disabled={saveClicked}
                    >
                        Process Refund
                        {saveClicked && (
                            <CircularProgress
                                size={24}
                                className={classesnew.buttonProgress}
                            />
                        )}
                    </Button>
                </div>
            </Dialog>

            <Dialog
                open={featureDisabled}
                fullWidth={true}
                aria-labelledby="show-client-dialog-title"
                aria-describedby="show-client-dialog-description"
            >
                <DialogTitle
                    id="show-client-dialog-title"
                    sx={{ fontSize: "24px", fontWeight: 400 }}
                >
                    This feature is currently disabled on your account
                </DialogTitle>
                <DialogContent dividers style={{ padding: 22 }}>
                    <DialogContentText id="show-client-dialog-description">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    To turn it on: Go to Company Settings{" "}
                                    {"\u2192"} Turn on 'Company Collects
                                    Payments'
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    You will then need to connect your Stripe
                                    account to MarketBox
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={0}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        setShowFeatureDisabled(false)
                                    }
                                    color="primary"
                                >
                                    Okay
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Grid container spacing={2} alignItems="center">
                {user && (
                    <>
                        {userHasAdminRole() && (
                            <Grid
                                item
                                xs={12}
                                sm={searchSize}
                                /* style={{ height: "56px" }} */
                            >
                                <UserLookup
                                    companyId={user && user.companyId}
                                    setSelectedUser={async (u) => {
                                        /* NOTE: selectedClient.id is actually the client's USER id. 
                                            Must be converted to Client Id for later use. */
                                        // Maybe not necessary
                                        console.log(u);
                                        setFilterOrders([]);
                                        setEndFilteredList(false);
                                        setExportPossible(true);
                                        //
                                        setSelectedClient(u);
                                        await setClientId(u);
                                        if (!u) {
                                            setFilteredSearching(false);
                                        }
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={searchSize}
                            /* style={{ height: "56px" }} */
                        >
                            <ProviderLookup
                                companyId={user && user.companyId}
                                setSelectedProvider={(prov) => {
                                    setFilterOrders([]);
                                    setEndFilteredList(false);
                                    setExportPossible(true);
                                    //
                                    setSelectedProvider(prov);
                                    if (!prov) {
                                        // setReloadByTab(true);
                                        setFilteredSearching(false);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={searchSize}>
                            <OrderNumberLookup
                                companyId={user && user.companyId}
                                setSelectedValue={(num) => {
                                    setFilterOrders([]);
                                    setEndFilteredList(false);
                                    setExportPossible(true);
                                    setSelectedOrderNumber(num);
                                    if (!num) {
                                        setFilteredSearching(false);
                                    }
                                }}
                                headTextFieldName="orderNum"
                                placeholder="Search Order Number"
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container alignItems={"center"}>
                <Grid
                    sx={{ display: "flex", alignItems: "center" }}
                    item
                    xs={6}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async (
                            clientId,
                            providerId,
                            status,
                            orderNum
                        ) =>
                            await handleSearchOrders(
                                clientId,
                                providerId,
                                status,
                                orderNum
                            )
                        }
                        startIcon={<SearchIcon />}
                    >
                        Search
                    </Button>

                    <CustomTooltip
                        placement="right"
                        title="Click the Search button to submit your search. All name/email searches are CASE SENSITIVE. To search by email, enter the full email address. If you are unable to find a user, please verify your search is formatted as it was entered on the user’s profile."
                    >
                        <IconButton size="medium">
                            <HelpOutlineIcon />
                        </IconButton>
                    </CustomTooltip>
                </Grid>

                <Grid item xs={6} align="right" >
                    <Stack direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}>
                        {userHasAdminRole() && (
                        <>
                             {getUserFromCache()?.company.collectpayment && (
                                <Link underline="hover" to="/processcharge" onClick={() => {
                                    actions.setPage(OUTLET_PAGE)
                                }}>
                                    <Typography color="primary">
                                        <b>Process charge</b>
                                    </Typography>
                                </Link>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<GetAppIcon />}
                                disabled={!exportPossible}
                                onClick={_handleExportOrders}
                            >
                                Export CSV
                            </Button>
                        </>
                    )}
                    </Stack>   
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <div>
                        <OrderTable
                            rows={!filteredSearching ? rows : searchResults}
                            role={role}
                            page={page}
                            handleLoadMore={handleLoadMore}
                            handleRefund={handleShowRefund}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            endOfList={disableLoadMore}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default OrderForm;
