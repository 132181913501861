import React, { useState, Fragment } from "react";
import classes from "./styles/OnboardingLandingPage.module.css";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import OnboardingLandingPage from "./components/pages/OnboardingLandingPage";
import "./OnboardingApp.css";
import UserInputState from "./context/userInput/userInputState";
import ActivateAccount from "./components/pages/ActivateAccount";
import { useWindowSize } from "react-use";

function OnboardingApp(props) {
    const [renderActivateForm, setRenderActivateForm] = useState(false);
    const [userInputState, setUserInputState] = useState({});
    let { width } = useWindowSize();
    function renderActivateAccountForm(value, state) {
        setUserInputState(state);
        setRenderActivateForm(value);
        return;
    }

    return (
        <Fragment>
            <UserInputState>
                <Navbar width={width} />
                {renderActivateForm ? (
                    <ActivateAccount state={userInputState} />
                ) : (
                    <main className={classes.main}>
                        <OnboardingLandingPage
                            renderActivateAccountForm={
                                renderActivateAccountForm
                            }
                            width={width}
                            freeTrial={props.freeTrial}
                        />
                    </main>
                )}
            </UserInputState>
            <Footer />
        </Fragment>
    );
}

export default OnboardingApp;
