import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import AddIcon from "@mui/icons-material/Add";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    Paper,
    Grid,
    Button,
    Snackbar,
    Typography,
    Tooltip
} from "@mui/material";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { BlockedTimeTable } from "../components/BlockedTimeTable";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import {
    getUnavailabilityOfProvider,
    deactivateSchedule
} from "../modules/ScheduleService";
import { auditProviderBlockTimeDelete } from "../modules/Audit";
import { getUserFromCache } from "../user/UserCommon";
import { OUTLET_PAGE } from "../context/reducers";
import { useNavigate } from "react-router-dom";

export const ProviderBlockTime = (props) => {
    const navigate = useNavigate();
    const { actions, state } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    //Selected rows
    const [selected, setSelected] = useState([]);
    const [searchedFor, setSearchedFor] = useState(false);

    //Search Values
    const [values, setValues] = useState({
        type: "",
        name: ""
    });

    let filter = {};

    useEffect(() => {
        if (sessionStorage.getItem("search") === "true") setSearchedFor(true);
        _getUnavailableTimeBlockList();
    }, []);

    // Get
    const _getUnavailableTimeBlockList = async () => {
        const loggedInUser = getUserFromCache();
        const companyId = loggedInUser.companyId;

        console.log("Getting getUnavailabilityOfProvider for display");
        let unavblocks = await getUnavailabilityOfProvider({
            companyId,
            providerId: state.provider.id
        });
        console.log("unavblocks", JSON.stringify(unavblocks));
        if (unavblocks) {
            if (unavblocks.length > 0) {
                let arr = unavblocks.filter((item) => {
                    item.timeRange = `${withAMPM(item.startTime)} - ${withAMPM(
                        item.endTime
                    )}`;
                    let repeat = "Every ";
                    const weekDaysArr =
                        !!item.weekDays && item.weekDays.split("");
                    console.log("weekDaysArr", weekDaysArr);
                    if (weekDaysArr) {
                        const weeks = [
                            "Sun",
                            "Mon",
                            "Tue",
                            "Wed",
                            "Thu",
                            "Fri",
                            "Sat"
                        ];
                        for (const we of weekDaysArr) {
                            repeat = repeat + weeks[we] + " ";
                        }
                        item.repeat = repeat;
                    } else {
                        item.repeat = "N/A";
                    }

                    return item;
                });
                console.log("unavblocks", arr);
            }
            setRows(unavblocks);
        }
    };

    function withAMPM(t) {
        if (!t) return "";
        const hm = t.split(":");
        let hh = Number.parseInt(hm[0]);
        let hr = hh < 13 ? hh : hh - 12;
        let hrstr = hr < 10 ? `0${hr}` : `${hr}`;
        let ampm = hh < 12 ? "AM" : "PM";
        return `${hrstr}:${hm[1]} ${ampm}`;
    }

    function displayCancelButton() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser.role != "PROVIDER") {
            return (
                <Grid item>
                    <Tooltip title="Returns back to the main provider page">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleTimeBlockCancel}
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    function displayReturnButton() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser.role != "PROVIDER") {
            return (
                <Grid item>
                    <Tooltip title="Returns to the page with the providers matching your search">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleReturnToSearch}
                        >
                            Return to Search
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    function displayBackButton() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser.role != "PROVIDER") {
            return (
                <Grid item>
                    <Tooltip title="Returns to only the provider you selected">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleBack}
                        >
                            Back
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    const _handleBlockTimeAdd = () => {
        actions.setMode("Add");
        actions.setPage("BlockTimeAddEdit");
    };

    const _handleTimeBlockCancel = () => {
        sessionStorage.setItem("back", false);
        sessionStorage.setItem("search", false);
        actions.setId(state.provider.id);
        actions.setMode("Edit");
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
        
    };

    const _handleReturnToSearch = () => {
        sessionStorage.setItem("back", true);
        actions.setMode("Add");
        actions.setPage("ProviderForm");
    };

    const _handleBack = () => {
        sessionStorage.setItem("back", true);
        sessionStorage.setItem("search", false);
        actions.setId(state.id);
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
    };

    const _handleDeleteDialogDecline = () => {
        setDeleteDialog(false);
    };

    const deleteTimeBlock = async (id) => {
        const loggedInUser = getUserFromCache();
        console.log("delete time block id", id);
        try {
            let res = await deactivateSchedule(id);
            await auditProviderBlockTimeDelete(loggedInUser, res);
            if (!res) {
                setSnackMsg("Something went wrong, while deleting TimeBlock.");
                setMsgOpen(true);
            } else {
                await _getUnavailableTimeBlockList();
                setDeleteDialog(false);
                setSelected([]);
                actions.setPage("ProviderBlockTime");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const _handleDeleteDialogAccept = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteTimeBlock(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <FormHeading
                title={`Provider Blocked Time / Time Off`}
                classes={classes}
            />
            <DeleteDialog
                title="Time block"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <Paper rounded="true" className={classes.root}>
                <Typography className={classes.title} variant="h6" noWrap>
                    Blocked Times / Time Off for {state.provider.name}
                </Typography>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleBlockTimeAdd}
                        >
                            <AddIcon className={classes.leftIcon} />
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <BlockedTimeTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                rows={rows}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    {searchedFor && displayReturnButton()}
                    &nbsp;
                    {displayBackButton()}
                    &nbsp;
                    {displayCancelButton()}
                </Grid>
            </Paper>
        </>
    );
};
