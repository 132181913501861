import { useEffect, useState } from "react";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";

function useFetchGraphQL(query, filter) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    async function fetchData() {
        const response = await graphql(graphqlOperation(query, filter));

        // data.listServiceTypes.items
        let result = null;

        if (query.includes("listServiceTypes")) {
            result = await response.data.listServiceTypes.items;
            console.log("result for listServiceTypes = ");
        } else if (query.includes("listProviders")) {
            result = await response.data.listProviders.items;
            console.log("result for listProviders = ");
        } else if (query.includes("listSkills")) {
            result = await response.data.listSkills.items;
            console.log("result for listSkills = ");
        } else if (query.includes("listPromotions")) {
            result = await response.data.listPromotions.items;
            console.log("ewsult for listPromotions", result);
        } else if (query.includes("listPackages")) {
            result = await response.data.listPackages.items;
            console.log("result for listPackages = ");
        }

        console.log(result);
        setData(result);
        setLoading(false);
    }
    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        if (mounted) fetchData();
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    }, []);
    return [data, loading];
}

export { useFetchGraphQL };
