import React, { useContext, useEffect, useState, useRef } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Snackbar, Paper, Typography, Grid, Chip, TextField, MenuItem, Card, Button, CardMedia, CardContent, Avatar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { useProviderDirectoryStyles } from "../styles/ProviderDirectoryStyles";
import axios from "axios";
import { getJsonApi } from "../modules/AmplifyServices";
import { getUrl } from "aws-amplify/storage";
import { ConsoleLogger } from "aws-amplify/utils";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import LocationIcon from "@mui/icons-material/LocationOn";
import { Rating } from "@mui/material";
import { getUserFromCache } from "../user/UserCommon";
import _ from "lodash";
import SelectedIcon from "@mui/icons-material/Done";

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex"
    },
    details: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "1 0 auto",
        paddingRight: 20,
        width: 200
    },
    cover: {
        width: 200,
        paddingRight: 20
    }
}));

export const ProviderDirectory = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [showSuggest, setShowSuggest] = useState(false);
    const [locations, setLocations] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [providerDisplayData, setProviderDisplayData] = useState([]);
    const [services, setServices] = useState([]);
    const [skills, setSkills] = useState([]);
    const [msgOpen, setMsgOpen] = useState(false);
    const [serviceList, setServiceList] = useState(false);
    const [skillsList, setSkillsList] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [snackMsg, setSnackMsg] = useState("");
    const { actions, state } = useContext(StoreContext);
    const [selectLoc, setSelectLoc] = useState("");
    const [addrCoords, setAddrCoords] = useState(null);
    const classes = useProviderDirectoryStyles();
    const node = useRef();
    const companyId = getUserFromCache().company.id;

    const logger = new ConsoleLogger("ProviderDirectory");

    const classes2 = useStyles();
    const theme = useTheme();

    const showPosition = (pos) => {
        let position = {
            latitude: getUserFromCache().latitude,
            longitude: getUserFromCache().longitude
        };
        if (pos) {
            if (pos.coords) {
                console.log("pos", pos.coords.latitude, pos.coords.longitude);
                position = {
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude
                };
            }
        }
        const filter = {
            and: [
                { companyId: { eq: getUserFromCache().company.id } },
                { active: { eq: true } },
                { deleted: { ne: true } }
            ]
        };
        _getProvidersFromES(position);
        //_getProviders(filter, true, position);
    };

    const _getSortData = async () => {};

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                showPosition,
                (err) => {
                    console.warn(`ERROR(${err.code}): ${err.message}`);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        }
        async function getFilterData() {
            await _getSortData();
        }
        getFilterData();
        // add when mounted
        document.addEventListener("mousedown", handleDivClick);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleDivClick);
        };
    }, []);

    useEffect(() => {
        showPosition(addrCoords);
    }, [addrCoords, selectedSkills, selectedServices]);

    const handleDivClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            console.log("*** inside click");
            return;
        } else {
            // outside click
            console.log("*** outside click");
            if (serviceList) setServiceList(false);
            return;
        }
    };

    const _setProvider = (e, item) => {
        actions.setBookingState({ provider: item });
        actions.setPage("BookingForm");
    };

    const _getProvidersFromES = async (pos) => {
        //selected services and selected skills are in the component state
        try {
            //companyid, lat, lng, servicetypeid
            const result = await getJsonApi("searchapi", "/providers", {
                queryParams: {
                    companyId: getUserFromCache().company.id,
                    lng: pos.longitude,
                    lat: pos.latitude,
                    stids: selectedServices.join(),
                    skids: selectedSkills.join()
                }
            });
            console.log(JSON.stringify(result));

            let providersArr = [];
            if (result && result.length > 0) {
                const promises = result.map(async (esr) => {
                    await getUrl({ key: esr.provider.pictures3key }).then(
                        (img) => (esr.provider.picUrl = img?.url)
                    );
                    return {
                        ...esr.provider,
                        schedule: {
                            id: esr.id,
                            active: esr.active,
                            scheduleinfo: esr.scheduleinfo,
                            locationId: esr.locationId
                        }
                    };
                });
                providersArr = await Promise.all(promises);
            }
            if (providersArr) {
                setProviderData(providersArr);
                setProviderDisplayData(providersArr);
            } else {
                //TODO:
            }
        } catch (ex) {
            console.log("error", ex);
        }
    };

    const _handleFetchProviderPerSkill = (id) => {
        setServiceList(false);
        let selected = selectedSkills;
        if (_.includes(selected, id)) {
            selected = selected.filter((item) => {
                return item !== id;
            });
        } else {
            selected = selected.concat(id);
        }
        setSelectedSkills(selected);
        // populateProviderDisplay(selected, selectedServices);
    };

    const _handleFetchProviderPerService = (id) => {
        setSkillsList(false);
        let selected = selectedServices;
        if (_.includes(selected, id)) {
            selected = selected.filter((item) => {
                return item !== id;
            });
        } else {
            selected = selected.concat(id);
        }
        setSelectedServices(selected);
        //populateProviderDisplay(selectedSkills, selected);
    };

    function onQuery(evt) {
        const query = evt.target.value;
        setSearchTerm(query);

        if (query && query.length > 5) {
            axios
                .get(
                    "https://autocomplete.geocoder.api.here.com/6.2/suggest.json",
                    {
                        params: {
                            app_id: process.env.REACT_APP_GEOCODER_APP_ID,
                            app_code: process.env.REACT_APP_GEOCODER_CODE,
                            query: query
                        }
                    }
                )
                .then(function (response) {
                    if (response.data.suggestions.length > 0) {
                        setShowSuggest(true);
                        setLocations(response.data.suggestions);
                    } else {
                        setShowSuggest(false);
                        setLocations([]);
                    }
                });
        }
    }

    const getAddressCoords = (addr) => {
        let params = {
            app_id: process.env.REACT_APP_GEOCODER_APP_ID,
            app_code: process.env.REACT_APP_GEOCODER_CODE,
            searchtext: addr
        };
        console.log("params", params);
        axios
            .get("https://geocoder.api.here.com/6.2/geocode.json", {
                params: params
            })
            .then(function (response) {
                console.log("geocode response:" + response);
                const view = response.data.Response.View;
                if (view.length > 0 && view[0].Result.length > 0) {
                    const location = view[0].Result[0].Location;
                    setAddrCoords({
                        coords: {
                            latitude: location.DisplayPosition.Latitude,
                            longitude: location.DisplayPosition.Longitude
                        }
                    });
                } else {
                    setAddrCoords(null);
                }
            })
            .catch(function (error) {
                console.log("caught failed query");
                setAddrCoords(null);
            });
    };

    const _handleSetSelected = async (loc) => {
        const { address, label } = loc;
        setSelectLoc(loc);
        setSearchTerm(label);
        setShowSuggest(false);
        getAddressCoords(label);
    };

    function DisplayServiceTypeChip(displayAvatar) {
        if (displayAvatar) {
            return (
                <Chip
                    label="Services Selected"
                    onClick={(e) => setServiceList(!serviceList)}
                    avatar={<Avatar>{<SelectedIcon />}</Avatar>}
                    color="primary"
                />
            );
        } else {
            return (
                <Chip
                    label="Select Services"
                    onClick={(e) => setServiceList(!serviceList)}
                    color="primary"
                />
            );
        }
    }

    function DisplaySkillChip(displayAvatar) {
        if (displayAvatar) {
            return (
                <Chip
                    label="Skills Selected"
                    onClick={(e) => setSkillsList(!skillsList)}
                    avatar={<Avatar>{<SelectedIcon />}</Avatar>}
                    color="primary"
                />
            );
        } else {
            return (
                <Chip
                    label="Select Skills"
                    onClick={(e) => setSkillsList(!skillsList)}
                    color="primary"
                />
            );
        }
    }

    const handleClose = () => {
        setServiceList(false);
    };

    const handleCloseSkills = () => {
        setSkillsList(false);
    };

    return (
        <div ref={node}>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                    EnhancedToolbar: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={serviceList}
                onClose={handleClose}
                aria-labelledby="select-services"
            >
                <DialogTitle id="select-services">Services</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select the services you require and we will only
                        include providers who provide these services.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            {serviceList &&
                                !!services &&
                                services.length > 0 && (
                                    <div>
                                        {services.map((serv) => (
                                            <MenuItem
                                                classes={{ root: classes.menu }}
                                                component="div"
                                                selected={_.includes(
                                                    selectedServices,
                                                    serv.id
                                                )}
                                                key={serv.name}
                                                onClick={() =>
                                                    _handleFetchProviderPerService(
                                                        serv.id
                                                    )
                                                }
                                            >
                                                {serv.name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={skillsList}
                onClose={handleCloseSkills}
                aria-labelledby="select-skills"
            >
                <DialogTitle id="select-skills">Skills</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select the skills you require and we will only
                        include providers who have these skills.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            {skillsList && !!skills && skills.length > 0 && (
                                <div>
                                    {skills.map((skill) => (
                                        <MenuItem
                                            classes={{ root: classes.menu }}
                                            component="div"
                                            selected={_.includes(
                                                selectedSkills,
                                                skill.id
                                            )}
                                            key={skill.name}
                                            onClick={() =>
                                                _handleFetchProviderPerSkill(
                                                    skill.id
                                                )
                                            }
                                        >
                                            {skill.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSkills} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <FormHeading
                title="View Providers In Your Area"
                classes={classes}
            />
            <br />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {DisplayServiceTypeChip(selectedServices.length > 0)}
                        &nbsp;
                        {DisplaySkillChip(selectedSkills.length > 0)}
                    </Grid>
                </Grid>
                <br />
                <Typography>
                    Please enter your address, city or postal/zip code
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            label="Please enter full address"
                            id="{props.id}"
                            fullWidth
                            value={searchTerm}
                            onChange={onQuery}
                            placeholder="start typing"
                        />
                        {locations.length > 0 && showSuggest && (
                            <div style={{ boxShadow: "0px 1px 2px #ccc" }}>
                                {locations.map((loc) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        component="div"
                                        key={loc.label}
                                        onClick={() => _handleSetSelected(loc)}
                                    >
                                        {loc.label}
                                    </MenuItem>
                                ))}
                            </div>
                        )}
                    </Grid>
                </Grid>
                <br />
                {!!providerDisplayData && providerDisplayData.length > 0 && (
                    <Grid container spacing={2} alignItems="flex-start">
                        {providerDisplayData.map((item, i) => {
                            console.log("item", item);
                            return (
                                <>
                                    <Grid item xs={12} key={i}>
                                        <Card
                                            raised="true"
                                            className={classes2.card}
                                        >
                                            <div className={classes2.details}>
                                                <CardContent
                                                    className={classes2.content}
                                                >
                                                    <Typography
                                                        component="h5"
                                                        variant="h5"
                                                    >
                                                        {`${item.firstname} ${item.lastname}`}
                                                    </Typography>
                                                    <Rating
                                                        name="hover-side"
                                                        value={
                                                            item.ratingstarsavg
                                                        }
                                                        readOnly={true}
                                                    />
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                    >
                                                        based on{" "}
                                                        {item.numberofratings
                                                            ? item.numberofratings
                                                            : "0"}{" "}
                                                        review(s)
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) =>
                                                            _setProvider(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                    >
                                                        Book {item.firstname}
                                                    </Button>
                                                </CardContent>
                                                <div
                                                    className={
                                                        classes2.controls
                                                    }
                                                ></div>
                                            </div>
                                            <CardMedia
                                                component="img"
                                                className={classes2.cover}
                                                height="250"
                                                style={{ paddingLeft: 20 }}
                                                image={`${item.picUrl}`}
                                                title={`${item.firstname} ${item.lastname}`}
                                            />
                                            <div>
                                                <Typography
                                                    variant="subtitle2"
                                                    gutterBottom
                                                >
                                                    <LocationIcon
                                                        aria-label={
                                                            item.addressoneline
                                                        }
                                                    />
                                                    {item.addressoneline}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    gutterBottom
                                                >
                                                    {item.firstname}'s bio:
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    {item.bio}
                                                </Typography>
                                                {!!item.servicetypes &&
                                                    !!item.servicetypes.items &&
                                                    item.servicetypes.items
                                                        .length > 0 && (
                                                        <>
                                                            <Typography
                                                                variant="subtitle2"
                                                                gutterBottom
                                                            >
                                                                {item.firstname}{" "}
                                                                offers these
                                                                services:
                                                            </Typography>
                                                            <div>
                                                                {item.servicetypes.items.map(
                                                                    (
                                                                        service,
                                                                        idx
                                                                    ) => {
                                                                        let servicetype =
                                                                            {};
                                                                        if (
                                                                            service.servicetype
                                                                        )
                                                                            servicetype =
                                                                                service.servicetype;
                                                                        else
                                                                            servicetype =
                                                                                service;
                                                                        return (
                                                                            <>
                                                                                <Chip
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    label={
                                                                                        servicetype.name
                                                                                    }
                                                                                    key={`${idx}-${servicetype.id}`}
                                                                                />{" "}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                {!!item.skills &&
                                                    !!item.skills.items &&
                                                    item.skills.items.length >
                                                        0 && (
                                                        <>
                                                            <br></br>
                                                            <Typography
                                                                variant="subtitle2"
                                                                gutterBottom
                                                            >
                                                                {item.firstname}{" "}
                                                                has these
                                                                skills:
                                                            </Typography>
                                                            <div>
                                                                {item.skills.items.map(
                                                                    (
                                                                        skill,
                                                                        idx
                                                                    ) => {
                                                                        let sk =
                                                                            {};
                                                                        if (
                                                                            skill.skill
                                                                        )
                                                                            sk =
                                                                                skill.skill;
                                                                        else
                                                                            sk =
                                                                                skill;
                                                                        return (
                                                                            <>
                                                                                <Chip
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    label={
                                                                                        sk.name
                                                                                    }
                                                                                    key={`${idx}-${sk.id}`}
                                                                                />{" "}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        </Card>
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                )}
            </Paper>
        </div>
    );
};
