import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";

import ClientPackageAddEditForm from "./ClientPackageAddEditForm";

const setupService = document.getElementById("setup-service");

function ClientPackageAddEditModal(props) {
    /* useEffect(() => {
        // console.log("Provider Id in Service ADD Modal", props.providerInfo);
    }, [props.providerInfo]); */
    const smallerScreen = useMediaQuery("(max-width:600px)");
    return ReactDOM.createPortal(
        <Dialog
            sx={{ padding: "1.5rem" }}
            open={props.open}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
                style: {
                    maxWidth: !smallerScreen ? "42%" : "100%",
                    maxHeight: "100%"
                }
            }}
        >
            <DialogContent>
                <ClientPackageAddEditForm
                    originPath={props.originPath}
                    closeModal={props.closeModal}
                    clientPackageMode={props.clientPackageMode}
                    changeClientPackageMode={props.changeClientPackageMode}
                    packageId={props.packageId}
                    clientPackage={props.clientPackageForModal}
                    showPackageDialog={props.showPackageDialog}
                    resetStates={props.resetStates}
                />
            </DialogContent>
        </Dialog>,
        setupService
    );
}

export default ClientPackageAddEditModal;
