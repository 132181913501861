import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import Snackbar from "@mui/material/Snackbar";
import throttle from "lodash/throttle";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { Cache } from "aws-amplify/utils";
import { ordersByCompanyOrderNo } from "../queries/ListBookingsQueries";
import { userHasAdminRole } from "../user/UserCommon";
import { clientByUserId } from "../queries/ListBookingsQueries";
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "theme.palette.text.secondary",
        marginRight: theme.spacing(2)
    },
    autocomplete: {
        width: 320,
        [theme.breakpoints.down("sm")]: {
            width: 250
        }
    },
    inputRoot: (theme) => ({
        color: "#000"
    })
}));

export default function OrderNumberLookup(props) {
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const [filter, setFilter] = React.useState(null);

    //Initial building of filter - build to limit access to info
    useEffect(() => {
        async function getQueryFilter() {
            let filter;
            if (userHasAdminRole()) {
                filter = null;
            } else {
                // need to find client id based on user
                let clientId = await Cache.getItem("ClientId");
                if (!clientId) {
                    clientId = await getClientByUserId();
                }
                filter = {
                    clientId: { eq: clientId }
                };
            }
            setFilter(filter);
        }
        getQueryFilter();
    }, []);
    async function getClientByUserId() {
        let clientIds = [];
        //write get clients query
        const clientProfile = await graphql(
            graphqlOperation(clientByUserId, {
                userId: props.user?.id,
                filter: { companyId: { eq: props.companyId } }
            })
        );
        if (
            clientProfile &&
            clientProfile.data.clientByUserId.items &&
            clientProfile.data.clientByUserId.items.length
        ) {
            for (let ci of clientProfile.data.clientByUserId.items) {
                clientIds.push(ci.id);
            }
        }
        return clientIds.length ? clientIds[0] : null;
    }
    //Renders dropdown options
    function renderOption(propsOp, option) {
        console.log("renderOptions OrderNumberLookup");
        console.log(option);
        return (
            <li {...propsOp}>
                <Grid key={option.key} container alignItems="center">
                    <Grid item>
                        <ArrowRight className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                        <span
                            key={option.key}
                            style={{
                                fontWeight: 700
                            }}
                        >
                            {option}
                        </span>
                    </Grid>
                </Grid>
            </li>
        );
    }

    //Logic to verify if should even bother searching yet
    function inputReady(inp) {
        if (inp) {
            inp = inp.trimStart();
            if (inp.length >= 6) {
                return true;
            }
        }
        return false;
    }

    //Logic searching for order number
    async function searchOrderNo(input) {
        const result = await graphql(
            graphqlOperation(ordersByCompanyOrderNo, {
                companyId: props.companyId,
                orderNo: { eq: parseInt(input.input) },
                filter: input.filter,
                limit: 5
            })
        );
        return result;
    }

    //Lower spam on user fast input
    const fetch = React.useMemo(
        () =>
            throttle(async (request, callback) => {
                let result = await searchOrderNo(request);
                callback(result);
            }, 10),
        []
    );

    //On user input change, run logic to handle accordingly
    React.useEffect(() => {
        async function doSearchOrderNo() {
            let active = true;

            if (inputValue === "") {
                return undefined;
            } else if (inputReady(inputValue) && !value) {
                fetch({ input: inputValue, filter }, (results) => {
                    if (active) {
                        let newOptions = [];

                        if (
                            !results ||
                            !results.data ||
                            !results.data.ordersByCompanyOrderNo ||
                            !results.data.ordersByCompanyOrderNo.items ||
                            results.data.ordersByCompanyOrderNo.items.length ===
                                0
                        ) {
                            //Invalid results.
                            // setSnackMsg("No valid order found. Please verify the number input.")
                            // setMsgOpen(true);
                            setOptions([]);
                            return () => {
                                active = false;
                            };
                        }
                        //Got past here, so result is valid.
                        const data = results.data.ordersByCompanyOrderNo.items;
                        for (let resultIndex in data) {
                            newOptions.push(
                                data[resultIndex].orderNo.toString()
                            );
                        }

                        setOptions(newOptions);

                        return () => {
                            active = false;
                        };
                    }
                });
            }
        }
        doSearchOrderNo();
    }, [value, inputValue, fetch, filter]);

    function clear() {
        setOptions([]);
        setValue(null);
        setInputValue(null);
        props.setSelectedValue(null);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={1000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Autocomplete
                id="google-map-demo"
                getOptionLabel={(option) => option}
                filterOptions={(x) => x}
                options={Object.values(options)}
                autoComplete
                includeInputInList
                filterSelectedOptions
                openOnFocus
                freeSolo
                value={value}
                onChange={(event, newValue) => {
                    //setOptions([]);
                    setValue(newValue);
                    setInputValue(newValue);
                    props.setSelectedValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    //setValue();
                    setInputValue(newInputValue);
                    props.setSelectedValue(newInputValue);
                }}
                renderOption={(propsOp, option) =>
                    renderOption(propsOp, option)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        id="search-ordernum-field"
                        label="Search by Order Number"
                        variant="outlined"
                        fullWidth
                        placeholder={props.placeholder}
                        margin="normal"
                        type="number"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                            // ...(value
                            //     ? {
                            //         endAdornment: (
                            //             <IconButton
                            //                 aria-label="clear"
                            //                 onClick={() => {
                            //                     clear();
                            //                 }
                            //                 }
                            //             >
                            //                 <ClearIcon />
                            //             </IconButton>
                            //         )
                            //     }
                            //     : {})
                        }}
                    />
                )}
            />
        </>
    );
}
