import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { userByCompany } from "../queries/UserQueries";
import { execReadBySortkey, execReadByPK } from "../modules/DBService";
import {
    providerByCompanyWithLocations,
    providerByCompany,
    serviceTypeByCompanyCustom
} from "../queries/ProviderFormQueries";
import { getUserFromCache } from "../user/UserCommon";
import {
    getScheduleCounts,
    getScheduleDatesToReport
} from "../modules/ScheduleService";
import { CSVLink } from "react-csv";
import {
    IconButton,
    Grid,
    LinearProgress,
    CircularProgress,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const ExportProvidersList = () => {
    const limit = process.env.REACT_APP_LISTLIMIT;
    const [exportDialog, setExportDialog] = useState();
    const [exportReadyProviders, setExportReadyProviders] = useState();
    const [exportDownloadReady, setExportDownloadReady] = useState(false);
    const [linearProgressValue, setLinearProgressValue] = useState(0);
    const [preparingExportData, setPreparingExportData] = useState(false);
    const [serviceTypeOption, setServiceTypeOption] = useState(0);
    const [showExportSpinner, setShowExportSpinner] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [serviceTypeData, setServiceTypeData] = useState([]);

    async function fetchServiceTypes() {
        const authuser = getUserFromCache();
        const result = await graphql(
            graphqlOperation(serviceTypeByCompanyCustom, {
                companyId: authuser.company.id,
                limit: limit,
                filter: {
                    and: [
                        { deleted: { ne: true } },
                        { active: { ne: false } },
                        { isVisible: { ne: false } }
                    ]
                }
            })
        );
        return result.data.serviceTypeByCompany.items;
    }

    const csvData = async (providersList) => {
        try {
            const loggedInUser = getUserFromCache();
            const schedules = await getScheduleCounts(loggedInUser.company.id);
            const scheduleDatesToReport = await getScheduleDatesToReport(
                loggedInUser.company.id,
                schedules.SCs
            );
            providersList.items.sort(
                //sort the providersList is descending order based on created At
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setLinearProgressValue(20);
            let rows = [];
            let serviceTypeId,
                serviceTypeList = "",
                skillsList = "",
                i,
                j;
            let newItem;

            let usersByCompany = await execReadBySortkey({
                opname: "userByCompany",
                op: userByCompany,
                id: { companyId: loggedInUser.company.id },
                skey: {
                    roleEmailaddress: {
                        beginsWith: {
                            role: "PROVIDER"
                        }
                    }
                }, //TODO: shhould be based on StartDate and EndDate
                limit: process.env.REACT_APP_LISTLIMIT
            });
            setLinearProgressValue(30);
            console.log("omar this is the userByCompany", usersByCompany);
            let providerLocationData = await execReadByPK({
                opname: "providerByCompany",
                op: providerByCompanyWithLocations,
                id: { companyId: loggedInUser.company.id },
                limit: 1000
            });
            setLinearProgressValue(40);
            console.log("omar this is the providers", providersList);
            const companyServices = await fetchServiceTypes();
            if (providersList && providersList.items.length > 0) {
                providersList.items.map(async (item) => {
                    usersByCompany.items.map(async (item1) => {
                        if (item.emailaddress == item1.emailaddress) {
                            const loginInfo = JSON.parse(item1.LoginInfo);
                            item.phonepreference = item1.phonepref;
                            item.addressLine = item1.addressoneline;
                            item.city = item1.city ? item1.city : "";
                            item.state = item1.state ? item1.state : "";
                            item.country = item1.country ? item1.country : "";
                            item.postalcode = item1.postalcode
                                ? item1.postalcode
                                : "";
                            item.completedloginstatus =
                                (item1.active && item1.LoginInfo) ||
                                item1.registered === false
                                    ? "True"
                                    : !item.active
                                      ? "INACTIVE"
                                      : "False";
                            item.lastLogin = moment(
                                loginInfo?.lastLoginDate
                            ).format("YYYY-MM-DD hh:mm A");
                            item.loginCount = loginInfo?.loginCount;
                        }
                    });

                    providerLocationData.items.map(async (item2) => {
                        if (item.emailaddress == item2.emailaddress) {
                            try {
                                if (item2.locations.items.length > 1) {
                                    for (
                                        j = 0;
                                        j < item2.locations.items.length;
                                        j++
                                    ) {
                                        if (item.travelzoneaddress === "") {
                                            if (
                                                item2.locations.items[j]
                                                    .addressoneline != undefined
                                            ) {
                                                item.travelzoneaddress =
                                                    item2.locations.items[
                                                        j
                                                    ].addressoneline;
                                            } else {
                                                item.travelzoneaddress = "";
                                            }
                                        } else {
                                            if (
                                                item.travelzoneaddress ==
                                                undefined
                                            ) {
                                                item.travelzoneaddress = "";
                                            }
                                            let tempstorage = item2.locations
                                                .items[j].addressoneline
                                                ? item2.locations.items[j]
                                                      .addressoneline
                                                : "";
                                            if (tempstorage != "") {
                                                if (
                                                    item.travelzoneaddress == ""
                                                ) {
                                                    item.travelzoneaddress +=
                                                        tempstorage;
                                                } else {
                                                    item.travelzoneaddress +=
                                                        "; " + tempstorage;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    item.travelzoneaddress =
                                        item2.locations.items[0].addressoneline;
                                }
                            } catch {
                                item.travelzoneaddress = "";
                            }
                        }
                    });

                    item.completedloginstatus = item.completedloginstatus
                        ? item.completedloginstatus
                        : "False";
                    item.addressLine = item.addressLine ? item.addressLine : "";
                    item.phonepreference = item.phonepreference
                        ? item.phonepreference
                        : "";
                    serviceTypeList = "";
                    skillsList = "";
                    let providesServiceType = false;

                    for (i = 0; i < item.servicetypes.items.length; i++) {
                        serviceTypeId =
                            item.servicetypes.items[i].servicetype.id;

                        // filter service types
                        if (
                            serviceTypeOption != 0 &&
                            serviceTypeId === serviceTypeOption
                        ) {
                            providesServiceType = true;
                        }
                        for (j = 0; j < companyServices.length; j++) {
                            if (companyServices[j].id === serviceTypeId) {
                                const std = companyServices[j].name;
                                if (serviceTypeList === "") {
                                    serviceTypeList = std;
                                } else {
                                    serviceTypeList += "; " + std;
                                }
                                newItem = { ...item, serviceTypeList };
                                item = newItem;
                            }
                        }
                        for (let a = 0; a < item.skills.items.length; a++) {
                            const std = item.skills.items[a].skill.name;
                            if (skillsList === "") {
                                skillsList = std;
                            } else if (!skillsList.includes(std)) {
                                skillsList += "; " + std;
                            }
                            newItem = { ...item, skillsList };
                            item = newItem;
                        }
                    }
                    item.createdAt = moment(item.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    );

                    item.virtualmeetingroomlink = item.vmlink
                        ? item.vmlink
                        : "";
                    item.virtualservicestatus = item.offersVirtualServices
                        ? item.offersVirtualServices
                        : "FALSE";

                    if (
                        item.virtualservicestatus == true &&
                        item.virtualmeetingroomlink == ""
                    ) {
                        item.virtualmeetingroomlink = "N/A";
                    }
                    item.permalink =
                        loggedInUser && loggedInUser.company
                            ? `https://${loggedInUser.company.subdomain}/provider/${item.permalink}`
                            : "";
                    item.emailaddress = item.emailaddress
                        ? item.emailaddress
                        : "";
                    item.firstname = item.firstname ? item.firstname : "";
                    item.lastname = item.lastname ? item.lastname : "";
                    item.email = item.email ? item.email : "";
                    item.phone = item.phone ? item.phone : "";
                    item.addressoneline = item.addressoneline
                        ? item.addressoneline
                        : "";
                    item.city = item.city ? item.city : "";
                    item.state = item.state ? item.state : "";
                    item.country = item.country ? item.country : "";
                    item.postalcode = item.postalcode ? item.postalcode : "";
                    item.timezone = item.timezone ? item.timezone : "";
                    item.bio = item.bio ? item.bio.replace(/\n/g, " ") : "";
                    item.bio = item.bio?.endsWith('"')
                        ? (item.bio = item.bio.slice(0, -1))
                        : item.bio;
                    item.skillsList = item.skillsList ? item.skillsList : "";
                    item.serviceList = item.serviceTypeList
                        ? item.serviceTypeList
                        : "";
                    item.status = item.active != false ? "Active" : "Inactive";
                    item.ratingstaravg = !!item.ratingstaravg
                        ? item.ratingstaravg
                        : 0;
                    item.numberofratings = item.numberofratings
                        ? item.numberofratings
                        : 0;
                    item.maxtraveltype = item.maxtraveltype
                        ? item.maxtraveltype
                        : "MAX_DISTANCE";
                    item.locationCount = item.locations.items
                        ? item.locations.items.length
                        : 0;
                    item.numberofschedules = schedules.scheduleCounts[item.id]
                        ? schedules.scheduleCounts[item.id]
                        : 0;
                    item.latestScheduleUpdatedAt =
                        scheduleDatesToReport[item.id] &&
                        scheduleDatesToReport[item.id].latestScheduleUpdatedAt
                            ? moment(
                                  scheduleDatesToReport[item.id]
                                      .latestScheduleUpdatedAt
                              ).format("YYYY-MM-DD")
                            : "";
                    item.farthestScheduleDate =
                        scheduleDatesToReport[item.id] &&
                        scheduleDatesToReport[item.id].farthestScheduleDate
                            ? scheduleDatesToReport[item.id].sbs.some(
                                  (item) => item.active === true
                              )
                                ? moment(
                                      scheduleDatesToReport[item.id]
                                          .farthestScheduleDate
                                  ).format("YYYY-MM-DD")
                                : "Availability is inactive"
                            : "";
                    item.lastLogin = item.lastLogin ? item.lastLogin : "N/A";
                    item.loginCount = item.loginCount ? item.loginCount : "N/A";
                    rows.push(item);
                });
            }
            const csvData = rows.map((item) => {
                const {
                    id: ID,
                    createdAt: DATECREATED,
                    firstname: FIRSTNAME,
                    lastname: LASTNAME,
                    emailaddress: EMAIL,
                    phone: PHONENUMBER,
                    phonepreference: PHONEPREFERENCE,
                    addressLine: ADDRESSLINE,
                    city: CITY,
                    state: STATE,
                    country: COUNTRY,
                    postalcode: POSTALCODE,
                    bio: BIO,
                    skillsList: SKILLS,
                    serviceList: SERVICES,
                    completedloginstatus: COMPLETEDLOGINSTATUS,
                    status: ACTIVE,
                    ratingstaravg: RATINGAVERAGE,
                    numberofratings: RATINGCOUNT,
                    maxtraveltype: MAXTRAVELTYPE,
                    locationCount: LOCATIONCOUNT,
                    travelzoneaddress: TRAVELZONEADDRESS,
                    numberofschedules: SCHEDULECOUNT,
                    permalink: PERMALINK,
                    virtualservicestatus: VIRTUALSERVICESTATUS,
                    virtualmeetingroomlink: VIRTUALMEETINGROOMLINK,
                    latestScheduleUpdatedAt: LATESTSCHEDULEUPDATEDAT,
                    farthestScheduleDate: FARTHESTSCHEDULEDATE,
                    lastLogin: LASTLOGIN,
                    loginCount: LOGINCOUNT
                } = item;
                const newItem = {
                    ID: ID,
                    "Date Created": DATECREATED,
                    "First Name": FIRSTNAME,
                    "Last Name": LASTNAME,
                    Email: EMAIL,
                    "Phone Number": PHONENUMBER,
                    "Phone Preference": PHONEPREFERENCE,
                    Address: ADDRESSLINE,
                    City: CITY,
                    State: STATE,
                    Country: COUNTRY,
                    "Postal Code": POSTALCODE,
                    Skills: SKILLS,
                    "Completed Login": COMPLETEDLOGINSTATUS,
                    Status: ACTIVE,
                    "Rating Average": RATINGAVERAGE,
                    "Rating Count": RATINGCOUNT,
                    "Max Travel Type (Travel Zone Type)": MAXTRAVELTYPE,
                    "Location Count": LOCATIONCOUNT,
                    "Travel Zone Address": TRAVELZONEADDRESS,
                    "Schedule Count (Future Availability)": SCHEDULECOUNT,
                    Permalink: PERMALINK,
                    "Offers Virtual Services": VIRTUALSERVICESTATUS,
                    "Virtual Meeting Room Link": VIRTUALMEETINGROOMLINK,
                    Services: SERVICES,
                    "Schedule Last Updated": LATESTSCHEDULEUPDATEDAT,
                    "Last Available Date": FARTHESTSCHEDULEDATE,
                    "Last login": LASTLOGIN,
                    "Login count": LOGINCOUNT,
                    Bio: BIO
                };
                return newItem;
            });

            if (csvData && csvData.length) {
                return csvData;
            }
        } catch (e) {
            console.log(e);
        }
    };

    async function prepareExportData() {
        const loggedInUser = getUserFromCache();

        const providersToExport = await execReadByPK({
            opname: "providerByCompany",
            op: providerByCompany,
            id: { companyId: loggedInUser.company.id },
            limit: 10000,
            sortDirection: "ASC"
        });
        setLinearProgressValue(15);

        if (providersToExport.items && providersToExport.items.length > 0) {
            console.log("called prepareExportData", providersToExport.items);
            const providersCSVData = await csvData({
                items: providersToExport.items
            });
            setLinearProgressValue(100);
            setExportReadyProviders(providersCSVData);
            setExportDownloadReady(true);
            return;
        } else {
            setMsgOpen(true);
            setSnackMsg("No providers were found to export.");
        }
        setExportReadyProviders([]);
    }

    function handleExportClick() {
        setExportDialog(true);
    }

    return (
        <>
            <Dialog
                open={exportDialog}
                fullWidth={true}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Export Providers
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setExportDialog(false);
                            setExportDownloadReady(false);
                            setExportReadyProviders([]);
                            setPreparingExportData(false);
                            setLinearProgressValue(0);
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ padding: 24 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "16px" }}
                        >
                            {"Please confirm the following export criteria: "}
                        </Typography>
                        <Typography variant="body1">
                            {"All providers"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon />}
                            onClick={async () => {
                                console.log("prepare export was clicked");
                                setExportReadyProviders([]);
                                setShowExportSpinner(true);
                                setPreparingExportData(true);

                                await prepareExportData();

                                setShowExportSpinner(false);
                            }}
                            disabled={preparingExportData}
                            style={{ marginTop: "24px" }}
                        >
                            Prepare Export
                        </Button>
                    </Grid>
                    {preparingExportData && (
                        <Grid
                            container
                            style={{
                                flexDirection: "column",
                                marginTop: "24px",
                                gap: "16px"
                            }}
                        >
                            {!exportDownloadReady && (
                                <CircularProgress
                                    color="primary"
                                    size={24}
                                    style={{ alignSelf: "center" }}
                                />
                            )}
                            <LinearProgress
                                color="primary"
                                variant="buffer"
                                valueBuffer={linearProgressValue}
                                value={linearProgressValue}
                            />
                        </Grid>
                    )}
                    {exportDownloadReady && (
                        <>
                            <br></br>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {`Please click the download button below to download all provider details.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CSVLink
                                    data={exportReadyProviders}
                                    filename={`providers_${moment(
                                        Date.now()
                                    ).format("YYYY-MM-DD")}.csv`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetAppIcon />}
                                    >
                                        Download
                                    </Button>
                                </CSVLink>
                            </Grid>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <ListItem button onClick={() => handleExportClick()}>
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Providers list" />
            </ListItem>
        </>
    );
};

export default ExportProvidersList;
