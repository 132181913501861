import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter,
    IconButton,
    TableContainer,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Typography,
    Link
} from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { DataTableWrapper } from "../utils/CommonComonents/DataTableWrapper";
import { PotentialSalesColumns } from "../utils/Constants";
import moment from "moment";
const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
export const PotentialSalesTable = (props) => {
    const classes = useStyles();

    // const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { rows } = props;
    const columns = PotentialSalesColumns;

    const [clientMessage, setClientMessage] = React.useState("");
    const [showMessageDialog, setShowMessageDialog] = React.useState(false);
    const [clientName, setClientName] = React.useState("");

    function showMessageContent(item) {
        if (item) {
            const message = item.message ? item.message : "None";
            setClientMessage(message);
            setClientName(item.clientName);
        }
        setShowMessageDialog(true);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    useEffect(() => {
        setPage(0);
    }, [rows]);

    console.log("just a test", rows);

    function getFormattedDate(dateString) {
        return moment(dateString).format("MMM DD, YYYY HH:mm a");
    }

    rows.map((item) => {
        item.createdAtDisplay = getFormattedDate(item.requestDate);
    });
    // const _handleEditClick = (id) => {
    //     actions.setMode("Edit");
    //     actions.setPage("ProviderScheduleAddEditForm");
    //     actions.setId(id);
    // };

    // const _handleViewClick = (id) => {
    //     actions.setMode("View");
    //     actions.setPage("ProviderScheduleAddEditForm");
    //     actions.setId(id);
    // };

    // const _handleTimeClick = (id, location) => {
    //     actions.setPage("TimeBlockForm");
    //     actions.setId(id);
    //     localStorage.setItem("timeBlockLocation", location);
    // };

    return (
        <>
            <div>
                <Dialog
                    open={showMessageDialog}
                    fullWidth={true}
                    aria-labelledby="show-client-dialog-title"
                    aria-describedby="show-client-dialog-description"
                >
                    <DialogTitle
                        id="show-client-dialog-title"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Customer Message From {clientName}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="show-client-dialog-description">
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="body1">
                                        <b>Message:</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body1">
                                        {clientMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowMessageDialog(false)}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        {/*<DataTableWrapper
                        classes={classes}
                        columns={PotentialSalesColumns}
                        rows={rows}
                        multiSelectable={false}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleRowClick={() => {
                            return false;
                        }}
                        handleSelectAllClick={() => {
                            return false;
                        }}
                        showTime={false}
                        showAction={false}
                        showSelect={false}
                        showReceipt={false}
                    />*/}
                        <Table className={classes.Table}>
                            <TableHead>
                                <TableRow>
                                    {columns &&
                                        columns.length > 0 &&
                                        columns.map((column, i) => {
                                            return (
                                                <TableCell
                                                    align={column.align}
                                                    className={
                                                        classes[
                                                            column.className
                                                        ]
                                                    }
                                                    key={i}
                                                >
                                                    {column.title}
                                                </TableCell>
                                            );
                                        })}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows &&
                                    rows.length > 0 &&
                                    rows
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((items, i) => {
                                            return (
                                                <React.Fragment>
                                                    <TableRow hover>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            {
                                                                items.createdAtDisplay
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            {items.clientName}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            <Link
                                                                href={`tel:${items.phone}`}
                                                            >
                                                                {items.phone}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            {items.emailaddress}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            <Link
                                                                target="_blank"
                                                                href={`https://www.google.com/maps/search/?api=1&query=${items.addressoneline.replaceAll(
                                                                    " ",
                                                                    "+"
                                                                )}`}
                                                            >
                                                                {
                                                                    items.addressoneline
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            {items.serviceString
                                                                ? items.serviceString
                                                                : "Requested service has been deleted"}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            {items.skillsString
                                                                ? items.skillsString
                                                                : "None"}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                classes.customCellPading
                                                            }
                                                        >
                                                            {items.todString}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() =>
                                                                    showMessageContent(
                                                                        items
                                                                    )
                                                                }
                                                                color="primary"
                                                            >
                                                                View Message
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        })}
                                {}
                            </TableBody>
                        </Table>
                    </div>
                    <TableFooter>
                        <TableRow style={{ float: "right" }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "Rows per page"
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Paper>
            </div>
        </>
    );
};
