import React, { Fragment } from "react";
import { Typography, Grid } from "@mui/material";
import classes from "../../styles/BottomPanel.module.css";
import capterrarating from "../../assets/capterra-rating.png";
import googlerating from "../../assets/google-rating.png";
import getapprating from "../../assets/getapp-rating.png";
import g4rating from "../../assets/g4-rating.png";

const BottomPanel = (props) => {
    console.log("BottomPanel Loaded");

    return (
        <Fragment>
            {/* this will be the grid that hold the bottom panel */}
            <Grid container className={classes.textgrid}>
                <Typography
                    style={{
                        fontFamily: "poppins, sans-serif",
                        fontSize: "20px"
                    }}
                >
                    There's a reason why our customers continue to rate us 5
                    stars
                </Typography>
                {props.freeTrial && (
                    <Typography
                        style={{
                            fontFamily: "poppins, sans-serif",
                            fontSize: "16px"
                        }}
                    >
                        Join the growing list of businesses that are powered by
                        MarketBox
                    </Typography>
                )}
            </Grid>
            <div className={classes.panel}>
                <a
                    href="https://www.capterra.ca/software/204909/marketbox"
                    target="_blank"
                >
                    <img
                        src={capterrarating}
                        alt="Capterra Rating"
                        className={classes.capterrarating}
                    />
                </a>

                <a
                    href="https://www.google.com/search?q=marketbox&rlz=1C1CHBF_enCA903CA903&oq=marketbox&aqs=chrome..69i57j46i13i175i199i512j69i59l2j69i61j69i60l2j69i65.1653j0j9&sourceid=chrome&ie=UTF-8#lrd=0x882b3516908d6405:0x6f9b93e8fcf0d09d,1,,,,"
                    target="_blank"
                >
                    <img
                        src={googlerating}
                        alt="Google Rating"
                        className={classes.googlerating}
                    />
                </a>

                <a
                    href="https://www.getapp.com/recreation-wellness-software/a/marketbox-1/"
                    target="_blank"
                >
                    <img
                        src={getapprating}
                        alt="Get App Rating"
                        className={classes.getapprating}
                    />
                </a>

                <a
                    href="https://www.g2.com/products/marketbox/reviews"
                    target="_blank"
                >
                    <img
                        src={g4rating}
                        alt="G4 Rating"
                        className={classes.g4rating}
                    />
                </a>
            </div>
        </Fragment>
    );
};

export default BottomPanel;
