import { schedulesByCompanyCondensed } from "./ScheduleQueries";
import { execReadBySortkeyFromTokenLimited } from "./DBService";

async function getCompanyWelcomeMessageState(companyData) {
    // company active/inactive
    // if active no active schedule, show the message
    // if inactive, show the message
    const welcomeMessageState = {
        hasActiveSchedule: false,
        companyIsActive: companyData.active
    };
    // read company's schedules if active
    let schResp = await execReadBySortkeyFromTokenLimited(
        {
            opname: "schedulesByCompany",
            op: schedulesByCompanyCondensed,
            id: { companyId: companyData.id },
            skey: { scheduleinfo: { beginsWith: `SC` } },
            filter: { active: { ne: false } },
            limit: 20
        },
        null,
        [],
        5
    );

    if (schResp && schResp.items) {
        for (let sch of schResp.items) {
            if (sch.provider.active)
                welcomeMessageState.hasActiveSchedule = true;
        }
    }

    return welcomeMessageState;
}

export { getCompanyWelcomeMessageState };
