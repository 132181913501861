import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "./AmplifyServices";
import {
    clientPackageByUser,
    getUser,
    getRefData,
    getCompany,
    getClientPackage
} from "../graphql/queries";
import {
    createClient,
    createBooking,
    createOrder,
    updateClientPackage,
    createBillingProgress,
    createClientPackage,
    createBookingRequest,
    createServiceType,
    createUISession,
    createRepeatApptCreation,
    updateProviderSchedule
} from "../graphql/mutations";
import {
    listPackages,
    listRegionalPricings,
    getCompanyLocation
} from "../graphql/queries";
import { bundledServicesByCompany } from "../queries/AdhocBookingQueries";
import { execWrite, execReadBySortkey } from "./DBService";
import moment from "moment";
import { getServiceTaxRate } from "./TaxService";
import {
    createTimeblock,
    getAWSDate,
    createProviderScheduleBk
} from "../modules/ScheduleService";
import { _handleSendEmail } from "../user/UserCommon";
import { ProviderBookingConfirmation } from "../utils/Common/ProviderBookingConfirmation";
import { TriggerManager } from "../modules/TriggerManager";

async function getUserInfo(userid) {
    const result = await graphql(graphqlOperation(getUser, { id: userid }));
    return result.data.getUser;
}

/**
 * Handles the creation of recurring bookings based on the provided booking state and order.
 *
 * @param {Object} bookingState - The booking state containing information about the booking.
 * @param {Object} order - The order associated with the booking.
 * @returns {Promise<Object>} - A promise resolving to the response from the recurringBookingsAPI.
 * @throws {Error} - Throws an error if there is an issue during the creation of recurring bookings.
 */
async function handleCreateRecurringBookings(bookingState, order) {
    try {
        let latitude;
        let longitude;
        if (
            bookingState.isRemoteLocation !== undefined &&
            bookingState.isRemoteLocation
        ) {
            latitude = bookingState.remoteAddressCoordinates.lat
                ? bookingState.remoteAddressCoordinates.lat
                : null;
            longitude = bookingState.remoteAddressCoordinates.lng
                ? bookingState.remoteAddressCoordinates.lng
                : null;
        } else {
            const companyLocation = await fetchCompanyLocation(
                bookingState.locationId
            );
            latitude = companyLocation.latitude;
            longitude = companyLocation.longitude;
        }

        let body = {
            companyId: bookingState.company.id,
            orderId: order.id,
            selectedSlot: bookingState.selectedslot,
            bookingData: {
                bookingBookedById: bookingState.bookedByUser.id,
                location: bookingState.location,
                locationId:
                    bookingState.locationId !== undefined &&
                    bookingState.locationId
                        ? bookingState.locationId
                        : "",
                serviceType: bookingState.serviceType,
                client: {
                    firstName: bookingState.client.user.firstname,
                    lastName: bookingState.client.user.lastname,
                    id: bookingState.client.id
                },
                provider: {
                    firstName: bookingState.provider.firstname,
                    lastName: bookingState.provider.lastname,
                    id: bookingState.provider.id
                },
                isVirtual:
                    bookingState.isVirtual !== undefined &&
                    bookingState.isVirtual
                        ? true
                        : false,
                isRemoteLocation:
                    bookingState.isRemoteLocation !== undefined &&
                    bookingState.isRemoteLocation
                        ? true
                        : false,
                remoteAddressCoordinates:
                    bookingState.isRemoteLocation !== undefined &&
                    bookingState.isRemoteLocation
                        ? {
                              lat: bookingState.remoteAddressCoordinates.lat,
                              lng: bookingState.remoteAddressCoordinates.lng
                          }
                        : "",
                dayCount: bookingState.dayCount,
                dayType: bookingState.dayType,
                daysOfWeek: bookingState.daysOfWeek,
                rrule: bookingState.rrule,
                manualBooking: bookingState.manualBooking || false,
                latitude,
                longitude
            }
        };
        if (bookingState.clientNotes) {
            body.bookingData.Notes = JSON.stringify([
                {
                    createdBy:
                        bookingState.bookedByUser.firstname +
                        " " +
                        bookingState.bookedByUser.lastname,
                    createdAt: new Date(),
                    notes: bookingState.clientNotes
                }
            ]);
        }

        let recurBookingsResponse = await postApi(
            "recurringBookingsApi",
            "/create-recurring-bookings",
            {
                body
            }
        );

        console.log("recurringbookigsAPI response data", recurBookingsResponse);
        return recurBookingsResponse.response;
    } catch (e) {
        console.log("error while creating recurring bookings", e);
    }
}

const handleOrderAndBookingCreation = async (bookingState, prps) => {
    let usedPkgIdForCredit = null;
    if (bookingState.boughtpackage)
        usedPkgIdForCredit = bookingState.newclientpkg.id;
    else if (bookingState.clientpackage) {
        usedPkgIdForCredit = bookingState.clientpackage.id;
    }

    let input = {
        ...{
            bookedByUser: bookingState.bookedByUser,
            uiSessionId: bookingState.uiSessionId,
            bookingTz: bookingState.bookingTz,
            clientNotes: bookingState.clientNotes,
            bookingAddress: getBookingAddress(bookingState),
            currency: bookingState.currency,
            acknowledgementDateTime: bookingState.acknowledgementDateTime,
            location: bookingState.location,
            bookingType: bookingState.sdt,
            chargeBreakup: bookingState.cbu,
            orderSummary: bookingState.osd,
            isVirtual: bookingState.isVirtual,
            providerInfo: bookingState.provider,
            heldSlots: bookingState.heldSlots,
            //    taxJurisdiction,
            userid: bookingState.client.user.username,
            providerid: bookingState.provider.id,
            scheduleid:
                !!bookingState.selectedslot &&
                !!bookingState.selectedslot.scheduleId &&
                bookingState.selectedslot.scheduleId,
            company: prps.company,
            servicetype: {
                id: bookingState.serviceType.id,
                desc: bookingState.serviceType.desc,
                name: bookingState.serviceType.name,
                price: bookingState.serviceType.price,
                minutes: bookingState.serviceType.minutes
            },
            booking: {
                date: new Date(bookingState.selectedslot.date),
                time: bookingState.selectedslot.label,
                slot12: bookingState.selectedslot.slot12,
                dateInfo: bookingState.selectedslot.dateInfo
            },
            usedPkgIdForCredit: usedPkgIdForCredit,
            ...(bookingState.repeatingAppointment && {
                hasrepeatingappt: bookingState.repeatingAppointment,
                repeatingapptinfo: {
                    apptlist: bookingState.repeatingApptList
                }
            })
        },
        bookingState: bookingState
    };

    let localBookingDetails = null;
    try {
        localBookingDetails = await createOrderAndBookings(input);
        if (
            localBookingDetails &&
            localBookingDetails.order &&
            localBookingDetails.order.id
        )
            await updateOrderStats(
                prps.company.id,
                localBookingDetails.order.total
            );
    } catch (err) {
        console.error("Confirm booking error => ", err);
    }
    return localBookingDetails;
};

async function updateOrderStats(companyId, orderTotal) {
    try {
        const company = await graphql(
            graphqlOperation(getCompany, {
                id: companyId
            })
        );
        if (company && company.data && company.data.getCompany) {
            let dbinfo;
            if (!company.data.getCompany.DashboardInfo) {
                dbinfo = {
                    orders_today: 1,
                    orders_mtd: 1,
                    orders_ytd: 1,
                    sales_today: orderTotal,
                    sales_mtd: orderTotal,
                    sales_ytd: orderTotal
                };
            } else {
                dbinfo = JSON.parse(company.data.getCompany.DashboardInfo);
                dbinfo.orders_today = dbinfo.orders_today
                    ? dbinfo.orders_today + 1
                    : 1;
                dbinfo.orders_mtd = dbinfo.orders_mtd
                    ? dbinfo.orders_mtd + 1
                    : 1;
                dbinfo.orders_ytd = dbinfo.orders_ytd
                    ? dbinfo.orders_ytd + 1
                    : 1;
                dbinfo.sales_today = dbinfo.sales_today
                    ? dbinfo.sales_today + orderTotal
                    : orderTotal;
                dbinfo.sales_mtd = dbinfo.sales_mtd
                    ? dbinfo.sales_mtd + orderTotal
                    : orderTotal;
                dbinfo.sales_ytd = dbinfo.sales_ytd
                    ? dbinfo.sales_ytd + orderTotal
                    : orderTotal;
            }

            const updateCompany = /* GraphQL */ `
                mutation UpdateCompany($input: UpdateCompanyInput!) {
                    updateCompany(input: $input) {
                        id
                        name
                        DashboardInfo
                    }
                }
            `;
            await execWrite({
                opname: "updateCompany",
                op: updateCompany,
                input: {
                    id: companyId,
                    DashboardInfo: JSON.stringify(dbinfo)
                }
            });
        }
    } catch (e) {
        console.log("error updating company stats");
    }
}

const createOrderAndBookings = async ({
    bookedByUser,
    uiSessionId,
    clientNotes,
    bookingAddress,
    currency,
    acknowledgementDateTime,
    bookingType,
    chargeBreakup,
    userid,
    providerid,
    scheduleid,
    company,
    servicetype,
    booking,
    location,
    hasrepeatingappt,
    repeatingapptinfo,
    heldSlots,
    usedPkgIdForCredit,
    isVirtual,
    providerInfo,
    orderSummary,
    bookingTz,
    bookingState
}) => {
    //get userInfo if virtual:
    let userInfo = null;
    if (isVirtual) userInfo = await getUserInfo(userid);
    //Create client
    let client = await getClientIfExists({
        userid,
        companyid: company.id
    });
    if (client && client.error) {
        return {
            error: client.error
        };
    }

    if (!client) {
        const clientData = {
            userId: userid,
            currency: currency, //company.currency ? company.currency : "CAD"
            companyId: company.id,
            clientUserId: userid,
            clientCompanyId: company.id,
            accountbalance: 0.0
        };
        client = await execWrite({
            opname: "createClient",
            op: createClient,
            input: clientData
        });
        if (client && client.error) {
            return {
                error: client.error
            };
        }
    }
    let orderType = "SINGLE";
    if (bookingType === "package") orderType = "PACKAGE";
    if (bookingType === "forever" || bookingState.repeatingAppointment) {
        orderType = "ONGOING";
    }
    //Create Order
    const orderNo = await getNewOrderNo(company.id);
    const orderData = {
        bookedByAdmin: true,
        clientnotes: clientNotes,
        orderNo,
        bookingAddress: JSON.stringify(bookingAddress), //is JSON
        currency,
        desc: prepareOrderDesc(servicetype, booking, bookingType), //servicetype.name,
        type: orderType,
        companyId: company.id,
        providerId: providerid,
        orderProviderId: providerid,
        subtotal: chargeBreakup.subtotal,
        servicechargeamt: chargeBreakup.servicechargeamt,
        taxamt: chargeBreakup.taxamt,
        total: chargeBreakup.total,
        status: "CONFIRMED",
        orderCompanyId: company.id,
        taxrate: chargeBreakup.taxrate,
        clientId: client.id,
        legaltermsAcceptedAt: acknowledgementDateTime,
        orderSummary: JSON.stringify(orderSummary),
        orderClientId: client.id,
        ...(usedPkgIdForCredit && {
            orderClientpackageId: usedPkgIdForCredit
        })
    };

    const order = await execWrite({
        opname: "createOrder",
        op: createOrder,
        input: orderData
    });
    // console.log("order:" + JSON.stringify(order));

    if (order && order.error) {
        return {
            error: order.error
        };
    }

    let firstBooking;
    let bookingsList = [];

    if (bookingState.repeatingAppointment) {
        //Create recurring booking using recurringBookingsAPI
        const newBookingsResponse = await handleCreateRecurringBookings(
            bookingState,
            order
        );
        //bookingsList.push(newBookingsResponse.newBookings);
        bookingsList = generateBookingsList(
            newBookingsResponse.newBookings,
            bookingState.repeatingApptList
        );
        firstBooking = newBookingsResponse.newBookings;
    } else {
        //Create Booking
        const bookingData = {
            desc: servicetype.name,
            startdate: booking.dateInfo.dtstamp_str,
            // startdate: getDateTimeWithOffset(
            //   `${getAWSDate(booking.date)}T${getBookingTime(booking.time)}:00`
            // ),
            minutes: servicetype.minutes,
            location: location,
            companyId: company.id,
            bookingOrderId: order.id,
            orderId: order.id,
            orderType: orderType,
            isVirtual: isVirtual,
            bookingServicetypeId: servicetype.id,
            bookingProviderId: providerid,
            bookingClientId: client.id,
            bookingCompanyId: company.id,
            timeblockid: `${heldSlots[0].id}`,
            providerId: providerid,
            clientId: client.id,
            status: "SCHEDULED",
            timezone: bookingTz,
            TimeDisplayInfo: JSON.stringify(booking.dateInfo),
            manualBooking: true,
            bookingBookedById: bookedByUser.id
        };

        try {
            if (clientNotes) {
                bookingData.Notes = JSON.stringify([
                    {
                        createdBy:
                            client.user.firstname + " " + client.user.lastname,
                        createdAt: order.createdAt,
                        notes: clientNotes
                    }
                ]);
            }
        } catch (e) {
            console.log("ERROR with adding Notes to bookingData", e);
        }

        const newbooking = await execWrite({
            opname: "createBooking",
            op: createBooking,
            input: bookingData
        });

        if (newbooking && newbooking.error) {
            return {
                error: newbooking.error
            };
        }

        bookingsList.push(newbooking);
        firstBooking = newbooking;
        prepareBookingDateTimeDisplayStrings(bookingsList);
    }

    //once the order is created and bookings are created,
    //update uisession as completed
    await createUISessionEntry({
        uiSessionId: uiSessionId,
        eventName: "COMPLETE:SYNCORDER",
        companyId: company.id
    });

    if (isVirtual) {
        const vmReq = {
            vendor: "zoom",
            action: "create_meetings",
            data: {
                companyId: company.id,
                companyName: company.name,
                startTime: [
                    {
                        bookingId: firstBooking.id,
                        startTime: firstBooking.startdate
                    }
                ],
                hostUserId: providerInfo.virtualMeetingUserId,
                hostFullName:
                    providerInfo.firstname + " " + providerInfo.lastname,
                hostEmailAddress: providerInfo.emailaddress,
                // timezone: "America/New_York",
                timezone: bookingTz,
                serviceDuration: servicetype.minutes,
                serviceTypeName: servicetype.name,
                participant: {
                    email: userInfo.emailaddress,
                    firstname: userInfo.firstname,
                    lastname: userInfo.lastname
                }
            }
        };
        try {
            const result = await postApi("virtualmeeting", "/meetings", {
                body: vmReq
            });

            if (result && result.success) {
                for (let vm of result.meetings) {
                    //for loop iterates only once... improve this code
                    await addVmInfoToBooking(firstBooking, vm);
                }
            }
            if (
                orderType === "ONGOING" ||
                (hasrepeatingappt && repeatingapptinfo.apptlist)
            )
                await postApi("virtualmeeting", "/meetings", {
                    body: {
                        action: "schedule_meeting_creation",
                        data: firstBooking
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    /* Zapier Trigger - New Order Created (new-order-zapier)*/
    try {
        let zapOrderData = {
            clientFirstName: client.user.firstname,
            clientLastName: client.user.lastname,
            clientEmail: client.user.emailaddress,
            clientPhoneNumber: client.user.mobilephone,
            providerFirstName: order.provider.firstname,
            providerLastName: order.provider.lastname,
            providerEmail: order.provider.emailaddress,
            orderNumber: order.orderNo,
            orderDescription: order.desc,
            orderAmount: order.total,
            orderCurrency: currency,
            orderType: "Single",
            numberOfBookings: bookingsList.length,
            service: bookingsList[0].desc,
            bookedBy: "Admin",
            createdAt: new Date(order.createdAt).toLocaleString("en-US")
        };
        let hookName = "new-order-zapier";
        await TriggerManager(zapOrderData, company.id, hookName);
    } catch (e) {
        console.log("ERROR: unable to perform triggerManager", e);
    }

    return {
        order,
        booking: firstBooking,
        bookingsList,
        client
    };
};

function prepareBookingDateTimeDisplayStrings(bookingsList) {
    if (bookingsList && bookingsList.length) {
        for (let bk of bookingsList) {
            bk.TimeDisplayInfo = JSON.parse(bk.TimeDisplayInfo);
        }
    }
}

async function getNewOrderNo(companyId) {
    const ordNoResp = await getJsonApi("bookingapi", "/id", {
        queryParams: {
            companyId,
            idName: "ORDERNO"
        }
    });
    if (ordNoResp && ordNoResp.success) return ordNoResp.id;
    else {
        return Number(new String(Date.now()).slice(-8));
    }
}

/**
 * Generates a new array of booking objects based on the given bookingObject
 * and repeatApptList. The first object in the new array is the original
 * bookingObject, and subsequent objects are based on the repeatApptList with
 * specific attributes updated in the TimeDisplayInfo.
 *
 * @param {Object} bookingObject - The original booking object.
 * @param {Array} repeatApptList - An array of dateTimeObjects for different days.
 * @returns {Array} An array of booking objects with updated attributes.
 */
function generateBookingsList(bookingObject, repeatApptList) {
    // Initialize the new array
    let bookingsList = [];

    // Iterate over each dateTimeObject in repeatApptList
    for (const dateTimeObject of repeatApptList) {
        // Clone the bookingObject to avoid modifying the original object
        let newBooking = {
            provider: bookingObject.provider,
            TimeDisplayInfo: JSON.parse(
                JSON.stringify(bookingObject.TimeDisplayInfo)
            ),
            isVirtual: bookingObject.isVirtual,
            location: bookingObject.location
        };

        // Update attributes in TimeDisplayInfo
        newBooking.TimeDisplayInfo.dt_disp = dateTimeObject.dt_disp;
        newBooking.TimeDisplayInfo.dtstamp_str = dateTimeObject.dtstamp_str;
        newBooking.TimeDisplayInfo.dt_long_disp = dateTimeObject.dt_long_disp;
        newBooking.TimeDisplayInfo.dt_full_disp =
            dateTimeObject.partial_dt_full_disp;
        newBooking.TimeDisplayInfo.en_slot_disp =
            dateTimeObject.partial_en_slot_disp;

        // Push the modified object to the new array
        bookingsList.push(newBooking);
    }

    // Replace the first object in bookingsList with bookingObject
    bookingsList[0] = bookingObject;

    return bookingsList;
}

function getBookingAddress(bookingState) {
    let bookingAddress = {};
    if (bookingState.appointmentLocation === "remote") {
        bookingAddress.addrOneLine = bookingState.location;
        bookingAddress.state = bookingState.remoteAddressState;
        bookingAddress.countryCode = bookingState.remoteAddressCountryShort;
        bookingAddress.postalCode = bookingState.remoteAddressPostalCode;
        bookingAddress.country = bookingState.remoteAddressCountry;
    } else {
        bookingAddress.addrOneLine = bookingState.location;
        bookingAddress.state = bookingState.province;
        bookingAddress.countryCode = bookingState.countryShort;
        bookingAddress.postalCode = bookingState.postalCode;
        bookingAddress.country = bookingState.country;
    }
    return bookingAddress;
}

const createClientRecord = async (user, company) => {
    const clientData = {
        userId: user.id,
        currency: company.currency ? company.currency : "CAD",
        companyId: company.id,
        clientUserId: user.id,
        clientCompanyId: company.id,
        accountbalance: 0.0
    };

    let client = await execWrite({
        opname: "createClient",
        op: createClient,
        input: clientData
    });

    if (client && client.error) {
        return {
            error: client.error
        };
    } else return client;
};

const holdSlotsForAppointments = async ({
    uiSessionId,
    sdt,
    scheduleid,
    company,
    servicetype,
    booking,
    hasrepeatingappt,
    repeatingapptinfo,
    geoLoc,
    locationId
}) => {
    console.log("holdSlotsForAppointments :scheduleid:" + scheduleid);
    const returnTimeblockList = [];
    // Create Timeblock
    if (booking) {
        const tbendtime = getTimeblockEndTime(servicetype, booking);
        let bookedtimeblockData = {
            companyId: company.id,
            startDate: getAWSDate(booking.date),
            startTime: booking.time,
            endTime: tbendtime,
            type: "BOOKED",
            scheduleId: scheduleid,
            status: "PENDING",
            tz: booking.dateInfo.tz,
            sdtutc: booking.dateInfo.dtstamp_str,
            locationId,
            latitude: geoLoc.lat ? geoLoc.lat : null,
            longitude: geoLoc.lng ? geoLoc.lng : null
        };
        const tb = await createProviderScheduleBk(bookedtimeblockData);
        returnTimeblockList.push(tb);
    }
    if (hasrepeatingappt && repeatingapptinfo.apptlist) {
        for (const apptDT of repeatingapptinfo.apptlist) {
            // Create Timeblock
            const tbendtime = getTimeblockEndTimeOfAppt(servicetype, apptDT);

            let bookedtimeblockData = {
                companyId: company.id,
                startDate: getAWSDate(apptDT),
                startTime: getBookingTimeOfAppt(apptDT),
                endTime: tbendtime,
                type: "BOOKED",
                scheduleId: scheduleid,
                status: "PENDING", //TODO: add tz, sdtutc when we implement repeating bookings creation manually
                locationId,
                latitude: geoLoc.lat ? geoLoc.lat : null,
                longitude: geoLoc.lng ? geoLoc.lng : null
            };
            const tb = await createProviderScheduleBk(bookedtimeblockData);
            returnTimeblockList.push({ ...tb, date: apptDT });
        }
    }

    try {
        const result = await postApi("bookingapi", "/pendingslotstracking", {
            body: {
                heldSlots: returnTimeblockList,
                uiSessionId: uiSessionId,
                companyId: company.id
            }
        });

        if (result && result.success) {
            console.log("tracking slots");
        }
    } catch (e) {
        console.log(" queueing heldslots for tracking error", e);
    }

    return {
        heldSlots: returnTimeblockList
    };
};

const calculateChargeBreakup = (
    basePrice,
    company,
    { countryCode, stateCode },
    isPkgCredit,
    bookingState,
    hasOverride,
    overrideServicePrice,
    overrideServiceFee,
    taxOverride
) => {
    if (hasOverride && overrideServicePrice !== null) {
        basePrice = Number.parseFloat(
            overrideServicePrice ? overrideServicePrice : 0
        );
    }
    let taxableAmount = totalTaxableAmount(
        bookingState,
        hasOverride,
        overrideServicePrice
    );

    let taxRate =
        !isNaN(parseFloat(taxOverride)) && isFinite(taxOverride)
            ? taxOverride
            : getServiceTaxRate(countryCode, stateCode);
    let taxAmount = (taxableAmount * taxRate) / 100;

    const charge = {
        subtotal: Number.parseFloat(basePrice),
        taxableamt: Number.parseFloat(taxableAmount),
        servicechargeamt: 0.0,
        total: 0.0,
        taxrate: taxRate
    };

    if (
        countryCode.toUpperCase() !== "US" &&
        countryCode.toUpperCase() !== "AU" &&
        countryCode.toUpperCase() !== "NZ" &&
        countryCode.toUpperCase() !== "GB" &&
        charge.taxrate === 0
    ) {
        //  "Changing unknown local taxrate to company default taxrate"
        charge.taxrate = company.taxrate;
    }

    if (company.addServiceFee && !isPkgCredit) {
        if (hasOverride && overrideServiceFee !== null) {
            charge.servicechargeamt = Number.parseFloat(
                overrideServiceFee ? overrideServiceFee : 0
            );
        } else {
            if (company.serviceFeeType === "PERCENTAGE") {
                charge.servicechargeamt = Number.parseFloat(
                    (basePrice * company.serviceFeeAmount) / 100
                );
            } else {
                charge.servicechargeamt = Number.parseFloat(
                    company.serviceFeeAmount
                );
            }
        }
        // Service fees taxation jusrestiction based logic
        // Assume no cross border orders
        if (countryCode.toUpperCase() === "CA" && charge.servicechargeamt > 0) {
            taxableAmount += charge.servicechargeamt;
            taxAmount += charge.servicechargeamt * (charge.taxrate / 100);
        }
    }

    charge.taxableamt = taxableAmount;
    charge.taxamt = taxAmount;
    charge.total = charge.subtotal + charge.servicechargeamt + charge.taxamt;
    return charge;
};

const updateClientPackageToPaid = async (pkg, usedQuantity) => {
    const updCliPkg = /* GraphQL */ `
        mutation UpdateClientPackage($input: UpdateClientPackageInput!) {
            updateClientPackage(input: $input) {
                id
            }
        }
    `;

    return await execWrite({
        opname: "updateClientPackage",
        op: updCliPkg,
        input: {
            id: pkg.id,
            status: "paid",
            usedQuantity,
            servicetypeId: pkg.servicetypeId,
            packageId: pkg.packageId,
            createdAt: pkg.createdAt
        }
    });
};

function prepareOrderDesc(servicetype, booking, bookingType) {
    //For single booking.
    //TBD: For repeated booking.
    if (bookingType === "forever") {
        return `Repeating bookings for ${servicetype.name} starting from ${booking.dateInfo.dt_full_disp}`;
    } else if (bookingType === "package") {
        return `Package booking for ${servicetype.name}`;
    } else
        return `Booking for ${servicetype.name} on ${booking.dateInfo.dt_full_disp} `;
}

function getTimeblockEndTime(servicetype, booking) {
    const endtime = new Date();
    const parts = booking.time.split(":");
    endtime.setHours(Number.parseInt(parts[0]));
    endtime.setMinutes(servicetype.minutes + Number.parseInt(parts[1]));
    return `${
        endtime.getHours() < 10 ? "0" + endtime.getHours() : endtime.getHours()
    }:${
        endtime.getMinutes() < 10
            ? "0" + endtime.getMinutes()
            : endtime.getMinutes()
    }`;
}

function getTimeblockEndTimeOfAppt(servicetype, apptDatetime) {
    const endtime = new Date(apptDatetime.getTime());
    endtime.setMinutes(servicetype.minutes + endtime.getMinutes());
    return `${
        endtime.getHours() < 10 ? "0" + endtime.getHours() : endtime.getHours()
    }:${
        endtime.getMinutes() < 10
            ? "0" + endtime.getMinutes()
            : endtime.getMinutes()
    }`;
}

function getBookingTimeOfAppt(apptDatetime) {
    return `${
        apptDatetime.getHours() < 10
            ? "0" + apptDatetime.getHours()
            : apptDatetime.getHours()
    }:${
        apptDatetime.getMinutes() < 10
            ? "0" + apptDatetime.getMinutes()
            : apptDatetime.getMinutes()
    }`;
}

async function getClientIfExists(cd) {
    const ClientByUserId = /* GraphQL */ `
        query ClientByUserId(
            $userId: String
            $id: ModelIDKeyConditionInput
            $sortDirection: ModelSortDirection
            $filter: ModelClientFilterInput
            $limit: Int
            $nextToken: String
        ) {
            clientByUserId(
                userId: $userId
                id: $id
                sortDirection: $sortDirection
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                    id
                    userId
                    companyId
                    stripeCustomerId
                    defaultpartialcc
                    user {
                        id
                        username
                        emailaddress
                        firstname
                        lastname
                        homephone
                        workphone
                        mobilephone
                        phonepref
                    }
                }
                nextToken
            }
        }
    `;

    const response = await graphql(
        graphqlOperation(ClientByUserId, {
            userId: cd.userid,
            filter: { companyId: { eq: cd.companyid } }
        })
    );
    let client =
        response.data.clientByUserId.items &&
        response.data.clientByUserId.items[0];

    return client;
}

const getPaidPackages = async ({ userid, servicetypeid }) => {
    const filter = {
        and: [{ active: { ne: false } }, { status: { eq: "paid" } }]
    };

    const listClientPackagesData = await execReadBySortkey({
        op: clientPackageByUser,
        opname: "clientPackageByUser",
        id: { userId: userid },
        skey: {
            servicetypeIdPackageIdCreatedAt: {
                beginsWith: { servicetypeId: servicetypeid }
            }
        },
        filter,
        limit: 10
    });
    if (listClientPackagesData.error) {
        console.log(
            "error while getting paid packages",
            listClientPackagesData.error
        );
        return [];
    }
    return listClientPackagesData.items
        ? listClientPackagesData.items.filter(
              (cp) => cp.initialQuantity > cp.usedQuantity
          )
        : [];
};

const updateUsedQuantityOfPackage = async ({ pkg, increaseby }) => {
    //should we check if usedQuantity will be greater than initial quantity
    const input = {
        id: pkg.id,
        servicetypeId: pkg.servicetypeId,
        packageId: pkg.packageId,
        createdAt: pkg.createdAt,
        usedQuantity: pkg.usedQuantity + increaseby
    };
    const response = await execWrite({
        opname: "updateClientPackage",
        op: updateClientPackage,
        input
    });
    return response;
};

const updateOrderStatusToPaidByPkgCredit = async (orderid) => {
    return await updateOrderStatus(
        orderid,
        "PAIDBYPKGCRE",
        {
            subtotal: 0.0,
            servicechargeamt: 0.0,
            total: 0.0,
            taxamt: 0.0
        },
        null
    );
};

const updateOrderStatusToConfirmed = async (
    orderid,
    chargeBreakup,
    receipt
) => {
    return await updateOrderStatus(
        orderid,
        "CONFIRMED",
        chargeBreakup,
        receipt
    );
};
const updateOrderStatusToPaid = async (orderid, chargeBreakup, receipt) => {
    return await updateOrderStatus(orderid, "PAID", chargeBreakup, receipt);
};
const updateOrderStatusToClosed = async (orderid) => {
    return await updateOrderStatus(orderid, "CLOSED", null, null);
};
const updateOrderStatus = async (orderid, status, chargeBreakup, receipt) => {
    const updOrder = `mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
  `;
    console.log(
        "chargeBreakup in updateOrderStatus" + JSON.stringify(chargeBreakup)
    );
    return await execWrite({
        opname: "updateOrder",
        op: updOrder,
        input: {
            id: orderid,
            status,
            ...(receipt
                ? {
                      orderReceipt: receipt
                  }
                : {}),
            ...(chargeBreakup
                ? {
                      subtotal: chargeBreakup.subtotal,
                      total: chargeBreakup.total,
                      servicechargeamt: chargeBreakup.servicechargeamt,
                      taxamt: chargeBreakup.taxamt
                  }
                : {})
        }
    });
};

const updateClientBalanceTo = async (clientId, balance) => {
    const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
    }
  }`;
    return await execWrite({
        opname: "updateClient",
        op: updateClient,
        input: {
            id: clientId,
            accountbalance: balance
        }
    });
};

const addVmInfoToBooking = async (firstBooking, vminfo) => {
    const updBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
    updateBooking(input: $input) {
      id
    }
  }`;
    const bkgresp = await execWrite({
        opname: "updateBooking",
        op: updBooking,
        input: {
            id: firstBooking.id,
            startdate: firstBooking.startdate,
            timeblockid: firstBooking.timeblockid,
            virtualMeetingInfo: JSON.stringify(vminfo)
        }
    });

    return bkgresp;
};

const cancelBooking = async (booking) => {
    const updBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    id
    status
    timeblockid
    providerId
    clientId
    companyId
  }
}`;
    const bkgresp = await execWrite({
        opname: "updateBooking",
        op: updBooking,
        input: {
            id: booking.id,
            status: "CANCELLED",
            cancelledAt: booking.cancelledAt,
            bookingCancelledById: booking.cancelledBy
        }
    });
    //console.log("Cancel bkgresp: " + JSON.stringify(bkgresp));

    if (bkgresp && bkgresp.timeblockid) {
        const tbpksk = bkgresp.timeblockid.split("::");
        const updatetb = `mutation UpdateProviderSchedule($input: UpdateProviderScheduleInput!) {
      updateProviderSchedule(input: $input) {
        id
        scheduleinfo
        status
      }
    }`;
        await execWrite({
            opname: "updateProviderSchedule",
            op: updatetb,
            input: {
                id: tbpksk[0],
                scheduleinfo: tbpksk[1],
                status: "CANCELLED"
            }
        });
    }
    return bkgresp;
};

const saveNoshowBooking = async (booking) => {
    const updBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    id
    status
  }
}`;
    return await execWrite({
        opname: "updateBooking",
        op: updBooking,
        input: {
            id: booking.id,
            status: "NOSHOW",
            noshowAt: booking.noshowAt,
            bookingNoshowById: booking.noshowBy
        }
    });
};

const saveBillingProgress = async (input) => {
    const {
        currency,
        company,
        order,
        booking,
        client,
        user,
        serviceType,
        chargeBreakup
    } = input;
    const ts = getCurrentDate();
    const jsonData = JSON.stringify({
        companyId: company.id,
        orderId: order.id,
        bookingId: booking.id,
        userId: user.id,
        userEmailAddress: user.emailaddress,
        currency: currency,
        stripeCustomerId: client.stripeCustomerId,
        clientId: client.id,
        serviceTypeName: serviceType.name,
        bookingTimeblockId: booking.timeblockid,
        appointmentDate: booking.startdate,
        timestamp: ts,
        chargeBreakup
    });

    const bpData = {
        companyId: company.id,
        dateTime: new Date(),
        status: "QUEUED",
        data: jsonData
    };

    try {
        const bp = await execWrite({
            opname: "createBillingProgress",
            op: createBillingProgress,
            input: bpData
        });

        if (bp && bp.error) {
            return {
                error: bp.error,
                success: false
            };
        } else {
            return { success: true };
        }
    } catch (e) {
        return { success: false, error: e };
    }
};
const getCurrentDate = () => {
    return moment.utc().format("YYYY-MM-DDTHH-mm-ss.SSS");
};

const updateHeldSlotsToConfirmed = async (heldSlots) => {
    for (let hs of heldSlots) {
        const tbpksk = hs.id.split("::");
        await execWrite({
            opname: "updateProviderSchedule",
            op: updateProviderSchedule,
            input: {
                id: tbpksk[0],
                scheduleinfo: tbpksk[1],
                status: "CONFIRMED"
            }
        });
    }
};

const deleteHeldSlots = async (heldSlots) => {
    for (let hs of heldSlots) {
        const tbpksk = hs.id.split("::");
        const deletetb = /* GraphQL */ `
            mutation DeleteProviderSchedule(
                $input: DeleteProviderScheduleInput!
            ) {
                deleteProviderSchedule(input: $input) {
                    id
                    scheduleinfo
                    status
                }
            }
        `;
        await execWrite({
            opname: "deleteProviderSchedule",
            op: deletetb,
            input: {
                id: tbpksk[0],
                scheduleinfo: tbpksk[1]
            }
        });
    }
};

async function sendProviderBookingConfirmation({
    provider,
    client,
    company,
    serviceType,
    bookings,
    orderNo,
    orderType,
    orderNotes,
    wdDisplay
}) {
    const providerConfirmation = await ProviderBookingConfirmation({
        provider,
        client,
        company,
        serviceType,
        bookings,
        orderNo,
        orderType,
        orderNotes,
        wdDisplay
    });
    if (providerConfirmation.length > 0)
        await _handleSendEmail(
            `You have received a new booking from ${company.name}: Ref No. ${orderNo}`,
            providerConfirmation,
            [provider.emailaddress],
            [],
            [],
            company.replyemailaddress,
            company.name
        );
}

async function sendOrderReceipt(orderId) {
    try {
        await postApi("bookingapi", "/booking/receipt-email", {
            body: { orderId }
        });
    } catch (e) {
        console.log("ERROR generating receipt email", e);
    }
}

async function saveClientPackage(user, bookingState) {
    try {
        const pkg = bookingState.boughtpackage;

        let input = {
            userId: user.username,
            clientPackageUserId: user.username,
            clientPackageServicetypeId: bookingState.serviceType.id,
            clientId: bookingState.client.id,
            clientPackageClientId: bookingState.client.id,
            servicetypeId: bookingState.serviceType.id,
            clientPackagePackageId: pkg.id,
            packageId: pkg.id,
            initialQuantity: pkg.quantity,
            usedQuantity: 0,
            status: "pending",
            active: true,
            createdAt: new Date().toISOString()
        };
        const result = await graphql(
            graphqlOperation(createClientPackage, { input })
        );

        return {
            success: true,
            pkg: result.data.createClientPackage
        };
    } catch (err) {
        console.log("Create package error");
        return {
            success: false
        };
    }
}

async function fetchPackages(companyId, serviceTypeId) {
    // check for available active packages for this company and service type
    let filter = {
        and: [
            { active: { ne: false } },
            { companyId: { eq: companyId } },
            { servicetypeId: { eq: serviceTypeId } },
            { deleted: { ne: true } }
        ]
    };

    const listPackagesData = await graphql(
        graphqlOperation(listPackages, { filter, limit: 500 })
    );
    if (
        listPackagesData.data.listPackages.items &&
        listPackagesData.data.listPackages.items.length > 0
    ) {
        listPackagesData.data.listPackages.items.sort(
            (p1, p2) => p1.quantity - p2.quantity
        );
    }
    return listPackagesData.data.listPackages.items;
}

async function fetchRegionalPricing(companyId, serviceTypeId) {
    // check for available regional pricing for this company and service type
    let filter = {
        and: [
            { active: { ne: false } },
            { companyId: { eq: companyId } },
            { servicetypeId: { eq: serviceTypeId } },
            { deleted: { ne: true } }
        ]
    };

    const listRegionalPricingData = await graphql(
        graphqlOperation(listRegionalPricings, { filter, limit: 500 })
    );
    return listRegionalPricingData.data.listRegionalPricings.items;
}

// gets correct price based on regional pricing
async function getPrice(bookingState, serviceType) {
    const price = serviceType.price;

    // virtual appts have no regional pricing
    if (bookingState.isVirtual) return price;

    const regpri = await fetchRegionalPricing(
        bookingState.company.id,
        serviceType.id
    );

    let country = bookingState.isRemoteLocation
        ? bookingState.remoteAddressCountryShort
        : bookingState.countryShort;
    let prov = bookingState.isRemoteLocation
        ? bookingState.remoteAddressState
        : bookingState.province;
    let postalCode = bookingState.isRemoteLocation
        ? bookingState.remoteAddressPostalCode
        : bookingState.postalCode;

    // filters regional pricing into separate arrays by type
    let countryRegPri = regpri.filter((o) => o.pricingtype === "COUNTRY");
    let provRegPri = regpri.filter((o) => o.pricingtype === "PROVINCE");
    let postalRegPri = regpri.filter((o) => o.pricingtype === "POSTALCODE");
    // further filters through postalcode regional pricing array to find matching postal code
    if (postalCode)
        postalRegPri = postalRegPri.filter((o) =>
            o.postalcodes.find(
                (p) =>
                    p.replace(/\s+/g, "").toUpperCase() ===
                    postalCode.replace(/\s+/g, "").toUpperCase()
            )
        );

    // if there is postalcode regional pricing and it matches the booking postal code, choose that price
    if (postalCode && postalRegPri.length) {
        return postalRegPri[0].price;
    }
    // if there is province regional pricing and it matches the booking province, choose that price
    else if (
        provRegPri.length &&
        provRegPri.find((o) => o.province.trim() === prov)
    ) {
        return provRegPri.find((o) => o.province.trim() === prov).price;
    }
    // if there is country regional pricing and it matches the booking country, choose that price
    else if (
        countryRegPri.length &&
        countryRegPri.find((o) => o.country.trim() === country)
    ) {
        return countryRegPri.find((o) => o.country.trim() === country).price;
    }
    // if no regional pricing, stick with original service price
    else {
        return price;
    }
}

// populates any description fields with the proper price per session values
function getDescription(val, price, currency) {
    // note that dollar packages use val.price, while percentage packages. use passed in price value
    if (!val.desc.includes("$[price]") && !val.desc.includes("[price]"))
        return val.desc;
    else if (val.packagetype === "DOLLAR") {
        return val.desc
            .replace(/\$/gi, "")
            .replace(
                "[price]",
                `${getCurrencySymbol(currency)}${(
                    val.price / val.quantity
                ).toFixed(2)}`
            );
    } else if (val.packagetype === "PERCENTAGE") {
        let pricePerSession =
            (val.quantity * price * (1 - val.discount / 100)) / val.quantity;
        return val.desc
            .replace(/\$/gi, "")
            .replace(
                "[price]",
                `${getCurrencySymbol(currency)}${pricePerSession.toFixed(2)}`
            );
    } else {
        return "PACKAGE TYPE MISSING";
    }
}

async function fetchCompanyLocation(locationId) {
    const getCompanyLocationData = await graphql(
        graphqlOperation(getCompanyLocation, { id: locationId })
    );
    return getCompanyLocationData.data.getCompanyLocation;
}

function getStripeAmount(num) {
    let num_check = (parseFloat(num) * 100).toString();
    const decimal_pos = num_check.indexOf(".");
    if (decimal_pos === -1) {
        return parseInt(num_check);
    } else {
        const rounded_num = num_check.charAt(decimal_pos + 1);
        if (parseInt(rounded_num) >= 5) {
            return parseInt(num_check) + 1;
        } else if (parseInt(rounded_num) < 5) {
            return parseInt(num_check);
        }
    }
}

const saveBookingRequest = async (bookingState, client) => {
    // Read client:
    if (!client) {
        let clientRecord = await getClientIfExists({
            userid: bookingState.client.user.username,
            companyid: bookingState.company.id
        });
        client = clientRecord;
    }
    let savedNotDisplayedProviderData = [];
    let savedDisplayedProviderData = [];
    if (
        bookingState.savedNotDisplayedProviderData &&
        bookingState.savedNotDisplayedProviderData.length > 0
    ) {
        let usethese = [];
        if (bookingState.savedNotDisplayedProviderData.length > 10) {
            usethese = bookingState.savedNotDisplayedProviderData.slice(0, 10);
        } else usethese = bookingState.savedNotDisplayedProviderData;
        savedNotDisplayedProviderData = usethese.map((p) => {
            return {
                id: p.id,
                firstname: p.firstname,
                lastname: p.lastname,
                active: p.active,
                maxtraveltype: p.maxtraveltype,
                emailaddress: p.emailaddress,
                phone: p.phone,
                pictures3key: p.pictures3key,
                virtualMeetingUserId: p.virtualMeetingUserId,
                vmlink: p.vmlink,
                schedule: {
                    ...p.schedule
                },
                SBs: p.SBs.map((sb) => {
                    return {
                        startDate: sb.startDate,
                        endDate: sb.endDate,
                        startTime: sb.startTime,
                        endTime: sb.endTime,
                        weekDays: sb.weekDays
                    };
                })
            };
        });
    }

    if (
        bookingState.savedDisplayedProviderData &&
        bookingState.savedDisplayedProviderData.length > 0
    ) {
        let usethese = [];
        if (bookingState.savedDisplayedProviderData.length > 10) {
            usethese = bookingState.savedDisplayedProviderData.slice(0, 10);
        } else usethese = bookingState.savedDisplayedProviderData;

        savedDisplayedProviderData = usethese.map((p) => {
            return {
                id: p.id,
                firstname: p.firstname,
                lastname: p.lastname,
                active: p.active,
                maxtraveltype: p.maxtraveltype,
                emailaddress: p.emailaddress,
                phone: p.phone,
                pictures3key: p.pictures3key,
                virtualMeetingUserId: p.virtualMeetingUserId,
                vmlink: p.vmlink,
                schedule: {
                    ...p.schedule
                },
                SBs: p.SBs.map((sb) => {
                    return {
                        startDate: sb.startDate,
                        endDate: sb.endDate,
                        startTime: sb.startTime,
                        endTime: sb.endTime,
                        weekDays: sb.weekDays
                    };
                })
            };
        });
    }

    let br = {
        geoLoc: bookingState.isRemoteLocation
            ? bookingState.remoteAddressCoordinates
            : null,
        bookingTz: bookingState.bookingTz,
        clientNotes: bookingState.clientNotes,
        //orderNo,
        bookingAddress: getBookingAddress(bookingState),
        location: bookingState.location,
        tryOtherProviders: bookingState.tryOtherProviders,
        currency: bookingState.currency,
        client,
        dir: {
            notdisplayed: savedNotDisplayedProviderData,
            displayed: savedDisplayedProviderData
        },
        companyId: bookingState.company.id,
        serviceType: {
            id: bookingState.serviceType.id,
            name: bookingState.serviceType.name,
            price: bookingState.serviceType.price,
            minutes: bookingState.serviceType.minutes
        },
        user: client.user,
        address: bookingState.address,
        isVirtual: bookingState.isVirtual,
        isRemoteLocation: bookingState.isRemoteLocation,
        appointmentLocation: bookingState.appointmentLocation,
        datefilter: bookingState.datefilter,
        provider: {
            id: bookingState.provider.id,
            emailaddress: bookingState.provider.emailaddress,
            firstname: bookingState.provider.firstname,
            lastname: bookingState.provider.lastname,
            phone: bookingState.provider.phone,
            pictures3key: bookingState.provider.pictures3key,
            virtualMeetingUserId: bookingState.provider.virtualMeetingUserId,
            vmlink: bookingState.provider.vmlink,
            schedule: {
                ...bookingState.provider.schedule
            },
            SBs: bookingState.provider.SBs.map((sb) => {
                return {
                    startDate: sb.startDate,
                    endDate: sb.endDate,
                    startTime: sb.startTime,
                    endTime: sb.endTime,
                    weekDays: sb.weekDays
                };
            })
        },
        origProvider: {
            id: bookingState.provider.id,
            emailaddress: bookingState.provider.emailaddress,
            firstname: bookingState.provider.firstname,
            lastname: bookingState.provider.lastname,
            phone: bookingState.provider.phone,
            virtualMeetingUserId: bookingState.provider.virtualMeetingUserId,
            vmlink: bookingState.provider.vmlink
        },
        selectedslot: {
            ...bookingState.selectedslot,
            day: bookingState.selectedslot.date.getDay(),
            tz: bookingState.bookingTz
        },
        dayCount: bookingState.dayCount,
        dayType: bookingState.dayType,
        daysOfWeek: bookingState.daysOfWeek,
        repeatingAppointment: bookingState.repeatingAppointment,
        repeatingApptList: bookingState.repeatingApptList,
        newclientpkgid: bookingState.boughtpackage
            ? bookingState.newclientpkg.id
            : "",
        boughtpackage: bookingState.boughtpackage,
        clientpackage: bookingState.clientpackage,
        sdt: bookingState.sdt,
        selectedScheduleId: bookingState.selectedScheduleId,
        osd: bookingState.osd,
        cbu: bookingState.cbu,
        heldSlots: bookingState.heldSlots
            ? bookingState.heldSlots.map((hs) => {
                  return {
                      id: hs.id,
                      scheduleinfo: hs.scheduleinfo,
                      startDate: hs.startDate,
                      endDate: hs.endDate,
                      startTime: hs.startTime,
                      endTime: hs.endTime,
                      createdAt: hs.createdAt
                  };
              })
            : [],
        serviceLocation: bookingState.serviceLocation,
        company: getOnlyNeededFields(bookingState.company),
        lastForeverApptDate: bookingState.lastForeverApptDate
            ? getAWSDate(new Date(bookingState.lastForeverApptDate))
            : "", //in YYYY-MM-DD so that lambda does not have to do this conversion
        wdDisplay: bookingState.wdDisplay
    };

    const id = `CL-${client.id}`;

    const data = JSON.stringify(br);

    const brData = {
        id,
        recordType: `REQ|${Date.now()}`,
        providerId: bookingState.provider.id,
        accepted: false,
        status: "REQUESTED",
        data
    };

    try {
        const br = await execWrite({
            opname: "createBookingRequest",
            op: createBookingRequest,
            input: brData
        });

        if (br && br.error) {
            return {
                error: br.error,
                success: false
            };
        } else {
            await createUISessionEntry({
                uiSessionId: bookingState.uiSessionId,
                eventName: "COMPLETE:BR",
                companyId: bookingState.company.id
            });
            return { success: true };
        }
    } catch (e) {
        return { success: false, error: e };
    }
};

function getOnlyNeededFields(company) {
    return {
        id: company.id,
        name: company.name,
        contactname: company.contactname,
        emailaddress: company.emailaddress,
        replyemailaddress: company.replyemailaddress,
        currency: company.currency,
        currencyBasedOnLocation: company.currencyBasedOnLocation,
        addressoneline: company.addressoneline,
        street: company.street,
        city: company.city,
        state: company.state,
        country: company.country,
        postalcode: company.postalcode,
        longitude: company.longitude,
        latitude: company.latitude,
        ApptAcceptanceFlowConfig: company.ApptAcceptanceFlowConfig,
        subdomain: company.subdomain,
        tagline: company.tagline,
        logoUrl: company.logoUrl,
        billingMode: company.billingMode,
        iframeURL: company.iframeURL,
        primaryColor: company.primaryColor,
        addServiceFee: company.addServiceFee,
        serviceFeeType: company.serviceFeeType,
        serviceFeeAmount: company.serviceFeeAmount,
        taxrate: company.taxrate,
        travelTime: company.travelTime,
        countrycode3166alpha2: company.countrycode3166alpha2,
        publishableStripeKey: company.publishableStripeKey,
        offersForeverAppt: company.offersForeverAppt,
        virtualMeetingConfig: company.virtualMeetingConfig,
        BccLists: getOnlyForOrdSum(company.BccLists)
    };
}

function getOnlyForOrdSum(bccListsJson) {
    let BccLists = { forOrdSum: [] };
    if (bccListsJson && bccListsJson.length) {
        let bccListsObj = JSON.parse(bccListsJson);
        if (bccListsObj && bccListsObj.forOrdSum)
            BccLists.forOrdSum = bccListsObj.forOrdSum;
    }
    return BccLists;
}

async function readCurrency(countryCode) {
    const result = await graphql(
        graphqlOperation(getRefData, {
            refType: "CURRENCY",
            refName: countryCode
        })
    );
    let val = result.data.getRefData ? result.data.getRefData.refValue : null;

    return val;
}
async function getCurrency(bookingState) {
    console.log("getCurrency Got Called", bookingState);
    if (bookingState.company.currencyBasedOnLocation) {
        let countryCode;
        if (bookingState.appointmentLocation === "remote") {
            countryCode = bookingState.remoteAddressCountryShort;
        } else {
            countryCode = bookingState.countryShort;
        }

        let currency = await readCurrency(countryCode);
        if (currency) return currency;
        else return bookingState.company.currency;
    } else return bookingState.company.currency;
}

const getCurrencySymbol = (bookingState) => {
    const companyCurrency = bookingState.currency;
    switch (companyCurrency) {
        case "GBP":
            return "£";
        case "CAD":
            return "$";
        case "USD":
            return "$";
        case "AUD":
            return "$";
        default:
            return "$";
    }
};

const getOrderTrackingData = (order, bookingState, company) => {
    let {
        providerId,
        firstname,
        lastname,
        emailaddress,
        maxtraveltype,
        offersVirtualServices
    } = bookingState.provider;
    let { id, name, categoryId, categoryName, price, minutes } =
        bookingState.serviceType;
    return {
        companyId: company.id,
        companyName: company.name,
        provider: {
            providerId,
            firstname,
            lastname,
            emailaddress,
            maxtraveltype,
            offersVirtualServices
        },
        serviceType: { id, name, categoryId, categoryName, price, minutes },
        location: bookingState.location,
        bookingAddress: order.bookingAddress,
        orderSummary: bookingState.osd,
        orderId: order.id,
        orderType: order.type
    };
};

function totalRegionalPrice(bs) {
    if (bs.numServices === 1) {
        return bs.regionalServicePrice1 * bs.serviceQty1;
    }
    if (bs.numServices === 2) {
        return (
            bs.regionalServicePrice1 * bs.serviceQty1 +
            bs.regionalServicePrice2 * bs.serviceQty2
        );
    }
    if (bs.numServices === 3) {
        return (
            bs.regionalServicePrice1 * bs.serviceQty1 +
            bs.regionalServicePrice2 * bs.serviceQty2 +
            bs.regionalServicePrice3 * bs.serviceQty3
        );
    }
}
function totalTaxableAmount(bs, hasOverride, overrideServicePrice) {
    let taxableAmount = 0;
    if (bs.sdt === "package") {
        const { boughtpackage: boughtPackage } = bs;
        if (
            boughtPackage &&
            boughtPackage.servicetype &&
            !boughtPackage.servicetype.taxexempt
        ) {
            taxableAmount =
                boughtPackage.packagetype === "DOLLAR"
                    ? boughtPackage.price
                    : boughtPackage.quantity *
                      bs.regionalServicePrice *
                      (1 - boughtPackage.discount / 100);
        }
    } else {
        if (bs.serviceType1 && !bs.serviceType1.taxexempt) {
            //adhoc booking support only one service so override code for first service
            taxableAmount +=
                (hasOverride
                    ? Number.parseFloat(
                          overrideServicePrice ? overrideServicePrice : 0
                      )
                    : bs.regionalServicePrice1) * bs.serviceQty1;
        }
        if (bs.serviceType2 && !bs.serviceType2.taxexempt) {
            taxableAmount += bs.regionalServicePrice2 * bs.serviceQty2;
        }
        if (bs.serviceType3 && !bs.serviceType3.taxexempt) {
            taxableAmount += bs.regionalServicePrice3 * bs.serviceQty3;
        }
    }
    return taxableAmount;
}
async function findOrCreateService(bs) {
    if (bs.numServices === 1 && bs.serviceQty1 === 1) {
        return {
            ...bs.serviceType1,
            units: bs.serviceQty1
        };
    }

    if (
        (bs.company.multipleServices && bs.numServices > 1) ||
        (bs.company.multipleQty && bs.serviceQty1 > 1)
    ) {
        const tempArr = [];
        if (bs.serviceType1)
            tempArr.push({ ...bs.serviceType1, units: bs.serviceQty1 });
        if (bs.serviceType2)
            tempArr.push({ ...bs.serviceType2, units: bs.serviceQty2 });
        if (bs.serviceType3)
            tempArr.push({ ...bs.serviceType3, units: bs.serviceQty3 });

        let includedServices = "";
        let bundledName;
        let categoryId;
        let categoryName;
        let bundledMinutes;
        if (tempArr.length > 1)
            tempArr.sort((s1, s2) => {
                const t1 = new Date(s1.createdAt).getTime();
                const t2 = new Date(s2.createdAt).getTime();
                if (t1 < t2) return -1;
                if (t1 > t2) return 1;
                return 0;
            });

        if (bs.numServices === 1)
            includedServices = `${tempArr[0].id}#${tempArr[0].units}`;
        if (bs.numServices === 2)
            includedServices = `${tempArr[0].id}#${tempArr[0].units}|${tempArr[1].id}#${tempArr[1].units}`;
        if (bs.numServices === 3)
            includedServices = `${tempArr[0].id}#${tempArr[0].units}|${tempArr[1].id}#${tempArr[1].units}|${tempArr[2].id}#${tempArr[2].units}`;

        categoryId = tempArr[0].category
            ? tempArr[0].category.id
            : tempArr[0].categoryId;
        categoryName = tempArr[0].category
            ? tempArr[0].category.name
            : tempArr[0].categoryName;
        if (tempArr.length === 1) {
            bundledName = `${tempArr[0].units} x ${tempArr[0].name}`;

            bundledMinutes = tempArr[0].minutes * tempArr[0].units;
        }
        if (tempArr.length === 2) {
            bundledName = `${tempArr[0].units} x ${tempArr[0].name} and ${tempArr[1].units} x ${tempArr[1].name}`;

            bundledMinutes =
                tempArr[0].minutes * tempArr[0].units +
                tempArr[1].minutes * tempArr[1].units;
        }
        if (tempArr.length === 3) {
            bundledName = `${tempArr[0].units} x ${tempArr[0].name}, ${tempArr[1].units} x ${tempArr[1].name} and  ${tempArr[2].units} x ${tempArr[2].name}`;
            bundledMinutes =
                tempArr[0].minutes * tempArr[0].units +
                tempArr[1].minutes * tempArr[1].units +
                tempArr[2].minutes * tempArr[2].units;
        }

        //now we have bundled services - included services id
        //first check if the bundled service exists
        const result = await graphql(
            graphqlOperation(bundledServicesByCompany, {
                companyId: bs.company.id,
                includedServices: { eq: includedServices }
            })
        );
        if (
            result &&
            result.data.bundledServicesByCompany.items.length &&
            result.data.bundledServicesByCompany.items[0]
        ) {
            return {
                ...result.data.bundledServicesByCompany.items[0],
                units: 1,
                componentServices: tempArr
            }; //units=1 for consistency only for bundledservice
        }
        if (result && !result.data.bundledServicesByCompany.items.length) {
            //if bundled service does not exist, create it
            let input = {
                name: bundledName,
                minutes: bundledMinutes,
                price: bs.regionalServicePrice,
                desc: "Bundled service",
                active: true,
                serviceTypeCompanyId: bs.company.id,
                companyId: bs.company.id,
                serviceTypeCategoryId: categoryId,
                categoryId: categoryId,
                categoryName: categoryName,
                deleted: false,
                includedServices: includedServices,
                isVisible: false,
                isBundledService: true
            };

            const newBundledService = await graphql(
                graphqlOperation(createServiceType, { input })
            );
            if (newBundledService && newBundledService.data.createServiceType)
                return {
                    ...newBundledService.data.createServiceType,
                    units: 1,
                    componentServices: tempArr
                };
            else return null;
        }
    }
    return bs.serviceType1; //serviceType1 is the main service when multipleServices is off.
}

function heldSlotsTimeoutExpired(bookingState) {
    let heldSlotTime = bookingState.heldSlotsOnDateTime;
    console.log(
        "heldSlotsTimeoutExpired",
        heldSlotTime.getTime(),
        Date.now(),
        Date.now() - heldSlotTime.getTime()
    );
    //900000 = 15 mins
    if (Date.now() - heldSlotTime.getTime() > 900000) return true;
    return false;
}

async function createNewUiSession(newSessionDetails) {
    return await createUISessionEntry(newSessionDetails);
}

async function createUISessionEntry({ eventName, companyId, uiSessionId }) {
    try {
        let input = {
            sessionItem: eventName,
            companyId: companyId
        };

        if (uiSessionId) input = { ...input, id: uiSessionId };

        const newUiSessionEntry = await graphql(
            graphqlOperation(createUISession, { input })
        );
        console.log("newUiSessionEntry", newUiSessionEntry);
        if (newUiSessionEntry && newUiSessionEntry.data.createUISession)
            return newUiSessionEntry.data.createUISession.id;
        else return null;
    } catch (e) {
        console.log("error while creating UI session", eventName);
    }
}

async function updateClientPackageIfValid(bookingState) {
    try {
        const num_of_credits_being_booked = bookingState.repeatingAppointment
            ? bookingState.repeatingApptList.length + 1
            : 1;
        //Do strong read query from here but for now read using appsync
        const result = await graphql(
            graphqlOperation(getClientPackage, {
                id: bookingState.clientpackage.id
            })
        );
        if (result && result.data && result.data.getClientPackage) {
            if (
                result.data.getClientPackage.initialQuantity -
                    result.data.getClientPackage.usedQuantity >=
                num_of_credits_being_booked
            ) {
                await updateUsedQuantityOfPackage({
                    pkg: bookingState.clientpackage,
                    increaseby: bookingState.repeatingAppointment
                        ? bookingState.repeatingApptList.length + 1
                        : 1
                });
                return true;
            } else return false; //not enough credits
        } else {
            console.log("Client package is not found or an error occurred");
            return false;
        }
    } catch (e) {
        return false;
    }
}
const chargeBreakup = (
    basePrice,
    isPkgCredit,
    bookingState,
    company,
    hasOverride,
    overrideServicePrice,
    overrideServiceFee
) => {
    const taxJurisdiction = { countryCode: "", stateCode: "" };

    const {
        serviceType: { TaxOverride }
    } = bookingState;
    if (bookingState.appointmentLocation === "remote") {
        taxJurisdiction.countryCode = bookingState.remoteAddressCountryShort;
        taxJurisdiction.stateCode = bookingState.remoteAddressState;
    }
    if (bookingState.appointmentLocation === "local") {
        taxJurisdiction.countryCode = company.countrycode3166alpha2;
        taxJurisdiction.stateCode = company.state;
    }
    let taxOverrideValue = null;
    try {
        taxOverrideValue = JSON.parse(TaxOverride);
    } catch {
        console.log("Error parsing tax override value");
    }

    return calculateChargeBreakup(
        basePrice,
        company,
        taxJurisdiction,
        isPkgCredit,
        bookingState,
        hasOverride,
        overrideServicePrice,
        overrideServiceFee,
        taxOverrideValue
    );
};

function ccyFormat(num, bookingState) {
    return `${
        getCurrencySymbol(bookingState) + Number.parseFloat(num).toFixed(2)
    }`;
}

function formatRawMoneyAmount(amount) {
    return Number.parseFloat(amount).toFixed(2);
}
function createOsd(
    bookingState,
    company,
    hasOverride,
    overrideServicePrice,
    overrideServiceFee
) {
    console.log("AdhocBookingService Booking State", bookingState);
    const osd = { rows: [] };
    let creditsRemaining = 0;
    let cbu = {};
    let boughtPackage = bookingState.boughtpackage; //will be null for now
    //  existing pkg
    if (bookingState.sdt === "package") {
        console.log("Entered bookingState === package");
        const numofappts = bookingState.repeatingAppointment
            ? bookingState.repeatingApptList.length + 1
            : 1;
        if (bookingState.clientpackage) {
            console.log("Entered bookingState.clientpackage");
            osd.rows.push({
                service: `Previously purchased package of ${
                    bookingState.clientpackage.initialQuantity
                } sessions - ${
                    bookingState.clientpackage.initialQuantity -
                    bookingState.clientpackage.usedQuantity
                } of ${bookingState.clientpackage.initialQuantity} remaining`,
                qty: `${numofappts} of ${
                    bookingState.clientpackage.initialQuantity -
                    bookingState.clientpackage.usedQuantity
                } credits used`,
                price: ccyFormat(0, bookingState)
            });
            cbu = chargeBreakup(0, true, bookingState, company);
            creditsRemaining =
                bookingState.clientpackage.initialQuantity -
                bookingState.clientpackage.usedQuantity -
                numofappts;
        }
        if (boughtPackage) {
            // calculate subtotal based on package type
            // discount for the taxableAmount is calculated in the totalTaxableAmount function
            // dollar packages use the original price value (unchanged by regional pricing) in the boughtpackage object
            // percentage packages use the regionalServicePrice value
            const subtotal =
                boughtPackage.packagetype === "DOLLAR"
                    ? boughtPackage.price
                    : boughtPackage.quantity *
                      bookingState.regionalServicePrice *
                      (1 - boughtPackage.discount / 100);
            const description = getDescription(
                boughtPackage,
                bookingState.regionalServicePrice,
                bookingState.currency
            );

            osd.rows.push({
                service: description,
                qty: `${numofappts} of ${boughtPackage.quantity} credits used`,
                price: ccyFormat(subtotal, bookingState)
            });
            cbu = chargeBreakup(subtotal, false, bookingState, company);
        }
    } else {
        const service =
            bookingState.sdt === "single"
                ? bookingState.serviceType.name
                : `${bookingState.serviceType.name} - first appointment`;
        const qty = 1; //single or forever
        const rawPrice =
            hasOverride && overrideServicePrice !== null
                ? Number.parseFloat(
                      overrideServicePrice ? overrideServicePrice : 0
                  )
                : bookingState.regionalServicePrice * qty;

        const price = ccyFormat(rawPrice, bookingState);
        osd.rows.push({
            service,
            qty,
            price,
            rawPrice: formatRawMoneyAmount(rawPrice)
        });

        cbu = chargeBreakup(
            bookingState.regionalServicePrice * qty,
            false,
            bookingState,
            company,
            hasOverride,
            overrideServicePrice,
            overrideServiceFee
        );
    }

    osd["subtotal"] = ccyFormat(cbu.subtotal, bookingState);
    osd["servicefee"] = ccyFormat(cbu.servicechargeamt, bookingState);
    osd["rawServiceFee"] = formatRawMoneyAmount(cbu.servicechargeamt);
    osd["taxrate"] = cbu.taxrate;
    osd["taxamount"] = ccyFormat(cbu.taxamt, bookingState);
    osd["total"] = ccyFormat(cbu.total, bookingState);
    osd["taxable"] = ccyFormat(cbu.taxableamt, bookingState);
    bookingState.osd = osd;
    bookingState.cbu = cbu;

    return {
        od: osd,
        creditsRemaining,
        taxExemption: cbu.taxableamt !== cbu.subtotal
    };
}

const holdSlots = async (bookingState, company) => {
    let input = {
        uiSessionId: bookingState.uiSessionId,
        sdt: bookingState.sdt,
        scheduleid: bookingState.selectedScheduleId,
        company: company,
        servicetype: {
            id: bookingState.serviceType.id,
            desc: bookingState.serviceType.desc,
            name: bookingState.serviceType.name,
            price: bookingState.serviceType.price,
            minutes: bookingState.serviceType.minutes
        },
        ...(bookingState.selectedslot && {
            booking: {
                date: new Date(bookingState.selectedslot.date),
                time: bookingState.selectedslot.label,
                slot12: bookingState.selectedslot.slot12,
                dateInfo: bookingState.selectedslot.dateInfo
            }
        })
    };
    if (bookingState.isRemoteLocation) {
        input = {
            ...input,
            locationId: `PL-${bookingState.locationId}`,
            geoLoc: bookingState.remoteAddressCoordinates
        };
    } else {
        const companyLocation = await fetchCompanyLocation(
            bookingState.locationId
        );
        input = {
            ...input,
            locationId: `CL-${bookingState.locationId}`,
            geoLoc: {
                lat: companyLocation.latitude,
                lng: companyLocation.longitude
            }
        };
    }
    try {
        let response = await holdSlotsForAppointments(input);
        if (!!response) {
            bookingState.heldSlots = response.heldSlots;
            bookingState.heldSlotsOnDateTime = new Date();
        }
    } catch (err) {
        console.error("Confirm booking error => ", err);
    }
    return bookingState;
};

const completeBookingWithoutCharge = async (bookingState, company, reason) => {
    let ret = { success: false };
    try {
        if (!bookingState.repeatingAppointment) {
            await holdSlots(bookingState, company);
        }
    } catch (e) {
        console.log("error while holding slot", e);
    }
    let localBookingDetails = null;
    try {
        localBookingDetails = await handleOrderAndBookingCreation(
            bookingState,
            {
                company
            }
        );
    } catch (err) {
        console.error("Confirm booking error => ", err);
        return { ...ret, message: "Error while creating order" };
    }
    if (localBookingDetails) {
        if (!bookingState.repeatingAppointment) {
            await updateHeldSlotsToConfirmed(bookingState.heldSlots);
        }
        if (reason === "PKG_CREDIT") {
            await updateUsedQuantityOfPackage({
                pkg: bookingState.clientpackage,
                increaseby: bookingState.repeatingAppointment
                    ? bookingState.repeatingApptList.length + 1
                    : 1
            });
            await updateOrderStatusToPaidByPkgCredit(
                localBookingDetails.order.id
            );
        }

        try {
            let orderReceipt = await sendOrderReceipt(
                localBookingDetails.order.id
            );
            await updateOrderStatusToConfirmed(
                localBookingDetails.order.id,
                bookingState.cbu,
                orderReceipt
            );
        } catch (err) {
            console.log("error sending notifications ", err);
            return {
                success: true,
                message:
                    "Order created successfully. However, error occurred while sending order receipt."
            };
        }
        try {
            await sendProviderBookingConfirmation({
                provider: bookingState.provider,
                client: localBookingDetails.client,
                company: company,
                serviceType: bookingState.serviceType,
                bookings: localBookingDetails.bookingsList,
                orderNo: localBookingDetails.order.orderNo,
                orderType: localBookingDetails.order.type,
                orderNotes: localBookingDetails.order.clientnotes,
                wdDisplay: bookingState.wdDisplay
            });
        } catch (err) {
            console.log("error sending provider notifications ", err);
            return {
                success: true,
                message:
                    "Order created successfully. However, error occurred while sending provider notification."
            };
        }
    }
    return { success: true, message: "Order created successfully." };
};

export {
    //saveBookingForServiceType,
    getPaidPackages,
    updateUsedQuantityOfPackage,
    updateClientPackageToPaid,
    updateOrderStatusToPaidByPkgCredit,
    updateOrderStatusToPaid,
    updateOrderStatusToClosed,
    cancelBooking,
    saveNoshowBooking,
    saveBillingProgress,
    calculateChargeBreakup,
    updateClientBalanceTo,
    holdSlotsForAppointments,
    getClientIfExists,
    createClientRecord,
    //createOrderAndBookings,
    updateHeldSlotsToConfirmed,
    deleteHeldSlots,
    addVmInfoToBooking,
    //getCompanyAdmins,
    sendOrderReceipt,
    handleOrderAndBookingCreation,
    saveClientPackage,
    fetchPackages,
    fetchRegionalPricing,
    fetchCompanyLocation,
    getPrice,
    getDescription,
    getStripeAmount,
    saveBookingRequest,
    getCurrency,
    getCurrencySymbol,
    sendProviderBookingConfirmation,
    getOrderTrackingData,
    totalRegionalPrice,
    totalTaxableAmount,
    findOrCreateService,
    heldSlotsTimeoutExpired,
    createNewUiSession,
    updateClientPackageIfValid,
    createOsd,
    holdSlots,
    completeBookingWithoutCharge
};
