import React, { useState, useEffect } from "react";
// prettier-ignore
import { Grid, FormControlLabel, TextField, Typography, Snackbar, Popper } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";

import { getJsonApi } from "../modules/AmplifyServices";
import { Autocomplete } from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";

export const ClientAddressInput = (props) => {
    const classes = useStyles();
    const [optionsGM, setOptionsGM] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(
        props.addressOneLine ? props.addressOneLine : ""
    );
    const [place_id, setPlaceId] = useState();
    const [addressOneLine, setAddressOneLine] = React.useState();
    const [prov, setProv] = React.useState();
    const [street_number, setStreet_Number] = useState();
    const [route, setRoute] = useState("");
    const [country, setCountry] = React.useState();
    const [postalCode, setPostalCode] = useState();
    const [countryShortName, setCountryShortName] = useState();
    const [coords, setCoords] = useState();
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [isAddressFocused, setIsAddressFocused] = useState(false);
    let autocompleteService = props.autocompleteService;
    const loaded = React.useRef(false);
    function loadScript(src, position, id) {
        if (!position) {
            return;
        }

        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", id);
        script.src = src;
        position.appendChild(script);
    }
    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            const url =
                "https://maps.googleapis.com/maps/api/js?key=" +
                process.env.REACT_APP_GOOGLE_MAP_KEY +
                "&libraries=places";
            loadScript(url, document.querySelector("head"), "google-maps");
        }

        loaded.current = true;
    }
    async function getPlaceDetails() {
        const result = await getJsonApi(
            "mapdirection",
            `/placedetails/${place_id}`
        );
        if (result && result.success) {
            return result.placedetails;
        } else {
            return null;
        }
    }
    const fetch = React.useMemo(
        () =>
            throttle((input, callback) => {
                autocompleteService?.current?.getPlacePredictions(
                    input,
                    callback
                );
            }, 200),
        []
    );
    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptionsGM([]);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                setOptionsGM(results || []);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    useEffect(() => {
        async function getPlaceAddress() {
            try {
                const placeDetails = await getPlaceDetails();
                console.log(
                    "Get PLace details clientAddressInput",
                    placeDetails
                );
                if (placeDetails) {
                    if (
                        placeDetails.route ||
                        placeDetails.street_number ||
                        placeDetails.postal_code
                    ) {
                        setProv(placeDetails.state);
                        setCountry(placeDetails.country);
                        setCountryShortName(placeDetails.country_short_name);
                        setPostalCode(placeDetails.postal_code);
                        setAddressOneLine(placeDetails.addressOneLine);
                        setCoords(placeDetails.location);
                        setInputValue(placeDetails.addressOneLine);
                        props.setAddress({
                            city: placeDetails.city,
                            prov: placeDetails.state,
                            country: placeDetails.country,
                            countryShortName: placeDetails.country_short_name,
                            postalCode: placeDetails.postal_code,
                            addressOneLine: placeDetails.addressOneLine,
                            coords: placeDetails.location,
                            route: placeDetails.route,
                            street_number: placeDetails.street_number
                        });
                        props.setPlaceId && props.setPlaceId(place_id);
                        setRoute(placeDetails.route);
                        setStreet_Number(placeDetails.street_number);
                    } else {
                        setSnackMsg(
                            "Please provide a street address for your booking."
                        );
                        setMsgOpen(true);
                        props.setPlaceId && props.setPlaceId(null);
                        props.setAddress({
                            prov: "",
                            country: "",
                            countryShortName: "",
                            postalCode: "",
                            addressOneLine: "",
                            coords: "",
                            route: "",
                            street_number: ""
                        });
                        resetAddressFields();
                    }
                } else {
                    setSnackMsg(
                        "Please provide a street address for your booking."
                    );
                    setMsgOpen(true);
                    props.setPlaceId && props.setPlaceId(null);
                    props.setAddress({
                        prov: "",
                        country: "",
                        countryShortName: "",
                        postalCode: "",
                        addressOneLine: "",
                        coords: "",
                        route: "",
                        street_number: ""
                    });
                    resetAddressFields();
                }
            } catch (e) {
                console.log("error getting place: ", e);
            }
        }
        if (place_id) getPlaceAddress();
    }, [place_id]);

    //useEffect handles when default values (props.addressOneLine) is passed through. ie. client's saved address
    useEffect(() => {
        setInputValue(props.addressOneLine);
        if (props.addressOneLine) {
            fetch({ input: props.addressOneLine }, (results) => {
                setOptionsGM(results || []);
                let values = { place_id: results[0]?.place_id };
                handleAddressChange(null, values);
            });
        } else if (props.onClear) {
            resetAddressFields();
            props.onClear();
        }
    }, [fetch, props.addressOneLine]);

    function resetAddressFields() {
        setPlaceId(null);
        setInputValue("");
        setProv("");
        setCountry("");
        setCountryShortName("");
        setPostalCode("");
        setAddressOneLine("");
        setCoords();
        setRoute("");
        setStreet_Number();
    }

    const PopperMy = function (props) {
        const { anchorEl, ...otherProps } = props;

        return (
            <Popper
                {...otherProps}
                anchorEl={anchorEl}
                placement="bottom"
                modifiers={[
                    {
                        name: "flip",
                        options: {
                            fallbackPlacements: ["bottom-start"]
                        }
                    }
                ]}
            />
        );
    };

    const handleAddressChange = (event, values) => {
        try {
            setPlaceId(values.place_id);
        } catch (err) {
            console.log(
                "An error occurred in handleAddressChange. Error was " +
                    JSON.stringify(err)
            );
        }
    };
    const handleChange = (event) => {
        setInputValue(event.target.value);
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={5000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <FormControlLabel
                labelPlacement="top"
                style={{
                    width: props.formWidth
                        ? props.formWidth
                        : props.overrideModalDisplay
                          ? "542px"
                          : "300px",
                    margin: props.formMargin ? props.formMargin : "8px",
                    marginLeft: props.formMarginLeft
                        ? props.formMarginLeft
                        : props.overrideModalDisplay
                          ? "0px"
                          : "8px"
                }}
                control={
                    <Autocomplete
                        id="client-address"
                        inputValue={inputValue}
                        className={classes.autocomplete}
                        getOptionLabel={(optionGM) => optionGM.description}
                        filterOptions={(x) => x}
                        options={optionsGM}
                        classes={{
                            inputRoot: classes.inputRoot1
                        }}
                        autoComplete
                        onChange={handleAddressChange}
                        onInputChange={(event, newInputValue, reason) => {
                            if ("clear" === reason && props.onClear)
                                props.onClear();
                            resetAddressFields();
                        }}
                        includeInputInList
                        freeSolo
                        openOnFocus
                        style={{
                            width: props.formWidth
                                ? props.formWidth
                                : props.overrideModalDisplay
                                  ? "100%"
                                  : "300px"
                        }}
                        renderInput={(params) => (
                            <TextField
                                sx={{ position: "relative" }}
                                variant="outlined"
                                {...params}
                                size={props.smallSize ? "small" : "medium"}
                                InputProps={{
                                    ...params.InputProps,
                                    style: props.smallSize
                                        ? {
                                              padding: "5.45px",
                                              fontSize: "14px"
                                          }
                                        : {},
                                    classes: {
                                        notchedOutline:
                                            classes.notchedOutlineProviderDir
                                    }
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    style: props.smallSize
                                        ? {
                                              top:
                                                  inputValue || isAddressFocused
                                                      ? "2px"
                                                      : "",
                                              lineHeight:
                                                  inputValue || isAddressFocused
                                                      ? ""
                                                      : "18px",
                                              fontSize: "14px"
                                          }
                                        : {},
                                    classes: {
                                        outlined: classes.inputRoot1,
                                        focused: classes.inputRoot1
                                    }
                                }}
                                label={
                                    props.width === "md"
                                        ? "Please enter an address"
                                        : props.label
                                          ? props.label
                                          : "Please enter an address for the service"
                                }
                                onFocus={() => setIsAddressFocused(true)}
                                onBlur={() => setIsAddressFocused(false)}
                                fullWidth
                                onChange={handleChange}
                            />
                        )}
                        disabled={props.disableElements}
                        renderOption={(props, option) => {
                            const matches =
                                option.structured_formatting
                                    .main_text_matched_substrings || [];
                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [
                                    match.offset,
                                    match.offset + match.length
                                ])
                            );

                            return (
                                <li sx={{ position: "relative" }} {...props}>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <LocationOnIcon
                                                className={classes.icon}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight:
                                                            part.highlight
                                                                ? 700
                                                                : 400
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}

                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {
                                                    option.structured_formatting
                                                        .secondary_text
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                            );
                        }}
                        PopperComponent={(popperProps) => (
                            <PopperMy
                                {...popperProps}
                                anchorEl={popperProps.anchorEl}
                            />
                        )}
                    />
                }
            />
        </>
    );
};
