// ************************************
// Service module to handle messaging common functionality //
// ************************************

import { graphql, graphqlOperation, postApi } from "./AmplifyServices";
import voucherCodes from "voucher-code-generator";
import { getCompanyAdmins } from "../user/UserCommon";
import * as Sentry from "@sentry/react";

async function sendSms(to, msg, countryCode) {
    let from = await getSenderNumberByCountry(countryCode);
    const result = await postApi("messaging", "/send", {
        body: {
            from,
            to,
            msg
        }
    });
    console.log(JSON.stringify(result));
}

async function getShort(link) {
    const response = await postApi("messaging", "/short", {
        body: {
            link
        }
    });
    return response.short;
}

async function getSenderNumberByCountry(countryCode) {
    const getRefData = /* GraphQL */ `
        query GetRefData($refType: ID!, $refName: String!) {
            getRefData(refType: $refType, refName: $refName) {
                refType
                refName
                refValue
            }
        }
    `;
    let resp = await graphql(
        graphqlOperation(getRefData, {
            refType: "TWILIO_FROM_NUM",
            refName: countryCode
        })
    );
    if (resp.data.getRefData) return resp.data.getRefData.refValue;
    else {
        resp = await graphql(
            graphqlOperation(getRefData, {
                refType: "TWILIO_FROM_NUM",
                refName: "DEFAULT"
            })
        );
        if (resp.data.getRefData) return resp.data.getRefData.refValue;
    }
    return process.env.REACT_APP_TWILIO_SMS_SEND_NUMBER;
}

function genpass() {
    return voucherCodes.generate({
        length: 8,
        count: 1,
        charset: voucherCodes.charset("alphanumeric")
    })[0];
}

async function getCompanyAdminEmailAddresses() {
    const companyAdmins = await getCompanyAdmins();
    let companyAdminEmails = [];
    if (companyAdmins && companyAdmins.length >= 1) {
        companyAdminEmails = companyAdmins.map((ca) => ca.emailaddress);
    }
    return companyAdminEmails;
}

/**
 * Function to send a user their password management email.
 * @param {*} temppas - temporary password
 * @param {*} passwordManagementType - type of password management request, "RESET" || "SET-TEMP-PWD"
 * @param {*} userData - data pertaining to the user recipient
 * @param {*} companyData - data pertaining to the users company
 */
async function sendPasswordManagementEmail(
    temppas,
    passwordManagementType,
    userData,
    companyData
) {
    const adminAddresses = await getCompanyAdminEmailAddresses();
    adminAddresses.push(userData.emailAddress);

    const subDomain =
        companyData.maskeddomain && companyData.maskeddomain.length
            ? companyData.maskeddomain
            : companyData.subdomain;

    const clientActions = `You can then use this site’s links in the Left NavBar as follows:
        <ol>
        <li><b>My Orders</b> to view your Orders</li>
        <li><b>My Bookings</b> to view your Bookings</li>
        <li><b>My Profile</b> to update your Profile</li>
        </ol>
        Please visit https://${subDomain} to book an appointment.<br><br>`;
    const providerActions = `You can then use this site’s links in the Left NavBar as follows:
        <ol>
        <li><b>My Profile</b> to set up your Provider Profile, including your Bio and Photo</li>
        <li><b>My Locations</b> to set up one or more Provider Locations</li>
        <li><b>My Schedules</b> to add one or more Schedules</li>
        <li><b>My Blocked Times</b> to manage your Blocked Time</li>
        <li><b>My Bookings</b> to view your bookings</li>
        </ol>
        Clients visit https://${subDomain} to book an appointment.<br><br>`;
    const companyAdminActions = `You can then use this site to administer your MarketBox site.<br><br>
        Clients visit https://${subDomain} to book an appointment.<br><br>`;

    const dataObj = {
        user_firstname: userData.firstName,
        user_lastname: userData.lastName,
        user_email: userData.emailAddress,
        user_temppas: temppas,
        user_actions:
            userData.userRole === "CLIENT"
                ? clientActions
                : userData.userRole === "PROVIDER"
                  ? providerActions
                  : companyAdminActions,
        user_role: userData.userRole,
        company_email: companyData.emailaddress,
        company_name: companyData.name,
        isRegisteredUser: true
    };

    let templateName =
        passwordManagementType === "RESET"
            ? "reset-password.html"
            : "resend-temp-password.html";

    await postApi("sendtwilioemail", "/sendtwilioemailtemplate", {
        body: {
            templateName: templateName,
            subject:
                passwordManagementType === "RESET"
                    ? `Password reset request for ${companyData.name} on MarketBox`
                    : `${userData.firstName} ${userData.lastName} - Temporary password request for ${companyData.name} on MarketBox`,
            body: null,
            toAddresses: [userData.emailAddress],
            ccAddresses: [],
            bccAddresses:
                passwordManagementType === "RESET" ? [] : adminAddresses, //cc to admins only for temp pass
            replyTo: companyData.replyemailaddress
                ? companyData.replyemailaddress
                : companyData.emailaddress,
            companyName: companyData.name,
            companyId: companyData.id,
            dataObj: dataObj,
            companyLogoUrl: companyData.logoUrl,
            companyColor: companyData.primaryColor,
            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
        }
    });
}

/**
 * Function to send registration email to the client.
 * @param {*} temppas
 * @param {*} cleanValidPhone - a phone number with no spaces, parenthesis, or dashes
 * @param {*} userData - data pertaining to the user recipient
 * @param {*} companyData - data pertaining to the users company
 */
async function sendRegistrationEmail(
    temppas,
    cleanValidPhone,
    userData,
    companyData
) {
    let bccAddresses;
    if (companyData.BccLists) {
        let bccLists = JSON.parse(companyData.BccLists);
        if (bccLists && bccLists.forWelcome) {
            bccAddresses = bccLists.forWelcome;
        }
    }

    const subDomain =
        companyData.maskeddomain && companyData.maskeddomain.length
            ? companyData.maskeddomain
            : companyData.subdomain;

    const clientActions = `You can then use this site’s links in the Left NavBar as follows:
        <ol>
        <li><b>My Orders</b> to view your Orders</li>
        <li><b>My Bookings</b> to view your Bookings</li>
        <li><b>My Profile</b> to update your Profile</li>
        </ol>
        Please visit https://${subDomain} to book an appointment.<br><br>`;
    const providerActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Profile</b> to set up your Provider Profile, including your Bio and Photo</li>
            <li><b>My Locations</b> to set up one or more Provider Locations</li>
            <li><b>My Schedules</b> to add one or more Schedules</li>
            <li><b>My Blocked Times</b> to manage your Blocked Time</li>
            <li><b>My Bookings</b> to view your bookings</li>
            </ol>
            Clients visit https://${subDomain} to book an appointment.<br><br>`;
    const companyAdminActions = `You can then use this site to administer your MarketBox site.<br><br>
            Clients visit https://${subDomain} to book an appointment.<br><br>`;

    const dataObj = {
        user_firstname: userData.firstName,
        user_lastname: userData.lastName,
        user_email: userData.emailAddress,
        user_phone: cleanValidPhone,
        user_phone_type: "mobile",
        user_temppas: temppas,
        user_actions:
            userData.userRole === "CLIENT"
                ? clientActions
                : userData.userRole === "PROVIDER"
                  ? providerActions
                  : companyAdminActions,
        user_role: userData.userRole,
        company_email: companyData.emailaddress,
        company_name: companyData.name,
        isRegisteredUser: true
    };

    let templateName =
        userData.userRole === "PROVIDER"
            ? "welcome-email-provider.html"
            : "welcome-email.html";

    try {
        await postApi("sendtwilioemail", "/sendtwilioemailtemplate", {
            body: {
                templateName: templateName,
                subject: `${userData.firstName}, you've been invited to join ${companyData.name} on MarketBox!`,
                body: null,
                toAddresses: [userData.emailAddress],
                ccAddresses: [],
                bccAddresses: bccAddresses,
                replyTo: companyData.replyemailaddress
                    ? companyData.replyemailaddress
                    : companyData.emailaddress,
                companyName: companyData.name,
                companyId: companyData.id,
                dataObj: dataObj,
                companyLogoUrl: companyData.logoUrl,
                companyColor: companyData.primaryColor,
                authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
            }
        });
    } catch (e) {
        Sentry.captureException(e);
        console.log("asdf error while sending registration email", e);
    }
}

export {
    sendSms,
    getShort,
    getSenderNumberByCountry,
    genpass,
    sendPasswordManagementEmail,
    sendRegistrationEmail
};
