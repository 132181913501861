// atoms.ts
import { atom } from "jotai";
// import { atomWithStorage } from "jotai/utils"; // if state that survives refresh use this
export const mbxUserAtom = atom({});
export const isMbxUserActive = atom((get) => {
    const mbxUser = get(mbxUserAtom);
    return (
        (mbxUser?.active && mbxUser?.company?.active) ||
        mbxUser?.role === "MARKETBOX_ADMIN"
    );
});
export const isMbxUserProvider = atom((get) => {
    const mbxUser = get(mbxUserAtom);
    return mbxUser?.role === "PROVIDER";
});
export const isMbxFreeTrialUser = atom((get) => {
    const mbxUser = get(mbxUserAtom);
    return mbxUser?.company?.subscriptionLevel === "FREE_TRIAL_BASIC";
});
export const dashboardInfoAtom = atom({});
export const companyIdAtom = atom((get) => {
    const {
        company: { id }
    } = get(mbxUserAtom);
    return id;
});
/**
 * data used for Integrations page
 */
export const integrationRefDataAtom = atom({});
// atoms.ts
export const providerPermalinkURL = atom((get) => {
    const mbxUser = get(mbxUserAtom);
    return `https://${mbxUser.company.subdomain}/provider`;
});
export const allUsersAtom = atom([]);
export const allSchedulesAtom = atom([]);
export const allActiveServicesAtom = atom([]);
export const allActiveSkillsAtom = atom([]);
export const allActiveLocationsAtom = atom([]);
export const updateUserInAllUsersAtom = atom(
    null,
    (get, set, updatedUserData) => {
        const allUsers = get(allUsersAtom);
        const updatedUsers = allUsers.map((user) =>
            user.id === updatedUserData.id
                ? { ...user, ...updatedUserData }
                : user
        );
        set(allUsersAtom, updatedUsers);
    }
);

export const deleteUserInAllUsersAtom = atom(null, (get, set, userId) => {
    const allUsers = get(allUsersAtom);
    const updatedUsers = allUsers.filter((user) => user.id !== userId);
    set(allUsersAtom, updatedUsers);
});

export const conformationDialogOpenAtom = atom(false);
export const conformationDialogContentAtom = atom("");
export const conformationDialogTitleAtom = atom("");
const fnAtom = atom({ fn: () => {} });
export const confirmationFunctionAtom = atom(
    (get) => get(fnAtom),
    (get, set, newFn) => {
        set(fnAtom, { fn: newFn });
    }
);
