import React from "react";
import { TableRow, TableCell, Typography, Link, Tooltip } from "@mui/material";
import { UserCard } from "./UserCard";
import { UserAvailability } from "./UserAvailability";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { USERROLE } from "../../user/UserCommon";
import { providerPermalinkURL } from "../../atoms/atoms";
import { selectedUserIdAtom, openUserDialogAtom } from "../../atoms/usersTable";
import { useAtom } from "jotai";
import { Stack } from "@mui/system";
const ADMIN_LABELS = {
    [USERROLE.PROVIDER]: "Provider",
    [USERROLE.COMPANY_ADMIN_PROVIDER]: "Admin"
};
export const UserRow = ({
    userData: {
        role,
        active,
        phone = "",
        firstName,
        lastName,
        email,
        permalink = "",
        photoUrl,
        skills = [],
        services = [],
        createdAt,
        lastLoginDate,
        activeSchedule,
        availability
    },
    id
}) => {
    const theme = useTheme();
    const [permalinkUrl] = useAtom(providerPermalinkURL);
    const [, setSelectedUserId] = useAtom(selectedUserIdAtom);
    const [, setOpenUserDialog] = useAtom(openUserDialogAtom);
    const handlePermalinkClick = (event) => {
        event.stopPropagation(); //Prevents the handleRowClick function from being triggered when the permalink Icon is clicked
    };
    const handleRowClick = (id) => {
        if (!id) return;
        setSelectedUserId(id);
        setOpenUserDialog(true);
    };

    return (
        <TableRow key={id} hover onClick={() => handleRowClick(id)}>
            <TableCell sx={{ width: "4%", textAlign: "center" }}>
                <Tooltip title={active ? "Active" : "Inactive"}>
                    <CircleIcon
                        fontSize="small"
                        sx={{
                            color: active
                                ? theme.palette.primary.main
                                : grey[300]
                        }}
                    />
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: "30%" }}>
                <UserCard
                    {...{
                        phone,
                        combinedName: `${firstName} ${lastName}`,
                        email,
                        permalink,
                        photoUrl
                    }}
                />
            </TableCell>
            <TableCell sx={{ width: "4%" }}>
                {permalink ? (
                    <Link
                        href={`${permalinkUrl}/${permalink}`}
                        target="_blank"
                        rel="noopener"
                        onClick={handlePermalinkClick}
                    >
                        <LaunchIcon />
                    </Link>
                ) : (
                    <LaunchIcon />
                )}
            </TableCell>
            <TableCell sx={{ width: "5%" }}>
                <Typography>{ADMIN_LABELS[role]}</Typography>
            </TableCell>
            <TableCell sx={{ width: "7%", textAlign: "center" }}>
                <Tooltip
                    title={
                        skills.length > 0 ? (
                            <Stack>
                                {skills.map(
                                    (
                                        { skill: { name } } // TODO: Move to data adapter in the atom
                                    ) => (
                                        <Typography>{name}</Typography>
                                    )
                                )}
                            </Stack>
                        ) : (
                            ""
                        )
                    }
                >
                    <Typography textAlign={"center"}>
                        {skills.length}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: "7%", textAlign: "center" }}>
                <Tooltip
                    title={
                        services.length > 0 ? (
                            <Stack>
                                {services.map(
                                    (
                                        { servicetype: { name } } // TODO: Move to data adapter in the atom
                                    ) => (
                                        <Typography>{name}</Typography>
                                    )
                                )}
                            </Stack>
                        ) : (
                            ""
                        )
                    }
                >
                    <Typography textAlign={"center"}>
                        {services.length}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: "25%" }}>
                <UserAvailability {...{ activeSchedule, availability }} />
            </TableCell>
            <TableCell sx={{ width: "9%" }}>
                <Typography>
                    {lastLoginDate && (
                        <>
                            {moment(lastLoginDate).format("MMM d, Y")}
                            <br />
                            <Typography textTransform={"uppercase"}>
                                {moment(lastLoginDate).format("h:mm a")}
                            </Typography>
                        </>
                    )}
                </Typography>
            </TableCell>
            <TableCell sx={{ width: "9%" }}>
                <Typography>
                    <>
                        {moment(createdAt).format("MMM d, Y")}
                        <br />
                        <Typography textTransform={"uppercase"}>
                            {moment(lastLoginDate).format("h:mm a")}
                        </Typography>
                    </>
                </Typography>
            </TableCell>
        </TableRow>
    );
};
