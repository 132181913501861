export const getProviderExtended = /* GraphQL */ `
    query GetProvider($id: ID!) {
        getProvider(id: $id) {
            id
            firstname
            lastname
            emailaddress
            phone
            addressoneline
            street
            city
            state
            country
            postalcode
            longitude
            latitude
            bio
            pictures3key
            ratingstarsavg
            numberofratings
            offersVirtualServices
            virtualMeetingUserId
            vmlink
            maxtraveltype
            company {
                id
            }
            companyId
            servicetypes {
                items {
                    id
                    servicetype {
                        id
                        name
                        deleted
                        active
                    }
                }
                nextToken
            }
            skills {
                items {
                    id
                    skill {
                        id
                    }
                }
                nextToken
            }
            locations {
                items {
                    id
                    name
                    desc
                    deleted
                }
                nextToken
            }
            user {
                active
                registered
                role
                LoginInfo
            }
            active
            deleted
            permalink
            createdAt
            updatedAt
            timezone
        }
    }
`;
export const providerByPermalink = /* GraphQL */ `
    query ProviderByPermalink(
        $companyId: String
        $permalink: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        providerByPermalink(
            companyId: $companyId
            permalink: $permalink
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstname
                lastname
                permalink
            }
            nextToken
        }
    }
`;
