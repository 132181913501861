import React, { useContext, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import GetAppIcon from "@mui/icons-material/GetApp";
import ExportExpiredBookigns from "./ExportExpiredBookings";
import ExportProvidersReviews from "./ExportProvidersReviews";
import ExportClientList from "./ExportClientList";
import ExportProvidersList from "./ExportProvidersList";
import AuditRecordsForm from "./AuditRecordsForm";
import { Cache } from "aws-amplify/utils";
import { OUTLET_PAGE } from "../context/reducers";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    Button
} from "@mui/material";
import ExportPayrollList from "./ExportPayrollList";
import { getUserFromCache } from "../user/UserCommon";

const Pages = {
    dashboard: "Dashboard",
    provider: "Service Provider",
    customer: "Customer",
    bookings: "Bookings",
    orders: "Orders",
    services: "Services",
    locations: "Company Locations",
    promotions: "Promotions",
    reports: "Reports",
    company: "Company",
    admin: "Admin",
    package: "Packages",
    users: "Users",
    region: "Region",
    admincolor: "ColorForm",
    adminbilling: "MiscBillingTransaction",
    envt: "EnvtForm",
    addcharges: "AdditionalCharges",
    potentialsalesform: "PotentialSalesForm",
    auditRecords: "AuditRecordsForm"
};

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function ReportForm(props) {
    const navigate = useNavigate();

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper
        }
    }));

    const [featureDisabled, setShowFeatureDisabled] = useState(false);

    const classes = useStyles();
    const { state, dispatch, actions } = useContext(StoreContext);

    function handleMenuListClick(nextPage) {
        //console.log('nextPage = ' + nextPage);
        if (nextPage === Pages.dashboard) actions.setPage("DashboardForm");
        else if (nextPage === Pages.provider) actions.setPage("ProviderForm");
        else if (nextPage === Pages.customer) actions.setPage("CustomerForm");
        else if (nextPage === Pages.bookings) actions.setPage("BookingForm");
        else if (nextPage === Pages.orders) actions.setPage("OrderForm");
        else if (nextPage === Pages.services) actions.setPage("ServiceForm");
        else if (nextPage === Pages.promotions)
            actions.setPage("PromotionForm");
        else if (nextPage === Pages.reports) actions.setPage("ReportForm");
        else if (nextPage === Pages.company) actions.setPage("CompanyForm");
        else if (nextPage === Pages.admin) actions.setPage("AdminForm");
        else if (nextPage === Pages.package) actions.setPage("PackageForm");
        else if (nextPage === Pages.users) actions.setPage("UsersForm");
        else if (nextPage === Pages.region) actions.setPage("RegionForm");
        else if (nextPage === Pages.admincolor) actions.setPage("ColorForm");
        else if (
            nextPage === Pages.addcharges &&
            getUserFromCache()?.company.collectpayment
        )
            actions.setPage("AdditionalChargesForm");
        else if (
            nextPage === Pages.addcharges &&
            getUserFromCache()?.company.collectpayment == false
        )
            setShowFeatureDisabled(true);
        else if (nextPage === Pages.adminbilling)
            actions.setPage("MiscBillingTransaction");
        else if (nextPage === Pages.locations)
            actions.setPage("CompanyLocationForm");
        else if (nextPage === Pages.envt) actions.setPage("EnvtForm");
        else if (nextPage === Pages.potentialsalesform)
            actions.setPage("PotentialSalesForm");
        else if (
            nextPage === Pages.billingchargesform &&
            getUserFromCache()?.company.collectpayment
        )
            actions.setPage("BillingForm");
        else if (
            nextPage === Pages.billingchargesform &&
            getUserFromCache()?.company.collectpayment == false
        )
            setShowFeatureDisabled(true);
        else if (nextPage === Pages.auditRecords)
            actions.setPage("AuditRecordsForm")
    }

    let title = (
        <Typography
            id="MBReportsForm"
            className={classes.title}
            sx={{ fontSize: "24px", fontWeight: 400 }}
            variant="h4"
            noWrap
        >
            Reports
        </Typography>
    );

    let potentialSales = (
        <>
            <ListItem
                button
                onClick={() => handleMenuListClick(Pages.potentialsalesform)}
            >
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Waitlist" />
            </ListItem>
            <Divider />
        </>
    );
    let billingCharges = (
        <>
            <ListItem
                button
                onClick={() => handleMenuListClick(Pages.billingchargesform)}
            >
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Billing charges" />
            </ListItem>
            <Divider />
        </>
    );

    let addCharges = (
        <>
            <ListItem
                button
                onClick={() => handleMenuListClick(Pages.addcharges)}
            >
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Additional charges" />
            </ListItem>
            <Divider />
        </>
    );

    /* let providerData = (
        <>
            <ProviderDataReport />
            <Divider />
        </>
    ); */

    let exportPayrollList = (
        <>
            <ExportPayrollList />
            <Divider />
        </>
    );

    let exportDeclinedBookings = (
        <>
            <ExportExpiredBookigns />
            <Divider />
        </>
    );

    let exportProviderRatings = (
        <>
            <ExportProvidersReviews />
            <Divider />
        </>
    );

    let exportClientList = (
        <>
            <ExportClientList />
            <Divider />
        </>
    );

    let exportProvidersList = (
        <>
            <ExportProvidersList />
            <Divider />
        </>
    );

    let exportAuditRecords = (
        <>
            <ListItem
                button
                onClick={() => {
                    actions.setPage(OUTLET_PAGE);
                    navigate("/audit")
                }}
            >
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Audit Trail" />
            </ListItem>
        </>
    );

    let display, options, list;
    options = [
        potentialSales,
        billingCharges,
        addCharges,
        /* providerData, */
        exportPayrollList,
        exportDeclinedBookings,
        exportProviderRatings,
        exportClientList,
        exportProvidersList,
        exportAuditRecords
    ];
    list = (
        <List component="nav" aria-label="main mailbox folders">
            {options}
        </List>
    );
    display = <div className={classes.root}>{list}</div>;
    display = [title, display];

    if (featureDisabled == false) {
        return display;
    } else {
        return (
            <>
                <Dialog
                    open={featureDisabled}
                    fullWidth={true}
                    aria-labelledby="show-client-dialog-title"
                    aria-describedby="show-client-dialog-description"
                >
                    <DialogTitle
                        id="show-client-dialog-title"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        This feature is currently disabled on your account
                    </DialogTitle>
                    <DialogContent dividers style={{ padding: 22 }}>
                        <DialogContentText id="show-client-dialog-description">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        To turn it on: Go to Company Settings{" "}
                                        {"\u2192"} Turn on 'Company Collects
                                        Payments'
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        You will then need to connect your
                                        Stripe account to MarketBox
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={0}>
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setShowFeatureDisabled(false)
                                        }
                                        color="primary"
                                    >
                                        Okay
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default ReportForm;
