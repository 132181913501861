import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    IconButton,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Link,
    LinearProgress
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import moment from "moment";
import { CSVLink } from "react-csv";
import { getUserFromCache } from "../user/UserCommon";
import { execReadByPK } from "../modules/DBService";
import { providerRatingsByCompany } from "../graphql/queries";
import { clientByCompany } from "../queries/CustomQueries";

const ExportProvidersReviews = () => {
    const classes = usePromotionsStyles();
    const [exportReadyReviews, setExportReadyReviews] = useState();
    const [exportDialog, setExportDialog] = useState();
    const [noRatingsFoundDialog, setNoRatingsFoundExportDialog] = useState();
    const [exportDownloadReady, setExportDownloadReady] = useState(false);
    const [prepareExportData, setPrepareExportData] = useState(false);
    const [showExportProgress, setShowExportProgress] = useState(false);
    const [exportProgress, setExportProgress] = useState(0);

    const csvHeaders = [
        { label: "ID", key: "ID" },
        { label: "Created at", key: "CreatedAt" },
        { label: "Created by", key: "ClientName" },
        { label: "Client first name", key: "ClientFirstName" },
        { label: "Client last name", key: "ClientLastName" },
        { label: "Client email", key: "ClientEmail" },
        { label: "Provider first name", key: "ProviderFirstName" },
        { label: "Provider last name", key: "ProviderLastName" },
        { label: "Provider email", key: "ProviderEmail" },
        { label: "Rating (1-5)", key: "RatingStars" },
        { label: "Review", key: "RatingText" }
    ];

    const handleExportClick = () => {
        setExportDialog(true);
    };

    const csvData = (reviewList, clientList) => {
        setExportProgress(60);
        try {
            let rows = [];

            if (reviewList.items && reviewList.items.length > 0) {
                reviewList.items.map(async (item) => {
                    enrichReviewData(item, clientList);
                });
                rows = reviewList.items;
            }
            setExportProgress(100);
            const csvData = rows.map((item) => {
                const {
                    id: ID,
                    createdAt: CreatedAt,
                    providerFirstName: ProviderFirstName,
                    providerLastName: ProviderLastName,
                    providerEmail: ProviderEmail,
                    ratingstars: RatingStars,
                    ratingtext: RatingText,
                    ratinguserId: ClientName,
                    clientfirstname: ClientFirstName,
                    clientlastname: ClientLastName,
                    clientemail: ClientEmail
                } = item;
                const newItem = {
                    ID,
                    CreatedAt,
                    ProviderFirstName,
                    ProviderLastName,
                    ProviderEmail,
                    RatingStars,
                    RatingText,
                    ClientName,
                    ClientFirstName,
                    ClientLastName,
                    ClientEmail
                };
                return newItem;
            });
            console.log("this is the csvData", csvData);
            if (csvData && csvData.length) return csvData;
        } catch (e) {
            console.log(e);
        }
    };

    function enrichReviewData(item, clientList) {
        item.providerFirstName = item.provider.firstname;
        item.providerLastName = item.provider.lastname;
        item.providerEmail = item.provider.emailaddress;
        item.createdAt = moment(item.createdAt).format("YYYY-MM-DD hh:mm A");
        //only go through for loop if there is a reviewuserId
        if (item.ratinguserId) {
            for (let i = 0; i < clientList.items.length; i++) {
                if (item.createdAt > "2022-08-31 11:55") {
                    //2022-08-31 is used because ratinguserId started being populated with the booking.items.user.id on 2022-09-01
                    if (item.ratinguserId == clientList.items[i].userId) {
                        item.ratinguserId = "client";
                        item.clientfirstname =
                            clientList.items[i].user.firstname;
                        item.clientlastname = clientList.items[i].user.lastname;
                        item.clientemail =
                            clientList.items[i].user.emailaddress;
                        return;
                    } else if (i == clientList.items.length - 1) {
                        item.ratinguserId = "Manually Added by an Admin";
                    }
                } else {
                    item.ratinguserId = "Manually Added by an Admin";
                }
            }
        }

        return item;
    }

    const _getReviewList = async () => {
        const authuser = getUserFromCache();

        const reviewsForExport = await execReadByPK({
            opname: "providerRatingsByCompany",
            op: providerRatingsByCompany,
            id: { companyId: authuser.companyId },
            sortDirection: "DESC"
        });
        {
            reviewsForExport.items.length > 100
                ? setExportProgress(15)
                : setExportProgress(0);
        }

        const clientList = await execReadByPK({
            opname: "clientByCompany",
            op: clientByCompany,
            id: { companyId: authuser.companyId }
        });
        {
            clientList.items.length > 100
                ? setExportProgress(25)
                : setExportProgress(0);
        }

        console.log("reviewsForExport", reviewsForExport);
        console.log("clientList", clientList);

        let enrichedClientList = []; //holds only the clients ID and Name

        if (reviewsForExport.items && reviewsForExport.items.length > 0) {
            setExportProgress(30);
            const csvDatas = csvData(
                {
                    items: reviewsForExport.items
                },
                clientList
            );
            setExportReadyReviews(csvDatas);
            setExportDownloadReady(true);
            setPrepareExportData(false);
            return;
        } else {
            setExportDialog(false);
            setShowExportProgress(false);
            setExportProgress(0);
            setPrepareExportData(false);
            setExportDownloadReady(false);
            setExportReadyReviews([]);
            setNoRatingsFoundExportDialog(true);
        }
        setExportReadyReviews([]);
    };

    return (
        <>
            <Dialog
                open={exportDialog}
                fullWidth={true}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Export customer reviews
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setExportDialog(false);
                            setShowExportProgress(false);
                            setExportProgress(0);
                            setPrepareExportData(false);
                            setExportDownloadReady(false);
                            setExportReadyReviews([]);
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ padding: 24 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "16px" }}
                        >
                            {"Please confirm the following export criteria: "}
                        </Typography>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "16px" }}
                        >
                            {"All customer reviews"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon />}
                            onClick={async () => {
                                setShowExportProgress(true);
                                setPrepareExportData(true);

                                // handles the formatting of the CSVData
                                await _getReviewList();

                                setPrepareExportData(false);
                            }}
                            disabled={prepareExportData}
                            style={{ marginTop: "24px" }}
                        >
                            Prepare Export
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {prepareExportData && (
                            <>
                                <br></br>
                                <Typography variant="body1">
                                    {`We are preparing the data for export. This may take a while.`}
                                </Typography>
                            </>
                        )}
                    </Grid>
                    {showExportProgress && (
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                style={{
                                    paddingTop: "10px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                {exportProgress + "%"}
                            </Typography>
                            <LinearProgress
                                variant="buffer"
                                value={exportProgress}
                                valueBuffer={exportProgress}
                            />
                        </Grid>
                    )}
                    {exportDownloadReady && (
                        <>
                            <br></br>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {`Please click the download button below to download all customer reviews/ratings.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CSVLink
                                    data={exportReadyReviews}
                                    headers={csvHeaders}
                                    filename={`providerreviews_${moment(
                                        Date.now()
                                    ).format("YYYY-MM-DD")}.csv`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetAppIcon />}
                                    >
                                        Download
                                    </Button>
                                </CSVLink>
                            </Grid>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                open={noRatingsFoundDialog}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <Typography variant="h6">No Ratings Found</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setNoRatingsFoundExportDialog(false);
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ paddingTop: "16px" }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "9px", fontSize: "14px" }}
                        >
                            {
                                "Clients can leave ratings for their providers once their booking is marked completed."
                            }
                        </Typography>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ fontSize: "14px" }}
                        >
                            {"Visit our "}
                            <Link
                                href="https://www.gomarketbox.com/help"
                                underline="none"
                                target="_blank"
                                style={{
                                    fontFamily: "Roboto",
                                    color: "#0087ee"
                                }}
                            >
                                {"Help Center"}
                            </Link>{" "}
                            {" to learn more about our Ratings Feature"}
                        </Typography>
                    </Grid>
                </DialogContent>
            </Dialog>

            <ListItem button onClick={() => handleExportClick()}>
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Customer reviews" />
            </ListItem>
        </>
    );
};

export default ExportProvidersReviews;
