import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import { useStyles } from "../styles/MainContainerStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation, postApi } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import validator from "validator";
import { chargeCreditCard } from "./BillingCommon";
import { Autocomplete } from "@mui/material";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { AmountInputField } from "../styles/CustomMuiStyles";
// prettier-ignore
import { Paper, Grid, FormControl, Typography, CircularProgress } from "@mui/material";
// prettier-ignore
import { TextField, Button, Snackbar, InputLabel, MenuItem, Select } from "@mui/material";
import { getUserFromCache, getCompanyAdmins } from "../user/UserCommon";
import { execReadByPK } from "../modules/DBService";
import { ordersByCompanyOrderNo } from "../queries/ListBookingsQueries";
import { servicesOfCompany } from "../queries/AdhocBookingQueries";
import { auditChargesRefunds } from "../modules/Audit";

function MiscBillingTransaction(props) {
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    const { state, dispatch, actions } = useContext(StoreContext);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [clients, setClients] = useState([]);
    const [clientTags, setClientTags] = useState([]);
    const [orders, setOrders] = useState([]);
    const [clientCharges, setClientCharges] = useState([]);
    const [orderId, setOrderId] = useState("");
    const [clientId, setClientId] = useState("");
    const [values, setValues] = useState({
        id: "",
        title: ""
    });
    const [prevChargeId, setPrevChargeId] = useState("");
    const [serviceTypes, setServiceTypes] = useState([]);
    const [servicetype, setServiceType] = useState("");
    const [credit, setCredit] = useState(false);
    const [amount, setAmount] = useState(0.0);
    const [showCharges, setShowCharges] = useState(false);
    const [desc, setDesc] = useState("");
    const logger = new ConsoleLogger("MiscBilling");
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [saveClicked, setSaveClicked] = useState(false);

    async function getClients(user) {
        try {
            logger.debug("In getClients. user = " + JSON.stringify(user));
            let customersList = [];
            let nextToken = null;
            do {
                let result = await graphql(
                    graphqlOperation(queries.clientByCompany, {
                        companyId: user.company.id,
                        limit: process.env.REACT_APP_LISTLIMIT,
                        nextToken
                    })
                );
                if (result && result.data && result.data.clientByCompany) {
                    customersList.push(...result.data.clientByCompany.items);
                    if (result.data.clientByCompany.nextToken) {
                        nextToken = result.data.clientByCompany.nextToken;
                    } else {
                        nextToken = null;
                    }
                } else nextToken = null;
            } while (nextToken);

            if (customersList && customersList.length > 0) {
                const filteredResult = customersList.filter((item) => {
                    return item.user && item.user.deleted != true;
                });
                setClients(filteredResult);
                setClientTags(computeClientTags(filteredResult));
            }
        } catch (err) {
            logger.error(`Error fetching clients `, err);
        }
    }

    async function getServiceTypes(user) {
        try {
            const filter = {
                and: [
                    { active: { ne: false } },
                    { deleted: { ne: true } },
                    { isVisible: { ne: false } },
                    { behavior: { ne: "MANUAL" } }
                ]
            };
            const response = await graphql(
                graphqlOperation(servicesOfCompany, {
                    companyId: user.company.id,
                    filter,
                    limit: process.env.REACT_APP_LISTLIMIT
                })
            );
            const result = response.data.serviceTypeByCompany.items;
            setServiceTypes(sortByOrdinal(result));
        } catch (err) {
            logger.error(`Error fetching service types `, err);
        }
    }

    function sortByOrdinal(services) {
        if (services) {
            return services.sort((s1, s2) => {
                if (s1.category.ordinal > s2.category.ordinal) return 1;
                if (s1.category.ordinal < s2.category.ordinal) return -1;
                if (s1.category.id === s2.category.id) {
                    if (s1.ordinal > s2.ordinal) return 1;
                    if (s1.ordinal < s2.ordinal) return -1;
                    return 0;
                }
                return 0;
            });
        }
        return [];
    }
    async function getOrders(clientId) {
        try {
            logger.debug(
                "In getOrders. Searching for order for client " + clientId
            );
            const filter = {
                clientId: { eq: clientId }
            };
            const loggedInUser = getUserFromCache();

            const result = await execReadByPK({
                opname: "ordersByCompanyOrderNo",
                op: ordersByCompanyOrderNo,
                id: { companyId: loggedInUser.company.id },
                filter: filter,
                sortDirection: "DESC"
            });
            logger.debug("result order = ", result);

            if (result && result.items && result.items.length > 0) {
                setOrders(result.items);
            }
        } catch (err) {
            logger.error(`Error fetching orders `, err);
        }
    }

    async function getPrevCharges(selectedOrderId, orderList) {
        try {
            if (selectedOrderId) {
                let clientChargesOfOrder = await graphql(
                    graphqlOperation(queries.clientChargeByOrder, {
                        orderId: selectedOrderId
                    })
                );
                if (
                    clientChargesOfOrder &&
                    clientChargesOfOrder.data.clientChargeByOrder &&
                    clientChargesOfOrder.data.clientChargeByOrder.items
                )
                    setClientCharges(
                        clientChargesOfOrder.data.clientChargeByOrder.items
                    );
            } else {
                if (orderList) {
                    let charges = [];
                    for (let o of orderList) {
                        let clientChargesOfOrder = await graphql(
                            graphqlOperation(queries.clientChargeByOrder, {
                                orderId: o.id
                            })
                        );
                        if (
                            clientChargesOfOrder &&
                            clientChargesOfOrder.data.clientChargeByOrder &&
                            clientChargesOfOrder.data.clientChargeByOrder.items
                        )
                            charges = charges.concat(
                                clientChargesOfOrder.data.clientChargeByOrder
                                    .items
                            );
                    }
                    setClientCharges(charges);
                }
            }
        } catch (err) {
            logger.error(`Error fetching client charges `, err);
        }
    }

    function findClient(clientId) {
        let theClient = clients.filter((item) => item.id === clientId);
        logger.debug(theClient);
        return theClient[0];
    }
    function findOrder(orderId) {
        let theOrder = orders.filter((item) => item.id === orderId);
        logger.debug(theOrder);
        return theOrder[0];
    }

    // This function is needed to enable a better search engine for clients
    // Without this function, the Autocomplete component would use the clients array
    // and a user could input a proper name such as "Cli" and be returned all options instead
    // of just the user with that name.
    // @param: array of clients
    // output: an array of non-null client tags
    function computeClientTags(clients) {
        // clientObjects is an array subset of the clients array
        // It contains a title, which will be displayed to the user,
        // along with a needed id for the onChange function in the Autocomplete component
        let clientObjects = clients.map((option) => {
            if (
                option != null &&
                option.user != null &&
                option.user.emailaddress != null &&
                option.user.firstname != null &&
                option.user.emailaddress != null &&
                option.id != null
            )
                return {
                    title:
                        option.user.firstname +
                        " " +
                        option.user.lastname +
                        " (" +
                        option.user.emailaddress +
                        ")",
                    id: option.id
                };
        });
        // We filter out all of the undefined objects, which may be because
        // the user has no email address, first name, or last name.
        return clientObjects.filter(function (option) {
            if (typeof option != undefined) return option;
        });
    }

    const handleChangeClient = async (e, value) => {
        logger.debug("Unchecked Selected ID ", value);
        // need to check if the value from autocomplete is non-null
        // since it can be null if the user inputs nothing
        if (value) {
            logger.debug("Checked Selected ID ", value.id);
            setClientId(value.id);
            setValues({ id: value.id, title: value.title });
            await getOrders(value.id);
            // await getPrevCharges(value.id);
        }
        // user has selected nothing, so we reset the states to null
        else {
            setClientId(null);
            setOrders(null);
            setClientCharges(null);
            setValues({ id: "", title: "" });
        }
    };

    const handleChangeOrder = (e) => {
        setOrderId(e.target.value);
    };

    const handleChangePrevCharge = (e) => {
        setPrevChargeId(e.target.value);
    };

    const handleChangeServiceType = (e) => {
        setServiceType(e.target.value);
    };

    const handleChangeDesc = (e) => {
        setDesc(e.target.value);
    };

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
    };

    const handleChangeCredit = (e) => {
        setCredit(e.target.checked);
    };

    function handleCancel() {
        // cancel and go back to providerform
        actions.setPage("AdminForm");
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    // function getStripeAmount() {
    //     // return stripe amount:
    //     // 1. multiply by 100 as stripe only accepts int values and assumes 2 digit cents
    //     let stripeAmount = amount * 100;
    //     return stripeAmount;
    // }

    function getStripeAmount() {
        let num_check = (parseFloat(amount) * 100).toString();
        const decimal_pos = num_check.indexOf(".");
        if (decimal_pos === -1) {
            return parseInt(num_check);
        } else {
            const rounded_num = num_check.charAt(decimal_pos + 1);
            if (parseInt(rounded_num) >= 5) {
                return parseInt(num_check) + 1;
            } else if (parseInt(rounded_num) < 5) {
                return parseInt(num_check);
            }
        }
    }

    function reset() {
        // clear all fields
        setDesc("");
        setAmount(0.0);
        setCredit(false);
        setServiceType("");
        setClientId("");
        setOrderId("");
        setValues({ id: "", title: "" });
    }
    // load provider data if in edit mode
    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        if (mounted) {
            // get user data from cache
            const authuser = getUserFromCache();
            setLoggedInUser(authuser);
            getClients(authuser);
            getServiceTypes(authuser);
        }
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    }, []);

    useEffect(() => {
        async function fetchClientChargesOfOrders() {
            await getPrevCharges(orderId, orders);
        }
        fetchClientChargesOfOrders();
    }, [orders, orderId]);

    function handleErrorMsg(item) {
        if (!credit) {
            if (!item.charge && item.message) {
                setSnackMsg(item.message);
                setMsgOpen(true);
                setSaveClicked(false);
                return true;
            } else if (item.error) {
                setSnackMsg(item.error.raw.message);
                setMsgOpen(true);
                setSaveClicked(false);
                return true;
            } else if (item.charge && !item.message) {
                if (item.charge.failure_message != null) {
                    setSnackMsg(item.charge.failure_message);
                    setMsgOpen(true);
                    setSaveClicked(false);
                    return true;
                }
            }
        } else if (credit && !item.refund) {
            //It is credit, but there is no refund. Case of double refund.
            if (
                item &&
                item.result &&
                item.result.raw &&
                item.result.raw.code &&
                item.result.raw.code === "charge_already_refunded"
            ) {
                setSnackMsg("Unable to process refund. " + item.message + ".");
                setMsgOpen(true);
                setSaveClicked(false);
                return true;
            }
        } else {
            //It is credit.
            console.log("asdf item", item);
            if (item.refund.failure_reason) {
                setSnackMsg(
                    "Refund failed, reason: ",
                    item.refund.failure_reason
                );
                setMsgOpen(true);
                setSaveClicked(false);
                return true;
            }
        }
    }

    async function handleSaveBilling() {
        setSaveClicked(true);
        // validate
        if (!clientId || clientId === "") {
            setSnackMsg("Please select a client for the transaction.");
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        if (credit && prevChargeId === "") {
            setSnackMsg(
                "Please select a previous charge to apply the refund to."
            );
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        if (validator.isFloat(amount.toString())) {
            if (amount <= 0) {
                setSnackMsg(
                    "Please specify a positive amount for the transaction. If the transaction is a credit, please enable the Credit Amount button."
                );
                setMsgOpen(true);
                setSaveClicked(false);
                return;
            }
        } else {
            setSnackMsg("Please specify a valid amount for the transaction.");
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        if (!desc || desc === "") {
            setSnackMsg("Please enter a description for the transaction.");
            setMsgOpen(true);
            setSaveClicked(false);
            return;
        }

        let clientChargesData;
        for (var i = 0; i < clientCharges.length; i++) {
            if (clientCharges[i].stripechargeid === prevChargeId) {
                clientChargesData = clientCharges[i];
                break;
            }
        }

        // 1. Get Client and Order
        // 2. Create Billing Transaction
        // 3. Charge card and update clientCharge table
        // 4. Handle system action auditing
        // 5. Send SMS notifying client
        // 6. Send email notifying Client and Admin (TODO)

        try {
            // get client from clientId
            const theclient = findClient(clientId);
            logger.debug("theclient = ");
            logger.debug(theclient);

            // get order from orderId
            let theorder = null;
            if (orderId) {
                theorder = findOrder(orderId);
                logger.debug("theorder = ");
                logger.debug(theorder);
            }
            let input = {
                billingTransactionClientId: clientId,
                clientId: clientId,
                amount: parseFloat(amount).toFixed(2),
                billingTransactionCreatorId: loggedInUser.id,
                creatorId: loggedInUser.id,
                billingTransactionCompanyId: loggedInUser.company.id,
                companyId: loggedInUser.company.id,
                desc: desc,
                credit: credit
            };

            if (orderId) {
                input = {
                    ...input,
                    billingTransactionOrderId: orderId,
                    orderId: orderId
                };
            }

            let serviceTypeName = "";
            if (servicetype) {
                input = {
                    ...input,
                    billingTransactionAddservicetypeId: servicetype,
                    addservicetypeid: servicetype
                };

                // Get service type name by servicetype
                const serviceTypeResult = await graphql(
                    graphqlOperation(queries.getServiceType, {
                        id: servicetype
                    })
                );
                serviceTypeName = serviceTypeResult.data.getServiceType.name;
            }

            logger.debug("input = ");
            logger.debug(input);

            const result = await graphql(
                graphqlOperation(mutations.createBillingTransaction, { input })
            );

            if (result && result.data) {
                logger.debug("*** result = " + JSON.stringify(result));
                const billingTransactionId =
                    result.data.createBillingTransaction.id;
                // data saved correctly, now update Stripe
                const stripe_result = await chargeCreditCard(
                    theclient.stripeCustomerId,
                    getStripeAmount(),
                    theorder
                        ? theorder.currency
                        : loggedInUser.company.currency,
                    theclient.user.emailaddress,
                    credit,
                    prevChargeId,
                    loggedInUser.company.id,
                    loggedInUser.company.stripeAccount,
                    loggedInUser.company.stripeConnectEnabled
                );
                logger.debug("stripe_result = ");
                logger.debug(stripe_result);

                console.log("asdf result", stripe_result);

                if (handleErrorMsg(stripe_result) == true) {
                    return;
                }

                // now we can save the data to the ClientCharge table
                const dateNow = new Date();
                const options = { dateStyle: "long" };
                const dateNowString =
                    dateNow.toLocaleDateString("en-US", options) + // use en-US for now
                    " " +
                    dateNow.toLocaleTimeString();
                input = {
                    clientId: clientId,
                    clientChargeClientId: clientId,
                    companyId: loggedInUser.company.id,
                    description:
                        "Misc Billing Transaction created on " + dateNowString,
                    status: "created",
                    stripechargeid: credit
                        ? stripe_result.refund.id
                        : stripe_result.charge.id,
                    stripepaymentmethod: credit
                        ? "refund"
                        : stripe_result.charge.payment_method,
                    stripe_payment_method_details: credit
                        ? "refund"
                        : stripe_result.charge.stripe_payment_method_details,
                    balance_transaction: credit
                        ? stripe_result.refund.balance_transaction
                        : stripe_result.charge.balance_transaction,
                    stripe_refund_charge: credit
                        ? stripe_result.refund.charge
                        : stripe_result.charge.id,
                    stripe_status: credit
                        ? stripe_result.refund.status
                        : stripe_result.charge.status,
                    clientChargeBillingtransactionId: billingTransactionId,
                    billingtransactionId: billingTransactionId,
                    amount: credit ? 0 : parseFloat(amount).toFixed(2),
                    amount_refunded: credit ? parseFloat(amount).toFixed(2) : 0,
                    currency: theorder
                        ? theorder.currency
                        : loggedInUser.company.currency
                };

                if (theorder) {
                    input = {
                        ...input,
                        clientChargeOrderId: orderId,
                        orderId: orderId
                    };
                }

                //Charging client
                const resul2 = await graphql(
                    graphqlOperation(mutations.createClientCharge, { input })
                );

                //Handle system action auditing
                let auditInput = credit
                    ? result.data.createBillingTransaction
                    : resul2.data.createClientCharge;

                await auditChargesRefunds(
                    loggedInUser,
                    result.data.createBillingTransaction,
                    clientChargesData
                );
                if (theorder) {
                    let newOrderSummary = JSON.parse(
                        theorder.orderSummary ? theorder.orderSummary : {}
                    );
                    let miscBillingTotal = parseFloat(
                        newOrderSummary.miscBillingTotal
                            ? newOrderSummary.miscBillingTotal
                            : "0"
                    );
                    if (credit) {
                        miscBillingTotal = Number.parseFloat(
                            (
                                miscBillingTotal -
                                Number.parseFloat(parseFloat(amount).toFixed(2))
                            ).toFixed(2)
                        );
                    } else {
                        miscBillingTotal = Number.parseFloat(
                            (
                                miscBillingTotal +
                                Number.parseFloat(parseFloat(amount).toFixed(2))
                            ).toFixed(2)
                        );
                    }
                    newOrderSummary.miscBillingTotal = miscBillingTotal;
                    newOrderSummary = JSON.stringify(newOrderSummary);
                    const updateOrder = await graphql(
                        graphqlOperation(mutations.updateOrder, {
                            input: {
                                id: orderId,
                                orderSummary: newOrderSummary
                            }
                        })
                    );
                    console.log("asdf update order result = ", updateOrder);
                }

                //Send SMS message to client notifying additional charge
                const totalAmt = Number.parseFloat(amount).toFixed(2);
                const companyName = loggedInUser.company.name;
                const companyEmail = loggedInUser.company.emailaddress;
                const companycode = loggedInUser.company.countrycode3166alpha2
                    ? "en-" +
                      loggedInUser.company.countrycode3166alpha2.toUpperCase()
                    : "en-CA";
                logger.debug("companycode = " + companycode);

                const creditCharge = credit ? "refund" : "charge";

                // Handle sending email

                //Get admins
                const companyAdminsList = await getCompanyAdmins(
                    loggedInUser.company.id
                );
                let companyAdminsEmailList = [];
                for (let companyAdmin of companyAdminsList) {
                    companyAdminsEmailList.push(companyAdmin.emailaddress);
                }
                const companyAdmins = companyAdminsEmailList;

                //Prepare constants
                // const companyName = theorder.company.name;
                // const companyEmail = theorder.company.emailaddress;
                const companyPhone = theclient.company.companyPhone
                    ? theclient.company.companyPhone
                    : "";
                const clientFirstName = theclient.user.firstname;
                const clientLastName = theclient.user.lastname;
                const clientLastNameInitial = clientLastName.charAt(0);
                const clientName = clientFirstName + " " + clientLastName;
                const chargeAmount = totalAmt;
                const orderNumber = theorder ? theorder.orderNo : "";
                const service_product = servicetype
                    ? " due to " + "'" + serviceTypeName + "'."
                    : ".";
                //notes is defined with desc
                //Prepare data obj
                const dataObj = {
                    company_name: companyName,
                    company_email: companyEmail,
                    company_phone: companyPhone,
                    client_firstname: clientFirstName,
                    client_lastNameInitial: clientLastNameInitial,
                    client_lastName: clientLastName,
                    dollar: chargeAmount,
                    credit: creditCharge,
                    order_no: orderNumber
                        ? " associated with Order Number: " + orderNumber
                        : "",
                    service_product: service_product,
                    notes: desc,
                    isRegisteredUser: theclient.user.registered
                };
                let emres = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName:
                                "client-transaction-notification.html",
                            subject: `${clientName} your ${companyName} account has received a ${creditCharge}.`,
                            body: null,
                            toAddresses: [theclient.user.emailaddress],
                            bccAddresses: companyAdmins,
                            replyTo: loggedInUser.company.replyemailaddress,
                            companyName: loggedInUser.company.name,
                            companyId: loggedInUser.company.id,
                            dataObj: dataObj,
                            companyLogoUrl: loggedInUser.company.logoUrl,
                            companyColor: loggedInUser.company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                        }
                    }
                );
                console.log("asdf result", emres);

                setSnackMsg("Billing transaction successfully processed.");
                setMsgOpen(true);
                reset();
            } else {
                setSnackMsg("Unable to save your changes.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.log("asdf error is ", error);
            if (credit && prevChargeId != "") {
                console.log(
                    "asdf credit and previous charge id is not empty",
                    prevChargeId
                );
                if (getStripeAmount() > clientChargesData.amount) {
                    setSnackMsg(
                        "Unable to process as credit/refund exceeds the previous charge."
                    );
                    setMsgOpen(true);
                    setSaveClicked(false);
                } else {
                    setSnackMsg("Unable to save your changes.");
                    setMsgOpen(true);
                }
            } else {
                console.log("asdf generic failure");
                setSnackMsg("Unable to save your changes.");
                setMsgOpen(true);
                console.error(
                    "An error occurred saving the billing transaction. The error was: ",
                    error
                );
            }
        }
        setSaveClicked(false);
    }

    // hide role element if user is editing themselves
    function DisplayClientCharges() {
        return (
            <div>
                {credit ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography>
                                For Non-Package Orders: <br />
                                If you wish to apply a full refund please use
                                the bookings page to cancel the booking.
                                <br />
                                This will automatically apply a full refund to
                                applicable bookings.
                                <br />
                                Please do not apply a manual refund in this
                                case.
                            </Typography>
                            <FormControl
                                margin="none"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor="select-charges">
                                    Please select an previous charge to apply a
                                    refund (mandatory)
                                </InputLabel>
                                <Select
                                    value={prevChargeId}
                                    onChange={handleChangePrevCharge}
                                    name="type"
                                >
                                    {clientCharges &&
                                        clientCharges.length > 0 &&
                                        clientCharges.map((item, i) => {
                                            return (
                                                <MenuItem
                                                    value={item.stripechargeid}
                                                    key={i}
                                                >
                                                    {item.description +
                                                        " ($" +
                                                        item.amount +
                                                        ")"}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    ""
                )}
            </div>
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography
                className={classes.title}
                sx={{ fontSize: "24px", fontWeight: 400 }}
                variant="h4"
                noWrap
            >
                Process Charges
            </Typography>
            <p></p>
            <Paper
                sx={{ paddingBottom: "1rem" }}
                className={classes.paperStyle}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="creator"
                                label="Created By"
                                disabled
                                className={classes.textField}
                                value={
                                    loggedInUser.firstname +
                                    " " +
                                    loggedInUser.lastname
                                }
                                margin="normal"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <Autocomplete
                                value={values}
                                loading={props.loading}
                                loadingText="Loading..."
                                noOptionsText="No Clients Found"
                                options={clientTags}
                                getOptionLabel={(option) =>
                                    option ? option.title : ""
                                }
                                className={classes.formControl}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label="Please select a client (mandatory)"
                                        InputProps={{
                                            ...params.InputProps
                                        }}
                                        InputLabelProps={{
                                            ...params.InputLabelProps
                                        }}
                                        fullWidth
                                    />
                                )}
                                onChange={async (e, v) => {
                                    await handleChangeClient(e, v);
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel id="select-clients">
                                Please select an order (optional)
                            </InputLabel>
                            <Select
                                labelId="select-order"
                                label="Please select an order (optional)"
                                value={orderId}
                                onChange={handleChangeOrder}
                                name="type"
                            >
                                {orders &&
                                    orders.length > 0 &&
                                    orders.map((item, i) => {
                                        return (
                                            <MenuItem value={item.id} key={i}>
                                                {item.desc && item.total
                                                    ? item.desc +
                                                      " ($" +
                                                      (
                                                          Math.round(
                                                              item.total * 100
                                                          ) / 100
                                                      ).toFixed(2) +
                                                      ")"
                                                    : item.desc
                                                      ? item.desc + " ($0.00)"
                                                      : ""}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel id="select-add-serv">
                                Please select an additional service type
                                (optional)
                            </InputLabel>
                            <Select
                                labelId="select-add-serv"
                                label="Please select an additional service type
                                (optional)"
                                value={servicetype}
                                onChange={handleChangeServiceType}
                                name="type"
                            >
                                {serviceTypes &&
                                    serviceTypes.length > 0 &&
                                    serviceTypes.map((item, i) => {
                                        return (
                                            <MenuItem value={item.id} key={i}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <AmountInputField
                                error={amount === ""}
                                value={amount}
                                setValue={setAmount}
                            ></AmountInputField>
                        </FormControl>
                    </Grid>
                </Grid>
                {/*<Grid container spacing={2}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                        {/*<FormControlLabel
                            margin="normal"
                            className={classes.formControl}
                            control={
                                <Switch
                                    checked={credit}
                                    onChange={handleChangeCredit}
                                    value={credit}
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox",
                                    }}
                                />
                            }
                            label="Credit Amount"
                        />
                        <Grid item>
                            <Switch
                                checked={credit}
                                onChange={handleChangeCredit}
                                value={credit}
                                color="primary"
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>
                                Credit Amount
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="An order is optional for additional charges and services. A previous order is mandatory to issue a credit because processing requires an original charge to apply a credit against. You can only apply a credit up to the limit of the order charge. These measures are to prevent fraud.">
                                <IconButton>
                                    <HelpOutlineIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                            </Grid>*/}
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="desc"
                                label="Description"
                                multiline
                                rows="6"
                                defaultValue=""
                                className={classes.textField}
                                value={desc}
                                onChange={handleChangeDesc}
                                margin="normal"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid
                    sx={{ margin: "1rem  0rem", gap: "1rem" }}
                    container
                    item
                    spacing={5}
                    xs={5}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveBilling}
                        disabled={saveClicked}
                    >
                        Save
                        {saveClicked && (
                            <CircularProgress
                                size={24}
                                className={classesnew.buttonProgress}
                            />
                        )}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Paper>
        </>
    );
}

export default MiscBillingTransaction;
