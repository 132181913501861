import React, { useContext, useEffect } from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Button,
    TableContainer,
    Menu,
    MenuItem,
    Tooltip,
    Fade
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { CustomerColumns } from "../utils/Constants";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from "../context/StoreContext";
import CurrencyFormat from "react-currency-format";
import { getCurrencySymbol } from "../utils";
import { MoreHoriz } from "@mui/icons-material";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import ChatIcon from "@mui/icons-material/Chat";
import CircleIcon from "@mui/icons-material/Circle";

const tableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        backgroundColor: "white",
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light",
                cursor: "pointer"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

export const CustomerTable = (props) => {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("customersTable")
    );
    const { rows, selectedRow } = props;

    const [selectedCustomer, setSelectedCustomer] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const columns = CustomerColumns;

    useEffect(() => {
        if (props.searching) {
            setPage(0);
        }
    }, [props.searching]);

    const handleThreeDotsClick = (event, id) => {
        console.log("thredotsclicked", id);
        console.log("Rows of the customer table", rows);
        setSelectedCustomer(id);
        setAnchorEl(event.currentTarget);
    };

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        setSelectedCustomer("");
    };

    const filteredSelectedCustomer = (str, itemId) => {
        let customerId = itemId ? itemId : selectedCustomer;
        let filteredCustomer = rows.filter((item) => item.id === customerId);
        if (str === "details") {
            props.showClientDialog(filteredCustomer[0], "EDIT");
        }
        if (str === "package") {
            props.showPackageDialog(filteredCustomer[0]);
        }
        if (str === "card") {
            props.showUpdateCCDialog(filteredCustomer[0]);
        }
    };
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("customersTable")) {
            setTableRowsSession("customersTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const _handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    };
    const _handleRowClick = (event, id) => {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    };

    return (
        <>
            <div>
                <TableContainer>
                    <Table sx={tableTheme}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align={
                                        columns.find(
                                            (column) =>
                                                column.name === "user.active"
                                        ).align
                                    }
                                    sx={{ width: "7%" }}
                                >
                                    Active
                                </TableCell>
                                <TableCell sx={{ width: "30%" }}>
                                    Client
                                </TableCell>
                                <TableCell
                                    align={
                                        columns.find(
                                            (column) =>
                                                column.name === "client.notes"
                                        ).align
                                    }
                                    sx={{ width: "10%" }}
                                >
                                    Notes
                                </TableCell>
                                <TableCell
                                    sx={{ width: "33%", minWidth: "28%" }}
                                >
                                    Address
                                </TableCell>
                                <TableCell
                                    align={
                                        columns.find(
                                            (column) =>
                                                column.name ===
                                                "stats.sales_ytd"
                                        ).align
                                    }
                                    sx={{ width: "8%" }}
                                >
                                    Sales YTD
                                </TableCell>
                                <TableCell
                                    align={
                                        columns.find(
                                            (column) =>
                                                column.name ===
                                                "user.registered"
                                        ).align
                                    }
                                    sx={{ width: "8%" }}
                                >
                                    Login
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.length > 0 &&
                                rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((item, i) => {
                                        return (
                                            <TableRow
                                                sx={{
                                                    backgroundColor:
                                                        selectedCustomer ===
                                                        item.id
                                                            ? "primary.light"
                                                            : "white"
                                                }}
                                                key={item.id}
                                                hover
                                                onClick={(event) =>
                                                    _handleRowClick(
                                                        event,
                                                        item.id
                                                    )
                                                }
                                            >
                                                {columns &&
                                                    columns.length > 0 &&
                                                    columns.map(
                                                        (column, indx) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={indx}
                                                                >
                                                                    <TableCell
                                                                        key={
                                                                            indx
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        className={
                                                                            classes[
                                                                                column
                                                                                    .className
                                                                            ]
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            setSelectedCustomer(
                                                                                item.id
                                                                            );
                                                                            filteredSelectedCustomer(
                                                                                "details",
                                                                                item.id
                                                                            );
                                                                            handleThreeDotsClose();
                                                                            _handleRowClick(
                                                                                event,
                                                                                item.id
                                                                            );
                                                                        }}
                                                                        onMouseEnter={(
                                                                            e
                                                                        ) => {
                                                                            setSelectedCustomer(
                                                                                item.id
                                                                            );
                                                                        }}
                                                                        onMouseLeave={(
                                                                            e
                                                                        ) => {
                                                                            setSelectedCustomer(
                                                                                ""
                                                                            );
                                                                        }}
                                                                    >
                                                                        {column.name ===
                                                                            "user.active" &&
                                                                            item.user && (
                                                                                <CircleIcon
                                                                                    style={{
                                                                                        height: "18px",
                                                                                        color:
                                                                                            item
                                                                                                .user
                                                                                                .active ===
                                                                                            true
                                                                                                ? props
                                                                                                      .loggedInUser
                                                                                                      .company
                                                                                                      .primaryColor
                                                                                                : "#DEDEDE"
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        {column.name ===
                                                                            "user.name" &&
                                                                        item.user &&
                                                                        item
                                                                            .user
                                                                            .firstname &&
                                                                        item
                                                                            .user
                                                                            .lastname ? (
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    flexDirection:
                                                                                        "column"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    className={
                                                                                        classes.customerNameText
                                                                                    }
                                                                                    style={{
                                                                                        color: "#000",
                                                                                        fontWeight:
                                                                                            "500"
                                                                                    }}
                                                                                    onMouseEnter={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target.style.textDecoration =
                                                                                            "underline";
                                                                                        setSelectedCustomer(
                                                                                            item.id
                                                                                        );
                                                                                    }}
                                                                                    onMouseLeave={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target.style.textDecoration =
                                                                                            "none";
                                                                                        setSelectedCustomer(
                                                                                            ""
                                                                                        );
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setSelectedCustomer(
                                                                                            item.id
                                                                                        );
                                                                                        filteredSelectedCustomer(
                                                                                            "details"
                                                                                        );
                                                                                        handleThreeDotsClose();
                                                                                    }}
                                                                                >
                                                                                    {item
                                                                                        .user
                                                                                        .firstname +
                                                                                        " " +
                                                                                        item
                                                                                            .user
                                                                                            .lastname}
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily:
                                                                                            "Roboto"
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .user
                                                                                            .emailaddress
                                                                                    }
                                                                                </span>
                                                                                {item
                                                                                    .user
                                                                                    .mobilephone &&
                                                                                    item
                                                                                        .user
                                                                                        .mobilephone
                                                                                        .length >
                                                                                        4 && (
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily:
                                                                                                    "Roboto"
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                item
                                                                                                    .user
                                                                                                    .mobilephone
                                                                                            }
                                                                                        </span>
                                                                                    )}
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {column.name ===
                                                                            "client.notes" && (
                                                                            <div
                                                                                onMouseEnter={(
                                                                                    e
                                                                                ) => {
                                                                                    setSelectedCustomer(
                                                                                        item.id
                                                                                    );
                                                                                }}
                                                                                onMouseLeave={(
                                                                                    e
                                                                                ) => {
                                                                                    setSelectedCustomer(
                                                                                        ""
                                                                                    );
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSelectedCustomer(
                                                                                        item.id
                                                                                    );
                                                                                    props.setFocusNotes(
                                                                                        true
                                                                                    );
                                                                                    filteredSelectedCustomer(
                                                                                        "details"
                                                                                    );
                                                                                    handleThreeDotsClose();
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    title={
                                                                                        <div
                                                                                            style={{
                                                                                                whiteSpace:
                                                                                                    "pre-line"
                                                                                            }}
                                                                                        >
                                                                                            {JSON.parse(
                                                                                                item.Notes
                                                                                            )
                                                                                                ?.notes
                                                                                                ? JSON.parse(
                                                                                                      item.Notes
                                                                                                  )
                                                                                                      .notes
                                                                                                : "Click here to add notes"}
                                                                                        </div>
                                                                                    }
                                                                                    placement="right"
                                                                                    arrow
                                                                                    TransitionComponent={
                                                                                        Fade
                                                                                    }
                                                                                >
                                                                                    <ChatIcon
                                                                                        style={{
                                                                                            color: JSON.parse(
                                                                                                item.Notes
                                                                                            )
                                                                                                ?.notes
                                                                                                ? props
                                                                                                      .loggedInUser
                                                                                                      .company
                                                                                                      .primaryColor
                                                                                                : "#c5c5c5",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {column.name ===
                                                                            "user.address" &&
                                                                        item.user &&
                                                                        item
                                                                            .user
                                                                            .addressoneline ? (
                                                                            <Typography
                                                                                sx={{
                                                                                    color: "primary.main",
                                                                                    cursor: "pointer",
                                                                                    fontWeight: 400
                                                                                }}
                                                                                onMouseEnter={(
                                                                                    e
                                                                                ) => {
                                                                                    e.target.style.textDecoration =
                                                                                        "underline";
                                                                                }}
                                                                                onMouseLeave={(
                                                                                    e
                                                                                ) => {
                                                                                    e.target.style.textDecoration =
                                                                                        "none";
                                                                                }}
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        `https://www.google.com/maps/search/?api=1&query=${item.user.addressoneline.replaceAll(
                                                                                            " ",
                                                                                            "+"
                                                                                        )}`,
                                                                                        "_blank"
                                                                                    );
                                                                                    setTimeout(
                                                                                        () => {
                                                                                            props.onCloseClientInfoDialog();
                                                                                        },
                                                                                        50
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item
                                                                                        .user
                                                                                        .addressoneline
                                                                                }
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {column.name ===
                                                                            "stats.sales_ytd" &&
                                                                            item.stats &&
                                                                            item
                                                                                .stats
                                                                                .sales_ytd && (
                                                                                <CurrencyFormat
                                                                                    value={
                                                                                        item
                                                                                            .stats
                                                                                            .sales_ytd
                                                                                    }
                                                                                    displayType={
                                                                                        "text"
                                                                                    }
                                                                                    thousandSeparator={
                                                                                        true
                                                                                    }
                                                                                    prefix={getCurrencySymbol()}
                                                                                    decimalScale={
                                                                                        2
                                                                                    }
                                                                                    fixedDecimalScale={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            )}
                                                                        {column.name ===
                                                                            "user.registered" &&
                                                                        item.user &&
                                                                        item
                                                                            .user
                                                                            .registered ===
                                                                            true
                                                                            ? "Yes"
                                                                            : column.name ===
                                                                                    "user.registered" &&
                                                                                item.user &&
                                                                                item
                                                                                    .user
                                                                                    .registered ===
                                                                                    false
                                                                              ? "No"
                                                                              : ""}

                                                                        {column.name ===
                                                                            "packagecredits" &&
                                                                            "No"}
                                                                    </TableCell>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                {props.showPurchasedPkgButton && (
                                                    <TableCell>
                                                        <MoreHoriz
                                                            sx={{
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={(e) =>
                                                                handleThreeDotsClick(
                                                                    e,
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                        <Menu
                                                            PaperProps={{
                                                                style: {
                                                                    width: 200,
                                                                    border: "1px solid #e5e5e5",
                                                                    boxShadow:
                                                                        "1px 1px #e5e5e5"
                                                                }
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={() => {
                                                                setAnchorEl(
                                                                    null
                                                                );
                                                                setSelectedCustomer(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            {props.showPurchasedPkgButton && (
                                                                <MenuItem
                                                                    sx={{
                                                                        fontWeight: 700,
                                                                        color: "#0087EE"
                                                                    }}
                                                                    onClick={() => {
                                                                        filteredSelectedCustomer(
                                                                            "package"
                                                                        );

                                                                        handleThreeDotsClose();
                                                                    }}
                                                                >
                                                                    Packages
                                                                </MenuItem>
                                                            )}
                                                        </Menu>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    {rows.length !== 0 && (
                        <Button
                            sx={{ display: props.searching ? "none" : "block" }}
                            variant="contained"
                            /* color={"#0087ee"} */
                            onClick={props.loadMore}
                            disabled={props.endOfList}
                        >
                            {props.endOfList
                                ? "No more clients to load"
                                : "Load more clients"}
                        </Button>
                    )}

                    <TablePagination
                        component={"div"}
                        sx={{
                            marginLeft: "auto",
                            "& .MuiToolbar-root": {
                                alignItems: "center",
                                "& .MuiInputBase-root": {
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "1.4375em"
                                },
                                "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                    {
                                        color: "rgba(0,0,0,0.5)",
                                        marginBottom: 0
                                    },
                                "& .MuiTablePagination-actions": {
                                    display: "flex",
                                    gap: "0.5rem",
                                    "& button": {
                                        border: "1px solid rgba(0,0,0,0.2)",
                                        borderRadius: "4px"
                                    },

                                    "& button:hover": {
                                        backgroundColor: "primary.light"
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={12}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    );
};
