import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import TopPanel from "../layout/TopPanel";
import GoogleFontLoader from "../../../../components/GoogleFontLoader";

const CheckoutLandingPage = (props) => {
    return (
        <Fragment>
            <GoogleFontLoader
                fonts={[{ font: "Poppins", weights: [400, 700, 900] }]}
            />
            {/* main grid that holds top panel and bottom panel */}
            <Grid
                container
                style={{ maxWidth: "1460px", justifyContent: "center" }}
            >
                <TopPanel
                    monthlyLookupKey={props.monthlyLookupKey}
                    annualLookupKey={props.annualLookupKey}
                    planType={props.planType}
                    width={props.width}
                    reactivate={props.reactivate ? true : false}
                />
            </Grid>
        </Fragment>
    );
};

export default CheckoutLandingPage;
