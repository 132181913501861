import { graphql, graphqlOperation } from "../modules/AmplifyServices";

export const listProvidersExtended = `query ListProviders(
  $filter: ModelProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstname
      lastname
      emailaddress
      phone
      pictures3key
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      ratingstarsavg
      numberofratings
      latitude
      longitude
      active
      company {
        id
      }
      servicetypes {
        items {
          id
          servicetype {
            id
            name
          }
        }  
        nextToken
      }
      skills {
        items {
          id
          skill {
            id
            name
          }
        }
      }
    }
    nextToken
  }
}
`;
export const providerByCompanyWithLocations = /* GraphQL */ `
    query ProviderByCompany(
        $companyId: String
        $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        providerByCompany(
            companyId: $companyId
            firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                emailaddress
                addressoneline
                locations {
                    items {
                        id
                        providerId
                        companyId
                        locationpath
                        name
                        desc
                        addressoneline
                        street
                        city
                        state
                        country
                        postalcode
                        longitude
                        latitude
                        timezone
                        maxtraveltype
                        traveldistance
                        traveldistanceunit
                        travelregions
                        active
                        deleted
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;
export const providerByCompany = `query ProviderByCompany(
  $companyId: String
  $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  providerByCompany(
    companyId: $companyId
    firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstname
      lastname
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      numberofschedules
      vmlink
      offersVirtualServices
      company {
        id
      }
      companyId
      servicetypes {
        items {
          id
          servicetype {
            id
            name
          }
        }  
        nextToken
      }
      skills {
        items {
          id
          skill {
            id
            name
          }
        }
      }
      locations {
        items {
          id
          name
          desc
          deleted
        }
        nextToken
      }  
      user {
        active
        registered
        role
        LoginInfo
      }  
      active
      deleted
      ratingstarsavg
      numberofratings
      permalink
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const providersForOverride = `query ProviderByCompany(
  $companyId: String
  $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  providerByCompany(
    companyId: $companyId
    firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstname
      lastname
      emailaddress
      phone
      addressoneline
      timezone
      servicetypes {
        items {
          id
          servicetype {
            id
            name
          }
        }  
        nextToken
      }
      active
      deleted
    }
    nextToken
  }
}
`;

export const listServiceTypes = `
  query
{
	listServiceTypes {
    items {
	    id,
  	  name,
      defaultminutes
    }
  }
}`;

function fetchProviderData(query, filter) {
    async function fetchGraphQLProviders(query, filter) {
        const response = await graphql(graphqlOperation(query, filter));
        return response.data.listProviders.items;
    }

    fetchGraphQLProviders(query, filter);
}

export const providerByCompanyForSearch = `query ProviderByCompany(
  $companyId: String
  $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  providerByCompany(
    companyId: $companyId
    firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstname
      lastname
      emailaddress
      phone
      active
      deleted

    }
    nextToken
  }
}
`;

export const serviceTypeByCompanyCustom = /* GraphQL */ `
    query ServiceTypeByCompany(
        $companyId: String
        $categoryNameName: ModelServiceTypeByCompanySortedByCategoryNameNameCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelServiceTypeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        serviceTypeByCompany(
            companyId: $companyId
            categoryNameName: $categoryNameName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                categoryId
                categoryName
                minutes
                price
                active
                deleted
                companyId
                s3key
                imagedesc
                offeredremote
                offeredonpremise
                isBundledService
                isVisible
                includedServices
                behavior
                ordinal
                taxexempt
                company {
                    id
                }
                category {
                    id
                    name
                    deleted
                    ordinal
                }
            }
            nextToken
        }
    }
`;

export const skillByCompanyCustom = /* GraphQL */ `
    query SkillByCompany(
        $companyId: String
        $name: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSkillFilterInput
        $limit: Int
        $nextToken: String
    ) {
        skillByCompany(
            companyId: $companyId
            name: $name
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                importance
                active
                deleted
            }
            nextToken
        }
    }
`;

export const locationByProvider = /* GraphQL */ `
    query LocationByProvider(
        $providerId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderLocationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        locationByProvider(
            providerId: $providerId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                providerId
                companyId
                locationpath
                name
                desc
                addressoneline
                street
                city
                state
                country
                postalcode
                longitude
                latitude
                timezone
                maxtraveltype
                traveldistance
                traveldistanceunit
                travelregions
                active
                deleted
            }
            nextToken
        }
    }
`;

export { fetchProviderData };
