import React, { Fragment, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function ExportDateRangePicker(props) {
    const ALLOW_EXPORT_DATE_RANGE = 90;
    const [fromDate, setFromDate] = useState(props.from);
    const [toDate, setToDate] = useState(props.to);
    const [maxDate, setMaxDate] = useState(
        new Date(props.from.getTime() + 86400000 * ALLOW_EXPORT_DATE_RANGE)
    );

    function handleFromDate(fd) {
        setFromDate(fd);
        const to = new Date(fd.getTime() + 86400000 * ALLOW_EXPORT_DATE_RANGE);
        setMaxDate(to);
        setToDate(to);
        props.setFrom(fd);
        props.setTo(to);
    }
    function handleToDate(td) {
        //check td is within 90 days
        const curfdplus90daysmilis =
            fromDate.getTime() + 86400000 * ALLOW_EXPORT_DATE_RANGE;
        const curtdmilis = td.getTime();
        if (curfdplus90daysmilis >= curtdmilis) {
            setToDate(td);
            props.setTo(td);
        }
    }

    return (
        <Fragment>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={customLocale}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <DatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            label="From"
                            value={fromDate}
                            onChange={handleFromDate}
                            renderInput={(params) => (
                                <TextField
                                    style={{ maxWidth: "200px" }}
                                    {...params}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            label="To"
                            value={toDate}
                            onChange={handleToDate}
                            maxDate={maxDate}
                            maxDateMessage="Please select a date range upto 90 days."
                            renderInput={(params) => (
                                <TextField
                                    style={{
                                        maxWidth: "200px"
                                    }}
                                    {...params}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Fragment>
    );
}

export default ExportDateRangePicker;
