import { createTheme } from "@mui/material/styles";

export const DatePickerTheme = ({ palette }) =>
    createTheme({
        palette,
        components: {
            MuiDialog: {
                paperWidth: {
                    width: "300px"
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: "36px",
                        overflow: "hidden"
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        height: "36px",
                        maxHeight: "36px !important"
                    },
                    input: {
                        padding: "0px !important",
                        height: "36px",
                        maxHeight: "36px !important"
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        padding: "7.95px 14px",
                        fontSize: "14px",
                        height: "36px",
                        maxHeight: "36px !important"
                    },
                    input: {
                        padding: "0px !important",
                        height: "36px",
                        maxHeight: "36px !important"
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "0px 36px 36px 36px !important"
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontSize: "14px"
                    },
                    h3: {
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "38px",
                        color: "black"
                    },
                    h4: {
                        fontSize: "34px"
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        minWidth: "90px",
                        lineHeight: "28px",
                        "&.Mui-disabled": {
                            color: "white"
                        }
                    },
                    text: {
                        display: "flex",
                        minWidth: "64px",
                        lineHeight: "16px",
                        padding: "0px",
                        alignItems: "center",
                        height: "24px"
                    }
                }
            }
        }
    });
