import React, { useEffect, useState } from "react";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import DateRangePicker from "../utils/CommonComonents/DateRangePicker";
import {
    Paper,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    Typography,
    Snackbar
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { BillingTable } from "../components/BillingTable";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import SearchIcon from "@mui/icons-material/Search";
import * as queries from "../graphql/queries";
import { clientChargeByCompanyForBilling } from "../queries/BillingChargesQueries";
import moment from "moment";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { getCurrencySymbol, getCurrencySymbolFromParam } from "../utils";
import { execReadBySortkey, execReadByPK } from "../modules/DBService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CSVLink } from "react-csv";
import { getUserFromCache } from "../user/UserCommon";
import { ClientSelector } from "../components/ClientSelector";
import { convertTo12HourFormat } from "../utils/Common/dateFormatter";

const dtFormat = new Intl.DateTimeFormat("en", {
    timeStyle: "long",
    dateStyle: "short"
});

function BillingForm(props) {
    const classes = usePromotionsStyles();
    const [filteredRows, setFilteredRows] = useState([]);
    const [role, setRole] = useState();
    const [grandTotal, setGrandTotal] = useState(`${getCurrencySymbol()}0.00`);
    const [clientFilter, setClientFilter] = useState([]);
    const [dateRangeChange, setDateRangeChange] = useState(true);
    const [showDateRange, setShowDateRange] = useState(false);
    // Set default date range to be MTD
    const [selectedFromDate, setSelectFromDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );
    const [selectedToDate, setSelectToDate] = useState(new Date());
    const [providerOptions, setProviderOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [numProviders, setNumProviders] = useState(0);
    const [selectedClients, setSelectedClients] = useState([]);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [clientToChargesData, setClientToChargesData] = useState([]);
    const [exportReadyCharges, setExportReadyCharges] = useState();
    const [generatedSummary, setGeneratedSummary] = useState(false);
    const [displayNone, setDisplayNone] = useState(true);
    useEffect(() => {
        setShowLoading(true);
        getProviderOptions();
        setShowLoading(false);
    }, []);

    const csvHeaders = [
        { label: "Date", key: "DATE" },
        { label: "Order number", key: "ORDERNUMBER" },
        { label: "Subtotal", key: "SUBTOTAL" },
        { label: "Service fee", key: "SERVICEFEE" },
        { label: "Tax", key: "TAXAMOUNT" },
        { label: "Total", key: "TOTAL" },
        { label: "Currency", key: "CURRENCY" },
        { label: "Billing type", key: "BILLINGTYPE" },
        { label: "Description", key: "DESCRIPTION" },
        { label: "Client ID", key: "CLIENTID" },
        { label: "Client first name", key: "CLIENTFIRSTNAME" },
        { label: "Client last name", key: "CLIENTLASTNAME" },
        { label: "Client email", key: "CLIENTEMAIL" },
        { label: "Provider first name", key: "PROVIDERFIRSTNAME" },
        { label: "Provider last name", key: "PROVIDERLASTNAME" },
        { label: "Provider email", key: "PROVIDEREMAIL" }
    ];

    const getProviderOptions = async () => {
        const user = getUserFromCache();
        try {
            const result = await execReadByPK({
                opname: "providerByCompany",
                op: queries.providerByCompany,
                id: { companyId: user.company.id },
                filter: {
                    deleted: { ne: true }
                },
                sortDirection: "ASC"
            });

            if (result.items.length === 0) {
                setProviderOptions([]);
                return;
            }

            setNumProviders(result.items.length);
            const totalProviderOptions = ["All"];
            result.items.map((item) => {
                totalProviderOptions.push({
                    firstName: item.firstname,
                    lastName: item.lastname,
                    email: item.emailaddress,
                    id: item.id
                });
            });
            setProviderOptions(totalProviderOptions);

            // We want to default the selected providers to all provider options
            setSelectedProviders(totalProviderOptions.slice(1));
        } catch (error) {
            console.log("Error retrieving provider options");
            console.log(error);
        }
    };

    async function computeExportCSVData(clientDataArray, grandTotalString) {
        const rows = [];

        if (clientDataArray && clientDataArray.length > 0) {
            clientDataArray.map((clientItem) => {
                let curClient = clientItem.client;
                if (curClient.charges && curClient.charges.length > 0) {
                    curClient.charges.map((item) => {
                        let curItem = {};
                        curItem.clientId = curClient.clientData.id;
                        curItem.clientFirstName =
                            curClient.clientData.firstName;
                        curItem.clientLastName = curClient.clientData.lastName;
                        curItem.clientEmail = curClient.clientData.email;
                        curItem.orderNumber = item.order
                            ? item.order.orderNo
                            : "";
                        curItem.desc = item.descriptionString
                            ? item.descriptionString
                            : "";
                        curItem.subtotal =
                            item.order.subtotal &&
                            item.description.includes("Payment for")
                                ? item.order.subtotal.toFixed(2)
                                : 0;
                        curItem.servicefee =
                            item.order.servicechargeamt &&
                            item.description.includes("Payment for")
                                ? item.order.servicechargeamt.toFixed(2)
                                : 0;
                        curItem.taxAmount =
                            item.order.taxamt &&
                            item.description.includes("Payment for")
                                ? item.order.taxamt.toFixed(2)
                                : 0;
                        curItem.total = item.amountValue;
                        curItem.currencyType = item.currency
                            ? item.currency
                            : "";
                        curItem.providerFirstName = item.provider
                            ? item.provider.firstName
                            : "";
                        curItem.providerLastName = item.provider
                            ? item.provider.lastName
                            : "";
                        curItem.providerEmail = item.provider
                            ? item.provider.email
                            : "";
                        curItem.dateString = convertTo12HourFormat(
                            item.dateString
                        );
                        curItem.billingType = item.description.includes(
                            "Client generated tip amount"
                        )
                            ? "Tip"
                            : item.description.includes("Payment for")
                              ? "Payment"
                              : item.description.includes(
                                      "Misc Billing Transaction"
                                  )
                                ? "Misc charge"
                                : item.description.includes(
                                        "Additional Charge Billing"
                                    )
                                  ? "Additional charge"
                                  : item.description.includes("Refund of")
                                    ? "Refund"
                                    : "Payment";
                        rows.push(curItem);
                    });
                }
                if (curClient.miscCharges && curClient.miscCharges.length > 0) {
                    curClient.miscCharges.map((item) => {
                        let curItem = {};
                        curItem.clientId = curClient.clientData.id;
                        curItem.clientFirstName =
                            curClient.clientData.firstName;
                        curItem.clientLastName = curClient.clientData.lastName;
                        curItem.clientEmail = curClient.clientData.email;
                        curItem.orderNumber = item.order
                            ? item.order.orderNo
                            : "Misc. Order";
                        curItem.desc = item.descriptionString
                            ? item.descriptionString
                            : "";
                        curItem.subtotal = 0;
                        curItem.servicefee = 0;
                        curItem.taxAmount = 0;
                        curItem.total = item.amountValue;
                        curItem.currencyType = item.currency
                            ? item.currency
                            : "";
                        curItem.providerFirstName = item.provider
                            ? item.provider.firstName
                            : "";
                        curItem.providerLastName = item.provider
                            ? item.provider.lastName
                            : "";
                        curItem.providerEmail = item.provider
                            ? item.provider.email
                            : "";
                        curItem.dateString = convertTo12HourFormat(
                            item.dateString
                        );
                        rows.push(curItem);
                    });
                }
            });
        }
        const csvData = rows.map((item) => {
            const {
                clientId: CLIENTID,
                clientFirstName: CLIENTFIRSTNAME,
                clientLastName: CLIENTLASTNAME,
                clientEmail: CLIENTEMAIL,
                orderNumber: ORDERNUMBER,
                desc: DESCRIPTION,
                providerFirstName: PROVIDERFIRSTNAME,
                providerLastName: PROVIDERLASTNAME,
                providerEmail: PROVIDEREMAIL,
                dateString: DATE,
                subtotal: SUBTOTAL,
                servicefee: SERVICEFEE,
                taxAmount: TAXAMOUNT,
                total: TOTAL,
                currencyType: CURRENCY,
                billingType: BILLINGTYPE
            } = item;
            const newItem = {
                CLIENTID,
                CLIENTFIRSTNAME,
                CLIENTLASTNAME,
                CLIENTEMAIL,
                ORDERNUMBER,
                DESCRIPTION,
                PROVIDERFIRSTNAME,
                PROVIDERLASTNAME,
                PROVIDEREMAIL,
                DATE,
                SUBTOTAL,
                SERVICEFEE,
                TAXAMOUNT,
                TOTAL,
                CURRENCY,
                BILLINGTYPE
            };
            return newItem;
        });
        setExportReadyCharges(csvData);
    }

    const handleSelectProvider = (e, value) => {
        if (value === "All") {
            setSelectedProviders(providerOptions.slice(1));
        } else if (value) {
            setSelectedProviders([value]);
        } else setSelectedProviders([]);
    };

    const handleSelectClient = (value) => {
        if (value) {
            setSelectedClients([
                {
                    firstName: value.user.firstname ? value.user.firstname : "",
                    lastName: value.user.lastname ? value.user.lastname : "",
                    email: value.user.emailaddress
                        ? value.user.emailaddress
                        : "",
                    id: value.userId ? value.userId : ""
                }
            ]);
        } else setSelectedClients([]);
    };

    const handleGenerateSummary = async (e, value) => {
        setDisplayNone(true);
        setGeneratedSummary(true);
        const user = getUserFromCache();
        setShowLoading(true);
        if (!selectedProviders || !selectedProviders.length) {
            setSnackMsg("Please select a provider.");
            setShowLoading(false);
            setMsgOpen(true);
            return;
        }

        let newSelectedToDate = new Date(selectedToDate);
        newSelectedToDate.setHours(23);
        newSelectedToDate.setMinutes(59);
        newSelectedToDate.setSeconds(59);
        newSelectedToDate.setMilliseconds(999);
        const chargesResult = await execReadBySortkey({
            opname: "clientChargeByCompany",
            op: clientChargeByCompanyForBilling,
            id: {
                companyId: user.company.id
            },
            skey: {
                createdAtId: {
                    between: [
                        {
                            createdAt: `${selectedFromDate.toISOString()}`
                        },
                        {
                            createdAt: `${newSelectedToDate.toISOString()}`
                        }
                    ]
                }
            }
        });
        // Create a map of each client to the array of charges associated with that client
        const clientToCharges = new Map();
        if (selectedClients && selectedClients.length) {
            selectedClients.map((item) => {
                return clientToCharges.set(item.id, {
                    ["charges"]: [],
                    ["miscCharges"]: [],
                    clientData: item
                });
            });
        } else {
            //if "All" clients, use the user object from the chargesResult
            chargesResult.items.map((item) => {
                return clientToCharges.set(item.client.userId, {
                    ["charges"]: [],
                    ["miscCharges"]: [],
                    clientData: {
                        firstName: item.client.user.firstname
                            ? item.client.user.firstname
                            : "",
                        lastName: item.client.user.lastname
                            ? item.client.user.lastname
                            : "",
                        email: item.client.user.emailaddress
                            ? item.client.user.emailaddress
                            : "",
                        id: item.client.userId ? item.client.userId : ""
                    }
                });
            });
        }

        // Create a map of provider IDs to allow for linear search access
        const providerIdMap = new Map();
        selectedProviders.map((item) => {
            providerIdMap.set(item.id, item);
        });

        // Parse through each order, and set the provider value for each charge.
        // Parse through the charges found in the selected time period, and assign
        // only those with the selected providers and matching clients to clientToCharges mapping.

        chargesResult.items.map((currentCharge, index) => {
            if (
                currentCharge.order &&
                currentCharge.order.providerId &&
                clientToCharges.has(currentCharge.client.userId) &&
                providerIdMap.has(currentCharge.order.providerId)
            ) {
                // Setting the provider field if there doesn't exist one
                if (!currentCharge.provider) {
                    currentCharge.provider = providerIdMap.get(
                        currentCharge.order.providerId
                    );
                }
                //Display only if provider selected is all, or provider is same as provider selected.
                if (
                    selectedProviders.length === numProviders ||
                    currentCharge.provider.id == selectedProviders[0].id
                ) {
                    const objVal = clientToCharges.get(
                        currentCharge.client.userId
                    );
                    objVal.charges.push(currentCharge);
                    clientToCharges.set(currentCharge.client.userId, objVal);
                    setDisplayNone(false);
                }
            } else if (
                !currentCharge.order &&
                clientToCharges.has(currentCharge.client.userId)
            ) {
                //Since these have no related provider, only display when selected provider is "All"
                if (!currentCharge.provider || currentCharge.provider == "") {
                    currentCharge.provider = "N/A";
                }
                currentCharge.orderNo = "N/A";
                //If selectedProvider.length === numProviders, then all has been selected.
                if (selectedProviders.length === numProviders) {
                    const objVal = clientToCharges.get(
                        currentCharge.client.userId
                    );
                    objVal.miscCharges.push(currentCharge);
                    clientToCharges.set(currentCharge.client.userId, objVal);
                    setDisplayNone(false);
                }
            }
        });

        // Convert the mapping into an array
        const clientDataArray = Array.from(
            clientToCharges,
            ([clientId, client]) => ({
                client
            })
        );

        // Sort array such that the clients with the largest number of charges display first
        clientDataArray.sort((a, b) => {
            return a.client.charges.length + a.client.miscCharges.length <
                b.client.charges.length + b.client.miscCharges.length
                ? 1
                : a.client.charges.length + a.client.miscCharges.length >
                    b.client.charges.length + b.client.miscCharges.length
                  ? -1
                  : 1;
        });

        // We sort the charges by the order number, highest to lowest.
        clientDataArray.map((item) => {
            item.client.charges.sort((a, b) => {
                if (a.order.orderNo > b.order.orderNo) {
                    return -1;
                } else return 1;
            });
            item.client.miscCharges.sort((a, b) => {
                if (a.createdAt > b.createdAt) {
                    return -1;
                } else return 1;
            });
        });

        // Use a map to store the grand total for each currency
        let grandTotalMap = {};

        clientDataArray.map((item, clientIndex) => {
            let clientTotalMap = {};
            item.client.charges.map((charge, chargeIndex) => {
                let curAmount = 0;
                if (charge.amount) {
                    curAmount += charge.amount;
                }
                if (charge.amount_refunded) {
                    curAmount -= charge.amount_refunded;
                }
                charge.amountValue = curAmount;
                charge.amountString = `${
                    getCurrencySymbolFromParam(charge.currency) +
                    curAmount.toFixed(2).toString() +
                    " " +
                    charge.currency
                }`;
                charge.dateString = `${dtFormat.format(
                    new Date(charge.createdAt)
                )}`;
                charge.descriptionString = charge.billingtransaction
                    ? charge.billingtransaction.desc
                    : charge.desc
                      ? charge.desc
                      : charge.description
                        ? charge.description
                        : charge.order && charge.order.desc
                          ? charge.order.desc
                          : "";
                if (!!clientTotalMap[charge.currency]) {
                    clientTotalMap[charge.currency] += curAmount;
                } else {
                    clientTotalMap[charge.currency] = curAmount;
                }
            });
            item.client.miscCharges.map((charge, chargeIndex) => {
                let curAmount = 0;
                if (charge.amount) {
                    curAmount += charge.amount;
                }
                if (charge.amount_refunded) {
                    curAmount -= charge.amount_refunded;
                }
                charge.amountValue = curAmount;
                charge.amountString = `${
                    getCurrencySymbolFromParam(charge.currency) +
                    curAmount.toFixed(2).toString() +
                    " " +
                    charge.currency
                }`;
                charge.dateString = `${dtFormat.format(
                    new Date(charge.createdAt)
                )}`;
                charge.descriptionString = !charge.order
                    ? charge.billingtransaction
                        ? charge.billingtransaction.desc
                        : charge.desc
                    : charge.order
                      ? charge.order.desc
                      : charge.description
                        ? charge.description
                        : charge.desc
                          ? charge.desc
                          : "";
                if (!!clientTotalMap[charge.currency]) {
                    clientTotalMap[charge.currency] += curAmount;
                } else {
                    clientTotalMap[charge.currency] = curAmount;
                }
            });
            // Create the total string for each client
            const clientTotalStrings = [];
            Object.entries(clientTotalMap).forEach((item) => {
                clientTotalStrings.push(
                    `${getCurrencySymbolFromParam(item[0])}${item[1].toFixed(
                        2
                    )} ${item[0]}`
                );
                // Add each currency type to the grand total mapping
                if (!grandTotalMap[item[0]]) {
                    grandTotalMap[item[0]] = item[1];
                } else grandTotalMap[item[0]] += item[1];
            });

            let clientTotalSingleString = "";
            clientTotalStrings.map((item, index) => {
                if (index == 0) {
                    clientTotalSingleString += item;
                } else {
                    clientTotalSingleString += ", " + item;
                }
            });

            // Create the Total string for each client, and add to the grandTotal mapping
            if (item.client.charges[0] || item.client.miscCharges[0]) {
                item.client.totalChargeAmountString =
                    `Total: ` + clientTotalSingleString;
            } else {
                item.client.totalChargeAmountString = `Total: ${getCurrencySymbol()}0.00`;
            }
        });

        // We convert the grand total mapping into a string to be displayed as the grand total
        const grandTotalStrings = [];
        Object.entries(grandTotalMap).forEach((item) => {
            grandTotalStrings.push(
                `${getCurrencySymbolFromParam(item[0])}${item[1].toFixed(2)} ${
                    item[0]
                }`
            );
        });
        let grandTotalSingleString = "";
        grandTotalStrings.map((item, index) => {
            if (index == 0) {
                grandTotalSingleString += item;
            } else {
                grandTotalSingleString += ", " + item;
            }
        });
        setGrandTotal(grandTotalSingleString);
        setClientToChargesData(clientDataArray);
        computeExportCSVData(clientDataArray, grandTotalSingleString);
        setShowLoading(false);
    };
    const handleMsgClose = (e, value) => {
        setMsgOpen(false);
    };
    const handleOpenDateRange = () => {
        setShowDateRange(true);
    };

    const handleCloseDateRange = () => {
        setShowDateRange(false);
    };

    const handleChangeDateRange = (value) => {
        const today = new Date();
        if (value == "Last Week") {
            setSelectFromDate(
                new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - 7
                )
            );
            setSelectToDate(today);
            setShowDateRange(false);
            setDateRangeChange(true);
            return;
        } else if (value == "Last Month") {
            const today = new Date();
            setSelectFromDate(
                new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - 30
                )
            );
            setSelectToDate(today);
            setShowDateRange(false);
            setDateRangeChange(true);
            return;
        } else if (value == "Last Year") {
            const today = new Date();
            setSelectFromDate(new Date(today.getFullYear() - 1, 0, 1));
            setSelectToDate(new Date(today.getFullYear() - 1, 11, 31));
            setShowDateRange(false);
            setDateRangeChange(true);
            return;
        } else if (value == "MTD") {
            const today = new Date();
            setSelectFromDate(
                new Date(today.getFullYear(), today.getMonth(), 1)
            );
            setSelectToDate(today);
            setShowDateRange(false);
            setDateRangeChange(true);
            return;
        } else if (value == "YTD") {
            const today = new Date();
            setSelectFromDate(new Date(today.getFullYear(), 0, 1));
            setSelectToDate(today);
            setShowDateRange(false);
            setDateRangeChange(true);
            return;
        }
    };

    const handleApplyDateRange = () => {
        // Uncomment this if we want to refresh the data immediately after changing the date
        // handleGenerateSummary();
        setShowDateRange(false);
        setDateRangeChange(true);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <FormHeading title={"Billing Charges"} classes={classes} />
            <Paper rounded="true" className={classes.root}>
                <Dialog
                    open={showDateRange}
                    onClose={handleCloseDateRange}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <DialogContentText>
                            Choose a date range:
                        </DialogContentText>

                        <DialogActions>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs>
                                    <Button
                                        onClick={() =>
                                            handleChangeDateRange("Last Week")
                                        }
                                        color="primary"
                                        size="small"
                                    >
                                        Last Week
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        onClick={() =>
                                            handleChangeDateRange("Last Month")
                                        }
                                        color="primary"
                                        size="small"
                                    >
                                        Last Month
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        onClick={() =>
                                            handleChangeDateRange("Last Year")
                                        }
                                        color="primary"
                                        size="small"
                                    >
                                        Last Year
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        onClick={() =>
                                            handleChangeDateRange("MTD")
                                        }
                                        color="primary"
                                        size="small"
                                    >
                                        MTD
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        onClick={() =>
                                            handleChangeDateRange("YTD")
                                        }
                                        color="primary"
                                        size="small"
                                    >
                                        YTD
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DateRangePicker
                                    setFrom={setSelectFromDate}
                                    setTo={setSelectToDate}
                                    from={selectedFromDate}
                                    to={selectedToDate}
                                />
                            </Grid>
                        </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseDateRange} color="primary">
                            Close
                        </Button>
                        <Button onClick={handleApplyDateRange} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <ClientSelector
                            setClient={handleSelectClient}
                            defaultValue={{ title: `All` }}
                        />
                    </Grid>
                    <Grid item xs={1} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenDateRange}
                            startIcon={<DateRangeIcon />}
                        >
                            {dateRangeChange
                                ? `${moment(selectedFromDate).format(
                                      "MMM DD YYYY"
                                  )} to ${moment(selectedToDate).format(
                                      "MMM DD YYYY"
                                  )}`
                                : "Filter by Date"}
                        </Button>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleGenerateSummary}
                                startIcon={<SearchIcon />}
                            >
                                Search
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Autocomplete
                            defaultValue="All"
                            loading={props.loading}
                            loadingText="Loading..."
                            noOptionsText="No Providers Found"
                            options={providerOptions}
                            className={classes.formControl}
                            getOptionLabel={(option) => {
                                return option === "All"
                                    ? "All"
                                    : option.email
                                      ? option.firstName +
                                        " " +
                                        option.lastName +
                                        " (" +
                                        option.email +
                                        ")"
                                      : option.firstName +
                                        " " +
                                        option.lastName;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    {...params}
                                    label="Select Provider"
                                    InputProps={{
                                        ...params.InputProps
                                    }}
                                    InputLabelProps={{
                                        ...params.InputLabelProps
                                    }}
                                    fullWidth
                                />
                            )}
                            onChange={handleSelectProvider}
                        />
                    </Grid>
                    <Grid item xs={1} sm={6}>
                        {exportReadyCharges && exportReadyCharges.length && (
                            <CSVLink
                                data={exportReadyCharges}
                                headers={csvHeaders}
                                filename={`billing_${moment(Date.now()).format(
                                    "YYYY-MM-DD"
                                )}.csv`}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<GetAppIcon />}
                                >
                                    Export CSV
                                </Button>
                            </CSVLink>
                        )}
                    </Grid>
                    <Typography variant="h6">
                        Grand Total:{" "}
                        {grandTotal && grandTotal.length > 0
                            ? grandTotal
                            : `${getCurrencySymbol()}0.00`}
                    </Typography>
                </Grid>
                <Grid container spacing={3}>
                    {clientToChargesData &&
                        clientToChargesData.length != 0 &&
                        clientToChargesData.map((curClient) => {
                            const chargeRows = curClient.client.charges;
                            const miscChargeRows = curClient.client.miscCharges;
                            const clientTotalString =
                                curClient.client.totalChargeAmountString;
                            if (
                                chargeRows.length !== 0 ||
                                miscChargeRows.length !== 0
                            ) {
                                return (
                                    <Grid item xs={12}>
                                        <div className={classes.root}>
                                            <BillingTable
                                                chargeRows={chargeRows}
                                                miscChargeRows={miscChargeRows}
                                                columns={[
                                                    {
                                                        title:
                                                            "Billing Summary for " +
                                                            `${
                                                                curClient.client
                                                                    .clientData
                                                                    .firstName +
                                                                " " +
                                                                curClient.client
                                                                    .clientData
                                                                    .lastName
                                                            }`,
                                                        name: "description",
                                                        align: "left",
                                                        whiteSpace: "normal",
                                                        wordBreak: "break-word"
                                                    },
                                                    {
                                                        title: `${clientTotalString}`,
                                                        name: "total",
                                                        align: "right"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </Grid>
                                );
                            } else {
                                //Client has no relevant transactions, do not return anything.
                            }
                        })}
                    {displayNone && generatedSummary && (
                        <Grid item xs={12}>
                            <div>
                                <p>
                                    <b>NO RELEVANT TRANSACTIONS FOUND</b>
                                </p>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </>
    );
}

export default BillingForm;
