import React, { useState, useEffect } from "react";
// prettier-ignore
import { Grid, FormControlLabel, TextField, Typography, Snackbar, InputAdornment, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import { getJsonApi } from "../../modules/AmplifyServices";
import { Autocomplete } from "@mui/material";
import { useStyles } from "../../styles/MainContainerStyles";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

export const AddressEntryTextBox = (props) => {
    const classes = useStyles();
    const [optionsGM, setOptionsGM] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(
        props.addressOneLine ? props.addressOneLine : ""
    );
    const [place_id, setPlaceId] = useState();

    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const loaded = React.useRef(false);
    useEffect(() => {
        if (typeof window !== "undefined" && !loaded.current) {
            if (!document.querySelector("#google-maps")) {
                const url =
                    "https://maps.googleapis.com/maps/api/js?key=" +
                    process.env.REACT_APP_GOOGLE_MAP_KEY +
                    "&libraries=places";
                loadScript(url, document.querySelector("head"), "google-maps");
            }

            loaded.current = true;
        }
    }, []);

    let autocompleteService = props.autocompleteService;
    async function getPlaceDetails() {
        const result = await getJsonApi(
            "mapdirection",
            `/placedetails/${place_id}`
        );
        if (result && result.success) {
            return result.placedetails;
        } else {
            return null;
        }
    }
    const fetch = React.useMemo(
        () =>
            throttle((input, callback) => {
                autocompleteService.current.getPlacePredictions(
                    input,
                    callback
                );
            }, 200),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptionsGM([]);
            return ""; //undefined;
        }
        if (inputReady(inputValue))
            fetch({ input: inputValue }, (results) => {
                if (active) {
                    setOptionsGM(results || []);
                }
            });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    function inputReady(inp) {
        if (inp && inp.length > 3) {
            inp = inp.trimStart();
            if (inp.includes(" ")) {
                let spaceInd = inp.indexOf(" ");
                if (inp.length - spaceInd >= 5) return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (!props.addressOneLine) {
            setInputValue("");
        }
    }, [props.addressOneLine]);

    useEffect(() => {
        async function getPlaceAddress() {
            try {
                const placeDetails = await getPlaceDetails();
                if (placeDetails) {
                    if (
                        placeDetails.route ||
                        placeDetails.street_number ||
                        placeDetails.postal_code
                    ) {
                        setInputValue(placeDetails.addressOneLine);
                        props.setAddress(
                            placeDetails.addressOneLine,
                            placeDetails.route,
                            placeDetails.city,
                            placeDetails.state,
                            placeDetails.postal_code,
                            placeDetails.country,
                            placeDetails.location.lat,
                            placeDetails.location.lng,
                            true
                        );
                    } else {
                        setSnackMsg(
                            "Please provide a street address for your location."
                        );
                        setMsgOpen(true);
                    }
                } else {
                    console.log("Could not get place details.");
                }
            } catch (e) {
                console.log("error getting place: ", e);
            }
        }
        if (place_id) getPlaceAddress();
    }, [place_id]);

    const handleAddressChange = (event, values) => {
        try {
            setPlaceId(values.place_id);
            setIsOpen(false);
        } catch (err) {
            console.log(
                "An error occurred in handleAddressChange. Error was " +
                    JSON.stringify(err)
            );
        }
    };
    const handleChange = (event) => {
        setIsOpen(true);
        setInputValue(event.target.value);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={5000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <FormControlLabel
                sx={{ position: "relative" }}
                labelPlacement="top"
                style={
                    props.style
                        ? props.style
                        : {
                              width: "100%",
                              marginLeft: "0px",
                              marginTop: props.marginAbove ? "1rem" : "32px",
                              marginBottom: "16px",
                              display: "inline-block"
                          }
                }
                control={
                    <Autocomplete
                        disablePortal={true}
                        id="user-address"
                        inputValue={inputValue}
                        getOptionLabel={(optionGM) => {
                            return optionGM.description || "";
                        }}
                        filterOptions={(x) => x}
                        options={optionsGM}
                        classes={{
                            inputRoot: classes.inputRoot1
                        }}
                        autoComplete
                        open={isOpen}
                        onChange={handleAddressChange}
                        includeInputInList
                        freeSolo
                        openOnFocus
                        style={{
                            width: "100%",
                            outline: "5px solid red",
                            position: "relative"
                        }}
                        renderInput={(params) => (
                            <TextField
                                sx={{
                                    backgroundColor: "white",
                                    border: "2px solid #d4d4d4",
                                    borderRadius: "4px",
                                    "&:hover": { backgroundColor: "white" },
                                    "& .MuiFilledInput-root": {
                                        "&:hover": { backgroundColor: "white" },
                                        backgroundColor: "white",
                                        "&.Mui-focused": {
                                            backgroundColor: "white"
                                        }
                                    }
                                }}
                                variant="filled"
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment:
                                        props.fromDashboardInStore ||
                                        props.fromDashboardOnline ? (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title={
                                                        props.fromDashboardInStore
                                                            ? "Clients travel to this location and will see this address on your booking page"
                                                            : props.fromDashboardOnline
                                                              ? "This address is used to align schedules in different time zones"
                                                              : ""
                                                    }
                                                    placement="bottom"
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "20px",
                                                        marginBottom: "20px"
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        ) : props?.tooltipTitle ? (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "flex-end"
                                                }}
                                            >
                                                <Tooltip
                                                    title={props.tooltipTitle}
                                                    placement="bottom"
                                                    style={{
                                                        color: "#a2a2a2",
                                                        fontSize: "20px"
                                                    }}
                                                >
                                                    {<InfoIcon />}
                                                </Tooltip>
                                            </InputAdornment>
                                        ) : (
                                            ""
                                        )
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps
                                }}
                                label={
                                    props.label ? props.label : "Full address"
                                }
                                fullWidth
                                onChange={handleChange}
                            />
                        )}
                        renderOption={(props, option) => {
                            const matches =
                                option.structured_formatting
                                    .main_text_matched_substrings || [];

                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [
                                    match.offset,
                                    match.offset + match.length
                                ])
                            );

                            return (
                                <li sx={{ position: "relative" }} {...props}>
                                    <Grid
                                        sx={{ position: "relative" }}
                                        container
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <LocationOnIcon
                                                className={classes.icon}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight:
                                                            part.highlight
                                                                ? 700
                                                                : 400
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}

                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {
                                                    option.structured_formatting
                                                        .secondary_text
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                            );
                        }}
                    />
                }
            />
        </>
    );
};
