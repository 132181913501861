import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
    Tabs,
    Tab,
    Typography,
} from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from '../context/StoreContext';
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { getUserFromCache } from '../user/UserCommon';

import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import isFeatureGated from '../modules/FeatureGatingModule';
import FeatureGateDialog from '../components/FeatureGateDialog';
import StarsIcon from "@mui/icons-material/Stars";

const ServicePageTabs = () => {
    const { state, actions } = useContext(StoreContext);
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();
    const user = getUserFromCache();

    const selectedTab = location.pathname.includes("/locations") ? "/services/locations"
    : location.pathname.includes("/packages") ? "/services/packages"
    : location.pathname.includes("/promo-codes") ? "/services/promo-codes"
    : location.pathname.includes("/regional-pricing") ? "/services/regional-pricing"
    : "/services";

    const [showFeatureGateDialog, setShowFeatureGateDialog] = useState(false);

    const canAccessPackages = user && user.company && !user.company.singleApptOnly && !user.company.offersForeverAppt;
    const regionalPricingFeatureGated = isFeatureGated(user.company.subscriptionLevel, "RegionalPricing");

    const handleOnClose = () => {
        setShowFeatureGateDialog(false);
    };
    
    return (
    <>
        <FormHeading
            id="MBServicesForm"
            title={"Services"}
            classes={classes}
        />
        <Tabs value={selectedTab} indicatorColor="primary" textColor="primary">
            <Tab value="/services" onClick={() => navigate("/services")} label="Services" />
            <Tab value="/services/locations" onClick={() => {
                actions.setId(user.company.id)
                navigate("locations")
            }} label="Locations" />
            <Tab value="/services/packages" onClick={() => navigate("packages")} label="Packages" disabled={!canAccessPackages}/>
            <Tab value="/services/promo-codes" onClick={() => navigate("promo-codes")} label="Promo Codes" />
            <Tab value="/services/regional-pricing" label="Regional Pricing" icon={
                isFeatureGated(user.company.subscriptionLevel, "RegionalPricing") && (
                    <StarsIcon
                        style={{
                            color: "#fcaf17"
                        }}
                    />
                )
            } iconPosition="end" onClick={() => {
                if (regionalPricingFeatureGated) {
                    setShowFeatureGateDialog(true);
                } else {
                    navigate("regional-pricing");
                }
            }}>
            </Tab>
        </Tabs>
        <FeatureGateDialog open={showFeatureGateDialog} handleOnClose={handleOnClose}/>
        <Outlet/>
    </>
    );
};

export default ServicePageTabs;