import {
    graphql,
    graphqlOperation,
    postApi
} from "../../../modules/AmplifyServices";

async function sendSms(to, msg, countryCode) {
    let from = await getSenderNumberByCountry(countryCode);
    const result = await postApi("messaging", "/send", {
        body: {
            from,
            to,
            msg
        }
    });
    console.log(JSON.stringify(result));
}

async function getSenderNumberByCountry(countryCode) {
    const getRefData = /* GraphQL */ `
        query GetRefData($refType: ID!, $refName: String!) {
            getRefData(refType: $refType, refName: $refName) {
                refType
                refName
                refValue
            }
        }
    `;
    let resp = await graphql(
        graphqlOperation(getRefData, {
            refType: "TWILIO_FROM_NUM",
            refName: countryCode
        })
    );
    if (resp.data.getRefData) return resp.data.getRefData.refValue;
    else {
        resp = await graphql(
            graphqlOperation(getRefData, {
                refType: "TWILIO_FROM_NUM",
                refName: "DEFAULT"
            })
        );
        if (resp.data.getRefData) return resp.data.getRefData.refValue;
    }
    return process.env.REACT_APP_TWILIO_SMS_SEND_NUMBER;
}

async function sendFreeTrialSignupEmail(state) {
    const dataObj = {
        firstName: state.firstNameInput,
        lastName: state.lastNameInput,
        companyName: state.companyNameInput,
        emailAddress: state.emailAddress,
        phoneNumber: state.phoneNumber,
        numProviders: "1",
        requestedURL: state.desiredSubdomain + state.subdomainEnding.label,
        isRegisteredUser: true
    };

    // Send email
    const result = await postApi(
        "sendtwilioemail",
        "/sendtwilioemailtemplate",
        {
            body: {
                templateName: "signup-confirmation-code.html",
                subject: `MarketBox - New Company Sign Up`,
                body: null,
                toAddresses: [
                    "omar@gomarketbox.com",
                    "diana@gomarketbox.com",
                    "support@gomarketbox.com"
                ],
                ccAddresses: [],
                bccAddresses: [],
                companyName: "MarketBox",
                companyId: "marketBox",
                replyTo: "support@gomarketbox.com",
                dataObj: dataObj,
                authSiteUrl: "https://www.gomarketbox.com/"
            }
        }
    );
}

async function sendRegistrationEmail(temppas, state) {
    console.log("asdf sending registration email");

    const _usertype = "COMPANY_ADMIN";
    const _firstname = state.firstNameInput;
    const _lastname = state.lastNameInput;
    const _email = state.emailAddress;
    const _phoneNumber = state.cleanPhone;

    const _subdomain = state.desiredSubdomain + state.subdomainEnding.label;
    const companyAdminActions = `You can then use this site to administer your MarketBox site.<br><br>
        Clients visit https://${_subdomain} to book an appointment.<br><br>`;

    const dataObj = {
        user_firstname: _firstname,
        user_lastname: _lastname,
        user_email: _email,
        user_phone: _phoneNumber,
        user_phone_type: "mobile",
        user_temppas: temppas,
        user_actions: companyAdminActions,
        user_role: "COMPANY_ADMIN",
        company_email: state.emailAddress,
        company_name: state.companyNameInput,
        isRegisteredUser: true
    };
    console.log("asdf done making dataObj");

    const _templateName = "welcome-email-freetrial.html";
    const authSiteUrl = process.env.REACT_APP_AUTH_SITE_URL;
    let favicon;
    //Verify if favicon works... Also consider creating a new email template for this? idk.
    try {
        const result = await postApi(
            "sendtwilioemail",
            "/sendtwilioemailtemplate",
            {
                body: {
                    templateName: _templateName,
                    subject: `${_firstname} ${_lastname}, Welcome to MarketBox!`,
                    body: null,
                    toAddresses: [_email],
                    ccAddresses: [],
                    bccAddresses: [],
                    replyTo: "support@gomarketbox.com",
                    companyName: "MarketBox",
                    companyId: state.companyId,
                    dataObj: dataObj,
                    companyLogoUrl: favicon,
                    companyColor: "#7AA0CD",
                    authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                }
            }
        );
        console.log("asdf result", result);
    } catch (e) {
        console.log("asdf error while sending registration email", e);
    }

    // Send SMS to phone number (at least try)
    const companyName = state.companyNameInput;
    const uriEmail = encodeURIComponent(_email);
    const tempPassword = temppas;
    let smsBody = `Welcome to MarketBox! A login account has been created for you and an email sent to ${uriEmail}. `;
    smsBody += `Your temporary password is ${tempPassword} and expires in 7 days. `;
    smsBody += `Please login and reset your password before it expires at: ${authSiteUrl}.`;

    // get the from number - use CA since no company info.
    const from = await getSenderNumberByCountry("ca");

    // send the SMS
    const result = await postApi("messaging", "/send", {
        body: {
            from,
            to: _phoneNumber,
            msg: smsBody
        }
    });
}
export {
    sendSms,
    getSenderNumberByCountry,
    sendRegistrationEmail,
    sendFreeTrialSignupEmail
};
