export const regionalPricingByCompanyServiceType = /* GraphQL */ `
    query RegionalPricingByCompanyServiceType(
        $companyId: String
        $servicetypeId: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelRegionalPricingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        regionalPricingByCompanyServiceType(
            companyId: $companyId
            servicetypeId: $servicetypeId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                servicetypeId
                pricingtype
                country
                countryName
                province
                postalcodes
                price
                notes
                companyId
                active
                deleted
                createdAt
                updatedAt
                servicetype {
                    id
                    name
                    desc
                    categoryId
                    categoryName
                    minutes
                    price
                    active
                    deleted
                    companyId
                    s3key
                    imagedesc
                    offeredremote
                    offeredonpremise
                    isBundledService
                    isVisible
                    includedServices
                    behavior
                    ordinal
                    taxexempt
                }
            }
            nextToken
        }
    }
`;

export const packageByCompany = /* GraphQL */ `
    query PackageByCompany(
        $companyId: String
        $servicetypeIdCreatedAt: ModelPackageByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelPackageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        packageByCompany(
            companyId: $companyId
            servicetypeIdCreatedAt: $servicetypeIdCreatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                companyId
                servicetypeId
                packagetype
                price
                discount
                quantity
                active
                deleted
                createdAt
                updatedAt
                servicetype {
                    id
                    name
                    desc
                    categoryId
                    categoryName
                    minutes
                    price
                    active
                    deleted
                    companyId
                    s3key
                    imagedesc
                    offeredremote
                    offeredonpremise
                    isBundledService
                    isVisible
                    includedServices
                    behavior
                    ordinal
                    taxexempt
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;

export const providerRatingsByProvider = /* GraphQL */ `
    query ProviderRatingsByProvider(
        $providerId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderRatingsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        providerRatingsByProvider(
            providerId: $providerId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                providerId
                ratingstars
                ratingtext
                ratinguserId
                active
                companyId
            }
            nextToken
        }
    }
`;

export const serviceTypeCategoryByCompany = /* GraphQL */ `
    query ServiceTypeCategoryByCompany(
        $companyId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelServiceTypeCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        serviceTypeCategoryByCompany(
            companyId: $companyId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                desc
                companyId
                deleted
                ordinal
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const clientByCompany = /* GraphQL */ `
    query ClientByCompany(
        $companyId: String
        $id: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelClientFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientByCompany(
            companyId: $companyId
            id: $id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                userId
                companyId
                user {
                    id
                    firstname
                    lastname
                    emailaddress
                }
            }
            nextToken
        }
    }
`;

export const ordersByCompanyOrderNo = /* GraphQL */ `
    query OrdersByCompanyOrderNo(
        $companyId: String
        $orderNo: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ordersByCompanyOrderNo(
            companyId: $companyId
            orderNo: $orderNo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                clientId
                createdAt
            }
            nextToken
        }
    }
`;

// The next 2 queries are used for providerData exports, reads only the necessary data
export const bookingByProvider = /* GraphQL */ `
    query BookingByProvider(
        $providerId: String
        $startdateTimeblockid: ModelBookingByProviderCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingByProvider(
            providerId: $providerId
            startdateTimeblockid: $startdateTimeblockid
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                status
            }
            nextToken
        }
    }
`;

export const ordersByProviderCreatedAt = /* GraphQL */ `
    query OrdersByProviderCreatedAt(
        $providerId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ordersByProviderCreatedAt(
            providerId: $providerId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                type
                orderNo
            }
            nextToken
        }
    }
`;

export const listCompanys = /* GraphQL */ `
    query ListCompanys(
        $filter: ModelCompanyFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;
