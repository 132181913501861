import React from "react";
import { StoreContext } from "../context/StoreContext";
import {
    TextField,
    Box,
    Button,
    Paper,
    Grid,
    FormControl,
    Typography,
    Snackbar,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel
} from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { useFetchGraphQL } from "../hooks/useFetchGraphQL";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getUserFromCache } from "../user/UserCommon";

export default function PromotionAddForm(props) {
    const { state, dispatch, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //fields
    const [name, setName] = React.useState("");
    const [amount, setAmount] = React.useState("");

    const [date, setDate] = React.useState();
    const [enddate, setEndDate] = React.useState();

    const [description, setDescription] = React.useState("");
    const [active, setActive] = React.useState(true);
    const [isEdit, setisEdit] = React.useState(false);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    //type
    const [values, setValues] = React.useState({
        type: ""
    });
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    console.log(loggedInUser, "UserObj");
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        if (state.mode === "Edit") {
            getpromotionData();
        }
    }, []);
    // service type select
    const [serviceTypeOption, setServiceTypeOption] = React.useState(0);
    const [serviceTypeData, serviceTypeLoading] = useFetchGraphQL(
        queries.listServiceTypes,
        ""
    );
    //package select
    const [packageOption, setPackageOption] = React.useState(0);
    const [packagesTypeData, packagesLoading] = useFetchGraphQL(
        queries.listPackages,
        ""
    );

    async function getpromotionData() {
        const promotionId = state.id;
        if (!!promotionId) {
            setisEdit(true);
            const input = { id: promotionId };
            const result = await graphql(
                graphqlOperation(queries.getPromotion, input)
            );
            if (!(!!result.errors && result.errors.length > 0)) {
                const {
                    amount,
                    desc,
                    enddate,
                    startdate,
                    active,
                    promotiontype,
                    servicetype,
                    name
                } = result.data.getPromotion;
                setAmount(amount);
                setDescription(desc);
                setPackageOption(
                    result.data.getPromotion.package &&
                        result.data.getPromotion.package.id
                );
                setServiceTypeOption(servicetype && servicetype.id);
                setEndDate(moment(enddate).format("YYYY-MM-DD"));
                setDate(moment(startdate).format("YYYY-MM-DD"));
                setActive(active);
                setName(name);
                setValues((oldValues) => ({
                    ...oldValues,
                    type: promotiontype
                }));
            }
        }
    }

    // cancel and go back to PromotionForm
    function handleCancelProvider() {
        actions.setPage("PromotionForm");
    }
    // handle select change for package
    function handleTypeChange(event) {
        setValues((oldValues) => ({
            ...oldValues,
            [event.target.name]: event.target.value
        }));
    }

    async function addPromotion() {
        try {
            let input = {
                promotionCompanyId: loggedInUser && loggedInUser.company.id,
                amount: parseFloat(amount),
                startdate: new Date(date),
                enddate: new Date(enddate),
                promotiontype: values.type,
                desc: description,
                active: active,
                name: name,
                promotionServicetypeId: serviceTypeOption,
                promotionPackageId: packageOption
            };
            let response;
            if (isEdit) {
                input.id = state.id;
                response = await graphql(
                    graphqlOperation(mutations.updatePromotion, { input })
                );
            } else {
                response = await graphql(
                    graphqlOperation(mutations.createPromotion, { input })
                );
            }
            reset();
            if (
                response && response.data && isEdit
                    ? response.data.updatePromotion
                    : response.data.createPromotion
            ) {
                actions.setPage("PromotionForm");
                setSnackMsg("Promotion successfully saved.");
                setMsgOpen(true);
                // reset fields
            } else {
                setSnackMsg("Something went wrong, Please try again later.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error("Add Promotion error => ", error);
        }
    }

    async function handleSaveProvider() {
        // validate
        if (name === "") {
            setSnackMsg("Please enter a value for name.");
            setMsgOpen(true);
            return;
        }
        if (amount === "") {
            setSnackMsg("Please enter a value for amount.");
            setMsgOpen(true);
            return;
        }
        if (date === "") {
            setSnackMsg("Please enter a value for Start Date");
            setMsgOpen(true);
            return;
        }
        if (enddate === "") {
            setSnackMsg("Please enter a value for End Date");
            setMsgOpen(true);
            return;
        }
        if (values.type === "") {
            setSnackMsg("Please choose type");
            setMsgOpen(true);
            return;
        }
        if (packageOption === "") {
            setSnackMsg("Please choose Package");
            setMsgOpen(true);
            return;
        }
        if (description === "") {
            setSnackMsg("Please enter some description.");
            setMsgOpen(true);
            return;
        }
        if (
            !!name &&
            !!date &&
            !!enddate &&
            !!values.type &&
            !!description &&
            !!amount
        ) {
            addPromotion();
        }
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    function reset() {
        // reset fields
        setName("");
        setDate("");
        setEndDate("");
        setDescription("");
        setAmount("");
    }
    // handle select change for service type option
    function handleServiceSelectChange(event) {
        setServiceTypeOption(event.target.value);
    }
    // handle select change for package
    function handlePackageSelectChange(event) {
        setPackageOption(event.target.value);
    }
    // handle amount change
    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    // handle name change
    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
    };
    // handle description change
    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };
    // handle start date change
    const handleChangeDate = (e) => {
        setDate(e);
    };
    // handle end date change
    const handleChangeEndDate = (e) => {
        setEndDate(e);
    };

    console.log("packsges", packagesTypeData);
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />

            <Typography className={classes.title} variant="h4" noWrap>
                Promotions \ {state.mode}
            </Typography>

            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="name"
                                label="Name"
                                className={classes.textField}
                                value={name}
                                inputProps={{ tabIndex: "1" }}
                                onChange={handleChangeName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="amount"
                                label="Amount"
                                className={classes.textField}
                                value={amount}
                                inputProps={{ tabIndex: "2" }}
                                onChange={handleChangeAmount}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel
                                ref={inputLabel}
                                htmlFor="outlined-service-simple"
                            >
                                Services
                            </InputLabel>
                            <Select
                                value={serviceTypeOption}
                                onChange={handleServiceSelectChange}
                                name="service"
                                inputProps={{ tabIndex: "3" }}
                            >
                                <MenuItem value={0}>All Services</MenuItem>
                                {serviceTypeData &&
                                    serviceTypeData.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel
                                ref={inputLabel}
                                htmlFor="outlined-service-simple"
                            >
                                Package
                            </InputLabel>
                            <Select
                                value={packageOption}
                                onChange={handlePackageSelectChange}
                                name="package"
                                inputProps={{ tabIndex: "4" }}
                            >
                                <MenuItem value={0}>All Packages</MenuItem>
                                {packagesTypeData &&
                                    packagesTypeData.length > 0 &&
                                    packagesTypeData.map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.desc}
                                            </MenuItem>
                                        );
                                    })}
                                {/* <MenuItem value={'DOLLAR'}>DOLLAR</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className="pickers">
                                    <DatePicker
                                        value={date}
                                        onChange={handleChangeDate}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                            {/* <TextField
                                id="date"
                                label="Start Date"
                                type="date"
                                value={date}
                                className={classes.textField}
                                inputProps={{ tabIndex: "5" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChangeDate}
                            /> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className="pickers">
                                    <DatePicker
                                        value={date}
                                        onChange={handleChangeEndDate}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel
                                ref={inputLabel}
                                htmlFor="outlined-age-simple"
                            >
                                Type
                            </InputLabel>
                            <Select
                                value={values.type}
                                onChange={handleTypeChange}
                                name="type"
                                inputProps={{ tabIndex: "7" }}
                            >
                                <MenuItem value={0}>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"PERCENTAGE"}>
                                    PERCENTAGE
                                </MenuItem>
                                <MenuItem value={"DOLLAR"}>DOLLAR</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="description"
                                label="Description"
                                multiline
                                rows="4"
                                value={description}
                                className={classes.textField}
                                onChange={handleChangeDescription}
                                inputProps={{ tabIndex: "8" }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormControlLabel
                            margin="normal"
                            className={classesnew.marginBottom}
                            control={
                                <Switch
                                    checked={active}
                                    onChange={(event) =>
                                        setActive(event.target.checked)
                                    }
                                    value="active"
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={5}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveProvider}
                                disabled={state.mode === "View"}
                            >
                                Save
                            </Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelProvider}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
