import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

function NumberFormatCustom(props) {
    const { inputRef, onChange, prefix, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            isNumericString
            prefix={prefix ? prefix : "$"}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export function MoneyAmountInput(props) {
    //  const [amount, setAmount] = React.useState(props.amount);

    const handleChange = (event) => {
        //   setAmount(event.target.value);
        props.setAmount(event.target.value);
        console.log(event.target.value);
    };

    return (
        <TextField
            sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 }
            }}
            value={props.amount}
            onChange={handleChange}
            prefix={props.prefix}
            name={props.name}
            id={props.name}
            InputProps={{
                inputComponent: NumberFormatCustom,
                shrink: false
            }}
            style={{ marginLeft: "8px", width: "140px" }}
            variant="outlined"
            size="small"
            disabled={props.disabled ? props.disabled : false}
        />
    );
}
