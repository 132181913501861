import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import BookingFlowSettings from "./BookingFlowSettings";

const bookingFlowSettings = document.getElementById("booking-flow-settings");
function BookingFlowSettingsModal(props) {
    return ReactDOM.createPortal(
        <Dialog open={props.open} fullWidth={true} maxWidth={"md"}>
            <DialogContent>
                <BookingFlowSettings
                    originPath={props.originPath}
                    close={props.close}
                    compId={props.compId}
                    loadingON={props.loadingON}
                    loadingOFF={props.loadingOFF}
                    doneTodoHandler={props.doneTodoHandler}
                    showClose={true}
                    saveAndClose={true}
                />
            </DialogContent>
        </Dialog>,
        bookingFlowSettings
    );
}

export default BookingFlowSettingsModal;
