/*jshint esversion: 6 */

import React, { useContext, useEffect, useState, useCallback } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Paper, TextField, InputAdornment, MenuItem, Grid, Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Avatar, Tabs, Tab } from '@mui/material';
// prettier-ignore
import { Checkbox, Button, Typography, TableFooter, TablePagination, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getUserFromCache } from "../user/UserCommon";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useStyles } from "../styles/MainNavbarStyles";
import * as queries from "../graphql/queries";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { Cache } from "aws-amplify/utils";
import { getUrl } from "aws-amplify/storage";
import { SmallSpinner } from "../utils/CommonComponents/Spinner";

//import * as queries from "../graphql/queries"

import { userByCompany } from "../queries/UserQueries";
import {
    providerByCompanyWithLocations,
    providerByCompany,
    serviceTypeByCompanyCustom,
    skillByCompanyCustom
} from "../queries/ProviderFormQueries";
import * as mutations from "../graphql/mutations";
import { getProviderExtended } from "../queries/ProviderAddEditQueries";
import { createSchedule, createTimeblock } from "../modules/ScheduleService";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
// import AmpStoriesIcon from '@mui/icons-material/AmpStories';
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import {
    getScheduleCounts,
    getScheduleDatesToReport
} from "../modules/ScheduleService";
import { CSVLink } from "react-csv";
import { logSystemError } from "../modules/SystemErrorService";
import { execReadByPKFromToken, execReadBySortkey } from "../modules/DBService";
import ClearIcon from "@mui/icons-material/Clear";
import GetAppIcon from "@mui/icons-material/GetApp";

import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { execReadByPK } from "../modules/DBService";
import { MoreHoriz } from "@mui/icons-material";
import { Menu, Stack } from "@mui/material";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { Link } from "react-router-dom";
import { OUTLET_PAGE } from "../context/reducers";

const providerTableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    },
    actionCellWidth: {
        width: "250px"
    },
    nameCellWidth: {
        width: "180px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const logger = new ConsoleLogger("ProviderForm");

function ProviderDropDown(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        //setSelectedOrder('');
    };
    return (
        <div>
            <MoreHoriz
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    props.handleThreeDotsClick(
                        e,
                        props.providerId,
                        props.providerName,
                        props.index
                    );
                }}
            />

            <Menu
                PaperProps={{
                    style: {
                        width: 150,
                        border: "1px solid #e5e5e5",
                        boxShadow: "1px 1px #e5e5e5"
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleThreeDotsClose}
            >
                <MenuItem
                    sx={{ fontWeight: 700, color: "#0087EE" }}
                    onClick={(e) => {
                        console.log(props.index);

                        props.handleProviderLocations(e, props.row);

                        handleThreeDotsClose();
                        /* props.getIndex(
              e,
              props.providerId,
              props.providerName,
              props.index
            ); */
                    }}
                >
                    {"Travel Zones "}
                    {props.locationCount ? `(${props.locationCount})` : "(0)"}
                </MenuItem>
                <MenuItem
                    sx={{ fontWeight: 700, color: "#FF681D" }}
                    onClick={(e) => {
                        props.handleBlockTimeClick(e, props.row);
                        handleThreeDotsClose();
                        /* props.getIndex(
              e,
              props.providerId,
              props.providerName,
              props.index
            ); */
                    }}
                >
                    Block Time
                </MenuItem>
            </Menu>
        </div>
    );
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, onHandleClearClick } =
        props;
    /* const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      }; */

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" sx={{ paddingTop: 0 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all providers"
                        }}
                    />
                </TableCell>
                <TableCell sx={{ paddingTop: 0 }}>
                    {numSelected > 0 ? (
                        <Typography
                            sx={{
                                flex: "1 1 100%",
                                fontWeight: 700,
                                color: "primary.main"
                            }}
                        >
                            {numSelected + " selected "}
                            <span style={{ color: "rgba(0,0,0,0.75)" }}>(</span>
                            <span
                                style={{
                                    color: "#FF681D",
                                    fontWeight: 700,
                                    cursor: "pointer"
                                }}
                                onClick={(event) => onHandleClearClick(event)}
                            >
                                Clear
                            </span>
                            <span style={{ color: "rgba(0,0,0,0.75)" }}>)</span>
                        </Typography>
                    ) : (
                        "Status"
                    )}
                </TableCell>
                <TableCell sx={{ paddingTop: 0 }}>
                    Name & Email Address
                </TableCell>
                <TableCell sx={{ paddingTop: 0 }} align="center">
                    Role
                </TableCell>
                <TableCell sx={{ maxWidth: "200px", paddingTop: 0 }}>
                    Schedule & Ratings
                </TableCell>
                <TableCell sx={{ maxWidth: "400px", paddingTop: 0 }}>
                    Services
                </TableCell>
                <TableCell sx={{ paddingTop: 0 }} align="center">
                    Login
                </TableCell>
                <TableCell sx={{ paddingTop: 0 }} align="center">
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    console.log("*Table Pagination ProviderForm Props");
    console.log(props);

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            {/* <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton> */}
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

function ProviderForm(props) {
    const [searchValue, setSearchValue] = useState("");
    const [filteredToken, setFilteredToken] = useState();
    const [filteredCache, setFilteredCache] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [mode, setMode] = useState(true);
    const { actions, state } = useContext(StoreContext);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const classes = useStyles();
    const classes1 = useStyles1();
    //  const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [endOfList, setEndOfList] = useState(false);
    const [token, setToken] = useState();
    const [cachedResults, setCachedResults] = useState([]);
    const [isProviderAccept, setIsProviderAccept] = useState(false);
    // service type select
    const [serviceTypeOption, setServiceTypeOption] = useState(0);
    const [serviceTypeData, setServiceTypeData] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [user, setUser] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [searchedProviders, setSearchedProviders] = useState([]);
    const [exportReadyProviders, setExportReadyProviders] = useState();
    const [preparingExportData, setPreparingExportData] = useState(false);
    const [showExportSpinner, setShowExportSpinner] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const limit = process.env.REACT_APP_LISTLIMIT;
    const APP_ID_HERE = process.env.REACT_APP_GEOCODER_APP_ID;
    const APP_CODE_HERE = process.env.REACT_APP_GEOCODER_CODE;
    // back button from a provider page
    const [backSearchValue, setBackSearchValue] = useState("");
    const [nonMainPage, setNonMainPage] = useState(false);
    // service dialog
    const [maxServiceClick, setMaxServiceClick] = useState(false);
    const [showServiceInfoDialog, setShowServiceInfoDialog] = useState(false);
    const [providerName, setProviderName] = useState("");
    const [servicePage, setServicePage] = React.useState(0);
    const [serviceRowsPerPage, setServiceRowsPerPage] = React.useState(5);
    const [serviceArray, setServiceArray] = useState([]);
    const [providerPicLink, setProviderPicLink] = useState("");

    const [selectedProvider, setSelectedProvider] = useState("");
    //const [selected, setSelected] = useState([]);

    const [tabValue, setTabValue] = useState("0");

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("providersTable")
    );

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }
    function clearSearch() {
        setIsSearching(false);
        setNonMainPage(false);
        setBackSearchValue("");
        setSearchValue("");
        debounce("", token, cachedResults);
    }
    function _handleDeleteDialogAccept() {
        // proceed to delete providers
        selected.map((item) => {
            logger.debug(item);
            deleteProvider(item);
        });
    }

    function handleLoadMore() {
        refreshClicked(token, cachedResults, true);
    }

    async function deleteProvider(providerId) {
        async function deleteGraphQLProviders(providerId) {
            let providerTobeDeleted;
            if (isSearching) {
                providerTobeDeleted = filteredRows.filter(
                    (p) => p.id === providerId
                );
            } else {
                providerTobeDeleted = rows.filter((p) => p.id === providerId);
            }
            const input = {
                id: providerId,
                deleted: true,
                firstname: providerTobeDeleted[0].firstname,
                lastname: providerTobeDeleted[0].lastname,
                createdAt: providerTobeDeleted[0].createdAt
            };
            const deletedProvider = await graphql(
                graphqlOperation(mutations.updateProvider, { input })
            );
            if (!!deletedProvider) {
                setSnackMsg(
                    "The selected providers have been successfully deleted."
                );
                setMsgOpen(true);
                setDeleteDialog(false);
                // clear table... cannot do refresh right away or we will get stale data ()
                const empty = [];
                // setRows(empty);
                setSelected(empty);
                setToken();
                setCachedResults([]);
                setEndOfList(false);
                refreshClicked();
                setPage(0);
            }
        }
        await deleteGraphQLProviders(providerId);
    }

    // handling back button usage
    let backSearch, backButton, savedSearchValue;
    useEffect(() => {
        if (sessionStorage.getItem("search") == "true") {
            backSearch = true;
            savedSearchValue = sessionStorage.getItem("value");
            setBackSearchValue(savedSearchValue);
        } else {
            backSearch = false;
            savedSearchValue = false;
        }
        if (sessionStorage.getItem("back") == "true") {
            backButton = true;
            setNonMainPage(true);
        } else backButton = false;
    }, []);

    useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth);
        const user = getUserFromCache();
        setUser(user);
    }, []);

    useEffect(() => {
        const authuser = getUserFromCache();

        async function fetchServiceTypes() {
            const result = await graphql(
                graphqlOperation(serviceTypeByCompanyCustom, {
                    companyId: authuser.company.id,
                    limit: limit,
                    filter: {
                        and: [
                            { deleted: { ne: true } },
                            { active: { ne: false } },
                            { isVisible: { ne: false } }
                        ]
                    }
                })
            );
            setServiceTypeData(result.data.serviceTypeByCompany.items);
        }
        fetchServiceTypes();

        async function fetchSkills() {
            const result = await graphql(
                graphqlOperation(skillByCompanyCustom, {
                    companyId: authuser.company.id,
                    limit: limit,
                    filter: {
                        and: [
                            { deleted: { ne: true } },
                            { active: { ne: false } }
                        ]
                    }
                })
            );
            setSkillData(result.data.skillByCompany.items);
        }
        fetchSkills();
    }, []);

    useEffect(() => {
        if (backSearch && backButton) {
            searchProviders({ input: savedSearchValue });
        } else if (backButton && !backSearch) {
            setBackSearchValue(
                "Hit the X to return to the main provider page or search again for other providers"
            );
            getBackProvider();
        } else {
            refreshClicked(token, cachedResults);
        }
        getCompanyData();
    }, [serviceTypeOption, mode]);

    async function getCompanyData() {
        const loggedInUser = getUserFromCache();
        const companyId = loggedInUser.company.id;

        if (!!companyId) {
            const input = { id: companyId };
            const result = await graphql(
                graphqlOperation(queries.getCompany, input)
            );

            if (result && result.data.getCompany) {
                setIsProviderAccept(
                    result.data.getCompany.providerMustAcceptAppt
                );
            }
        }
    }

    // sort the array in reverse alphabetical order
    function sortArray(name1, name2) {
        const nameA = name1.name
            ? name1.name.toLowerCase()
            : name1.servicetype.name.toLowerCase();
        const nameB = name2.name
            ? name2.name.toLowerCase()
            : name2.servicetype.name.toLowerCase();
        let comparison = 0;
        if (nameA > nameB) {
            comparison = 1;
        } else if (nameB > nameA) {
            comparison = -1;
        }
        return comparison * -1;
    }

    // create arrays for the service information
    let serviceId = [];
    let serviceName = [];
    let serviceDesc = [];
    let serviceTime = [];
    let serviceBehavior = [];
    let taxExempt = [];
    let serviceInfo = [];

    async function showServiceDialog(item) {
        console.log("Provider Form Item", item);
        try {
            setProviderName(item.name);
            // put the loading back in case it takes too long to load
            // setShowLoading(true);
            const serviceTypesCache = await Cache.getItem("servicetypes");

            console.log("services", serviceTypesCache);
            const input = { id: item.id };
            const result = await graphql(
                graphqlOperation(getProviderExtended, input)
            );

            console.log("just a test", result.data.getProvider);

            const provData = result.data.getProvider;
            if (result && provData) {
                let serviceLen = provData.servicetypes.items.length;
                let index = 0;
                let serviceTypeId;
                for (var i = 0; i < serviceLen; i++) {
                    serviceTypeId =
                        provData.servicetypes.items[i].servicetype.id;

                    for (var j = 0; j < serviceTypesCache.length; j++) {
                        if (serviceTypesCache[j].id === serviceTypeId) {
                            const name = serviceTypesCache[j].name;
                            const desc = serviceTypesCache[j].desc;
                            const time = `${serviceTypesCache[j].minutes} min`;
                            const behavior = serviceTypesCache[j].behavior;
                            const exempt = serviceTypesCache[j].taxexempt;
                            serviceId[index] = serviceTypesCache[j].id;
                            serviceName[index] = name;
                            serviceDesc[index] = desc;
                            serviceTime[index] = time;
                            if (behavior === null) {
                                serviceBehavior[index] = "EXTERNAL";
                            } else {
                                serviceBehavior[index] = behavior;
                            }
                            if (exempt === null) {
                                taxExempt[index] = "No";
                            } else {
                                if (exempt) taxExempt[index] = "Yes";
                                else taxExempt[index] = "No";
                            }
                        }
                    }
                    index += 1;
                }

                for (var i = 0; i < serviceLen; i++) {
                    if (serviceName[i]) {
                        serviceInfo.push({
                            id: serviceId[i],
                            name: serviceName[i],
                            desc: serviceDesc[i],
                            time: serviceTime[i],
                            behavior: serviceBehavior[i],
                            taxexempt: taxExempt[i]
                        });
                    }
                }
                console.log("serviceInfo", serviceInfo);
                serviceInfo.sort(sortArray);

                setServiceArray(serviceInfo);
            }
            // setShowLoading(false);
            setShowServiceInfoDialog(true);
        } catch (e) {
            console.log("there was an error", e);
        }
    }

    async function getBackProvider() {
        const loggedInUser = getUserFromCache();
        try {
            setShowLoading(true);
            let input = { id: state.id };
            let providers = [];
            const result = await graphql(
                graphqlOperation(getProviderExtended, input)
            );
            const providerData = result.data.getProvider;
            console.log("getBackProvider");
            console.log(providerData);
            providers.push(providerData);
            let builtDataOfProvider = await buildTableData(providers);
            setRows(builtDataOfProvider);
            sessionStorage.setItem("back", false);
            setShowLoading(false);
        } catch (e) {
            console.log("there was an error", e);
        }
    }

    const csvData = async (providersList) => {
        try {
            const loggedInUser = getUserFromCache();
            const schedules = await getScheduleCounts(loggedInUser.company.id);
            const scheduleDatesToReport = await getScheduleDatesToReport(
                loggedInUser.company.id,
                schedules.SCs
            );
            providersList.items.sort(
                //sort the providersList is descending order based on created At
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            let rows = [];
            // let combinedList = [];
            let serviceTypeId,
                serviceTypeList = "",
                skillsList = "",
                i,
                j;
            let newItem;

            let usersByCompany = await execReadBySortkey({
                opname: "userByCompany",
                op: userByCompany,
                id: { companyId: loggedInUser.company.id },
                skey: {
                    roleEmailaddress: {
                        beginsWith: {
                            role: "PROVIDER"
                        }
                    }
                }, //TODO: shhould be based on StartDate and EndDate
                limit: process.env.REACT_APP_LISTLIMIT
            });

            let providerLocationData = await execReadByPK({
                opname: "providerByCompany",
                op: providerByCompanyWithLocations,
                id: { companyId: loggedInUser.company.id },
                limit: 1000
            });

            if (providersList && providersList.items.length > 0) {
                providersList.items.map(async (item) => {
                    usersByCompany.items.map(async (item1) => {
                        if (item.emailaddress == item1.emailaddress) {
                            const loginInfo = JSON.parse(item1.LoginInfo);
                            item.phonepreference = item1.phonepref;
                            item.addressLine = item1.addressoneline;
                            item.city = item1.city ? item1.city : "";
                            item.state = item1.state ? item1.state : "";
                            item.country = item1.country ? item1.country : "";
                            item.postalcode = item1.postalcode
                                ? item1.postalcode
                                : "";
                            item.completedloginstatus =
                                (item1.active && item1.LoginInfo) ||
                                item1.registered === false
                                    ? "True"
                                    : !item.active
                                      ? "INACTIVE"
                                      : "False";
                            item.lastLogin = moment(
                                loginInfo?.lastLoginDate
                            ).format("YYYY-MM-DD hh:mm A");
                            item.loginCount = loginInfo?.loginCount;
                        }
                    });

                    providerLocationData.items.map(async (item2) => {
                        if (item.emailaddress == item2.emailaddress) {
                            try {
                                if (item2.locations.items.length > 1) {
                                    for (
                                        j = 0;
                                        j < item2.locations.items.length;
                                        j++
                                    ) {
                                        if (item.travelzoneaddress === "") {
                                            if (
                                                item2.locations.items[j]
                                                    .addressoneline != undefined
                                            ) {
                                                item.travelzoneaddress =
                                                    item2.locations.items[
                                                        j
                                                    ].addressoneline;
                                            } else {
                                                item.travelzoneaddress = "";
                                            }
                                        } else {
                                            if (
                                                item.travelzoneaddress ==
                                                undefined
                                            ) {
                                                item.travelzoneaddress = "";
                                            }
                                            let tempstorage = item2.locations
                                                .items[j].addressoneline
                                                ? item2.locations.items[j]
                                                      .addressoneline
                                                : "";
                                            if (tempstorage != "") {
                                                if (
                                                    item.travelzoneaddress == ""
                                                ) {
                                                    item.travelzoneaddress +=
                                                        tempstorage;
                                                } else {
                                                    item.travelzoneaddress +=
                                                        "; " + tempstorage;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    item.travelzoneaddress =
                                        item2.locations.items[0].addressoneline;
                                }
                            } catch {
                                item.travelzoneaddress = "";
                            }
                        }
                    });

                    item.completedloginstatus = item.completedloginstatus
                        ? item.completedloginstatus
                        : "False";
                    item.addressLine = item.addressLine ? item.addressLine : "";
                    item.phonepreference = item.phonepreference
                        ? item.phonepreference
                        : "";
                    serviceTypeList = "";
                    skillsList = "";
                    let providesServiceType = false;
                    const serviceTypesCache =
                        await Cache.getItem("servicetypes");

                    for (i = 0; i < item.servicetypes.items.length; i++) {
                        serviceTypeId =
                            item.servicetypes.items[i].servicetype.id;

                        // filter service types
                        if (
                            serviceTypeOption != 0 &&
                            serviceTypeId === serviceTypeOption
                        ) {
                            providesServiceType = true;
                        }
                        for (j = 0; j < serviceTypesCache.length; j++) {
                            if (serviceTypesCache[j].id === serviceTypeId) {
                                const std = serviceTypesCache[j].name;
                                if (serviceTypeList === "") {
                                    serviceTypeList = std;
                                } else {
                                    serviceTypeList += "; " + std;
                                }
                                newItem = { ...item, serviceTypeList };
                                item = newItem;
                            }
                        }
                        for (let a = 0; a < item.skills.items.length; a++) {
                            const std = item.skills.items[a].skill.name;
                            if (skillsList === "") {
                                skillsList = std;
                            } else if (!skillsList.includes(std)) {
                                skillsList += "; " + std;
                            }
                            newItem = { ...item, skillsList };
                            item = newItem;
                        }
                    }
                    item.createdAt = moment(item.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    );

                    item.virtualmeetingroomlink = item.vmlink
                        ? item.vmlink
                        : "";
                    item.virtualservicestatus = item.offersVirtualServices
                        ? item.offersVirtualServices
                        : "FALSE";

                    if (
                        item.virtualservicestatus == true &&
                        item.virtualmeetingroomlink == ""
                    ) {
                        item.virtualmeetingroomlink = "N/A";
                    }
                    item.permalink =
                        loggedInUser && loggedInUser.company
                            ? `https://${loggedInUser.company.subdomain}/provider/${item.permalink}`
                            : "";
                    item.emailaddress = item.emailaddress
                        ? item.emailaddress
                        : "";
                    item.firstname = item.firstname ? item.firstname : "";
                    item.lastname = item.lastname ? item.lastname : "";
                    item.email = item.email ? item.email : "";
                    item.phone = item.phone ? item.phone : "";
                    item.addressoneline = item.addressoneline
                        ? item.addressoneline
                        : "";
                    item.city = item.city ? item.city : "";
                    item.state = item.state ? item.state : "";
                    item.country = item.country ? item.country : "";
                    item.postalcode = item.postalcode ? item.postalcode : "";
                    item.timezone = item.timezone ? item.timezone : "";
                    item.bio = item.bio ? item.bio.replace(/\n/g, " ") : "";
                    item.skillsList = item.skillsList ? item.skillsList : "";
                    item.serviceList = item.serviceTypeList
                        ? item.serviceTypeList
                        : "";
                    item.status = item.active != false ? "Active" : "Inactive";
                    item.ratingstaravg = !!item.ratingstaravg
                        ? item.ratingstaravg
                        : 0;
                    item.numberofratings = item.numberofratings
                        ? item.numberofratings
                        : 0;
                    item.maxtraveltype = item.maxtraveltype
                        ? item.maxtraveltype
                        : "MAX_DISTANCE";
                    item.locationCount = item.locations.items
                        ? item.locations.items.length
                        : 0;
                    item.numberofschedules = schedules.scheduleCounts[item.id]
                        ? schedules.scheduleCounts[item.id]
                        : 0;
                    item.latestScheduleUpdatedAt =
                        scheduleDatesToReport[item.id] &&
                        scheduleDatesToReport[item.id].latestScheduleUpdatedAt
                            ? moment(
                                  scheduleDatesToReport[item.id]
                                      .latestScheduleUpdatedAt
                              ).format("YYYY-MM-DD")
                            : "";
                    item.farthestScheduleDate =
                        scheduleDatesToReport[item.id] &&
                        scheduleDatesToReport[item.id].farthestScheduleDate
                            ? scheduleDatesToReport[item.id].sbs.some(
                                  (item) => item.active === true
                              )
                                ? moment(
                                      scheduleDatesToReport[item.id]
                                          .farthestScheduleDate
                                  ).format("YYYY-MM-DD")
                                : "Availability is inactive"
                            : "";
                    item.lastLogin = item.lastLogin ? item.lastLogin : "N/A";
                    item.loginCount = item.loginCount ? item.loginCount : "N/A";
                    rows.push(item);
                });
            }
            const csvData = rows.map((item) => {
                const {
                    id: ID,
                    createdAt: DATECREATED,
                    firstname: FIRSTNAME,
                    lastname: LASTNAME,
                    emailaddress: EMAIL,
                    phone: PHONENUMBER,
                    phonepreference: PHONEPREFERENCE,
                    addressLine: ADDRESSLINE,
                    city: CITY,
                    state: STATE,
                    country: COUNTRY,
                    postalcode: POSTALCODE,
                    bio: BIO,
                    skillsList: SKILLS,
                    serviceList: SERVICES,
                    completedloginstatus: COMPLETEDLOGINSTATUS,
                    status: ACTIVE,
                    ratingstaravg: RATINGAVERAGE,
                    numberofratings: RATINGCOUNT,
                    maxtraveltype: MAXTRAVELTYPE,
                    locationCount: LOCATIONCOUNT,
                    travelzoneaddress: TRAVELZONEADDRESS,
                    numberofschedules: SCHEDULECOUNT,
                    permalink: PERMALINK,
                    virtualservicestatus: VIRTUALSERVICESTATUS,
                    virtualmeetingroomlink: VIRTUALMEETINGROOMLINK,
                    latestScheduleUpdatedAt: LATESTSCHEDULEUPDATEDAT,
                    farthestScheduleDate: FARTHESTSCHEDULEDATE,
                    lastLogin: LASTLOGIN,
                    loginCount: LOGINCOUNT
                } = item;
                const newItem = {
                    ID: ID,
                    "Date Created": DATECREATED,
                    "First Name": FIRSTNAME,
                    "Last Name": LASTNAME,
                    Email: EMAIL,
                    "Phone Number": PHONENUMBER,
                    "Phone Preference": PHONEPREFERENCE,
                    Address: ADDRESSLINE,
                    City: CITY,
                    State: STATE,
                    Country: COUNTRY,
                    "Postal Code": POSTALCODE,
                    Skills: SKILLS,
                    "Completed Login": COMPLETEDLOGINSTATUS,
                    Status: ACTIVE,
                    "Rating Average": RATINGAVERAGE,
                    "Rating Count": RATINGCOUNT,
                    "Max Travel Type (Travel Zone Type)": MAXTRAVELTYPE,
                    "Location Count": LOCATIONCOUNT,
                    "Travel Zone Address": TRAVELZONEADDRESS,
                    "Schedule Count (Future Availability)": SCHEDULECOUNT,
                    Permalink: PERMALINK,
                    "Offers Virtual Services": VIRTUALSERVICESTATUS,
                    "Virtual Meeting Room Link": VIRTUALMEETINGROOMLINK,
                    Services: SERVICES,
                    "Schedule Last Updated": LATESTSCHEDULEUPDATEDAT,
                    "Last Available Date": FARTHESTSCHEDULEDATE,
                    "Last login": LASTLOGIN,
                    "Login count": LOGINCOUNT,
                    Bio: BIO
                };
                return newItem;
            });

            if (csvData && csvData.length) {
                return csvData;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getPicture = (item) => {
        //let pictureURL;
        try {
            if (item.pictures3key) {
                const pictureURL = Storage.get(item.pictures3key).then(
                    (res) => {
                        console.log(typeof res);
                        return res;
                        //return res.then((data) => data);
                    }
                );
                if (pictureURL) {
                    // setProviderPicLink(pictureURL);
                    console.log(pictureURL);
                }
                return pictureURL;
            }
        } catch (err) {
            //setProviderPicLink("");
            console.log("Error from get picture");
        }
    };

    async function buildTableData(data) {
        console.log("**Table Data - Provider Form**");
        console.log(data);
        const loggedInUser = getUserFromCache();
        const schedules = await getScheduleCounts(loggedInUser.company.id);

        const newRows = [];
        let newRowsWithPicture = [];

        setRows([]);
        let serviceTypeId,
            serviceTypeDesc = "",
            i,
            j;

        await fetchServiceTypeData();
        let newItem;
        const serviceTypesCache = await Cache.getItem("servicetypes");

        data &&
            data.map((item) => {
                //window.prompt(item.firstname , JSON.stringify(item))
                // logger.debug("item = " + JSON.stringify(item));
                // get servicetype desc
                serviceTypeDesc = "";
                let providesServiceType = false;
                const numberofservices = item.servicetypes.items.length;

                item.servicetypes.items.sort(sortArray);

                for (i = 0; i < item.servicetypes.items.length; i++) {
                    // if more than 3 items, break the loop
                    if (i >= 3) {
                        item.maxServices = true;
                        break;
                    }
                    serviceTypeId = item.servicetypes.items[i].servicetype.id;
                    // filter service types
                    if (
                        serviceTypeOption != 0 &&
                        serviceTypeId === serviceTypeOption
                    ) {
                        providesServiceType = true;
                    }
                    for (j = 0; j < serviceTypesCache.length; j++) {
                        if (serviceTypesCache[j].id === serviceTypeId) {
                            const std = serviceTypesCache[j].name;
                            if (serviceTypeDesc === "") {
                                serviceTypeDesc = std;
                            } else {
                                serviceTypeDesc += " | " + std;
                            }
                            newItem = { ...item, serviceTypeDesc };
                            item = newItem;
                        }
                    }
                }

                if (
                    (serviceTypeOption === 0 || providesServiceType) &&
                    item.company.id === loggedInUser.company.id &&
                    item.active === mode
                ) {
                    const providerlocations = [];
                    if (item.locations && item.locations.items) {
                        // filter any deleted provider locations
                        const allproviderlocations = item.locations.items;
                        for (let i = 0; i < allproviderlocations.length; i++) {
                            logger.debug(
                                "allproviderlocations = " +
                                    JSON.stringify(allproviderlocations[i])
                            );
                            if (
                                allproviderlocations[i].deleted == false ||
                                allproviderlocations[i].deleted == null
                            ) {
                                providerlocations.push(allproviderlocations[i]);
                            }
                        }
                    }

                    newRows.push({
                        id: item.id,
                        companyId: item.companyId,
                        createdAt: item.createdAt,
                        blank: "",
                        permalink: item.permalink,
                        name: item.firstname + " " + item.lastname,
                        firstname: item.firstname,
                        lastname: item.lastname,
                        email: item.emailaddress,
                        phone: item.phone,
                        service: item.serviceTypeDesc,
                        active: item.active,
                        maxtraveltype: item.maxtraveltype,
                        maxServices: item.maxServices,
                        pictures3key: item.pictures3key,
                        locationCount:
                            item.locations &&
                            item.locations.items &&
                            providerlocations
                                ? providerlocations.length
                                : 0,
                        numberofschedules: schedules.scheduleCounts[item.id]
                            ? schedules.scheduleCounts[item.id]
                            : 0,
                        numberofservices: numberofservices,
                        numberofratings: item.numberofratings,
                        registered: item?.user ? item.user.registered : false,
                        role: item?.user ? item.user.role : "PROVIDER",
                        LoginInfo: item?.user?.LoginInfo
                            ? item.user.LoginInfo
                            : null,
                        userActive: item?.user?.active
                            ? item.user.active
                            : false
                    });
                }
            });

        console.log("**Modified Table Data - Provider Form**");
        console.log(newRows);

        const promises = newRows.map(async (item) => {
            if (item.pictures3key)
                await getUrl({ key: item.pictures3key }).then((val) => {
                    item.pictureURL = val?.url;
                    console.log(
                        item.pictures3key,
                        "item.pictures3key getUrl output---------------------2",
                        JSON.stringify(val)
                    );
                });
            return {
                ...item
            };
        });
        newRowsWithPicture = await Promise.all(promises);

        //console.log(newRows);
        console.log(newRowsWithPicture);
        return newRowsWithPicture;
    }

    //console.log(pictureURL);

    function handleProviderAddClick() {
        actions.setMode("Add");
        actions.setPage("ProviderAddEdit");
    }

    function handleEditClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("Edit");
        actions.setPage("ProviderAddEdit");
        actions.setId(rowId);
        actions.setContextCompanyId(isProviderAccept);
        if (isSearching || sessionStorage.getItem("back") === "true") {
            sessionStorage.setItem("search", true);
            if (!!searchValue) {
                sessionStorage.setItem("value", searchValue);
            }
        }
    }

    function handleViewClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("View");
        actions.setPage("ProviderAddEdit");
        actions.setId(rowId);
    }

    function handleRatingClick(event, row) {
        // set context and pass to ProviderRating screen
        // actions.setMode("Add");
        if (isSearching || sessionStorage.getItem("back") === "true") {
            sessionStorage.setItem("search", true);
            if (!!searchValue) {
                sessionStorage.setItem("value", searchValue);
            }
        }
        actions.setPage("ProviderReviewForm");
        actions.setProvider(row);
        actions.setId(row.id);
    }

    function handleScheduleClick(event, row) {
        if (isSearching || sessionStorage.getItem("back") === "true") {
            sessionStorage.setItem("search", true);
            if (!!searchValue) {
                sessionStorage.setItem("value", searchValue);
            }
        }
        // set context and pass to ProviderSchedule screen

        actions.setPage("ProviderSchedule");
        actions.setMode("Edit");
        actions.setProvider(row);
        // localStorage.setItem('provider_service', row);
        actions.setId(row.id);

        /*  let provider_data = {
            row
        };

        localStorage.setItem(
            "provider_data",
            JSON.stringify(provider_data.row)
        ); */
    }

    function handleBlockTimeClick(event, row) {
        actions.setMode("Edit");
        actions.setPage("ProviderBlockTime");
        actions.setProvider(row);
        actions.setId(row.id);
        if (isSearching || sessionStorage.getItem("back") === "true") {
            sessionStorage.setItem("search", true);
            if (!!searchValue) {
                sessionStorage.setItem("value", searchValue);
            }
        }
    }

    function handlePhotosClick(event, row) {
        actions.setPage("ProviderAdditionalPhotos");
        actions.setProvider(row);
        actions.setId(row.id);
        if (isSearching || sessionStorage.getItem("back") === "true") {
            sessionStorage.setItem("search", true);
            if (!!searchValue) {
                sessionStorage.setItem("value", searchValue);
            }
        }
    }

    const getExportProviders = async (data) => {
        setExportReadyProviders(await csvData(data));
    };
    /* const refreshClicked = async (token, cachedResults, loadmore) => {
        console.log("*Token*" + token);
        setShowLoading(true);
        //setPage(0);
        let queryToken = typeof token !== "undefined" ? token : undefined;
        let queryCache = cachedResults || [];
        let oldRows = loadmore ? rows : [];

        // let oldFilteredRows = filteredRows;
        // let searchRows = filteredCache;
        // let searchCache = filteredCache;
        // let searchToken = filteredToken;

        try {
            const loggedInUser = getUserFromCache();
            let result = {};
            let data = [];

            let filter = {
                and: [{ deleted: { ne: true } }, { active: { ne: !mode } }]
            };

            if (typeof queryToken !== "undefined" && !queryToken) {
                data = queryCache;
            } else {
                if (!token && cachedResults?.length) {
                    let newCache;
                    if (cachedResults.length > 25) {
                        data = cachedResults.slice(0, 25);
                        newCache = cachedResults.slice(25);
                        setCachedResults(newCache);
                    } else {
                        data = cachedResults.map((p) => p);
                        newCache = [];
                        setCachedResults(newCache);
                    }
                    if (newCache?.length === 0) setEndOfList(true);
                } else {
                    result = await execReadByPKFromToken(
                        {
                            opname: "providerByCompany",
                            op: providerByCompany,
                            id: { companyId: loggedInUser.company.id },
                            filter: !!filter ? filter : null,
                            limit: limit,
                            sortDirection: "ASC"
                        },
                        queryToken,
                        queryCache,
                        25
                    );
                    setToken(result.token);
                    console.log("setEndofList", result.token);
                    console.log("cached response", result.cachedResponse);
                    setCachedResults(result.cachedResponse);
                    data = result.items;

                    // if (!result.cachedResponse.length) {
                    //     setEndOfList(true);
                    //     console.log("setEndofList", 1);
                    // }
                    if (
                        typeof token !== "undefined" &&
                        !token &&
                        result.cachedResponse?.length === 0
                    ) {
                        setEndOfList(true);
                        console.log("setEndofList", 2, token);
                    }
                }
            }
            // Active or Inactive mode is used to filter the data in buildTableData
            console.log("refreshClicked");
            console.log(data);
            console.log(rows);
            oldRows.push(...(await buildTableData(data)));
            setRows(oldRows);
        } catch (err) {
            setShowLoading(false);
            logger.error(`Error fetching provider list with filter`, err);
        }
        setShowLoading(false);
    }; */

    const refreshClicked = async (token, cachedResults, loadmore) => {
        setShowLoading(true);
        let queryToken = typeof token !== "undefined" ? token : undefined;
        let tokenHasValue = true;
        if (queryToken === null) tokenHasValue = false;
        let queryCache = cachedResults || [];
        let oldRows = loadmore ? rows : [];
        try {
            const loggedInUser = getUserFromCache();
            let result = {};
            let data = [];

            let filter = {
                and: [{ deleted: { ne: true } }, { active: { ne: !mode } }]
            };

            console.log(
                "setEndOfList queryToken",
                typeof queryToken,
                JSON.stringify(queryToken),
                queryToken
            );

            if (loadmore && cachedResults?.length) {
                let newCache;
                if (cachedResults.length > 25) {
                    data = cachedResults.slice(0, 25);
                    newCache = cachedResults.slice(25);
                    setCachedResults(newCache);
                } else {
                    data = cachedResults.map((p) => p);
                    newCache = [];
                    setCachedResults(newCache);
                }
                if (newCache?.length === 0 && !tokenHasValue) {
                    console.log("setEndOfList", 1);
                    setEndOfList(true);
                }
            } else {
                console.log("**else - Cached Results**");
                result = await execReadByPKFromToken(
                    {
                        opname: "providerByCompany",
                        op: providerByCompany,
                        id: { companyId: loggedInUser.company.id },
                        filter: !!filter ? filter : null,
                        limit: limit,
                        sortDirection: "ASC"
                    },
                    queryToken,
                    queryCache,
                    25
                );
                setToken(result.token);
                setCachedResults(result.cachedResponse);
                data = result.items;

                if (!result.cachedResponse.length) {
                    console.log("setEndOfList", 2);
                    setEndOfList(true);
                }
                if (typeof token !== "undefined" && !token) {
                    console.log("setEndOfList", 3, token, result.token);
                    if (!result.cachedResponse.length) setEndOfList(true);
                }
            }
            // Active or Inactive mode is used to filter the data in buildTableData
            console.log("refreshClicked");
            console.log(data);
            console.log(rows);
            oldRows.push(...(await buildTableData(data)));
            setRows(oldRows);
        } catch (err) {
            setShowLoading(false);
            logger.error(`Error fetching provider list with filter`, err);
        }
        setShowLoading(false);
    };

    function handleSearchChange(e) {
        //Reset state for new rows
        // setToken();
        // setCachedResults([]);
        // setEndOfList(false);
        // setPage(0);
        // setRows([]);
        // setFilteredRows([]);
        // setFilteredCache([]);
        // setFilteredToken();
        if (e.target.value) {
            setIsSearching(true);
            console.log("I am in Handle Search if");
            console.log(rows);
            console.log(filteredRows);
        } else {
            console.log("I am in Handle Search else");
            console.log(rows);
            console.log(filteredRows);
            setIsSearching(false);
            setNonMainPage(false);
        }
        setSearchValue(e.target.value);
        setBackSearchValue("");
        debounce(e.target.value, token, cachedResults);
        console.log(e.target.value);
    }

    function validSearchStr(ss) {
        if (ss && ss.startsWith("@")) {
            setSnackMsg(
                "Please enter a valid search term e.g. first name or last name or email of the provider."
            );
            setMsgOpen(true);
            return false;
        }
        return true;
        // if (ss && ss.length < 4) {
        //     setSnackMsg(
        //         "Please enter a valid search term e.g. minimum first 4 letters of first name or last name of the provider."
        //     );
        //     setMsgOpen(true);
        //     return false;
        // }
    }

    const debounce = useCallback(
        _.debounce((searchValue, tok, cachedRes) => {
            backSearch = false;
            backButton = false;
            savedSearchValue = false;
            sessionStorage.setItem("search", false);
            sessionStorage.setItem("back", false);
            sessionStorage.setItem("value", "");
            if (!!searchValue) searchProviders({ input: searchValue });
            else {
                setEndOfList(false);
                refreshClicked(tok, cachedRes);
                setFilteredRows([]);
            }
        }, 500),
        [mode]
    );

    async function searchProviders({ input }) {
        const searchProviders = `query SearchProviders(
        $filter: SearchableProviderFilterInput
        $sort: SearchableProviderSortInput
        $limit: Int
        $nextToken: String
      ) {
        searchProviders(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            firstname
            lastname
            emailaddress
            phone
            state
            country
            active
            deleted
          }
          nextToken
          total
        }
      }
      `;
        if (!validSearchStr(input)) return;
        setShowLoading(true);
        let searchStr = input ? input.toLowerCase() : "";
        if (searchStr.includes("@")) {
            searchStr = searchStr.split("@")[0];
        }
        const loggedInUser = getUserFromCache();
        console.log("bouncemodeis", mode);
        try {
            const result = await graphql(
                graphqlOperation(searchProviders, {
                    filter: {
                        companyId: {
                            eq: loggedInUser.company.id
                        },
                        active: { ne: !mode },
                        deleted: { ne: true },
                        or: [
                            { firstname: { wildcard: searchStr + "*" } },
                            { lastname: { wildcard: searchStr + "*" } },
                            { emailaddress: { match: searchStr } },
                            { phone: { match: searchStr } }
                        ]
                    }
                })
            );
            if (result && result.data.searchProviders.items.length > 50) {
                setSnackMsg(
                    "Too many records found. Please enter a more specifc search term e.g. first name or last name or email address of the provider."
                );
                setMsgOpen(true);
                setShowLoading(false);
                return false;
            }
            if (result && result.data.searchProviders.items.length) {
                let dataOfSearchResultProviders = [];

                if (input.includes("@")) {
                    result.data.searchProviders.items =
                        result.data.searchProviders.items.filter(
                            (cp) =>
                                cp.emailaddress &&
                                cp.emailaddress
                                    .toLowerCase()
                                    .includes(input.trim().toLowerCase())
                        );
                }
                for (let p of result.data.searchProviders.items) {
                    const aProvider = await graphql(
                        graphqlOperation(getProviderExtended, { id: p.id })
                    );
                    dataOfSearchResultProviders.push(
                        aProvider.data.getProvider
                    );
                }
                console.log("**dataOfSearchProviders**");
                console.log(dataOfSearchResultProviders);
                let builtDataOfSearchedProviders = await buildTableData(
                    dataOfSearchResultProviders
                );

                console.log(builtDataOfSearchedProviders);
                if (backSearch && backButton) {
                    setRows(builtDataOfSearchedProviders);
                } else {
                    setFilteredRows(builtDataOfSearchedProviders);
                }
                setPage(0);
                // setSearchedProviders(dataOfSearchResultProviders);
            } else setFilteredRows([]);
        } catch (e) {
            setShowLoading(false);
            console.log(e);
        }
        setShowLoading(false);
    }

    // handle select change for service type option
    // function handleSelectChange(event) {
    //     //reset state for new rows
    //     setToken();
    //     setCachedResults([]);
    //     setEndOfList(false);
    //     setPage(0);
    //     setRows([]);
    //     setFilteredRows([]);
    //     setFilteredCache([]);
    //     setFilteredToken();
    //     if (event.target.value) {
    //         setIsSearching(true);
    //     } else {
    //         setIsSearching(false);
    //     }
    //     setServiceTypeOption(event.target.value);
    // }

    // table values

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        console.log("val", event.target.value, parseInt(event.target.value));

        if (getTableRowsSession("providersTable")) {
            setTableRowsSession("providersTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    // service dialog page change
    function handleServicePage(event, newPage) {
        setServicePage(newPage);
    }

    function handleServiceRowsPerPage(event) {
        console.log("val", event.target.value, parseInt(event.target.value));
        setServiceRowsPerPage(parseInt(event.target.value));
        setServicePage(0);
    }

    /*ProviderForm.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired
    };*/

    const handleStatusChange = (event, value) => {
        // if (event.target.value) {
        //     setIsSearching(true);
        // } else {
        //     setIsSearching(false);
        // }
        //console.log("newState", status, event.target.value);
        let status = value === "0" ? true : false;
        console.log("**Status Change**");
        setTabValue(value);
        console.log(status);
        if (status != null) {
            // Reset for new rows
            setToken();
            setCachedResults([]);
            setEndOfList(false);
            setPage(0);
            setRows([]);
            setFilteredRows([]);
            setFilteredCache([]);
            setFilteredToken();
            setIsSearching(false);
            setNonMainPage(false);
            setSearchValue("");
            setMode(status);
        }
    };
    const handleProviderLocations = (e, prov) => {
        console.log("handleProviderLocations", prov.id);
        actions.setMode("Edit");
        actions.setPage("ProviderLocationForm");
        actions.setId(prov.id);
        actions.setProvider(prov);
        localStorage.setItem("providerId", prov.id);
        localStorage.setItem("providerName", prov.name);
        if (isSearching || sessionStorage.getItem("back") === "true") {
            sessionStorage.setItem("search", true);
            if (!!searchValue) {
                sessionStorage.setItem("value", searchValue);
            }
        }
    };
    function handleSearchMsgClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setMsgOpen(false);
    }

    /*    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    } */

    function handleDeleteProviders(event) {
        setDeleteDialog(true);
    }

    function handleRowClick(event, id) {
        // setSelectedProvider("");
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    const fetchServiceTypeData = async () => {
        const authuser = getUserFromCache();

        const result = await graphql(
            graphqlOperation(serviceTypeByCompanyCustom, {
                companyId: authuser.company.id,
                limit: limit,
                filter: {
                    and: [
                        { deleted: { ne: true } },
                        { active: { ne: false } },
                        { isVisible: { ne: false } }
                    ]
                }
            })
        );
        await Cache.setItem(
            "servicetypes",
            result.data.serviceTypeByCompany.items
        );
    };

    const fileInput = React.createRef();

    // this function returns an array of service or skill IDs that will be used in the handleReadCSV function
    const getServiceSkillIds = (provider, type) => {
        let data,
            ids = [],
            serviceOrSkillTypes;
        // 0 means services
        if (type === 0) {
            data = serviceTypeData;
            serviceOrSkillTypes = provider.services; // this saves the list of services specified for the provider at index i
        }
        // 1 means skills
        else if (type === 1) {
            data = skillData;
            serviceOrSkillTypes = provider.skills;
        }

        // create an array for each specified service/skill type (separated by semi-colon)
        if (serviceOrSkillTypes && serviceOrSkillTypes.length > 0)
            serviceOrSkillTypes = serviceOrSkillTypes.split("; ");

        // find matches between csv services/skills and results from query and saves their ids into an array
        if (
            data &&
            data.length > 0 &&
            serviceOrSkillTypes &&
            serviceOrSkillTypes.length > 0
        ) {
            for (let x = 0; x < data.length; x++) {
                serviceOrSkillTypes.map((s) => {
                    if (s === data[x].name) {
                        ids.push(data[x].id);
                    }
                });
            }
        }
        return ids;
    };

    // this function retrieves location info from geocoder api
    const getLocationData = async (provider) => {
        let params = {
            app_id: APP_ID_HERE,
            app_code: APP_CODE_HERE,
            searchtext: `${provider.locationaddress} ${provider.locationstate} ${provider.locationzipcode} ${provider.locationcountry}`
        };

        try {
            const response = await axios.get(
                "https://geocoder.api.here.com/6.2/geocode.json",
                { params: params }
            );
            const view = response.data.Response.View;
            if (view.length > 0 && view[0].Result.length > 0) {
                return view[0].Result[0].Location;
            }
        } catch (err) {
            console.log(`Couldn't find address ${params.searchtext}`, err);
        }
    };

    // this function checks for duplicate providers based on email
    const checkDuplicateProv = async (email) => {
        // check for duplicate email in DB
        let resultDupProv = {},
            resultDupProvExt = {},
            dupProv;

        const filter = {
            and: [{ emailaddress: { eq: email } }]
        };

        // resultDupProv = await graphql(
        //     graphqlOperation(queries.listProviders, {
        //         filter,
        //         limit
        //     })
        // );

        resultDupProv = await execReadByPK({
            opname: "listProviders",
            op: queries.listProviders,
            filter: filter,
            sortDirection: "DESC"
        });

        if (!!resultDupProv && !!resultDupProv.items.length > 0) {
            let input = { id: resultDupProv.items[0].id };
            resultDupProvExt = await graphql(
                graphqlOperation(getProviderExtended, input)
            );

            let filter = {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            };

            let dupProvLocation = await execReadByPK({
                opname: "locationByProvider",
                op: queries.locationByProvider,
                id: { providerId: resultDupProv.items[0].id },
                filter,
                sortDirection: "DESC"
            });

            // const dupProvLocation = await graphql(
            //     graphqlOperation(queries.listProviderLocations, {
            //         companyId: user.company.id,
            //         limit: limit,
            //         filter
            //     })
            // );

            dupProv = {
                ...resultDupProv.items[0],
                ...resultDupProvExt.data.getProvider,
                locations: dupProvLocation.items[0]
            };
        }
        return dupProv;
    };

    // this function removes any duplicate services/skills
    const checkDuplicateServiceSkill = (dupProvST, ids, type) => {
        let field = "";
        if (type === 0) {
            field = "servicetype";
        } else if (type === 1) {
            field = "skill";
        }

        if (dupProvST && dupProvST.length > 0 && ids && ids.length > 0) {
            for (let x = 0; x < dupProvST.length; x++) {
                ids.map((id, index) => {
                    if (id === dupProvST[x][field].id) {
                        ids.splice(index, 1);
                    }
                });
            }
        }
        return ids;
    };

    const addProviderSchedule = async (id) => {
        const res1 = await graphql(
            graphqlOperation(getProviderExtended, { id: id })
        ); // returns provider object with nested service type ids
        const res2 = await graphql(
            graphqlOperation(queries.getProvider, { id: id })
        ); // returns provider object with nested location object

        const result = {
            ...res1.data.getProvider,
            locations: res2.data.getProvider.locations,
            company: res2.data.getProvider.company
        }; // save into one object
        console.log("Provider Data: ", result);
        const serviceIds = result.servicetypes.items.map(
            (s) => s.servicetype.id
        );

        const input = {
            user: user.id,
            providerId: id,
            locationId: result.locations.items[0].id, // assuming only one location, the previously created default location
            locationType: "PL", // provider location by default
            companyId: result.company.id,
            services: serviceIds, // array of servicetype IDs
            active: true,
            slotStartStep: 30 //Default 30
        };

        let res = await createSchedule(input); // call createSchedule, which adds SC and SR records for each service type
        console.log("res:", res);
        if (!!res && !res["error"]) {
            res = await handleCreateTimeBlock(res.id, result);
        }
    };

    const handleCreateTimeBlock = async (schId, providerData) => {
        const startDate = new Date(); // default today
        const endYear = startDate.getFullYear() + 5;
        const endDate = new Date(endYear, 11, 31); // default dec 31 five years from today, month is 0-indexed
        const startTime = new Date(
            moment().format("YYYY"),
            moment().format("MM"),
            moment().format("DD"),
            8,
            0
        );
        const endTime = new Date(
            moment().format("YYYY"),
            moment().format("MM"),
            moment().format("DD"),
            22,
            0
        ); // default 8am - 10pm
        const week = [0, 1, 2, 3, 4, 5, 6]; // default repeat all days

        const body = {
            user: user.id,
            companyId: providerData.company.id,
            locationId: providerData.locations.items[0].id,
            startDate: moment(startDate).format("YYYY-MM-DD"), // date and time must be saved in these formats
            endDate: moment(endDate).format("YYYY-MM-DD"),
            startTime: moment(startTime).format("HH:mm"),
            endTime: moment(endTime).format("HH:mm"),
            scheduleId: schId,
            weeksToRepeat: 1,
            weekDays: week,
            type: "AVAILABLE", // to indicate this block is avilable
            active: true
        };

        let res = await createTimeblock(body); // call createTimeblock, which creates SB record

        console.log("handleCreateTimeBlock response", JSON.stringify(res));
        return res;
    };

    const handleReadCSV = async (res) => {
        setShowLoading(true);
        try {
            logger.debug("Parsing Complete: ", res);
            let serviceTypeIds = [],
                skillIds = [],
                newProv,
                location = {},
                providersAdded = [],
                providersUpdated = [];

            if (!!res) {
                // res.data is an array of provider objects
                // res.meta.fields is an array of field or key names (i.e. firstname, lastname)

                await Promise.all(
                    res.data.map(async (provider, index) => {
                        let hasDuplicateProv = false,
                            dupProv = {};

                        // we force the email address of the current provider object to become lowercase
                        provider.emailaddress =
                            provider.emailaddress.toLowerCase();

                        // check for matching email in DB - if match, then update provider, else create provider
                        if (provider.emailaddress != "") {
                            dupProv = await checkDuplicateProv(
                                provider.emailaddress
                            );
                            if (dupProv) hasDuplicateProv = true;
                        }

                        if (
                            provider.firstname != "" ||
                            provider.lastname != "" ||
                            provider.emailaddress != ""
                        ) {
                            // create/update provider
                            let {
                                services,
                                skills,
                                locationaddress,
                                locationcity,
                                locationstate,
                                locationcountry,
                                locationzipcode,
                                locationradius,
                                travelunits,
                                ...input
                            } = provider;
                            input = {
                                ...input,
                                maxtraveltype: provider.maxtraveltype
                                    ? provider.maxtraveltype
                                    : "MAX_DISTANCE",
                                providerCompanyId: user.company.id,
                                companyId: user.company.id,
                                createdAt: hasDuplicateProv
                                    ? dupProv.createdAt
                                    : new Date()
                            };

                            let result = {};
                            if (!!hasDuplicateProv) {
                                input = { ...input, id: dupProv.id };
                                result = await graphql(
                                    graphqlOperation(mutations.updateProvider, {
                                        input
                                    })
                                );
                                logger.debug(
                                    "updateProvider result = " +
                                        JSON.stringify(result)
                                );
                                providersUpdated.push(result);
                            } else {
                                result = await graphql(
                                    graphqlOperation(mutations.createProvider, {
                                        input
                                    })
                                );
                                logger.debug(
                                    "createProvider result = " +
                                        JSON.stringify(result)
                                );
                                providersAdded.push(result);
                            }

                            // if provider creation/update was successful,
                            if (
                                !!result &&
                                (!!result.data.createProvider ||
                                    !!result.data.updateProvider)
                            ) {
                                let newProviderId = hasDuplicateProv
                                    ? result.data.updateProvider.id
                                    : result.data.createProvider.id;

                                // get the ids for services/skills specified in csv
                                if (
                                    provider.services &&
                                    provider.services != ""
                                ) {
                                    let ids = getServiceSkillIds(provider, 0);
                                    if (ids && ids.length > 0)
                                        serviceTypeIds[index] = ids;
                                    else serviceTypeIds[index] = [];
                                }
                                if (provider.skills && provider.skills != "") {
                                    let ids = getServiceSkillIds(provider, 1);
                                    if (ids && ids.length > 0)
                                        skillIds[index] = ids;
                                    else skillIds[index] = [];
                                }

                                // if provider was updated, remove any services/skills that are already specified for the user
                                if (hasDuplicateProv) {
                                    serviceTypeIds[index] =
                                        checkDuplicateServiceSkill(
                                            dupProv.servicetypes.items,
                                            serviceTypeIds[index],
                                            0
                                        );
                                    skillIds[index] =
                                        checkDuplicateServiceSkill(
                                            dupProv.skills.items,
                                            skillIds[index],
                                            1
                                        );
                                }

                                // if services/skills are to be added, add them for the current provider
                                if (
                                    serviceTypeIds[index] &&
                                    serviceTypeIds[index].length > 0
                                ) {
                                    await serviceTypeIds[index].map(
                                        async (id) => {
                                            let input = {
                                                providerServiceTypeProviderId:
                                                    newProviderId,
                                                providerServiceTypeServicetypeId:
                                                    id
                                            };
                                            logger.debug(
                                                "createProviderServiceType input = " +
                                                    JSON.stringify(input)
                                            );
                                            newProv = await graphql(
                                                graphqlOperation(
                                                    mutations.createProviderServiceType,
                                                    { input }
                                                )
                                            );
                                            logger.debug(
                                                "newProvST = " +
                                                    JSON.stringify(newProv)
                                            );
                                        }
                                    );
                                }

                                if (
                                    skillIds[index] &&
                                    skillIds[index].length > 0
                                ) {
                                    await skillIds[index].map(async (id) => {
                                        let input = {
                                            providerSkillProviderId:
                                                newProviderId,
                                            providerSkillSkillId: id
                                        };
                                        logger.debug(
                                            "createProviderSkill input = " +
                                                JSON.stringify(input)
                                        );
                                        newProv = await graphql(
                                            graphqlOperation(
                                                mutations.createProviderSkill,
                                                { input }
                                            )
                                        );
                                        logger.debug(
                                            "newProvST = " +
                                                JSON.stringify(newProv)
                                        );
                                    });
                                }

                                // add travelzone and location for provider if available
                                if (provider.locationaddress) {
                                    location = await getLocationData(provider); // make location data call
                                    if (location) {
                                        let street = `${
                                            !!location.Address.HouseNumber
                                                ? location.Address.HouseNumber
                                                : ""
                                        } ${
                                            location.Address.Street &&
                                            location.Address.Street
                                        } ${
                                            location.Address.suite
                                                ? location.Address.suite
                                                : ""
                                        }`;
                                        let addressoneline = `${street} ${location.Address.City} ${location.Address.State} ${location.Address.PostalCode}`;
                                        let traveltype = hasDuplicateProv
                                            ? result.data.updateProvider
                                                  .maxtraveltype
                                            : result.data.createProvider
                                                  .maxtraveltype;

                                        let input = {
                                            name: "Default Location",
                                            addressoneline: addressoneline,
                                            street: street,
                                            city: location.Address.City,
                                            state: location.Address.State,
                                            country: location.Address.Country,
                                            postalcode:
                                                location.Address.PostalCode,
                                            longitude:
                                                location.DisplayPosition
                                                    .Longitude,
                                            latitude:
                                                location.DisplayPosition
                                                    .Latitude,
                                            timezone: "EST",
                                            companyId: user.company.id,
                                            maxtraveltype:
                                                provider.maxtraveltype
                                                    ? provider.maxtraveltype
                                                    : "MAX_DISTANCE",
                                            traveldistance:
                                                provider.locationradius &&
                                                traveltype === "MAX_DISTANCE"
                                                    ? provider.locationradius
                                                    : traveltype ===
                                                        "MAX_DISTANCE"
                                                      ? 25
                                                      : null,
                                            traveldistanceunit:
                                                provider.travelunits &&
                                                traveltype === "MAX_DISTANCE"
                                                    ? provider.travelunits
                                                    : traveltype ===
                                                        "MAX_DISTANCE"
                                                      ? "km"
                                                      : null,
                                            providerId: newProviderId,
                                            providerLocationProviderId:
                                                newProviderId,
                                            providerLocationCompanyId:
                                                user.company.id,
                                            createdAt: hasDuplicateProv
                                                ? dupProv.locations.createdAt
                                                : new Date()
                                        };

                                        // if duplicate provider, update existing location
                                        if (!!hasDuplicateProv) {
                                            input = {
                                                ...input,
                                                id: dupProv.locations.id
                                            };
                                            newProv = await graphql(
                                                graphqlOperation(
                                                    mutations.updateProviderLocation,
                                                    { input }
                                                )
                                            );
                                            logger.debug(
                                                "updateProvLOcation = " +
                                                    JSON.stringify(newProv)
                                            );
                                        } else {
                                            newProv = await graphql(
                                                graphqlOperation(
                                                    mutations.createProviderLocation,
                                                    { input }
                                                )
                                            );
                                            logger.debug(
                                                "newProvLocation = " +
                                                    JSON.stringify(newProv)
                                            );
                                        }
                                    }
                                }

                                await addProviderSchedule(newProviderId); // add default schedule for provider after everything else has been added

                                setToken();
                                setCachedResults([]);
                                setPage(0);
                                setEndOfList(false);
                                refreshClicked();
                            }
                        }
                    })
                );

                //check if csv number of rows equals inputs providers added
                if (
                    res.data.length ===
                    providersAdded.length + providersUpdated.length
                ) {
                    let message =
                        providersAdded.length === 0 || providersAdded.length > 1
                            ? `${providersAdded.length} providers were imported. You can view the imported providers below.`
                            : `${providersAdded.length} provider was imported. You can view the imported provider below.`;
                    setSnackMsg(message);
                    setMsgOpen(true);
                } else {
                    setSnackMsg(`Unable to import providers.`);
                    setMsgOpen(true);
                }
            }
        } catch (err) {
            logger.error(
                "*** an error occurred. The error was: " + err.message
            );
            const errorresult = await logSystemError(
                user.company.id,
                "ERROR",
                "Error importing providers on ProviderForm.",
                0,
                err
            );
            logger.debug(errorresult);
            setSnackMsg(
                "Unable to import providers. The error was: " + err.message
            );
            setMsgOpen(true);
            setShowLoading(false);
            return;
        }
        setShowLoading(false);
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    const handleImportOffer = () => {
        fileInput.current.click();
    };

    async function prepareExportData() {
        console.log("called prepareExportData");
        const loggedInUser = getUserFromCache();
        console.log("called prepareExportData");

        let filter = {
            and: [{ deleted: { ne: true } }, { active: { ne: !mode } }]
        };

        const providersToExport = await execReadByPK({
            opname: "providerByCompany",
            op: providerByCompany,
            id: { companyId: loggedInUser.company.id },
            filter: !!filter ? filter : null,
            limit: 10000,
            sortDirection: "ASC"
        });
        console.log("called prepareExportData");

        if (providersToExport.items && providersToExport.items.length > 0) {
            console.log("called prepareExportData", providersToExport.items);
            const providersCSVData = await csvData({
                items: providersToExport.items
            });
            console.log("providersCSVData", JSON.stringify(providersCSVData));
            setExportReadyProviders(providersCSVData);
            return;
        } else {
            setMsgOpen(true);
            setSnackMsg("No providers were found to export.");
        }
        setExportReadyProviders([]);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(
                  0,
                  (1 + page) * rowsPerPage -
                      (isSearching ? filteredRows : rows).length
              )
            : 0;

    //selecting all rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = (isSearching ? filteredRows : rows).map(
                (n) => n.id
            );
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClearClick = (event) => {
        setSelected([]);
    };

    //selecting individual rows
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleThreeDotsClick = (event, providerId, providerName, idx) => {
        setSelectedProvider(providerId.toString());
        //setAnchorEl(event.currentTarget);

        console.log("**Handle Three Dots Click - Provider Table**");

        console.log(providerId);
        console.log(providerName);
        console.log(idx);
        console.log(event.currentTarget);
        console.log("-------------");
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleSearchMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="providers"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <Dialog open={showServiceInfoDialog} fullWidth={true} maxWidth="lg">
                <DialogTitle sx={{ fontSize: "24px", fontWeight: 400 }}>
                    All Services Provided by {providerName}
                </DialogTitle>
                <DialogContent>
                    {/*<DialogContentText id="show-provider-services">*/}
                    <Paper className={classes.root} style={{ padding: "0px" }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Service Name
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.customHeadPadding}
                                    >
                                        Service Description
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.customHeadPadding}
                                    >
                                        Service Behavior
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.customHeadPadding}
                                    >
                                        Service Duration
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.customHeadPadding}
                                    >
                                        Tax Exempt
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {serviceArray
                                    .slice(
                                        servicePage * serviceRowsPerPage,
                                        servicePage * serviceRowsPerPage +
                                            serviceRowsPerPage
                                    )
                                    .map((item, index) => {
                                        return (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {item.name}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {item.desc}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {item.behavior}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {item.time}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {item.taxexempt}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={12}
                                        count={serviceArray.length}
                                        rowsPerPage={serviceRowsPerPage}
                                        page={servicePage}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page"
                                            },
                                            native: true
                                        }}
                                        onPageChange={handleServicePage}
                                        onRowsPerPageChange={
                                            handleServiceRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                    {/*</DialogContentText>*/}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setMaxServiceClick(false);
                            setSelected([]);
                            setShowServiceInfoDialog(false);
                            setTimeout(() => {
                                setServicePage(0);
                                setServiceRowsPerPage(5);
                            }, 100);
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showExport}
                fullWidth={true}
                maxWidth={"xs"}
                onEnter={() => {}}
            >
                <DialogTitle>
                    <Typography
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                        variant="h4"
                    >
                        Export Users
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setExportReadyProviders([]);
                            setShowExport(false);
                            setPreparingExportData(false);
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ padding: 24 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                {`The following list of users will be exported:`}
                            </Typography>
                            <Typography variant="body1">
                                {mode
                                    ? `All Active users`
                                    : `All Inactive users`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={preparingExportData}
                                onClick={async () => {
                                    setExportReadyProviders([]);
                                    setShowExportSpinner(true);
                                    setPreparingExportData(true);
                                    await prepareExportData();
                                    setPreparingExportData(false);
                                    setShowExportSpinner(false);
                                }}
                                style={{ marginTop: "24px" }}
                            >
                                Prepare Export
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {preparingExportData && (
                                <Typography variant="body1">
                                    {`We are preparing your users for export.`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {showExportSpinner && <SmallSpinner />}
                        </Grid>
                        {exportReadyProviders &&
                            exportReadyProviders.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {`Please click the download button below to download your users.`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CSVLink
                                            data={exportReadyProviders}
                                            filename={`users_${moment(
                                                Date.now()
                                            ).format("YYYY-MM-DD")}.csv`}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<GetAppIcon />}
                                            >
                                                Download
                                            </Button>
                                        </CSVLink>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </DialogContent>
            </Dialog>
            <FormHeading title={"Users"} classes={classes.title} />
            <Backdrop className={classes1.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* }
        <Box component="span" m={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={refreshClicked}
          >
            Refresh
          </Button>
        </Box>
      { */}

                    <form
                        onSubmit={(evt) => {
                            // stop default form processing
                            evt.preventDefault();
                            // make function call passed in props to add
                            //reset();
                        }}
                    >
                        <Grid container alignItems={"center"}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    display: "flex",
                                    gap: "0.8rem",
                                    flexWrap: "wrap"
                                }}
                            >
                                <Button
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleProviderAddClick}
                                    startIcon={<AddIcon />}
                                >
                                    Add User
                                </Button>

                                <Button
                                    onClick={() => setShowExport(true)}
                                    variant="contained"
                                    style={{
                                        textTransform: "capitalize",
                                        background: "white",
                                        border: "1px solid rgba(0,0,0,0.3)",
                                        boxShadow: "none",
                                        color: "rgba(0,0,0,0.8)"
                                    }}
                                >
                                    Export CSV
                                </Button>
                                {selected.length > 0 && (
                                    <Button
                                        onClick={() => setDeleteDialog(true)}
                                        variant="contained"
                                        style={{
                                            textTransform: "capitalize",
                                            background:
                                                "rgba(255, 104, 29, 0.2)",
                                            boxShadow: "none",
                                            color: "#FF681D"
                                        }}
                                    >
                                        Delete
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Link align="center" underline="hover" to="/skills" onClick={() => {
                                        actions.setPage(OUTLET_PAGE);
                                    }}>
                                        <Typography noWrap={true} color="primary">
                                            <b>Manage skills</b>
                                        </Typography>
                                    </Link>
                                    <TextField
                                        id="search"
                                        value={
                                            searchValue
                                                ? searchValue
                                                : backSearchValue
                                        }
                                        onChange={handleSearchChange}
                                        label="Search by name, email or phone"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="clear"
                                                        onClick={() =>
                                                            clearSearch()
                                                        }
                                                        size="large"
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid sx={{ width: "100%" }} container item>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    width: "100%"
                                }}
                            >
                                <Tabs
                                    value={tabValue}
                                    onChange={handleStatusChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab label="Active" value={"0"} />
                                    <Tab label="Inactive" value={"1"} />
                                </Tabs>
                            </Box>
                        </Grid>
                    </form>
                </Grid>
            </Grid>

            {/*New Table Starts*/}
            <div style={{ overflow: "auto", marginTop: "1rem" }}>
                {/*  <EnhancedTableToolbar numSelected={selected.length} /> */}

                <TableContainer sx={{ minWidth: "1000px" }}>
                    <Table sx={providerTableTheme}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={
                                isSearching ? filteredRows.length : rows.length
                            }
                            onSelectAllClick={handleSelectAllClick}
                            onHandleClearClick={handleClearClick}
                        />

                        <TableBody
                            sx={{
                                "& .MuiTableRow-root": {
                                    "& .MuiTableCell-root": {
                                        "& .MuiTypography-root": {
                                            fontSize: "1rem"
                                        }
                                    }
                                }
                            }}
                        >
                            {(isSearching ? filteredRows : rows)
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const servicesLength =
                                        row.numberofservices &&
                                        row.service &&
                                        row.numberofservices > 0
                                            ? row.service.split("|").join(",")
                                                  .length > 45
                                            : false;
                                    return (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                backgroundColor:
                                                    selectedProvider === row.id
                                                        ? "primary.light"
                                                        : "white"
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    onClick={(event) =>
                                                        handleRowClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        maxWidth: "60px",
                                                        backgroundColor:
                                                            row.active
                                                                ? "success.light"
                                                                : "rgba(0,0,0,0.1)",
                                                        color: row.active
                                                            ? "success.dark"
                                                            : "rgba(0,0,0,0.5)",
                                                        textTransform:
                                                            "uppercase",
                                                        fontWeight: 600,
                                                        padding: "1px",
                                                        borderRadius: "2px",
                                                        textAlign: "center",
                                                        margin:
                                                            selected.length > 0
                                                                ? "0 auto"
                                                                : "0"
                                                    }}
                                                    style={{
                                                        fontSize: "0.75rem"
                                                    }}
                                                >
                                                    {row.active
                                                        ? "Active"
                                                        : "Inactive"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "0.5rem",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Avatar
                                                        variant="rounded"
                                                        src={row.pictureURL}
                                                        sx={{
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius: "8px"
                                                        }}
                                                        alt="Provider"
                                                    />

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center"
                                                            }}
                                                        >
                                                            <Typography
                                                                component={
                                                                    "div"
                                                                }
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontSize:
                                                                        "1rem",
                                                                    color: "rgba(0,0,0,0.75)",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    "&:hover": {
                                                                        cursor: "pointer",
                                                                        textDecoration:
                                                                            row?.permalink &&
                                                                            "underline"
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    const loggedInUser =
                                                                        getUserFromCache();
                                                                    let URL =
                                                                        loggedInUser
                                                                            .company
                                                                            .subdomain;
                                                                    if (
                                                                        row?.permalink
                                                                    ) {
                                                                        window.open(
                                                                            `https://${URL}/provider/${row?.permalink}`,
                                                                            "_blank"
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {row.name}
                                                            </Typography>
                                                        </div>

                                                        <Typography
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize:
                                                                    "0.75rem",
                                                                color: "rgba(0,0,0,0.5)"
                                                            }}
                                                        >
                                                            {row.email}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography>
                                                    {row.role ===
                                                    "COMPANY_ADMIN_PROVIDER"
                                                        ? "Admin"
                                                        : "Provider"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <Typography
                                                        onClick={(event) =>
                                                            handleScheduleClick(
                                                                event,
                                                                row
                                                            )
                                                        }
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                            lineHeight:
                                                                "1.5rem",
                                                            color: "primary.main",
                                                            cursor: "pointer",
                                                            "&:hover": {
                                                                textDecoration:
                                                                    "underline"
                                                            }
                                                        }}
                                                    >
                                                        {row.numberofschedules &&
                                                        row.numberofschedules >
                                                            0
                                                            ? row.numberofschedules +
                                                              `${
                                                                  row.numberofschedules ===
                                                                  1
                                                                      ? " Schedule"
                                                                      : " Schedules"
                                                              }`
                                                            : "No Schedules"}
                                                    </Typography>

                                                    <Typography
                                                        onClick={(event) =>
                                                            handleRatingClick(
                                                                event,
                                                                row
                                                            )
                                                        }
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                            lineHeight:
                                                                "1.5rem",
                                                            color: "primary.main",
                                                            cursor: "pointer",
                                                            "&:hover": {
                                                                textDecoration:
                                                                    "underline"
                                                            }
                                                        }}
                                                    >
                                                        {row.numberofratings &&
                                                        row.numberofratings > 0
                                                            ? row.numberofratings +
                                                              `${
                                                                  row.numberofratings ===
                                                                  1
                                                                      ? " Rating"
                                                                      : " Ratings"
                                                              }`
                                                            : "0 Ratings"}
                                                    </Typography>
                                                </div>
                                            </TableCell>

                                            <TableCell
                                                sx={{ maxWidth: "400px" }}
                                            >
                                                <div
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        textOverflow: "ellipsis"
                                                    }}
                                                >
                                                    <Typography
                                                        onClick={() =>
                                                            showServiceDialog(
                                                                row
                                                            )
                                                        }
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                            lineHeight:
                                                                "1.5rem",
                                                            color: "primary.main",
                                                            cursor: "pointer",
                                                            "&:hover": {
                                                                textDecoration:
                                                                    "underline"
                                                            }
                                                        }}
                                                    >
                                                        {row.numberofservices &&
                                                        row.numberofservices > 0
                                                            ? row.numberofservices +
                                                              `${
                                                                  row.numberofservices ===
                                                                  1
                                                                      ? " Service"
                                                                      : " Services"
                                                              }`
                                                            : "No Services"}
                                                    </Typography>

                                                    {row.numberofservices &&
                                                        row.numberofservices >
                                                            0 && (
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        "0.75rem",
                                                                    color: "rgba(0,0,0,0.75)",
                                                                    textOverflow:
                                                                        "ellipsis"
                                                                }}
                                                            >
                                                                {/*servicesLength > 4
                                    ? row.services.split('|').splice(0, 4).join(',') +
                                    '...'
                                : row.services.split('|').join(',')*/}
                                                                {servicesLength
                                                                    ? row.service
                                                                          ?.split(
                                                                              "|"
                                                                          )
                                                                          .join(
                                                                              ","
                                                                          )
                                                                          .substring(
                                                                              0,
                                                                              45
                                                                          ) +
                                                                      "..."
                                                                    : row.service
                                                                      ? row.service
                                                                            .split(
                                                                                "|"
                                                                            )
                                                                            .join(
                                                                                ","
                                                                            )
                                                                      : ""}
                                                            </Typography>
                                                        )}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography>
                                                    {row?.userActive &&
                                                    row.LoginInfo
                                                        ? "Yes"
                                                        : !row?.userActive
                                                          ? "No"
                                                          : "Pending"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "1rem",
                                                        fontSize: "1rem",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Typography
                                                        onClick={(event) =>
                                                            handleEditClick(
                                                                event,
                                                                row.id
                                                            )
                                                        }
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "1rem",
                                                            color: "primary.main",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        Edit
                                                    </Typography>

                                                    <ProviderDropDown
                                                        providerId={row.id}
                                                        providerName={row.name}
                                                        row={row}
                                                        handleThreeDotsClick={
                                                            handleThreeDotsClick
                                                        }
                                                        locationCount={
                                                            row.locationCount
                                                        }
                                                        handleProviderLocations={
                                                            handleProviderLocations
                                                        }
                                                        handleBlockTimeClick={
                                                            handleBlockTimeClick
                                                        }
                                                        index={index}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap"
                    }}
                >
                    {!isSearching && !nonMainPage && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLoadMore}
                            disabled={endOfList}
                            style={{
                                padding: "0.2rem",
                                paddingRight: "0.5rem",
                                paddingLeft: "0.5rem"
                            }}
                        >
                            {endOfList
                                ? "No More Rows To Load"
                                : "Load More Rows"}
                        </Button>
                    )}
                    <TablePagination
                        sx={{
                            marginLeft: "auto",
                            "& .MuiToolbar-root": {
                                alignItems: "center",
                                "& .MuiInputBase-root": {
                                    fontWeight: 700
                                },
                                "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                    {
                                        color: "rgba(0,0,0,0.5)",
                                        marginBottom: 0
                                    },
                                "& .MuiTablePagination-actions": {
                                    display: "flex",
                                    gap: "0.5rem",
                                    "& button": {
                                        border: "1px solid rgba(0,0,0,0.2)",
                                        borderRadius: "4px"
                                    },

                                    "& button:hover": {
                                        backgroundColor: "primary.light"
                                    }
                                }
                            }
                        }}
                        count={isSearching ? filteredRows.length : rows.length}
                        component={"div"}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        rowsPerPage={rowsPerPage}
                    />
                </div>
            </div>
        </>
    );
}

export default ProviderForm;
