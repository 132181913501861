import React, { useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Box, Button, Paper, Grid, FormControl, Typography, Snackbar, Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, InputAdornment, Input, CardMedia, Link, Checkbox } from "@mui/material";
// prettier-ignore
import { Select, FormHelperText, CircularProgress, Backdrop, MenuItem, InputLabel, DialogContentText, DialogActions, Chip, Tabs, Tab, Avatar, Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Autocomplete } from "@mui/material";
import { AddressEntryTextBox } from "../components/address/AddressEntryTextBox";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { useStyles } from "../styles/MainNavbarStyles";
import { CustomTooltip, CustomBasicTooltip } from "../styles/CustomMuiStyles";
import { useCompanyStyles } from "../styles/CompanyFormStyles";
import {
    graphql,
    graphqlOperation,
    postApi,
    putApi,
    getJsonApi
} from "../modules/AmplifyServices";
import { getCurrentUser } from "aws-amplify/auth";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getUser } from "../graphql/queries";
import {
    companyIsSoleOperator,
    getUserFromCache,
    userHasCompanyAdminRole,
    userHasMarketboxAdminRole,
    updateCompanyObjectInCachedUser
} from "../user/UserCommon";
import {
    currencies,
    countryCodes,
    subscriptionLevelConst,
    FEATURE_NAME_CLUSTERING,
    SUBDOMAIN_REGEX_SPECIAL_CHARS,
    SUBDOMAIN_REGEX_MULTIPLE_HYPHENS
} from "../utils/Constants";
import * as Sentry from "@sentry/react";
import validator from "validator";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SketchPicker } from "react-color";
import { auditCompanyUpdate, auditCompanyCreate } from "../modules/Audit";
import { getCompanyWelcomeMessageState } from "../modules/Company";
import StripeConnect from "../components/stripe/StripeConnect";
import validateCompany from "../utils/WhiteLabelling";
import isFeatureGated, {
    isOpenForClusters
} from "../modules/FeatureGatingModule";
import FeatureGateDialog from "../components/FeatureGateDialog";
import StarsIcon from "@mui/icons-material/Stars";
import {
    TIME_SUGGESTION_CONFIG_DEFAULTS,
    anchorTimeOptions,
    maxTravelTimeMinutesBetweenBookings
} from "../utils/Constants";
import { validateUniqueCompanyName } from "../pages/checkout/modules/ValidityCheck";

import { useAtom } from "jotai";
import { brandingStatesAtom, domainStatesAtom, bookingStatesAtom, desiredSubdomainAtom, CompanySettingsInitialTabAtom, bookingFlowSettingsFilesAtom} from "../atoms/bookingFlowCustomization";
import { defaultBookingStates, defaultBrandingStates, defaultDomainStates, defaultBookingFlowSettingsFiles } from "../utils/Constants";
import BookingFlowSettings from "./BookingFlowSettings/BookingFlowSettings";
import { useNavigate, useLocation } from "react-router-dom";
import { OUTLET_PAGE } from "../context/reducers";

const axios = require("axios");

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const autocompleteService = { current: null };
const defaultNoProviderMsg = `Thank you for your interest. Unfortunately at this time, there is no availability in your area. Please leave your contact info and we will reach out when this changes.`;
const defaultTagline = "Your company's tagline goes here";
const defaultHeading1Text = "Your Heading 1 Text Goes Here";
const defaultHeading2Text = "Your Heading 2 Text Goes Here";
const defaultTextColor = "#FFFFFF";
const defaultButtonColor = "#0087EE";
const defaultButtonTextColor = "#FFFFFF";
const defaultlogoimg =
    "https://marketbox-prod-booknow.s3.amazonaws.com/public/company-assets/default_logo.png";
const defaultbackgroundimg =
    "https://marketbox-prod-booknow.s3.amazonaws.com/public/company-assets/default_background.png";

export default function CompanyAddEditForm(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const { state, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    // fields
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [contactname, setContactName] = useState("");
    const [emailaddress, setEmailAddress] = useState("");
    const [replyemailaddress, setReplyEmailAddress] = useState("");
    const [currency, setCurrency] = useState("");
    const [companyId, setCompanyId] = useState(
        props.companyId ? props.companyId : ""
    );
    const [addressOneLine, setAddressOneLine] = useState("");
    const [addressStreet, setAddressStreet] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [isEdit, setisEdit] = useState(false);
    const [longitude, setLongitude] = useState(0);
    const [latitude, setLatitude] = useState(0);
    const [oldInfo, setOldInfo] = useState([{}]);
    //loading scree
    const [isLoading, setIsLoading] = useState(true);

    // This state holds the url loaded from getCompanyData
    const [logoUrl, setLogoUrl] = useState(defaultlogoimg);
    // The following two states are required to update the pages with the current uploaded logo file
    const [currentLogoUrl, setCurrentLogoUrl] = useState("");
    const [currentLogoFile, setCurrentLogoFile] = useState("");
    // Holds the tos url loaded in getCompanyData
    const [tosUrl, setTosUrl] = useState("");
    const [currentTosFile, setCurrentTosFile] = useState("");

    // This state holds the url loaded from getCompanyData
    const [backgroundImageUrl, setBackgroundImageUrl] =
        useState(defaultbackgroundimg);
    const [setupCompleted, setSetupCompleted] = useState("");
    const [currentBackgroundImageUrl, setCurrentBackgroundImageUrl] =
        useState();
    const [currentBackgroundImageFile, setCurrentBackgroundImageFile] =
        useState("");
    const [tosOpen, setTosOpen] = useState(false);
    const [iframeUrl, setIframeUrl] = useState("");
    const [stripeKey, setStripeKey] = useState("");
    const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = useState(false);
    const [showButtonColorPicker, setShowButtonColorPicker] = useState(false);
    const [showButtonTextColorPicker, setShowButtonTextColorPicker] =
        useState(false);
    const [primaryColor, setPrimaryColor] = useState("#0087EE");
    const [providerRatios, setProviderRatios] = useState(false);
    const [active, setActive] = useState(true);
    const [collectPayments, setCollectPayment] = useState(false);
    const [subdomain, setSubdomain] = useState("");
    const [maskedDomain, setMaskedDomain] = useState("");
    const [serviceFeeType, setServiceFeeType] = useState("PERCENTAGE");
    const [serviceFeeAmount, setServiceFeeAmount] = useState(10);
    const [addServiceFee, setAddserviceFee] = useState(false);
    const [countrycode3166alpha2, setCountrycode3166alpha2] = useState("");
    const [selectProvTimeout, setSelectProvTimeout] = useState(60);
    const [addProvTimeout, setAddProvTimeout] = useState(60);
    const [displayOnlyLogo, setDisplayOnlyLogo] = useState(false);
    const [providerMustAcceptAppt, setProviderMustAcceptAppt] = useState(false);
    const [sendratingTipMsg, setSendratingTipMsg] = useState(true);
    const [showTipOption, setShowTipOption] = useState(true);
    const [clientNotesHeading, setClientNotesHeading] = useState("");
    const [ratingTipTextMsg, setRatingTipTextMsg] = useState(
        `Please take a moment to leave a rating for [provider-first-name] for your "[service]" session on [booking_short_date]. You may also leave a tip if you wish:\n\n[link]`
    );
    const [offersPackagesOnly, setOffersPackagesOnly] = useState(false);
    const [forceRepeatingPackages, setForceRepeatingPackages] = useState(false);
    const [offersForeverAppt, setOffersForeverAppt] = useState(false);
    const [tagline, setTagline] = useState(defaultTagline);
    const [travelTime, setTravelTime] = useState(0);
    const [currencyBasedOnLocation, setCurrencyBasedOnLocation] =
        useState(false);
    const [homepage, setHomepage] = useState("");
    const [bookingReqCreatedList, setBookingReqCreatedList] = useState("");
    const [bookingReqExpiredList, setBookingReqExpiredList] = useState("");
    const [orderSummaryBccList, setOrderSummaryBccList] = useState("");
    const [welcomeBccList, setWelcomeBccList] = useState("");
    const [hideRatingsFromClients, setHideRatingsFromClients] = useState(false);
    const [subscriptionLevel, setSubscriptionLevel] = useState("");
    const [cancelHours, setCancelHours] = useState(0);
    const [cancelPolicyMinsBefore, setCancelPolicyMinsBefore] = useState(0);
    const [providerCanCancelAppt, setProviderCanCancelAppt] = useState(false);
    const [canProviderReschedule, setCanProviderReschedule] = useState(false);
    const [canClientReschedule, setCanClientReschedule] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showFeatureGateDialog, setShowFeatureGateDialog] = useState(false);
    const [noProviderMsg, setNoProviderMsg] = useState(defaultNoProviderMsg);

    // tabs
    const [CompanySettingsInitialTab, setCompanySettingsInitialTab] = useAtom(CompanySettingsInitialTabAtom);
    const [tabValue, setTabValue] = useState(CompanySettingsInitialTab);
    setCompanySettingsInitialTab(0);
    const [secvalue, setSecvalue] = useState();
    const [newSecvalue, setNewSecvalue] = useState();

    const [showSecvalUploadDialog, setShowSecvalUploadDialog] = useState(false);
    const [welcomeMessageState, setWelcomeMessageState] = useState();
    const [bookingIncrement, setBookingIncrement] = useState(15);
    const [stripePaymentSettings, setPaymentSettings] = useState(null);
    const [stripeConnectedAccount, setStripeConnectedAccount] = useState(null);
    const [stripeConnectEnabled, setStripeConnectEnabled] = useState(false);
    const [showStripeConnectResult, setShowStripeConnectResult] =
        useState(false);
    const [stripeConnectError, setStripeConnectError] = useState(false);

    const [mandatoryNotes, setMandatoryNotes] = useState(false);
    const [providerAgnosticFlow, setProviderAgnosticFlow] = useState(false);
    const [chipsOrder, setChipsOrder] = useState([]);
    const [chipsWelcome, setChipsWelcome] = useState([]);
    const [chipsOrderCreated, setChipsOrderCreated] = useState([]);
    const [chipsOrderExpired, setChipsOrderExpired] = useState([]);
    const classes = useStyles();
    const classesnew = useCompanyStyles();
    const classes3 = usePromotionsStyles();
    //type
    const [values, setValues] = useState({
        type: "",
        remote: true,
        premise: true,
        heading1Text: defaultHeading1Text,
        heading2Text: defaultHeading2Text,
        textColor: defaultTextColor,
        buttonColor: defaultButtonColor,
        buttonTextColor: defaultButtonTextColor,
        id: ""
    });
    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    //setup a default currency state
    const [defaultCurrencyValue, setDefaultCurrencyValue] = useState({});
    const [singleApptOnly, setSingleApptOnly] = useState(false);
    const [showStripeKeyInput, setShowStripeKeyInput] = useState(false);


    const [brandingStates, setBrandingStates] = useAtom(brandingStatesAtom);
    const [domainStates, setDomainStates] = useAtom(domainStatesAtom);
    const [bookingStates, setBookingStates] = useAtom(bookingStatesAtom);
    const [desiredSubdomain, setDesiredSubdomain] = useAtom(desiredSubdomainAtom);
    const [bookingFlowSettingsFiles, setBookingFlowSettingsFiles] = useAtom(bookingFlowSettingsFilesAtom);

    const [timeSuggestionsEnabled, setTimeSuggestionsEnabled] = useState(false);
    const [
        timeSuggestionsForceClientAccept,
        setTimeSuggestionsForceClientAccept
    ] = useState(true);
    const [
        timeSuggestionsFirstBookingOfTheDay,
        setTimeSuggestionsFirstBookingOfTheDay
    ] = useState(TIME_SUGGESTION_CONFIG_DEFAULTS.timeOfFirstBookingOfTheDay);
    const [
        timeSuggestionsMaxTravelTimeMinutesBetweenBookings,
        setTimeSuggestionsMaxTravelTimeMinutesBetweenBookings
    ] = useState(TIME_SUGGESTION_CONFIG_DEFAULTS.maxTravelTimeMinutes);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (stripeConnectedAccount && !stripeConnectEnabled) {
                async function getStripeAccountDetails(accountId) {
                    const result = await getJsonApi(
                        "stripeconnect",
                        "/account",
                        {
                            queryParams: {
                                stripeAccountId: accountId
                            }
                        }
                    );
                    console.log("result", result);
                    if (result && result.account) {
                        if (
                            result.account.charges_enabled &&
                            result.account.details_submitted
                        ) {
                            setStripeConnectEnabled(true);
                            setCollectPayment(true);
                            await saveStripeAccountInfo(
                                result.account,
                                props.companyId ? props.companyId : state.id,
                                true
                            );
                        }
                    } else setStripeConnectError(true);
                    setShowStripeConnectResult(true);
                }
                getStripeAccountDetails(stripeConnectedAccount);
            }
        }
        return () => {
            mounted = false;
        };
    }, [stripeConnectedAccount]);

    const isMarketboxAdmin = () => {
        if (loggedInUser?.role === "MARKETBOX_ADMIN") {
            return true;
        }
        return false;
    };

    function showStripeConnectButton() {
        if (stripeKey && secvalue) return false;
        if (stripeConnectEnabled) return false;
        if (stripeConnectedAccount && !stripeConnectEnabled) return true;
        return true;
    }

    async function saveStripeAccountInfo(
        stripeAccountInfo,
        companyId,
        stripeConnectEnabled
    ) {
        try {
            let result = await graphql(
                graphqlOperation(mutations.updateCompany, {
                    input: {
                        id: companyId,
                        stripeConnectEnabled,
                        collectpayment: true,
                        PaymentSettings: JSON.stringify({
                            stripeAccount: stripeAccountInfo
                        })
                    }
                })
            );
            await updateCompanyObjectInCachedUser(result?.data?.updateCompany);
        } catch (e) {
            console.log("error", e);
        }
    }

    function showSuggestionSettings() {
        return (
            isOpenForClusters(companyId) &&
            !isFeatureGated(subscriptionLevel, FEATURE_NAME_CLUSTERING)
        );
    }

    //setup a useEffect hook to configure that default value based on the currency value
    useEffect(() => {
        console.log(currencies);
        let mounted = true;
        if (mounted)
            currencies.filter((item) =>
                item.value === currency
                    ? setDefaultCurrencyValue(item)
                    : console.log("Currency not existed")
            );
        return () => {
            mounted = false;
        };
    }, [currency]);

    //setup a default code state
    const [defaultCountryCode, setDefaultCountryCode] = useState({});
    //setup a useEffect hook to configure that default value based on the code value
    useEffect(() => {
        console.log(countryCodes);
        let mounted = true;
        if (mounted)
            countryCodes.filter((item) =>
                item.value === countrycode3166alpha2
                    ? setDefaultCountryCode(item)
                    : console.log("Code not existed")
            );
        return () => {
            mounted = false;
        };
    }, [countrycode3166alpha2]);

    useEffect(() => {
        console.log("**Default Currency Value**");
        console.log(defaultCurrencyValue);
        console.log(defaultCountryCode);
    }, [defaultCurrencyValue, defaultCountryCode]);

    //setup a default enterprise state
    const [defaultSubscriptionLevel, setDefaultSubscriptionLevel] = useState(
        {}
    );

    //setup a useEffect hook to configure that default value based on the currency value
    useEffect(() => {
        let mounted = true;
        if (mounted)
            subscriptionLevelConst.filter((item) =>
                item.value === subscriptionLevel
                    ? setDefaultSubscriptionLevel(item)
                    : console.log("None")
            );
        return () => {
            mounted = false;
        };
    }, [subscriptionLevel]);

    useEffect(() => {
        let mounted = true;
        if (mounted)
            if (singleApptOnly) {
                setOffersForeverAppt(false);
                setOffersPackagesOnly(false);
                setForceRepeatingPackages(false);
            }
        return () => {
            mounted = false;
        };
    }, [singleApptOnly]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            async function fetch() {
                //setLabelWidth(inputLabel.current.offsetWidth);
                setBrandingStates(defaultBrandingStates);
                setDomainStates(defaultDomainStates);
                setBookingStates(defaultBookingStates);
                setBookingFlowSettingsFiles(defaultBookingFlowSettingsFiles);
                setDesiredSubdomain("");
                
                if (state.mode === "Edit" || state.mode === "View") {
                    const companyDataLocal = await getCompanyData();
                    const welcomeMegState =
                        await getCompanyWelcomeMessageState(companyDataLocal);
                    setWelcomeMessageState(welcomeMegState);
                } else {
                    setIsLoading(false);
                }
            }
            fetch();
        }

        return () => {
            mounted = false;
        };
    }, []);

    // service type select
    const [bookingIntervalMinutes, setBookingIntervalMinutes] = useState(0);
    const [multipleServices, setMultipleServices] = useState(false);
    const [multipleQty, setMultipleQty] = useState(false);
    const [useAnonymousPermalink, setUseAnonymousPermalink] = useState(true);

    //checking for valeues id field
    useEffect(() => {
        console.log("Checking Values", values);
        console.log("Subdomain Company Settings", homepage, subdomain, tagline);
    }, [values, subdomain, homepage, tagline]);

    // color picker styling
    const popover = {
        position: "absolute",
        left: "500px",
        zIndex: "2"
    };

    const dropdownStyle = {
        right: "12px"
    };

    function throwEditSaveError(errorText, pageNum) {
        //Numerated beginning at 1 in input, but counting starts at 0. Incredibly tragic. Massive blunder.
        let num = pageNum - 1;

        setSnackMsg("Error: " + errorText);
        setMsgOpen(true);
        setTabValue(num);
    }

    async function getCompanyData() {
        setIsLoading(true);
        const companyId = props.companyId ? props.companyId : state.id;
        if (!!companyId) {
            await computeCompanyHasPreviousOrders();

            setCompanyId(companyId);
            setisEdit(true);
            const input = { id: companyId };
            const result = await graphql(
                graphqlOperation(queries.getCompany, input)
            );

            const {
                data: {
                    publicsiteByCompany: { items: [publicSiteData = {}] = [] } = {}
                } = {}
            } = await graphql(
                graphqlOperation(queries.publicsiteByCompany, {
                    companyId: companyId
                })
            );

            let publicResultData = {
                ...publicSiteData
            };

            let originalHeading1Text, originalHeading2Text;

            originalHeading1Text = publicSiteData.heading1Text;
            originalHeading2Text = publicSiteData.heading2Text;

            if (publicSiteData && Object.keys(publicResultData.length > 0)) {
                setValues((oldvalues) => ({
                    ...oldvalues,
                    id: publicSiteData.id
                }));

                if (!publicResultData.StyleCustomization) {
                    setBrandingStates((prevState) => {
                        return {
                            ...prevState,
                            backgroundURL: publicResultData.backgroundImageUrl,
                            buttonColor: publicSiteData.buttonColor,
                            companyTagline:
                                publicSiteData.heading1Text.length > 0
                                    ? publicSiteData.heading1Text
                                    : "Describe your what company offers",
                            companyDescription:
                                publicSiteData.heading2Text.length > 0
                                    ? publicSiteData.heading2Text
                                    : "Put a call to action or your company slogan",
                            headerFontColor: publicSiteData.textColor,
                            logoURL: publicSiteData.company.logoUrl,
                            companyName:
                                publicSiteData.company.name > 0
                                    ? publicSiteData.company.name
                                    : "Put your company name here",
                            showHeader: true,
                            backgroundColor: publicSiteData.buttonColor,
                            headerTransparency: 1,
                            showHeadingOne: true,
                            showHeadingTwo: true,
                            showHeadingThree: true
                        };
                    });
                }

                //StyleCustomization.bookingFlowCustomizations
                if (publicResultData.StyleCustomization &&
                    publicResultData.StyleCustomization.length > 0) {
                    
                    let publicResultDataStyle = JSON.parse(
                        publicResultData.StyleCustomization
                    );

                    
                    if (publicResultDataStyle?.bookingFlowCustomizations?.mandatoryNotes) {
                        setMandatoryNotes(
                            publicResultDataStyle.bookingFlowCustomizations
                                .mandatoryNotes
                        );
                    }
                    console.log("final testL: ", publicResultDataStyle);
                    if (
                        publicResultDataStyle?.tipPageCustomizations
                            ?.showTipOption !== undefined
                    ) {
                        setShowTipOption(
                            publicResultDataStyle.tipPageCustomizations
                                .showTipOption
                        );
                    }
                    
                    publicResultData.StyleCustomization = {
                            ...publicResultDataStyle
                        };

                    let bookingFlowCustomValues = {
                            ...publicResultData?.StyleCustomization
                                ?.bookingFlowCustomizations
                        };

                    setBookingStates((prevState) => {
                        return {
                            ...prevState,
                            categoryDisplay: bookingFlowCustomValues.categoryDisplay
                                ? bookingFlowCustomValues.categoryDisplay
                                : "ALLOPEN",
                            servicesDisplay: bookingFlowCustomValues.servicesDisplay
                                ? bookingFlowCustomValues.servicesDisplay
                                : "CARD",
                            checkoutOption: bookingFlowCustomValues.checkoutOption
                                ? bookingFlowCustomValues.checkoutOption
                                : "ACCOUNT_OR_GUEST",
                            displayServicePrices:
                                bookingFlowCustomValues &&
                                bookingFlowCustomValues.displayServicePrices !==
                                    undefined
                                    ? bookingFlowCustomValues.displayServicePrices
                                    : true
                        };
                    });
                    setBrandingStates((prevState) => {
                        return {
                            backgroundURL: publicResultData.backgroundImageUrl,
                            buttonColor: publicResultData.buttonColor,
                            buttonTextColor:
                                publicResultDataStyle.bookingFlowCustomizations
                                    .buttonTextColor,
                            companyTagline: publicResultData.heading1Text,
                            companyDescription: publicResultData.heading2Text,
                            headerFontColor: publicResultData.textColor,
                            logoURL: publicResultData.company.logoUrl,
                            showLogo: bookingFlowCustomValues.showLogo,
                            logoAsCircle: bookingFlowCustomValues.circularLogo,
                            showHeader: bookingFlowCustomValues.showHeader,
                            backgroundColor:
                                bookingFlowCustomValues.backgroundColor,
                            headerTransparency: bookingFlowCustomValues.opacity,
                            showHeaderText: bookingFlowCustomValues.showHeadings,
                            companyName: bookingFlowCustomValues.mainHeading,
                            headerFontType:
                                bookingFlowCustomValues.fontTypeForAllHeading,
                            companyNameFontSize:
                                bookingFlowCustomValues.fontSizeHeader1,
                            companyTaglineFontSize:
                                bookingFlowCustomValues.fontSizeHeader2,
                            companyDescriptionFontSize:
                                bookingFlowCustomValues.fontSizeHeader3,
                            showHeadingOne: bookingFlowCustomValues.hasOwnProperty(
                                "showHeadingOne"
                            )
                                ? bookingFlowCustomValues.showHeadingOne
                                : true,
                            showHeadingTwo: bookingFlowCustomValues.hasOwnProperty(
                                "showHeadingTwo"
                            )
                                ? bookingFlowCustomValues.showHeadingTwo
                                : true,
                            showHeadingThree:
                                bookingFlowCustomValues.hasOwnProperty(
                                    "showHeadingThree"
                                )
                                    ? bookingFlowCustomValues.showHeadingThree
                                    : true
                        };
                    });
                }
            }
            let tosURL;
            const tosExists = await getJsonApi(
                "s3filemgmt",
                `/exists/${result.data.getCompany.id}/${"tos"}`
            );
            if (tosExists.success === true) {
                tosURL = `https://marketbox-prod-booknow.s3.amazonaws.com/public/tos/${result.data.getCompany.id}-tos.pdf`;
                setTosUrl(tosURL);

                setBookingStates((prevState) => {
                    return {
                        ...prevState,
                        tosURL: tosURL
                    }
                })
            }

            if (!(!!result.errors && result.errors.length > 0)) {
                const {
                    name,
                    contactname,
                    emailaddress,
                    replyemailaddress,
                    currency,
                    addressoneline,
                    street,
                    bookingIntervalMinutes,
                    city,
                    state,
                    country,
                    postalcode,
                    longitude,
                    latitude,
                    active,
                    offersOnPremiseServices,
                    offersRemoteServices,
                    currencyBasedOnLocation,
                    collectpayment,
                    subdomain,
                    maskeddomain,
                    tagline,
                    logoUrl,
                    iframeURL,
                    primaryColor,
                    publishableStripeKey,
                    serviceFeeAmount,
                    serviceFeeType,
                    addServiceFee,
                    countrycode3166alpha2,
                    displayOnlyLogo,
                    providerMustAcceptAppt,
                    ratingTipTextMsg,
                    sendratingTipMsg,
                    offersPackagesOnly,
                    forceRepeatingPackages,
                    offersForeverAppt,
                    ApptAcceptanceFlowConfig,
                    travelTime,
                    homepage,
                    multipleServices,
                    multipleQty,
                    useAnonymousPermalink,
                    clientnotesheading,
                    BccLists,
                    hideRatingsFromClients,
                    subscriptionLevel,
                    cancelPolicyMinsBefore,
                    providerCanCancelAppt,
                    noProviderMsg,
                    displayProviderRatios,
                    bookingIncrement,
                    stripeAccount,
                    stripeConnectEnabled,
                    PaymentSettings,
                    singleApptOnly,
                    setupCompleted,
                    providerCanReschedule,
                    clientCanReschedule,
                    ProvAgnoFlowConfig,
                    SuggestionConfig
                } = result.data.getCompany;
                setSingleApptOnly(singleApptOnly ? singleApptOnly : false);
                console.log("result", result.data.getCompany);
                setStripeConnectEnabled(
                    stripeConnectEnabled ? stripeConnectEnabled : false
                );
                setStripeConnectedAccount(stripeAccount ? stripeAccount : null);
                if (ProvAgnoFlowConfig) {
                    const provAgnoFlowConfigObj =
                        JSON.parse(ProvAgnoFlowConfig);
                    setProviderAgnosticFlow(provAgnoFlowConfigObj?.isEnabled);
                }
                if (!ProvAgnoFlowConfig) {
                    //if config does not exist, provider directory is ON and agnostic flow is OFF
                    setProviderAgnosticFlow(false);
                }
                if (SuggestionConfig) {
                    const suggestionConfigObj = JSON.parse(SuggestionConfig);
                    const {
                        isEnabled,
                        timeOfFirstBookingOfTheDay,
                        forceClientAccept,
                        maxTravelTimeMinutes
                    } = suggestionConfigObj;
                    setTimeSuggestionsEnabled(isEnabled);
                    setTimeSuggestionsFirstBookingOfTheDay(
                        timeOfFirstBookingOfTheDay
                    );
                    setTimeSuggestionsForceClientAccept(forceClientAccept);
                    setTimeSuggestionsMaxTravelTimeMinutesBetweenBookings(
                        maxTravelTimeMinutes
                    );
                }
                if (PaymentSettings)
                    setPaymentSettings(JSON.parse(PaymentSettings));
                setTravelTime(
                    travelTime ? travelTime : 0 === travelTime ? 0 : 30
                );
                setName(name);
                setStripeKey(publishableStripeKey);
                if (setServiceFeeAmount) setServiceFeeAmount(serviceFeeAmount);
                if (serviceFeeType) setServiceFeeType(serviceFeeType);
                setAddserviceFee(addServiceFee);
                setContactName(contactname);
                setEmailAddress(emailaddress);
                setReplyEmailAddress(replyemailaddress);
                setBookingIntervalMinutes(bookingIntervalMinutes);
                setAddressOneLine(addressoneline);
                setAddressCity(city);
                setAddressState(state);
                setPostalCode(postalcode);
                setCountry(country);
                setCurrency(currency);
                setActive(active);
                setAddressStreet(street);
                setLongitude(longitude);
                setLatitude(latitude);
                setCollectPayment(collectpayment);
                setSubdomain(subdomain);
                setDesiredSubdomain(subdomain);
                setMaskedDomain(maskeddomain);
                setTagline(tagline ? tagline : defaultTagline);
                setLogoUrl(logoUrl ? logoUrl : defaultlogoimg);
                setIframeUrl(iframeURL ? iframeURL : "");
                setPrimaryColor(primaryColor ? primaryColor : "#0087EE");
                setCountrycode3166alpha2(
                    countrycode3166alpha2 ? countrycode3166alpha2 : ""
                );
                setDisplayOnlyLogo(displayOnlyLogo);
                setProviderMustAcceptAppt(providerMustAcceptAppt);
                setSendratingTipMsg(sendratingTipMsg);
                setOffersPackagesOnly(offersPackagesOnly);
                setForceRepeatingPackages(forceRepeatingPackages);
                setOffersForeverAppt(offersForeverAppt);
                setCurrencyBasedOnLocation(currencyBasedOnLocation);
                setClientNotesHeading(clientnotesheading);
                if (!!ratingTipTextMsg) setRatingTipTextMsg(ratingTipTextMsg);
                setSwitches({
                    target: { name: "remote", checked: offersRemoteServices }
                });
                setSwitches({
                    target: {
                        name: "premise",
                        checked: offersOnPremiseServices
                    }
                });
                setHomepage(homepage);
                setMultipleServices(multipleServices);
                setMultipleQty(multipleQty);
                setUseAnonymousPermalink(useAnonymousPermalink);

                let homepageState;
                if (homepage) {
                    if (homepage.length <= 0) {
                        homepageState = "";
                    } else {
                        let split_homepage = homepage.includes(
                            "http"
                        )
                            ? homepage.split("//")[1]
                            : homepage;
                        homepageState = split_homepage;
                    }
                }
                let subdomainState;
                if (result.data.getCompany.subdomain) {
                    if (subdomain.length <= 0) {
                        subdomainState = subdomain;
                    } else {
                        let split_subdomain = subdomain.includes(
                            ".gomarketbox"
                        )
                            ? subdomain.split(".gomarketbox")[0]
                            : subdomain;
                        subdomainState = split_subdomain;
                    }
                }

                let taglineState;
                if (tagline?.length <= 0) {
                    taglineState = name;
                } else {
                    taglineState = tagline;
                }

                setDomainStates((prevState) => {
                    if (result)
                        return {
                            ...prevState,
                            homepageURL: homepageState,
                            subdomain: subdomainState,
                            tagline: taglineState
                        };
                });

                setBookingStates((prevState) => {
                    return {
                        ...prevState,
                        bookingIncrements: bookingIncrement,
                        noProviderMessage:
                            noProviderMsg?.length <= 0
                                ? setupCompleted
                                    ? noProviderMsg
                                    : "Thank you for your interest. Unfortunately at this time, there is no availability in your area. Please leave your contact info and we will reach out when this changes."
                                : noProviderMsg,
                        showProviderAcceptance:
                            displayProviderRatios
                    };
                });

                let ordList = "",
                    welcomeList = "",
                    bookingReqCreatedList = "",
                    bookingReqExpiredList = "";

                if (BccLists) {
                    let obj = JSON.parse(BccLists);
                    if (
                        obj.hasOwnProperty("forOrdSum") &&
                        obj["forOrdSum"].length > 0
                    ) {
                        setChipsOrder([...obj["forOrdSum"]]);
                        ordList = obj["forOrdSum"].join(";");
                    }

                    if (
                        obj.hasOwnProperty("forWelcome") &&
                        obj["forWelcome"].length > 0
                    ) {
                        setChipsWelcome([...obj["forWelcome"]]);
                        welcomeList = obj["forWelcome"].join(";");
                    }

                    if (
                        obj.hasOwnProperty("forBookingReqCreated") &&
                        obj["forBookingReqCreated"].length > 0
                    ) {
                        setChipsOrderCreated([...obj["forBookingReqCreated"]]);
                        bookingReqCreatedList =
                            obj["forBookingReqCreated"].join(";");
                    }

                    if (
                        obj.hasOwnProperty("forBookingReqExpired") &&
                        obj["forBookingReqExpired"].length > 0
                    ) {
                        setChipsOrderExpired([...obj["forBookingReqExpired"]]);
                        bookingReqExpiredList =
                            obj["forBookingReqExpired"].join(";");
                    }
                }
                setHideRatingsFromClients(hideRatingsFromClients);
                if (ApptAcceptanceFlowConfig) {
                    const flowConfig = JSON.parse(ApptAcceptanceFlowConfig);
                    if (flowConfig.selectProvTimeout) {
                        setSelectProvTimeout(flowConfig.selectProvTimeout);
                    }
                    if (flowConfig.addProvTimeout) {
                        setAddProvTimeout(flowConfig.addProvTimeout);
                    }
                }
                setSubscriptionLevel(
                    subscriptionLevel ? subscriptionLevel : ""
                );
                setCancelPolicyMinsBefore(
                    cancelPolicyMinsBefore ? cancelPolicyMinsBefore : 0
                );
                setProviderCanCancelAppt(
                    providerCanCancelAppt === null
                        ? true
                        : providerCanCancelAppt
                );
                if (providerCanReschedule) {
                    setCanProviderReschedule(providerCanReschedule);
                } else {
                    setCanProviderReschedule(false);
                }

                if (clientCanReschedule) {
                    setCanClientReschedule(clientCanReschedule);
                } else {
                    setCanClientReschedule(false);
                }
                setNoProviderMsg(
                    noProviderMsg ? noProviderMsg : defaultNoProviderMsg
                );

                setProviderRatios(
                    displayProviderRatios === null
                        ? false
                        : displayProviderRatios
                );
                setSetupCompleted(setupCompleted ? setupCompleted : "");
                setBookingIncrement(bookingIncrement ? bookingIncrement : 15);
                let curData = {
                    name: name,
                    contactname: contactname,
                    emailaddress: emailaddress,
                    address: addressoneline,
                    currency: currency,
                    country: countrycode3166alpha2,
                    role: subscriptionLevel,
                    stripeKey: publishableStripeKey,
                    tipMsg: sendratingTipMsg,
                    textMsg: ratingTipTextMsg,
                    clientMsg: clientnotesheading,
                    ordList, //: convertArrayToString(BccLists, "forOrdSum"),
                    welcomeList, //: convertArrayToString(BccLists, "forWelcome"),
                    bookingReqCreatedList, //: convertArrayToString(BccLists,"bookingReqCreatedList"),
                    bookingReqExpiredList, //: convertArrayToString(BccLists,"bookingReqExpiredList"),
                    subdomain: subdomain,
                    tagline: tagline,
                    heading1Text: originalHeading1Text
                        ? originalHeading1Text
                        : values.heading1Text,
                    heading2Text: originalHeading2Text
                        ? originalHeading2Text
                        : values.heading2Text,
                    noProviderMsg: noProviderMsg,
                    currencyByLocation: currencyBasedOnLocation,
                    offersMobileServices: offersRemoteServices,
                    offersOnPremiseServices: offersOnPremiseServices,
                    displayOnlyLogo: displayOnlyLogo,
                    singleApptOnly: singleApptOnly,
                    offersPackagesOnly: offersPackagesOnly,
                    forceRepeatingPackages,
                    offersForeverAppt: offersForeverAppt,
                    multipleServices: multipleServices,
                    multipleQuantity: multipleQty,
                    hideProviderRatingsfromClients: hideRatingsFromClients,
                    useAnonymousPermalink: useAnonymousPermalink,
                    providerMustAcceptBooking: providerMustAcceptAppt,
                    providerCanCancelAppt: providerCanCancelAppt,
                    providerMayRescheduleBooking: providerCanReschedule,
                    clientMayRescheduleBooking: clientCanReschedule,
                    primaryColor: primaryColor,
                    serviceFeeAmount: serviceFeeAmount,
                    serviceFeeType: serviceFeeType,
                    addServiceFee: addServiceFee,
                    minTime: bookingIntervalMinutes,
                    travelTime: travelTime,
                    cancelPolicy: cancelPolicyMinsBefore,
                    providerRatios:
                        displayProviderRatios === null
                            ? false
                            : displayProviderRatios,
                    addProvTimeout: parseInt(
                        JSON.parse(ApptAcceptanceFlowConfig)?.addProvTimeout
                    ),
                    selectProvTimeout: parseInt(
                        JSON.parse(ApptAcceptanceFlowConfig)?.selectProvTimeout
                    )
                };
                setOldInfo([curData]);
            }

            //get secvalue
            const stripePubKey = await readStripeSecvalue(companyId);
            setIsLoading(false);
            return { ...result.data.getCompany, stripePubKey };
        } else {
            setCompanyId("");
            setisEdit(false);
        }
        setIsLoading(false);
        return;
    }

    async function readStripeSecvalue(companyId) {
        try {
            //get secvalue
            const secval = await getJsonApi("adminapi", `/secval`, {
                queryParams: {
                    companyId,
                    valueType: "SS"
                }
            });
            if (secval && secval.value) {
                setSecvalue(secval.value);
                return secval.value;
            }
        } catch (e) {
            console.log("error in api call");
        }
    }

    async function isSubdomainProvisioned(subdomain) {
        //get secvalue
        const yesno = await getJsonApi("adminapi", `/subdomain`, {
            queryParams: {
                subdomain
            }
        });
        return yesno && yesno.value;
    }

    async function computeCompanyHasPreviousOrders() {
        const loggedInUser = getUserFromCache();
        const result = await graphql(
            graphqlOperation(queries.bookingByCompany, {
                companyId: loggedInUser.companyId,
                filter: null,
                limit: 2,
                nextToken: null
            })
        );
        console.log("result booking = ", result);
    }

    async function uploadFile(file, fileType, companyIdValue) {
        // console.log("Uploading", file, fileType);
        try {
            let fileName = "";
            if (fileType === "logo") {
                const fileExtension = file.name.substring(
                    file.name.lastIndexOf(".") + 1
                );
                fileName = `${companyIdValue.toString()}-${Date.now()}-logo.${fileExtension}`;
            } else if (fileType === "tos") {
                fileName = companyIdValue.toString() + "-tos.pdf";
            } else if (fileType === "backgroundImage") {
                const fileExtension = file.name.substring(
                    file.name.lastIndexOf(".") + 1
                );
                fileName = `${companyIdValue.toString()}-${Date.now()}-backgroundImage.${fileExtension}`;
            } else throw new Error("Unsupported file type.");
            const response = await postApi("s3filemgmt", "/upload", {
                body: {
                    companyId,
                    fileName: fileName,
                    fileType: fileType
                }
            });
            var options = {
                headers: {
                    "Content-Type": file.type,
                    "x-amz-acl": "public-read"
                }
            };
            await axios.put(response.uploadURL, file, options);
            const url = response.uploadURL.split("?");
            if (fileType === "logo") {
                setLogoUrl(url[0]);
            } else if (fileType === "tos") {
                setTosUrl(url[0]);
            } else if (fileType === "backgroundImage") {
                setBackgroundImageUrl(url[0]);
            }
            // console.log(
            //     "File uploaded successfully",
            //     file,
            //     result,
            //     response,
            //     url
            // );
            return url[0];
        } catch (err) {
            console.log("File upload error", err);
            Sentry.captureException(err);
            setSnackMsg("Error uploading company logo.");
            setMsgOpen(true);
        }
    }

    // cancel and go back to PromotionForm
    function handleCancelCompanyForm() {
        if (userHasCompanyAdminRole()) actions.setPage("DashboardForm");
        else actions.setPage("CompanyForm");
    }

    function isValidColor(color) {
        const hexColorRegex = /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i;
        return hexColorRegex.test(color);
    }

    const handleInputKeyDown = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            console.log("Event on keyDown", event);
            if (event.target.id === "bcc-list-1") {
                addChip(orderSummaryBccList.trim(), "orderBcc");
            }

            if (event.target.id === "bcc-list-2") {
                addChip(welcomeBccList.trim(), "welcomeBcc");
            }

            if (event.target.id === "orderCreated-emails") {
                addChip(bookingReqCreatedList.trim(), "orderCreated");
            }

            if (event.target.id === "orderExpired-emails") {
                addChip(bookingReqExpiredList.trim(), "orderExpired");
            }
        }
    };

    const addChip = (chipValue, str) => {
        if (str === "orderBcc") {
            if (chipValue && !chipsOrder.includes(chipValue)) {
                setChipsOrder([...chipsOrder, chipValue]);
                setOrderSummaryBccList("");
            }
        }

        if (str === "welcomeBcc") {
            if (chipValue && !chipsWelcome.includes(chipValue)) {
                setChipsWelcome([...chipsWelcome, chipValue]);
                setWelcomeBccList("");
            }
        }

        if (str === "orderCreated") {
            if (chipValue && !chipsOrderCreated.includes(chipValue)) {
                setChipsOrderCreated([...chipsOrderCreated, chipValue]);
                setBookingReqCreatedList("");
            }
        }

        if (str === "orderExpired") {
            if (chipValue && !chipsOrderExpired.includes(chipValue)) {
                setChipsOrderExpired([...chipsOrderExpired, chipValue]);
                setBookingReqExpiredList("");
            }
        }
    };

    const removeChip = (chipValue, str) => {
        if (str === "orderBcc") {
            setChipsOrder(chipsOrder.filter((chip) => chip !== chipValue));
        }

        if (str === "welcomeBcc") {
            setChipsWelcome(chipsWelcome.filter((chip) => chip !== chipValue));
        }

        if (str === "orderCreated") {
            setChipsOrderCreated(
                chipsOrderCreated.filter((chip) => chip !== chipValue)
            );
        }

        if (str === "orderExpired") {
            setChipsOrderExpired(
                chipsOrderExpired.filter((chip) => chip !== chipValue)
            );
        }
    };

    async function addCompany() {
        // Steps to add a company:
        // 1. Create the company with all company data that does not require the company id
        // 2. Take the company id, and use that to upload the data requiring a company id
        // 3. Finally, update the company with the urls that require the company id

        // If we are creating a company, we need to first get the company Id in order to be able to upload
        // the TOS, Logo, or background image.
        // If we are editing a company, we first need to upload the file, and then save the link to the company
        // with the link.
        try {
            let input = {
                name: name,
                contactname: contactname,
                emailaddress: emailaddress,
                replyemailaddress: replyemailaddress,
                currency: currency,
                addressoneline: addressOneLine,
                street: addressStreet,
                city: addressCity,
                state: addressState,
                country: country,
                postalcode: postalCode,
                longitude: longitude,
                latitude: latitude,
                active: active,
                offersOnPremiseServices: values.premise,
                offersRemoteServices: values.remote,
                currencyBasedOnLocation: currencyBasedOnLocation,
                collectpayment: collectPayments,
                subdomain: desiredSubdomain ? desiredSubdomain : `${name.toLowerCase()
                .trim()
                .replace(SUBDOMAIN_REGEX_SPECIAL_CHARS, '')
                .replace(SUBDOMAIN_REGEX_MULTIPLE_HYPHENS, '-')}.gomarketbox.com`,
                maskeddomain: maskedDomain,
                logoUrl: brandingStates.logoURL,
                iframeURL: iframeUrl ? iframeUrl : "",
                tagline: domainStates.tagline.trim() ? domainStates.tagline.trim() : name,
                primaryColor: primaryColor ? primaryColor : "",
                publishableStripeKey: stripeKey,
                serviceFeeAmount: parseFloat(serviceFeeAmount),
                serviceFeeType: serviceFeeType,
                addServiceFee: addServiceFee,
                bookingIntervalMinutes: bookingIntervalMinutes,
                countrycode3166alpha2: countrycode3166alpha2,
                displayOnlyLogo: displayOnlyLogo,
                providerMustAcceptAppt: providerMustAcceptAppt,
                ratingTipTextMsg: ratingTipTextMsg,
                sendratingTipMsg: sendratingTipMsg,
                offersPackagesOnly: offersPackagesOnly,
                forceRepeatingPackages,
                offersForeverAppt: offersForeverAppt,
                ApptAcceptanceFlowConfig: JSON.stringify({
                    selectProvTimeout: selectProvTimeout,
                    addProvTimeout: addProvTimeout
                }),
                travelTime,
                homepage: domainStates.homepageURL ? `https://${domainStates.homepageURL.trim()}` : "",
                multipleServices: multipleServices,
                multipleQty: multipleQty,
                useAnonymousPermalink: useAnonymousPermalink,
                clientnotesheading: clientNotesHeading,
                //BccLists is used for all the emaill address lists and not just for Bcc addresses
                BccLists: JSON.stringify({
                    forOrdSum: chipsOrder, //convertStringToArray(orderSummaryBccList),
                    forWelcome: chipsWelcome, //convertStringToArray(welcomeBccList),
                    forBookingReqCreated: chipsOrderCreated, //convertStringToArray(bookingReqCreatedList),
                    forBookingReqExpired: chipsOrderExpired //convertStringToArray(bookingReqExpiredList)
                }),
                hideRatingsFromClients,
                subscriptionLevel: subscriptionLevel ? subscriptionLevel : "",
                cancelPolicyMinsBefore: cancelPolicyMinsBefore
                    ? cancelPolicyMinsBefore
                    : 0,
                providerCanCancelAppt: providerCanCancelAppt,
                noProviderMsg: bookingStates.noProviderMessage,
                displayProviderRatios: bookingStates.showProviderAcceptance,
                bookingIncrement: bookingStates.bookingIncrements,
                singleApptOnly: singleApptOnly,
                providerCanReschedule: canProviderReschedule,
                clientCanReschedule: canClientReschedule,
                ProvAgnoFlowConfig: JSON.stringify({
                    isEnabled: providerAgnosticFlow
                }),
                SuggestionConfig: JSON.stringify({
                    isEnabled: timeSuggestionsEnabled ? true : false,
                    timeOfFirstBookingOfTheDay:
                        timeSuggestionsFirstBookingOfTheDay,
                    forceClientAccept: timeSuggestionsForceClientAccept
                        ? true
                        : false,
                    maxTravelTimeMinutes:
                        timeSuggestionsMaxTravelTimeMinutesBetweenBookings
                })
                //setupCompleted: true
            };
            let response;

            let publicInput = {
                heading1Text: brandingStates.companyTagline,
                heading2Text: brandingStates.companyDescription,
                buttonColor: brandingStates.buttonColor,
                textColor: brandingStates.headerFontColor,
                companyId: props.compId,
                backgroundImageUrl: brandingStates.backgroundURL
            };

            let customValues = {
                bookingFlowCustomizations: {
                    mainHeading: brandingStates.companyName,
                    backgroundColor: brandingStates.backgroundColor,
                    opacity: brandingStates.headerTransparency,
                    fontTypeForAllHeading: brandingStates.headerFontType,
                    fontSizeHeader1: brandingStates.companyNameFontSize,
                    fontSizeHeader2: brandingStates.companyTaglineFontSize,
                    fontSizeHeader3: brandingStates.companyDescriptionFontSize,
                    showHeadings: brandingStates.showHeaderText,
                    showLogo: brandingStates.showLogo,
                    showBackgroundImage: brandingStates.showBackgroundImage,
                    circularLogo: brandingStates.logoAsCircle,
                    initialSetup: true,
                    showHeader: brandingStates.showHeader,
                    categoryDisplay: bookingStates.categoryDisplay,
                    servicesDisplay: bookingStates.servicesDisplay,
                    checkoutOption: bookingStates.checkoutOption,
                    showHeadingOne: brandingStates.showHeadingOne,
                    showHeadingTwo: brandingStates.showHeadingTwo,
                    showHeadingThree: brandingStates.showHeadingThree,
                    displayServicePrices: bookingStates.displayServicePrices,
                    buttonTextColor: brandingStates.buttonTextColor,
                    mandatoryNotes: mandatoryNotes,
                    tipPageCustomizations: {
                        showTipOption: showTipOption
                    }
                }
            };

            customValues.tipPageCustomizations = {
                showTipOption: showTipOption
            };

            publicInput.StyleCustomization = JSON.stringify(customValues);

            let publicResponse;

            // Perform final save.
            if (isEdit) {
                let companyId = props.companyId ? props.companyId : state.id;
                input.id = companyId;
                response = await graphql(
                    graphqlOperation(mutations.updateCompany, { input })
                );
                await updateCompanyObjectInCachedUser(
                    response?.data?.updateCompany
                );

                newInfo = Object.fromEntries(
                    Object.entries(newInfo[0]).filter(
                        ([_, v]) => v !== "No Change"
                    )
                );

                console.log("values: ", values);

                if (Object.keys(newInfo).length !== 0) {
                    await auditCompanyUpdate(
                        loggedInUser,
                        response.data.updateCompany,
                        [newInfo],
                        tabValue
                    );
                }

                publicInput.companyId = companyId;

                console.log("public input value", publicInput);
                console.log("Values to check ID", values);
                if (values.id) {
                    console.log("inside first");
                    publicInput.id = values.id;
                    console.log("Final Public Input value", publicInput);
                    publicResponse = await graphql(
                        graphqlOperation(mutations.updateCompanyPublicSite, {
                            input: publicInput
                        })
                    );
                }
                console.log("public response", publicResponse);
            } else {
                response = await graphql(
                    graphqlOperation(mutations.createCompany, {
                        input
                    })
                );

                console.log("just a test", response.data.createCompany);

                await auditCompanyCreate(
                    loggedInUser,
                    response.data.createCompany
                );

                const createdCompanyId = response.data.createCompany.id;

                publicInput.companyId = createdCompanyId;
                publicInput.companyPublicSiteCompanyId = createdCompanyId;

                if (bookingFlowSettingsFiles.logoFile) {
                    let uploadLogoURL = await uploadFile(bookingFlowSettingsFiles.logoFile, "logo", createdCompanyId);
                    if (uploadLogoURL) {
                        input.logoUrl = uploadLogoURL;
                        input.id = createdCompanyId;

                        setBrandingStates ((prevState) => {
                            return { ...prevState, logoURL: uploadLogoURL}
                        })

                        const updatedResponse = await graphql(
                            graphqlOperation(mutations.updateCompany, { input })
                        );

                        await updateCompanyObjectInCachedUser(
                            updatedResponse?.data?.updateCompany
                        );
                    }
                }

                if (bookingFlowSettingsFiles.backgroundFile) {
                    let uploadBackgroundURL = await uploadFile(bookingFlowSettingsFiles.backgroundFile, "logo", createdCompanyId);
                    if (uploadBackgroundURL) {
                        publicInput.backgroundImageUrl = uploadBackgroundURL;

                        setBrandingStates ((prevState) => {
                            return { ...prevState, logoURL: uploadBackgroundURL}
                        })
                    }
                }

                if (bookingFlowSettingsFiles.tosFile) {
                    const newTOSURL = await uploadFile(bookingFlowSettingsFiles.tosFile, "tos", createdCompanyId);

                    if (newTOSURL) {
                        setBookingStates ((prevState) => {
                            return { ...prevState, tosURL: newTOSURL}
                        })
                    }
                }

                publicResponse = await graphql(
                    graphqlOperation(mutations.createCompanyPublicSite, {
                        input: publicInput
                    })
                );
            }

            try {
                await reSaveCompanyInfoForLandingPage({
                    ...input,
                    stripeAccount: stripeConnectedAccount,
                    stripeConnectEnabled
                });
            } catch (err) {
                console.log("saving company info to refdata failed", err);
                Sentry.captureException(err);
            }

            try {
                await reSaveCompanyPublicSiteInfoForLandingPage(publicInput);
            } catch (err) {
                console.log(
                    "saving public company info to public refdata failed",
                    err
                );
                Sentry.captureException(err);
            }
            if (
                response && response.data && isEdit
                    ? response.data.updateCompany
                    : response.data.createCompany
            ) {
                let companyId;
                if (isEdit) {
                    companyId = response.data.updateCompany.id;
                    await createScheduleIndex(companyId);
                } else {
                    companyId = response.data.createCompany.id;
                    await createScheduleIndex(companyId);
                }
                return true;
            } else {
                throwEditSaveError(
                    "Unable to save the company information.",
                    1
                );
                return false;
            }
        } catch (err) {
            console.error("Add Company error => ", err);
            Sentry.captureException(err);
            return false;
        }
    }

    // function updateCompanyObjectInCachedUser(updatedCompanyObj) {
    //     let cachedUser = getUserFromCache();
    //     if (cachedUser.company.id === updatedCompanyObj.id)
    //         cachedUser.company = updatedCompanyObj;
    // }
    async function reSaveCompanyInfoForLandingPage(company) {
        try {
            let companyInfo = {};

            for (let prop in company) {
                if (
                    "DashboardInfo" !== prop &&
                    "createdAt" !== prop &&
                    "updatedAt" !== prop
                )
                    companyInfo[prop] = company[prop];
            }
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `domain|${company.subdomain}`,
                    refName: "data-company"
                })
            );
            // console.log("currency", result);
            let refVal = exists.data.getRefData
                ? exists.data.getRefData.refValue
                : null;
            if (refVal)
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
            else
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
        } catch (e) {
            console.log("saving company info to refdata failed");
        }
    }
    async function reSaveCompanyPublicSiteInfoForLandingPage(publicSite) {
        try {
            const result = await graphql(
                graphqlOperation(queries.getCompany, {
                    id: publicSite.companyId
                })
            );

            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `domain|${result.data.getCompany.subdomain}`,
                    refName: "data-publicSite"
                })
            );
            // console.log("currency", result);
            let refVal = exists.data.getRefData
                ? exists.data.getRefData.refValue
                : null;
            let overrideValue = {
                backgroundImageUrl: publicSite.backgroundImageUrl,
                heading1Text: publicSite.heading1Text,
                heading2Text: publicSite.heading2Text,
                textColor: publicSite.textColor,
                buttonColor: publicSite.buttonColor
            };
            if (
                publicSite.StyleCustomization &&
                publicSite.StyleCustomization !== null
            )
                overrideValue.StyleCustomization =
                    publicSite.StyleCustomization;
            if (refVal) {
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `domain|${result.data.getCompany.subdomain}`,
                            refName: "data-publicSite",
                            refValue: "publicSite",
                            overrideValue: JSON.stringify(overrideValue)
                        }
                    })
                );
            } else {
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `domain|${result.data.getCompany.subdomain}`,
                            refName: "data-publicSite",
                            refValue: "publicSite",
                            overrideValue: JSON.stringify(overrideValue)
                        }
                    })
                );
            }
        } catch (e) {
            console.log("saving company public site info to refdata failed", e);
        }
    }

    async function createScheduleIndex(companyId) {
        const result = await getJsonApi("searchapi", `/index/${companyId}`);
        if (result.success && !result.exists) {
            const indexresult = await postApi("searchapi", "/index", {
                body: {
                    companyId
                }
            });
            if (!indexresult.success) {
                setSnackMsg(
                    "Company is saved but there was an error while create the index for the company."
                );
                setMsgOpen(true);
            }
        } else if (result.success && result.exists) {
            // setSnackMsg("Company index already exists.");
            // setMsgOpen(true);
        } else {
            setSnackMsg(
                "Company is saved but there was an error while create the index for the company."
            );
            setMsgOpen(true);
        }
    }

    function endsWithHLD() {
        if (subdomain && subdomain.endsWith(".gomarketbox.com")) {
            return true;
        }
        if (subdomain && subdomain.endsWith("register.aquamobileswim.com")) {
            return true;
        }

        if (validateCompany(loggedInUser.company.id).checked) {
            return true;
        }
        return false;
    }

    function isValidHostname() {
        let value = domainStates.subdomain;
        if (typeof value !== "string") return false;

        const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
        if (!validHostnameChars.test(value)) {
            return false;
        }

        if (value.endsWith(".")) {
            value = value.slice(0, value.length - 1);
        }

        if (value.length > 253) {
            return false;
        }

        const labels = value.split(".");

        const isValid = labels.every(function (label) {
            const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

            const validLabel =
                validLabelChars.test(label) &&
                label.length < 64 &&
                !label.startsWith("-") &&
                !label.endsWith("-");

            return validLabel;
        });

        return isValid;
    }
    let newInfo;
    async function handleSaveCompany() {
        setShowLoading(true);
        if (tabValue === 0) {
            newInfo = [
                {
                    name: "No Change",
                    contactname: "No Change",
                    emailaddress: "No Change",
                    address: "No Change",
                    currency: "No Change",
                    country: "No Change",
                    role: "No Change",
                    stripeKey: "No Change"
                }
            ];
        } else if (tabValue === 2) {
            newInfo = [
                {
                    tipMsg: "No Change",
                    textMsg: "No Change",
                    clientMsg: "No Change",
                    ordSum: "No Change",
                    WelcomeList: "No Change"
                }
            ];
        } else if (tabValue === 3) {
            newInfo = [
                {
                    subdomain: "No Change",
                    tagline: "No Change",
                    heading1Text: "No Change",
                    heading2Text: "No Change",
                    noProviderMsg: "No Change",
                    providerRatios: "No Change"
                }
            ];
        } else {
            newInfo = [
                {
                    offersMobileServices: "No Change",
                    offersOnPremiseServices: "No Change",
                    currencyByLocation: "No Change",
                    displayOnlyLogo: "No Change",
                    singleApptOnly: "No Change",
                    offersPackagesOnly: "No Change",
                    forceRepeatingPackages: "No Change",
                    offersForeverAppt: "No Change",
                    multipleServices: "No Change",
                    multipleQuantity: "No Change",
                    primaryColor: "No Change",
                    serviceFeeAmount: "No Change",
                    serviceFeeType: "No Change",
                    addServiceFee: "No Change",
                    hideProviderRatingsfromClients: "No Change",
                    useAnonymousPermalink: "No Change",
                    providerMustAcceptBooking: "No Change",
                    providerCanCancelAppt: "No Change",
                    providerMayRescheduleBooking: "No Change",
                    clientMayRescheduleBooking: "No Change",
                    minTime: "No Change",
                    travelTime: "No Change",
                    cancelPolicy: "No Change",
                    addProvTimeout: "No Change",
                    selectProvTimeout: "No Change"
                }
            ];
        }
        // validate
        if (name === "") {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for name.", 1);
            return;
        }
        if (contactname === "") {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for contact name.", 1);
            return;
        }
        if (emailaddress === "") {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for email address.", 1);
            return;
        }

        if (!validator.isEmail(emailaddress)) {
            setShowLoading(false);
            throwEditSaveError("Please specifiy a valid email address.", 1);
            return;
        }
        if (currency === "") {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for currency.", 1);
            return;
        }

        if (domainStates.subdomain.trim().length <= 0) {
            if (state.mode !== "Add") {
                setShowLoading(false);
                throwEditSaveError("Personalized Booking URL cannot be empty", 4);
                setDomainStates((prevState) => {
                    return {
                        ...prevState,
                        subdomainError: true,
                        subdomainErrorMessage:
                            "Personalized Booking URL cannot be empty"
                    };
                });
    
                return;
            }
        }

        if (domainStates.tagline.trim().length <= 0) {
            if (state.mode !== "Add") {
                setShowLoading(false);
                throwEditSaveError("Title Tag cannot be empty", 4);
                setDomainStates((prevState) => {
                    return {
                        ...prevState,
                        taglineError: true,
                        taglineErrorMessage: "Title Tag cannot be empty"
                    };
                });
                return;
            }
        }

        if (domainStates.subdomainError) {
            setShowLoading(false);
            throwEditSaveError(
                domainStates.subdomainErrorMessage,
                4
            );
            return;
        }

        if (state.mode !== "Add") {
            if (domainStates.taglineError) {
                setShowLoading(false);
                throwEditSaveError(domainStates.taglineErrorMessage, 4);
                return;
            }
        }
        
        if (!serviceFeeType) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for service fee type.", 2);
            return;
        }
        if (addServiceFee && !serviceFeeAmount) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a value for service fee amount.",
                2
            );
            return;
        }
        if (!stripeKey && collectPayments && !stripeConnectEnabled) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for the stripe key.", 1);
            return;
        }
        if (!brandingStates.logoURL && !bookingFlowSettingsFiles.logoFile) {
            setShowLoading(false);
            throwEditSaveError("Please upload a company logo.", 4);
            return;
        }
        if (!primaryColor) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for primary color.", 2);
            return;
        }
        if (primaryColor && !isValidColor(primaryColor)) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a valid hex color code for primary color (e.g. #84c9de).",
                2
            );
            return;
        }
        if (!selectProvTimeout || selectProvTimeout < 5) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a value for selected provider timeout than is greater than or equal to 5 minutes.",
                2
            );
            return;
        }
        if (!addProvTimeout || addProvTimeout < 5) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a value for additional provider timeout than is greater than or equal to 5 minutes.",
                2
            );
            return;
        }

        if (offersForeverAppt && offersPackagesOnly) {
            setShowLoading(false);
            throwEditSaveError(
                `Invalid Combination: Both "Packages Only" and "Ongoing Appointments Only" are switched on.`,
                2
            );
            return;
        }
        if (!subscriptionLevel) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a value for subscription level.",
                1
            );
            return;
        }
        if ((!cancelHours && cancelHours !== 0) || cancelHours < 0) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a number greater or equal to 0 for Cancel Time (hrs).",
                2
            );
            return;
        }

        if (
            (!cancelPolicyMinsBefore && cancelPolicyMinsBefore !== 0) ||
            cancelPolicyMinsBefore < 0
        ) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a number greater or equal to 0 for Cancel Time (hours).",
                2
            );
            return;
        }
        // limit to 10080 minutes (one week)
        if (
            (!bookingIntervalMinutes && bookingIntervalMinutes !== 0) ||
            bookingIntervalMinutes < 0 ||
            bookingIntervalMinutes > 10080
        ) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a number in the range of 0 - 168 for Minimum Booking Notice (hours).",
                2
            );
            return;
        }

        if (providerCanCancelAppt === null) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a value for Provider May Cancel Booking.",
                2
            );
            return;
        }
        // Public Site Settings
        if (!brandingStates.companyTagline) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for heading 1 text.", 4);
            return;
        }
        if (!brandingStates.companyDescription) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for heading 2 text.", 4);
            return;
        }
        if (!brandingStates.backgroundURL && !bookingFlowSettingsFiles.backgroundFile) {
            setShowLoading(false);
            throwEditSaveError("Please upload a background image.", 4);
            return;
        }
        if (!brandingStates.headerFontColor) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for text color.", 4);
            return;
        }
        if (brandingStates.headerFontColor && !isValidColor(brandingStates.headerFontColor)) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a valid hex color code for text color (e.g. #ffffff).",
                4
            );
            return;
        }
        if (!brandingStates.buttonColor) {
            setShowLoading(false);
            throwEditSaveError("Please enter a value for button color.", 4);
            return;
        }
        if (brandingStates.buttonColor && !isValidColor(brandingStates.buttonColor)) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a valid hex color code for button color (e.g. #84c9de).",
                4
            );
            return;
        }
        if (!bookingStates.noProviderMessage) {
            setShowLoading(false);
            throwEditSaveError(
                "Please enter a value for No Provider Available Message.",
                4
            );
            return;
        } else {
            console.log("value for no provider message is: " + bookingStates.noProviderMessage);
        }
        const bookingReqCreatedArr = [...chipsOrderCreated]; //convertStringToArray(bookingReqCreatedList);
        const bookingReqExpiredArr = [...chipsOrderExpired]; //convertStringToArray(bookingReqExpiredList);
        if (providerMustAcceptAppt) {
            for (let emailIndex in bookingReqCreatedArr) {
                if (bookingReqCreatedArr[emailIndex].length === 0) {
                    setShowLoading(false);
                    throwEditSaveError(
                        "Please do not end the Email address list for Order Created notifications  with an extra semicolon.",
                        3
                    );
                    return;
                }
            }
            for (let emailIndex in bookingReqExpiredArr) {
                if (bookingReqExpiredArr[emailIndex].length === 0) {
                    setShowLoading(false);
                    throwEditSaveError(
                        "Please do not end the Email address list for Order expired/declined notifications with an extra semicolon.",
                        3
                    );
                    return;
                }
            }
            // bookingReqCreatedList && bookingReqCreatedList.length
            if (chipsOrderCreated.length > 0) {
                let emailArr = [...chipsOrderCreated]; //bookingReqCreatedList.split(";");
                let invalidEmail;

                for (let em of emailArr) {
                    if (!validator.isEmail(em)) {
                        invalidEmail = em;
                        break;
                    }
                }

                if (invalidEmail) {
                    setShowLoading(false);
                    setSnackMsg(
                        `Invalid email address in Email address list for Order Created notifications: ${invalidEmail}`
                    );
                    setMsgOpen(true);
                    throwEditSaveError(
                        `Invalid email address in Email address list for Order Created notifications: ${invalidEmail}`,
                        3
                    );
                    return;
                }
                //bookingReqCreatedList != oldInfo[0].ordList
                if (chipsOrderCreated.join(";") != oldInfo[0].ordList) {
                    newInfo.map((item) => {
                        item.bookingReqCreatedList = emailArr;
                    });
                }
            }
            //bookingReqExpiredList && bookingReqExpiredList.length
            if (chipsOrderExpired.length > 0) {
                let emailArr = [...chipsOrderExpired]; //bookingReqExpiredList.split(";");
                let invalidEmail;

                for (let em of emailArr) {
                    if (!validator.isEmail(em)) {
                        invalidEmail = em;
                        break;
                    }
                }

                if (invalidEmail) {
                    setShowLoading(false);
                    setSnackMsg(
                        `Invalid email address in Email addres list for Order expired/declined notifications: ${invalidEmail}`
                    );
                    setMsgOpen(true);
                    throwEditSaveError(
                        `Invalid email address in Email addres list for Order  expired/declined notifications: ${invalidEmail}`,
                        3
                    );
                    return;
                }
                //bookingReqExpiredList != oldInfo[0].ordList
                if (chipsOrderExpired.join(";") != oldInfo[0].ordList) {
                    newInfo.map((item) => {
                        item.bookingReqExpiredList = emailArr;
                    });
                }
            }
        }

        const ordSumArr = [...chipsOrder]; //convertStringToArray(orderSummaryBccList);
        const welcomeArr = [...chipsWelcome]; //convertStringToArray(welcomeBccList);
        for (let emailIndex in ordSumArr) {
            if (ordSumArr[emailIndex].length === 0) {
                setShowLoading(false);
                throwEditSaveError(
                    "Please do not end the Welcome Email BCC field with an extra semicolon.",
                    3
                );
                return;
            }
        }
        for (let emailIndex in welcomeArr) {
            if (welcomeArr[emailIndex].length === 0) {
                setShowLoading(false);
                throwEditSaveError(
                    "Please do not end the Welcome Email BCC field with an extra semicolon.",
                    3
                );
                return;
            }
        }
        //orderSummaryBccList && orderSummaryBccList.length
        if (chipsOrder.length > 0) {
            let emailArr = [...chipsOrder]; //orderSummaryBccList.split(";");
            let invalidEmail;

            for (let em of emailArr) {
                if (!validator.isEmail(em)) {
                    invalidEmail = em;
                    break;
                }
            }

            if (invalidEmail) {
                setShowLoading(false);
                console.log("asdf invalid email");
                setSnackMsg(
                    `Invalid email address in Order Summary Email Bcc email list: ${invalidEmail}`
                );
                setMsgOpen(true);
                throwEditSaveError(
                    `Invalid email address in Order Summary Email Bcc email list: ${invalidEmail}`,
                    3
                );
                return;
            }
            //orderSummaryBccList != oldInfo[0].ordList
            if (chipsOrder.join(";") != oldInfo[0].ordList) {
                newInfo.map((item) => {
                    item.ordSum = emailArr;
                });
            }
        }

        //welcomeBccList && welcomeBccList.length
        if (chipsWelcome.length > 0) {
            let emailArr = [...chipsWelcome]; //welcomeBccList.split(";");
            let invalidEmail;

            for (let em of emailArr) {
                if (!validator.isEmail(em)) {
                    invalidEmail = em;
                    break;
                }
            }

            if (invalidEmail) {
                setShowLoading(false);
                console.log("asdf invalid email");
                setSnackMsg(
                    `Invalid email address in Welcome Email Bcc email list: ${invalidEmail}`
                );
                setMsgOpen(true);
                throwEditSaveError(
                    `Invalid email address in Welcome Email Bcc email list: ${invalidEmail}`,
                    3
                );
                return;
            }
            //welcomeBccList != oldInfo[0].welcomeList
            if (chipsWelcome.join(";") != oldInfo[0].welcomeList) {
                newInfo.map((item) => {
                    item.WelcomeList = emailArr;
                });
            }
        }

        //check to see if the company name is already in use, only if were adding a new company or company name is being changed
        if (!isEdit || name !== oldInfo[0]?.name) {
            const isUniqueCompanyName = await validateUniqueCompanyName(name);
            if (!isUniqueCompanyName) {
                setShowLoading(false);
                throwEditSaveError(
                    "A company with this name already exists in our system",
                    1
                );
                return;
            }
        }

        if (isEdit) {
            // General settings changed for audit logging
            if (name != oldInfo[0].name) {
                newInfo.map((item) => {
                    item.name = name;
                });
            }
            if (contactname != oldInfo[0].contactname) {
                newInfo.map((item) => {
                    item.contactname = contactname;
                });
            }
            if (emailaddress != oldInfo[0].emailaddress) {
                newInfo.map((item) => {
                    item.emailaddress = emailaddress;
                });
            }
            if (addressOneLine != oldInfo[0].address) {
                newInfo.map((item) => {
                    item.address = addressOneLine;
                });
            }
            if (countrycode3166alpha2 != oldInfo[0].country) {
                newInfo.map((item) => {
                    item.country = countrycode3166alpha2;
                });
            }
            if (currency != oldInfo[0].currency) {
                newInfo.map((item) => {
                    item.currency = currency;
                });
            }
            if (subscriptionLevel != oldInfo[0].role) {
                newInfo.map((item) => {
                    item.role = subscriptionLevel;
                });
            }
            if (stripeKey != oldInfo[0].stripeKey) {
                newInfo.map((item) => {
                    item.stripeKey = stripeKey;
                });
            }
            if (sendratingTipMsg != oldInfo[0].tipMsg) {
                newInfo.map((item) => {
                    item.tipMsg = sendratingTipMsg;
                });
            }
            if (ratingTipTextMsg != oldInfo[0].textMsg) {
                newInfo.map((item) => {
                    item.textMsg = ratingTipTextMsg;
                });
            }
            if (clientNotesHeading != oldInfo[0].clientMsg) {
                newInfo.map((item) => {
                    item.clientMsg = clientNotesHeading;
                });
            }

            let checkingForWL = validateCompany(companyId);
            if (!checkingForWL?.checked) {
                if (`${domainStates.subdomain}.gomarketbox.com` != oldInfo[0].subdomain) {
                    console.log("state subdomain: ", `${domainStates.subdomain}.gomarketbox.com`, ", is not equal to old info subdomain: " + oldInfo[0].subdomain);
                    newInfo.map((item) => {
                        item.subdomain = domainStates.subdomain;
                    });
                } else {
                    console.log(`${domainStates.subdomain}.gomarketbox.com`, " | ", oldInfo[0].subdomain);
                }
            }
            
            if (domainStates.tagline != oldInfo[0].tagline) {
                newInfo.map((item) => {
                    item.tagline = domainStates.tagline;
                });
            }
            if (brandingStates.companyTagline != oldInfo[0].heading1Text) {
                newInfo.map((item) => {
                    item.heading1Text = brandingStates.companyTagline;
                });
            }
            if (brandingStates.companyDescription != oldInfo[0].heading2Text) {
                newInfo.map((item) => {
                    item.heading2Text = brandingStates.companyDescription;
                });
            }
            if (bookingStates.noProviderMessage != oldInfo[0].noProviderMsg) {
                newInfo.map((item) => {
                    item.noProviderMsg = bookingStates.noProviderMessage;
                });
            }
            if (bookingStates.showProviderAcceptance != oldInfo[0].providerRatios) {
                newInfo.map((item) => {
                    item.providerRatios = bookingStates.showProviderAcceptance;
                });
            }

            if (primaryColor != oldInfo[0].primaryColor) {
                newInfo.map((item) => {
                    item.primaryColor = primaryColor;
                });
            }

            if (values.remote != oldInfo[0].offersMobileServices) {
                newInfo.map((item) => {
                    item.offersMobileServices = values.remote;
                });
            }

            if (values.premise != oldInfo[0].offersOnPremiseServices) {
                newInfo.map((item) => {
                    item.offersOnPremiseServices = values.premise;
                });
            }

            if (currencyBasedOnLocation != oldInfo[0].currencyByLocation) {
                newInfo.map((item) => {
                    item.currencyByLocation = currencyBasedOnLocation;
                });
            }

            if (displayOnlyLogo != oldInfo[0].displayOnlyLogo) {
                newInfo.map((item) => {
                    item.displayOnlyLogo = displayOnlyLogo;
                });
            }

            if (singleApptOnly != oldInfo[0].singleApptOnly) {
                newInfo.map((item) => {
                    item.singleApptOnly = singleApptOnly;
                });
            }

            if (offersPackagesOnly != oldInfo[0].offersPackagesOnly) {
                newInfo.map((item) => {
                    item.offersPackagesOnly = offersPackagesOnly;
                });
            }
            if (forceRepeatingPackages != oldInfo[0].forceRepeatingPackages) {
                newInfo.map((item) => {
                    item.forceRepeatingPackages = forceRepeatingPackages;
                });
            }
            if (offersForeverAppt != oldInfo[0].offersForeverAppt) {
                newInfo.map((item) => {
                    item.offersForeverAppt = offersForeverAppt;
                });
            }

            if (multipleServices != oldInfo[0].multipleServices) {
                newInfo.map((item) => {
                    item.multipleServices = multipleServices;
                });
            }

            if (multipleQty != oldInfo[0].multipleQuantity) {
                newInfo.map((item) => {
                    item.multipleQuantity = multipleQty;
                });
            }

            if (addServiceFee != oldInfo[0].addServiceFee) {
                newInfo.map((item) => {
                    item.addServiceFee = addServiceFee;
                });
            }

            if (serviceFeeType != oldInfo[0].serviceFeeType) {
                newInfo.map((item) => {
                    item.serviceFeeType = serviceFeeType;
                });
            }

            if (serviceFeeAmount != oldInfo[0].serviceFeeAmount) {
                newInfo.map((item) => {
                    item.serviceFeeAmount = serviceFeeAmount;
                });
            }

            if (
                hideRatingsFromClients !=
                oldInfo[0].hideProviderRatingsfromClients
            ) {
                newInfo.map((item) => {
                    item.hideProviderRatingsfromClients =
                        hideRatingsFromClients;
                });
            }

            if (useAnonymousPermalink != oldInfo[0].useAnonymousPermalink) {
                newInfo.map((item) => {
                    item.useAnonymousPermalink = useAnonymousPermalink;
                });
            }

            if (
                providerMustAcceptAppt != oldInfo[0].providerMustAcceptBooking
            ) {
                newInfo.map((item) => {
                    item.providerMustAcceptBooking = providerMustAcceptAppt;
                });
            }

            if (selectProvTimeout != oldInfo[0].selectProvTimeout) {
                newInfo.map((item) => {
                    item.selectProvTimeout = selectProvTimeout;
                });
            }

            if (addProvTimeout != oldInfo[0].addProvTimeout) {
                newInfo.map((item) => {
                    item.addProvTimeout = addProvTimeout;
                });
            }

            if (providerCanCancelAppt != oldInfo[0].providerCanCancelAppt) {
                newInfo.map((item) => {
                    item.providerCanCancelAppt = providerCanCancelAppt;
                });
            }

            if (
                canProviderReschedule != oldInfo[0].providerMayRescheduleBooking
            ) {
                newInfo.map((item) => {
                    item.providerMayRescheduleBooking = canProviderReschedule;
                });
            }

            if (canClientReschedule != oldInfo[0].clientMayRescheduleBooking) {
                newInfo.map((item) => {
                    item.clientMayRescheduleBooking = canClientReschedule;
                });
            }

            if (bookingIntervalMinutes != oldInfo[0].minTime) {
                newInfo.map((item) => {
                    item.minTime = bookingIntervalMinutes;
                });
            }
            if (travelTime != oldInfo[0].travelTime) {
                newInfo.map((item) => {
                    item.travelTime = travelTime;
                });
            }
            if (cancelPolicyMinsBefore != oldInfo[0].cancelPolicy) {
                newInfo.map((item) => {
                    item.cancelPolicy = cancelPolicyMinsBefore;
                });
            }
        }
        
        let subdomainUpdatesData = {
            registerSubdomain: false,
            newSubdomain: null,
            oldSubdomain: null,
            subdomainChanged: false
        };
        try {
            let inputtedSubdomainLC = desiredSubdomain?.toLowerCase();

            if (!inputtedSubdomainLC) {
                // if subdomain field is empty when creating new company, convert company name to url as default subdomain
                let defaultSubdomain = name.toLowerCase()
                .trim()
                .replace(SUBDOMAIN_REGEX_SPECIAL_CHARS, '')
                .replace(SUBDOMAIN_REGEX_MULTIPLE_HYPHENS, '-');

                setDomainStates((prevState) => {
                    return {
                        ...prevState,
                        subdomain: defaultSubdomain
                    }
                })

                inputtedSubdomainLC = `${defaultSubdomain}.gomarketbox.com`
            }

            let existingSubdomainLC = oldInfo[0].subdomain
                ? oldInfo[0].subdomain.toLowerCase()
                : "";

            //Check if subdomain is provisioned
            let isProvisioned = await isSubdomainProvisioned(
                getSubdomainPrefix(inputtedSubdomainLC)
            );

            if (!isProvisioned) {
                //Check if any other company uses the same domain using the index
                //If not used by any company, set local variable to registerSubdomain = true so that
                //after the company is saved, we can add subdomain to the associated domain in amplify
                const r = await graphql(
                    graphqlOperation(queries.companyBySubdomain, {
                        subdomain: inputtedSubdomainLC
                    })
                );

                if (r.data.companyBySubdomain.items.length === 1) {
                    let company = r.data.companyBySubdomain.items[0];
                    if (companyId !== company.id) {
                        setShowLoading(false);
                        throwEditSaveError("Subdomain is already in use.", 4);
                        return;
                    } else {
                        //companyId is the same but it is not provisioned so, now provision it
                        subdomainUpdatesData.registerSubdomain = true;
                        subdomainUpdatesData.newSubdomain =
                            getSubdomainPrefix(inputtedSubdomainLC);
                        subdomainUpdatesData.subdomainChanged = false;
                    }
                }
                if (r.data.companyBySubdomain.items.length === 0) {
                    subdomainUpdatesData.registerSubdomain = true;
                    subdomainUpdatesData.newSubdomain =
                        getSubdomainPrefix(inputtedSubdomainLC);
                    if (existingSubdomainLC) {
                        subdomainUpdatesData.subdomainChanged = true;
                        subdomainUpdatesData.oldSubdomain =
                            getSubdomainPrefix(existingSubdomainLC);
                    }
                }
            } else {
                if (state.mode === "Add") {
                    setShowLoading(false);
                    throwEditSaveError("Subdomain is already in use.", 4);
                    return;
                } else {
                    const r = await graphql(
                        graphqlOperation(queries.companyBySubdomain, {
                            subdomain: inputtedSubdomainLC
                        })
                    );

                    if (r.data.companyBySubdomain.items.length === 1) {
                        let company = r.data.companyBySubdomain.items[0];
                        if (companyId !== company.id) {
                            setShowLoading(false);
                            throwEditSaveError("Subdomain is already in use.", 4);
                            return;
                        }
                    }
                }
            }
        } catch (e) {
            setShowLoading(false);
            console.log("Error while validating subDomain");
            console.log(e);
        }

        if (
            !!name &&
            /* !!country &&
            !!addressState && */
            !!currency &&
            !!contactname &&
            !!emailaddress &&
            /* !!addressStreet && */
            (!!logoUrl || !!currentLogoFile) &&
            !!serviceFeeType &&
            !!tagline &&
            !!primaryColor &&
            isValidColor(primaryColor)
        ) {
            //This function also handles saving changes for company
            let result = await addCompany();
            if (result) {
                if (subdomainUpdatesData.registerSubdomain) {
                    try {
                        await postApi("adminapi", "/subdomain", {
                            body: {
                                newSubdomain: subdomainUpdatesData.newSubdomain,
                                oldSubdomain: subdomainUpdatesData.oldSubdomain
                            }
                        });

                        //if oldsubdomain exists, create cache of services, skills, companylocations.
                        //read cache items of oldsubdomain, and create cache entries for the newSubdomain
                        if (subdomainUpdatesData.oldSubdomain) {
                            const response = await graphql(
                                graphqlOperation(
                                    ` 
                                  query ListRefDatas(
                                    $refType: ID
                                    $refName: ModelStringKeyConditionInput
                                    $filter: ModelRefDataFilterInput
                                    $limit: Int
                                    $nextToken: String
                                    $sortDirection: ModelSortDirection
                                  ) {
                                    listRefDatas(
                                      refType: $refType
                                      refName: $refName
                                      filter: $filter
                                      limit: $limit
                                      nextToken: $nextToken
                                      sortDirection: $sortDirection
                                    ) {
                                      items {
                                        refValue
                                        overrideValue
                                        refName
                                        refType
                                      }
                                      nextToken
                                    }
                                  }
                                `,
                                    {
                                        refType: `domain|${oldInfo[0].subdomain}`,
                                        refName: { beginsWith: "data" }
                                    }
                                )
                            );
                            for (let d of response.data.listRefDatas.items) {
                                if (
                                    d.refValue === "services" ||
                                    d.refValue === "skills" ||
                                    d.refValue === "companyLocations"
                                ) {
                                    d.refType = `domain|${subdomain}`;
                                    await graphql(
                                        graphqlOperation(
                                            mutations.createRefData,
                                            {
                                                input: d
                                            }
                                        )
                                    );
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }

                //to update 'loggedInMbUser' in local storage upon saving company
                const loggedInUser = await getCurrentUser();
                const { username } = loggedInUser;
                const user = await graphql(
                    graphqlOperation(getUser, {
                        id: username
                    })
                );
                localStorage.setItem(
                    "loggedInMbUser",
                    JSON.stringify(user.data.getUser)
                );

                setShowLoading(false);
                setSnackMsg("Company successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    if (userHasCompanyAdminRole()) {
                        actions.setPage(OUTLET_PAGE);
                        navigate("/dashboard");
                    }
                    else {
                        if (location.pathname.includes("/settings")) navigate("/settings");
                        else { actions.setPage(OUTLET_PAGE); navigate("/companies"); }
                    }
                }, 2000);
            }
        }
    }

    function getSubdomainPrefix(subdomain) {
        //.gomarketbox.com
        return subdomain.substring(
            0,
            subdomain.lastIndexOf(".gomarketbox.com")
        );
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    const handleOnClose = () => {
        setShowFeatureGateDialog(false);
    };

    // handle name change
    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    // handle contactname change
    const handleChangeContactName = (e) => {
        setContactName(e.target.value);
    };
    // handle currency change
    const handleChangeCurrency = (e, value) => {
        setCurrency(value.value);
    };

    // handle EmailAddress change
    const handleChangeEmailAddress = (e) => {
        const email = e.target.value.trim().toLowerCase();
        setEmailAddress(email);
    };

    const handleChangeReplyEmailAddress = (e) => {
        setReplyEmailAddress(e.target.value);
    };
    const handleChangePrimaryColor = (e) => {
        const color = e.target.value.replace(/\s/g, "");
        setPrimaryColor(color);
    };

    const handleChangeShowTipOption = (e) => {
        setShowTipOption(e.target.checked);
    };
    function handleAddressDialogSave(
        addressOneLine,
        street,
        city,
        state,
        postalCode,
        country,
        lat,
        lon,
        checked
    ) {
        setAddressOneLine(addressOneLine ? addressOneLine : null);
        setAddressStreet(street ? street : null);
        setAddressCity(city ? city : null);
        setAddressState(state ? state : null);
        setPostalCode(postalCode ? postalCode : null);
        setCountry(country);
        setLongitude(lon);
        setLatitude(lat);
    }

    const setSwitches = ({ target: { name, checked } }) => {
        setValues((oldValues) => ({
            ...oldValues,
            [name]: checked
        }));
    };

    const chipStyles = {
        margin: "15px",
        width: "100px",
        height: "50px"
    };

    const handlePackagesOnlySwitch = (value) => {
        setOffersPackagesOnly(value);
        if (value) {
            setMultipleQty(false);
            setMultipleServices(false);
        }
    };

    const handleChooseSpecificProviderSwitch = (value) => {
        setProviderAgnosticFlow(!value);
        if (providerMustAcceptAppt && !value) setProviderMustAcceptAppt(false);
        if (!value) {
            setSingleApptOnly(true);
            setOffersPackagesOnly(false);
            setOffersForeverAppt(false);
            setCanClientReschedule(false);
            setForceRepeatingPackages(false);
            setMultipleQty(false);
            setMultipleServices(false);
        }
    };

    const handleChangeTime = (e) => {
        const value = e.target.value;
        setBookingIntervalMinutes(value ? Math.ceil(value * 60) : 0);
    };

    function handleTabChange(event, newValue) {
        console.log("Tab Value ", newValue);

        setTabValue(newValue);

        // setTabValue(newValue);
        setShowPrimaryColorPicker(false);
        setShowButtonColorPicker(false);
        setShowButtonTextColorPicker(false);
        setShowTextColorPicker(false);
        if (newValue === 1) {
            // logic for second tab
        }
    }

    const handleCopyId = () => {
        setSnackMsg("Company ID copied.");
        setMsgOpen(true);
    };

    //function to handle when the user changes the 'Enter Manually' check box on "Payment page"
    function handleEnterStripeDetailsManually(e) {
        setShowStripeKeyInput(e.target.checked);
    }
    function displayTermsConditions() {
        return (
            <div>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        <Dialog
                            fullScreen
                            open={tosOpen}
                            maxWidth="md"
                            classes={{ paper: classes.dialogPaper }}
                        >
                            <CardMedia
                                component="iframe"
                                style={{ height: "100vh", scrolling: "no" }}
                                src={
                                    currentTosFile
                                        ? URL.createObjectURL(currentTosFile)
                                        : tosUrl
                                }
                                scrolling="no"
                            ></CardMedia>
                            <DialogActions>
                                <Button
                                    onClick={(e) => {
                                        setTosOpen(false);
                                    }}
                                    color="primary"
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function DisplayProviderTimeout() {
        if (providerMustAcceptAppt) {
            return (
                <>
                    <TableRow key={17}>
                        <TableCell component="th" scope="row" width="30%">
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <TextField
                                    variant="outlined"
                                    id="select-prov-timeout"
                                    label="Primary Provider Timeout (in minutes)"
                                    type="number"
                                    className={classes.textField}
                                    value={selectProvTimeout}
                                    onChange={(e) =>
                                        setSelectProvTimeout(e.target.value)
                                    }
                                />
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            Time given to the selected provider to accept or
                            decline a new order (if an order is not accepted in
                            this window and no other providers are available, it
                            automatically expires and the client's card is not
                            charged)
                        </TableCell>
                    </TableRow>
                    <TableRow key={18}>
                        <TableCell component="th" scope="row" width="30%">
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                <TextField
                                    variant="outlined"
                                    id="add-prov-timeout"
                                    label="Secondary Provider(s) Timeout (in minutes)"
                                    type="number"
                                    className={classes.textField}
                                    value={addProvTimeout}
                                    onChange={(e) =>
                                        setAddProvTimeout(e.target.value)
                                    }
                                />
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            Time given to additional providers to accept or
                            decline a new order after the primary provider
                            either declined or did not respond in time{" "}
                            {`\u2014`} this is on a first come first served
                            basis (if an order is not accepted in this window by
                            any of the providers, it automatically expires and
                            the client's card is not charged)
                        </TableCell>
                    </TableRow>
                </>
            );
        }
    }

    async function uploadSecvalue() {
        const companyId = props.companyId ? props.companyId : state.id;

        try {
            const body = {
                companyId,
                valueType: "SS",
                value: newSecvalue
            };
            let result;
            if (secvalue) {
                //if current value exists then update it
                result = await putApi("adminapi", `/secval`, {
                    body
                });
            } else {
                //create/upload new value
                result = await postApi("adminapi", `/secval`, {
                    body
                });
            }
            if (result && result.success) {
                await readStripeSecvalue(companyId);
            }
            setShowSecvalUploadDialog(false);
            setSnackMsg("Saved your Stripe secret key successfully.");
            setMsgOpen(true);
            setMsgOpen(true);
        } catch (e) {
            setShowSecvalUploadDialog(false);
            setSnackMsg("Could not upload the Stripe secret key.");
            setMsgOpen(true);
            console.log("error", e);
        }
    }

    function showCompanyHeading() {
        if (
            welcomeMessageState &&
            welcomeMessageState.hasActiveSchedule &&
            welcomeMessageState.companyIsActive
        )
            return true;
        return false;
    }

    function showCompanySettingsInfoNotification() {
        if (!showCompanyHeading() && !addressOneLine) {
            return true;
        } else if (
            !showCompanyHeading() &&
            showNoScheduleMessage() &&
            addressOneLine &&
            setupCompleted
        ) {
            return true;
        }
        return false;
    }

    function showNoScheduleMessage() {
        if (
            welcomeMessageState &&
            !welcomeMessageState.hasActiveSchedule &&
            welcomeMessageState.companyIsActive
        )
            return true;
        return false;
    }

    if (isLoading) {
        return (
            <Backdrop className={classes3.backdrop} open={true}>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Backdrop className={classes3.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <Dialog
                open={showStripeConnectResult}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {stripeConnectEnabled
                        ? " Success!"
                        : " Stripe Connect not set up"}
                </DialogTitle>
                <DialogContent>
                    {stripeConnectEnabled && (
                        <>
                            <DialogContentText>
                                Your Stripe account has been setup. You are
                                ready to collect payments through MarketBox.
                            </DialogContentText>
                        </>
                    )}
                    {!stripeConnectEnabled && !stripeConnectError && (
                        <DialogContentText>
                            Use the <b>‘Connect with Stripe’</b> button to
                            finish setting up your account.
                        </DialogContentText>
                    )}{" "}
                    {stripeConnectError && (
                        <DialogContentText>
                            We have encountered an error while trying to connect
                            to your Stripe Account. Please contact MarketBox
                            Support.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowStripeConnectResult(false)}
                        color="primary"
                    >
                        Close
                    </Button>{" "}
                </DialogActions>
            </Dialog>
            <Dialog
                open={showSecvalUploadDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Enter Secret Key
                </DialogTitle>
                <DialogContent style={{ paddingBottom: "0px" }}>
                    <Grid item>
                        {/* <DialogContentText>
                            Please enter your Stripe account's secret key in the
                            box below and click Save. This action will replace
                            your currently configured Stripe secret key used by
                            your Marketbox account.
                        </DialogContentText> */}
                        <Typography
                            style={{ paddingBottom: "16px", fontSize: "13px" }}
                        >
                            Your Stripe Secret key will be securely stored and
                            is kept strictly confidential.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                id="outlined-multiline-static"
                                multiline
                                value={newSecvalue}
                                fullWidth
                                size="small"
                                label="Please enter key here."
                                onChange={(e) => setNewSecvalue(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowSecvalUploadDialog(false)}
                        color="primary"
                        variant="outlined"
                    >
                        Close
                    </Button>{" "}
                    <Button
                        onClick={async () => await uploadSecvalue()}
                        color="primary"
                        variant="contained"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <FeatureGateDialog
                open={showFeatureGateDialog}
                handleOnClose={handleOnClose}
            />
            <Typography
                className={classes.title}
                variant="h4"
                noWrap
                style={{ marginBottom: 8 }}
            >
                Company settings{/*state.mode*/}
            </Typography>
            {showCompanySettingsInfoNotification() && (
                <Paper
                    rounded="true"
                    className={classes.root}
                    style={{
                        marginBottom: 8,
                        margingTop: 8,
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "14px",
                        backgroundColor: "rgba(0,135,238,0.15)"
                    }}
                    elevation={4}
                >
                    <Grid container>
                        <Grid
                            item
                            style={{ display: "flex", alignContent: "center" }}
                        >
                            <InfoIcon
                                style={{
                                    height: "20px",
                                    color: "rgba(0,135,238,0.5)"
                                }}
                            />
                        </Grid>
                        <Grid item style={{ paddingLeft: "5px" }}>
                            {!addressOneLine && (
                                <Typography
                                    variant="body1"
                                    style={{ fontSize: "14px" }}
                                >
                                    Please add your company address to complete
                                    your profile
                                </Typography>
                            )}
                            {showNoScheduleMessage() &&
                                addressOneLine &&
                                setupCompleted && (
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: "14px" }}
                                    >
                                        {"Visit our "}
                                        <Link
                                            href="https://www.gomarketbox.com/help"
                                            underline="none"
                                            target="_blank"
                                            style={{
                                                fontFamily: "Roboto",
                                                color: "#0087ee"
                                            }}
                                        >
                                            {"Help Center"}
                                        </Link>
                                        {
                                            " to learn how to add your first service, travel zone, and schedule."
                                        }
                                    </Typography>
                                )}
                        </Grid>
                    </Grid>
                </Paper>
            )}
            <Paper
                style={{ minWidth: "320px", overflow: "auto" }}
                rounded="true"
                className={classes.root}
                elevation={4}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="Company" />
                    <Tab label="Configuration" />
                    <Tab label="Communications" />
                    <Tab label="Online Bookings" />
                    <Tab label="Payments" />
                </Tabs>

                {tabValue === 0 && (
                    <TabContainer>
                        <Paper
                            rounded="true"
                            className={classes.root}
                            variant="outlined"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            variant="outlined"
                                            id="name"
                                            label="Company name"
                                            className={classes.textField}
                                            value={name}
                                            onChange={handleChangeName}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            variant="outlined"
                                            id="contactname"
                                            label="Contact name"
                                            className={classes.textField}
                                            value={contactname}
                                            onChange={handleChangeContactName}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            variant="outlined"
                                            id="email"
                                            label="Email address"
                                            className={classes.textField}
                                            value={emailaddress}
                                            onChange={handleChangeEmailAddress}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <AddressEntryTextBox
                                            outlineVariant="outlined"
                                            style={{
                                                marginTop: "0px",
                                                width: "100%",
                                                marginLeft: "0px"
                                            }}
                                            setAddress={handleAddressDialogSave}
                                            autocompleteService={
                                                autocompleteService
                                            }
                                            addressOneLine={addressOneLine}
                                            id="addressoneline"
                                            label={"Company address"}
                                            className={classes.textField}
                                            value={addressOneLine}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl
                                        margin="normal"
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            variant="outlined"
                                            id="replyemail"
                                            label="Reply to email address (optional)"
                                            className={classes.textField}
                                            value={replyemailaddress}
                                            onChange={
                                                handleChangeReplyEmailAddress
                                            }
                                        />
                                    </FormControl>
                                </Grid>{" "}
                                <Grid item xs={3}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            style={{ width: "100%" }}
                                            id="currency"
                                            options={currencies}
                                            classes={{
                                                option: classes.option
                                            }}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    label="Currency"
                                                    variant="outlined"
                                                />
                                            )}
                                            onChange={handleChangeCurrency}
                                            defaultValue={
                                                Object.keys(
                                                    defaultCurrencyValue
                                                ).length !== 0
                                                    ? defaultCurrencyValue
                                                    : { value: "", label: "" }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            id="countrycodealpha2"
                                            style={{ width: "97%" }}
                                            options={countryCodes}
                                            classes={{
                                                option: classes.option
                                            }}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Country code"
                                                    variant="outlined"
                                                />
                                            )}
                                            onChange={(e, value) =>
                                                setCountrycode3166alpha2(
                                                    value.value
                                                )
                                            }
                                            defaultValue={
                                                Object.keys(defaultCountryCode)
                                                    .length !== 0
                                                    ? defaultCountryCode
                                                    : { value: "", label: "" }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={6}>
                                    {userHasMarketboxAdminRole() ? (
                                        <FormControl
                                            margin="normal"
                                            className={classes.formControl}
                                            fullWidth
                                            required
                                        >
                                            <Autocomplete
                                                id="subscription-level-enabled"
                                                fullWidth
                                                options={subscriptionLevelConst}
                                                classes={{
                                                    option: classes.option
                                                }}
                                                autoHighlight
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <li {...props}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Subscription level"
                                                        variant="outlined"
                                                    />
                                                )}
                                                defaultValue={subscriptionLevelConst.find(
                                                    (item) =>
                                                        item.value ===
                                                        subscriptionLevel
                                                )}
                                                onChange={(e) => {
                                                    const val =
                                                        e.target.innerText;
                                                    if (val === "None") {
                                                        setSubscriptionLevel(
                                                            ""
                                                        );
                                                    } else if (
                                                        val === "Free Trial"
                                                    ) {
                                                        setSubscriptionLevel(
                                                            "FREE_TRIAL_BASIC"
                                                        );
                                                    } else if (
                                                        val === "Starter"
                                                    ) {
                                                        setSubscriptionLevel(
                                                            "BASIC"
                                                        );
                                                    } else if (
                                                        val === "Professional"
                                                    ) {
                                                        setSubscriptionLevel(
                                                            "PROFESSIONAL"
                                                        );
                                                    } else if (
                                                        val === "Enterprise"
                                                    ) {
                                                        setSubscriptionLevel(
                                                            "ENTERPRISE"
                                                        );
                                                    } else if (
                                                        val === "Sole Operator"
                                                    ) {
                                                        setSubscriptionLevel(
                                                            "SOLE_OPERATOR"
                                                        );
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    ) : (
                                        <FormControl
                                            margin="normal"
                                            className={classes.formControl}
                                            fullWidth
                                            required
                                            disabled
                                        >
                                            <InputLabel htmlFor="subscripton-level-disabled">
                                                Subscription Level
                                            </InputLabel>
                                            <Select
                                                value={
                                                    subscriptionLevel
                                                        ? subscriptionLevel
                                                        : "None"
                                                }
                                                inputProps={{
                                                    name: "type",
                                                    id: "subscription-level-disabled"
                                                }}
                                                className={classes.fullwidth}
                                                displayEmpty
                                            >
                                                <MenuItem value={"None"}>
                                                    None
                                                </MenuItem>
                                                <MenuItem
                                                    value={"SOLE_OPERATOR"}
                                                >
                                                    Sole Operator
                                                </MenuItem>
                                                <MenuItem value={"BASIC"}>
                                                    Starter
                                                </MenuItem>
                                                <MenuItem
                                                    value={"PROFESSIONAL"}
                                                >
                                                    Professional
                                                </MenuItem>
                                                <MenuItem value={"ENTERPRISE"}>
                                                    Enterprise
                                                </MenuItem>
                                                <MenuItem
                                                    value={"FREE_TRIAL_BASIC"}
                                                >
                                                    Free Trial
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                {userHasMarketboxAdminRole() &&
                                    state.mode !== "Add" && (
                                        <Grid item xs={6}>
                                            <FormControl
                                                margin="normal"
                                                required
                                                className={classes.formControl}
                                                style={{ width: "100%" }}
                                            >
                                                <InputLabel>
                                                    Company ID
                                                </InputLabel>
                                                <Input
                                                    id="companyId"
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={companyId}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <CopyToClipboard
                                                                text={companyId}
                                                            >
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        handleCopyId()
                                                                    }
                                                                >
                                                                    Copy
                                                                </Button>
                                                            </CopyToClipboard>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                            </Grid>
                            {loggedInUser?.role === "MARKETBOX_ADMIN" && (
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems={"center"}
                                >
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            margin="normal"
                                            className={classesnew.marginBottom}
                                            control={
                                                <Switch
                                                    checked={active}
                                                    onChange={(event) =>
                                                        setActive(
                                                            event.target.checked
                                                        )
                                                    }
                                                    value="active"
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label":
                                                            "primary checkbox"
                                                    }}
                                                />
                                            }
                                            label="Active"
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </TabContainer>
                )}
                {tabValue === 1 && (
                    <TabContainer>
                        <Paper
                            rounded="true"
                            className={classes.root}
                            variant="outlined"
                        >
                            <TableContainer component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-label="Configuration Settings"
                                >
                                    <TableBody>
                                        <TableRow key={0}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Typography
                                                    variant="button"
                                                    noWrap
                                                >
                                                    Setting
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Typography
                                                    variant="button"
                                                    noWrap
                                                >
                                                    Description
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={1}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <TextField
                                                    style={{
                                                        marginTop: "15px"
                                                    }}
                                                    variant="outlined"
                                                    id="primarycolor"
                                                    label="Primary color"
                                                    className={
                                                        classes.textField
                                                    }
                                                    value={primaryColor}
                                                    onChange={
                                                        handleChangePrimaryColor
                                                    }
                                                />
                                                <Chip
                                                    style={{
                                                        ...chipStyles,
                                                        backgroundColor:
                                                            primaryColor
                                                    }}
                                                />
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPrimaryColorPicker(
                                                            (
                                                                showPrimaryColorPicker
                                                            ) =>
                                                                !showPrimaryColorPicker
                                                        )
                                                    }
                                                    style={dropdownStyle}
                                                    size="large"
                                                >
                                                    {showPrimaryColorPicker ? (
                                                        <ArrowDropUpIcon
                                                            style={{
                                                                color: "black"
                                                            }}
                                                        />
                                                    ) : (
                                                        <ArrowDropDownIcon
                                                            style={{
                                                                color: "black"
                                                            }}
                                                        />
                                                    )}
                                                </IconButton>
                                                {showPrimaryColorPicker && (
                                                    <div style={popover}>
                                                        <SketchPicker
                                                            color={primaryColor}
                                                            onChange={(
                                                                updatedColor
                                                            ) =>
                                                                setPrimaryColor(
                                                                    updatedColor.hex
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {" "}
                                                The primary color is used to
                                                style features such as buttons,
                                                labels, and the banner on your
                                                MarketBox booking flow. Note:
                                                You may use the dropdown icon to
                                                select a color via the color
                                                picker or type a color hexcode
                                                in the text field.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={2}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                values.remote
                                                            }
                                                            onChange={
                                                                setSwitches
                                                            }
                                                            value="remote"
                                                            name="remote"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Mobile services"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if your
                                                company allows providers to
                                                travel to bookings. Note:
                                                Provider travel zones can be
                                                configured in their profile
                                                section.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={3}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                values.premise
                                                            }
                                                            onChange={
                                                                setSwitches
                                                            }
                                                            value="premise"
                                                            color="primary"
                                                            name="premise"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="On-premise or virtual services"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if your
                                                company offers services from
                                                physical or virtual locations.
                                                You will now be able to add
                                                locations to your profile under
                                                Admin {"\u2192"} Locations.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={4}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                onClick={() => {
                                                    if (
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "PriceByLocation"
                                                        )
                                                    ) {
                                                        setShowFeatureGateDialog(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                    className={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "PriceByLocation"
                                                        )
                                                            ? classesnew.marginFeatureGateCustom
                                                            : classesnew.marginBottomNone
                                                    }
                                                    sx={{
                                                        margin: isFeatureGated(
                                                            subscriptionLevel,
                                                            "PriceByLocation"
                                                        )
                                                            ? "0xp 0px 0px -11px"
                                                            : ""
                                                    }}
                                                    control={
                                                        <Switch
                                                            checked={
                                                                currencyBasedOnLocation
                                                            }
                                                            disabled={isFeatureGated(
                                                                subscriptionLevel,
                                                                "PriceByLocation"
                                                            )}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setCurrencyBasedOnLocation(
                                                                    event.target
                                                                        .checked
                                                                );
                                                            }}
                                                            value="currencyBasedOnLocation"
                                                            color="primary"
                                                            name="currency"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    /* label={"Currency based on location"} */
                                                    label={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "PriceByLocation"
                                                        ) ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "space-between"
                                                                }}
                                                            >
                                                                <Typography>
                                                                    Currency
                                                                    based on
                                                                    location
                                                                </Typography>
                                                                <StarsIcon
                                                                    style={{
                                                                        color: "#fcaf17"
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            "Currency based on location"
                                                        )
                                                    }
                                                    classes={{
                                                        label: classes.tableCellLabel
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                If you wish to charge customers
                                                in the default currency set for
                                                your company, turn off this
                                                setting. If you wish to charge
                                                customers in the base currency
                                                in the location where a booking
                                                takes place, turn this setting
                                                on.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={5}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                displayOnlyLogo
                                                            }
                                                            onChange={(event) =>
                                                                setDisplayOnlyLogo(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="displayOnlyLogo"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Display only logo"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on to display
                                                your company logo on its own, as
                                                is, as uploaded. If you wish to
                                                have your logo displayed in a
                                                smaller, circular format, with
                                                your company name beside it,
                                                turn the setting off.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={6.1}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                singleApptOnly
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    isFeatureGated(
                                                                        subscriptionLevel,
                                                                        "DisableSingleAppointments"
                                                                    )
                                                                ) {
                                                                    setShowFeatureGateDialog(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setSingleApptOnly(
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    );
                                                                }
                                                            }}
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Single appointment only"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if a
                                                customer must be able to
                                                purchase only a single booking
                                                at a time.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={8}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                onClick={() => {
                                                    if (
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "RecurringAppointments"
                                                        )
                                                    ) {
                                                        setShowFeatureGateDialog(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "RecurringAppointments"
                                                        )
                                                            ? classesnew.marginFeatureGateCustom
                                                            : classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            disabled={
                                                                singleApptOnly
                                                            }
                                                            checked={
                                                                offersForeverAppt
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setOffersForeverAppt(
                                                                    event.target
                                                                        .checked
                                                                );
                                                            }}
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "RecurringAppointments"
                                                        ) ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "space-between"
                                                                }}
                                                            >
                                                                <Typography>
                                                                    Ongoing
                                                                    appointments
                                                                    only
                                                                </Typography>
                                                                <StarsIcon
                                                                    style={{
                                                                        color: "#fcaf17"
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            "Ongoing appointments only"
                                                        )
                                                    }
                                                    classes={{
                                                        label: classes.tableCellLabel
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if you only
                                                wish to allow customers to book
                                                services on an ongoing or
                                                repeating basis (ex. weekly,
                                                monthly etc.)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={7}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                onClick={() => {
                                                    if (
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "Packages"
                                                        )
                                                    ) {
                                                        setShowFeatureGateDialog(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "Packages"
                                                        )
                                                            ? classesnew.marginFeatureGateCustom
                                                            : classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            disabled={
                                                                singleApptOnly
                                                            }
                                                            checked={
                                                                offersPackagesOnly
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handlePackagesOnlySwitch(
                                                                    event.target
                                                                        .checked
                                                                );
                                                            }}
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "Packages"
                                                        ) ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "space-between"
                                                                }}
                                                            >
                                                                <Typography>
                                                                    Packages
                                                                    only
                                                                </Typography>
                                                                <StarsIcon
                                                                    style={{
                                                                        color: "#fcaf17"
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            "Packages only"
                                                        )
                                                    }
                                                    classes={{
                                                        label: classes.tableCellLabel
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if a
                                                customer must purchase a package
                                                of services in order to book a
                                                service. If you turn this
                                                setting off, customers will be
                                                able to book and pay for
                                                services on a pay as you go
                                                basis.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={9}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "Packages"
                                                        )
                                                            ? classesnew.marginFeatureGateCustom
                                                            : classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            disabled={
                                                                singleApptOnly
                                                            }
                                                            checked={
                                                                forceRepeatingPackages
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setForceRepeatingPackages(
                                                                    event.target
                                                                        .checked
                                                                );
                                                            }}
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        isFeatureGated(
                                                            subscriptionLevel,
                                                            "Packages"
                                                        ) ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "space-between"
                                                                }}
                                                            >
                                                                <Typography>
                                                                    Packages
                                                                    only
                                                                </Typography>
                                                                <StarsIcon
                                                                    style={{
                                                                        color: "#fcaf17"
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            "Force repeating packages"
                                                        )
                                                    }
                                                    classes={{
                                                        label: classes.tableCellLabel
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Clients can book either
                                                repeating packages for same day
                                                and time every week or
                                                cherry-pick single sessions with
                                                no repeating pattern; if this
                                                setting is on, it will only
                                                allow repeating sessions
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={9}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                multipleServices
                                                            }
                                                            disabled={
                                                                offersPackagesOnly ||
                                                                providerAgnosticFlow
                                                            }
                                                            onChange={(event) =>
                                                                setMultipleServices(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Multiple services"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if you wish
                                                to allow customers to book
                                                multiple services for each
                                                booking. If this setting is off,
                                                customers will only be able to
                                                book one service per booking.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={10}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                multipleQty
                                                            }
                                                            disabled={
                                                                offersPackagesOnly ||
                                                                providerAgnosticFlow
                                                            }
                                                            onChange={(event) =>
                                                                setMultipleQty(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Multiple quantity"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if you wish
                                                to allow customers to book
                                                multiple quantities of a service
                                                for each booking. If this
                                                setting is off, customers will
                                                only be able to book one unit of
                                                a service per booking.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={11}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                width="35%"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    required
                                                    style={{
                                                        width: "60%",
                                                        marginBottom: "3px"
                                                    }}
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                addServiceFee
                                                            }
                                                            onChange={(e) =>
                                                                setAddserviceFee(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="premise"
                                                            color="primary"
                                                            name="premise"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Additional service fee"
                                                />
                                                <Autocomplete
                                                    options={[
                                                        "DOLLAR",
                                                        "PERCENTAGE"
                                                    ]}
                                                    disabled={!addServiceFee}
                                                    style={{ width: "70%" }}
                                                    value={serviceFeeType}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Service fee type"
                                                        />
                                                    )}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <li {...props}>
                                                            {option}
                                                        </li>
                                                    )}
                                                    onChange={(e, newValue) => {
                                                        console.log(
                                                            "This is the serviceFeeType",
                                                            serviceFeeType
                                                        );
                                                        console.log(
                                                            "This is the event target value",
                                                            newValue
                                                        );
                                                        if (newValue)
                                                            setServiceFeeType(
                                                                newValue
                                                            );
                                                    }}
                                                />
                                                <FormControl
                                                    margin="none"
                                                    required
                                                    style={{
                                                        width: "70%",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id="serviceFeeAmount"
                                                        label="Service fee amount"
                                                        className={
                                                            classes.textField
                                                        }
                                                        type="number"
                                                        value={serviceFeeAmount}
                                                        inputProps={{
                                                            min: "1",
                                                            step: "1"
                                                        }}
                                                        onChange={(e) =>
                                                            setServiceFeeAmount(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={
                                                            !addServiceFee
                                                        }
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                These settings allow you to
                                                specify if you wish to charge a
                                                service fee as part of each
                                                booking, and if so, the fixed
                                                amount or percentage amount (of
                                                the cost of the booking) to
                                                charge.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={12}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                hideRatingsFromClients
                                                            }
                                                            onChange={(event) =>
                                                                setHideRatingsFromClients(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Hide provider ratings from clients"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Turn this setting on if you wish
                                                to hide provider ratings and
                                                reviews from your clients. If
                                                this setting is off, clients
                                                will be able to view your
                                                provider ratings and reviews.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={13}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                useAnonymousPermalink
                                                            }
                                                            onChange={(event) =>
                                                                setUseAnonymousPermalink(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Anonymous provider permalinks"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                If this setting is off, the
                                                provider permalink will be
                                                generated in the format of
                                                [firstname]-[lastname]. If this
                                                setting is on, the provider
                                                permalink will be generated in
                                                the format of
                                                [firstname]-[number]. This
                                                allows for a level of anonymity
                                                for providers.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={14}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                width="30%"
                                            >
                                                <FormControl
                                                    margin="normal"
                                                    required
                                                    style={{ width: "65%" }}
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id="bookingIntervalMinutes"
                                                        label="Minimum booking notice (hours)"
                                                        defaultValue={(
                                                            bookingIntervalMinutes /
                                                            60
                                                        ).toFixed(2)}
                                                        className={
                                                            classes.textField
                                                        }
                                                        type="number"
                                                        onChange={
                                                            handleChangeTime
                                                        }
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                This establishes the minimum
                                                amount of notice that a client
                                                may book a provider. For
                                                example, if this value is set to
                                                1.5, a client cannot book an
                                                appointment with a provider less
                                                than 1.5 hours before the
                                                appointment start time.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={15}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                width="30%"
                                            >
                                                <FormControl
                                                    margin="normal"
                                                    required
                                                    style={{ width: "60%" }}
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        label="Buffer time between bookings (min)"
                                                        select
                                                        onChange={(e) => {
                                                            setTravelTime(
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={travelTime}
                                                        className={
                                                            classes.fullwidth
                                                        }
                                                    >
                                                        <MenuItem value={0}>
                                                            0
                                                        </MenuItem>
                                                        <MenuItem value={15}>
                                                            15
                                                        </MenuItem>
                                                        <MenuItem value={30}>
                                                            30
                                                        </MenuItem>
                                                        <MenuItem value={45}>
                                                            45
                                                        </MenuItem>
                                                        <MenuItem value={60}>
                                                            60
                                                        </MenuItem>
                                                        <MenuItem value={90}>
                                                            90
                                                        </MenuItem>
                                                        <MenuItem value={120}>
                                                            120
                                                        </MenuItem>
                                                    </TextField>
                                                    {/* <FormHelperText id="traveltimehelp">
                                                Travel time added for remote
                                                bookings
                                            </FormHelperText> */}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                This setting determines the
                                                travel time buffer between
                                                remote appointments. For
                                                example, if this value is set to
                                                60 and the provider has an
                                                appointment at location A which
                                                ends at 2pm, a client at
                                                location B cannot book the
                                                provider before 3pm.
                                            </TableCell>
                                        </TableRow>
                                        {!companyIsSoleOperator() && (
                                            <>
                                                <TableRow key={16}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <FormControlLabel
                                                            margin="normal"
                                                            className={
                                                                classesnew.marginBottomNone
                                                            }
                                                            control={
                                                                <Switch
                                                                    checked={
                                                                        !providerAgnosticFlow
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        handleChooseSpecificProviderSwitch(
                                                                            event
                                                                                .target
                                                                                .checked
                                                                        );
                                                                    }}
                                                                    value="active"
                                                                    color="primary"
                                                                    inputProps={{
                                                                        "aria-label":
                                                                            "primary checkbox"
                                                                    }}
                                                                />
                                                            }
                                                            label="Let customers choose a specific provider"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        Turn this on if you want
                                                        to allow your customers
                                                        to choose a specific
                                                        provider and view their
                                                        bios and photos. Turn
                                                        this off if you want
                                                        your customers to be
                                                        assigned a provider
                                                        based on the time they
                                                        select.
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key={16}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={() => {
                                                            if (
                                                                isFeatureGated(
                                                                    subscriptionLevel,
                                                                    "ProviderAcceptance"
                                                                )
                                                            ) {
                                                                setShowFeatureGateDialog(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            margin="normal"
                                                            className={
                                                                isFeatureGated(
                                                                    subscriptionLevel,
                                                                    "ProviderAcceptance"
                                                                )
                                                                    ? classesnew.marginFeatureGateCustom
                                                                    : classesnew.marginBottomNone
                                                            }
                                                            control={
                                                                <Switch
                                                                    checked={
                                                                        providerMustAcceptAppt
                                                                    }
                                                                    disabled={isFeatureGated(
                                                                        subscriptionLevel,
                                                                        "ProviderAcceptance"
                                                                    )}
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        if (
                                                                            event
                                                                                .target
                                                                                .checked &&
                                                                            providerAgnosticFlow
                                                                        )
                                                                            setProviderAgnosticFlow(
                                                                                false
                                                                            );
                                                                        setProviderMustAcceptAppt(
                                                                            event
                                                                                .target
                                                                                .checked
                                                                        );
                                                                    }}
                                                                    value="active"
                                                                    color="primary"
                                                                    inputProps={{
                                                                        "aria-label":
                                                                            "primary checkbox"
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                isFeatureGated(
                                                                    subscriptionLevel,
                                                                    "ProviderAcceptance"
                                                                ) ? (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            justifyContent:
                                                                                "space-between"
                                                                        }}
                                                                    >
                                                                        <Typography>
                                                                            Provider
                                                                            must
                                                                            accept
                                                                            booking
                                                                        </Typography>
                                                                        <StarsIcon
                                                                            style={{
                                                                                color: "#fcaf17"
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                ) : (
                                                                    "Provider must accept booking"
                                                                )
                                                            }
                                                            classes={{
                                                                label: classes.tableCellLabel
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        Turn this setting on if
                                                        you would like providers
                                                        to explicitly accept
                                                        bookings via Text
                                                        message or email. If you
                                                        turn this setting off,
                                                        providers will be booked
                                                        automatically by the
                                                        system based on their
                                                        scheduled availability.
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                        {!companyIsSoleOperator() &&
                                            DisplayProviderTimeout()}
                                        <TableRow key={17}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControl
                                                    margin="none"
                                                    required
                                                    style={{
                                                        width: "60%"
                                                    }}
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        id="cancelPolicyMinutesBefore"
                                                        label="Cancel time (hours)"
                                                        defaultValue={(
                                                            cancelPolicyMinsBefore /
                                                            60
                                                        ).toFixed(2)}
                                                        className={
                                                            classes.textField
                                                        }
                                                        type="number"
                                                        onChange={(e) => {
                                                            const val =
                                                                e.target.value;
                                                            setCancelPolicyMinsBefore(
                                                                val
                                                                    ? Math.ceil(
                                                                          val *
                                                                              60
                                                                      )
                                                                    : 0
                                                            );
                                                        }}
                                                    ></TextField>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                This sets the minimum notice
                                                that a customer must provide if
                                                they cancel a booking, in order
                                                to avoid a penalty. For example,
                                                if you specify a cancellation
                                                period of 24.5, customers will
                                                be able to cancel their
                                                appointments and receive either
                                                a credit back to their account,
                                                or a refund, as long as they
                                                cancel at least 24 hours and 30
                                                minutes before their
                                                appointment.
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={18}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                providerCanCancelAppt
                                                            }
                                                            onChange={(event) =>
                                                                setProviderCanCancelAppt(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Provider may cancel booking"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                If this setting is on, providers
                                                may cancel their bookings. If
                                                this setting is off, the cancel
                                                button will not display for
                                                providers and only company
                                                admins and clients may cancel a
                                                booking.
                                            </TableCell>
                                        </TableRow>

                                        {/* reschedule rows configuration settings */}
                                        <TableRow key={19}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={
                                                                canProviderReschedule
                                                            }
                                                            onChange={(event) =>
                                                                setCanProviderReschedule(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Provider may reschedule bookings"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                If this setting is on, providers
                                                may reschedule the date/time of
                                                their own bookings.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={20}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <FormControlLabel
                                                    margin="normal"
                                                    className={
                                                        classesnew.marginBottomNone
                                                    }
                                                    control={
                                                        <Switch
                                                            disabled={
                                                                providerAgnosticFlow
                                                            }
                                                            checked={
                                                                canClientReschedule
                                                            }
                                                            onChange={(event) =>
                                                                setCanClientReschedule(
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            value="active"
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Clients may reschedule bookings"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                If this setting is on, clients
                                                may reschedule the date/time of
                                                their own bookings.
                                            </TableCell>
                                        </TableRow>
                                        {showSuggestionSettings() && (
                                            <>
                                                <TableRow key={21}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        width="30%"
                                                    >
                                                        <FormControl
                                                            margin="normal"
                                                            required
                                                            style={{
                                                                width: "90%"
                                                            }}
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <TextField
                                                                variant="outlined"
                                                                label="Place first booking of the day at or near"
                                                                select
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setTimeSuggestionsFirstBookingOfTheDay(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                value={
                                                                    timeSuggestionsFirstBookingOfTheDay
                                                                }
                                                                className={
                                                                    classes.fullwidth
                                                                }
                                                            >
                                                                {anchorTimeOptions.map(
                                                                    (
                                                                        anchorTime
                                                                    ) => (
                                                                        <MenuItem
                                                                            value={
                                                                                anchorTime.value
                                                                            }
                                                                        >
                                                                            {
                                                                                anchorTime.text
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </TextField>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell>
                                                        The first booking on an
                                                        empty day for a provider
                                                        can be placed at a time
                                                        of your choosing and
                                                        will be used as an
                                                        "anchor" point to build
                                                        the day's schedule
                                                        around and cluster
                                                        subsequent bookings
                                                        around that first one
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key={22}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        width="30%"
                                                    >
                                                        <FormControl
                                                            margin="normal"
                                                            required
                                                            style={{
                                                                width: "90%"
                                                            }}
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <TextField
                                                                variant="outlined"
                                                                label="Max travel time between bookings (mins)"
                                                                select
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setTimeSuggestionsMaxTravelTimeMinutesBetweenBookings(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                value={
                                                                    timeSuggestionsMaxTravelTimeMinutesBetweenBookings
                                                                }
                                                                className={
                                                                    classes.fullwidth
                                                                }
                                                            >
                                                                {maxTravelTimeMinutesBetweenBookings.map(
                                                                    (
                                                                        minutes
                                                                    ) => (
                                                                        <MenuItem
                                                                            value={
                                                                                minutes
                                                                            }
                                                                        >
                                                                            {
                                                                                minutes
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </TextField>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell>
                                                        Maximum travel time
                                                        between bookings within
                                                        a provider's travel zone
                                                        (the provider's overall
                                                        coverage area can be
                                                        large, but travel time
                                                        between bookings is
                                                        capped){" "}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </TabContainer>
                )}
                {tabValue === 2 && (
                    <TabContainer>
                        <Paper
                            rounded="true"
                            className={classes.root}
                            variant="outlined"
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    flexDirection: "column",
                                    marginBottom: "16px"
                                }}
                            >
                                <Grid item sx={{ maxWidth: "100%" }}>
                                    <FormControlLabel
                                        margin="normal"
                                        style={{ marginBottom: "0px" }}
                                        control={
                                            <Switch
                                                checked={sendratingTipMsg}
                                                onChange={(event) =>
                                                    setSendratingTipMsg(
                                                        event.target.checked
                                                    )
                                                }
                                                value="active"
                                                color="primary"
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox"
                                                }}
                                            />
                                        }
                                        label="Send clients an SMS with link to review their provider once booking is complete"
                                    />
                                    <CustomTooltip
                                        placement="right"
                                        title="This SMS will contain a message and link which redirects the client to the Review Page, where they can leave a review and optionally add a tip for the provider"
                                    >
                                        <IconButton size="large">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        margin="normal"
                                        style={{ marginBottom: "0px" }}
                                        control={
                                            <Switch
                                                checked={showTipOption}
                                                onChange={(event) =>
                                                    handleChangeShowTipOption(
                                                        event
                                                    )
                                                }
                                                color="primary"
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox"
                                                }}
                                            />
                                        }
                                        label="Show tip options on review page"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <FormControl
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            variant="outlined"
                                            id="ratingTipTextMsg"
                                            label="Message for provider review SMS"
                                            multiline
                                            rows="3"
                                            className={classes.textField}
                                            value={ratingTipTextMsg}
                                            onChange={(e) =>
                                                setRatingTipTextMsg(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    style={{ alignSelf: "center" }}
                                >
                                    <CustomTooltip
                                        placement="bottom"
                                        title="This message is displayed on the Provider Review SMS which will be sent to the client once their booking is marked complete. Valid tokens include [client_name], [provider-fullname], and [provider-first-name]. Please see the MarketBox wiki for more information."
                                    >
                                        <IconButton size="large">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <span>
                                        <FormControl
                                            margin="normal"
                                            required
                                            fullWidth
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                variant="outlined"
                                                id="clientNotesHeading"
                                                label="Display message for client notes box on booking confirmation page"
                                                multiline
                                                rows="3"
                                                className={classes.textField}
                                                value={clientNotesHeading}
                                                onChange={(e) =>
                                                    setClientNotesHeading(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </FormControl>
                                    </span>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    style={{ alignSelf: "center" }}
                                >
                                    <CustomTooltip
                                        placement="bottom"
                                        title="This message allows you to request that a client include additional information related to the booking. For example name, age, skill level of each participant, allergies, nail polish color request, parking instructions, etc."
                                    >
                                        <IconButton size="large">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={mandatoryNotes}
                                            onChange={(event) =>
                                                setMandatoryNotes(
                                                    (prevState) => !prevState
                                                )
                                            }
                                            color="primary"
                                        />
                                    }
                                    label="Mandatory"
                                />
                            </Grid>

                            <Grid
                                sx={{ marginTop: "0.5rem" }}
                                container
                                spacing={2}
                            >
                                <Grid item xs={11}>
                                    <Autocomplete
                                        value={chipsOrder}
                                        inputValue={orderSummaryBccList}
                                        onInputChange={(e, value) =>
                                            setOrderSummaryBccList(value)
                                        }
                                        onChange={(event, value) =>
                                            setChipsOrder(value)
                                        }
                                        onKeyDown={handleInputKeyDown}
                                        fullWidth
                                        clearIcon={false}
                                        multiple
                                        id="ac-bcc-list-1"
                                        options={[]}
                                        freeSolo
                                        renderTags={(value, getTagProps) =>
                                            value.map((chip, index) => (
                                                <Chip
                                                    key={index}
                                                    label={chip}
                                                    onDelete={() =>
                                                        removeChip(
                                                            chip,
                                                            "orderBcc"
                                                        )
                                                    }
                                                    sx={{
                                                        marginBottom: "0.25rem",
                                                        verticalAlign: "middle"
                                                    }}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="bcc-list-1"
                                                variant="outlined"
                                                label="BCC email address list for order summary notifications"
                                                placeholder={
                                                    chipsOrder &&
                                                    chipsOrder.length <= 0
                                                        ? "Press enter to separate emails"
                                                        : ""
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={1}
                                    style={{ alignSelf: "center" }}
                                >
                                    <CustomTooltip title="Order Summary notifications are sent out when a booking has been confirmed. Add the email address of any admin who would like to be notified of Order Summaries. To add multiple emails, separate the emails by separate the emails by pressing enter.">
                                        <IconButton size="large">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                sx={{ marginTop: "1.1rem" }}
                                spacing={2}
                            >
                                <Grid container item xs={11}>
                                    <Autocomplete
                                        value={chipsWelcome}
                                        inputValue={welcomeBccList}
                                        onInputChange={(e, value) =>
                                            setWelcomeBccList(value)
                                        }
                                        onChange={(event, value) =>
                                            setChipsWelcome(value)
                                        }
                                        onKeyDown={handleInputKeyDown}
                                        fullWidth
                                        clearIcon={false}
                                        multiple
                                        id="ac-bcc-list-2"
                                        options={[]}
                                        freeSolo
                                        renderTags={(value, getTagProps) =>
                                            value.map((chip, index) => (
                                                <Chip
                                                    key={index}
                                                    label={chip}
                                                    onDelete={() =>
                                                        removeChip(
                                                            chip,
                                                            "welcomeBcc"
                                                        )
                                                    }
                                                    sx={{
                                                        marginBottom: "0.25rem",
                                                        verticalAlign: "middle"
                                                    }}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="bcc-list-2"
                                                variant="outlined"
                                                label="BCC email address list for welcome email"
                                                placeholder={
                                                    chipsWelcome &&
                                                    chipsWelcome.length <= 0
                                                        ? "Press enter to separate emails"
                                                        : ""
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={1}
                                    style={{ alignSelf: "center" }}
                                >
                                    <CustomTooltip title="Welcome Emails are sent out when a new user has been added to your account. Add the email address of any admin who should receive copies of the Welcome Emails. To add multiple emails, separate the emails by separate the emails by pressing enter.">
                                        <IconButton size="large">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>

                            {providerMustAcceptAppt && (
                                <>
                                    <Grid
                                        container
                                        sx={{ marginTop: "1.1rem" }}
                                        spacing={2}
                                    >
                                        <Grid container item xs={11}>
                                            <Autocomplete
                                                value={chipsOrderCreated}
                                                inputValue={
                                                    bookingReqCreatedList
                                                }
                                                onInputChange={(e, value) =>
                                                    setBookingReqCreatedList(
                                                        value
                                                    )
                                                }
                                                onChange={(event, value) =>
                                                    setChipsOrderCreated(value)
                                                }
                                                onKeyDown={handleInputKeyDown}
                                                fullWidth
                                                clearIcon={false}
                                                multiple
                                                id="ac-orderCreated-emails"
                                                options={[]}
                                                freeSolo
                                                renderTags={(
                                                    value,
                                                    getTagProps
                                                ) =>
                                                    value.map((chip, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={chip}
                                                            onDelete={() =>
                                                                removeChip(
                                                                    chip,
                                                                    "orderCreated"
                                                                )
                                                            }
                                                            sx={{
                                                                marginBottom:
                                                                    "0.25rem",
                                                                verticalAlign:
                                                                    "middle"
                                                            }}
                                                            {...getTagProps({
                                                                index
                                                            })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        id="orderCreated-emails"
                                                        variant="outlined"
                                                        label="Email address list for Order Created notifications"
                                                        placeholder={
                                                            chipsOrderCreated &&
                                                            chipsOrderCreated.length <=
                                                                0
                                                                ? "Press enter to separate emails"
                                                                : ""
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                            style={{ alignSelf: "center" }}
                                        >
                                            <CustomTooltip title="Order Created notifications are sent out when an Order is created and is pending to be accepted by a provider. Add the email address of any admin who would like to be notified. To add multiple emails, separate the emails by separate the emails by pressing enter.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </CustomTooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        sx={{ marginTop: "1.1rem" }}
                                        container
                                        spacing={2}
                                    >
                                        <Grid container item xs={11}>
                                            <Autocomplete
                                                value={chipsOrderExpired}
                                                inputValue={
                                                    bookingReqExpiredList
                                                }
                                                onInputChange={(e, value) =>
                                                    setBookingReqExpiredList(
                                                        value
                                                    )
                                                }
                                                onChange={(event, value) =>
                                                    setChipsOrderExpired(value)
                                                }
                                                onKeyDown={handleInputKeyDown}
                                                fullWidth
                                                clearIcon={false}
                                                multiple
                                                id="ac-orderExpired-emails"
                                                options={[]}
                                                freeSolo
                                                renderTags={(
                                                    value,
                                                    getTagProps
                                                ) =>
                                                    value.map((chip, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={chip}
                                                            onDelete={() =>
                                                                removeChip(
                                                                    chip,
                                                                    "orderExpired"
                                                                )
                                                            }
                                                            sx={{
                                                                marginBottom:
                                                                    "0.25rem",
                                                                verticalAlign:
                                                                    "middle"
                                                            }}
                                                            {...getTagProps({
                                                                index
                                                            })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        id="orderExpired-emails"
                                                        variant="outlined"
                                                        label="Email address list for Order Expired/Declined notifications"
                                                        placeholder={
                                                            chipsOrderExpired &&
                                                            chipsOrderExpired.length <=
                                                                0
                                                                ? "Press enter to separate emails"
                                                                : ""
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                            style={{ alignSelf: "center" }}
                                        >
                                            <CustomTooltip title="Order Expired/Declined notifications are sent out when an order has been not been accepted by any providers within the set time window. Add the email address of any admin who would like to be notified. To add multiple emails, separate the emails by pressing enter.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </CustomTooltip>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    </TabContainer>
                )}
                {tabValue === 3 && (
                    <BookingFlowSettings compId={props.companyId ? props.companyId : state.id} mode={state.mode === "Add" ? "Add" : "Edit"}/>
                )}
                {tabValue === 4 && (
                    <TabContainer>
                        <Paper rounded="true" variant="outlined">
                            <Grid
                                container
                                spacing={0}
                                alignItems="normal"
                                justify="center"
                                style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        maxWidth: "100%",
                                        padding: "39px"
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: "flex",
                                            maxWidth: "100%",
                                            flexDirection:
                                                window.innerWidth <= 400
                                                    ? "column"
                                                    : ""
                                        }}
                                    >
                                        <Paper
                                            elevation={3}
                                            style={{
                                                height: "101px",
                                                background: "#FAF8F8",
                                                borderRadius: "11px",
                                                width:
                                                    window.innerWidth <= 400
                                                        ? "100%"
                                                        : "26%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent:
                                                    window.innerWidth <= 1024
                                                        ? "center"
                                                        : "space-between",
                                                flexDirection:
                                                    window.innerWidth <= 1024
                                                        ? "column"
                                                        : ""
                                            }}
                                        >
                                            <img
                                                src="https://marketbox-prod-booknow.s3.amazonaws.com/public/email-templates/87d5b146-e678-4578-b351-1b697815ed61/stripe-connect-logo.png"
                                                alt="logo"
                                                height="51%"
                                                style={{
                                                    paddingLeft:
                                                        window.innerWidth > 1024
                                                            ? "20px"
                                                            : ""
                                                }}
                                            />
                                            <img
                                                src="https://marketbox-prod-booknow.s3.amazonaws.com/public/email-templates/87d5b146-e678-4578-b351-1b697815ed61/stripe-accepted-cards.png"
                                                alt="logo"
                                                style={{
                                                    paddingRight:
                                                        window.innerWidth > 1024
                                                            ? "20px"
                                                            : "",
                                                    height:
                                                        window.innerWidth >=
                                                        1466
                                                            ? "25%"
                                                            : "16%"
                                                }}
                                            />
                                        </Paper>
                                        {stripeConnectedAccount &&
                                        stripeConnectEnabled ? (
                                            <Grid
                                                item
                                                style={{
                                                    width:
                                                        window.innerWidth <= 400
                                                            ? "100%"
                                                            : "50%",
                                                    paddingLeft:
                                                        window.innerWidth > 400
                                                            ? "32px"
                                                            : "",
                                                    paddingTop:
                                                        window.innerWidth > 400
                                                            ? "10px"
                                                            : "20px"
                                                }}
                                            >
                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px"
                                                        }}
                                                    >
                                                        {
                                                            "Your Stripe account has been successfully connected, all payments collected through MarketBox will appear in your Stripe Account on the "
                                                        }
                                                        <Link
                                                            href="https://dashboard.stripe.com/payments"
                                                            underline="none"
                                                            target="_blank"
                                                            style={{
                                                                fontFamily:
                                                                    "Roboto",
                                                                color: "#0087ee"
                                                            }}
                                                        >
                                                            {"Payments"}
                                                        </Link>
                                                        {" page"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        style={{
                                                            display:
                                                                window.innerWidth >
                                                                400
                                                                    ? "flex"
                                                                    : "",
                                                            paddingTop: "16px",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        {stripeConnectedAccount &&
                                                            stripeConnectEnabled && (
                                                                <Grid
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: "1rem",
                                                                        maxWidth:
                                                                            window.innerWidth <=
                                                                            400
                                                                                ? "100%"
                                                                                : ""
                                                                    }}
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            width: "100%"
                                                                        }}
                                                                        size="small"
                                                                        label="Stripe Account ID"
                                                                        id="outlined-start-adornment"
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        value={
                                                                            stripeConnectedAccount
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                item
                                                style={{
                                                    width:
                                                        window.innerWidth <= 400
                                                            ? "100%"
                                                            : "50%",
                                                    paddingLeft:
                                                        window.innerWidth > 400
                                                            ? "32px"
                                                            : "",
                                                    paddingTop:
                                                        window.innerWidth > 400
                                                            ? "10px"
                                                            : "20px"
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize:
                                                            window.innerWidth >
                                                            400
                                                                ? "26px"
                                                                : "15px"
                                                    }}
                                                >
                                                    Accept payments online
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize:
                                                            window.innerWidth >
                                                            400
                                                                ? "12px"
                                                                : "11px",
                                                        paddingTop:
                                                            window.innerWidth <=
                                                            400
                                                                ? "7px"
                                                                : ""
                                                    }}
                                                >
                                                    {
                                                        "Connect your Stripe account to start accepting payments online. "
                                                    }
                                                    <Link
                                                        href="https://stripe.com/docs/connect"
                                                        underline="none"
                                                        target="_blank"
                                                        style={{
                                                            fontFamily:
                                                                "Roboto",
                                                            color: "#0087ee"
                                                        }}
                                                    >
                                                        {"Read more."}
                                                    </Link>
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize:
                                                            window.innerWidth >
                                                            400
                                                                ? "12px"
                                                                : "11px",
                                                        paddingTop:
                                                            window.innerWidth <=
                                                            400
                                                                ? "7px"
                                                                : ""
                                                    }}
                                                >
                                                    Dont have an account? Click
                                                    the Connect button to create
                                                    an account for free.
                                                </Typography>
                                            </Grid>
                                        )}

                                        <Grid
                                            item
                                            style={{
                                                alignSelf: "center",
                                                width: "24%",
                                                paddingTop:
                                                    window.innerWidth <= 400
                                                        ? "20px"
                                                        : ""
                                            }}
                                        >
                                            <StripeConnect
                                                companyId={companyId}
                                                stripeAccountId={
                                                    stripeConnectedAccount
                                                }
                                                setShowLoading={setShowLoading}
                                                enabled={showStripeConnectButton()}
                                                connected={
                                                    stripeConnectedAccount &&
                                                    stripeConnectEnabled
                                                }
                                            ></StripeConnect>
                                        </Grid>
                                    </Grid>
                                    {!(
                                        stripeConnectedAccount &&
                                        stripeConnectEnabled
                                    ) && (
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                paddingTop: "8px"
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="16px"
                                                        onChange={(e) =>
                                                            handleEnterStripeDetailsManually(
                                                                e
                                                            )
                                                        }
                                                        defaultChecked={
                                                            stripeKey &&
                                                            secvalue
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={
                                                            stripeKey &&
                                                            secvalue
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                }
                                                style={{
                                                    display: "contents"
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    color: "rgba(0,0,0,0.56)",
                                                    fontSize: "13px"
                                                }}
                                            >
                                                Enter manually
                                            </Typography>
                                        </Grid>
                                    )}

                                    {(showStripeKeyInput ||
                                        (stripeKey && secvalue)) &&
                                        !(
                                            stripeConnectedAccount &&
                                            stripeConnectEnabled
                                        ) && (
                                            <Grid item>
                                                <Paper
                                                    elevation={3}
                                                    style={{
                                                        height:
                                                            window.innerWidth >
                                                            400
                                                                ? "165px"
                                                                : "234px",
                                                        background: "#FAF8F8",
                                                        borderRadius: "11px",
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            window.innerWidth >
                                                            400
                                                                ? "flex-start"
                                                                : "space-between",
                                                        paddingLeft: "20px",
                                                        flexDirection:
                                                            window.innerWidth <=
                                                            400
                                                                ? "column"
                                                                : "",
                                                        paddingRight:
                                                            window.innerWidth <=
                                                            400
                                                                ? "20px"
                                                                : ""
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            width:
                                                                window.innerWidth <=
                                                                400
                                                                    ? "100%"
                                                                    : ""
                                                        }}
                                                    >
                                                        {(!stripeConnectedAccount ||
                                                            !stripeConnectEnabled) &&
                                                            (showStripeKeyInput ||
                                                                (stripeKey &&
                                                                    secvalue)) && (
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        display:
                                                                            window.innerWidth >
                                                                            400
                                                                                ? "flex"
                                                                                : ""
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        style={{
                                                                            maxWidth:
                                                                                "100%",
                                                                            width: "100%"
                                                                        }}
                                                                    >
                                                                        <FormControl
                                                                            margin="normal"
                                                                            required
                                                                        >
                                                                            <TextField
                                                                                style={{
                                                                                    width:
                                                                                        window.innerWidth >
                                                                                        400
                                                                                            ? "340px"
                                                                                            : "100%"
                                                                                }}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                id="publishablestripekey"
                                                                                label="Stripe Publishable Key"
                                                                                className={
                                                                                    classes.textField
                                                                                }
                                                                                value={
                                                                                    stripeKey
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setStripeKey(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        {companyId &&
                                                            (!stripeConnectedAccount ||
                                                                !stripeConnectEnabled) &&
                                                            (showStripeKeyInput ||
                                                                (stripeKey &&
                                                                    secvalue)) && (
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        style={{
                                                                            display:
                                                                                window.innerWidth >
                                                                                400
                                                                                    ? "flex"
                                                                                    : ""
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                5
                                                                            }
                                                                            style={{
                                                                                maxWidth:
                                                                                    "100%",
                                                                                width: "100%"
                                                                            }}
                                                                        >
                                                                            <FormControl
                                                                                margin="normal"
                                                                                required
                                                                                fullWidth
                                                                                className={
                                                                                    classes.formControl
                                                                                }
                                                                            >
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    id="sskey"
                                                                                    label="Stripe Secret Key"
                                                                                    className={
                                                                                        classes.textField
                                                                                    }
                                                                                    value={
                                                                                        secvalue
                                                                                            ? secvalue
                                                                                            : " "
                                                                                    }
                                                                                    style={{
                                                                                        width:
                                                                                            window.innerWidth >
                                                                                            400
                                                                                                ? "340px"
                                                                                                : "100%"
                                                                                    }}
                                                                                    readOnly
                                                                                    InputProps={{
                                                                                        endAdornment:
                                                                                            (
                                                                                                <InputAdornment position="end">
                                                                                                    <FormControl
                                                                                                        margin="normal"
                                                                                                        required
                                                                                                        fullWidth
                                                                                                        className={
                                                                                                            classes.formControl
                                                                                                        }
                                                                                                    >
                                                                                                        <div></div>
                                                                                                        <Button
                                                                                                            component="span"
                                                                                                            m={
                                                                                                                1
                                                                                                            }
                                                                                                            variant="text"
                                                                                                            color="primary"
                                                                                                            onClick={() =>
                                                                                                                setShowSecvalUploadDialog(
                                                                                                                    true
                                                                                                                )
                                                                                                            }
                                                                                                            style={{
                                                                                                                transform:
                                                                                                                    window.innerWidth >
                                                                                                                    400
                                                                                                                        ? "translate(7px,-4px)"
                                                                                                                        : "translate(0px,-4px)"
                                                                                                            }}
                                                                                                        >
                                                                                                            Enter
                                                                                                            Key
                                                                                                        </Button>
                                                                                                    </FormControl>
                                                                                                </InputAdornment>
                                                                                            )
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            transform:
                                                                "translateY(-28px)",
                                                            paddingLeft:
                                                                window.innerWidth >
                                                                400
                                                                    ? "32px"
                                                                    : ""
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: "12px"
                                                            }}
                                                        >
                                                            {
                                                                "You can find your keys on the "
                                                            }
                                                            <Link
                                                                href="https://dashboard.stripe.com/apikeys"
                                                                underline="none"
                                                                target="_blank"
                                                                style={{
                                                                    fontFamily:
                                                                        "Roboto",
                                                                    color: "#0087ee"
                                                                }}
                                                            >
                                                                {
                                                                    "Stripe API Keys"
                                                                }
                                                            </Link>
                                                            {
                                                                " page in the Developers Dashboard."
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>
                        </Paper>
                        {userHasMarketboxAdminRole() && (
                            <FormControlLabel
                                margin="normal"
                                className={classesnew.marginBottomNone}
                                control={
                                    <Switch
                                        checked={collectPayments}
                                        onChange={(event) => {
                                            setCollectPayment(
                                                event.target.checked
                                            );
                                        }}
                                        value="collectPayments"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "primary checkbox"
                                        }}
                                    />
                                }
                                label="Collect payments on-platform"
                            />
                        )}
                    </TabContainer>
                )}
                <Grid item style={{ paddingLeft: "16px" }}>
                    <Grid item xs={5}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveCompany}
                                disabled={state.mode === "View"}
                            >
                                Save Changes
                            </Button>
                        </Box>
                        {setupCompleted && (
                            <Box component="span" m={1}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleCancelCompanyForm}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
