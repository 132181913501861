export const getPendingCompany = /* GraphQL */ `
  query GetPendingCompany($id: ID!) {
    getPendingCompany(id: $id) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const listPendingCompanys = /* GraphQL */ `
  query ListPendingCompanys(
    $filter: ModelPendingCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactname
        emailaddress
        subdomain
        publishableStripeKey
        subscriptionLevel
        estimatedNumOfProviders
        status
        PaymentSettings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        offersForeverAppt
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      offersForeverAppt
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      createdAt
      updatedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    emailaddress
    registered
    firstname
    lastname
    homephone
    workphone
    mobilephone
    phonepref
    addressoneline
    street
    city
    state
    country
    postalcode
    longitude
    latitude
    role
    companyId
    providerId
    active
    deleted
    contactconsent
    contactconsentdatetime
    LoginInfo
    SalesInfo
    PNInfo
    lc_name
    lc_emailaddress
    createdAt
    updatedAt
    company {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      offersForeverAppt
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      createdAt
      updatedAt
    }
    provider {
      id
      firstname
      lastname
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      companyId
      numberofschedules
      active
      deleted
      ratingstarsavg
      numberofratings
      offersVirtualServices
      virtualMeetingUserId
      permalink
      AcceptanceRatios
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        offersForeverAppt
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        createdAt
        updatedAt
      }
      locations {
        nextToken
      }
      servicetypes {
        nextToken
      }
      skills {
        nextToken
      }
    }
  }
}
`;