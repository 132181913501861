import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import { clientPackageByUser } from "../graphql/queries";
import { ordersByCompanyOrderNo } from "../queries/CustomQueries";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";
import { computeTotalOrdersAndLastOrder } from "../utils/Common/ClientFunctions";
import { CSVLink } from "react-csv";
// prettier-ignore
import {
    IconButton, Grid, LinearProgress, CircularProgress, Button, Dialog, DialogContent, DialogTitle, ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const ExportClientList = () => {
    const limit = process.env.REACT_APP_LISTLIMIT;
    const [exportDialog, setExportDialog] = useState();
    const [exportReadyCustomers, setExportReadyCustomers] = useState();
    const [exportDownloadReady, setExportDownloadReady] = useState(false);
    const [prepareExportData, setPrepareExportData] = useState(false);
    const [linearProgressValue, setLinearProgressValue] = useState(0);
    const [csvHeaders, setCsvHeaders] = useState([
        { label: "Status", key: "STATUS" },
        { label: "Created on", key: "CREATEDAT" },
        { label: "First name", key: "FIRSTNAME" },
        { label: "Last name", key: "LASTNAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Phone number", key: "MOBILEPHONE" },
        { label: "Adress", key: "ADDRESS" },
        { label: "Total orders", key: "ORDERCOUNT" },
        { label: "Last order date", key: "LASTORDER" }
    ]);

    async function prepareExportCSVData() {
        setPrepareExportData(true);
        const currentuser = await getUserFromCache();
        let customers = [];
        let nextToken = null;
        do {
            let result = await graphql(
                graphqlOperation(queries.clientByCompany, {
                    companyId: currentuser.company.id,
                    limit,
                    nextToken
                })
            );
            console.log(
                "this is the clients",
                result.data.clientByCompany.items
            );
            setLinearProgressValue(15);
            if (result && result.data && result.data.clientByCompany) {
                customers.push(...result.data.clientByCompany.items);
                if (result.data.clientByCompany.nextToken) {
                    nextToken = result.data.clientByCompany.nextToken;
                    if (linearProgressValue < 60) {
                        setLinearProgressValue(linearProgressValue + 10);
                    }
                } else {
                    nextToken = null;
                    if (linearProgressValue < 60) {
                        setLinearProgressValue(60);
                    }
                }
            } else {
                nextToken = null;
                if (linearProgressValue < 60) {
                    setLinearProgressValue(60);
                }
            }
        } while (nextToken);
        console.log("this is the customers", customers);

        const fetchPackagesForCustomers = async (customers) => {
            // Map over the customers array and fetch packages for each customer
            const customersWithPackages = await Promise.all(
                customers.map(async (customer) => {
                    const filter = {
                        and: [
                            { active: { ne: false } },
                            { status: { eq: "paid" } }
                        ]
                    };
                    try {
                        const listClientPackagesData = await execReadByPK({
                            op: clientPackageByUser,
                            opname: "clientPackageByUser",
                            id: { userId: customer.userId },
                            filter
                        });
                        if (listClientPackagesData.error) {
                            console.log(
                                "error while getting packages",
                                listClientPackagesData.error
                            );
                            return [];
                        }
                        // Only add active packages to the cusomer (packages with remaining credits)
                        customer["activePackages"] =
                            listClientPackagesData.items
                                ? listClientPackagesData.items.filter(
                                      (cp) =>
                                          cp.initialQuantity >
                                              cp.usedQuantity &&
                                          cp.package.companyId ===
                                              currentuser.companyId
                                  )
                                : [];
                    } catch (error) {
                        console.error(
                            `Error fetching packages for customer ${customer.id}:`,
                            error
                        );
                    }
                    // Return the original customer if fetching packages failed
                    return customer;
                })
            );
            return customersWithPackages;
        };

        fetchPackagesForCustomers(customers);

        setLinearProgressValue(70);
        const orders = await execReadByPK({
            opname: "ordersByCompanyOrderNo",
            op: ordersByCompanyOrderNo,
            id: { companyId: currentuser.company.id },
            sortDirection: "DESC",
            limit: limit
        });

        console.log("this is the orders", orders);

        let orderCountAndLastByClientId = computeTotalOrdersAndLastOrder(
            orders.items,
            customers
        );
        setLinearProgressValue(90);

        console.log(
            "this is the ordercount and last order from exportclientlist",
            orderCountAndLastByClientId
        );

        let rows = [];

        if (customers && customers.length > 0) {
            customers.forEach((item) => {
                if (item.activePackages.length > 0) {
                    item.activePackages.forEach((activePackage) => {
                        let activePackageDesc = `${activePackage.servicetype?.name} | ${activePackage.package?.desc}`;
                        if (activePackageDesc.includes("[price]")) {
                            activePackageDesc = activePackageDesc.replace(
                                "[price]",
                                "$0.00"
                            );
                        }
                        let initialQuantity = activePackage.initialQuantity;
                        let usedQuantity = activePackage.usedQuantity;
                        let creditsRemaining = `${initialQuantity - usedQuantity} of ${initialQuantity}`;
                        let purchaseDate = moment(
                            activePackage.createdAt
                        ).format("YYYY-MM-DD HH:mm");

                        rows.push({
                            ...item,
                            activePackage: activePackageDesc,
                            creditsRemaining: creditsRemaining,
                            purchaseDate: purchaseDate,
                            email: item.user?.emailaddress
                                ? item.user.emailaddress
                                : "",
                            address: item.user?.addressoneline
                                ? item.user.addressoneline
                                : "",
                            fn: item.user?.firstname ? item.user.firstname : "",
                            ln: item.user?.lastname ? item.user.lastname : "",
                            mphone: item.user?.mobilephone
                                ? item.user.mobilephone
                                : "",
                            ordercount:
                                orderCountAndLastByClientId[item.id]
                                    ?.orderCount || "0",
                            lastorder: orderCountAndLastByClientId[item.id]
                                ? moment(
                                      orderCountAndLastByClientId[item.id]
                                          .lastOrderDate
                                  ).format("YYYY-MM-DD HH:mm")
                                : "",
                            status:
                                (item.user?.active && item.user?.LoginInfo) ||
                                item.user?.registered === false
                                    ? "ACTIVE"
                                    : !item.user?.active
                                      ? "INACTIVE"
                                      : "PENDING",
                            createdAtValue: item.user?.createdAt
                                ? moment(item.user.createdAt).format(
                                      "YYYY-MM-DD HH:mm"
                                  )
                                : ""
                        });
                    });
                } else {
                    rows.push({
                        ...item,
                        email: item.user?.emailaddress
                            ? item.user.emailaddress
                            : "",
                        address: item.user?.addressoneline
                            ? item.user.addressoneline
                            : "",
                        fn: item.user?.firstname ? item.user.firstname : "",
                        ln: item.user?.lastname ? item.user.lastname : "",
                        mphone: item.user?.mobilephone
                            ? item.user.mobilephone
                            : "",
                        ordercount:
                            orderCountAndLastByClientId[item.id]?.orderCount ||
                            "0",
                        lastorder: orderCountAndLastByClientId[item.id]
                            ? moment(
                                  orderCountAndLastByClientId[item.id]
                                      .lastOrderDate
                              ).format("YYYY-MM-DD HH:mm")
                            : "",
                        status:
                            (item.user?.active && item.user?.LoginInfo) ||
                            item.user?.registered === false
                                ? "ACTIVE"
                                : !item.user?.active
                                  ? "INACTIVE"
                                  : "PENDING",
                        createdAtValue: item.user?.createdAt
                            ? moment(item.user.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                              )
                            : ""
                    });
                }
            });
        }

        const csvData = rows.map((item) => {
            const {
                status: STATUS,
                fn: FIRSTNAME,
                ln: LASTNAME,
                email: EMAIL,
                mphone: MOBILEPHONE,
                address: ADDRESS,
                ordercount: ORDERCOUNT,
                lastorder: LASTORDER,
                createdAtValue: CREATEDAT,
                activePackage: ACTIVEPACKAGE = null,
                purchaseDate: PURCHASEDATE = null,
                creditsRemaining: CREDITSREMAINING = null
            } = item;

            let newItem = {
                STATUS,
                FIRSTNAME,
                LASTNAME,
                EMAIL,
                MOBILEPHONE,
                ADDRESS,
                ORDERCOUNT,
                LASTORDER,
                CREATEDAT
            };

            if (ACTIVEPACKAGE) {
                newItem = {
                    ...newItem,
                    ACTIVEPACKAGE,
                    PURCHASEDATE,
                    CREDITSREMAINING
                };

                setCsvHeaders([
                    ...csvHeaders,
                    ...[
                        { label: "Active package", key: "ACTIVEPACKAGE" },
                        { label: "Purchase date", key: "PURCHASEDATE" },
                        {
                            label: "Credits remaining",
                            key: "CREDITSREMAINING"
                        }
                    ]
                ]);
            }

            return newItem;
        });

        // Sort the exportData array
        csvData.sort((a, b) => {
            // If client_firstname is the same, sort by client_lastname (A->Z)
            const lastNameComparison = a.LASTNAME.localeCompare(b.LASTNAME);
            if (lastNameComparison !== 0) {
                return lastNameComparison;
            }

            // Sort by client_firstname (A->Z)
            const firstNameComparison = a.FIRSTNAME.localeCompare(b.FIRSTNAME);
            if (firstNameComparison !== 0) {
                return firstNameComparison;
            }

            // If lastname is the same, sort by Created On
            const t1 = new Date(a.CREATEDAT);
            const t2 = new Date(b.CREATEDAT);
            return t2 - t1;
        });

        if (csvData && csvData.length) {
            setExportReadyCustomers(csvData);
            setExportDownloadReady(true);
            setLinearProgressValue(100);
        } else {
            setExportReadyCustomers(null);
        }
    }

    function handleExportClick() {
        setExportDialog(true);
    }

    return (
        <>
            <Dialog
                open={exportDialog}
                fullWidth={true}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        Export clients
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            console.log("close button was clicked");
                            setExportDialog(false);
                            setPrepareExportData(false);
                            setExportDownloadReady(false);
                            setExportReadyCustomers();
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ padding: 24 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "16px" }}
                        >
                            {"Please confirm the following export criteria: "}
                        </Typography>
                        <Typography variant="body1">{"All clients"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon />}
                            onClick={async () => {
                                console.log("prepare export was clicked");
                                await prepareExportCSVData();
                            }}
                            disabled={prepareExportData}
                            style={{ marginTop: "24px" }}
                        >
                            Prepare Export
                        </Button>
                    </Grid>
                    {prepareExportData && (
                        <Grid
                            container
                            style={{
                                flexDirection: "column",
                                marginTop: "24px",
                                gap: "16px"
                            }}
                        >
                            {!exportDownloadReady && (
                                <CircularProgress
                                    color="primary"
                                    size={24}
                                    style={{ alignSelf: "center" }}
                                />
                            )}
                            <LinearProgress
                                color="primary"
                                variant="buffer"
                                valueBuffer={linearProgressValue}
                                value={linearProgressValue}
                            />
                        </Grid>
                    )}
                    {exportDownloadReady && (
                        <>
                            <br></br>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {`Please click the download button below to download all client details.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CSVLink
                                    data={exportReadyCustomers}
                                    headers={csvHeaders}
                                    filename={`Clients_${moment(
                                        Date.now()
                                    ).format("YYYY-MM-DD")}.csv`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetAppIcon />}
                                    >
                                        Download
                                    </Button>
                                </CSVLink>
                            </Grid>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <ListItem button onClick={() => handleExportClick()}>
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Client list" />
            </ListItem>
        </>
    );
};

export default ExportClientList;
