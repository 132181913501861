import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import SearchIcon from "@mui/icons-material/Search";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import {
    Paper, Grid, TextField,
    InputAdornment,
    Button,
    Snackbar,
    Typography
} from '@mui/material';
import * as mutations from "../graphql/mutations";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { RegionTable } from "../components/RegionTable";
import { auditPriceDelete } from "../modules/Audit";
import { regionalPricingByCompanyServiceType } from "../queries/CustomQueries";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import isFeatureGated from "../modules/FeatureGatingModule";

export const RegionForm = (props) => {
    const navigate = useNavigate();
    
    const { actions } = useContext(StoreContext);
    const classes = usePromotionsStyles();

    //Rows for table
    const [rows, setRows] = useState([]);

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    //DeleteDialog
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    //Selected rows
    const [selected, setSelected] = useState([]);

    //Search Values
    const [searchTerm, setSearchTerm] = useState("");

    const authuser = getUserFromCache();

    let filter = {
        and: [{ active: { ne: false } }, { deleted: { ne: true } }]
    };

    useEffect(() => {
        _getRegionList(filter);
    }, []);
    //Region Operations

    //Get
    const _getRegionList = async (newFilter) => {
        let regionListData = await execReadByPK({
            opname: "regionalPricingByCompanyServiceType",
            op: regionalPricingByCompanyServiceType,
            id: { companyId: authuser.company.id },
            filter: newFilter,
            sortDirection: "DESC"
        });

        // let regionListData = await graphql(
        //     graphqlOperation(queries.listRegionalPricings, {
        //         filter: !!newFilter ? newFilter : filter
        //     })
        // );
        console.log("regionList", regionListData);
        if (regionListData.items) {
            // sort data
            regionListData.items.sort((p1, p2) => {
                const psq1 = p1.pricingtype.toUpperCase();
                const psq2 = p2.pricingtype.toUpperCase();
                if (psq1 === psq2) {
                    return p1.quantity - p2.quantity;
                }
                if (psq1 < psq2) return -1;
                if (psq1 > psq2) return 1;
                return 0;
            });

            setRows(regionListData.items);
        }
    };
    //Delete
    const _deleteRegion = async (id) => {
        const input = {
            id: id
        };
        console.log("in delete region");
        const deleteRegionData = await graphql(
            graphqlOperation(mutations.deleteRegionalPricing, { input })
        );
        await auditPriceDelete(
            authuser,
            deleteRegionData.data.deleteRegionalPricing
        );
        if (deleteRegionData.errors && deleteRegionData.errors.length > 0) {
            setSnackMsg("Unable to delete regional pricing information.");
            setMsgOpen(true);
        } else {
            _getRegionList();
            setDeleteDialog(false);
            setSelected([]);
        }
    };

    //Delete Dialog
    const _handleDeleteDialogDecline = () => {
        setDeleteDialog(false);
    };

    const _handleDeleteDialogAccept = () => {
        // proceed to delete regions
        console.log("selected", selected);
        selected.map((item) => {
            _deleteRegion(item);
        });

        setSnackMsg(
            "The selected regional pricing(s) have been successfully deleted."
        );
        setMsgOpen(true);
        setDeleteDialog(false);
        // clear table... cannot do refresh right away or we will get stale data ()
        setRows([]);
        setSelected([]);
    };

    //Search Values
    const _handleSearchTermChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        let newFilter = {
            ...filter,
            or: [{ countryName: { contains: value } }]
        };
        console.log("_handleSearchTermChange", newFilter);
        if (searchTerm != "") _getRegionList(newFilter);
        else _getRegionList();
    };

    //Add Region
    const _handleRegionAdd = () => {
        actions.setMode("Add");
        navigate("create");
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="regional pricing information"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <TextField
                            id="search"
                            name="name"
                            label="Search by country"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={searchTerm}
                            onChange={_handleSearchTermChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classes.marginBottom}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleRegionAdd}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <RegionTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                listRegions={rows}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
