import * as Sentry from "@sentry/react";
import { format } from "date-fns-tz";
import { postApi, getJsonApi } from "./AmplifyServices";
const getTimezoneIdByGeoLoc = async ({ lat, lng }) => {
    try {
        const stamp = Math.floor(Date.now() / 1000);
        const tzidResp = await getJsonApi("mapdirection", "/tzid", {
            queryParams: {
                lat,
                lng,
                stamp
            }
        });
        if (tzidResp && tzidResp.success) return tzidResp.tzid;
        else return Intl.DateTimeFormat().resolvedOptions().timeZone; //return browser tz
    } catch (err) {
        Sentry.captureException(err);
        return Intl.DateTimeFormat().resolvedOptions().timeZone; //return browser tz
    }
};

async function getBookingDateDescription(apptdate, timeZone, durationMins) {
    apptdate = new Date(apptdate); //work with the copy
    // if date-fns-tz returns tz abbr as GMT+ or GMT-, that means it does not have
    //  complete locale info so call server side api to do the formatting using moment-timezone
    // return
    // object
    //      dtstamp_str:  '2021-01-14T10:11:04-08:00'
    //      dt_disp:  '2021-01-14'
    //      dt_full_disp: "MMM DD, YYYY h:mm A z" (moment formatting e.g.) 'Thu, Jan 14, 2021 10:11 AM PST'
    //      tm_st_disp: "h:mm A" e.g. '10:26 AM'
    //      tm_et_disp: "h:mm A" e.g. '10:26 AM'
    //      tz_abbr_disp: "z" e.g. EST
    //      durationMins: e.g. 30
    let tzAbbrv = format(apptdate, "z", { timeZone });
    console.log("tzAbbrv", tzAbbrv);
    if (tzAbbrv && (tzAbbrv.includes("GMT+") || tzAbbrv.includes("GMT-"))) {
        const result = await postApi("mapdirection", "/timezone", {
            body: {
                action: "formatInLocationTz",
                dateInfo: {
                    datetime: getDateParts(apptdate),
                    tz: timeZone,
                    durationMins
                }
            }
        });
        if (result && result.success && result.dateInfo) {
            console.log("formatInLocationTz", JSON.stringify(result));
            return result.dateInfo;
        }
    } else {
        let tz = { timeZone };

        return {
            dtstamp_str: format(apptdate, "yyyy-MM-dd'T'HH:mm:ssXXX", tz),
            tz_abbr_disp: format(apptdate, "z", tz),
            tz_gmtoff: `GMT${format(apptdate, "XXX", tz)}`,
            dt_disp: format(apptdate, "yyyy-MM-dd", tz),
            dt_long_disp: format(apptdate, "EEEE, MMMM d, yyyy", tz),
            dt_full_disp: format(apptdate, "EEE, MMM d, yyyy h:mm a zzz", tz),
            en_slot_disp: `${format(
                apptdate,
                "EEE, MMM d 'at' h:mm a zzz",
                tz
            )}`,
            tm_st_disp: format(apptdate, "h:mm a", tz),
            tm_et_disp: format(
                apptdate.setMinutes(apptdate.getMinutes() + durationMins),
                "h:mm a",
                tz
            ),
            durationMins: durationMins,
            tz: timeZone
        };
    }
}

function getDateParts(d) {
    return {
        year: d.getFullYear(),
        month: d.getMonth(),
        date: d.getDate(),
        hours: d.getHours(),
        minutes: d.getMinutes()
    };
}

function getFullDateDisplayWithGMTOffset(dateInfo) {
    let gmtoff = dateInfo.tz_gmtoff ? `(${dateInfo.tz_gmtoff})` : "";
    return `${dateInfo.dt_full_disp} ${gmtoff}`;
}

function getSlotDisplayWithGMTOffset(dateInfo) {
    let gmtoff = dateInfo.tz_gmtoff ? `(${dateInfo.tz_gmtoff})` : "";
    return `${dateInfo.en_slot_disp} ${gmtoff}`;
}

function getEndTimeDisplayWithGMTOffset(dateInfo) {
    let gmtoff = dateInfo.tz_gmtoff ? `(${dateInfo.tz_gmtoff})` : "";
    let tz_abbr = dateInfo.tz_abbr_disp ? dateInfo.tz_abbr_disp : "";
    return `${dateInfo.tm_et_disp} ${tz_abbr} ${gmtoff}`;
}

export {
    getTimezoneIdByGeoLoc,
    getFullDateDisplayWithGMTOffset,
    getEndTimeDisplayWithGMTOffset,
    getSlotDisplayWithGMTOffset,
    getBookingDateDescription
};
