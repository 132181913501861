import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import classes from "../../styles/CheckoutLandingPage.module.css";
import "../../CheckoutApp.css";

import { useWindowSize } from "react-use";
import ActivateAccountForm from "../forms/ActivateAccountForm";

function ActivateAccountApp(props) {
    const [user, setUser] = useState();
    const [renderActivateForm, setRenderActivateForm] = useState(false);
    const [userInputState, setUserInputState] = useState({});
    const [guestUser, setGuestUser] = useState();
    const [userAttributes, setAttributes] = useState({});
    const query = new URLSearchParams(window.location.search);
    const [companyData, setCompanyData] = useState({});
    const companyDummyData = {
        companyId: "",
        emailAddress: "omar+hasio@gomarketbox.com",
        cleanPhone: "+14168360759",
        phoneNumber: "+14168360759",
        lastNameInput: "tahir",
        subdomainEnding: {
            label: ".gomarketbox.com",
            value: ".gomarketbox.com"
        },
        desiredSubdomain: "satcomp2",
        companyNameInput: "satcomp2",
        firstNameInput: "omar"
    };

    let { width } = useWindowSize();

    return (
        <Fragment>
            <Navbar width={width} />
            <main className={classes.main_activate}>
                <ActivateAccountForm state={companyData} />
            </main>
            <Footer />
        </Fragment>
    );
}

export default ActivateAccountApp;
