import React from "react";
import PersonLookup from "./PersonLookup";
import Snackbar from "@mui/material/Snackbar";
import {
    searchByName,
    userByCompany,
    searchByLastName
} from "../queries/ListBookingsQueries";
import { execReadBySortkey } from "../modules/DBService";
export const UserLookup = (props) => {
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    function isEmail(input) {
        return input && input.includes("@");
    }
    async function searchUsers(input) {
        let users;
        let clientnameinput = input.input;
        if (clientnameinput) {
            if (isEmail(clientnameinput)) {
                users = await execReadBySortkey({
                    opname: "userByCompany",
                    op: userByCompany,
                    id: {
                        companyId: props.companyId
                    },
                    skey: {
                        roleEmailaddress: {
                            beginsWith: {
                                role: "CLIENT",
                                emailaddress: clientnameinput
                            }
                        }
                    }
                });
                if (
                    !users ||
                    users.error ||
                    (users.items && users.items.length === 0)
                ) {
                    //search other roles
                    users = await execReadBySortkey({
                        opname: "userByCompany",
                        op: userByCompany,
                        id: {
                            companyId: props.companyId
                        },
                        skey: {
                            roleEmailaddress: {
                                beginsWith: {
                                    role: "COMPANY_ADMIN",
                                    emailaddress: clientnameinput
                                }
                            }
                        }
                    });
                }
                if (
                    !users ||
                    users.error ||
                    (users.items && users.items.length === 0)
                ) {
                    //search other roles
                    users = await execReadBySortkey({
                        opname: "userByCompany",
                        op: userByCompany,
                        id: {
                            companyId: props.companyId
                        },
                        skey: {
                            roleEmailaddress: {
                                beginsWith: {
                                    role: "COMPANY_ADMIN_PROVIDER",
                                    emailaddress: clientnameinput
                                }
                            }
                        }
                    });
                }
                if (
                    users &&
                    !users.error &&
                    users.items &&
                    users.items.length
                ) {
                    users.items.map((u) => {
                        u.name = `${u.firstname ? u.firstname : ""} ${
                            u.lastname ? u.lastname : ""
                        }`;
                    });
                    return users.items;
                } else {
                    setSnackMsg("Client is not found.");
                    setMsgOpen(true);
                    return [];
                }
            } else {
                let usersByFn = await execReadBySortkey({
                    opname: "searchByName",
                    op: searchByName,
                    id: {
                        companyId: props.companyId
                    },
                    skey: {
                        lc_name: {
                            beginsWith: clientnameinput.toLowerCase()
                        }
                    },
                    filter: {
                        role: { ne: "PROVIDER" } //BUGS-865
                    }
                });
                let usersByln = await execReadBySortkey({
                    opname: "searchByLastName",
                    op: searchByLastName,
                    id: {
                        companyId: props.companyId
                    },
                    skey: {
                        lc_ln: {
                            beginsWith: clientnameinput.toLowerCase()
                        }
                    },
                    filter: {
                        role: { ne: "PROVIDER" } //BUGS-865
                    }
                });

                let userSet;
                if (
                    usersByFn &&
                    !usersByFn.error &&
                    usersByFn.items &&
                    usersByFn.items.length
                )
                    userSet = new Set(
                        usersByFn.items.map((u) => JSON.stringify(u))
                    );
                if (userSet) {
                    if (
                        usersByln &&
                        !usersByln.error &&
                        usersByln.items &&
                        usersByln.items.length
                    )
                        usersByln.items.forEach((u) => {
                            let uJson = JSON.stringify(u);
                            if (!userSet.has(uJson)) {
                                userSet.add(uJson);
                            }
                        });
                } else {
                    if (
                        usersByln &&
                        !usersByln.error &&
                        usersByln.items &&
                        usersByln.items.length
                    )
                        userSet = new Set(
                            usersByln.items.map((u) => JSON.stringify(u))
                        );
                }
                if (userSet) {
                    let jsonUsers = [...userSet];
                    users = jsonUsers.map((ujson) => JSON.parse(ujson));
                }
                if (users && users.length) {
                    users.map((u) => {
                        u.name = `${u.firstname ? u.firstname : ""} ${
                            u.lastname ? u.lastname : ""
                        }`;
                    });
                    return users;
                } else {
                    setSnackMsg("Client is not found.");
                    setMsgOpen(true);
                    return [];
                }
            }
        }
    }

    function setSelectedValue(client) {
        props.setSelectedUser(client);
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <PersonLookup
                headTextFieldName="name"
                subTextFieldName="emailaddress"
                searchService={searchUsers}
                setSelectedValue={setSelectedValue}
                placeholder="Search Client"
            />
        </>
    );
};
