import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NoPromoCodes from "./NoPromoCodes";
import PromoCodesTable from "./PromoCodesTable";
import AddPromoCodeModal from "./AddPromoCodeModal";
import { usePromosList } from "./usePromoList";
import { servicesOfCompany } from "../../queries/AdhocBookingQueries";
import {
    graphql,
    graphqlOperation,
    postApi,
    putApi
} from "../../modules/AmplifyServices";
import { packageByCompany } from "../../queries/CustomQueries";
import { execReadByPK } from "../../modules/DBService";
import { delApi } from "../../modules/AmplifyServices";
function PromoCodes({ company, user }) {
    const [openAddPromoModal, setOpenPromoModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    // const [promoCodes, setPromoCodes] = useState([]);
    const [promoCodeId, setPromoCodeId] = useState("");
    const {
        promoList: promoCodes,
        uniquePromoCodes,
        setRefresh,
        isLoading
    } = usePromosList([], company.id);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [pkgs, setPackages] = useState([]);
    useEffect(() => {
        const getServiceTypes = async () => {
            const filter = {
                and: [
                    { active: { ne: false } },
                    { deleted: { ne: true } },
                    { isVisible: { ne: false } },
                    { behavior: { ne: "MANUAL" } }
                ]
            };
            const response = await graphql(
                graphqlOperation(servicesOfCompany, {
                    companyId: company.id,
                    filter,
                    limit: process.env.REACT_APP_LISTLIMIT
                })
            );
            const result = await response.data.serviceTypeByCompany.items;
            //   console.log("getServiceTypes", JSON.stringify(result));
            setServiceTypes(sortByOrdinal(result));
        };
        getServiceTypes();
    }, []);

    useEffect(() => {
        const getPackages = async () => {
            let listPackagesData = await execReadByPK({
                opname: "packageByCompany",
                op: packageByCompany,
                id: { companyId: company.id },
                filter: {
                    and: [{ active: { ne: false } }, { deleted: { ne: true } }]
                },
                sortDirection: "DESC"
            });

            // sort packages
            listPackagesData.items.sort((p1, p2) => {
                const psq1 = p1.servicetype.name.toUpperCase();
                const psq2 = p2.servicetype.name.toUpperCase();
                if (psq1 === psq2) {
                    return p1.quantity - p2.quantity;
                }
                if (psq1 < psq2) return -1;
                if (psq1 > psq2) return 1;
                return 0;
            });
            setPackages(listPackagesData.items);
        };
        getPackages();
    }, []);

    function sortByOrdinal(services) {
        if (services) {
            return services.sort((s1, s2) => {
                if (s1.category.ordinal > s2.category.ordinal) return 1;
                if (s1.category.ordinal < s2.category.ordinal) return -1;
                if (s1.category.id === s2.category.id) {
                    if (s1.ordinal > s2.ordinal) return 1;
                    if (s1.ordinal < s2.ordinal) return -1;
                    return 0;
                }
                return 0;
            });
        }
        return [];
    }
    // useEffect(() => {
    //     setPromoCodes(promoCodesData);
    // }, []);

    const openPromoModal = () => {
        setEditMode(false);
        setOpenPromoModal(true);
    };
    const closeAddPromoModal = () => {
        setEditMode(false);
        setOpenPromoModal(false);
    };

    const editPromoCode = (id) => {
        setPromoCodeId(id);
        setEditMode(true);

        setOpenPromoModal(true);
    };

    const deletePromoHandler = (id) => {
        console.log(id);
        let newData = promoCodes.filter((item) => item.id !== id);
        console.log(newData);
        // setPromoCodes(newData);
        setPromoCodeId("");
        //setOpenPromoModal(false);
    };

    const savePromoHandler = async (promoData) => {
        try {
            console.log("promoData", promoData);
            let result;
            if (promoData.id) {
                result = await putApi("promoapi", "/promo", {
                    body: {
                        promoData,
                        actionUser: user
                    }
                });
                console.log("promoapi result", result);
            } else {
                result = await postApi("promoapi", "/promo", {
                    body: {
                        promoData,
                        actionUser: user
                    }
                });
                console.log("promoapi result", result);
            }
            if (result && result.success) {
                setOpenPromoModal(false);
                setPromoCodeId("");
                setRefresh(true);
            } else {
                //TODO
                setOpenPromoModal(false);
                setPromoCodeId("");
            }
        } catch (e) {
            console.log("promoapi error", e);
        }
    };

    const terminatePromoHandler = async (promoData) => {
        //this is where the API calls will be made
        console.log("Reading promoData from PromoCodes.js: ", promoData);
        try {
            console.log("promoData", promoData);
            let result;

            result = await delApi("promoapi", "/promo", {
                body: {
                    promoData,
                    actionUser: user
                }
            });
            console.log("promoapi result", result);
            if (result && result.success) setRefresh(true);
        } catch (e) {
            console.log("promoapi error", e);
        }
    };

    const newPromoCodeHandler = () => {
        setEditMode(false);
        setPromoCodeId("");
        setOpenPromoModal(true);
    };
    console.log("Promo List", promoCodes);
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "1rem"
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                {!(promoCodes && promoCodes.length === 0) && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setEditMode(false);
                            setPromoCodeId("");
                            setOpenPromoModal(true);
                        }}
                    >
                        New Promo Code
                    </Button>
                )}
                <AddPromoCodeModal
                    editMode={editMode}
                    promoCodeId={promoCodeId}
                    openAddPromoModal={openAddPromoModal}
                    closeAddPromoModal={closeAddPromoModal}
                    deletePromoHandler={deletePromoHandler}
                    savePromoHandler={savePromoHandler}
                    terminatePromoHandler={terminatePromoHandler}
                    company={company}
                    uniquePromoCodes={uniquePromoCodes}
                    serviceTypes={serviceTypes}
                    pkgs={pkgs}
                />
            </div>

            {!isLoading && promoCodes && promoCodes.length === 0 ? (
                <NoPromoCodes newPromoCodeHandler={newPromoCodeHandler} />
            ) : (
                <PromoCodesTable
                    isLoading={isLoading}
                    promoCodesData={promoCodes}
                    editPromoCode={editPromoCode}
                />
            )}
        </div>
    );
}

export default PromoCodes;
