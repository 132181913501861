import { Typography } from "@mui/material";
import React from "react";
export const UserAvailability = ({
    activeSchedule,
    activeSchedule: {
        startTime = "",
        endTime = "",
        startDate = "",
        endDate = "",
        weekDays,
        location
    } = {},
    availability
}) => (
    <>
        {activeSchedule !== undefined ? (
            <>
                <Typography>{`${startDate} – ${endDate} | ${startTime} – ${endTime}`}</Typography>{" "}
                <Typography>{` ${weekDays}`}</Typography>
                <Typography>{` ${location}`}</Typography>
            </>
        ) : (
            <span>
                {availability?.length > 0
                    ? `${availability.length} Schedule${availability?.length > 1 ? "s" : ""}`
                    : ""}
                {}
            </span>
        )}
    </>
);
