import React from "react";
import { execReadBySortkey } from "./DBService";
import moment from "moment";

const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//Returns a list of all booking date/time information for all bookings that
//need to be made according to the initialDate, untilDate and ruleComponents
//For the Reschedule scenerio, we only need to consider the number of
async function prepareBookingsDateTimeListForReschedule(
    apptsByOrder,
    originalBookingData,
    initialDateTime,
    originalRruleUntilDate,
    untilDate,
    ruleComponents,
    rruleChanged,
    xoccurChanged
) {
    const bookings = [];
    const [initialDate, initialFullTime] = initialDateTime.split("T");
    const [initialTime, timezoneOffset] = initialFullTime.split("-");
    let currentDate = initialDate;
    if (
        ruleComponents.frequency === "MONTHLY" &&
        ruleComponents.days === "WEEK" &&
        isLastOccurence(currentDate)
    ) {
        ruleComponents.lastOccurence = true;
    }

    if (!xoccurChanged) {
        ruleComponents.xoccur = apptsByOrder.length;
    }

    let offsetUntilDate = await getCheckUntilDate(
        initialDateTime,
        ruleComponents
    );

    if (rruleChanged && originalRruleUntilDate !== untilDate) {
        offsetUntilDate =
            untilDate < offsetUntilDate ? untilDate : offsetUntilDate;
    }

    //Prepares the 'bookings' array with a 'bookingObject's for each booking that will take place from the currentDate to the untilDate
    while (currentDate <= offsetUntilDate) {
        let currDate = new Date(`${currentDate}T${initialTime}`);

        const bookingObject = {
            date: currDate,
            dt_disp: moment(currDate).format("YYYY-MM-DD")
        };

        bookings.push(bookingObject);

        //If there is a set number of x occurences, break from the while loop and return the bookings once the x number of occurences is reached
        if (ruleComponents.xoccur && bookings.length == ruleComponents.xoccur) {
            return bookings;
        }

        // Update currentDate for the next iteration based on the rule components
        currentDate = calculateNextDate(currentDate, ruleComponents);
    }

    return bookings;
}

//Parses the rrule and returns the rrule has an object with its respective attributes
function parseRRule(rrule) {
    const components = rrule.split(";");
    const parsedRule = {
        frequency: "",
        interval: 1,
        days: [],
        until: null,
        xoccur: null
    };

    components.forEach((component) => {
        const [key, value] = component.split("=");
        switch (key) {
            case "FREQ":
                parsedRule.frequency = value;
                break;
            case "INTERVAL":
                parsedRule.interval = parseInt(value, 10);
                break;
            case "BYDAY":
                if (value) {
                    if (value === "NUMBER" || value === "WEEK") {
                        parsedRule.days = value;
                        break;
                    }
                    const daysArray = value.split(",");
                    daysArray.forEach((day) => {
                        parsedRule.days.push(convertDayToNumber(day));
                    });
                    //sort parsedRule.days in ascending order
                    parsedRule.days.sort((a, b) => a - b);
                }
                break;
            case "UNTIL":
                parsedRule.until = value;
                break;
            case "XOCCUR":
                parsedRule.xoccur = value;
                break;
            default:
                // Handle other RRULE components if necessary
                break;
        }
    });

    return parsedRule;
}

/**
 * Determines the future date ('until' date) that we generate and check valid bookings for in the front end
 * We need to because we cannot check the validity for every booking that is part of a recurring booking set
 * Instead, we check a specifed distance in the future to check until
 * @param {Date} startDate - The start date
 * @param {Object} ruleComponents - The components of the rrule
 * @returns {string} - The cutoff date until which valid bookings will be considered, YYYY-MM-DD format.
 */
async function getCheckUntilDate(startDate, ruleComponents) {
    // Create a moment object based on the start date
    let untilDate = moment(startDate);

    /* console.log(
        "omar this is the startDate and ruleComponents",
        startDate,
        ruleComponents,
        untilDate.format("YYYY-MM-DD")
    ); */

    if (ruleComponents.frequency === "WEEKLY") {
        // Add 2 months to the moment object
        untilDate.add(2, "months");

        return untilDate.format("YYYY-MM-DD");
    } else if (ruleComponents.frequency === "MONTHLY") {
        // Add 6 months to the moment object
        untilDate.add(6, "months");

        return untilDate.format("YYYY-MM-DD");
    } else if (ruleComponents.frequency === "YEARLY") {
        // Add 2 months to the moment object
        untilDate.add(2, "months");

        return untilDate.format("YYYY-MM-DD");
    }
}

function convertDayToNumber(day) {
    const dayMappings = {
        MO: 0,
        TU: 1,
        WE: 2,
        TH: 3,
        FR: 4,
        SA: 5,
        SU: 6
    };
    return dayMappings[day];
}

//returns true , if the date is the 5th and last occurence in a month
function isLastOccurence(dateInput) {
    const date = new Date(dateInput);
    const currentOccurrence = Math.floor((date.getDate() - 1) / 7) + 1;
    return currentOccurrence === 5;
}

function calculateNextDate(currentDate, ruleComponents) {
    const { frequency, interval, days, until } = ruleComponents;
    let nextDate = moment(currentDate);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    // Then convert it to MB calendar week setting (0 = Monday, 1 = Tuesday, ..., 6 = Sunday)
    let currentDay = nextDate.day();
    currentDay = (currentDay + 6) % 7;

    if (frequency === "WEEKLY") {
        let daysToAdd;
        if (days.length > 1) {
            let closestValidDay = days.find((day) => day > currentDay);
            if (!closestValidDay) {
                closestValidDay = days[0];
                daysToAdd =
                    7 - (currentDay - closestValidDay) + (interval - 1) * 7;
            } else {
                daysToAdd = closestValidDay - currentDay;
            }
        } else {
            daysToAdd = 7 * interval;
        }

        nextDate.add(daysToAdd, "days");
    } else if (frequency === "MONTHLY") {
        if (days === "WEEK") {
            const currentOccurrence = Math.floor((nextDate.date() - 1) / 7) + 1;

            nextDate.add(interval, "months");

            if (ruleComponents.lastOccurence) {
                nextDate.add(1, "months").date(0);

                while ((nextDate.day() + 6) % 7 !== currentDay) {
                    nextDate.subtract(1, "days");
                }
                return nextDate.format("YYYY-MM-DD");
            } else if (currentOccurrence > 1) {
                nextDate.date(7 * (currentOccurrence - 1));
            } else {
                nextDate.date(1);
            }

            while (
                (nextDate.day() + 6) % 7 !== currentDay ||
                Math.floor((nextDate.date() - 1) / 7) + 1 !== currentOccurrence
            ) {
                nextDate.add(1, "days");
            }
        } else if (days === "NUMBER") {
            let tempNextDate;
            const dayOfMonth = nextDate.date();
            let nextMonthDay;
            let count = 1;

            while (dayOfMonth !== nextMonthDay) {
                tempNextDate = nextDate.clone().add(interval * count, "months");
                nextMonthDay = tempNextDate.date();

                if (dayOfMonth == nextMonthDay) {
                    nextDate = tempNextDate;
                    break;
                }
                count++;
            }
        }
    } else if (frequency === "YEARLY") {
        nextDate.add(interval, "years");
    }
    return nextDate.format("YYYY-MM-DD");
}

const generateRRULE = (
    chronologicalSelector,
    numberSelector,
    selectedDays,
    endDate,
    xoccur
) => {
    const interval = numberSelector; // Set the interval based on numberSelector
    const dayAbbreviations = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
    let days;

    if (chronologicalSelector == "weeks") {
        // Map the selected day numbers to day abbreviations
        const selectedDayAbbreviations = selectedDays
            .sort((a, b) => a - b)
            .map((dayNumber) => dayAbbreviations[dayNumber]);
        days = selectedDayAbbreviations.join(",");
    } else if (chronologicalSelector == "months") {
        days = selectedDays;
    } else if (chronologicalSelector == "years") {
        days = selectedDays;
    }

    let formattedDate;
    if (endDate) {
        // Parse the date using Moment.js
        let dateObject = moment(endDate);

        // Extract components
        let year = dateObject.format("YYYY");
        let month = dateObject.format("MM");
        let day = dateObject.format("DD");

        // Create "YYYY-MM-DD" format
        formattedDate = `${year}-${month}-${day}`;
    }
    // Set the until date, and occurrences
    const endDateStr = xoccur
        ? `;UNTIL=2099-12-31;XOCCUR=${xoccur}`
        : endDate
          ? `;UNTIL=${formattedDate}`
          : "";

    // Set the frequency based on chronologicalSelector
    const freq =
        chronologicalSelector === "weeks"
            ? "WEEKLY"
            : chronologicalSelector === "months"
              ? "MONTHLY"
              : "YEARLY";

    return `FREQ=${freq};INTERVAL=${interval};BYDAY=${days}${endDateStr}`;
};

function daysArrayToString(daysArray) {
    // Sort the array to ensure it is in the correct order
    daysArray.sort((a, b) => a - b);

    let result = [];
    let start = null;
    let end = null;

    for (let i = 0; i < daysArray.length; i++) {
        if (start === null) {
            start = daysArray[i];
        }

        if (end === null || daysArray[i] === end + 1) {
            end = daysArray[i];
        } else {
            if (start === end) {
                result.push(DAY_NAMES[start]);
            } else {
                result.push(`${DAY_NAMES[start]} \u2014 ${DAY_NAMES[end]}`);
            }
            start = daysArray[i];
            end = daysArray[i];
        }
    }

    // Handle the last range or single day
    if (start !== null) {
        if (start === end) {
            result.push(DAY_NAMES[start]);
        } else {
            result.push(`${DAY_NAMES[start]} \u2014 ${DAY_NAMES[end]}`);
        }
    }

    return result.join(", ");
}

export {
    prepareBookingsDateTimeListForReschedule,
    calculateNextDate,
    parseRRule,
    generateRRULE,
    daysArrayToString
};
