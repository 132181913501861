import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDropzoneArea from "./FileDropzoneArea";
import imageCompression from "browser-image-compression";
import uuid from "uuid/v4";
import { uploadData, getUrl } from "aws-amplify/storage";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { updateProvider } from "../graphql/mutations";
import { useAtom } from "jotai";
import { selectedUserAtom, userPageSnackbarAtom } from "../atoms/usersTable";
import { updateUserInAllUsersAtom } from "../atoms/atoms";

const UploadPhotoModal = ({ open, onClose, setS3Link }) => {
    const [selectedUser] = useAtom(selectedUserAtom);
    const [, updateUserInAllUsers] = useAtom(updateUserInAllUsersAtom);
    const [, setUserPageSnackbar] = useAtom(userPageSnackbarAtom);
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const handleClose = () => {
        onClose();
    };

    const handleSavePhoto = async () => {
        if (!file || file.length === 0) {
            setUserPageSnackbar({
                open: true,
                message: `Please upload a photo before saving`,
                severity: "error"
            });
            return;
        }

        setIsUploading(true);

        try {
            let image = file[0];
            const { size, name: fileName, type: mimeType } = image;

            if (size > 100000) {
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 800
                };
                //Compress the image until it's under the size limit
                while (image.size > 100000) {
                    image = await imageCompression(image, options);
                }
            }

            const key = `${uuid()}-${fileName}`;
            const uploadResponseResult = await uploadData({
                key,
                data: image,
                options: {
                    contentType: mimeType
                }
            }).result;
            const s3key = uploadResponseResult?.key;
            if (!s3key) {
                throw new Error("Failed to upload photo");
            }

            const input = {
                id: selectedUser.provider.id,
                pictures3key: s3key
            };
            await graphql(graphqlOperation(updateProvider, { input }));

            updateUserInAllUsers({
                id: selectedUser.id,
                provider: {
                    ...selectedUser.provider,
                    pictures3key: s3key
                }
            });
            const s3UrlData = await getUrl({
                key: s3key
            });
            setS3Link(s3UrlData?.url);
            setUserPageSnackbar({
                open: true,
                message: `Photo has been successfully uploaded`,
                severity: "success"
            });
            handleClose();
        } catch (error) {
            console.error("Error uploading photo:", error);
            setUserPageSnackbar({
                open: true,
                message: `Failed to upload photo: ${error.message}`,
                severity: "error"
            });
        } finally {
            setIsUploading(false);
            setFile(null);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle
                    variant="h3"
                    sx={{
                        padding: "36px 36px 0px 36px"
                    }}
                >
                    Upload photo
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "0px" }}>
                    <Grid container sx={{ paddingTop: "18px", height: "100%" }}>
                        <FileDropzoneArea
                            acceptedFileTypes={{
                                "image/jpeg": [".jpeg"],
                                "image/jpg": [".jpg"],
                                "image/png": [".png"]
                            }}
                            maxFiles={1}
                            setFile={setFile}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ display: "flex", gap: "18px" }}>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        onClick={handleSavePhoto}
                        loading={isUploading}
                        disabled={!file}
                        sx={{ marginLeft: "0px !important" }}
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UploadPhotoModal;
