// *******************************************************
// Service module to handle error logging functionality //
// *******************************************************

import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "./AmplifyServices";
import * as mutations from "../graphql/mutations";
import moment from "moment";

const logger = new ConsoleLogger("SystemError");

const getCurrentDate = () => {
    return moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

async function logSystemError(companyId, severity, source, line, error) {
    const input = {
        companyId,
        severity,
        source,
        // error.lineNumber this does not work
        line: error.stack,
        //stack: error.stack,
        error: error.message,
        createdAt: getCurrentDate()
    };

    const result = await graphql(
        graphqlOperation(mutations.createSystemErrorLog, { input })
    );

    logger.debug("In logSystemError, result = " + JSON.stringify(result));
}

export { logSystemError };
