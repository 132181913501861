import React from "react";
import { Grid, Typography } from "@mui/material";
export const FormHeading = ({ classes, title, isMobile, id }) => {
    console.log("classes", classes);
    return (
        <Grid container alignItems="center" className={classes.marginBottom}>
            <Grid item xs>
                <Typography
                    className={classes.title}
                    id={id}
                    variant="h4"
                    style={
                        isMobile
                            ? { marginLeft: "30px" }
                            : { marginLeft: "0px" }
                    }
                    sx={{ fontSize: "24px" }}
                    noWrap
                >
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
};

//
