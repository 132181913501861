import {
  SET_USER_INPUT_STATE,
  //SET_ACTIVESTEP,
  SET_HUBSPOT_CONTACT,
} from "../types";

const UserInputReducer = (state, action) => {
  switch (action.type) {
    /* case SET_ACTIVESTEP:
      return {
        ...state,
        activeStep: action.payload,
      }; */
    case SET_USER_INPUT_STATE:
      return {
        ...state,
        userInputs: action.payload,
      };
    case SET_HUBSPOT_CONTACT:
      return {
        ...state,
        hubspotContact: action.payload,
      };
    default:
      return state;
  }
};

export default UserInputReducer;
