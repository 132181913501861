import { generateClient, post, get, put, del } from "aws-amplify/api";
const client = generateClient();

export async function graphql(query) {
    return client.graphql(query);
}

export function graphqlOperation(query, variables) {
    const gop = { query, variables };
    return gop;
}

export function subscribe(query, handler) {
    return client.graphql({ query }).subscribe({
        next: async (data) => {
            console.log("subscribed data " + JSON.stringify(data));
            await handler();
        }
    });
}

export async function postApi(apiName, apiPath, options) {
    try {
        const restOperation = post({
            apiName,
            path: apiPath,
            options
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        return response;
    } catch (e) {
        console.log("POST call failed: ", JSON.parse(e.response.body));
    }
}

export const postApiWithStatusCode = async (apiName, apiPath, options) => {
    try {
        const restOperation = post({
            apiName,
            path: apiPath,
            options
        });

        let { body, statusCode } = await restOperation.response;
        body = await body.json();

        return { body, statusCode };
    } catch (e) {
        console.log("POST call failed: ", JSON.parse(e.response.body));
    }
};

export async function putApi(apiName, apiPath, options) {
    try {
        const restOperation = put({
            apiName,
            path: apiPath,
            options
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        console.log("PUT call succeeded");
        console.log(response);
        return response;
    } catch (e) {
        console.log("PUT call failed: ", JSON.parse(e.response.body));
    }
}

export const putApiWithStatusCode = async (apiName, apiPath, options) => {
    try {
        const restOperation = put({
            apiName,
            path: apiPath,
            options
        });

        let { body, statusCode } = await restOperation.response;
        body = await body.json();
        return { body, statusCode };
    } catch (e) {
        console.log("POST call failed: ", JSON.parse(e.response.body));
    }
};

export async function delApi(apiName, apiPath, options) {
    try {
        const restOperation = del({
            apiName,
            path: apiPath,
            options
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        console.log("DEL call succeeded");
        console.log(response);
        return response;
    } catch (e) {
        console.log("DEL call failed: ", JSON.parse(e.response.body));
    }
}
export const delApiWithStatus = async (apiName, apiPath, options) => {
    try {
        const restOperation = del({
            apiName,
            path: apiPath,
            options
        });

        let { body, statusCode } = await restOperation.response;
        body = await body.json();
        return { body, statusCode };
    } catch (e) {
        console.log("DEL call failed: ", JSON.parse(e.response.body));
    }
};

export async function getJsonApi(apiName, apiPath, options) {
    try {
        console.log("getJsonApi", apiName, apiPath, options);
        const restOperation = get({
            apiName,
            path: apiPath,
            options
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        console.log("GET call succeeded");
        console.log(response);
        return response;
    } catch (e) {
        console.log("GET call failed: ", JSON.parse(e.response.body));
    }
}
