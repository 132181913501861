import React, { useContext, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter,
    IconButton,
    TableContainer,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Typography
} from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { DataTableWrapper } from "../utils/CommonComonents/DataTableWrapper";
import { PotentialSalesColumns } from "../utils/Constants";
import moment from "moment";
import { StoreContext } from "../context/StoreContext";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

export const AuditRecordsTable = (props) => {
    const { actions, state } = useContext(StoreContext);
    const classes = useStyles();

    // const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("auditRecordsTable")
    );
    const { rows } = props;

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("auditRecordsTable")) {
            setTableRowsSession("auditRecordsTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    useEffect(() => {
        if (props.toFirstPage) setPage(0);
    }, [props.toFirstPage]);

    console.log("auditRows", rows);

    return (
        <>
            <Paper className={classes.paper}>
                <Table className={classes.Table}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="left"
                                className={classes.customHeadPadding}
                            >
                                Date Audited
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.customHeadPadding}
                            >
                                User Name
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.customHeadPadding}
                            >
                                User Role
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.customHeadPadding}
                            >
                                Audit Action
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.customHeadPadding}
                            >
                                Audit Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows &&
                            rows.length > 0 &&
                            rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((items, i) => {
                                    return (
                                        <React.Fragment>
                                            <TableRow hover>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {items.updatedAtFormatted}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {items.userName}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {items.roleFormatted}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {items.auditAction}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.customCellPadding
                                                    }
                                                >
                                                    {items.auditDesc}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                    </TableBody>
                </Table>
                <div style={{ textAlign: "center", margin: "1rem" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleLoadMore}
                        disabled={props.endOfList}
                        style={{
                            padding: "0.2rem",
                            paddingRight: "0.5rem",
                            paddingLeft: "0.5rem"
                        }}
                    >
                        {props.endOfList
                            ? "No More Rows To Load"
                            : "Load More Rows..."}
                    </Button>
                </div>
                <TableFooter>
                    <TableRow style={{ float: "right" }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={6}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "Rows per page"
                                },
                                native: true
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Paper>
        </>
    );
};
