import React, { useState, Fragment, useEffect } from "react";
import { Grid, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";

/* styles used in FeatureList */
const useStyles = makeStyles({
    header: {
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "18px"
    },
    heading: {
        fontSize: "25px",
        color: "#134A55",
        fontFamily: "Poppins, sans-serif"
    },
    subheading: {
        fontFamily: "Poppins, sans-serif",
        color: "#134A55",
        fontSize: "20px"
    },
    listItem: {
        gap: "8px"
    },
    listItemText: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px"
    },
    link: {
        color: "#007bff"
    }
});

const FeatureList = (props) => {
    const basicFeatures = [
        "Branded booking flow",
        "Client-led online booking",
        "Service provider availability management",
        "Custom travel zones",
        "Online payment processing",
        "Client and service provider portal",
        "Reports"
    ];

    const professionalFeatures = [
        "Location-based pricing",
        "Booking requests",
        "1000+ standard integrations"
    ];
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);
    const [featureList, setFeatureList] = useState(
        props.planType === "professional" ? professionalFeatures : basicFeatures
    );

    useEffect(() => {
        if (props.reactivate) {
            setFeatureList(
                props.planType === "professional"
                    ? professionalFeatures
                    : basicFeatures
            );
        }
    }, [props.planType]);

    return (
        <Fragment>
            {/*FeatureList Grid*/}
            <Grid container>
                <Grid container className={classes.header}>
                    <Typography className={classes.heading}>
                        {props.planType === "professional"
                            ? `Professional plan`
                            : `Starter plan`}
                    </Typography>

                    <Typography className={classes.subheading}>
                        {props.planType === "professional"
                            ? `$49.00 per user`
                            : `$39.00 per user`}
                    </Typography>
                </Grid>
                <Grid>
                    {featureList.map((feature) => (
                        <Grid container className={classes.listItem}>
                            <CheckIcon />
                            <Typography className={classes.listItemText}>
                                {feature}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid container className={classes.listItem}>
                        <CheckIcon />
                        <Typography className={classes.listItemText}>
                            <Link
                                href="https://www.gomarketbox.com/pricing"
                                underline="hover"
                                target="_blank"
                                className={`${classes.listItemText} ${classes.link}`}
                            >
                                {"And more..."}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default FeatureList;
