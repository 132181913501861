import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const NotificationSnackbar = ({ open, severity, message, onClose }) => {
    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            autoHideDuration={4000}
            onClose={onClose}
        >
            <Alert
                onClose={onClose}
                severity={severity}
                variant="filled"
                sx={{ width: "100%" }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default NotificationSnackbar;
