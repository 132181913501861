import React, { useState, useEffect, Fragment, useContext } from "react";
import CheckoutForm from "../forms/CheckoutForm.js";
import { Cache } from "aws-amplify/utils";
import * as queries from "../../../../graphql/queries";
import {
    graphql,
    graphqlOperation,
    postApi
} from "../../../../modules/AmplifyServices";
import { fetchAuthSession, signIn } from "aws-amplify/auth";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StripeCheckout from "../custom/StripeCheckout.js";
import UserInputContext from "../../context/userInput/userInputContext";
import OrderSummaryCalculator from "../custom/OrderSummaryCalculator.js";
import {
    createPendingCompany,
    createCompanyAndPublicSite,
    createScheduleIndex,
    updateReactivatedCompany
} from "../../modules/CreateCompany";
import FeatureList from "../custom/FeatureList.js";
import { useAtom } from "jotai";
import { mbxUserAtom } from "../../../../atoms/atoms.js";
import { useNavigate } from "react-router-dom";
import {
    getSubscriptionSessionData,
    createStripeSessionData,
    updateSubscriptionSessionData
} from "../../modules/ManageRefData.js";
import * as Sentry from "@sentry/react";

/* styles used in right panel */
const useStyles = makeStyles({
    panel1: {
        width: "46%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "6px",
        flexDirection: "column"
    },
    panel2: {
        width: "34%",
        display: "flex",
        paddingTop: "6px",
        flexDirection: "column",
        padding: "6px 42px 0px 0px",
        gap: "36px",
        alignItems: "center"
    },
    panel_mobile: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "6px",
        flexDirection: "column",
        alignItems: "center"
    },
    selector_rows: {
        width: "100%",
        padding: "8px 42px 0px 42px"
    },
    contactBox: {
        borderRadius: 4,
        padding: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "50%"
    },
    link: {
        cursor: "pointer",
        textDecoration: "none",
        fontFamily: "Poppins",
        "&:hover": {
            textDecoration: "underline" // Add underline on hover
        }
    }
});

const TopPanel = (props) => {
    const userInputContext = useContext(UserInputContext);
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);
    const navigate = useNavigate();
    const lookupKeys = {
        basic: {
            monthlyLookupKey: "basic-monthly",

            annualLookupKey: "basic-annually"
        },
        professional: {
            monthlyLookupKey: "professional-monthly",

            annualLookupKey: "professional-annually"
        }
    };
    const query = new URLSearchParams(window.location.search);
    const {
        userInputs: state,
        hubspotContact,
        setHubspotContact
    } = userInputContext;
    const [quantity, setQuantity] = useState(1);
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
        React.useState("professional");
    const [billingPlan, setBillingPlan] = useState(
        query.get("bp") === "M8tD4"
            ? props.reactivate
                ? lookupKeys[selectedSubscriptionPlan].annualLookupKey
                : props.annualLookupKey
            : props.reactivate
              ? lookupKeys[selectedSubscriptionPlan].monthlyLookupKey
              : props.monthlyLookupKey
    );
    const [email, setEmail] = useState("");
    const [validate, setValidate] = useState(false);
    const [isValid, setIsValid] = useState(false);
    //const [existingSessionData, setExistingSessionData] = useState("");
    // let [user, setUser] = useState("");
    // let [attributes, setAttributes] = useState("");
    // let [guestUser, setGuestUser] = useState("");
    // const [showCheckout, setShowCheckout] = useState(true);
    // const [companyData, setCompanyData] = useState({});
    const { setUserState } = userInputContext;
    // const basicFeatures = [
    //     "Unlimited Bookings",
    //     "Secure payment processing",
    //     "Customizable online booking page",
    //     "Custom appointment packages",
    //     "Personal onboarding",
    //     "Active customer support"
    // ];

    const classes = useStyles();

    useEffect(() => {
        //check if the URL has any sessionId and status
        async function checkSessionId() {
            if (query.get("success") || query.get("canceled")) {
                await setSessionData(query.get("session_id"));
            }
        }
        checkSessionId();
    }, []);

    async function setSessionData(sessionId) {
        try {
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `subscriptionsessiondata|${sessionId}`,
                    refName: "data-subscriptionsession"
                })
            );
            if (
                exists.data.getRefData &&
                exists.data.getRefData.overrideValue
            ) {
                const refOverrideValue = await JSON.parse(
                    exists.data.getRefData.overrideValue
                );
                sessionStorage.setItem(
                    "email",
                    refOverrideValue.companyData.emailAddress
                );
                //setExistingSessionData(refOverrideValue);
                setUserState({
                    firstNameInput: refOverrideValue?.companyData
                        ?.firstNameInput
                        ? refOverrideValue.companyData.firstNameInput
                        : "",
                    lastNameInput: refOverrideValue?.companyData?.lastNameInput
                        ? refOverrideValue?.companyData?.lastNameInput
                        : "",
                    companyNameInput: refOverrideValue?.companyData
                        ?.companyNameInput
                        ? refOverrideValue.companyData.companyNameInput
                        : "",
                    emailAddress: refOverrideValue?.companyData?.emailAddress
                        ? refOverrideValue.companyData.emailAddress
                        : "",
                    phoneNumber: refOverrideValue?.companyData?.phoneNumber
                        ? refOverrideValue.companyData.phoneNumber
                        : "",
                    cleanPhone: refOverrideValue?.companyData?.cleanPhone
                        ? refOverrideValue.companyData.cleanPhone
                        : "",
                    numProvidersSelected: { value: 1, label: " 1 " },
                    desiredSubdomain: refOverrideValue?.companyData
                        ?.desiredSubdomain
                        ? refOverrideValue.companyData.desiredSubdomain
                        : "",
                    subdomainEnding: {
                        value: ".gomarketbox.com",
                        label: ".gomarketbox.com"
                    },
                    userInputtedConfirmationCode: "",
                    verificationCode: "",
                    codeMatches: false,
                    passedCaptcha: false,
                    sentVerificationCode: false,
                    emailAddressError: false,
                    phoneNumberError: false,
                    subdomainError: false,
                    companyExists: false,
                    companyId: "",
                    temppas: "",
                    companiesArr: null
                });
            } else {
                //integration refData for this id does not exist
                return;
            }
        } catch (e) {
            console.log("error in setting Session Data", e);
            Sentry.captureException(e);
        }
    }

    const handleQuantityUpdate = (updatedQuantity) => {
        setQuantity(updatedQuantity);
    };

    const handleSubscriptionPlanUpdate = (updatedSubscriptionPlan) => {
        setSelectedSubscriptionPlan(updatedSubscriptionPlan);
        handlePlanUpdate(
            billingPlan.split("-")[1] === "monthly"
                ? lookupKeys[updatedSubscriptionPlan].monthlyLookupKey
                : lookupKeys[updatedSubscriptionPlan].annualLookupKey
        );
    };

    const handlePlanUpdate = (updatedPlan) => {
        setBillingPlan(updatedPlan);
    };

    const handleEmailUpdate = (updatedEmail) => {
        setEmail(updatedEmail);
    };

    const handleValidateUpdate = (updatedValidate) => {
        setValidate(updatedValidate);
    };

    const handleIsValidUpdate = (updatedIsValid) => {
        setIsValid(updatedIsValid);
    };

    const handleCreateCompany = async () => {
        const companyData = await createCompany();
        if (companyData) {
            window.location = `${
                process.env.REACT_APP_AUTH_SITE_URL
            }/activate-account?success=true&session_id=${query.get(
                "session_id"
            )}`;
        }
    };

    const handleActivateCompany = async () => {
        try {
            //1.) Get the companies subscription session data
            const exists = await getSubscriptionSessionData(
                query.get("session_id")
            );

            //2.) Provided the subscription session data exists, reactivate company by making it active
            if (
                exists?.data?.getRefData &&
                exists?.data?.getRefData.overrideValue
            ) {
                const sessionData = JSON.parse(
                    exists.data.getRefData.overrideValue
                );
                sessionData.companyData.done = true;
                sessionData.companyData.companyId = mbxUser.company.id;
                await updateReactivatedCompany(
                    sessionData.companyData.companyId,
                    sessionData.companyData.companyNameInput,
                    sessionData.companyData.firstNameInput,
                    sessionData.companyData.lastNameInput,
                    sessionData.companyData.emailAddress,
                    sessionData.planType
                );

                //3.) Create stripesessiondata in refData table
                await createStripeSessionData(
                    sessionData.companyData.companyId,
                    sessionData.stripeSessionId
                );

                //4.) Update the subscription session data with the company id
                await updateSubscriptionSessionData(
                    query.get("session_id"),
                    sessionData
                );

                //5) Update storage variables that are used throughout application
                const updatedCompanyData = {
                    ...mbxUser,
                    company: {
                        ...mbxUser.company,
                        active: true,
                        name: sessionData.companyData.companyNameInput,
                        contactname:
                            sessionData.companyData.firstNameInput +
                            " " +
                            sessionData.companyData.lastNameInput,
                        emailaddress: sessionData.companyData.emailAddress,
                        subscriptionLevel:
                            sessionData.planType === "basic"
                                ? "BASIC"
                                : "PROFESSIONAL"
                    }
                };
                sessionStorage.removeItem("email");
                sessionStorage.removeItem("processingCompanyCreation");
                await Cache.setItem("user", updatedCompanyData);
                localStorage.setItem(
                    "loggedInMbUser",
                    JSON.stringify(updatedCompanyData)
                );
                setMbxUser(updatedCompanyData);
                navigate("/");
            }
        } catch (e) {
            console.log("Error: Unable to update company info", e);
            Sentry.captureException(e);
        }
    };

    async function createCompany() {
        await SignInAsGuest();

        try {
            //first: check for company refData from the session
            const exists = await getSubscriptionSessionData(
                query.get("session_id")
            );

            if (
                exists.data.getRefData &&
                exists.data.getRefData.overrideValue &&
                !JSON.parse(exists.data.getRefData.overrideValue).companyData
                    .companyId
            ) {
                const sessionData = JSON.parse(
                    exists.data.getRefData.overrideValue
                );

                //Before we create the company, call the FirstPromoter referral function
                try {
                    const userEmail = sessionData.companyData.emailAddress;
                    window.fpr("referral", { email: userEmail });
                } catch (e) {
                    console.log("error with FirstPromoter referral request", e);
                    Sentry.captureException(e);
                }

                sessionData.companyData.planType = sessionData.planType;
                //second: create the pending company, return pending company ID
                let pendingCompanyId = await createPendingCompany(
                    sessionData.companyData
                );
                if (pendingCompanyId) {
                    sessionData.companyData.companyId = pendingCompanyId;
                    sessionData.companyData.companyExists = true;
                }
                //refData creation here: put stripe session ID with company ID into the refData

                //third: convert pending company to official company and create public site, return official company ID
                //Also, update subscriptionsessiondata refData, and create stripesessiondata refData
                let companyId = await createCompanyAndPublicSite(
                    sessionData.companyData
                );
                if (companyId) {
                    sessionData.companyData.done = true;
                    sessionData.companyData.companyId = companyId;
                    await updateSubscriptionSessionData(
                        query.get("session_id"),
                        sessionData
                    );
                    await createStripeSessionData(
                        sessionData.companyData.companyId,
                        sessionData.stripeSessionId
                    );
                }

                //createScheduleIndex
                await createScheduleIndex(companyId);

                //fourth: submit HubSpot contact
                try {
                    let hubspotContactResponse;
                    let body = {
                        companyName: sessionData.companyData.companyNameInput,
                        lastname: sessionData.companyData.lastNameInput,
                        firstname: sessionData.companyData.firstNameInput,
                        emailaddress: sessionData.companyData.emailAddress,
                        phoneNumber: sessionData.companyData.cleanPhone,
                        numberOfProviders: "1",
                        freeTrialSignUp: false,
                        mbSignUp: false,
                        mbSelfSignUp: true
                    };
                    if (hubspotContact)
                        body = { ...body, id: hubspotContact.id }; //update existing hubspot contact
                    hubspotContactResponse = await postApi(
                        "hubspotapi",
                        "/contacts",
                        {
                            body
                        }
                    );

                    if (hubspotContactResponse && hubspotContactResponse.data)
                        setHubspotContact(hubspotContactResponse.data);
                } catch (e) {
                    console.log("error while creating hubspot contact", e);
                    Sentry.captureException(e);
                }
                return sessionData.companyData;
                //props.renderActivateAccountForm(true, sessionData.companyData);
            }
        } catch (e) {
            console.log("error while creating company", e);
            Sentry.captureException(e);
        }
    }

    async function SignInAsGuest() {
        let userAuthenticated = false;
        try {
            // check if user signed in
            await fetchAuthSession({ bypassCache: true })
                .then((user) => {
                    if (
                        user &&
                        user.tokens &&
                        user.tokens.idToken &&
                        user.tokens.idToken.payload
                    ) {
                        let groups =
                            user.tokens.idToken.payload["cognito:groups"];
                        if (groups && groups.length) {
                            userAuthenticated = true;
                        }
                    }
                })
                .catch((err) => {
                    console.log("*** Error: User is not authenticated - ", err);
                    Sentry.captureException(err);
                });

            if (!userAuthenticated) {
                await signIn({
                    username: process.env.REACT_APP_MB_GUEST_EMAIL,
                    password: process.env.REACT_APP_MB_GUEST_PWD
                });
                // setUser(user);
                // setAttributes(user.attributes);
                // setGuestUser(true);
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === "PasswordResetRequiredException") {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === "NotAuthorizedException") {
                // The error happens when the incorrect password is provided
            } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(
                    "An error occurred attempting to login. Error was: " +
                        JSON.stringify(err)
                );
                Sentry.captureException(err);
            }
        }
    }

    return (
        <Fragment>
            {/*Top Panel Grid*/}
            <Grid
                item
                className={
                    props.width > 900 ? classes.panel1 : classes.panel_mobile
                }
            >
                <CheckoutForm
                    onEmailUpdate={handleEmailUpdate}
                    width={props.width}
                    validate={validate}
                    onValidateUpdate={handleValidateUpdate}
                    onIsValid={handleIsValidUpdate}
                    reactivate={props.reactivate ? true : false}
                />
                {!query.get("success") && (
                    <OrderSummaryCalculator
                        onQuantityUpdate={handleQuantityUpdate}
                        onPlanUpdate={handlePlanUpdate}
                        onSubscriptionPlanUpdate={handleSubscriptionPlanUpdate}
                        monthlyLookupKey={
                            props.reactivate
                                ? lookupKeys[selectedSubscriptionPlan]
                                      .monthlyLookupKey
                                : props.monthlyLookupKey
                        }
                        annualLookupKey={
                            props.reactivate
                                ? lookupKeys[selectedSubscriptionPlan]
                                      .annualLookupKey
                                : props.annualLookupKey
                        }
                        quantity={quantity}
                        plan={billingPlan}
                        planType={
                            props.reactivate
                                ? selectedSubscriptionPlan
                                : props.planType
                        }
                        width={props.width}
                        reactivate={props.reactivate ? true : false}
                    />
                )}
                <StripeCheckout
                    quantity={quantity}
                    plan={billingPlan}
                    planType={
                        props.reactivate
                            ? selectedSubscriptionPlan
                            : props.planType
                    }
                    email={email}
                    isValid={isValid}
                    onValidateUpdate={handleValidateUpdate}
                    onIsValid={handleIsValidUpdate}
                    onCreateCompany={handleCreateCompany}
                    onActivateCompany={handleActivateCompany}
                    width={props.width}
                    reactivate={props.reactivate ? true : false}
                />
            </Grid>

            <Grid
                item
                className={
                    props.width > 900 ? classes.panel2 : classes.panel_mobile
                }
                style={{ display: props.width < 900 ? "none" : "" }}
            >
                <FeatureList
                    planType={
                        props.reactivate
                            ? selectedSubscriptionPlan
                            : props.planType
                    }
                    reactivate={props.reactivate ? true : false}
                />
                <Grid className={classes.contactBox}>
                    <Typography sx={{ fontFamily: "Poppins" }}>
                        Have a question?
                    </Typography>
                    <a
                        href={"https://www.gomarketbox.com/contact-us"}
                        target="_blank"
                        className={classes.link}
                    >
                        Contact us
                    </a>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default TopPanel;
