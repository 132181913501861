import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
import clsx from "clsx";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import { useStyles } from "../styles/MainNavbarStyles";
import Button from "@mui/material/Button";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import { serviceAddStyles } from "../styles/ServiceAddStyles";
import {
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    CircularProgress,
    Backdrop,
    Tooltip
} from "@mui/material";
import { getCurrencySymbol } from "../utils";
import { execRead } from "../modules/DBService";
import { userHasMarketboxAdminRole } from "../user/UserCommon";
import { auditServiceCreate, auditServiceUpdate } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { serviceTypeCategoryByCompany } from "../queries/CustomQueries";
import { getUserFromCache } from "../user/UserCommon";
import { ArrowDropDown, CloseRounded } from "@mui/icons-material";
import CategoryAddModal from "./CategoryAddModal";
import LocationsDropdown from "../components/LocationsDropdown";
import { useNavigate } from "react-router-dom";

const headingStyles = {
    color: "rgba(0,0,0,0.5)",
    fontSize: "0.75rem"
};

const textFieldStyling = {
    backgroundColor: "white",
    border: "2px solid #d4d4d4",
    borderRadius: "4px",
    "&:hover": { backgroundColor: "white" },
    "& .MuiFilledInput-root": {
        "&:hover": { backgroundColor: "white" },
        backgroundColor: "white",
        "&.Mui-focused": {
            backgroundColor: "white"
        }
    }
};

const selectFieldStyling = {
    backgroundColor: "white",
    border: "2px solid #d4d4d4",
    borderRadius: "4px",
    "&:hover": {
        backgroundColor: "white"
    },

    "&.Mui-focused": {
        backgroundColor: "white",
        "& .MuiSelect-select": {
            backgroundColor: "white"
        }
    }
};

function ServiceAdd(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const componentClasses = serviceAddStyles();
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const { state, actions } = useContext(StoreContext);
    const [snackMsg, setSnackMsg] = useState();

    // fields
    const [id, setId] = useState(0);
    const [serviceName, setServiceName] = useState("");
    const [price, setPrice] = useState(10.0);
    const [duration, setDuration] = useState(60);
    const [desc, setDesc] = useState("");
    const [active, setActive] = useState(true);
    const [taxExempt, setTaxExempt] = useState(false);
    const [taxOverride, setTaxOverride] = useState("");
    const [loggedInUser, setLoggedInUser] = useState({});
    const [errDuration, setErrDuration] = useState(false);
    const [errTaxOverride, setErrTaxOverride] = useState(false);
    const [errPrice, setErrPrice] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [category, setCategory] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [order, setOrder] = useState("");
    const inputLabel = React.useRef(null);
    const [oldInfo, setOldInfo] = useState([]);
    const [companyLocationData, setCompanyLocationData] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [disableSave, setDisableSave] = useState(false);

    const limit = process.env.REACT_APP_LISTLIMIT;
    const authuser = getUserFromCache();
    const [serviceBehavior, setServiceBehavior] = useState("EXTERNAL");

    //category portal states
    const [displayCategoryPortal, setDisplayCategoryPortal] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {}, [displayCategoryPortal]);

    //get necessary data for the form
    useEffect(() => {
        const fetchData = async () => {
            setLoggedInUser(authuser);
            setIsEdit(
                props.mode === "Edit" && state.id.length > 0 && !fromDashboard()
            );
            setIsView(state.mode === "View");
            getCategories();
            if (props.mode === "Add" || fromDashboard()) {
                let companyLocations = await getCompanyLocations();
                manageCompanyLocations(companyLocations);
            }
            if (
                (props.mode === "Edit" || state.mode === "View") &&
                !fromDashboard()
            ) {
                getServiceData();
            }
        };

        fetchData();
    }, []);

    const closeCategoryPortal = () => {
        setDisplayCategoryPortal(false);
    };

    const handleServiceBehaviorChange = (e) => {
        setServiceBehavior(e.target.value);
    };
    const handleChangeServiceName = (e) => {
        setServiceName(e.target.value);
    };

    const fromDashboard = () => {
        if (props.originPath === "DashboardForm") {
            return true;
        }
        return false;
    };

    const handleChangePrice = (e) => {
        if (
            Number.isInteger(Number.parseInt(e.target.value)) &&
            Number.parseInt(e.target.value) >= 0
        ) {
            setPrice(e.target.value);
            setErrPrice(false);
        } else {
            setPrice("");
            setErrPrice(true);
        }
    };
    const handleChangeTaxOverride = ({ target: { value } }) => {
        if (!isNaN(parseFloat(value)) && isFinite(value) && value >= 0) {
            setTaxOverride(value);
            setErrTaxOverride(false);
        } else {
            setTaxOverride("");
            setErrTaxOverride(true);
        }
    };

    const handleChangeDuration = (e) => {
        if (
            Number.isInteger(Number.parseInt(e.target.value)) &&
            Number.parseInt(e.target.value) > 0
        ) {
            setDuration(e.target.value);
            setErrDuration(false);
        } else {
            setDuration("");
            setErrDuration(true);
        }
    };

    const handleChangeDesc = (e) => {
        setDesc(e.target.value);
    };

    let newInfo;
    async function handleSaveServiceType(val) {
        setDisableSave(true);
        if (isEdit && !fromDashboard()) {
            newInfo = [
                {
                    oldName: oldInfo[0].serviceName,
                    serviceName: "No Change",
                    duration: "No Change",
                    price: "No Change",
                    category: "No Change",
                    behavior: "No Change",
                    desc: "No Change",
                    taxExempt: "No Change",
                    active: "No Change"
                }
            ];
        }
        // validate
        if (serviceName === "") {
            setSnackMsg("Please enter a name for the service.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (serviceBehavior === "") {
            setSnackMsg("Please enter a behavior type for the service.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (duration === "") {
            setSnackMsg("Please enter a duration for the service.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (price === "") {
            setSnackMsg("Please enter a price for the service.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (desc === "") {
            setSnackMsg("Please enter a description for the service.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (!category || category === "") {
            setSnackMsg("Please select a category for the service.");
            setMsgOpen(true);
            setDisableSave(false);
        }

        // check if any data has changed
        if (isEdit && !fromDashboard()) {
            if (serviceName != oldInfo[0].serviceName) {
                newInfo.map((item) => {
                    item.serviceName = serviceName;
                });
            }
            if (duration != oldInfo[0].duration) {
                newInfo.map((item) => {
                    item.duration = duration;
                });
            }
            if (price != oldInfo[0].price) {
                newInfo.map((item) => {
                    item.price = price;
                });
            }
            if (categoryName != oldInfo[0].category) {
                newInfo.map((item) => {
                    item.category = categoryName;
                });
            }
            if (serviceBehavior != oldInfo[0].behavior) {
                newInfo.map((item) => {
                    item.behavior = serviceBehavior;
                });
            }
            if (desc != oldInfo[0].desc) {
                newInfo.map((item) => {
                    item.desc = desc;
                });
            }
            if (taxExempt != oldInfo[0].taxExempt) {
                newInfo.map((item) => {
                    item.taxExempt = taxExempt ? "Yes" : "No";
                });
            }
            if (active != oldInfo[0].active) {
                newInfo.map((item) => {
                    item.active = active ? "Active" : "Inactive";
                });
            }
        }
        // save service data
        if (!(errPrice || errDuration) && !!category) {
            await saveServiceData(val);
        }
    }

    function handleCancel() {
        reset();
        navigate("..");
    }
    function reset() {
        // reset fields
        setServiceName("");
        setPrice("");
        setDuration("");
        setDesc("");
        setActive("true");
        setTaxExempt(false);
        setId(0);
        setIsEdit(false);
        //category will be empty if orign path is dashboard
        if (fromDashboard()) {
            setCategory("");
        }
    }

    const validateService = async () => {
        // check for duplicate service by name
        const listServiceTypes = /* GraphQL */ `
            query ListServiceTypes(
                $filter: ModelServiceTypeFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listServiceTypes(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                ) {
                    items {
                        id
                        name
                    }
                    nextToken
                }
            }
        `;

        const filter = {
            and: [
                { companyId: { eq: loggedInUser.company.id } },
                { deleted: { ne: true } },
                { active: { ne: false } }
            ]
        };

        let services = await execRead({
            opname: "listServiceTypes",
            op: listServiceTypes,
            filter: filter,
            limit: limit
        });
        services = services.items;

        if (isEdit) {
            services = services.filter((s) => s.id !== id);
        }
        const duplicate = services.filter(
            (s) =>
                s.name.trim().toUpperCase() === serviceName.trim().toUpperCase()
        )[0];

        if (duplicate) {
            return true;
        } else return false;
    };

    //saveServiceData(serviceTypeIds, skillIds, val)
    async function saveServiceData(val) {
        async function saveGraphQLService() {
            let input = {
                name: serviceName,
                minutes: duration,
                price: price,
                desc: desc,
                active: active,
                taxexempt: taxExempt,
                TaxOverride: taxExempt ? null : JSON.stringify(taxOverride),
                serviceTypeCompanyId: loggedInUser.company.id,
                companyId: loggedInUser.company.id,
                serviceTypeCategoryId: category,
                categoryId: category,
                categoryName: categoryName,
                behavior: serviceBehavior,
                deleted: false
            };
            if (
                selectedLocations &&
                selectedLocations.length >= 1 &&
                selectedLocations.length !== companyLocationData.length
            ) {
                const locationIds = selectedLocations.map(
                    (location) => location.id
                );
                input.locations = JSON.stringify(locationIds);
            } else {
                input.locations = null;
            }
            if (isEdit && !fromDashboard()) {
                input.id = id;
                const updatedServiceType = await graphql(
                    graphqlOperation(mutations.updateServiceType, { input })
                );

                await auditServiceUpdate(
                    loggedInUser,
                    updatedServiceType.data.updateServiceType,
                    newInfo
                );
            } else {
                const newServiceType = await graphql(
                    graphqlOperation(mutations.createServiceType, { input })
                );

                try {
                    if (newServiceType && fromDashboard()) {
                        let input = {
                            providerServiceTypeProviderId:
                                loggedInUser && loggedInUser.provider
                                    ? loggedInUser.providerId
                                    : state.provider.id,
                            providerServiceTypeServicetypeId:
                                newServiceType.data.createServiceType.id
                        };

                        const {
                            data: { createProviderServiceType }
                        } = await graphql(
                            graphqlOperation(
                                mutations.createProviderServiceType,
                                {
                                    input
                                }
                            )
                        );
                        props.doneTodoHandler(
                            "services",
                            createProviderServiceType
                        );
                    }
                } catch (err) {
                    console.log("ServiceAddUpdateError", err);
                }

                await auditServiceCreate(
                    loggedInUser,
                    newServiceType.data.createServiceType
                );
            }
        }

        try {
            const isDuplicate = await validateService();

            if (!!isDuplicate) {
                setSnackMsg(
                    `Service with the name "${serviceName}" already exists. Please use another name for the service`
                );
                setMsgOpen(true);
                setDisableSave(false);
            } else if (!isDuplicate) {
                await saveGraphQLService();
                setSnackMsg("Service successfully saved.");
                setMsgOpen(true);

                if (val === "addSingle") {
                    setTimeout(() => {
                        reset();
                        setDisableSave(false);
                        if (fromDashboard()) {
                            props.close();
                        } else {
                            navigate("..");
                        }
                    }, 2000);
                } else {
                    reset();
                    setDisableSave(false);
                }
            }
        } catch (err) {
            console.error(`Error saving service type data`, err);
            setDisableSave(false);
        }
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    async function getServiceData() {
        const serviceId = state.id;

        if (!!serviceId) {
            try {
                let companyLocations = await getCompanyLocations();

                const input = { id: serviceId };
                const result = await graphql(
                    graphqlOperation(queries.getServiceType, input)
                );

                if (!result.data.getServiceType) {
                    navigate("..");
                }

                if (result?.data?.getServiceType && !fromDashboard()) {
                    if (!(!!result.errors && result.errors.length > 0)) {
                        const {
                            id,
                            name,
                            desc,
                            category,
                            minutes,
                            price,
                            ordinal,
                            active,
                            behavior,
                            taxexempt,
                            locations,
                            TaxOverride
                        } = result.data.getServiceType;
                        setId(id);
                        setServiceName(name);
                        setDesc(desc);
                        setDuration(minutes);
                        setPrice(price);
                        setOrder(ordinal ? ordinal : "");
                        setActive(active);
                        setCategory(category.id);
                        setCategoryName(category.name);
                        setTaxExempt(taxexempt ? true : false);
                        if (!!behavior) {
                            setServiceBehavior(behavior);
                        } else setServiceBehavior("EXTERNAL");
                        manageCompanyLocations(
                            companyLocations,
                            JSON.parse(locations)
                        );

                        try {
                            setTaxOverride(JSON.parse(TaxOverride));
                        } catch {
                            console.log("error parsing tax Override");
                        }

                        // saving old information to check for changes
                        oldInfo.push({
                            serviceName: name,
                            duration: minutes,
                            price: price,
                            category: category.name,
                            behavior: !!behavior ? behavior : "EXTERNAL",
                            desc: desc,
                            taxExempt: taxexempt ? true : false,
                            active: active
                        });
                    }
                }
            } catch (err) {
                console.error(`Error fetching service type `, err);
            }
        } else {
            navigate("..");
        }
    }

    useEffect(() => {
        getCategories();
    }, [displayCategoryPortal]);

    const getCategories = async () => {
        const authuser = getUserFromCache();
        // TO-DO: USE THIS FILTER WHEN active added to the type

        const categoriesResult = await execReadByPK({
            opname: "serviceTypeCategoryByCompany",
            op: serviceTypeCategoryByCompany,
            id: { companyId: authuser.company.id },
            filter: {
                deleted: { ne: true }
            },
            sortDirection: "DESC"
        });

        if (!!categoriesResult.items) {
            setCategoryData(categoriesResult.items);
        }
    };

    /**Function to retreieve and set company location data
     * The 'companyLocationsByCompany' query is used to get all company locations that are still active
     * @updates setCompanyLocationData() - for the 'Locations' drop-down
     */
    async function getCompanyLocations() {
        let locationData = await execReadByPK({
            opname: "companyLocationByCompany",
            op: queries.companyLocationByCompany,
            id: {
                companyId: authuser.company.id
            },
            filter: { deleted: { ne: true } }
        });

        if (locationData?.items && locationData?.items.length > 0) {
            setCompanyLocationData(locationData.items);
            return locationData.items;
        }
    }

    /**
     * Utility function to manage the selectLocations within state.
     * @param {*} companyLocations
     * @param {*} previousLocations
     */
    function manageCompanyLocations(companyLocations, previousLocations) {
        if (
            props.mode === "Add" ||
            previousLocations === null ||
            fromDashboard()
        ) {
            setSelectedLocations(companyLocations);
        } else if (props.mode === "Edit" && previousLocations) {
            setSelectedLocations(
                companyLocations.filter((location) =>
                    previousLocations.includes(location.id)
                )
            );
        }
    }

    function handleCategoryChange(e) {
        const { value } = e.target;

        if (value === "add-new-category") {
            setDisplayCategoryPortal(true);
            return;
        }
        setCategory(value);
        let categoryName = "";
        // find in categoryData
        for (let x = 0; x < categoryData.length; x++) {
            if (categoryData[x].id === value) {
                categoryName = categoryData[x].name;
                break;
            }
        }
        setCategoryName(categoryName);
    }

    function updateCategoryValue(id, name) {
        setCategory(id);
        setCategoryName(name);
    }

    const handleOpenSelect = () => {
        setOpenMenu(true);
    };

    const handleCloseSelect = () => {
        setOpenMenu(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />

            {disableSave && (
                <Backdrop
                    sx={{ zIndex: 3000, color: "#fff" }}
                    open={disableSave}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
            )}

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "8px"
                }}
            >
                {fromDashboard() && (
                    <>
                        <Typography
                            className={classes.title}
                            variant="h4"
                            noWrap
                        >
                            Add your services
                        </Typography>
                        <CloseRounded
                            sx={{
                                width: "2rem",
                                color: "rgba(0,0,0,0.5)",
                                fontWeight: 700,
                                cursor: "pointer"
                            }}
                            onClick={props.close}
                        />
                    </>
                )}
            </div>
            {!fromDashboard() && (
                <Grid
                    container
                    flexDirection={"column"}
                    item
                    xs={12}
                    sx={{ marginTop: "1rem" }}
                >
                    <Typography sx={headingStyles}>Service Status</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={active}
                                onChange={(event) =>
                                    setActive(event.target.checked)
                                }
                                value="active"
                                color="primary"
                                inputProps={{
                                    "aria-label": "primary checkbox"
                                }}
                            />
                        }
                        label="Active"
                    />
                </Grid>
            )}

            <Grid
                sx={{ marginTop: "0.5rem" }}
                container
                item
                xs={fromDashboard() ? 12 : 5}
            >
                <TextField
                    fullWidth
                    variant="filled"
                    sx={{ ...textFieldStyling }}
                    label="Service name"
                    id="service-name"
                    value={serviceName}
                    onChange={handleChangeServiceName}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    title="This is the name of your service that your customers will see and book "
                                    placement="bottom"
                                    style={{
                                        color: "#a2a2a2",
                                        fontSize: "20px"
                                    }}
                                >
                                    {<InfoIcon />}
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>

            <Grid
                sx={{ marginTop: "1rem" }}
                container
                item
                xs={fromDashboard() ? 12 : 5}
            >
                <TextField
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    title="Tell clients more about the services you are offering"
                                    placement="bottom"
                                    style={{
                                        color: "#a2a2a2",
                                        fontSize: "20px",
                                        marginBottom: "14px"
                                    }}
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    sx={textFieldStyling}
                    id="service-description"
                    label="Description"
                    multiline
                    rows="2"
                    maxRows="10"
                    value={desc}
                    variant="filled"
                    onChange={handleChangeDesc}
                />
            </Grid>

            <Grid container sx={{ marginTop: "1rem" }}>
                <Grid item xs={fromDashboard() ? 6 : 2.5}>
                    <TextField
                        variant="filled"
                        sx={{ ...textFieldStyling, width: "calc(100% - 8px)" }}
                        label="Duration (minutes) "
                        id="service-duration"
                        type="number"
                        value={duration}
                        error={errDuration}
                        helperText={
                            errDuration &&
                            "Please enter a positive number (minutes)"
                        }
                        onChange={handleChangeDuration}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title="Let customers know the length of this service"
                                        placement="bottom"
                                        style={{
                                            color: "#a2a2a2",
                                            fontSize: "20px"
                                        }}
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid container item xs={fromDashboard() ? 6 : 2.5}>
                    <TextField
                        fullWidth
                        variant="filled"
                        sx={textFieldStyling}
                        label={`Price`}
                        id="service-price"
                        value={price}
                        type="number"
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>
                                        {getCurrencySymbol()}
                                    </Typography>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title="Let customers know how much you charge for this service"
                                        placement="bottom"
                                        style={{
                                            color: "#a2a2a2",
                                            fontSize: "20px"
                                        }}
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                        onChange={handleChangePrice}
                        error={errPrice}
                    />
                    {errPrice && (
                        <Typography>Please enter a positive price</Typography>
                    )}
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={fromDashboard() ? 12 : 5}
                sx={{ marginTop: "1rem" }}
            >
                <FormControl variant="filled" fullWidth>
                    <InputLabel ref={inputLabel} id="category-service">
                        Category
                    </InputLabel>
                    <Select
                        variant="filled"
                        fullWidth
                        disableUnderline
                        sx={{
                            ...selectFieldStyling,
                            "& .MuiSelect-icon": {
                                color: "disabled.main"
                            }
                        }}
                        id="category-service"
                        label="Category"
                        value={category}
                        onChange={handleCategoryChange}
                        name="category"
                        open={openMenu}
                        onOpen={handleOpenSelect}
                        onClose={handleCloseSelect}
                        IconComponent={ArrowDropDown} // Use the custom expand icon component
                        endAdornment={
                            <React.Fragment>
                                <Tooltip
                                    title="This allows you to group certain services together on your booking page"
                                    placement="bottom"
                                    style={{
                                        color: "#a2a2a2",
                                        fontSize: "20px",
                                        marginRight: "1rem"
                                    }}
                                >
                                    <InfoIcon
                                        color="disabled"
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            right: "16px", // Adjust the positioning as needed
                                            transform: "translateY(-50%)"
                                        }}
                                    />
                                </Tooltip>
                            </React.Fragment>
                        }
                    >
                        {categoryData &&
                            categoryData.length > 0 &&
                            categoryData.map((item, i) => {
                                return (
                                    <MenuItem
                                        value={item.id}
                                        key={i}
                                        divider={categoryData?.length === i + 1}
                                    >
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        <MenuItem key={"add-new"} value={"add-new-category"}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    color: "primary.main",
                                    cursor: "pointer"
                                }}
                            >
                                Add new category
                            </Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {loggedInUser?.company?.offersOnPremiseServices &&
                companyLocationData &&
                companyLocationData.length > 0 && (
                    <LocationsDropdown
                        fromDashboard={fromDashboard}
                        inputLabelRef={inputLabel}
                        initialLocations={selectedLocations}
                        setLocations={setSelectedLocations}
                        companyLocationData={companyLocationData}
                    />
                )}

            {!fromDashboard() && (
                <Grid
                    container
                    item
                    xs={fromDashboard() ? 12 : 5}
                    sx={{ marginTop: "1rem" }}
                >
                    <FormControl variant="filled" fullWidth>
                        <InputLabel ref={inputLabel} id="service-behavior">
                            Service behavior
                        </InputLabel>
                        <Select
                            sx={selectFieldStyling}
                            disableUnderline
                            value={serviceBehavior}
                            id="service-behavior"
                            label="Service Behavior"
                            onChange={handleServiceBehaviorChange}
                        >
                            <MenuItem value="EXTERNAL">
                                Public (bookable by clients and admins)
                            </MenuItem>
                            <MenuItem value="INTERNAL">
                                Private (bookable by admins only)
                            </MenuItem>
                            <MenuItem value="MANUAL">
                                Contact us form (client must complete form to
                                book this service type)
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            )}

            {!fromDashboard() && (
                <Grid container item xs={12} sx={{ marginTop: "1rem" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={taxExempt}
                                onChange={(event) =>
                                    setTaxExempt(event.target.checked)
                                }
                                value="active"
                                color="primary"
                                inputProps={{
                                    "aria-label": "primary checkbox"
                                }}
                            />
                        }
                        label="Tax Exempt"
                    />
                </Grid>
            )}
            {!fromDashboard() && (
                <Grid container xs={12}>
                    <Grid item xs={fromDashboard() ? 6 : 2.5}>
                        <TextField
                            variant="filled"
                            sx={{
                                ...textFieldStyling,
                                width: "calc(100% - 8px)"
                            }}
                            label="Tax Override"
                            id="service-tax-override"
                            type="number"
                            value={taxExempt ? "" : taxOverride}
                            error={errTaxOverride}
                            disabled={taxExempt}
                            helperText={
                                errTaxOverride &&
                                "Please enter a positive number "
                            }
                            onChange={handleChangeTaxOverride}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title="Override regular tax rates for this service"
                                            placement="bottom"
                                            sx={{
                                                color: "#a2a2a2",
                                                fontSize: "20px"
                                            }}
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={2}>
                {userHasMarketboxAdminRole() && state.mode === "View" && (
                    <Grid item xs={12}>
                        <TextField
                            label="Display Order"
                            id="display-order"
                            type="number"
                            value={order}
                            className={clsx(
                                componentClasses.margin,
                                componentClasses.textField
                            )}
                            readOnly
                            margin="normal"
                        />
                    </Grid>
                )}

                <Grid
                    container
                    item
                    xs={fromDashboard() ? 11 : 5}
                    sx={{ gap: "1rem", marginTop: "1rem" }}
                >
                    <Button
                        sx={{ boxShadow: "none" }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveServiceType("addSingle")}
                        disabled={isView || serviceName === ""}
                    >
                        {fromDashboard() ? "Save & Next" : "Save"}
                    </Button>

                    {!fromDashboard() && (
                        <Button
                            sx={{ boxShadow: "none" }}
                            variant="contained"
                            color="primary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    )}
                </Grid>
            </Grid>

            <CategoryAddModal
                open={displayCategoryPortal}
                originPath={"DashboardForm"}
                returnPath={"ServiceAdd"}
                close={closeCategoryPortal}
                updateCategoryValue={updateCategoryValue}
            />
        </>
    );
}

export default ServiceAdd;
