import { CloseRounded } from "@mui/icons-material";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    IconButton,
    Alert,
    DialogTitle,
    DialogActions,
    Link
} from "@mui/material";
import React, { useState, useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as queries from "../graphql/queries";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import RecurringBookingSelector from "./RecurringBookingSelector";
import moment from "moment";
import { getUserFromCache } from "../user/UserCommon";
import { graphql, graphqlOperation, postApi } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import { getBookingDateDescription } from "../modules/TimeService";
import { auditOverrideBooking } from "../modules/Audit";
import {
    checkSchedule,
    getCompanyLocation,
    getCompanyLocationId,
    getProviderLocation,
    getProviderLocationId,
    getScheduleLocationTz,
    makeProviderScheduled,
    populateDisplayMap
} from "../modules/ReschedulingFunctions";
import {
    checkProviderAvailabilityForRechedule,
    checkProviderAvailabilityForRecurringReschedule
} from "../modules/CheckAvailability";
import ReschedulePolicyModal from "./ReschedulePolicyModal";
import { generateRRULE } from "../modules/RecurringBookingsUtilityFunctions";
import { extractLocationIdFromScheduleinfo } from "../utils/providerLocationUtils";
import { createProviderScheduleBK } from "../modules/ScheduleService";

function ClientRescheduleModal(props) {
    const [dateValue, setDateValue] = useState(null);
    const [displayDates, setDisplayDates] = useState({});
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [timeZone, setTimeZone] = useState("");
    const [timeValue, setTimeValue] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [error, setError] = useState(false);
    const [showCircular, setShowCircular] = useState(false);
    const [disableCalendar, setDisableCalendar] = useState(false);
    const [showReschedulePolicyModal, setShowReschedulePolicyModal] =
        useState(false);
    const [showProviderUnavailableModal, setShowProviderUnavailableModal] =
        useState(false);
    const [policyTime, setPolicyTime] = useState(24);
    const [showCloseBtn, setShowCloseBtn] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(false);
    // const [origDate, setOrigDate] = useState(false);

    //recurring booking override states
    const [rrule, setRrule] = useState(null);
    const [parsedRrule, setParsedRrule] = useState(null);
    const [originalRrule, setOriginalRrule] = useState(null);
    const [rruleList, setRruleList] = useState(null);
    const [rruleLabel, setRruleLabel] = useState(null);
    const [rruleChanged, setRruleChanged] = useState(false);
    const [xoccurChanged, setXoccurChanged] = useState(false);
    const [isRecurrenceShown, setIsRecurrenceShown] = useState(false);
    const [
        recurringBookingAllOrSelectedOverride,
        setRecurringBookingAllOrSelectedOverride
    ] = useState(false);
    useEffect(() => {
        const fetchSchedule = async (data) => {
            let dd;

            const loggedInUser = getUserFromCache();
            if (loggedInUser?.company?.cancelPolicyMinsBefore) {
                let hour = loggedInUser.company.cancelPolicyMinsBefore / 60;
                setPolicyTime(hour);
            }
            const sch = await checkSchedule(data);
            if (sch && sch.length > 0 && sch[0].slots.length > 0) {
                dd = populateDisplayMap(sch[0].slots);
                setDisplayDates(dd);
                let d = dd[Object.keys(dd)[0]];

                let d_formatted = moment(d.date).format("YYMMDD");
                if (data?.startdate) {
                    let dt = moment(data.startdate).format("YYMMDD");
                    let t = new Date(data.startdate);
                    if (!dd[dt]) {
                        dt = Object.keys(dd)[0];
                        t = new Date(dd[dt].slots[0].datetime);
                    }
                    let tz = t.toLocaleDateString("en", {
                        timeZone: data.timezone,
                        timeZoneName: "short"
                    });
                    if (tz.includes(",")) {
                        let split_tz = tz.split(",");
                        setTimeZone(split_tz[1]);
                    }
                    let first_slot = dd[dt].slots[0].start24.split(":");
                    t.setHours(+first_slot[0]);
                    t.setMinutes(+first_slot[1]);
                    setDateValue(t);
                    setSelectedSlots(dd[dt].slots);
                    setTimeValue(dd[dt].slots[0].start24);
                }
            }
        };

        if (props?.selectedRowData) {
            try {
                fetchSchedule(props.selectedRowData);
                if (props.selectedRowData.orderType === "ONGOING") {
                    fetchRecurringInfo(props.selectedRowData);
                }
            } catch (err) {
                console.log("Fetching Schedule Error", err);
            }
        }
    }, [props.selectedRowData]);

    useEffect(() => {
        if (selectedSlots && selectedSlots.length > 0) {
            console.log("Selected Slots", selectedSlots);
        }
    }, [selectedSlots]);

    const resetStates = () => {
        setDateValue(null);
        setDisplayDates({});
        setSelectedSlots([]);
        setTimeZone("");
        setTimeValue("");
        setShowCloseBtn(true);
        setOriginalRrule(null);
        setRrule(null);
        setParsedRrule(null);
        setRruleLabel(null);
        setRruleChanged(false);
        setXoccurChanged(false);
        setIsRecurrenceShown(false);
        setShowProviderUnavailableModal(false);
    };

    const formatDate = (d) => {
        /* console.log("Date Value from format Date", d); */
        let date = moment(d).format("ll");
        let day = moment(d).format("dddd");
        return day + ", " + date;
    };

    function closeModalHandler() {
        resetStates();
        if (recurringBookingAllOrSelectedOverride) {
            handleCloseRecurringBookingAllOrSelected();
        }
        props.closeRescheduleModal();
    }

    function toggleShowRecurrence() {
        setIsRecurrenceShown(!isRecurrenceShown);
    }

    const handleCloseRecurringBookingAllOrSelected = () => {
        setRecurringBookingAllOrSelectedOverride(false);
    };

    const dateChangeHandler = (newValue) => {
        setDisableSubmit(false);
        let d_formatted = moment(newValue).format("YYMMDD");
        let d = displayDates[d_formatted];
        setSelectedSlots(d.slots);

        let actual_date = new Date(newValue);
        let t = d.slots[0].start24.split(":");
        actual_date.setHours(+t[0]);
        actual_date.setMinutes(+t[1]);
        setTimeValue(d.slots[0].start24);
        //console.log("Actual Date", actual_date);
        if (
            actual_date.getDay() !== dateValue.getDay() &&
            props.selectedRowData.orderType === "ONGOING"
        ) {
            setRruleChanged(true);
            let newRrule = generateRRULE(
                parsedRrule.frequency,
                parsedRrule.interval ? parsedRrule.interval : null,
                [(new Date(newValue).getDay() + 6) % 7],
                parsedRrule.until
                    ? new Date(`${parsedRrule.until}T14:00:00Z`)
                    : null,
                parsedRrule.xoccur
            );
            setRrule(newRrule);
            setParsedRrule(parseRRuleForSelector(newRrule));
        }
        setDateValue(actual_date);
    };

    const timeChangeHandler = (e) => {
        setDisableSubmit(false);
        let val = e.target.value.split(":");
        let hours = val[0];
        let minutes = val[1];
        let new_date = new Date(dateValue);
        new_date.setHours(+hours);
        new_date.setMinutes(+minutes);
        setDateValue(new_date);
        setTimeValue(e.target.value);
    };

    const closeReschedulePolicyModal = () => {
        setShowReschedulePolicyModal(false);
    };

    const closeProviderUnavailableModal = () => {
        setShowProviderUnavailableModal(false);
    };

    function onRruleChange(newRrule) {
        if (newRrule !== rrule) {
            setRruleChanged(true);
        }
        setRrule(newRrule);
        setParsedRrule(parseRRuleForSelector(newRrule));
        const rruleLabel = constructRruleLabel(newRrule);
        setRruleLabel(rruleLabel);
        //bookingState.rrule = rrule;
    }
    function onXoccurChange(value) {
        setXoccurChanged(value);
        setRruleChanged(true);
        const rruleLabel = constructRruleLabel(rrule);
        setRruleLabel(rruleLabel);
    }

    //Function to fetch the recurring booking information for a booking, given the booking data
    const fetchRecurringInfo = async (data) => {
        try {
            //First, fetch the repeatApptCreationRecord assuming datakey starting with "O-" (recurring order has no until date)
            let result = await graphql(
                graphqlOperation(queries.getRepeatApptCreation, {
                    id: data.companyId,
                    datakey: `O-${data.order.id}`
                })
            );

            let repeatApptRecord = result.data.getRepeatApptCreation;
            repeatApptRecord.extented = JSON.parse(
                result.data.getRepeatApptCreation.extented
            );

            //Second, check for an rrule, if there is none then construct one
            //an rrule will exist for any repeatApptCreation record created after MBX-36 was released... unless there was an error ofcourse
            //we'll need to construct an rrule for any recurring booking created before the release of MBX-36
            let rruleString;
            if (!repeatApptRecord?.extented?.rrule) {
                //constructRRuleFromRepeatApprCreation will setRrule , which is then passed to RecurringBookingSelector
                rruleString = await constructRRuleFromRepeatApptCreation(
                    repeatApptRecord,
                    data.bookingDate
                );
            } else {
                rruleString = await getRelevantRRule(
                    data.bookingDate,
                    repeatApptRecord.extented.rrule
                );
                setRruleList(repeatApptRecord.extented.rrule);
                setRrule(rruleString);
                setParsedRrule(parseRRuleForSelector(rruleString));
                setOriginalRrule(rruleString);
            }

            //Converts the rruleString into a "user-friendly", easy to read label
            //it also will setRruleLabel
            const rruleLabel = constructRruleLabel(rruleString, data.startdate);
            setRruleLabel(rruleLabel);
        } catch (e) {
            console.log("Error fetching recurringApptCreation record", e);
        }
    };

    async function constructRRuleFromRepeatApptCreation(
        repeatApptRecord,
        bookingStartDate
    ) {
        const repeatCount = repeatApptRecord.repeatCount;
        let repeatUnit = repeatApptRecord.repeatUnit;
        const daysOfWeek = repeatApptRecord.extented.daysOfWeek;

        let selectedDays;
        const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
        if (daysOfWeek && daysOfWeek.length > 0) {
            selectedDays = days.filter((day, index) => daysOfWeek[index]);
        } else {
            let dateString = bookingStartDate;
            const date = moment(dateString, "YYYY-MM-DD");
            const dayOfWeek = date.day();
            selectedDays = [days[dayOfWeek]];
        }
        if (repeatUnit === "weeks") {
            repeatUnit = "WEEKLY";
        } else if (repeatUnit === "months") {
            repeatUnit = "MONTHLY";
        } else if (repeatUnit === "years") {
            repeatUnit = "YEARLY";
        }

        // Construct the RRULE string
        let rruleString = `FREQ=${repeatUnit.toUpperCase()};`;
        if (repeatCount) {
            rruleString += `INTERVAL=${repeatCount};`;
        }

        if (selectedDays.length > 0) {
            rruleString += `BYDAY=${selectedDays.join(",")}`;
        }

        const rruleList = [rruleString];
        setRruleList(rruleList);
        setRrule(rruleString);
        setParsedRrule(parseRRuleForSelector(rruleString));
        setOriginalRrule(rruleString);
        return rruleString;
    }

    /**
     * Retrieves the first rrule from the rrule list that pertains to the given selected date.
     *
     * @param {string} selectedDate - The selected date in 'YYYY-MM-DD' format.
     * @param {Array<string>} rruleList - List of rrules in string format.
     * @returns {string|null} The first relevant rrule, or null if none match the criteria.
     */
    async function getRelevantRRule(selectedDate, rruleList) {
        for (const rrule of rruleList) {
            const untilMatch = /UNTIL=([0-9-]+)|$/.exec(rrule);
            const untilDate = untilMatch ? untilMatch[1] : null;

            if (!untilDate || untilDate >= selectedDate) {
                return rrule;
            }
        }

        // Return null if no matching rule is found
        return null;
    }

    function parseRRuleForSelector(rrule) {
        const components = rrule.split(";");
        const parsedRule = {
            frequency: "",
            interval: 1,
            days: [],
            until: null,
            xoccur: null
        };

        components.forEach((component) => {
            const [key, value] = component.split("=");
            switch (key) {
                case "FREQ":
                    if (value == "WEEKLY") {
                        parsedRule.frequency = "weeks";
                    } else if (value == "MONTHLY") {
                        parsedRule.frequency = "months";
                    } else if (value == "YEARLY") {
                        parsedRule.frequency = "years";
                    }
                    break;
                case "INTERVAL":
                    parsedRule.interval = parseInt(value, 10);
                    break;
                case "BYDAY":
                    if (value) {
                        if (value === "NUMBER" || value === "WEEK") {
                            parsedRule.days = value;
                            break;
                        }
                        const daysArray = value.split(",");
                        daysArray.forEach((day) => {
                            parsedRule.days.push(convertDayToNumber(day));
                        });
                        //sort parsedRule.days in ascending order
                        parsedRule.days.sort((a, b) => a - b);
                    }
                    break;
                case "UNTIL":
                    parsedRule.until = value;
                    break;
                case "XOCCUR":
                    parsedRule.xoccur = value;
                    break;
                default:
                    // Handle other RRULE components if necessary
                    break;
            }
        });

        return parsedRule;
    }

    /**
     * Converts an rruleString into a user-friendly sentence for easy comprehension.
     *
     * @param {string} rruleString - The input rruleString to be converted, e.g., "FREQ=MONTHLY;INTERVAL=1;BYDAY=WEEK;UNTIL=2024-03-31".
     * @param {string} [bookingDate] - Optional booking date in the format "YYYY-MM-DD".
     *
     * @returns {string} A user-friendly sentence describing the recurrence rule.
     * For example, "Occurs on the last Friday of every month, until 2024-03-31"
     */
    function constructRruleLabel(rruleString, bookingDate) {
        // Parse the rruleString into an object
        const rruleObject = parseRruleString(rruleString);

        // Extract relevant information
        const freq = rruleObject.FREQ || "";
        const interval = rruleObject.INTERVAL || 1;
        const byDay = rruleObject.BYDAY || "";
        let until = rruleObject.UNTIL || "";
        const xOccur = rruleObject.XOCCUR || "";

        const inputDate = new Date(
            dateValue ? dateValue : props.selectedRowData
        );

        const options = { month: "2-digit", day: "2-digit", year: "numeric" };
        const startingDate = inputDate.toLocaleDateString("en-US", options);
        if (until) {
            until = new Date(`${until}T00:00:00`).toLocaleDateString(
                "en-US",
                options
            );
        }
        //until = new Date(until);
        // Build the user-friendly label
        let label = `Occurs`;
        //BYDAY
        if (byDay && freq == "WEEKLY") {
            label += ` on ${formatByDay(byDay)}`;
        } else if (byDay == "NUMBER" && freq == "MONTHLY") {
            const date = bookingDate
                ? new Date(bookingDate)
                : new Date(dateValue);
            const dayOfMonth = date.getDate();
            label += ` on day ${dayOfMonth}`;
        } else if (byDay == "WEEK" && freq == "MONTHLY") {
            const date = bookingDate
                ? new Date(bookingDate)
                : new Date(dateValue);
            const dayOfWeek = date.toLocaleDateString("en-US", {
                weekday: "long"
            });
            const weekOccurence = Math.floor((date.getDate() - 1) / 7) + 1;
            const occurenceLabels = [
                "first",
                "second",
                "third",
                "fourth",
                "last"
            ];

            label += ` on the ${
                occurenceLabels[weekOccurence - 1]
            } ${dayOfWeek}`;
        } else if (freq == "YEARLY") {
            const date = new Date(dateValue);
            const dayOfWeek = date.toLocaleDateString("en-US", {
                weekday: "long"
            });
            label += ` on the same day`;
        }
        //FREQ/INTERVAL
        if (freq == "WEEKLY") {
            if (interval > 1) {
                label += ` every ${interval} weeks`;
            } else {
                label += ` every week`;
            }
        } else if (freq == "MONTHLY") {
            if (interval > 1) {
                label += ` every ${interval} months`;
            } else {
                label += ` of every month`;
            }
        } else if (freq == "YEARY") {
            if (interval > 1) {
                label += ` every ${interval} years`;
            } else {
                label += ` every year`;
            }
        }
        if (rruleChanged) {
            label += ` starting ${startingDate}`;
        }
        //XOCCUR/UNTILL
        /* if (xOccur && rruleChanged && !xoccurChanged) {
            label += `, for all remaining sessions`;
        } else */ if (xOccur) {
            label += `, for a total of ${xOccur} sessions`;
        } else if (until) {
            label += `, until ${until}`;
        }

        //setRruleLabel(label);
        return label;
    }

    function convertDayToNumber(day) {
        const dayMappings = {
            MO: 0,
            TU: 1,
            WE: 2,
            TH: 3,
            FR: 4,
            SA: 5,
            SU: 6
        };
        return dayMappings[day];
    }

    // Helper function to format BYDAY field
    function formatByDay(byDay) {
        const days = byDay.split(",");
        const formattedDays = days.map(getFullDayString);
        return formattedDays.join(", ");
    }

    // Helper function to parse rruleString into an object
    function parseRruleString(rruleString) {
        const rruleObject = {};
        const parts = rruleString.split(";");
        parts.forEach((part) => {
            const [key, value] = part.split("=");
            rruleObject[key] = value;
        });
        return rruleObject;
    }

    function getFullDayString(day) {
        const dayMap = {
            MO: "Monday",
            TU: "Tuesday",
            WE: "Wednesday",
            TH: "Thursday",
            FR: "Friday",
            SA: "Saturday",
            SU: "Sunday"
        };

        return dayMap[day] || day; // If the day is not found in the map, return the original value
    }

    async function handleOverrideRecurringBookings(bookingState) {
        try {
            let loggedInUser = getUserFromCache();
            setRecurringBookingAllOrSelectedOverride(false);
            setShowBackdrop(true);

            //first, check if the client is outside the cancellation time
            if (loggedInUser?.company?.cancelPolicyMinsBefore) {
                let s_date = new Date(bookingState.startdate);
                let t_date = new Date();

                //calculate the difference in time
                let diffInMilliseconds = s_date - t_date;
                let diffInMinutes = diffInMilliseconds / 60000;
                console.log(
                    "Policy Check Dates",
                    s_date,
                    t_date,
                    diffInMinutes
                );

                if (
                    diffInMinutes < loggedInUser.company.cancelPolicyMinsBefore
                ) {
                    setShowCircular(false);
                    setDisableCalendar(false);
                    setDisableSubmit(false);
                    setShowCloseBtn(true);
                    setShowReschedulePolicyModal(true);
                    setShowBackdrop(false);
                    return;
                }
                // setShowCircular(false);
                //setDisableCalendar(false);
                //setDisableSubmit(false);
                // console.log("Policy Check Dates", s_date, b_date, diffInMinutes);
            }

            if (new Date(dateValue) === new Date(bookingState.startdate)) {
                setShowCircular(false);
                setDisableCalendar(false);
                setShowCloseBtn(true);
                setShowBackdrop(false);
                //setDisableSubmit(true);
                return;
            }

            const startDate = moment(dateValue).format("YYYY-MM-DD");
            const startTime = moment(dateValue).format("HH:mm");
            const endTime = moment(dateValue)
                .add(bookingState.minutes, "minutes")
                .format("HH:mm");
            const providerScheduleId = makeProviderScheduled(
                bookingState.timeblockid
            );

            let isRemoteLocation = providerScheduleId.includes("|PL-")
                ? true
                : false;
            let locationData = await getScheduleLocationTz(providerScheduleId);
            let timezone = locationData.timezone;
            let dateInfo = await getBookingDateDescription(
                dateValue,
                timezone,
                bookingState.minutes
            );
            let isAvailableForAll =
                await checkProviderAvailabilityForRecurringReschedule(
                    bookingState,
                    dateInfo,
                    originalRrule,
                    rrule,
                    rruleChanged,
                    xoccurChanged
                );
            if (!isAvailableForAll) {
                setShowCircular(false);
                setDisableCalendar(false);
                setDisableSubmit(false);
                setShowCloseBtn(true);
                setShowProviderUnavailableModal(true);
                setShowBackdrop(false);
                return;
            }

            let body = {
                companyId: bookingState.companyId,
                orderId: bookingState.orderId,
                selectedSlot: {
                    scheduleId: providerScheduleId,
                    dateInfo: dateInfo,
                    slot12: dateInfo.tm_st_disp,
                    label: startTime,
                    tz: dateInfo.tz
                },
                bookingData: {
                    location: bookingState.location,
                    serviceType: bookingState.servicetype,
                    client: {
                        firstName: bookingState.client.user.firstname,
                        lastName: bookingState.client.user.lastname,
                        id: bookingState.client.id
                    },
                    provider: {
                        firstName: bookingState.provider.firstname,
                        lastName: bookingState.provider.lastname,
                        id: bookingState.provider.id
                    },
                    isVirtual: bookingState.isVirtual,
                    isRemoteLocation,
                    rrule: rrule,
                    rruleList: rruleList,
                    rruleChanged: rruleChanged,
                    xoccurChanged: xoccurChanged,
                    locationId:
                        extractLocationIdFromScheduleinfo(providerScheduleId),
                    latitude: locationData.latitude,
                    longitude: locationData.longitude
                },
                originalBooking: {
                    id: bookingState.id,
                    startDate: bookingState.bookingDate
                }
            };
            if (bookingState.Notes) {
                body.bookingData.Notes = JSON.stringify([
                    {
                        createdBy: bookingState.Notes.createdBy,
                        createdAt: bookingState.Notes.createdAt,
                        notes: bookingState.notes
                    }
                ]);
            }
            try {
                await postApi(
                    "recurringBookingsApi",
                    "/edit-recurring-bookings",
                    {
                        body
                    }
                );
            } catch (e) {
                console.log("recurringBookingsAPI response timed out", e);
                setTimeout(() => {
                    // Your code to be executed after the delay
                    console.log("Continuing after 15 seconds");
                }, 15000); // 15 seconds in milliseconds
            }
            setIsRecurrenceShown(false);
            setDisableSubmit(false);

            setDisableCalendar(false);
            setShowCircular(false);
            setShowBackdrop(false);
            props.refreshTable();
            closeModalHandler();

            props.openSnackbar("Success! Your bookings have been rescheduled");
        } catch (e) {
            console.log("error while creating recurring bookings", e);
            setDisableSubmit(false);
            setShowBackdrop(false);

            setDisableCalendar(false);
            setShowCircular(false);
        }
    }

    const submitReschedulingHandler = async (data) => {
        setShowBackdrop(true);
        try {
            let loggedInUser = getUserFromCache();
            setShowCircular(true);
            setDisableSubmit(true);
            setShowCloseBtn(false);

            if (loggedInUser?.company?.cancelPolicyMinsBefore) {
                let s_date = new Date(data.startdate);
                let t_date = new Date();

                //calculate the difference in time
                let diffInMilliseconds = s_date - t_date;
                let diffInMinutes = diffInMilliseconds / 60000;
                console.log(
                    "Policy Check Dates",
                    s_date,
                    t_date,
                    diffInMinutes
                );

                if (
                    diffInMinutes < loggedInUser.company.cancelPolicyMinsBefore
                ) {
                    setShowCircular(false);
                    setDisableCalendar(false);
                    setDisableSubmit(false);
                    setShowCloseBtn(true);
                    setShowReschedulePolicyModal(true);
                    setShowBackdrop(false);
                    return;
                }
            }

            if (new Date(dateValue) === new Date(data.startdate)) {
                console.log("date matches");
                setShowCircular(false);
                setDisableCalendar(false);
                setShowCloseBtn(true);
                setShowBackdrop(false);
                //setDisableSubmit(true);
                return;
            }
            const user = getUserFromCache();
            let locationData;
            let companyLocationId;
            let providerLocationId;
            const providerScheduleId = makeProviderScheduled(data.timeblockid);

            if (data.timeblockid.includes("CL-")) {
                companyLocationId = await getCompanyLocationId(
                    data.timeblockid
                );

                locationData = await getCompanyLocation(companyLocationId);
            }

            if (data.timeblockid.includes("PL-")) {
                providerLocationId = await getProviderLocationId(
                    data.timeblockid
                );
                locationData = await getProviderLocation(providerLocationId);
                // isRemoteLoc = true;
            }
            let coords = {
                lat: locationData.latitude,
                lng: locationData.longitude
            };
            let newDate = `${moment(dateValue).format("ll")} at ${moment(
                dateValue
            ).format("LT")}`;

            console.log("LoggedInUser", user);
            console.log("Selected Row Data", data);
            console.log("Provider Schedule ID", providerScheduleId);

            // Delete old timeblocks and create new ones
            const timeblock = data.timeblockid.split("::");
            const input = {
                id: timeblock[0],
                scheduleinfo: timeblock[1]
            };

            //if the provider is same and booking date and time is same, no need to check availability

            let skipavailabilitycheck = false;
            if (
                providerScheduleId &&
                providerScheduleId.includes(data.providerId)
            ) {
                console.log("if providerScheduleId providerScheduleId");
                const currentdatetime = new Date(data.startdate);
                const selecteddatetime = new Date(dateValue);
                if (currentdatetime && selecteddatetime) {
                    console.log("currentdatetime && selecteddatetime");
                    if (
                        currentdatetime.valueOf() === selecteddatetime.valueOf()
                    ) {
                        console.log(
                            " currentdatetime.valueOf() === selecteddatetime.valueOf()"
                        );
                        skipavailabilitycheck = true;
                    }
                }
            }
            console.log("Skip Availabily Check", skipavailabilitycheck);

            const startDate = moment(dateValue).format("YYYY-MM-DD");
            const startTime = moment(dateValue).format("HH:mm");
            const endTime = moment(dateValue)
                .add(data.minutes, "minutes")
                .format("HH:mm");

            //if not available check
            if (!skipavailabilitycheck) {
                console.log("SkipAvailabilityCheck if");
                let isBookedTimeAvailable =
                    await checkProviderAvailabilityForRechedule({
                        timezone: locationData.timezone,
                        startdate: startDate,
                        startTime,
                        endTime,
                        geoLoc: coords,
                        overriddenbookedslot: input,
                        bookingIncrement: data.company?.bookingIncrement
                            ? data.company?.bookingIncrement
                            : 15 //does not have impact of checking availability but for the sake of consistency with the other similar functions.
                    });
                console.log("isBookedTimeAvailable", isBookedTimeAvailable);

                if (!isBookedTimeAvailable) {
                    setShowCircular(false);
                    setDisableSubmit(false);
                    setDisableCalendar(false);
                    setShowCloseBtn(true);
                    setShowBackdrop(false);
                    setError(true);
                    return;
                }

                await graphql(
                    graphqlOperation(mutations.deleteProviderSchedule, {
                        input
                    })
                );

                let timezone = locationData.timezone;
                let dateInfo = await getBookingDateDescription(
                    dateValue,
                    timezone,
                    data.minutes
                );
                let bookedtimeblockData = {
                    companyId: data.companyId,
                    startDate: startDate,
                    endDate: startDate,
                    startTime,
                    endTime,
                    type: "BOOKED",
                    providerId: data.provider.id,
                    status: "CONFIRMED",
                    tz: timezone,
                    sdtutc: dateInfo.dtstamp_str,
                    locationId: extractLocationIdFromScheduleinfo(
                        input.scheduleinfo
                    ),
                    latitude: locationData.latitude,
                    longitude: locationData.longitude
                };

                const newTb =
                    await createProviderScheduleBK(bookedtimeblockData);

                // Update booking
                const bookingInput = {
                    id: data.id,
                    startdate: dateInfo.dtstamp_str,
                    bookingProviderId: data.provider.id,
                    providerId: data.provider.id,
                    timeblockid: newTb.id,
                    timezone,
                    TimeDisplayInfo: JSON.stringify(dateInfo)
                };

                if (
                    !data.isVirtual &&
                    locationData.locationType === "CL" &&
                    locationData.addressoneline
                ) {
                    bookingInput.location = locationData.addressoneline;
                }
                const result = await graphql(
                    graphqlOperation(mutations.updateBooking, {
                        input: bookingInput
                    })
                );

                await auditOverrideBooking(
                    user,
                    data,
                    result.data.updateBooking,
                    "OVERRIDE"
                );

                let old_booking_datetime = "";
                if (data.TimeDisplayInfo) {
                    let old_dateInfo = JSON.parse(data.TimeDisplayInfo);
                    old_booking_datetime = `${old_dateInfo.dt_full_disp}`;
                } else {
                    old_booking_datetime = moment(data.startdate).format(
                        "MMM DD, YYYY [at] h:mm a"
                    );
                }

                console.log("Result after Updating", result);
                //SEnd Emails

                if (result && result.data) {
                    const dataObj = {
                        client_firstname: data.client.user.firstname,
                        client_lastname: data.client.user.lastname,
                        servicetype_name: data.servicetype.name,
                        old_booking_datetime,
                        changedto_booking_datetime: `${dateInfo.dt_long_disp}`,
                        changedto_booking_time: `${dateInfo.tm_st_disp}`,
                        changedto_provider_firstname: data.provider.firstname,
                        changedto_provider_lastname: data.provider.lastname,
                        changedto_booking_location: bookingInput.location
                            ? bookingInput.location
                            : data.location,
                        booking_service: data.serviceName,
                        company_email: data.company.emailaddress,
                        company_name: data.company.name,
                        isRegisteredUser: data.client.user.registered
                    };
                    let ccAddresses = [data.company.emailaddress];
                    if (data.company && data.company.emailaddress) {
                        ccAddresses = ccAddresses.concat([
                            data.company.emailaddress
                        ]);
                    }
                    const result = await postApi(
                        "sendtwilioemail",
                        "/sendtwilioemailtemplate",
                        {
                            body: {
                                templateName: "appt-change-provider.html",
                                subject: `${data.provider.firstname}, your upcoming appointment has changed.`,
                                body: null,
                                toAddresses: [data.provider.emailaddress],
                                ccAddresses: ccAddresses,
                                replyTo: data.company.replyemailaddress
                                    ? data.company.replyemailaddress
                                    : data.company.emailaddress,
                                companyName: data.company.name,
                                companyId: data.company.id,
                                dataObj: dataObj,
                                companyLogoUrl: data.company.logoUrl,
                                companyColor: data.company.primaryColor,
                                authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                            }
                        }
                    );

                    console.log("Email Result", result);
                }
                setDisableSubmit(false);

                setDisableCalendar(false);
                setShowCircular(false);
                setShowBackdrop(false);
                //close reschdeule modal
                //resetStates();

                props.refreshTable();
                //props.closeRescheduleModal();
                closeModalHandler();

                props.openSnackbar(
                    "Your booking has been rescheduled for " + newDate
                );
                //alert("Booking changed successfully");
                //setMsgOpen(true);
            }
        } catch (err) {
            console.log("Error Submitting ClientRescheduling", err);
            setDisableSubmit(false);
            setShowBackdrop(false);

            setDisableCalendar(false);
            setShowCircular(false);
        }
    };
    return (
        <>
            {showBackdrop && (
                <Backdrop
                    open={showBackdrop}
                    sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
            )}
            <Dialog
                open={props.rescheduleModal}
                //fullWidth={true}
                //maxWidth={"sm"}
                onClose={() => {
                    closeModalHandler();
                }}
                PaperProps={{
                    style: {
                        maxWidth: "700px"
                    }
                }}
            >
                <DialogContent sx={{ padding: "1.5rem" /* width: "700px" */ }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            paddingLeft: "1.25rem"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                        >
                            Reschedule booking
                        </Typography>
                        {showCloseBtn && (
                            <CloseRounded
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    //setError(false);
                                    //props.closeRescheduleModal();
                                    closeModalHandler();
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{ marginBottom: "1rem", paddingLeft: "1.25rem" }}
                    >
                        <Typography sx={{ color: "rgba(0,0,0,0.5)" }}>
                            {props?.selectedRowData
                                ? props.selectedRowData.serviceName
                                : ""}
                        </Typography>
                    </div>

                    <Grid
                        container
                        sx={{ flexWrap: "nowrap", paddingRight: "24px" }}
                    >
                        <Grid item /* xs={12} sm={6} */>
                            <Box
                                sx={{
                                    "& .MuiPickerStaticWrapper-content": {
                                        "& div": {
                                            marginLeft: 0
                                        }
                                    },
                                    "& .PrivatePickersSlideTransition-root": {
                                        minHeight: "230px",
                                        marginBottom: 0,
                                        overflowY: "hidden"
                                    }
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={customLocale}
                                >
                                    <StaticDatePicker
                                        sx={{
                                            marginLeft: "0px"
                                        }}
                                        disabled={disableCalendar}
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        minDate={new Date()}
                                        onChange={dateChangeHandler}
                                        value={dateValue}
                                        PopperProps={{
                                            disablePortal: true,
                                            placement: "bottom-end"
                                        }}
                                        shouldDisableDate={(newValue) => {
                                            let disable = false;
                                            if (
                                                !displayDates[
                                                    moment(newValue).format(
                                                        "YYMMDD"
                                                    )
                                                ]
                                            ) {
                                                disable = true;
                                                //console.log("Unavailable Date", d);
                                            }

                                            return disable;
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            item
                            /* xs={12}
                            sm={6} */
                            sx={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginTop: "20px",
                                paddingLeft: "1rem"
                            }}
                        >
                            <Typography
                                sx={{ fontWeight: 700, fontSize: "1rem" }}
                            >
                                {dateValue && formatDate(dateValue)}
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: "18px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem"
                                    /* width: "175px" */
                                }}
                            >
                                <div>
                                    <Typography
                                        sx={{
                                            fontSize: "0.875rem",
                                            color: "rgba(0,0,0,0.75)"
                                        }}
                                    >
                                        Available slots
                                    </Typography>
                                </div>
                                <div>
                                    {selectedSlots &&
                                        selectedSlots.length > 0 && (
                                            <Select
                                                sx={{
                                                    "& legend": {
                                                        display: "none"
                                                    },
                                                    "& fieldset": { top: 0 }
                                                }}
                                                label="Select time"
                                                labelId="provider-time"
                                                value={timeValue}
                                                onChange={timeChangeHandler}
                                            >
                                                {selectedSlots.length > 0 &&
                                                    selectedSlots.map(
                                                        (slot, index) => (
                                                            <MenuItem
                                                                key={
                                                                    slot.start24
                                                                }
                                                                value={
                                                                    slot.start24
                                                                }
                                                            >
                                                                {slot.start12 +
                                                                    " " +
                                                                    timeZone}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        )}
                                </div>
                                {props?.selectedRowData?.orderType ===
                                    "ONGOING" && (
                                    <div>
                                        <Alert
                                            severity={"warning"}
                                            sx={{
                                                padding: "0px 8px 0px 12px",
                                                width: "306px",
                                                marginTop: "14px",
                                                fontFamily: "Roboto",
                                                "& .MuiAlert-icon": {
                                                    alignSelf: "center"
                                                },
                                                color: "primary.dark",
                                                backgroundColor:
                                                    "primary.light",
                                                borderColor: "primary.light"
                                            }}
                                            variant="outlined"
                                            icon={false}
                                        >
                                            This booking is part of a recurring
                                            booking set
                                        </Alert>
                                        <Grid
                                            className="toggle-container"
                                            onClick={toggleShowRecurrence}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                gap: "4px",
                                                marginTop: "14px"
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    padding: "0px",
                                                    color: "primary.main"
                                                }}
                                            >
                                                <KeyboardArrowDownIcon
                                                    sx={{
                                                        transform: `rotate(${
                                                            isRecurrenceShown
                                                                ? "180deg"
                                                                : "0deg"
                                                        })`,
                                                        transition:
                                                            "transform 0.1s ease-in-out"
                                                    }}
                                                />
                                            </IconButton>
                                            <Typography
                                                id="toggleText"
                                                sx={{
                                                    color: "primary.main",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                {isRecurrenceShown
                                                    ? "Hide recurrence"
                                                    : "Change recurrence"}
                                            </Typography>
                                        </Grid>
                                        {isRecurrenceShown && (
                                            <RecurringBookingSelector
                                                setRrule={onRruleChange} //optional
                                                setXoccurChanged={
                                                    onXoccurChange
                                                } //optional
                                                rrule={rrule} //optional
                                                selectedDate={
                                                    //required
                                                    dateValue
                                                        ? dateValue
                                                        : props?.selectedRowData
                                                                ?.startdate
                                                          ? props
                                                                .selectedRowData
                                                                .startdate
                                                          : new Date()
                                                }
                                                numberSelector={
                                                    parsedRrule?.interval
                                                        ? parsedRrule.interval
                                                        : null
                                                } //optional
                                                chronologicalSelector={
                                                    parsedRrule?.frequency
                                                } //optional
                                                selectedDays={
                                                    //optional
                                                    parsedRrule?.days ===
                                                        "NUMBER" ||
                                                    parsedRrule?.days === "WEEK"
                                                        ? null
                                                        : parsedRrule?.days
                                                }
                                                selectedMonthOption={
                                                    //optional
                                                    parsedRrule?.days ===
                                                        "NUMBER" ||
                                                    parsedRrule?.days === "WEEK"
                                                        ? parsedRrule?.days
                                                        : null
                                                }
                                                endDate={
                                                    parsedRrule?.until
                                                        ? new Date(
                                                              `${parsedRrule?.until}T14:00:00Z`
                                                          )
                                                        : null
                                                } //optional
                                                occurrences={
                                                    parsedRrule?.xoccur
                                                } //optional
                                            />
                                        )}
                                    </div>
                                )}
                            </Box>
                            <Grid
                                conatiner
                                item
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%"
                                }}
                            >
                                <Button
                                    sx={{
                                        marginTop: "2rem",
                                        width: "150px",
                                        textTransform: "uppercase"
                                    }}
                                    disabled={disableSubmit}
                                    variant="contained"
                                    onClick={() => {
                                        //console.log("Date Value", dateValue);
                                        //console.log("Time Value", timeValue);
                                        if (
                                            props.selectedRowData.orderType ==
                                                "ONGOING" &&
                                            rrule !== originalRrule &&
                                            isRecurrenceShown
                                        ) {
                                            handleOverrideRecurringBookings(
                                                props.selectedRowData
                                            );
                                        } else if (
                                            props.selectedRowData.orderType ==
                                            "ONGOING"
                                        ) {
                                            setRecurringBookingAllOrSelectedOverride(
                                                true
                                            );
                                        } else {
                                            submitReschedulingHandler(
                                                props.selectedRowData
                                            );
                                        }
                                    }}
                                >
                                    {disableSubmit && showCircular ? (
                                        <CircularProgress size={"1rem"} />
                                    ) : (
                                        "Reschedule"
                                    )}
                                </Button>
                            </Grid>

                            {/* <Button
                                onClick={() =>
                                    makeProviderScheduled(
                                        props.selectedRowData.timeblockid
                                    )
                                }
                            >
                                Schedule ID
                            </Button> */}
                            {/*  <Button
                                onClick={async () => {
                                    //const providerLocation = await getProviderLocation(locationId)
                                    const s = await checkSchedule(
                                        props.selectedRowData
                                    );
                                    console.log("check schedule S", s);
                                    if (s[0]?.schedule?.avtbs[0].locationId) {
                                        const providerLocation =
                                            await getProviderLocation(
                                                s[0]?.schedule?.avtbs[0].locationId.split(
                                                    "PL-"
                                                )[1]
                                            );
                                        console.log(
                                            "Provider Location",
                                            providerLocation
                                        );
                                    }
                                }}
                            >
                                Check Schedule
                            </Button> */}
                        </Grid>
                    </Grid>
                    {/*error*/}
                    {false && (
                        <Typography
                            variant="caption"
                            sx={{
                                color: "error.main",
                                marginLeft: "1.5rem"
                            }}
                        >
                            This time conflicts with an existing booking. Please
                            select another time.
                        </Typography>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog
                open={recurringBookingAllOrSelectedOverride}
                onClose={handleCloseRecurringBookingAllOrSelected}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    This booking is part of a recurring bookings set
                </DialogTitle>
                <DialogContent sx={{ paddingRight: "10px" }}>
                    <Typography>
                        Would you like to override all upcoming bookings, or
                        just the selected booking?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() =>
                            submitReschedulingHandler(props.selectedRowData)
                        }
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Selected booking
                    </Button>
                    <Button
                        onClick={() =>
                            handleOverrideRecurringBookings(
                                props.selectedRowData
                            )
                        }
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        All upcoming bookings
                    </Button>

                    {/* <Button onClick={getSlotData}>Get Slots Data</Button> */}
                </DialogActions>
            </Dialog>
            <ReschedulePolicyModal
                open={showReschedulePolicyModal}
                onClose={closeReschedulePolicyModal}
                policyTime={policyTime}
            />
            {/* Provider unavailable modal */}
            <Dialog
                open={showProviderUnavailableModal}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={closeProviderUnavailableModal}
            >
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                        >
                            Provider is unavailable
                        </Typography>
                        <InfoOutlinedIcon sx={{ color: "error.main" }} />
                    </Box>
                    <Box sx={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
                        <Typography>
                            The provider is not available at this time for all
                            of your future sessions. Please choose an
                            alternative time or reach out to{" "}
                            <Link
                                href={`mailto:${props?.selectedRowData?.company?.emailaddress}`}
                            >
                                {props?.selectedRowData?.company?.emailaddress}
                            </Link>{" "}
                            for further assistance.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={closeProviderUnavailableModal}
                        >
                            Okay
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ClientRescheduleModal;
