import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box, Divider, Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const palette = {
    lightGrey: "#DEDEDE",
    darkGrey: "#6B6B6C",
    focusedBorder: "#2196f3",
    acceptBorder: "#00e676",
    rejectBorder: "#ff1744"
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 4,
    borderColor: palette.lightGrey,
    borderStyle: "dashed",
    color: palette.darkGrey,
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "335px"
};

const focusedStyle = {
    borderColor: palette.focusedBorder
};

const acceptStyle = {
    borderColor: palette.acceptBorder
};

const rejectStyle = {
    borderColor: palette.rejectBorder
};

const thumbsContainer = {
    maxWidth: "290px",
    maxHeight: "290px",
    overflow: "hidden"
};

const img = {
    display: "block",
    height: "100%",
    width: "100%"
};

const FileDropzoneArea = ({
    acceptedFileTypes = "",
    maxFiles = 1,
    setFile
}) => {
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: acceptedFileTypes,
        maxFiles: maxFiles,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length) {
                setFile &&
                    setFile(
                        acceptedFiles.map((file) =>
                            Object.assign(file, {
                                preview: URL.createObjectURL(file)
                            })
                        )
                    );
                setFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })
                    )
                );
            }
        }
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const thumbs = files.map((file) => (
        <img
            key={file.name}
            src={file.preview}
            style={img}
            alt="preview"
            // Revoke data url after image is loaded
            onLoad={() => {
                URL.revokeObjectURL(file.preview);
            }}
        />
    ));

    useEffect(() => {
        //Revoking the data urls to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {Array.isArray(files) && !files?.length && (
                <Grid
                    container
                    sx={{
                        gap: "36px",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Grid
                        container
                        flexDirection={"column"}
                        gap={"8px"}
                        alignItems={"center"}
                    >
                        <CloudUploadIcon sx={{ color: palette.lightGrey }} />
                        <Typography>Drag and drop a file</Typography>
                    </Grid>
                    <Divider sx={{ color: palette.lightGrey, width: "75%" }}>
                        or
                    </Divider>
                    <Typography fontWeight={700} color={"primary"}>
                        Browse
                    </Typography>
                </Grid>
            )}
            <div style={thumbsContainer}>{thumbs}</div>
        </Box>
    );
};

export default FileDropzoneArea;
