import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { TableSortLabel } from "@mui/material";
import { COLUMNS } from "./constants";
import { tableConfigAtom } from "../../atoms/usersTable";
import { useAtom } from "jotai";
export const UsersTableHead = () => {
    const [tableConfig, setTableCongif] = useAtom(tableConfigAtom);
    const {
        sort: { sortBy, direction }
    } = tableConfig;
    return (
        <TableHead>
            <TableRow>
                {COLUMNS.map(({ key, label, sortable }) => (
                    <TableCell key={key}>
                        {sortable !== false ? (
                            <TableSortLabel
                                active={sortBy === key}
                                direction={direction?.toLowerCase()}
                                onClick={() =>
                                    setTableCongif({
                                        ...tableConfig,
                                        sort: {
                                            sortBy: key,
                                            direction:
                                                direction === "ASC"
                                                    ? "DESC"
                                                    : "ASC"
                                        }
                                    })
                                }
                            >
                                {label}
                            </TableSortLabel>
                        ) : (
                            label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
