import { graphql, graphqlOperation, postApi } from "./AmplifyServices";
import { Cache } from "aws-amplify/utils";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { execWrite, execReadBySortkey, execReadByPK } from "./DBService";
import * as scheduleQueries from "./ScheduleQueries";
import {
    listBookingsQuery,
    bookingByProvider,
    companyLocationByCompany
} from "../queries/ListBookingsQueries";
import moment from "moment";
import { utcToZonedTime, format, toDate } from "date-fns-tz";
import { getBookingDateDescription } from "../modules/TimeService";
import {
    DEFAULT_NUMBER_OF_LOOKAHEAD_DAYS,
    MAX_NUMBER_OF_SUGGESTED_DAYS_TO_DISPLAY,
    MAX_NUMBER_OF_SUGGESTED_SLOTS_TO_DISPLAY
} from "../utils/Constants";
import uuidv4 from "uuid/v4";
//TODO: Timezone has to be Location timezone or defaule timezone at company level
//TODO: Move date functions to its own DateUtil module
//The dates passed into the ScheduleService will be considered to be Location Timezone or Company Timezone
const SLOTSINADAY = 288;
const SLOTUNITMINS = 5;
const SLOTSINHOUR = 12;
const TIMEMAP = buildTimeMap();
const SCHEDPKSKSPLITAT = "::";
const ANYLOCATIONDESC = "'Any location' within travel radius";
const WEEKDAY = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const NEXT_BOOKING = "NEXT";
const PREVIOUS_BOOKING = "PREVIOUS";
const TRAVEL_TIME_WARNING = {
    OVERLAP: { code: "BOOKING_OVERLAP", message: "Bookings will overlap" },
    NOT_ENOUGH_TIME_TO_TRAVEL_TO: {
        code: "NOT_ENOUGH_TIME_TO_TRAVEL_TO",
        nextBookingMessage: "Not enough travel time to next booking",
        previousBookingMessage: "Not enough travel time from previous booking"
    },
    OVER_MAX_TRAVEL_TIME: {
        codeL: "OVER_MAX_TRAVEL_TIME",
        message: "Not enough travel time to next booking"
    },
    TOO_MUCH_TIME_BETWEEN_BOOKINGS: {
        code: "TOO_MUCH_TIME_BETWEEN_BOOKINGS",
        message: "Too much time between bookings"
    }
};
const LOCATION_PREFIXES = /^PL-|^CL-|^GL-/;

function getAnyLocationObj(companyId) {
    return {
        id: `${companyId}-ANY`,
        locationname: ANYLOCATIONDESC
    };
}
const createSchedule = async ({
    user,
    providerId,
    locationId,
    locationType,
    companyId,
    //servicetypeId,
    services,
    active,
    internal,
    slotStartStep,
    instructions,
    postBookingInstructions
}) => {
    const scheduleData = {
        id: getScheduleTimeblockId(companyId, providerId),
        scheduleinfo: getScheduleInfo(),
        scheduleTimeblockProviderId: providerId,
        companyId,
        providerId,
        locationId,
        // servicetypeId,
        active,
        internal,
        slotStartStep,
        instructions,
        postBookingInstructions
    };
    let response = await execWrite({
        opname: "createScheduleTimeblock",
        op: mutations.createScheduleTimeblock,
        input: scheduleData
    });
    if (response && services && services.length > 0) {
        response = {
            ...response,
            id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`
        };

        const saveServPromises = services.map(async (srv) => {
            const scheduleServiceData = {
                id: scheduleData.id,
                scheduleinfo: getScheduleInfoForService(
                    scheduleData.scheduleinfo,
                    srv
                ),
                locationId: scheduleData.locationId,
                servicetypeId: srv,
                companyId,
                active,
                scheduleTimeblockProviderId: providerId
            };
            const servResp = await execWrite({
                opname: "createScheduleTimeblock",
                op: mutations.createScheduleTimeblock,
                input: scheduleServiceData
            });
            console.log(
                " createScheduleTimeblock response for creating services:" +
                    JSON.stringify(servResp)
            );
        });
        await Promise.all(saveServPromises);
        response = { ...response, services };
    }

    return response;
};

const updateSchedule = async ({
    id,
    user,
    providerId,
    locationId,
    companyId,
    // servicetypeId,
    services,
    active,
    internal,
    slotStartStep,
    instructions,
    postBookingInstructions,
    originalServices
}) => {
    //IMP:id passed in is composite of id:schedileinfo
    const pksk = id.split(SCHEDPKSKSPLITAT);
    const scheduleData = {
        id: pksk[0],
        scheduleinfo: pksk[1],
        companyId,
        providerId,
        locationId,
        // servicetypeId,
        active,
        internal,
        slotStartStep,
        instructions,
        postBookingInstructions,
        scheduleTimeblockProviderId: providerId
    };

    let response = await execWrite({
        opname: "updateScheduleTimeblock",
        op: mutations.updateScheduleTimeblock,
        input: scheduleData
    });
    if (response) response = { ...response, id };

    console.log(
        " updateScheduleTimeblock response:" + JSON.stringify(response)
    );
    //check if any services changed
    const removeOriginalService = [];
    const addNewService = [];
    originalServices.forEach((os) => {
        //os.servicetypeId
        let foundO = false;
        services.forEach((s) => {
            if (s === os.servicetypeId) {
                foundO = true;
            }
        });
        if (!foundO) removeOriginalService.push(os);
    });
    services.forEach((s) => {
        //os.servicetypeId
        let foundO = false;
        originalServices.forEach((os) => {
            if (s === os.servicetypeId) {
                foundO = true;
            }
        });
        if (!foundO) addNewService.push(s);
    });

    if (removeOriginalService.length > 0) {
        const delPromises = removeOriginalService.map(async (os) => {
            await execWrite({
                opname: "deleteScheduleTimeblock",
                op: mutations.deleteScheduleTimeblock,
                input: {
                    id: pksk[0],
                    scheduleinfo: os.scheduleinfo
                }
            });
        });

        const delServResp = await Promise.all(delPromises);
    }

    if (addNewService && addNewService.length > 0) {
        const saveServPromises = addNewService.map(async (srv) => {
            const scheduleServiceData = {
                id: scheduleData.id,
                scheduleinfo: getScheduleInfoForService(
                    scheduleData.scheduleinfo,
                    srv
                ),
                locationId: scheduleData.locationId,
                servicetypeId: srv,
                companyId,
                active,
                scheduleTimeblockProviderId: providerId
            };
            const servResp = await execWrite({
                opname: "createScheduleTimeblock",
                op: mutations.createScheduleTimeblock,
                input: scheduleServiceData
            });
            console.log(
                " createScheduleTimeblock response for creating services:" +
                    JSON.stringify(servResp)
            );
        });
        await Promise.all(saveServPromises);
        response = { ...response, services: addNewService };
    }

    return response;
};

async function _deleteSchedule(pk, sk) {
    const input = {
        id: pk,
        scheduleinfo: sk
    };
    let response = null;
    console.log("_deleteScheduleInput:", input);
    try {
        response = await execWrite({
            opname: "deleteScheduleTimeblock",
            op: mutations.deleteScheduleTimeblock,
            input: input
        });
        console.log("after deleting schedule");
        console.log("_deleteSchedule", response);
    } catch (error) {
        console.log(error);
        return {
            error
        };
    }
    return response;
}

async function activateSchedule(schCompositeId) {
    return await changeActivenessOfSchedule(schCompositeId, true);
}

async function deactivateSchedule(schCompositeId) {
    return await changeActivenessOfSchedule(schCompositeId, false);
}

async function changeActivenessOfSchedule(schCompositeId, activeness) {
    const pksk = schCompositeId.split(SCHEDPKSKSPLITAT);
    const scheduleData = {
        id: pksk[0],
        scheduleinfo: pksk[1],
        active: activeness
    };

    let response = await execWrite({
        opname: "updateScheduleTimeblock",
        op: mutations.updateScheduleTimeblock,
        input: scheduleData
    });
    if (response) response = { ...response, id: schCompositeId };
    return response;
}

function getScheduleInfo() {
    const uuid = uuidv4();
    return `SC|${uuid}`;
}
function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}
function getScheduleInfoForTimeblock(
    schedulesk,
    user,
    tbtype,
    startDate,
    startTime
) {
    console.log("schedulesk", schedulesk, "user", user);
    if (typeIsABooking(tbtype)) {
        return `BK|${startDate} ${startTime}|${schedulesk}|${(
            "" + Date.now()
        ).slice(-6)}`;
    } else if (typeIsUnavailable(tbtype)) {
        return `SBU|${startDate} ${startTime}|${user.substring(
            0,
            3
        )}${getRandomInt(1000)}-${Date.now()}`;
    } else
        return `SB|${schedulesk}|${user.substring(0, 3)}${getRandomInt(
            1000
        )}-${Date.now()}`;
}
function getScheduleInfoForService(schedulesk, servid) {
    return `SR|${servid}|${schedulesk}`;
}

function typeIsABooking(tbtype) {
    if (tbtype === "BOOKED") return true;
    else return false;
}

function typeIsUnavailable(tbtype) {
    if (tbtype === "UNAVAILABLE") return true;
    else return false;
}

const createUnavailableTimeblock = async (input) => {
    //input contains providerId
    const { providerId, startDate, startTime, companyId, user } = input;
    const scheduleId = `${getScheduleTimeblockId(
        companyId,
        providerId
    )}${SCHEDPKSKSPLITAT}${getScheduleInfoForTimeblock(
        null,
        user,
        "UNAVAILABLE",
        startDate,
        startTime
    )}`;
    const response = await createTimeblock({
        ...input,
        scheduleId,
        tz: BROWSER_TZ
    });
    return response;
};

const createTimeblock = async ({
    user,
    companyId,
    locationId,
    scheduleId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    weeksToRepeat,
    weekDays,
    active,
    sdtutc,
    tz
}) => {
    const weekdaysStr = weekDays ? weekdaysString(weekDays) : weekDays;
    console.log("createTimeblock:scheduleId" + scheduleId);
    const pksk = scheduleId.split(SCHEDPKSKSPLITAT);

    let timeblockData = {
        id: pksk[0],
        scheduleinfo: getScheduleInfoForTimeblock(
            pksk[1],
            user,
            type,
            startDate,
            startTime
        ),
        companyId,
        locationId,
        startDate,
        endDate,
        startTime,
        endTime,
        type,
        status,
        active,
        weeksToRepeat,
        weekDays: weekdaysStr ? weekdaysStr : "",
        scheduleTimeblockProviderId: pksk[0].split("|")[1].slice(2)
    };
    if (sdtutc) timeblockData = { ...timeblockData, sdtutc };
    if (tz) timeblockData = { ...timeblockData, tz };

    let response = await execWrite({
        opname: "createScheduleTimeblock",
        op: mutations.createScheduleTimeblock,
        input: timeblockData
    });
    if (response) {
        response = {
            ...response,
            id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`
        };
    }
    return response;
};

const createProviderScheduleBK = async ({
    companyId,
    locationId,
    latitude,
    longitude,
    providerId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    sdtutc,
    tz
}) => {
    try {
        let timeblockData = {
            id: `C-${companyId}|P-${providerId}`,
            scheduleinfo: `BK|${startDate} ${startTime}|${locationId}|${uuidv4()}`,
            companyId,
            providerId,
            providerScheduleProviderId: providerId,
            locations: JSON.stringify([locationId]),
            latitude,
            longitude,
            startDate,
            endDate,
            startTime,
            endTime,
            type,
            status
        };
        if (sdtutc) timeblockData = { ...timeblockData, sdtutc };
        if (tz) timeblockData = { ...timeblockData, tz };

        let response = await execWrite({
            opname: "createProviderSchedule",
            op: mutations.createProviderSchedule,
            input: timeblockData
        });
        if (response) {
            response = {
                ...response,
                id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`
            };
        }
        return response;
    } catch (e) {
        console.log("Error: Unable to create BK record", e);
    }
};

const createProviderScheduleBk = async ({
    companyId,
    locationId,
    latitude,
    longitude,
    scheduleId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    active,
    sdtutc,
    tz
}) => {
    const pksk = scheduleId.split(SCHEDPKSKSPLITAT);
    let timeblockData = {
        id: pksk[0],
        scheduleinfo: `BK|${startDate} ${startTime}|${locationId}|${pksk[1]}`,
        companyId,
        locations: JSON.stringify([locationId]),
        latitude,
        longitude,
        startDate,
        endDate,
        startTime,
        endTime,
        type,
        status,
        active,
        providerId: pksk[0].split("|")[1].slice(2),
        providerScheduleProviderId: pksk[0].split("|")[1].slice(2)
    };
    if (sdtutc) timeblockData = { ...timeblockData, sdtutc };
    if (tz) timeblockData = { ...timeblockData, tz };

    let response = await execWrite({
        opname: "createProviderSchedule",
        op: mutations.createProviderSchedule,
        input: timeblockData
    });
    if (response) {
        response = {
            ...response,
            id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`
        };
    }
    return response;
};

const updateTimeblock = async ({
    id,
    companyId,
    locationId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    weeksToRepeat,
    weekDays,
    active,
    sdtutc,
    tz
}) => {
    const pksk = id.split(SCHEDPKSKSPLITAT);

    const weekdaysStr = weekDays ? weekdaysString(weekDays) : weekDays;

    let timeblockData = {
        id: pksk[0],
        scheduleinfo: pksk[1],
        companyId,
        locationId,
        startDate,
        endDate,
        startTime,
        endTime,
        type,
        status,
        active,
        weeksToRepeat,
        weekDays: weekdaysStr ? weekdaysStr : "",
        scheduleTimeblockProviderId: pksk[0].split("|")[1].slice(2)
    };
    if (sdtutc) timeblockData = { ...timeblockData, sdtutc };
    if (tz) timeblockData = { ...timeblockData, tz };
    let response = await execWrite({
        opname: "updateScheduleTimeblock",
        op: mutations.updateScheduleTimeblock,
        input: timeblockData
    });
    if (response) {
        response = {
            ...response,
            id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`
        };
    }
    return response;
};

async function getScheduleCounts(companyId) {
    const SCs = await execReadBySortkey({
        opname: "schedulesByCompany",
        op: scheduleQueries.schedulesByCompanyForCount,
        id: { companyId },
        skey: { scheduleinfo: { beginsWith: "SC|" } }, //TODO: shhould be based on StartDate and EndDate
        filter: { active: { ne: false } },
        limit: process.env.REACT_APP_LISTLIMIT
    });

    let scheduleCounts = {};
    if (SCs && SCs.items) {
        for (let sc of SCs.items) {
            if (scheduleCounts.hasOwnProperty(sc.providerId)) {
                scheduleCounts[sc.providerId] =
                    scheduleCounts[sc.providerId] + 1;
            } else {
                scheduleCounts[sc.providerId] = 1;
            }
        }
    }
    return { scheduleCounts, SCs: SCs.items };
}

async function getScheduleDatesToReport(companyId, SCs) {
    const SBs = await execReadBySortkey({
        opname: "schedulesByCompany",
        op: scheduleQueries.timeblocksByCompanyFor,
        id: { companyId },
        skey: { scheduleinfo: { beginsWith: "SB|" } },
        //filter: { active: { ne: false } },
        limit: process.env.REACT_APP_LISTLIMIT
    });

    let scheduleDatesForReport = {};
    if (SBs && SBs.items) {
        for (let sb of SBs.items) {
            let providerId = sb.id.slice(sb.id.indexOf("|P-") + 3);
            if (scheduleDatesForReport.hasOwnProperty(providerId)) {
                scheduleDatesForReport[providerId].sbs.push(sb);
            } else {
                scheduleDatesForReport[providerId] = { sbs: [sb] };
            }
        }
    }
    if (SCs && SCs.length)
        for (let sc of SCs) {
            if (scheduleDatesForReport.hasOwnProperty(sc.providerId)) {
                if (scheduleDatesForReport[sc.providerId].scs) {
                    scheduleDatesForReport[sc.providerId].scs.push(sc);
                } else {
                    scheduleDatesForReport[sc.providerId].scs = [sc];
                }
            }
        }

    for (let providerId in scheduleDatesForReport) {
        let sbs = [];
        //remove sbs of a provider that don't have active scs
        for (let sb of scheduleDatesForReport[providerId].sbs) {
            if (
                scheduleDatesForReport[providerId].scs &&
                scheduleDatesForReport[providerId].scs.length
            )
                for (let sc of scheduleDatesForReport[providerId].scs) {
                    if (sb.scheduleinfo.includes(sc.scheduleinfo)) {
                        sbs.push(sb);
                        break;
                    }
                }
        }
        if (sbs && sbs.length) {
            //sort by updatedAt
            let sortedByUpdatedAt = sbs.sort((av1, av2) => {
                let d1 = new Date(av1.updatedAt).valueOf();
                let d2 = new Date(av2.updatedAt).valueOf();
                if (d1 > d2) return -1;
                if (d1 < d2) return 1;
                return 0;
            });
            if (sortedByUpdatedAt && sortedByUpdatedAt.length)
                scheduleDatesForReport[providerId].latestScheduleUpdatedAt =
                    new Date(sortedByUpdatedAt[0].updatedAt).toDateString();
            //sort by endDate
            let sortedByEndDate = sbs.sort((av1, av2) => {
                let d1 = new Date(av1.endDate).valueOf();
                let d2 = new Date(av2.endDate).valueOf();
                if (d1 > d2) return -1;
                if (d1 < d2) return 1;
                return 0;
            });
            if (sortedByEndDate && sortedByEndDate.length)
                scheduleDatesForReport[providerId].farthestScheduleDate =
                    new Date(sortedByEndDate[0].endDate).toDateString();
        }
    }
    return scheduleDatesForReport;
}

function getNowDate() {
    return getAWSDate(new Date()); //local datetime
}

function getAWSDateOf(date) {
    return getAWSDate(date);
}

function getAWSDate(date) {
    let oy = { year: "numeric" };
    let YYYY = date.toLocaleDateString("en-US", oy);
    let om = { month: "2-digit" };
    let MM = date.toLocaleDateString("en-US", om);
    let od = { day: "2-digit" };
    let DD = date.toLocaleDateString("en-US", od);
    return `${YYYY}-${MM}-${DD}`;
}

function awsDateToJsDate(awsDate) {
    // from YYYY-MM-DD to local timezone
    const dateparts = awsDate.split("-");
    return new Date(
        parseInt(dateparts[0]),
        parseInt(dateparts[1] - 1),
        parseInt(dateparts[2])
    ); //yes, local time
}

function worksonday(sample, range) {
    // console.log("worksonday:" + sample.getDay() + " " + range);
    const adjustedDay = (sample.getDay() + 6) % 7;
    /* return range.indexOf(sample.getDay()) !== -1 ? true : false; */
    return range.indexOf(adjustedDay) !== -1 ? true : false;
}

function weekdaysString(wdArray) {
    return "" + wdArray.join("");
}
const isbool = (val) => "boolean" === typeof val;

function getByValue(arr, value) {
    if (!arr) return null;
    var result = arr.filter(function (o) {
        return o.id == value;
    });
    return result ? result[0] : null;
}

const getTimeblocksbyScheduleId = async (scheduleid) => {
    const cachedblocks = await Cache.getItem("tbs-of-schedule-" + scheduleid);
    if (cachedblocks) return cachedblocks;
    const pksk = scheduleid.split(SCHEDPKSKSPLITAT);
    const blocks = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listTimeblocks,
        id: { id: pksk[0] },
        skey: { scheduleinfo: { beginsWith: `SB|${pksk[1]}` } },
        filter: null,
        limit: process.env.REACT_APP_LISTLIMIT
    });
    Cache.setItem("tbs-of-schedule-" + scheduleid, blocks.items);
    return blocks.items;
};

const getTimeblocksbyScheduleIdNoCache = async (scheduleid) => {
    const pksk = scheduleid.split(SCHEDPKSKSPLITAT);
    const blocks = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listTimeblocks,
        id: { id: pksk[0] },
        skey: { scheduleinfo: { beginsWith: `SB|${pksk[1]}` } },
        filter: null,
        limit: process.env.REACT_APP_LISTLIMIT
    });
    return blocks.items;
};

const checkServiceHasExistingSchedule = async (
    checkTheseServices,
    companyId,
    providerId,
    locationId,
    schCompositeId //may not be passed in some cases like for new schedule
) => {
    //SR|8ac413d9-20df-4b28-9a5c-4d75e5125cfd|SC|CL-7128a9e4-07ed-4b84-acb6-99149a794f10
    let beingEditedScheduleScheduleInfo;
    if (schCompositeId) {
        const pksk = schCompositeId.split(SCHEDPKSKSPLITAT);
        beingEditedScheduleScheduleInfo = pksk[1];
    }
    /*WILL BE REMOVED - new UI on new Users modal*/
    const allscsatlocation = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listSchedules,
        id: { id: getScheduleTimeblockId(companyId, providerId) },
        skey: { scheduleinfo: { beginsWith: `SC|` } },
        filter: { locationId: { eq: locationId.slice(3) } },
        limit: process.env.REACT_APP_LISTLIMIT
    });
    const activeScsAtLocation = new Set();
    if (allscsatlocation && allscsatlocation.items) {
        for (let sc of allscsatlocation.items) {
            if (sc.active === true) activeScsAtLocation.add(sc.scheduleinfo);
        }
    }
    if (beingEditedScheduleScheduleInfo) {
        //remove the schedule that is being edited
        if (activeScsAtLocation.has(beingEditedScheduleScheduleInfo))
            activeScsAtLocation.delete(beingEditedScheduleScheduleInfo);
    }

    /*WILL BE REMOVED - new UI on new Users modal*/
    const srs = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listSchedules,
        id: { id: getScheduleTimeblockId(companyId, providerId) },
        skey: { scheduleinfo: { beginsWith: `SR|` } },
        filter: { locationId: { eq: locationId.slice(3) } },
        limit: process.env.REACT_APP_LISTLIMIT
    });
    let returnServices = [];
    if (srs && srs.items) {
        returnServices = srs.items
            .filter((sr) => {
                let found = false;
                for (let checkSr of checkTheseServices) {
                    if (
                        checkSr === sr.servicetypeId &&
                        activeScsAtLocation.has(
                            sr.scheduleinfo.slice(
                                sr.scheduleinfo.indexOf("SC|")
                            )
                        )
                    ) {
                        found = true;
                        break;
                    }
                }
                return found;
            })
            .map((sr) => sr.servicetypeId);
    }
    return returnServices;
};

function buildTimeMap() {
    const tmap = new Array(SLOTSINADAY);
    for (let s = 0; s < SLOTSINADAY; s++) {
        const hr = Math.floor(s / SLOTSINHOUR);
        const mins = (s % SLOTSINHOUR) * SLOTUNITMINS;
        const tstr24 = `${hr < 10 ? "0" : ""}${hr}:${
            mins < 10 ? "0" : ""
        }${mins}`;
        const tfd =
            hr > 6 && hr < 12
                ? 1
                : hr > 11 && hr < 17
                  ? 2
                  : hr > 16 && hr <= 23
                    ? 3
                    : 4;
        const tstr12 = `${hr < 13 ? hr : hr - 12}:${
            mins < 10 ? "0" : ""
        }${mins} ${hr > 11 ? "PM" : "AM"}`;
        tmap[s] = { tstr24, tstr12, tfd, hr, mins };
        //console.log(s, tmap[s]);
    }
    return tmap;
}

const _getTimeblocks = async (filter) => {
    const { companyId, providerId, scheduleId, type } = filter;
    console.log("_getTimeblocks:scheduleId:" + scheduleId);
    const pksk = scheduleId.split(SCHEDPKSKSPLITAT);

    const response = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listTimeblocks,
        id: { id: pksk[0] },
        skey: { scheduleinfo: { beginsWith: `SB|${pksk[1]}` } },
        filter: { type: { eq: type } },
        limit: process.env.REACT_APP_LISTLIMIT
    });
    const returnTimeblocks =
        response.items &&
        response.items.map((schedule) => {
            return {
                ...schedule,
                id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo //to support DataTableWrapper
            };
        });
    return returnTimeblocks;
};

const getScheduleData = async (schId) => {
    const pksk = schId.split(SCHEDPKSKSPLITAT);
    const comp_prov = pksk[0].split("|");
    const companyId = comp_prov[0].substring(2);
    const providerId = comp_prov[1].substring(2);
    const schedules = await _getSchedules({ companyId, providerId });
    const returnSchedule = schedules.filter((sch) => sch.id === schId);
    return returnSchedule[0];
};
const _getProviderBookings = async (providerId) => {
    const limit = process.env.REACT_APP_LISTLIMIT;
    let filter = {
        providerId: { eq: providerId }
    };
    let bookingsList = {};
    bookingsList = await graphql(
        graphqlOperation(listBookingsQuery, { filter, limit })
    );
    console.log("ProviderBookingList", bookingsList.data);
    if (
        bookingsList.data.listBookings &&
        bookingsList.data.listBookings.items &&
        bookingsList.data.listBookings.items.length > 0
    ) {
        return bookingsList.data.listBookings.items;
    } else {
        return false;
    }
};
const _getSchedulesTime = async (filter) => {
    let schedules = await _getSchedules(filter);
    let bookings = await _getProviderBookings(filter.providerId);
    console.log(bookings, "PRINCE:Bookings");
    console.log("inside", schedules);
    const blocks = schedules.map(async (item, i) => {
        let blocks = [];

        const availableTimeBlocks = await _getTimeblocks({
            scheduleId: item.id,
            type: "AVAILABLE"
        });
        const unAvailableTimeBlocks = await _getTimeblocks({
            scheduleId: item.id,
            type: "UNAVAILABLE"
        });
        console.log("timeblocks_get", availableTimeBlocks);
        // Available TimeBlocks
        for (const timeblock of availableTimeBlocks) {
            const startDate = new Date(timeblock.startDate);
            const endDate = new Date(timeblock.endDate);
            const DifferenceInDays =
                (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
            const startTime = timeblock.startTime.split(":");
            const endTime = timeblock.endTime.split(":");
            let loopDay = new Date();
            loopDay.setHours(0, 0, 0, 0);
            if (loopDay.getTime() < startDate.getTime()) {
                loopDay = startDate;
            }
            for (let i = 0; i <= DifferenceInDays; i++) {
                const dayOfWeek = loopDay.getDay();
                if (timeblock.weekDays.includes(dayOfWeek)) {
                    blocks.push({
                        title: "AVAILABLE",
                        start: new Date(
                            loopDay.getFullYear(),
                            loopDay.getMonth(),
                            loopDay.getDate(),
                            startTime[0],
                            startTime[1],
                            0,
                            0
                        ),
                        end: new Date(
                            loopDay.getFullYear(),
                            loopDay.getMonth(),
                            loopDay.getDate(),
                            endTime[0],
                            endTime[1],
                            0,
                            0
                        )
                    });
                }
                if (loopDay.getTime() === endDate.getTime()) {
                    break;
                }
                loopDay.setDate(loopDay.getDate() + 1);
            }
        }
        // UNAvailable TimeBlocks
        for (const timeblock of unAvailableTimeBlocks) {
            const startDate = new Date(timeblock.startDate);
            const endDate = new Date(timeblock.endDate);
            const DifferenceInDays =
                (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
            const startTime = timeblock.startTime.split(":");
            const endTime = timeblock.endTime.split(":");
            let loopDay = new Date();
            loopDay.setHours(0, 0, 0, 0);
            if (loopDay.getTime() < startDate.getTime()) {
                loopDay = startDate;
            }
            for (let i = 0; i <= DifferenceInDays; i++) {
                const dayOfWeek = loopDay.getDay();
                if (timeblock.weekDays.includes(dayOfWeek)) {
                    blocks.push({
                        title: "UNAVAILABLE",
                        start: new Date(
                            loopDay.getFullYear(),
                            loopDay.getMonth(),
                            loopDay.getDate(),
                            startTime[0],
                            startTime[1],
                            0,
                            0
                        ),
                        end: new Date(
                            loopDay.getFullYear(),
                            loopDay.getMonth(),
                            loopDay.getDate(),
                            endTime[0],
                            endTime[1],
                            0,
                            0
                        )
                    });
                }
                if (loopDay.getTime() === endDate.getTime()) {
                    break;
                }
                loopDay.setDate(loopDay.getDate() + 1);
            }
        }
        // Bookings TimeBlocks
        if (bookings) {
            for (const booking of bookings) {
                const startDate = new Date(booking.startdate);
                const endDate = new Date(
                    startDate.getTime() + booking.minutes * 60000
                );
                const timeblockidparts = booking.timeblockid.split("::");
                const scheduleIdIsInsideHere =
                    timeblockidparts[timeblockidparts.length - 1];
                const bookingScheduleId = scheduleIdIsInsideHere.substring(
                    scheduleIdIsInsideHere.indexOf("SC|")
                );

                if (item.id === bookingScheduleId) {
                    blocks.push({
                        title: booking.desc,
                        start: startDate,
                        end: endDate
                    });
                }
            }
            // console.log("inside bookings", blocks)
        }
        return { blocks: blocks };
    });

    return blocks;
};
//For UI : _getSchedules
const _getSchedules = async ({ companyId, providerId }) => {
    console.log(
        "I am in _getSchedules preparebookingdata",
        companyId,
        providerId
    );
    const response = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: { id: getScheduleTimeblockId(companyId, providerId) },
        skey: { scheduleinfo: { beginsWith: "S" } },
        filter: { deleted: { ne: true } },
        limit: process.env.REACT_APP_LISTLIMIT
    });

    const allSchedules = response.items.filter((schitem) =>
        schitem.scheduleinfo.startsWith("SC|")
    );

    const cls = allSchedules.length
        ? allSchedules
              .flatMap((schedule) =>
                  JSON.parse(schedule.locations).filter((location) =>
                      location.startsWith("CL-")
                  )
              )
              .map((location) => location.replace("CL-", ""))
        : [];

    const uniqueLocations = [...new Set(cls)];
    const allServices = response.items.filter((schitem) =>
        schitem.scheduleinfo.startsWith("SR|")
    );
    const allTBs = response.items.filter((schitem) =>
        schitem.scheduleinfo.startsWith("SB|")
    );

    const ORlocations = uniqueLocations.map((lId) => {
        return {
            id: {
                eq: lId
            }
        };
    });
    const locationsFilter = {
        or: ORlocations
    };
    const locations = await execReadByPK({
        opname: "companyLocationByCompany",
        op: queries.companyLocationByCompany,
        id: {
            companyId: companyId
        },
        filter: locationsFilter,
        limit: process.env.REACT_APP_LISTLIMIT
    });

    const pd = await getProviderDataForSchedules(providerId);

    const returnSchedules = [];
    for (let schedule of allSchedules) {
        let locationIds = JSON.parse(schedule.locations);
        for (let locationId of locationIds) {
            let loc = getLocationById(
                locationId,
                pd.locations,
                locations.items
            );
            returnSchedules.push({
                ...schedule,
                id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo,
                tbs: allTBs
                    .filter((tb) =>
                        tb.scheduleinfo.includes(schedule.scheduleinfo)
                    )
                    .map((tb) => {
                        return {
                            ...tb,
                            id: tb.id + SCHEDPKSKSPLITAT + tb.scheduleinfo
                        };
                    }),
                location: loc,
                locationId: loc.id,
                selectedServices: allServices
                    .filter((serv) =>
                        serv.scheduleinfo.endsWith(schedule.scheduleinfo)
                    )
                    .map((serv) => {
                        const [prefix, serviceId] = serv.scheduleinfo.split(
                            "|",
                            2
                        );
                        return {
                            scheduleinfo: serv.scheduleinfo,
                            servicetypeId: serviceId,
                            name: getServiceName(pd.servicetypes, serviceId)
                        };
                    })
            });
        }
    }
    return returnSchedules;
};

const _getAvailability = async ({ companyId, providerArr }) => {
    let allSchedulesAvailability = [];
    const locations = await execReadBySortkey({
        opname: "companyLocationByCompany",
        op: companyLocationByCompany,
        limit: process.env.REACT_APP_LISTLIMIT,
        id: { companyId }
    });

    console.log("Locations _getAvailability", locations);

    const schLocations = new Map();

    for (let provider of providerArr) {
        let providerId = provider.id;

        const response = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: getScheduleTimeblockId(companyId, providerId) },
            skey: { scheduleinfo: { beginsWith: "S" } },
            filter: { deleted: { ne: true }, active: { ne: false } },
            limit: process.env.REACT_APP_LISTLIMIT
        });

        const allSchedules = response.items.filter((schitem) =>
            schitem.scheduleinfo.startsWith("SC|")
        );

        const allTBs = response.items.filter((schitem) =>
            schitem.scheduleinfo.startsWith("SB|")
        );

        const pd = await getProviderDataForSchedules(providerId);

        for (let schedule of allSchedules) {
            let locationIds = JSON.parse(schedule.locations);

            for (let locationId of locationIds) {
                let loc = getLocationById(
                    locationId,
                    pd.locations,
                    locations.items
                );
                if (loc && !schLocations.has(locationId)) {
                    let strippedId = stripLocationPrefix(locationId);
                    schLocations.set(strippedId, {
                        id: strippedId,
                        text: loc.locationname
                    });
                }

                let schCompositeId =
                    schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo;
                let scheduleAvailabilityArr = consolidateAvailability(
                    allTBs,
                    schedule,
                    loc,
                    schCompositeId,
                    providerId
                );
                allSchedulesAvailability.push(...scheduleAvailabilityArr);
            }
        }
    }
    return {
        providers: providerArr,
        locations: Array.from(schLocations.values()),
        availability: allSchedulesAvailability
    };
};

function getLocationById(locationId, pdLocations, allLocations) {
    if (locationId.startsWith("PL-")) {
        return pdLocations
            ? getProviderLocationName(
                  pdLocations,
                  stripLocationPrefix(locationId)
              )
            : null;
    } else {
        return allLocations
            ? getByValue(allLocations, stripLocationPrefix(locationId))
            : null;
    }
}

function stripLocationPrefix(locationId) {
    return locationId.replace(LOCATION_PREFIXES, "");
}

function consolidateAvailability(
    allTBs,
    schedule,
    loc,
    schCompositeId,
    providerId
) {
    const schTbs = allTBs.filter((tb) =>
        tb.scheduleinfo.includes(schedule.scheduleinfo)
    );
    let availabilityArr = [];
    for (let tb of schTbs) {
        let rRule;
        let _enddatetime = makeDateTime(tb.endDate, tb.endTime);
        let _enddatetimeUTCStr = _enddatetime.toISOString();
        _enddatetimeUTCStr = _enddatetimeUTCStr.replaceAll("-", "");
        _enddatetimeUTCStr = _enddatetimeUTCStr.replaceAll(":", "");
        _enddatetimeUTCStr = _enddatetimeUTCStr.slice(0, 15);
        let endDateTime = _enddatetimeUTCStr;

        if (tb.weekDays && tb.weekDays.length) {
            //weekly rRule
            const weekDaysArr = tb.weekDays.split("");
            weekDaysArr.sort();
            let wda = [];
            for (const wd of weekDaysArr) wda.push(WEEKDAY[wd]);

            rRule = `FREQ=WEEKLY;UNTIL=${endDateTime};WKST=SU;BYDAY=${wda.join()}`;
        } else {
            //Daily

            rRule = `FREQ=DAILY;UNTIL=${endDateTime}`;
        }

        availabilityArr.push({
            rRule,
            startDate: makeDateTime(tb.startDate, tb.startTime),
            endDate: makeDateTime(tb.startDate, tb.endTime),
            locationId: loc.id,
            id: schCompositeId,
            providerId
        });
    }
    return availabilityArr;
}

function makeDateTime(awsdt, tm) {
    let dateparts = awsdt.split("-");
    let timeparts = tm.split(":");
    return new Date(
        Number(dateparts[0]),
        Number(dateparts[1] - 1),
        Number(dateparts[2]),
        Number(timeparts[0]),
        Number(timeparts[1])
    );
}
async function getBookingsList(
    providerId,
    startDate,
    endDate,
    providerTimezone
) {
    let bookings = [];

    try {
        const result = await execReadBySortkey({
            opname: "bookingByProvider",
            op: bookingByProvider,
            id: {
                providerId
            },
            skey: {
                startdateTimeblockid: {
                    between: [
                        {
                            startdate: `${moment(startDate).format(
                                "YYYY-MM-DD"
                            )}T00:00:00-00:00`
                        },
                        {
                            startdate: `${moment(endDate).format(
                                "YYYY-MM-DD"
                            )}T23:59:59-00:00`
                        }
                    ]
                }
            },
            filter: { status: { ne: "CANCELLED" } },
            limit: process.env.REACT_APP_LISTLIMIT
        });
        // console.log(
        //     "bookingByProvider",
        //     JSON.parse(JSON.stringify(result.items, null, 4))
        // );
        if (result && result.items) {
            for (let bk of result.items) {
                bookings.push(bk);
                bk.service = bk.servicetype.name;
                bk.clientName = `${
                    bk.client && bk.client.user ? bk.client.user.firstname : ""
                } ${
                    bk.client && bk.client.user ? bk.client.user.lastname : ""
                }`;
                bk.title = `${bk.service} for ${bk.clientName}`;
                bk.providerName =
                    bk.provider &&
                    `${bk.provider.firstname} ${bk.provider.lastname}`;

                // bk.date = moment(bk.startdate).format("MMM DD, YYYY h:mm a");
                bk.date = format(
                    utcToZonedTime(
                        bk.startdate,
                        bk.timezone ? bk.timezone : "America/Toronto"
                    ),
                    "MMM dd, yyyy h:mm a zzz",
                    { timeZone: bk.timezone ? bk.timezone : "America/Toronto" }
                );
                let startAndEndTimes = await getStartAndEndTimes(
                    bk.startdate,
                    bk.servicetype.minutes,
                    bk.timezone ? bk.timezone : "America/Toronto",
                    providerTimezone
                );
                bk.startDTStr = startAndEndTimes.startDTstr;
                bk.endDTStr = startAndEndTimes.endDTstr;
                bk.startDate = startAndEndTimes.startDT;
                bk.endDate = startAndEndTimes.endDT;
                bk.browserTzBasedBookingTzAbbr =
                    startAndEndTimes.browserTzBasedBookingTzAbbr;
                if (bk.TimeDisplayInfo) {
                    bk.TimeDisplayInfo = JSON.parse(bk.TimeDisplayInfo);
                }
            }
        }
    } catch (err) {}
    return bookings;
}

async function getStartAndEndTimes(
    bookingDateTime,
    serviceDuration,
    tz,
    providerTimezone
) {
    let stDt = new Date(bookingDateTime);
    let endDt = new Date(bookingDateTime);
    endDt.setMinutes(endDt.getMinutes() + serviceDuration);
    let startDTstr = format(
        utcToZonedTime(stDt, tz),
        "MMM dd, yyyy h:mm a zzz",
        { timeZone: tz }
    );
    let endDTstr = format(
        utcToZonedTime(endDt, tz),
        "MMM dd, yyyy h:mm a zzz",
        { timeZone: tz }
    );
    let browserOrProviderTz = providerTimezone ? providerTimezone : BROWSER_TZ;
    let stDtWithTz = toDate(utcToZonedTime(stDt, browserOrProviderTz), {
        timeZone: browserOrProviderTz
    });
    let endDtWithTz = toDate(utcToZonedTime(endDt, browserOrProviderTz), {
        timeZone: browserOrProviderTz
    });
    let timeDisplayInfoForShortFormTZName = await getBookingDateDescription(
        stDtWithTz,
        browserOrProviderTz,
        serviceDuration
    );

    return {
        startDTstr,
        endDTstr,
        endDT: endDtWithTz,
        startDT: stDtWithTz,
        browserTzBasedBookingTzAbbr:
            timeDisplayInfoForShortFormTZName.tz_abbr_disp
    };

    /* //original code
    let startDTstr = moment(bookingDateTime).format("YYYY-MM-DDTHH:mm");
    const bdt = new Date(bookingDateTime);
    bdt.setMinutes(bdt.getMinutes() + serviceDuration);
    let endDTstr = moment(bdt).format("YYYY-MM-DDTHH:mm");
    return {
        startDTstr,
        endDTstr,
        endDT: bdt,
        startDT: new Date(bookingDateTime)
    };
    */
}

function getProviderLocationName(locations, locationId) {
    let locationname = "Provider Location";
    const ps = locations.forEach((pl) => {
        if (locationId === pl.id) {
            locationname = pl.name;
        }
    });
    return {
        id: locationId,
        locationname
    };
}

function getServiceName(providerServices, servicetypeId) {
    let serviceTypeName = null;
    const ps = providerServices.forEach((st) => {
        if (servicetypeId === st.servicetype.id) {
            serviceTypeName = st.servicetype.name;
        }
    });
    return serviceTypeName;
}
function getScheduleTimeblockId(cid, pid) {
    return `C-${cid}|P-${pid}`;
}
const getProviderSchedulesByLocationByService = async ({
    companyId,
    locationId,
    servicetypeId,
    providers
}) => {
    let returnProviders = [];

    //TODO: improve - read schedules only if there is atleast one AVAILABLE TB
    const srs = await execReadBySortkey({
        opname: "schedulesByLocation",
        op: scheduleQueries.listAllScheduleItemsByLocation,
        id: { locationId },
        skey: { scheduleinfo: { beginsWith: `SR|${servicetypeId}` } },
        filter: { active: { eq: true } },
        limit: process.env.REACT_APP_LISTLIMIT
    });

    console.log("schedules", JSON.stringify(srs));

    if (srs && srs.items && srs.items.length > 0) {
        returnProviders = providers.filter((provider) => {
            let found = false;
            srs.items.forEach((sr) => {
                if (sr.id.endsWith(provider.id)) found = true;
            });
            return found;
        });
    }

    return returnProviders;
};

const getProviderDataForSchedules = async (providerid) => {
    const providerData = await graphql(
        graphqlOperation(scheduleQueries.providerDataForScheduleQuery, {
            id: providerid
        })
    );

    let providerDataForSchedule = {};

    if (
        providerData &&
        providerData.data &&
        providerData.data.getProvider &&
        providerData.data.getProvider.servicetypes &&
        providerData.data.getProvider.servicetypes.items
    ) {
        providerDataForSchedule = {
            ...providerDataForSchedule,
            servicetypes:
                providerData.data.getProvider.servicetypes.items.filter(
                    (s) => !(true === s.servicetype.deleted)
                )
        };
    }
    if (
        providerData &&
        providerData.data &&
        providerData.data.getProvider &&
        providerData.data.getProvider.locations &&
        providerData.data.getProvider.locations.items
    ) {
        providerDataForSchedule = {
            ...providerDataForSchedule,
            locations: providerData.data.getProvider.locations.items.filter(
                (pl) => !(true === pl.deleted)
            )
        };
    }
    console.log(
        "providerDataForSchedule",
        JSON.stringify(providerDataForSchedule, null, 3)
    );
    return providerDataForSchedule;
};

const getUnavailabilityOfProvider = async (filter) => {
    const { companyId, providerId } = filter;
    console.log("getUnavailabilityOfProvider:providerId:", filter);

    const response = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listTimeblocks,
        id: { id: getScheduleTimeblockId(companyId, providerId) },
        skey: { scheduleinfo: { beginsWith: `SBU|` } },
        filter: { type: { eq: "UNAVAILABLE" }, active: { ne: false } },
        limit: process.env.REACT_APP_LISTLIMIT
    });
    console.log(
        "getUnavailabilityOfProvider response: " +
            JSON.stringify(response.items)
    );
    const returnTimeblocks = response.items.map((schedule) => {
        return {
            ...schedule,
            id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo //to support DataTableWrapper
        };
    });
    return returnTimeblocks;
};

const getUnavailabilityOfProviderScheduleList = async (filter) => {
    const { companyId, provider, startDate, endDate } = filter;

    const response = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: { id: getScheduleTimeblockId(companyId, provider.id) },
        skey: {
            scheduleinfo: { beginsWith: "B" }
        },
        filter: {
            and: [
                { active: { ne: false } },
                { deleted: { ne: true } },
                {
                    startDate: {
                        ge: `${moment(startDate).format("YYYY-MM-DD")}`
                    }
                },
                {
                    startDate: {
                        le: `${moment(endDate).format("YYYY-MM-DD")}`
                    }
                },
                { startTime: { ge: "00:00" } },
                { endTime: { le: "23:59" } }
            ],
            or: [
                { type: { eq: "UNAVAILABLE" } },
                { status: { eq: "PENDING" }, type: { eq: "BOOKED" } }
            ]
        },
        limit: process.env.REACT_APP_LISTLIMIT
    });
    const blockedTimes = [];
    if (response && response.items) {
        response.items.map((item) => {
            let rRule;
            if (item.status == "PENDING") {
                item.endDate = item.startDate;
            }
            ///calculate end date time in UTC
            let _enddatetime = makeDateTime(item.endDate, item.endTime);
            let _enddatetimeUTCStr = _enddatetime.toISOString();
            _enddatetimeUTCStr = _enddatetimeUTCStr.replaceAll("-", "");
            _enddatetimeUTCStr = _enddatetimeUTCStr.replaceAll(":", "");
            _enddatetimeUTCStr = _enddatetimeUTCStr.slice(0, 15);
            ///
            let endDateTime = _enddatetimeUTCStr;
            if (item.weekDays && item.weekDays.length) {
                //weekly rRule
                const weekDaysArr = item.weekDays.split("");
                weekDaysArr.sort();
                let wda = [];
                for (const wd of weekDaysArr) wda.push(WEEKDAY[wd]);

                rRule = `FREQ=WEEKLY;UNTIL=${endDateTime};WKST=SU;BYDAY=${wda.join()}`;
            } else {
                //Daily
                if (
                    item.startDate === item.endDate ||
                    item.type === "UNAVAILABLE"
                ) {
                    //start and end date is same, then sometimes the UNTIL is in the past and the block does not appear in the calendar
                    let _enddatetimeAdjusted = makeDateTime(
                        item.endDate,
                        "23:59"
                    );
                    let _enddatetimeUTCStrAdjuste =
                        _enddatetimeAdjusted.toISOString();
                    _enddatetimeUTCStrAdjuste =
                        _enddatetimeUTCStrAdjuste.replaceAll("-", "");
                    _enddatetimeUTCStrAdjuste =
                        _enddatetimeUTCStrAdjuste.replaceAll(":", "");
                    _enddatetimeUTCStrAdjuste = _enddatetimeUTCStrAdjuste.slice(
                        0,
                        15
                    );
                    let endDateTimeAdjusted = _enddatetimeUTCStrAdjuste;

                    rRule = `FREQ=DAILY;INTERVAL=1;UNTIL=${endDateTimeAdjusted}`;
                } else rRule = `FREQ=DAILY;INTERVAL=1;UNTIL=${endDateTime}`;
            }

            blockedTimes.push({
                rRule,
                startDate: makeDateTime(item.startDate, item.startTime),
                endDate: makeDateTime(item.startDate, item.endTime),
                id: item.id + SCHEDPKSKSPLITAT + item.scheduleinfo,
                providerId: provider.id,
                servicetype: {
                    name:
                        item.type === "UNAVAILABLE"
                            ? "Blocked"
                            : "Pending Booking"
                },
                title:
                    item.type === "UNAVAILABLE" ? "Blocked" : "Pending Booking",
                providerName: `${provider.firstname} ${provider.lastname}`,
                clientName: ""
            });
        });
    }
    return blockedTimes;
};

const removeLocationSchedules = async (locationId, providerId, companyId) => {
    // query for SC records
    const id = getScheduleTimeblockId(companyId, providerId);
    let response = await execReadBySortkey({
        opname: "listScheduleTimeblocks",
        op: scheduleQueries.listAllScheduleItems,
        id: { id: id },
        skey: { scheduleinfo: { beginsWith: "SC|" } },
        filter: null,
        limit: process.env.REACT_APP_LISTLIMIT
    });
    const schedules = response.items;

    // filter for matching location ID
    const matches = schedules.filter((s) => s.locationId === locationId);

    // set active and deleted fields if matches found
    if (matches && matches.length > 0) {
        for (let sch of matches) {
            const scheduleData = {
                id: id,
                scheduleinfo: sch.scheduleinfo,
                active: false,
                deleted: true
            };

            response = await execWrite({
                opname: "updateScheduleTimeblock",
                op: mutations.updateScheduleTimeblock,
                input: scheduleData
            });

            console.log(" updateScheduleTimeblock response:", response);
        }
    }
};

async function doTheseServicesHaveSchedules(
    services,
    serviceIds,
    companyId,
    providerId
) {
    let result = {
        foundScheduleForTheseServices: [],
        haveAtLeastOneSchedule: false,
        error: false
    };
    console.log("doTheseServicesHaveSchedules", services);
    try {
        //Get all relevant schedule timeblock info.
        const schResp = await execReadBySortkey({
            opname: "listScheduleTimeblocks",
            op: scheduleQueries.listAllScheduleItems,
            id: { id: getScheduleTimeblockId(companyId, providerId) },
            // skey: { scheduleinfo: { beginsWith: "SR|" } },
            filter: { active: { eq: true } },
            limit: process.env.REACT_APP_LISTLIMIT
        });
        //Get schedules associated with the service types.
        for (let scheduleTimeBlock of schResp.items) {
            if (scheduleTimeBlock.scheduleinfo.startsWith("SR|")) {
                if (serviceIds.includes(scheduleTimeBlock.servicetypeId)) {
                    //This is a SR Type, and is relevant to the removed types.
                    let splitId = scheduleTimeBlock.scheduleinfo.split("|SC");
                    let scheduleId = "SC" + splitId[1];
                    let schedule = schResp.items.filter(
                        (sch) => sch.scheduleinfo === scheduleId
                    );
                    //Since schedule is filtered by ID, should only be 1 item.
                    if (schedule.length > 0 && schedule[0].active) {
                        result.foundScheduleForTheseServices.push(
                            scheduleTimeBlock.servicetypeId
                        );
                        result.haveAtLeastOneSchedule = true;
                    }
                }
            }
        }
    } catch (e) {
        console.log(e);
        result["error"] = true;
        return result;
    }
    return result;
}

function displaySlotsByStep2(step, sm, tmap, serviceDuration, stiEtiArr) {
    let stepstarted = false;
    const steplen = step / SLOTUNITMINS;
    const serviceDurationlen = serviceDuration / SLOTUNITMINS;
    let returnStartTimes = [];

    let startIndex = 288,
        endIndex = 0;
    for (let stiEti of stiEtiArr) {
        let localEndIndex = stiEti.eti < stiEti.sti ? SLOTSINADAY : stiEti.eti;
        if (stiEti.sti < startIndex) startIndex = stiEti.sti;
        if (localEndIndex > endIndex) endIndex = localEndIndex;
    }
    const foundStartTimes = [];
    for (let s = startIndex; s < endIndex; s++) {
        if (stepstarted && sm[s] === 1) {
            for (let fst of foundStartTimes) {
                if (fst.live) fst.uc += 1;
            }
        } else {
            for (let fst of foundStartTimes) {
                fst.live = false;
            }

            stepstarted = false;
        }
        if ((s - startIndex) % steplen === 0) {
            //start of timeslot as per the configuration of step size
            if (sm[s] === 1) {
                foundStartTimes.push({ st: tmap[s], uc: 1, live: true });
                // currentStepStartTime = tmap[s];
                stepstarted = true;
            }
        }
    }
    returnStartTimes = returnStartTimes.concat(
        foundStartTimes
            .filter((fst) => fst.uc >= serviceDurationlen)
            .map((fst) => fst.st)
    );

    return returnStartTimes;
}

function toReableArray(slotmap, step, date, stiEtiArr, serviceDuration) {
    // console.log(
    //   "stiEtiArr",
    //   stiEtiArr,
    //   "serviceDuration",
    //   serviceDuration,
    //   step,
    //   date
    // );
    const readableSlots = [];
    const displaybleSlots = displaySlotsByStep2(
        step,
        slotmap,
        TIMEMAP,
        serviceDuration,
        stiEtiArr
    );
    // console.log("toReableArray", date, displaybleSlots);
    if (displaybleSlots.length > 0) {
        displaybleSlots.forEach((ds) => {
            const datetime = new Date(date.getTime());
            datetime.setHours(ds.hr);
            datetime.setMinutes(ds.mins);
            readableSlots.push({
                len: step,
                start24: ds.tstr24,
                start12: ds.tstr12,
                datetime,
                tfd: ds.tfd,
                hr: ds.hr
            });
        });
    }
    //console.log("toReableArray readableSlots", date, readableSlots);

    return readableSlots;
}

function toReadableSlots(schedule, sma, step, serviceDuration) {
    const readableArray = [];
    sma.forEach((d) => {
        readableArray.push({
            schid: schedule.id,
            date: d.date,
            slots: toReableArray(
                d.slotmap,
                step,
                d.date,
                d.stiEtiArr,
                serviceDuration
            )
        });
    });
    //console.log("readableArray, ", readableArray);
    return readableArray;
}

function getEndingDate(sd, nd) {
    const ed = awsDateToJsDate(sd);
    ed.setDate(ed.getDate() + nd);
    return getAWSDate(ed);
}
const getProviderLocation = async (id) => {
    const data = await graphql(
        graphqlOperation(
            `query GetProviderLocation($id: ID!) {
        getProviderLocation(id: $id) {
          id
          name 
          timezone
        }
      }`,
            {
                id
            }
        )
    );
    return data.data.getProviderLocation;
};

const getCompanyLocation = async (id) => {
    const data = await graphql(
        graphqlOperation(
            `query GetCompanyLocation($id: ID!) {
          getCompanyLocation(id: $id) {
          id
          locationname 
          timezone
        }
      }`,
            {
                id
            }
        )
    );
    return data.data.getCompanyLocation;
};

async function getLocationTimezone(scheduleinfo) {
    const parts = scheduleinfo.split("|");
    const locationId = parts[1].slice(3);
    if (scheduleinfo && scheduleinfo.includes("|PL-")) {
        const providerLocation = await getProviderLocation(locationId);
        if (providerLocation) {
            return providerLocation.timezone;
        }
    }
    if (scheduleinfo && scheduleinfo.includes("|CL-")) {
        const companyLocation = await getCompanyLocation(locationId);
        if (companyLocation) {
            return companyLocation.timezone;
        }
    }
    return BROWSER_TZ;
}
function calculateBookedMinutes(bkstartTime, bkendTime) {
    //this works only if start and end times are within the same date
    //Jan 10th 11pm to Jan 11th 1am won't work.
    const startHHMM = bkstartTime.split(":");
    const endHHMM = bkendTime.split(":");
    const startTime = new Date(2000, 1, 1, startHHMM[0], startHHMM[1]);
    const endTime = new Date(2000, 1, 1, endHHMM[0], endHHMM[1]);
    const diff = endTime.valueOf() - startTime.valueOf();
    const bookedMinutes = diff / (1000 * 60);
    console.log("bookedMinutes", bookedMinutes);
    return bookedMinutes;
}

function adjustGlobalBookingTimesForBookingLocationTimezone(
    locationTz,
    bookings,
    serviceDuration
) {
    for (let b of bookings) {
        if (b.sdtutc && b.startTime && b.endTime) {
            console.log("before:1", b.startTime, b.endTime);
            const bookedMinutes = calculateBookedMinutes(
                b.startTime,
                b.endTime
            );
            const startDateUTC = new Date(b.sdtutc);
            const startDateInLocZone = utcToZonedTime(startDateUTC, locationTz);
            b.startDate = format(startDateInLocZone, "yyyy-MM-dd", {
                timeZone: locationTz
            });
            b.startTime = format(startDateInLocZone, "HH:mm", {
                timeZone: locationTz
            });
            const endDateUTC = new Date(startDateInLocZone);
            endDateUTC.setMinutes(endDateUTC.getMinutes() + bookedMinutes);
            b.endTime = format(endDateUTC, "HH:mm", locationTz);
            console.log("before2:2", b.startTime, b.endTime, b);
        }
    }
}

function getIndexByTime(t) {
    const parts = t.split(":");
    const h = parseInt(parts[0]);
    const m = parseInt(parts[1]);
    const totalMins = h * 60 + m;
    const index = (h * 60 + m) / SLOTUNITMINS;
    // const index1 = ((h*60)+m) % SLOTSINADAY;
    return index;
}

function markAvailability(sm, si, ei) {
    if (ei === 0) ei = SLOTSINADAY;
    for (let s = si; s <= ei - 1; s++) {
        sm[s] = 1;
    }
}

function includeTravelTime(tb, geoLoc) {
    if (tb && tb.locationId && geoLoc) {
        let tbll = tb.locationId.split("|");
        if (!tbll || !tbll.length === 2) return true;
        const tbLat = Number(tbll[0]);
        const tbLng = Number(tbll[1]);
        const bookLat = Number(geoLoc.lat);
        const bookLng = Number(geoLoc.lng);
        const lat = tbLat - bookLat;
        const lng = tbLng - bookLng;
        const dLat = (lat * Math.PI) / 180;
        const dLng = (lng * Math.PI) / 180;
        const bookLatRad = (bookLat * Math.PI) / 180;
        const tbLatRad = (tbLat * Math.PI) / 180;

        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(bookLatRad) *
                Math.cos(tbLatRad) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = 6371e3 * c;
        if (d < 1000) return false;
    }
    return true;
}

function markUnAvailability(
    sm,
    si,
    ei,
    travelTime,
    tb,
    geoLoc,
    bookingIncrement
) {
    // console.log("markUnAvailability1", travelTime, si, ei, tb, geoLoc);
    // console.log(tb, geoLoc);
    if (tb && tb.locationId && geoLoc) {
        if (!includeTravelTime(tb, geoLoc)) travelTime = 0;
    }
    if (travelTime && travelTime > 0) {
        let ttIndLen = travelTime / SLOTUNITMINS;
        si = si - ttIndLen;
        ei = ei + ttIndLen;
        //console.log("markUnAvailability2", travelTime, si, ei);
        let clawbackInd = ei % (bookingIncrement / SLOTUNITMINS);
        if (clawbackInd <= 1) {
            //10 mins into the next half an hour like 10:10 or 10:40
            ei = ei - clawbackInd;
        }
        // console.log(
        //   "markUnAvailability3",
        //   travelTime,
        //   clawbackInd,
        //   ttIndLen,
        //   si,
        //   ei
        // );
    }
    //in above block ei could correspond to endTime 00:00 i.e. ei=0 (or near midnight like 11:50pm), and hence its travel time adjusted ei could be between 0 and 5 of the NEXT  DAY.
    // we have no way of storing and presenting this fact to users so reset the endTime back 00:00
    if (ei < si) ei = SLOTSINADAY;
    for (let s = si; s <= ei - 1; s++) {
        sm[s] = 0;
    }
}

function hasUnavailability(
    slotmapDate,
    unavStartDate,
    unavEndDate,
    unavWeekDays
) {
    // if SBU block has weekdays, weekday must match
    // otherwise only date match is required

    if (
        slotmapDate.getTime() >= unavStartDate.getTime() &&
        slotmapDate.getTime() <= unavEndDate.getTime()
    ) {
        if (unavWeekDays && unavWeekDays.length) {
            let unavaOnWeekday =
                unavWeekDays.indexOf(slotmapDate.getDay()) !== -1
                    ? true
                    : false;
            if (!unavaOnWeekday) return false;
        }
        // console.log(
        //   "has unavailability for ",
        //   slotmapDate,
        //   unavStartDate,
        //   unavEndDate
        // );
        return true;
    } else return false;
}

function computeAvailableSlots(
    tb,
    booked,
    sd,
    nd,
    bookingIncrement,
    travelTime,
    geoLoc
) {
    travelTime = travelTime ? travelTime : 0;
    const sdate = awsDateToJsDate(sd); //let it be local browser date
    //Create the placeholder array of by-date slots objects for asked numdays
    //For earch day create slots array based on step (15,30,60 mins)
    let slotMapArray = new Array(nd).fill(null).map((a, b, c) => ({
        date: new Date(
            sdate.getFullYear(),
            sdate.getMonth(),
            sdate.getDate() + b
        ),
        stiEtiArr: [],
        hasavail: false,
        slotmap: new Array(SLOTSINADAY) // initSlotMap(step)
    }));
    // console.log("slotMapArray", JSON.stringify(slotMapArray));
    //Check availability templates
    //iterate over the AVAILABLE timeblocks
    //  for each AVAILABLE timeblock
    //    based on startTime and endTime (and step), calculate start index and stop index to be marked as 1 (meaning available) [TBD: it is possible to have this number higher than 1 indicating that multiple booking is possible but need to design the feature end-to-end]
    //     Once start and stop indices are calculated, for each day in the slotMapArray which falls within the AVAILABLE timeblock's range and it is the workingday of the provider, fill the availability in each day's slotmap

    tb.filter((timeblock) => timeblock.type === "AVAILABLE").forEach(
        (timeblock) => {
            // console.log(
            //   `available start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            // console.log(
            //   `available start date: ${timeblock.startDate} end date: ${timeblock.endDate}`
            // );
            slotMapArray
                .filter((slotmap) => {
                    // console.log(
                    //   "slotmap.date",
                    //   slotmap.date,
                    //   awsDateToJsDate(timeblock.startDate),
                    //   awsDateToJsDate(timeblock.endDate)
                    // );
                    return (
                        slotmap.date >= awsDateToJsDate(timeblock.startDate) &&
                        slotmap.date <= awsDateToJsDate(timeblock.endDate) &&
                        worksonday(slotmap.date, timeblock.weekDays)
                    );
                })
                .forEach((slotmap) => {
                    slotmap.hasavail = true;
                    const sti = getIndexByTime(timeblock.startTime);
                    const eti = getIndexByTime(timeblock.endTime);
                    slotmap.stiEtiArr.push({ sti, eti });
                    markAvailability(slotmap.slotmap, sti, eti);
                });
        }
    );
    // console.log("AVAILABLE slotMapArray", JSON.stringify(slotMapArray));

    //console.log('u');//not doing anything for unavailable slots
    //TODO: do we need to check for UNAVAILABLE?
    tb.filter((timeblock) => timeblock.type === "UNAVAILABLE").forEach(
        (timeblock) => {
            // console.log(
            //   `unavailable date: ${timeblock.startDate}  start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.hasavail &&
                        hasUnavailability(
                            slotmap.date,
                            awsDateToJsDate(timeblock.startDate),
                            awsDateToJsDate(timeblock.endDate),
                            timeblock.weekDays
                        )
                )
                .forEach((slotmap) => {
                    markUnAvailability(
                        slotmap.slotmap,
                        getIndexByTime(timeblock.startTime),
                        getIndexByTime(timeblock.endTime),
                        0, //Travel timee zero for
                        null,
                        null,
                        bookingIncrement
                    );
                });
        }
    );
    // iterate over BOOKED blocks (appointments already booked)
    // For each booked appointment,
    //    compute start and stop index based on the start and end time of the appointment
    //    get the date of the appointment
    //    find the day in slotMapArray that matches the appointment date
    //    for the matched day in the slotMapArray, mark the slotmap's elements as booked (i.e. unavailable)
    booked
        .filter((timeblock) => timeblock.type === "BOOKED")
        .forEach((timeblock) => {
            // console.log(
            //   `booked date: ${timeblock.startDate}  start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.hasavail &&
                        slotmap.date.getTime() ===
                            awsDateToJsDate(timeblock.startDate).getTime()
                )
                .forEach((slotmap) => {
                    markUnAvailability(
                        slotmap.slotmap,
                        getIndexByTime(timeblock.startTime),
                        getIndexByTime(timeblock.endTime),
                        travelTime,
                        timeblock,
                        geoLoc,
                        bookingIncrement
                    );
                });
        });
    return slotMapArray;
}

const getProviderFutureAvailableSlots = async ({
    providerId,
    companyId,
    locationId,
    serviceId,
    startdate,
    numdays,
    timeofday,
    bookingIntervalMinutes,
    serviceDuration,
    travelTime,
    geoLoc,
    bookingIncrement
}) => {
    try {
        travelTime = travelTime ? travelTime : 0;
        bookingIntervalMinutes = bookingIntervalMinutes
            ? Number(bookingIntervalMinutes)
            : 0 === Number(bookingIntervalMinutes)
              ? 0
              : 0;
        const primaryKey = `C-${companyId}|P-${providerId}`;
        const startingDate = startdate ? startdate : getNowDate();
        const endingDate = getEndingDate(startingDate, numdays);
        const locationTz = await getLocationTimezone(`SC|${locationId}`);

        let scheduleData = {};
        // Get all future SB records (where the enddate is greater than or equal to today)
        const schResp = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: primaryKey },
            skey: { scheduleinfo: { beginsWith: "SB" } },
            filter: {
                active: { ne: false },
                deleted: { ne: true },
                endDate: { ge: moment().format("YYYY-MM-DD") }
            },
            limit: 500
        });
        if (!schResp && !schResp.items)
            throw new Error("Provider has no availablity");

        const filteredAvailability = filterAvailableBlocksByServiceAndLocation(
            schResp.items,
            serviceId,
            locationId
        );
        if (!filteredAvailability && !filteredAvailability.items)
            throw new Error("Provider has no availablity");

        scheduleData = {
            ...scheduleData,
            id: `${filteredAvailability[0].id}${SCHEDPKSKSPLITAT}${filteredAvailability[0].scheduleinfo}`,
            scheduleinfo: filteredAvailability[0].scheduleinfo
        };

        //Get all provider blocked times
        const avandunavBlocksResp = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: primaryKey },
            skey: { scheduleinfo: { beginsWith: "BUT" } },
            filter: { active: { ne: false }, deleted: { ne: true } },
            limit: 500
        });

        //Get all provider booking within a date range
        const bookedResp = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: primaryKey },
            skey: {
                scheduleinfo: {
                    between: [`BK|${startingDate}`, `BK|${endingDate}`]
                }
            },
            filter: { status: { ne: "CANCELLED" } },
            limit: 500
        });
        if (bookedResp && bookedResp.items) {
            adjustGlobalBookingTimesForBookingLocationTimezone(
                locationTz,
                bookedResp.items,
                serviceDuration
            );
            scheduleData = { ...scheduleData, booked: bookedResp.items };
        }

        const avTBs = filteredAvailability;
        const unavTBs = avandunavBlocksResp.items;

        let tbs = [];
        if (avTBs && avTBs.length > 0) tbs = tbs.concat(avTBs);
        if (unavTBs && unavTBs.length > 0) tbs = tbs.concat(unavTBs);

        const unreadableSlots = computeAvailableSlots(
            tbs,
            scheduleData.booked,
            startingDate,
            numdays,
            bookingIncrement,
            travelTime,
            geoLoc
        );

        const readableSlots = toReadableSlots(
            scheduleData,
            unreadableSlots.filter((urs) => urs.hasavail),
            bookingIncrement,
            serviceDuration
        );
        console.log("readableSlots", readableSlots);

        const slotsinfo = [
            {
                schedule: {
                    id: scheduleData.id,
                    scheduleinfo: scheduleData.scheduleinfo,
                    companyId: companyId,
                    providerId: providerId,
                    locationId: locationId,
                    avtbs: avTBs
                },
                slots: readableSlots
            }
        ];

        const futureSlotsOnly = filterOutTodaysPastSlots(
            slotsinfo,
            bookingIntervalMinutes
        );

        const s = !timeofday[0]
            ? filterByTimesofday(futureSlotsOnly, timeofday)
            : futureSlotsOnly; //Validate timeofday (1 for morning, 2 for afternoon, 3 for evening)
        return s;
    } catch (error) {
        console.log("Provider Availability Error: ", error);
    }
};

const filterAvailableBlocksByServiceAndLocation = (
    availableBlocks,
    serviceId,
    locationId
) => {
    return availableBlocks.filter((block) => {
        const services = JSON.parse(block.services);
        const locations = JSON.parse(block.locations);
        return services.includes(serviceId) && locations.includes(locationId);
    });
};

function filterOutTodaysPastSlots(slots, bookingIntervalMinutes) {
    const checkDateTime = new Date(
        Date.now() + bookingIntervalMinutes * 60 * 1000
    );
    slots.forEach((e) => {
        e.slots.forEach((day) => {
            if (day.date.getTime() < checkDateTime.getTime())
                day.slots = day.slots.filter((slot) =>
                    isFutureSlot_v3(slot, day.date, checkDateTime)
                );
        });
    });

    return slots;
}

function filterByTimesofday(slots, timesofday) {
    slots.forEach((e) => {
        e.slots.forEach((day) => {
            day.slots = day.slots.filter((slot) => timesofday[slot.tfd]);
        });
    });

    return slots;
}

function isFutureSlot_v3(slot, dayDate, checkDateTime) {
    //Calculate millis of the slot
    const dayDateMillis = dayDate.getTime();
    let slotDate = new Date(dayDateMillis);
    slotDate.setHours(Number.parseInt(slot.start24.slice(0, 2)));
    slotDate.setMinutes(Number.parseInt(slot.start24.slice(-2)));
    //console.log(slotDate, checkDateTime);
    return slotDate.getTime() > checkDateTime.getTime();
}
function getPhysicalLocationGeoLoc(serviceLocationObj) {
    if (serviceLocationObj) {
        let locObj = serviceLocationObj;
        if (locObj.latitude && locObj.longitude) {
            return {
                lat: locObj.latitude,
                lng: locObj.longitude
            };
        }
    }
    return;
}
async function getTimeSuggestions(
    geoLoc, //remote or physical/virtual
    providerSchedule,
    lookAheadDays = DEFAULT_NUMBER_OF_LOOKAHEAD_DAYS,
    date = new Date(),
    maxNumberOfSlots = MAX_NUMBER_OF_SUGGESTED_SLOTS_TO_DISPLAY,
    maxNumberOfFullDays = MAX_NUMBER_OF_SUGGESTED_DAYS_TO_DISPLAY,
    company,
    service,
    bookingLocationTimeZone
) {
    try {
        let firstBookingAnchorTime;
        let maxTravelTimeMinutes;
        if (company.SuggestionConfig) {
            const suggestionConfigObj = JSON.parse(company.SuggestionConfig);
            firstBookingAnchorTime =
                suggestionConfigObj.timeOfFirstBookingOfTheDay;
            maxTravelTimeMinutes = suggestionConfigObj.maxTravelTimeMinutes;
        }
        if (geoLoc) {
            const result = await postApi("bookingapi", "/clusteredslots", {
                body: {
                    scheduleid: providerSchedule,
                    startdate: getAWSDateOf(date),
                    numdays: lookAheadDays,
                    bookingIntervalMinutes: company.bookingIntervalMinutes,
                    serviceDuration: service.minutes,
                    maxTravelTime: maxTravelTimeMinutes, // Provider.maxTravelTime (when becomes available) or company.SuggestionConfig.maxTravelTimeMinutes
                    geoLoc,
                    bookingIncrement: 15,
                    BROWSER_TZ: bookingLocationTimeZone,
                    firstBookingAnchorTime,
                    locationTz: bookingLocationTimeZone
                }
            });
            if (
                result?.clusteredslots &&
                result.clusteredslots[0]?.clusteredAvailability
            ) {
                let clusteredSlotsInfo =
                    result.clusteredslots[0]?.clusteredAvailability;
                if (clusteredSlotsInfo.slots?.length > maxNumberOfSlots)
                    clusteredSlotsInfo.slots = clusteredSlotsInfo.slots.slice(
                        0,
                        maxNumberOfSlots
                    );
                if (clusteredSlotsInfo.fullDays?.length > maxNumberOfFullDays)
                    clusteredSlotsInfo.fullDays =
                        clusteredSlotsInfo.fullDays.slice(
                            0,
                            maxNumberOfFullDays
                        );
                return clusteredSlotsInfo;
            }
        } else {
            //handle error situation where there geoLoc on physical location
            return {
                slots: [],
                fullDays: []
            };
        }
    } catch (e) {
        console.log(e);
        return {
            slots: [],
            fullDays: []
        };
    }
}

function getTravelTimeValidityWarningMessage(
    travelTimeWarning,
    bookingNextOrPrevious
) {
    if (TRAVEL_TIME_WARNING.OVERLAP.code === travelTimeWarning) {
        return TRAVEL_TIME_WARNING.OVERLAP.message;
    } else if (TRAVEL_TIME_WARNING.OVER_MAX_TRAVEL_TIME === travelTimeWarning) {
        return TRAVEL_TIME_WARNING.OVER_MAX_TRAVEL_TIME.message;
    } else if (
        TRAVEL_TIME_WARNING.TOO_MUCH_TIME_BETWEEN_BOOKINGS === travelTimeWarning
    ) {
        return TRAVEL_TIME_WARNING.TOO_MUCH_TIME_BETWEEN_BOOKINGS.message;
    } else if (
        TRAVEL_TIME_WARNING.NOT_ENOUGH_TIME_TO_TRAVEL_TO === travelTimeWarning
    ) {
        return bookingNextOrPrevious === NEXT_BOOKING
            ? TRAVEL_TIME_WARNING.NOT_ENOUGH_TIME_TO_TRAVEL_TO
                  .nextBookingMessage
            : TRAVEL_TIME_WARNING.NOT_ENOUGH_TIME_TO_TRAVEL_TO
                  .previousBookingMessage;
    } else return "Travel times between bookings are not optimized";
}

async function getTravelTimeValidity({
    bookingDateTime, //bookingDate is string - coming over web
    bookingGeoLoc,
    provider,
    company,
    service
}) {
    try {
        let maxTravelTimeMinutes;
        if (company.SuggestionConfig) {
            const suggestionConfigObj = JSON.parse(company.SuggestionConfig);
            maxTravelTimeMinutes = suggestionConfigObj.maxTravelTimeMinutes;
        }
        const result = await postApi("bookingapi", "/traveltime", {
            body: {
                bookingDateTime,
                bookingGeoLoc,
                providerId: provider.id,
                companyId: company.id,
                maxTravelTime: maxTravelTimeMinutes,
                serviceDuration: service.minutes
            }
        });
        if (result.success && result?.travelTimeAnalysis) {
            if (result.travelTimeAnalysis.previousBooking?.travelTimeAnalysis) {
                result.travelTimeAnalysis.previousBooking.travelTimeWarningMessage =
                    getTravelTimeValidityWarningMessage(
                        result.travelTimeAnalysis.previousBooking
                            .travelTimeAnalysis,
                        PREVIOUS_BOOKING
                    );
            }
            if (result.travelTimeAnalysis.nextBooking?.travelTimeAnalysis) {
                result.travelTimeAnalysis.nextBooking.travelTimeWarningMessage =
                    getTravelTimeValidityWarningMessage(
                        result.travelTimeAnalysis.nextBooking
                            .travelTimeAnalysis,
                        NEXT_BOOKING
                    );
            }
            return result.travelTimeAnalysis;
        }

        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export {
    createSchedule,
    updateSchedule,
    createTimeblock,
    updateTimeblock,
    getNowDate, //returns browser local TZ now date in YYYY-MM-DD format
    getAWSDateOf, //returns a give given date in YYYY-MM-DD format
    awsDateToJsDate, // turns YYYY-MM-DD formated date into JS date object of browser local TZ
    getAWSDate, //returns a given date in YYYY-MM-DD format
    _getSchedules,
    _getTimeblocks,
    getProviderSchedulesByLocationByService,
    getProviderDataForSchedules,
    getScheduleData,
    getUnavailabilityOfProvider,
    getUnavailabilityOfProviderScheduleList,
    createUnavailableTimeblock,
    SCHEDPKSKSPLITAT,
    _getSchedulesTime,
    getProviderFutureAvailableSlots,
    getTimeblocksbyScheduleId,
    getTimeblocksbyScheduleIdNoCache,
    getAnyLocationObj,
    getScheduleCounts,
    checkServiceHasExistingSchedule,
    activateSchedule,
    deactivateSchedule,
    removeLocationSchedules,
    doTheseServicesHaveSchedules,
    getBookingsList,
    _getAvailability,
    getScheduleInfo,
    getScheduleTimeblockId,
    getScheduleDatesToReport,
    TIMEMAP,
    getTimeSuggestions,
    getTravelTimeValidity,
    createProviderScheduleBk,
    createProviderScheduleBK
};
