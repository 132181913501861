import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { Box, Stack, Typography } from "@mui/material";

export default function FakeCircularProgress({
    initialValue,
    incrementBy,
    updateDurationMiliseconds,
    spinnerSize,
    message
}) {
    const [progress, setProgress] = React.useState(initialValue);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 100 : prevProgress + incrementBy
            );
        }, updateDurationMiliseconds);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Stack spacing={2} direction="column" alignItems="center">
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                    size={spinnerSize}
                    variant="determinate"
                    value={progress}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Typography variant="caption" component="div">
                        {`${Math.round(progress)}%`}
                    </Typography>
                </Box>
            </Box>
            {message && (
                <Typography sx={{ fontSize: "14px" }}>{message}</Typography>
            )}
        </Stack>
    );
}
