import React, { useContext, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { userHasMarketboxAdminRole } from "../user/UserCommon";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    Button
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    }
}));

const Pages = {
    masterDashboard: "MasterDashboard",
    updateEmail: "UpdateEmailForm",
    dashboard: "Dashboard",
    provider: "Service Provider",
    customer: "Customer",
    bookings: "Bookings",
    orders: "Orders",
    billing: "Billing",
    services: "Services",
    locations: "Company Locations",
    promotions: "Promotions",
    reports: "Reports",
    company: "Company",
    admin: "Admin",
    package: "Packages",
    users: "Users",
    region: "Region",
    admincolor: "ColorForm",
    envt: "EnvtForm"
};

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function AdminForm(props) {
    const classes = useStyles();
    const { state, dispatch, actions } = useContext(StoreContext);

    const [featureDisabled, setShowFeatureDisabled] = useState(false);

    function handleMenuListClick(nextPage) {
        //console.log('nextPage = ' + nextPage);
        if (nextPage === Pages.dashboard) actions.setPage("DashboardForm");
        else if (nextPage === Pages.masterDashboard)
            actions.setPage("MasterDashboardForm");
        else if (nextPage === Pages.updateEmail)
            actions.setPage("UpdateEmailForm");
        else if (nextPage === Pages.provider) actions.setPage("ProviderForm");
        else if (nextPage === Pages.customer) actions.setPage("CustomerForm");
        else if (nextPage === Pages.bookings) actions.setPage("BookingForm");
        else if (nextPage === Pages.orders) actions.setPage("OrderForm");
        else if (nextPage === Pages.billing) actions.setPage("BillingForm");
        else if (nextPage === Pages.services) actions.setPage("ServiceForm");
        else if (nextPage === Pages.promotions)
            actions.setPage("PromotionForm");
        else if (nextPage === Pages.reports) actions.setPage("ReportForm");
        else if (nextPage === Pages.company) actions.setPage("CompanyForm");
        else if (nextPage === Pages.admin) actions.setPage("AdminForm");
        else if (nextPage === Pages.package) actions.setPage("PackageForm");
        else if (nextPage === Pages.users) actions.setPage("UsersForm");
        else if (nextPage === Pages.region) actions.setPage("RegionForm");
        else if (nextPage === Pages.admincolor) actions.setPage("ColorForm");
        else if (nextPage === Pages.locations)
            actions.setPage("CompanyLocationForm");
        else if (nextPage === Pages.envt) actions.setPage("EnvtForm");
        //else if (nextPage === Pages.audit) actions.setPage("AuditRecordsForm");
    }

    let title = (
        <Typography
            id="MBAdministrationForm"
            className={classes.title}
            sx={{ fontSize: "24px", fontWeight: 400 }}
            variant="h4"
            noWrap
        >
            Administration
        </Typography>
    );

    let env = (
        <>
            <ListItem onClick={() => handleMenuListClick(Pages.envt)}>
                <ListItemIcon>
                    <SettingsApplicationsIcon />
                </ListItemIcon>
                <ListItemText primary="View Environment" />
            </ListItem>
            <Divider></Divider>
        </>
    );

    let masterDashboard = (
        <>
            <ListItem
                onClick={() => handleMenuListClick(Pages.masterDashboard)}
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Master Dashboard" />
            </ListItem>
            <Divider></Divider>
        </>
    );

    let updateEmail = (
        <>
            <ListItem
                button
                onClick={() => handleMenuListClick(Pages.updateEmail)}
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Update Email" />
            </ListItem>
            <Divider></Divider>
        </>
    );

    var options;
    var result;

    if (userHasMarketboxAdminRole()) {
        result = [masterDashboard, env, updateEmail];
    }

    if (result) {
        options = (
            <List component="nav" aria-label="main mailbox folders">
                {result}
            </List>
        );

        options = <div className={classes.root}>{options}</div>;
    }

    let display = [title, options];

    if (featureDisabled == false) {
        return display;
    } else {
        return (
            <>
                <Dialog
                    open={featureDisabled}
                    fullWidth={true}
                    aria-labelledby="show-client-dialog-title"
                    aria-describedby="show-client-dialog-description"
                >
                    <DialogTitle
                        id="show-client-dialog-title"
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                    >
                        This feature is currently disabled on your account
                    </DialogTitle>
                    <DialogContent dividers style={{ padding: 22 }}>
                        <DialogContentText id="show-client-dialog-description">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        To turn it on: Go to Company Settings{" "}
                                        {"\u2192"} Turn on 'Company Collects
                                        Payments'
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        You will then need to connect your
                                        Stripe account to MarketBox
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={0}>
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setShowFeatureDisabled(false)
                                        }
                                        color="primary"
                                    >
                                        Okay
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default AdminForm;
