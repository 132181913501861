import moment from "moment";
import { execReadBySortkey, execWrite } from "./DBService";
import * as scheduleQueries from "./ScheduleQueries";
import * as mutations from "../graphql/mutations";
import { SCHEDPKSKSPLITAT } from "./ScheduleService";
const SLOTSINADAY = 288;
const SLOTUNITMINS = 5;

async function getAllSbBlocks(schPK, schSK) {
    const schResp = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: { id: schPK },
        skey: { scheduleinfo: { beginsWith: "SB" } },
        filter: { active: { ne: false } },
        limit: process.env.REACT_APP_LISTLIMIT
    });

    if (schResp && schResp.items) {
        return { success: true, queryResponse: { Items: schResp.items } };
    } else {
        return { success: false };
    }
}

async function getAllButBlocks(schPK) {
    const schResp = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: { id: schPK },
        skey: { scheduleinfo: { beginsWith: "BUT" } },
        filter: { active: { ne: false } },
        limit: process.env.REACT_APP_LISTLIMIT
    });

    if (schResp && schResp.items) {
        return { success: true, queryResponse: { Items: schResp.items } };
    } else {
        return { success: false };
    }
}

async function getAllBKs(schPK, sd, ed) {
    const bookedResp = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: { id: schPK },
        skey: {
            scheduleinfo: {
                between: [`BK|${sd}`, `BK|${ed}`]
            }
        }, //TODO: should be based on StartDate and EndDate
        filter: { status: { ne: "CANCELLED" } },
        limit: process.env.REACT_APP_LISTLIMIT
    });
    if (bookedResp && bookedResp.items) {
        return { success: true, queryResponse: { Items: bookedResp.items } };
    } else {
        return { success: false };
    }
}

const checkAvailability = async (
    schPK,
    schSK,
    bookingDate,
    bookingTimeStr,
    serviceLengthMins
) => {
    let sbs, sbus, bks;
    console.log(
        "checkAvailability",
        schPK,
        schSK,
        bookingDate,
        bookingTimeStr,
        serviceLengthMins
    );
    const dr = getDateRange(bookingDate);
    console.log("dr", dr);
    let isAvailable = false;
    //Read from DB all SBs that includes SBUs
    //Read from DB all the future BKs for next 3 months
    let result = await getAllSbBlocks(schPK, schSK);
    let allButRecords = await getAllButBlocks(schPK);
    if (result.success) {
        const blocks = result.queryResponse.Items;
        result = await getAllBKs(schPK, dr.sd, dr.ed);
        if (result.success) {
            bks = result.queryResponse.Items;
            sbs = blocks.filter((b) => b.scheduleinfo.indexOf(schSK) > 0);
            sbus = allButRecords.queryResponse.Items;
            let tbs = [];
            if (sbs && sbs.length > 0) tbs = tbs.concat(sbs);
            if (sbus && sbus.length > 0) tbs = tbs.concat(sbus);
            const unreadableSlots = computeAvailableSlots(
                tbs,
                bks,
                dr.sd,
                2, //What should set this to for checking acceptance availability
                30 //get this value as input to this method
            );
            //   console.log(
            //     "calling calculateDailyStats for:" + schSK,
            //     JSON.stringify(unreadableSlots, null, 2)
            //   );
            isAvailable = isSlotAvailable(
                unreadableSlots,
                bookingDate,
                bookingTimeStr,
                serviceLengthMins
            );
        }
    }
    console.log("isAvailable", isAvailable);
    return isAvailable;
};

function isSlotAvailable(
    unreadableSlots,
    bookingDate,
    bookingTimeStr,
    serviceLengthMins
) {
    let yesno = false;
    //calculate starting index by bookingTimeStr
    const stInd = getIndexByTime(bookingTimeStr);
    //calculate ending index by using starting index and serviceLengthMins
    const numOfRequiredAvailableUnits = serviceLengthMins / SLOTUNITMINS;
    const enInd = stInd + (numOfRequiredAvailableUnits - 1);
    console.log(
        "stInd, enInd, numOfRequiredAvailableUnits",
        stInd,
        enInd,
        numOfRequiredAvailableUnits
    );
    for (let d of unreadableSlots) {
        let countOfAvailableUnits = 0;
        if (
            d.date.getMonth() === bookingDate.getMonth() &&
            d.date.getDate() === bookingDate.getDate() &&
            d.date.getFullYear() === bookingDate.getFullYear()
        ) {
            //   for (let i = 0; i < d.slotmap.length; i++) {
            //     console.log(" slot i=", i, d.slotmap[i]);
            //   }
            for (let cell = stInd; cell <= enInd; cell++) {
                //      console.log(d.slotmap[cell]);
                if (d.slotmap[cell] === 1) countOfAvailableUnits++;
            }
            console.log("countOfAvailableUnits", countOfAvailableUnits);
            if (countOfAvailableUnits === numOfRequiredAvailableUnits)
                yesno = true;
        }
    }
    return yesno;
}

function hasUnavailability(slotmapDate, unavStartDate, unavEndDate) {
    if (
        slotmapDate.getTime() >= unavStartDate.getTime() &&
        slotmapDate.getTime() <= unavEndDate.getTime()
    )
        return true;
    else return false;
}

function computeAvailableSlots(tb, booked, sd, nd, step) {
    //sd - start date, nd - number of days
    const sdate = awsDateToJsDate(sd);
    let slotMapArray = new Array(nd).fill(null).map((a, b, c) => ({
        date: new Date(
            sdate.getFullYear(),
            sdate.getMonth(),
            sdate.getDate() + b
        ),
        stiEtiArr: [],
        hasavail: false,
        slotmap: new Array(SLOTSINADAY).fill(0) // initSlotMap(step)
    }));
    // console.log("slotMapArray", JSON.stringify(slotMapArray));
    tb.filter((timeblock) => timeblock.type === "AVAILABLE").forEach(
        (timeblock) => {
            // console.log(
            //   `available start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            // console.log(
            //   `available start date: ${timeblock.startDate} end date: ${timeblock.endDate}`
            // );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.date >= new Date(timeblock.startDate) &&
                        slotmap.date <= new Date(timeblock.endDate) &&
                        worksonday(slotmap.date, timeblock.weekDays)
                ) //TODO: fix the date constructor
                .forEach((slotmap) => {
                    slotmap.hasavail = true;
                    const sti = getIndexByTime(timeblock.startTime);
                    const eti = getIndexByTime(timeblock.endTime);
                    slotmap.stiEtiArr.push({ sti, eti });
                    markAvailability(slotmap.slotmap, sti, eti);
                });
        }
    );
    //console.log('u');//not doing anything for unavailable slots
    //TODO: do we need to check for UNAVAILABLE?
    tb.filter((timeblock) => timeblock.type === "UNAVAILABLE").forEach(
        (timeblock) => {
            //    console.log(
            //      `unavailable date: ${timeblock.startDate}  start time: ${timeblock.startTime} end time: $//{timeblock.endTime}`
            //    );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.hasavail &&
                        hasUnavailability(
                            slotmap.date,
                            awsDateToJsDate(timeblock.startDate),
                            awsDateToJsDate(timeblock.endDate)
                        )
                )
                .forEach((slotmap) => {
                    markUnAvailability(
                        slotmap.slotmap,
                        getIndexByTime(timeblock.startTime),
                        getIndexByTime(timeblock.endTime)
                    );
                });
        }
    );
    // iterate over BOOKED blocks (appointments already booked)
    // For each booked appointment,
    //    compute start and stop index based on the start and end time of the appointment
    //    get the date of the appointment
    //    find the day in slotMapArray that matches the appointment date
    //    for the matched day in the slotMapArray, mark the slotmap's elements as booked (i.e. unavailable)
    booked
        .filter((timeblock) => timeblock.type === "BOOKED")
        .forEach((timeblock) => {
            //   console.log(
            //     `booked date: ${timeblock.startDate}  start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            //   );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.hasavail &&
                        slotmap.date.getTime() ===
                            awsDateToJsDate(timeblock.startDate).getTime()
                )
                .forEach((slotmap) => {
                    markUnAvailability(
                        slotmap.slotmap,
                        getIndexByTime(timeblock.startTime),
                        getIndexByTime(timeblock.endTime)
                    );
                });
        });
    return slotMapArray;
}

function worksonday(sample, range) {
    return range.indexOf(sample.getDay()) !== -1 ? true : false;
}

function getDateRange(bookingDate) {
    const sD = new Date(bookingDate);
    const sAwsD = getAWSDateOf(sD);
    sD.setDate(sD.getDate() + 1);
    const eAwsD = getAWSDateOf(sD);

    // console.table(months);
    return { sd: sAwsD, ed: eAwsD };
}

function awsDateToJsDate(awsDate) {
    // from YYYY-MM-DD to local timezone
    const dateparts = awsDate.split("-");
    return new Date(
        parseInt(dateparts[0]),
        parseInt(dateparts[1] - 1),
        parseInt(dateparts[2])
    ); //yes, local time
}

function getAWSDateOf(date) {
    return getAWSDate(date);
}

function getAWSDate(date) {
    let oy = { year: "numeric" };
    let YYYY = date.toLocaleDateString("en-US", oy);
    let om = { month: "2-digit" };
    let MM = date.toLocaleDateString("en-US", om);
    let od = { day: "2-digit" };
    let DD = date.toLocaleDateString("en-US", od);
    return `${YYYY}-${MM}-${DD}`;
}
function getIndexByTime(t) {
    const parts = t.split(":");
    const h = parseInt(parts[0]);
    const m = parseInt(parts[1]);
    const totalMins = h * 60 + m;
    const index = (h * 60 + m) / SLOTUNITMINS;
    return index;
}

function markAvailability(sm, si, ei) {
    for (let s = si; s <= ei - 1; s++) {
        sm[s] = 1;
    }
}

function markUnAvailability(sm, si, ei) {
    for (let s = si; s <= ei - 1; s++) {
        sm[s] = 0;
    }
}

export { checkAvailability };
