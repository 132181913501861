import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    Button,
    Tabs,
    Tab,
    Typography,
    Grid
} from "@mui/material";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import ProfileTab from "./ProfileTab";
import AvailabilityTab from "./AvailabilityTab";
import BlockedTimeTab from "./BlockedTimeTab";
import { useAtom } from "jotai";
import {
    selectedUserIdAtom,
    selectedUserAtom,
    userModalTabValue
} from "../../atoms/usersTable";
import { useQuery } from "@tanstack/react-query";
import { graphqlOperation, graphql } from "../../modules/AmplifyServices";
import {
    skillByCompanyCustom,
    serviceTypeByCompanyCustom
} from "../../queries/ProviderFormQueries";
import { companyLocationByCompany } from "../../queries/ListBookingsQueries";
import {
    mbxUserAtom,
    isMbxUserProvider,
    allActiveServicesAtom,
    allActiveSkillsAtom,
    allActiveLocationsAtom
} from "../../atoms/atoms";
import { useNavigate } from "react-router-dom";

const UserProfileModal = ({ open, onClose }) => {
    const navigate = useNavigate();
    const [mbxUser] = useAtom(mbxUserAtom);
    const [isProviderLoggedIn] = useAtom(isMbxUserProvider);
    const { companyId } = mbxUser;
    const [tabValue, setTabValue] = useAtom(userModalTabValue);
    const [, setSelectedUserId] = useAtom(selectedUserIdAtom);
    const [, setAllActiveServices] = useAtom(allActiveServicesAtom);
    const [, setAllActiveSkills] = useAtom(allActiveSkillsAtom);
    const [, setAllActiveLocations] = useAtom(allActiveLocationsAtom);
    const [selectedUser] = useAtom(selectedUserAtom);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            //Delay added so when modal closes User doesnt see all states being reset
            setTabValue(0);
            if (!isProviderLoggedIn) setSelectedUserId(null);
        }, 100);
    };

    const handleGoToCalendar = () => {
        const path = `/schedule?pIds=${selectedUser.provider.id}`;
        navigate(path);
        handleClose();
    };

    useQuery({
        queryKey: ["get-services"],
        queryFn: async () => {
            try {
                let {
                    data: {
                        serviceTypeByCompany: { items }
                    }
                } = await graphql(
                    graphqlOperation(serviceTypeByCompanyCustom, {
                        companyId: companyId,
                        filter: {
                            deleted: { ne: true },
                            active: { eq: true },
                            isBundledService: { ne: true }
                        },
                        limit: 500
                    })
                );
                return items || null;
            } catch (e) {
                console.log(e);
            }
        },
        onSuccess: (servicesData) => {
            setAllActiveServices(servicesData);
        }
    });
    useQuery({
        queryKey: ["get-skills"],
        queryFn: async () => {
            try {
                let {
                    data: {
                        skillByCompany: { items }
                    }
                } = await graphql(
                    graphqlOperation(skillByCompanyCustom, {
                        companyId: companyId,
                        filter: {
                            deleted: { ne: true },
                            active: { eq: true }
                        },
                        limit: 500
                    })
                );
                return items || null;
            } catch (e) {
                console.log(e);
            }
        },
        onSuccess: (SkillsData) => {
            setAllActiveSkills(SkillsData);
        }
    });
    useQuery({
        queryKey: ["get-locations"],
        queryFn: async () => {
            try {
                let {
                    data: {
                        companyLocationByCompany: { items }
                    }
                } = await graphql(
                    graphqlOperation(companyLocationByCompany, {
                        companyId: companyId,
                        filter: {
                            deleted: { ne: true }
                        },
                        limit: 500
                    })
                );
                return items || null;
            } catch (e) {
                console.log(e);
            }
        },
        onSuccess: (LocationsData) => {
            setAllActiveLocations(LocationsData);
        }
    });

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle
                sx={{
                    padding: "36px 36px 0px 36px"
                }}
            >
                <Grid container sx={{ alignItems: "center", gap: "18px" }}>
                    <Typography variant="h3">
                        {selectedUser
                            ? `${selectedUser?.firstname} ${selectedUser?.lastname}`
                            : "User Name"}
                    </Typography>
                </Grid>
                {selectedUser && (
                    <Grid
                        container
                        justifyContent={
                            selectedUser ? "space-between" : "flex-end"
                        }
                        alignItems="center"
                    >
                        <Tabs value={tabValue} onChange={handleChangeTab}>
                            <Tab label="Profile" />
                            <Tab label="Availability" />
                            <Tab label="Blocked Time" />
                        </Tabs>

                        <Button
                            variant="text"
                            sx={{
                                gap: "8px"
                            }}
                            onClick={handleGoToCalendar}
                        >
                            <Typography
                                variant="button"
                                sx={{
                                    textTransform: "none",
                                    lineHeight: "16px"
                                }}
                            >
                                {`Go to calendar`}
                            </Typography>
                            <EventRoundedIcon color="primary" />
                        </Button>
                    </Grid>
                )}
            </DialogTitle>

            {tabValue === 0 && <ProfileTab onClose={handleClose} />}
            {tabValue === 1 && <AvailabilityTab onClose={handleClose} />}
            {tabValue === 2 && <BlockedTimeTab onClose={handleClose} />}
        </Dialog>
    );
};

export default UserProfileModal;
