import React, { useState, useRef } from "react";
import {
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    TextField,
    Box
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as mutations from "../graphql/mutations";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import moment from "moment";
import Button from "@mui/material/Button";
import { auditOrderNotesUpdate } from "../modules/Audit";

const useStyles = makeStyles({
    notesInput: {
        borderColor: "initial",
        transition: "border-color 0.3s",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid black"
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
            /* border: "1px solid rgba(0, 135, 238, 0.66)",
            backgroundColor: "rgba(0, 135, 238, 0.12)" */
            border: "1px solid black"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        }
    },
    mainButtonContainer: {
        padding: "10px 0px"
    },
    leftButtonContainer: {
        display: "flex",
        gap: "12px"
    },
    rightButtonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    saveButton: {
        color: "white",
        borderRadius: "4px",
        padding: "6px 42px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        height: "32px"
    },
    cancelButton: {
        cursor: "pointer",
        color: "rgb(0,0,0,0.8)",
        height: "32px"
    },
    clearButton: {
        cursor: "pointer",
        color: "#FF681D",
        paddingRight: "0px",
        justifyContent: "right",
        height: "32px"
    },
    notesUpdateText: {
        fontSize: "12px",
        fontStyle: "italic",
        color: "#5d5d5d",
        paddingTop: "4px"
    }
});

function BookingNotesDialog(props) {
    const [notes, setNotes] = useState(
        props.noteDialogContent.notes ? props.noteDialogContent.notes : ""
    );
    const [originalNotes, setOriginalNotes] = useState(
        props.noteDialogContent.notes ? props.noteDialogContent.notes : ""
    );
    const [bookingNotes, setBookingNotes] = useState(props.noteDialogContent);
    const [updatedAt, setUpdatedAt] = useState(
        props.noteDialogContent ? props.noteDialogContent.updatedAt : ""
    );
    const [updatedBy, setUpdatedBy] = useState(
        props.noteDialogContent ? props.noteDialogContent.updatedBy : ""
    );

    const [clientNotes, setClientNotes] = useState();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const classes = useStyles();
    const notesRef = useRef(null);

    function getInitials(fullName) {
        const names = fullName.split(" ");
        const initials = names.map((name) => name[0].toUpperCase()).join("");
        return initials;
    }

    const handleInputFocus = () => {
        setIsInputFocused(true);
        /* console.log("in focus"); */
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
        /* console.log("out of focus"); */
    };

    const handleNotesChange = (event) => {
        const newText = event.target.value;
        setNotes(newText);
        /* console.log("Updated Notes:", newText); */
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClear = () => {
        setNotes("");
        notesRef.current.focus();
    };

    const saveBookingNotes = async () => {
        /* try {
            const bookingInput = {
                id: "b7a3726b-3f82-48ab-b4e5-fea8b7c56ca2",
                Notes: JSON.stringify([
                    {
                        createdBy: "Omar Tahir",
                        createdAt: "2023-08-18T02:49:12.924Z",
                        notes: "EDIT Student: Omar !! Tahir age 16 EDIT"
                    }
                ])
            };
            const result = await graphql(
                graphqlOperation(mutations.updateBooking, {
                    input: bookingInput
                })
            );

            console.log("this is the result my guy", result);
        } catch (e) {
            console.log("ERROR: Unable to save Booking notes");
        } */

        document.activeElement.blur(); // Unfocus the text field
        setOriginalNotes(notes);
        setUpdatedBy(
            props.loggedInUser.firstname + " " + props.loggedInUser.lastname
        );
        setUpdatedAt(moment().format("YYYY-MM-DD HH:mm"));
        let newNotes = {};
        const date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        try {
            if (bookingNotes) {
                //if Notes exists, then only update and use the same createdAt and createdBy attributes
                newNotes = {
                    notes: notes,
                    createdBy: bookingNotes.createdBy,
                    updatedBy:
                        props.loggedInUser.firstname +
                        " " +
                        props.loggedInUser.lastname,
                    createdAt: bookingNotes.createdAt,
                    updatedAt: date
                };
                setBookingNotes(newNotes);
            } else {
                //if Notes dont exists, add new created stamps and update stamps
                newNotes = {
                    notes: notes,
                    createdBy:
                        props.loggedInUser.firstname +
                        " " +
                        props.loggedInUser.lastname,
                    updatedBy:
                        props.loggedInUser.firstname +
                        " " +
                        props.loggedInUser.lastname,
                    createdAt: date,
                    updatedAt: date
                };
                setBookingNotes(newNotes);
            }

            const orderInput = {
                id: props.noteOrderNumber,
                Notes: JSON.stringify([newNotes])
            };
            const result = await graphql(
                graphqlOperation(mutations.updateOrder, {
                    input: orderInput
                })
            );

            console.log("this is the result my guy", result);

            props.onSaveNotes(
                props.noteOrderNumber,
                JSON.stringify([newNotes])
            );

            props.onClose();

            if (result && result.data && result.data.updateOrder) {
                try {
                    await auditOrderNotesUpdate(
                        props.loggedInUser,
                        result.data.updateOrder,
                        props.rowBookingId,
                        notes,
                        originalNotes,
                        "ORDER_NOTES"
                    );
                } catch (e) {
                    console.log("Error while entering audit");
                }
            }
            /* console.log(
                "successfully updated Client Omar, heres rows",
                props.rows
            ); */
            //props.onSaveNotes(props.clientId, JSON.stringify(newNotes));
        } catch (e) {
            console.log("ERROR: Unable to save Booking notes ", e);
        }
    };

    return (
        <>
            <DialogTitle sx={{ color: "#000000", opacity: "0.75" }}>
                Note
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "0px"
                }}
            >
                <Box sx={{ paddingBottom: "16px" }}>
                    <Typography>
                        <b>Customer: </b>
                        {props.noteClientName}
                    </Typography>
                    <Typography>
                        <b>Service: </b>
                        {props.noteServiceName}
                    </Typography>
                    <Typography>
                        <b>Start date: </b>
                        {props.noteBookingStartDate}
                    </Typography>
                </Box>
                {/* <Paper
                        sx={{
                            backgroundColor: "#ececec",
                            padding: "12px",
                            borderRadius: "6px",
                            boxShadow: "none"
                        }}
                    >
                        <Typography>{noteDialogContent}</Typography>
                    </Paper> */}
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    InputProps={{
                        className: classes.notesInput
                        /*  onFocus: handleInputFocus,
                                onBlur: handleInputBlur */
                    }}
                    value={notes}
                    onChange={handleNotesChange}
                    inputRef={notesRef}
                />
                {updatedBy && (
                    <Grid container item xs={12}>
                        <Typography className={classes.notesUpdateText}>
                            Last edited by {updatedBy} on{" "}
                            {moment(updatedAt).format("YYYY-MM-DD HH:mm")}
                        </Typography>
                    </Grid>
                )}
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.mainButtonContainer}
                >
                    <Grid item xs={4} className={classes.leftButtonContainer}>
                        <Button
                            onClick={saveBookingNotes}
                            variant="contained"
                            className={classes.saveButton}
                            disabled={notes === originalNotes ? true : false}
                        >
                            <Typography
                                variant="button"
                                style={{
                                    textTransform: "none"
                                }}
                            >
                                {`Save`}
                            </Typography>
                        </Button>
                        <Button
                            onClick={handleCancel}
                            variant="text"
                            className={classes.cancelButton}
                        >
                            {
                                <Typography
                                    variant="button"
                                    style={{
                                        textTransform: "none"
                                    }}
                                >
                                    {`Cancel`}
                                </Typography>
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={8} className={classes.rightButtonContainer}>
                        <Button
                            onClick={handleClear}
                            variant="text"
                            className={classes.clearButton}
                        >
                            <Typography
                                variant="button"
                                style={{
                                    textTransform: "none"
                                }}
                            >
                                {`Clear`}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}

export default BookingNotesDialog;
