import React, { useState, useEffect } from "react";
// prettier-ignore
import { TextField, Button, Paper, Grid, IconButton, FormControl, Typography, Snackbar, Dialog, DialogContent, DialogContentText, DialogActions, CircularProgress } from "@mui/material";
// prettier-ignore
//import { DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { CustomTooltip } from "../styles/CustomMuiStyles";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import { AuditRecordsTable } from "../components/AuditRecordsTable";
import DateRangeIcon from "@mui/icons-material/DateRange";
import * as queries from "../graphql/queries";
import { execReadByPK, execReadBySortkeyFromToken } from "../modules/DBService";
import { getFullDateDisplayWithGMTOffset } from "../modules/TimeService";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import { getUserFromCache } from "../user/UserCommon";
import { utcToZonedTime, format } from "date-fns-tz";
import DateRangePicker from "../utils/CommonComonents/DateRangePicker";
//import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function AuditRecordsForm(props) {
    const classes = usePromotionsStyles();
    // generic audit info
    const [token, setToken] = useState(null);
    const [cachedResults, setCachedResults] = useState([]);
    const [endList, setEndList] = useState(false);
    const [userList, setUserList] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);
    const [toFirstPage, setToFirstPage] = useState(false);

    const [searching, setSearching] = useState(false);
    const [searchToken, setSearchToken] = useState(null);
    const [searchCache, setSearchCache] = useState([]);
    const [searchUserById, setSearchUserById] = useState(false);
    const [searchUserId, setSearchUserId] = useState("");
    const [auditRows, setAuditRows] = useState([]);
    const [filteredAuditRows, setFilteredAuditRows] = useState([]);
    const [auditedAction, setAuditedAction] = useState("ALL");
    const [users, setUsers] = useState([]);
    const [userTags, setUserTags] = useState([]);
    const [showLoading, setShowLoading] = useState([]);
    const logger = new ConsoleLogger("AuditRecords");

    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");

    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRangeChange, setDateRangeChange] = useState(false);
    const [dateFilter, setDateFilter] = useState(false);
    const [changeAuditAction, setChangeAuditAction] = useState(false);
    const [selectedFromDate, setSelectFromDate] = useState(new Date());
    const [selectedToDate, setSelectToDate] = useState(
        new Date(Date.now() + 86400000 * 15)
    );

    const auditActions = [
        {
            value: "ALL",
            label: "All"
        },
        {
            value: "CLIENT_PKG",
            label: "Client Package Changes"
        },
        {
            value: "CLIENT",
            label: "Credit Card Changes"
        },
        {
            value: "CLIENT_NOTES",
            label: "Customer Note Updates"
        },
        {
            value: "USER",
            label: "User Changes (Includes Password Resets)"
        },
        {
            value: "COMPANY",
            label: "Company Setting Changes"
        },
        {
            value: "COMPANY_LOCATION",
            label: "Company Location Changes"
        },
        {
            value: "BILLING_TRANSACTION",
            label: "Charges and Refunds"
        },
        {
            value: "PROVIDER",
            label: "Provider Changes"
        },
        {
            value: "PROVIDER_LOCATION",
            label: "Provider Location Changes"
        },
        {
            value: "PROVIDER_SCHEDULE",
            label: "Provider Schedule Changes"
        },
        {
            value: "PACKAGE",
            label: "Package Changes"
        },
        {
            value: "SERVICE_TYPE",
            label: "Service Type Changes (Includes Categories)"
        },
        {
            value: "REGIONAL_PRICING",
            label: "Regional Pricing Changes"
        },
        {
            value: "SKILL",
            label: "Skill Changes"
        },
        {
            value: "BOOKING",
            label: "Booking State Changes"
        },
        {
            value: "PROMO",
            label: "Promo Codes"
        }
    ];

    // useEffect for first load of the audit page
    useEffect(() => {
        async function firstLoad() {
            const loggedInUser = getUserFromCache();
            const result = await execReadByPK({
                opname: "userByCompany",
                op: queries.userByCompany,
                id: { companyId: loggedInUser.companyId },
                limit: process.env.REACT_APP_LISTLIMIT
            });

            setUserList(result);

            fetchUsersList(result);
            _getPartialAuditList(false, result);
        }
        firstLoad();
    }, []);

    useEffect(() => {
        if (reloadPage) {
            setToFirstPage(true);
            _getPartialAuditList(false, userList);
        }
        setReloadPage(false);
    }, [reloadPage]);

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    const handleCloseDateRange = () => {
        setShowDateRange(false);
    };

    const handleClickOpenDateRange = () => {
        setShowDateRange(true);
    };

    const handleClearDateRange = () => {
        setDateRangeChange(false);
        if (!searchUserById && auditedAction === "ALL" && searching) {
            setReloadPage(true);
            setSearching(false);
        }
    };

    const handleApplyDateRange = () => {
        if (selectedToDate < selectedFromDate) {
            setSnackMsg("Please select a valid date range.");
            setMsgOpen(true);
            return;
        }
        setShowDateRange(false);
        setDateRangeChange(true);
    };

    const handleChangeAuditAction = (event) => {
        setAuditedAction(event.target.value);
        setChangeAuditAction(true);
        if (
            !searchUserById &&
            !dateRangeChange &&
            event.target.value === "ALL" &&
            searching
        ) {
            setReloadPage(true);
            setChangeAuditAction(false);
            setSearching(false);
        }
    };

    const handleChangeUser = (e, value) => {
        if (value) {
            setSearchUserById(true);
            setSearchUserId(value.id);
        } else {
            setSearchUserById(false);
            setSearchUserId("");
            if (!dateRangeChange && auditedAction === "ALL" && searching) {
                setReloadPage(true);
                setSearching(false);
            }
        }
    };

    async function handleSearchAudits() {
        if (searchUserById || dateRangeChange || changeAuditAction) {
            resetSearchFields();
            setSearching(true);
            setToFirstPage(true);
            await _getFilteredAuditList(true, userList);
        } else {
            setSearching(false);
        }
    }

    function resetSearchFields() {
        setFilteredAuditRows([]);
        setSearchToken(null);
        setSearchCache([]);
    }

    async function fetchUsersList(usersList) {
        const loggedInUser = getUserFromCache();
        try {
            if (usersList && usersList.items && usersList.items.length > 0) {
                const filteredResult = usersList.items.filter((item) => {
                    return (
                        item.deleted != true &&
                        (item.role === "CLIENT" ||
                            item.role === "COMPANY_ADMIN" ||
                            item.role === "MARKETBOX_ADMIN" ||
                            item.role === "COMPANY_ADMIN_PROVIDER" ||
                            item.role === "PROVIDER")
                    );
                });
                setUsers(filteredResult);
                setUserTags(computeUserTags(filteredResult));
            }
        } catch (err) {
            logger.error(`Error fetching clients `, err);
        }
    }

    function computeUserTags(users) {
        let userObjects = users.map((option) => {
            if (
                option != null &&
                option.emailaddress != null &&
                option.firstname != null &&
                option.lastname != null &&
                option.id != null
            )
                return {
                    title:
                        option.firstname +
                        " " +
                        option.lastname +
                        " (" +
                        option.emailaddress +
                        ")",
                    id: option.id
                };
        });
        return userObjects.filter(function (option) {
            if (typeof option != undefined) return option;
        });
    }

    /* function sortByDate(date1, date2) {
        const firstDate = date1.updatedAt;
        const secondDate = date2.updatedAt;

        let comparison = 0;
        if (firstDate > secondDate) {
            comparison = 1;
        } else if (firstDate < secondDate) {
            comparison = -1;
        }
        return comparison * -1;
    } */

    const _getPartialAuditList = async (loadMore, usersList) => {
        setShowLoading(true);
        const loggedInUser = getUserFromCache();
        console.log("loggedInUser", loggedInUser);
        const limit = process.env.REACT_APP_LISTLIMIT;
        let operation = {
            opname: "auditByCompany",
            op: queries.auditByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                datetime: {
                    between: [
                        `${moment()
                            .subtract(100, "years")
                            .format("YYYY-MM-DDT00:00:00Z")}`,
                        `${moment().format("YYYY-MM-DDT23:59:59Z")}`
                    ]
                }
            },
            limit: 50,
            sortDirection: "DESC"
        };

        const resp = await execReadBySortkeyFromToken(
            operation,
            loadMore ? token : null,
            loadMore ? cachedResults : []
        );

        if (!resp.token) {
            setEndList(true);
        } else setEndList(false);

        console.log("what is it", resp);
        setToken(resp.token);
        if (resp.items && resp.items.length > 0) {
            resp.items.map((item) => {
                displayAuditData(item, usersList);
            });

            setAuditRows(resp.items);
            setCachedResults(resp.items);
        }
        setShowLoading(false);
        setToFirstPage(false);
    };

    const _getFilteredAuditList = async (newSearch, usersList) => {
        setShowLoading(true);
        const loggedInUser = getUserFromCache();

        let filteredList = newSearch ? [] : searchCache;
        let nextToken = newSearch ? null : searchToken;
        let newList = newSearch ? [] : filteredAuditRows;
        let isEndOfList = false;

        let operation = await prepareFilterOperation(loggedInUser);

        while (filteredList.length <= 50) {
            const filterResp = await execReadBySortkeyFromToken(
                operation,
                nextToken,
                []
            );

            nextToken = filterResp.token;

            if (filterResp.items && filterResp.items.length > 0) {
                filterResp.items.map((item) => {
                    displayAuditData(item, usersList);
                });

                filteredList.push(...filterResp.items);
            }

            if (!filterResp.token) {
                setEndList(true);
                isEndOfList = true;
                break;
            } else setEndList(false);

            console.log("filteredList", filteredList, filteredList.length);
        }

        if (isEndOfList) {
            newList.push(...filteredList);
            setSearchToken(nextToken);
            setSearchCache(filteredList.slice(50));
            setFilteredAuditRows(newList);
        } else {
            newList.push(...filteredList.slice(0, 50));
            setSearchToken(nextToken);
            setSearchCache(filteredList.slice(50));
            setFilteredAuditRows(newList);
        }

        if (newList.length === 0) {
            setSnackMsg("There are no audits matching your search.");
            setMsgOpen(true);
        }

        setShowLoading(false);
        setToFirstPage(false);
    };

    async function displayAuditData(item, usersList) {
        if (typeof item.data === "string") {
            const parsedDataInfo = JSON.parse(item.data);
            item.data = parsedDataInfo;
        }
        item.userName = item.userFN + " " + item.userLN;
        item.updatedAtFormatted = moment(item.updatedAt).format(
            "YYYY-MM-DD hh:mm A"
        );
        item.roleFormatted = formatUserRole(item.userRole);
        if (item.resType == "CLIENT_PKG") {
            const changedClient = usersList.items.filter((user) => {
                return user.id === item.data.userId;
            });
            item.auditAction =
                item.userAction[0].toUpperCase() +
                item.userAction.toLowerCase().slice(1) +
                " Client Package";

            if (changedClient[0])
                item.clientName = `${
                    changedClient[0].firstname + " " + changedClient[0].lastname
                }`;
            item.auditDesc = clientPkgDesc(item, item.userAction);
        } else if (item.resType == "CLIENT") {
            const changedClient = usersList.items.filter((user) => {
                return user.id === item.data.client.userId;
            });

            if (changedClient[0])
                item.clientName = `${
                    changedClient[0].firstname + " " + changedClient[0].lastname
                }`;

            item.auditAction = "Update Credit Card";
            item.auditDesc = `${item.userName} has updated ${item.clientName}'s credit card`;
        } else if (item.resType == "CLIENT_NOTES") {
            const changedClient = usersList.items.filter((user) => {
                return user.id === item.data.client.userId;
            });

            if (changedClient[0])
                item.clientName = `${
                    changedClient[0].firstname + " " + changedClient[0].lastname
                }`;

            item.auditAction = "Updated Customer Notes";
            item.auditDesc = customerNotesUpdateDesc(item.userName, item.data);
        } else if (item.resType == "USER") {
            item.isReset =
                item.data.newInfo && item.userAction === "UPDATE"
                    ? "UPDATE"
                    : "RESET";

            item.auditAction = userChangeAction(
                item.userAction,
                item.isReset,
                item.data.resetAction
            );
            item.auditDesc = userChangeDesc(
                item,
                item.userAction,
                item.isReset,
                item.data.resetAction
            );
        } else if (item.resType == "COMPANY") {
            item.auditAction =
                item.userAction === "UPDATE"
                    ? "Update Company"
                    : item.userAction === "CREATE"
                      ? "Create Company"
                      : "Delete Company";
            if (item.data.tabValue === 0)
                item.data.newInfo[0].country = formatCountry(
                    item.data.newInfo[0].country
                );
            item.auditDesc = companyChangeDesc(
                item,
                item.userAction,
                item.data.tabValue
            );
        } else if (item.resType == "COMPANY_LOCATION") {
            item.auditAction = "Company Location Changes";
            item.auditDesc = companyLocChangeDesc(item, item.userAction);
        } else if (item.resType == "BILLING_TRANSACTION") {
            item.auditAction = "Charges and Refunds";
            if (item.data.clientDetails)
                item.clientName =
                    item.data.clientDetails.firstname +
                    " " +
                    item.data.clientDetails.lastname;
            if (
                !item.data.refundData &&
                !item.data.chargesRefunds.stripe_status
            )
                item.auditDesc = chargesDesc(
                    item,
                    item.data.chargesRefunds.addservicetype
                );
            else if (
                item.data.refundData &&
                !item.data.chargesRefunds.stripe_status
            )
                item.auditDesc = refundsDesc(
                    item,
                    item.data.chargesRefunds.addservicetype,
                    item.data.refundData
                );
        } else if (item.resType == "PROVIDER") {
            if (item.data.isRating) {
                item.auditAction = "Rating Changes";
                item.auditDesc = ratingDesc(
                    item,
                    item.data.ratingChange,
                    item.userAction
                );
            } else {
                item.auditAction = "Provider Changes";
                item.auditDesc = providerChangeDesc(
                    item,
                    item.data.providerChange,
                    item.userAction
                );
            }
        } else if (item.resType == "PROVIDER_LOCATION") {
            item.auditAction = "Provider Location Changes";
            item.auditDesc = providerLocDesc(
                item,
                item.data.providerChange,
                item.userAction
            );
        } else if (item.resType == "PROVIDER_SCHEDULE") {
            item.auditAction = "Provider Schedule Changes";
            item.auditDesc = providerSchedDesc(
                item,
                item.data.schedChange,
                item.data.schedBlock,
                item.userAction
            );
        } else if (item.resType == "PACKAGE") {
            item.auditAction = "Package Changes";
            item.auditDesc = pkgDesc(
                item,
                item.data.pkgChange,
                item.userAction
            );
        } else if (item.resType == "SERVICE_TYPE") {
            if (!item.data.isCategory) {
                item.auditAction = "Service Type Changes";
                item.auditDesc = serviceDesc(
                    item,
                    item.data.servChange,
                    item.userAction
                );
            } else if (item.data.isCategory) {
                item.auditAction = "Category Changes";
                item.auditDesc = categoryDesc(
                    item,
                    item.data.servChange,
                    item.userAction
                );
            }
        } else if (item.resType == "REGIONAL_PRICING") {
            item.auditAction = "Regional Changes";
            item.auditDesc = regionDesc(
                item,
                item.data.priceChange,
                item.userAction
            );
        } else if (item.resType == "SKILL") {
            item.auditAction = "Skill Changes";
            item.auditDesc = skillsDesc(
                item,
                item.data.skillChange,
                item.userAction
            );
        } else if (item.resType == "BOOKING") {
            if (
                item.data.status === "ACCEPTED" ||
                item.data.status === "DECLINED"
            ) {
                item.auditAction = "Accept/Decline Bookings";
                item.auditDesc = pendingBookingsDesc(
                    item,
                    item.data.bookingChange,
                    item.data.provider,
                    item.data.status
                );
            } else if (item.data.status === "ADD CHARGE") {
                item.auditAction = "Add Charge to Bookings";
                item.auditDesc = bookingAddChrgDesc(
                    item,
                    item.data.bookingChange,
                    item.data.addAmount
                );
            } else if (item.data.status === "OVERRIDE") {
                item.auditAction = "Booking Overrides";
                item.auditDesc = overrideDesc(
                    item,
                    item.data.oldBooking,
                    item.data.newBooking
                );
            } else if (item.data.status === "ORDER_NOTES") {
                item.auditAction = "Updated Booking Notes";
                item.auditDesc = orderNotesUpdateDesc(item.userName, item.data);
            } else {
                item.auditAction = "Booking State Changes";
                item.auditDesc = bookingStateDesc(
                    item,
                    item.data.bookingChange,
                    item.data.status
                );
            }
        } else if (item.resType === "PROMO") {
            if (item.userAction === "CREATE") {
                item.auditDesc = `${item.userFN} ${item.userLN} created Promo code ${item.data?.name} (${item.data?.promocode}).`;
            } else if (item.userAction === "UPDATE") {
                item.auditDesc = `${item.userFN} ${
                    item.userLN
                } updated Promo code ${item.data?.name} (${
                    item.data?.promocode
                }). ${getDiffs(item.data?.diffs)}`;
            }
        }

        return item;
    }

    function getDiffs(diffs) {
        let diffDesc = "Changes - ";
        for (let d of diffs) {
            diffDesc += `${d.field} from: ${d.from} to: ${d.to} `;
        }
        return diffDesc;
    }

    function prepareDateRange() {
        let datetime;
        if (dateRangeChange) {
            datetime = {
                between: [
                    `${moment(selectedFromDate).format(
                        "YYYY-MM-DDT00:00:00Z"
                    )}`,
                    `${moment(selectedToDate).format("YYYY-MM-DDT23:59:59Z")}`
                ]
            };
        } else {
            datetime = {
                between: [
                    `${moment()
                        .subtract(100, "years")
                        .format("YYYY-MM-DDT00:00:00Z")}`,
                    `${moment().format("YYYY-MM-DDT23:59:59Z")}`
                ]
            };
        }
        return datetime;
    }

    function prepareFilter() {
        let filter;
        if (auditedAction !== "ALL") {
            filter = {
                resType: { eq: auditedAction }
            };
        }
        return filter;
    }

    async function prepareFilterOperation(loggedInUser) {
        let filter = await prepareFilter();
        let datetime = prepareDateRange();
        let limit =
            auditedAction === "ALL" ? 50 : process.env.REACT_APP_LISTLIMIT;
        let op = {
            opname: "auditByCompany",
            op: queries.auditByCompany,
            id: {
                companyId: loggedInUser.companyId
            },
            skey: {
                datetime
            },
            filter: filter,
            limit: limit,
            sortDirection: "DESC"
        };
        if (searchUserById && searchUserId !== "") {
            op.opname = "listAudits";
            op.op = queries.listAudits;
            let pk = `${loggedInUser.companyId}|${searchUserId}`;
            op.id = {
                companyIdUserId: pk
            };
        }
        return op;
    }

    // Makes the user roles into a readable format
    function formatUserRole(item) {
        if (item === "CLIENT") return "Client";
        else if (item === "PROVIDER") return "Provider";
        else if (item === "COMPANY_ADMIN") return "Company Admin";
        else if (item === "COMPANY_ADMIN_PROVIDER") return "Provider Admin";
        else return "MarketBox Admin";
    }

    // The following three functions create audit descriptions based on the audit function and the user action
    function clientPkgDesc(item, action) {
        let service = item.data.servicetype;
        let pkg = item.data.package;
        let createdTime = moment(pkg.createdAt).format("YYYY-MM-DD hh:mm A");
        if (action === "CREATE")
            return `${item.userName} has created the client package: "${service.name} [${pkg.desc}]" for the client: ${item.clientName}`;
        else
            return `${item.userName} has updated the client package: "${service.name} [${pkg.desc}]" 
                   created on ${createdTime} to ${item.data.usedQuantity} 
                   credit(s) used for the client: ${item.clientName}`;
    }

    function userChangeAction(action, reset, resetAction) {
        if (action === "CREATE") return "Create User";
        else if (action === "DELETE") return "Delete User";
        else if (reset === "UPDATE") return "Update User";
        else if (reset === "RESET" && resetAction === "RESET")
            return "Reset Password";
        else if (reset === "RESET" && resetAction === "SET-TEMP-PWD")
            return "Temp Password";
    }

    function userChangeDesc(item, action, reset, resetAction) {
        let user = item.data.userChange;
        let updates = item.data.newInfo
            ? item.data.newInfo[0]
            : user.firstname + " " + user.lastname;
        if (action === "CREATE")
            return `${item.userName} has created a new ${formatUserRole(
                user.role
            ).toLowerCase()}: ${updates}`;
        else if (action === "DELETE")
            return `${item.userName} has deleted the ${formatUserRole(
                user.role
            ).toLowerCase()}: ${updates}`;
        else if (reset === "RESET" && resetAction === "RESET")
            return `${
                item.userName
            } has sent a password reset to ${formatUserRole(
                user.role
            ).toLowerCase()}: ${updates}`;
        else if (reset === "RESET" && resetAction === "SET-TEMP-PWD")
            return `${
                item.userName
            } has sent a temporary password to ${formatUserRole(
                user.role
            ).toLowerCase()}: ${updates}`;
        else if (reset === "UPDATE")
            return `${item.userName} has made updates to the user: ${
                user.firstname + " " + user.lastname
            }, 
                    new user details -> Firstname: ${
                        updates.firstname
                    }, Lastname: ${updates.lastname}, 
                    Address: ${updates.address}, Email: ${
                        updates.emailaddress
                            ? updates.emailaddress
                            : "No Change"
                    }, Homephone: ${updates.homephone}, Workphone: ${
                        updates.workphone
                    }, 
                    Mobilephone: ${updates.mobilephone}, Role: ${
                        updates.role
                    }, Contact Consent: ${updates.contactconsent ? "yes" : "no"}`;
    }

    function customerNotesUpdateDesc(userName, data) {
        return (
            <>
                <Typography
                    variant="subtitle2"
                    style={{ paddingBottom: "4px" }}
                >
                    {userName} changed the note for{" "}
                    {data.client.firstname + " " + data.client.lastname}:
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "2px" }}>
                    Old note:
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "12px" }}>
                    "{data.prevNotes ? data.prevNotes : " "}"
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "2px" }}>
                    New note: <br />
                </Typography>
                <Typography variant="body2">
                    "{data.newNotes ? data.newNotes : " "}"
                </Typography>
            </>
        );
    }

    function companyChangeDesc(item, action, tabValue) {
        let company = item.data.companyChange;

        if (action === "UPDATE" && item.data.newInfo[0]) {
            let updates = item.data.newInfo[0];

            let ordEmailCount = 0;
            let welcomeCount = 0;

            if (updates.ordSum) {
                ordEmailCount = updates.ordSum.length;
            }
            if (updates.welcomeList) {
                welcomeCount = updates.welcomeList.length;
            }

            return (
                <>
                    <Typography variant="subtitle2">
                        {item.userName} has updated "{company.name}" company
                        information. Updates made:{" "}
                    </Typography>
                    <Typography variant="body2">
                        {updates.name ? `Name: ${updates.name}` : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.contactname
                            ? `Contact Name: ${updates.contactname}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.emailaddress
                            ? `Email: ${updates.emailaddress}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.address ? `Address: ${updates.address}` : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.country ? `Country: ${updates.country}` : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.currency
                            ? `Currency: ${updates.currency}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.role ? `Subscription: ${updates.role}` : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.stripeKey
                            ? `Stripe Key: ${updates.stripeKey}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {updates.primaryColor
                            ? `Primary Color: ${updates.primaryColor}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"offersMobileServices" in updates
                            ? `Mobile Services: ${updates.offersMobileServices}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"offersOnPremiseServices" in updates
                            ? `On premise or virtual services: ${updates.offersOnPremiseServices}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"currencyByLocation" in updates
                            ? `Currency based on location: ${updates.currencyByLocation}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"displayOnlyLogo" in updates
                            ? `Display only logo: ${updates.displayOnlyLogo}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"singleApptOnly" in updates
                            ? `Single appointment only: ${updates.singleApptOnly}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"offersPackagesOnly" in updates
                            ? `Packages only: ${updates.offersPackagesOnly}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"forceRepeatingPackages" in updates
                            ? `force Repeating Packages: ${updates.forceRepeatingPackages}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"offersForeverAppt" in updates
                            ? `Ongoing appointments only: ${updates.offersForeverAppt}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"multipleServices" in updates
                            ? `Multiple services: ${updates.multipleServices}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"multipleQuantity" in updates
                            ? `Multiple quantity: ${updates.multipleQuantity}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"addServiceFee" in updates
                            ? `Additional service fee: ${updates.addServiceFee}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {updates.serviceFeeType
                            ? `Service fee type: ${updates.serviceFeeType}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {updates.serviceFeeAmount
                            ? `Service fee amount: ${updates.serviceFeeAmount}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"hideProviderRatingsfromClients" in updates
                            ? `Hide provider ratings from clients: ${updates.hideProviderRatingsfromClients}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"useAnonymousPermalink" in updates
                            ? `Anonymous provider permalinks: ${updates.useAnonymousPermalink}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"providerMustAcceptBooking" in updates
                            ? `Provider must accept booking: ${updates.providerMustAcceptBooking}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {updates.selectProvTimeout
                            ? `Primary provider timeout: ${updates.selectProvTimeout} min`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {updates.addProvTimeout
                            ? `Secondary provider(s) timeout: ${updates.addProvTimeout} min`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"providerCanCancelAppt" in updates
                            ? `Provider may cancel booking: ${updates.providerCanCancelAppt}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"providerMayRescheduleBooking" in updates
                            ? `Provider may reschedule bookings: ${updates.providerMayRescheduleBooking}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {"clientMayRescheduleBooking" in updates
                            ? `Clients may reschedule bookings: ${updates.clientMayRescheduleBooking}`
                            : ``}
                    </Typography>

                    <Typography variant="body2">
                        {updates.minTime
                            ? `Min Time Before Booking: ${updates.minTime} min`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.travelTime
                            ? `Travel Time: ${updates.travelTime} min`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.cancelPolicy
                            ? `Cancel Time: ${updates.cancelPolicy} min`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"tipMsg" in updates
                            ? `Send Provider Rating/Tip: 
                            ${updates.tipMsg}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.textMsg
                            ? `Provider MSG: ${updates.textMsg}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.clientMsg
                            ? `Client Notes: ${updates.clientMsg}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {ordEmailCount !== 0
                            ? `Order Emails: ${ordEmailCount} email(s), please see communications page for details`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {welcomeCount !== 0
                            ? `Welcome Emails: ${welcomeCount} email(s), please see communications page for details`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.subdomain
                            ? `Subdomain: ${updates.subdomain}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {updates.tagline ? `Tagline: ${updates.tagline}` : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"heading1Text" in updates
                            ? `Heading Text 1: ${updates.heading1Text}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"heading2Text" in updates
                            ? `Heading Text 2: ${updates.heading2Text}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"noProviderMsg" in updates
                            ? `No Provider MSG: ${updates.noProviderMsg}`
                            : ``}
                    </Typography>
                    <Typography variant="body2">
                        {"providerRatios" in updates
                            ? `Display Provider Ratios: ${updates.providerRatios}`
                            : ``}
                    </Typography>
                </>
            );
        } else if (action === "CREATE") {
            return `${item.userName} has created the company: "${company.name}". Please see their page for more details`;
        } else if (action === "DELETE") {
            return `${item.userName} has deleted the company: "${company.name}"`;
        }
    }

    function companyLocChangeDesc(item, action) {
        let location = item.data.companyLoc;
        let compName = item.data.companyLoc.company.name;
        if (action === "CREATE") {
            return `${item.userName} has created the location: "${location.locationname}" for the company: "${compName}". Please view the location for more details`;
        } else if (action === "DELETE") {
            return `${item.userName} has deleted the location: "${location.locationname}" for the company: "${compName}".`;
        } else if (action === "UPDATE") {
            let updates = item.data.newInfo[0];
            return `${item.userName} has updated the location: "${
                location.locationname
            }" for the company: "${compName}".
                    Updates made -> ${
                        updates.locationName
                            ? `Location Name: ${updates.locationName},`
                            : ``
                    }
                    ${
                        updates.locationDesc
                            ? `Location Desc: ${updates.locationDesc},`
                            : ``
                    }
                    ${updates.address ? `Address: ${updates.address},` : ``}
                    ${updates.phone ? `Phone: ${updates.phone},` : ``}
                    ${updates.virtual ? `Virtual: ${updates.virtual},` : ``}`;
        }
    }

    function chargesDesc(item, service) {
        let data = item.data.chargesRefunds;
        let desc;
        if (data.order) {
            desc =
                data.order.desc && data.order.total
                    ? (Math.round(data.order.total * 100) / 100).toFixed(2)
                    : data.order
                      ? data.order.desc + " ($0.00)"
                      : "";
        }
        if (data.order && service)
            return `${item.userName} has processed a charge for: ${item.clientName}. Charge details -> Optional Order: ${data.order.desc} ($${desc}), 
                    Additional Service Type: ${service.name}, Amount Charged: $${data.amount}, Charge Desc: ${data.desc}`;
        else if (data.order && !service)
            return `${item.userName} has processed a charge for: ${item.clientName}. Charge details -> Optional Order: ${data.order.desc} ($${desc}), 
                    Amount Charged: $${data.amount}, Charge Desc: ${data.desc}`;
        else if (!data.order && service)
            return `${item.userName} has processed a charge for: ${item.clientName}. Charge details -> Additional Service Type: ${service.name}, 
                    Amount Charged: $${data.amount}, Charge Desc: ${data.desc}`;
        else if (!data.order && !service)
            return `${item.userName} has processed a charge for: ${item.clientName}. Charge details -> Charge Amount: $${data.amount}, 
                    Charge Desc: ${data.desc}`;
    }

    function refundsDesc(item, service, refundData) {
        let data = item.data.chargesRefunds;
        let desc;
        let chargeDesc;
        if (data.order) {
            desc =
                data.order.desc && data.order.total
                    ? (Math.round(data.order.total * 100) / 100).toFixed(2)
                    : data.order
                      ? data.order.desc + " ($0.00)"
                      : "";
        }
        chargeDesc =
            refundData.amount && refundData.description
                ? (Math.round(refundData.amount * 100) / 100).toFixed(2)
                : refundData.description
                  ? refundData.description + " ($0.00)"
                  : "";
        if (data.order && service)
            return `${item.userName} has processed a refund for: ${item.clientName}. Refund details -> Refunded Order: ${data.order.desc} ($${desc}), 
                    Previous Charge: ${refundData.description} ($${chargeDesc}), Amount Refunded: $${data.amount}, Additional Service Type: ${service.name}, 
                    Charge Desc: ${data.desc}`;
        else if (data.order && !service)
            return `${item.userName} has processed a refund for: ${item.clientName}. Refund details -> Refunded Order: ${data.order.desc} ($${desc}), 
                    Previous Charge: ${refundData.description} ($${chargeDesc}), Amount Refunded: $${data.amount}, Charge Desc: ${data.desc}`;
        else if (!data.order && service)
            return `${item.userName} has processed a refund for: ${item.clientName}. Refund details -> Refunded Order: ${refundData.description}, 
                    Amount Refunded: $${data.amount}, Additional Service Type: ${service.name}, Charge Desc: ${data.desc}`;
        else if (!data.order && !service)
            return `${item.userName} has processed a refund for: ${item.clientName}. Refund details -> Refunded Order: ${refundData.description}, 
                    Amount Refunded: $${data.amount}, Charge Desc: ${data.desc}`;
    }

    function providerChangeDesc(item, provider, action) {
        if (action == "CREATE") {
            return `${item.userName} has created a new provider: ${provider.firstname} ${provider.lastname}, 
                   please view on the provider page for more details`;
        } else if (action == "UPDATE") {
            let updates = item.data.newInfo[0];
            let picChange = item.data.newInfo[0].pic
                ? item.data.newInfo[0].pic
                : "No Change";
            return `${item.userName} has updated the provider: ${provider.firstname} ${provider.lastname}. Updates made -> 
                   Name: ${updates.name}, Phone: ${updates.phone}, Travel Type: ${updates.travelType}, Active: ${updates.active}, 
                   Virtual Services: ${updates.virtualServices}, Bio: ${updates.bio}, Services: ${updates.services}, 
                   Skills: ${updates.skills}, Provider Picture: ${picChange}`;
        }
    }

    function providerLocDesc(item, locInfo, action) {
        if (action == "CREATE") {
            return `${item.userName} has created a location named: "${locInfo.name}" for the provider: 
                    ${locInfo.provider.firstname} ${locInfo.provider.lastname}`;
        } else if (action == "UPDATE") {
            console.log("item: ", JSON.stringify(item.data.newInfo, null, 4));
            let updates = item.data.newInfo ? item.data.newInfo[0] : null;
            if (updates?.travelregions)
                return `${item.userName} has updated the location: "${locInfo.name}" for the provider: 
                        ${locInfo.provider.firstname} ${locInfo.provider.lastname}. Updates made -> Name: 
                        ${updates.locationname}, Desc: ${updates.locationdesc}, Address: ${updates.address}, 
                        Travel Zones (Drawn): Please see the locations page for drawn zone changes`;
            else if (updates?.traveldistance && updates?.travelunits)
                return `${item.userName} has updated the location: "${locInfo.name}" for the provider: 
                        ${locInfo.provider.firstname} ${locInfo.provider.lastname}. Updates made -> Name: 
                        ${updates.locationname}, Desc: ${updates.locationdesc}, Address: ${updates.address}, 
                        Travel Distance (Circular): Distance Change: ${updates.traveldistance}, Unit Change: ${updates.travelunits}`;
        } else {
            return `${item.userName} has deleted the location: "${locInfo.name}" for the provider: 
                    ${locInfo.provider.firstname} ${locInfo.provider.lastname}`;
        }
    }

    function providerSchedDesc(item, schedInfo, schedBlock, action) {
        if (action === "CREATE" && schedBlock == true && item.data.newInfo) {
            let updates = item.data.newInfo[0];
            let locInfo = item.data.newInfo.length > 0 ? updates : "no locname";
            let locName = locInfo.phone ? locInfo.locationname : locInfo.name;
            return `${item.userName} has created a schedule for: ${schedInfo.provider.firstname} ${schedInfo.provider.lastname}
                    with the location: ${locName}. Please see the provider's schedule page for more information`;
        } else if (
            action === "DELETE" &&
            schedBlock == true &&
            item.data.newInfo
        ) {
            let updates = item.data.newInfo[0];
            let locInfo = item.data.newInfo.length > 0 ? updates : "no locname";
            let locName = locInfo.phone ? locInfo.locationname : locInfo.name;
            return `${item.userName} has deactivated a schedule for: ${schedInfo.provider.firstname} ${schedInfo.provider.lastname}
                    with the location: ${locName}. Please see the provider's schedule page for more information`;
        } else if (
            action === "UPDATE" &&
            schedBlock === "ACTIVATE" &&
            item.data.newInfo
        ) {
            let updates = item.data.newInfo[0];
            let locInfo = item.data.newInfo.length > 0 ? updates : "no locname";
            let locName = locInfo.phone ? locInfo.locationname : locInfo.name;
            return `${item.userName} has activated a schedule for: ${schedInfo.provider.firstname} ${schedInfo.provider.lastname}
                    with the location: ${locName}. Please see the provider's schedule page for more information`;
        } else if (
            action === "UPDATE" &&
            schedBlock === "EDIT_SCHED" &&
            item.data.newInfo
        ) {
            let updates = item.data.newInfo[0];
            let locInfo = updates.locname[0]
                ? updates.locname[0].locationname
                : "";
            return `${item.userName} has edited a schedule for ${schedInfo.provider.firstname} ${schedInfo.provider.lastname}
                    with the location: ${locInfo}. Updates made: ${updates.services}`;
        } else if (
            action === "CREATE" &&
            schedBlock === "ADD_TB" &&
            item.data.newInfo
        ) {
            let updates = item.data.newInfo[0];
            let locInfo =
                updates.locname && updates.locname.length > 0
                    ? updates.locname[0]
                    : "no locname";
            let locName = locInfo.phone ? locInfo.locationname : locInfo.name;
            return `${item.userName} has added an availability for ${
                schedInfo.provider.firstname
            } ${schedInfo.provider.lastname}
                    with the location: ${locName}. Start Date: ${moment(
                        updates.startDate
                    ).format("YYYY-MM-DD")}, 
                    Time Range: ${moment(updates.startTime).format(
                        "hh:mm A"
                    )} - ${moment(updates.endTime).format("hh:mm A")}. Please 
                    see the availability for more information`;
        } else if (
            action === "UPDATE" &&
            schedBlock === "EDIT_TB" &&
            item.data.newInfo
        ) {
            let updates = item.data.newInfo[0];
            let locInfo = updates.locname[0];
            let locName = locInfo.phone ? locInfo.locationname : locInfo.name;
            return `${item.userName} has edited an availability for ${
                schedInfo.provider.firstname
            } ${schedInfo.provider.lastname}
                    with the location: ${locName}. New Information -> Start Date: ${moment(
                        updates.startDate
                    ).format("YYYY-MM-DD")}, 
                    Time Range: ${moment(updates.startTime).format(
                        "hh:mm A"
                    )} - ${moment(updates.endTime).format("hh:mm A")}. Please 
                    see the availability for more information`;
        } else if (action === "CREATE" && schedBlock === "ADD_BLOCKTIME") {
            let provider = schedInfo.provider;
            return `${item.userName} has added a blocked time for ${
                provider.firstname
            } ${provider.lastname}. Blocked Time Info ->
                    Date Range: ${schedInfo.startDate} to ${
                        schedInfo.endDate
                    }, Time Range: ${schedInfo.startTime} - ${schedInfo.endTime}, 
                    Repeating? ${
                        schedInfo.weekDays === ""
                            ? "No"
                            : "Yes. Please see blocked time for more info"
                    }`;
        } else if (action === "DELETE" && schedBlock === "DEL_BLOCKTIME") {
            let provider = schedInfo.provider;
            return `${item.userName} has deleted a blocked time for ${
                provider.firstname
            } ${provider.lastname}. Blocked Time Info ->
                    Date Range: ${schedInfo.startDate} to ${
                        schedInfo.endDate
                    }, Time Range: ${schedInfo.startTime} - ${schedInfo.endTime}, 
                    Repeating? ${
                        schedInfo.weekDays === ""
                            ? "No"
                            : "Yes. Please see blocked time for more info"
                    }`;
        } else if (action === "UPDATE" && schedBlock === "UPDATE_BLOCKTIME") {
            let provider = schedInfo.provider;
            return `${item.userName} has updated a blocked time for ${
                provider.firstname
            } ${provider.lastname}. Blocked Time Info -> 
                    Date Range: ${schedInfo.startDate} to ${
                        schedInfo.endDate
                    }, Time Range: ${schedInfo.startTime} - ${schedInfo.endTime}, 
                    Repeating? ${
                        schedInfo.weekDays === ""
                            ? "No"
                            : "Yes. Please see blocked time for more info"
                    }`;
        }
    }

    function pkgDesc(item, pkgChange, action) {
        if (action === "CREATE") {
            let priceDesc;
            if (pkgChange.packagetype === "DOLLAR")
                priceDesc = `Dollar Package with total price of $${pkgChange.price}`;
            else
                priceDesc = `Percentage Package with a discount of ${pkgChange.discount}%`;
            return `${item.userName} has added a package. Package details -> Service Name: ${pkgChange.servicetype.name}, 
                    Package Type: ${priceDesc}, Quantity: ${pkgChange.quantity} sessions, Package Desc: ${pkgChange.desc}, 
                    Active: ${pkgChange.active}`;
        } else if (action === "DELETE") {
            let priceDesc;
            if (pkgChange.packagetype === "DOLLAR")
                priceDesc = `Dollar Package with total price of $${pkgChange.price}`;
            else
                priceDesc = `Percentage Package with a discount of ${pkgChange.discount}%`;
            return `${item.userName} has deleted a package. Package details -> Service Name: ${pkgChange.servicetype.name}, 
                    Package Type: ${priceDesc}, Quantity: ${pkgChange.quantity} sessions, Package Desc: ${pkgChange.desc}, 
                    Active: ${pkgChange.active}`;
        } else if (action === "UPDATE" && item.data.newInfo) {
            let updates = item.data.newInfo[0];
            let priceDesc;
            if (updates.packagetype === "DOLLAR")
                priceDesc = `Dollar Package with total price of $${updates.price}`;
            else if (updates.packagetype === "PERCENTAGE")
                priceDesc = `Percentage Package with a discount of ${updates.discount}%`;
            else if (updates.packagetype === "No Change") {
                if (
                    updates.price === "No Change" &&
                    updates.discount !== "No Change"
                ) {
                    priceDesc = `Percentage Package with a discount of ${updates.discount}%`;
                } else if (
                    updates.discount === "No Change" &&
                    updates.price !== "No Change"
                ) {
                    priceDesc = `Dollar Package with total price of $${updates.price}`;
                } else {
                    priceDesc = updates.packagetype;
                }
            }
            return `${item.userName} has updated a package with service type: ${
                pkgChange.servicetype.name
            }. 
                    Package details -> Service Name: ${
                        updates.servicetype
                    }, Package Type: ${priceDesc}, Quantity: 
                    ${
                        updates.quantity != "No Change"
                            ? updates.quantity + " sessions"
                            : updates.quantity
                    }, Package Desc: 
                    ${updates.desc}, Active: ${updates.active}`;
        }
    }

    function serviceDesc(item, service, action) {
        if (action === "CREATE") {
            return `${item.userName} has created a new service with name: "${service.name}" for the service category: 
                    "${service.categoryName}", please see the service page for more info.`;
        } else if (action === "DELETE") {
            return `${item.userName} has deleted the service with name: "${service.name}" for the service category: 
                   "${service.categoryName}", please see the service page for more info.`;
        } else if (action === "UPDATE") {
            let updates = item.data.newInfo[0];
            if (updates.duration != "No Change") {
                updates.duration = updates.duration + " mins";
            }
            if (updates.price != "No Change") {
                updates.price = "$" + updates.price;
            }
            if (updates.behavior === "EXTERNAL") {
                updates.behavior = "Public (bookable by clients and admins)";
            } else if (updates.behavior === "INTERNAL") {
                updates.behavior = "Private (bookable by admins only)";
            } else if (updates.behavior === "MANUAL") {
                updates.behavior =
                    "Contact us form (client must complete form to book this service type)";
            }
            return `${item.userName} has updated a service with name: "${updates.oldName}" for the service category:
                    "${service.categoryName}". Updates made -> New service name: ${updates.serviceName}, Category: ${updates.category}, 
                    Duration: ${updates.duration}, Price: ${updates.price}, Behavior: ${updates.behavior}, Desc: ${updates.desc}, 
                    Tax Exempt: ${updates.taxExempt}, Active: ${updates.active}`;
        }
    }

    function categoryDesc(item, category, action) {
        if (action === "CREATE") {
            return `${item.userName} has created a new service category named: ${category.name}, with the description: 
                    ${category.desc}`;
        } else if (action === "DELETE") {
            return `${item.userName} has deleted the service category named: ${category.name}, with the description:
                    ${category.desc}`;
        } else if (action === "UPDATE") {
            let updates = item.data.newInfo ? item.data.newInfo[0] : "";
            if (updates.desc != "No Change" && updates.name != "No Change")
                return `${item.userName} has updated the service category that was named: ${updates.oldName}. Updates made ->
                        New Name: ${updates.name}, New Description: ${updates.desc}`;
            else if (
                updates.name === "No Change" &&
                updates.desc != "No Change"
            )
                return `${item.userName} has updated the service category named: ${updates.oldName}. Updates made -> 
                        New Description: ${updates.desc}`;
            else if (updates.name != "No Change" && updates.desc == "No Change")
                return `${item.userName} has updated the service that was named: ${updates.oldName}. Updates made -> 
                        New Name: ${updates.name}`;
        }
    }

    function regionDesc(item, region, action) {
        if (action === "CREATE") {
            let provinceFormat =
                region.countryName === "Canada" ? "Province" : "State";
            let codeFormat =
                region.countryName === "USA" ? "Zipcodes" : "Postalcodes";
            if (region.pricingtype === "COUNTRY")
                return `${item.userName} has created a regional price for service: "${region.servicetype.name}".
                        Details -> Pricing type: ${region.pricingtype}, Country: ${region.countryName}, Price: $${region.price}, 
                        Notes: ${region.notes}`;
            else if (region.pricingtype === "PROVINCE" && item.data.province)
                return `${item.userName} has created a regional price for the service: "${region.servicetype.name}". 
                        Details -> Pricing type: ${region.pricingtype}, Country: ${region.countryName}, 
                        ${provinceFormat}: ${item.data.province}, Price: $${region.price}, Notes: ${region.notes}`;
            else if (region.pricingtype === "POSTALCODE" && item.data.province)
                return `${
                    item.userName
                } has created a regional price for the service: "${
                    region.servicetype.name
                }". 
                        Details -> Pricing type: ${
                            region.pricingtype
                        }, Country: ${region.countryName}, 
                        ${provinceFormat}: ${
                            item.data.province
                        }, ${codeFormat}: ${
                            region.postalcodes.length
                        } ${codeFormat.toLocaleLowerCase()}, Price: 
                        $${region.price}, notes: ${
                            region.notes
                        }. Please see the region for a full list of ${codeFormat.toLocaleLowerCase()}`;
        } else if (action === "DELETE") {
            let provFormat =
                region.countryName === "Canada"
                    ? "Province Code"
                    : "State Code";
            let codeFormat =
                region.countryName === "USA" ? "Zipcodes" : "Postalcodes";
            if (region.pricingtype === "COUNTRY")
                return `${item.userName} has deleted a regional price for the service: "${region.servicetype.name}". 
                        Details of deleted pricing -> Pricing type: ${region.pricingtype}, Country: ${region.countryName}, 
                        Price: $${region.price}, Notes: ${region.notes}`;
            else if (region.pricingtype === "PROVINCE")
                return `${item.userName} has deleted a regional price for the service: "${region.servicetype.name}". 
                        Details of deleted pricing -> Pricing type: ${region.pricingtype}, Country: ${region.countryName}, 
                        ${provFormat}: ${region.province}, Price: $${region.price}, Notes: ${region.notes}`;
            else if (region.pricingtype === "POSTALCODE")
                return `${
                    item.userName
                } has deleted a regional price for the service: "${
                    region.servicetype.name
                }". 
                        Details of deleted pricing -> Pricing type: ${
                            region.pricingtype
                        }, Country: ${region.countryName}, 
                        ${provFormat}: ${region.province}, ${codeFormat}: ${
                            region.postalcodes.length
                        } ${codeFormat.toLocaleLowerCase()}, 
                        Price: ${region.price}, Notes: ${region.notes}`;
        } else if (action === "UPDATE") {
            let updates = item.data.newInfo[0];
            let price =
                updates.price === "No Change"
                    ? updates.price
                    : "$" + updates.price;
            let provFormat =
                region.countryName === "Canada" ? "Province" : "State";
            let codeFormat =
                region.countryName === "USA" ? "Zipcodes" : "Postalcodes";
            if (region.pricingtype === "COUNTRY")
                return `${item.userName} has updated a regional price. Old Information -> Service: ${updates.oldName}, 
                        Country: ${region.countryName}, Price: $${updates.oldPrice}, Desc: ${updates.oldDesc}. New Information -> 
                        Service: ${updates.serviceName}, Price: ${price}, Desc: ${updates.desc}`;
            else if (region.pricingtype === "PROVINCE")
                return `${item.userName} has updated a regional price. Old Information -> Service: ${updates.oldName}, 
                        ${provFormat}: ${region.province} ${region.countryName}, Price: $${updates.oldPrice}, Desc: ${updates.oldDesc}. 
                        New Information -> Service: ${updates.serviceName}, Price: ${price}, Desc: ${updates.desc}`;
            else if (region.pricingtype === "POSTALCODE")
                return `${
                    item.userName
                } has updated a regional price. Old Information -> Service: ${
                    updates.oldName
                }, 
                        ${provFormat}; ${region.province} ${
                            region.countryName
                        }, ${codeFormat}: ${
                            region.postalcodes.length
                        } ${codeFormat.toLowerCase()}, 
                        Price: $${updates.oldPrice}, Desc: ${
                            updates.oldDesc
                        }. New Information -> Service: ${updates.serviceName}, 
                        Price: ${price}, Desc: ${
                            updates.desc
                        }, ${codeFormat}: ${updates.postalcodes}`;
        }
    }

    function skillsDesc(item, skills, action) {
        if (action === "CREATE") {
            return `${item.userName} has created a new skill. Skill details -> Name: ${skills.name}, 
                    Active: ${skills.active}`;
        } else if (action === "DELETE") {
            return `${item.userName} has deleted a skill. Deleted skill details -> Name: ${skills.name}, 
                    Active: ${skills.active}`;
        } else if (action === "UPDATE") {
            let updates = item.data.newInfo[0];
            return `${item.userName} has updated a skill. Old skill details -> Name: ${updates.oldName}, 
                    Active: ${updates.oldActive}. Updated details -> Name: ${updates.name}, Active: ${updates.active}`;
        }
    }

    function ratingDesc(item, rating, action) {
        if (action === "CREATE") {
            let providerName =
                rating.provider.firstname + " " + rating.provider.lastname;
            return `${item.userName} has created a new rating for provider: ${providerName}. Rating details -> 
                    Rating: ${rating.ratingstars} stars, Review: ${rating.ratingtext}`;
        } else if (action === "UPDATE") {
            let providerName =
                rating.provider.firstname + " " + rating.provider.lastname;
            let updates = item.data.newInfo[0];
            let starsFormat =
                updates.rating === "No Change"
                    ? updates.rating
                    : updates.rating + " stars";
            let reviewFormat =
                updates.review === "None"
                    ? "The review has been removed"
                    : updates.review;
            return `${item.userName} has updated a rating for provider: ${providerName}. Old rating 
                    details -> Rating: ${updates.oldRating}, Review: ${updates.oldReview}. New rating 
                    details -> Rating: ${starsFormat}, Review: ${reviewFormat}`;
        }
    }

    function pendingBookingsDesc(item, booking, provider, status) {
        if (status === "ACCEPTED" && provider) {
            let role = "Admin";
            let provName = `${provider.firstname} ${provider.lastname}`;
            return `${role} ${
                item.userName
            } has accepted a booking for client: ${
                booking.clientDetails
            }. Booking 
                    details -> Service: ${
                        booking.serviceAndPackage
                    }, Provider: ${provName}, Location: ${booking.bookingLoc}, 
                    Start Date: ${
                        booking.bookingTimes[0]
                    }, Date created: ${moment(booking.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    )}. 
                    Please see the booking for more information.`;
        } else if (status === "ACCEPTED" && !provider) {
            let role = "Provider";
            return `${role} ${
                item.userName
            } has accepted their booking through the booking page for client: ${
                booking.clientDetails
            }. Booking details -> 
                    Service: ${booking.serviceAndPackage}, Location: ${
                        booking.bookingLoc
                    }, Start Date: ${booking.bookingTimes[0]}, 
                    Date Created: ${moment(booking.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    )}. Please see the booking for more information.`;
        } else if (status === "DECLINED" && item.roleFormatted !== "Provider") {
            let role = "Admin";
            return `${role} ${item.userName} has declined a booking for client: ${booking.clientDetails}. Booking details -> 
                    Service: ${booking.serviceAndPackage}, Provider: ${booking.providerDetails}, Booking Type: ${booking.bookingType}, 
                    Location: ${booking.bookingLoc}. Please see the Declined/Expired tab for more details.`;
        } else if (status === "DECLINED" && item.roleFormatted === "Provider") {
            let role = "Provider";
            return `${role} ${item.userName} has declined their booking through the booking page for client: ${booking.clientDetails}. Booking details -> 
                    Service: ${booking.serviceAndPackage}, Provider: ${booking.providerDetails}, Booking Type: ${booking.bookingType}, 
                    Location: ${booking.bookingLoc}. Please see the Declined/Expired tab for more details.`;
        }
    }

    function bookingAddChrgDesc(item, booking, amount) {
        if (item.roleFormatted !== "Provider") {
            let role = "Admin";
            let clientName =
                booking.clientFirstName + " " + booking.clientLastName;
            let orderNo = booking.order.orderNo;
            let addChrg = amount.totalAddChg - booking.totalAddChg;
            return `${role} ${
                item.userName
            } has added a charge to the booking with the order number: ${orderNo}. Booking 
                    details -> Client: ${clientName}, Provider: ${
                        booking.providerName
                    }, Service: ${booking.serviceName}, 
                    Location: ${booking.location}, Start date: ${
                        booking.startDateDesc
                    }, Charge Added: $${addChrg.toFixed(2)}. Please 
                    see the additional charges page for more info on the charge.`;
        } else if (item.roleFormatted === "Provider") {
            let role = "Provider";
            let clientName =
                booking.clientFirstName + " " + booking.clientLastName;
            let orderNo = booking.order.orderNo;
            let addChrg = amount.totalAddChg - booking.totalAddChg;
            return `${role} ${
                item.userName
            } has added a charge to the booking with the order number: ${orderNo}. Booking 
                    details -> Client: ${clientName}, Provider: ${
                        booking.providerName
                    }, Service: ${booking.serviceName}, 
                    Location: ${booking.location}, Start date: ${
                        booking.startDateDesc
                    }, Charge Added: $${addChrg.toFixed(2)}. 
                    Please see the additional charges page for more info on the charge`;
        }
    }

    function overrideDesc(item, oldBooking, newBooking) {
        let newProv =
            newBooking.provider.firstname + " " + newBooking.provider.lastname;
        if (newProv === oldBooking.providerName) {
            let newDate = formatDate(newBooking);
            return `${item.userName} has overrided a booking for the client: ${oldBooking.clientName}. Booking details -> 
                    Provider: ${newProv}, Service: ${oldBooking.serviceName}, Location: ${newBooking.location}, 
                    Status: ${newBooking.status}. Old booking date: ${oldBooking.startDateDesc}, New booking date: ${newDate}. 
                    Please see the booking for more details.`;
        } else {
            let newDate = formatDate(newBooking);
            let dateDesc;
            if (newDate === oldBooking.startDateDesc) {
                dateDesc = "Booking date has not changed";
            } else if (newDate !== oldBooking.startDateDesc) {
                dateDesc = `New booking date: ${newDate}`;
            }
            return `${item.userName} has overrided a booking for the client: ${oldBooking.clientName}. Booking details -> 
                    Provider: ${newProv}, Service: ${oldBooking.serviceName}, Location: ${newBooking.location}, 
                    Status: ${newBooking.status}. Old booking date: ${oldBooking.startDateDesc}, ${dateDesc}. Please see 
                    the booking for more details.`;
        }
    }

    function orderNotesUpdateDesc(userName, data) {
        return (
            <>
                <Typography
                    variant="subtitle2"
                    style={{ paddingBottom: "4px" }}
                >
                    {userName} updated a booking note.
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "8px" }}>
                    <b style={{ fontWeight: "500" }}>Booking description: </b>{" "}
                    {data.order.desc}
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "2px" }}>
                    Old note:
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "12px" }}>
                    "{data.prevNotes ? data.prevNotes : " "}"
                </Typography>
                <Typography variant="body2" style={{ paddingBottom: "2px" }}>
                    New note: <br />
                </Typography>
                <Typography variant="body2">
                    "{data.newNotes ? data.newNotes : " "}"
                </Typography>
            </>
        );
    }

    function bookingStateDesc(item, booking, status) {
        let role;
        let whosBooking;
        if (item.roleFormatted === "Provider") {
            role = "Provider";
            whosBooking = "one of their bookings";
        } else if (item.roleFormatted === "Client") {
            role = "Client";
            whosBooking = "one of their bookings";
        } else {
            role = "Admin";
            whosBooking = "a booking";
        }
        if (status === "ARRIVED" && booking.clientFirstName) {
            let clientName =
                booking.clientFirstName + " " + booking.clientLastName;
            return `${role} ${
                item.userName
            } has set ${whosBooking} to "ARRIVED". Booking details -> Client: ${clientName}, 
                    Provider: ${booking.providerName}, Service: ${
                        booking.serviceName
                    }, Location: ${booking.location}, 
                    Start date: ${
                        booking.startDateDesc
                    }, Date Created: ${moment(booking.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    )}. 
                    Please see the booking for more information.`;
        } else if (status === "CANCEL") {
            return `${role} ${
                item.userName
            } has set ${whosBooking} to "CANCELLED". Booking details -> Client: ${
                booking.clientName
            }, 
                    Provider: ${booking.providerName}, Service: ${
                        booking.serviceName
                    }, Location: ${booking.location}, 
                    Start date: ${
                        booking.startDateDesc
                    }, Date Created: ${moment(booking.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    )}. 
                    Please see the booking for more information.`;
        } else if (status === "COMPLETED") {
            return `${role} ${
                item.userName
            } has set ${whosBooking} to "COMPLETED". Booking details -> Client: ${
                booking.clientName
            }, 
                    Provider: ${booking.providerName}, Service: ${
                        booking.serviceName
                    }, Location: ${booking.location}, 
                    Start date: ${
                        booking.startDateDesc
                    }, Date Created: ${moment(booking.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    )}. 
                    Please see the booking for more information.`;
        } else if (status === "NO SHOW") {
            return `${role} ${
                item.userName
            } has set ${whosBooking} to "NOSHOW. Booking details -> Client: ${
                booking.clientName
            }, 
                    Provider: ${booking.providerName}, Service: ${
                        booking.serviceName
                    }, Location: ${booking.location}, 
                    Start date: ${
                        booking.startDateDesc
                    }, Date Created: ${moment(booking.createdAt).format(
                        "YYYY-MM-DD hh:mm A"
                    )}.
                    Please see the booking for more information.`;
        }
    }

    function formatDate(item) {
        let TimeDisplayInfo = JSON.parse(item.TimeDisplayInfo);
        let date = TimeDisplayInfo
            ? getFullDateDisplayWithGMTOffset(TimeDisplayInfo)
            : format(
                  utcToZonedTime(
                      item.startdate,
                      item.timezone ? item.timezone : "America/Toronto"
                  ),
                  "MMM dd, yyyy h:mm a zzz",
                  {
                      timeZone: item.timezone
                          ? item.timezone
                          : "America/Toronto"
                  }
              );
        return date;
    }

    function formatCountry(country) {
        if (country === "ca") return "Canada";
        else if (country === "us") return "United States";
        else if (country === "gb") return "United Kingdom";
        else if (country === "au") return "Australia";
        else return country;
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={showDateRange}
                onClose={handleCloseDateRange}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>Choose a date range:</DialogContentText>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <DateRangePicker
                                setFrom={setSelectFromDate}
                                setTo={setSelectToDate}
                                from={selectedFromDate}
                                to={selectedToDate}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DialogActions>
                                <Button
                                    onClick={handleClearDateRange}
                                    color="primary"
                                    size="small"
                                >
                                    Clear
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                    {/* </MuiPickersUtilsProvider> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDateRange} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleApplyDateRange} color="primary">
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <FormHeading title={"View Audit Records"} classes={classes} />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <FormControl
                            margin="none"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <Autocomplete
                                loading={props.loading}
                                loadingText="Loading..."
                                noOptionsText="No Users Found"
                                options={userTags}
                                getOptionLabel={(option) =>
                                    option ? option.title : ""
                                }
                                className={classes.formControl}
                                renderInput={(params) => (
                                    <TextField
                                        variant="outlined"
                                        {...params}
                                        label="Select a User to view their audits"
                                        InputProps={{
                                            ...params.InputProps
                                        }}
                                        InputLabelProps={{
                                            ...params.InputLabelProps
                                        }}
                                        fullWidth
                                    />
                                )}
                                onChange={handleChangeUser}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            margin="none"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                select
                                label="Audit Action Select"
                                value={auditedAction}
                                onChange={handleChangeAuditAction}
                                SelectProps={{
                                    native: true
                                }}
                                variant="outlined"
                            >
                                {auditActions.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpenDateRange}
                            startIcon={<DateRangeIcon />}
                        >
                            {dateRangeChange
                                ? `${moment(selectedFromDate).format(
                                      "MMM DD"
                                  )} to ${moment(selectedToDate).format(
                                      "MMM DD"
                                  )}`
                                : "Filter by Date"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => await handleSearchAudits()}
                            startIcon={<SearchIcon />}
                        >
                            Search
                        </Button>
                        <div
                            style={{
                                display: "inline-block",
                                padding: "16px",
                                margin: "8px"
                            }}
                        >
                            <CustomTooltip
                                placement="right"
                                title="Enter user, audit action and/or date information and then click on the Search button to see the results."
                            >
                                <IconButton size="large">
                                    <HelpOutlineIcon />
                                </IconButton>
                            </CustomTooltip>
                        </div>
                    </Grid>
                    <Grid container sx={{ paddingLeft: "25px" }}>
                        <Grid item xs={12}>
                            <AuditRecordsTable
                                rows={searching ? filteredAuditRows : auditRows}
                                handleLoadMore={async () => {
                                    if (searching) {
                                        await _getFilteredAuditList(
                                            false,
                                            userList
                                        );
                                    } else {
                                        await _getPartialAuditList(
                                            true,
                                            userList
                                        );
                                    }
                                }}
                                toFirstPage={toFirstPage}
                                endOfList={endList}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
