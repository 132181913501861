import React, { useState } from "react";
import {
    Button,
    Menu,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { clientByUserId } from "../queries/ListBookingsQueries";
import { MoreHoriz } from "@mui/icons-material";
import { userHasAdminRole } from "../user/UserCommon";
import { useContext } from "react";
import { StoreContext } from "../context/StoreContext";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { mbxUserAtom } from "../atoms/atoms";
import { useAtom } from "jotai";

const BookingTableTheme = {
    display: "inline-table",
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

function DropDown(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        //setSelectedOrder('');
    };
    return (
        <div>
            <MoreHoriz
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    /*  props.handleThreeDotsClick(
                        e
                       
                    ); */
                }}
            />

            <Menu
                PaperProps={{
                    style: {
                        width: 150,
                        border: "1px solid #e5e5e5",
                        boxShadow: "1px 1px #e5e5e5"
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleThreeDotsClose}
            >
                <MenuItem
                    sx={{ fontWeight: 700, color: "#0087EE" }}
                    onClick={() => {
                        props.handleReqDetails(props.row);
                        handleThreeDotsClose();
                    }}
                >
                    Details
                </MenuItem>
            </Menu>
        </div>
    );
}

function BookingDeclineExpiredTabTable(props) {
    const [mbxUser] = useAtom(mbxUserAtom);
    const { state, actions } = useContext(StoreContext);
    //Virtual Link States
    const [openVMDialog, setOpenVMDialog] = useState(false);
    const [serviceDesc, setServiceDesc] = useState("");
    const [serviceProv, setServiceProv] = useState("");
    const [startDate, setStartDate] = useState("");
    const [serviceLoc, setServiceLoc] = useState("");

    //Table Pagination States
    const [page, setPage] = React.useState(0);
    //const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("bookingsTable")
    );

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("bookingsTable")) {
            setTableRowsSession("bookingsTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    const handleThreeDotsClick = (event) => {
        //setSelectedProvider(providerId.toString());
        //setAnchorEl(event.currentTarget);
    };

    //Function to retrieve client data using a users id
    const getClientData = async (user) => {
        try {
            let clientData = await graphql(
                graphqlOperation(clientByUserId, {
                    userId: user.id
                })
            );
            if (clientData && clientData.data.clientByUserId.items) {
                clientData = clientData.data.clientByUserId.items[0];
                clientData.user = user;
                /* console.log("this is the client profile: ", clientData); */
                return clientData;
            }
        } catch (e) {
            console.log(
                "ERROR: unable to retrieve client data using userId ",
                user.id,
                e
            );
        }
    };

    return (
        <>
            <TableContainer sx={BookingTableTheme}>
                <TableHead>
                    <TableRow>
                        {/*headers &&
                                headers.length > 0 &&
                                headers.map((item, index) => {
                                    return <TableCell>{item.title}</TableCell>;
                                })*/}
                        {/* Future Tab With All Headers*/}
                        <TableCell>Status</TableCell>
                        <TableCell>Booking Type</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Location</TableCell>
                        {props.role !== "PROVIDER" && (
                            <TableCell>Provider</TableCell>
                        )}
                        {props.role !== "CLIENT" && (
                            <TableCell>Client</TableCell>
                        )}

                        <TableCell sx={{ minWidth: "140px" }}>
                            No. of Bookings
                        </TableCell>
                        <TableCell sx={{ minWidth: "125px" }}>
                            Final Update
                        </TableCell>

                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.rows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                            return (
                                <TableRow key={row.id + "-" + index}>
                                    <TableCell>
                                        {row.status ? row.status : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {row.bookingType
                                            ? row.bookingType
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {row.serviceAndPackage
                                            ? row.serviceAndPackage
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {row.bookingLoc
                                            ? row.bookingLoc
                                            : "N/A"}
                                    </TableCell>

                                    {props.role !== "PROVIDER" && (
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    "&:hover": {
                                                        cursor:
                                                            row?.data.provider
                                                                ?.permalink &&
                                                            "pointer",
                                                        textDecoration:
                                                            row?.data.provider
                                                                ?.permalink &&
                                                            "underline"
                                                    }
                                                }}
                                                onClick={() => {
                                                    if (
                                                        row.data.provider
                                                            .permalink
                                                    ) {
                                                        window.open(
                                                            `https://${mbxUser.company.subdomain}/provider/${row.data.provider.permalink}`,
                                                            "_blank"
                                                        );
                                                    }
                                                }}
                                            >
                                                {row.providerDetails || "N/A"}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {props.role !== "CLIENT" && (
                                        <TableCell>
                                            <span
                                                onMouseEnter={(e) => {
                                                    e.target.style.textDecoration =
                                                        "underline";
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.textDecoration =
                                                        "none";
                                                }}
                                                onClick={async () => {
                                                    /* console.log(
                                                        "row from the table",
                                                        row
                                                    ); */

                                                    props.showClientDialog(
                                                        await getClientData(
                                                            row.data.user
                                                        )
                                                    );
                                                }}
                                                style={{
                                                    fontFamily: "Roboto",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                {row.clientDetails
                                                    ? row.clientDetails
                                                    : "N/A"}
                                            </span>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {row.numberOfBookings
                                            ? row.numberOfBookings
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {row.createdDesc
                                            ? row.createdDesc
                                            : "N/A"}
                                    </TableCell>

                                    <TableCell>
                                        {userHasAdminRole && (
                                            <DropDown
                                                row={row}
                                                handleReqDetails={
                                                    props.handleReqDetails
                                                }
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TableContainer>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                {props.rows.length !== 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleLoadMore}
                        disabled={props.endOfList}
                    >
                        {props.endOfList ? "No More Rows To Load" : "Load More"}
                    </Button>
                )}

                <TablePagination
                    sx={{
                        marginLeft: "auto",
                        "& .MuiToolbar-root": {
                            alignItems: "center",
                            "& .MuiInputBase-root": {
                                fontWeight: 700
                            },
                            "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                {
                                    color: "rgba(0,0,0,0.5)",
                                    marginBottom: 0
                                },
                            "& .MuiTablePagination-actions": {
                                display: "flex",
                                gap: "0.5rem",
                                "& button": {
                                    border: "1px solid rgba(0,0,0,0.2)",
                                    borderRadius: "4px"
                                },

                                "& button:hover": {
                                    backgroundColor: "primary.light"
                                }
                            }
                        }
                    }}
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={props.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

export default BookingDeclineExpiredTabTable;
