import React, { useState } from "react";
import { Typography, TextField, Button, Grid } from "@mui/material";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import validator from "validator";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { execReadBySortkey } from "../modules/DBService";

import { userHasMarketboxAdminRole } from "../user/UserCommon";

import { userByCompany } from "../queries/UserQueries";

import { getProvider, providerByCompany } from "../graphql/queries";
import { tableTheme } from "../styles/TableTheme";

function UpdateEmailForm({ company }) {
    const [usertype, setUserType] = useState("CLIENT");
    const [useremail, setUserEmail] = useState("");
    const [useremailnew, setUserEmailNew] = useState("");

    const [userData, setUserData] = useState();
    const [providerData, setProviderData] = useState();
    const [cognitoUserData, setCognitoUserData] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [searched, setSearched] = useState();
    const [invlidemail, setInvalidemail] = useState(false);
    const handleChangeUserType = (event) => {
        setUserType(event.target.value);
    };

    const handleChangeUserEmail = (event) => {
        setSearched(false);
        setUserEmail(
            event.target.value ? event.target.value.toLowerCase() : ""
        );
        setProviderData();
        setUserData();
        setCognitoUserData();
        setInvalidemail(false);
    };

    const handleChanageUserEmailNew = (event) => {
        setError();
        setUserEmailNew(
            event.target.value ? event.target.value.toLowerCase() : ""
        );
    };

    const searchUsers = async () => {
        //  console.log("company id: ", companyId);
        console.log("role: ", usertype);
        console.log("email: ", useremail);
        console.log("new email: ", useremailnew);
        if (!useremail || !validator.isEmail(useremail)) {
            setInvalidemail(true);
            return;
        }

        setUserData();
        setProviderData();
        setCognitoUserData();
        setUserEmailNew();

        //search users
        let usersByCompany = await graphql(
            graphqlOperation(userByCompany, {
                companyId: company.id,
                roleEmailaddress: {
                    beginsWith: {
                        role: usertype,
                        emailaddress: useremail
                    }
                }
            })
        );

        if (
            usertype === "PROVIDER" &&
            usersByCompany.data.userByCompany.items[0] == undefined
        ) {
            usersByCompany = await graphql(
                graphqlOperation(userByCompany, {
                    companyId: company.id,
                    roleEmailaddress: {
                        beginsWith: {
                            role: "COMPANY_ADMIN_PROVIDER",
                            emailaddress: useremail
                        }
                    }
                })
            );
        }

        console.log("users by company: ", usersByCompany);

        if (usersByCompany.data.userByCompany.items.length) {
            setUserData(usersByCompany.data.userByCompany.items[0]);
            console.log(usersByCompany.data.userByCompany.items[0]);

            if (usertype == "PROVIDER") {
                let providersByCompany = await graphql(
                    graphqlOperation(getProvider, {
                        id: usersByCompany.data.userByCompany.items[0]
                            .providerId
                    })
                );

                setProviderData(providersByCompany.data.getProvider);
                console.log(providersByCompany);
            }

            await getCognitoUser(usersByCompany.data.userByCompany.items[0].id);
        } else {
            //if PROVIDER, then find provider who does not have a login directly from Provider table
            if (usertype === "PROVIDER") {
                const providers = await execReadBySortkey({
                    opname: "providerByCompany",
                    op: providerByCompany,
                    id: {
                        companyId: company.id
                    },
                    filter: {
                        and: [{ emailaddress: { beginsWith: useremail } }]
                    }
                });
                console.log("PROVIDERS", providers);
                if (providers.items?.length) {
                    setProviderData(providers.items[0]);
                }
            }
        }
        setSearched(true);
    };

    const updateEmails = async () => {
        try {
            setSuccess(false);
            setError();
            if (!useremailnew || !validator.isEmail(useremailnew)) {
                setError("Please enter a valid new email address");
                return;
            }
            let cognitoUpdateIsGood = cognitoUserData ? false : true;
            if (cognitoUserData) {
                const result = await updateCognitoEmail();
                if (result && result.success === true)
                    cognitoUpdateIsGood = true;
            }
            if (!cognitoUpdateIsGood) {
                setError("Cognito email update error");
                return;
            }
            if (userData) {
                await updateEmailUser();
            }
            if (providerData) {
                await updateEmailProvider();
            }
            setSuccess(true);
        } catch (e) {
            console.log(e);
            setError("An error occurred.");
        }
        setUserEmailNew();
    };
    const updateEmailUser = async () => {
        const input = {
            role: userData.role,
            id: userData.id,
            firstname: userData.firstname,
            lastname: userData.lastname,
            emailaddress: useremailnew
        };

        const updateUser = await graphql(
            graphqlOperation(mutations.updateUser, { input })
        );
        console.log(JSON.stringify(updateUser, null, 4));
    };

    const updateEmailProvider = async () => {
        const input = {
            id: providerData.id,
            companyId: providerData.company.id,
            firstname: providerData.firstname,
            lastname: providerData.lastname,
            createdAt: providerData.createdAt,
            emailaddress: useremailnew
        };

        const updateProvider = await graphql(
            graphqlOperation(mutations.updateProvider, { input })
        );
        console.log(JSON.stringify(updateProvider, null, 4));
    };

    async function getCognitoUser(id) {
        const result = await getJsonApi("changeuserrole", "/getuser", {
            queryParams: {
                username: id
            }
        });
        if (result && result.data?.UserAttributes)
            setCognitoUserData(result.data.UserAttributes);

        console.log("getCognitoUser result", result?.data?.UserAttributes);
    }

    const updateCognitoEmail = async () => {
        const result = await postApi("changeuserrole", "/updateemail", {
            body: {
                username: cognitoUserData[0].Value,
                newEmail: useremailnew
            }
        });

        console.log("updateCognitoEmail result", result);
        console.log(JSON.stringify(result, null, 4));
        return result;
    };

    return userHasMarketboxAdminRole() ? (
        <Grid container flexDirection={"column"} gap={4}>
            <Grid item xs={12}>
                <Typography variant="h4" noWrap>
                    Update Email
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginLeft: "8px" }}>
                        <b>Search the customer or provider by email address</b>
                    </Typography>
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                        Using this screen, you can update the email address of
                        all the customers and the providers.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={12} container gap={2}>
                <Grid item xs={12} sm={3} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            User Type
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="usertype"
                            value={usertype}
                            label="usertype"
                            onChange={handleChangeUserType}
                        >
                            <MenuItem value={"CLIENT"}>Client</MenuItem>
                            <MenuItem value={"PROVIDER"}>Provider</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        id="email"
                        label="Email Address"
                        value={useremail}
                        onChange={handleChangeUserEmail}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        onClick={searchUsers}
                        sx={{ m: 1 }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
            <Grid container flexDirection={"row"} gap={4}>
                <Grid item xs={12}>
                    {invlidemail && (
                        <Typography
                            variant="body1"
                            style={{ marginLeft: "8px", color: "red" }}
                        >
                            Please enter a valid email address to search{" "}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {providerData || userData ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                style={{ marginLeft: "8px" }}
                            >
                                <b>
                                    Enter new email address and click Update
                                    Email.
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={"row"} gap={4}>
                        <Grid item xs={12} sm={5} md={3}>
                            <TextField
                                id="newemail"
                                label="New Email Address"
                                value={useremailnew}
                                onChange={handleChanageUserEmailNew}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={5} md={2}>
                            <Button
                                variant="contained"
                                onClick={updateEmails}
                                sx={{ m: 1 }}
                            >
                                Update Email
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={"row"} gap={4}>
                        <Grid item xs={12}>
                            {error && (
                                <Typography
                                    variant="body1"
                                    style={{ marginLeft: "8px", color: "red" }}
                                >
                                    {error}
                                </Typography>
                            )}{" "}
                            {success && (
                                <Typography
                                    variant="body1"
                                    style={{ marginLeft: "8px" }}
                                >
                                    Email address is updated in the records
                                    listed below
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container flexDirection={"row"} gap={4}>
                    {searched && !userData && !providerData && (
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                style={{ marginLeft: "8px", color: "red" }}
                            >
                                No records found for the email address{" "}
                                {useremail}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid item md={10}>
                <TableContainer>
                    <Table sx={tableTheme} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Record</TableCell>
                                <TableCell align="right">Full Name</TableCell>
                                <TableCell align="right">
                                    Phone Number
                                </TableCell>
                                <TableCell align="right">Status</TableCell>
                                {/* <TableCell align="right">
                                    Current email
                                </TableCell> */}
                                {/* <TableCell align="right">New email</TableCell> */}
                                {/* <TableCell align="right"></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userData && (
                                <TableRow>
                                    <TableCell>
                                        {usertype === "CLIENT"
                                            ? "User record of customer"
                                            : "User record of provider"}
                                    </TableCell>
                                    <TableCell align="right">
                                        {userData
                                            ? userData?.firstname +
                                              " " +
                                              userData?.lastname
                                            : ""}
                                    </TableCell>
                                    <TableCell align="right">
                                        {userData?.mobilephone}
                                    </TableCell>
                                    <TableCell align="right">
                                        {userData?.active
                                            ? "Active"
                                            : "Inactive"}
                                    </TableCell>
                                    {/* <TableCell align="right">
                                        {userData?.emailaddress}
                                    </TableCell> */}
                                    {/* <TableCell align="right">
                                        {useremailnew}
                                    </TableCell> */}
                                    {/* <TableCell align="right">
                                        <Button onClick={updateEmailUser}>
                                            Update Email
                                        </Button>
                                    </TableCell> */}
                                </TableRow>
                            )}

                            {providerData && (
                                <TableRow>
                                    <TableCell>Provider</TableCell>
                                    <TableCell align="right">
                                        {providerData
                                            ? providerData?.firstname +
                                              " " +
                                              providerData?.lastname
                                            : ""}
                                    </TableCell>
                                    <TableCell align="right">
                                        {providerData?.phone}
                                    </TableCell>
                                    <TableCell align="right">
                                        {providerData?.active
                                            ? "Active"
                                            : "Inactive"}
                                    </TableCell>
                                    {/* <TableCell align="right">
                                        {providerData?.emailaddress}
                                    </TableCell> */}
                                    {/* <TableCell align="right">
                                        {useremailnew}
                                    </TableCell> */}
                                    {/* <TableCell align="right">
                                        <Button onClick={updateEmailProvider}>
                                            Update Email
                                        </Button>
                                    </TableCell> */}
                                </TableRow>
                            )}

                            {cognitoUserData && (
                                <TableRow>
                                    <TableCell>
                                        {usertype === "CLIENT"
                                            ? "Customer login"
                                            : "Provider login"}
                                    </TableCell>
                                    <TableCell align="right">
                                        {cognitoUserData &&
                                            cognitoUserData.find(
                                                (e) => e.Name === "given_name"
                                            ).Value}
                                    </TableCell>
                                    <TableCell align="right">
                                        {cognitoUserData &&
                                            cognitoUserData.find(
                                                (e) => e.Name === "phone_number"
                                            ).Value}
                                    </TableCell>
                                    <TableCell align="right">{"N/A"}</TableCell>
                                    {/* <TableCell align="right">
                                        {cognitoUserData &&
                                            cognitoUserData.find(
                                                (e) => e.Name === "email"
                                            ).Value}
                                    </TableCell> */}
                                    {/* <TableCell align="right">
                                        {useremailnew}
                                    </TableCell> */}
                                    {/* <TableCell align="right">
                                        <Button
                                            onClick={() => updateCognitoEmail()}
                                        >
                                            Update Email
                                        </Button>
                                    </TableCell> */}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    ) : (
        <>{`You don't have access`}</>
    );
}

export default UpdateEmailForm;
