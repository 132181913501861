import React, { useState, useContext } from "react";
// prettier-ignore
import { GoogleMap, DrawingManager, Polygon, Marker, Circle } from "@react-google-maps/api";
// prettier-ignore
import { Box, Button } from "@mui/material";
import { useRef } from "react";
import { useEffect } from "react";
import { GoogleMapsContext } from "../../context/GoogleMapsContext";

function MapComponent(props) {
    //Map Component at the time of writing this is only
    // called from TestMapComponent and ProviderLocationAddEdit
    const [enableDrawing, setEnableDrawing] = useState(null);
    const mapRef = useRef(null);
    const polygonRef = useRef(null);
    const drawingManagerRef = useRef(null);
    const { isLoaded } = useContext(GoogleMapsContext);
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log("is keydown works", event);
            if (event.key === "Backspace" || event.key === "r") {
                resetPolygon();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        console.log("Props polygon mapcomponent", props.polygons);
        if (props.polygons.length > 1 && props.fromDashboard()) {
            setEnableDrawing(false);
        }
        if (props.polygons.length <= 0 || !props.fromDashboard()) {
            setEnableDrawing(true);
        }
    }, [props.polygons, props.fromDashboard]);

    useEffect(() => {
        console.log("Drawing Mode", enableDrawing);
    }, [enableDrawing]);

    const resetPolygon = () => {
        console.log("Drawing Manager Ref", drawingManagerRef);
        console.log("Polygon Ref", polygonRef);
        if (drawingManagerRef.current) {
            drawingManagerRef.current.setDrawingMode(null);
            //polygonRef.current.setMap(null);
            //polygonRef.current = null;
            drawingManagerRef.current.setDrawingMode(
                window.google.maps.drawing.OverlayType.POLYGON
            );
            props.deletePolygon();
        }
    };

    // console.log("asdf props", props);
    // console.log("Point Ref Outside", pointRef);
    const position = {
        lat: props.lat,
        lng: props.lng
    };

    const onLoad = (marker) => {
        console.log("marker: ", marker);
    };
    const onLoad3 = (marker) => {
        // console.log("googlemaps done loading", marker);
    };

    function notViewMode() {
        return !(props.viewType === "View");
    }

    function renderMap() {
        return (
            <GoogleMap
                id="provider-map"
                mapContainerStyle={{
                    width: props.mapWidth ? props.mapWidth : "552px",
                    height: props.mapHeight ? props.mapHeight : "417px"
                }}
                mapTypeId="roadmap"
                clickableIcons={false}
                onLoad={(map) => {
                    mapRef.current = map;
                }}
                options={{
                    mapTypeControl: false,
                    streetViewControl: false
                }}
                zoom={props.lat && props.lng ? 8 : 1}
                center={{
                    lat: props.lat || 51.03841,
                    lng: props.lng || -114.01679
                }}
            >
                {/*   <div
                    style={{
                        position: "absolute",
                        top: "5px",
                        transform: "translateX(50%)",
                        backgroundColor: "#fff",
                        padding: "5px 10px",
                        zIndex: 9999
                    }}
                >
                    <code>r or backspace</code> - reset timezone
                </div> */}
                <Marker onLoad={onLoad} position={position} />
                {props.drawType === 2 && notViewMode() && enableDrawing && (
                    <DrawingManager
                        onLoad={(drawingManager) => {
                            drawingManagerRef.current = drawingManager;
                        }}
                        drawingMode={"polygon"}
                        options={{
                            drawingControl: true,
                            drawingControlOptions: {
                                drawingModes: ["polygon"]
                            },

                            circleOptions: {
                                fillColor: `#ffff00`,
                                fillOpacity: 1,
                                strokeWeight: 5,
                                clickable: false,
                                editable: true,
                                zIndex: 1
                            },
                            polygonOptions: {
                                strokeWeight: 2, // Line thickness
                                fillColor: "#ff0000", // Polygon fill color
                                fillOpacity: 0.4, // Polygon fill opacity
                                editable: true,
                                clickable: false,
                                zIndex: 1, // Set a higher value to bring the polygon to the front
                                icons: [
                                    {
                                        icon: {
                                            path: window.google.maps.SymbolPath
                                                .LINE,
                                            scale: 5, // Adjust the scale to make the polygon points bigger
                                            fillColor: "#ffffff", // Point fill color
                                            fillOpacity: 1, // Point fill opacity
                                            strokeWeight: 2 // Point border thickness
                                        },
                                        offset: "0%", // Offset the points to be centered on the polygon vertices
                                        repeat: "10px" // Repeat the points every 10 pixels
                                    }
                                ]
                            }
                        }}
                        onPolygonComplete={(polygon) => {
                            polygonRef.current = polygon;
                            if (props.fromDashboard()) {
                                setEnableDrawing(false);
                            }

                            console.log("Do i get called ?");
                            const polyArray = polygon.getPath().getArray();
                            const paths = [];
                            polyArray.forEach(function (path) {
                                paths.push({
                                    lat: path.lat(),
                                    lng: path.lng()
                                });
                            });
                            console.log("What are paths", paths);
                            if (paths && paths.length > 1)
                                props.setPolygons(paths);
                            /*   if (paths && paths.length <= 0) {
                                setEnableDrawing(false);
                            } */
                            polygon.setMap(null);
                        }}
                    />
                )}
                {props.drawType === 2 &&
                    props.polygons.map((polygon, i) => (
                        <Polygon paths={polygon} key={i} />
                    ))}
                {/*props.drawType === 1 && (
                    <DrawingManager drawingControl={false} drawingMode={"circle"} />
                )*/}
                {props.drawType === 1 && (
                    <Circle
                        center={{
                            lat: props.lat,
                            lng: props.lng
                        }}
                        radius={props.radius}
                        fillColor={`#ffff00`}
                        fillOpacity={1}
                        strokeWeight={1}
                        clickable={false}
                        editable={false}
                    />
                )}
            </GoogleMap>
        );
    }

    return (
        <div style={{ width: "100%" }} className="App">
            {isLoaded && renderMap()}
            {props.drawType === 2 && (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "flex-end"
                    }}
                >
                    {notViewMode() && !props.hideSaveClose && (
                        <Button
                            sx={{ marginTop: "18px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => props.closeMap()}
                            disabled={props?.polygons?.length <= 0}
                        >
                            Save & Close
                        </Button>
                    )}
                    {notViewMode() && (
                        <Button
                            sx={{ marginTop: "18px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => props.undoPolygon()}
                            disabled={props?.polygons?.length <= 0}
                        >
                            {props.fromDashboard()
                                ? "Remove zone"
                                : "Remove last zone"}
                        </Button>
                    )}

                    {!notViewMode() && props.closeMap && (
                        <Button
                            sx={{ marginTop: "18px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => props.closeMap()}
                        >
                            Close
                        </Button>
                    )}
                </Box>
            )}

            {/*  <Button
                component="span"
                m={1}
                variant="contained"
                color="primary"
                onClick={() => props.closeMap()}
            >
                Close
            </Button> */}
        </div>
    );
}

export default MapComponent;
