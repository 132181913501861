// A container for the React router pages, remove when fully transitioned to React router
export const OUTLET_PAGE = "Outlet";

const appState = JSON.parse(localStorage.getItem("appState"));

const initialState = {
    ...{
        techList: ["TypeScript", "React Hooks"],
        page: "DashboardForm", // initial page
        mode: "Edit", // initial mode
        id: "", // context id,
        contextCompanyId: "", // this is the current company being viewed
        bookingState: {
            serviceType: "",
            provider: ""
        },
        provider: {},
        user: {},
        pageList: [
            "ManageBookingReq",
            "MasterDashboardForm",
            "DashboardForm",
            "ProviderForm",
            "ProviderAddEdit",
            "ProviderSchedule",
            "ProviderScheduleAddEditForm",
            "AuditRecordsForm",
            "CustomerForm",
            "ClientPackageAddEditForm",
            "CompanyLocationForm",
            "CompanyLocationAddEditForm",
            "ProviderLocationForm",
            "ProviderLocationAddEditForm",
            "CustomerAddEditForm",
            "BookingForm",
            "BookingUserForm",
            "BookingFormTrue",
            "OrderForm",
            "BillingForm",
            "ServiceForm",
            "ServiceAdd",
            "ManageServiceCategoryForm",
            "ManageServiceOrder",
            "CategoryAddEditForm",
            "PromotionForm",
            "ReportForm",
            "CompanyForm",
            "AdminForm",
            "IntegrationForm",
            "UpdateEmailForm",
            "PackageForm",
            "PromotionAddForm",
            "CompanyAddEditForm",
            "PackageAddEditForm",
            "ProviderDirectory",
            "ProviderAdditionalPhotos",
            "ProviderAdditionalPhotosAddEdit",
            "UsersForm",
            "UsersEditViewForm",
            "RegionForm",
            "RegionAddEditForm",
            "ProviderReview",
            "ColorForm",
            "TimeBlockProviders",
            "TimeBlockForm",
            "MiscBillingTransaction",
            "ProviderReviewForm",
            "BookingsListForm",
            "BookNowLandingPage",
            "EnvtForm",
            "ProviderBlockTime",
            "BlockTimeAddEdit",
            "SkillsForm",
            "SkillsAddEditForm",
            "CompanyReminderForm",
            "CompanyReminderAddEditForm",
            "AdditionalChargesForm",
            "ProviderScheduleDevEx",
            "PotentialSalesForm",
            "ClientBillingInfo",
            "BFSettings",
            OUTLET_PAGE
        ],
        modes: ["Add", "Edit", "View", "Client", "AddTb", "AddSc"]
    },
    ...appState,
    // here we handle a url edit or a direct link to a page
    page:
        OUTLET_PAGE
};

const types = {
    SET_PAGE: "SET_PAGE",
    SET_MODE: "SET_MODE",
    SET_ID: "SET_ID",
    SET_USER: "SET_USER",
    SET_BOOKING_STATE: "SET_BOOKING_STATE",
    SET_CONTEXT_COMPANY: "SET_CONTEXT_COMPANY",
    SET_PROVIDER: "SET_PROVIDER",
    SET_BR: "SET_BR",
    SET_BOOKINGS_TAB: "SET_BOOKINGS_TAB",
    SET_PROVIDER_SCHEDULE_VIEW_TYPE: "SET_PROVIDER_SCHEDULE_VIEW_TYPE",
    SET_CLIENT_FOR_PACKAGE_DIALOG: "SET_CLIENT_FOR_PACKAGE_DIALOG",
    SET_TABLE_ROWS: "SET_TABLE_ROWS"
};

const reducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.SET_BOOKINGS_TAB:
            newState = {
                ...state,
                bookingsTab: action.payload
            };
            break;
        case types.SET_BR:
            newState = {
                ...state,
                bookingRequest: action.payload
            };
            break;
        case types.SET_PAGE:
            newState = {
                ...state,
                page: action.payload
            };
            break;
        case types.SET_MODE:
            newState = {
                ...state,
                mode: action.payload
            };
            break;
        case types.SET_ID:
            newState = {
                ...state,
                id: action.payload
            };
            break;
        case types.SET_USER:
            newState = {
                ...state,
                user: action.payload
            };
            break;
        case types.SET_PROVIDER:
            newState = {
                ...state,
                provider: action.payload
            };
            break;
        case types.SET_PROVIDER_SCHEDULE_VIEW_TYPE:
            newState = {
                ...state,
                providerScheduleViewType: action.payload
            };
            break;
        case types.SET_BOOKING_STATE:
            newState = {
                ...state,
                bookingState: action.payload
            };
            break;
        case types.SET_CONTEXT_COMPANY:
            newState = {
                ...state,
                contextCompanyId: action.payload
            };
            break;
        case types.SET_CLIENT_FOR_PACKAGE_DIALOG:
            newState = {
                ...state,
                clientForPackageDialog: action.payload
            };
            break;
        default:
            throw new Error("Unexpected action");
    }
    localStorage.setItem("appState", JSON.stringify(newState));
    return newState;
};
export { initialState, types, reducer };
