import React, { useState, useRef } from "react";
import {
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    Switch
} from "@mui/material";
import * as mutations from "../graphql/mutations";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { auditClientNotesUpdate, auditUserUpdate } from "../modules/Audit";
import { userHasAdminRole } from "../user/UserCommon";

const useStyles = makeStyles({
    customDialogContent: {
        padding: "0px",
        maxWidth: "420px"
    },
    customerFormSubtitle: {
        color: "rgb(0,0,0,0.87)",
        fontSize: "14px",
        fontWeight: "500"
    },
    customerFormBodyText: {
        color: "black",
        fontSize: "16px"
    },

    titleContainer: {
        display: "grid",
        gridTemplateColumns: "68px 1fr",
        gap: "18px",
        alignItems: "center"
    },
    customDialogTitle: {
        padding: "24px 24px 14px 24px",
        maxWidth: "420px"
    },
    titleItem: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: "24px",
        color: "rgb(0,0,0,0.8)"
    },

    greySquare: {
        width: "68px",
        height: "68px",
        backgroundColor: "#D9D9D9",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "24px",
        color: "#1c1c1c",
        fontFamily: "Roboto"
    },
    notesInput: {
        borderColor: "initial",
        transition: "border-color 0.3s",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid black"
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
            /* border: "1px solid rgba(0, 135, 238, 0.66)",
            backgroundColor: "rgba(0, 135, 238, 0.12)" */
            border: "1px solid black"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        }
    },
    notesUpdateText: {
        fontSize: "12px",
        fontStyle: "italic",
        color: "#5d5d5d",
        paddingTop: "4px"
    },
    mainButtonContainer: {
        paddingTop: "10px"
    },
    leftButtonContainer: {
        display: "flex",
        gap: "12px"
    },
    rightButtonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    saveButton: {
        color: "white",
        borderRadius: "4px",
        padding: "6px 42px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        height: "32px"
    },

    cancelButton: {
        cursor: "pointer",
        color: "rgb(0,0,0,0.8)",
        height: "32px"
    },
    clearButton: {
        cursor: "pointer",
        color: "#FF681D",
        paddingRight: "0px",
        justifyContent: "right",
        height: "32px"
    }
});

function CustomerInfoDialog(props) {
    const [notes, setNotes] = useState(props.clientNotes.notes);
    const [originalNotes, setOriginalNotes] = useState(props.clientNotes.notes);
    const [clientNotes, setClientNotes] = useState(props.clientNotes);
    const [updatedAt, setUpdatedAt] = useState(props.clientNotes.updatedAt);
    const [updatedBy, setUpdatedBy] = useState(props.clientNotes.updatedBy);
    const [contactConsent, setContactConsent] = useState(
        props.clientContactConsent ? true : false
    );
    const [contactConsentOrig] = useState(
        props.clientContactConsent ? true : false
    );
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const classes = useStyles();
    const notesRef = useRef(null);

    const originalColor = props.loggedInUser.company.primaryColor;

    function getInitials(fullName) {
        const names = fullName.split(" ");
        const initials = names.map((name) => name[0].toUpperCase()).join("");
        return initials;
    }

    const handleInputFocus = () => {
        setIsInputFocused(true);
        /* console.log("in focus"); */
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
        /* console.log("out of focus"); */
    };

    const handleNotesChange = (event) => {
        const newText = event.target.value;
        setNotes(newText);
        /* console.log("Updated Notes:", newText); */
    };

    const saveContactConsent = async () => {
        try {
            const input = {
                role: props.clientUserRole,
                id: props.clientUserId,
                firstname: props.clientFirstName,
                lastname: props.clientLastName,
                emailaddress: props.clientEmailAddress,
                contactconsent: contactConsent,
                contactconsentdatetime: new Date().toISOString()
            };

            const updateUser = await graphql(
                graphqlOperation(mutations.updateUser, { input })
            );

            if (updateUser && updateUser.data && updateUser.data.updateUser) {
                try {
                    await auditUserUpdate(
                        props.loggedInUser,
                        updateUser.data.updateUser,
                        [
                            {
                                firstname: "No Change",
                                lastname: "No Change",
                                address: "No Change",
                                homephone: "No Change",
                                mobilephone: "No Change",
                                workphone: "No Change",
                                prefphonetype: "No Change",
                                role: "No Change",
                                contactconsent: contactConsent
                            }
                        ]
                    );
                } catch (e) {
                    console.log("Error while entering audit");
                }
            }
        } catch (e) {
            console.log(
                "ERROR: Unable to save client user contact consent ",
                e
            );
        }
    };

    const saveNotes = async () => {
        setOriginalNotes(notes);
        setUpdatedBy(
            props.loggedInUser.firstname + " " + props.loggedInUser.lastname
        );
        setUpdatedAt(moment().format("YYYY-MM-DD HH:mm"));
        let newNotes = {};
        const date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        try {
            if (clientNotes) {
                //if Notes exists, then only update and use the same createdAt and createdBy attributes
                newNotes = {
                    notes: notes,
                    createdBy: clientNotes.createdBy,
                    updatedBy:
                        props.loggedInUser.firstname +
                        " " +
                        props.loggedInUser.lastname,
                    createdAt: clientNotes.createdAt,
                    updatedAt: date
                };
                setClientNotes(newNotes);
            } else {
                //if Notes dont exists, add new created stamps and update stamps
                newNotes = {
                    notes: notes,
                    createdBy:
                        props.loggedInUser.firstname +
                        " " +
                        props.loggedInUser.lastname,
                    updatedBy:
                        props.loggedInUser.firstname +
                        " " +
                        props.loggedInUser.lastname,
                    createdAt: date,
                    updatedAt: date
                };
                setClientNotes(newNotes);
            }

            const updatedClient = await graphql(
                graphqlOperation(mutations.updateClient, {
                    input: {
                        id: props.clientId,
                        Notes: JSON.stringify(newNotes)
                    }
                })
            );

            if (
                updatedClient &&
                updatedClient.data &&
                updatedClient.data.updateClient
            ) {
                try {
                    await auditClientNotesUpdate(
                        props.loggedInUser,
                        updatedClient.data.updateClient,
                        notes,
                        originalNotes
                    );
                } catch (e) {
                    console.log("Error while entering audit");
                }
            }

            return newNotes;
        } catch (e) {
            console.log("ERROR: Unable to save client Notes ", e);
        }
    };

    const handleSave = async () => {
        /* console.log("Saved Notes:", notes); */
        document.activeElement.blur(); // Unfocus the text field
        // handle contactConsent change separately
        const clientUpdates = {};
        if (
            userHasAdminRole(props.loggedInUser) &&
            contactConsent !== contactConsentOrig
        ) {
            clientUpdates.consent = contactConsent;
            await saveContactConsent();
        }
        if (originalNotes !== notes) {
            const newNotes = await saveNotes();
            clientUpdates.newNotes = JSON.stringify(newNotes);
        }

        if (props.onSave) {
            props.onSave(props.clientId, clientUpdates);
        }

        props.onClose();
    };

    const handleCancel = () => {
        if (isInputFocused) {
            document.activeElement.blur();
        } else {
            props.onClose();
        }
    };

    const handleClear = () => {
        setNotes("");
        notesRef.current.focus();
    };

    return (
        <>
            <DialogTitle className={classes.customDialogTitle}>
                <Grid container className={classes.titleContainer}>
                    <div className={classes.titleItem}>
                        <div className={classes.greySquare}>
                            {getInitials(
                                props.clientFirstName.trim() +
                                    " " +
                                    props.clientLastName.trim()
                            )}
                        </div>
                    </div>
                    <div className={classes.titleItem}>
                        {props.clientFirstName + " " + props.clientLastName}
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    className={classes.customDialogContent}
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            className={classes.customerFormSubtitle}
                        >
                            {`First name`}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.customerFormBodyText}
                        >
                            {props.clientFirstName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            className={classes.customerFormSubtitle}
                        >
                            {`Last name`}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.customerFormBodyText}
                        >
                            {props.clientLastName}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            className={classes.customerFormSubtitle}
                        >
                            {`Email`}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.customerFormBodyText}
                        >
                            {props.clientEmailAddress}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            className={classes.customerFormSubtitle}
                        >
                            {`Mobile phone`}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.customerFormBodyText}
                        >
                            {props.clientMobilePhone}
                        </Typography>
                    </Grid>
                    {userHasAdminRole(props.loggedInUser) && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={contactConsent}
                                            onChange={(event) =>
                                                setContactConsent(
                                                    event.target.checked
                                                )
                                            }
                                            value={contactConsent}
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "primary checkbox"
                                            }}
                                        />
                                    }
                                    label="Send client appointment reminder emails"
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            className={classes.customerFormSubtitle}
                        >
                            {`Notes`}
                        </Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={6}
                            maxRows={6}
                            InputProps={{
                                className: classes.notesInput,
                                onFocus: handleInputFocus,
                                onBlur: handleInputBlur
                            }}
                            value={notes}
                            onChange={handleNotesChange}
                            inputRef={notesRef}
                        />
                        {updatedBy && (
                            <Grid container item xs={12}>
                                <Typography className={classes.notesUpdateText}>
                                    Last edited by {updatedBy} on{" "}
                                    {moment(updatedAt).format(
                                        "YYYY-MM-DD HH:mm"
                                    )}
                                </Typography>
                            </Grid>
                        )}
                        {/* {isInputFocused && ( */}
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.mainButtonContainer}
                        >
                            <Grid
                                item
                                xs={4}
                                className={classes.leftButtonContainer}
                            >
                                <Button
                                    onClick={handleSave}
                                    variant="contained"
                                    className={classes.saveButton}
                                    disabled={
                                        notes === originalNotes &&
                                        contactConsent === contactConsentOrig
                                    }
                                >
                                    <Typography
                                        variant="button"
                                        style={{
                                            textTransform: "none"
                                        }}
                                    >
                                        {`Save`}
                                    </Typography>
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    variant="text"
                                    className={classes.cancelButton}
                                >
                                    {
                                        <Typography
                                            variant="button"
                                            style={{
                                                textTransform: "none"
                                            }}
                                        >
                                            {`Cancel`}
                                        </Typography>
                                    }
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                className={classes.rightButtonContainer}
                            >
                                <Button
                                    onClick={handleClear}
                                    variant="text"
                                    className={classes.clearButton}
                                >
                                    <Typography
                                        variant="button"
                                        style={{
                                            textTransform: "none"
                                        }}
                                    >
                                        {`Clear`}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        {/* )} */}
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}

export default CustomerInfoDialog;
