import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// prettier-ignore
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    postApi
} from "../../modules/AmplifyServices";
// prettier-ignore
import { Grid, Button, Typography, FormControlLabel, Snackbar } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "./stripe.css";
import {
    updateClient,
    createBillingTransaction,
    createClientCharge
} from "../../graphql/mutations";
// prettier-ignore
import { updateClientPackageToPaid, updateOrderStatusToPaid, handleOrderAndBookingCreation, updateHeldSlotsToConfirmed, updateUsedQuantityOfPackage, sendOrderReceipt, getStripeAmount, getCurrencySymbol, sendProviderBookingConfirmation, holdSlots, deleteHeldSlots } from "../../modules/AdhocBookingService";
import { blue } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

const logger = new ConsoleLogger("StripeChargeCard");

const StripeChargeCard = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [state, setState] = useState({
        useDifferentCard: false,
        errorMessage: "",
        thanksMessage: false,
        cardForm: false,
        msgOpen: false,
        snackMsg: "",
        bookingDetails: {},
        showSlotsTimeoutExpired: false,
        packageCreditsAreNotValid: false
    });
    const [payClicked, setPayClicked] = useState(false);

    const createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: "16px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#c23d4b"
                }
            }
        };
    };

    const buttonProgress = () => {
        return {
            style: {
                buttonProgress: {
                    color: blue[500],
                    position: "absolute",
                    top: "10%",
                    marginTop: -1,
                    marginLeft: -1
                }
            }
        };
    };

    const wrapper = () => {
        return {
            style: {
                wrapper: {
                    margin: "8px",
                    position: "relative"
                }
            }
        };
    };

    async function handleClick() {
        setPayClicked(true);
        props.disableElementsHandler();

        const { isNewClient } = props.bookingState;
        if (
            isNewClient ||
            !props.bookingState.client.stripeCustomerId ||
            state.useDifferentCard ||
            props.bookingState.cardExpired
        ) {
            //create token, customer
            const cne = elements.getElement(CardNumberElement);
            const connectedAccount =
                props.company.stripeConnectEnabled &&
                props.company.stripeAccount
                    ? { stripeAccount: props.company.stripeAccount }
                    : null;
            let { token, error } = await stripe.createToken(
                cne,
                connectedAccount
            );
            if (error) {
                setPayClicked(false);
                setState({
                    ...state,
                    msgOpen: true,
                    snackMsg: error.message
                });
                props.setBookingCompleted({
                    success: false,
                    message: "Unable to create stripe token"
                });
                return;
            }
            try {
                const emailaddress = props.bookingState.bookingOnBehalf
                    ? props.bookingState.client.user.emailaddress
                    : props.bookingState.client.user.attributes.email;

                const resultStripe = await postApi(
                    "stripesavecard",
                    "/savecardinfo",
                    {
                        body: {
                            token,
                            emailaddress,
                            companyId: props.company.id,
                            stripeAccount: props.company.stripeAccount,
                            stripeConnectEnabled:
                                props.company.stripeConnectEnabled
                        }
                    }
                );
                //  console.log("result for resultStripe = ", resultStripe);
                if (resultStripe.error) {
                    setPayClicked(false);
                    setState({
                        ...state,
                        msgOpen: true,
                        snackMsg: resultStripe.error.message
                    });
                    props.setBookingCompleted({
                        success: false,
                        message: `Unable to save stripe card. ${resultStripe.error.message}`
                    });
                    return;
                }
                if (!resultStripe.customer) {
                    setPayClicked(false);
                    setState({
                        ...state,
                        msgOpen: true,
                        snackMsg: "Could not save credit card information."
                    });
                    props.setBookingCompleted({
                        success: false,
                        message: `Could not save credit card information.`
                    });
                    return;
                }
                // save customer id from result
                const stripeCustomerId = resultStripe.customer.id;
                const cclast4 = resultStripe.customer.sources.data[0].last4;
                const input = {
                    id: props.bookingState.client.id,
                    defaultpartialcc: cclast4,
                    stripeCustomerId
                };
                // save customer.id from Stripe to Customer type
                const updatedClient = await graphql(
                    graphqlOperation(updateClient, { input })
                );
                //   console.log("result for resultCustomer = ", updatedClient);
                await handleOrderCreation(updatedClient.data.updateClient);
            } catch (e) {
                console.log("error", JSON.stringify(e));
                setPayClicked(false);
                setState({
                    ...state,
                    msgOpen: true,
                    snackMsg: "Error while saving credit card information."
                });
                props.setBookingCompleted({
                    success: false,
                    message: "Error while saving credit card information."
                });
                return;
            }
        } else await handleOrderCreation(props.bookingState.client);
    }

    async function handleOrderCreation(client) {
        await processPayment(client);
    }

    async function charge(stripeCustomerId) {
        const emailaddress = props.bookingState.bookingOnBehalf
            ? props.bookingState.client.user.emailaddress
            : props.bookingState.client.user.attributes.email;
        const resultStripeCharge = await postApi(
            "stripechargecard",
            "/stripechargecard",
            {
                body: {
                    receiptemailaddress: emailaddress,
                    stripecustomerid: stripeCustomerId,
                    customerCurrency: props.bookingState.currency,
                    amount: props.bookingState.cbu.total
                        ? getStripeAmount(props.bookingState.cbu.total)
                        : 0,
                    credit: false,
                    prevcharge: false,
                    companyId: props.company.id,
                    stripeAccount: props.company.stripeAccount,
                    stripeConnectEnabled: props.company.stripeConnectEnabled
                }
            }
        );
        // console.log("result for resultStripeCharge = ");
        // console.log(resultStripeCharge);
        return resultStripeCharge;
    }

    async function processPayment(client) {
        try {
            setState({
                ...state,
                msgOpen: true,
                snackMsg: "Processing payment"
            });
            try {
                if (!props.bookingState?.repeatingAppointment) {
                    await holdSlots(props.bookingState, props.company);
                }
            } catch (e) {
                console.log("error while holding slot", e);
            }
            //Charge customer
            const resultStripeCharge = await charge(client.stripeCustomerId);
            // console.log("resultStripeCharge", resultStripeCharge);
            if (resultStripeCharge && resultStripeCharge.error) {
                try {
                    await deleteHeldSlots(props.bookingState.heldSlots);
                } catch (e) {
                    console.log("error while deleting held slots", e);
                }
                setPayClicked(false);
                setState({
                    ...state,
                    msgOpen: true,
                    snackMsg: resultStripeCharge.error.raw.message
                });
                props.setBookingCompleted({
                    success: false,
                    message: `Error while charging credit card. ${resultStripeCharge.error.raw.message}`
                });
                return;
            }
            if (
                (resultStripeCharge && !resultStripeCharge.charge) ||
                (resultStripeCharge &&
                    resultStripeCharge.charge &&
                    resultStripeCharge.charge.paid !== true)
            ) {
                try {
                    await deleteHeldSlots(props.bookingState.heldSlots);
                } catch (e) {
                    console.log("error while deleting held slots", e);
                }
                setPayClicked(false);
                setState({
                    ...state,
                    msgOpen: true,
                    snackMsg: "Unable to process your payment."
                });
                props.setBookingCompleted({
                    success: false,
                    message: "Unable to process your payment."
                });
                return;
            }
            setState({
                ...state,
                msgOpen: true,
                snackMsg: "Saving booking details"
            });
            var localBookingDetails = await handleOrderAndBookingCreation(
                props.bookingState,
                props
            );
            if (!!localBookingDetails) {
                setState({
                    ...state,
                    bookingDetails: localBookingDetails
                });
            }

            let input = {
                billingTransactionClientId: client.id,
                clientId: client.id,
                amount: Number.parseFloat(
                    Number.parseFloat(
                        props.bookingState.cbu.total
                            ? props.bookingState.cbu.total
                            : 0
                    ).toFixed(2)
                ),
                billingTransactionCreatorId:
                    props.bookingState.client.user.username,
                creatorId: props.bookingState.client.user.username,
                billingTransactionCompanyId: props.companyId,
                companyId: props.companyId,
                desc: `Payment for ${props.bookingState.serviceType.name}`,
                billingTransactionOrderId: localBookingDetails.order.id,
                orderId: localBookingDetails.order.id
            };

            const billingTransaction = await graphql(
                graphqlOperation(createBillingTransaction, { input })
            );

            if (!props.bookingState?.repeatingAppointment) {
                await updateHeldSlotsToConfirmed(props.bookingState.heldSlots);
            }
            setState({
                ...state,
                msgOpen: true,
                snackMsg: "Sending confirmation email"
            });
            const orderReceipt = await sendEmailBookingConfirmation(
                localBookingDetails.order.id
            );
            try {
                await sendProviderBookingConfirmation({
                    provider: props.bookingState.provider,
                    client: localBookingDetails.client,
                    company: props.company,
                    serviceType: props.bookingState.serviceType,
                    bookings: localBookingDetails.bookingsList,
                    orderNo: localBookingDetails.order.orderNo,
                    orderType: localBookingDetails.order.type,
                    orderNotes: localBookingDetails.order.clientnotes,
                    wdDisplay: props.bookingState.wdDisplay
                });
            } catch (e) {
                console.log("error sending provider notifications ", e);
            }
            //Create ClientCharge
            input = {
                clientId: client.id,
                clientChargeClientId: client.id,
                companyId: props.companyId,
                description: `Payment for ${props.bookingState.serviceType.name}`,
                status: "created",
                stripechargeid: resultStripeCharge.charge.id,
                stripepaymentmethod: resultStripeCharge.charge.payment_method,
                stripe_payment_method_details:
                    resultStripeCharge.charge.stripe_payment_method_details,
                balance_transaction:
                    resultStripeCharge.charge.balance_transaction,
                //stripe_refund_charge: resultStripeCharge.charge.id,
                stripe_status: resultStripeCharge.charge.status,
                clientChargeBillingtransactionId:
                    billingTransaction.data.createBillingTransaction.id,
                billingtransactionId:
                    billingTransaction.data.createBillingTransaction.id,
                amount: Number.parseFloat(
                    Number.parseFloat(
                        resultStripeCharge.charge.amount / 100
                    ).toFixed(2)
                ),
                amount_refunded: 0,
                currency: props.bookingState.currency,
                clientChargeOrderId: localBookingDetails.order.id,
                orderId: localBookingDetails.order.id
            };
            await graphql(graphqlOperation(createClientCharge, { input }));

            if (props.bookingState.boughtpackage) {
                const usedQuantity = props.bookingState.repeatingAppointment
                    ? props.bookingState.repeatingApptList.length + 1
                    : 1;
                //update clientpackage status to paid
                await updateClientPackageToPaid(
                    props.bookingState.newclientpkg,
                    usedQuantity
                );
            }
            if (props.bookingState.clientpackage) {
                await updateUsedQuantityOfPackage({
                    pkg: props.bookingState.clientpackage,
                    increaseby: props.bookingState.repeatingAppointment
                        ? props.bookingState.repeatingApptList.length + 1
                        : 1
                });
            }
            await updateOrderStatusToPaid(
                localBookingDetails.order.id,
                props.bookingState.cbu,
                orderReceipt
            );
            setState({
                ...state,
                msgOpen: true,
                snackMsg: "Order processing Successful"
            });
            //props.history.push("/booking-complete");
            props.setBookingCompleted({ success: true });
        } catch (error) {
            props.setBookingCompleted({
                success: false,
                message: "Unable to process your payment"
            });
            setPayClicked(false);
            setState({
                ...state,
                msgOpen: true,
                snackMsg: "Unable to process your payment."
            });
            console.error(
                "An error occurred processing the payment. The error was: ",
                error
            );
        }
    }
    const ccyFormat = (num) => {
        return `${Number.parseFloat(num ? num : 0).toFixed(2)}`;
    };
    const handleChange = ({ error }) => {
        if (error) {
            setState({ ...state, errorMessage: error.message });
        } else setState({ ...state, errorMessage: "" });
    };
    const handleCardChange = (name) => (event) => {
        setState({ ...state, [name]: event.target.checked });
    };

    const handleMsgClose = (event, reason) => {
        setState({ ...state, msgOpen: false });
    };

    async function sendEmailBookingConfirmation(orderId) {
        let ordreceipt = null;
        try {
            ordreceipt = await sendOrderReceipt(orderId);
        } catch (e) {
            console.log("error sedning notifications ", e);
        }
        return ordreceipt;
    }

    const { isNewClient } = props.bookingState;
    let hasStripeCustomerId = false;
    let hasCardExpired;
    try {
        hasStripeCustomerId = !!props.bookingState.client.stripeCustomerId;
        hasCardExpired = !!props.bookingState.cardExpired;
    } catch (err) {
        logger.error(
            "Error attempting to determine stripeCustomerId. Error was: " +
                JSON.stringify(err)
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={state.msgOpen}
                autoHideDuration={5000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{state.snackMsg}</span>}
            />
            <div style={{ padding: "20px" }}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    wrap="nowrap"
                >
                    <Grid item>
                        {(isNewClient ||
                            !hasStripeCustomerId ||
                            hasCardExpired) && (
                            <>
                                <Typography variant="body1">
                                    Please provide client's credit card details.{" "}
                                    {getCurrencySymbol(props.bookingState)}
                                    {ccyFormat(
                                        props.bookingState.cbu.total
                                    )}{" "}
                                    will be charged to client's credit card.
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                        >
                                            <b>Card Number</b>
                                        </Typography>
                                        <CardNumberElement
                                            {...createOptions()}
                                            onChange={handleChange}
                                        />
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                        >
                                            <b>Expiration date</b>
                                        </Typography>
                                        <CardExpiryElement
                                            {...createOptions()}
                                            onChange={handleChange}
                                        />
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                        >
                                            <b>CVC</b>
                                        </Typography>
                                        <CardCvcElement
                                            {...createOptions()}
                                            onChange={handleChange}
                                        />
                                        <div className="error" role="alert">
                                            {state.errorMessage}
                                        </div>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={10}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            style={{
                                                minHeight: "30px",
                                                maxHeight: "30px"
                                            }}
                                            startIcon={<CheckIcon />}
                                            color="primary"
                                            onClick={handleClick}
                                            disabled={payClicked}
                                        >
                                            {"PAY"}
                                            {payClicked && (
                                                <CircularProgress
                                                    size={18}
                                                    {...buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {!isNewClient &&
                            hasStripeCustomerId &&
                            !hasCardExpired && (
                                <>
                                    <Typography variant="body1">
                                        {getCurrencySymbol(props.bookingState)}
                                        {ccyFormat(
                                            props.bookingState.cbu.total
                                        )}{" "}
                                        will be charged to client's credit card
                                        with last four digits{" "}
                                        <b>
                                            {
                                                props.bookingState.client
                                                    .defaultpartialcc
                                            }
                                        </b>
                                        .
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.useDifferentCard}
                                                onChange={handleCardChange(
                                                    "useDifferentCard"
                                                )}
                                                value="useDifferentCard"
                                                color="primary"
                                            />
                                        }
                                        label="Client would like to pay using a different credit card "
                                    />{" "}
                                    {state.useDifferentCard && (
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                    >
                                                        <b>Card Number</b>
                                                    </Typography>
                                                    <CardNumberElement
                                                        {...createOptions()}
                                                        onChange={handleChange}
                                                    />
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                    >
                                                        <b> Expiration date</b>
                                                    </Typography>
                                                    <CardExpiryElement
                                                        {...createOptions()}
                                                        onChange={handleChange}
                                                    />
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                    >
                                                        <b>CVC</b>
                                                    </Typography>
                                                    <CardCvcElement
                                                        {...createOptions()}
                                                        onChange={handleChange}
                                                    />
                                                    <div
                                                        className="error"
                                                        role="alert"
                                                    >
                                                        {state.errorMessage}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            {" "}
                                            <div {...wrapper}>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        minHeight: "30px",
                                                        maxHeight: "30px"
                                                    }}
                                                    startIcon={<CheckIcon />}
                                                    color="primary"
                                                    disabled={payClicked}
                                                    onClick={handleClick}
                                                >
                                                    {"PAY"}
                                                    {payClicked && (
                                                        <CircularProgress
                                                            size={18}
                                                            {...buttonProgress}
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default StripeChargeCard;
