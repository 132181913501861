export const createPendingCompany = /* GraphQL */ `
  mutation CreatePendingCompany($input: CreatePendingCompanyInput!) {
    createPendingCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const updatePendingCompany = /* GraphQL */ `
  mutation UpdatePendingCompany($input: UpdatePendingCompanyInput!) {
    updatePendingCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const deletePendingCompany = /* GraphQL */ `
  mutation DeletePendingCompany($input: DeletePendingCompanyInput!) {
    deletePendingCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      username
      emailaddress
      registered
      firstname
      lastname
      homephone
      workphone
      mobilephone
      phonepref
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      role
      companyId
      providerId
      active
      deleted
      contactconsent
      contactconsentdatetime
      LoginInfo
      SalesInfo
      PNInfo
      lc_name
      lc_emailaddress
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        offersForeverAppt
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lastname
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        permalink
        AcceptanceRatios
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          offersForeverAppt
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      username
      emailaddress
      registered
      firstname
      lastname
      homephone
      workphone
      mobilephone
      phonepref
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      role
      companyId
      providerId
      active
      deleted
      contactconsent
      contactconsentdatetime
      LoginInfo
      SalesInfo
      PNInfo
      lc_name
      lc_emailaddress
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        offersForeverAppt
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lastname
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        permalink
        AcceptanceRatios
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          offersForeverAppt
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      offersForeverAppt
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      offersForeverAppt
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyPublicSite = /* GraphQL */ `
  mutation CreateCompanyPublicSite($input: CreateCompanyPublicSiteInput!) {
    createCompanyPublicSite(input: $input) {
      id
      companyId
      backgroundImageUrl
      heading1Text
      heading2Text
      textColor
      buttonColor
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        offersForeverAppt
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRefData = /* GraphQL */ `
  mutation CreateRefData($input: CreateRefDataInput!) {
    createRefData(input: $input) {
      refType
      refName
      refValue
      refInt
      overrideValue
      createdAt
      updatedAt
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      id
      firstname
      lc_fn
      lastname
      lc_ln
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      companyId
      numberofschedules
      active
      deleted
      ratingstarsavg
      numberofratings
      offersVirtualServices
      virtualMeetingUserId
      vmlink
      permalink
      AcceptanceRatios
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        createdAt
        updatedAt
      }
      locations {
        items {
          id
          providerId
          companyId
          locationpath
          name
          desc
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          maxtraveltype
          traveldistance
          traveldistanceunit
          travelregions
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      servicetypes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      skills {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
