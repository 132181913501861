import React, { useState } from "react";
import {
    TextField,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    Box,
    InputAdornment,
    IconButton
} from "@mui/material";
import { useEffect } from "react";
import { AccountCircle, Clear } from "@mui/icons-material";

const CustomAutocomplete = ({ options, onSelect, onInputChange, onClear }) => {
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(
        options ? options : []
    );
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        console.log("Options", options);
    }, [options]);
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        if (onInputChange) {
            onInputChange(inputValue);
        }

        setOpen(true);
    };

    const handleOptionSelect = (option) => {
        setInputValue(option.firstname + " " + option.lastname);
        setFilteredOptions([]);
        setOpen(false);

        setSelectedOption(option);

        if (onSelect) {
            onSelect(option.id);
        }
    };

    const handleInputBlur = () => {
        // Reset the input value to the currently selected option's firstname when the user clicks out of the TextField
        if (selectedOption) {
            setInputValue(selectedOption.firstname);
        } else {
            setInputValue(""); // Reset the input value if no option is selected
        }
    };

    const handleClearClick = () => {
        setInputValue(""); // Clear the input value
        setFilteredOptions([]); // Clear the filtered options
        setOpen(false);
        setSelectedOption(null); // Reset the selected option state

        if (onClear) {
            onClear(); // Fetch the original 50 results
        }
    };

    return (
        <div>
            <TextField
                sx={{ width: "350px", maxWidth: "350px" }}
                fullWidth
                label="Search"
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                InputProps={{
                    endAdornment: inputValue && inputValue.length > 0 && (
                        <InputAdornment position="end">
                            <IconButton onClick={handleClearClick}>
                                <Clear />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {open && options && options.length > 0 ? (
                <Paper
                    elevation={3}
                    style={{
                        position: "absolute",
                        zIndex: 500,
                        width: "350px",
                        maxHeight: "500px",
                        overflowY: "auto",
                        maxWidth: "350px"
                    }}
                >
                    <List>
                        {options.map((option) => (
                            <ListItem
                                sx={{ cursor: "pointer" }}
                                key={option.id}
                                onClick={() => handleOptionSelect(option)}
                            >
                                <ListItemText
                                    primary={
                                        <Box
                                            display={"flex"}
                                            alignItems={"flex-start"}
                                        >
                                            <AccountCircle sx={"sm"} />
                                            <Box
                                                ml={2}
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "rgba(0,0,0,0.8)"
                                                    }}
                                                >
                                                    {option.firstname +
                                                        " " +
                                                        option.lastname}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {option.emailaddress}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            ) : (
                options.length === 0 &&
                inputValue.length > 0 && (
                    <Paper
                        elevation={3}
                        style={{
                            position: "absolute",
                            zIndex: 500,
                            width: "350px",
                            height: "75px",
                            overflowY: "auto",
                            maxWidth: "350px"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "rgba(0,0,0,0.5)",
                                textAlign: "center",
                                margin: "16px auto"
                            }}
                        >
                            No client found
                        </Typography>
                    </Paper>
                )
            )}
        </div>
    );
};

export default CustomAutocomplete;
