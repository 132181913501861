import React from "react";
import { Grid } from "@mui/material";
import {
    graphql,
    graphqlOperation,
    postApi
} from "../../modules/AmplifyServices";
import { updateCompany } from "../../graphql/mutations";
import "./connect.css";
import { updateCompanyObjectInCachedUser } from "../../user/UserCommon";

function StripeConnect(props) {
    async function saveStateAndLoadStripe() {
        props.setShowLoading(true);
        try {
            localStorage.setItem("stripeConnectFlowAdminApp", "");
            const result = await postApi("stripeconnect", "/onboarduser", {
                body: {
                    fromApp: "adminapp",
                    stripeAccountId: props.stripeAccountId
                }
            });
            localStorage.setItem(
                "stripeConnectFlowAdminApp",
                JSON.stringify({
                    companyId: props.companyId,
                    timestamp: Date.now()
                })
            );
            if (!props.stripeAccountId)
                await saveStripeAccountId(result.accountId, props.companyId);
            props.setShowLoading(false);
            window.location = result.url;
        } catch (e) {
            console.log(e);
            props.setShowLoading(false);
        }
    }

    async function saveStripeAccountId(stripeAccountId, companyId) {
        try {
            let response = await graphql(
                graphqlOperation(updateCompany, {
                    input: {
                        id: companyId,
                        stripeAccount: stripeAccountId
                    }
                })
            );
            await updateCompanyObjectInCachedUser(
                response?.data?.updateCompany
            );
        } catch (e) {
            console.log("error", e);
        }
    }

    return (
        <Grid container spacing={1} style={{ justifyContent: "center" }}>
            <Grid item style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                {props.enabled && (
                    <a
                        href="#"
                        class="stripe-connect"
                        onClick={async (e) => {
                            e.preventDefault();
                            await saveStateAndLoadStripe();
                        }}
                    >
                        <span
                            style={{ verticalAlign: "-webkit-baseline-middle" }}
                        >
                            Connect
                        </span>
                    </a>
                )}
                {!props.enabled && !props.connected && (
                    <div class="stripe-connect-disabled">
                        <span
                            style={{ verticalAlign: "-webkit-baseline-middle" }}
                        >
                            Connect
                        </span>
                    </div>
                )}
                {props.connected && (
                    <div class="stripe-connect-connected">
                        <span
                            style={{ verticalAlign: "-webkit-baseline-middle" }}
                        >
                            Connected
                        </span>
                    </div>
                )}
            </Grid>
        </Grid>
    );
}

export default StripeConnect;
