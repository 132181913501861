import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Snackbar } from "@mui/material";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import IntegrationSetupPanel from "../utils/CommonComponents/IntegrationSetupPanel";
import IntegrationSetupCard from "../utils/CommonComponents/IntegrationSetupCard";
import {
    apiIntegrationsList,
    crmIntegrationsList,
    emailMarketingIntegrationsList,
    onlineFormsIntegrationsList,
    analyticsIntegrationsList
} from "../utils/CommonComponents/IntegrationsCardData";
import { useAtom } from "jotai";
import { mbxUserAtom, integrationRefDataAtom } from "../atoms/atoms";

function IntegrationForm(props) {
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    const [open, setOpen] = useState(false);
    const [panelText, setPanelText] = useState("");
    const [panelTitle, setPanelTitle] = useState("");
    const [templateIds, setTemplateIds] = useState("");
    const [setupSection, setSetupSection] = useState(null);
    const [noInstructions, setNoInstructions] = useState(false);
    const [instructionStepOne, setInstructionStepOne] = useState("");
    const [instructionStepTwo, setInstructionStepTwo] = useState("");
    const [integrationRefData, setIntegrationRefData] = useAtom(
        integrationRefDataAtom
    );
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);

    if (
        integrationRefData &&
        typeof integrationRefData === "object" &&
        Object.keys(integrationRefData).length === 0
    ) {
        checkCompanyIntegrationRefDataRecord();
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    async function checkCompanyIntegrationRefDataRecord() {
        try {
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `integration|${mbxUser.companyId}`,
                    refName: "data-integration"
                })
            );
            let refOverrideValue = {
                secretkey: "",
                hooks: {}
            };
            if (
                exists.data.getRefData &&
                exists.data.getRefData.overrideValue
            ) {
                //integration refData already exists for this companyId
                refOverrideValue = await JSON.parse(
                    exists.data.getRefData.overrideValue
                );
            } else {
                //integration refData for this companyId does not exist so we create one
                let key = handleGenerateSecretKey();
                refOverrideValue.secretkey = key;
                await createIntegrationRefDataRecord(key);
            }
            setIntegrationRefData(refOverrideValue);
        } catch (e) {
            console.log("error in getting ref data", e);
        }
    }

    function handleSetupClick(cardData) {
        setPanelText(cardData.panelText);
        setPanelTitle(cardData.title);
        setTemplateIds(cardData.templateIds ? cardData.templateIds : null);
        setSetupSection(cardData.setupSection ? cardData.setupSection : null);
        setNoInstructions(
            cardData.noInstructions ? cardData.noInstructions : false
        );
        setInstructionStepOne(
            cardData.instructionStepOne ? cardData.instructionStepOne : ""
        );
        setInstructionStepTwo(
            cardData.instructionStepTwo ? cardData.instructionStepTwo : ""
        );
        setOpen(!open);
    }

    function handlePanelOpenClose() {
        setOpen(!open);
    }

    function handleGenerateSecretKey() {
        let key = "";
        const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        for (let i = 0; i < 15; i++) {
            if (i % 5 === 0 && i !== 0) {
                key += "-";
            }
            const randomIndex = Math.floor(Math.random() * characters.length);
            key += characters[randomIndex];
        }
        return key;
    }

    async function createIntegrationRefDataRecord(key) {
        try {
            await graphql(
                graphqlOperation(mutations.createRefData, {
                    input: {
                        refType: `integration|${mbxUser.companyId}`,
                        refName: "data-integration",
                        refValue: "integration",
                        overrideValue: JSON.stringify({
                            secretkey: key,
                            hooks: {}
                        })
                    }
                })
            );
        } catch (e) {
            console.log("Error: Unable to create integration refData", e);
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />

            {/*Form Header*/}
            <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
                <Grid item xs>
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "0px",
                            fontSize: "24px",
                            fontWeight: 400
                        }}
                        noWrap
                    >
                        {"Integrations"}
                    </Typography>
                </Grid>
            </Grid>

            {/* 'API' Row */}
            <Typography
                sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    marginTop: "22px",
                    marginBottom: "12px",
                    fontFamily: "'Roboto','Lato','Raleway'"
                }}
            >
                {"API"}
            </Typography>
            <Grid container spacing={2}>
                {apiIntegrationsList.map((integration) => (
                    <Grid item xs={12} sm={6} md={4} key={integration.id}>
                        <IntegrationSetupCard
                            key={integration.id}
                            data={integration}
                            handleClick={handleSetupClick}
                            enabled={
                                integration.title === "Zapier" &&
                                integrationRefData?.hooks &&
                                Object.keys(integrationRefData.hooks).some(
                                    (key) => key.endsWith("zapier")
                                )
                            }
                        />
                    </Grid>
                ))}
            </Grid>

            {/* 'CRM' Row */}
            <Typography
                sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    marginTop: "32px",
                    marginBottom: "12px",
                    fontFamily: "'Roboto','Lato','Raleway'"
                }}
            >
                {"CRM"}
            </Typography>
            <Grid container spacing={2}>
                {crmIntegrationsList.map((integration) => (
                    <Grid item xs={12} sm={6} md={4} key={integration.id}>
                        <IntegrationSetupCard
                            key={integration.id}
                            data={integration}
                            handleClick={handleSetupClick}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* 'Analytics & Conversion Tracking' Row */}
            <Typography
                sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    marginTop: "32px",
                    marginBottom: "12px",
                    fontFamily: "'Roboto','Lato','Raleway'"
                }}
            >
                {"Analytics & Conversion Tracking"}
            </Typography>
            <Grid container spacing={2}>
                {analyticsIntegrationsList.map((integration) => (
                    <Grid item xs={12} sm={6} md={4} key={integration.id}>
                        <IntegrationSetupCard
                            key={integration.id}
                            data={integration}
                            handleClick={handleSetupClick}
                            enabled={
                                integration.title === "Google Tag Manager" &&
                                integrationRefData?.hooks?.[
                                    "google-tag-manager"
                                ] !== undefined &&
                                integrationRefData?.hooks?.[
                                    "google-tag-manager"
                                ].length !== 0
                            }
                        />
                    </Grid>
                ))}
            </Grid>

            {/* 'Email Marketing' Row */}
            <Typography
                sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    marginTop: "32px",
                    marginBottom: "12px",
                    fontFamily: "'Roboto','Lato','Raleway'"
                }}
            >
                {"Email Marketing"}
            </Typography>
            <Grid container spacing={2}>
                {emailMarketingIntegrationsList.map((integration) => (
                    <Grid item xs={12} sm={6} md={4} key={integration.id}>
                        <IntegrationSetupCard
                            key={integration.id}
                            data={integration}
                            handleClick={handleSetupClick}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* 'Online Forms' Row */}
            <Typography
                sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    marginTop: "32px",
                    marginBottom: "12px",
                    fontFamily: "'Roboto','Lato','Raleway'"
                }}
            >
                {"Online Forms"}
            </Typography>
            <Grid container spacing={2}>
                {onlineFormsIntegrationsList.map((integration) => (
                    <Grid item xs={12} sm={6} md={4} key={integration.id}>
                        <IntegrationSetupCard
                            key={integration.id}
                            data={integration}
                            handleClick={handleSetupClick}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Open integration panel when 'Setup' button is clicked */}
            <IntegrationSetupPanel
                open={open}
                panelTitle={panelTitle}
                panelText={panelText}
                handlePanelOpenClose={handlePanelOpenClose}
                handleClick={handleSetupClick}
                templateIds={templateIds}
                setupSection={setupSection}
                noInstructions={noInstructions}
                instructionStepOne={instructionStepOne}
                instructionStepTwo={instructionStepTwo}
            />
        </>
    );
}

export default IntegrationForm;
