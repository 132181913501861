import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../../../modules/AmplifyServices";
import { sendRegistrationEmail } from "./MessagingService";
import validator from "validator";
import voucher_codes from "voucher-code-generator";
import * as mutations from "../graphqlOperations/mutations";
import * as queries from "../graphqlOperations/queries";
import * as Sentry from "@sentry/react";
import { TIME_SUGGESTION_CONFIG_DEFAULTS } from "../../../utils/Constants";

async function createPendingCompany(state) {
    let input = {
        emailaddress: state.emailAddress,
        name: state.companyNameInput,
        contactname: state.firstNameInput + " " + state.lastNameInput,
        subdomain: state.desiredSubdomain + state.subdomainEnding.label,
        status: "PROFILE_STARTED"
    };
    let result;
    if (state.companyExists) {
        input = {
            ...input,
            id: state.companyId,
            status: "PROFILE_EDITED"
        };

        try {
            result = await graphql(
                graphqlOperation(mutations.updatePendingCompany, { input })
            );
        } catch (e) {
            console.log("asdf error", e);
        }
    } else {
        try {
            result = await graphql(
                graphqlOperation(mutations.createPendingCompany, { input })
            );
        } catch (e) {
            console.log("asdf error", e);
        }

        return result.data.createPendingCompany.id;
        //state.companyId = result.data.createPendingCompany.id;
        //Create User as company admin. Save info to state.
    }
    // Do not add user until official company is created.

    //_handleAddUser()

    //state.companyExists = true;
    return;
}

/***** Create Official Company *****/
async function convertPendingCompanyToRegular(state) {
    //Create regular company based off pendingCompany values. Supply Default values. Swap over to recognizing regular company.

    let input = {
        name: state.companyNameInput,
        contactname: state.firstNameInput + " " + state.lastNameInput,
        emailaddress: state.emailAddress,
        currency: "USD",
        subdomain: state.desiredSubdomain + state.subdomainEnding.label,
        publishableStripeKey: state.publishableStripeKey
            ? state.publishableStripeKey
            : null,
        cancelPolicyMinsBefore: 0,
        subscriptionLevel:
            state.planType === "basic" ? "BASIC" : "PROFESSIONAL",
        active: true,

        BccLists: JSON.stringify({
            forOrdSum: [],
            forWelcome: []
        }),
        DashboardInfo: JSON.stringify({
            orders_today: 0,
            orders_mtd: 0,
            orders_ytd: 0,
            sales_today: 0,
            sales_mtd: 0,
            sales_ytd: 0
        }),
        primaryColor: "#0087EE",
        noProviderMsg: "",
        logoUrl:
            "https://marketbox-prod-booknow.s3.amazonaws.com/public/company-assets/default_logo.png",
        iframeURL: "",
        tagline: "",
        ratingTipTextMsg: `Please take a moment to leave a rating for [provider-first-name] for your "[service]" session on [booking_short_date]. You may also leave a tip if you wish:\n\n[link]`,
        addressoneline: "",
        street: "",
        city: "",
        state: "",
        country: "",
        postalcode: "",
        longitude: null,
        latitude: null,
        currencyBasedOnLocation: state.planType === "basic" ? false : true,
        clientcanselectprovider: true,
        offersRemoteServices: true,
        offersOnPremiseServices: true,
        offersVirtualServices: false,
        providerMustAcceptAppt: false,
        useAnonymousPermalink: false,
        multipleServices: false,
        multipleQty: false,
        ApptAcceptanceFlowConfig: null,
        collectpayment: false,
        billingMode: null,
        addServiceFee: false,
        serviceFeeType: "PERCENTAGE",
        serviceFeeAmount: 0,
        taxrate: null,
        travelTime: 0,
        bookingIntervalMinutes: 0,
        countrycode3166alpha2: null,
        displayOnlyLogo: false,
        sendratingTipMsg: null,
        offersPackagesOnly: false,
        offersForeverAppt: false,
        singleApptOnly: false,
        virtualMeetingConfig: null,
        maskeddomain: null,
        replyemailaddress: "",
        homepage: null,
        clientnotesheading: null,
        hideRatingsFromClients: false,
        providerCanCancelAppt: true,
        displayProviderRatios: false,
        bookingIncrement: 15,
        setupCompleted: false,
        clientCanReschedule: false,
        providerCanReschedule: false
    };
    if (input.subscriptionLevel === "PROFESSIONAL") {
        input.SuggestionConfig = JSON.stringify(
            TIME_SUGGESTION_CONFIG_DEFAULTS
        );
    }
    let response;
    try {
        try {
            response = await graphql(
                graphqlOperation(mutations.createCompany, {
                    input
                })
            );
        } catch (e) {
            console.log("asdf failed to create company", e);
        }

        if (
            response &&
            response.data &&
            response.data.createCompany &&
            response.data.createCompany.id
        ) {
            //create refData for toDo list befor return
            await createTodoRefData(response.data.createCompany.id);
            return response.data.createCompany;
        } else {
            console.log("asdf error converting pending to company");
        }
    } catch (e) {
        console.log("asdf error creating company", e);
    }
    console.log("Company Created Response", response);
}

const createTodoRefData = async (id) => {
    let obj = {
        dismissTodo: false
    };
    let response;

    try {
        response = await graphql(
            graphqlOperation(mutations.createRefData, {
                input: {
                    refType: `company|${id}|to-do`,
                    refName: "show-todo",
                    refValue: "toDoListDisplay",
                    overrideValue: JSON.stringify(obj)
                }
            })
        );
        console.log("RefData Created");
    } catch (err) {
        console.log("Error while creating refData - dismissTodoHandler", err);
    }
};

async function createPublicSite(state) {
    let input = {
        companyId: state.companyId,
        companyPublicSiteCompanyId: state.companyId,
        backgroundImageUrl:
            "https://marketbox-prod-booknow.s3.amazonaws.com/public/company-assets/default_background.png",
        heading1Text: "Describe what your company offers",
        heading2Text: "Put a call to action or your company slogan",
        textColor: "#FFFFFF",
        buttonColor: "#0087EE"
    };
    console.log("asdf input for public site is", createPublicSite);
    const response = await graphql(
        graphqlOperation(mutations.createCompanyPublicSite, { input })
    );
    console.log("asdf response", response);

    try {
        await reSaveCompanyPublicSiteInfoForLandingPage(input, state);
    } catch (e) {
        console.log("saving company info to refdata failed", e);
    }
}

function genpassconf() {
    return voucher_codes.generate({
        length: 8,
        count: 1,
        charset: voucher_codes.charset("alphanumeric")
    })[0];
}

async function reSaveCompanyPublicSiteInfoForLandingPage(publicSite, state) {
    try {
        const result = await graphql(
            graphqlOperation(queries.getCompany, {
                id: state.companyId
            })
        );

        await graphql(
            graphqlOperation(mutations.createRefData, {
                input: {
                    refType: `domain|${result.data.getCompany.subdomain}`,
                    refName: "data-publicSite",
                    refValue: "publicSite",
                    overrideValue: JSON.stringify({
                        backgroundImageUrl: publicSite.backgroundImageUrl,
                        heading1Text: publicSite.heading1Text,
                        heading2Text: publicSite.heading2Text,
                        textColor: publicSite.textColor,
                        buttonColor: publicSite.buttonColor
                    })
                }
            })
        );
    } catch (e) {
        console.log("saving company public site info to refdata failed");
    }
}

/***** Creating User Functions *****/
async function _handleAddUser(providerId, state) {
    //Previous check should have verified that email is not in use for company or client

    //Plan: Create pending company.
    try {
        const temppas = genpassconf();
        state.temppas = temppas;

        let cleanPhone = state.phoneNumber.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-");
        state.cleanPhone = cleanPhone;

        const result = await postApi("changeuserrole", "/createuser", {
            body: {
                username: state.emailAddress,
                email: state.emailAddress,
                group: "CompanyAdminProvider",
                phone_number: cleanPhone,
                temppas,
                firstname: state.firstNameInput,
                lastname: state.lastNameInput
            }
        });
        console.log("asdf changeuserrole result", result);

        if (result && !result.error) {
            //Verify no admin with this email exists?

            // Add user to the User table
            await addUserToDb(result.newUserCognitoID, providerId);
            // Email to the user
            try {
                await sendRegistrationEmail(temppas, state);
            } catch (e) {
                console.log(
                    "asdf Registration Email Error = " + JSON.stringify(e)
                );
                /* setSnackMsg("Error sending registration email!"); */
                /* setMsgOpen(true); */
            }
            /* setSnackMsg("User has been created successfully.");
      setMsgOpen(true); */
        } else {
            console.log("asdf user changerole error");
            /* setSnackMsg(result.error.message);
      setMsgOpen(true); */
        }
    } catch (e) {
        /* setSnackMsg(
      "An unexpected error occured while creating your user account."
    );
    setMsgOpen(true); */
    }
}

async function addUserToDb(newUserCognitoID, providerId, state) {
    let registerUserInput = {
        id: newUserCognitoID,
        username: newUserCognitoID,
        emailaddress: state.emailAddress,
        firstname: state.firstNameInput,
        lastname: state.lastNameInput,
        registered: true,
        active: true,
        role: "COMPANY_ADMIN_PROVIDER",
        companyId: state.companyId,
        userCompanyId: state.companyId,
        mobilephone: state.cleanPhone,
        phonepref: "MOBILE",
        providerId: providerId,
        contactconsent: true,
        contactconsentdatetime: new Date().toISOString()
    };
    const newUser = await graphql(
        graphqlOperation(mutations.createUser, { input: registerUserInput })
    );
}

async function createCompanyAndPublicSite(state) {
    /* setLoading(true); */
    //Create official non-pending Company
    const company = await convertPendingCompanyToRegular(state);
    const companyId = company.id;
    state.companyId = companyId;
    //Create official non-pending company public site stuff.
    await createPublicSite(state);
    //const providerId = await createProvider(companyId);
    //Create / Add User to DB
    //await _handleAddUser(providerId);

    //Login User
    // await loginUser();
    /* state.done = true;
    state.companyId = companyId; */
    /*  setLoading(false); */

    return companyId;
}

async function createScheduleIndex(companyId) {
    try {
        const result = await getJsonApi("searchapi", `/index/${companyId}`);
        if (result.success && !result.exists) {
            const indexresult = await postApi("searchapi", "/index", {
                body: {
                    companyId
                }
            });
        }
    } catch (e) {
        console.log("Error while creating index", e);
    }
}

const createProvider = async (companyId, state) => {
    let cleanPhone = state.phoneNumber.replace(/\s/g, ""); // strip spaces
    cleanPhone = validator.blacklist(cleanPhone, "()-");
    const input = {
        firstname: state.firstNameInput,
        lastname: state.lastNameInput,
        emailaddress: state.emailAddress,
        phone: cleanPhone,
        active: true,
        deleted: false,
        providerCompanyId: companyId,
        companyId
    };
    let newProvider = await graphql(
        graphqlOperation(mutations.createProvider, { input })
    );
    return newProvider?.data?.createProvider?.id;
};

async function updateReactivatedCompany(
    companyId,
    companyNameInput,
    firstNameInput,
    lastNameInput,
    emailAddress,
    planType
) {
    try {
        await graphql(
            graphqlOperation(mutations.updateCompany, {
                input: {
                    id: companyId,
                    active: true,
                    name: companyNameInput,
                    contactname: firstNameInput + " " + lastNameInput,
                    emailaddress: emailAddress,
                    subscriptionLevel:
                        planType === "basic" ? "BASIC" : "PROFESSIONAL"
                }
            })
        );
    } catch (e) {
        console.log("Error: Unable to update company data", e);
        Sentry.captureException(e);
    }
}

export {
    createPendingCompany,
    convertPendingCompanyToRegular,
    createPublicSite,
    createScheduleIndex,
    reSaveCompanyPublicSiteInfoForLandingPage,
    _handleAddUser,
    addUserToDb,
    createCompanyAndPublicSite,
    createProvider,
    updateReactivatedCompany
};
