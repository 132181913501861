import React, { useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Box, Button, Paper, Grid, FormControl, Typography, Snackbar, InputLabel, Select, MenuItem, Switch, FormControlLabel, InputAdornment, CircularProgress } from "@mui/material";
// prettier-ignore
//import { DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { getCurrencySymbol } from "../utils";
import { serviceTypeByCompanyCustom } from "../queries/ProviderFormQueries";
import { auditPackagesCreate, auditPackagesUpdate } from "../modules/Audit";
import { getUserFromCache } from "../user/UserCommon";
import { useNavigate } from "react-router-dom";

export default function PackageAddEditForm(props) {
    const navigate = useNavigate();
    const { state, dispatch, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //fields
    const [description, setDescription] = React.useState("");
    const [quantity, setQuantity] = React.useState("");
    const [price, setPrice] = React.useState("");
    const [discount, setDiscount] = useState("");
    const [createdAt, setCreatedAt] = React.useState();
    const [serviceTypeData, setServiceTypeData] = useState();
    const [packageType, setPackageType] = useState("");
    const [oldInfo, setOldInfo] = useState([]);
    const [active, setActive] = React.useState(true);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    //type
    const [values, setValues] = React.useState({
        type: ""
    });
    const inputLabel = React.useRef(null);
    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    console.log(loggedInUser, "UserObj");
    // tracks if fields are blurred in order to generate description
    const [isBlurred, setisBlurred] = useState({
        price: true,
        discount: true,
        quantity: true
    });

    const [disableSave, setDisableSave] = useState(false);

    const fetchServiceTypes = async () => {
        const loggedInUser = getUserFromCache();

        const filter = {
            and: [
                { active: { ne: false } },
                { deleted: { ne: true } },
                { isVisible: { ne: false } }
            ]
        };

        let serviceTypes = await graphql(
            graphqlOperation(serviceTypeByCompanyCustom, {
                companyId: loggedInUser.company.id,
                filter
            })
        );
        setServiceTypeData(serviceTypes.data.serviceTypeByCompany.items);
    };

    useEffect(() => {
        //setServiceTypeData(Cache.getItem("servicetypes"));
        fetchServiceTypes();

        //setLabelWidth(inputLabel.current.offsetWidth);
        if (props.mode === "Edit" || state.mode === "View") {
            getPackageData();
        }
    }, []);

    async function getPackageData() {
        const packageId = state.id;
        if (!!packageId) {
            const input = { id: packageId };
            const result = await graphql(
                graphqlOperation(queries.getPackage, input)
            );
            console.log("getPackageData", result);

            if (!result.data.getPackage) {
                navigate("..");
            } else {
                if (!(!!result.errors && result.errors.length > 0)) {
                    const {
                        price,
                        discount,
                        servicetype,
                        packagetype,
                        active,
                        createdAt,
                        desc,
                        quantity
                    } = result.data.getPackage;
                    console.log("result", result.data.getPackage);
                    setPrice(price);
                    setDiscount(discount);
                    setPackageType(packagetype);
                    setActive(active);
                    setCreatedAt(createdAt);
                    setDescription(desc);
                    setValues((oldValues) => ({
                        ...oldValues,
                        type: servicetype.id
                    }));
                    setQuantity(quantity);
    
                    oldInfo.push({
                        servicetype: servicetype.name,
                        packagetype: packagetype,
                        price: price,
                        discount: discount,
                        quantity: quantity,
                        desc: desc,
                        active: active
                    });
                    console.log("what is old", oldInfo);
                }
            }  
        } else {
            navigate("..");
        }
    }

    // cancel and go back to PromotionForm
    function handleCancelPackageForm() {
        navigate("..");
    }

    async function savePackage() {
        try {
            let input = {
                packageCompanyId: loggedInUser && loggedInUser.company.id,
                companyId: loggedInUser && loggedInUser.company.id,
                price: price === "" ? 0 : price,
                discount: discount === "" ? 0 : discount,
                packageServicetypeId: values.type,
                servicetypeId: values.type,
                desc: description,
                active: active,
                quantity: quantity,
                packagetype: packageType
            };
            let response;
            if (props.mode === "Edit") {
                input.id = state.id;
                input.createdAt = createdAt;
                response = await graphql(
                    graphqlOperation(mutations.updatePackage, { input })
                );

                await auditPackagesUpdate(
                    loggedInUser,
                    response.data.updatePackage,
                    newInfo
                );
            } else {
                input.createdAt = new Date();
                response = await graphql(
                    graphqlOperation(mutations.createPackage, { input })
                );

                await auditPackagesCreate(
                    loggedInUser,
                    response.data.createPackage
                );
                // console.log("just a test", response.data.createPackage);
            }

            if (response && response.data) {
                setSnackMsg("Package saved successfully.");
                navigate("..");
                setMsgOpen(true);
            } else {
                setSnackMsg("Unable to save the package.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error(
                "An error occurred saving the package. The error was: "
            );
            console.error(error);
            setDisableSave(false);
        }
    }

    let newInfo;
    async function handleSavePackage() {
        setDisableSave(true);
        let serviceName;
        newInfo = [
            {
                servicetype: "No Change",
                packagetype: "No Change",
                price: "No Change",
                discount: "No Change",
                quantity: "No Change",
                desc: "No Change",
                active: "No Change"
            }
        ];

        // validate
        if (values.type === 0 || values.type === "") {
            setSnackMsg("Please specify a service type");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        } else {
            serviceName = serviceTypeData.filter((item) => {
                return values.type === item.id;
            });
        }

        if (!packageType) {
            setSnackMsg("Please select a package type.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (showPackageType() && (price === "" || price === null)) {
            setSnackMsg("Please enter a price for the package.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (!showPackageType() && (discount === "" || discount === null)) {
            setSnackMsg("Please enter a discount for the package.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (quantity === "" || quantity == 0) {
            setSnackMsg("Please enter a quantity for the package.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (description === "") {
            setSnackMsg("Please enter a description for the package.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        // check to see if any info was updated
        if (props.mode === "Edit") {
            if (oldInfo[0].servicetype != serviceName[0].name) {
                newInfo.map((item) => {
                    item.servicetype = serviceName[0].name;
                });
            }

            if (oldInfo[0].packagetype != packageType) {
                newInfo.map((item) => {
                    item.packagetype = packageType;
                });
            }

            if (oldInfo[0].price != price) {
                newInfo.map((item) => {
                    item.price = price;
                });
            }

            if (oldInfo[0].discount != discount) {
                newInfo.map((item) => {
                    item.discount = discount;
                });
            }

            if (oldInfo[0].quantity != quantity) {
                newInfo.map((item) => {
                    item.quantity = quantity;
                });
            }

            if (oldInfo[0].desc != description) {
                newInfo.map((item) => {
                    item.desc = description;
                });
            }

            if (oldInfo[0].active != active) {
                newInfo.map((item) => {
                    item.active = active;
                });
            }
        }

        console.log("just a test", newInfo);

        savePackage();
    }

    function showPackageType() {
        if (packageType === "DOLLAR") return true;
        else if (packageType === "PERCENTAGE") return false;
    }

    useEffect(() => {
        if (!packageType && price > 0) {
            // for packages defined prior to introduction of packagetype - default to fixed price
            setPackageType("DOLLAR");
        }
    });

    useEffect(() => {
        // if all required fields are blurred and there is no description, then generate description
        if (
            !description &&
            isBlurred.quantity &&
            ((packageType === "DOLLAR" && isBlurred.price) ||
                (packageType === "PERCENTAGE" && isBlurred.discount))
        ) {
            generateDescription();
        }
    }, [isBlurred]);

    function generateDescription() {
        let value = showPackageType() ? price : discount;
        let session = quantity == 1 ? "SESSION" : "SESSIONS";
        // if all required values are not null, set the new description
        if (value !== "" && quantity !== "" && quantity != 0) {
            setDescription(
                showPackageType()
                    ? `${quantity} ${session} AT ${getCurrencySymbol()}${(
                          price / quantity
                      ).toFixed(2)} PER SESSION`
                    : `${quantity} ${session} AT [price] PER SESSION - SAVE ${discount}%!`
            );
        }
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    function reset() {
        // reset fields
        setPackageType("");
        setDescription("");
        setPrice("");
        setDiscount("");
        setQuantity("");
        setDescription("");
    }
    // handle desc change
    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };
    // handle price change
    const handleChangePrice = (e) => {
        if (e.target.value >= 0) {
            setDiscount("");
            setPrice(e.target.value);
        }
    };
    // handle quantity change
    const handleChangeQuantity = (e) => {
        if (e.target.value >= 0) {
            setQuantity(e.target.value);
        }
    };
    // handle discount change
    const handleChangeDiscount = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setPrice("");
            setDiscount(e.target.value);
        }
    };
    // handle select change for package
    function handleTypeChange(event) {
        setValues((oldValues) => ({
            ...oldValues,
            [event.target.name]: event.target.value
        }));
    }
    // handle select change for package type
    function handleChangePackageType(event) {
        setPackageType(event.target.value);
    }

    const handleFocus = (e) => {
        if (e.target.id === "price") {
            setisBlurred({ ...isBlurred, price: false });
        } else if (e.target.id === "discount") {
            setisBlurred({ ...isBlurred, discount: false });
        } else if (e.target.id === "quantity") {
            setisBlurred({ ...isBlurred, quantity: false });
        }
    };

    const handleBlur = (e) => {
        if (e.target.id === "price") {
            setisBlurred({ ...isBlurred, price: true });
        } else if (e.target.id === "discount") {
            setisBlurred({ ...isBlurred, discount: true });
        } else if (e.target.id === "quantity") {
            setisBlurred({ ...isBlurred, quantity: true });
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel
                                ref={inputLabel}
                                id="select-serivce-type"
                            >
                                Service Type
                            </InputLabel>
                            <Select
                                value={values.type}
                                onChange={handleTypeChange}
                                name="type"
                                id="select-serivce-type"
                                label="Service Type"
                            >
                                <MenuItem value={0}>
                                    <em>None</em>
                                </MenuItem>
                                {serviceTypeData &&
                                    serviceTypeData.length > 0 &&
                                    serviceTypeData.map((item, i) => {
                                        return (
                                            <MenuItem value={item.id} key={i}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel
                                ref={inputLabel}
                                id="select-package-type"
                            >
                                Package Type
                            </InputLabel>
                            <Select
                                value={packageType}
                                onChange={handleChangePackageType}
                                id="select-package-type"
                                label="Package Type"
                            >
                                <MenuItem value={"DOLLAR"}>Dollar</MenuItem>
                                <MenuItem value={"PERCENTAGE"}>
                                    Percentage
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {packageType && (
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                className={classes.formControl}
                            >
                                {showPackageType() && (
                                    <TextField
                                        id="price"
                                        label="Price"
                                        type="number"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {getCurrencySymbol()}
                                                </InputAdornment>
                                            )
                                        }}
                                        step="0.01"
                                        min="0"
                                        className={classes.textField}
                                        value={price}
                                        onChange={handleChangePrice}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                )}
                                {!showPackageType() && (
                                    <TextField
                                        id="discount"
                                        label="Discount (%)"
                                        type="number"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            )
                                        }}
                                        step="0.01"
                                        min="0"
                                        className={classes.textField}
                                        value={discount}
                                        onChange={handleChangeDiscount}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="quantity"
                                label="Quantity"
                                type="number"
                                className={classes.textField}
                                value={quantity}
                                onChange={handleChangeQuantity}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start"
                    direction="row"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="description"
                                label="Description"
                                className={classes.textField}
                                value={description}
                                multiline
                                rows="4"
                                onChange={handleChangeDescription}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={generateDescription}
                                disabled={
                                    state.mode === "View" ||
                                    !packageType ||
                                    !quantity ||
                                    quantity == 0 ||
                                    (showPackageType() && price === "") ||
                                    (!showPackageType() && discount === "")
                                }
                                size="small"
                            >
                                Set Default Description
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormControlLabel
                            margin="normal"
                            className={classesnew.marginBottom}
                            control={
                                <Switch
                                    checked={active}
                                    onChange={(event) =>
                                        setActive(event.target.checked)
                                    }
                                    value="active"
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={5}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSavePackage}
                                disabled={state.mode === "View" || disableSave}
                            >
                                Save
                                {disableSave && (
                                    <CircularProgress
                                        size={24}
                                        className={classesnew.buttonProgress}
                                    />
                                )}
                            </Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelPackageForm}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
