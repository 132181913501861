import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import chroma from "chroma-js";

export const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "block",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "32px",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        },
        grow: {
            flexGrow: 1
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    paper: {
        padding: 2
    },
    root: {
        padding: theme.spacing(3, 2),
        boxShadow: "none"
    },
    blockedTimeSpacing: {
        padding: theme.spacing(1)
    },
    fullwidth: {
        width: "100%",
        marginBottom: "10px !important"
    },
    selectr: {
        color: "#fff",
        "&:before": {
            borderBottom: "1px solid #fff !important"
        }
    },
    customlabel: {
        color: "#fff !important"
    },
    tagsInput: {
        marginTop: "26px !important"
    },
    helpertitle: {
        marginBottom: "20px"
    },
    iconPadding: {
        paddingTop: "30px !important"
    },
    menuItem: {
        whiteSpace: "pre-wrap",
        padding: "5px"
    },
    appBarShadow: {
        boxShadow: "none"
    },
    navbarButton: {
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: "24px",
        background: chroma(theme.palette.primary.main).darken(2).hex(),
        "&:hover": {
            background: chroma(theme.palette.primary.main).darken(2).hex()
        }
    },
    noHoverEffect: {
        "&:hover": {
            background: "none",
            cursor: "not-allowed"
        }
    },
    tableCellLabel: {
        width: "100%"
    }
}));
