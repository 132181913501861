import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  paper: {
    padding: 2
  },
  root: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: "32px",
    }
  },
  fullWidth: {
    width: "100%"
  },
  Tabsheader: {
    boxShadow: "none",
    background: "transparent"
  },
  marginBottom: {
    marginBottom: "20px"
  },
  dateField: {
    border: "1px solid #ccc",
    borderRadius: "4px"
  },
  tabsunderline: {
    borderBottom: "1px solid #ccc"
  },
  flexDisplay: {
    display: "flex",
    alignItems: "center"
  },
  actionsCellWidth: {
    width: "150px"
  },
  selectr: {
    color: '#fff',
    "&:before": {
      borderBottom: '1px solid #fff !important'
    }

  },

}));
