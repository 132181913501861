import React, { useState, useEffect } from "react";
// prettier-ignore
import { TextField, Tooltip, Grid, Button, Typography, Snackbar, Backdrop, CircularProgress, FormControl, InputLabel, InputAdornment, Select, MenuItem, Alert } from "@mui/material";
// prettier-ignore
import { useStyles } from "../styles/MainNavbarStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { StoreContext } from "../context/StoreContext";
import axios from "axios";
import { AddressEntryTextBox } from "../components/address/AddressEntryTextBox";
import { CloseRounded } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import validator from "validator";
import { prepareLocationPath } from "../utils";
import { getTimezoneIdByGeoLoc } from "../modules/TimeService";
import { auditCompanyLocCreate, auditCompanyLocUpdate } from "../modules/Audit";
import { useTheme } from "@mui/styles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    getUserFromCache,
    updateCompanyObjectInCachedUser
} from "../user/UserCommon";
import { auditCompanyUpdate } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { useNavigate } from "react-router-dom";

const autocompleteService = { current: null };

const CompanyLocationAddEditForm = (props) => {
    const navigate = useNavigate();
    const { state, dispatch, actions } = React.useContext(StoreContext);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    const [addressOneLine, setAddressOneLine] = useState("");
    const [addressStreet, SetStreet] = useState("");
    const [addressCity, SetCity] = useState("");
    const [addressState, SetState] = useState("");
    const [postalCode, SetPostalCode] = useState("");
    //const [company, setCompany] = useState("");
    const [companyId, SetCompanyID] = useState(state.id);
    const [phone, setPhone] = useState("");
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    const [location, SetLocation] = useState("");
    const [locDescription, SetLocDescription] = useState("");
    const [longitude, SetLongitude] = useState("");
    const [latitude, SetLatitude] = useState("");
    const [country, SetCountry] = useState("");
    const [locationId, SetLocationId] = useState("");
    const [locationpath, setLocationPath] = useState();
    const [checked, SetChecked] = useState(false);
    const [createdAt, SetCreatedAt] = useState();
    const [updatedAt, SetUpdatedAt] = useState("");
    const [virtual, setVirtual] = useState(false);
    const [isEdit, setisEdit] = React.useState(false);
    const [addressDialogOpen, setAddressDialogOpen] = React.useState(false);
    const [coords, SetCoords] = useState({});
    const [oldInfo, setOldInfo] = useState([]);
    const [disableSave, setDisableSave] = useState(false);
    const [disableSaveAndAdd, setDisableSaveAndAdd] = useState(false);

    const [locationDropDown, setLocationDropDown] = useState(
        props.path === "DashboardForm" ? "client-location" : "company-location"
    );

    const APP_ID_HERE = process.env.REACT_APP_GEOCODER_APP_ID;
    const APP_CODE_HERE = process.env.REACT_APP_GEOCODER_CODE;
    const loggedInUser = getUserFromCache();
    const company = loggedInUser.company;
    const logger = new ConsoleLogger("CompanyLocationAddEditForm");
    const [countryTypeId, setCountryTypeId] = useState();
    const [countryTypeData, setCountryTypeData] = useState([
        { id: "CA", name: "Canada" },
        { id: "CA", name: "Can" },
        { id: "US", name: "USA" },
        { id: "US", name: "United States" },
        { id: "GB", name: "United Kingdom" },
        { id: "GB", name: "UK" },
        { id: "GB", name: "England" },
        { id: "JM", name: "Jamaica" },
        { id: "AU", name: "Australia" }
    ]);
    const [isloading, setIsloading] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        console.log("state", state);
        //setLabelWidth(inputLabel.current.offsetWidth);
        if (props.mode === "Edit" || state.mode === "View") {
            getCompanyLocationData();
        } else {
            setIsloading(false);
        }
    }, []);

    /*  useEffect(() => {
        if (getPath()) {
            console.log(
                "Props Company Data - CompanyLocationAddEditForm",
                props.companyDataDashboard
            );
        }
    }, [props.companyDataDashboard, props.compId]); */

    /* useEffect(() => {
        console.log("Address One Line", addressOneLine);
    }, [addressOneLine]); */

    const getPath = () => {
        if (props.path === "DashboardForm") {
            return true;
        }
        return false;
    };

    async function getCompanyLocationData() {
        setIsloading(true);
        console.log("what is it", state.contextCompanyId);
        console.log("Logged In user | getCompanyLocationData", loggedInUser);
        const companyLocationId = state.id;
        if (!!companyLocationId) {
            console.log("Company ID", companyLocationId, !!companyLocationId);
            setisEdit(true);
            const input = { id: companyLocationId };
            const result = await graphql(
                graphqlOperation(queries.getCompanyLocation, input)
            );
            console.log("getCompanyData", result);

            if (!result.data.getCompanyLocation) {
                navigate("..");
            }

            if (result?.data?.getCompanyLocation) {
                if (!(!!result.errors && result.errors.length > 0)) {
                    const {
                        addressoneline,
                        street,
                        city,
                        state,
                        country,
                        postalcode,
                        longitude,
                        latitude,
                        company,
                        companyId,
                        locationdesc,
                        locationname,
                        phone,
                        createdAt,
                        virtual,
                        locationpath
                    } = result.data.getCompanyLocation;
                    console.log("*** result", result.data.getCompanyLocation);
                    setAddressOneLine(addressoneline);
                    SetStreet(street);
                    SetCity(city);
                    SetState(result.data.getCompanyLocation.state);
                    SetPostalCode(postalcode);
                    SetCountry(country);
                    SetLocDescription(locationdesc);
                    SetLocation(locationname);
                    setPhone(phone);
                    SetLongitude(longitude);
                    SetLatitude(latitude);
                    SetCompanyID(companyId);
                    //setCompany(company);
                    SetCreatedAt(createdAt);
                    // SetLocationId("");
                    SetChecked(true);
                    setVirtual(virtual);
                    setLocationPath(locationpath);
                    // SetCoords({});
                    for (let x = 0; x < countryTypeData.length; x++) {
                        if (countryTypeData[x].name === country) {
                            const id = countryTypeData[x].id;
                            setCountryTypeId(id);
                            console.log("handleChangeCountryType id = " + id);
                        }
                    }
                    oldInfo.push({
                        locationName: locationname,
                        locationDesc: locationdesc,
                        address: addressoneline,
                        phone: phone,
                        virtual: virtual
                    });
                }
            } else {
                setisEdit(false);
            }
        } else {
            navigate("..");
        }
        setIsloading(false);
    }

    function getCountryCode() {
        let countryCode = "CA";
        for (let x = 0; x < countryTypeData.length; x++) {
            if (countryTypeData[x].name === country) {
                countryCode = countryTypeData[x].id;
            }
        }
        return countryCode;
    }

    const fetchServiceLocationsData = async () => {
        // const loggedInUser = getUserFromCache();
        const companyId = loggedInUser.companyId;
        if (loggedInUser.id) {
            let compLocations;
            console.log(
                "CompanyLocAddEdit - Logged In User exists ",
                loggedInUser
            );
            try {
                const filter = {
                    and: [{ active: { ne: false } }, { deleted: { ne: true } }]
                };
                compLocations = await execReadByPK({
                    opname: "companyLocationByCompany",
                    op: queries.companyLocationByCompany,
                    id: {
                        companyId: companyId
                    },
                    filter: filter
                });
                console.log(
                    "CompanyLocAddEdit - Company Locations",
                    compLocations
                );
            } catch (err) {
                console.log(
                    "CompanyLocAddEdit - Error in getting company locations"
                );
            }

            return compLocations;
        } else {
            console.log(
                "Dashboard Form - Logged In User Does not exist yet",
                loggedInUser
            );
        }
    };
    async function addCompanyLocation(val) {
        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
            tz = await getTimezoneIdByGeoLoc({
                lat: latitude,
                lng: longitude
            });
        } catch (err) {
            console.log(
                "could not get time zone from geo loc - addEditCompanyLocation"
            );
        }
        try {
            let input = {
                locationname: location,
                phone: phone,
                addressoneline: addressOneLine,
                street: addressStreet,
                city: addressCity,
                state: addressState,
                country: country,
                countrycode3166alpha2: countryTypeId
                    ? countryTypeId
                    : getCountryCode(),
                postalcode: postalCode,
                longitude: longitude,
                latitude: latitude,
                companyId: getPath()
                    ? loggedInUser.companyId
                    : state.contextCompanyId,
                locationdesc: locDescription,
                companyLocationCompanyId: getPath()
                    ? loggedInUser.companyId
                    : state.contextCompanyId,
                virtual,
                locationpath: prepareLocationPath(
                    countryTypeId,
                    addressState,
                    addressCity,
                    postalCode
                ),
                timezone: tz
            };
            let response;
            logger.debug(" input = " + JSON.stringify(input));
            logger.debug(input);
            if (isEdit) {
                input.id = state.id;
                input.createdAt = createdAt;
                response = await graphql(
                    graphqlOperation(mutations.updateCompanyLocation, { input })
                );

                newInfo = Object.fromEntries(
                    Object.entries(newInfo[0]).filter(
                        ([_, v]) => v !== "No Change"
                    )
                );

                await auditCompanyLocUpdate(
                    loggedInUser,
                    response.data.updateCompanyLocation,
                    [newInfo]
                );
            } else {
                input.createdAt = new Date();
                response = await graphql(
                    graphqlOperation(mutations.createCompanyLocation, { input })
                );

                await auditCompanyLocCreate(
                    loggedInUser,
                    response.data.createCompanyLocation
                );
            }
            let compLocations;

            if (
                (props.path
                    ? props.path === "DashboardForm"
                        ? true
                        : false
                    : false) ||
                response?.data?.createCompanyLocation?.id
            ) {
                console.log(
                    "I am in if block of compLocations",
                    props,
                    compLocations
                );
                try {
                    const companyResponse = await graphql(
                        graphqlOperation(queries.getCompany, {
                            id: getPath()
                                ? loggedInUser.companyId
                                : state.contextCompanyId
                        })
                    );
                    const company = companyResponse.data.getCompany;
                    console.log("companyread", companyResponse);
                    if (company) {
                        if (
                            !company.addressoneline &&
                            !company.countrycode3166alpha2 &&
                            !company.state
                        ) {
                            //company does not have address info, so populate it from company location
                            let countrycode3166alpha2 = countryTypeId
                                ? countryTypeId
                                : getCountryCode();
                            let companyInput = {
                                addressoneline: addressOneLine,
                                street: addressStreet,
                                city: addressCity,
                                state: addressState,
                                country: country,
                                countrycode3166alpha2:
                                    countrycode3166alpha2.toLowerCase(),
                                postalcode: postalCode,
                                longitude: longitude,
                                latitude: latitude,
                                id: getPath()
                                    ? loggedInUser.companyId
                                    : state.contextCompanyId
                            };
                            let companyUpdateResponse = await graphql(
                                graphqlOperation(mutations.updateCompany, {
                                    input: { ...companyInput }
                                })
                            );
                            console.log(
                                "companyUpdateResponse",
                                companyUpdateResponse
                            );
                        }
                    }
                } catch (err) {
                    console.log("error while updating company address.", err);
                }
            } else {
                console.log(
                    "I am in else block of compLocations and here is response",
                    response
                );
            }

            //resetForm();
            if (val === "add" || getPath()) {
                if (
                    response && response.data && isEdit
                        ? response.data.updateCompanyLocation
                        : response.data.createCompanyLocation
                ) {
                    setSnackMsg("Company location successfully saved.");
                    setMsgOpen(true);
                    // reset fields
                    resetForm();
                    props.doneTodoHandler("serviceLoc");
                } else {
                    setSnackMsg("Unable to save company location.");
                    setMsgOpen(true);
                }
            } else {
                if (
                    response && response.data && isEdit
                        ? response.data.updateCompanyLocation
                        : response.data.createCompanyLocation
                ) {
                    navigate("..");
                    actions.setId(state.contextCompanyId);
                    setSnackMsg("Company location successfully saved.");
                    setMsgOpen(true);
                    // reset fields
                } else {
                    setSnackMsg("Unable to save company location.");
                    setMsgOpen(true);
                }
            }
            /* if (
                response && response.data && isEdit
                    ? response.data.updateCompanyLocation
                    : response.data.createCompanyLocation
            ) {
                actions.setPage("CompanyLocationForm");
                actions.setId(state.contextCompanyId);
                setSnackMsg("Company location successfully saved.");
                setMsgOpen(true);
                // reset fields
            } else {
                setSnackMsg("Unable to save company location.");
                setMsgOpen(true);
            } */
        } catch (error) {
            console.error("Add Company location error => ", error);
        }
    }

    function handleAddressFocus() {
        if (!addressDialogOpen) {
            setAddressDialogOpen(true);
        }
    }
    function handleAddressDialogClose() {
        setAddressDialogOpen(false);
    }
    function handleAddressDialogSave(
        addressOneLine,
        street,
        city,
        state,
        postalCode,
        country,
        lat,
        lon
    ) {
        setAddressDialogOpen(false);
        setAddressOneLine(addressOneLine ? addressOneLine : null);
        SetStreet(street ? street : null);
        SetCity(city ? city : null);
        SetState(state ? state : null);
        SetPostalCode(postalCode ? postalCode : null);
        SetCountry(country);
        SetLongitude(lon);
        SetLatitude(lat);
    }

    const handlePhoneChange = (value) => {
        console.log(value);
        setPhone(value);
    };

    function validatePhone(phone, type) {
        let cleanPhone = phone.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-"); // strip brackets or dash
        if (validator.isMobilePhone(cleanPhone)) {
            return cleanPhone;
        } else {
            setSnackMsg("Please specifiy a valid " + type + " phone number.");
            setMsgOpen(true);
            return null;
        }
    }

    async function reSaveCompanyInfoForLandingPage(company) {
        try {
            let companyInfo = {};

            for (let prop in company) {
                if (
                    "DashboardInfo" !== prop &&
                    "createdAt" !== prop &&
                    "updatedAt" !== prop
                )
                    companyInfo[prop] = company[prop];
            }
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `domain|${company.subdomain}`,
                    refName: "data-company"
                })
            );
            // console.log("currency", result);
            let refVal = exists.data.getRefData
                ? exists.data.getRefData.refValue
                : null;
            if (refVal)
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
            else
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
        } catch (e) {
            console.log("saving company info to refdata failed");
        }
    }
    const clientLocationHandler = async (val) => {
        let newInfo = [
            {
                tipMsg: "No Change",
                textMsg: "No Change",
                clientMsg: "No Change",
                ordSum: "No Change",
                WelcomeList: "No Change"
            }
        ];
        let tabValue = 2;
        let input = {
            id: loggedInUser.companyId,
            offersOnPremiseServices: true
        }; //= { ...props.companyDataDashboard };

        try {
            if (val === "off") {
                input.offersOnPremiseServices = false;
                //input.offersVirtualServices = false;
            }

            if (val === "on") {
                input.offersOnPremiseServices = true;
                //input.offersVirtualServices = false;
            }

            let compID = loggedInUser.companyId;
            console.log("Company ID Check", compID);
            let response = await graphql(
                graphqlOperation(mutations.updateCompany, {
                    input
                })
            );
            console.log("Response from saving company settings", response);
            await updateCompanyObjectInCachedUser(
                response?.data?.updateCompany
            );

            await auditCompanyUpdate(
                loggedInUser,
                response.data.updateCompany,
                newInfo,
                tabValue
            );

            if (response) {
                await reSaveCompanyInfoForLandingPage(
                    response.data.updateCompany
                );
                props.doneTodoHandler("serviceLoc");
                setDisableSave(false);
                // return true;
                // props.loadingOFF();
                // props.close();
                //setLoading(false);
                // props.close();
            }
        } catch (err) {
            console.log("Client Location -error", err);
        }
    };

    let newInfo;
    async function handleSaveCompany(val) {
        setDisableSave(true);
        if (val === "client-location") {
            await clientLocationHandler("off");
            if (getPath()) {
                props.closeServiceLocationPortal();
            }
            return;
        } else {
            if (getPath()) {
                await clientLocationHandler("on");
            }
        }
        console.log("This never runs");
        newInfo = [
            {
                locationName: "No Change",
                locationDesc: "No Change",
                address: "No Change",
                phone: "No Change",
                virtual: "No Change"
            }
        ];
        // validate

        if (location === "") {
            setSnackMsg("Please enter a value for location name.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (addressOneLine === "") {
            setSnackMsg("Please enter a value for address.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        // validate any changes, that is if the newInfo = oldInfo
        if (isEdit) {
            if (location != oldInfo[0].locationName) {
                newInfo.map((item) => {
                    item.locationName = location;
                });
            }
            if (locDescription != oldInfo[0].locationDesc) {
                newInfo.map((item) => {
                    item.locationDesc = locDescription;
                });
            }
            if (addressOneLine != oldInfo[0].address) {
                newInfo.map((item) => {
                    item.address = addressOneLine;
                });
            }
            if (phone != oldInfo[0].phone) {
                newInfo.map((item) => {
                    item.phone = phone;
                });
            }
            if (virtual != oldInfo[0].virtual) {
                newInfo.map((item) => {
                    item.virtual = virtual;
                });
            }
        }

        //if (!!phone) {
        //  if (!validatePhone(phone, "Phone")) {
        //    return;
        //  }
        //} else {
        //  setSnackMsg("Please enter a value for phone.");
        //  setMsgOpen(true);
        //  return;
        // }

        /*  if (!!phone && !!locDescription && !!addressOneLine && !!location) {
            setDisableSave(true);
            await addCompanyLocation();
            setDisableSave(false);
        } */
        if (!!addressOneLine && !!location) {
            /*  if(val === 'add'){

            } */
            setDisableSave(true);
            await addCompanyLocation(val);
            setDisableSave(false);
            if (val === "single" && getPath()) {
                props.closeServiceLocationPortal();
            }
        }
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    function resetForm() {
        setAddressOneLine("");
        SetStreet("");
        SetCity("");
        SetState("");
        SetPostalCode("");
        SetCountry("");
        SetLocationId("");
        SetChecked(false);
        SetCoords({});
        SetLocation("");
        setVirtual(false);
        setLocationDropDown("company-location");
    }

    function onQuery(evt) {
        const query = evt.target.value;
        setAddressOneLine(query);
        SetLocation(query);
        console.log("query = " + query);

        if (query && query.length > 5) {
            axios
                .get(
                    "https://autocomplete.geocoder.api.here.com/6.2/suggest.json",
                    {
                        params: {
                            app_id: APP_ID_HERE,
                            app_code: APP_CODE_HERE,
                            query: query,
                            maxresults: 1
                        }
                    }
                )
                .then(function (response) {
                    if (response.data.suggestions.length > 0) {
                        const id = response.data.suggestions[0].locationId;
                        const address = response.data.suggestions[0].address;
                        console.log("address = ");
                        console.log(address);
                        if (!!address.houseNumber) {
                            SetStreet(
                                `${
                                    address.houseNumber && address.houseNumber
                                } ${address.street && address.street}`
                            );
                        } else {
                            SetStreet(address.street);
                        }
                        SetCity(address.city);
                        SetState(address.state);
                        SetPostalCode(address.postalCode);
                        SetCountry(address.country);
                        SetLocationId(id);
                    } else {
                        //resetAddress();
                    }
                });
        } else {
            // clear lookup fields
            SetStreet("");
            SetCity("");
            SetState("");
            SetPostalCode("");
            SetCountry("");
            SetChecked(false);
        }
    }

    function handleAddressCheck(evt) {
        let params = {
            app_id: APP_ID_HERE,
            app_code: APP_CODE_HERE
        };

        if (locationId.length > 0) {
            params["locationId"] = locationId;
        } else {
            params["searchtext"] =
                addressStreet +
                addressCity +
                addressState +
                postalCode +
                country;
        }

        axios
            .get("https://geocoder.api.here.com/6.2/geocode.json", {
                params: params
            })
            .then(function (response) {
                const view = response.data.Response.View;
                if (view.length > 0 && view[0].Result.length > 0) {
                    const location = view[0].Result[0].Location;

                    // update values
                    SetChecked(true);
                    SetLocationId("");
                    setAddressOneLine(location.Address.Label);
                    SetStreet(
                        `${
                            location.Address.HouseNumber &&
                            location.Address.HouseNumber
                        } ${location.Address.Street && location.Address.Street}`
                    );

                    SetCity(location.Address.City);
                    SetState(location.Address.State);
                    SetPostalCode(location.Address.PostalCode);
                    SetCountry(location.Address.Country);
                    SetCoords({
                        lat: location.DisplayPosition.Latitude,
                        lon: location.DisplayPosition.Longitude
                    });
                    SetLongitude(location.DisplayPosition.Longitude);
                    SetLatitude(location.DisplayPosition.Latitude);
                } else {
                    SetChecked(false);
                    SetCoords({});
                }
            })
            .catch(function (error) {
                console.log("caught failed query");
                SetChecked(false);
                SetCoords({});
            });
    }

    if (isloading) {
        return (
            <Backdrop
                style={{
                    zIndex: theme.zIndex.drawer + 1,
                    color: "#fff"
                }}
                open={true}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <>
            {disableSave && (
                <Backdrop
                    sx={{ zIndex: theme.zIndex.drawer + 10, color: "#fff" }}
                    open={disableSave}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {getPath() && (
                    <>
                        <Typography
                            className={classes.title}
                            variant="h4"
                            noWrap
                        >
                            Set up your service locations
                        </Typography>
                        <CloseRounded
                            sx={{
                                width: "2rem",
                                color: "rgba(0,0,0,0.5)",
                                fontWeight: 700,
                                cursor: "pointer"
                            }}
                            onClick={props.closeServiceLocationPortal}
                        />
                    </>
                )}
            </div>

            <Grid
                container
                item
                xs={getPath() ? 12 : 5}
                flexDirection={"column"}
                sx={{ gap: "1rem", marginTop: "1rem" }}
            >
                {props.path === "DashboardForm" && (
                    <Typography>
                        Where do you primarily service clients?
                    </Typography>
                )}
                <FormControl
                    sx={{ "& label": { color: "primary.main" } }}
                    variant="filled"
                >
                    <InputLabel id="location-select">
                        Service location
                    </InputLabel>
                    <Select
                        disableUnderline
                        sx={{
                            backgroundColor: "white",
                            border: "2px solid #d4d4d4",
                            borderRadius: "4px",
                            "&:hover": {
                                backgroundColor: "white"
                            },

                            "&.Mui-focused": {
                                backgroundColor: "white",
                                "& .MuiSelect-select": {
                                    backgroundColor: "white"
                                }
                            }
                        }}
                        labelId="location-select"
                        id="location-select-id"
                        value={locationDropDown}
                        onChange={(e) => {
                            if (e.target.value === "virtual") {
                                setVirtual(true);
                            } else {
                                setVirtual(false);
                            }
                            setLocationDropDown(e.target.value);
                        }}
                    >
                        {props.path === "DashboardForm" && (
                            <MenuItem value={"client-location"}>
                                At client's location
                            </MenuItem>
                        )}
                        <MenuItem value={"company-location"}>
                            {props.path === "DashboardForm"
                                ? " At your 'in-store' location"
                                : "Business location"}
                        </MenuItem>
                        <MenuItem value={"virtual"}>Online</MenuItem>
                    </Select>
                </FormControl>

                {locationDropDown === "company-location" &&
                    props.path === "DashboardForm" && (
                        <Alert
                            severity="info"
                            sx={{
                                "& .MuiAlert-icon": {
                                    color: "primary.main",
                                    display: "none"
                                },
                                "& .MuiAlert-message": {
                                    fontFamily: "Roboto"
                                },
                                fontFamily: "Roboto",
                                backgroundColor: "primary.light",
                                color: "rgba(0,0,0,0.8)"
                            }}
                        >
                            Note: To add additional locations go to Admin{" > "}{" "}
                            Locations.
                        </Alert>
                    )}

                {/* locationDropDown === "client-location" &&
                    props.path === "DashboardForm" && (
                        <Alert
                            severity="info"
                            sx={{
                                "& .MuiAlert-icon": {
                                    color: "primary.main",
                                    display: "none"
                                },
                                "& .MuiAlert-message": {
                                    fontFamily: "Roboto"
                                },
                                fontFamily: "Roboto",
                                backgroundColor: "primary.light",
                                color: "rgba(0,0,0,0.8)"
                            }}
                        >
                            Travel zones will be set up by each Service
                            Provider. To turn on additional location types go to
                            Company Settings {" > "} Configuration.
                        </Alert>
                    ) */}
                {locationDropDown === "virtual" &&
                    props.path === "DashboardForm" && (
                        <Alert
                            severity="info"
                            sx={{
                                "& .MuiAlert-icon": {
                                    color: "primary.main",
                                    display: "none"
                                },
                                "& .MuiAlert-message": {
                                    fontFamily: "Roboto"
                                },
                                fontFamily: "Roboto",
                                backgroundColor: "primary.light",
                                color: "rgba(0,0,0,0.8)"
                            }}
                        >
                            Note: To add additional locations go to Admin{" > "}{" "}
                            Locations.
                        </Alert>
                    )}
            </Grid>
            {(locationDropDown == "company-location" ||
                locationDropDown == "virtual") && (
                <Grid
                    container
                    sx={{ marginTop: "1.5rem", gap: "1rem" }}
                    flexDirection={"column"}
                >
                    <Typography>
                        Enter information about this location
                    </Typography>
                    <Grid item xs={getPath() ? 12 : 5}>
                        <TextField
                            sx={{
                                backgroundColor: "white",
                                border: "2px solid #d4d4d4",
                                borderRadius: "4px",
                                "&:hover": { backgroundColor: "white" },
                                "& .MuiFilledInput-root": {
                                    "&:hover": { backgroundColor: "white" },
                                    backgroundColor: "white",
                                    "&.Mui-focused": {
                                        backgroundColor: "white"
                                    }
                                }
                            }}
                            label="Location name"
                            //id="{props.id}"
                            type={"text"}
                            fullWidth
                            value={location}
                            onChange={({ target: { value } }) => {
                                SetLocation(value);
                            }}
                            placeholder="Location name"
                            className={classes.fullwidth}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title="Location name will be publicly displayed on your booking page for clients to see"
                                            placement="bottom"
                                            style={{
                                                color: "grey",
                                                fontSize: "20px"
                                            }}
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            )}

            {/*  <Grid container sx={{ marginTop: "1" }}>
                <Grid item xs={6}>
                    <TextField
                        label="Location Description"
                        //id="{props.id}"
                        fullWidth
                        value={locDescription}
                        onChange={({ target: { value } }) => {
                            SetLocDescription(value);
                        }}
                        placeholder="Description"
                        multiline
                        rows="4"
                        className={classes.fullwidth}
                    />
                </Grid>
            </Grid> */}

            {(locationDropDown == "company-location" ||
                locationDropDown == "virtual") && (
                <Grid container>
                    <Grid item xs={getPath() ? 12 : 5}>
                        <AddressEntryTextBox
                            setAddress={handleAddressDialogSave}
                            autocompleteService={autocompleteService}
                            addressOneLine={addressOneLine}
                            marginAbove={true}
                            label={"Location address"}
                            fromDashboardInStore={
                                locationDropDown == "company-location"
                                    ? true
                                    : ""
                            }
                            fromDashboardOnline={
                                locationDropDown == "virtual" ? true : ""
                            }
                        />
                    </Grid>
                </Grid>
            )}
            {/* <Grid container sx={{ marginTop: "0.75rem" }}>
                <Grid item xs={6}>
                    <FormControl>
                        <MuiPhoneNumber
                            variant="outlined"
                            id="phoneinput"
                            label="Phone"
                            inputExtraProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true
                            }}
                            defaultCountry={
                                company && company.countrycode3166alpha2
                                    ? company.countrycode3166alpha2
                                    : "ca"
                            }
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                    </FormControl>
                </Grid>
            </Grid> */}
            {/*  <Grid container>
                <Grid item xs={4}>
                    <Tooltip title="A physical address is still required for virtual locations. It will not be displayed to the public. ">
                        <FormControlLabel
                            margin="normal"
                            //className={classesnew.marginBottom}
                            control={
                                <Switch
                                    checked={virtual}
                                    onChange={(event) =>
                                        setVirtual(event.target.checked)
                                    }
                                    value="virtual"
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "primary checkbox"
                                    }}
                                />
                            }
                            label="Virtual Location"
                        />
                    </Tooltip>
                </Grid>
            </Grid> */}
            <Grid container sx={{ marginTop: "1.5rem" }}>
                <Grid
                    container
                    sx={{ gap: "1rem" }}
                    item
                    xs={getPath() ? 12 : 5}
                >
                    {/* <Button
              variant="contained"
              color="primary"
              onClick={handleAddressCheck}
            >
              Validate
            </Button>
            &nbsp;&nbsp; */}
                    {locationDropDown === "client-location" ? (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={disableSave}
                            onClick={() => handleSaveCompany("client-location")}
                        >
                            Save & Next
                            {/* disableSave && (
                        <CircularProgress
                            size={24}
                            className={classesnew.buttonProgress}
                        />
                    ) */}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={disableSave}
                            onClick={() => handleSaveCompany("single")}
                        >
                            {props.path === "DashboardForm"
                                ? "Save & Next"
                                : "Save"}
                            {/* disableSave && (
                            <CircularProgress
                                size={24}
                                className={classesnew.buttonProgress}
                            />
                        ) */}
                        </Button>
                    )}

                    {false && locationDropDown !== "client-location" && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                backgroundColor: "white",
                                border: "2px solid #d4d4d4",
                                color: "rgba(0,0,0,0.75)",
                                boxShadow: "none",
                                "&:hover": {
                                    backgroundColor: "white",
                                    boxShadow: "none"
                                }
                            }}
                            disabled={disableSave}
                            onClick={() => handleSaveCompany("add")}
                        >
                            Save & Add Another
                            {/* disableSave && (
                            <CircularProgress
                                size={24}
                                className={classesnew.buttonProgress}
                            />
                        ) */}
                        </Button>
                    )}

                    {!getPath() && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (props.path === "DashboardForm") {
                                    props.closeServiceLocationPortal();
                                } else {
                                    navigate("..");
                                    actions.setId(state.contextCompanyId);
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
export default CompanyLocationAddEditForm;
