import React, { useContext, useEffect, useState } from "react";
import { ConsoleLogger } from "aws-amplify/utils";
import {
    graphql,
    graphqlOperation,
    postApi,
    putApi,
    getJsonApi
} from "../modules/AmplifyServices";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Grid, Typography, FormControl, TextField, Button, Tooltip, Box, Snackbar } from "@mui/material";
import Rating from "@mui/material/Rating";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { auditRatingsCreate, auditRatingsUpdate } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { providerRatingsByProvider } from "../queries/CustomQueries";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserFromCache } from "../user/UserCommon";

const labels = {
    1: "Very Bad",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent"
};

const logger = new ConsoleLogger("ProviderReview");

function IconContainer(props) {
    const { value, ...other } = props;
    return (
        <Tooltip title={labels[value] || ""}>
            <div {...other} />
        </Tooltip>
    );
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired
};

const useStyles = makeStyles({
    rating1: {
        width: 200,
        display: "flex",
        alignItems: "center"
    }
});

function ProviderReview(props) {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = React.useState(-1);
    const [review, setReview] = useState("");
    const [providerId, setProviderId] = useState(0);
    const [provider, setProvider] = useState();
    const [providerName, setProviderName] = useState("");
    const [loggedInUser, setLoggedInUser] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [id, setId] = useState(0);
    const [saveClicked, setSaveClicked] = useState(false);
    const [oldInfo, setOldInfo] = useState([]);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const { state, dispatch, actions } = useContext(StoreContext);
    const [snackMsg, setSnackMsg] = useState();

    const handleChangeReview = (e) => {
        setReview(e.target.value);
    };

    // load provider data if in edit mode
    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        getProviderData();

        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    }, []);

    useEffect(() => {
        setIsEdit(state.mode === "Edit");
        if (state.mode === "Edit") {
            getRatingData();
        }
    }, []);

    async function getProviderData() {
        try {
            // get user data from cache
            const authuser = getUserFromCache();
            setLoggedInUser(authuser);

            let providerId = state.provider.id;
            // testing
            if (!providerId) {
                logger.debug("*** WARNING: PROVIDER ID PASSED IS NULL!!!");
            }
            const input = { id: providerId };

            const result = await graphql(
                graphqlOperation(queries.getProvider, input)
            );
            const providerData = result.data.getProvider;
            logger.debug("result for providerData = ");
            logger.debug(providerData);

            // populate form state
            if (providerData) {
                setProviderId(providerData.id);
                setProviderName(
                    providerData.firstname + " " + providerData.lastname
                );
                setProvider(providerData);
            }
        } catch (err) {
            logger.error(`Error fetching provider `, err);
        }
    }

    async function getRatingData() {
        try {
            // getting data about the selected rating
            const ratingId = state.id;
            const input = { id: ratingId };

            const result = await graphql(
                graphqlOperation(queries.getProviderRatings, input)
            );

            const ratingData = result.data.getProviderRatings;
            if (ratingData) {
                setId(ratingData.id);
                setRating(ratingData.ratingstars);
                setReview(ratingData.ratingtext);
                // console.log("ratingData", ratingData);

                oldInfo.push({
                    rating: ratingData.ratingstars,
                    review: ratingData.ratingtext
                });

                console.log("what is it", oldInfo);
            }
        } catch (err) {
            console.error(`Error fetching rating `, err);
        }
    }

    function reset() {
        // clear fields
        setId(0);
        setRating(0);
        setReview("");
    }

    let newInfo;
    function handleSaveReview() {
        setSaveClicked(true);
        if (isEdit) {
            newInfo = [
                {
                    oldRating: oldInfo[0].rating,
                    oldReview:
                        oldInfo[0].review === ""
                            ? "No Previous Review"
                            : oldInfo[0].review,
                    rating: "No Change",
                    review: "No Change"
                }
            ];

            if (rating != oldInfo[0].rating) {
                newInfo.map((item) => {
                    item.rating = rating;
                });
            }

            if (review != oldInfo[0].review && review !== "") {
                newInfo.map((item) => {
                    item.review = review;
                });
            } else if (review != oldInfo[0].review && review === "") {
                newInfo.map((item) => {
                    item.review = "None";
                });
            }

            console.log("newInfo", newInfo);
        }
        // save review data
        saveReviewData({});
        //reset();
        setSnackMsg("Review successfully saved.");
        setMsgOpen(true);
        // allow the user time to read success message
        setTimeout(() => {
            reset();
            actions.setId(state.provider.id);
            console.log(state.provider.id, "ProviderReviewForm");
            actions.setPage("ProviderReviewForm");
        }, 1500);
    }

    function saveReviewData() {
        async function saveGraphQLService() {
            let input = {
                providerRatingsProviderId: providerId,
                providerId: providerId,
                ratingstars: rating,
                ratingtext: review,
                active: true,
                ratinguserId: loggedInUser.id,
                companyId: loggedInUser.companyId
            };
            logger.debug("input:" + input);
            if (isEdit) {
                input.id = id;
                const updateReview = await graphql(
                    graphqlOperation(mutations.updateProviderRatings, { input })
                );
                console.log("updateReview", updateReview);

                if (
                    newInfo[0].rating != "No Change" ||
                    newInfo[0].review != "No Change"
                ) {
                    await auditRatingsUpdate(
                        loggedInUser,
                        updateReview.data.updateProviderRatings,
                        newInfo
                    );
                }
            } else {
                const newReview = await graphql(
                    graphqlOperation(mutations.createProviderRatings, { input })
                );
                logger.debug("New review created = ");
                logger.debug(newReview);

                await auditRatingsCreate(
                    loggedInUser,
                    newReview.data.createProviderRatings
                );
            }
            // now recalcuate average stars
            const filter = {
                and: [
                    { active: { eq: true } },
                    { providerId: { eq: providerId } }
                ]
            };
            const limit = process.env.REACT_APP_LISTLIMIT;

            const result = await execReadByPK({
                opname: "providerRatingsByProvider",
                op: providerRatingsByProvider,
                id: { providerId: providerId },
                filter: {
                    active: { eq: true }
                },
                sortDirection: "DESC"
            });

            let totalstars = 0,
                avgstars = 0;

            for (let i = 0; i < result.items.length; i++) {
                totalstars += result.items[i].ratingstars;
            }

            avgstars = totalstars / result.items.length;

            const numberofratings = !!provider.numberofratings
                ? isEdit
                    ? provider.numberofratings
                    : provider.numberofratings + 1
                : 1;
            console.log(
                numberofratings,
                provider.numberofratings,
                "numberofratings"
            );
            // save avgstars
            input = {
                id: providerId,
                ratingstarsavg: avgstars,
                numberofratings,
                firstname: provider.firstname,
                lastname: provider.lastname,
                createdAt: provider.createdAt
            };

            const newProvider = await graphql(
                graphqlOperation(mutations.updateProvider, { input })
            );

            logger.debug("newProvider = ");
            logger.debug(newProvider);
        }

        try {
            saveGraphQLService();
        } catch (err) {
            logger.error(`Error saving review data`, err);
            setSaveClicked(false);
        }
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    function handleCancel() {
        // cancel and go back to providerform
        reset();
        actions.setId(state.provider.id);
        console.log(state.provider.id, "ProviderReviewForm");
        actions.setPage("ProviderReviewForm");
        // actions.setPage("ProviderForm");
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography className={classes.title} variant="h4" noWrap>
                Create a Provider Review for {providerName}
            </Typography>
            <p />
            <div>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Overall Rating</Typography>
                    <div className={classes.rating1}>
                        <Rating
                            name="hover-side"
                            value={rating}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                        />
                        <Box ml={2}>
                            {labels[hover !== -1 ? hover : rating]}
                        </Box>
                    </div>
                </Box>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <FormControl
                        margin="normal"
                        required
                        fullWidth
                        className={classes.formControl}
                    >
                        <TextField
                            id="bio"
                            label="Provider Review"
                            multiline
                            rows="10"
                            defaultValue=""
                            className={classes.textField}
                            value={review}
                            onChange={handleChangeReview}
                            margin="normal"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Box component="span" m={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveReview}
                            disabled={saveClicked || !providerName}
                        >
                            Save
                            {saveClicked && (
                                <CircularProgress
                                    size={24}
                                    className={classesnew.buttonProgress}
                                />
                            )}
                        </Button>
                    </Box>
                    <Box component="span" m={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ProviderReview;
