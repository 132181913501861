export const clientChargeByCompanyForBilling = /* GraphQL */ `
    query ClientChargeByCompany(
        $companyId: String
        $createdAtId: ModelClientChargeByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelClientChargeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientChargeByCompany(
            companyId: $companyId
            createdAtId: $createdAtId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                clientId
                companyId
                orderId
                billingtransactionId
                description
                status
                stripechargeid
                stripepaymentmethod
                stripe_payment_method_details
                stripe_refund_charge
                stripe_status
                amount
                amount_refunded
                balance_transaction
                currency
                createdAt
                updatedAt
                client {
                    id
                    userId
                    currency
                    accountbalance
                    stripeCustomerId
                    companyId
                    defaultpartialcc
                    ClientInfo
                    createdAt
                    updatedAt
                    user {
                        id
                        emailaddress
                        firstname
                        lastname
                        mobilephone
                        providerId
                    }
                }
                order {
                    id
                    desc
                    type
                    orderNo
                    companyId
                    clientId
                    providerId
                    currency
                    subtotal
                    servicechargeamt
                    taxamt
                    total
                    providerPayAmt
                    status
                    orderSummary
                    taxrate
                    bookingAddress
                    legaltermsAcceptedAt
                    clientnotes
                    createdAt
                    updatedAt
                }
                billingtransaction {
                    id
                    orderId
                    addservicetypeid
                    amount
                    creatorId
                    clientId
                    companyId
                    desc
                    credit
                    approved
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
