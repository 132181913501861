import React from "react";
import { StoreContext } from "../context/StoreContext";
import {
    TextField,
    Box,
    Button,
    Paper,
    Grid,
    FormControl,
    Typography,
    Snackbar
} from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { getUserFromCache } from "../user/UserCommon";

export default function CustomerAddEditForm() {
    const { state, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //fields
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [, setCustomerid] = React.useState("");
    const [AccountBalance, setAccountBalance] = React.useState("");
    const [emailaddress, setEmailAddress] = React.useState("");
    const [currency, setCurrency] = React.useState("");
    const [isEdit, setisEdit] = React.useState(false);
    const [, setCreatedAt] = React.useState("");
    const [, setUpdatedAt] = React.useState("");
    const [, setCustomerUseridId] = React.useState("");
    const [customerId, setCustomerId] = React.useState("");
    const classes = useStyles();

    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    // if(!!loggedInUser){
    //     setCustomerUseridId(loggedInUser.id)
    // }
    React.useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth);
        if (state.mode === "Edit" || state.mode === "View") {
            _getClientData();
        }
    }, []);
    const _getClientData = async () => {
        const customerId = state.id;
        if (!!customerId) {
            setisEdit(true);
            const input = { id: customerId };
            const result = await graphql(
                graphqlOperation(queries.getClient, input)
            );
            console.log("getClient", result);
            if (!(!!result.errors && result.errors.length > 0)) {
                const {
                    firstname,
                    lastname,
                    emailaddress,
                    currency,
                    createdAt,
                    updatedAt,
                    accountbalance,
                    customerid,
                    user
                } = result.data.getClient;
                console.log("result", result.data.getClient);
                setFirstName(firstname);
                setLastName(lastname);
                setEmailAddress(emailaddress);
                setCurrency(currency);
                setAccountBalance(accountbalance);
                setCustomerid(customerid);
                setCustomerUseridId(user);
                setCreatedAt(createdAt);
                setUpdatedAt(updatedAt);
            }
        }
    };

    // cancel and go back to PromotionForm
    function _handleCustomerForm() {
        actions.setPage("CustomerForm");
    }

    async function _addCustomer() {
        try {
            let input = {
                firstname: firstName,
                lastname: lastName,
                currency: currency,
                customerUseridId: loggedInUser.id,
                accountbalance: AccountBalance,
                customerid: customerId
            };
            let response;
            if (isEdit) {
                input.id = state.id;
                response = await graphql(
                    graphqlOperation(mutations.updateClient, { input })
                );
            } else {
                response = await graphql(
                    graphqlOperation(mutations.createClient, { input })
                );
            }

            reset();
            if (
                response && response.data && isEdit
                    ? response.data.updateClient
                    : response.data.createClient
            ) {
                actions.setPage("CustomerForm");
                setSnackMsg("Customer successfully saved.");
                setMsgOpen(true);
                // reset fields
            } else {
                setSnackMsg("Something went wrong, Please try again later.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error("Add Customer error => ", error);
        }
    }

    async function _handleSaveCustomer() {
        // validate
        if (firstName === "") {
            setSnackMsg("Please enter a value for first name.");
            setMsgOpen(true);
            return;
        }
        if (lastName === "") {
            setSnackMsg("Please enter a value for lastname.");
            setMsgOpen(true);
            return;
        }
        if (emailaddress === "") {
            setSnackMsg("Please enter a value for email address.");
            setMsgOpen(true);
            return;
        }
        if (AccountBalance === "") {
            setSnackMsg("Please enter a value for address.");
            setMsgOpen(true);
            return;
        }
        if (!!firstName && !!lastName && !!AccountBalance && !!currency) {
            _addCustomer();
        }
    }
    function handleMsgClose() {
        setMsgOpen(false);
    }
    function reset() {
        // reset fields
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setAccountBalance("");
        setCurrency("");
    }
    // handle name change
    const _handleChangeFirstName = (e) => {
        setFirstName(e.target.value);
    };
    // handle lastname change
    const _handleChangeLastName = (e) => {
        setLastName(e.target.value);
    };
    // handle currency change
    const _handleChangeCurrency = (e) => {
        setCurrency(e.target.value);
    };

    // handle EmailAddress change
    const _handleChangeEmailAddress = (e) => {
        setEmailAddress(e.target.value);
    };

    // handle EmailAddress change
    const _handleChangeAccountBalance = (e) => {
        setAccountBalance(e.target.value);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography className={classes.title} variant="h4" noWrap>
                Customer \ {state.mode}
            </Typography>

            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="firstname"
                                label="First Name"
                                className={classes.textField}
                                value={firstName}
                                inputProps={{ tabIndex: "1" }}
                                onChange={_handleChangeFirstName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="lastname"
                                label="Last Name"
                                className={classes.textField}
                                value={lastName}
                                inputProps={{ tabIndex: "2" }}
                                onChange={_handleChangeLastName}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="email"
                                label="Email Address"
                                className={classes.textField}
                                value={emailaddress}
                                inputProps={{ tabIndex: "1" }}
                                onChange={_handleChangeEmailAddress}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="currency"
                                label="Currency"
                                className={classes.textField}
                                value={currency}
                                inputProps={{ tabIndex: "2" }}
                                onChange={_handleChangeCurrency}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="customerid"
                                label="Customer Id"
                                className={classes.textField}
                                value={customerId}
                                inputProps={{ tabIndex: "1" }}
                                onChange={({ target: { value } }) =>
                                    setCustomerId(value)
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="userId"
                                label="User Id"
                                disabled
                                className={classes.textField}
                                value={loggedInUser.id}
                                inputProps={{ tabIndex: "2" }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="accountbalance"
                                label="Account Balance"
                                className={classes.textField}
                                type="number"
                                value={AccountBalance}
                                inputProps={{ tabIndex: "1" }}
                                onChange={_handleChangeAccountBalance}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={5}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleSaveCustomer}
                                disabled={state.mode === "View"}
                            >
                                Save
                            </Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleCustomerForm}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
