import React, { Fragment, useState } from "react";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../../../../modules/AmplifyServices";
import { signIn, signOut } from "aws-amplify/auth";
import * as mutations from "../../../../graphql/mutations";
import * as queries from "../../../../graphql/queries";
import { sendFreeTrialSignupEmail } from "../../modules/MessagingService";
import {
    Typography,
    TextField,
    Grid,
    Paper,
    Button,
    FormControl,
    InputAdornment,
    CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TimezoneList } from "../../../../onboarding/components/custom/TimezoneList";
import { updateProvider } from "../../../../user/UserCommon";

const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

/* styles used in free trial form */
const useStyles = makeStyles({
    paper: {
        width: "95%",
        padding: "14px 0px 14px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        borderRadius: "6px",
        maxWidth: "450px"
    },
    papertxt: {
        flexDirection: "column",
        alignContent: "center",
        gap: "8px",
        paddingBottom: "16px",
        paddingTop: "10px"
    },
    paperheading: {
        textAlign: "center",
        fontSize: "21px",
        fontWeight: "bold",
        color: "#0087ee",
        fontFamily: "sans-serif"
    },
    papersubheading: {
        textAlign: "center",
        fontSize: "14px",
        color: "#98a9b9"
    },
    fieldgrid: {
        justifyContent: "space-between",
        padding: "0px 42px",
        flexDirection: "column",
        alignContent: "center",
        gap: "16px"
    },
    button: {
        backgroundColor: "#FF681D",
        color: "white",
        width: "100%",
        height: "38px",
        borderRadius: "2px",
        "&:hover": {
            backgroundColor: "#E64C00"
        }
    },
    field: {
        width: "100%"
    },
    input: {
        "&:before": {
            borderBottom: "2px solid #E6E6E6"
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: "2px solid blue"
        },
        "&:after": {
            borderBottom: "2px solid black"
        }
    },
    select: {
        backgroundColor: "white",
        height: "1.688em",
        paddingLeft: "14px",
        borderRadius: "4px"
    }
});

const ActivateAccountForm = (props) => {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [timezone, setTimezone] = useState(BROWSER_TZ);
    const query = new URLSearchParams(window.location.search);
    const classes = useStyles();

    //returns company data for a sessionId
    const fetchCompanyData = async (sessionId) => {
        try {
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `subscriptionsessiondata|${sessionId}`,
                    refName: "data-subscriptionsession"
                })
            );
            console.log(
                "exists from fetch company data",
                exists,
                JSON.parse(exists.data.getRefData.overrideValue)
            );

            return JSON.parse(exists.data.getRefData.overrideValue).companyData;
        } catch (e) {
            console.log(
                "Error in fetching company ref data. sessionId = ",
                query.get("session_id"),
                e
            );
        }
    };

    function handlePassword(value) {
        setPasswordError(false);
        setPasswordErrorMessage("");
        setPassword(value);
        console.log(password);
    }

    function handleShowPassword() {
        setShowPassword(!showPassword);
        console.log(password);
    }

    function validatePassword(password) {
        if (/\s/g.test(password)) {
            setPasswordError(true);
            setPasswordErrorMessage("Password cannot contain any spaces");
            return false;
        } else {
            if (password.length < 8) {
                setPasswordErrorMessage(
                    "Password must be at least 8 characters"
                );
                setPasswordError(true);
                return false;
            } else {
                setPasswordError(false);
                return true;
            }
        }
    }

    function handleTimezoneChange(event) {
        setTimezone(event.target.value);
    }

    const createProvider = async (state) => {
        const input = {
            firstname: state.firstNameInput,
            lastname: state.lastNameInput,
            emailaddress: state.emailAddress,
            phone: state.cleanPhone,
            active: true,
            deleted: false,
            providerCompanyId: state.companyId,
            companyId: state.companyId,
            createdAt: new Date()
        };
        let newProvider = await graphql(
            graphqlOperation(mutations.createProvider, { input })
        );
        return newProvider?.data?.createProvider;
    };

    async function isSubdomainProvisioned(subdomain) {
        //get secvalue
        const yesno = await getJsonApi("adminapi", `/subdomain`, {
            queryParams: {
                subdomain
            }
        });
        console.log("this is yesno", yesno);
        return yesno && yesno.value;
    }

    const provisionSubdomain = async (state) => {
        const subdomain = state.desiredSubdomain;
        let subdomainUpdatesData = {
            registerSubdomain: false,
            newSubdomain: null,
            oldSubdomain: null,
            subdomainChanged: false
        };
        try {
            //Check if subdomain is provisioned
            let isProvisioned = await isSubdomainProvisioned(
                state.desiredSubdomain.toLowerCase()
            );
            if (!isProvisioned) {
                //Check if any other company uses the same domain using the index
                //If not used by any company, set local variable to registerSubdomain = true then
                //add subdomain to the associated domain in amplify
                const r = await graphql(
                    graphqlOperation(queries.companyBySubdomain, {
                        subdomain
                    })
                );
                console.log("this is r ->", r);

                if (r.data.companyBySubdomain.items.length === 0) {
                    subdomainUpdatesData.registerSubdomain = true;
                    subdomainUpdatesData.newSubdomain = state.desiredSubdomain;
                    subdomainUpdatesData.subdomainChanged = false;
                    console.log(
                        "SUCCESS: companyId is the same but it is not provisioned so, now provision it"
                    );

                    if (subdomainUpdatesData.registerSubdomain) {
                        try {
                            await postApi("adminapi", "/subdomain", {
                                body: {
                                    newSubdomain:
                                        subdomainUpdatesData.newSubdomain,
                                    oldSubdomain:
                                        subdomainUpdatesData.oldSubdomain
                                }
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } else {
                    console.log(
                        "[ERROR]: subdomain is not provisioned ",
                        state.desiredSubdomain
                    );
                }
            }
        } catch (e) {
            console.log("Error while validating subDomain");
            console.log(e);
        }
    };

    const saveCompanyTimezoneInRefData = async (companyData) => {
        try {
            const companyId = companyData?.companyId;
            if (companyId && timezone) {
                let newTimezone = await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `TIMEZONE|${companyId}`,
                            refName: "timezone",
                            refValue: timezone
                        }
                    })
                );
                console.log(
                    "Timezone addedd to refData",
                    JSON.stringify(newTimezone?.data?.createRefData)
                );
            }
        } catch (e) {
            console.log("error while saving timezone", e);
        }
    };

    async function addUserToDb(newUserCognitoID, providerId, state) {
        let registerUserInput = {
            id: newUserCognitoID,
            username: newUserCognitoID,
            emailaddress: state.emailAddress,
            firstname: state.firstNameInput,
            lastname: state.lastNameInput,
            registered: true,
            active: true,
            role: "COMPANY_ADMIN_PROVIDER",
            companyId: state.companyId,
            userCompanyId: state.companyId,
            mobilephone: state.cleanPhone,
            phonepref: "MOBILE",
            providerId: providerId,
            userProviderId: providerId,
            contactconsent: true,
            contactconsentdatetime: new Date().toISOString()
        };
        const newUser = await graphql(
            graphqlOperation(mutations.createUser, { input: registerUserInput })
        );
        return newUser?.data?.createUser;
    }

    /* function that handles adding a user to cognito and DB  */
    async function handleAddUser(providerId, state) {
        try {
            // creates confirmed user (in cognito)
            const result = await postApi(
                //step 1
                "changeuserrole",
                "/createconfirmeduser",
                {
                    body: {
                        username: state.emailAddress,
                        email: state.emailAddress,
                        group: "CompanyAdminProvider",
                        phone_number: state.cleanPhone,
                        firstname: state.firstNameInput,
                        lastname: state.lastNameInput,
                        pas: password
                    }
                }
            );
            console.log(
                "asdf ActivateAccountForm changeuserrole result",
                result
            );

            if (result && !result.error) {
                //Verify no admin with this email exists?

                // Add user to the User table
                let newUser = await addUserToDb(
                    result.newUserCognitoID,
                    providerId,
                    state
                );
                return newUser;
                // Email to the user
                /* try {
                    await sendRegistrationEmail(state);
                } catch (e) {
                    console.log(
                        "asdf Registration Email Error = " + JSON.stringify(e)
                    ); */
            } else {
                console.log("asdf user changerole error");
            }
        } catch (e) {
            console.log(
                "asdf an unexpected error occured while creating your user account"
            );
        }
    }

    /* function that handles activating a user, using subscriptionsessiondata refData
        step 1: create provider ; return providerId
        step 2: add user 
        step 3: sign in */
    async function handleActivate(e) {
        setDisableButton(true);

        const validPassword = await validatePassword(password);
        if (validPassword) {
            const companyData = await fetchCompanyData(query.get("session_id"));
            console.log(
                "validate was clicked in Activate Account",
                companyData
            );
            const newProvider = await createProvider(companyData);
            let newUser = await handleAddUser(
                newProvider.id,
                companyData,
                password
            );
            await updateProvider(newProvider, newUser); //we call this function to update the provider record with the new userId
            await sendFreeTrialSignupEmail(companyData); //sends email to MB admins that a new free trial user signed up

            console.log(
                "creds before Auth",
                companyData.emailAddress,
                password
            );
            await saveCompanyTimezoneInRefData(companyData);
            await provisionSubdomain(companyData);
            localStorage.removeItem("appState");
            await signOut();
            const user = await signIn({
                username: companyData.emailAddress,
                password
            }); //last step IMPORTANT
            console.log("user = " + JSON.stringify(user));
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("processingCompanyCreation");
            window.location = process.env.REACT_APP_AUTH_SITE_URL; // "http://localhost:3000";
        } else {
            setPasswordError(true);
            setDisableButton(false);
        }
    }

    return (
        <Fragment>
            {/*Activate Account Form*/}

            <Paper className={classes.paper}>
                <Grid container className={classes.papertxt}>
                    <Typography className={classes.paperheading}>
                        Activate your account
                    </Typography>
                    <Typography className={classes.papersubheading}>
                        Enter your password and timezone
                    </Typography>
                </Grid>

                <Grid container className={classes.fieldgrid}>
                    <TextField
                        id="username-input"
                        label="Username"
                        defaultValue={
                            sessionStorage.getItem("email")
                                ? sessionStorage.getItem("email")
                                : ""
                        }
                        disabled={true}
                        className={classes.field}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        id="password-input"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        className={classes.field}
                        error={passwordError}
                        helperText={passwordErrorMessage}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            style: {
                                fontWeight: showPassword ? "100" : "900"
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Show Password"
                                        onClick={handleShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff
                                                style={{ color: "#626262" }}
                                            />
                                        ) : (
                                            <Visibility
                                                style={{ color: "#626262" }}
                                            />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => handlePassword(e.target.value)}
                    />
                    <FormControl required fullWidth>
                        <TimezoneList
                            value={timezone}
                            onChange={handleTimezoneChange}
                        />
                    </FormControl>
                </Grid>

                <Grid
                    container
                    style={{
                        justifyContent: "center",
                        padding: "16px 42px"
                    }}
                >
                    <Button
                        className={classes.button}
                        disabled={disableButton}
                        onClick={(e) => {
                            handleActivate(e);
                        }}
                    >
                        {disableButton ? (
                            <CircularProgress
                                size={24}
                                style={{ color: "white" }}
                            />
                        ) : (
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "sans-serif",
                                    textTransform: "none",
                                    fontWeight: "bold"
                                }}
                            >
                                Activate account
                            </Typography>
                        )}
                    </Button>
                </Grid>
            </Paper>
        </Fragment>
    );
};

export default ActivateAccountForm;
