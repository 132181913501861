import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter,
    IconButton,
    TableContainer
} from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from "../context/StoreContext";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import moment from "moment";
const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
export const BillingTable = (props) => {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    // const [selected, setSelected] = React.useState([]);
    // const [page, setPage] = React.useState(0);
    // const [selectAll, setSelectAll] = React.useState(false);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { chargeRows, miscChargeRows, columns } = props;

    // function handleChangePage(event, newPage) {
    //     setPage(newPage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     setRowsPerPage(parseInt(event.target.value));
    //     setPage(0);
    // }

    // const _handleEditClick = (id) => {
    //     actions.setMode("Edit");
    //     actions.setPage("ProviderScheduleAddEditForm");
    //     actions.setId(id);
    // };

    // const _handleViewClick = (id) => {
    //     actions.setMode("View");
    //     actions.setPage("ProviderScheduleAddEditForm");
    //     actions.setId(id);
    // };

    // const _handleTimeClick = (id, location) => {
    //     actions.setPage("TimeBlockForm");
    //     actions.setId(id);
    //     localStorage.setItem("timeBlockLocation", location);
    // };

    function sortByDate(date1, date2) {
        const firstDate = date1.updatedAt;
        const secondDate = date2.updatedAt;

        let comparison = 0;
        if (firstDate > secondDate) {
            comparison = 1;
        } else if (firstDate < secondDate) {
            comparison = -1;
        }
        return comparison * -1;
    }

    chargeRows.sort(sortByDate);
    miscChargeRows.sort(sortByDate);

    return (
        <>
            <div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                {columns &&
                                    columns.length > 0 &&
                                    columns.map((column, i) => {
                                        return (
                                            <TableCell
                                                align={column.align}
                                                className={
                                                    classes[column.className]
                                                }
                                                key={i}
                                            >
                                                <h5>{column.title}</h5>
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chargeRows &&
                                chargeRows.length > 0 &&
                                chargeRows.map((item, i) => {
                                    return (
                                        <TableRow key={item.id} hover>
                                            {columns &&
                                                columns.length > 0 &&
                                                columns.map((column, indx) => {
                                                    return (
                                                        <TableCell
                                                            key={indx}
                                                            align={column.align}
                                                            className={
                                                                classes[
                                                                    column
                                                                        .className
                                                                ]
                                                            }
                                                            style={{
                                                                verticalAlign:
                                                                    "top"
                                                            }}
                                                            width={
                                                                column.name ==
                                                                "description"
                                                                    ? "70%"
                                                                    : "30%"
                                                            }
                                                        >
                                                            {column.name ==
                                                            "description" ? (
                                                                <div>
                                                                    <b>
                                                                        Order
                                                                        number:{" "}
                                                                    </b>{" "}
                                                                    {
                                                                        item
                                                                            .order
                                                                            .orderNo
                                                                    }
                                                                    <br />
                                                                    <b>
                                                                        Billing
                                                                        type:{" "}
                                                                    </b>{" "}
                                                                    {item.description.includes(
                                                                        "Client generated tip amount"
                                                                    )
                                                                        ? "Tip"
                                                                        : item.description.includes(
                                                                              "Payment for"
                                                                          )
                                                                        ? "Payment"
                                                                        : item.description.includes(
                                                                              "Misc Billing Transaction"
                                                                          )
                                                                        ? "Misc charge"
                                                                        : item.description.includes(
                                                                              "Additional Charge Billing"
                                                                          )
                                                                        ? "Additional charge"
                                                                        : item.description.includes(
                                                                              "Refund of"
                                                                          )
                                                                        ? "Refund"
                                                                        : "Payment"}
                                                                    <br />
                                                                    <b>
                                                                        Date:{" "}
                                                                    </b>{" "}
                                                                    {moment(
                                                                        item.createdAt
                                                                    ).format(
                                                                        "MMMM DD, YYYY h:mm:ss A"
                                                                    )}
                                                                    {item.provider && (
                                                                        <div>
                                                                            <b>
                                                                                Provider:{" "}
                                                                            </b>
                                                                            {item
                                                                                .provider
                                                                                .firstName +
                                                                                " " +
                                                                                item
                                                                                    .provider
                                                                                    .lastName}
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                    {item.descriptionString && (
                                                                        <div>
                                                                            <b>
                                                                                Description:{" "}
                                                                            </b>
                                                                            {
                                                                                item.descriptionString
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : column.name ==
                                                                  "total" &&
                                                              item.amountString ? (
                                                                <div>
                                                                    <b>
                                                                        Amount:{" "}
                                                                    </b>
                                                                    {
                                                                        item.amountString
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        {miscChargeRows && miscChargeRows.length > 0 && (
                            <TableHead>
                                <TableRow key={1} hover>
                                    <TableCell align={"left"}>
                                        <b>Miscellaneous Charges</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {miscChargeRows &&
                                miscChargeRows.length > 0 &&
                                miscChargeRows.map((item, i) => {
                                    return (
                                        <TableRow key={item.id} hover>
                                            {columns &&
                                                columns.length > 0 &&
                                                columns.map((column, indx) => {
                                                    return (
                                                        <TableCell
                                                            key={indx}
                                                            align={column.align}
                                                            className={
                                                                classes[
                                                                    column
                                                                        .className
                                                                ]
                                                            }
                                                            style={{
                                                                verticalAlign:
                                                                    "top"
                                                            }}
                                                        >
                                                            {column.name ==
                                                            "description" ? (
                                                                <div>
                                                                    {item.orderNo && (
                                                                        <div>
                                                                            <b>
                                                                                Order
                                                                                number:{" "}
                                                                            </b>{" "}
                                                                            {
                                                                                item.orderNo
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    <b>
                                                                        Date:{" "}
                                                                    </b>{" "}
                                                                    {moment(
                                                                        item.createdAt
                                                                    ).format(
                                                                        "MMMM DD, YYYY h:mm:ss A"
                                                                    )}
                                                                    {item.provider && (
                                                                        <div>
                                                                            <b>
                                                                                Provider:{" "}
                                                                            </b>{" "}
                                                                            {
                                                                                item.provider
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    {item.descriptionString && (
                                                                        <div>
                                                                            <b>
                                                                                Description:{" "}
                                                                            </b>
                                                                            {
                                                                                item.descriptionString
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : column.name ==
                                                                  "total" &&
                                                              item.amountString ? (
                                                                <div>
                                                                    <b>
                                                                        Amount:{" "}
                                                                    </b>
                                                                    {
                                                                        item.amountString
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                        </TableRow>
                                    );
                                })}
                            {/* Display no charges message if number of rows are 0 */}
                            {chargeRows &&
                                chargeRows.length == 0 &&
                                miscChargeRows &&
                                miscChargeRows.length == 0 && (
                                    <TableRow key={1} hover>
                                        {columns &&
                                            columns.length > 0 &&
                                            columns.map((column, indx) => {
                                                return (
                                                    <TableCell
                                                        key={indx}
                                                        align={column.align}
                                                        className={
                                                            classes[
                                                                column.className
                                                            ]
                                                        }
                                                    >
                                                        {/* We only want to display the no charges message in the first column */}
                                                        {indx == 0
                                                            ? "No Charges Found"
                                                            : ""}
                                                    </TableCell>
                                                );
                                            })}
                                    </TableRow>
                                )}
                        </TableBody>
                        {/* <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={6}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "Rows per page"
                                        },
                                        native: true
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                /> 
                            </TableRow>
                        </TableFooter> */}
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};
