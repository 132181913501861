import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { ProviderLocationAddEditForm } from "./ProviderLocationAddEdit";

const travelZoneModal = document.getElementById("travelZone");

const TravelZoneModal = (props) => {
    return ReactDOM.createPortal(
        <Dialog
            open={props.displayTravelZoneForm}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogContent>
                <ProviderLocationAddEditForm
                    isModalOpen={props.displayTravelZoneForm ? true : false}
                    pathname={props.pathname}
                    returnTo={props.returnTo}
                    closeTravelZonePortal={props.closeTravelZonePortal}
                    closeAvailabilityPortal={props.closeAvailabilityPortal}
                    updateTravelZoneValue={props.updateTravelZoneValue}
                />
            </DialogContent>
        </Dialog>,
        travelZoneModal
    );
};

export default TravelZoneModal;
