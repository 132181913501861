import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    Snackbar,
    FormControl,
    FormControlLabel,
    Switch,
    Tooltip
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import validator from "validator";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import { validatePhoneNumber } from "../onboarding/modules/ValidityCheck";
import { ClientAddressInput } from "../components/ClientAddressInput";
import { makeStyles } from "@mui/styles";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import { isUniqueEmailAddress, USERROLE } from "../user/UserCommon";
import {
    auditUserCreate,
    auditClientNotesUpdate,
    auditUserUpdate
} from "../modules/Audit";
import * as Sentry from "@sentry/react";
import { ClientCreditCardUpdateForm } from "../components/stripe/ClientCreditCardUpdateForm";
import CircularProgress from "@mui/material/CircularProgress";
import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";
import moment from "moment";
import { TriggerManager } from "../modules/TriggerManager";
import uuid from "uuid/v4";
import { genpass, sendRegistrationEmail } from "../modules/MessagingService";
import { getCognitoUser, updateCognitoUser } from "../user/CognitoUserCommon";
import PasswordManagementModal from "./PasswordManagmentModal";

const useStyles = makeStyles({
    customPaperProps: {
        padding: "36px 18px 36px 18px",
        width: "750px",
        maxWidth: "750px",
        display: "flex",
        flexDirection: "column"
    },
    customDialogTitle: {
        fontSize: "24px",
        lineHeight: "20px",
        padding: "0px 18px",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        height: "20px",
        gap: "18px"
    },
    customDialogContent: {
        padding: "0px",
        display: "flex",
        flexDirection: "column",
        gap: "18px"
    },
    customDialogActions: {
        padding: "18px 18px 0px 18px",
        gap: "18px"
    },
    customDialogButton: {
        height: "2.25rem",
        boxShadow: "none",
        minWidth: "5.625rem",
        alignItems: "center",
        lineHeight: "inherit",
        "&:hover": {
            boxShadow: "none"
        }
    },
    customDialogButtonText: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        fontSize: "0.875rem",
        fontWeight: 500,
        fontFamily: "Roboto",
        paddingTop: "0.1rem"
    },
    notesUpdateText: {
        fontSize: "12px",
        fontStyle: "italic",
        color: "rgba(0,0,0,0.4)",
        paddingTop: "0px",
        lineHeight: "20px"
    },
    resetPasswordButton: {
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-end",
        lineHeight: "16px",
        padding: "0px"
    },
    clearButton: {
        cursor: "pointer",
        padding: "0px",
        justifyContent: "right",
        height: "22px",
        minWidth: "0px"
    }
});
const customTextFieldStyle = {
    inputProps: {
        style: {
            padding: "7.95px 14px",
            fontSize: "14px"
        }
    }
};
const autocompleteService = { current: null };

const ClientUserInfoModal = ({
    isOpen,
    onSave,
    onClose,
    mode,
    loggedInUser,
    clientUser,
    isGuestOnly,
    focusNotes
}) => {
    const classes = useStyles();
    const [placeId, setPlaceId] = useState();
    let dialogMode = mode ? mode : "ADD";
    const [emailChangeWarningModal, setEmailChangeWarningModal] =
        useState(false);
    const [userSaveInProgess, setUserSaveInProgress] = useState(false);

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    //Form states
    const [active, setActive] = useState(
        clientUser.active ? clientUser.active : false
    );
    const [firstName, setFirstName] = useState(
        clientUser.firstName ? clientUser.firstName : ""
    );
    const [lastName, setLastName] = useState(
        clientUser.lastName ? clientUser.lastName : ""
    );
    const [emailAddress, setEmailAddress] = useState(
        clientUser.emailAddress ? clientUser.emailAddress : ""
    );
    const [phoneNumber, setPhoneNumber] = useState(
        clientUser.phone ? clientUser.phone : ""
    );
    const [address, setAddress] = useState(
        clientUser.addressOneLine ? clientUser.addressOneLine : null
    );
    const [addressAttributes, setAddressAttributes] = useState();
    const [defaultPartialCC, setDefaultPartialCC] = useState("");
    const [notes, setNotes] = useState("");
    const [clientNotes, setClientNotes] = useState(
        clientUser?.clientNotes ? clientUser.clientNotes : ""
    );
    const [contactConsent, setContactConsent] = useState(
        clientUser.contactConsent ? clientUser.contactConsent : false
    );
    const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);

    //Is field focused states
    const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
    const [isLastNameFocused, setIsLastNameFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isNotesFocused, setIsNotesFocused] = useState(
        focusNotes ? focusNotes : false
    );
    //Original values. Used to compare.
    let originalFirstName = clientUser.firstName ? clientUser.firstName : "";
    let originalLastName = clientUser.lastName ? clientUser.lastName : "";
    let originalEmailAddress = clientUser?.emailAddress
        ? clientUser.emailAddress
        : "";
    let originalPhoneNumber = clientUser.phone ? clientUser.phone : "";
    let originalAddress = clientUser.addressOneLine
        ? clientUser.addressOneLine
        : null;
    let originalNotes = clientUser?.clientNotes?.notes
        ? clientUser.clientNotes.notes
        : "";
    let originalContantConsent = clientUser.contactConsent
        ? clientUser.contactConsent
        : false;
    let originalActive = clientUser.active ? clientUser.active : false;

    let formDetailsChanged = !(
        active === originalActive &&
        firstName === originalFirstName &&
        lastName === originalLastName &&
        emailAddress === originalEmailAddress &&
        phoneNumber === originalPhoneNumber &&
        address === originalAddress &&
        contactConsent === originalContantConsent &&
        notes === originalNotes
    );

    //Error states
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailaddressError, setEmailAddressError] = useState(false);
    const [emailaddressErrorMessage, setEmailAddressErrorMessage] =
        useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

    //Password management states
    const [passwordManagementType, setPasswordManagementType] = useState();
    const [showManagePasswordDialog, setShowManagePasswordDialog] =
        useState(false);
    const [showResetPasswordSpinner, setShowResetPasswordSpinner] =
        useState(false);

    //Credit card management states
    const [showUpdateCCDetailsDialog, setShowUpdateCCDetailsDialog] =
        useState(false);

    //useEffect is used to update the form state with initial values being passed in from the parent component
    useEffect(() => {
        dialogMode = mode ? mode : "ADD";
        setFirstName(clientUser.firstName ? clientUser.firstName : "");
        setLastName(clientUser.lastName ? clientUser.lastName : "");
        setEmailAddress(clientUser.emailAddress ? clientUser.emailAddress : "");
        setPhoneNumber(clientUser.phone ? clientUser.phone : "");
        setDefaultPartialCC(
            clientUser?.defaultPartialCC ? clientUser.defaultPartialCC : ""
        );
        setActive(clientUser.active ? clientUser.active : false);
        setContactConsent(
            clientUser.contactConsent ? clientUser.contactConsent : false
        );
        setAddress(
            clientUser.addressOneLine ? clientUser.addressOneLine : null
        );
        setNotes(
            clientUser?.clientNotes?.notes ? clientUser.clientNotes.notes : ""
        );
        setClientNotes(clientUser?.clientNotes ? clientUser.clientNotes : "");
        setAddressAttributes(
            clientUser.addressOneLine
                ? {
                      street: clientUser.street,
                      city: clientUser.city,
                      state: clientUser.state,
                      country: clientUser.country,
                      postalcode: clientUser.postalcode,
                      longitude: clientUser.longitude,
                      latitude: clientUser.latitude
                  }
                : {
                      street: null,
                      city: null,
                      state: null,
                      country: null,
                      postalcode: null,
                      longitude: null,
                      latitude: null
                  }
        );
        setIsNotesFocused(focusNotes ? focusNotes : false);

        originalFirstName = clientUser.firstName ? clientUser.firstName : "";
        originalLastName = clientUser.lastName ? clientUser.lastName : "";
        originalEmailAddress = clientUser.emailAddress
            ? clientUser.emailAddress
            : "";
        originalPhoneNumber = clientUser.phone ? clientUser.phone : "";
        originalActive = clientUser.active ? clientUser.active : false;
        originalContantConsent = clientUser.contactConsent
            ? clientUser.contactConsent
            : false;
        originalAddress = clientUser.addressOneLine
            ? clientUser.addressOneLine
            : null;
        originalNotes = clientUser?.clientNotes?.notes
            ? clientUser.clientNotes.notes
            : "";
    }, [
        clientUser.firstName,
        clientUser.lastName,
        clientUser.emailAddress,
        clientUser.phone,
        clientUser.defaultPartialCC,
        clientUser.active,
        clientUser.contactConsent,
        clientUser.addressOneLine,
        clientUser.clientNotes,
        mode,
        focusNotes
    ]);

    /* functions to handle user inputs */
    function handleFirstName(value) {
        setFirstName(value);
        setFirstNameError(false);
    }
    function handleLastName(value) {
        setLastName(value);
        setLastNameError(false);
    }
    function handleEmailAddress(value) {
        setEmailAddress(value.trim().toLowerCase());
        setEmailAddressError(false);
    }
    function handlePhoneNumber(value) {
        setPhoneNumber(value);
        setPhoneNumberError(false);
    }
    function handleNotes(value) {
        setNotes(value);
    }
    function handleActiveSwitch(value) {
        setActive(value);
    }
    function handleDefaultCC(value) {
        setDefaultPartialCC(value);
    }
    function handleAddress(value) {
        setAddress(value.addressOneLine);
        setAddressAttributes({
            street: value.route,
            city: value.city,
            state: value.prov,
            country: value.country,
            postalcode: value.postalCode,
            longitude: value.coords.lng,
            latitude: value.coords.lat
        });
    }
    function savePlaceId(placeId) {
        if (placeId === null) {
            setPlaceId(null);
        } else {
            setPlaceId(placeId);
        }
    }

    function resetErrorStates() {
        setFirstNameError(false);
        setLastNameError(false);
        setEmailAddressError(false);
        setEmailAddressErrorMessage("");
        setPhoneNumberError(false);
        setPhoneNumberErrorMessage("");
    }
    function resetFormStates() {
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setPhoneNumber("");
        setAddress(null);
        setAddressAttributes();
        setDefaultPartialCC("");
        setNotes("");
        setClientNotes("");
        setContactConsent(true);
        setSendWelcomeEmail(true);
    }

    /**
     * Function to handle clearing the notes field
     */
    const handleClearNotes = () => {
        setNotes("");
    };

    /**
     * Function to handle a users credit card details update attempt
     */
    async function handleUpdateCCDetailsClick() {
        setShowUpdateCCDetailsDialog(true);
    }

    /**
     * Function to validate a phone number.
     * @param {String} phone
     * @returns
     */
    function validatePhone(phone) {
        let cleanPhone = phone.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-"); // strip brackets or dash
        let isValidPhone = validatePhoneNumber(cleanPhone);

        //check for AUS phone numbers
        if (!isValidPhone) {
            if (cleanPhone.startsWith("+61"))
                isValidPhone =
                    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/.test(
                        cleanPhone
                    );
        }

        if (isValidPhone) {
            return cleanPhone;
        } else {
            setPhoneNumberError(true);
            setPhoneNumberErrorMessage("Please enter a valid phone number");
            setUserSaveInProgress(false);
            return null;
        }
    }

    /**
     * Function to handle a users password reset attempt.
     */
    async function handleResetPasswordClick() {
        setShowManagePasswordDialog(true);
        setShowResetPasswordSpinner(true);
        try {
            const result = await getJsonApi("changeuserrole", "/getuser", {
                queryParams: {
                    username: clientUser.userId
                }
            });
            if (result && result.success) {
                setShowResetPasswordSpinner(false);
                let userStatus = result.data.UserStatus;
                if (
                    userStatus === "CONFIRMED" ||
                    userStatus === "RESET_REQUIRED"
                ) {
                    //allow reset for these two statuses
                    setPasswordManagementType("RESET");
                } else if (userStatus === "FORCE_CHANGE_PASSWORD") {
                    setPasswordManagementType("SET-TEMP-PWD");
                }
            } else {
                setShowResetPasswordSpinner(false);
                setShowManagePasswordDialog(false);
            }
        } catch (e) {
            setShowResetPasswordSpinner(false);
            setShowManagePasswordDialog(false);
            console.log("Error: Unable to get cognito password data", e);
        }
    }

    /**
     * Function to save a client's Notes to the Client record in the DB
     */
    async function saveNotes() {
        let newNotes = {};
        const date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        try {
            if (clientNotes) {
                //if Notes exists, then only update and use the same createdAt and createdBy attributes
                newNotes = {
                    notes: notes,
                    createdBy: clientNotes.createdBy,
                    updatedBy:
                        loggedInUser.firstname + " " + loggedInUser.lastname,
                    createdAt: clientNotes.createdAt,
                    updatedAt: date
                };
                setClientNotes(newNotes);
            } else {
                //if Notes dont exists, add new created stamps and update stamps
                newNotes = {
                    notes: notes,
                    createdBy:
                        loggedInUser.firstname + " " + loggedInUser.lastname,
                    updatedBy:
                        loggedInUser.firstname + " " + loggedInUser.lastname,
                    createdAt: date,
                    updatedAt: date
                };
                setClientNotes(newNotes);
            }

            const updatedClient = await graphql(
                graphqlOperation(mutations.updateClient, {
                    input: {
                        id: clientUser.id,
                        Notes: JSON.stringify(newNotes)
                    }
                })
            );

            if (
                updatedClient &&
                updatedClient.data &&
                updatedClient.data.updateClient
            ) {
                try {
                    await auditClientNotesUpdate(
                        loggedInUser,
                        updatedClient.data.updateClient,
                        notes,
                        originalNotes
                    );
                } catch (e) {
                    console.log("Error while entering audit");
                }
            }
            originalNotes = notes;
            return newNotes;
        } catch (e) {
            console.log("ERROR: Unable to save client Notes ", e);
        }
    }

    /**
     * Function to save user information to the DB
     * @param {*} cleanValidPhone
     */
    async function updateUser(cleanValidPhone) {
        try {
            let input = {
                id: clientUser.userId,
                username: clientUser.userId,
                lastname: lastName.trim(),
                firstname: firstName.trim(),
                emailaddress: emailAddress,
                addressoneline: address,
                street: addressAttributes.street,
                city: addressAttributes.city,
                state: addressAttributes.state,
                country: addressAttributes.country,
                postalcode: addressAttributes.postalcode,
                longitude: addressAttributes.longitude,
                latitude: addressAttributes.latitude,
                active: active,
                mobilephone: cleanValidPhone,
                contactconsent: contactConsent,
                contactconsentdatetime: new Date().toISOString(),
                role: clientUser.userRole
            };

            let response = await graphql(
                graphqlOperation(mutations.updateUser, { input })
            );

            //Audit the user save
            let newInfo = [
                {
                    firstname: "No Change",
                    lastname: "No Change",
                    address: "No Change",
                    mobilephone: "No Change",
                    emailaddress: "No Change"
                }
            ];
            let doAudit = false;
            if (firstName !== originalFirstName) {
                newInfo[0] && (newInfo[0].firstname = firstName);
                doAudit = true;
            }
            if (lastName !== originalLastName) {
                newInfo[0] && (newInfo[0].lastname = lastName);
                doAudit = true;
            }
            if (address !== originalAddress) {
                newInfo[0] && (newInfo[0].address = address);
                doAudit = true;
            }
            if (phoneNumber !== originalPhoneNumber) {
                newInfo[0] && (newInfo[0].mobilephone = phoneNumber);
                doAudit = true;
            }
            if (emailAddress !== originalEmailAddress) {
                newInfo[0] && (newInfo[0].emailaddress = emailAddress);
                doAudit = true;
            }
            if (doAudit)
                await auditUserUpdate(
                    loggedInUser,
                    response.data.updateUser,
                    newInfo,
                    "UPDATE"
                );
        } catch (error) {
            console.error(
                "An error occurred updating the user. The error was: ",
                error
            );
        }
    }

    /**
     * Function to handle Client cognito record, Client User record and Client record creation in DB
     * @param {*} cleanValidPhone
     */
    async function handleAddClientRecords(cleanValidPhone) {
        try {
            const temppas = genpass();
            let result;
            if (!isGuestOnly) {
                /**1. Create the cognito record, provided the company is not Guest Checkout Only*/
                result = await postApi("changeuserrole", "/createuser", {
                    body: {
                        username: emailAddress,
                        email: emailAddress,
                        group: "Client",
                        phone_number: cleanValidPhone,
                        temppas,
                        firstname: firstName.trim(),
                        lastname: lastName.trim()
                    }
                });
            }

            /**2. If cogntio record creation was successful, move forward with User & Client record creation*/
            /** Or if the company is Guest Checkout Only, then a new user is created with new IDs */
            if ((result && !result.error) || isGuestOnly) {
                // Add user to the User table
                await addClientUserToDb(
                    isGuestOnly ? uuid() : result.newUserCognitoID,
                    cleanValidPhone
                );

                /* Zapier Trigger - New Client (new-client-zapier)*/
                try {
                    const date = new Date();
                    const dateString = date.toLocaleString("en-US");
                    let clientData = {
                        email: emailAddress,
                        userType: "Client",
                        phoneNumber: cleanValidPhone,
                        createdAt: dateString,
                        firstName: firstName.trim(),
                        lastName: lastName.trim()
                    };
                    let hookName = "new-client-zapier";
                    await TriggerManager(
                        clientData,
                        loggedInUser.companyId,
                        hookName
                    );
                } catch (e) {
                    console.log("ERROR: unable to perform trigger manager", e);
                }

                // Email to the user and the compnayAdmin
                try {
                    let userData = {
                        firstName: firstName.trim(),
                        lastName: lastName.trim(),
                        emailAddress: emailAddress,
                        userRole: USERROLE.CLIENT
                    };
                    if (sendWelcomeEmail && !isGuestOnly) {
                        await sendRegistrationEmail(
                            temppas,
                            cleanValidPhone,
                            userData,
                            loggedInUser.company
                        );
                    }
                } catch (e) {
                    Sentry.captureException(e);
                    console.log(
                        "asdf Registration Email Error = " + JSON.stringify(e)
                    );
                    setSnackMsg("Error sending registration email");
                    setMsgOpen(true);
                }
            }
        } catch (error) {
            console.error(
                "Error: occurred adding the user. The error was: ",
                error
            );
        }
    }

    /**
     * Function to add a new Client User and Client record to the DB
     * @param {*} newUserCognitoID
     * @param {*} cleanValidPhone
     */
    async function addClientUserToDb(newUserCognitoID, cleanValidPhone) {
        let registerUserInput = {
            id: newUserCognitoID,
            username: newUserCognitoID,
            lastname: lastName.trim(),
            firstname: firstName.trim(),
            emailaddress: emailAddress,
            active: active,
            registered: isGuestOnly ? false : true,
            mobilephone: cleanValidPhone,
            contactconsent: contactConsent,
            contactconsentdatetime: new Date().toISOString(),
            companyId: loggedInUser.companyId,
            userCompanyId: loggedInUser.companyId,
            role: USERROLE.CLIENT
        };
        if (address) {
            registerUserInput.addressoneline = address;
            registerUserInput.street = addressAttributes.street;
            registerUserInput.city = addressAttributes.city;
            registerUserInput.state = addressAttributes.state;
            registerUserInput.country = addressAttributes.country;
            registerUserInput.postalcode = addressAttributes.postalcode;
            registerUserInput.longitude = addressAttributes.longitude;
            registerUserInput.latitude = addressAttributes.latitude;
        }
        try {
            const newUser = await graphql(
                graphqlOperation(mutations.createUser, {
                    input: registerUserInput
                })
            );

            await auditUserCreate(loggedInUser, newUser.data.createUser);

            if (newUser && newUser.data && newUser.data.createUser) {
                let user = newUser.data.createUser;
                const date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                //add Client record
                let clientData = {
                    userId: user.id,
                    currency: loggedInUser.company.currency
                        ? loggedInUser.company.currency
                        : "CAD",
                    companyId: loggedInUser.companyId,
                    clientUserId: user.id,
                    clientCompanyId: loggedInUser.companyId,
                    accountbalance: 0.0
                };
                if (notes) {
                    let newNotes = {
                        notes: notes,
                        createdBy:
                            loggedInUser.firstname +
                            " " +
                            loggedInUser.lastname,
                        updatedBy:
                            loggedInUser.firstname +
                            " " +
                            loggedInUser.lastname,
                        createdAt: date,
                        updatedAt: date
                    };
                    clientData.Notes = JSON.stringify(newNotes);
                }
                await graphql(
                    graphqlOperation(mutations.createClient, {
                        input: clientData
                    })
                );
            }
        } catch (error) {
            console.log("Error: Unable to create User and Client", error);
        }
    }

    /**
     * Function to save a user based on the input form state.
     * This function will validate and save user data into the correct DB tables.
     * Then Audit the user record change.
     */
    async function handleSaveClientUser() {
        let cognitoUser;
        let newNotes;
        setUserSaveInProgress(true);
        setEmailChangeWarningModal(false);
        setIsNotesFocused(false);

        /** 1) Validate the form fields **/
        //First check if any of the mandatory form fields are empty
        if (!firstName) {
            setFirstNameError(true);
        }
        if (!lastName) {
            setLastNameError(true);
        }
        if (!emailAddress) {
            setEmailAddressError(true);
            setEmailAddressErrorMessage("Please enter your email address");
        }
        if (!phoneNumber) {
            setPhoneNumberError(true);
            setPhoneNumberErrorMessage("Please enter your phone number");
        }
        if (!firstName || !lastName || !emailAddress || !phoneNumber) {
            setUserSaveInProgress(false);
            return;
        }
        //Second validate the email and phone values
        if (!validator.isEmail(emailAddress)) {
            setEmailAddressError(true);
            setEmailAddressErrorMessage("Please enter a valid email address");
            setUserSaveInProgress(false);
            return;
        }
        let cleanValidPhone = validatePhone(phoneNumber);
        if (!cleanValidPhone) {
            setUserSaveInProgress(false);
            return;
        }

        //check if the email was changed, if so then we need to check if its already being used
        //this 'if' statement will also be accessed if the client is being Added using the Add Client button
        if (emailAddress !== originalEmailAddress) {
            //check if the emailaddress entered is a unique email address
            let isUniqueEmail = await isUniqueEmailAddress(emailAddress);
            if (!isUniqueEmail) {
                setEmailAddressError(true);
                setEmailAddressErrorMessage(
                    "This email is already in use, please try another email"
                );
                setUserSaveInProgress(false);
                return;
            }

            //attempt to get the users cognito record (only if the user is not being added for the first time)
            if (dialogMode !== "ADD") {
                cognitoUser = await getCognitoUser(clientUser.userId);
            }
        }

        //If the user is in Add mode, we need to create the cognito, user records
        //Else, we just need to update the records (provided they exist)
        if (dialogMode === "ADD") {
            /**2) Add the user to the DB **/
            await handleAddClientRecords(cleanValidPhone);
        } else {
            /**2) Update/Save the user to the DB **/
            await updateUser(cleanValidPhone);

            /**3) Update/Save the cognito record (provided the email was changed, and a cognito record exists)*/
            if (emailAddress !== originalEmailAddress && cognitoUser) {
                await updateCognitoUser(cognitoUser, emailAddress);
            }

            /**4) Update/Save the client Notes to the DB in the Client record*/
            if (notes !== originalNotes && dialogMode !== "ADD" && clientUser) {
                newNotes = await saveNotes();
            }
        }

        let clientUpdates = {
            active,
            firstName,
            lastName,
            emailAddress,
            phoneNumber: cleanValidPhone,
            defaultPartialCC,
            address,
            addressAttributes,
            consent: contactConsent,
            newNotes
        };

        if (onSave) {
            onSave(clientUser.id, clientUpdates);
        }
        setSnackMsg(
            dialogMode === "ADD"
                ? "Client was created successfully"
                : "Client was updated successfully"
        );
        setMsgOpen(true);
        setUserSaveInProgress(false);
        setTimeout(() => {
            resetErrorStates();
            if (dialogMode === "ADD") {
                resetFormStates();
            }
            onClose();
        }, 1000);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setMsgOpen(false);
                }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />

            <PasswordManagementModal
                showManagePasswordDialog={showManagePasswordDialog}
                passwordManagementType={passwordManagementType}
                setPasswordManagementType={setPasswordManagementType}
                setShowManagePasswordDialog={setShowManagePasswordDialog}
                showResetPasswordSpinner={showResetPasswordSpinner}
                userData={clientUser}
                loggedInUser={loggedInUser}
                emailAddress={originalEmailAddress}
            />

            {/** Email Change Warning Modal **/}
            <SmallSizedModal
                open={emailChangeWarningModal}
                onClose={() => {
                    setEmailChangeWarningModal(false);
                }}
                headingIcon={WarningTriangleIcon}
                headingText={"Change email address"}
            >
                <Box>
                    <Typography variant="body2">
                        Changing this client's email address will update their
                        unique identifier in the system and will affect all
                        scheduled email notifications to this person. Are you
                        sure you wish to continue?
                    </Typography>
                </Box>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1.25rem"
                    }}
                >
                    <Button
                        sx={{
                            borderColor: "primary.main"
                        }}
                        className={classes.customDialogButton}
                        onClick={() => {
                            setEmailChangeWarningModal(false);
                        }}
                        variant="outlined"
                    >
                        <div className={classes.customDialogButtonText}>
                            Cancel
                        </div>
                    </Button>
                    <Button
                        className={classes.customDialogButton}
                        variant="contained"
                        onClick={() => {
                            handleSaveClientUser();
                        }}
                    >
                        <div className={classes.customDialogButtonText}>
                            Continue
                        </div>
                    </Button>
                </Box>
            </SmallSizedModal>

            {/** Update Credit Card Details Modal **/}
            <ClientCreditCardUpdateForm
                show={showUpdateCCDetailsDialog}
                clientId={clientUser.id}
                setPartialCC={(value) => {
                    handleDefaultCC(value);
                }}
                onClose={() => {
                    setShowUpdateCCDetailsDialog(false);
                }}
                updatedBySelf={false}
            />

            {/** Main Form **/}
            <Dialog
                open={isOpen}
                onClose={() => {
                    resetErrorStates();
                    onClose();
                }}
                fullWidth
                PaperProps={{
                    className: classes.customPaperProps
                }}
            >
                <DialogTitle className={classes.customDialogTitle}>
                    {dialogMode === "ADD"
                        ? "New client"
                        : clientUser
                          ? clientUser.firstName + " " + clientUser.lastName
                          : ""}
                    {dialogMode !== "ADD" && (
                        <Tooltip
                            title={active ? "Active" : "Inactive"}
                            placement="right"
                        >
                            <FormControl sx={{ width: "48px" }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ top: "4px" }}
                                            checked={active}
                                            onChange={(event) =>
                                                handleActiveSwitch(
                                                    event.target.checked
                                                )
                                            }
                                            value={active}
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "primary checkbox"
                                            }}
                                        />
                                    }
                                />
                            </FormControl>
                        </Tooltip>
                    )}
                </DialogTitle>
                <DialogContent
                    style={{ padding: "36px 18px 18px 18px" }}
                    className={classes.customDialogContent}
                >
                    <Grid container spacing={2.25}>
                        {dialogMode === "ADD" && !isGuestOnly && (
                            <Grid item xs={12} sx={{ height: "40px" }}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        sx={{
                                            height: "20px",
                                            margin: "0px 0px 0px -11px"
                                        }}
                                        control={
                                            <Switch
                                                checked={sendWelcomeEmail}
                                                onChange={(event) =>
                                                    setSendWelcomeEmail(
                                                        event.target.checked
                                                    )
                                                }
                                                value={sendWelcomeEmail}
                                                color="primary"
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox"
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: "14px"
                                                }}
                                            >
                                                {`Send welcome email`}
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <TextField
                                disabled={
                                    dialogMode !== "ADD" &&
                                    clientUser.userRole !== USERROLE.CLIENT
                                }
                                id="first-name-input"
                                label="First name"
                                size="small"
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "60px"
                                        }
                                    }
                                }}
                                fullWidth
                                error={firstNameError}
                                helperText={
                                    firstNameError
                                        ? "Please enter your first name"
                                        : ""
                                }
                                value={firstName}
                                onChange={(e) =>
                                    handleFirstName(e.target.value)
                                }
                                onFocus={() => setIsFirstNameFocused(true)}
                                onBlur={() => setIsFirstNameFocused(false)}
                                InputLabelProps={{
                                    style: {
                                        top:
                                            firstName || isFirstNameFocused
                                                ? "2px"
                                                : "",
                                        lineHeight:
                                            firstName || isFirstNameFocused
                                                ? ""
                                                : "18px",
                                        fontSize: "14px"
                                    }
                                }}
                                {...customTextFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                disabled={
                                    dialogMode !== "ADD" &&
                                    clientUser.userRole !== USERROLE.CLIENT
                                }
                                id="last-name-input"
                                label="Last name"
                                size="small"
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "60px"
                                        }
                                    }
                                }}
                                fullWidth
                                error={lastNameError}
                                helperText={
                                    lastNameError
                                        ? "Please enter your last name"
                                        : ""
                                }
                                value={lastName}
                                onChange={(e) => handleLastName(e.target.value)}
                                onFocus={() => setIsLastNameFocused(true)}
                                onBlur={() => setIsLastNameFocused(false)}
                                InputLabelProps={{
                                    style: {
                                        top:
                                            lastName || isLastNameFocused
                                                ? "2px"
                                                : "",
                                        lineHeight:
                                            lastName || isLastNameFocused
                                                ? ""
                                                : "18px",
                                        fontSize: "14px"
                                    }
                                }}
                                {...customTextFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                disabled={
                                    dialogMode !== "ADD" &&
                                    clientUser.userRole !== USERROLE.CLIENT
                                }
                                id="email-input"
                                label="Email"
                                size="small"
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "36px"
                                        }
                                    }
                                }}
                                fullWidth
                                error={emailaddressError}
                                helperText={
                                    emailaddressError
                                        ? emailaddressErrorMessage
                                        : ""
                                }
                                value={emailAddress}
                                onChange={(e) =>
                                    handleEmailAddress(e.target.value)
                                }
                                onFocus={() => setIsEmailFocused(true)}
                                onBlur={() => setIsEmailFocused(false)}
                                InputLabelProps={{
                                    style: {
                                        top:
                                            emailAddress || isEmailFocused
                                                ? "2px"
                                                : "",
                                        lineHeight:
                                            emailAddress || isEmailFocused
                                                ? ""
                                                : "18px",
                                        fontSize: "14px"
                                    }
                                }}
                                {...customTextFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MuiPhoneNumber
                                disabled={
                                    dialogMode !== "ADD" &&
                                    clientUser.userRole !== USERROLE.CLIENT
                                }
                                id="mobilephone-input"
                                variant="outlined"
                                label="Phone"
                                sx={{
                                    display: "flex",
                                    "& fieldset": {
                                        "& legend": {
                                            width: "40px"
                                        }
                                    }
                                }}
                                size="small"
                                fullWidth
                                defaultCountry={
                                    loggedInUser?.company &&
                                    loggedInUser?.company?.countrycode3166alpha2
                                        ? loggedInUser.company
                                              .countrycode3166alpha2
                                        : "ca"
                                }
                                error={phoneNumberError}
                                helperText={
                                    phoneNumberError
                                        ? phoneNumberErrorMessage
                                        : ""
                                }
                                value={phoneNumber}
                                onChange={(e) => handlePhoneNumber(e)}
                                inputProps={{
                                    style: {
                                        padding: "7.95px 6px",
                                        fontSize: "14px"
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        top: "2px",
                                        fontSize: "14px"
                                    }
                                }}
                                /* onChange={handlePhoneNumber} */
                            />
                        </Grid>
                        {loggedInUser.company?.collectpayment &&
                            dialogMode !== "ADD" && (
                                <Grid item xs={6}>
                                    <TextField
                                        label="Credit card"
                                        fullWidth
                                        disabled={true}
                                        size="small"
                                        sx={{
                                            "& fieldset": {
                                                "& legend": {
                                                    width: "62px"
                                                }
                                            }
                                        }}
                                        value={
                                            defaultPartialCC
                                                ? `XXXX-XXXX-XXXX-${defaultPartialCC}`
                                                : ""
                                        }
                                        InputLabelProps={{
                                            style: {
                                                top: defaultPartialCC
                                                    ? "2px"
                                                    : "",
                                                lineHeight: defaultPartialCC
                                                    ? ""
                                                    : "18px",
                                                fontSize: "14px"
                                            }
                                        }}
                                        {...customTextFieldStyle}
                                    />
                                </Grid>
                            )}
                        {loggedInUser.company?.collectpayment &&
                            dialogMode !== "ADD" && (
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        display: "flex",
                                        alignSelf: "center"
                                    }}
                                >
                                    <Button
                                        onClick={handleUpdateCCDetailsClick}
                                        variant="text"
                                        sx={{ minWidth: "0px", padding: "0px" }}
                                    >
                                        <Typography
                                            variant="button"
                                            sx={{
                                                textTransform: "none",
                                                lineHeight: "16px"
                                            }}
                                        >
                                            {`Update`}
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <ClientAddressInput
                                setPlaceId={savePlaceId}
                                setAddress={handleAddress}
                                formWidth={"100%"}
                                formMargin={"0px"}
                                formMarginLeft={"0px"}
                                label={"Address"}
                                addressOneLine={address ? address : ""}
                                autocompleteService={autocompleteService}
                                smallSize={true}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ height: "40px" }}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    sx={{
                                        height: "20px",
                                        margin: "0px 0px 0px -11px"
                                    }}
                                    control={
                                        <Switch
                                            checked={contactConsent}
                                            onChange={(event) =>
                                                setContactConsent(
                                                    event.target.checked
                                                )
                                            }
                                            value={contactConsent}
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "primary checkbox"
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: "14px"
                                            }}
                                        >
                                            {`Send appointment reminder emails`}
                                        </Typography>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Notes"
                                multiline
                                fullWidth
                                variant="outlined"
                                rows={3}
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "38px"
                                        }
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        fontSize: "14px"
                                    }
                                }}
                                value={notes}
                                inputRef={(input) => {
                                    if (isNotesFocused) {
                                        input && input.focus();
                                    }
                                }}
                                onFocus={(e) => {
                                    setIsNotesFocused(true);
                                    if (focusNotes) {
                                        e.currentTarget.setSelectionRange(
                                            e.currentTarget.value.length,
                                            e.currentTarget.value.length
                                        );
                                    }
                                }}
                                onBlur={() => setIsNotesFocused(false)}
                                InputLabelProps={{
                                    style: {
                                        top:
                                            notes || isNotesFocused
                                                ? "2px"
                                                : "",
                                        fontSize: "14px"
                                    }
                                }}
                                onChange={(e) => handleNotes(e.target.value)}
                            />
                        </Grid>
                        {clientNotes.updatedBy && (
                            <Grid
                                container
                                item
                                xs={12}
                                style={{
                                    paddingTop: "0px",
                                    justifyContent: "space-between",
                                    height: "16px"
                                }}
                            >
                                <Typography className={classes.notesUpdateText}>
                                    Last edited by {clientNotes.updatedBy} on{" "}
                                    {moment(clientNotes.updatedAt).format(
                                        "YYYY-MM-DD HH:mm"
                                    )}
                                </Typography>
                                <Button
                                    onClick={handleClearNotes}
                                    variant="text"
                                    className={classes.clearButton}
                                >
                                    <Typography
                                        variant="button"
                                        style={{
                                            textTransform: "none"
                                        }}
                                    >
                                        {`Clear`}
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                        {clientUser.registered &&
                            dialogMode !== "ADD" &&
                            clientUser.userRole === USERROLE.CLIENT && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "36px" }}
                                >
                                    <Button
                                        className={classes.resetPasswordButton}
                                        onClick={handleResetPasswordClick}
                                        variant="text"
                                    >
                                        <Typography
                                            variant="button"
                                            sx={{
                                                textTransform: "none",
                                                lineHeight: "16px"
                                            }}
                                        >
                                            {`Reset password`}
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                </DialogContent>
                <DialogActions
                    className={classes.customDialogActions}
                    disableSpacing
                >
                    <Button
                        className={classes.customDialogButton}
                        onClick={() => {
                            resetErrorStates();
                            onClose();
                        }}
                        disabled={userSaveInProgess}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.customDialogButton}
                        variant="contained"
                        color="primary"
                        disabled={userSaveInProgess || !formDetailsChanged}
                        onClick={() => {
                            if (
                                emailAddress !== originalEmailAddress &&
                                dialogMode !== "ADD"
                            ) {
                                setEmailChangeWarningModal(true);
                            } else {
                                handleSaveClientUser();
                            }
                        }}
                    >
                        Save
                        {userSaveInProgess && (
                            <CircularProgress
                                size={18}
                                sx={{
                                    position: "absolute"
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ClientUserInfoModal;
