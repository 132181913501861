import React from "react";
import {
    Authenticator,
    ThemeProvider,
    View,
    Button,
    useAuthenticator,
    Image,
    Grid
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { signIn, getCurrentUser } from "aws-amplify/auth";
import { updateUser } from "../graphql/mutations";
import { getUser } from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { Typography } from "@mui/material";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);
I18n.setLanguage("en");
I18n.putVocabularies({
    en: {
        "Sign in": "Log in",
        "Send code": "Send code",
        "Back to Sign In": "Back to login"
    }
});

export function CustomSignIn(props) {
    const theme = {
        tokens: {
            components: {
                authenticator: {
                    router: {
                        boxShadow: "0 0 0 0",
                        borderWidth: "0"
                    }
                },
                button: {
                    primary: {
                        backgroundColor: "#3A85E6",
                        _hover: {
                            backgroundColor: "#3a70e6"
                        }
                    },
                    link: {
                        color: "#3A85E6",
                        _hover: {
                            backgroundColor: "#9cd4f7",
                            color: "#3A85E6"
                        }
                    }
                },
                fieldcontrol: {
                    _focus: {
                        boxShadow: `0 0 0 1px #3A85E6`
                    }
                }
            }
        }
    };
    const components = {
        SignIn: {
            Header() {
                return (
                    <>
                        <div
                            style={{
                                margin: "auto",
                                width: "90%",
                                padding: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: 400,
                                    color: "#313131",
                                    textAlign: "center"
                                }}
                                variant="h4"
                            >
                                Login
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    color: "#313131",
                                    textAlign: "center"
                                }}
                                variant="subtitle1"
                            >
                                Login to access your MarketBox account !
                            </Typography>
                        </div>
                    </>
                );
            },
            Footer() {
                const { toForgotPassword } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="500"
                            fontSize="14"
                            onClick={toForgotPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot Password
                        </Button>
                    </View>
                );
            }
        },
        ForgotPassword: {
            Header() {
                return (
                    <>
                        <div
                            style={{
                                margin: "auto",
                                width: "90%",
                                padding: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: 400,
                                    color: "#313131",
                                    textAlign: "center"
                                }}
                                variant="h4"
                            >
                                Forgot your password?
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    color: "#313131",
                                    textAlign: "center"
                                }}
                                variant="subtitle1"
                            >
                                Don't worry, happens to all of us. Enter your
                                Email below to recover your password.
                            </Typography>
                        </div>
                    </>
                );
            }
        },
        ConfirmResetPassword: {
            Header() {
                return (
                    <>
                        <div
                            style={{
                                margin: "auto",
                                width: "90%",
                                padding: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: 400,
                                    color: "#313131",
                                    textAlign: "center"
                                }}
                                variant="h4"
                            >
                                Reset your password
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    color: "#313131",
                                    textAlign: "center"
                                }}
                                variant="subtitle1"
                            >
                                Enter your code and update your password below
                            </Typography>
                        </div>
                    </>
                );
            }
        },
        ForceNewPassword: {
            Header() {
                return (
                    <>
                        <div
                            style={{
                                margin: "auto",
                                width: "90%",
                                padding: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: 400,
                                    color: "#313131",
                                    textAlign: "center",
                                    marginBottom: "30px"
                                }}
                                variant="h4"
                                noWrap
                            >
                                Change password
                            </Typography>
                        </div>
                    </>
                );
            }
        }
    };

    const formFields = {
        signIn: {
            username: {
                placeholder: "Enter your email",
                labelHidden: true
            },
            password: {
                placeholder: "Enter your password",
                labelHidden: true
            }
        },
        forgotPassword: {
            username: {
                placeholder: "Enter your email",
                labelHidden: true
            }
        },
        confirmResetPassword: {
            confirmation_code: {
                placeholder: "Enter your Confirmation Code",
                labelHidden: true
            },
            password: {
                placeholder: "Enter your Password",
                labelHidden: true
            },
            confirm_password: {
                placeholder: "Re-enter your Password",
                labelHidden: true
            }
        },
        forceNewPassword: {
            password: {
                placeholder: "Enter your new password",
                labelHidden: true
            },
            confirm_password: {
                placeholder: "Re-enter your new password",
                labelHidden: true
            }
        }
    };

    const services = {
        async handleSignIn(formData) {
            try {
                let { username, password } = formData;
                username = username.toLowerCase(); //email
                const signedInDetails = await signIn({
                    username,
                    password: password.trim()
                });
                console.log("isSignedIn, nextStep", signedInDetails);
                if (signedInDetails?.isSignedIn) {
                    const loggedInUser = await getCurrentUser();

                    const { username: useruuid } = loggedInUser;
                    const user = await graphql(
                        graphqlOperation(getUser, {
                            id: useruuid
                        })
                    );

                    localStorage.setItem(
                        "loggedInCongnitoUser",
                        JSON.stringify(loggedInUser)
                    );
                    localStorage.setItem(
                        "loggedInMbUser",
                        JSON.stringify(user.data.getUser)
                    );
                    props.setMbUserInCache(user.data.getUser);
                    console.log("logged in loggedInUser", loggedInUser);
                    // it's hard to clear the appState if cognito token expired and the user got logged out, so we can do on fresh login
                    localStorage.removeItem("appState");
                    const prevLoginInfo = JSON.parse(
                        user.data.getUser.LoginInfo
                    );
                    const newLoginCount = prevLoginInfo
                        ? ++prevLoginInfo.loginCount
                        : 1;
                    const loginInfo = {
                        lastLoginDate: new Date(),
                        loginCount: newLoginCount
                    };
                    const LoginInfo = JSON.stringify(loginInfo);
                    const updateMBUserInput = {
                        role: user.data.getUser.role,
                        emailaddress: user.data.getUser.emailaddress,
                        id: useruuid,
                        LoginInfo
                    };
                    await graphql(
                        graphqlOperation(updateUser, {
                            input: updateMBUserInput
                        })
                    );
                }

                return signedInDetails;
            } catch (e) {
                console.log(
                    "SIGN IN ERROR ***********************************"
                );
                console.log(e);
                throw e;
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <View
                style={{
                    backgroundColor: "white",
                    minHeight: "100vh"
                }}
            >
                <Grid justifyContent="center" alignItems="center">
                    <View
                        height="200"
                        marginTop={{
                            base: "7rem",
                            large: "9rem"
                        }}
                        marginBottom={{
                            base: "4rem",
                            large: "6rem"
                            // base: tokens.space.xxxl,
                            // large: tokens.space.xxxl
                        }}
                    >
                        <Image
                            alt="MarketBox logo"
                            src="https://marketbox-prod-booknow.s3.amazonaws.com/public/marketbox/logo/MB-2.png"
                            height={{ base: "24px", large: "32px" }}
                            width={{ base: "172px", large: "254px" }}
                        />
                    </View>
                </Grid>
                <Authenticator
                    formFields={formFields}
                    components={components}
                    services={services}
                    hideSignUp={true}
                />
            </View>
        </ThemeProvider>
    );
}
