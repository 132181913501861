import { postApi } from "./AmplifyServices";
export async function auditClientPackageUpdate(actingUser, clientPackage) {
    await auditClientPackage(actingUser, clientPackage, "UPDATE");
}
export async function auditClientPackageAdd(actingUser, clientPackage) {
    await auditClientPackage(actingUser, clientPackage, "CREATE");
}
export async function auditUserCreate(actingUser, userChange) {
    await auditUser(actingUser, userChange, null, null, "CREATE");
}
export async function auditUserUpdate(actingUser, userChange, newInfo) {
    await auditUser(actingUser, userChange, newInfo, null, "UPDATE");
}
export async function auditUserDelete(actingUser, userChange) {
    await auditUser(actingUser, userChange, null, null, "DELETE");
}
export async function auditUserResetPw(actingUser, userChange, resetAction) {
    await auditUser(actingUser, userChange, null, resetAction, "UPDATE");
}
export async function auditCompanyUpdate(
    actingUser,
    companyChange,
    newInfo,
    tabValue
) {
    await auditCompany(actingUser, companyChange, newInfo, tabValue, "UPDATE");
}
export async function auditCompanyCreate(actingUser, companyChange) {
    await auditCompany(actingUser, companyChange, null, null, "CREATE");
}
export async function auditCompanyDelete(actingUser, companyChange) {
    await auditCompany(actingUser, companyChange, null, null, "DELETE");
}
export async function auditCompanyLocCreate(actingUser, companyLoc) {
    await auditCompanyLoc(actingUser, companyLoc, null, "CREATE");
}
export async function auditCompanyLocDelete(actingUser, companyLoc) {
    await auditCompanyLoc(actingUser, companyLoc, null, "DELETE");
}
export async function auditCompanyLocUpdate(actingUser, companyLoc, newInfo) {
    await auditCompanyLoc(actingUser, companyLoc, newInfo, "UPDATE");
}
export async function auditProviderCreate(actingUser, providerChange) {
    await auditProvider(actingUser, providerChange, null, "CREATE");
}
export async function auditProviderUpdate(actingUser, providerChange, newInfo) {
    await auditProvider(actingUser, providerChange, newInfo, "UPDATE");
}
export async function auditProviderLocCreate(actingUser, providerChange) {
    await auditProviderLoc(actingUser, providerChange, null, "CREATE");
}
export async function auditProviderLocUpdate(
    actingUser,
    providerChange,
    newInfo
) {
    await auditProviderLoc(actingUser, providerChange, newInfo, "UPDATE");
}
export async function auditProviderLocDelete(actingUser, providerChange) {
    await auditProviderLoc(actingUser, providerChange, null, "DELETE");
}
export async function auditProviderSchedCreate(
    actingUser,
    schedChange,
    newInfo
) {
    await auditProviderSched(actingUser, schedChange, newInfo, true, "CREATE");
}
export async function auditProviderSchedDelete(
    actingUser,
    schedChange,
    newInfo
) {
    await auditProviderSched(actingUser, schedChange, newInfo, true, "DELETE");
}
export async function auditProviderSchedActivate(
    actingUser,
    schedChange,
    newInfo
) {
    await auditProviderSched(
        actingUser,
        schedChange,
        newInfo,
        "ACTIVATE",
        "UPDATE"
    );
}
export async function auditProviderSchedEdit(actingUser, schedChange, newInfo) {
    await auditProviderSched(
        actingUser,
        schedChange,
        newInfo,
        "EDIT_SCHED",
        "UPDATE"
    );
}
export async function auditProviderSchedAddtb(
    actingUser,
    schedChange,
    newInfo
) {
    await auditProviderSched(
        actingUser,
        schedChange,
        newInfo,
        "ADD_TB",
        "CREATE"
    );
}
export async function auditProviderSchedEdittb(
    actingUser,
    schedChange,
    newInfo
) {
    await auditProviderSched(
        actingUser,
        schedChange,
        newInfo,
        "EDIT_TB",
        "UPDATE"
    );
}
export async function auditProviderBlockTimeCreate(actingUser, schedChange) {
    await auditProviderSched(
        actingUser,
        schedChange,
        null,
        "ADD_BLOCKTIME",
        "CREATE"
    );
}
export async function auditProviderBlockTimeDelete(actingUser, schedChange) {
    await auditProviderSched(
        actingUser,
        schedChange,
        null,
        "DEL_BLOCKTIME",
        "DELETE"
    );
}
export async function auditProviderBlockTimeUpdate(actingUser, schedChange) {
    await auditProviderSched(
        actingUser,
        schedChange,
        null,
        "UPDATE_BLOCKTIME",
        "UPDATE"
    );
}
export async function auditPackagesCreate(actingUser, pkgChange) {
    await auditPackages(actingUser, pkgChange, null, "CREATE");
}
export async function auditPackagesDelete(actingUser, pkgChange) {
    await auditPackages(actingUser, pkgChange, null, "DELETE");
}
export async function auditPackagesUpdate(actingUser, pkgChange, newInfo) {
    await auditPackages(actingUser, pkgChange, newInfo, "UPDATE");
}
export async function auditServiceCreate(actingUser, servChange) {
    await auditServiceType(actingUser, servChange, null, false, "CREATE");
}
export async function auditServiceDelete(actingUser, servChange) {
    await auditServiceType(actingUser, servChange, null, false, "DELETE");
}
export async function auditServiceUpdate(actingUser, servChange, newInfo) {
    await auditServiceType(actingUser, servChange, newInfo, false, "UPDATE");
}
export async function auditCategoryCreate(actingUser, servChange) {
    await auditServiceType(actingUser, servChange, null, true, "CREATE");
}
export async function auditCategoryDelete(actingUser, servChange) {
    await auditServiceType(actingUser, servChange, null, true, "DELETE");
}
export async function auditCategoryUpdate(actingUser, servChange, newInfo) {
    await auditServiceType(actingUser, servChange, newInfo, true, "UPDATE");
}
export async function auditPriceCreate(actingUser, priceChange, province) {
    await auditRegionalPricing(
        actingUser,
        priceChange,
        province,
        null,
        "CREATE"
    );
}
export async function auditPriceDelete(actingUser, priceChange) {
    await auditRegionalPricing(actingUser, priceChange, null, null, "DELETE");
}
export async function auditPriceUpdate(
    actingUser,
    priceChange,
    province,
    newInfo
) {
    await auditRegionalPricing(
        actingUser,
        priceChange,
        province,
        newInfo,
        "UPDATE"
    );
}
export async function auditSkillsCreate(actingUser, skillChange) {
    await auditSkills(actingUser, skillChange, null, "CREATE");
}
export async function auditSkillsDelete(actingUser, skillChange) {
    await auditSkills(actingUser, skillChange, null, "DELETE");
}
export async function auditSkillsUpdate(actingUser, skillChange, newInfo) {
    await auditSkills(actingUser, skillChange, newInfo, "UPDATE");
}
export async function auditRatingsCreate(actingUser, ratingChange) {
    await auditRatings(actingUser, ratingChange, true, null, "CREATE");
}
export async function auditRatingsUpdate(actingUser, ratingChange, newInfo) {
    await auditRatings(actingUser, ratingChange, true, newInfo, "UPDATE");
}
export async function auditAcceptBookingOnBehalf(
    actingUser,
    bookingChange,
    provider,
    status
) {
    await auditPendingBookings(
        actingUser,
        bookingChange,
        provider,
        status,
        "UPDATE"
    );
}
export async function auditAcceptBooking(actingUser, bookingChange, status) {
    await auditPendingBookings(
        actingUser,
        bookingChange,
        null,
        status,
        "UPDATE"
    );
}
export async function auditDeclineBooking(actingUser, bookingChange, status) {
    await auditPendingBookings(
        actingUser,
        bookingChange,
        null,
        status,
        "UPDATE"
    );
}
async function auditClientPackage(actingUser, clientPackage, userAction) {
    if (actingUser && actingUser.company && clientPackage) {
        try {
            console.log("in auditClientPackage audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            let {
                id: clientPackageId,
                clientId,
                userId,
                servicetypeId,
                initialQuantity,
                usedQuantity,
                status,
                active,
                createAt,
                updatedAt
            } = clientPackage;
            let {
                company: pkgCompany,
                servicetype: pkgServicetype,
                ...restOfPackage
            } = clientPackage.package;
            let {
                company,
                category,
                packages,
                providers,
                ...restOfServicetype
            } = clientPackage.servicetype;

            const resourceId = clientPackage.id;
            const resourceType = "CLIENT_PKG";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };

            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        id: clientPackageId,
                        clientId,
                        userId,
                        servicetypeId,
                        initialQuantity,
                        usedQuantity,
                        status,
                        active,
                        servicetype: restOfServicetype,
                        package: restOfPackage,
                        createAt,
                        updatedAt
                    }
                }
            });
            console.log(JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

export async function auditClientCCUpdate(actingUser, client, prevCCInfo) {
    if (actingUser && actingUser.company && client) {
        try {
            // console.log("in auditClientCCUpdate audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = client.id;
            const resourceType = "CLIENT";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };

            let {
                id: clientId,
                ClientInfo,
                company,
                user: clientUser,
                ...restOfClient
            } = client;

            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "UPDATE",
                    data: {
                        prevCCInfo,
                        client: {
                            id: clientId,
                            ...restOfClient
                        }
                    }
                }
            });
            console.log(JSON.stringify(result));
        } catch (e) {
            console.log(e);
        }
    }
}

export async function auditClientNotesUpdate(
    actingUser,
    client,
    newNotes,
    prevNotes
) {
    if (actingUser && actingUser.company && client) {
        try {
            // console.log("in auditClientCCUpdate audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = client.id;
            const resourceType = "CLIENT_NOTES";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };

            let {
                id: clientId,
                company,
                user: clientUser,
                ...restOfClient
            } = client;

            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "UPDATE",
                    data: {
                        newNotes,
                        prevNotes,
                        client: {
                            id: clientId,
                            firstname: clientUser.firstname,
                            lastname: clientUser.lastname
                        }
                    }
                }
            });
            console.log("successfully audited notes", JSON.stringify(result));
        } catch (e) {
            console.log(e);
        }
    } else {
        console.log("ERROR: Unable to audit customer notes");
    }
}

async function auditUser(
    actingUser,
    userChange,
    newInfo,
    resetAction,
    userAction
) {
    if (actingUser && actingUser.company && userChange) {
        try {
            console.log("in auditUser audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = userChange.id;
            const resourceType = "USER";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        userChange,
                        newInfo,
                        resetAction
                    }
                }
            });
            console.log(JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditCompany(
    actingUser,
    companyChange,
    newInfo,
    tabValue,
    userAction
) {
    if (actingUser && actingUser.company && companyChange) {
        try {
            console.log("in auditCompany audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = companyChange.id;
            const resourceType = "COMPANY";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        companyChange,
                        newInfo,
                        tabValue
                    }
                }
            });
            console.log(JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditCompanyLoc(actingUser, companyLoc, newInfo, userAction) {
    if (actingUser && actingUser.company && companyLoc) {
        try {
            console.log("in auditCompanyLoc audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = companyLoc.id;
            const resourceType = "COMPANY_LOCATION";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        companyLoc,
                        newInfo
                    }
                }
            });
            console.log(JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

export async function auditChargesRefunds(
    actingUser,
    chargesRefunds,
    refundData
) {
    if (actingUser && actingUser.company && chargesRefunds) {
        try {
            console.log("in auditChargesRefunds audit ");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = chargesRefunds.id;
            const resourceType = "BILLING_TRANSACTION";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "CREATE",
                    data: {
                        chargesRefunds,
                        clientDetails: chargesRefunds.client.user,
                        refundData
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditProvider(actingUser, providerChange, newInfo, userAction) {
    if (actingUser && actingUser.company && providerChange) {
        try {
            console.log("in auditProvider audit");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = providerChange.id;
            const resourceType = "PROVIDER";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        providerChange,
                        newInfo
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditProviderLoc(
    actingUser,
    providerChange,
    newInfo,
    userAction
) {
    if (actingUser && actingUser.company && providerChange) {
        try {
            console.log("in auditProviderLoc audit");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = providerChange.id;
            const resourceType = "PROVIDER_LOCATION";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        providerChange,
                        newInfo
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditProviderSched(
    actingUser,
    schedChange,
    newInfo,
    schedBlock,
    userAction
) {
    if (actingUser && actingUser.company && schedChange) {
        try {
            console.log("in auditProviderSched audit");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = schedChange.id;
            const resourceType = "PROVIDER_SCHEDULE";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        schedChange,
                        newInfo,
                        schedBlock
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditPackages(actingUser, pkgChange, newInfo, userAction) {
    if (actingUser && actingUser.company && pkgChange) {
        try {
            console.log("in auditPackages audit");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = pkgChange.id;
            const resourceType = "PACKAGE";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        pkgChange,
                        newInfo
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditServiceType(
    actingUser,
    servChange,
    newInfo,
    isCategory,
    userAction
) {
    if (actingUser && actingUser.company && servChange) {
        try {
            console.log("in auditServiceTypes");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = servChange.id;
            const resourceType = "SERVICE_TYPE";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        servChange,
                        newInfo,
                        isCategory
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditRegionalPricing(
    actingUser,
    priceChange,
    province,
    newInfo,
    userAction
) {
    if (actingUser && actingUser.company && priceChange) {
        try {
            console.log("in auditRegionalPricing");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = priceChange.id;
            const resourceType = "REGIONAL_PRICING";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        priceChange,
                        province,
                        newInfo
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditSkills(actingUser, skillChange, newInfo, userAction) {
    if (actingUser && actingUser.company && skillChange) {
        try {
            console.log("in auditSkills");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = skillChange.id;
            const resourceType = "SKILL";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        skillChange,
                        newInfo
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditRatings(
    actingUser,
    ratingChange,
    isRating,
    newInfo,
    userAction
) {
    if (actingUser && actingUser.company && ratingChange) {
        try {
            console.log("in auditRatings");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = ratingChange.id;
            const resourceType = "PROVIDER";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        ratingChange,
                        isRating,
                        newInfo
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

async function auditPendingBookings(
    actingUser,
    bookingChange,
    provider,
    status,
    userAction
) {
    if (actingUser && actingUser.company && bookingChange) {
        try {
            console.log("in auditPendingBookings");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = bookingChange.id;
            const resourceType = "BOOKING";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction,
                    data: {
                        bookingChange,
                        provider,
                        status
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

export async function auditBookingAddChrg(
    actingUser,
    bookingChange,
    addAmount,
    status
) {
    if (actingUser && actingUser.company && bookingChange && addAmount) {
        try {
            console.log("in auditBookingAddChrg");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = addAmount.id;
            const resourceType = "BOOKING";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "UPDATE",
                    data: {
                        bookingChange,
                        addAmount,
                        status
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

export async function auditBookingState(actingUser, bookingChange, status) {
    if (actingUser && actingUser.company && bookingChange) {
        try {
            console.log("auditBookingState");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = bookingChange.id;
            const resourceType = "BOOKING";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "UPDATE",
                    data: {
                        bookingChange,
                        status
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

export async function auditOverrideBooking(
    actingUser,
    oldBooking,
    newBooking,
    status
) {
    if (actingUser && actingUser.company && oldBooking && newBooking) {
        try {
            console.log("in auditOverrideBooking");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = newBooking.id;
            const resourceType = "BOOKING";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "UPDATE",
                    data: {
                        oldBooking,
                        newBooking,
                        status
                    }
                }
            });
            console.log("success false?", JSON.stringify(result));
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}

export async function auditOrderNotesUpdate(
    actingUser,
    order,
    bookingId,
    newNotes,
    oldNotes,
    status
) {
    if (actingUser && actingUser.company) {
        try {
            console.log("in auditOrderNotesUpdate");
            let {
                companyId,
                role,
                firstname,
                lastname,
                emailaddress,
                id: actingUserId
            } = actingUser;
            const resourceId = order.id;
            const resourceType = "BOOKING";
            const user = {
                firstname,
                lastname,
                emailaddress,
                role,
                id: actingUserId
            };
            const result = await postApi("auditapi", "/audit", {
                body: {
                    companyId,
                    resourceId,
                    resourceType,
                    user,
                    userAction: "UPDATE",
                    data: {
                        order,
                        bookingId,
                        newNotes,
                        oldNotes,
                        status
                    }
                }
            });
            console.log(
                "successfully audited booking notes",
                JSON.stringify(result)
            );
        } catch (e) {
            console.log("there was an error", e);
        }
    }
}
