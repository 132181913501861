import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    Typography
} from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function ReschedulePolicyModal(props) {
    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={props.onClose}
        >
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                    >
                        Reschedule Policy
                    </Typography>
                    <InfoOutlinedIcon sx={{ color: "error.main" }} />
                </Box>
                <Box sx={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
                    <Typography>
                        Bookings cannot be rescheduled by clients within{" "}
                        {`${props.policyTime} ${
                            props.policyTime > 1 ? "hours" : "hour"
                        }`}{" "}
                        of the start time. Please contact your provider to
                        reschedule the booking.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Button variant="contained" onClick={props.onClose}>
                        Okay
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ReschedulePolicyModal;
