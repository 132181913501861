import { execReadBySortkey } from "./DBService";
import { userByCompany, searchByName } from "../queries/ListBookingsQueries";
export const searchUsers = async (str, companyId, role = "CLIENT") => {
    let usersByEmail, usersByFirstName;
    try {
        if (str && str.length >= 1) {
            if (str && str.includes("@")) {
                usersByEmail = await execReadBySortkey({
                    opname: "userByCompany",
                    op: userByCompany,
                    id: {
                        companyId: companyId
                    },
                    skey: {
                        roleEmailaddress: {
                            beginsWith: {
                                role,
                                emailaddress: str
                            }
                        }
                    },
                    filter: { deleted: { ne: true } }
                });

                if (usersByEmail && usersByEmail.items) {
                    return [...usersByEmail.items];
                }
            } else {
                usersByFirstName = await execReadBySortkey({
                    opname: "searchByName",
                    op: searchByName,
                    id: {
                        companyId: companyId
                    },

                    skey: {
                        lc_name: {
                            beginsWith: str.toLowerCase()
                        }
                    },
                    filter: {
                        and: [{ role: { eq: role } }, { deleted: { ne: true } }]
                    }
                });

                if (usersByFirstName && usersByFirstName.items) {
                    return [...usersByFirstName.items];
                }
            }
        }
        return [];
    } catch (error) {
        console.log("not able to search user", error);
    }
};
