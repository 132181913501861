import React, { useState } from "react";
import throttle from "lodash/throttle";
import { useStyles } from "../../styles/MainNavbarStyles";
import Button from "@mui/material/Button";
import { TextField, Snackbar } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

const autocompleteService = { current: null };
const placeDetailsService = { current: null };

function AddressForm(props) {
    const classes = useStyles();
    const [addressOneLine, setAddressOneLine] = useState("");
    const [street, SetStreet] = useState("");
    const [suite, setSuite] = useState("");
    const [city, SetCity] = useState("");
    const [state, SetState] = useState("");
    const [postalCode, SetPostalCode] = useState("");
    const [country, SetCountry] = useState("");
    const [checked, SetChecked] = useState(true);
    const [coords, SetCoords] = useState({});
    const [locations, setLocations] = useState([]);
    const [showSuggest, setShowSuggest] = useState(false);
    const [selectLoc, setSelectLoc] = useState();
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    function resetAddress() {
        setAddressOneLine("");
        SetStreet("");
        SetCity("");
        SetState("");
        SetPostalCode("");
        SetCountry("");
        SetChecked(false);
        SetCoords({});
    }

    function onClear(evt) {
        resetAddress();
    }

    function loadScript(src, position, id) {
        if (!position) {
            return;
        }

        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", id);
        script.src = src;
        position.appendChild(script);
    }

    const fetch = React.useMemo(
        () =>
            throttle((input, callback) => {
                autocompleteService.current.getPlacePredictions(
                    input,
                    callback
                );
            }, 200),
        []
    );

    function handleAddressCheck(evt) {
        SetChecked(false);

        props.save(
            addressOneLine,
            street,
            city,
            state,
            postalCode,
            country,
            coords.lat,
            coords.lon,
            true
        );
    }

    function renderCoords() {
        if (!checked) {
            return;
        }

        if (coords === null) {
            return (
                <div className="alert alert-warning" role="alert">
                    <b>Invalid.</b> The address is not recognized.
                </div>
            );
        } else {
            return (
                <div>
                    <b>Valid Address.</b> <br></br>Location is {coords.lat},{" "}
                    {coords.lon}.
                </div>
            );
        }
    }

    const handleSuiteChange = (e) => {
        console.log(
            "handleSuiteChange fired. e.target.value = " +
                JSON.stringify(e.target.value)
        );
        setSuite(e.target.value);
    };

    function onQuery(evt) {
        const query = evt.target.value;
        setAddressOneLine(query);
        console.log("query = " + query);

        if (
            !autocompleteService.current &&
            !placeDetailsService.current &&
            window.google
        ) {
            console.log("Call Google API");
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
            placeDetailsService.current =
                new window.google.maps.places.PlacesService(
                    document.getElementById("map")
                );
        }

        if (!document.querySelector("#google-maps")) {
            const url =
                "https://maps.googleapis.com/maps/api/js?key=" +
                process.env.REACT_APP_GOOGLE_MAP_KEY +
                "&libraries=places";

            loadScript(url, document.querySelector("head"), "google-maps");
        }

        if (addressOneLine) {
            if (autocompleteService.current) {
                fetch({ input: query }, (results) => {
                    setLocations(results || []);
                    if (results && results.length > 0) {
                        setShowSuggest(true);
                        SetChecked(true);
                    } else {
                        setSnackMsg(
                            "Please enter a valid address starting with street number."
                        );
                        setMsgOpen(true);
                        resetAddress();
                    }
                });
            }
            console.log(locations);
        } else {
            // clear lookup fields
            SetStreet("");
            SetCity("");
            SetState("");
            SetPostalCode("");
            SetCountry("");
            SetChecked(false);
        }
    }

    const _handleSetSelected = (loc) => {
        const componentForm = {
            street_number: "short_name",
            route: "short_name",
            locality: "long_name",
            administrative_area_level_1: "short_name",
            country: "long_name",
            postal_code: "short_name"
        };

        let params = {
            placeId: loc.place_id,
            fields: ["address_components", "formatted_address", "geometry"]
        };

        placeDetailsService.current.getDetails(params, (place, status) => {
            if (status && status === "OK") {
                let populateStreet = "";
                //Lat and lon were previously set as functions, now set as result of functions.
                //Verify it isn't intentional to have them set as functions.
                const lat = place.geometry.location.lat();
                const lon = place.geometry.location.lng();
                setAddressOneLine(place.formatted_address);

                SetCoords({
                    lat: lat,
                    lon: lon
                });
                let street_name_exists = false;
                let street_number_exists = false;
                for (var i = 0; i < place.address_components.length; i++) {
                    let addressType = place.address_components[i].types[0];

                    if (componentForm[addressType]) {
                        let addressAttribute =
                            place.address_components[i][
                                componentForm[addressType]
                            ];

                        switch (addressType) {
                            case "street_number":
                                populateStreet += addressAttribute;
                                populateStreet += " ";
                                street_number_exists = true;
                                break;
                            case "route":
                                populateStreet += addressAttribute;
                                SetStreet(populateStreet);
                                street_name_exists = true;
                                break;
                            case "locality":
                                SetCity(addressAttribute);
                                break;
                            case "administrative_area_level_1":
                                SetState(addressAttribute);
                                break;
                            case "country":
                                SetCountry(addressAttribute);
                                break;
                            case "postal_code":
                                SetPostalCode(addressAttribute);
                        }
                    }
                }
                if (!street_name_exists || !street_number_exists) {
                    setSnackMsg(
                        "We are not able to recognize the address you have entered. Please check the address via a Google search and use the exact address and format returned by Google."
                    );
                    setMsgOpen(true);
                    resetAddress();
                }
            } else {
                setSnackMsg(
                    "Please enter a valid address starting with street number."
                );
                setMsgOpen(true);
                resetAddress();
            }
        });
        setSelectLoc(loc);
        setShowSuggest(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <div id="map" />
            <Grid container spacing={2} className="classes.root">
                <Grid item xs={12} sm={5}>
                    <TextField
                        label="Full Address"
                        id="{props.id}"
                        fullWidth
                        value={addressOneLine}
                        onChange={onQuery}
                        placeholder="start typing"
                    />
                    {locations.length > 0 && showSuggest && (
                        <div style={{ boxShadow: "0px 1px 2px #ccc" }}>
                            {locations.map((loc) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    component="div"
                                    key={loc.description}
                                    onClick={() => _handleSetSelected(loc)}
                                >
                                    {loc.description}
                                </MenuItem>
                            ))}
                        </div>
                    )}
                </Grid>
            </Grid>
            {!!selectLoc && (
                <>
                    <Grid container spacing={2} className="classes.root">
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Street"
                                id="street"
                                fullWidth
                                value={street}
                                placeholder="Street"
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <TextField
                                label="Suite/Floor"
                                id="suite"
                                fullWidth
                                value={suite}
                                onChange={handleSuiteChange}
                                placeholder="Suite"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="classes.root">
                        <Grid item xs={6} sm={2}>
                            <TextField
                                label="Country"
                                id="country"
                                value={country}
                                placeholder="Country"
                            />
                            <TextField
                                label="State"
                                id="state"
                                value={state}
                                placeholder="State"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                label="City"
                                id="city"
                                value={city}
                                placeholder="City"
                            />
                            <TextField
                                label="Zip/Postal Code"
                                id="postalCode"
                                value={postalCode}
                                placeholder="Zip/Postal"
                            />
                        </Grid>
                        <Grid container spacing={2} className="classes.root">
                            <Grid item xs={12} sm={5}>
                                {renderCoords()}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className="classes.root">
                            <Grid item xs={12} sm={4}>
                                <Button
                                    onClick={handleAddressCheck}
                                    variant="contained"
                                    color="primary"
                                    disabled={!checked}
                                >
                                    Save
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    onClick={props.cancel}
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

export default AddressForm;
