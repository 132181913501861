import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import StripeInput from "./StripeInput";
import { getUserFromCache } from "../../user/UserCommon";
// prettier-ignore
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import {
    graphql,
    graphqlOperation,
    postApi
} from "../../modules/AmplifyServices";
// prettier-ignore
import { Grid, Button, Typography, Snackbar, Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";
import "./stripe.css";
import { updateClient } from "../../graphql/mutations";
import CircularProgress from "@mui/material/CircularProgress";
import { getClient } from "../../graphql/queries";
import { auditClientCCUpdate } from "../../modules/Audit";
const CCForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [state, setState] = useState({
        errorMessage: "",
        saveClicked: false
    });

    const createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: "16px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#c23d4b"
                }
            }
        };
    };

    const handleChange = ({ error }) => {
        if (error) {
            setState({
                ...state,
                errorMessage:
                    error.message +
                    " Please fix this before entering another field."
            });
        } else {
            setState({ ...state, errorMessage: "" });
        }
    };

    const handleSave = async () => {
        setState({ ...state, saveClicked: true });
        const cne = elements.getElement(CardNumberElement);
        const connectedAccount =
            props.company.stripeConnectEnabled && props.company.stripeAccount
                ? { stripeAccount: props.company.stripeAccount }
                : null;
        let { token, error } = await stripe.createToken(cne, connectedAccount);
        let resultFromStripe = null;
        try {
            if (props.client.stripeCustomerId) {
                //update existing stripe customer record
                resultFromStripe = await postApi(
                    "stripesavecard",
                    "/stripeupdatecard",
                    {
                        body: {
                            token,
                            stripe_customerId: props.client.stripeCustomerId,
                            companyId: props.companyId,
                            stripeAccount: props.company.stripeAccount,
                            stripeConnectEnabled:
                                props.company.stripeConnectEnabled
                        }
                    }
                );
            } else {
                //create new stripe customer record
                resultFromStripe = await postApi(
                    "stripesavecard",
                    "/savecardinfo",
                    {
                        body: {
                            token,
                            emailaddress: props.client.user.emailaddress,
                            companyId: props.companyId,
                            stripeAccount: props.company.stripeAccount,
                            stripeConnectEnabled:
                                props.company.stripeConnectEnabled
                        }
                    }
                );
            }

            if (resultFromStripe) {
                const stripeCustomerId = resultFromStripe.customer.id;
                const cclast4 = resultFromStripe.customer.sources.data[0].last4;

                props.onSave({
                    resultFromStripe,
                    cclast4,
                    stripeCustomerId
                });

                setState({ ...state, invalidNum: false });
                setState({ ...state, invalidExpDate: false });
                setState({ ...state, invalidCVC: false });
            } else {
            }
        } catch (e) {
            if (resultFromStripe.error.raw)
                setState({
                    ...state,
                    errorMessage: resultFromStripe.error.raw.message
                });
            setState({ ...state, saveClicked: false });
        }
    };
    const buttonProgress = () => {
        return {
            style: {
                buttonProgress: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12
                }
            }
        };
    };
    return (
        <Grid container spacing={2} gap={"20px"}>
            <Grid container item spacing={2.25} style={{ paddingLeft: "16px" }}>
                <Grid item xs={12}>
                    <TextField
                        label="Credit card number"
                        name="ccnumber"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement,
                                onChange: handleChange
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Expiration date"
                        name="expdate"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardExpiryElement,
                                onChange: handleChange
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="CVC"
                        name="cvcfield"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardCvcElement,
                                onChange: handleChange
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "18px"
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={props.onClose}
                    disabled={state.saveClicked}
                    sx={{
                        height: "2.25rem",
                        boxShadow: "none",
                        minWidth: "5.625rem",
                        alignItems: "center",
                        borderColor: "primary.main",
                        lineHeight: "inherit",
                        "&:hover": {
                            boxShadow: "none"
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={state.saveClicked}
                    sx={{
                        height: "2.25rem",
                        boxShadow: "none",
                        minWidth: "5.625rem",
                        alignItems: "center",
                        lineHeight: "inherit",
                        "&:hover": {
                            boxShadow: "none"
                        }
                    }}
                >
                    Update card
                    {state.saveClicked && (
                        <CircularProgress
                            size={18}
                            sx={{
                                position: "absolute"
                            }}
                        />
                    )}
                </Button>
            </Grid>
        </Grid>
    );
};

const CCFormContainer = (props) => {
    const [stripeObject, setStripeObject] = useState(null);

    useEffect(() => {
        const fetchStripeObject = async () => {
            if (props.company?.stripeConnectEnabled) {
                //For Connected Account
                const res = await loadStripe(
                    process.env.REACT_APP_STRIPE_MB_PUB_KEY,
                    {
                        stripeAccount: props.company.stripeAccount
                    }
                );
                setStripeObject(res);
            } else {
                //For Not Connected Account
                const res = await loadStripe(
                    props.company.publishableStripeKey
                );
                setStripeObject(res);
            }
        };
        fetchStripeObject();
    }, []);

    if (!stripeObject) {
        return <p>Loading...</p>;
    }

    return (
        <Elements stripe={stripeObject}>
            <CCForm
                client={props.client}
                onSave={props.onSave}
                onClose={props.onClose}
                companyId={props.companyId}
                company={props.company}
            />
        </Elements>
    );
};

export function ClientCreditCardUpdateForm(props) {
    const [client, setClient] = useState();
    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);
    const [currentCardLast4, setCurrentCardLast4] = useState();
    const user = getUserFromCache();

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    async function fetchClient() {
        try {
            const result = await graphql(
                graphqlOperation(getClient, { id: props.clientId })
            );
            if (result && result.data && result.data.getClient) {
                setClient(result.data.getClient);
                setCurrentCardLast4(result.data.getClient.defaultpartialcc);
            }
        } catch (e) {
            console.log("error while getting client");
        }
    }
    async function saveCardInClient(stripeResult) {
        JSON.stringify(stripeResult);
        try {
            setCurrentCardLast4(stripeResult.cclast4);
            const input = {
                id: client.id,
                defaultpartialcc: stripeResult.cclast4,
                stripeCustomerId: stripeResult.stripeCustomerId
            };
            // save customer.id from Stripe to Customer type
            const updatedClient = await graphql(
                graphqlOperation(updateClient, { input })
            );
            if (
                updatedClient &&
                updatedClient.data &&
                updatedClient.data.updateClient
            ) {
                setSnackMsg("Credit card details were updated successfully");
                setMsgOpen(true);
                if (props.setPartialCC) {
                    props.setPartialCC(stripeResult.cclast4);
                }
                try {
                    await auditClientCCUpdate(
                        user,
                        updatedClient.data.updateClient,
                        {
                            defaultpartialcc: client.defaultpartialcc,
                            stripeCustomerId: client.stripeCustomerId
                        }
                    );
                } catch (e) {
                    console.log("Error while entering audit");
                }
            }
            props.onClose();
            setSuccess(true);
        } catch (e) {
            setDone(true);
            setSuccess(false);
        }
    }

    function onClose() {
        if (props.onClose) props.onClose();
    }

    async function onEnter() {
        reset();
        if (props.clientId && props.show) await fetchClient();
    }

    function reset() {
        setDone(false);
        setSuccess(false);
        setCurrentCardLast4(null);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setMsgOpen(false);
                }}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={props.show}
                fullWidth={true}
                maxWidth={"xs"}
                TransitionProps={{
                    onEnter: async () => await onEnter()
                }}
            >
                <DialogTitle
                    sx={{
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        padding: "36px 36px 0px 36px"
                    }}
                >
                    Update credit card
                </DialogTitle>
                <DialogContent style={{ padding: "36px" }}>
                    {!done && (
                        <>
                            <CCFormContainer
                                client={client}
                                onSave={async (result) =>
                                    await saveCardInClient(result)
                                }
                                onClose={onClose}
                                companyId={user?.companyId}
                                company={user?.company}
                            />
                        </>
                    )}
                    {done && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {success ? (
                                    <>
                                        <Typography variant="body1">
                                            {`The credit card on file for ${client.user.firstname} ${client.user.lastname} has been updated.`}
                                        </Typography>
                                        <Typography variant="body1">
                                            <b>
                                                {` Last 4 digits of the updated card: ${currentCardLast4}`}
                                            </b>
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="body1">
                                        {`The credit card could not be updated for ${client.user.firstname} ${client.user.lastname}.`}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={props.onClose}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
