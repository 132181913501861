import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Grid,
    FormControlLabel,
    Button,
    Switch
} from "@mui/material";
import AvailabilityModal from "./AvailabilityModal";
import { useAtom } from "jotai";
import {
    selectedUserAtom,
    userPageSnackbarAtom,
    selectedUserSchedulesAtom,
    selectedAvailabilityScIdAtom,
    openAvailabilityDialogAtom
} from "../../atoms/usersTable";
import { allActiveServicesAtom } from "../../atoms/atoms";
import {
    parseRRule,
    daysArrayToString
} from "../../modules/RecurringBookingsUtilityFunctions";

const AvailabilityTab = ({ onClose }) => {
    const [openAvailabilityDialog, setOpenAvailabilityDialog] = useAtom(
        openAvailabilityDialogAtom
    );
    const [allActiveServices] = useAtom(allActiveServicesAtom);
    const [selectedUser] = useAtom(selectedUserAtom);
    const [selectedUserSchedules] = useAtom(selectedUserSchedulesAtom);
    const [, setSelectedAvailabilityScId] = useAtom(
        selectedAvailabilityScIdAtom
    );

    const handleOpenAvailabilityDialog = () => {
        setOpenAvailabilityDialog(true);
    };

    const handleCloseAvailabilityDialog = () => {
        setOpenAvailabilityDialog(false);
        setSelectedAvailabilityScId(null);
    };

    const ServicesTableCell = ({ row, allActiveServices }) => {
        if (JSON.parse(row.services).length === allActiveServices.length) {
            return <TableCell>All</TableCell>;
        }

        return (
            <TableCell>
                {`${JSON.parse(row.services).length} / ${allActiveServices.length}`}
            </TableCell>
        );
    };

    const getTravelTypeText = (locations) => {
        const plLocation = locations.find((location) =>
            location.startsWith("PL-")
        );

        if (plLocation) {
            const id = plLocation.split("PL-")[1];
            const providerLocation = selectedUser.provider.locations.items.find(
                (loc) => loc.id === id
            );

            if (
                providerLocation &&
                providerLocation.maxtraveltype === "DRAW_TRAVEL_REGION"
            ) {
                return "DRAWN";
            } else {
                return "CIRCULAR";
            }
        } else {
            return "Company Locations";
        }
    };

    const handleRowClick = (id) => {
        if (!id) return;
        setSelectedAvailabilityScId(id);
        setOpenAvailabilityDialog(true);
    };

    return (
        <>
            {openAvailabilityDialog && (
                <AvailabilityModal
                    open={openAvailabilityDialog}
                    onClose={handleCloseAvailabilityDialog}
                />
            )}
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date Range</TableCell>
                                <TableCell>Time Range</TableCell>
                                <TableCell>Days of Week</TableCell>
                                <TableCell>Coverage Area</TableCell>
                                <TableCell>Services</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedUserSchedules.map((row, index) => (
                                <TableRow
                                    key={row.scheduleinfo}
                                    hover
                                    onClick={() =>
                                        handleRowClick(row.scheduleinfo)
                                    }
                                >
                                    <TableCell>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                        >
                                            {`${row.SB[0].startDate} \u2014 ${row.SB[0].endDate}`}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        {row.SB.map(
                                            (sb) =>
                                                `${sb.startTime} \u2014 ${sb.endTime} \n`
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {daysArrayToString(
                                            row.SB[0].weekDays
                                                .split("")
                                                .map(Number)
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {getTravelTypeText(
                                            JSON.parse(row.locations)
                                        )}
                                    </TableCell>
                                    <ServicesTableCell
                                        row={row}
                                        allActiveServices={allActiveServices}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Show expired"
                />

                <Grid display={"flex"} gap={"18px"}>
                    <Button onClick={onClose} variant="outlined">
                        Cancel
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOpenAvailabilityDialog}
                    >
                        + Add Availability
                    </Button>
                </Grid>
            </DialogActions>
        </>
    );
};

export default AvailabilityTab;
