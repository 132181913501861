import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Button, Paper, Grid, Typography } from "@mui/material";
// prettier-ignore
import { useStyles } from "../styles/UserEditViewFormStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { getCurrentUser } from "aws-amplify/auth";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { ClientCreditCardUpdateForm } from "../components/stripe/ClientCreditCardUpdateForm";
import { execWrite } from "../modules/DBService";

export default function ClientBillingInfo(props) {
    const logger = new ConsoleLogger("ClientBillingInfo");

    const { state, dispatch, actions } = useContext(StoreContext);
    const [userId, setUserId] = useState("");
    const [openUpdateCCDialog, setOpenUpdateCCDialog] = useState(false);
    const [updateCCClientId, setUpdateCCClientId] = useState();
    const [stripeCustomerId, setStripeCustomerId] = useState();
    const [currentCardLast4, setCurrentCardLast4] = useState();
    const [reload, setReload] = useState(false);

    const classes = useStyles();
    const classesnew = usePromotionsStyles();

    //Fetching user info from cache.

    useEffect(() => {
        // if the source="user", set mode to edit
        const fetchData = async () => {
            await getUserData();
        };

        fetchData();
    }, []);

    useEffect(() => {
        // if the source="user", set mode to edit
        const fetchData = async () => {
            await getUserData();
        };
        if (reload) {
            setReload(false);
            fetchData();
        }
    }, [reload]);

    async function getUserData() {
        const user = await getCurrentUser();
        let userId = user.username;

        console.log("In getUserData", userId);
        setUserId(userId);
        if (!!userId) {
            const input = { id: userId };
            const result = await graphql(
                graphqlOperation(queries.getUser, input)
            );
            console.log("getUserData", result);
            if (result.data.getUser) {
                const clientProfile = await graphql(
                    graphqlOperation(queries.clientByUserId, {
                        userId: result.data.getUser.id
                    })
                );
                if (
                    clientProfile &&
                    clientProfile.data.clientByUserId.items.length
                ) {
                    const clientId =
                        clientProfile.data.clientByUserId.items[0].id;
                    setUpdateCCClientId(clientId);
                    setStripeCustomerId(
                        clientProfile.data.clientByUserId.items[0]
                            .stripeCustomerId
                    );
                    setCurrentCardLast4(
                        clientProfile.data.clientByUserId.items[0]
                            .defaultpartialcc
                    );
                } else {
                    //create client record (may be imported user)
                    const newClient = await createClientRecord(
                        result.data.getUser
                    );
                    setUpdateCCClientId(newClient.id);
                }
            }
        }
    }

    const createClientRecord = async (user) => {
        console.log("CREATEINGNEWCLIENT");
        const clientData = {
            userId: user.id,
            currency: user.company.currency ? user.company.currency : "CAD",
            companyId: user.companyId,
            clientUserId: user.id,
            clientCompanyId: user.companyId,
            accountbalance: 0.0
        };

        let client = await execWrite({
            opname: "createClient",
            op: mutations.createClient,
            input: clientData
        });

        if (client && client.error) {
            return {
                error: client.error
            };
        } else return client;
    };

    function showUpdateCCDialog() {
        // setUpdateCCClientId();
        setOpenUpdateCCDialog(true);
    }
    return (
        <>
            <Typography className={classes.title} variant="h4" noWrap>
                Add/Edit Credit Card
            </Typography>
            <ClientCreditCardUpdateForm
                show={openUpdateCCDialog}
                clientId={updateCCClientId}
                onClose={() => {
                    setOpenUpdateCCDialog(false);
                    setReload(true);
                }}
                updatedBySelf={true}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {currentCardLast4 ? (
                            <Typography
                                style={{ marginLeft: "8px" }}
                                variant="body2"
                            >
                                {`Your current credit card on file ends in ${currentCardLast4}.`}
                            </Typography>
                        ) : (
                            <Typography
                                style={{ marginLeft: "8px" }}
                                variant="body2"
                            >
                                {`Currently we don't have your credit card on file.`}
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {currentCardLast4 ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={showUpdateCCDialog}
                                style={{ marginLeft: "8px", marginTop: "16px" }}
                            >
                                Update Credit Card
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={showUpdateCCDialog}
                                style={{ marginLeft: "8px", marginTop: "16px" }}
                            >
                                Add Credit Card
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
