import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import CheckIcon from "@mui/icons-material/Check";
import StarsIcon from "@mui/icons-material/Stars";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogTitleContainer: {
        alignItems: "center",
        gap: "8px"
    },
    dialogTitle: {
        padding: "0px",
        paddingLeft: "12px",
        fontSize: "26px",
        color: "#000000e0"
    },
    listContainer: {
        marginTop: "0px",
        padding: "0px"
    },
    listItem: {
        padding: "0px 0px 8px 0px"
    },
    dialogText: {
        padding: "12px",
        fontFamily: "Roboto"
    }
}));

export default function FeatureGateDialog(props) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleOnClose();
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                fullWidth={true}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxWidth: 400
                    }
                }}
            >
                <DialogContent>
                    <Grid container className={classes.dialogTitleContainer}>
                        <DialogTitle className={classes.dialogTitle}>
                            MarketBox Pro
                        </DialogTitle>
                        <StarsIcon
                            style={{
                                color: "#fcaf17",
                                height: "32px",
                                width: "32px"
                            }}
                        />
                    </Grid>
                    <Typography className={classes.dialogText}>
                        Get this feature and many more by upgrading to MarketBox
                        pro
                    </Typography>
                    <List className={classes.listContainer}>
                        {/* <ListItem className={classes.listItem}>
                            <Checkbox
                                icon={<CheckIcon />}
                                checkedIcon={<CheckIcon />}
                            />
                            <ListItemText primary="Packages & recurring appointments" />
                        </ListItem> */}
                        <ListItem className={classes.listItem}>
                            <Checkbox
                                icon={<CheckIcon />}
                                checkedIcon={<CheckIcon />}
                            />
                            <ListItemText primary="Location-based pricing" />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Checkbox
                                icon={<CheckIcon />}
                                checkedIcon={<CheckIcon />}
                            />
                            <ListItemText primary="Booking requests" />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Checkbox
                                icon={<CheckIcon />}
                                checkedIcon={<CheckIcon />}
                            />
                            <ListItemText primary="1000+ standard integrations" />
                        </ListItem>
                    </List>
                    <Typography className={classes.dialogText}>
                        Please contact our{" "}
                        <a
                            href="https://www.gomarketbox.com/contact-us"
                            style={{ fontFamily: "Roboto" }}
                        >
                            support team
                        </a>{" "}
                        to upgrade your account.
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}
