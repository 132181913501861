import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as mbtheme from "./theme";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import amplifyconfig from "./amplifyconfiguration.json";
import { StoreProvider } from "./context/StoreContext";
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme
} from "@mui/material";
import FlagsProvider from "./context/flags";
import { purple } from "@mui/material/colors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
// config for Amplify
Amplify.configure(amplifyconfig);
const primaryColor = mbtheme.getPrimaryColor();
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#1e88e5"
        }
    }
});

const customTheme = createTheme({
    palette: {
        primary: primaryColor
            ? { main: primaryColor[500] }
            : { main: purple[500] }
    }
});

ReactDOM.render(
    <FlagsProvider>
        <StoreProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider
                    theme={primaryColor ? customTheme : defaultTheme}
                >
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </QueryClientProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </StoreProvider>
    </FlagsProvider>,
    document.getElementById("root")
);
