import { graphql, graphqlOperation } from "../../../modules/AmplifyServices";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import * as Sentry from "@sentry/react";

async function getSubscriptionSessionData(sessionId) {
    try {
        const sessionData = await graphql(
            graphqlOperation(queries.getRefData, {
                refType: `subscriptionsessiondata|${sessionId}`,
                refName: "data-subscriptionsession"
            })
        );
        return sessionData;
    } catch (e) {
        console.log("Error: Unable to retrieve Subscription Session Data", e);
        Sentry.captureException(e);
    }
}

async function updateSubscriptionSessionData(sessionId, sessionData) {
    try {
        await graphql(
            graphqlOperation(mutations.updateRefData, {
                input: {
                    refType: `subscriptionsessiondata|${sessionId}`,
                    refName: "data-subscriptionsession",
                    refValue: "subscriptionsessiondata",
                    overrideValue: JSON.stringify(sessionData)
                }
            })
        );
    } catch (e) {
        console.log(
            "Error: Unable to update subscriptionsessiondata ref data",
            e
        );
        Sentry.captureException(e);
    }
}

async function createStripeSessionData(companyId, stripeSessionId) {
    try {
        await graphql(
            graphqlOperation(mutations.createRefData, {
                input: {
                    refType: `stripesessiondata|${companyId}`,
                    refName: "data-stripesession",
                    refValue: "stripesessiondata",
                    overrideValue: JSON.stringify({
                        stripeSessionId: stripeSessionId
                    })
                }
            })
        );
    } catch (e) {
        console.log("Error: Unable to create ref data", e);
        Sentry.captureException(e);
    }
}

export {
    getSubscriptionSessionData,
    createStripeSessionData,
    updateSubscriptionSessionData
};
