/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProviderRatings = /* GraphQL */ `
  subscription OnCreateProviderRatings {
    onCreateProviderRatings {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateProviderRatings = /* GraphQL */ `
  subscription OnUpdateProviderRatings {
    onUpdateProviderRatings {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteProviderRatings = /* GraphQL */ `
  subscription OnDeleteProviderRatings {
    onDeleteProviderRatings {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const onCreateServiceType = /* GraphQL */ `
  subscription OnCreateServiceType {
    onCreateServiceType {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateServiceType = /* GraphQL */ `
  subscription OnUpdateServiceType {
    onUpdateServiceType {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteServiceType = /* GraphQL */ `
  subscription OnDeleteServiceType {
    onDeleteServiceType {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateSkill = /* GraphQL */ `
  subscription OnCreateSkill {
    onCreateSkill {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSkill = /* GraphQL */ `
  subscription OnUpdateSkill {
    onUpdateSkill {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSkill = /* GraphQL */ `
  subscription OnDeleteSkill {
    onDeleteSkill {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateSystemErrorLog = /* GraphQL */ `
  subscription OnCreateSystemErrorLog {
    onCreateSystemErrorLog {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const onUpdateSystemErrorLog = /* GraphQL */ `
  subscription OnUpdateSystemErrorLog {
    onUpdateSystemErrorLog {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const onDeleteSystemErrorLog = /* GraphQL */ `
  subscription OnDeleteSystemErrorLog {
    onDeleteSystemErrorLog {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const onCreateAudit = /* GraphQL */ `
  subscription OnCreateAudit {
    onCreateAudit {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAudit = /* GraphQL */ `
  subscription OnUpdateAudit {
    onUpdateAudit {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAudit = /* GraphQL */ `
  subscription OnDeleteAudit {
    onDeleteAudit {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
