import React, { useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import CircularProgress from "@mui/material/CircularProgress";
import {
    TextField,
    Box,
    Button,
    Checkbox,
    Grid,
    Typography,
    FormGroup,
    Switch,
    FormControlLabel,
    Alert,
    Tooltip
} from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    createUnavailableTimeblock,
    updateTimeblock,
    SCHEDPKSKSPLITAT,
    deactivateSchedule
} from "../modules/ScheduleService";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
    auditProviderBlockTimeCreate,
    auditProviderBlockTimeDelete,
    auditProviderBlockTimeUpdate
} from "../modules/Audit";
import { getUserFromCache, userHasAdminRole } from "../user/UserCommon";

export const BlockTimeAddEdit = (props) => {
    const { state, dispatch, actions } = React.useContext(StoreContext);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();

    // const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [appoitmentCount, setAppointmentCount] = React.useState(false);

    const [checkstate, setCheckstate] = React.useState("");
    const [checkAllstate, setAllDaystate] = React.useState(false);
    const [saveClicked, setSaveClicked] = useState(false);
    const [values, setValues] = React.useState({
        age: "",
        name: "hai"
    });
    const [repeatOn, setRepeatOn] = useState(false);
    const [allDayState, setAllDay] = React.useState(false);
    const [disableRepeat, setDisableRepeat] = useState(false);
    const [showRepeatError, setShowRepeatError] = useState(false);
    //  const [active, setActive] = React.useState(true);
    const [timeValues, setTimeValues] = useState({
        startTime: new Date(
            moment().format("YYYY"),
            moment().format("MM"),
            moment().format("DD"),
            0,
            0
        ),
        endTime: new Date(
            moment().format("YYYY"),
            moment().format("MM"),
            moment().format("DD"),
            23,
            55
        )
    });

    const [dateValues, setDateValues] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    React.useEffect(() => {
        const loggedInUser = getUserFromCache();
        // const companyid = loggedInUser.companyId;
        if (
            !fromProviderSchedule() &&
            (state.mode === "Edit" || state.mode === "View")
        ) {
            _getUnavailableTimeBlock();
        }

        if (fromProviderSchedule() && props.mode === "Edit") {
            console.log("FromProviderSchedule - Edit getUnavailableTimeBlock");
            _getUnavailableTimeBlock();
        }
        if (fromProviderSchedule() && props.cellData) {
            setTimeValues((prevState) => ({
                startTime: new Date(props.cellData.startDate),
                endTime: prevState.endTime
            }));

            setDateValues((prevState) => ({
                startDate: new Date(props.cellData.startDate),
                endDate: new Date(props.cellData.startDate)
            }));

            setDisableRepeat(true);
        }
    }, [props.cellData]);

    useEffect(() => {
        let date1 = moment(dateValues.startDate);
        let date2 = moment(dateValues.endDate);
        let difference = date2.diff(date1, "days");

        console.log("Date Values", dateValues, date1, date2, difference);
        if (difference < 1) {
            setDisableRepeat(true);
            setRepeatOn(false);
        } else {
            setDisableRepeat(false);
            setShowRepeatError(false);
        }
    }, [dateValues]);

    const _getUnavailableTimeBlock = async () => {
        const pksk =
            props.blockModalId &&
            props.blockModalId.length > 0 &&
            fromProviderSchedule()
                ? props.blockModalId.split(SCHEDPKSKSPLITAT)
                : state.id.split(SCHEDPKSKSPLITAT);

        console.log("Primary key getUnavailableTimeBlock", pksk);

        if (fromProviderSchedule()) {
            console.log(
                "Primary key getUnavailableTimeBlock",
                pksk[1].split(props.cellData.locationId)[0]
            );
        }

        const timeData = await graphql(
            graphqlOperation(queries.getScheduleTimeblock, {
                id: pksk[0],
                scheduleinfo: !fromProviderSchedule()
                    ? pksk[1]
                    : pksk[1].split(props.cellData.locationId)[0]
            })
        );

        console.log("Time Data getUnavailableTimeBlock", timeData);

        if (!!timeData.data.getScheduleTimeblock) {
            //   setActive(timeData.data.getScheduleTimeblock.active);

            // console.log(
            //     "show val",
            //     timeData.data.getScheduleTimeblock &&
            //         timeData.data.getScheduleTimeblock.weekDays
            // );
            setTimeValues((oldValues) => ({
                ...oldValues,
                startTime: new Date(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    timeData.data.getScheduleTimeblock.startTime.split(":")[0],
                    timeData.data.getScheduleTimeblock.startTime.split(":")[1]
                ),
                endTime: new Date(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    timeData.data.getScheduleTimeblock.endTime.split(":")[0],
                    timeData.data.getScheduleTimeblock.endTime.split(":")[1]
                )
            }));
            setDateValues((oldValues) => ({
                ...oldValues,
                startDate: moment(timeData.data.getScheduleTimeblock.startDate),
                endDate: moment(timeData.data.getScheduleTimeblock.endDate)
            }));
            if (timeData.data.getScheduleTimeblock.weekDays) {
                setCheckstate(timeData.data.getScheduleTimeblock.weekDays);
                setRepeatOn(true);
            }
        }
    };

    const fromProviderSchedule = () => {
        if (props.fromPath === "ProviderSchedule") {
            return true;
        }

        return false;
    };
    const handleAllDayChange = (name) => (event) => {
        setAllDaystate(event.target.checked);
        if (event.target.checked) setCheckstate("0123456");
        else setCheckstate("");
    };
    const handleChange = (name) => (event) => {
        // week = Object.keys({ ...checkstate, [name]: event.target.checked });
        //   week.filter(item => {
        //     return item === true
        //   })
        let arr = !!checkstate ? checkstate.split("") : [];
        if (event.target.checked) arr.push(name.toString());
        else
            arr = arr.filter((item) => {
                return item != name;
            });
        setCheckstate(arr.sort().join(""));
        arr = [];
    };

    // function handleSelectChange(event) {
    //   if (event.target.name === "type") {
    //     setType(event.target.value);
    //   } else {
    //     setStatus(event.target.value);
    //   }
    // }
    const _handleCreateBlockedTimeBlock = async (e) => {
        const { startDate, endDate } = dateValues;
        const { startTime, endTime } = timeValues;
        const startMin = startTime.getMinutes();
        const endMin = endTime.getMinutes();
        setSaveClicked(true);

        if (endTime < startTime) {
            setSnackMsg("End time should be greater than start time.");
            setMsgOpen(true);
            setSaveClicked(false);
        } else if (moment(endDate).diff(startDate) < 0) {
            setSnackMsg("End date should be greater than start date.");
            setMsgOpen(true);
            setSaveClicked(false);
        } else if (!startDate && !endDate) {
            setSnackMsg("Please select start date and end date.");
            setMsgOpen(true);
            setSaveClicked(false);
        } else if (!startTime && !endTime) {
            setSnackMsg("Please enter start time and end time.");
            setMsgOpen(true);
            setSaveClicked(false);
        } else if (startMin % 5 != 0) {
            setSnackMsg(
                "Please enter a start time that appears in 5 minute intervals. Ex: 10:05, 10:10 etc."
            );
            setMsgOpen(true);
            setSaveClicked(false);
        } else if (endMin % 5 != 0) {
            setSnackMsg(
                "Please enter an end time that appears in 5 minute intervals. Ex: 10:05, 10:10 etc."
            );
            setMsgOpen(true);
            setSaveClicked(false);
        } else {
            let week;
            if (repeatOn)
                if (checkAllstate) {
                    week = [0, 1, 2, 3, 4, 5, 6];
                } else {
                    week = checkstate.split("");
                }
            const loggedInUser = getUserFromCache();
            console.log("Props res:", props, state);
            const body = {
                user: loggedInUser.id,
                companyId: loggedInUser.companyId,
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
                startTime: moment(startTime).format("HH:mm"),
                endTime: moment(endTime).format("HH:mm"),
                weeksToRepeat: 1,
                weekDays: week,
                type: "UNAVAILABLE", //type,
                providerId: fromProviderSchedule()
                    ? props.cellData.providerId &&
                      props.cellData.providerId.length > 0
                        ? props.cellData.providerId
                        : state.provider.id
                    : state.provider.id,
                active: true //always true in this screen.
            };
            let res;
            if (!fromProviderSchedule() && state.mode === "Edit") {
                body.id = state.id; //state.id;
                res = await updateTimeblock(body);
                await auditProviderBlockTimeUpdate(loggedInUser, res);
            } else if (props.mode === "Edit" && fromProviderSchedule()) {
                body.id =
                    props.blockModalId &&
                    props.blockModalId.length > 0 &&
                    fromProviderSchedule()
                        ? props.blockModalId.split(props.cellData.locationId)[0]
                        : state.id; //state.id;
                res = await updateTimeblock(body);
                await auditProviderBlockTimeUpdate(loggedInUser, res);
            } else {
                res = await createUnavailableTimeblock(body);
                await auditProviderBlockTimeCreate(loggedInUser, res);
            }
            console.log("res:", res);
            console.log("res: body", body);
            if (!!res.id) {
                setSnackMsg("Blocked Time saved successfully.");
                setMsgOpen(true);
                // display a message indicating it was succesful

                if (props.fromPath === "ProviderSchedule") {
                    props.closeBlockModalHandler("done");
                }

                if (props.fromPath !== "ProviderSchedule") {
                    setTimeout(() => {
                        actions.setId(res.id);
                        actions.setMode("Add");
                        actions.setPage("ProviderBlockTime");
                    }, 1000);
                }
            }
        }
    };

    const _handleTimeChange = (name, value) => {
        setTimeValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));
    };

    const _handleDateChange = (name, value) => {
        setDateValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));
    };

    const handleMsgClose = (e) => {
        setMsgOpen(false);
    };

    const handleAllChange = ({ target: { checked } }) => {
        setAllDay(checked);
        //changes the start time to 12:00am and
        //end time to 11:55pm when the 'All Day' button is clicked
        _handleTimeChange(
            "startTime",
            new Date(
                moment().format("YYYY"),
                moment().format("MM"),
                moment().format("DD"),
                0,
                0
            )
        );
        _handleTimeChange(
            "endTime",
            new Date(
                moment().format("YYYY"),
                moment().format("MM"),
                moment().format("DD"),
                23,
                55
            )
        );
    };

    const cancelHandler = () => {
        if (props.fromPath === "ProviderSchedule") {
            props.closeBlockModalHandler("close");
        }

        if (props.fromPath !== "ProviderSchedule") {
            actions.setPage("ProviderBlockTime");
        }
    };

    const blockDeleteHandler = async () => {
        const loggedInUser = getUserFromCache();
        setSaveClicked(true);

        try {
            let id = props.blockModalId.split(props.cellData.locationId)[0];
            console.log("delete time block id", id);
            let response = await deactivateSchedule(id);
            await auditProviderBlockTimeDelete(loggedInUser, response);

            if (!response) {
                console.error(
                    "response Something went wrong, while deleting this block time"
                );
            } else {
                console.log(" response Successfully deleted ");
                setSaveClicked(false);
                props.closeBlockModalHandler("done");
            }
        } catch (err) {
            console.log("Block Delete Handler Error", err);
        }
    };

    console.log("State blocktimeaddedit", state);
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Typography className={classes.title} variant="h4" noWrap>
                Blocked Time or Time Off
            </Typography>

            {props.mode !== "Add" && (
                <Typography className={classes.helpertitle} variant="h6" noWrap>
                    {state.mode === "Edit"
                        ? "Editing"
                        : state.mode === "View"
                          ? "Viewing"
                          : "Adding"}{" "}
                    Unavailable time blocks for {state.provider.name}
                </Typography>
            )}
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={customLocale}
            >
                <Grid
                    container
                    spacing={2}
                    className={classes.blockedTimeSpacing}
                >
                    <Grid item>
                        <div>
                            <DatePicker
                                label="Start Date"
                                format="MMM dd, yyyy"
                                minDateMessage={"Please select a start date."}
                                value={dateValues.startDate}
                                onChange={(e) =>
                                    _handleDateChange("startDate", e)
                                }
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                                disabled={saveClicked}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <DatePicker
                                label="End Date"
                                format="MMM dd, yyyy"
                                minDate={dateValues.startDate}
                                minDateMessage={
                                    "Please select an end date greater than start date."
                                }
                                value={dateValues.endDate}
                                onChange={(e) =>
                                    _handleDateChange("endDate", e)
                                }
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                                disabled={saveClicked}
                            />
                        </div>
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <Grid container spacing={2} className={classes.blockedTimeSpacing}>
                <Grid item xs={6} className={classes.iconPadding}>
                    <FormControlLabel
                        disabled={saveClicked}
                        control={
                            <Checkbox
                                // checked={checksta  te.allday}
                                onChange={handleAllChange}
                                value="allday"
                            />
                        }
                        label="All Day"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.blockedTimeSpacing}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div>
                            <TimePicker
                                label="Start Time"
                                format="hh:mm a"
                                minDateMessage={"Please select a start time."}
                                value={timeValues.startTime}
                                disabled={allDayState || saveClicked}
                                onChange={(e) =>
                                    _handleTimeChange("startTime", e)
                                }
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                        </div>
                    </LocalizationProvider>{" "}
                </Grid>{" "}
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div>
                            <TimePicker
                                label="End Time"
                                format="hh:mm a"
                                disabled={allDayState || saveClicked}
                                minDateMessage={
                                    "Please select an end time greater than start time."
                                }
                                value={timeValues.endTime}
                                onChange={(e) =>
                                    _handleTimeChange("endTime", e)
                                }
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Grid>
            </Grid>

            {userHasAdminRole() && (
                <Grid
                    sx={{ mt: 1, mb: 1, ml: 0.8 }}
                    item
                    container
                    spacing={2}
                    xs={fromProviderSchedule() ? 10 : 6}
                >
                    <Alert
                        severity="info"
                        variant={"outlined"}
                        sx={{
                            width: "100%",
                            backgroundColor: "#E5F6FD",
                            "& .MuiAlert-icon": {
                                "& svg": {
                                    color: "#0087EE"
                                }
                            },
                            "& .MuiAlert-message": {
                                color: "#0087EE",
                                fontWeight: 700
                            }
                        }}
                    >
                        These times are in the provider's timezone{" "}
                    </Alert>
                </Grid>
            )}

            <Grid container spacing={2} className={classes.blockedTimeSpacing}>
                <Grid item>
                    <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
                        <FormControlLabel
                            sx={{ mb: 0 }}
                            disabled={saveClicked}
                            control={
                                <Tooltip
                                    disablePortal
                                    PopperProps={{
                                        style: { zIndex: 3000 }
                                    }}
                                    title="Set the date range to two or more days to repeat"
                                    disableHoverListener={!disableRepeat}
                                    disableFocusListener={!disableRepeat}
                                    disableTouchListener={!disableRepeat}
                                    open={showRepeatError}
                                    onClose={() => setShowRepeatError(false)}
                                >
                                    <span>
                                        <Switch
                                            checked={repeatOn}
                                            onChange={(event) => {
                                                if (!disableRepeat) {
                                                    setRepeatOn(
                                                        event.target.checked
                                                    );
                                                } else {
                                                    setShowRepeatError(true);
                                                }
                                            }}
                                            value="active"
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "primary checkbox"
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            }
                            label="Repeat On"
                        />{" "}
                    </Box>

                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(0)}
                                    onChange={handleChange(0)}
                                    value={0}
                                />
                            }
                            label="S"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(1)}
                                    onChange={handleChange(1)}
                                    value={1}
                                />
                            }
                            label="M"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(2)}
                                    onChange={handleChange(2)}
                                    value={2}
                                />
                            }
                            label="T"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(3)}
                                    onChange={handleChange(3)}
                                    value={3}
                                />
                            }
                            label="W"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(4)}
                                    onChange={handleChange(4)}
                                    value={4}
                                />
                            }
                            label="T"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(5)}
                                    onChange={handleChange(5)}
                                    value={5}
                                />
                            }
                            label="F"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkstate.includes(6)}
                                    onChange={handleChange(6)}
                                    value={6}
                                />
                            }
                            label="S"
                            disabled={checkAllstate || !repeatOn || saveClicked}
                        />
                        <FormControlLabel
                            disabled={saveClicked}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkAllstate.fullweek}
                                    onChange={handleAllDayChange("fullweek")}
                                    value="fullweek"
                                />
                            }
                            label="All"
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container gap={2} item xs={8}>
                <Button
                    sx={{ textTransform: "uppercase" }}
                    variant="contained"
                    color="primary"
                    disabled={state.mode === "View" || saveClicked}
                    onClick={_handleCreateBlockedTimeBlock}
                >
                    {props.mode !== "Add" && state.mode === "Edit"
                        ? "Save"
                        : "Create"}
                    {saveClicked && (
                        <CircularProgress
                            size={24}
                            className={classesnew.buttonProgress}
                        />
                    )}
                </Button>

                <Button
                    sx={{ textTransform: "uppercase" }}
                    disabled={saveClicked}
                    variant="contained"
                    color="primary"
                    onClick={cancelHandler}
                >
                    Cancel
                </Button>

                {fromProviderSchedule() && props.mode === "Edit" && (
                    <Button
                        disabled={saveClicked}
                        sx={{ textTransform: "uppercase" }}
                        variant={"contained"}
                        onClick={blockDeleteHandler}
                    >
                        Delete
                    </Button>
                )}
            </Grid>
        </>
    );
};
