import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Paper, Grid, Button, Snackbar, Tooltip } from "@mui/material";
//import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { ReviewTable } from "../components/ReviewTable";

export const ProviderReviewForm = (props) => {
    const { actions, state } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);

    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //Selected rows
    const [selected, setSelected] = useState([]);
    const [searchedFor, setSearchedFor] = useState(false);

    //Search Values
    const [values, setValues] = useState({
        type: "",
        name: ""
    });

    let filter = {};

    useEffect(() => {
        if (sessionStorage.getItem("search") === "true") setSearchedFor(true);
        // allows for the queries to update so that the search works
        setTimeout(() => {
            _getReviewList();
        }, 500);
    }, []);

    // Get
    const _getReviewList = async () => {
        /* let reviewList = await graphql(
            graphqlOperation(queries.searchProviderRatingss, {
                filter: { providerId: { eq: state.id } },
                sort: { field: "createdAt", direction: "desc" }
            })
        ); */

        let reviewList = await graphql(
            graphqlOperation(queries.providerRatingsByProvider, {
                providerId: state.id,
                sortDirection: "DESC"
            })
        );

        console.log("*** providerId = ", JSON.stringify(state.id));
        /* console.log(
            "ReviewList",
            reviewList.data.searchProviderRatingss.items,
            state
        ); */
        console.log("ReviewList", reviewList);
        if (
            reviewList.data.providerRatingsByProvider &&
            reviewList.data.providerRatingsByProvider.items &&
            reviewList.data.providerRatingsByProvider.items.length > 0
        ) {
            setRows(reviewList.data.providerRatingsByProvider.items);
        }
    };

    const _handleReviewAdd = () => {
        actions.setMode("Add");
        actions.setPage("ProviderReview");
    };

    const _handleScheduleCancel = () => {
        sessionStorage.setItem("back", false);
        sessionStorage.setItem("search", false);
        actions.setMode("Add");
        actions.setPage("ProviderForm");
    };

    const _handleReturnToSearch = () => {
        sessionStorage.setItem("back", true);
        actions.setMode("Add");
        actions.setPage("ProviderForm");
    };

    const _handleBack = () => {
        sessionStorage.setItem("back", true);
        sessionStorage.setItem("search", false);
        actions.setId(state.id);
        actions.setPage("ProviderForm");
    };

    const _handleSearchTermChange = (event) => {
        const { name, value } = event.target;
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));

        filter = {
            ...filter,
            name: { contains: value }
        };
        console.log("_handleSearchTermChange", _handleSearchTermChange);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <FormHeading
                title={`Ratings for ${state.provider.name}`}
                classes={classes}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleReviewAdd}
                        >
                            {/* <AddIcon className={classes.leftIcon} /> */}
                            &nbsp;Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <ReviewTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                rows={rows}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    {searchedFor && (
                        <Grid item>
                            <Tooltip title="Returns to the page with the providers matching your search">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={_handleReturnToSearch}
                                >
                                    Return to Search
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                    &nbsp;&nbsp;
                    <Grid item>
                        <Tooltip title="Returns to only the provider you selected">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleBack}
                            >
                                Back
                            </Button>
                        </Tooltip>
                    </Grid>
                    &nbsp;&nbsp;
                    <Grid item>
                        <Tooltip title="Returns back to the main provider page">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleScheduleCancel}
                            >
                                Cancel
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
