export const COLUMNS = [
    { key: "active", label: "", sortable: false },
    { key: "user", label: "User" },
    { key: "permalink", label: "Link", sortable: false },
    { key: "role", label: "Role" },
    { key: "skills", label: "Skills", textAlign: "right" },
    { key: "services", label: "Services", textAlign: "right" },
    { key: "availability", label: "Availability" },
    { key: "lastLoginDate", label: "Last login" },
    { key: "createdAt", label: "Created on" }
];
