import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    Paper,
    Grid,
    Button,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Snackbar,
    Typography
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { ScheduleTable } from "../components/ScheduleTable";
import { AvailabilityDisplay } from "./AvailabilityDisplay";
import { deactivateSchedule } from "../modules/ScheduleService";
import { auditProviderSchedDelete } from "../modules/Audit";
import { _getSchedules } from "../modules/ScheduleService";
import {
    userHasCompanyAdminRole,
    userHasProviderRole,
    userHasMarketboxAdminRole,
    userHasProviderOnlyRole
} from "../user/UserCommon";
import { getUserFromCache } from "../user/UserCommon";
import moment from "moment";
import { execReadBySortkey } from "../modules/DBService";
import { useCallback } from "react";
import { getMasterTimezone } from "../utils/CalendarFunctions/timezoneFunctions";
import { useAtom } from "jotai";
import { mbxUserAtom } from "../atoms/atoms";

export const ProviderSchedule = (props) => {
    const [mbxUser] = useAtom(mbxUserAtom);
    const { actions, state } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    const [rows, setRows] = useState([]);
    const [viewType, setViewType] = useState("availability");
    const [displayInactiveSchedules, setDisplayInactiveSchedules] =
        useState(false);

    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    const [declineDiaog, setDeclineDialog] = React.useState(false);
    //Selected rows
    const [selected, setSelected] = useState([]);
    const [user, setUser] = useState(getUserFromCache());
    const [currentDate, setCurrentDate] = useState(new Date());
    const [searchedFor, setSearchedFor] = useState(false);
    const [refershScheduleList, setRefreshScheduleList] = useState(false);
    const [deactivateSchDialog, setDeactivateSchDialog] = useState(false);
    const [tobedeletedSchedule, setTobedeletedSchedule] = useState(null);
    const [providersList, setProvidersList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(
        mbxUser.role === "PROVIDER"
            ? {
                  id: mbxUser.provider.id,
                  text: mbxUser.provider.firstname,
                  timezone: mbxUser.provider.timezone
              }
            : {
                  id: state.provider.id,
                  text: state.provider.firstname,
                  timezone: state.provider.timezone
              }
    );

    /*  const [providerData, setProviderData] = useState(
        JSON.parse(localStorage.getItem("provider_data"))
    ); */

    /* useEffect(() => {
        if (state.providerScheduleViewType)
            setViewType(state.providerScheduleViewType);
    }, []); */

    /*    console.log("localstorage", localStorage.getItem("provider_data"));
    useEffect(() => {
        console.log(
            "localstorage useffect",
            localStorage.getItem("provider_data")
        );
        if (!providerData && localStorage.getItem("provider_data")) {
            console.log(
                "Provider Data in ProviderSchedule useEffect",
                providerData
            );
            setProviderData(JSON.parse(localStorage.getItem("provider_data")));
        }
    }, [providerData]); */

    useEffect(() => {
        if (sessionStorage.getItem("search") === "true") setSearchedFor(true);
        const loggedInUser = getUserFromCache();
        setUser(loggedInUser);
        const companyId = loggedInUser.companyId;
        if (viewType === "manage_availability") {
            _getScheduleList(
                {
                    companyId,
                    providerId:
                        userHasCompanyAdminRole() || userHasMarketboxAdminRole()
                            ? state.id
                            : loggedInUser.providerId
                },
                viewType
            );
        }
    }, [viewType]);

    useEffect(() => {
        //console.log("user providerschedule", user);
        console.log("ProviderSchedule Props", props);
        fetchProviders(props.company.id);
    }, [user, props.company]);

    /*   useEffect(() => {
        console.log("Providers List", providersList);
    }, [providersList]); */

    useEffect(() => {
        if (refershScheduleList && viewType === "manage_availability") {
            const loggedInUser = getUserFromCache();
            const companyId = loggedInUser.companyId;
            _getScheduleList(
                {
                    companyId,
                    providerId:
                        userHasCompanyAdminRole() || userHasMarketboxAdminRole()
                            ? state.id
                            : loggedInUser.providerId
                },
                viewType
            );
            setRefreshScheduleList(false);
        }
    }, [refershScheduleList]);

    // Get
    const _getScheduleList = async (filter, viewType) => {
        console.log("view", viewType);
        if (viewType == "manage_availability") {
            let scheduleList = await _getSchedules(filter);
            console.log("scheduleListJson", JSON.stringify(scheduleList));
            if (scheduleList) {
                scheduleList = scheduleList.map((item) => {
                    item.locationName =
                        item.location && item.location.locationname
                            ? item.location.locationname
                            : "";
                    item.selectedServices = item.selectedServices.filter(
                        (s) => !(null === s.name)
                    );
                    item.serviceName =
                        item.selectedServices &&
                        item.selectedServices.length > 1
                            ? `${item.selectedServices[0].name} and more services`
                            : item.selectedServices.length === 0
                              ? "No active service"
                              : item.selectedServices[0].name;
                    return item;
                });
                let schedulesWithTbs = [];
                for (let sc of scheduleList) {
                    schedulesWithTbs.push({
                        providerId: sc.providerId,
                        active: sc.active,
                        activeSchedule: sc.active,
                        locationName: sc.locationName,
                        serviceName: sc.serviceName,
                        id: `sc,${sc.id}`,
                        editType: "sc",
                        showTime: true,
                        showDeactivate: sc.active ? true : false,
                        showActivate: !sc.active ? true : false,
                        numoftbs: sc.tbs.length
                    });
                    for (let tb of sc.tbs) {
                        tb.timeRange = `${withAMPM(tb.startTime)} - ${withAMPM(
                            tb.endTime
                        )}`;
                        tb.dateRange = `${moment(tb.startDate).format(
                            "ll"
                        )} - ${moment(tb.endDate).format("ll")}`;

                        const weeks = [
                            "Sun",
                            "Mon",
                            "Tue",
                            "Wed",
                            "Thu",
                            "Fri",
                            "Sat"
                        ];

                        if (tb.weekDays) {
                            let repeat = "Every ";
                            const weekDaysArr =
                                !!tb.weekDays && tb.weekDays.split("");
                            // sort array before displaying
                            weekDaysArr.sort();

                            for (const we of weekDaysArr) {
                                repeat = repeat + weeks[we] + " ";
                            }

                            tb.repeat = repeat;
                        } else {
                            tb.repeat = "Never";
                        }

                        schedulesWithTbs.push({
                            ...tb,
                            providerId: sc.providerId,
                            id: `tb,${sc.id},${tb.id}`,
                            editType: "tb",
                            showTime: false,
                            activeSchedule: sc.active
                        });
                    }
                }
                console.log(
                    "schedulesWithTbs",
                    JSON.parse(JSON.stringify(schedulesWithTbs))
                );
                setRows(schedulesWithTbs);
            }
        }
    };

    async function handleDeactivateSchedule(sc) {
        setTobedeletedSchedule(sc);
        setDeactivateSchDialog(true);
        // console.log("deactivateSchedule", sc);
        // let res = await deactivateSchedule(sc.id.slice(3)); //remove sc,
        // await auditProviderSchedDelete(user, res);
        // setSnackMsg("Schedule is deleted successfully");
        // setMsgOpen(true);
        // setRefreshScheduleList(true);
    }

    async function processDeactivateSchedule() {
        if (tobedeletedSchedule) {
            console.log("processDeactivateSchedule", tobedeletedSchedule);
            setDeactivateSchDialog(false);
            let res = await deactivateSchedule(tobedeletedSchedule.id.slice(3)); //remove sc,
            await auditProviderSchedDelete(user, res);
            setSnackMsg("Schedule is deleted successfully");
            setMsgOpen(true);
            setRefreshScheduleList(true);
            setTobedeletedSchedule(null);
        }
    }

    function withAMPM(t) {
        if (!t) return "";
        const hm = t.split(":");
        let hh = Number.parseInt(hm[0]);
        let hr = hh < 13 ? hh : hh - 12;
        let hrstr = hr < 10 ? `0${hr}` : `${hr}`;
        let ampm = hh < 12 ? "AM" : "PM";
        return `${hrstr}:${hm[1]} ${ampm}`;
    }

    const _handleScheduleAdd = () => {
        actions.setMode("AddSc");
        actions.setPage("ProviderScheduleAddEditForm");
    };

    const _handleScheduleCancel = () => {
        sessionStorage.setItem("back", false);
        sessionStorage.setItem("search", false);
        actions.setMode("Add");
        actions.setPage("ProviderForm");
    };

    const _handleReturnToSearch = () => {
        sessionStorage.setItem("back", true);
        actions.setMode("Add");
        actions.setPage("ProviderForm");
    };

    const _handleBack = () => {
        sessionStorage.setItem("back", true);
        sessionStorage.setItem("search", false);
        actions.setId(state.id);
        actions.setPage("ProviderForm");
    };

    const declineSchedule = async (id, status) => {};

    const handleViewChange = (e, value) => {
        console.log("value", value);
        if (value != null) {
            setViewType(value);
        }
    };

    const _handleAddEvent = (start, end) => {
        console.log("start", start, end);
    };

    function displayCancelButton() {
        const loggedInUser = getUserFromCache();
        if (!userHasProviderOnlyRole()) {
            return (
                <Grid item>
                    <Tooltip title="Returns back to the main provider page">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleScheduleCancel}
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    function displayReturnButton() {
        const loggedInUser = getUserFromCache();
        if (!userHasProviderRole()) {
            return (
                <Grid item>
                    <Tooltip title="Returns to the page with the providers matching your search">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleReturnToSearch}
                        >
                            Return to Search
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    function displayBackButton() {
        const loggedInUser = getUserFromCache();
        if (!userHasProviderOnlyRole()) {
            return (
                <Grid item>
                    <Tooltip title="Returns to only the provider you selected">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleBack}
                        >
                            Back
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    const fetchProviders = useCallback(async (companyId) => {
        console.log("Providers List fetchProvider", providersList);
        let providersArray = await execReadBySortkey({
            opname: "providerByCompany",
            op: `query ProviderByCompany(
                $companyId: String
                $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
                $sortDirection: ModelSortDirection
                $filter: ModelProviderFilterInput
                $limit: Int
                $nextToken: String
              ) {
                providerByCompany(
                  companyId: $companyId
                  firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
                  sortDirection: $sortDirection
                  filter: $filter
                  limit: $limit
                  nextToken: $nextToken
                ) {
                  items {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    timezone
                  }
                  nextToken
                }
              }
              `,
            id: {
                companyId: companyId
            },
            filter: {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            }
        });

        if (
            providersArray &&
            !providersArray.error &&
            providersArray.items &&
            providersArray.items.length
        ) {
            providersArray.items.forEach((item) => {
                item.title = `${item.firstname} ${item.lastname} (${item.emailaddress})`;
            });
            setProvidersList(providersArray.items);
            const [{ timezone, id, firstname } = {}] =
                providersArray.items.filter(
                    (item) => item.id === state.provider.id
                );
            let providerTz = timezone;

            if (timezone === undefined || timezone === null) {
                providerTz = await getMasterTimezone();

                setSelectedProvider({
                    id,
                    text: firstname,
                    timezone: providerTz
                });
            } else {
                setSelectedProvider({
                    id,
                    text: firstname,
                    timezone: providerTz
                });
            }
        }
    }, []);

    const selectingProviderHandler = (providerId) => {
        console.log("selecting provider handler", providerId);
        if (providerId) {
            const [{ timezone, firstname } = {}] = providersList.filter(
                (item) => item.id === providerId
            );

            setSelectedProvider({
                id: providerId,
                text: firstname,
                timezone: timezone
            });

            return {
                id: providerId,
                text: firstname,
                timezone: timezone
            };
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <FormHeading title={"Schedules"} classes={classes} />
            <Dialog
                open={deactivateSchDialog}
                onClose={() => setDeactivateSchDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Schedule"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to delete the schedule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={processDeactivateSchedule} color="primary">
                        Yes
                    </Button>
                    <Button
                        onClick={() => setDeactivateSchDialog(false)}
                        color="primary"
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper rounded="true" className={classes.root}>
                {!(user && userHasProviderRole()) && (
                    <Typography className={classes.title} variant="h6" noWrap>
                        Schedules for{" "}
                        {state.provider.firstname +
                            " " +
                            state.provider.lastname}
                    </Typography>
                )}
                {viewType === "manage_availability" && (
                    <Grid
                        container
                        alignItems="baseline"
                        justifyContent="flex-end"
                        direction="row"
                        className={classes.marginBottom}
                    >
                        {/* <Grid item>
                                <FormControlLabel
                                    margin="normal"
                                    className={classes.marginBottom}
                                    control={
                                        <Switch
                                            checked={displayInactiveSchedules}
                                            onChange={(e) =>
                                                setDisplayInactiveSchedules(
                                                    e.target.checked
                                                )
                                            }
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "primary checkbox"
                                            }}
                                        />
                                    }
                                    label="Display Inactive Schedules"
                                />
                            </Grid>*/}
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleScheduleAdd}
                            >
                                {/* <AddIcon className={classes.leftIcon} /> */}
                                &nbsp;NEW SCHEDULE
                            </Button>
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            {/* viewType === "schedule" && (
                                    <ScheduleDisplay
                                        isAdminRole={userHasAdminRole()}
                                        selectedProviders={[
                                            {
                                                id: state.provider.id,
                                                text: state.provider.firstname,
                                                timezone: state.provider.timezone
                                            }
                                        ]}
                                        company={props.company}
                                        currentDate={currentDate}
                                        setCurrentDate={setCurrentDate}
                                    />
                                ) */}
                            {viewType === "availability" && (
                                <AvailabilityDisplay
                                    selectedProviders={[selectedProvider]}
                                    company={props.company}
                                    currentDate={currentDate}
                                    setCurrentDate={setCurrentDate}
                                    viewType={viewType}
                                    fromPath={"ProviderSchedule"}
                                    selectingProviderHandler={
                                        selectingProviderHandler
                                    }
                                    providersList={providersList}
                                />
                            )}
                            {viewType === "manage_availability" && (
                                <ScheduleTable
                                    selectedRow={(selected) =>
                                        setSelected(selected)
                                    }
                                    rows={rows.filter((item) =>
                                        displayInactiveSchedules
                                            ? true
                                            : item.activeSchedule
                                    )}
                                    handleDeactivateSchedule={
                                        handleDeactivateSchedule
                                    }
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    {searchedFor && displayReturnButton()}
                    &nbsp;
                    {displayBackButton()}
                    &nbsp;
                    {displayCancelButton()}
                </Grid>
            </Paper>
        </>
    );
};
