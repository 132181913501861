import { createContext } from "react";
const flags = {
    providerAvailabilityFlag:
        process.env.REACT_APP_FF_PROVIDER_AVAILABILITY === "true"
};
export const FlagsContext = createContext(flags);
const FlagsProvider = ({ children }) => {
    return (
        <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
    );
};
export default FlagsProvider;
