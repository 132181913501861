import { Box } from "@mui/material";
import React, { useState } from "react";

const ImageTooltip = ({ content, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = (e) => {
        // console.log("I am on icon");
        setShowTooltip(true);
        e.stopPropagation();
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <Box
            sx={{ position: "relative", display: "inline-block" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "80%",
                        left: "100%",
                        transform: "translateY(-20%)",
                        //backgroundColor: "black",
                        color: "white",
                        padding: "8px",
                        borderRadius: "4px",
                        zIndex: "tooltip"
                    }}
                >
                    {content}
                </Box>
            )}
        </Box>
    );
};

export default ImageTooltip;
