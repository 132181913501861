import React, { useState, useEffect, Fragment } from "react";
import classes from "../../styles/OnboardingLandingPage.module.css";
import Navbar from "../layout/Navbar";
import ActivateAccountForm from "../forms/ActivateAccountForm";
import "../../OnboardingApp.css";
import UserInputState from "../../context/userInput/userInputState";

function ActivateAccount(props) {
    const [user, setUser] = useState();

    return (
        <Fragment>
            <UserInputState>
                <Navbar />
                <main className={classes.main_activate}>
                    <ActivateAccountForm state={props.state} />
                </main>
            </UserInputState>
        </Fragment>
    );
}

export default ActivateAccount;
