import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Box, Button, Paper, Grid, FormControl, Typography, Snackbar, Select, InputLabel, MenuItem, Chip, InputAdornment, CircularProgress, Autocomplete } from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import postalCodeValidator from "postal-codes-js";
import { serviceAddStyles } from "../styles/ServiceAddStyles";
import { getCurrencySymbol } from "../utils";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@mui/material";
import { auditPriceCreate, auditPriceUpdate } from "../modules/Audit";
import { regionalPricingByCompanyServiceType } from "../queries/CustomQueries";
import { execReadBySortkey } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";
import { useNavigate } from "react-router-dom";

export default function RegionAddEditForm({mode}) {
    const navigate = useNavigate();

    const { state, dispatch, actions } = useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    //fields
    //const [name, setName] = useState("");
    const [postalcode, setPostalCode] = useState([]);
    const [pricingType, setPricingType] = useState("");
    const [pricingTypeId, setPricingTypeId] = useState();
    const [description, setDescription] = useState("");
    const [isEdit, setisEdit] = useState(false);
    const [price, setPrice] = useState();
    const [serviceType, setServiceType] = useState("");
    const [serviceTypeId, setServiceTypeId] = useState("");
    const [serviceTypeData, setServiceTypeData] = useState([]);
    const [pricingTypeData, setPricingTypeData] = useState([
        { id: "COUNTRY", name: "Pricing by Country" },
        { id: "PROVINCE", name: "Pricing by State / Province" },
        { id: "POSTALCODE", name: "Pricing by  Zip / Postal Code" }
    ]);
    const [countryType, setCountryType] = useState("");
    const [countryTypeId, setCountryTypeId] = useState("");
    const [countryTypeData, setCountryTypeData] = useState([
        { id: "CA", name: "Canada" },
        { id: "US", name: "USA" },
        { id: "AU", name: "Australia" }
    ]);
    const [provinceType, setProvinceType] = useState("");
    const [provinceTypeId, setProvinceTypeId] = useState("");
    const [provinceTypeData, setProvinceTypeData] = useState([]);
    const [oldInfo, setOldInfo] = useState([]);

    const [id, setId] = useState("");
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    const componentClasses = serviceAddStyles();
    const [disableSave, setDisableSave] = useState(false);

    // Import zip codes
    const [importDialog, setImportDialog] = useState(false);
    const [validateClicked, setValidateClicked] = useState(false);
    const [postalCodesList, setPostalCodesList] = useState("");
    const [importList, setImportList] = useState([]);

    // formatting for services
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    function isEqual(array1, array2) {
        if (array1.length != array2.length) {
            return false;
        } else {
            array1.sort();
            array2.sort();
            for (var i = 0; i < array1.length; i++) {
                if (array1[i] != array2[i]) return false;
            }
            return true;
        }
    }

    useEffect(() => {
        async function doFetch() {
            const listofServiceTypes = await getServiceTypeData();
            if (mode === "Edit") {
                await getRegionData(listofServiceTypes);
            }
        }
        doFetch();
    }, []);

    useEffect(() => {
        console.log("Postal Code", postalcode);
    }, [postalcode]);

    async function getServiceTypeData() {
        const loggedInUser = getUserFromCache();

        const result = await graphql(
            graphqlOperation(queries.serviceTypeByCompany, {
                companyId: loggedInUser.company.id,
                filter: {
                    deleted: { ne: true },
                    active: { ne: false },
                    isVisible: { ne: false }
                },
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );
        // sort services
        result.data.serviceTypeByCompany.items.sort((p1, p2) => {
            const psq1 = p1.name.toUpperCase();
            const psq2 = p2.name.toUpperCase();
            if (psq1 < psq2) return -1;
            if (psq1 > psq2) return 1;
            return 0;
        });
        const listofServiceTypes = result.data.serviceTypeByCompany.items;
        setServiceTypeData(listofServiceTypes);
        return listofServiceTypes;
    }

    async function getRegionData(listofServiceTypes) {
        const regionalPriceId = state.id;
        if (!!regionalPriceId) {
            setisEdit(true);
            const input = { id: regionalPriceId };
            const result = await graphql(
                graphqlOperation(queries.getRegionalPricing, input)
            );
            console.log("getRegionalPricing", result);

            if (!result.data.getRegionalPricing) {
                navigate("..");
            } else {
                if (!(!!result.errors && result.errors.length > 0)) {
                    const {
                        id,
                        servicetype,
                        pricingtype,
                        country,
                        countryName,
                        province,
                        postalcodes,
                        price,
                        notes
                    } = result.data.getRegionalPricing;
                    console.log("*** result", result.data.getRegionalPricing);
                    setServiceTypeId(servicetype.id);
                    setPricingTypeId(pricingtype);
                    setCountryTypeId(country);
                    setProvinceTypeId(province);
                    setProvinceTypeData(
                        country === "CA"
                            ? CANADA_PROV
                            : country === "US"
                              ? USA_STATES
                              : AUSTRALIA_STATES
                    );
                    setPostalCode(postalcodes);
                    setPrice(price);
                    setDescription(notes);
                    setCountryType(countryName);
                    setId(id);
    
                    console.log(
                        "listofServiceTypes = " + JSON.stringify(listofServiceTypes)
                    );
                    // find in serviceTypeData
                    let serviceName;
                    for (let x = 0; x < listofServiceTypes.length; x++) {
                        if (listofServiceTypes[x].id === servicetype.id) {
                            const value = listofServiceTypes[x].name;
                            console.log(
                                "find in listofServiceTypes, value = " + value
                            );
                            serviceName = value;
                            setServiceType(value);
                        }
                    }
    
                    // find in pricingTypeData
                    for (let x = 0; x < pricingTypeData.length; x++) {
                        if (pricingTypeData[x].id === pricingtype) {
                            const value = pricingTypeData[x].name;
                            console.log(
                                "find in pricingTypeData, value = " + value
                            );
                            setPricingType(value);
                        }
                    }
    
                    if (pricingtype === "COUNTRY" || pricingtype === "PROVINCE") {
                        oldInfo.push({
                            serviceName: serviceName,
                            price: price,
                            desc: notes
                        });
                    } else if (pricingtype === "POSTALCODE") {
                        oldInfo.push({
                            serviceName: serviceName,
                            postalcodes: postalcodes,
                            price: price,
                            desc: notes
                        });
                    }
                    console.log("oldInfo", oldInfo);
                }
            }
        } else {
            navigate("..");
        }
    }

    // populate province/state field
    useEffect(() => {
        // find in provinceTypedata
        for (let x = 0; x < provinceTypeData.length; x++) {
            if (provinceTypeData[x].id === provinceTypeId) {
                const value = provinceTypeData[x].name;
                console.log("find in provinceTypeData, value = " + value);
                setProvinceType(value);
            }
        }
    }, [provinceTypeData, provinceTypeId]);

    async function addRegionalPrice() {
        console.log("In addRegionalPrice...");
        try {
            const authuser = getUserFromCache();
            let input = {
                regionalPricingServicetypeId: serviceTypeId,
                servicetypeId: serviceTypeId,
                pricingtype: pricingTypeId,
                country: countryTypeId,
                countryName: countryType,
                price: price,
                companyId: authuser.company.id,
                regionalPricingCompanyId: authuser.company.id,
                active: true,
                deleted: false
            };

            if (provinceTypeId && provinceTypeId != "") {
                input = {
                    ...input,
                    province: provinceTypeId
                };
            }

            if (postalcode && postalcode != "") {
                input = {
                    ...input,
                    postalcodes: postalcode
                };
            }

            if (description && description != "") {
                input = {
                    ...input,
                    notes: description
                };
            }

            console.log(
                "In addRegionalPrice, input = " + JSON.stringify(input)
            );

            let response;
            if (isEdit) {
                input.id = state.id;
                response = await graphql(
                    graphqlOperation(mutations.updateRegionalPricing, { input })
                );

                await auditPriceUpdate(
                    authuser,
                    response.data.updateRegionalPricing,
                    provinceType,
                    newInfo
                );
                // console.log("what is it", response.data.updateRegionalPricing);
            } else {
                response = await graphql(
                    graphqlOperation(mutations.createRegionalPricing, { input })
                );

                await auditPriceCreate(
                    authuser,
                    response.data.createRegionalPricing,
                    provinceType
                );
                // console.log("what is it", response.data.createRegionalPricing);
            }
            console.log(
                "In addRegionalPrice, response = " + JSON.stringify(response)
            );
            if (response && response.data) {
                setSnackMsg("Regional pricing info successfully saved.");
                setMsgOpen(true);
                setTimeout(() => {
                    navigate("..");
                }, 2000);
            } else {
                setSnackMsg("Unable to save regional pricing info.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error("Add Region error => ", error);
            setDisableSave(false);
        }
    }

    let newInfo;
    async function handleSaveRegionalPrice() {
        setDisableSave(true);

        // validate
        if (!serviceTypeId || serviceTypeId === "") {
            setSnackMsg("Please enter a value for service type.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (!pricingTypeId || pricingTypeId === "") {
            setSnackMsg("Please enter a value for the package pricing type.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (!countryTypeId || countryTypeId === "") {
            setSnackMsg(
                "Please enter a value for the regional pricing country."
            );
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (
            pricingTypeId != "COUNTRY" &&
            (!provinceTypeId || provinceTypeId === "")
        ) {
            setSnackMsg(
                "Please enter a value for the regional pricing province or state."
            );
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        console.log("postalcode", postalcode);
        if (pricingTypeId === "POSTALCODE" && postalcode.length <= 0) {
            setSnackMsg("Please enter at least one postal code.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (!price || price === "") {
            setSnackMsg("Please enter a value for the regional price.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }
        // notes are optional
        const regpri = await fetchRegionalPricings();

        const validationResult = validateRegionalPricing(regpri);

        if (validationResult) {
            let message =
                validationResult === "COUNTRY"
                    ? `A ${pricingTypeId.toLowerCase()} region already exists for ${countryType}.`
                    : validationResult === "PROVINCE"
                      ? `A ${pricingTypeId.toLowerCase()} region already exists for ${provinceType}.`
                      : validationResult.includes("POSTALCODE")
                        ? `The postal/zip code ${validationResult.replace(
                              "POSTALCODE",
                              ""
                          )} has already been specified in another regional price.`
                        : validationResult.includes("OWNDUPLICATE")
                          ? `The postal/zip code ${validationResult.replace(
                                "OWNDUPLICATE",
                                ""
                            )} has already been specified in this regional price.`
                          : "";

            setSnackMsg(message);
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (isEdit) {
            if (pricingTypeId === "COUNTRY" || pricingTypeId === "PROVINCE") {
                newInfo = [
                    {
                        oldName: oldInfo[0].serviceName,
                        oldPrice: oldInfo[0].price,
                        oldDesc: oldInfo[0].desc,
                        serviceName: "No Change",
                        price: "No Change",
                        desc: "No Change"
                    }
                ];
            } else if (pricingTypeId === "POSTALCODE") {
                newInfo = [
                    {
                        oldName: oldInfo[0].serviceName,
                        oldPrice: oldInfo[0].price,
                        oldPostal: oldInfo[0].postalcodes.length,
                        oldDesc: oldInfo[0].desc,
                        serviceName: "No Change",
                        postalcodes: "No Change",
                        price: "No Change",
                        desc: "No Change"
                    }
                ];

                let codeFormat =
                    countryType === "USA" ? "zipcode(s)" : "postalcode(s)";

                if (!isEqual(oldInfo[0].postalcodes, postalcode)) {
                    if (postalcode.length > oldInfo[0].postalcodes.length) {
                        let newCodes =
                            postalcode.length - oldInfo[0].postalcodes.length;
                        newInfo.map((item) => {
                            item.postalcodes = `${newCodes} ${codeFormat} have been added, please see region for more info.`;
                        });
                    } else if (
                        postalcode.length < oldInfo[0].postalcodes.length
                    ) {
                        let newCodes =
                            oldInfo[0].postalcodes.length - postalcode.length;
                        newInfo.map((item) => {
                            item.postalcodes = `${newCodes} ${codeFormat} have been removed, please see region for more info.`;
                        });
                    } else {
                        codeFormat =
                            countryType === "USA" ? "zipcode" : "postalcode";
                        newInfo.map((item) => {
                            item.postalcodes = `At least one ${codeFormat} has been changed, please see region for more info.`;
                        });
                    }
                }
            }

            console.log("newInfo", newInfo);

            if (serviceType != oldInfo[0].serviceName) {
                newInfo.map((item) => {
                    item.serviceName = serviceType;
                });
            }

            if (price != oldInfo[0].price) {
                newInfo.map((item) => {
                    item.price = price;
                });
            }

            if (description != oldInfo[0].desc) {
                newInfo.map((item) => {
                    item.desc = description;
                });
            }
        }
        addRegionalPrice();
    }

    async function fetchRegionalPricings() {
        const authuser = getUserFromCache();
        // get all regional pricings for this company and service type combo

        let listRegionalPricingData = await execReadBySortkey({
            opname: "regionalPricingByCompanyServiceType",
            op: regionalPricingByCompanyServiceType,
            id: { companyId: authuser.company.id },
            skey: { servicetypeId: { eq: serviceTypeId } },
            filter: {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            },
            sortDirection: "DESC"
        });

        return listRegionalPricingData.items;
    }
    // 1. filter regpri into the specified pricing type
    // 2. filter further to see if there is a matching country/province/postal code value that is NOT in the current regional pricing being edited
    // 3. if after all the filtering, there is still something in the array, that means there's a duplicate regional pricing set up

    function validateRegionalPricing(regpri) {
        if (pricingTypeId === "COUNTRY" && regpri) {
            let countryRegPri = regpri.filter(
                (o) => o.pricingtype === "COUNTRY"
            );
            if (countryRegPri)
                countryRegPri = countryRegPri.filter(
                    (o) => o.id !== id && o.country.trim() === countryTypeId
                );
            if (countryRegPri.length) return "COUNTRY";
        } else if (pricingTypeId === "PROVINCE" && regpri) {
            let provRegPri = regpri.filter((o) => o.pricingtype === "PROVINCE");
            if (provRegPri)
                provRegPri = provRegPri.filter(
                    (o) => o.id !== id && o.province.trim() === provinceTypeId
                );
            if (provRegPri.length) return "PROVINCE";
        } else if (pricingTypeId === "POSTALCODE" && regpri) {
            let postalRegPri = regpri.filter(
                (o) => o.pricingtype === "POSTALCODE"
            );
            if (postalRegPri)
                postalRegPri = postalRegPri.filter((o) => o.id !== id);
            let postalcodes = [];
            // since postal codes are stored in an array for Regional Pricing, save all possible postal codes into postalcodes variable for easy access
            if (postalRegPri) {
                postalRegPri.map((pc) => {
                    postalcodes.push(...pc.postalcodes);
                });
            }
            let postalCodeIsDuplicate = false;
            let matchedpc;
            //iterate through user-inputted array of postal codes and see if it matches any values in postalcodes array
            if (postalcodes) {
                for (let x = 0; x < postalcode.length; x++) {
                    postalcodes.map((pc) => {
                        if (
                            postalcode[x].replace(/\s+/g, "").toUpperCase() ===
                            pc.replace(/\s+/g, "").toUpperCase()
                        ) {
                            matchedpc = pc;
                            postalCodeIsDuplicate = true;
                        }
                    });
                }
            }

            if (!!postalCodeIsDuplicate) return `POSTALCODE${matchedpc}`;

            // this will return an array of all duplicate values - this matches against the postalcodes already inputted
            let duppc = postalcode.filter((p, index) => {
                return postalcode.indexOf(p) !== index;
            });

            if (duppc && duppc.length > 0) return `OWNDUPLICATE${duppc[0]}`;
        } else return null;
    }

    const handleChangePrice = (e) => {
        if (
            Number.isInteger(Number.parseInt(e.target.value)) &&
            Number.parseInt(e.target.value) >= 0
        ) {
            setPrice(e.target.value);
            //setErrPrice(false);
        } else {
            setPrice("");
            //setErrPrice(true);
        }
    };

    function handleChangePricingType(event) {
        const value = event.target.value;
        setPricingType(event.target.value);
        // find in pricingTypeData
        for (let x = 0; x < pricingTypeData.length; x++) {
            if (pricingTypeData[x].name === value) {
                const id = pricingTypeData[x].id;
                setPricingTypeId(id);
                console.log("handleChangePricingType id = " + id);
            }
        }
    }

    function handleChangeCountryType(event) {
        const value = event.target.value;
        setCountryType(event.target.value);
        // find in countryTypeData
        for (let x = 0; x < countryTypeData.length; x++) {
            if (countryTypeData[x].name === value) {
                const id = countryTypeData[x].id;
                setCountryTypeId(id);
                console.log("handleChangeCountryType id = " + id);
            }
        }
        if (value === "Canada") {
            setProvinceTypeData(CANADA_PROV);
        } else {
            if (value === "USA") {
                setProvinceTypeData(USA_STATES);
            } else {
                setProvinceTypeData(AUSTRALIA_STATES);
            }
        }
        setProvinceType("");
        setProvinceTypeId("");
    }

    function handleChangeProvinceType(event) {
        const value = event.target.value;
        setProvinceType(event.target.value);
        // find in provinceTypeData
        for (let x = 0; x < provinceTypeData.length; x++) {
            if (provinceTypeData[x].name === value) {
                const id = provinceTypeData[x].id;
                setProvinceTypeId(id);
                console.log("handleChangeProvinceType id = " + id);
            }
        }
    }

    function handleServicesChange(event) {
        const value = event.target.value;
        setServiceType(value);
        console.log("handleServicesChange value = " + value);
        // find in serviceTypeData
        for (let x = 0; x < serviceTypeData.length; x++) {
            if (serviceTypeData[x].name === value) {
                const id = serviceTypeData[x].id;
                setServiceTypeId(id);
                console.log("handleServicesChange id = " + id);
            }
        }
    }

    // cancel and go back to PromotionForm
    function handleCancelRegion() {
        navigate("..");
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    function reset() {
        // reset fields
        //setName("");
        setDescription("");
        setPostalCode([]);
    }

    function validatePostalCode(postalcode) {
        let isValid = false;
        if (postalCodeValidator.validate("us", postalcode) === true)
            isValid = true; // USA 5 digit
        if (postalCodeValidator.validate("USA", postalcode) === true)
            isValid = true; // USA 9 digit
        if (postalCodeValidator.validate("CAN", postalcode) === true)
            isValid = true; // Canada
        return isValid;
    }

    // const handleChangeName = (e) => {
    //   setName(e.target.value);
    // };
    // handle description change
    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };

    const handleOpenImport = () => {
        setImportDialog(true);
    };

    const handleCloseImport = () => {
        setImportDialog(false);
    };

    const handlePostalCodesList = (e) => {
        setImportList([]);
        setValidateClicked(false);
        setPostalCodesList(e.target.value);
    };

    const handleValidateList = () => {
        setValidateClicked(true);
        const postalCodesArray = postalCodesList.replace(/\s+/g, "").split(",");
        const validated = postalCodesArray
            .filter((item) => validatePostalCode(item))
            .map((item) => item.toUpperCase());
        setImportList(validated);
    };

    const saveList = () => {
        console.log("import list", importList);
        setPostalCode((prevState) => [...prevState, ...importList]);
        setPostalCodesList("");
        setImportList([]);
        setValidateClicked(false);
        setImportDialog(false);
    };

    function displayCountrySelection() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl
                        margin="normal"
                        required
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel id="label-select-country">
                            {isEdit ? "Country (not editable)" : "Country"}
                        </InputLabel>
                        <Select
                            labelId="label-select-country"
                            label={
                                isEdit ? "Country (not editable)" : "Country"
                            }
                            value={countryType}
                            disabled={isEdit}
                            onChange={handleChangeCountryType}
                            renderValue={(selected) => (
                                <Chip
                                    color="primary"
                                    size="small"
                                    key={id}
                                    label={selected}
                                    className={classes.chip}
                                />
                            )}
                            MenuProps={MenuProps}
                        >
                            {countryTypeData &&
                                countryTypeData.map((item) => (
                                    <MenuItem key={item.id} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    function displayProvinceSelection() {
        console.log("pricingTypeId = " + pricingType);
        if (
            (pricingTypeId === "PROVINCE" || pricingTypeId === "POSTALCODE") &&
            countryType
        ) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel id="label-select-province">
                                {isEdit
                                    ? "Province (not editable)"
                                    : "Province"}
                            </InputLabel>
                            <Select
                                labelId="label-select-province"
                                label={
                                    isEdit
                                        ? "Province (not editable)"
                                        : "Province"
                                }
                                value={provinceType}
                                disabled={isEdit}
                                onChange={handleChangeProvinceType}
                                renderValue={(selected) => (
                                    <Chip
                                        color="primary"
                                        size="small"
                                        key={id}
                                        label={selected}
                                        className={classes.chip}
                                    />
                                )}
                                MenuProps={MenuProps}
                            >
                                {provinceTypeData &&
                                    provinceTypeData.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }
    }

    function displayPostalCodeSelection() {
        if (pricingTypeId === "POSTALCODE" && provinceType) {
            return (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl margin="normal" required fullWidth>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={postalcode}
                                value={postalcode}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        if (validatePostalCode(option)) {
                                            return (
                                                <Chip
                                                    variant="filled"
                                                    color="primary"
                                                    label={option.toUpperCase()}
                                                    {...getTagProps({ index })}
                                                    onDelete={(chip) => {
                                                        console.log(
                                                            "onDelete",
                                                            chip
                                                        );
                                                        console.log(
                                                            "onDelete",
                                                            index
                                                        );
                                                        console.log(
                                                            "onDelete",
                                                            option
                                                        );
                                                        setPostalCode(
                                                            postalcode.filter(
                                                                (item, i) => {
                                                                    return (
                                                                        i !==
                                                                        index
                                                                    );
                                                                }
                                                            )
                                                        );
                                                    }}
                                                />
                                            );
                                        } else {
                                            setSnackMsg(
                                                "Please enter a valid postal code."
                                            );
                                            setMsgOpen(true);
                                            return;
                                        }
                                    })
                                }
                                onChange={(e) => {
                                    console.log(
                                        "Autocomplete-830",
                                        e.target.value
                                    );
                                    console.log(
                                        "validating",
                                        validatePostalCode(e.target.value)
                                    );
                                    /*  let postalCodeArray = [];
                                if (postalcode.length > 0) {
                                    postalCodeArray = [
                                        ...postalcode,
                                        e.target.value
                                    ];
                                } else {
                                    postalCodeArray.push(e.target.value);
                                } */
                                    if (
                                        validatePostalCode(e.target.value) ===
                                        true
                                    ) {
                                        setPostalCode(
                                            postalcode.concat(
                                                e.target.value
                                                    .replace(/\s+/g, "")
                                                    .toUpperCase()
                                            )
                                        );
                                    } else {
                                        setSnackMsg(
                                            "Please enter a valid postal code."
                                        );
                                        setMsgOpen(true);
                                        return;
                                    }
                                }}
                                renderInput={(params) => {
                                    console.log(params);
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Enter Postal Codes"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenImport}
                        >
                            Import List
                        </Button>
                    </Grid>
                    <Dialog
                        fullWidth
                        maxWidth={"md"}
                        onClose={handleCloseImport}
                        open={importDialog}
                    >
                        <DialogTitle>Import Comma-Seperated List</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please paste a comma-separated list of
                                postal/zip codes into the text field below to
                                import list:
                            </DialogContentText>
                            <TextField
                                multiline
                                fullWidth
                                margin="dense"
                                label="Paste List Here"
                                onChange={handlePostalCodesList}
                                value={postalCodesList}
                            />
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={importList.length > 0}
                                    onClick={handleValidateList}
                                >
                                    Validate
                                </Button>
                            </DialogActions>
                            {validateClicked ? (
                                <DialogContentText>
                                    {importList.length
                                        ? `${importList.length} postal/zip codes will be imported`
                                        : "Validation error: Unable to parse the list of postal/zip codes"}
                                </DialogContentText>
                            ) : (
                                ""
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseImport} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={saveList}
                                disabled={!importList.length}
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            );
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            fullWidth
                            required
                            className={classes.formControl}
                        >
                            <InputLabel id="label-select-service">
                                Service Type
                            </InputLabel>
                            <Select
                                labelId="label-select-service"
                                label="Service Type"
                                value={serviceType}
                                onChange={handleServicesChange}
                                renderValue={(selected) => (
                                    <Chip
                                        color="primary"
                                        size="small"
                                        key={id}
                                        label={selected}
                                        className={classes.chip}
                                    />
                                )}
                                MenuProps={MenuProps}
                            >
                                {serviceTypeData &&
                                    serviceTypeData.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>

                            {/* <FormHelperText>
                This required field allows you to choose the level at which
                regional pricing will apply.
              </FormHelperText> */}
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel id="label-select-pricingtype">
                                {isEdit
                                    ? "Pricing Type (not editable)"
                                    : "Pricing Type"}
                            </InputLabel>
                            <Select
                                labelId="label-select-pricingtype"
                                label={
                                    isEdit
                                        ? "Pricing Type (not editable)"
                                        : "Pricing Type"
                                }
                                disabled={isEdit}
                                value={pricingType}
                                onChange={handleChangePricingType}
                                renderValue={(selected) => (
                                    <Chip
                                        color="primary"
                                        size="small"
                                        key={id}
                                        label={selected}
                                        className={classes.chip}
                                    />
                                )}
                                MenuProps={MenuProps}
                            >
                                {pricingTypeData &&
                                    pricingTypeData.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {displayCountrySelection()}
                {displayProvinceSelection()}
                {displayPostalCodeSelection()}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Regional Price"
                            id="regional-price"
                            value={price}
                            type="number"
                            // className={clsx(
                            //   componentClasses.margin,
                            //   componentClasses.textField
                            // )}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {getCurrencySymbol()}
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            onChange={handleChangePrice}
                            //error={errPrice}
                            //helperText={errPrice ? "Please enter a posivite price" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <TextField
                                id="description"
                                label="Notes"
                                multiline
                                rows="4"
                                value={description}
                                className={classes.textField}
                                onChange={handleChangeDescription}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={5}>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveRegionalPrice}
                                disabled={disableSave || state.mode === "View"}
                            >
                                Save
                                {disableSave && (
                                    <CircularProgress
                                        size={24}
                                        className={classesnew.buttonProgress}
                                    />
                                )}
                            </Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelRegion}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

const USA_STATES = [
    { id: "AL", name: "Alabama" },
    { id: "AK", name: "Alaska" },
    { id: "AS", name: "American Samoa" },
    { id: "AZ", name: "Arizona" },
    { id: "AR", name: "Arkansas" },
    { id: "CA", name: "California" },
    { id: "CO", name: "Colorado" },
    { id: "CT", name: "Connecticut" },
    { id: "DE", name: "Delaware" },
    { id: "DC", name: "District Of Columbia" },
    { id: "FM", name: "Federated States Of Micronesia" },
    { id: "FL", name: "Florida" },
    { id: "GA", name: "Georgia" },
    { id: "GU", name: "Guam" },
    { id: "HI", name: "Hawaii" },
    { id: "ID", name: "Idaho" },
    { id: "IL", name: "Illinois" },
    { id: "IN", name: "Indiana" },
    { id: "IA", name: "Iowa" },
    { id: "KS", name: "Kansas" },
    { id: "KY", name: "Kentucky" },
    { id: "LA", name: "Louisiana" },
    { id: "ME", name: "Maine" },
    { id: "MH", name: "Marshall Islands" },
    { id: "MD", name: "Maryland" },
    { id: "MA", name: "Massachusetts" },
    { id: "MI", name: "Michigan" },
    { id: "MN", name: "Minnesota" },
    { id: "MS", name: "Mississippi" },
    { id: "MO", name: "Missouri" },
    { id: "MT", name: "Montana" },
    { id: "NE", name: "Nebraska" },
    { id: "NV", name: "Nevada" },
    { id: "NH", name: "New Hampshire" },
    { id: "NJ", name: "New Jersey" },
    { id: "NM", name: "New Mexico" },
    { id: "NY", name: "New York" },
    { id: "NC", name: "North Carolina" },
    { id: "ND", name: "North Dakota" },
    { id: "MP", name: "Northern Mariana Islands" },
    { id: "OH", name: "Ohio" },
    { id: "OK", name: "Oklahoma" },
    { id: "OR", name: "Oregon" },
    { id: "PW", name: "Palau" },
    { id: "PA", name: "Pennsylvania" },
    { id: "PR", name: "Puerto Rico" },
    { id: "RI", name: "Rhode Island" },
    { id: "SC", name: "South Carolina" },
    { id: "SD", name: "South Dakota" },
    { id: "TN", name: "Tennessee" },
    { id: "TX", name: "Texas" },
    { id: "UT", name: "Utah" },
    { id: "VT", name: "Vermont" },
    { id: "VI", name: "Virgin Islands" },
    { id: "VA", name: "Virginia" },
    { id: "WA", name: "Washington" },
    { id: "WV", name: "West Virginia" },
    { id: "WI", name: "Wisconsin" },
    { id: "WY", name: "Wyoming" }
];

const CANADA_PROV = [
    { id: "AB", name: "Alberta" },
    { id: "BC", name: "British Columbia" },
    { id: "MB", name: "Manitoba" },
    { id: "NB", name: "New Brunswick" },
    { id: "NL", name: "Newfoundland and Labrador" },
    { id: "NS", name: "Nova Scotia" },
    { id: "NT", name: "Northwest Territories" },
    { id: "NU", name: "Nunavut" },
    { id: "ON", name: "Ontario" },
    { id: "PE", name: "Prince Edward Island" },
    { id: "QC", name: "Québec" },
    { id: "SK", name: "Saskatchewan" },
    { id: "YT", name: "Yukon" }
];

const AUSTRALIA_STATES = [
    { id: "NSW", name: "New South Wales" },
    { id: "QLD", name: "Queensland" },
    { id: "SA", name: "South Australia" },
    { id: "TAS", name: "Tasmania" },
    { id: "VIC", name: "Victoria" },
    { id: "WA", name: "Western Australia" },
    { id: "ACT", name: "Australian Capital Territory" },
    { id: "NT", name: "Northern Territory" }
];
