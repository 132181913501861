import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import {
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    IconButton,
    Table,
    Checkbox,
    TableBody,
    TableFooter,
    Grid
} from "@mui/material";
import { DataTableWrapper } from "../utils/CommonComonents/DataTableWrapper";
import { TimeBlockColumns } from "../utils/Constants";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from "../context/StoreContext";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
//{classes, page, rowsPerPage, columns, rows, multiSelectable, selected, selectAll, handleSelectAllClick, handleRowClick, handleEditClick, handleViewClick}) => {

const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
export const TimeBlockTable = (props) => {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const { rows, selectedRow } = props;

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const _handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    };
    const _handleRowClick = (event, id) => {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    };

    const _handleEditClick = (id) => {
        actions.setMode("Edit");
        actions.setPage("TimeBlockProviders");
        actions.setId(id);
    };

    const _handleViewClick = (id) => {
        actions.setMode("View");
        actions.setPage("TimeBlockProviders");
        actions.setId(id);
    };

    const _handleTimeClick = (id) => {
        actions.setPage("TimeBlockForm");
        actions.setId(id);
    };
    return (
        <>
            <div>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <DataTableWrapper
                            classes={classes}
                            columns={TimeBlockColumns}
                            rows={rows}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={selected}
                            selectAll={selectAll}
                            handleEditClick={_handleEditClick}
                            handleRowClick={_handleRowClick}
                            handleSelectAllClick={_handleSelectAllClick}
                            handleViewClick={_handleViewClick}
                            handleTimeClick={_handleTimeClick}
                            showAction={true}
                        />
                    </div>
                    <TableFooter>
                        <TableRow style={{ float: "right" }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "Rows per page"
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Paper>
            </div>
        </>
    );
};
