import format from "date-fns/format";
import moment from "moment";
import { getUserFromCache } from "../user/UserCommon";
import uuid from "uuid/v4";

export const convertDollarsToCents = (price) => (price * 100).toFixed(0);

export const convertCentsToDollars = (price) => (price / 100).toFixed(2);

export const formatProductDate = (date) => format(date, "MMM Do, YYYY");

export const getCurrencySymbol = () => {
    const companyCurrency = getUserFromCache()?.company
        ? getUserFromCache().company.currency
        : "$";
    switch (companyCurrency) {
        case "GBP":
            return "£";
        case "CAD":
            return "$";
        case "USD":
            return "$";
        case "AUD":
            return "$";
        case "BBD":
            return "$";
        default:
            return "$";
    }
};
export const getCurrencySymbolFromParam = (companyCurrency) => {
    switch (companyCurrency) {
        case "GBP":
            return "£";
        case "CAD":
            return "$";
        case "USD":
            return "$";
        case "AUD":
            return "$";
        case "BBD":
            return "$";
        default:
            return "$";
    }
};

export const formatOrderDate = (date) =>
    format(date, "ddd h:mm A, MMM Do, YYYY");

export const _findPromotions = (data, tab) => {
    let rows = data;
    if (data && data.length) {
        if (tab === 0) {
            rows = data.filter((item) => {
                return (
                    _getStartOf().diff(_getStartOf(item.startdate), "days") >=
                        0 &&
                    _getStartOf().diff(_getStartOf(item.enddate), "days") < 0
                );
            });
        } else if (tab === 1) {
            rows = data.filter((item) => {
                return (
                    _getStartOf(item.startdate).diff(_getStartOf(), "days") > 0
                );
            });
        } else {
            rows = data.filter((item) => {
                return (
                    _getStartOf(item.enddate).diff(_getStartOf(), "days") <= 0
                );
            });
        }
    }
    return rows;
};

export const filterBookings = (
    rows,
    clientFilter,
    providerFilter,
    serviceTypeId
) => {
    if (serviceTypeId && serviceTypeId !== 0) {
        rows = rows.filter((item) => item.servicetype.id === serviceTypeId);
    }
    if (clientFilter && clientFilter.length === 2) {
        rows = rows.filter((item) =>
            item.client.user.firstname && item.client.user.lastname
                ? item.client.user.firstname
                      .toLowerCase()
                      .includes(clientFilter[0].toLowerCase()) &&
                  item.client.user.lastname
                      .toLowerCase()
                      .includes(clientFilter[1].toLowerCase())
                : false
        );
    }
    if (clientFilter && clientFilter.length === 1) {
        rows = rows.filter((item) =>
            item.client.user.firstname && item.client.user.lastname
                ? item.client.user.firstname
                      .toLowerCase()
                      .includes(clientFilter[0].toLowerCase()) ||
                  item.client.user.lastname
                      .toLowerCase()
                      .includes(clientFilter[0].toLowerCase()) ||
                  item.client.user.emailaddress
                      .toLowerCase()
                      .includes(clientFilter[0].toLowerCase())
                : false
        );
    }
    if (providerFilter && providerFilter.length === 2) {
        rows = rows.filter((item) =>
            item.provider.firstname && item.provider.lastname
                ? item.provider.firstname
                      .toLowerCase()
                      .includes(providerFilter[0].toLowerCase()) &&
                  item.provider.lastname
                      .toLowerCase()
                      .includes(providerFilter[1].toLowerCase())
                : false
        );
    }
    if (providerFilter && providerFilter.length === 1) {
        rows = rows.filter((item) =>
            item.provider.firstname && item.provider.lastname
                ? item.provider.firstname
                      .toLowerCase()
                      .includes(providerFilter[0].toLowerCase()) ||
                  item.provider.lastname
                      .toLowerCase()
                      .includes(providerFilter[0].toLowerCase()) ||
                  item.provider.emailaddress
                      .toLowerCase()
                      .includes(providerFilter[0].toLowerCase())
                : false
        );
    }
    return rows;
};

export const _findBooking = (
    data,
    tab,
    clientFilter,
    providerFilter,
    serviceTypeId,
    dateRangeChange,
    selectedFromDate,
    selectedToDate
) => {
    let rows = [];
    const now = new Date();
    if (data && data.length) {
        if (dateRangeChange) {
            rows = data.filter((item) => {
                return (
                    new Date(item.startdate).getTime() <
                        selectedToDate.getTime() &&
                    new Date(item.startdate).getTime() >
                        selectedFromDate.getTime()
                );
            });
            rows = filterBookings(
                rows,
                clientFilter,
                providerFilter,
                serviceTypeId
            );
            rows.sort((a, b) => {
                return new Date(a.startdate) - new Date(b.startdate);
            });
        } else if (tab === 0) {
            rows = data.filter((item) => {
                return new Date(item.startdate).getTime() > now.getTime();
            });
            rows = filterBookings(
                rows,
                clientFilter,
                providerFilter,
                serviceTypeId
            );
            rows.sort((a, b) => {
                return new Date(a.startdate) - new Date(b.startdate);
            });
        } else {
            rows = data.filter((item) => {
                return new Date(item.startdate).getTime() <= now.getTime();
            });
            console.log("rows", rows);
            rows = filterBookings(
                rows,
                clientFilter,
                providerFilter,
                serviceTypeId
            );
            rows.sort((a, b) => {
                return new Date(b.startdate) - new Date(a.startdate);
            });
        }
    }
    return rows;
};

export const _formatDate = (
    date,
    format = "MMM DD, YYYY",
    showTime = false
) => {
    let formattedDate = moment(date).format(format);
    return formattedDate;
};

export const _getStartOf = (date = moment()) => moment(date).startOf("day");

export const _userRoles = () => {
    let roles = [
        { label: "COMPANY_ADMIN", value: "COMPANY_ADMIN" },
        { label: "PROVIDER", value: "PROVIDER" },
        { label: "CLIENT", value: "CLIENT" }
    ];
    return roles;
};

export const prepareLocationPath = (
    countryTypeId,
    addressState,
    addressCity,
    postalCode
) => {
    return `${countryTypeId ? countryTypeId.trim().toLowerCase() : ""}/${
        addressState ? addressState.trim().toLowerCase() : ""
    }/${addressCity ? addressCity.trim().toLowerCase() : ""}/${
        postalCode ? postalCode.trim().replace(/\s/g, "").toLowerCase() : ""
    }/${uuid()}`;
};

export const jsonParse = (
    jsonString,
    defaultValue = undefined,
    loggingMessage = "Error parsing json"
) => {
    try {
        return JSON.parse(jsonString);
    } catch {
        console.log(loggingMessage);
        return defaultValue;
    }
};
export const tableDateFormat = (date) => moment(date).format("MMM D, Y");
export const twentyFourHourTimeToAmPm = (time) => {
    const [hours, minutes] = time.split(":");
    return `${hours % 12}:${minutes} ${hours > 12 ? "PM" : "AM"}`;
};

export function getCountryCode(country) {
    if (!country) return "CA";
    const countryCodes = {
        Canada: "CA",
        Can: "CA",
        USA: "US",
        "United States": "US",
        "United Kingdom": "GB",
        UK: "GB",
        England: "GB",
        Jamaica: "JM",
        Australia: "AU"
    };

    return countryCodes[country] ? countryCodes[country] : "CA";
}
