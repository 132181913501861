import React, { useState, useEffect } from "react";
// prettier-ignore
import { TextField, Grid, Button, Typography, Snackbar, Dialog, DialogContent, DialogActions, CircularProgress, Backdrop, FormControlLabel, Radio, RadioGroup, InputAdornment, FormControl, InputLabel, Tooltip, Select, MenuItem, Divider } from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { StoreContext } from "../context/StoreContext";
//import axios from "axios";
import { AddressEntryTextBox } from "../components/address/AddressEntryTextBox";
//import { Description } from "@mui/icons-material";
import validator from "validator";
import MapComponent from "../utils/CommonComonents/MapComponent";
import { prepareLocationPath } from "../utils";
import { getTimezoneIdByGeoLoc } from "../modules/TimeService";
import { useTheme } from "@mui/material/styles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    auditProviderLocUpdate,
    auditProviderLocCreate
} from "../modules/Audit";
import { getUserFromCache } from "../user/UserCommon";
import { useNavigate } from "react-router-dom";
import {
    Close,
    CloseRounded,
    Error,
    ErrorOutlineOutlined,
    Info
} from "@mui/icons-material";
import { Box } from "@mui/system";

const autocompleteService = { current: null };

export const ProviderLocationAddEditForm = (props) => {
    const { state, dispatch, actions } = React.useContext(StoreContext);
    const classes = useStyles();
    const [addressOneLine, setAddressOneLine] = useState("");
    const [addressStreet, SetStreet] = useState("");
    const [addressCity, SetCity] = useState("");
    const [addressState, SetState] = useState("");
    const [postalCode, SetPostalCode] = useState("");
    const [company, SetCompany] = useState("");
    const [companyId, SetCompanyID] = useState();
    const [phone, SetPhone] = useState("");
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const [location, SetLocation] = useState("");
    const [locDescription, SetLocDescription] = useState("");
    const [longitude, SetLongitude] = useState("");
    const [latitude, SetLatitude] = useState("");
    const [country, SetCountry] = useState("");
    const [locationId, SetLocationId] = useState("");
    const [checked, SetChecked] = useState(false);
    const [createdAt, SetCreatedAt] = useState();
    const [updatedAt, SetUpdatedAt] = useState("");
    const [isEdit, setisEdit] = useState(false);
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const [travelRegionDialogOpen, setTravelRegionDialogOpen] = useState(false);
    const [coords, SetCoords] = useState({});
    const [travelType, setTravelType] = useState("DRAW_TRAVEL_REGION");
    const [travelDistance, setTravelDistance] = useState(25);
    const [travelDistanceUnit, setTravelDistanceUnit] = useState("km");
    const [travelregions, setTravelregions] = useState("");
    const [polygons, setPolygons] = useState([]);
    const [timeZone, setTimeZone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const logger = new ConsoleLogger("ProviderLocationAddEdit");
    const [disableSave, setDisableSave] = useState(false);
    const [notInViewMode, setNotInViewMode] = useState();
    const [oldInfo, setOldInfo] = useState([]);
    const [isloading, setIsloading] = useState(true);
    const theme = useTheme();
    const classesnew = usePromotionsStyles();
    const [travelTypeChanged, setTravelTypeChanged] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    const history = useNavigate();
    const cachedLoggedUser = getUserFromCache();

    useEffect(() => {
        console.log("state", state);
        if (
            (state.mode === "Edit" || state.mode === "View") &&
            state.provider &&
            Object.keys(state.provider).length > 0
        ) {
            getProviderLocationData();
        } else {
            setIsloading(false);
        }
        const viewMode = notViewMode();
        setNotInViewMode(viewMode);
    }, [state.provider]);

    const handleOpenSelect = () => {
        setOpenMenu(true);
    };

    const handleCloseSelect = () => {
        setOpenMenu(false);
    };

    const fromDashboard = () => {
        if (props.pathname === "DashboardForm" || props.isModalOpen) {
            return true;
        }
        return false;
    };

    const fromScheduleAddEdit = () => {
        if (props.pathname === "ProviderScheduleAddEditForm") {
            return true;
        }
        return false;
    };

    async function initialTravelType() {
        if (state.provider && state.provider.maxtraveltype) {
            console.log("maxtraveltype-2");
            setTravelType(state.provider.maxtraveltype);
        }
    }

    async function getProviderLocationData() {
        setIsloading(true);
        const loggedinUser = getUserFromCache();
        const companyId = loggedinUser.companyId;
        console.log("loggedUser | ProvLocAddEdit ln 111", loggedinUser);
        console.log("State ID | ProvLocAddEdit ln 111", state);
        if (!!companyId) {
            setisEdit(true);
            const input = { id: state.id }; //state.id
            const result = await graphql(
                graphqlOperation(queries.getProviderLocation, input)
            );
            logger.debug("getProviderLocation", result);
            console.log("Does Company location Exist", result);
            //If there are not errors, run the following:
            if (!(!!result.errors && result.errors.length > 0)) {
                const {
                    addressoneline,
                    street,
                    city,
                    state,
                    country,
                    postalcode,
                    longitude,
                    latitude,
                    companyId,
                    desc,
                    name,
                    //travelminutes,
                    travelregions,
                    traveldistance,
                    traveldistanceunit,
                    timezone,
                    maxtraveltype
                } = result.data.getProviderLocation;
                console.log(
                    "result provider location add edit",
                    result.data.getProviderLocation
                );

                setAddressOneLine(addressoneline);
                SetStreet(street);
                SetCity(city);
                SetState(state);
                SetPostalCode(postalcode);
                SetCountry(country);
                SetLocDescription(desc);
                SetLocation(name);
                SetPhone(phone);
                SetLongitude(longitude);
                SetLatitude(latitude);
                SetCompanyID(companyId);
                SetCreatedAt(createdAt);
                setTimeZone(timezone);
                setTravelDistance(traveldistance);
                setTravelDistanceUnit(traveldistanceunit);
                setTravelregions(travelregions);

                if (maxtraveltype && maxtraveltype.length > 0) {
                    setTravelType(maxtraveltype);
                    console.log("maxtraveltype-1", maxtraveltype);
                } else {
                    await initialTravelType();
                }
                console.log("maxtraveltype", maxtraveltype);
                //console.log("State", state);

                //if (travelregions && travelregions != "") setPaths(travelregions);
                //setTravelMinutes(travelminutes);
                // SetLocationId("");
                SetChecked(true);
                // SetCoords({});

                const oldInfoObject = {
                    locationname: name,
                    locationdesc: desc,
                    address: addressoneline,
                    travelregions: travelregions,
                    traveldistance: traveldistance,
                    travelunits: traveldistanceunit,
                    traveltype: maxtraveltype //showTravelDistance() ? "CIRCULAR" : "DRAWN"
                };

                /* oldInfo.push({
                    locationname: name,
                    locationdesc: desc,
                    address: addressoneline,
                    travelregions: travelregions,
                    traveldistance: traveldistance,
                    travelunits: traveldistanceunit,
                    traveltype:
                        maxtraveltype && maxtraveltype.length > 0
                            ? maxtraveltype
                            : await initialTravelType() //showTravelDistance() ? "CIRCULAR" : "DRAWN"
                }); */

                setOldInfo([oldInfoObject]);
                console.log("just a test", oldInfo);
            }
        }
        setIsloading(false);
    }

    useEffect(() => {
        setPolygons(getPaths(travelregions));
        console.log("Props from ProviderLocAddEdit", props);
    }, [travelregions]);

    useEffect(() => {
        /*  if (oldInfo.length > 0 && travelType !== oldInfo[0].traveltype) {
            console.log("Travel Type UseEffect");
            console.log(travelType, oldInfo[0].traveltype);
            console.log(polygons);
            alert("Travel Type Changed");
        } */
        if (oldInfo.length > 0) {
            console.log("Travel Type UseEffect");
            console.log(travelType);
            console.log(oldInfo);
            console.log(polygons);
        }
    }, [travelType, oldInfo]);

    async function addProviderLocation() {
        let tz = timeZone;

        try {
            tz = await getTimezoneIdByGeoLoc({
                lat: latitude,
                lng: longitude
            });
        } catch (err) {
            console.log(
                "could not get time zone from geo loc - addEditCompanyLocation"
            );
        }
        const loggedinUser = getUserFromCache();
        const companyId = loggedinUser.companyId;
        try {
            let input = {
                name: location,
                desc: locDescription,
                addressoneline: addressOneLine,
                street: addressStreet,
                city: addressCity,
                state: addressState,
                country: country,
                postalcode: postalCode,
                longitude: longitude,
                latitude: latitude,
                // timezone: timeZone,
                companyId: loggedinUser.companyId,
                traveldistance: travelDistance,
                traveldistanceunit: travelDistanceUnit,
                travelregions: JSON.stringify(polygons),
                providerId: fromDashboard()
                    ? loggedinUser &&
                      loggedinUser.providerId &&
                      !fromScheduleAddEdit()
                        ? loggedinUser.providerId
                        : state.provider.id
                    : state.provider.id, //localStorage.getItem("providerId"),
                providerLocationProviderId:
                    fromDashboard() && !fromScheduleAddEdit()
                        ? loggedinUser && loggedinUser.providerId
                            ? loggedinUser.providerId
                            : state.provider.id
                        : state.provider.id, //localStorage.getItem("providerId"),
                providerLocationCompanyId: companyId,
                locationpath: prepareLocationPath(
                    getCountryCode(country),
                    addressState,
                    addressCity,
                    postalCode
                ),
                timezone: tz,
                maxtraveltype: travelType
            };
            let response;
            if (isEdit) {
                input.id = state.id;
                input.createdAt = createdAt;
                response = await graphql(
                    graphqlOperation(mutations.updateProviderLocation, {
                        input
                    })
                );

                await auditProviderLocUpdate(
                    loggedinUser,
                    response.data.updateProviderLocation,
                    newInfo
                );
            } else {
                input.createdAt = new Date();
                response = await graphql(
                    graphqlOperation(mutations.createProviderLocation, {
                        input
                    })
                );
                console.log("Travel Zone response added", response);

                await auditProviderLocCreate(
                    loggedinUser,
                    response.data.createProviderLocation
                );
            }

            if (fromDashboard()) {
                try {
                    const companyResponse = await graphql(
                        graphqlOperation(queries.getCompany, {
                            id: loggedinUser.companyId
                        })
                    );
                    const company = companyResponse.data.getCompany;
                    if (company) {
                        if (
                            !company.addressoneline &&
                            !company.countrycode3166alpha2 &&
                            !company.state
                        ) {
                            //company does not have address info, so populate it from company location
                            let countrycode3166alpha2 = getCountryCode(country);
                            let companyInput = {
                                addressoneline: addressOneLine,
                                street: addressStreet,
                                city: addressCity,
                                state: addressState,
                                country: country,
                                postalcode: postalCode,
                                longitude: longitude,
                                latitude: latitude,
                                countrycode3166alpha2:
                                    countrycode3166alpha2.toLowerCase(),
                                id: loggedinUser.companyId
                            };
                            let companyUpdateResponse = await graphql(
                                graphqlOperation(mutations.updateCompany, {
                                    input: { ...companyInput }
                                })
                            );
                            console.log(
                                "companyUpdateResponse",
                                companyUpdateResponse
                            );
                        }
                    }
                } catch (err) {
                    console.log("error while updating company address.", err);
                }
            }

            resetForm();
            if (
                response && response.data && isEdit
                    ? response.data.updateProviderLocation
                    : response.data.createProviderLocation
            ) {
                setSnackMsg("Provider location successfully saved.");
                setMsgOpen(true);
                // allow time so user can read success message
                if (props.pathname === "DashboardForm") {
                    if (response?.data?.createProviderLocation?.id) {
                        props.updateTravelZoneValue(
                            response?.data?.createProviderLocation?.id
                        );
                    }
                    setTimeout(async () => {
                        props.returnTo();
                        props.closeTravelZonePortal();
                    }, 1000);
                } else {
                    setTimeout(() => {
                        props?.closeTravelZonePortal &&
                            props.closeTravelZonePortal();
                        props.pathname
                            ? actions.setPage(props.pathname)
                            : actions.setPage("ProviderLocationForm");
                    }, 1000);
                }

                // reset fields
            } else {
                setSnackMsg("Something went wrong, Please try again later.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error("Add Provider location error => ", error);
            setDisableSave(false);
        }
    }

    function confirmTravelZoneChange(zone) {
        console.log("Zone", zone);
        if (zone === "MAX_DISTANCE") {
            setPolygons([]);
            setTravelDistance(25);
            setTravelDistanceUnit("km");
            setTravelType(zone);
            setOldInfo((prevState) => [{ ...prevState[0], traveltype: zone }]);
            setTravelTypeChanged(false);
            return;
        }

        setTravelType(zone);
        setOldInfo((prevState) => [{ ...prevState[0], traveltype: zone }]);
        setTravelTypeChanged(false);
    }

    function travelTypeHandler(e) {
        if (
            e.target.value !== oldInfo[0]?.traveltype ||
            e.target.value !== travelType
        ) {
            //alert("You are going to change the travel zone type");
            //setPolygons([]);
            // setTravelTypeChanged(true);
        }
    }

    function getCountryCode(country) {
        if (!country) return "CA";
        let c = [
            { id: "CA", name: "Canada" },
            { id: "CA", name: "Can" },
            { id: "US", name: "USA" },
            { id: "US", name: "United States" },
            { id: "GB", name: "United Kingdom" },
            { id: "GB", name: "UK" },
            { id: "GB", name: "England" },
            { id: "JM", name: "Jamaica" },
            { id: "AU", name: "Australia" }
        ].filter((c) => c.name.toLowerCase() === country.toLowerCase());
        return c[0] ? c[0].id : "CA";
    }
    function getPaths() {
        if (travelregions) {
            const paths = [];
            const tr = JSON.parse(travelregions);
            for (let r of tr) {
                const rpaths = [];
                for (let rr of r) {
                    rpaths.push({ lat: rr.lat, lng: rr.lng });
                }
                paths.push(rpaths);
            }
            return paths;
        }
        return []; //must always return empty
    }

    function handleAddressFocus() {
        if (!addressDialogOpen) {
            setAddressDialogOpen(true);
        }
    }
    function handleAddressDialogClose() {
        setAddressDialogOpen(false);
    }

    function handleClickTravelRegions() {
        setTravelRegionDialogOpen(true);
    }

    function handleAddressDialogSave(
        addressOneLine,
        street,
        city,
        state,
        postalCode,
        country,
        lat,
        lon
    ) {
        setAddressDialogOpen(false);
        setAddressOneLine(addressOneLine ? addressOneLine : null);
        SetStreet(street ? street : null);
        SetCity(city ? city : null);
        SetState(state ? state : null);
        SetPostalCode(postalCode ? postalCode : null);
        SetCountry(country);
        SetLongitude(lon);
        SetLatitude(lat);
    }

    function validatePhone(phone, type) {
        let cleanPhone = phone.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-"); // strip brackets or dash
        if (validator.isMobilePhone(cleanPhone)) {
            return cleanPhone;
        } else {
            setSnackMsg("Please specifiy a valid " + type + " phone number.");
            setMsgOpen(true);
            return null;
        }
    }

    const doesPolygonExist = () => {
        if (travelType === "DRAW_TRAVEL_REGION" && polygons.length > 0) {
            return true;
        }

        //if it is circular doesnt really matter if the the polygon exists
        if (travelType === "MAX_DISTANCE") {
            return true;
        }
        return false;
    };

    let newInfo;
    async function handleSaveCompanyLocation() {
        setDisableSave(true);
        if (state.mode === "Edit" && !props.pathname === "DashboardForm") {
            // oldInfo[0].traveltype === "CIRCULAR"
            if (travelType === "MAX_DISTANCE") {
                newInfo = [
                    {
                        locationname: "No Change",
                        locationdesc: "No Change",
                        address: "No Change",
                        traveldistance: "No Change",
                        travelunits: "No Change"
                    }
                ];

                if (travelDistance != oldInfo[0].traveldistance) {
                    newInfo.map((item) => {
                        item.traveldistance = travelDistance;
                    });
                }
                if (travelDistanceUnit != oldInfo[0].travelunits) {
                    newInfo.map((item) => {
                        item.travelunits = travelDistanceUnit;
                    });
                }
                //oldInfo[0].traveltype === "DRAWN"
            } else if (travelType === "DRAW_TRAVEL_REGION") {
                newInfo = [
                    {
                        locationname: "No Change",
                        locationdesc: "No Change",
                        address: "No Change",
                        travelregions: "No Change"
                    }
                ];

                if (JSON.stringify(polygons) != oldInfo[0].travelregions) {
                    newInfo.map((item) => {
                        item.travelregions =
                            "Travel Zone has changed, please see location page for details";
                    });
                }
            }
        }
        // validate
        //state.provider && state.provider.maxtraveltype === "DRAW_TRAVEL_REGION"
        if (travelType === "DRAW_TRAVEL_REGION") {
            if (polygons.length === 0) {
                setSnackMsg(
                    "Please draw at least one travel zone by clicking on Draw Travel Zones button. "
                );
                setMsgOpen(true);
                setDisableSave(false);
                return;
            }
        }
        if (addressOneLine === "") {
            setSnackMsg("Please enter the Travel Zone Address.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }
        /*Removed Travel Zone Description from validation check 
        if (locDescription === "") {
            setSnackMsg("Please enter the Travel Zone Description.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }*/

        if (!timeZone) {
            setSnackMsg("Please enter a value for timezone.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }
        if (location === "") {
            setSnackMsg("Please enter the Travel Zone Name.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }
        if (travelDistance === "") {
            setSnackMsg("Please enter a value for minumum travel radius.");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        // checking for updates made
        if (state.mode === "Edit" && !props.pathname === "DashboardForm") {
            if (location != oldInfo[0].locationname) {
                newInfo.map((item) => {
                    item.locationname = location;
                });
            }
            if (locDescription != oldInfo[0].locationdesc) {
                newInfo.map((item) => {
                    item.locationdesc = locDescription;
                });
            }
            if (addressOneLine != oldInfo[0].address) {
                newInfo.map((item) => {
                    item.address = addressOneLine;
                });
            }
        }

        //console.log("just a test", newInfo);

        if (!!timeZone && !!addressOneLine && !!location) {
            await addProviderLocation();
        }
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    function resetForm() {
        setAddressOneLine("");
        SetStreet("");
        SetCity("");
        SetState("");
        SetPostalCode("");
        SetCountry("");
        SetLocationId("");
        SetChecked(false);
        SetCoords({});
    }

    /* START Handle Polygons for travel regions */

    function handlePolygons(polygon) {
        logger.debug("ADDING POLYGON...");
        logger.debug(polygon);
        setPolygons([...polygons, polygon]);
    }

    function deletePolygon() {
        setPolygons((prevPolygons) => {
            const updatedPolygons = [...prevPolygons];
            console.log("Updated POlygons", updatedPolygons);
            updatedPolygons.pop();
            return updatedPolygons;
        });
    }

    function undoPolygon() {
        setPolygons(polygons.slice(0, -1));
    }

    function closeMap() {
        setTravelRegionDialogOpen(false);
    }

    function showTravelDistance() {
        /* if (state.provider && state.provider.maxtraveltype === "MAX_DISTANCE")
            return true; */
        if (travelType === "MAX_DISTANCE") return true;
        return false;
    }

    function decideDrawType() {
        //1: circle, 2: polygon
        // if (showTravelDistance()) return 1;
        if (travelType === "MAX_DISTANCE") return 1;

        return 2;
    }

    function getRadius() {
        if (travelDistanceUnit === "km") {
            return travelDistance * 1000;
        } else {
            //asume miles
            return travelDistance * 1.60934 * 1000;
        }
    }

    function showRegionButtonTitle() {
        // if (showTravelDistance()) return "View Travel Zone";
        if (travelType === "MAX_DISTANCE") return "View Travel Zone";
        if (!notInViewMode) return "View Travel Zone";
        else return "Draw Travel Zones";
    }

    function showDrawZoneInstructions() {
        if (!showTravelDistance()) {
            /*  return (
                <span>
                    This Travel Zone consists of one or more drawn areas on the
                    map.
                    <br></br>
                    To draw an area, click once on the map to begin your shape,
                    then move your mouse and click to draw your first boundary.
                    <br></br>
                    Continue to move and click to outline the remaining
                    boundaries of the area.
                </span>
            ); */

            return (
                <Box
                    sx={{
                        display: "flex",
                        gap: "0.5rem",
                        backgroundColor: "primary.light",
                        borderRadius: "8px",
                        padding: "5px 16px",
                        alignItems: "center"
                    }}
                >
                    <ErrorOutlineOutlined color="primary" />
                    <Typography
                        sx={{ fontSize: "12px", color: "rgba(0,0,0,0.75)" }}
                    >
                        {`Click and lift your mouse or finger to draw a point.
                        Move to a new spot, and then click and lift again.
                        Repeat until desired shape is created. ${
                            !fromDashboard()
                                ? "You can draw multiple shapes if desired."
                                : ""
                        }`}
                        {/* Click once on the map to begin your shape. Move your
                        mouse and click to draw your first boundary. Continue to
                        move and click to outline the remaining boundaries of
                the zone. When you are done adding your travel zone, click on the back arrow to save and complete.*/}
                    </Typography>
                </Box>
            );
        } else {
            /*  return (
                <span>
                    This Travel Zone has a radius of{" "}
                    <b>
                        {travelDistance} {travelDistanceUnit}
                    </b>{" "}
                    centered on <b>{addressOneLine}</b>.<br></br>
                    To change the Travel Zone, please press Close and update the
                    Maximum Travel Radius and/or the Address.
                </span>
        ); */
            return (
                <Box
                    sx={{
                        display: "flex",
                        gap: "0.5rem",
                        backgroundColor: "primary.light",
                        borderRadius: "8px",
                        padding: "5px 16px",
                        alignItems: "center"
                    }}
                >
                    <ErrorOutlineOutlined color="primary" />
                    <Typography
                        sx={{ fontSize: "12px", color: "rgba(0,0,0,0.75)" }}
                    >
                        This Travel Zone has a radius of{" "}
                        <b>
                            {travelDistance} {travelDistanceUnit}
                        </b>{" "}
                        centered on <b>{addressOneLine}</b>.
                    </Typography>
                </Box>
            );
        }
    }
    /* END Handle Polygons for travel regions */

    function handleCancel() {
        console.log("Cancel History ProLocAddEdit", history);
        console.log("Pathname ProviderLocationAddEdit", props.pathname);

        if (props.pathname === "ProviderScheduleAddEditForm") {
            actions.setId(state.provider.id);
            actions.setPage(props.pathname);
        } else {
            if (props.pathname === "DashboardForm") {
                props.closeTravelZonePortal();
            } else {
                actions.setId(state.provider.id);
                actions.setPage("ProviderLocationForm");
            }

            /* if (props.pathname === "DashboardForm") {
                props.closeTravelZonePortal();
            } */
        }
    }

    function notViewMode() {
        return !(state.mode === "View");
    }

    if (isloading) {
        return (
            <Backdrop
                style={{
                    zIndex: theme.zIndex.drawer + 1,
                    color: "#fff"
                }}
                open={true}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={travelRegionDialogOpen}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth={true}
            >
                {/* <DialogTitle id="form-dialog-title">
                    Travel Zone for {location}{" "}
                    {!notInViewMode && <> in View Mode</>}
                </DialogTitle> */}
                <DialogContent sx={{ padding: "24px" }}>
                    <Grid container>
                        <Grid
                            container
                            item
                            xs={12}
                            md={12}
                            sx={{
                                gap: "1rem",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "24px"
                                }}
                            >
                                {!notInViewMode
                                    ? "View travel zone"
                                    : "Draw travel zones"}
                            </Typography>
                            <CloseRounded
                                sx={{
                                    width: "2rem",
                                    color: "rgba(0,0,0,0.5)",
                                    fontWeight: 700,
                                    cursor: "pointer"
                                }}
                                onClick={closeMap}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: "1rem" }}>
                        <Grid item xs={12}>
                            {showDrawZoneInstructions()}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: "1rem" }}>
                        <Grid item xs={12} md={12}>
                            <MapComponent
                                fromDashboard={fromDashboard}
                                lat={latitude}
                                lng={longitude}
                                polygons={polygons}
                                setPolygons={handlePolygons}
                                undoPolygon={undoPolygon}
                                closeMap={closeMap}
                                location={addressOneLine}
                                radius={getRadius()}
                                drawType={decideDrawType()}
                                viewType={state.mode}
                                deletePolygon={deletePolygon}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions />
            </Dialog>

            <Dialog open={travelTypeChanged}>
                <DialogContent sx={{ padding: "1.5rem" }}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Error color={"error"} />
                        <Typography variant="h5">
                            You are about to change the travel zone type
                        </Typography>
                    </Box>
                    <Divider
                        sx={{
                            width: "100%",
                            marginTop: "1rem",
                            marginBottom: "0.5rem"
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() =>
                                oldInfo[0]
                                    ? confirmTravelZoneChange(
                                          oldInfo[0].traveltype ===
                                              "MAX_DISTANCE"
                                              ? "DRAW_TRAVEL_REGION"
                                              : "MAX_DISTANCE"
                                      )
                                    : confirmTravelZoneChange(
                                          travelType === "MAX_DISTANCE"
                                              ? "DRAW_TRAVEL_REGION"
                                              : "MAX_DISTANCE"
                                      )
                            }
                        >
                            I Confirm
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setTravelTypeChanged(false)}
                        >
                            Keep It Same
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem"
                }}
            >
                <Typography className={classes.title} variant="h4" noWrap>
                    {/*  Provider Travel Zone \ {state.mode} */}
                    {fromDashboard()
                        ? "Add travel zone"
                        : state.mode === "AddSc" || state.mode === "Add"
                          ? "Add travel zone"
                          : "Edit travel zone"}
                </Typography>
                {(props.pathname === "ProviderScheduleAddEditForm" ||
                    props.pathname === "DashboardForm") && (
                    <Close onClick={handleCancel} sx={{ cursor: "pointer" }} />
                )}
            </Box>

            <Grid style={{ marginBottom: "1rem" }} container spacing={2}>
                <Grid item xs={fromDashboard() ? 12 : 6}>
                    <TextField
                        sx={{
                            backgroundColor: "white",
                            border: "2px solid #d4d4d4",
                            borderRadius: "4px",
                            "&:hover": { backgroundColor: "white" },
                            "& .MuiFilledInput-root": {
                                "&:hover": { backgroundColor: "white" },
                                backgroundColor: "white",
                                "&.Mui-focused": {
                                    backgroundColor: "white"
                                }
                            }
                        }}
                        label="Travel zone name"
                        variant="filled"
                        type={"text"}
                        fullWidth
                        value={location}
                        disabled={!notInViewMode}
                        onChange={({ target: { value } }) => {
                            SetLocation(value);
                        }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title="Keep your various travel zones organized with names. Your customers will not see this"
                                        placement="bottom"
                                        style={{
                                            color: "#a2a2a2",
                                            fontSize: "20px"
                                        }}
                                    >
                                        {<Info />}
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>

            {/*   <Grid style={{ marginBottom: "1rem" }} container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="Travel Zone Description"
                        //id="{props.id}"
                        fullWidth
                        value={locDescription}
                        disabled={!notInViewMode}
                        onChange={({ target: { value } }) => {
                            SetLocDescription(value);
                        }}
                        placeholder="Description"
                        multiline
                        rows="4"
                        className={classes.fullwidth}
                    />
                </Grid>
            </Grid> */}

            <Grid container>
                <Grid item xs={fromDashboard() ? 12 : 6}>
                    {/* <TextField
                        label="Travel Zone Type"
                        fullWidth
                        disabled={false}
                        value={
                            showTravelDistance()
                                ? "Circular Zone"
                                : "Drawn Zone"
                        }
                        InputProps={{
                            readOnly: true
                        }}
                        className={classes.fullwidth}
                    /> */}
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="travel-region-select">
                            Travel zone type
                            <Tooltip
                                title={`Allows you to set ${
                                    cachedLoggedUser?.provider
                                        ? cachedLoggedUser.provider.firstname +
                                          "'s"
                                        : "provider's"
                                } service area using either a drawn travel zone of any shape or a circular travel zone`}
                                placement="bottom"
                            >
                                <Info
                                    sx={{
                                        marginLeft: "5px",
                                        color: "rgb(162, 162, 162)"
                                    }}
                                />
                            </Tooltip>
                        </InputLabel>
                        <Select
                            sx={{
                                backgroundColor: "white",
                                border: "2px solid #d4d4d4",
                                borderRadius: "4px",

                                "& #travel-region-select": {
                                    display: "flex",
                                    justifyContent: "space-between"
                                },

                                "&:hover": {
                                    backgroundColor: "white"
                                },

                                "&.Mui-focused": {
                                    backgroundColor: "white",
                                    "& .MuiSelect-select": {
                                        backgroundColor: "white"
                                    }
                                },
                                "& .MuiSelect-icon": {
                                    color: "disabled.main"
                                }
                            }}
                            fullWidth
                            disableUnderline
                            variant="filled"
                            label={"Travel zone type"}
                            id="travel-region-select"
                            required
                            value={travelType}
                            // Use the custom expand icon component

                            onChange={() =>
                                oldInfo[0]
                                    ? confirmTravelZoneChange(
                                          oldInfo[0].traveltype ===
                                              "MAX_DISTANCE"
                                              ? "DRAW_TRAVEL_REGION"
                                              : "MAX_DISTANCE"
                                      )
                                    : confirmTravelZoneChange(
                                          travelType === "MAX_DISTANCE"
                                              ? "DRAW_TRAVEL_REGION"
                                              : "MAX_DISTANCE"
                                      )
                            }
                        >
                            <MenuItem
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                value={"MAX_DISTANCE"}
                            >
                                Circular zone
                                <Tooltip
                                    title="Draw a circular travel zone"
                                    placement="bottom"
                                    style={{
                                        color: "#a2a2a2",
                                        fontSize: "20px"
                                    }}
                                >
                                    <Info />
                                </Tooltip>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                value={"DRAW_TRAVEL_REGION"}
                            >
                                Drawn zone
                                <Tooltip
                                    title="Draw a travel zone of any shape"
                                    placement="bottom"
                                    style={{
                                        color: "#a2a2a2",
                                        fontSize: "20px"
                                    }}
                                >
                                    <Info />
                                </Tooltip>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid
                    item
                    xs={fromDashboard() ? 12 : 6}
                    sx={{ position: "relative" }}
                >
                    <AddressEntryTextBox
                        setAddress={handleAddressDialogSave}
                        autocompleteService={autocompleteService}
                        addressOneLine={addressOneLine}
                        outlineVariant="outlined"
                        marginAbove={true}
                        tooltipTitle={
                            travelType === "DRAW_TRAVEL_REGION"
                                ? "Address will be used as the center point of your drawn travel zone, which you can edit from the map that will pop up at this location"
                                : "Address will be used as the center point of your circular travel zone"
                        }
                    />
                </Grid>
            </Grid>

            {travelType === "DRAW_TRAVEL_REGION" &&
                props.pathname === "ProviderScheduleAddEditForm" && (
                    <Grid
                        container
                        item
                        xs={fromDashboard() ? 12 : 6}
                        flexDirection={"column"}
                        sx={{ marginBottom: "1rem" }}
                    >
                        <Typography
                            variant="body2"
                            sx={{ color: "rgba(0,0,0,0.75)" }}
                        >
                            Draw a custom Travel Zone
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#ffffff",
                                color: "rgba(0,0,0,0.75)",
                                fontWeight: 700,
                                maxWidth: "200px",
                                boxShadow: "none",
                                border: "2px solid #ABB0B8",
                                "&:hover": {
                                    backgroundColor: "#ffffff",
                                    boxShadow: "none",
                                    border: "2px solid #ABB0B8"
                                }
                            }}
                            disabled={addressOneLine === ""}
                            onClick={handleClickTravelRegions}
                        >
                            {showRegionButtonTitle()}
                        </Button>
                    </Grid>
                )}
            {travelType === "MAX_DISTANCE" && (
                <Grid
                    style={{ marginBottom: "1rem" }}
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        sx={{ display: "flex", gap: "8px" }}
                        xs={fromDashboard() ? 12 : 6}
                    >
                        <TextField
                            variant="filled"
                            sx={{
                                backgroundColor: "white",
                                border: "2px solid #d4d4d4",
                                borderRadius: "4px",
                                "&:hover": { backgroundColor: "white" },
                                "& .MuiFilledInput-root": {
                                    "&:hover": { backgroundColor: "white" },
                                    backgroundColor: "white",
                                    "&.Mui-focused": {
                                        backgroundColor: "white"
                                    }
                                }
                            }}
                            id="providerdistance"
                            label="Maximum Travel Radius"
                            type={"number"}
                            className={classes.textField}
                            value={travelDistance}
                            disabled={!notInViewMode}
                            onChange={(event) =>
                                setTravelDistance(event.target.value)
                            }
                            InputProps={{
                                disableUnderline: true
                            }}
                        />

                        <RadioGroup
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                            aria-label="Distance"
                            name="distanceunit"
                            value={travelDistanceUnit}
                            disabled={!notInViewMode}
                            onChange={(event) =>
                                setTravelDistanceUnit(event.target.value)
                            }
                        >
                            <FormControlLabel
                                value="km"
                                control={<Radio color="primary" />}
                                disabled={!notInViewMode}
                                label="km"
                            />
                            <FormControlLabel
                                value="mi"
                                control={<Radio color="primary" />}
                                disabled={!notInViewMode}
                                label="mi"
                            />
                        </RadioGroup>
                    </Grid>
                    {props.pathname === "ProviderScheduleAddEditForm" && (
                        <Grid item xs={fromDashboard() ? 12 : 6}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ffffff",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700,
                                    maxWidth: "200px",
                                    boxShadow: "none",
                                    border: "2px solid #ABB0B8",
                                    "&:hover": {
                                        backgroundColor: "#ffffff",
                                        boxShadow: "none",
                                        border: "2px solid #ABB0B8"
                                    }
                                }}
                                disabled={addressOneLine === ""}
                                onClick={handleClickTravelRegions}
                            >
                                {showRegionButtonTitle()}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid container>
                <Grid
                    item
                    xs={fromDashboard() ? 12 : 6}
                    sx={{ display: "flex", gap: "1rem" }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            state.mode === "View" ||
                            disableSave ||
                            !doesPolygonExist()
                        }
                        onClick={handleSaveCompanyLocation}
                    >
                        Save
                        {disableSave && (
                            <CircularProgress
                                size={24}
                                className={classesnew.buttonProgress}
                            />
                        )}
                    </Button>

                    {!(props.pathname === "ProviderScheduleAddEditForm") && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={addressOneLine === ""}
                            onClick={handleClickTravelRegions}
                        >
                            {showRegionButtonTitle()}
                        </Button>
                    )}

                    {!(props.pathname === "ProviderScheduleAddEditForm") &&
                        !(props.pathname === "DashboardForm") && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        )}

                    {/* props.pathname === "DashboardForm" && (
                        <Button
                            variant="contained"
                            onClick={() => props.closeTravelZonePortal()}
                        >
                            Cancel
                        </Button>
                    ) */}
                </Grid>
            </Grid>
        </>
    );
};
