import React, { useContext } from "react";
import Table from "@mui/material/Table";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, IconButton, Tooltip, Paper, Checkbox } from "@mui/material";
//import { Delete, Stop, Edit } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import EditIcon from "@mui/icons-material/Create";
//import LocationIcon from "@mui/icons-material/TransferWithinAStation";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        marginTop: theme.spacing(3),
        width: "100%",
        overflowX: "auto",
        marginBottom: theme.spacing(2)
    },
    //paper: {
    //  width: "100%",
    //  marginBottom: theme.spacing(2)
    //},
    tableWrapper: {
        overflowX: "auto"
    },
    pointer: {
        cursor: "pointer"
    },
    actionsCellWidth: {
        width: "200px"
    },
    table: {
        minWidth: 650
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};
export default function CompanyReminderTable({
    rows,
    fetchPromotionList,
    selectedRow,
    tabValue
}) {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [promotionId, setPromotionId] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    //   const [rows, setRows] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    const [deleteDialog, setDeleteDialog] = React.useState(false);

    const [endDialog, setEndDialog] = React.useState(false);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }
    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    function handleDeleteProviders(id, event) {
        setPromotionId(id);
        setDeleteDialog(true);
    }

    function handleEndDialogDecline() {
        setEndDialog(false);
    }
    function handleCompanyEditClick(id) {
        actions.setMode("Edit");
        actions.setPage("CompanyReminderAddEditForm");
        actions.setId(id);
    }

    const handleViewCompany = (id) => {
        actions.setMode("View");
        actions.setPage("CompanyReminderAddEditForm");
        actions.setId(id);
    };

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;

    //   const emptyRows =
    //     rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    // const row = props
    return (
        <>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectAll}
                                        onClick={handleSelectAllClick}
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    Reminder Type
                                </TableCell>
                                <TableCell align="left">Recipient</TableCell>
                                <TableCell align="center">Days</TableCell>
                                <TableCell align="center">Hours</TableCell>
                                <TableCell align="center">Minutes</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.length > 0 &&
                                rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map(
                                        (
                                            {
                                                reminderType,
                                                recipientType,
                                                reminderDays,
                                                reminderHours,
                                                reminderMinutes,
                                                id
                                            },
                                            i
                                        ) => {
                                            return (
                                                <TableRow
                                                    key={id}
                                                    hover
                                                    onClick={(event) =>
                                                        handleRowClick(
                                                            event,
                                                            id
                                                        )
                                                    }
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    id
                                                                ) !== -1 ||
                                                                selectAll
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {reminderType}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {recipientType}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {reminderDays}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {reminderHours}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {reminderMinutes}
                                                    </TableCell>

                                                    <div>
                                                        <TableCell align="center">
                                                            <Tooltip title="Edit">
                                                                <EditIcon
                                                                    aria-label="Edit"
                                                                    onClick={() =>
                                                                        handleCompanyEditClick(
                                                                            id
                                                                        )
                                                                    }
                                                                    className={
                                                                        classes.pointer
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </div>
                                                </TableRow>
                                            );
                                        }
                                    )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={6}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "Rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Paper>
            </div>
        </>
    );
}
