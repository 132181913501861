import React, { useContext } from "react";
import {
    Table,
    TableRow,
    TablePagination,
    TableFooter,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    Snackbar,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    Menu,
    MenuItem
} from "@mui/material";
import { getOrder } from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { orderColumns, orderColumnsAdmin } from "../utils/Constants";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from "../context/StoreContext";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import { dateFormatter } from "../utils/Common/dateFormatter";
import { userHasAdminRole } from "../user/UserCommon";
import CurrencyFormat from "react-currency-format";
import { getCurrencySymbol, getCurrencySymbolFromParam } from "../utils";

const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

const OrdersTableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

const statusTypes = ["PAID", "PAIDBYPKGCRE", "CONFIRMED"];

/* function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    console.log("**Props from TablePaginationActions - OrderTable.js**");
    console.log(props);
    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
} */
export const OrderTable = (props) => {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [selected, setSelected] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [receipt, setReceipt] = React.useState();
    const [receiptModal, setReceiptModal] = React.useState(false);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState(false);
    const {
        rows,
        role,
        handleLoadMore,
        endOfList,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        rowsPerPage,
        handleRefund
    } = props;
    console.log("**Props from OrderTable - OrderTable.js**");
    console.log(props);

    //new table
    const [selectedOrder, setSelectedOrder] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    const _handleEditClick = (id) => {
        actions.setMode("Edit");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleViewClick = (id) => {
        actions.setMode("View");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleTimeClick = (id, location) => {
        actions.setPage("TimeBlockForm");
        actions.setId(id);
        localStorage.setItem("timeBlockLocation", location);
    };

    const _handleReceipt = async () => {
        //console.log(item);
        console.log(selectedOrder);
        console.log(rows);

        let orderPassed = rows.filter(
            (item) => item.orderNo === Number(selectedOrder) && item.id
        );

        console.log(orderPassed);
        const orderData = await graphql(
            graphqlOperation(getOrder, { id: orderPassed[0].id })
        );

        if (orderData && orderData.data.getOrder.orderReceipt) {
            setReceiptModal(true);
            setReceipt(orderData.data.getOrder.orderReceipt);
        } else {
            setSnackMsg("There is no receipt found for the selected order.");
            setMsgOpen(true);
            setReceiptModal(false);
            setReceipt("");
        }
        handleThreeDotsClose();
    };

    const _handleRefund = async () => {
        let orderPassed = rows.filter(
            (item) => item.orderNo === Number(selectedOrder) && item.id
        );

        handleRefund(orderPassed[0]);
        handleThreeDotsClose();
    };

    const columns = (role) => {
        switch (role) {
            case "CLIENT":
                return orderColumns;
            case "PROVIDER":
                return orderColumns;
            case "MARKETBOX_ADMIN":
                return orderColumnsAdmin;
            case "COMPANY_ADMIN":
                return orderColumnsAdmin;
            case "COMPANY_ADMIN_PROVIDER":
                return orderColumnsAdmin;
        }
    };

    const handleThreeDotsClick = (event, orderNo) => {
        console.log(orderNo);
        setSelectedOrder(orderNo.toString());
        setAnchorEl(event.currentTarget);
    };

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        setSelectedOrder("");
    };

    const phonePreference = (arg) => {
        if (arg.user.phonepref === "MOBILE") {
            return arg.user.mobilephone;
        }
        if (arg.user.phonepref === "WORK") {
            return arg.user.workphone;
        }
        if (arg.user.phonepref === "HOME") {
            return arg.user.homephone;
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={receiptModal}
                fullScreen
                onClose={() => setReceiptModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Order Receipt</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div
                            dangerouslySetInnerHTML={{ __html: receipt }}
                        ></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setReceiptModal(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <div>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <DataTableWrapper
                            classes={classes}
                            columns={columns(role)}
                            rows={rows}
                            role={role}
                            multiSelectable={true}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={selected}
                            selectAll={selectAll}
                            handleEditClick={_handleEditClick}
                            handleRowClick={() => {
                                return false;
                            }}
                            handleSelectAllClick={() => {
                                return false;
                            }}
                            handleViewClick={_handleViewClick}
                            handleTimeClick={_handleTimeClick}
                            handleReceipt={_handleReceipt}
                            handleRefund={handleRefund}
                            showTime={false}
                            showAction={false}
                            showSelect={false}
                            showReceipt={true}
                            showRefund={true}
                        />
                        {rows.length != 0 ? (
                            <div
                                style={{ textAlign: "center", margin: "1rem" }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLoadMore}
                                    disabled={endOfList}
                                    style={{
                                        padding: "0.2rem",
                                        paddingRight: "0.5rem",
                                        paddingLeft: "0.5rem"
                                    }}
                                >
                                    {endOfList
                                        ? "No More Orders To Load"
                                        : "Load More Orders..."}
                                </Button>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <Table>
                            <TableFooter>
                                <TableRow style={{ float: "right" }}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={6}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page"
                                            },
                                            native: true
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div> */}

            <div>
                <TableContainer>
                    <Table sx={OrdersTableTheme}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Order No</TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                    Order Date
                                </TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell sx={{ minWidth: "200px" }}>
                                    Booking Address
                                </TableCell>
                                <TableCell>Provider</TableCell>
                                <TableCell>Credits Left</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            key={row.orderNo}
                                            sx={{
                                                backgroundColor:
                                                    selectedOrder ===
                                                    row.orderNo.toString()
                                                        ? "primary.light"
                                                        : "white"
                                            }}
                                        >
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        backgroundColor:
                                                            row.status &&
                                                            (row.status !==
                                                            statusTypes[2]
                                                                ? "success.light"
                                                                : "info.light"),
                                                        color:
                                                            row.status &&
                                                            (row.status !==
                                                            statusTypes[2]
                                                                ? "success.dark"
                                                                : "info.dark"),
                                                        textTransform:
                                                            "uppercase",
                                                        fontWeight: 600,
                                                        padding: "2px 4px",
                                                        borderRadius: "4px",
                                                        textAlign: "center"
                                                    }}
                                                    style={{
                                                        fontSize: "0.75rem"
                                                    }}
                                                >
                                                    {row.status
                                                        ? row.status
                                                        : ""}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        fontWeight: 700,
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    {row.orderNo
                                                        ? row.orderNo
                                                        : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography>
                                                    {row.createdAt
                                                        ? dateFormatter(
                                                              row.createdAt
                                                          )
                                                        : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography>
                                                    {row.clientName
                                                        ? row.clientName
                                                        : "N/A"}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {row.client &&
                                                    row.client.user.emailaddress
                                                        ? row.client.user
                                                              .emailaddress
                                                        : "N/A"}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {row.client
                                                        ? phonePreference(
                                                              row.client
                                                          )
                                                        : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography>
                                                    {row.addressoneline
                                                        ? row.addressoneline
                                                        : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography>
                                                    {row.providerName
                                                        ? row.providerName
                                                        : "N/A"}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {row.provider &&
                                                    row.provider.emailaddress
                                                        ? row.provider
                                                              .emailaddress
                                                        : "N/A"}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {row.provider &&
                                                    row.provider.phone
                                                        ? row.provider.phone
                                                        : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography>
                                                    {row.credits
                                                        ? row.credits
                                                        : "N/A"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography
                                                    sx={{ fontWeight: 700 }}
                                                >
                                                    <CurrencyFormat
                                                        value={
                                                            row.total
                                                                ? row.total
                                                                : "0.00"
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={
                                                            row.currencyValue
                                                                ? getCurrencySymbolFromParam(
                                                                      row.currencyValue
                                                                  )
                                                                : getCurrencySymbol()
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <MoreHoriz
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={(e) =>
                                                        handleThreeDotsClick(
                                                            e,
                                                            row.orderNo
                                                        )
                                                    }
                                                />
                                                <Menu
                                                    PaperProps={{
                                                        style: {
                                                            width: 150,
                                                            border: "1px solid #e5e5e5",
                                                            boxShadow:
                                                                "1px 1px #e5e5e5"
                                                        }
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={() => {
                                                        setAnchorEl(null);
                                                        setSelectedOrder("");
                                                    }}
                                                >
                                                    <MenuItem
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: "rgba(0,0,0,0.75)"
                                                        }}
                                                        onClick={() => {
                                                            console.log(row);

                                                            _handleReceipt(
                                                                row.id
                                                            );
                                                        }}
                                                    >
                                                        Receipt
                                                    </MenuItem>
                                                    {userHasAdminRole() && (
                                                        <MenuItem
                                                            sx={{
                                                                fontWeight: 700,
                                                                color: "#FF681D"
                                                            }}
                                                            onClick={() => {
                                                                _handleRefund();
                                                                /* handleThreeDotsClose(
                                                                row
                                                            ); */
                                                            }}
                                                        >
                                                            Refund
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                        <TableFooter>
                            <TableRow></TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    {rows.length !== 0 && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLoadMore}
                            disabled={endOfList}
                        >
                            {endOfList
                                ? "No More Orders To Load"
                                : "Load More Orders"}
                        </Button>
                    )}

                    <TablePagination
                        sx={{
                            marginLeft: "auto",
                            "& .MuiToolbar-root": {
                                alignItems: "center",
                                "& .MuiInputBase-root": {
                                    fontWeight: 700
                                },
                                "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                    {
                                        color: "rgba(0,0,0,0.5)",
                                        marginBottom: 0
                                    },
                                "& .MuiTablePagination-actions": {
                                    display: "flex",
                                    gap: "0.5rem",
                                    "& button": {
                                        border: "1px solid rgba(0,0,0,0.2)",
                                        borderRadius: "4px"
                                    },

                                    "& button:hover": {
                                        backgroundColor: "primary.light"
                                    }
                                }
                            }
                        }}
                        component={"div"}
                        rowsPerPageOptions={[5, 10, 25]}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    );
};
