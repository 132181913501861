import React, { useState } from "react";
import { StoreContext } from "../context/StoreContext";
import {
    TextField,
    Button,
    Grid,
    Typography,
    Snackbar,
    Backdrop,
    InputAdornment,
    Tooltip
} from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { CircularProgress } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { getUserFromCache } from "../user/UserCommon";
import { serviceTypeCategoryByCompany } from "../queries/CustomQueries";
import clsx from "clsx";
import { userHasMarketboxAdminRole } from "../user/UserCommon";
import { auditCategoryCreate, auditCategoryUpdate } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { CloseRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const textFieldStyling = {
    backgroundColor: "white",
    border: "2px solid #d4d4d4",
    borderRadius: "4px",
    "&:hover": { backgroundColor: "white" },
    "& .MuiFilledInput-root": {
        "&:hover": { backgroundColor: "white" },
        backgroundColor: "white",
        "&.Mui-focused": {
            backgroundColor: "white"
        }
    }
};

export default function CategoryAddEditForm(props) {
    const navigate = useNavigate();
    const { state, dispatch, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //fields
    const [description, setDescription] = React.useState("");
    const [saveClicked, setSaveClicked] = useState(false);
    const [name, setName] = React.useState("");
    const [order, setOrder] = useState("");
    const [oldInfo, setOldInfo] = useState([]);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    const inputLabel = React.useRef(null);
    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    React.useEffect(() => {
        console.log("State ID CatAddEditForm", state);
        //setLabelWidth(inputLabel.current.offsetWidth);
        if (
            (props.mode === "Edit" || state.mode === "View") &&
            !fromDashboard()
        ) {
            getCategoryData();
        }
    }, []);

    const fromDashboard = () => {
        if (props.originPath === "DashboardForm") {
            //actions.setMode("Add");
            return true;
        }
        return false;
    };
    async function getCategoryData() {
        const categoryId = state.id;
        if (!!categoryId) {
            console.log("Category ID", categoryId);
            const input = { id: categoryId };
            const result = await graphql(
                graphqlOperation(queries.getServiceTypeCategory, input)
            );
            console.log("getServiceTypeCategory", result);

            if (!result.data.getServiceTypeCategory) {
                navigate("..");
            }

            if (result?.data?.getServiceTypeCategory && !fromDashboard()) {
                if (!(!!result.errors && result.errors.length > 0)) {
                    const { desc, name, ordinal } =
                        result.data.getServiceTypeCategory;
                    console.log("result", result.data.getServiceTypeCategory);
                    setName(name);
                    setDescription(desc);
                    setOrder(ordinal);

                    oldInfo.push({
                        name: name,
                        desc: desc
                    });
                }
            }
        } else {
            navigate("..");
        }
    }

    // cancel and go back to PromotionForm
    function handleCancel() {
        navigate("..");
    }

    async function savecategory(val) {
        setSaveClicked(true);
        try {
            let input = {
                desc: description,
                name: name,
                serviceTypeCategoryCompanyId: loggedInUser.company.id,
                companyId: loggedInUser.company.id
            };
            let response;
            if (
                props.mode === "Edit" &&
                state.id.length > 0 &&
                !fromDashboard()
            ) {
                input.id = state.id;
                response = await graphql(
                    graphqlOperation(mutations.updateServiceTypeCategory, {
                        input
                    })
                );
                if (
                    newInfo[0].name != "No Change" ||
                    newInfo[0].desc != "No Change"
                ) {
                    await auditCategoryUpdate(
                        loggedInUser,
                        response.data.updateServiceTypeCategory,
                        newInfo
                    );
                }
            } else {
                response = await graphql(
                    graphqlOperation(mutations.createServiceTypeCategory, {
                        input
                    })
                );
                if (
                    fromDashboard() &&
                    response?.data?.createServiceTypeCategory
                ) {
                    console.log("Category Response", response);
                    props.updateCategoryValue(
                        response?.data?.createServiceTypeCategory.id,
                        response?.data?.createServiceTypeCategory.name
                    );
                }
                await auditCategoryCreate(
                    loggedInUser,
                    response.data.createServiceTypeCategory
                );
            }

            if (val === "addAnother") {
                if (response && response.data) {
                    setSnackMsg("Category saved successfully.");
                    setMsgOpen(true);
                    reset();
                } else {
                    setSnackMsg("Unable to save the category.");
                    setMsgOpen(true);
                }
            } else {
                if (response && response.data) {
                    setSnackMsg("Category saved successfully.");
                    setMsgOpen(true);

                    if (props.originPath === "DashboardForm") {
                        props.close();
                    } else {
                        navigate("..");
                    }
                } else {
                    setSnackMsg("Unable to save the category.");
                    setMsgOpen(true);
                }
            }
        } catch (error) {
            console.error(
                "An error occurred saving the category. The error was: "
            );
            console.error(error);
        }
        setSaveClicked(false);
    }

    let newInfo;
    async function handleSaveCategory(val) {
        // validate
        /* if (description === "") {
            setSnackMsg("Please enter some description.");
            setMsgOpen(true);
            return;
        } */
        if (name === "") {
            setSnackMsg("Please enter a value for name.");
            setMsgOpen(true);
            return;
        }

        if (props.mode === "Edit" && state.id.length > 0 && !fromDashboard()) {
            newInfo = [
                {
                    oldName: oldInfo[0].name,
                    oldDesc: oldInfo[0].desc,
                    name: "No Change",
                    desc: "No Change"
                }
            ];

            if (name != oldInfo[0].name) {
                newInfo.map((item) => {
                    item.name = name;
                });
            }
            if (description != oldInfo[0].desc) {
                newInfo.map((item) => {
                    item.desc = description;
                });
            }
        }
        const categoriesResult = await execReadByPK({
            opname: "serviceTypeCategoryByCompany",
            op: serviceTypeCategoryByCompany,
            id: { companyId: loggedInUser.company.id },
            filter: {
                deleted: { ne: true }
            },
            sortDirection: "DESC"
        });

        // const categoriesResult = await graphql(
        //     graphqlOperation(queries.serviceTypeCategoryByCompany, {
        //         companyId: loggedInUser.company.id,
        //         filter: {
        //             deleted: { ne: true }
        //         },
        //         limit: process.env.REACT_APP_LISTLIMIT
        //     })
        // );
        console.log("categoriesResult:", categoriesResult.items);
        console.log(state.id);
        // check to see if there is an existing category with same name
        if (
            !!categoriesResult.items &&
            categoriesResult.items.some(
                (item) =>
                    state.id !== item.id &&
                    item.name.toLowerCase() === name.toLowerCase()
            )
        ) {
            setSnackMsg(
                "Unable to save the category because there is already a category with the same name."
            );
            setMsgOpen(true);
            return;
        }
        if (!!name) {
            savecategory(val);
        }
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    function reset() {
        // reset fields
        setDescription("");
        setName("");
    }
    // handle desc change
    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };
    // handle name change
    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            {saveClicked && (
                <Backdrop
                    sx={{ zIndex: 3000, color: "#fff" }}
                    open={saveClicked}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
            )}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {fromDashboard() && (
                    <>
                        <Typography className={classes.title} variant="h4" noWrap>
                            Add new category
                        </Typography>
                        <CloseRounded
                            sx={{
                                width: "2rem",
                                color: "rgba(0,0,0,0.5)",
                                fontWeight: 700,
                                cursor: "pointer"
                            }}
                            onClick={props.close}
                        />
                    </>
                )}
            </div>

            <Grid
                container
                sx={{ marginTop: "1rem", maxWidth: "35%", minWidth: "300px" }}
                item
                xs={fromDashboard() ? 12 : 5}
            >
                <TextField
                    fullWidth
                    variant="filled"
                    sx={textFieldStyling}
                    type="text"
                    label="Name"
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    title="This category name will be visible to customers on the booking page. You can organize your services under categories"
                                    placement="bottom"
                                    style={{
                                        color: "#a2a2a2",
                                        fontSize: "20px"
                                    }}
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    value={name}
                    onChange={handleChangeName}
                />
            </Grid>
            {!fromDashboard() && (
                <Grid
                    container
                    sx={{
                        marginTop: "1rem",
                        maxWidth: "35%",
                        minWidth: "300px"
                    }}
                    item
                    xs={fromDashboard() ? 12 : 5}
                >
                    <TextField
                        fullWidth
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        id="description"
                        label="Description"
                        sx={textFieldStyling}
                        value={description}
                        multiline
                        rows={4}
                        onChange={handleChangeDescription}
                    />
                </Grid>
            )}

            {userHasMarketboxAdminRole() && state.mode === "View" && (
                <Grid container spacing={2}>
                    <Grid item xs={fromDashboard() ? 12 : 5}>
                        <TextField
                            label="Display Order"
                            id="service-category-order"
                            type="number"
                            value={order}
                            className={clsx(classes.margin, classes.textField)}
                            margin="dense"
                            readOnly
                        />
                    </Grid>
                </Grid>
            )}
            <Grid
                container
                item
                xs={fromDashboard() ? 12 : 5}
                sx={{ marginTop: "1rem", gap: "1rem" }}
            >
                <Button
                    sx={{ boxShadow: "none" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveCategory("addSingle")}
                    disabled={state.mode === "View"}
                >
                    {!fromDashboard() ? "Save" : "Save & Close"}
                    {/*saveClicked && (
                        <CircularProgress
                            size={24}
                            className={classesnew.buttonProgress}
                        />
                    )*/}
                </Button>

                {/* fromDashboard() && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveCategory("addAnother")}
                        disabled={state.mode === "View"}
                        sx={{
                            backgroundColor: "white",
                            border: "2px solid #d4d4d4",
                            color: "rgba(0,0,0,0.75)",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "white",
                                boxShadow: "none"
                            }
                        }}
                    >
                        Save & Add Another
                    </Button>
                ) */}

                {!fromDashboard() && (
                    <Button
                        sx={{ boxShadow: "none" }}
                        variant="contained"
                        color="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                )}
            </Grid>
        </>
    );
}
