import React, { useRef, useImperativeHandle, forwardRef } from "react";

const StripeInput = forwardRef(
    ({ component: Component, onChange, ...props }, ref) => {
        const elementRef = useRef();

        useImperativeHandle(ref, () => ({
            focus: () => elementRef.current.focus()
        }));

        const handleChange = (event) => {
            if (onChange) {
                onChange(event);
            }
        };

        return (
            <Component
                options={{
                    style: {
                        base: {
                            fontSize: "14px",
                            letterSpacing: "0.025em",
                            "::placeholder": {
                                color: "rgba(0,0,0,0.6)"
                            }
                        },
                        invalid: {
                            color: "#c23d4b"
                        }
                    }
                }}
                onChange={handleChange}
                onReady={(element) => (elementRef.current = element)}
                {...props}
            />
        );
    }
);

export default StripeInput;
