import { createTheme } from "@mui/material/styles";

export const SchedulerTheme = ({ palette }) =>
    createTheme({
        palette,
        breakpoints: {
            values: {
                smallDesktop: 1449
            }
        },
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "0px",
                        padding: "0 !important",
                        borderBottom: "none !important"
                    }
                }
            },
            MuiBox: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "0px"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: palette.text.primary,
                        boxSizing: "border-box",
                        boxShadow: "none",
                        borderColor: "lightgrey",
                        borderRadius: "4px",
                        maxHeight: "36px",
                        "&:hover": {
                            borderColor: "lightgrey",
                            boxShadow: "none"
                        },
                        "&.TodayButton-button": {
                            "&:hover": {
                                background: "none !important"
                            }
                        }
                    }
                }
            },
            MuiTouchRipple: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            background: "none !important"
                        }
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        fontSize: "14px",
                        fontWeight: "400",
                        boxSizing: "border-box",
                        boxShadow: "none",
                        borderColor: "lightgrey",
                        borderRadius: "4px",
                        maxHeight: "36px",
                        "&:hover": {
                            "&& fieldset": {
                                boxShadow: "none",
                                background: "inherit"
                            }
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        padding: "10px 0 0 0",
                        fontSize: "14px",
                        color: palette.text.secondary
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        "&.Mui-focused": {
                            top: "12px"
                        }
                    },
                    shrink: {
                        top: "12px",
                        color: palette.text.secondary
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        fontSize: "14px",
                        fontWeight: "400",
                        borderColor: "lightgrey",
                        borderRadius: "4px",
                        margin: "18px 0",
                        maxHeight: "36px",
                        padding: "14px 0px !important",
                        "&& fieldset": {
                            alignItems: "center",
                            alignContent: "center",
                            boxShadow: "none",
                            background: "inherit"
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "revert"
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: palette.primary.main
                            }
                    },
                    input: {
                        width: "100%",
                        padding: "0px 23px 0px 14px !important",
                        fontSize: "14px"
                    }
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputFocused: {
                        padding: "12px 25px 12px 14px!important"
                    },
                    listbox: {
                        fontSize: "14px"
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        minHeight: "36px",
                        axHeight: "36px",
                        fontSize: "14px"
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: "grey",
                        "&:hover": {
                            "&& fieldset": {
                                boxShadow: "none"
                            }
                        }
                    }
                }
            }
        }
    });
