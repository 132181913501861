import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    FormControlLabel,
    Button,
    Grid,
    Switch
} from "@mui/material";
import BlockedTimeModal from "./BlockedTimeModal";
import { useQuery } from "@tanstack/react-query";
import { graphqlOperation } from "../../modules/AmplifyServices";
import { graphql } from "../../modules/AmplifyServices";
import { useAtom } from "jotai";
import { listProviderSchedules } from "../../graphql/queries";
import {
    allBlockedTimesAtom,
    displayBlockedTimesAtom,
    selectedBuId,
    recordIdAtom,
    openBlockedTimeDialogAtom
} from "../../atoms/blockedTime";
const BlockedTimeTab = ({ onClose }) => {
    const [openBlockedTimeDialog, setBlockedTimeDialog] = useAtom(
        openBlockedTimeDialogAtom
    );
    const [selectedRow, setSelectedRow] = useState();
    const [recordId] = useAtom(recordIdAtom);
    const [displayBlockedTimeRows] = useAtom(displayBlockedTimesAtom);
    const [, setAllBlockedTimes] = useAtom(allBlockedTimesAtom);
    const [, setSelectedBuId] = useAtom(selectedBuId);
    const { refetch } = useQuery({
        queryKey: ["get-blocked-times"],
        queryFn: async () => {
            try {
                let { data, errors } = await graphql(
                    graphqlOperation(listProviderSchedules, {
                        id: recordId,
                        scheduleinfo: { beginsWith: "BU" },
                        filter: {
                            deleted: { ne: true }
                        }
                    })
                );
                if (errors) throw errors;

                const {
                    listProviderSchedules: { items }
                } = data;
                return items;
            } catch (e) {
                console.log(e);
            }
        },
        onSuccess: (blockedTimes) => {
            setAllBlockedTimes(blockedTimes);
        }
    });

    const handleBlockedTimeDialog = () => {
        setBlockedTimeDialog(true);
    };

    const handleCloseBlockedTimeDialog = () => {
        setBlockedTimeDialog(false);
    };

    return (
        <>
            <BlockedTimeModal
                open={openBlockedTimeDialog}
                onClose={() => {
                    handleCloseBlockedTimeDialog();
                    refetch();
                }}
                blockedTimeData={selectedRow}
            />

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date Range</TableCell>
                                <TableCell>Time Range</TableCell>
                                <TableCell>Days of Week</TableCell>
                                <TableCell>Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayBlockedTimeRows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    onClick={() => {
                                        setSelectedRow(index);
                                        setBlockedTimeDialog(true);
                                        setSelectedBuId(row.buId);
                                    }}
                                >
                                    <TableCell>{row.dateRange}</TableCell>
                                    <TableCell>{row.timeRange}</TableCell>
                                    <TableCell>{row.weekDays}</TableCell>
                                    <TableCell>{row.notes}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Show expired"
                />

                <Grid sx={{ display: "flex", gap: "18px" }}>
                    <Button onClick={onClose} variant="outlined">
                        Cancel
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setSelectedBuId();
                            handleBlockedTimeDialog();
                        }}
                    >
                        + Add Blocked Time
                    </Button>
                </Grid>
            </DialogActions>
        </>
    );
};

export default BlockedTimeTab;
