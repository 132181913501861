import validator from "validator";
import { execRead } from "./DBService";
import * as mutations from "../graphqlOperations/mutations";
import * as queries from "../graphqlOperations/queries";

/* function to validate an email address
@return true
@return false */
function validateEmailAddress(email) {
    if (validator.isEmail(email)) {
        return true;
    } else {
        return false;
    }
}

/* function to validate a clean phone number (one with no spaces, dashes, or parentheses)
checks the phone number for special characters, and if its greater than 7 digits
@return true  
@return false  */
function validatePhoneNumber(phonenumber) {
    console.log("validate Phone Number ->", phonenumber);
    if (phonenumber.length > 8) {
        let result = /^\d+$/.test(phonenumber.slice(1));
        if (result) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

/* utility function for validateUniqueEmailAndSubdomain()
@return listCompanies ; a list of all companies */
async function listAllCompanies() {
    const listCompanies = await execRead({
        opname: "listCompanys",
        op: queries.listCompanys
    });
    return listCompanies.items;
}

/* function to see if an email and subdomain are already in use by another company
@return uniqueEmail 
@return uniqueSubdomain */
async function validateUniqueEmailAndSubdomain(email, subdomain) {
    let companiesArr = await listAllCompanies();
    let uniqueEmail = true;
    let uniqueSubdomain = true;
    for (let i = 0; i < companiesArr.length; i++) {
        if (companiesArr[i].emailaddress === email) {
            uniqueEmail = false;
            break;
        }
    }
    for (let i = 0; i < companiesArr.length; i++) {
        if (companiesArr[i].subdomain === subdomain) {
            uniqueSubdomain = false;
            break;
        }
    }
    return [uniqueEmail, uniqueSubdomain];
}

/**
 * Function to check if a company name is unique
 * @param {*} companyName
 * @returns isUniqueCompanyName
 */
async function validateUniqueCompanyName(companyName) {
    let companiesArr = await listAllCompanies();
    return !companiesArr.some((company) => company.name === companyName);
}

export {
    validateEmailAddress,
    validatePhoneNumber,
    validateUniqueEmailAndSubdomain,
    validateUniqueCompanyName
};
