import moment from "moment";
import { graphql, graphqlOperation } from "./AmplifyServices";
import { getUserFromCache } from "../user/UserCommon";
import {
    getAWSDateOf,
    getProviderFutureAvailableSlots
} from "./ScheduleService";
import { extractLocationIdFromScheduleinfo } from "../utils/providerLocationUtils";

function decideSlotSearchDateRange(tz) {
    // console.log("toDate State", toDate);
    //console.log("fromDate State", fromDate);
    let toDate = new Date(Date.now() + 86400000 * 365);
    let fromDate = new Date();
    console.log("fromDate before TZ", fromDate); //dateValue <- change to this for start getting dates from the booking date;
    let fromDate_tz = fromDate.toLocaleString("en-US", {
        timeZone: tz
    });

    console.log("fromDate after TZ", new Date(fromDate_tz));
    let diff = toDate.getTime() - fromDate.getTime();
    //let smallerThan365 = diff < 86400000 * 365;
    let diffNumOfDays = diff / 86400000;
    if (diffNumOfDays <= 30) {
        return {
            startDate: fromDate_tz,
            numOfDays: 30
        };
    } else {
        const td = moment(toDate).endOf("month");
        // setToDate(td.toDate());
        return {
            startDate: fromDate,
            numOfDays: td.diff(moment(fromDate), "days")
        };
    }
}

function populateDisplayMap(slots) {
    let dm = {};
    let setFirstAvailableDate = false;
    let firstAvailableDate = null;
    if (slots && slots.length > 0) {
        for (let dayWithSlots of slots) {
            if (dayWithSlots.slots.length > 0) {
                dayWithSlots.slots = dayWithSlots.slots.sort((sl1, sl2) => {
                    let t1 = sl1.datetime.valueOf();
                    let t2 = sl2.datetime.valueOf();
                    if (t1 > t2) return 1;
                    if (t1 < t2) return -1;
                    return 0;
                });
                if (!setFirstAvailableDate) {
                    firstAvailableDate = dayWithSlots.date;
                    setFirstAvailableDate = true;
                }
                dm[moment(dayWithSlots.date).format("YYMMDD")] = dayWithSlots;
            }
        }
    }
    if (setFirstAvailableDate) {
        const item = dm[moment(firstAvailableDate).format("YYMMDD")];
        if (item) {
            const slot = item.slots[0];
            /* setCurrentTFD(slot.tfd);
        handleChipClick(
          slot.start24,
          slot.start12,
          item.date,
          item.schid,
          uniquechipid_v2(slot),
          slot.hr
        ); */
        }
        //setDate(firstAvailableDate);
    }
    if (Object.keys(dm).length === 0) {
        //setNotAvailableAppts(true);
    }
    console.log("Display Map dm", dm);
    return dm;
    // setDisplayMap(dm);
}

const getProviderLocationId = (str) => {
    console.log("Inside Pvoder Location ID", str);
    const splitatPL = str.split("PL-");
    const id = splitatPL[1].split("|");
    return id[0];
};

const getCompanyLocationId = (str) => {
    console.log("CL-STR", str);
    const splitatPL = str.split("CL-");
    const id = splitatPL[1].split("|");
    console.log("Company Location ID", id[0]);
    return id[0];
};

function makeProviderScheduled(bookingDataTimeblockid) {
    const timeblock = bookingDataTimeblockid.split("::");
    let scheduleinfoParts = timeblock[1].split("|");
    console.log(
        "Make Provider Schedule ID ",
        `${timeblock[0]}::${scheduleinfoParts[2]}|${scheduleinfoParts[3]}|${scheduleinfoParts[4]}`
    );
    return `${timeblock[0]}::${scheduleinfoParts[2]}|${scheduleinfoParts[3]}|${scheduleinfoParts[4]}`;
}

const getProviderLocation = async (id) => {
    const data = await graphql(
        graphqlOperation(
            `query GetProviderLocation($id: ID!) {
        getProviderLocation(id: $id) {
          id
          name 
          timezone
          longitude
          latitude
        }
      }`,
            {
                id
            }
        )
    );
    return data.data.getProviderLocation;
};

const getCompanyLocation = async (id) => {
    const data = await graphql(
        graphqlOperation(
            `query GetCompanyLocation($id: ID!) {
          getCompanyLocation(id: $id) {
          id
          locationname 
          timezone
          latitude
          longitude
        }
      }`,
            {
                id
            }
        )
    );
    console.log("Get COmpany Location", data);
    return data.data.getCompanyLocation;
};

async function getScheduleLocationTz(providerScheduleId) {
    const SCHEDPKSKSPLITAT = "::";
    //get loc id from scheduleId
    const pksk = providerScheduleId.split(SCHEDPKSKSPLITAT);
    let skparts = pksk[1].split("|");
    let locationId = skparts[1].slice(3);
    try {
        let input = { id: locationId };
        if (skparts[1].slice(0, 2) === "PL") {
            const result = await graphql(
                graphqlOperation(
                    `query GetProviderLocation($id: ID!) {
          getProviderLocation(id: $id) {
            id
            timezone
            addressoneline
            longitude
            latitude
          }
        }
      `,
                    input
                )
            );
            return {
                ...result.data.getProviderLocation,
                locationType: "PL"
            };
        } else {
            const result = await graphql(
                graphqlOperation(
                    `query GetCompanyLocation($id: ID!) {
          getCompanyLocation(id: $id) {
            id
            timezone
            addressoneline
            longitude
            latitude
          }
        }
      `,
                    input
                )
            );
            return {
                ...result.data.getCompanyLocation,
                locationType: "CL"
            };
        }
    } catch (e) {
        console.log("error getting tz of schedule location");
        return {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            locationType: "PL" /*default as we don't change address for PL */
        };
    }
}

const checkSchedule = async (bookingData) => {
    console.log("Booking Data", bookingData);
    const user = getUserFromCache();
    let companyLocationId;
    let providerLocationId;
    let locationData;
    let isRemoteLoc = false;
    if (bookingData.timeblockid.includes("CL-")) {
        companyLocationId = await getCompanyLocationId(bookingData.timeblockid);

        locationData = await getCompanyLocation(companyLocationId);
    }

    if (bookingData.timeblockid.includes("PL-")) {
        providerLocationId = await getProviderLocationId(
            bookingData.timeblockid
        );
        locationData = await getProviderLocation(providerLocationId);
        isRemoteLoc = true;
    }

    let coords;
    if (locationData) {
        coords = {
            lat: locationData.latitude,
            lng: locationData.longitude
        };
    }

    const timeblock = bookingData.timeblockid.split("::");
    let input = {
        id: timeblock[0],
        scheduleinfo: timeblock[1]
    };

    const dateCriteria = decideSlotSearchDateRange(bookingData.timezone);

    input = {
        providerId: bookingData.providerId,
        companyId: bookingData.companyId,
        locationId: extractLocationIdFromScheduleinfo(timeblock[1]),
        serviceId: bookingData.servicetype.id,
        startdate: getAWSDateOf(dateCriteria.startDate), //searchSlotStartDate,
        numdays: dateCriteria.numOfDays, //numDays,
        bookingIntervalMinutes: user.company.bookingIntervalMinutes,
        //locationType: appointmentLocation,
        serviceDuration: bookingData.duration,
        timeofday: [true, false, false, false],
        travelTime: isRemoteLoc ? bookingData.company.travelTime : 0, //use travel time only for remote location
        geoLoc: isRemoteLoc ? coords : null,
        bookingIncrement: user?.company?.bookingIncrement
            ? user.company.bookingIncrement
            : 15
    };

    let providerFutureAvailability =
        await getProviderFutureAvailableSlots(input);
    if (
        providerFutureAvailability &&
        providerFutureAvailability.length > 0 &&
        providerFutureAvailability[0].slots.length > 0
    ) {
        populateDisplayMap(providerFutureAvailability[0].slots);
    } else {
        console.log("Nothing To Put in Slots");
    }

    return providerFutureAvailability;
};

export {
    populateDisplayMap,
    checkSchedule,
    makeProviderScheduled,
    getProviderLocation,
    getProviderLocationId,
    getScheduleLocationTz,
    getCompanyLocationId,
    getCompanyLocation
};
