const setTableRowsSession = (property, num) => {
    sessionStorage.setItem(property, num);
};

const removeTableRowsSession = (property) => {
    sessionStorage.removeItem(property);
};

const getTableRowsSession = (property) => {
    if (sessionStorage.getItem(property)) {
        return Number(sessionStorage.getItem(property));
    } else {
        if (property === "auditRecordsTable") {
            return 25;
        } else {
            return 5;
        }
    }
};

export { setTableRowsSession, removeTableRowsSession, getTableRowsSession };
