import zapierLogo from "../../images/zapierLogo.png";
import hubspotLogo from "../../images/hubspotLogo.png";
import salesforceLogo from "../../images/salesforceLogo.png";
import infusionsoftLogo from "../../images/infusionsoftLogo.png";
import zohocrmLogo from "../../images/zohocrmLogo.png";
import pipedriveLogo from "../../images/pipedriveLogo.png";
import mailchimpLogo from "../../images/mailchimpLogo.png";
import activecampaignLogo from "../../images/activecampaignLogo.png";
import typeformLogo from "../../images/typeformLogo.png";
import gtmLogo from "../../images/gtmLogo.png";
import GoogleTagManagerSetupSection from "../../components/GoogleTagManagerSetupSection";

const apiIntegrationsList = [
    {
        id: "zapier-integration",
        image: zapierLogo,
        title: "Zapier",
        description: "Connect to 500+ other apps through Zapier.",
        panelText: (
            <div>
                <div
                    style={{
                        paddingBottom: "8px",
                        fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                    }}
                >
                    Connect to 500+ other apps through Zapier.
                </div>
                <div
                    style={{
                        fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                    }}
                >
                    Use Zapier to connect with Keap, Capsule CRM, Salesforce,
                    and hundreds of other apps.
                </div>
            </div>
        ),
        templateIds: [
            "1312419",
            "1314758",
            "1314748",
            "1354824",
            "1314752",
            "1354778"
        ]
    }
];

const crmIntegrationsList = [
    {
        id: "hubspot-integration",
        image: hubspotLogo,
        title: "HubSpot",
        description: "Sync with HubSpot using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with HubSpot using Zapier. Create new contacts and tasks in
                HubSpot, trigger workflows and more.
            </div>
        ),
        templateIds: ["1312419"]
    },
    {
        id: "salesforce-integration",
        image: salesforceLogo,
        title: "Salesforce",
        description: "Sync with Salesforce using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with Salesforce using Zapier. Create new contacts and tasks
                in Salesforce, block off time, and more.
            </div>
        ),
        templateIds: ["1314748"]
    },
    {
        id: "infusionsoft-integration",
        image: infusionsoftLogo,
        title: "Keap",
        description: "Sync with Keap using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with Keap using Zapier. Create or update Keap contacts,
                block off time, and more.
            </div>
        ),
        templateIds: ["1314752"]
    },
    {
        id: "zoho-integration",
        image: zohocrmLogo,
        title: "Zoho CRM",
        description: "Sync with Zoho CRM using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with Zoho CRM using Zapier. Book, reschedule, and cancel
                appointments with Zoho CRM customers.
            </div>
        ),
        templateIds: ["1354778"]
    },
    {
        id: "pipedrive-integration",
        image: pipedriveLogo,
        title: "Pipedrive",
        description: "Sync with Pipedrive using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with Pipedrive using Zapier. Automate your sales pipeline
                with Pipedrive. Create or update People in Pipedrive.
            </div>
        ),
        templateIds: ["1354824"]
    }
];

const analyticsIntegrationsList = [
    {
        id: "gtm-integration",
        image: gtmLogo,
        title: "Google Tag Manager",
        description:
            "Send events from your booking page to Google Tag Manager.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Read more about creating customized events in Google Tag
                Manager. Your GTM ID will be added to the <b>head</b> of your
                online booking page.
            </div>
        ),
        instructionStepOne: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Visit{" "}
                <a
                    href="https://tagmanager.google.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Tag Manager
                </a>
                , create or copy your container ID.
            </div>
        ),
        instructionStepTwo: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Paste your container ID below.
            </div>
        ),
        noInstructions: true,
        setupSection: <GoogleTagManagerSetupSection />
    }
];

const emailMarketingIntegrationsList = [
    {
        id: "mailchimp-integration",
        image: mailchimpLogo,
        title: "MailChimp",
        description: "Sync with MailChimp using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with MailChimp using Zapier. Add and update client lists,
                and optionally subscribe new clients to a MailChimp mailing
                list.
            </div>
        ),
        templateIds: ["1314758"]
    },
    {
        id: "activecampaign-integration",
        image: activecampaignLogo,
        title: "ActiveCampaign",
        description: "Sync with ActiveCampaign using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with ActiveCampaign using Zapier. Create new contacts,
                deals and events in ActiveCampaign, block off time, and more.
            </div>
        )
        //templateIds: "1354721"
    }
];

const onlineFormsIntegrationsList = [
    {
        id: "typeform-integration",
        image: typeformLogo,
        title: "Typeform",
        description: "Sync with Typeform using Zapier.",
        panelText: (
            <div
                style={{
                    fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
                }}
            >
                Sync with Typeform using Zapier. Create and update existing
                forms.
            </div>
        )
        //templateIds: "1354846"
    }
];

export {
    apiIntegrationsList,
    crmIntegrationsList,
    emailMarketingIntegrationsList,
    onlineFormsIntegrationsList,
    analyticsIntegrationsList
};
