import React, { useContext, useEffect, useState, useCallback } from "react";
import { StoreContext } from "../context/StoreContext";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
// prettier-ignore
import { Grid, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Switch, Button, FormControlLabel, Tooltip, IconButton, Dialog, DialogActions, Tabs, Tab, Box } from "@mui/material";
// prettier-ignore
import { DialogContent, DialogTitle, DialogContentText, Snackbar, Typography } from "@mui/material";
import UsersTable from "../components/UsersTable";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { _findPromotions } from "../utils";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../modules/AmplifyServices";
import { ConsoleLogger } from "aws-amplify/utils";
import voucher_codes from "voucher-code-generator";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import {
    getUserFromCache,
    getUserRole,
    userHasAdminRole,
    USERROLE,
    userHasMarketboxAdminRole,
    getCompanyAdmins
} from "../user/UserCommon";
import {
    auditUserCreate,
    auditUserDelete,
    auditUserResetPw
} from "../modules/Audit";
import { CSVReader } from "react-papaparse";
import uuid from "uuid/v4";
import MuiPhoneNumber from "material-ui-phone-number";
import validator from "validator";
import { validatePhoneNumber } from "../onboarding/modules/ValidityCheck";
import * as Sentry from "@sentry/react";
import {
    userByCompany,
    searchByName,
    searchByLastName
} from "../queries/UserQueries";
import { Spinner } from "../utils/CommonComponents/Spinner";
import _ from "lodash";
import ClearIcon from "@mui/icons-material/Clear";
import { TriggerManager } from "../modules/TriggerManager";
import UserExport from "../components/exportSheets/UserExport";
import { Close } from "@mui/icons-material";

const sixhours = 21600000; //in milis
function UsersForm(props) {
    const { actions } = useContext(StoreContext);
    const classes = usePromotionsStyles();
    //for type
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    //active pending switch
    const [active, setActive] = useState(true);
    const [deleted, setDeleted] = useState(false);
    const [selected, setSelected] = useState([]);

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    const [rows, setRows] = useState([]);
    const [addUserDialog, setAddUserDialog] = useState(false);
    const [firstname, setFirstName] = useState("");
    const [emailaddress, setEmailAddress] = useState("");
    const [lastname, setLastName] = useState("");
    const [userType, setUserType] = useState(2);
    const [phone_number, setPhoneNumber] = useState("");
    const [phoneNumberType, setPhoneNumberType] = useState(1);
    const [providerId, setProviderId] = useState("");
    const [addUserClicked, setAddUserClicked] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [emailNotRegistered, setEmailNotRegistered] = useState(false);
    const [registerUserDialog, setRegisterUserDialog] = useState(false);
    const [registerUserClicked, setRegisterUserClicked] = useState(false);
    const [inviteDialog, setInviteDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [allow, setAllow] = useState(false);
    const [
        providerWithEmaillAddressDoesNotExist,
        setProviderWithEmaillAddressDoesNotExist
    ] = useState(false);
    const logger = new ConsoleLogger("UsersForm");

    const [sendEmail, setSendEmail] = useState(true);
    const [tab, setTab] = useState(0);
    const [userForPassworManagement, setUserForPasswordManagement] = useState();
    const [showManagePasswordDialog, setShowManagePasswordDialog] = useState();
    const [passwordManagementType, setPasswordManagementType] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [userStatusType, setUserStatusType] = useState(1); //1-active, 2-inactive, 3-pending, 4-all
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [exportReadyUsers, setExportReadyUsers] = useState();
    const [company, setCompany] = useState();
    const [unregisteredUserIsTooNew, setunregisteredUserIsTooNew] =
        useState(false);
    const [
        showUnregisteredUserIsTooNewDialog,
        setShowUnregisteredUserIsTooNewDialog
    ] = useState(false);
    const [isGuestOnlyCheckout, setIsGuestOnlyCheckout] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const csvHeaders = [
        { label: "ID", key: "ID" },
        { label: "Created At", key: "CREATEDAT" },
        { label: "Registered", key: "REGISTERED" },
        { label: "Email", key: "EMAIL" },
        { label: "First Name", key: "FIRSTNAME" },
        { label: "Last Name", key: "LASTNAME" },
        { label: "Home Phone", key: "HOMEPHONE" },
        { label: "Work Phone", key: "WORKPHONE" },
        { label: "Mobile Phone", key: "MOBILEPHONE" },
        { label: "Phone Preference", key: "PHONEPREFERENCE" },
        { label: "Address", key: "ADDRESS" },
        { label: "City", key: "CITY" },
        { label: "State", key: "STATE" },
        { label: "Country", key: "COUNTRY" },
        { label: "Postal Code", key: "POSTALCODE" },
        { label: "Status", key: "STATUS" },
        { label: "Role", key: "ROLE" },
        { label: "Contact Me Opt-in", key: "CONTACTCONSENT" },
        { label: "Last Login Date", key: "LASTLOGINDATE" },
        { label: "Login Count", key: "LOGINCOUNT" }
    ];
    // style for CSVReader component
    const parserStyle = {
        display: "none"
    };
    // config for CSVReader component
    const parserOptions = {
        skipEmptyLines: true,
        header: true
    };

    const fileInput = React.createRef();
    const limit = process.env.REACT_APP_LISTLIMIT;

    const handleTabChange = (e, tabNum) => {
        setShowLoading(true);
        setUserStatusType(1);
        setPage(0);
        setTab(tabNum);
        setSearchText("");
        setShowLoading(false);
    };

    const closeExportModal = () => {
        setShowExportModal(false);
    };

    const openExportModal = () => {
        setShowExportModal(true);
    };

    async function loadUsers() {
        setShowLoading(true);
        // get current user
        const currentuser = getUserFromCache();
        setCurrentUser(currentuser);

        // MB Admin sees all a company users plus MB Admins
        // Company Admin can see only company users but not MB Admins
        // Provider and Client should not see this screen so do not display data

        const userRole = getUserRole();
        if (userHasAdminRole()) {
            if (searchText) fetchUsers(searchText);
            else fetchUsers();
            setAllow(true);
        } else {
            // no fetch so do nothing
            setAllow(false);
            logger.error(
                "WARNING. No role found for user. user id = " +
                    JSON.stringify(currentuser)
            );
        }
        setShowLoading(false);
    }

    async function getCompanyFromDB() {
        const currentUser = getUserFromCache();
        const companyId = currentUser.company.id;
        const input = { id: companyId };
        const result = await graphql(
            graphqlOperation(queries.getCompany, input)
        );
        setCompany(result.data.getCompany);
        //If company not set in time for when it is called, causes problems.
        return;
    }

    async function checkGuestOnlyCheckout() {
        const currentUser = getUserFromCache();
        const companyId = currentUser.company.id;
        const publicResult = await graphql(
            graphqlOperation(queries.publicsiteByCompany, {
                companyId: companyId
            })
        );
        if (
            !!publicResult &&
            !!publicResult.data &&
            !!publicResult.data.publicsiteByCompany.items
        ) {
            if (publicResult.data.publicsiteByCompany.items.length > 0) {
                let { StyleCustomization } =
                    publicResult.data.publicsiteByCompany.items[0];
                StyleCustomization = StyleCustomization
                    ? JSON.parse(StyleCustomization)
                    : null;
                const checkoutOption =
                    StyleCustomization?.bookingFlowCustomizations
                        ?.checkoutOption;
                if ("GUEST_ONLY" === checkoutOption) {
                    setIsGuestOnlyCheckout(true);
                }
            }
        }
    }

    useEffect(() => {
        try {
            getCompanyFromDB();
            checkGuestOnlyCheckout();
        } catch (err) {
            console.error(
                "Error while reading company info or public site info",
                err
            );
        }
    }, []);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        loadUsers();
        setShowLoading(true);
    }, [tab, userStatusType]);

    async function fetchUsers(searchInput) {
        setShowLoading(true);
        const currentuser = await getUserFromCache();
        const currentUserRole = getUserRole();
        let queryUserRole;
        if (tab === 0) queryUserRole = "PROVIDER";
        if (tab === 1) queryUserRole = "COMPANY_ADMIN";

        // All users are stored in the CSV file regardless of what tab is selected
        // We also filter the users depending on if the user is a Company Admin or MB Admin
        let users = [];
        let nextToken = null;

        if (searchInput) {
            console.log("searchInput", searchInput);
            if (searchInput.includes("@")) {
                do {
                    let usersByCompany = await graphql(
                        graphqlOperation(userByCompany, {
                            companyId: currentuser.company.id,
                            roleEmailaddress: {
                                beginsWith: {
                                    role: queryUserRole,
                                    emailaddress: searchInput.toLowerCase()
                                }
                            },
                            filter: getFilterByStatus({}),
                            limit,
                            nextToken
                        })
                    );
                    if (
                        usersByCompany &&
                        usersByCompany.data &&
                        usersByCompany.data.userByCompany
                    ) {
                        users.push(...usersByCompany.data.userByCompany.items);
                        if (usersByCompany.data.userByCompany.nextToken) {
                            nextToken =
                                usersByCompany.data.userByCompany.nextToken;
                        } else {
                            nextToken = null;
                        }
                    } else nextToken = null;
                } while (nextToken);
            } else {
                const userIds = new Set();
                const usersByFnOrLn = [];
                do {
                    let usersByName = await graphql(
                        graphqlOperation(searchByName, {
                            companyId: currentuser.company.id,
                            lc_name: {
                                beginsWith: searchInput.toLowerCase()
                            },
                            filter: getFilterByRoleAndStatus(queryUserRole),
                            limit,
                            nextToken
                        })
                    );
                    if (
                        usersByName &&
                        usersByName.data &&
                        usersByName.data.searchByName
                    ) {
                        usersByFnOrLn.push(
                            ...usersByName.data.searchByName.items
                        );
                        usersByName.data.searchByName.items.forEach((u) => {
                            userIds.add(u.id);
                        });
                        if (usersByName.data.searchByName.nextToken) {
                            nextToken = usersByName.data.searchByName.nextToken;
                        } else {
                            nextToken = null;
                        }
                    } else nextToken = null;
                } while (nextToken);
                do {
                    let usersByLn = await graphql(
                        graphqlOperation(searchByLastName, {
                            companyId: currentuser.company.id,
                            lc_ln: {
                                beginsWith: searchInput.toLowerCase()
                            },
                            filter: getFilterByRoleAndStatus(queryUserRole),
                            limit,
                            nextToken
                        })
                    );
                    if (
                        usersByLn &&
                        usersByLn.data &&
                        usersByLn.data.searchByLastName
                    ) {
                        usersByFnOrLn.push(
                            ...usersByLn.data.searchByLastName.items
                        );
                        usersByLn.data.searchByLastName.items.forEach((u) => {
                            userIds.add(u.id);
                        });
                        if (usersByLn.data.searchByLastName.nextToken) {
                            nextToken =
                                usersByLn.data.searchByLastName.nextToken;
                        } else {
                            nextToken = null;
                        }
                    } else nextToken = null;
                } while (nextToken);
                users.push(
                    ...usersByFnOrLn.filter((u) =>
                        userIds.has(u.id) ? userIds.delete(u.id) : false
                    )
                );
            }
        } else {
            do {
                let usersByCompany = await graphql(
                    graphqlOperation(userByCompany, {
                        companyId: currentuser.company.id,
                        roleEmailaddress: {
                            beginsWith: {
                                role: queryUserRole
                            }
                        },
                        filter: getFilterByStatus({}),
                        limit,
                        nextToken
                    })
                );
                if (
                    usersByCompany &&
                    usersByCompany.data &&
                    usersByCompany.data.userByCompany
                ) {
                    users.push(...usersByCompany.data.userByCompany.items);
                    if (usersByCompany.data.userByCompany.nextToken) {
                        nextToken = usersByCompany.data.userByCompany.nextToken;
                    } else {
                        nextToken = null;
                    }
                } else nextToken = null;
            } while (nextToken);
        }

        // let users = usersByCompany.data.userByCompany.items;
        if (tab === 2 && currentUserRole === "MARKETBOX_ADMIN") {
            let mbadmins = await graphql(
                graphqlOperation(userByCompany, {
                    companyId: currentuser.company.id,
                    roleEmailaddress: {
                        beginsWith: {
                            role: "MARKETBOX_ADMIN"
                        }
                    },
                    filter: getFilterByStatus({}),
                    limit
                })
            );
            users.push(...mbadmins.data.userByCompany.items);
        }
        users.map((item) => {
            item.status =
                (item.active && item.LoginInfo) || item.registered === false
                    ? "ACTIVE"
                    : !item.active
                      ? "INACTIVE"
                      : "PENDING";
            return item;
        });
        if (userStatusType === 3) users = keepUsersNotLoggedIn(users);
        else if (userStatusType === 1) users = keepOnlyLoggedInUsers(users); //not show never logged in users
        setRows(users);

        setShowLoading(false);
    }

    function keepUsersNotLoggedIn(users) {
        return (
            users && users.filter((u) => !u.LoginInfo && u.registered !== false)
        );
    }
    function keepOnlyLoggedInUsers(users) {
        return (
            users &&
            users.filter((u) => !!u.LoginInfo || u.registered === false)
        );
    }
    function getFilterByRoleAndStatus(role) {
        if (userStatusType === 1 || userStatusType === 3) {
            //because pending are active as well
            return {
                and: [
                    {
                        role: { eq: role },
                        active: { ne: false },
                        deleted: { ne: true }
                    }
                ]
            };
        }
        if (userStatusType === 2) {
            return {
                and: [
                    {
                        role: { eq: role },
                        active: { eq: false },
                        deleted: { ne: true }
                    }
                ]
            };
        }
        if (userStatusType === 4) {
            return {
                and: [{ role: { eq: role }, deleted: { ne: true } }]
            };
        }
        return {};
    }
    function getFilterByStatus(filter) {
        if (userStatusType === 1 || userStatusType === 3) {
            //because pending are active as well
            return {
                ...filter,
                and: [{ active: { ne: false }, deleted: { ne: true } }]
            };
        }
        if (userStatusType === 2) {
            return {
                ...filter,
                and: [{ active: { eq: false }, deleted: { ne: true } }]
            };
        }
        if (userStatusType === 4) {
            return {
                ...filter,
                and: [{ deleted: { ne: true } }]
            };
        }
        return filter;
    }

    async function handleUserType(event) {
        let value;
        if (userChange) {
            setUserType(event);
            value = event;
        } else {
            setUserType(event.target.value);
            value = event.target.value;
            enteredEmail = emailaddress;
        }
        setProviderWithEmaillAddressDoesNotExist(false);
        if (value === 1) {
            setFirstName("");
            setLastName("");
            setProviderWithEmaillAddressDoesNotExist(false);
        }
        if (value === 2 || value === 4) {
            const searchProviderByEmail = `query SearchProviders(
        $filter: SearchableProviderFilterInput
        $sort: SearchableProviderSortInput
        $limit: Int
        $nextToken: String
      ) {
        searchProviders(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            firstname
            lastname
            emailaddress
            phone
            state
            country
            active
            deleted
          }
          nextToken
          total
        }
      }
      `;
            try {
                //console.log("Testing the enteredEmail", enteredEmail);
                const result = await graphql(
                    graphqlOperation(searchProviderByEmail, {
                        filter: {
                            and: [
                                { companyId: { eq: currentUser.companyId } },
                                { emailaddress: { eq: enteredEmail } },
                                { active: { ne: false } }
                            ]
                        }
                    })
                );
                // console.log("testing result", result);
                if (result && result.data.searchProviders.items.length === 1) {
                    if (result.data.searchProviders.items[0].deleted) {
                        setSnackMsg(
                            "The entered email is associated with an already deleted user, please enter another email."
                        );
                        setMsgOpen(true);
                    } else {
                        setFirstName(
                            result.data.searchProviders.items[0].firstname
                        );
                        setLastName(
                            result.data.searchProviders.items[0].lastname
                        );
                        setProviderId(result.data.searchProviders.items[0].id);
                        setPhoneNumber(
                            result.data.searchProviders.items[0].phone
                        );

                        // If the entered email is linked to an existing provider, give the user a message to let them know
                        setSnackMsg(
                            "We have linked the email to an existing provider."
                        );
                        setMsgOpen(true);
                    }
                } else if (
                    result &&
                    result.data.searchProviders.items.length > 1
                ) {
                    setSnackMsg(
                        "More than one provider was found with this email address, please contact support."
                    );
                    setMsgOpen(true);
                } else {
                    setFirstName("");
                    setLastName("");
                    setProviderId("");
                    setPhoneNumber("");
                    setProviderWithEmaillAddressDoesNotExist(true);
                    setSnackMsg(
                        "We will create a provider profile for the new user. Please complete the provider profile."
                    );
                    setMsgOpen(true);
                }
            } catch (e) {
                Sentry.captureException(e);
                console.log("Error changing user type", e);
                setSnackMsg(
                    "We may not be able to complete this action, please contact support."
                );
                setMsgOpen(true);
            }
        }
    }

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    function clearSearch() {
        setSearchText("");
        debounce("");
    }

    function handleSearchChange(event) {
        setSearchText(event.target.value);
        const searchValue = event.target.value;
        debounce(searchValue);
    }

    // debounce with 500ms delay
    const debounce = useCallback(
        _.debounce((searchValue) => {
            if (!!searchValue) {
                fetchUsers(searchValue);
                setPage(0);
            } else {
                fetchUsers();
                setPage(0);
            }
        }, 500),
        [tab, userStatusType]
    );

    const deleteUser = async (id) => {
        const loggedInUser = await getUserFromCache();
        const input = {
            id: id,
            deleted: true
        };
        console.log("in delete user");
        const deleteUserData = await graphql(
            graphqlOperation(mutations.updateUser, { input })
        );

        await auditUserDelete(loggedInUser, deleteUserData.data.updateUser);

        if (deleteUserData.errors && deleteUserData.errors.length > 0) {
            setSnackMsg("Something went wrong when deleting user.");
            setMsgOpen(true);
        } else {
            fetchUsers();
            setDeleteDialog(false);
            setSelected([]);
            actions.setPage("UsersForm");
        }
    };
    const _handleDeleteDialogAccept = (e) => {
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteUser(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    const _handleInvite = () => {
        setInviteDialog(true);
    };

    const _handleAdd = () => {
        setFirstName("");
        setLastName("");
        setUserType(2);
        setEmailAddress("");
        setAddUserDialog(true);
        setProviderId("");
        setPhoneNumber("");
        setPhoneNumberType(1);
        setSendEmail(true);
        setAddUserClicked(false);
        setRegisterUserDialog(false);
        setRegisterUserClicked(false);
        setEmailNotRegistered(false);
        setunregisteredUserIsTooNew(false);
    };

    const handlePhoneNumberChange = (value) => {
        console.log("phoneNumber", value);
        setPhoneNumber(value);
    };

    function genpass() {
        return voucher_codes.generate({
            length: 8,
            count: 1,
            charset: voucher_codes.charset("alphanumeric")
        })[0];
    }
    function doValidateAddUserData() {
        if (!validator.isEmail(emailaddress)) {
            setSnackMsg("Please enter valid email address");
            setMsgOpen(true);
            return false;
        }
        if (!firstname) {
            setSnackMsg("Please enter valid first name");
            setMsgOpen(true);
            return false;
        }
        if (firstname != firstname.trim()) {
            setSnackMsg(
                "Please ensure that there are no spaces before or after the first name"
            );
            setMsgOpen(true);
            return false;
        }
        if (!lastname) {
            setSnackMsg("Please enter valid last name");
            setMsgOpen(true);
            return false;
        }
        if (lastname != lastname.trim()) {
            setSnackMsg(
                "Please ensure that there are no spaces before or after the last name"
            );
            setMsgOpen(true);
            return false;
        }
        let cleanPhone = phone_number.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-");
        if (!validatePhoneNumber(cleanPhone)) {
            setSnackMsg("Please enter valid phone number");
            setMsgOpen(true);
            return false;
        }
        console.log("cleanPhone", cleanPhone);
        return true;
    }

    const handleRegisterClient = async (existingClientUser) => {
        // In order to register a client who has been added by the public site
        // 1. We first create the Cognito profile
        // 2. We use the CognitoId, and the existing client fields to create a new User profile and add it as a User record
        // 3. We then set deleted=true for the old existing User record
        // 4. We change the existing client record to have the CognitoID as its userId
        //      -send email if necessary
        setRegisterUserClicked(true);
        try {
            const loggedInUser = await getUserFromCache();
            const clientData = existingClientUser;
            console.log("client data: ", clientData);
            const temppas = genpass();
            let cleanPhoneNumber =
                clientData.phonepref === "MOBILE"
                    ? clientData.mobilephone
                    : clientData.phonepref === "WORK"
                      ? clientData.workphone
                      : clientData.phonepref === "HOME"
                        ? clientData.homephone
                        : null;

            const result = await postApi("changeuserrole", "/createuser", {
                body: {
                    username: emailaddress,
                    email: emailaddress,
                    group: "Client", //userType === 1 ? "Client" : "Provider",
                    phone_number: cleanPhoneNumber,
                    temppas,
                    firstname: clientData.firstname,
                    lastname: clientData.lastname
                }
            });
            const newUserCognitoID = result.newUserCognitoID;

            console.log(
                "cognito profile created",
                result,
                temppas,
                cleanPhoneNumber
            );
            let registerUserInput = {
                id: newUserCognitoID,
                username: newUserCognitoID,
                emailaddress: emailaddress,
                firstname: clientData.firstname,
                lastname: clientData.lastname,
                registered: true,
                active: true,
                role: "CLIENT",
                companyId: loggedInUser.companyId,
                userCompanyId: loggedInUser.companyId,
                phonepref: clientData.phonepref,
                homephone: clientData.homephone,
                mobilephone: clientData.mobilephone,
                workphone: clientData.workphone,
                contactconsent: true,
                contactconsentdatetime: new Date().toISOString()
            };
            const newUser = await graphql(
                graphqlOperation(mutations.createUser, {
                    input: registerUserInput
                })
            );
            console.log("new user result", newUser);

            const deleteOldUserProfile = await graphql(
                graphqlOperation(mutations.updateUser, {
                    input: { id: clientData.id, deleted: true }
                })
            );

            console.log("delete result", deleteOldUserProfile);
            const clientProfile = await graphql(
                graphqlOperation(queries.clientByUserId, {
                    userId: clientData.id
                })
            );
            const clientId = clientProfile.data.clientByUserId.items[0].id;
            console.log("existing client profile", clientProfile);

            const updateClientResult = await graphql(
                graphqlOperation(mutations.updateClient, {
                    input: {
                        id: clientId,
                        userId: newUserCognitoID,
                        clientUserId: newUserCognitoID
                    }
                })
            );
            console.log("update client result: ", updateClientResult);
            const clientPackagesResult = await graphql(
                graphqlOperation(queries.clientPackageByUser, {
                    userId: clientData.id
                })
            );
            console.log("client packages", clientPackagesResult);

            if (
                clientPackagesResult &&
                clientPackagesResult.data.clientPackageByUser.items.length > 0
            ) {
                clientPackagesResult.data.clientPackageByUser.items.map(
                    async (item) => {
                        const updateClientPackageResult = await graphql(
                            graphqlOperation(mutations.updateClientPackage, {
                                input: {
                                    id: item.id,
                                    userId: newUserCognitoID,
                                    clientPackageUserId: newUserCognitoID
                                }
                            })
                        );
                        console.log(
                            "update package result",
                            updateClientPackageResult
                        );
                    }
                );
            }
            let phonetype =
                clientData.phonepref === "MOBILE"
                    ? 1
                    : clientData.phonepref === "WORK"
                      ? 2
                      : clientData.phonepref === "HOME"
                        ? 3
                        : null;

            clientData.phonetypeNum = phonetype;
            clientData.cleanPhoneNumber = cleanPhoneNumber;
            console.log("cient data before sending email", clientData);
            try {
                await sendRegistrationEmail(temppas, clientData);
            } catch (e) {
                Sentry.captureException(e);
                console.log("Registration Email Error = " + JSON.stringify(e));
                setSnackMsg("Error sending registration email");
                setMsgOpen(true);
            }
            setSnackMsg("User has been created successfully.");
            setMsgOpen(true);

            setAddUserDialog(false);
            setRegisterUserDialog(false);
            setEmailNotRegistered(false);
            setunregisteredUserIsTooNew(false);
        } catch (e) {
            Sentry.captureException(e);
            setAddUserClicked(false);
            setAddUserDialog(false);
            setRegisterUserDialog(false);
            setRegisterUserClicked(false);
            setEmailNotRegistered(false);
            setunregisteredUserIsTooNew(false);
            setSnackMsg("An error occured while linking the user profiles.");
            setMsgOpen(true);
        }
    };

    async function checkIfUnregisteredClientUserWithEmailAddressAlreadyExists(
        emailAddress
    ) {
        const loggedInUser = await getUserFromCache();
        async function checkUserEmailWithRole(role, email, loggedInUser) {
            const roleUser = await graphql(
                graphqlOperation(userByCompany, {
                    companyId: loggedInUser.company.id,
                    roleEmailaddress: {
                        beginsWith: {
                            role: role,
                            emailaddress: email
                        }
                    },
                    filter: {
                        deleted: { ne: true },
                        registered: { eq: false }
                    },
                    limit: process.env.REACT_APP_LISTLIMIT
                        ? process.env.REACT_APP_LISTLIMIT
                        : 3000 //limit paramerter is acually unuseful as the complete sort key has been provided.
                })
            );
            return roleUser;
        }
        let clientUser = await checkUserEmailWithRole(
            USERROLE.CLIENT,
            emailAddress,
            loggedInUser
        );
        if (clientUser && clientUser.data.userByCompany.items.length) {
            return {
                userExists: true,
                existingClientUser: clientUser.data.userByCompany.items[0]
            };
        } else {
            return {
                userExists: false
            };
        }
    }

    function isUserTooNewTobeInvited(unregisteredUser) {
        let createdAtMilis = new Date(unregisteredUser.createdAt).getTime();
        console.log("sixhours", Date.now(), createdAtMilis);
        if (Date.now() - createdAtMilis > sixhours) return false;
        else return true;
    }

    const _handleAddUser = async () => {
        setShowSpinner(true);
        setAddUserClicked(true);
        console.log("asdf adding user");
        if (!emailNotRegistered && !doValidateAddUserData()) {
            setShowSpinner(false);
            setAddUserClicked(false);
            return;
        }
        console.log("asdf email not registered, data is valid.");

        let { userExists, existingClientUser } =
            await checkIfUnregisteredClientUserWithEmailAddressAlreadyExists(
                emailaddress
            );
        if (userExists) {
            if (isGuestOnlyCheckout) {
                setSnackMsg("User already exists.");
                setMsgOpen(true);
                setShowSpinner(false);
                setAddUserClicked(false);
                return;
            } else {
                console.log("asdf user exists");
                await handleRegisterClient(existingClientUser);
                setShowSpinner(false);
                return;
            }
        }
        console.log("asdf user does not exist already");
        try {
            if (isGuestOnlyCheckout && getUsersGroup() === "Client") {
                await addUserToDb(uuid(), null);
                let cleanPhone = phone_number.replace(/\s/g, ""); // strip spaces
                cleanPhone = validator.blacklist(cleanPhone, "()-");
                /* Zapier Trigger - New Client (new-client-zapier)*/
                try {
                    if (getUsersGroup() === "Client") {
                        const loggedInUser = await getUserFromCache();
                        console.log(
                            "this is the logged in user companyID",
                            loggedInUser.companyId
                        );
                        const date = new Date();
                        const dateString = date.toLocaleString("en-US");
                        let clientData = {
                            email: emailaddress,
                            userType: "Client",
                            phoneNumber: cleanPhone,
                            createdAt: dateString,
                            firstName: firstname,
                            lastName: lastname
                        };
                        let hookName = "new-client-zapier";
                        console.log("this is the client data", clientData);
                        await TriggerManager(
                            clientData,
                            loggedInUser.companyId,
                            hookName
                        );
                    }
                } catch (e) {
                    console.log(
                        "ERROR: unable to perform trigger manager GUEST_ONLY company",
                        e
                    );
                }
                setAddUserDialog(false);
                setSnackMsg("User has been created successfully.");
                setMsgOpen(true);
                // refresh user list
                loadUsers();
            } else {
                const temppas = genpass();
                let cleanPhone = phone_number.replace(/\s/g, ""); // strip spaces
                cleanPhone = validator.blacklist(cleanPhone, "()-");
                console.log("asdf done general variables");
                console.log();

                const result = await postApi("changeuserrole", "/createuser", {
                    body: {
                        username: emailaddress,
                        email: emailaddress,
                        group: getUsersGroup(),
                        phone_number: cleanPhone,
                        temppas,
                        firstname,
                        lastname
                    }
                });
                console.log("asdf changeuserrole result", result);

                if (result && !result.error) {
                    console.log("createuser result: " + result);

                    let newProviderId = null;
                    if (
                        userTypeIsProvider() &&
                        providerWithEmaillAddressDoesNotExist
                    ) {
                        newProviderId = await createProvider();
                    }

                    // Add user to the User table
                    await addUserToDb(result.newUserCognitoID, newProviderId);

                    /* Zapier Trigger - New Client (new-client-zapier)*/
                    try {
                        if (getUsersGroup() === "Client") {
                            const loggedInUser = await getUserFromCache();
                            console.log(
                                "this is the logged in user companyID",
                                loggedInUser.companyId
                            );
                            const date = new Date();
                            const dateString = date.toLocaleString("en-US");
                            let clientData = {
                                email: emailaddress,
                                userType: "Client", //userType === 1 ? "Client" : "Provider",
                                phoneNumber: cleanPhone,
                                createdAt: dateString,
                                firstName: firstname,
                                lastName: lastname
                            };
                            let hookName = "new-client-zapier";
                            console.log("this is the client data", clientData);
                            await TriggerManager(
                                clientData,
                                loggedInUser.companyId,
                                hookName
                            );
                        }
                    } catch (e) {
                        console.log(
                            "ERROR: unable to perform trigger manager",
                            e
                        );
                    }

                    // Email to the user and the compnayAdmin
                    try {
                        await sendRegistrationEmail(temppas);
                    } catch (e) {
                        setShowSpinner(false);
                        Sentry.captureException(e);
                        console.log(
                            "asdf Registration Email Error = " +
                                JSON.stringify(e)
                        );
                        setSnackMsg("Error sending registration email");
                        setMsgOpen(true);
                    }
                    setAddUserDialog(false);
                    setSnackMsg("User has been created successfully.");
                    setMsgOpen(true);
                    // refresh user list
                    loadUsers();
                } else {
                    console.log("asdf user changerole error");
                    setAddUserDialog(false);
                    setSnackMsg(result.error.message);
                    setShowSpinner(false);
                    setMsgOpen(true);
                }
            }
        } catch (e) {
            Sentry.captureException(e);
            setAddUserClicked(false);
            setShowSpinner(false);
            setSnackMsg("An unexpected error occured while creating the user.");
            setMsgOpen(true);
        }
        setShowSpinner(false);
    };

    function getUsersGroup() {
        if (userType === 1) return "Client";
        if (userType === 2) return "Provider";
        if (userType === 3) return "CompanyAdmin";
        if (userType === 4) return "CompanyAdminProvider";
        return "Client";
    }
    function userTypeIsProvider() {
        if (userType === 2 || userType === 4) return true;
        else return false;
    }
    function getUsersRoleByUserType() {
        if (userType === 1) return "CLIENT";
        if (userType === 2) return "PROVIDER";
        if (userType === 3) return "COMPANY_ADMIN";
        if (userType === 4) return "COMPANY_ADMIN_PROVIDER";
        return "CLIENT";
    }
    function getUserTypeByRole(role) {
        if (role === "CLIENT") return 1;
        if (role === "PROVIDER") return 2;
        if (role === "COMPANY_ADMIN") return 3;
        if (role === "COMPANY_ADMIN_PROVIDER") return 4;
        return 1;
    }
    function getUserTypeNameByNumber(userTypeNum) {
        if (userTypeNum === 1) return "Client";
        if (userTypeNum === 2) return "Provider";
        if (userTypeNum === 3) return "CompanyAdmin";
        if (userType === 4) return "Company Admin Provider";
        return "Client";
    }
    function userTypeisProviderOnly(userTypeNum) {
        if (2 === userTypeNum) return true;
        return false;
    }

    function userTypeisAdmin(userTypeNum) {
        if (3 === userTypeNum || 4 === userTypeNum) return true;
        return false;
    }

    async function createProvider(user) {
        let _firstname = user ? user.firstname : firstname;
        let _lastname = user ? user.lastname : lastname;
        let _emailaddress = user ? user.emailaddress : emailaddress;
        let _phone = user ? user.cleanPhoneNumber : phone_number;
        let _active = user ? user.active : active;
        let _deleted = user ? user.deleted : deleted;

        const input = {
            firstname: _firstname,
            lastname: _lastname,
            emailaddress: _emailaddress,
            phone: _phone,
            active: _active,
            deleted: _deleted,
            providerCompanyId: company.id,
            companyId: company.id,
            createdAt: new Date()
        };
        let newProvider = await graphql(
            graphqlOperation(mutations.createProvider, { input })
        );

        return newProvider.data.createProvider.id;
    }

    async function sendRegistrationEmailToCompanyAdmin(temppas, user) {
        const adminAddresses = await getCompanyAdminEmailAddresses();
        adminAddresses.push(currentUser.emailaddress);
        // if user is passed in, import
        let _usertype = user
            ? user.usertype === "CLIENT"
                ? 1
                : user.usertype === "PROVIDER"
                  ? 2
                  : null
            : userType;
        let _firstname = user ? user.firstname : firstname;
        let _lastname = user ? user.lastname : lastname;
        let _email = user ? user.emailaddress : emailaddress;
        let _phoneNumber = user ? user.cleanPhoneNumber : phone_number;
        let _phoneNumberType = user ? user.phonetypeNum : phoneNumberType;

        const _subdomain =
            company.maskeddomain && company.maskeddomain.length
                ? company.maskeddomain
                : company.subdomain;

        const clientActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Orders</b> to view your Orders</li>
            <li><b>My Bookings</b> to view your Bookings</li>
            <li><b>My Profile</b> to update your Profile</li>
            </ol>
            Please visit https://${_subdomain} to book an appointment.<br><br>`;
        const providerActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Profile</b> to set up your Provider Profile, including your Bio and Photo</li>
            <li><b>My Locations</b> to set up one or more Provider Locations</li>
            <li><b>My Schedules</b> to add one or more Schedules</li>
            <li><b>My Blocked Times</b> to manage your Blocked Time</li>
            <li><b>My Bookings</b> to view your bookings</li>
            </ol>
            Clients visit https://${_subdomain} to book an appointment.<br><br>`;
        const companyAdminActions = `You can then use this site to administer your MarketBox site.<br><br>
            Clients visit https://${_subdomain} to book an appointment.<br><br>`;

        const dataObj = {
            user_firstname: _firstname,
            user_lastname: _lastname,
            user_email: _email,
            user_phone: _phoneNumber,
            user_phone_type:
                _phoneNumberType === 1
                    ? "mobile"
                    : _phoneNumberType === 2
                      ? "work"
                      : "home",
            user_temppas: temppas,
            user_actions: userTypeisAdmin(_usertype)
                ? companyAdminActions
                : userTypeisProviderOnly(_usertype)
                  ? providerActions
                  : clientActions,
            user_role: getUserTypeNameByNumber(_usertype),
            company_email: company.emailaddress,
            company_name: company.name,
            isRegisteredUser: true
        };

        let _templateName;
        _templateName = userTypeisProviderOnly(_usertype)
            ? "welcome-email-provider.html"
            : "welcome-email.html";
        try {
            if (sendEmail)
                await postApi("sendtwilioemail", "/sendtwilioemailtemplate", {
                    body: {
                        templateName: _templateName,
                        subject: `${firstname}, you've been invited to join ${company.name} on MarketBox!`,
                        body: null,
                        toAddresses: adminAddresses,
                        ccAddresses: [],
                        bccAddresses: [],
                        replyTo: company.replyemailaddress,
                        companyName: company.name,
                        companyId: company.id,
                        dataObj: dataObj,
                        companyLogoUrl: company.logoUrl,
                        companyColor: company.primaryColor,
                        authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                    }
                });
        } catch (e) {
            console.log("error while sending registration email");
        }
    }

    async function sendPasswordManagementEmail(temppas) {
        const adminAddresses = await getCompanyAdminEmailAddresses();
        adminAddresses.push(currentUser.emailaddress);
        let user = userForPassworManagement;
        let _usertype = getUserTypeByRole(user.role);
        // user.role === "CLIENT" ? 1 : user.role === "PROVIDER" ? 2 : 3;

        const _subdomain =
            company.maskeddomain && company.maskeddomain.length
                ? company.maskeddomain
                : company.subdomain;

        const clientActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Orders</b> to view your Orders</li>
            <li><b>My Bookings</b> to view your Bookings</li>
            <li><b>My Profile</b> to update your Profile</li>
            </ol>
            Please visit https://${_subdomain} to book an appointment.<br><br>`;
        const providerActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Profile</b> to set up your Provider Profile, including your Bio and Photo</li>
            <li><b>My Locations</b> to set up one or more Provider Locations</li>
            <li><b>My Schedules</b> to add one or more Schedules</li>
            <li><b>My Blocked Times</b> to manage your Blocked Time</li>
            <li><b>My Bookings</b> to view your bookings</li>
            </ol>
            Clients visit https://${_subdomain} to book an appointment.<br><br>`;
        const companyAdminActions = `You can then use this site to administer your MarketBox site.<br><br>
            Clients visit https://${_subdomain} to book an appointment.<br><br>`;

        const dataObj = {
            user_firstname: user.firstname,
            user_lastname: user.lastname,
            user_email: user.emailaddress,
            user_temppas: temppas,
            user_actions: userTypeisAdmin(_usertype)
                ? companyAdminActions
                : userTypeisProviderOnly(_usertype)
                  ? providerActions
                  : clientActions,
            user_role: getUsersRoleByUserType(_usertype),
            company_email: company.emailaddress,
            company_name: company.name,
            isRegisteredUser: true
        };

        let _templateName;
        _templateName =
            passwordManagementType === "RESET"
                ? "reset-password.html"
                : "resend-temp-password.html";

        await postApi("sendtwilioemail", "/sendtwilioemailtemplate", {
            body: {
                templateName: _templateName,
                subject:
                    passwordManagementType === "RESET"
                        ? `Password reset request for ${company.name} on MarketBox`
                        : `${user.firstname} ${user.lastname} - Temporary password request for ${company.name} on MarketBox`,
                body: null,
                toAddresses: [user.emailaddress],
                ccAddresses: [],
                bccAddresses:
                    passwordManagementType === "RESET" ? [] : adminAddresses, //cc to admins only for temp pass
                replyTo: company.replyemailaddress
                    ? company.replyemailaddress
                    : company.emailaddress,
                companyName: company.name,
                companyId: company.id,
                dataObj: dataObj,
                companyLogoUrl: company.logoUrl,
                companyColor: company.primaryColor,
                authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
            }
        });
    }

    // Relevant for - Adding Users by CSV, Adding User, Registering User made on Public Site
    async function sendRegistrationEmail(temppas, user) {
        console.log("asdf sending registration email");
        let bccAddresses;
        if (company.BccLists) {
            let bccLists = JSON.parse(company.BccLists);
            console.log("asdf bccLists", bccLists);
            if (bccLists && bccLists.forWelcome) {
                bccAddresses = bccLists.forWelcome;
            }
        }
        console.log("asdf done getting bccAddresses", bccAddresses);

        // const bccAddresses = await getCompanyAdminEmailAddresses();
        // bccAddresses.push(currentUser.emailaddress);
        // if user is passed in, import
        let _usertype = user
            ? user.usertype === "CLIENT"
                ? 1
                : user.usertype === "PROVIDER"
                  ? 2
                  : null
            : userType;
        let _firstname = user ? user.firstname : firstname;
        let _lastname = user ? user.lastname : lastname;
        let _email = user ? user.emailaddress : emailaddress;
        let _phoneNumber = user ? user.cleanPhoneNumber : phone_number;
        let _phoneNumberType = user ? user.phonetypeNum : phoneNumberType;

        const _subdomain =
            company.maskeddomain && company.maskeddomain.length
                ? company.maskeddomain
                : company.subdomain;

        const clientActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Orders</b> to view your Orders</li>
            <li><b>My Bookings</b> to view your Bookings</li>
            <li><b>My Profile</b> to update your Profile</li>
            </ol>
            Please visit https://${_subdomain} to book an appointment.<br><br>`;
        const providerActions = `You can then use this site’s links in the Left NavBar as follows:
            <ol>
            <li><b>My Profile</b> to set up your Provider Profile, including your Bio and Photo</li>
            <li><b>My Locations</b> to set up one or more Provider Locations</li>
            <li><b>My Schedules</b> to add one or more Schedules</li>
            <li><b>My Blocked Times</b> to manage your Blocked Time</li>
            <li><b>My Bookings</b> to view your bookings</li>
            </ol>
            Clients visit https://${_subdomain} to book an appointment.<br><br>`;
        const companyAdminActions = `You can then use this site to administer your MarketBox site.<br><br>
            Clients visit https://${_subdomain} to book an appointment.<br><br>`;

        const dataObj = {
            user_firstname: _firstname,
            user_lastname: _lastname,
            user_email: _email,
            user_phone: _phoneNumber,
            user_phone_type:
                _phoneNumberType === 1
                    ? "mobile"
                    : _phoneNumberType === 2
                      ? "work"
                      : "home",
            user_temppas: temppas,
            user_actions: userTypeisAdmin(_usertype)
                ? companyAdminActions
                : userTypeisProviderOnly(_usertype)
                  ? providerActions
                  : clientActions,
            user_role: getUsersRoleByUserType(_usertype),
            company_email: company.emailaddress,
            company_name: company.name,
            isRegisteredUser: true
        };
        console.log("asdf done making dataObj");

        let _templateName;
        _templateName = userTypeisProviderOnly(_usertype)
            ? "welcome-email-provider.html"
            : "welcome-email.html";
        try {
            if (sendEmail) {
                const result = await postApi(
                    "sendtwilioemail",
                    "/sendtwilioemailtemplate",
                    {
                        body: {
                            templateName: _templateName,
                            subject: `${_firstname}, you've been invited to join ${company.name} on MarketBox!`,
                            body: null,
                            toAddresses: [_email],
                            ccAddresses: [],
                            bccAddresses: bccAddresses,
                            replyTo: company.replyemailaddress
                                ? company.replyemailaddress
                                : company.emailaddress,
                            companyName: company.name,
                            companyId: company.id,
                            dataObj: dataObj,
                            companyLogoUrl: company.logoUrl,
                            companyColor: company.primaryColor,
                            authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                        }
                    }
                );
                console.log("asdf result", result);
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log("asdf error while sending registration email", e);
        }
    }

    async function getCompanyAdminEmailAddresses() {
        const companyAdmins = await getCompanyAdmins();
        let companyAdminEmails = [];
        if (companyAdmins && companyAdmins.length >= 1) {
            companyAdminEmails = companyAdmins.map((ca) => ca.emailaddress);
        }
        return companyAdminEmails;
    }

    function getUserPhoneFields(type, num) {
        // if type and num are passed in, import
        let cleanPhone = phone_number.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-");
        cleanPhone = num ? num : cleanPhone;
        let phoneType = type ? type : phoneNumberType; // phoneNumberType state has a default of 1, so check for passed in value first

        if (phoneType === 1) {
            return {
                mobilephone: cleanPhone,
                phonepref: "MOBILE"
            };
        }
        if (phoneType === 2) {
            return {
                workphone: cleanPhone,
                phonepref: "WORK"
            };
        }
        if (phoneType === 3) {
            return {
                homephone: cleanPhone,
                phonepref: "HOME"
            };
        }
        return {};
    }
    async function addUserToDb(newUserCognitoID, newProviderId) {
        //newUserCognitoID or uuid() when client type GUEST_ONLY company
        const loggedInUser = await getUserFromCache();
        let useThisProviderId = null;
        if (newProviderId) useThisProviderId = newProviderId;
        else useThisProviderId = providerId;
        const role = getUsersRoleByUserType();
        let registered = true;
        if (isGuestOnlyCheckout && role === "CLIENT") {
            registered = false;
        }
        let registerUserInput = {
            id: newUserCognitoID,
            username: newUserCognitoID,
            emailaddress,
            firstname,
            lastname,
            registered,
            active: true,
            contactconsent: true,
            contactconsentdatetime: new Date().toISOString(),
            role: role,
            ...(userTypeIsProvider() && useThisProviderId
                ? {
                      userProviderId: useThisProviderId,
                      providerId: useThisProviderId
                  }
                : {}),
            companyId: currentUser.companyId,
            userCompanyId: currentUser.companyId,
            ...getUserPhoneFields()
        };
        const newUser = await graphql(
            graphqlOperation(mutations.createUser, { input: registerUserInput })
        );

        await auditUserCreate(loggedInUser, newUser.data.createUser);

        if (
            newUser &&
            newUser.data &&
            newUser.data.createUser &&
            newUser.data.createUser.role === "CLIENT"
        ) {
            let user = newUser.data.createUser;
            //add Client record
            const clientData = {
                userId: user.id,
                currency: currentUser.company.currency
                    ? currentUser.company.currency
                    : "CAD",
                companyId: currentUser.company.id,
                clientUserId: user.id,
                clientCompanyId: currentUser.company.id,
                accountbalance: 0.0
            };
            const client = await graphql(
                graphqlOperation(mutations.createClient, { input: clientData })
            );
        }
    }

    const getProvider = async (email) => {
        // check if provider already exists in database
        const filter = {
            and: [
                { deleted: { ne: true } },
                { emailaddress: { eq: email.trim().toLowerCase() } }
            ]
        };

        const result = await graphql(
            graphqlOperation(queries.providerByCompany, {
                companyId: currentUser.companyId,
                filter,
                limit
            })
        );
        if (!!result && result.data.providerByCompany.items.length > 0) {
            // if duplicate found, return provider ID
            return result.data.providerByCompany.items[0].id;
        } else {
            return null;
        }
    };

    const createUserCognito = async (user, temppas) => {
        const firstname = user.firstname;
        const lastname = user.lastname;
        let cleanPhone = user.phone.replace(/\s/g, ""); // strip spaces
        cleanPhone = validator.blacklist(cleanPhone, "()-+");
        cleanPhone = `+${cleanPhone}`;

        const result = await postApi("changeuserrole", "/createuser", {
            body: {
                username: user.emailaddress.trim(),
                email: user.emailaddress.trim(),
                group: user.usertypeCog,
                phone_number: cleanPhone,
                temppas,
                firstname,
                lastname
            }
        });
        if (!!result && !result.error) {
            console.log("create Cognito user result: ", result);
            return result.newUserCognitoID;
        } else {
            console.log(
                "create Cognito user result ERROR: ",
                result.error.message
            );
            setSnackMsg(
                "An error occured while importing. The error was: ",
                result.error.message
            );
            setMsgOpen(true);
            return null;
        }
    };

    const handleReadCSV = async (res) => {
        let usersAdded = [],
            invalidUser = false,
            missingData = false;
        try {
            logger.debug("Parsing Complete: ", res);
            if (!!res) {
                await Promise.all(
                    res.data.map(async (user) => {
                        // for each user,
                        let newProviderId = null,
                            cognitoId,
                            newUser;
                        // create a user

                        // force the user's email address to lower case
                        user.emailaddress = user.emailaddress.toLowerCase();
                        if (
                            user.usertype !== "CLIENT" &&
                            user.usertype !== "PROVIDER"
                        ) {
                            invalidUser = true;
                        } else if (
                            !user.emailaddress ||
                            !user.firstname ||
                            !user.lastname ||
                            !user.phone
                        ) {
                            missingData = true;
                        } else {
                            setShowLoading(true);
                            let phonetype =
                                user.phonetype === "MOBILE"
                                    ? 1
                                    : user.phonetype === "WORK"
                                      ? 2
                                      : user.phonetype === "HOME"
                                        ? 3
                                        : null;
                            let phoneNumber = user.phone.replace(/\s/g, "");
                            phoneNumber = validator.blacklist(
                                phoneNumber,
                                "+()-"
                            );
                            phoneNumber = `+${phoneNumber}`;

                            user = {
                                ...user,
                                phonetypeNum: phonetype, // num representation of phone type
                                cleanPhoneNumber: phoneNumber,
                                emailaddress: user.emailaddress.trim(),
                                active: user.active === "FALSE" ? false : true,
                                deleted: user.deleted === "TRUE" ? true : false,
                                sendemail:
                                    user.sendemail === "TRUE" ? true : false,
                                usertypeCog:
                                    user.usertype === "CLIENT"
                                        ? "Client"
                                        : "Provider" // for cognito
                            };

                            console.log("New user: ", user);

                            const temppas = genpass();

                            cognitoId = await createUserCognito(user, temppas); // create user role for cognito

                            if (!!cognitoId) {
                                if (user.usertype === "PROVIDER")
                                    newProviderId = await getProvider(
                                        user.emailaddress
                                    ); // see if there's an existing provider
                                if (
                                    user.usertype === "PROVIDER" &&
                                    !newProviderId
                                ) {
                                    // no match, create provider
                                    newProviderId = await createProvider(user);
                                }
                                const input = {
                                    id: cognitoId,
                                    username: cognitoId,
                                    emailaddress: user.emailaddress,
                                    firstname: user.firstname,
                                    lastname: user.lastname,
                                    active: user.active,
                                    deleted: user.deleted,
                                    registered: true,
                                    contactconsent: true,
                                    contactconsentdatetime:
                                        new Date().toISOString(),
                                    role: user.usertype,
                                    companyId: currentUser.companyId,
                                    userCompanyId: currentUser.companyId,
                                    ...getUserPhoneFields(
                                        phonetype,
                                        phoneNumber
                                    )
                                };

                                if (user.usertype === "PROVIDER") {
                                    input.userProviderId = newProviderId;
                                    input.providerId = newProviderId;
                                }
                                // add user to database
                                newUser = await graphql(
                                    graphqlOperation(mutations.createUser, {
                                        input: input
                                    })
                                );

                                if (!!newUser) usersAdded.push(newUser);
                                if (user.sendemail)
                                    //imported user - send email/text only if user.sendemail is true
                                    await sendRegistrationEmail(temppas, user);
                                else
                                    sendRegistrationEmailToCompanyAdmin(
                                        temppas,
                                        user
                                    );
                                // refresh user list
                                loadUsers();
                            }
                        }
                    })
                );

                //check if csv number of rows equals inputs users added
                if (res.data.length === usersAdded.length) {
                    let message =
                        usersAdded.length === 0 || usersAdded.length > 1
                            ? `${usersAdded.length} users were imported. You can view the imported users below.`
                            : `${usersAdded.length} user was imported. You can view the imported user below.`;
                    setSnackMsg(message);
                    setMsgOpen(true);
                } else if (!!invalidUser) {
                    setSnackMsg("Invalid user type. Unable to import user.");
                    setMsgOpen(true);
                } else if (!!missingData) {
                    setSnackMsg("Missing user data. Unable to import user.");
                    setMsgOpen(true);
                } else {
                    setSnackMsg(
                        `An error occured while importing. Unable to import users.`
                    );
                    setMsgOpen(true);
                }
            }
        } catch (err) {
            Sentry.captureException(err);
            logger.error(
                "*** an error occurred. The error was: " + err.message
            );
            setSnackMsg(
                "Unable to import users. The error was: " + err.message
            );
            setMsgOpen(true);
            setShowLoading(false);
            return;
        }
        setShowLoading(false);
    };
    const handleOnError = (err) => {
        console.log(err);
        Sentry.captureException(err);
    };

    const handleImportOffer = () => {
        setImportDialog(true);
    };

    function handleImportDialogAccept() {
        setImportDialog(false);
        fileInput.current.click();
    }

    const handlePhoneNumberType = (event) => {
        setPhoneNumberType(event.target.value);
    };
    const handleChangeFirstName = (e) => {
        setFirstName(e.target.value);
    };
    // handle last name change
    const handleChangeLastName = (e) => {
        setLastName(e.target.value);
    };
    // handle EmailAddress change
    let enteredEmail;
    let userChange = false;
    const handleChangeEmailAddress = async (e) => {
        let email = e.target.value;
        email = email.trim().toLowerCase();
        setEmailAddress(email);
        enteredEmail = email;
        userChange = true;

        handleUserType(userType);
    };

    async function getUserWithEmailAddressAlreadyExists(emailAddress) {
        const loggedInUser = currentUser;

        async function checkUserEmailWithRole(role, email) {
            const roleUser = await graphql(
                graphqlOperation(userByCompany, {
                    companyId: loggedInUser.company.id,
                    roleEmailaddress: {
                        beginsWith: {
                            role: role,
                            emailaddress: email
                        }
                    },
                    filter: { deleted: { ne: true } },
                    limit: process.env.REACT_APP_LISTLIMIT
                        ? process.env.REACT_APP_LISTLIMIT
                        : 3000 //limit paramerter is acually unuseful as the complete sort key has been provided.
                })
            );
            return roleUser;
        }
        let clientUser = await checkUserEmailWithRole(
            USERROLE.CLIENT,
            emailAddress
        );
        if (clientUser && clientUser.data.userByCompany.items.length)
            return clientUser.data.userByCompany.items[0];
        else {
            //check if provider user exists
            let providerUser = await checkUserEmailWithRole(
                USERROLE.PROVIDER,
                emailAddress
            );
            if (providerUser && providerUser.data.userByCompany.items.length)
                return providerUser.data.userByCompany.items[0];
            else {
                let companyAdminProviderUser = await checkUserEmailWithRole(
                    USERROLE.COMPANY_ADMIN_PROVIDER,
                    emailAddress
                );
                if (
                    companyAdminProviderUser &&
                    companyAdminProviderUser.data.userByCompany.items.length
                )
                    return companyAdminProviderUser.data.userByCompany.items[0];
                else {
                    let companyAdminUser = await checkUserEmailWithRole(
                        USERROLE.COMPANY_ADMIN,
                        emailAddress
                    );
                    if (
                        companyAdminUser &&
                        companyAdminUser.data.userByCompany.items.length
                    ) {
                        return companyAdminUser.data.userByCompany.items[0];
                    } else return null;
                }
            }
        }
    }

    const handleChangeEmailAddressBlur = async (e) => {
        const email = e.target.value;
        if (validator.isEmail(email)) {
            const loggedInUser = currentUser;
            const existingUser =
                await getUserWithEmailAddressAlreadyExists(email);
            console.log("existingUser by company result", email, existingUser);
            if (existingUser && existingUser.registered === false) {
                if (isGuestOnlyCheckout) {
                    setSnackMsg(
                        `There already exists a registered user with the email ${emailaddress}. Please use a different email.`
                    );
                    setMsgOpen(true);
                    setEmailAddress("");
                    return;
                }
                if (isUserTooNewTobeInvited(existingUser)) {
                    setunregisteredUserIsTooNew(true);
                    setShowUnregisteredUserIsTooNewDialog(true);
                    return;
                }
                setEmailNotRegistered(true);
                const clientData = existingUser;
                let phonetype =
                    clientData.phonepref === "MOBILE"
                        ? 1
                        : clientData.phonepref === "WORK"
                          ? 2
                          : clientData.phonepref === "HOME"
                            ? 3
                            : null;
                let cleanPhoneNumber =
                    clientData.phonepref === "MOBILE"
                        ? clientData.mobilephone
                        : clientData.phonepref === "WORK"
                          ? clientData.workphone
                          : clientData.phonepref === "HOME"
                            ? clientData.homephone
                            : null;
                setFirstName(clientData.firstname);
                setLastName(clientData.lastname);
                setPhoneNumber(cleanPhoneNumber);
                setPhoneNumberType(phonetype);
                setUserType(2);
                setRegisterUserClicked(false);
                setRegisterUserDialog(true);
                return;
            } else if (existingUser && existingUser.registered == true) {
                setSnackMsg(
                    `There already exists a registered user with the email ${emailaddress}. Please use a different email.`
                );
                setEmailAddress("");
                setEmailNotRegistered(false);
                setunregisteredUserIsTooNew(false);
                setMsgOpen(true);
            } else if (emailNotRegistered) {
                setFirstName("");
                setLastName("");
                setUserType(2);
                setProviderId("");
                setPhoneNumber("");
                setPhoneNumberType(1);
                setEmailNotRegistered(false);
                setunregisteredUserIsTooNew(false);
            } else setEmailNotRegistered(false);
        }
    };
    function DisplayCompanyAdminOption() {
        if (userHasMarketboxAdminRole()) {
            return (
                <MenuItem value={3} key={3}>
                    Company Admin
                </MenuItem>
            );
        }
    }
    function DisplayCompanyAdminProviderOption() {
        if (userHasMarketboxAdminRole()) {
            return (
                <MenuItem value={4} key={4}>
                    Company Admin Provider
                </MenuItem>
            );
        }
    }

    async function managePassword(user) {
        setShowSpinner(true);
        setShowManagePasswordDialog(true);

        try {
            const result = await getJsonApi("changeuserrole", "/getuser", {
                queryParams: {
                    username: user.username
                }
            });
            if (result && result.success) {
                console.log("User data", result);
                let userStatus = result.data.UserStatus;
                console.log("what is this", result.data);
                if (
                    userStatus === "CONFIRMED" ||
                    userStatus === "RESET_REQUIRED"
                ) {
                    //allow reset for these two statuses
                    setPasswordManagementType("RESET");
                    setUserForPasswordManagement(user);
                    // setShowManagePasswordDialog(true);
                } else if (userStatus === "FORCE_CHANGE_PASSWORD") {
                    setPasswordManagementType("SET-TEMP-PWD");
                    setUserForPasswordManagement(user);
                    // setShowManagePasswordDialog(true);
                }
            } else {
                setShowManagePasswordDialog(false);
                setSnackMsg("There was an error while getting user details.");
                setMsgOpen(true);
            }
        } catch (e) {
            setShowManagePasswordDialog(false);
            setShowSpinner(false);
            setSnackMsg("There was an error while getting user details.");
            setMsgOpen(true);
        }
        setShowSpinner(false);
    }

    async function resetPassword() {
        const loggedInUser = await getUserFromCache();
        try {
            const result = await postApi("changeuserrole", "/resetpwd", {
                body: {
                    username: userForPassworManagement.username,
                    userEmail: userForPassworManagement.emailddress
                }
            });
            console.log("result from changeuserrole = ");
            console.log(result);
            if (result && result.success) {
                try {
                    await sendPasswordManagementEmail();
                    setSnackMsg("Password was reset succesfully.");
                    setMsgOpen(true);

                    await auditUserResetPw(
                        loggedInUser,
                        userForPassworManagement,
                        passwordManagementType
                    );
                } catch (e) {
                    setSnackMsg(
                        "Password was reset succesfully. But there was an error while sending email to the user."
                    );
                    setMsgOpen(true);
                }
            } else {
                setSnackMsg("Password reset was unsuccesful.");
                setMsgOpen(true);
            }
        } catch (e) {
            Sentry.captureException(e);
            setSnackMsg("There was an error while reseting the password.");
            setMsgOpen(true);
        }
        setShowManagePasswordDialog(false);
        setUserForPasswordManagement(null);
    }

    async function resendTempPassword() {
        const loggedInUser = await getUserFromCache();
        try {
            const temppas = genpass();
            const result = await postApi("changeuserrole", "/resendpwd", {
                body: {
                    username: userForPassworManagement.username,
                    temppas
                }
            });
            console.log("result from changeuserrole = ");
            console.log(result);
            if (result && result.success) {
                try {
                    await sendPasswordManagementEmail(temppas);
                    setSnackMsg("Temporary password was set succesfully.");
                    setMsgOpen(true);

                    await auditUserResetPw(
                        loggedInUser,
                        userForPassworManagement,
                        passwordManagementType
                    );
                } catch (e) {
                    setSnackMsg(
                        "Temporary password was set succesfully. But there was an error while sending email to the user."
                    );
                    setMsgOpen(true);
                }
            } else {
                setSnackMsg(
                    "Setting temporary password reset was unsuccesful."
                );
                setMsgOpen(true);
            }
        } catch (e) {
            Sentry.captureException(e);
            setSnackMsg(
                "There was an error while setting the temporary password."
            );
            setMsgOpen(true);
        }
        setShowManagePasswordDialog(false);
        setUserForPasswordManagement(null);
    }

    function handleUserTypeChange(event) {
        setUserStatusType(event.target.value);
        //debounce(searchText);
    }

    function displayTabControls() {
        return (
            <Grid
                container
                spacing={4}
                alignItems="center"
                style={{ marginTop: 8 }}
            >
                <Grid item>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        style={{ minWidth: 120 }}
                    >
                        <InputLabel
                            id="user-status-type-label"
                            ref={inputLabel}
                            shrink={true}
                        >
                            Status
                        </InputLabel>
                        <Select
                            labelId="user-status-type-label"
                            id="user-status-type"
                            value={userStatusType}
                            onChange={(e) => handleUserTypeChange(e)}
                            label="Status"
                            margin="dense"
                            labelWidth={labelWidth}
                        >
                            <MenuItem key={4} value={4}>
                                All
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                Active
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                Inactive
                            </MenuItem>
                            <MenuItem key={3} value={3}>
                                Pending
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8} sm={8} md={4} lg={4}>
                    <TextField
                        id="search"
                        name="searchTerms"
                        value={searchText}
                        label="Search user"
                        margin="dense"
                        variant="outlined"
                        placeholder=" first name OR last name OR email address"
                        style={{ paddingTop: 5 }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="clear"
                                        onClick={() => clearSearch()}
                                        size="large"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearchChange}
                    />
                </Grid>
            </Grid>
        );
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="users"
                open={deleteDialog}
                onDecline={_handleDeleteDialogDecline}
                onConfirm={_handleDeleteDialogAccept}
                sx={{ textTransform: "capitalize" }}
            />

            {/* export modal */}

            <Dialog title={"Export"} open={showExportModal}>
                <DialogContent sx={{ padding: "1.5rem" }}>
                    <Box
                        sx={{ marginBottom: "1.5rem" }}
                        display={"flex"}
                        justifyContent={"space-between"}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontSize: "24px", fontWeight: 400 }}
                            color={"rgba(0,0,0,0.8)"}
                        >
                            Export users
                        </Typography>
                        {
                            <Close
                                onClick={closeExportModal}
                                sx={{ cursor: "pointer" }}
                            />
                        }
                    </Box>
                    <UserExport />
                </DialogContent>
            </Dialog>

            <Dialog title="import" open={importDialog}>
                <DialogTitle
                    id="add-user-dialog-title"
                    sx={{ fontSize: "24px", fontWeight: 400 }}
                >
                    Import user(s) into{" "}
                    {currentUser && currentUser.company
                        ? currentUser.company.name + "?"
                        : "your company?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This user import should only be run once. Before you
                        import any users, make sure you have already run the
                        provider import on the "Providers" page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setImportDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={handleImportDialogAccept}
                    >
                        Import
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog title="ManagePassword" open={showManagePasswordDialog}>
                <DialogTitle id="manage-password-title">
                    Manage Password
                </DialogTitle>
                <DialogContent>
                    {showSpinner && <Spinner />}

                    {"RESET" === passwordManagementType && !showSpinner && (
                        <DialogContentText id="alert-dialog-description">
                            You may reset the password of{" "}
                            {userForPassworManagement &&
                                userForPassworManagement.firstname}
                            . A confirmation code will be sent via email to{" "}
                            {userForPassworManagement &&
                                userForPassworManagement.firstname}
                            .
                        </DialogContentText>
                    )}
                    {"SET-TEMP-PWD" === passwordManagementType &&
                        !showSpinner && (
                            <DialogContentText id="alert-dialog-description">
                                A temporary password will be sent via email to{" "}
                                {userForPassworManagement &&
                                    userForPassworManagement.firstname}
                                .
                            </DialogContentText>
                        )}
                </DialogContent>
                <DialogActions>
                    {"RESET" === passwordManagementType && !showSpinner && (
                        <Button
                            onClick={async () => await resetPassword()}
                            color="primary"
                        >
                            Reset Password
                        </Button>
                    )}
                    {"SET-TEMP-PWD" === passwordManagementType &&
                        !showSpinner && (
                            <Button
                                onClick={async () => await resendTempPassword()}
                                color="primary"
                            >
                                Resend Temporary Password
                            </Button>
                        )}
                    <Button
                        onClick={() => {
                            setShowManagePasswordDialog(false);
                            setUserForPasswordManagement();
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <Dialog
                open={registerUserDialog}
                aria-labelledby="register-user-dialog-title"
                aria-describedby="register-user-dialog-description"
            >
                <DialogTitle id="register-user-dialog-title">
                    Add a new user
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="register-user-dialog-description">
                        The user you are adding already exists but does not have
                        a valid login account. If you proceed, we will create a
                        login account for this user and link them to their
                        existing client profile and orders. The client first
                        name, last name and phone are not editable. Would you
                        like to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setFirstName("");
                            setLastName("");
                            setUserType(2);
                            setEmailAddress("");
                            setProviderId("");
                            setPhoneNumber("");
                            setPhoneNumberType(1);
                            setAddUserClicked(false);
                            setRegisterUserDialog(false);
                            setRegisterUserClicked(false);
                            setEmailNotRegistered(false);
                            setunregisteredUserIsTooNew(false);
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() => {
                            setRegisterUserDialog(false);
                            setRegisterUserClicked(true);
                        }}
                        disabled={registerUserClicked}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showUnregisteredUserIsTooNewDialog}
                aria-labelledby="register-user-dialog-title"
                aria-describedby="register-user-dialog-description"
            >
                <DialogTitle id="register-user-dialog-title">
                    Add a new user
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="register-user-dialog-description">
                        This client email has recently been added using the Add
                        Client button within the booking flow. You must wait at
                        least 6 hours before inviting the user to allow them to
                        register on the system. This is to allow time to
                        complete the booking.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setFirstName("");
                            setLastName("");
                            setUserType(2);
                            setEmailAddress("");
                            setProviderId("");
                            setPhoneNumber("");
                            setPhoneNumberType(1);
                            setAddUserClicked(false);
                            setRegisterUserDialog(false);
                            setRegisterUserClicked(false);
                            setEmailNotRegistered(false);
                            setShowUnregisteredUserIsTooNewDialog(false);
                        }}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addUserDialog}
                aria-labelledby="add-user-dialog-title"
                aria-describedby="add-user-dialog-description"
            >
                <DialogTitle
                    id="add-user-dialog-title"
                    sx={{ fontSize: "24px", fontWeight: 400 }}
                >
                    Add a new user to{" "}
                    {currentUser && currentUser.company
                        ? currentUser.company.name
                        : "your company"}
                </DialogTitle>
                <DialogContent>
                    <div id="alert-dialog-description">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    required
                                    fullWidth
                                    className={classes.formControl}
                                >
                                    <TextField
                                        id="email"
                                        label="Email Address"
                                        className={classes.textField}
                                        value={emailaddress}
                                        //inputProps={{ tabIndex: "1" }}
                                        onChange={(e) => {
                                            handleChangeEmailAddress(e);
                                        }}
                                        onBlur={(e) =>
                                            handleChangeEmailAddressBlur(e)
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    className={classes.formControl}
                                    fullWidth
                                    disabled={emailNotRegistered}
                                >
                                    <InputLabel htmlFor="user-type">
                                        The user is a
                                    </InputLabel>
                                    <Select
                                        labelId="user-type"
                                        label="The user is a"
                                        value={userType}
                                        onChange={handleUserType}
                                        className={classes.formControl}
                                        inputProps={{
                                            name: "type",
                                            id: "user-type"
                                        }}
                                    >
                                        <MenuItem value={2} key={2}>
                                            Provider
                                        </MenuItem>
                                        {DisplayCompanyAdminOption()}
                                        {DisplayCompanyAdminProviderOption()}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    required
                                    fullWidth
                                    className={classes.formControl}
                                >
                                    <TextField
                                        id="firstname"
                                        label="First Name"
                                        className={classes.textField}
                                        value={firstname}
                                        disabled={
                                            (userTypeIsProvider() &&
                                                providerId) ||
                                            emailNotRegistered
                                        }
                                        onChange={handleChangeFirstName}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    required
                                    fullWidth
                                    className={classes.formControl}
                                >
                                    <TextField
                                        id="lastname"
                                        label="Last Name"
                                        className={classes.textField}
                                        value={lastname}
                                        disabled={
                                            (userTypeIsProvider() &&
                                                providerId) ||
                                            emailNotRegistered
                                        }
                                        onChange={handleChangeLastName}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <FormControl
                                    margin="normal"
                                    fullWidth
                                    required
                                    disabled={emailNotRegistered}
                                >
                                    <MuiPhoneNumber
                                        variant="outlined"
                                        id="phone_number"
                                        label="Phone number"
                                        defaultCountry={
                                            currentUser &&
                                            currentUser.company &&
                                            currentUser.company
                                                .countrycode3166alpha2
                                                ? currentUser.company
                                                      .countrycode3166alpha2
                                                : "ca"
                                        }
                                        value={phone_number}
                                        onChange={handlePhoneNumberChange}
                                        disabled={emailNotRegistered}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {userType !== 3 &&
                            !(isGuestOnlyCheckout && userType === 1) && (
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            className={classes.formControl}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={sendEmail}
                                                        onChange={(event) =>
                                                            setSendEmail(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                        value="sendEmail"
                                                        color="primary"
                                                        inputProps={{
                                                            "aria-label":
                                                                "primary checkbox"
                                                        }}
                                                    />
                                                }
                                                label={`Send email invite to new ${
                                                    userType === 1
                                                        ? `client`
                                                        : `provider`
                                                }`}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                    </div>
                    {!(isGuestOnlyCheckout && userType === 1) && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>
                                        Reminder - This dialog will send a
                                        welcome email to the new user. To view
                                        their bookings, the user must follow the
                                        instructions in the welcome email to
                                        complete their registration and log into
                                        the application.
                                    </b>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setAddUserDialog(false);
                            setRegisterUserDialog(false);
                            setEmailNotRegistered(false);
                            setunregisteredUserIsTooNew(false);
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={_handleAddUser}
                        disabled={addUserClicked}
                    >
                        Add
                        {addUserClicked && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <FormHeading id="MBUsersForm" title={"Users"} classes={classes} />

            <Grid container spacing={10} alignItems="center">
                <Grid
                    container
                    item
                    xs={12}
                    flexWrap={"wrap"}
                    gap={2}
                    justifyContent={"flex-end"}
                >
                    <Tooltip title="Reminder - A user must still register a new account by clicking the login button of the booking flow, or have a company admin invite them via the authenticated site">
                        <Button
                            sx={{ textTransform: "capitalize" }}
                            variant="contained"
                            color="primary"
                            onClick={_handleAdd}
                        >
                            Add User
                        </Button>
                    </Tooltip>

                    {currentUser && userHasAdminRole() && (
                        <Button
                            variant="contained"
                            onClick={openExportModal}
                            sx={{ textTransform: "capitalize" }}
                        >
                            Export CSV
                        </Button>
                    )}

                    <CSVReader
                        onFileLoaded={handleReadCSV}
                        inputRef={fileInput}
                        style={parserStyle}
                        onError={handleOnError}
                        configOptions={parserOptions}
                    ></CSVReader>
                    <Tooltip title="Import users">
                        <Button
                            sx={{ textTransform: "capitalize" }}
                            variant="contained"
                            color="primary"
                            onClick={handleImportOffer}
                        >
                            <CloudUploadIcon
                                style={{
                                    margin: "0.2rem",
                                    fontSize: "1.2rem"
                                }}
                            />
                            Import
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.root}>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        className={classes.tabsunderline}
                    >
                        <Tab label="Providers" />
                        {/* <Tab label="Clients" /> */}
                        <Tab label="Admins" />
                    </Tabs>
                    {tab === 0 && (
                        <>
                            {displayTabControls()}
                            <UsersTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                fetchUsers={fetchUsers}
                                listUsersData={_findPromotions(rows)}
                                allow={allow}
                                managePassword={managePassword}
                                page={page}
                                setPage={setPage}
                            />
                        </>
                    )}
                    {tab === 1 && (
                        <>
                            {displayTabControls()}
                            <UsersTable
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                                fetchUsers={fetchUsers}
                                listUsersData={_findPromotions(rows)}
                                allow={allow}
                                managePassword={managePassword}
                                page={page}
                                setPage={setPage}
                            />
                        </>
                    )}
                </div>
            </Grid>
        </>
    );
}

export default UsersForm;
