import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    Typography,
    IconButton,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Box,
    Popper
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const daysStyleFunction = (val) => {
    return {
        width: 36,
        height: 36,
        borderRadius: "50%",
        backgroundColor: val ? "primary.main" : "white",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: val ? "primary.main" : "rgb(0,0,0,0.23)",
        minWidth: 0,
        color: val ? "white" : "primary.main",
        "&:hover": {
            backgroundColor: "primary.main",
            borderColor: "primary.main",
            color: "white"
        }
    };
};

const useStyles = makeStyles((theme) => ({
    recurringBooking: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(2)
    },
    recurringBookingRow: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(1, 0)
    },
    dayButton: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: `1px solid ${theme.palette.primary.main}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin: theme.spacing(0, 1)
    },
    selectedDayButton: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
    },
    endDateContainer: {
        display: "flex",
        alignItems: "center"
    },
    selectField: {
        "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.23)"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.67)",
            borderWidth: "1px"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        },
        width: "60px",
        maxWidth: "166px",
        height: "40px"
    },
    xoccurField: {
        "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.23)"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.23)",
            borderWidth: "1px"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        },
        width: "60px",
        maxWidth: "166px",
        height: "40px"
    },
    rootGrid: {
        flexGrow: 1,
        padding: "6px"
    },
    mainGrid: {
        display: "flex",
        flexDirection: "column"
    },
    firstRow: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    secondRow: {
        display: "flex",
        flexDirection: "column"
    },
    iconButton: {
        width: 36,
        height: 36,
        borderRadius: "50%",
        backgroundColor: "white",
        border: `primary.main`,
        minWidth: 0,
        color: "primary.main",
        "&:hover": {
            backgroundColor: "primary.main",
            border: `primary.main`,
            color: "#0087ee"
        }
    },
    activeButton: {
        backgroundColor: "primary.main",
        border: `primary.main`,
        color: "primary.main"
    },
    buttonText: {
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "roboto"
    }
}));

const RecurringBookingSelector = (props) => {
    const classes = useStyles();
    const days = [
        { id: 0, title: "M" },
        { id: 1, title: "T" },
        { id: 2, title: "W" },
        { id: 3, title: "T" },
        { id: 4, title: "F" },
        { id: 5, title: "S" },
        { id: 6, title: "S" }
    ];
    const daysAbbr = [
        { id: 0, title: "MO" },
        { id: 1, title: "TU" },
        { id: 2, title: "WE" },
        { id: 3, title: "TH" },
        { id: 4, title: "FR" },
        { id: 5, title: "SA" },
        { id: 6, title: "SU" }
    ];

    const [numberSelector, setNumberSelector] = useState(
        props.numberSelector ? props.numberSelector : 1
    );
    const [chronologicalSelector, setChronologicalSelector] = useState(
        props.chronologicalSelector ? props.chronologicalSelector : "weeks"
    );
    const [selectedDays, setSelectedDays] = useState(
        props.selectedDays
            ? props.selectedDays
            : props.selectedDate
              ? [(new Date(props.selectedDate).getDay() + 6) % 7]
              : []
    );
    const [selectedMonthOption, setSelectedMonthOption] = useState(
        props.selectedMonthOption ? props.selectedMonthOption : "NUMBER"
    );
    const [endDateEnabled, setEndDateEnabled] = useState(
        props.defaultIndefinitely ? false : props.endDate ? true : false
    );
    const [endDate, setEndDate] = useState(
        props.endDate
            ? props.endDate
            : props.selectedDate
              ? props.selectedDate
              : null
    );
    const [occurrences, setOccurrences] = useState(
        props.occurrences ? props.occurrences : 1
    );
    const [rrule, setRrule] = useState(
        props.rrule
            ? props.rrule
            : `FREQ=WEEKLY;INTERVAL=1;BYDAY=${
                  daysAbbr[(new Date(props.selectedDate).getDay() + 6) % 7]
                      .title
              }`
    );
    const [endDateOption, setEndDateOption] = useState(
        props.defaultIndefinitely
            ? "indefinitely"
            : props.occurrences
              ? "after"
              : props.endDate
                ? "until"
                : "indefinitely"
    );
    const [checkedDays, setCheckedDays] = useState(new Array(7).fill(false));
    const dayOfWeek = new Date(props.selectedDate).toLocaleDateString("en-US", {
        weekday: "long"
    });
    const weekOccurence =
        Math.floor((new Date(props.selectedDate).getDate() - 1) / 7) + 1;
    const occurenceLabels = ["first", "second", "third", "fourth", "last"];
    const [fieldStyle, setFieldStyles] = useState("xoccurField");
    /* console.log("omar this is the props.selectedDate", props.selectedDate);
    console.log("omar this is the props.endDate", props.endDate); */

    //the handleMouse function below are just to control the xoccur fields focused styling
    const handleMouseEnter = () => {
        setFieldStyles("selectField");
    };
    const handleMouseLeave = () => {
        setFieldStyles("xoccurField");
    };

    useEffect(() => {
        setRrule(props.rrule);
    }, [props.rrule]);

    useEffect(() => {
        console.log("props.selectedDate changed some how", props.selectedDate);
        setSelectedDays([(new Date(props.selectedDate).getDay() + 6) % 7]);
        if (endDate < new Date(props.selectedDate)) {
            setEndDate(new Date(props.selectedDate));
        }
        generateRRULE(
            chronologicalSelector,
            numberSelector,
            chronologicalSelector == "months"
                ? selectedMonthOption
                : [(new Date(props.selectedDate).getDay() + 6) % 7],
            endDate < new Date(props.selectedDate)
                ? new Date(props.selectedDate)
                : endDate,
            endDateOption == "after" ? occurrences : null
        );
    }, [props.selectedDate]);

    const handleNumberSelectorChange = (event) => {
        const newValue = event.target.value;
        console.log(`Number Selector changed to: ${newValue}`);
        setNumberSelector(newValue);
        generateRRULE(
            chronologicalSelector,
            newValue,
            chronologicalSelector == "weeks"
                ? selectedDays
                : chronologicalSelector == "months"
                  ? selectedMonthOption
                  : "",
            endDate,
            endDateOption == "after" ? occurrences : null
        );
    };

    const handleChronologicalSelectorChange = (event) => {
        const newValue = event.target.value;
        console.log(`Chronological Selector changed to: ${newValue}`);
        setChronologicalSelector(newValue);
        generateRRULE(
            newValue,
            numberSelector,
            newValue == "weeks"
                ? selectedDays
                : newValue == "months"
                  ? selectedMonthOption
                  : "",
            endDate,
            endDateOption == "after" ? occurrences : null
        );
    };

    const handleDayButtonClick = (day) => {
        console.log(
            "omar here are selected date and day",
            props.selectedDate,
            day
        );
        if (
            selectedDays.includes(day) &&
            (new Date(props.selectedDate).getDay() + 6) % 7 !== day
        ) {
            setSelectedDays(selectedDays.filter((d) => d !== day));
            generateRRULE(
                chronologicalSelector,
                numberSelector,
                selectedDays.filter((d) => d !== day),
                endDate,
                endDateOption == "after" ? occurrences : null
            );
        } else if (!selectedDays.includes(day)) {
            setSelectedDays([...selectedDays, day]);
            generateRRULE(
                chronologicalSelector,
                numberSelector,
                [...selectedDays, day],
                endDate,
                endDateOption == "after" ? occurrences : null
            );
        }
        console.log(`Selected days: ${selectedDays}`);
    };

    const handleEndDateOptionChange = (event) => {
        const selectedOption = event.target.value;
        // Update the state based on the selected option
        setEndDateOption(selectedOption);
        //console.clear(); // Clear console for better visibility
        console.log("Selected end date option:", selectedOption);

        if (selectedOption == "until") {
            setEndDateEnabled(true);
            if (endDate) {
                generateRRULE(
                    chronologicalSelector,
                    numberSelector,
                    chronologicalSelector == "weeks"
                        ? selectedDays
                        : chronologicalSelector == "months"
                          ? selectedMonthOption
                          : "",
                    endDate,
                    null,
                    true
                );
            }
        } else if (selectedOption == "after") {
            setEndDateEnabled(true);

            if (props.setXoccurChanged) {
                props.setXoccurChanged(true);
            }
            generateRRULE(
                chronologicalSelector,
                numberSelector,
                chronologicalSelector == "weeks"
                    ? selectedDays
                    : chronologicalSelector == "months"
                      ? selectedMonthOption
                      : "",
                "2099-12-31",
                occurrences
            );
        } else {
            setEndDateEnabled(false);
            generateRRULE(
                chronologicalSelector,
                numberSelector,
                chronologicalSelector == "weeks"
                    ? selectedDays
                    : chronologicalSelector == "months"
                      ? selectedMonthOption
                      : "",
                null
            );
        }
    };

    const handleMonthOptionChange = (event) => {
        const value = event.target.value;
        setSelectedMonthOption(value);

        if (value === "NUMBER") {
            console.log("NUMBER");
        } else if (value === "WEEK") {
            console.log("WEEK");
        }
        generateRRULE(
            chronologicalSelector,
            numberSelector,
            value,
            endDate,
            endDateOption == "after" ? occurrences : null
        );
    };

    const handleEndDateToggle = () => {
        setEndDateEnabled(!endDateEnabled);
        if (!endDateEnabled) {
            setEndDate(null);
        }
        generateRRULE(
            chronologicalSelector,
            numberSelector,
            chronologicalSelector == "weeks"
                ? selectedDays
                : chronologicalSelector == "months"
                  ? selectedMonthOption
                  : "",
            null
        );
    };

    const handleEndDateChange = (date) => {
        console.log(`End Date changed to: ${date}`);

        setEndDate(date);
        generateRRULE(
            chronologicalSelector,
            numberSelector,
            chronologicalSelector == "weeks"
                ? selectedDays
                : chronologicalSelector == "months"
                  ? selectedMonthOption
                  : "",
            date
        );
    };

    const handleOccurrencesChange = (event) => {
        setOccurrences(event.target.value);
        generateRRULE(
            chronologicalSelector,
            numberSelector,
            chronologicalSelector == "weeks"
                ? selectedDays
                : chronologicalSelector == "months"
                  ? selectedMonthOption
                  : "",
            "2099-12-31",
            event.target.value
        );
        if (props.setXoccurChanged) {
            props.setXoccurChanged(true);
        }
    };

    function getSelectedDays(val) {
        let checkedDaysArray = [...checkedDays];

        checkedDaysArray[val] = checkedDaysArray[val] ? false : true;
        setCheckedDays((prevState) => checkedDaysArray);
        //props.handleChangeModified(val, props.tb, checkedDaysArray[val]);
    }

    const generateRRULE = (
        chronologicalSelector,
        numberSelector,
        selectedDays,
        endDate,
        xoccur,
        addEndDate
    ) => {
        const interval = numberSelector; // Set the interval based on numberSelector
        const dayAbbreviations = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
        let days;

        if (chronologicalSelector == "weeks") {
            // Map the selected day numbers to day abbreviations
            const selectedDayAbbreviations = selectedDays
                .sort((a, b) => a - b)
                .map((dayNumber) => dayAbbreviations[dayNumber]);
            days = selectedDayAbbreviations.join(",");
        } else if (chronologicalSelector == "months") {
            days = selectedDays;
        } else if (chronologicalSelector == "years") {
            days = selectedDays;
        }

        let formattedDate;
        if (endDate) {
            // Parse the date using Moment.js
            let dateObject = moment(endDate);

            // Extract components
            let year = dateObject.format("YYYY");
            let month = dateObject.format("MM");
            let day = dateObject.format("DD");

            // Create "YYYY-MM-DD" format
            formattedDate = `${year}-${month}-${day}`;
        }
        // Set the until date, and occurrences
        const endDateStr = xoccur
            ? `;UNTIL=2099-12-31;XOCCUR=${xoccur}`
            : (endDateEnabled || addEndDate) && endDate
              ? `;UNTIL=${formattedDate}`
              : "";

        // Set the frequency based on chronologicalSelector
        const freq =
            chronologicalSelector === "weeks"
                ? "WEEKLY"
                : chronologicalSelector === "months"
                  ? "MONTHLY"
                  : "YEARLY";

        setRrule(
            `FREQ=${freq};INTERVAL=${interval};BYDAY=${days}${endDateStr}`
        );
        if (props.setRrule) {
            props.setRrule(
                `FREQ=${freq};INTERVAL=${interval};BYDAY=${days}${endDateStr}`
            );
        }
    };

    return (
        <div>
            <div className={classes.rootGrid}>
                <Grid container spacing={2} className={classes.mainGrid}>
                    {/* First Row */}
                    <Grid item xs={4} md={12} className={classes.firstRow}>
                        <Typography>Recur every</Typography>
                        <FormControl>
                            <Select
                                id="number-selector"
                                className={classes.selectField}
                                value={numberSelector}
                                onChange={handleNumberSelectorChange}
                            >
                                {Array.from({ length: 10 }, (_, i) => (
                                    <MenuItem key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Select
                                id="chronological-selector"
                                className={classes.selectField}
                                style={{ width: "102px" }}
                                onChange={handleChronologicalSelectorChange}
                                value={chronologicalSelector}
                            >
                                <MenuItem key={"weeks"} value={"weeks"}>
                                    week{numberSelector > 1 ? "s" : ""}
                                </MenuItem>
                                <MenuItem key={"months"} value={"months"}>
                                    month{numberSelector > 1 ? "s" : ""}
                                </MenuItem>
                                <MenuItem key={"years"} value={"years"}>
                                    year{numberSelector > 1 ? "s" : ""}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Typography>on</Typography>
                    </Grid>

                    {/* Second Row */}
                    <Grid item xs={4} md={12}>
                        {/* <Typography>Repeat on</Typography> */}
                        {chronologicalSelector == "weeks" && (
                            <Grid
                                sx={{
                                    display: "flex",
                                    gap: "8px",
                                    marginTop: "12px"
                                }}
                                item
                                xs={12}
                                lg={12}
                            >
                                {days.map((day) => (
                                    <IconButton
                                        key={day.id}
                                        onClick={() =>
                                            handleDayButtonClick(day.id)
                                        }
                                        sx={daysStyleFunction(
                                            selectedDays.includes(day.id)
                                        )}
                                    >
                                        <span className={classes.buttonText}>
                                            {day.title}
                                        </span>
                                    </IconButton>
                                ))}
                            </Grid>
                        )}
                        {chronologicalSelector == "months" && (
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="month-option"
                                    name="month-option"
                                    value={selectedMonthOption}
                                    onChange={handleMonthOptionChange}
                                >
                                    <FormControlLabel
                                        value="NUMBER"
                                        control={<Radio size="small" />}
                                        label={` Day ${new Date(
                                            props.selectedDate
                                        ).getDate()} of every ${
                                            numberSelector > 1
                                                ? numberSelector
                                                : ""
                                        } ${
                                            numberSelector > 1
                                                ? "months"
                                                : "month"
                                        }`} /* ${endDate.getDate()} */
                                    />
                                    <FormControlLabel
                                        value="WEEK"
                                        control={<Radio size="small" />}
                                        label={` The ${
                                            occurenceLabels[weekOccurence - 1]
                                        } ${dayOfWeek} of every ${
                                            numberSelector > 1
                                                ? numberSelector
                                                : ""
                                        } ${
                                            numberSelector > 1
                                                ? "months"
                                                : "month"
                                        }`}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                        {chronologicalSelector == "years" && (
                            <div>
                                <i>
                                    The booking will occur on the same day each
                                    year
                                </i>
                            </div>
                        )}
                    </Grid>

                    {/* Third Row */}
                    <Grid item xs={4} md={12}>
                        {/* <Typography>Repeats</Typography> */}
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={customLocale}
                        >
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="end-date-option"
                                    name="end-date-option"
                                    value={endDateOption}
                                    onChange={handleEndDateOptionChange}
                                >
                                    <FormControlLabel
                                        value="indefinitely"
                                        control={<Radio size="small" />}
                                        label="Indefinitely"
                                        sx={{ marginBottom: "9px" }}
                                    />
                                    <FormControlLabel
                                        value="until"
                                        control={<Radio size="small" />}
                                        label={
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                            >
                                                <Grid item>Until</Grid>
                                                <Grid item>
                                                    <DesktopDatePicker
                                                        value={endDate || null}
                                                        format="MM/DD/YYYY"
                                                        minDate={
                                                            new Date(
                                                                props.selectedDate
                                                            )
                                                        }
                                                        onChange={(date) =>
                                                            handleEndDateChange(
                                                                date
                                                            )
                                                        }
                                                        disabled={
                                                            endDateOption !==
                                                            "until"
                                                        }
                                                        PopperProps={{
                                                            placement: "right",
                                                            modifiers: [
                                                                {
                                                                    name: "offset",
                                                                    options: {
                                                                        offset: [
                                                                            0,
                                                                            50
                                                                        ] // Adjust the values as needed
                                                                    }
                                                                }
                                                            ],
                                                            sx: {
                                                                "& .PrivatePickersSlideTransition-root":
                                                                    {
                                                                        minHeight:
                                                                            "216px"
                                                                    }
                                                            }
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                className={
                                                                    classes.selectField
                                                                }
                                                                size="small"
                                                                variant="outlined"
                                                                style={{
                                                                    width: "154px",
                                                                    height: "40px"
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    />

                                    <FormControlLabel
                                        value="after"
                                        control={<Radio size="small" />}
                                        label={
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                            >
                                                <Grid item>For a total of</Grid>
                                                <Grid item>
                                                    <Select
                                                        value={occurrences}
                                                        className={
                                                            classes[fieldStyle]
                                                        }
                                                        onChange={
                                                            handleOccurrencesChange
                                                        }
                                                        disabled={
                                                            endDateOption !==
                                                            "after"
                                                        }
                                                        style={{
                                                            width: "66px",
                                                            height: "40px"
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 320 // Set your desired max height
                                                                }
                                                            }
                                                        }}
                                                        onMouseEnter={
                                                            handleMouseEnter
                                                        }
                                                        onMouseLeave={
                                                            handleMouseLeave
                                                        }
                                                    >
                                                        {Array.from(
                                                            { length: 99 },
                                                            (_, i) => (
                                                                <MenuItem
                                                                    key={i + 1}
                                                                    value={
                                                                        i + 1
                                                                    }
                                                                >
                                                                    {i + 1}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </Grid>
                                                <Grid item>sessions</Grid>
                                            </Grid>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default RecurringBookingSelector;
