import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import {
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    IconButton,
    Table,
    Checkbox,
    TableBody,
    TableFooter,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    Snackbar
} from "@mui/material";
import { DataTableWrapper } from "../utils/CommonComonents/DataTableWrapper";
import { AdditionalChargesColumns } from "../utils/Constants";
import { useStyles } from "../styles/MainContainerStyles";
import { StoreContext } from "../context/StoreContext";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
//{classes, page, rowsPerPage, columns, rows, multiSelectable, selected, selectAll, handleSelectAllClick, handleRowClick, handleEditClick, handleViewClick}) => {

const useStylesPageination = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));
function TablePaginationActions(props) {
    const classes = useStylesPageination();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
export const AdditionalChargesTable = (props) => {
    const { actions } = useContext(StoreContext);
    const classes = useStyles();
    const [AdditionalCharges, setAdditionalCharges] = React.useState("asc");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [receipt, setReceipt] = React.useState();
    const [receiptModal, setReceiptModal] = React.useState(false);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState(false);
    const { rows } = props;

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }
    const _handleEditClick = (id) => {
        actions.setMode("Edit");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleViewClick = (id) => {
        actions.setMode("View");
        actions.setPage("ProviderScheduleAddEditForm");
        actions.setId(id);
    };

    const _handleTimeClick = (id, location) => {
        actions.setPage("TimeBlockForm");
        actions.setId(id);
        localStorage.setItem("timeBlockLocation", location);
    };

    const _handleReceipt = (item) => {
        if (!!item.order && item.order.orderReceipt) {
            setReceiptModal(true);
            setReceipt(item.order.orderReceipt);
        } else {
            setReceipt("");
            setReceiptModal(false);
            setSnackMsg(
                "There is no receipt for the charge you have selected."
            );
            setMsgOpen(true);
        }
    };

    function sortByDate(date1, date2) {
        const firstDate = date1.updatedAt;
        const secondDate = date2.updatedAt;

        let comparison = 0;
        if (firstDate > secondDate) {
            comparison = 1;
        } else if (firstDate < secondDate) {
            comparison = -1;
        }
        return comparison * -1;
    }

    rows.sort(sortByDate);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={receiptModal}
                onClose={() => setReceiptModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // classes={{ paper: classes.dialogPaper }}
                // scroll={"paper"}
                fullScreen
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    Additional Charges Receipt
                </DialogTitle>
                <DialogContentText id="alert-dialog-description">
                    <div dangerouslySetInnerHTML={{ __html: receipt }}></div>
                </DialogContentText>
                <DialogActions>
                    <Button
                        onClick={() => setReceiptModal(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    {/* <Button onClick={onConfirm} color="primary" autoFocus>
              Continue
          </Button> */}
                </DialogActions>
            </Dialog>
            <div>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <DataTableWrapper
                            classes={classes}
                            columns={AdditionalChargesColumns}
                            rows={rows}
                            multiSelectable={true}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={selected}
                            selectAll={selectAll}
                            handleEditClick={_handleEditClick}
                            handleRowClick={() => {
                                return false;
                            }}
                            handleSelectAllClick={() => {
                                return false;
                            }}
                            handleViewClick={_handleViewClick}
                            handleTimeClick={_handleTimeClick}
                            handleReceipt={_handleReceipt}
                            showTime={false}
                            showAction={false}
                            showSelect={false}
                            showReceipt={true}
                        />
                    </div>
                    <TableFooter>
                        <TableRow style={{ float: "right" }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "Rows per page"
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Paper>
            </div>
        </>
    );
};
