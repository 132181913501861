import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import {
    Snackbar,
    Paper,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Button,
    Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Create";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../styles/MainContainerStyles";
import { serviceStyles } from "../styles/ServiceFormStyles";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { Spinner } from "../utils/CommonComponents/Spinner";
import { auditCategoryDelete } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { serviceTypeCategoryByCompany } from "../queries/CustomQueries";
import { getUserFromCache } from "../user/UserCommon";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { useNavigate } from "react-router-dom";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

function ManageServiceCategoryForm(props) {
    const { state, dispatch, actions } = useContext(StoreContext);
    const navigate = useNavigate();

    const classes = useStyles();
    const serviceClasses = serviceStyles();
    const [categoryTypeList, setCategoryTypeList] = useState([]);
    // const [servicesList, setServicesList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("categoriesTable")
    );
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const logger = new ConsoleLogger("ServiceForm");
    const [user, setUser] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const limit = process.env.REACT_APP_LISTLIMIT;

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("categoriesTable")) {
            setTableRowsSession("categoriesTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    useEffect(() => {
        console.log("in initial useffect");
        // store user
        setUser(getUserFromCache());

        const loadCategoryTypes = async () => {
            // get user data from cache
            _getCategoryType();
        };

        loadCategoryTypes();
    }, []);

    React.useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth);
        if (state.mode === "Edit" || state.mode === "View") {
            _getCategoryType();
        }
    }, []);

    const _getCategoryType = async () => {
        const authuser = getUserFromCache();
        const categoryResult = await execReadByPK({
            opname: "serviceTypeCategoryByCompany",
            op: serviceTypeCategoryByCompany,
            id: { companyId: authuser.company.id },
            filter: {
                deleted: { ne: true }
            },
            sortDirection: "DESC"
        });

        console.log(
            "_getCategoryType result = " + JSON.stringify(categoryResult)
        );
        // check to see if any items exist in the query
        if (categoryResult && categoryResult.items) {
            setCategoryTypeList(categoryResult.items);
        }
    };

    function handleCategoryAddClick() {
        actions.setMode("Add");
        navigate("create");
    }
    function handleEditClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("Edit");
        navigate("edit");
        actions.setId(rowId);
    }
    function handleViewClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("View");
        actions.setPage("CategoryAddEditForm");
        actions.setId(rowId);
    }

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    async function _handleDeleteDialogAccept() {
        setDeleteDialog(false);
        let deleteSuccess = true;
        await deleteCategories(selected).then(
            (value) => (deleteSuccess = value)
        );
        console.log("Deletion was successful:", deleteSuccess);
        if (deleteSuccess) {
            setSnackMsg(
                "The selected categories have been successfully deleted."
            );
            setMsgOpen(true);
        } else {
            setSnackMsg(
                "You cannot delete the selected categories because there are services currently assigned to these categories. You will need to assign these services to a new category before deleting these categories."
            );
            setMsgOpen(true);
        }
        // clear table... cannot do refresh right away or we will get stale data ()
        const empty = [];
        setSelected(empty);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = categoryTypeList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }

    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }
    async function ensureDeleteCategorysArePermitted(categoryIds) {
        const authuser = getUserFromCache();
        async function ensureDeleteIsPermitted(categoryId) {
            // we check to see that there are no existing services under the categoryId
            const serviceResult = await graphql(
                graphqlOperation(queries.serviceTypeByCompany, {
                    companyId: authuser.company.id,
                    filter: {
                        deleted: { ne: true },
                        isVisible: { ne: false },
                        categoryId: { eq: categoryId }
                    },
                    limit: process.env.REACT_APP_LISTLIMIT
                })
            );
            if (!!serviceResult && !!serviceResult.data.serviceTypeByCompany) {
                console.log(
                    "serviceResult here",
                    JSON.stringify(serviceResult)
                );
                if (
                    serviceResult.data.serviceTypeByCompany.items.filter(
                        (item) => item.deleted !== true
                    ).length != 0
                ) {
                    console.log("Failed Pass");
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        }
        for (let i = 0; i < categoryIds.length; i++) {
            let id = categoryIds[i];
            let permittedCategory = true;
            await ensureDeleteIsPermitted(id).then(function (result) {
                permittedCategory = result;
            });
            if (!permittedCategory) {
                return false;
            }
        }
        return true;
    }
    async function deleteCategories(categoryIds) {
        // first check to see if we can delete the selected categories
        console.log("categoryIDs:", categoryIds);
        let permitted = true;
        await ensureDeleteCategorysArePermitted(categoryIds).then(
            function (result) {
                permitted = result;
            }
        );
        // if we are not allowed, then return false, else delete the selected cateogories
        // and return true
        if (!permitted) {
            return false;
        } else {
            categoryIds.map((id) => deleteGraphQLCategory(id));
            return true;
        }
        async function deleteGraphQLCategory(categoryId) {
            const category = categoryTypeList.filter(
                (s) => s.id === categoryId
            );
            // this if condition should technically never pass.
            if (!category || category.length === 0) {
                setSnackMsg("We are not able to find this category.");
                setMsgOpen(true);
            } else {
                const authuser = getUserFromCache();
                const input = {
                    id: category[0].id,
                    deleted: true,
                    name: category[0].name
                };

                const updateService = await graphql(
                    graphqlOperation(mutations.updateServiceTypeCategory, {
                        input
                    })
                );

                await auditCategoryDelete(
                    authuser,
                    updateService.data.updateServiceTypeCategory
                );

                if (
                    updateService &&
                    updateService.data &&
                    updateService.data.updateServiceTypeCategory
                ) {
                    _getCategoryType();
                }
            }
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={10000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            {showLoading && <Spinner />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DeleteDialog
                        title="service categories"
                        open={deleteDialog}
                        onDecline={_handleDeleteDialogDecline}
                        onConfirm={_handleDeleteDialogAccept}
                    />
                    <Paper
                        rounded="true"
                        className={classes.root}
                        className={serviceClasses.paper}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCategoryAddClick}
                                >
                                    &nbsp;Add Category
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ textAlign: "right" }}
                                    onClick={() => {
                                        navigate("..")
                                    }}
                                >
                                    &nbsp;RETURN TO SERVICES
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <EnhancedToolbar
                                    numSelected={selected.length}
                                    handleDelete={() => setDeleteDialog(true)}
                                />
                                <Table size="small" className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={selectAll}
                                                    onClick={
                                                        handleSelectAllClick
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>Category</TableCell>
                                            {/*<TableCell align='left'>Type</TableCell>*/}
                                            <TableCell align="left">
                                                Description
                                            </TableCell>
                                            <TableCell align="left">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {categoryTypeList
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    hover
                                                    onClick={(event) =>
                                                        handleRowClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    row.id
                                                                ) !== -1
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell scope="row">
                                                        {row.name
                                                            ? row.name
                                                            : ""}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.desc.length <= 40
                                                            ? row.desc
                                                            : row.desc.slice(
                                                                0,
                                                                39
                                                            ) + "..."}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip title="Edit">
                                                            <EditIcon
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleEditClick(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={6}
                                                count={
                                                    categoryTypeList.filter(
                                                        (row) =>
                                                            row.deleted !==
                                                            "true"
                                                    ).length
                                                }
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label":
                                                            "Rows per page"
                                                    },
                                                    native: true
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={
                                                    handleChangeRowsPerPage
                                                }
                                                ActionsComponent={
                                                    TablePaginationActions
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default ManageServiceCategoryForm;
