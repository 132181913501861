import React from "react";
import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";
import { signOut } from "aws-amplify/auth";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import { makeStyles } from "@mui/styles";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { mbxUserAtom, isMbxFreeTrialUser } from "../atoms/atoms";

const useStyles = makeStyles({
    customDialogButton: {
        height: "2.25rem",
        boxShadow: "none",
        minWidth: "5.625rem",
        alignItems: "center",
        lineHeight: "inherit",
        "&:hover": {
            boxShadow: "none"
        }
    },
    customDialogButtonText: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        fontSize: "0.875rem",
        fontWeight: 500,
        fontFamily: "Roboto",
        paddingTop: "0.1rem"
    }
});

const ExpiredSubscriptionModal = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [mbxUser] = useAtom(mbxUserAtom);
    const [isFreeTrial] = useAtom(isMbxFreeTrialUser);
    let companyReplyEmail = mbxUser.company?.replyemailaddress
        ? mbxUser.company?.replyemailaddress
        : mbxUser.company?.emailaddress;

    let clientBodyContent = (
        <Typography variant="body2">
            Please contact {mbxUser.company?.name} on{" "}
            <a
                href={`mailto:${companyReplyEmail}`}
                style={{ fontFamily: "Roboto" }}
            >
                {companyReplyEmail}
            </a>{" "}
            for more details
        </Typography>
    );
    let providerBodyContent = isFreeTrial ? (
        <Typography variant="body2">
            Your free trial of MarketBox has expired. Please contact your
            administrator for more details
        </Typography>
    ) : (
        <Typography variant="body2">
            Your MarketBox account is inactive. Please contact your
            administrator for more details
        </Typography>
    );
    let adminBodyContent = isFreeTrial ? (
        <Typography variant="body2">
            Your free trial of MarketBox has expired – please contact{" "}
            <a
                href={`mailto:support@gomarketbox`}
                style={{ fontFamily: "Roboto" }}
            >
                support@gomarketbox.com
            </a>{" "}
            if you have any questions or feedback, or click on the button below
            to purchase a full subscription
        </Typography>
    ) : (
        <Typography variant="body2">
            Your MarketBox account is inactive – please contact{" "}
            <a
                href={`mailto:support@gomarketbox`}
                style={{ fontFamily: "Roboto" }}
            >
                support@gomarketbox.com
            </a>{" "}
            to reinstate it
        </Typography>
    );
    const userHeadings = {
        CLIENT: {
            heading: "System can’t be reached",
            body: clientBodyContent
        },
        PROVIDER: {
            heading: isFreeTrial ? "Free trial expired" : "Inactive account",
            body: providerBodyContent
        },
        COMPANY_ADMIN_PROVIDER: {
            heading: isFreeTrial ? "Free trial expired" : "Inactive account",
            body: adminBodyContent
        }
    };
    return (
        <>
            <SmallSizedModal
                open={true}
                headingIcon={WarningTriangleIcon}
                headingText={
                    mbxUser.role && userHeadings[mbxUser.role]
                        ? userHeadings[mbxUser.role].heading
                        : userHeadings["COMPANY_ADMIN_PROVIDER"].heading
                }
            >
                <Box>
                    {mbxUser.role && userHeadings[mbxUser.role]
                        ? userHeadings[mbxUser.role].body
                        : userHeadings["COMPANY_ADMIN_PROVIDER"].body}
                </Box>

                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1.25rem"
                    }}
                >
                    <Button
                        className={classes.customDialogButton}
                        variant={isFreeTrial ? "outlined" : "contained"}
                        onClick={async () => {
                            await signOut();
                            localStorage.removeItem("appState");
                        }}
                    >
                        <div className={classes.customDialogButtonText}>
                            {isFreeTrial ? "Log out" : "OK"}
                        </div>
                    </Button>
                    {mbxUser.role === "COMPANY_ADMIN_PROVIDER" &&
                        isFreeTrial && (
                            <Button
                                className={classes.customDialogButton}
                                variant="contained"
                                onClick={() => navigate("/checkout/reactivate")}
                            >
                                <div className={classes.customDialogButtonText}>
                                    Purchase Subscription
                                </div>
                            </Button>
                        )}
                </Box>
            </SmallSizedModal>
        </>
    );
};

export default ExpiredSubscriptionModal;
