import React, { useState } from "react";
import {
    Button,
    Typography,
    TablePagination,
    IconButton,
    TextField,
    InputAdornment,
    Divider,
    Radio,
    RadioGroup,
    Menu,
    Stack
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useQuery } from "@tanstack/react-query";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DeleteDialog } from "../../utils/CommonComonents/DeleteDialog";
import { UsersTable } from "./UserTable";
import { mbxUserAtom } from "../../atoms/atoms";
import { graphqlOperation, graphql } from "../../modules/AmplifyServices";
import { usersForTable } from "../../queries/UserQueries";
import { providerScheduleByCompany } from "../../graphql/queries";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
    visibleRows,
    tableConfigAtom,
    formatUsersAtom,
    visibleRowsPaginated,
    paginationAtom,
    setPageAtom,
    setPerPageAtom,
    openUserDialogAtom,
    userPageSnackbarAtom
} from "../../atoms/usersTable";
import { useAtom } from "jotai";
import { allUsersAtom, allSchedulesAtom } from "../../atoms/atoms";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import {
    ROLE_OPTIONS,
    AVAILABILITY_OPTIONS,
    STATUS_OPTIONS
} from "../../atoms/usersTable";
import { CSVLink } from "react-csv";
import moment from "moment";
import { COLUMNS } from "./constants";
import { DialogTheme } from "../../styles/DialogTheme";
import UserProfileModal from "../../user/components/UserProfileModal";
import NotificationSnackbar from "../NotificationSnackbar";
import { Link } from "react-router-dom";

const STATUS_OPTIONS_LABELS = {
    [STATUS_OPTIONS.ALL]: "All",
    [STATUS_OPTIONS.ACTIVE]: "Active",
    [STATUS_OPTIONS.INACTIVE]: "Inactive"
};
const ROLE_OPTIONS_LABELS = {
    [ROLE_OPTIONS.ALL]: "All roles",
    [ROLE_OPTIONS.ADMINS]: "Admins",
    [ROLE_OPTIONS.PROVIDERS]: "Providers"
};
const AVAILABILITY_OPTIONS_LABELS = {
    [AVAILABILITY_OPTIONS.ALL]: "All ",
    [AVAILABILITY_OPTIONS.ACTIVE_SCHEDULE]: "Avaliable",
    [AVAILABILITY_OPTIONS.NO_ACTIVE_SCHEDULE]: "Not avaliable"
};

const tableTheme = ({ palette, breakpoints }) =>
    createTheme({
        palette,
        components: {
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: grey[100],
                        height: "56px",
                        "& :first-child.MuiTableCell-root, ": {
                            borderRadius: "4px 0 0 0"
                        },
                        "& :last-child.MuiTableCell-root, ": {
                            borderRadius: "0 4px  0 0"
                        }
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        color: grey[700],
                        padding: "10px 14px !important",
                        [breakpoints.down("lg")]: {
                            padding: "10px 0px !important"
                        }
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&.MuiTableRow-hover:hover": {
                            backgroundColor: palette.primary.light,
                            cursor: "pointer"
                        },
                        "&:last-child .MuiTableCell-root": {
                            border: "none" // this takes care of the header row, and the last row in the list
                        }
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    body1: { fontSize: "14px" }
                },
                variants: [
                    {
                        props: { variant: "condensed" },
                        style: {
                            lineHeight: 1.4
                        }
                    }
                ]
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        color: "white"
                    }
                }
            }
        }
    });

const tableConfigTheme = (palette) =>
    createTheme({
        palette,
        components: {
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        "& .MuiInputBase-root": {
                            paddingLeft: "18px !important",
                            paddingRight: "5px !important"
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        "&:hover": { borderColor: "yellow !important" }
                    },
                    input: {
                        fontSize: "14px",
                        paddingTop: "8px !important",
                        paddingBottom: "8px !important",
                        paddingLeft: "0px !important"
                    } //TODO: figuer out the css presidence here
                }
            },
            MuiTypography: { styleOverrides: { h1: { fontSize: "24px" } } },
            // Move to main theme or a "all new designs theme"
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        padding: "6px 20px"
                    }
                }
            }
        }
    });

const dropDownMenuTheme = ({ palette }) =>
    createTheme({
        palette,
        typography: {
            fontSize: 11
        },
        components: {
            MuiDivider: {
                styleOverrides: { root: { marginBottom: "12px !important" } }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        textTransform: "uppercase !important",
                        marginBottom: "2px"
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        "& .MuiButtonBase-root": {
                            padding: "1px 9px !important"
                        }
                    }
                }
            }
        }
    });
const paginationTheme = ({ palette }) =>
    createTheme({
        palette,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "4px",
                        borderColor: palette.lightGrey
                    }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        alignItems: "center",
                        "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                            {
                                color: palette.grey,
                                marginBottom: 0
                            },
                        "& .MuiTablePagination-actions": {
                            display: "flex",
                            gap: "0.5rem",
                            "& button": {
                                border: "1px solid rgba(0,0,0,0.2)",
                                borderRadius: "4px"
                            },
                            "& button:hover": {
                                backgroundColor: palette.lightGrey
                            }
                        }
                    }
                }
            }
        }
    });
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
export const UsersPage = () => {
    const [mbxUser] = useAtom(mbxUserAtom);
    const { companyId } = mbxUser;
    const [displayUsers] = useAtom(visibleRowsPaginated);
    const [allViewableUsers] = useAtom(visibleRows);
    const [, setAllUsers] = useAtom(allUsersAtom);
    const [, setAllSchedules] = useAtom(allSchedulesAtom);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [paginationConfig] = useAtom(paginationAtom);
    const [, setPerPageAction] = useAtom(setPerPageAtom);
    const [, setPageAction] = useAtom(setPageAtom);
    const [userPageSnackbar, setUserPageSnackMessage] =
        useAtom(userPageSnackbarAtom);
    const {
        open: openSnackbar = false,
        message: snackMsg = "",
        severity: snackSeverity = "success"
    } = userPageSnackbar;
    const { page, perPage } = paginationConfig;
    const [openUserDialog, setOpenUserDialog] = useAtom(openUserDialogAtom);
    const theme = useTheme();

    useQuery({
        queryKey: ["get-schedules"],
        queryFn: async () => {
            try {
                let { data, errors } = await graphql(
                    graphqlOperation(providerScheduleByCompany, {
                        companyId: companyId,
                        scheduleinfo: { beginsWith: "S" },
                        filter: {
                            deleted: { ne: true }
                        },
                        limit: 2000 //temp, we need a better solution than setting a high limit. Probably by using 'nextToken'
                    })
                );
                if (errors) throw errors;

                const {
                    providerScheduleByCompany: { items }
                } = data;

                return items;
            } catch (e) {
                console.log(e);
            }
        },
        onSuccess: (schedulesData) => {
            setAllSchedules(schedulesData);
        }
    });
    useQuery({
        queryKey: ["get-users"],
        queryFn: async () => {
            try {
                let {
                    data: {
                        userByCompany: { items }
                    }
                } = await graphql(
                    graphqlOperation(usersForTable, {
                        companyId: companyId,
                        filter: {
                            deleted: { ne: true },
                            and: {
                                or: [
                                    {
                                        role: { eq: "PROVIDER" }
                                    },
                                    {
                                        role: { eq: "COMPANY_ADMIN_PROVIDER" }
                                    }
                                ]
                            }
                        },
                        limit: 500
                    })
                );
                return items || null;
            } catch (e) {
                console.log(e);
            }
        },
        onSuccess: (userData) => {
            setAllUsers(userData);
        }
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setUserPageSnackMessage({
            open: false,
            message: snackMsg,
            severity: snackSeverity
        });
    };

    return (
        <ThemeProvider theme={tableTheme(theme)}>
            <TableHeader onOpenUserDialog={() => setOpenUserDialog(true)} />
            <UsersTable users={displayUsers} />
            <ThemeProvider theme={paginationTheme(theme)}>
                <TablePagination
                    count={allViewableUsers?.length || 0}
                    component={"div"}
                    page={page}
                    onPageChange={(e, page) => setPageAction(page)}
                    onRowsPerPageChange={({ target: { value: perPage } }) =>
                        setPerPageAction(perPage)
                    }
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    rowsPerPage={perPage}
                    labelRowsPerPage=""
                />
            </ThemeProvider>
            <DeleteDialog open={deleteDialogOpen} />
            <ThemeProvider theme={DialogTheme(theme)}>
                <UserProfileModal
                    open={openUserDialog}
                    onClose={() => setOpenUserDialog(false)}
                />
            </ThemeProvider>
            <NotificationSnackbar
                open={openSnackbar}
                severity={snackSeverity}
                message={snackMsg}
                onClose={handleCloseSnackbar}
            />
        </ThemeProvider>
    );
};

const TableHeader = ({ onOpenUserDialog }) => {
    const [tableConfig, setTableCongif] = useAtom(tableConfigAtom);
    const { searchTerm } = tableConfig;
    const [allUsers] = useAtom(formatUsersAtom);
    const themePalette = useTheme().palette;
    return (
        <ThemeProvider theme={tableConfigTheme(themePalette)}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: "15px" }}
            >
                <Typography variant="h1">
                    Users{" "}
                    <CSVLink
                        data={allUsers}
                        headers={COLUMNS}
                        filename={`Users_${moment(Date.now()).format(
                            "YYYY-MM-DD"
                        )}.csv`}
                    >
                        <SaveAltIcon color="primary" />
                    </CSVLink>
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                >
                    <Link align="center" underline="hover" to="/skills">
                        <Typography color="primary">
                            <b>Manage skills</b>
                        </Typography>
                    </Link>
                    <FiltersMenu />
                    <TextField
                        id="search"
                        sx={{ maxWidth: "200px" }}
                        value={searchTerm}
                        onChange={({ target: { value } }) =>
                            setTableCongif({
                                ...tableConfig,
                                searchTerm: value
                            })
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        aria-label="clear"
                                        onClick={() =>
                                            setTableCongif({
                                                ...tableConfig,
                                                searchTerm: ""
                                            })
                                        }
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        textTransform="uppercase"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onOpenUserDialog}
                    >
                        <b> Add User</b>
                    </Button>
                </Stack>
            </Stack>
        </ThemeProvider>
    );
};

export default function FiltersMenu() {
    const [tableConfig, setTableCongif] = useAtom(tableConfigAtom);
    const { statusFilter, roleFilter, availabilityFilter } = tableConfig;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();
    return (
        <>
            <TextField
                sx={{ maxWidth: "175px" }}
                value={"Filters"}
                onClick={handleClick}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <KeyboardArrowDownIcon />
                        </InputAdornment>
                    )
                }}
            />
            <ThemeProvider theme={dropDownMenuTheme(theme)}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    fullWidth
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                        sx: {
                            width: anchorEl && anchorEl.offsetWidth,
                            padding: "10px 15px"
                        }
                    }}
                >
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            Role
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={ROLE_OPTIONS.ALL}
                            name="radio-buttons-group"
                            value={roleFilter}
                            onChange={({ target: { value } }) =>
                                setTableCongif({
                                    ...tableConfig,
                                    roleFilter: value
                                })
                            }
                        >
                            {Object.values(ROLE_OPTIONS).map((option) => (
                                <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={<Radio />}
                                    label={ROLE_OPTIONS_LABELS[option]}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <Divider />
                    <FormControl>
                        <FormLabel
                            id="demo-radio-buttons-group-label"
                            textTransform="uppercase"
                        >
                            Availability
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={AVAILABILITY_OPTIONS.ALL}
                            name="radio-buttons-group"
                            value={availabilityFilter}
                            onChange={({ target: { value } }) =>
                                setTableCongif({
                                    ...tableConfig,
                                    availabilityFilter: value
                                })
                            }
                        >
                            {Object.values(AVAILABILITY_OPTIONS).map(
                                (option) => (
                                    <FormControlLabel
                                        key={option}
                                        value={option}
                                        control={<Radio />}
                                        label={
                                            AVAILABILITY_OPTIONS_LABELS[option]
                                        }
                                    />
                                )
                            )}
                        </RadioGroup>
                    </FormControl>
                    <Divider />
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            Active
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={STATUS_OPTIONS.ALL}
                            name="radio-buttons-group"
                            value={statusFilter}
                            onChange={({ target: { value } }) =>
                                setTableCongif({
                                    ...tableConfig,
                                    statusFilter: value
                                })
                            }
                        >
                            {Object.values(STATUS_OPTIONS).map((option) => (
                                <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={<Radio />}
                                    label={STATUS_OPTIONS_LABELS[option]}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Menu>
            </ThemeProvider>
        </>
    );
}
