import { ConsoleLogger } from "aws-amplify/utils";
import { graphql, graphqlOperation, postApi } from "../modules/AmplifyServices";
import { getCurrentUser } from "aws-amplify/auth";
import { Cache } from "aws-amplify/utils";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { userByCompany } from "../queries/UserQueries";
import { execReadByPK } from "../modules/DBService";
const logger = new ConsoleLogger("UserCommon");

const USERROLE = {
    CLIENT: "CLIENT",
    PROVIDER: "PROVIDER",
    COMPANY_ADMIN: "COMPANY_ADMIN",
    MARKETBOX_ADMIN: "MARKETBOX_ADMIN",
    COMPANY_ADMIN_PROVIDER: "COMPANY_ADMIN_PROVIDER"
};

const getCognitoRole = (role) => {
    if (role === USERROLE.CLIENT) return "Client";
    if (role === USERROLE.PROVIDER) return "Provider";
    if (role === USERROLE.COMPANY_ADMIN) return "CompanyAdmin";
    if (role === USERROLE.COMPANY_ADMIN_PROVIDER) return "CompanyAdminProvider";
    if (role === USERROLE.MARKETBOX_ADMIN) return "MarketBoxAdmin";
    return "Client";
};

const getAuthUser = async () => {
    const user = await getCurrentUser();
    return user;
};

const getUserPrimaryColor = async () => {
    let primaryColor;
    const user = await getAuthUser();
    if (user) {
        const input = {
            id: user.username
        };
        const result = await graphql(graphqlOperation(queries.getUser, input));
        if (result.data.getUser && result.data.getUser.company) {
            primaryColor = result.data.getUser.company.primaryColor;
        }
    }
    return primaryColor;
};

async function getCompanyAdmins() {
    const user = await getUserFromCache();
    if (user) {
        const companyId = user.company.id;
        const result = await graphql(
            graphqlOperation(queries.userByCompany, {
                companyId,
                roleEmailaddress: {
                    beginsWith: {
                        role: "COMPANY_ADMIN"
                    }
                },
                filter: {
                    and: [
                        { active: { ne: false } },
                        { deleted: { ne: true } },
                        { registered: { ne: false } }
                    ]
                },
                limit: process.env.REACT_APP_LISTLIMIT
            })
        );
        return result.data.userByCompany.items;
    } else return [];
}

async function handleSendEmail(
    subject,
    body,
    sendAddress,
    ccAddresses,
    replyTo,
    companyName
) {
    const result = await postApi("sendtwilioemail", "/sendtwilioemail", {
        body: {
            subject,
            body,
            toAddresses: sendAddress,
            ccAddresses,
            replyTo,
            companyName
        }
    });
    console.log("result from sendtwilioemail = ");
    console.log(result);
}

async function _handleSendEmail(
    subject,
    body,
    toAddresses,
    ccAddresses,
    bccAddresses,
    replyTo,
    companyName
) {
    const result = await postApi("sendtwilioemail", "/sendtwilioemail", {
        body: {
            subject,
            body,
            toAddresses,
            ccAddresses,
            bccAddresses,
            replyTo,
            companyName
        }
    });
}

function getUserFromCache() {
    try {
        const userstring = localStorage.getItem("loggedInMbUser");
        if (userstring) {
            const user = JSON.parse(userstring);
            return user;
        }
        return null;
    } catch (e) {
        console.log("getUserFromCache error", e);
        return null;
    }
}

function getUserRole() {
    const user = getUserFromCache();
    logger.debug(user);
    if (user && user.role) {
        if (user.role === USERROLE.CLIENT) return USERROLE.CLIENT;
        if (user.role === USERROLE.PROVIDER) return USERROLE.PROVIDER;
        if (user.role === USERROLE.COMPANY_ADMIN) return USERROLE.COMPANY_ADMIN;
        if (user.role === USERROLE.COMPANY_ADMIN_PROVIDER)
            return USERROLE.COMPANY_ADMIN_PROVIDER;
        if (user.role === USERROLE.MARKETBOX_ADMIN)
            return USERROLE.MARKETBOX_ADMIN;
    } else {
        logger.debug("*** WARNING: user.role null in UserCommon, getUserRole");
        return USERROLE.CLIENT;
    }
}

function companyIsSoleOperator() {
    const user = getUserFromCache();
    if (user && user.company) {
        return user.company.subscriptionLevel === "SOLE_OPERATOR";
    }
    return false;
}

function userHasAdminRole() {
    const user = getUserFromCache();
    if (user && user.role)
        return (
            USERROLE.MARKETBOX_ADMIN === user.role ||
            USERROLE.COMPANY_ADMIN === user.role ||
            USERROLE.COMPANY_ADMIN_PROVIDER === user.role
        );
    else return false;
}

function userHasCompanyAdminRole() {
    const user = getUserFromCache();
    if (user && user.role)
        return (
            USERROLE.COMPANY_ADMIN === user.role ||
            USERROLE.COMPANY_ADMIN_PROVIDER === user.role
        );
    else return false;
}

function userHasMarketboxAdminRole() {
    const user = getUserFromCache();
    if (user && user.role) return USERROLE.MARKETBOX_ADMIN === user.role;
    else return false;
}

function userHasProviderRole() {
    const user = getUserFromCache();
    if (user && user.role)
        return (
            USERROLE.PROVIDER === user.role ||
            USERROLE.COMPANY_ADMIN_PROVIDER === user.role
        );
    else return false;
}

function userHasProviderOnlyRole() {
    const user = getUserFromCache();
    if (user && user.role) return USERROLE.PROVIDER === user.role;
    else return false;
}

function userHasClientRole() {
    const user = getUserFromCache();
    if (user && user.role) return USERROLE.CLIENT === user.role;
    else return false;
}

function userCanCancelAppt() {
    const user = getUserFromCache();
    if (
        user &&
        user.role === USERROLE.PROVIDER &&
        user.company.providerCanCancelAppt === false
    ) {
        return false;
    } else return true;
}

async function updateCompanyObjectInCachedUser(updatedCompanyObj) {
    let cachedUser = getUserFromCache();

    if (cachedUser?.company?.id === updatedCompanyObj?.id) {
        cachedUser.company = updatedCompanyObj;
        await Cache.setItem("user", cachedUser);
        localStorage.setItem("loggedInMbUser", JSON.stringify(cachedUser));
    }
}

/**
 * Function to check if a new user's emailaddres is already being used.
 * Note, this function only checks if the email is being used within the same company
 * @param {String} emailaddress - emailaddress to check
 */
async function isUniqueEmailAddress(emailaddress) {
    const user = getUserFromCache();
    const roles = Object.values(USERROLE);
    let usersByCompany = roles.map((role) =>
        graphql(
            graphqlOperation(userByCompany, {
                companyId: user.company.id,
                roleEmailaddress: {
                    beginsWith: {
                        role: role,
                        emailaddress: emailaddress
                    }
                }
            })
        )
    );
    const results = await Promise.all(usersByCompany);

    return results.every(
        (result) =>
            !(
                result.data.userByCompany.items &&
                result.data.userByCompany.items.length > 0
            )
    );
}

/**
 * Function to update a Provider record with a userId
 * @param {*} provider - Provider object
 * @param {*} newUser - User object
 */
const updateProvider = async (provider, newUser) => {
    await graphql(
        graphqlOperation(mutations.updateProvider, {
            input: {
                id: provider.id,
                companyId: provider.companyId,
                firstname: provider.firstname,
                lastname: provider.lastname,
                createdAt: provider.createdAt,
                emailaddress: provider.emailaddress,
                userId: newUser.id,
                providerUserId: newUser.id
            }
        })
    );
};

export {
    getUserRole,
    getUserFromCache,
    companyIsSoleOperator,
    USERROLE,
    handleSendEmail,
    _handleSendEmail, //uses bcc
    getCompanyAdmins,
    userHasAdminRole,
    getUserPrimaryColor,
    userHasProviderRole,
    userHasClientRole,
    userHasMarketboxAdminRole,
    userHasCompanyAdminRole,
    userHasProviderOnlyRole,
    userCanCancelAppt,
    getCognitoRole,
    updateCompanyObjectInCachedUser,
    isUniqueEmailAddress,
    updateProvider
};
