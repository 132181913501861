function isPointInPolygon(point, vs) {
    const x = point.lng,
        y = point.lat;
    let inside = false;

    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i].lng,
            yi = vs[i].lat;
        const xj = vs[j].lng,
            yj = vs[j].lat;

        const intersect =
            yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        /* without autoformat - we need to keep this in code
const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    */
        if (intersect) inside = !inside;
    }

    return inside;
}
/**
 * Check if a point is inside any polygon from an array of polygons
 * @param {Object} point - The point to check, with properties `lat` and `lng`.
 * @param {Array} polygons - The array of polygons, each polygon is an array of vertices with properties `lat` and `lng`.
 * @returns {boolean} - True if the point is inside any of the polygons, false otherwise.
 */
export const isPointInAnyDrawnPolygon = (point, polygons) => {
    for (let i = 0; i < polygons.length; i++) {
        if (isPointInPolygon(point, polygons[i])) {
            return true;
        }
    }
    return false;
};

function haversineDistance(point1, point2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((point2.lat - point1.lat) * Math.PI) / 180;
    const dLng = ((point2.lng - point1.lng) * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((point1.lat * Math.PI) / 180) *
            Math.cos((point2.lat * Math.PI) / 180) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
}

const isAddressInCircle = (addressGeoLocation, providerLocation) => {
    const distanceInKm = haversineDistance(addressGeoLocation, {
        lat: providerLocation.latitude,
        lng: providerLocation.longitude
    });
    let radiusInKm =
        providerLocation.traveldistanceunit === "km"
            ? providerLocation.traveldistance
            : providerLocation.traveldistance * 1.60934;
    return distanceInKm <= radiusInKm;
};

export const isAddressInTravelZone = (addressGeoLocation, providerLocation) => {
    if (
        addressGeoLocation.lat &&
        addressGeoLocation.lng &&
        providerLocation.latitude &&
        providerLocation.longitude &&
        providerLocation.travelregions
    ) {
        if (providerLocation.maxtraveltype === "MAX_DISTANCE") {
            return isAddressInCircle(addressGeoLocation, providerLocation);
        } else if (providerLocation.maxtraveltype === "DRAW_TRAVEL_REGION") {
            return isPointInAnyDrawnPolygon(
                addressGeoLocation,
                JSON.parse(providerLocation.travelregions)
            );
        } else return false;
    } else return false;
};
