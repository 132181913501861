import React, { useState, useEffect } from "react";
import {
    Checkbox,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    ListItemText,
    IconButton,
    Divider,
    Tooltip
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button } from "@mui/material";
import { ArrowDropDown, Cancel, InfoOutlined } from "@mui/icons-material";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import { graphql, graphqlOperation } from "../../modules/AmplifyServices";
import { getPromo } from "../../graphql/queries";
import DeletePromoCodeModal from "./DeletePromoCodeModal";

const getDate = () => {
    let date = new Date().toISOString();
    return date;
};
const PROMO_CODE_REGEX = /^[a-zA-Z0-9]+$/;

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const textFieldStyling = {
    "& .MuiFilledInput-root": {
        background: "white",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "4px"
    },
    "& .MuiFilledInput-root:hover": {
        background: "white",
        border: "1px solid rgba(0,0,0,0.5)",
        borderRadius: "4px"
    },
    "& .MuiFilledInput-root.Mui-focused": {
        background: "white",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "4px"
    }
};
function AddPromoCodeModal(props) {
    const [promoStatus, setPromoStatus] = useState("active");
    const [terminated, setTerminated] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [promoCode, setPromocode] = useState("");
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [promoCodeNotUniqueError, setPromoCodeNotUniqueError] =
        useState(false);
    const [startDate, setStartDate] = useState(getDate());
    const [endDate, setEndDate] = useState(getDate());
    const [createdAt, setCreatedAt] = useState("");
    const [discountValue, setDiscountValue] = useState(0);
    const [discountType, setDiscountType] = useState("DOLLAR");
    const [applyDiscount, setApplyDiscount] = useState("allservices");
    const [selectedServicesIds, setSelectedServicesIds] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [allPackages, setAllPackages] = useState(false);
    const [allServices, setAllServices] = useState(false);
    const [onePerClient, setOnePerClient] = useState(true);
    const [hasExpiryDate, setHasExpiryDate] = useState(false);
    const [redemptionError, setRedemptionError] = useState(false);
    const [maxNumOfRedemptions, setMaxNumOfRedemptions] = useState(0);
    const [maxRedemptionsSwitch, setMaxRedemptionsSwitch] = useState(false);
    const [promo, setPromo] = useState();
    const [openDeletePromoModal, setOpenDeletePromoModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [disableSave, setDisableSave] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    function resetState() {
        setPromoStatus("active");
        setTerminated(false);
        setName("");
        setPromocode("");
        setStartDate(getDateWithoutTime(getDate()));
        setEndDate(getDateWithoutTime(getDate()));
        setDiscountValue();
        setDiscountType("DOLLAR");
        setSelectedServices([]);
        setSelectedServicesIds([]);
        setSelectedPackages([]);
        setSelectedPackageIds([]);
        setAllPackages(false);
        setAllServices(false);
        setOnePerClient(false);
        setHasExpiryDate(false);
        setRedemptionError(false);
        setMaxNumOfRedemptions(0);
        setPromo();
        setIsLoading(false);
        setIsError(false);
        setApplyDiscount("allservices");
        setDisableSave(false);
        setCreatedAt("");
    }
    useEffect(() => {
        async function fetchPromoData() {
            setIsError(false);
            setIsLoading(true);

            try {
                let result = await graphql(
                    graphqlOperation(getPromo, {
                        id: props.promoCodeId,
                        recordType: "SETTINGS"
                    })
                );
                let promo = result.data.getPromo;
                let details = JSON.parse(promo.Details);
                if (details) for (let pro in details) promo[pro] = details[pro];

                setPromo(promo);
                setLocalState(promo);
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        }
        if (props.editMode && props.promoCodeId) fetchPromoData();
    }, [props.editMode, props.promoCodeId]);

    useEffect(() => {
        console.log("Props Serivce Types", props.serviceTypes);
        console.log("Promo Code Data", promo);

        if (selectedServicesIds.length > 0) {
            let servicesArray = [];
            selectedServicesIds.forEach((item) => {
                let propService = props.serviceTypes.filter(
                    (s) => s.id === item
                );
                console.log(propService);
                if (propService.length > 0) {
                    return servicesArray.push(...propService);
                }
            });
            console.log("Services Array", servicesArray);
            setSelectedServices((prevState) => servicesArray);
        }
    }, [selectedServicesIds, props.serviceTypes, promo]);

    useEffect(() => {
        if (selectedPackageIds.length > 0) {
            let pkgsArray = [];
            selectedPackageIds.forEach((item) => {
                let propPkg = props.pkgs.filter((p) => p.id === item);
                console.log(propPkg);
                if (propPkg.length > 0) {
                    return pkgsArray.push(...propPkg);
                }
            });
            setSelectedPackages((prevState) => pkgsArray);
        }
    }, [selectedPackageIds, props.pkgs]);

    useEffect(() => {
        console.log("Selected Serivces", selectedServices);
    }, [selectedServices]);

    /* useEffect(() => {
        console.log("Switch", maxRedemptionsSwitch);
        console.log("Redemptions", maxNumOfRedemptions);
        // if (!maxRedemptionsSwitch) setMaxNumOfRedemptions(0);
    }, [maxRedemptionsSwitch, maxNumOfRedemptions]);
 */
    function setLocalState(promoData) {
        setPromocode(promoData.promocode);
        setName(promoData.name);
        setStartDate(getDateWithoutTime(promoData.startDate));
        if (promoData.endDate) {
            setEndDate(getDateWithoutTime(promoData.endDate));
            setHasExpiryDate(true);
        }
        setDiscountValue(promoData.discountValue);
        setDiscountType(promoData.discountType);
        setOnePerClient(promoData.onePerClient);
        setTerminated(promoData.terminated);
        setAllPackages(promoData.allPackages);
        setAllServices(promoData.allServices);
        setMaxNumOfRedemptions(promoData.maxNumOfRedemptions);
        if (promoData.maxNumOfRedemptions > 0) {
            setMaxRedemptionsSwitch(true);
        } else {
            setMaxRedemptionsSwitch(false);
        }
        setPromoStatus(promoData.active ? "active" : "paused");
        setCreatedAt(promoData.createdAt);
        if (promoData.allPackages) setApplyDiscount("allpackages");
        if (promoData.allServices) setApplyDiscount("allservices");
        if (promoData.services) {
            setApplyDiscount("specificservices");
            setSelectedServices(promoData.services);
            setSelectedServicesIds(promoData.services.map((s) => s.id));
        }
        if (promoData.packages) {
            setApplyDiscount("specificpackages");
            setSelectedPackages(promoData.packages);
            setSelectedPackageIds(promoData.packages.map((p) => p.id));
        }
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleClickDelete() {
        setOpenDeletePromoModal(true);
    }

    function handleResetDeleteModal(value) {
        setOpenDeletePromoModal(value);
    }

    function handlePromoCodeName(name) {
        if (name.length < 26) {
            setName(name);
            setNameError(false);
            console.log(props.company);
            console.log(promo);
        } else {
            setName(name.slice(0, -1));
            setNameError(true);
        }
    }

    function handlePromoCode(promoCode) {
        if (promoCode.length < 21) {
            setPromocode(promoCode);
            console.log("omar promoCode", promoCode, promoCode.length);
            setPromoCodeError(false);
        } else {
            setPromocode(promoCode.slice(0, -1));
            setPromoCodeError(true);
        }
        if (promoCodeNotUniqueError) setPromoCodeNotUniqueError(false);
    }

    const servicesChangeHandler = (service) => {
        console.log(service);
    };

    function promoCodeNotUnique() {
        if (!promoCode) {
            setPromoCodeError(true);
            return true;
        }
        if (promoCode && promoCode !== "" && promoCode !== promo?.promocode) {
            if (props.uniquePromoCodes) {
                if (props.uniquePromoCodes.has(promoCode)) {
                    setPromoCodeNotUniqueError(true);
                    return true;
                }
            }
        }
        return false;
    }
    const savePromoHandler = async () => {
        if (maxRedemptionsSwitch && maxNumOfRedemptions <= 0) {
            setRedemptionError(true);
            return;
        }
        setDisableSave(true);
        if (promoCodeNotUnique()) {
            setDisableSave(false);
            return;
        }

        /*  if (+e.target.value <= 0) {
            setRedemptionError(true);
        } else {
            setRedemptionError(false);
        }
 */

        let promoData = {
            companyId: props.company.id,
            active: promoStatus === "active" ? true : false,
            terminated,
            allPackages: applyDiscount === "allpackages",
            allServices: applyDiscount === "allservices",
            onePerClient: onePerClient,
            maxNumOfRedemptions: maxRedemptionsSwitch ? maxNumOfRedemptions : 0,
            name,
            promocode: promoCode,
            startDate: getDateWithoutTime(startDate), // new Date(startDate).toISOString(),
            discountValue,
            discountType,
            hasExpiryDate,
            services: selectedServices
        };
        if (promo?.id) promoData.id = promo.id;
        if (hasExpiryDate || promoData.endDate)
            promoData.endDate = getDateWithoutTime(endDate); // new Date(endDate).toISOString();
        if (applyDiscount === "specificservices") {
            promoData.services = selectedServices.map((s) => {
                return { id: s.id, name: s.name };
            });
            promoData.packages = null;
        }
        if (applyDiscount === "specificpackages") {
            promoData.packages = selectedPackages.map((p) => {
                return { id: p.id, name: p.desc };
            });
            promoData.services = null;
        }
        if (promoData.allPackages || promoData.allServices) {
            promoData.services = null;
            promoData.packages = null;
        }
        await props.savePromoHandler(promoData);
        resetState();
    };

    function getDateWithoutTime(adateStr) {
        let adate = new Date(adateStr);
        let dateWithoutTime = new Date(
            adate.getFullYear(),
            adate.getMonth(),
            adate.getDate()
        );
        return dateWithoutTime.toISOString();
    }

    function handleOnePerClientSwitch(event) {
        setOnePerClient(event.target.checked);
    }

    return (
        <>
            <Dialog
                open={props.openAddPromoModal}
                onClose={() => {
                    resetState();
                    props.closeAddPromoModal();
                }}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogContent sx={{ padding: "1.5rem" }}>
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: 400
                            }}
                        >
                            {props.editMode ? "Edit " : "New "} Promo Code
                        </Typography>

                        <IconButton
                            onClick={() => {
                                resetState();
                                props.closeAddPromoModal();
                            }}
                        >
                            <Cancel sx={{ width: "32px", height: "32px" }} />
                        </IconButton>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                            gap: "0.8rem",
                            alignItems: "center",
                            margin: "1rem 0px"
                        }}
                    >
                        {/* <Typography>Status: </Typography> */}

                        <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            size="small"
                            variant="contained"
                            endIcon={<ArrowDropDown />}
                            sx={{
                                boxShadow: "none",
                                backgroundColor: "success.light",
                                color: "success.dark",
                                "&:hover": {
                                    backgroundColor: "success.light",
                                    color: "success.dark"
                                }
                            }}
                            onClick={handleClick}
                        >
                            {promoStatus}
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button"
                            }}
                        >
                            {promoStatus === "active" ? (
                                <MenuItem
                                    onClick={() => {
                                        setPromoStatus("paused");
                                        handleClose();
                                    }}
                                >
                                    Paused
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    onClick={() => {
                                        setPromoStatus("active");
                                        handleClose();
                                    }}
                                >
                                    Active
                                </MenuItem>
                            )}
                        </Menu>
                    </Grid>
                    <Grid
                        sx={{
                            backgroundColor: "#f4f4f4",
                            marginBottom: "1rem",
                            borderRadius: "4px"
                        }}
                        container
                    >
                        <Grid
                            item
                            container
                            sx={{ gap: "1rem", margin: "8px" }}
                        >
                            <Grid item sm={12} md={12}>
                                <Typography
                                    sx={{ fontWeight: 700, fontSize: "14px" }}
                                >
                                    Details
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={5.8}>
                                <TextField
                                    sx={textFieldStyling}
                                    fullWidth
                                    id="promo-name"
                                    label="Name"
                                    variant="filled"
                                    helperText={
                                        <Typography
                                            sx={{
                                                fontSize: "11px",
                                                lineHeight: "18px"
                                            }}
                                            variant="caption"
                                        >
                                            {" "}
                                            Displays on the order summary when
                                            code is redeemed
                                        </Typography>
                                    }
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    alignItems: "flex-start"
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        color: nameError
                                                            ? "red"
                                                            : ""
                                                    }}
                                                >
                                                    {name.length}/25
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={name}
                                    onChange={(e) => {
                                        handlePromoCodeName(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} md={5.8}>
                                <TextField
                                    sx={textFieldStyling}
                                    fullWidth
                                    id="promo-code"
                                    label="Code"
                                    variant="filled"
                                    type="text"
                                    error={
                                        promoCodeNotUniqueError ||
                                        promoCodeError
                                    }
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    alignItems: "flex-start"
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        color: promoCodeError
                                                            ? "red"
                                                            : ""
                                                    }}
                                                >
                                                    {promoCode.length}/20
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={promoCode}
                                    helperText={
                                        promoCodeNotUniqueError
                                            ? "This code already exists"
                                            : promoCodeError
                                              ? ""
                                              : ""
                                    }
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (
                                            value !== "" &&
                                            !PROMO_CODE_REGEX.test(value)
                                        ) {
                                            return;
                                        }

                                        handlePromoCode(
                                            event.target.value
                                                ? event.target.value.toUpperCase()
                                                : ""
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={customLocale}
                        >
                            <Grid
                                sx={{ margin: "8px", gap: "1rem" }}
                                item
                                container
                            >
                                <Grid item sm={12} md={5.8}>
                                    <DesktopDatePicker
                                        minDate={
                                            props.editMode
                                                ? new Date(startDate)
                                                : new Date()
                                        }
                                        label="Start Date"
                                        inputFormat="MM/dd/yyyy"
                                        InputProps={{ disableUnderline: true }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={textFieldStyling}
                                                variant="filled"
                                                fullWidth
                                                {...params}
                                            />
                                        )}
                                        value={startDate}
                                        onChange={(newValue) =>
                                            setStartDate(newValue)
                                        }
                                    />
                                </Grid>
                                {!hasExpiryDate && (
                                    <Grid container item xs={12} sm={12} md={5}>
                                        <FormControlLabel
                                            sx={{
                                                marginLeft: "1px"
                                            }}
                                            control={
                                                <Switch
                                                    checked={hasExpiryDate}
                                                    size="small"
                                                    onClick={(e) => {
                                                        if (e.target.checked) {
                                                            setEndDate(
                                                                tomorrow
                                                            );
                                                        }

                                                        setHasExpiryDate(
                                                            (prevState) =>
                                                                !prevState
                                                        );
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px"
                                                    }}
                                                >
                                                    Set Expiry Date
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                )}
                                {hasExpiryDate && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sm={12}
                                        md={5.8}
                                        alignItems={"center"}
                                    >
                                        <DesktopDatePicker
                                            minDate={tomorrow}
                                            label="Expiry Date"
                                            inputFormat="MM/dd/yyyy"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            ActionBar={
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() =>
                                                        setEndDate(tomorrow)
                                                    }
                                                >
                                                    Clear
                                                </Button>
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={textFieldStyling}
                                                    fullWidth
                                                    variant="filled"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    {...params}
                                                    helperText={
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between"
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={
                                                                            hasExpiryDate
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            if (
                                                                                hasExpiryDate
                                                                            ) {
                                                                                setEndDate(
                                                                                    tomorrow
                                                                                );
                                                                            }
                                                                            setHasExpiryDate(
                                                                                (
                                                                                    prevState
                                                                                ) =>
                                                                                    !prevState
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize:
                                                                                "14px"
                                                                        }}
                                                                    >
                                                                        Set
                                                                        Expiry
                                                                        Date
                                                                    </Typography>
                                                                }
                                                            />
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                size="small"
                                                                sx={{
                                                                    height: "27px"
                                                                }}
                                                                onClick={() =>
                                                                    setEndDate(
                                                                        tomorrow
                                                                    )
                                                                }
                                                            >
                                                                Reset
                                                            </Button>
                                                        </Grid>
                                                    }
                                                />
                                            )}
                                            value={endDate}
                                            onChange={(newValue) =>
                                                setEndDate(newValue)
                                            }
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                    <Divider light />
                    <Grid
                        sx={{
                            marginTop: "1rem",
                            backgroundColor: "#f4f4f4",
                            borderRadius: "4px"
                        }}
                        container
                    >
                        <Grid
                            item
                            container
                            sx={{ gap: "1rem", margin: "8px" }}
                        >
                            <Grid item sm={12}>
                                <Typography
                                    sx={{ fontWeight: 700, fontSize: "14px" }}
                                >
                                    Discount Configuration
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5.8}>
                                <TextField
                                    type="number"
                                    sx={{
                                        "& .MuiFilledInput-root": {
                                            background: "white",
                                            border: "1px solid rgba(0,0,0,0.3)",

                                            borderRadius: "4px",
                                            paddingRight: "0px"
                                        },
                                        "& .MuiFilledInput-root:hover": {
                                            background: "white",
                                            border: "1px solid rgba(0,0,0,0.5)",
                                            borderRadius: "4px"
                                        },
                                        "& .MuiFilledInput-root.Mui-focused": {
                                            background: "white",
                                            border: "1px solid rgba(0,0,0,0.3)",
                                            borderRadius: "4px"
                                        },
                                        "& #discount-type.MuiSelect-filled": {
                                            backgroundColor: "#E8E8E8",
                                            border: "none",
                                            borderRadius: "0px"
                                        }
                                    }}
                                    fullWidth
                                    id="promo-discount"
                                    label="Discount"
                                    variant="filled"
                                    value={discountValue}
                                    onChange={(e) =>
                                        setDiscountValue(e.target.value)
                                    }
                                    InputLabelProps={{
                                        shrink:
                                            discountValue >= 0 ? true : false
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment
                                                sx={{
                                                    width: "80px",
                                                    "& .MuiFilledInput-root.MuiInputBase-root":
                                                        {
                                                            backgroundColor:
                                                                "f4f4f4",
                                                            border: "none",
                                                            borderRadius: "0px"
                                                        },
                                                    "& .MuiSelect-select.MuiFilledInput-input":
                                                        {
                                                            backgroundColor:
                                                                "f4f4f4",
                                                            paddingTop: "16px",
                                                            paddingBottom:
                                                                "17px"
                                                        }
                                                }}
                                                position="end"
                                            >
                                                <Select
                                                    sx={{ paddingTop: "0px" }}
                                                    defaultValue={"$"}
                                                    variant="filled"
                                                    fullWidth
                                                    label="Type"
                                                    labelId="demo-simple-select-label"
                                                    id="discount-type"
                                                    disableUnderline
                                                    value={discountType}
                                                    onChange={(e) =>
                                                        setDiscountType(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <MenuItem value={"DOLLAR"}>
                                                        $
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={"PERCENTAGE"}
                                                    >
                                                        %
                                                    </MenuItem>
                                                </Select>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5.8}>
                                {/* <TextField
                            fullWidth
                            id="promo-quantity"
                            label="Quantity"
                            variant="filled"
                        /> */}
                                {/*  <FormControl variant="filled" fullWidth>
                                    <InputLabel id="promo-quantity">
                                        Total No. of Uses
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Total No. of Uses"
                                        labelId="promo-quantity"
                                        id="promo-quantity"
                                        disableUnderline
                                        value={discountQuantity}
                                        onChange={(e) =>
                                            setDiscountQuantity(e.target.value)
                                        }
                                    >
                                        <MenuItem value={"specific"}>
                                            Specific Amount
                                        </MenuItem>
                                        <MenuItem value={"unlimited"}>
                                            Unlimited
                                        </MenuItem>
                                    </Select>
                                </FormControl> */}

                                {maxRedemptionsSwitch && (
                                    <TextField
                                        type="number"
                                        error={redemptionError}
                                        sx={textFieldStyling}
                                        style={{ width: "100%" }}
                                        id="promo-specific-amount"
                                        label="Total No. of Uses"
                                        variant="filled"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        value={
                                            maxNumOfRedemptions === 0
                                                ? ""
                                                : maxNumOfRedemptions
                                        }
                                        onChange={(e) => {
                                            setMaxNumOfRedemptions(
                                                +e.target.value
                                            );
                                        }}
                                        helperText={
                                            redemptionError
                                                ? "Please enter number greater than 0"
                                                : ""
                                        }
                                    />
                                )}
                                {
                                    <FormControlLabel
                                        sx={{
                                            marginLeft: maxRedemptionsSwitch
                                                ? "4px"
                                                : "0px"
                                        }}
                                        control={
                                            <Switch
                                                checked={maxRedemptionsSwitch}
                                                size="small"
                                                onChange={(e) => {
                                                    console.log(
                                                        "e.target",
                                                        e.target.checked
                                                    );
                                                    if (!e.target.checked)
                                                        setMaxNumOfRedemptions(
                                                            0
                                                        );
                                                    setMaxRedemptionsSwitch(
                                                        (prevState) =>
                                                            !prevState
                                                    );
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: "14px"
                                                }}
                                            >
                                                Set Max Usage
                                                <span>
                                                    <Tooltip
                                                        title="Set the max number of times this promo code can be used"
                                                        placement="bottom"
                                                    >
                                                        <InfoOutlined
                                                            sx={{
                                                                marginLeft:
                                                                    "5px",
                                                                fontSize: "16px"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            </Typography>
                                        }
                                    />
                                }
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{ margin: "8px", gap: "1rem" }}
                            item
                            container
                        >
                            <Grid item xs={12} sm={12} md={5.8}>
                                {/* <TextField
                            fullWidth
                            id="promo-services"
                            label="Applies to"
                            variant="filled"
                            helperText="This promo code can be used for all services"
                        /> */}
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="promo-type-services">
                                        Applies to
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            "&.MuiFilledInput-root.MuiInputBase-root":
                                                {
                                                    backgroundColor: "white",
                                                    border: "1px solid rgba(0,0,0,0.3)",
                                                    borderRadius: "4px"
                                                },
                                            "&.Mui-focused": {
                                                backgroundColor: "white",
                                                border: "1px solid rgba(0,0,0,0.3)"
                                            }
                                        }}
                                        defaultValue={"all"}
                                        fullWidth
                                        labelId="promo-type-services"
                                        id="promo-type-services"
                                        disableUnderline
                                        value={applyDiscount}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            setApplyDiscount(val);
                                            if ("allservices") {
                                                setAllServices(true);
                                                setAllPackages(false);
                                            } else if ("allpackages") {
                                                setAllPackages(true);
                                                setAllServices(false);
                                            }
                                        }}
                                    >
                                        <MenuItem value={"allservices"}>
                                            All services
                                        </MenuItem>
                                        <MenuItem value={"allpackages"}>
                                            All packages
                                        </MenuItem>
                                        <MenuItem value={"specificservices"}>
                                            Specific services
                                        </MenuItem>
                                        <MenuItem value={"specificpackages"}>
                                            Specific packages
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5.8}>
                                {applyDiscount === "specificpackages" && (
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel id="promo-packges">
                                            Packages
                                        </InputLabel>
                                        <Select
                                            sx={{
                                                "&.MuiFilledInput-root.MuiInputBase-root":
                                                    {
                                                        backgroundColor:
                                                            "white",
                                                        border: "1px solid rgba(0,0,0,0.3)",
                                                        borderRadius: "4px"
                                                    },
                                                "&.Mui-focused": {
                                                    backgroundColor: "white",
                                                    border: "1px solid rgba(0,0,0,0.3)"
                                                }
                                            }}
                                            multiple
                                            fullWidth
                                            labelId="promo-packages"
                                            id="promo-pacakges"
                                            disableUnderline
                                            value={selectedPackageIds}
                                            renderValue={(selected) =>
                                                `${selected.length} Packages(s) Selected`
                                            }
                                            onChange={(e) => {
                                                const {
                                                    target: { value }
                                                } = e;
                                                setSelectedPackageIds(
                                                    // On autofill we get a stringified value.
                                                    typeof value === "string"
                                                        ? value.split(",")
                                                        : value
                                                );
                                            }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            {props.pkgs?.map((pkg, index) => (
                                                <MenuItem
                                                    key={pkg.id}
                                                    value={pkg.id}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            selectedPackageIds.indexOf(
                                                                pkg.id
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={`${pkg.servicetype.name} - ${pkg.desc}`}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}

                                {applyDiscount === "specificservices" && (
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel id="promo-services">
                                            Services
                                        </InputLabel>
                                        <Select
                                            sx={{
                                                "&.MuiFilledInput-root.MuiInputBase-root":
                                                    {
                                                        backgroundColor:
                                                            "white",
                                                        border: "1px solid rgba(0,0,0,0.3)",
                                                        borderRadius: "4px"
                                                    },
                                                "&.Mui-focused": {
                                                    backgroundColor: "white",
                                                    border: "1px solid rgba(0,0,0,0.3)"
                                                }
                                            }}
                                            multiple
                                            fullWidth
                                            labelId="promo-services"
                                            id="promo-services"
                                            disableUnderline
                                            value={selectedServicesIds}
                                            renderValue={(selected) =>
                                                `${selected.length} Service(s) Selected`
                                            }
                                            onChange={(e) => {
                                                const {
                                                    target: { value }
                                                } = e;
                                                setSelectedServicesIds(
                                                    // On autofill we get a stringified value.
                                                    typeof value === "string"
                                                        ? value.split(",")
                                                        : value
                                                );
                                            }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            {props.serviceTypes?.map(
                                                (service, index) => (
                                                    <MenuItem
                                                        key={service.id}
                                                        value={service.id}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedServicesIds.indexOf(
                                                                    service.id
                                                                ) > -1
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                service.name
                                                            }
                                                        />
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={7}
                        sx={{
                            marginTop: "1rem",
                            gap: "1rem"
                        }}
                        style={{ maxWidth: "100%", width: "100%" }}
                        flexDirection={"column"}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={onePerClient}
                                    onChange={handleOnePerClientSwitch}
                                />
                            }
                            label="Once Per Customer"
                        />
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "42px"
                            }}
                        >
                            <Grid item sx={{ maxWidth: "60%", width: "60%" }}>
                                <Button
                                    variant="contained"
                                    disabled={disableSave}
                                    onClick={async () =>
                                        await savePromoHandler()
                                    }
                                    size="large"
                                    sx={{
                                        maxWidth: "250px"
                                    }}
                                >
                                    SAVE PROMO CODE
                                    {disableSave && (
                                        <CircularProgress
                                            size={24}
                                            style={{
                                                position: "absolute",
                                                color: props.company
                                                    .primaryColor
                                            }}
                                        />
                                    )}
                                </Button>
                                {props.editMode && (
                                    <Button
                                        variant="outlined"
                                        disabled={disableSave}
                                        sx={{
                                            maxWidth: "100px",
                                            marginLeft: "15px"
                                        }}
                                        onClick={() => handleClickDelete()}
                                        size="large"
                                    >
                                        Delete
                                    </Button>
                                )}
                            </Grid>
                            {props.editMode && createdAt && (
                                <Grid
                                    item
                                    sx={{
                                        maxWidth: "48%",
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Typography style={{ color: "grey" }}>
                                        <i>
                                            Created:{" "}
                                            {moment(createdAt).format(
                                                "MMM DD, YYYY"
                                            )}
                                        </i>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                {openDeletePromoModal && (
                    <DeletePromoCodeModal
                        open={openDeletePromoModal}
                        handleResetDeleteModal={handleResetDeleteModal}
                        promoCodeName={name}
                        resetState={resetState}
                        closeAddPromoModal={props.closeAddPromoModal}
                        terminatePromoHandler={props.terminatePromoHandler}
                        promo={promo}
                        company={props.company}
                    />
                )}
            </Dialog>
        </>
    );
}

export default AddPromoCodeModal;
