const now = new Date();

export const colors = {
    MB_INFO_COLOR_LIGHT: "rgba(0, 135, 238, 0.2)",
    MB_INFO_COLOR_MAIN: "rgba(0, 135, 238, 1)",
    MB_SUCCESS_COLOR_LIGHT: "rgba(67, 182, 91, 0.2)",
    MB_SUCCESS_COLOR_MAIN: "rgba(67, 182, 91, 1)",
    MB_WARNING_COLOR_MAIN: "rgba(218, 1, 1, 1)",
    MB_WARNING_COLOR_DARK: "rgba(139,0,0,1)",
    MB_HELPERTEXT_COLOR_MAIN: "rgb(167,167,167)"
};
export const currencies = [
    {
        value: "CAD",
        label: "Canadian Dollar (CAD)"
    },
    {
        value: "USD",
        label: "US Dollar (USD)"
    },
    {
        value: "GBP",
        label: "British Pound sterling (GBP)"
    },
    {
        value: "AUD",
        label: "Australian Dollar (AUD)"
    },
    {
        value: "BBD",
        label: "Barbados Dollar (BBD)"
    },
    {
        value: "JMD",
        label: "Jamaican Dollar (JMD)"
    }
];

export const countryCodes = [
    {
        value: "ca",
        label: "Canada (ca)"
    },
    {
        value: "us",
        label: "United States (us)"
    },
    {
        value: "gb",
        label: "United Kingdom (gb)"
    },
    {
        value: "au",
        label: "Australia (au)"
    },
    {
        value: "bb",
        label: "Barbados (bb)"
    },
    {
        value: "jm",
        label: "Jamaica (jm)"
    }
];

export const subscriptionLevelConst = [
    {
        value: "NONE",
        label: "None"
    },
    {
        value: "FREE_TRIAL_BASIC",
        label: "Free Trial"
    },
    {
        value: "SOLE_OPERATOR",
        label: "Sole Operator"
    },
    {
        value: "BASIC",
        label: "Starter"
    },
    {
        value: "PROFESSIONAL",
        label: "Professional"
    },
    {
        value: "ENTERPRISE",
        label: "Enterprise"
    }
];

export const events = [
    {
        id: 0,
        title: "All Day Event very long title",
        allDay: true,
        start: new Date(2019, 11, 4, 0, 0, 0),
        end: new Date(2019, 11, 4, 0, 0, 0)
    },
    {
        id: 1,
        title: "Long Event",
        start: new Date(2015, 3, 7),
        end: new Date(2015, 3, 10)
    },

    {
        id: 2,
        title: "DTS STARTS",
        start: new Date(2016, 2, 13, 0, 0, 0),
        end: new Date(2016, 2, 20, 0, 0, 0)
    },

    {
        id: 3,
        title: "DTS ENDS",
        start: new Date(2016, 10, 6, 0, 0, 0),
        end: new Date(2016, 10, 13, 0, 0, 0)
    },

    {
        id: 4,
        title: "Some Event",
        start: new Date(2015, 3, 9, 0, 0, 0),
        end: new Date(2015, 3, 10, 0, 0, 0)
    },
    {
        id: 5,
        title: "Conference",
        start: new Date(2015, 3, 11),
        end: new Date(2015, 3, 13),
        desc: "Big conference for important people"
    },
    {
        id: 6,
        title: "Meeting",
        start: new Date(2015, 3, 12, 10, 30, 0, 0),
        end: new Date(2015, 3, 12, 12, 30, 0, 0),
        desc: "Pre-meeting meeting, to prepare for the meeting"
    },
    {
        id: 7,
        title: "Lunch",
        start: new Date(2015, 3, 12, 12, 0, 0, 0),
        end: new Date(2015, 3, 12, 13, 0, 0, 0),
        desc: "Power lunch"
    },
    {
        id: 8,
        title: "Meeting",
        start: new Date(2015, 3, 12, 14, 0, 0, 0),
        end: new Date(2015, 3, 12, 15, 0, 0, 0)
    },
    {
        id: 9,
        title: "Happy Hour",
        start: new Date(2015, 3, 12, 17, 0, 0, 0),
        end: new Date(2015, 3, 12, 17, 30, 0, 0),
        desc: "Most important meal of the day"
    },
    {
        id: 10,
        title: "Dinner",
        start: new Date(2015, 3, 12, 20, 0, 0, 0),
        end: new Date(2015, 3, 12, 21, 0, 0, 0)
    },
    {
        id: 11,
        title: "Birthday Party",
        start: new Date(2015, 3, 13, 7, 0, 0),
        end: new Date(2015, 3, 13, 10, 30, 0)
    },
    {
        id: 12,
        title: "Late Night Event",
        start: new Date(2015, 3, 17, 19, 30, 0),
        end: new Date(2015, 3, 18, 2, 0, 0)
    },
    {
        id: 12.5,
        title: "Late Same Night Event",
        start: new Date(2015, 3, 17, 19, 30, 0),
        end: new Date(2015, 3, 17, 23, 30, 0)
    },
    {
        id: 13,
        title: "Multi-day Event",
        start: new Date(2015, 3, 20, 19, 30, 0),
        end: new Date(2015, 3, 22, 2, 0, 0)
    },
    {
        id: 14,
        title: "Today",
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3))
    },
    {
        id: 15,
        title: "Point in Time Event",
        start: now,
        end: now
    }
];

export const CustomerColumns = [
    {
        title: "Active",
        name: "user.active",
        align: "center",
        className: ""
    },
    {
        title: "Name & Email Address",
        name: "user.name",
        align: "left",
        className: ""
    },
    {
        title: "Notes",
        name: "client.notes",
        align: "center",
        className: "clientNotesColumn"
    },
    {
        title: "Address",
        name: "user.address",
        align: "left",
        className: ""
    },
    {
        title: "Sales YTD",
        name: "stats.sales_ytd",
        align: "center",
        className: ""
    },
    {
        title: "Login",
        name: "user.registered",
        align: "center",
        className: ""
    }
];

export const CompanyLocationColumns = [
    {
        title: "Location Name",
        name: "locationname",
        align: "left",
        className: ""
    },
    {
        title: "Address",
        name: "addressoneline",
        align: "left",
        className: ""
    },
    {
        title: "Virtual",
        name: "virtual",
        align: "center",
        className: ""
    }
];

export const ProviderLocationColumns = [
    {
        title: "Travel Zone Name",
        name: "name",
        align: "left",
        className: ""
    },
    {
        title: "Address",
        name: "addressoneline",
        align: "left",
        className: ""
    }
];

export const ReviewColumns = [
    {
        title: "Rating",
        name: "ratingstars",
        align: "left",
        className: ""
    },
    {
        title: "Provider Review",
        name: "ratingtext",
        align: "left",
        className: ""
    },
    {
        title: "Date Created",
        name: "createdAt",
        align: "left",
        className: ""
    }
];

export const AdminBookingListColumns = [
    {
        title: "Description",
        name: "desc",
        align: "left",
        className: ""
    },
    {
        title: "Booking Location",
        name: "location",
        align: "left",
        className: ""
    },
    {
        title: "Client Name",
        name: "clientName",
        align: "left",
        className: ""
    },
    {
        title: "Client Phone",
        name: "clientPhone",
        align: "left",
        className: ""
    },
    {
        title: "Provider Name",
        name: "providerName",
        align: "left",
        className: ""
    },
    {
        title: "Start Date",
        name: "startDateDesc",
        align: "left",
        className: ""
    },
    {
        title: "Date Created",
        name: "dateCreated",
        align: "left",
        className: ""
    },
    {
        title: "Status",
        name: "status",
        align: "left",
        className: ""
    },
    {
        title: "Initial Charge",
        name: "initialCharge",
        align: "left",
        className: ""
    },
    {
        title: "Additional Charges",
        name: "additionalCharges",
        align: "left",
        className: ""
    },
    {
        title: "Rating",
        name: "ratingstars",
        align: "center",
        className: ""
    }
];

export const ClientBookingListColumns = [
    {
        title: "Description",
        name: "desc",
        align: "left",
        className: ""
    },
    {
        title: "Booking Location",
        name: "location",
        align: "left",
        className: ""
    },
    {
        title: "Provider Name",
        name: "providerName",
        align: "left",
        className: ""
    },
    {
        title: "Start Date",
        name: "startDateDesc",
        align: "left",
        className: ""
    },
    {
        title: "Date Created",
        name: "dateCreated",
        align: "left",
        className: ""
    },
    {
        title: "Status",
        name: "status",
        align: "left",
        className: ""
    },
    {
        title: "Initial Charge",
        name: "initialCharge",
        align: "left",
        className: ""
    },
    {
        title: "Additional Charges",
        name: "additionalCharges",
        align: "left",
        className: ""
    },
    {
        title: "Rating",
        name: "ratingstars",
        align: "center",
        className: ""
    }
];

export const ProviderBookingListColumnsMobile = [
    {
        title: "Description",
        name: "desc",
        align: "left",
        className: ""
    },
    {
        title: "Booking Location",
        name: "location",
        align: "left",
        className: ""
    },
    {
        title: "Start Date",
        name: "startDateDesc",
        align: "left",
        className: ""
    },
    {
        title: "Status",
        name: "status",
        align: "left",
        className: ""
    },
    {
        title: "Client Name",
        name: "clientName",
        align: "left",
        className: ""
    }
];

export const ProviderBookingListColumnsMobilePending =
    ProviderBookingListColumnsMobile.filter((col) => col.name !== "status");

export const ProviderBookingListColumnsMobileFuture = [
    ...ProviderBookingListColumnsMobile,
    {
        title: "Client Phone",
        name: "clientPhone",
        align: "left",
        className: ""
    }
];

export const ProviderBookingListColumnsDesktop = [
    ...ProviderBookingListColumnsMobile,
    {
        title: "Date Created",
        name: "dateCreated",
        align: "left",
        className: ""
    }
];

export const ProviderBookingListColumnsDesktopFuture = [
    ...ProviderBookingListColumnsMobileFuture,
    {
        title: "Date Created",
        name: "dateCreated",
        align: "left",
        className: ""
    }
];

export const BookingReqListColumns = [
    {
        title: "Booking Type",
        name: "bookingType",
        align: "left",
        className: ""
    },
    {
        title: "Description",
        name: "serviceAndPackage",
        align: "left",
        className: ""
    },
    {
        title: "Location",
        name: "bookingLoc",
        align: "left",
        className: ""
    },
    {
        title: "Provider",
        name: "providerDetails",
        align: "left",
        className: ""
    },
    {
        title: "Client",
        name: "clientDetails",
        align: "left",
        className: ""
    },
    {
        title: "No. of Bookings",
        name: "numberOfBookings",
        align: "left",
        className: ""
    },
    {
        title: "Alternate Providers?",
        name: "tryOtherProviders",
        align: "left",
        className: ""
    },
    {
        title: "Created",
        name: "createdDesc",
        align: "left",
        className: ""
    }
];
export const NonpendingBRListColumns = [
    {
        title: "Status",
        name: "status",
        align: "left",
        className: ""
    },
    {
        title: "Booking Type",
        name: "bookingType",
        align: "left",
        className: ""
    },
    {
        title: "Description",
        name: "serviceAndPackage",
        align: "left",
        className: ""
    },
    {
        title: "Location",
        name: "bookingLoc",
        align: "left",
        className: ""
    },
    {
        title: "Provider",
        name: "providerDetails",
        align: "left",
        className: ""
    },
    {
        title: "Client",
        name: "clientDetails",
        align: "left",
        className: ""
    },
    {
        title: "No. of Bookings",
        name: "numberOfBookings",
        align: "left",
        className: ""
    },
    {
        title: "Final Update",
        name: "createdDesc",
        align: "left",
        className: ""
    }
];

export const ScheduleColumns = [
    {
        title: "Location Name",
        name: "locationName",
        align: "left",
        className: ""
    },
    {
        title: "Service Type",
        name: "serviceName",
        align: "left",
        className: ""
    },
    {
        title: "Date Range",
        name: "dateRange",
        align: "left",
        className: ""
    },
    {
        title: "Repeat",
        name: "repeat",
        align: "left",
        className: ""
    },
    {
        title: "Time Range",
        name: "timeRange",
        align: "left",
        className: ""
    },
    {
        title: "",
        name: "active",
        align: "left",
        className: ""
    }
];

export const TimeBlockColumns = [
    {
        title: "Start Date",
        name: "startDate",
        align: "left",
        className: ""
    },
    {
        title: "End Date",
        name: "endDate",
        align: "left",
        className: ""
    },
    {
        title: "Repeat",
        name: "repeat",
        align: "left",
        className: ""
    },
    {
        title: "Time Range",
        name: "timeRange",
        align: "left",
        className: ""
    }
];

export const orderColumns = [
    {
        title: "Company",
        name: "companyName",
        align: "left",
        className: ""
    },
    {
        title: "Order Date",
        name: "createdAt",
        align: "left",
        className: ""
    },
    {
        title: "Status",
        name: "status",
        align: "left",
        className: ""
    },
    {
        title: "Client",
        name: "clientName",
        align: "left",
        className: ""
    },
    {
        title: "Provider",
        name: "providerName",
        align: "left",
        className: ""
    },
    {
        title: "Credits Left",
        name: "credits",
        align: "left",
        className: ""
    },
    {
        title: "Subtotal",
        name: "subtotal",
        align: "right",
        className: ""
    },
    {
        title: "Service Fee",
        name: "servicechargeamt",
        align: "right",
        className: ""
    },
    {
        title: "Tax",
        name: "taxamt",
        align: "right",
        className: ""
    },
    {
        title: "Total",
        name: "total",
        align: "right",
        className: ""
    },
    {
        title: "Misc. Charges",
        name: "miscChargesString",
        align: "right",
        className: ""
    },
    {
        title: "Additional Charges",
        name: "additionalChargesString",
        align: "right",
        className: ""
    }
];

export const orderColumnsAdmin = [
    {
        title: "Order Date",
        name: "createdAt",
        align: "left",
        className: ""
    },
    {
        title: "Order No.",
        name: "orderNo",
        align: "left",
        className: ""
    },
    {
        title: "Status",
        name: "status",
        align: "left",
        className: ""
    },
    {
        title: "Client",
        name: "clientName",
        align: "left",
        className: ""
    },
    {
        title: "Booking Address",
        name: "addressoneline",
        align: "left",
        className: ""
    },
    {
        title: "Provider",
        name: "providerName",
        align: "left",
        className: ""
    },
    {
        title: "Credits Left",
        name: "credits",
        align: "left",
        className: ""
    },
    {
        title: "Subtotal",
        name: "subtotal",
        align: "right",
        className: ""
    },
    {
        title: "Service Fee",
        name: "servicechargeamt",
        align: "right",
        className: ""
    },
    {
        title: "Tax",
        name: "taxamt",
        align: "right",
        className: ""
    },
    {
        title: "Total",
        name: "total",
        align: "right",
        className: ""
    },
    {
        title: "Misc. Charges",
        name: "miscChargesString",
        align: "right",
        className: ""
    },
    {
        title: "Additional Charges",
        name: "additionalChargesString",
        align: "right",
        className: ""
    }
];

export const AdditionalChargesColumns = [
    {
        title: "Charge Amount",
        name: "amount",
        align: "left",
        className: ""
    },
    {
        title: "Desc",
        name: "desc",
        align: "left",
        className: ""
    },
    {
        title: "Note",
        name: "note",
        align: "left",
        className: ""
    },
    {
        title: "Order No.",
        name: "orderNum",
        align: "left",
        className: ""
    },
    {
        title: "Created By",
        name: "createdBy",
        align: "left",
        className: ""
    },
    {
        title: "Date Created",
        name: "dateString",
        align: "left",
        className: ""
    }
];

export const PotentialSalesColumns = [
    {
        title: "Request Date",
        name: "createdAt",
        align: "left",
        className: ""
    },
    {
        title: "Client Name",
        name: "clientName",
        align: "left",
        className: ""
    },
    {
        title: "Client Phone",
        name: "phone",
        align: "left",
        className: ""
    },
    {
        title: "Email Address",
        name: "emailaddress",
        align: "left",
        className: ""
    },
    {
        title: "Booking Address",
        name: "addressoneline",
        align: "left",
        className: ""
    },
    {
        title: "Requested Services",
        name: "serviceString",
        align: "left",
        className: ""
    },
    {
        title: "Requested Skills",
        name: "skillsString",
        align: "left",
        className: ""
    },
    {
        title: "Requested Time of Day",
        name: "todString",
        align: "left",
        className: ""
    },
    {
        title: "Message",
        name: "",
        align: "left",
        className: ""
    }
];

export const GOOGLE_FONTS = [
    {
        font: "Open Sans"
    },
    {
        font: "Lobster"
    },
    {
        font: "Lato"
    },
    {
        font: "Nunito"
    },
    {
        font: "Ubuntu"
    },
    {
        font: "Merriweather Sans"
    },
    {
        font: "Pacifico"
    },
    {
        font: "Source Sans Pro"
    },
    {
        font: "Work Sans"
    },
    {
        font: "Titillium Web"
    }
];

export const TIME_SUGGESTION_CONFIG_DEFAULTS = {
    isEnabled: false,
    timeOfFirstBookingOfTheDay: "ANY_TIME",
    forceClientAccept: true,
    maxTravelTimeMinutes: 30
};

export const MAX_NUMBER_OF_SUGGESTED_SLOTS_TO_DISPLAY = 10;
export const MAX_NUMBER_OF_SUGGESTED_DAYS_TO_DISPLAY = 10;
export const DEFAULT_NUMBER_OF_LOOKAHEAD_DAYS = 7;
export const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const anchorTimeOptions = [
    {
        value: "ANY_TIME",
        text: "Any time"
    },
    {
        value: "00:00",
        text: "12 AM"
    },
    {
        value: "01:00",
        text: "1 AM"
    },
    {
        value: "02:00",
        text: "2 AM"
    },
    {
        value: "03:00",
        text: "3 AM"
    },
    {
        value: "04:00",
        text: "4 AM"
    },
    {
        value: "05:00",
        text: "5 AM"
    },
    {
        value: "06:00",
        text: "6 AM"
    },
    {
        value: "07:00",
        text: "7 AM"
    },
    {
        value: "08:00",
        text: "8 AM"
    },
    {
        value: "09:00",
        text: "9 AM"
    },
    {
        value: "10:00",
        text: "10 AM"
    },
    {
        value: "11:00",
        text: "11 AM"
    },
    {
        value: "12:00",
        text: "12 PM"
    },
    {
        value: "13:00",
        text: "1 PM"
    },
    {
        value: "14:00",
        text: "2 PM"
    },
    {
        value: "15:00",
        text: "3 PM"
    },
    {
        value: "16:00",
        text: "4 PM"
    },
    {
        value: "17:00",
        text: "5 PM"
    },
    {
        value: "18:00",
        text: "6 PM"
    },
    {
        value: "19:00",
        text: "7 PM"
    },
    {
        value: "20:00",
        text: "8 PM"
    },
    {
        value: "21:00",
        text: "9 PM"
    },
    {
        value: "22:00",
        text: "10 PM"
    },
    {
        value: "23:00",
        text: "11 PM"
    }
];

export const maxTravelTimeMinutesBetweenBookings = [15, 30, 45, 60, 90, 120];
export const TRAVEL_BUFFER = 10; //minutes
export const FEATURE_NAME_CLUSTERING = "Clustering"; // travel time optimized booking time suggestions

export const WEEK_DAYS_ABBREVIATED = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
];

export const defaultBrandingStates = {
    logoURL:
        "https://marketbox-prod-booknow.s3.amazonaws.com/public/company-assets/default_logo.png",
    showLogo: true,
    logoAsCircle: false,
    showHeader: true,
    backgroundURL:
        "https://marketbox-prod-booknow.s3.amazonaws.com/public/company-assets/default_background.png",
    backgroundColor: "#ffffff",
    buttonColor: "#000000",
    headerTransparency: 1,
    showHeaderText: true,
    headerFontType: "Raleway",
    headerFontColor: "#000000",
    buttonTextColor: "#000000",
    companyName: "Put your company name here",
    companyNameFontSize: 60,
    companyTagline: "Describe your what company offers",
    companyTaglineFontSize: 24,
    companyDescription: "Put a call to action or your company slogan",
    companyDescriptionFontSize: 16,
    showHeadingOne: true,
    showHeadingTwo: true,
    showHeadingThree: true
};

export const defaultDomainStates = {
    homepageURL: "",
    subdomain: "",
    subdomainError: false,
    subdomainErrorMessage: "",
    taglineError: false,
    taglineErrorMessage: "",
    tagline: ""
};

export const defaultBookingStates = {
    bookingIncrements: 15,
    noProviderMessage:
        "Thank you for your interest. Unfortunately at this time, there is no availability in your area. Please leave your contact info and we will reach out when this changes.",
    showProviderAcceptance: false,
    tosURL: "",
    categoryDisplay: "ALLOPEN",
    servicesDisplay: "CARD",
    checkoutOption: "ACCOUNT_OR_GUEST",
    displayServicePrices: true
};

export const defaultBookingFlowSettingsFiles = {
    logoFile: "",
    backgroundFile: "",
    tosFile: ""
};

export const SUBDOMAIN_REGEX_SPECIAL_CHARS = /[^a-z0-9-]/g;
export const SUBDOMAIN_REGEX_MULTIPLE_HYPHENS = /-+/g;
