import React, { useState, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TextField, Box, Button, Paper, Grid, FormControl, Typography, Snackbar, InputLabel, Select, MenuItem, CircularProgress } from "@mui/material";
// prettier-ignore
//import { DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useStyles } from "../styles/MainNavbarStyles";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { serviceTypeByCompanyCustom } from "../queries/ProviderFormQueries";
import {
    auditClientPackageUpdate,
    auditClientPackageAdd
} from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";
import { packageByCompany } from "../queries/CustomQueries";
export default function ClientPackageAddEditForm(props) {
    const { state, dispatch, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //fields
    const [description, setDescription] = React.useState("");
    const [initialQuantity, setInitialQuantity] = React.useState("");
    const [usedQuantity, setUsedQuantity] = React.useState("");
    const [createdAt, setCreatedAt] = React.useState();
    const [serviceTypeData, setServiceTypeData] = useState();
    const [packageTypeData, setPackageTypeData] = useState();
    const [packageType, setPackageType] = useState("");
    const [packageId, setPackageId] = React.useState("");
    const [serviceTypeId, setServiceTypeId] = React.useState("");
    const [active, setActive] = React.useState(true);
    const classes = useStyles();
    const classesnew = usePromotionsStyles();
    //type
    const [values, setValues] = React.useState({
        type: ""
    });

    const [packageService, setPackageService] = useState(null);

    const inputLabel = React.useRef(null);
    //Fetching user info from cache.
    const loggedInUser = getUserFromCache();
    console.log(loggedInUser);

    const [disableSave, setDisableSave] = useState(false);

    const fromCustomerForm = () => {
        if (props.originPath === "CustomerForm") {
            return true;
        }
        return false;
    };

    // fetches the service types for the client
    const fetchServiceTypes = async () => {
        const loggedInUser = getUserFromCache();
        const filter = {
            and: [
                { active: { ne: false } },
                { deleted: { ne: true } },
                { isVisible: { ne: false } }
            ]
        };

        let serviceTypes = await graphql(
            graphqlOperation(serviceTypeByCompanyCustom, {
                companyId: loggedInUser.companyId,
                filter
            })
        );
        console.log("Service Types", serviceTypes);
        setServiceTypeData(serviceTypes.data.serviceTypeByCompany.items);
    };

    // fetches all relevant packages for client package adding
    async function fetchPackages() {
        let packages = await execReadByPK({
            opname: "packageByCompany",
            op: packageByCompany,
            id: { companyId: loggedInUser.companyId },
            filter: {
                and: [{ active: { ne: false } }, { deleted: { ne: true } }]
            },
            sortDirection: "DESC"
        });
        setPackageTypeData(packages.items ? packages.items : []);
    }

    useEffect(() => {
        //setServiceTypeData(Cache.getItem("servicetypes"));

        //setLabelWidth(inputLabel.current.offsetWidth);
        //state.mode === "Edit" ||
        if (fromCustomerForm() && props.clientPackageMode === "Edit") {
            getPackageData();
            fetchServiceTypes();
        } else {
            fetchPackages();
            // fetchServiceTypes();
        }

        console.log("state useEffect CPAEF", state);
        console.log("props for cpaef", props);
    }, []);

    useEffect(() => {
        console.log("Values cpaef", values);
    }, [values]);

    // gets package data for the editing page
    async function getPackageData() {
        const packageId = fromCustomerForm() ? props.packageId : state.id;
        if (!!packageId) {
            const input = { id: packageId };
            const result = await graphql(
                graphqlOperation(queries.getClientPackage, input)
            );
            console.log("getPackageData", result);
            if (!(!!result.errors && result.errors.length > 0)) {
                const {
                    packageId,
                    servicetype,
                    active,
                    createdAt,
                    status,
                    initialQuantity,
                    usedQuantity
                } = result.data.getClientPackage;
                setPackageId(packageId);
                setActive(active);
                setCreatedAt(createdAt);
                setValues((oldValues) => ({
                    ...oldValues,
                    type: servicetype.id
                }));
                setInitialQuantity(initialQuantity);
                setUsedQuantity(usedQuantity);
                setPackageService({
                    name: servicetype.name,
                    id: servicetype.id
                });
            }
        }
    }

    // fetches all of the relevant data for the specific package
    async function getPkgData() {
        console.log(newPackageId);

        const packageData = await graphql(
            graphqlOperation(queries.getPackage, { id: newPackageId })
        );

        setPackageId(packageData.data.getPackage.id);
        setServiceTypeId(packageData.data.getPackage.servicetypeId);
        setInitialQuantity(packageData.data.getPackage.quantity);
    }

    // function that saves a new client packaged into the database
    async function saveNewPackage() {
        console.log("Save for CPAEF", state);
        try {
            let input = {
                userId: fromCustomerForm() ? props.packageId.id : state.id.id,
                clientPackageUserId: fromCustomerForm()
                    ? props.packageId.id
                    : state.id.id,
                clientPackageServicetypeId: serviceTypeId,
                servicetypeId: serviceTypeId,
                clientPackagePackageId: packageId,
                packageId: packageId,
                initialQuantity: initialQuantity,
                usedQuantity: 0,
                status: "paid",
                active: true,
                createdAt: new Date().toISOString(),
                clientId: fromCustomerForm()
                    ? props.clientPackage && props.clientPackage.id
                    : state.clientForPackageDialog &&
                      state.clientForPackageDialog.id,
                clientPackageClientId: fromCustomerForm()
                    ? props.clientPackage && props.clientPackage.id
                    : state.clientForPackageDialog &&
                      state.clientForPackageDialog.id
            };
            const result = await graphql(
                graphqlOperation(mutations.createClientPackage, { input })
            );

            if (result && result.data) {
                setSnackMsg("Package saved successfully.");
                await auditClientPackageAdd(
                    loggedInUser,
                    result.data.createClientPackage
                );
                handleCancelAddClientPackageForm();
                setMsgOpen(true);
            } else {
                setSnackMsg("Unable to save the package.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error(
                "An error occurred saving the package. The error was: "
            );
            console.error(error);
            setDisableSave(false);
        }
    }

    // function to update a package
    async function savePackage() {
        try {
            let input = {
                servicetypeId: values.type,
                packageId: packageId,
                active: active,
                initialQuantity: initialQuantity,
                usedQuantity: usedQuantity
            };
            let response;
            console.log("input before operation", input);
            //state.mode === 'Edit'
            if (fromCustomerForm() && props.clientPackageMode === "Edit") {
                input.id = fromCustomerForm() ? props.packageId : state.id;
                console.log("input line 220", input);
                input.createdAt = createdAt;
                response = await graphql(
                    graphqlOperation(mutations.updateClientPackage, { input })
                );
            } else {
                input.createdAt = new Date();
                response = await graphql(
                    graphqlOperation(mutations.createClientPackage, { input })
                );
            }
            console.log("should have mutated");
            if (response && response.data) {
                await auditClientPackageUpdate(
                    loggedInUser,
                    response.data.updateClientPackage
                        ? response.data.updateClientPackage
                        : response.data.createClientPackage
                );
                setSnackMsg("Package saved successfully.");
                setMsgOpen(true);
                //added if block to check if the path is  from customer form
                if (fromCustomerForm()) {
                    await props.showPackageDialog(props.clientPackage);
                    props.resetStates();
                    props.closeModal();
                } else {
                    actions.setPage("CustomerForm");
                }
            } else {
                setSnackMsg("Unable to save the package.");
                setMsgOpen(true);
            }
        } catch (error) {
            console.error(
                "An error occurred saving the package. The error was: "
            );
            console.error(error);
            setDisableSave(false);
        }
    }

    // handles any errors in creating or saving packages
    async function handleSavePackage() {
        setDisableSave(true);
        // validate

        if (values.type == 0 || values.type == "") {
            setSnackMsg("Please specify a package type");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }

        if (usedQuantity < 0 || usedQuantity > initialQuantity) {
            setSnackMsg("Please Enter a Valid Amount of Credits Used");
            setMsgOpen(true);
            setDisableSave(false);
            return;
        }
        //state.mode === 'Edit
        if (fromCustomerForm() && props.clientPackageMode === "Edit") {
            savePackage();
        } else {
            saveNewPackage();
        }
    }

    // handle used quantity change
    const handleChangeUsedQuantity = (e) => {
        if (e.target.value >= 0) {
            setUsedQuantity(e.target.value);
        }
    };

    let newPackageId = "";

    function handlePackageChange(event) {
        newPackageId = event.target.value;
        setValues((oldValues) => ({
            ...oldValues,
            [event.target.name]: event.target.value
        }));
        console.log("Here is event", event);
        if (event.target.value != 0) {
            getPkgData();
        }
    }

    async function handleCancelEditClientPackageForm() {
        if (fromCustomerForm()) {
            props.resetStates();
            props.closeModal();
        } else {
            actions.setId("");
            actions.setPage("CustomerForm");
        }
    }

    async function handleCancelAddClientPackageForm() {
        if (fromCustomerForm()) {
            await props.showPackageDialog(props.clientPackage);
            props.resetStates();
            props.closeModal();
        } else {
            actions.setId("");
            actions.setPage("CustomerForm");
        }
    }

    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    //  state.mode === "Edit"
    if (fromCustomerForm() && props.clientPackageMode === "Edit") {
        return (
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={msgOpen}
                    autoHideDuration={3000}
                    onClose={handleMsgClose}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">{snackMsg}</span>}
                />
                <Typography className={classes.title} variant="h4" noWrap>
                    {`${props.clientPackageMode} client package`}
                    {/*Client Package \ {state.mode}*/}
                </Typography>
                <Paper
                    rounded="true"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        padding: "0px"
                    }}
                    className={classes.root}
                >
                    <Grid sx={{ mt: 2 }} container>
                        <Grid item xs={8}>
                            {packageService && packageService.name && (
                                <TextField
                                    fullWidth
                                    id="service-type"
                                    label="Service type"
                                    value={packageService.name}
                                    disabled
                                />
                            )}
                            {/*   <Select
                                    id="outlined-age-simple"
                                    value={values.type}
                                    name="type"
                                    label="Service Type"
                                    disabled={
                                        props.clientPackageMode === "Edit" ||
                                        state.mode === "Edit"
                                    }
                                >
                                    {serviceTypeData &&
                                    serviceTypeData.length > 0 ? (
                                        serviceTypeData.map((item, i) => {
                                            return (
                                                <MenuItem
                                                    value={item.id}
                                                    key={i}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem value={0}>
                                            <em>None</em>
                                        </MenuItem>
                                    )}
                                </Select> */}
                        </Grid>
                    </Grid>
                    {packageService && packageService.name && (
                        <Grid container>
                            <Grid item xs={8}>
                                <TextField
                                    id="initialQuantity"
                                    label="Initial Credits"
                                    type="number"
                                    className={classes.textField}
                                    value={initialQuantity}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {packageService && packageService.name && (
                        <Grid container>
                            <Grid item xs={8}>
                                <TextField
                                    id="usedQuantity"
                                    label="Credits Used"
                                    type="number"
                                    className={classes.textField}
                                    onChange={handleChangeUsedQuantity}
                                    value={usedQuantity}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container>
                        <Grid item xs={6} container gap={1}>
                            <Box component="span">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSavePackage}
                                    disabled={disableSave}
                                >
                                    Save
                                    {disableSave && (
                                        <CircularProgress
                                            size={24}
                                            className={
                                                classesnew.buttonProgress
                                            }
                                        />
                                    )}
                                </Button>
                            </Box>
                            <Box component="span">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCancelEditClientPackageForm}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        );
    } else {
        return (
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={msgOpen}
                    autoHideDuration={3000}
                    onClose={handleMsgClose}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">{snackMsg}</span>}
                />
                {/*  <Typography className={classes.title} variant="h4" noWrap>
                    {`${props.clientPackageMode} client package`}
                    {/*  Client Package \ {state.mode}}
                </Typography> */}

                <Typography className={classes.title} variant="body2" noWrap>
                    Adding client package for{" "}
                    {fromCustomerForm() && props.packageId
                        ? `${props.packageId.firstname} ${props.packageId.lastname}`
                        : state.id.firstname + "" + state.id.lastname}
                    {/*state.id.firstname + "" + state.id.lastname*/}
                </Typography>
                <Grid container>
                    <Grid item xs={8}>
                        <FormControl
                            margin="normal"
                            required
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel
                                ref={inputLabel}
                                id="outlined-age-simple"
                            >
                                Packages
                            </InputLabel>
                            <Select
                                onChange={handlePackageChange}
                                name="type"
                                id="outlined-age-simple"
                                label="Packages"
                            >
                                <MenuItem value={0}>
                                    <em>None</em>
                                </MenuItem>
                                {packageTypeData &&
                                    packageTypeData.length > 0 &&
                                    packageTypeData.map((item, i) => {
                                        return (
                                            <MenuItem value={item.id} key={i}>
                                                {item.servicetype.name +
                                                    " [" +
                                                    item.desc +
                                                    "]"}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid sx={{ mt: 2 }} container>
                    <Grid item xs={5} container gap={1}>
                        <Box component="span">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSavePackage}
                                disabled={disableSave}
                            >
                                Save
                                {disableSave && (
                                    <CircularProgress
                                        size={24}
                                        className={classesnew.buttonProgress}
                                    />
                                )}
                            </Button>
                        </Box>
                        <Box component="span">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelAddClientPackageForm}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </>
        );
    }
}
