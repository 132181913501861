import React, { useState, useEffect, useContext, Fragment } from "react";
import { Grid, Typography, Link, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UserInputContext from "../../context/userInput/userInputContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { postApi } from "../../../../modules/AmplifyServices";
import { fetchAuthSession, signIn } from "aws-amplify/auth";
import * as Sentry from "@sentry/react";

/* styles used in right panel */
const useStyles = makeStyles({
    button: {
        backgroundColor: "#FF681D",
        color: "white",
        "&.MuiButton-root.Mui-disabled": {
            color: "white"
        },
        width: "67%",
        height: "3rem",
        borderRadius: "4px",
        "&:hover": {
            backgroundColor: "#E64C00"
        }
    },
    button_text: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "20px",
        fontWeight: "bold",
        textTransform: "initial"
    },
    buttonLabel: {
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center"
    },
    loadingContainer: {
        position: "relative",
        width: 40,
        height: 10,
        display: "flex",
        alignItems: "flex-end"
    },
    circle: {
        position: "absolute",
        width: 6,
        height: 6,
        backgroundColor: "#ffffff",
        borderRadius: "50%",
        animation: "$wave 1s infinite"
    },
    link: {
        color: "#007bff",
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        fontStyle: "italic"
    },
    "@keyframes wave": {
        "0%, 100%": {
            transform: "translateY(0)"
        },
        "50%": {
            transform: "translateY(-10px)"
        }
    }
});

const StripeCheckout = (props) => {
    const userInputContext = useContext(UserInputContext);
    const {
        userInputs: state,
        hubspotContact,
        setHubspotContact
    } = userInputContext;

    const {
        cleanPhone,
        companyId,
        companyNameInput,
        desiredSubdomain,
        emailAddress,
        firstNameInput,
        lastNameInput,
        phoneNumber,
        subdomainEnding
    } = state;
    const companyData = {
        cleanPhone,
        companyId,
        companyNameInput,
        desiredSubdomain,
        emailAddress,
        firstNameInput,
        lastNameInput,
        phoneNumber,
        subdomainEnding
    };

    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState("");

    const query = new URLSearchParams(window.location.search);

    const classes = useStyles();

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        /* async function checkSessionStatus() { */
        if (
            query.get("success") &&
            !sessionStorage.getItem("processingCompanyCreation")
        ) {
            sessionStorage.setItem("processingCompanyCreation", "true");
            setSuccess(true);
            setSessionId(query.get("session_id"));
            if (props.reactivate) {
                props.onActivateCompany();
            } else {
                props.onCreateCompany();
            }
        }

        if (query.get("canceled")) {
            setSuccess(false);
        }
    }, [props.onCreateCompany]);

    useEffect(() => {
        // This effect will only run when 'props.validate' is true
        if (props.isValid && sessionStorage.getItem("validating")) {
            handleSubmitSubscription();
        } else {
            props.onIsValid(false);
        }
    }, [props.isValid]);

    async function SignInAsGuest() {
        let userAuthenticated = false;
        let user = null;
        try {
            // check if user signed in
            await fetchAuthSession({ bypassCache: true })
                .then((user) => {
                    if (
                        user &&
                        user.tokens &&
                        user.tokens.idToken &&
                        user.tokens.idToken.payload
                    ) {
                        let groups =
                            user.tokens.idToken.payload["cognito:groups"];
                        if (groups && groups.length) {
                            userAuthenticated = true;
                        }
                    }
                })
                .catch((err) => {
                    console.log("*** Error: User is not authenticated - ", err);
                    Sentry.captureException(err);
                });

            if (!userAuthenticated) {
                await signIn({
                    username: process.env.REACT_APP_MB_GUEST_EMAIL,
                    password: process.env.REACT_APP_MB_GUEST_PWD
                });
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === "PasswordResetRequiredException") {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === "NotAuthorizedException") {
                // The error happens when the incorrect password is provided
            } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(
                    "An error occurred attempting to login. Error was: " +
                        JSON.stringify(err)
                );
                Sentry.captureException(err);
            }
        }
    }

    const LoadingIcon = () => {
        const classes = useStyles();
        const numCircles = 3;

        return (
            <div className={classes.loadingContainer}>
                {[...Array(numCircles)].map((_, index) => (
                    <div
                        key={index}
                        className={classes.circle}
                        style={{
                            left: `${index * 15}px`,
                            animationDelay: `${index * 0.1}s`
                        }}
                    />
                ))}
            </div>
        );
    };

    const CheckoutDisplay = () => (
        <Grid
            container
            style={{
                justifyContent: "center",
                padding:
                    props.width > 400 ? "20px 42px 0px 42px" : "20px 20px 0px",
                gap: "12px"
            }}
        >
            <Button
                id="stripe-create-subscription"
                className={classes.button}
                style={{ width: props.width < 550 ? "100%" : "" }}
                disabled={sessionStorage.getItem("validating")}
                onClick={() => {
                    props.onValidateUpdate(true);
                    sessionStorage.setItem("validating", "true");
                }}
            >
                <Grid container className={classes.buttonLabel}>
                    <Typography className={classes.button_text}>
                        {`Checkout`}
                    </Typography>
                    {sessionStorage.getItem("validating") ? (
                        <LoadingIcon />
                    ) : (
                        <ArrowForwardIcon style={{ marginLeft: "6px" }} />
                    )}
                </Grid>
            </Button>
            <Typography
                style={{
                    fontSize: "12px",
                    padding: "8px 0px",
                    textAlign: "center",
                    fontFamily: "Poppins, sans-serif"
                }}
            >
                By signing up for MarketBox, you agree to our{" "}
                <Link
                    href="https://www.gomarketbox.com/privacy"
                    underline="hover"
                    target="_blank"
                    className={classes.link}
                >
                    {"Privacy Policy"}
                </Link>{" "}
                and have read and agreed to our{" "}
                <Link
                    href="https://www.gomarketbox.com/master-service-agreement"
                    underline="hover"
                    target="_blank"
                    className={classes.link}
                >
                    {"Master Service Agreement"}
                </Link>
            </Typography>
        </Grid>
    );

    const SuccessDisplay = ({ sessionId }) => {
        return (
            <Grid
                container
                style={{
                    justifyContent: "center",
                    padding: "18px 42px 0px 42px",
                    gap: "6px"
                }}
            >
                <Button
                    id="stripe-create-subscription"
                    className={classes.button}
                    style={{ width: props.width < 550 ? "100%" : "" }}
                    disabled
                    onClick={() => props.onValidateUpdate(true)}
                >
                    <Grid container className={classes.buttonLabel}>
                        <Typography
                            className={classes.button_text}
                            style={{
                                fontSize: props.width < 400 ? "18px" : ""
                            }}
                        >
                            {`Setting up your account`}
                        </Typography>
                        <LoadingIcon />
                    </Grid>
                </Button>
            </Grid>
        );
    };

    async function handleSubmitSubscription() {
        await SignInAsGuest();
        try {
            let stripeClientResponse;
            let body = {
                type: "hidden",
                name: "lookup_key",
                value: props.plan,
                planType: props.planType,
                quantity: props.quantity,
                email: props.reactivate
                    ? emailAddress
                    : sessionStorage.getItem("email")
                      ? sessionStorage.getItem("email")
                      : props.email,
                companyData: JSON.stringify(companyData),
                MBSessionId: userInputContext.sessionId,
                reactivate: props.reactivate ? true : false
            };

            stripeClientResponse = await postApi(
                "stripesavecard",
                "/stripe-create-subscription",
                {
                    body
                }
            );

            sessionStorage.removeItem("validating");
            window.location.href = stripeClientResponse.response.stripeUrl;
        } catch (e) {
            console.log("error while creating mb stripe customer", e);
            Sentry.captureException(e);
        }
    }

    return (
        <Fragment>
            {!query.get("success") && <CheckoutDisplay />}
            {query.get("success") == "true" &&
                query.get("session_id") !== "" && (
                    <SuccessDisplay sessionId={sessionId} />
                )}
        </Fragment>
    );
};

export default StripeCheckout;
