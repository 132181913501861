import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    IconButton,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import { SmallSpinner } from "../utils/CommonComponents/Spinner";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ExportDateRangePicker from "../utils/CommonComonents/ExportDateRangePicker";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { userHasAdminRole } from "../user/UserCommon";
import { prepareBookingDateTimeDisplay } from "./BookingsListForm";
import { getUserFromCache } from "../user/UserCommon";

const ExportExpiredBookings = () => {
    const classes = usePromotionsStyles();
    const [showExport, setShowExport] = useState(false);
    const [preparingExportData, setPreparingExportData] = useState(false);
    const [showExportSpinner, setShowExportSpinner] = useState(false);
    const [exportReadyBookings, setExportReadyBookings] = useState();
    const [exportFromDate, setExportFromDate] = useState(new Date());
    const [exportToDate, setExportToDate] = useState(
        new Date(Date.now() + 86400000 * 15)
    );
    const csvHeaders = [
        { label: "ID", key: "ID" },
        { label: "Service Name", key: "ServiceName" },
        { label: "Start Date", key: "StartDate" },
        { label: "Date Created", key: "DateCreated" },
        { label: "Booking Time", key: "BookingTime" },
        { label: "Duration", key: "Duration" },
        { label: "Booking Location", key: "BookingLocation" },
        { label: "Booking Prov State", key: "BookingProvState" },
        { label: "Booking Postal Code", key: "BookingPostalCode" },
        { label: "Booking Country", key: "BookingCountry" },
        { label: "Status", key: "Status" },
        { label: "Initial Charge", key: "InitialCharge" },
        { label: "Client Name", key: "ClientName" },
        { label: "Client Email", key: "ClientEmail" },
        { label: "Client Phone", key: "ClientPhone" },
        { label: "Provider Name", key: "ProviderName" },
        { label: "Provider Email", key: "ProviderEmail" },
        { label: "ProviderPhone", key: "ProviderPhone" }
    ];

    const handleExportClick = () => {
        setShowExport(true);
    };

    async function readNonpendingBookingRequests() {
        if (userHasAdminRole()) {
            const authuser = getUserFromCache();
            let PK;
            let userREQs = [];
            PK = `UNSUCCESSFUL-C-${authuser.company.id}`;
            let recordType = {
                between: [
                    `${moment(exportFromDate).format("YYYY-MM-DDT00:00:00Z")}`,
                    `${moment(exportToDate).format("YYYY-MM-DDT23:59:59Z")}`
                ]
            };
            let nextToken = null;
            do {
                let result = await graphql(
                    graphqlOperation(queries.listBookingRequests, {
                        id: PK,
                        recordType,
                        limit: process.env.REACT_APP_LISTLIMIT,
                        sortDirection: "DESC",
                        nextToken
                    })
                );
                console.log("This is the results", result);
                if (result && result.data && result.data.listBookingRequests) {
                    userREQs.push(...result.data.listBookingRequests.items);
                    if (result.data.listBookingRequests.nextToken) {
                        nextToken = result.data.listBookingRequests.nextToken;
                    } else {
                        nextToken = null;
                    }
                } else nextToken = null;
            } while (nextToken);
            if (userREQs.length) {
                userREQs = userREQs.sort((a, b) => {
                    const t1 = new Date(a.createdAt).getTime();
                    const t2 = new Date(b.createdAt).getTime();
                    return t2 - t1;
                });
            }

            const REQs = [];

            for (let cReq of userREQs) {
                try {
                    let data = JSON.parse(cReq.data);
                    cReq.data = data.REQ.data;
                    cReq.id = data.REQ.id;
                    cReq.recordType = data.REQ.recordType;
                    cReq.providerDetails = prepareProviderDetails(cReq.data);
                    cReq.clientDetails = prepareClientDetails(cReq.data);
                    cReq.bookingType = prepareBookingTypeDesc(cReq.data.sdt);
                    cReq.serviceAndPackage = cReq.data.serviceType.name;
                    cReq.bookingLoc = cReq.data.bookingAddress.addrOneLine;
                    cReq.tryOtherProviders = cReq.data.tryOtherProviders
                        ? "Yes"
                        : "No";
                    cReq.numberOfOthers = countOtherProviders(cReq.data);

                    let bookingTimesInfo = prepareBookingDateTimeDisplay(
                        cReq.data
                    );
                    cReq.bookingTimes = bookingTimesInfo.bookingTimes;
                    cReq.numberOfBookings = bookingTimesInfo.numberOfBookings;
                    cReq.createdDesc = calculateBookingReqExpiry(cReq);
                    REQs.push(cReq);
                } catch (e) {
                    console.log(e);
                }
            }
            return REQs;
        } else {
            return [];
        }
    }
    const csvData = (bookingList) => {
        try {
            let rows = [];
            if (bookingList.items && bookingList.items.length > 0) {
                bookingList.items.map(async (item) => {
                    enrichBookingData(item);
                });
                rows = bookingList.items;
            }
            console.log("These are the rows", rows[0]);
            const csvData = rows.map((item) => {
                const {
                    id: ID,
                    serviceName: ServiceName,
                    startDateDesc: StartDate,
                    dateCreated: DateCreated,
                    bookingTime: BookingTime,
                    duration: Duration,
                    bookingAddressoneLine: BookingLocation,
                    bookingState: BookingProvState,
                    bookingPostalCode: BookingPostalCode,
                    bookingCountry: BookingCountry,
                    status: Status,
                    initialCharge: InitialCharge,
                    clientName: ClientName,
                    clientEmail: ClientEmail,
                    clientPhone: ClientPhone,
                    providerName: ProviderName,
                    providerEmail: ProviderEmail,
                    providerPhone: ProviderPhone
                } = item;
                const newItem = {
                    ID,
                    ServiceName,
                    StartDate,
                    DateCreated,
                    BookingTime,
                    Duration,
                    BookingLocation,
                    BookingProvState,
                    BookingPostalCode,
                    BookingCountry,
                    Status,
                    InitialCharge,
                    ClientName,
                    ClientEmail,
                    ClientPhone,
                    ProviderName,
                    ProviderEmail,
                    ProviderPhone
                };
                console.log("This is the newItem", newItem);
                return newItem;
            });
            if (csvData && csvData.length) return csvData;
        } catch (e) {
            console.log(e);
        }
    };

    function enrichBookingData(item) {
        let bookingAddress = item.data.bookingAddress;
        let TimeDisplayInfo = item.bookingTimes[0];
        item.serviceName = item.data.serviceType.name;
        item.startDateDesc = moment(TimeDisplayInfo).format(
            "ddd, MMM Do YYYY, h:mm a"
        );
        item.bookingTime = moment(item.bookingTimes[0]).format("hh:mm A");
        item.dateCreated = moment(item.createdAt).format("YYYY-MM-DD hh:mm A");
        item.duration = item.data.serviceType.minutes;
        item.bookingAddressoneLine = bookingAddress
            ? bookingAddress.addrOneLine
            : "";
        item.bookingState = bookingAddress ? bookingAddress.state : "";
        item.bookingPostalCode = bookingAddress
            ? bookingAddress.postalCode
            : "";
        item.bookingCountry = bookingAddress ? bookingAddress.country : "";
        item.initialCharge = item.data.osd.total;
        item.clientName = `${
            item.data.client && item.data.client.user
                ? item.data.client.user.firstname
                : ""
        } ${
            item.data.client && item.data.client.user
                ? item.data.client.user.lastname
                : ""
        }`;
        item.clientEmail =
            item.data.client &&
            item.data.client.user &&
            item.data.client.user.emailaddress
                ? item.data.client.user.emailaddress
                : "N/A";

        item.clientPhone =
            item.data.client.user.phonepref == "MOBILE"
                ? item.data.client.user.mobilephone
                : item.data.client.user.phonepref == "HOME"
                  ? item.data.client.user.homephone
                  : item.data.client.user.phonepref == "WORK"
                    ? item.data.client.user.workphone
                    : "Number Missing";
        item.providerName =
            item.data.provider &&
            `${item.data.provider.firstname} ${item.data.provider.lastname}`;
        item.providerEmail = item.data.provider.emailaddress;
        item.providerPhone = item.data.provider.phone;
        return item;
    }
    function prepareProviderDetails(bookingReqData) {
        let providerName = "";
        providerName = `${bookingReqData.origProvider.firstname} ${bookingReqData.origProvider.lastname}`;
        return providerName;
    }
    function prepareClientDetails(bookingReqData) {
        let clientNameRmail = "";
        clientNameRmail = `${bookingReqData.user.firstname} ${bookingReqData.user.lastname} (${bookingReqData.user.emailaddress})`;
        return clientNameRmail;
    }
    function prepareBookingTypeDesc(sdt) {
        if (sdt === "forever") {
            return "Regular";
        } else if (sdt === "package") {
            return "Package";
        } else if (sdt === "single") {
            return "Single";
        }
    }
    function calculateBookingReqExpiry(bookingReq) {
        let mCreated = moment(bookingReq.createdAt);
        return `${moment.duration(moment().diff(mCreated)).humanize()} ago`;
    }
    function countOtherProviders(brData) {
        let numberOfOthers = 0;
        if (
            brData &&
            brData.dir &&
            brData.dir.displayed &&
            brData.dir.displayed.length
        ) {
            numberOfOthers += brData.dir.displayed.length;
        }
        if (
            brData &&
            brData.dir &&
            brData.dir.notdisplayed &&
            brData.dir.notdisplayed.length
        ) {
            numberOfOthers += brData.dir.notdisplayed.length;
        }
        return numberOfOthers;
    }

    async function prepareExportData() {
        console.log("Preparing expired exports");
        const bookingsForExport = await readNonpendingBookingRequests();
        console.log("This is the bookingsForExport", bookingsForExport);
        if (bookingsForExport && bookingsForExport.length > 0) {
            const csvDatas = csvData({ items: bookingsForExport });
            setExportReadyBookings(csvDatas);
            return;
        }
        setExportReadyBookings([]);
    }

    return (
        <>
            <Dialog
                open={showExport}
                fullWidth={true}
                maxWidth={"xs"}
                TransitionProps={{
                    onEnter: () => {}
                }}
            >
                <DialogTitle>
                    <Typography
                        sx={{ fontSize: "24px", fontWeight: 400 }}
                        variant="h4"
                    >
                        Export bookings
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setExportReadyBookings([]);
                            setShowExport(false);
                            setPreparingExportData(false);
                        }}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "8px",
                            color: "primary"
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ padding: 24 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginBottom: "16px" }}
                        >
                            {`Please select a date range and click 'Prepare Export'.`}
                        </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                            <ExportDateRangePicker
                                setFrom={setExportFromDate}
                                setTo={setExportToDate}
                                from={exportFromDate}
                                to={exportToDate}
                                style={{ margin: "8px" }}
                            />
                            {/* </MuiPickersUtilsProvider> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={preparingExportData}
                                onClick={async () => {
                                    setExportReadyBookings([]);
                                    setShowExportSpinner(true);
                                    setPreparingExportData(true);
                                    await prepareExportData();
                                    setPreparingExportData(false);
                                    setShowExportSpinner(false);
                                }}
                                style={{ marginTop: "24px" }}
                            >
                                Prepare Export
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {preparingExportData && (
                                <Typography variant="body1">
                                    {`We are preparing your bookings for export.`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {showExportSpinner && <SmallSpinner />}
                        </Grid>
                        {exportReadyBookings &&
                            exportReadyBookings.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {`Please click the download button below to download your bookings.`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CSVLink
                                            data={exportReadyBookings}
                                            headers={csvHeaders}
                                            filename={`bookings_${moment(
                                                Date.now()
                                            ).format("YYYY-MM-DD")}.csv`}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<GetAppIcon />}
                                            >
                                                Download
                                            </Button>
                                        </CSVLink>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </DialogContent>
            </Dialog>
            <ListItem button onClick={() => handleExportClick()}>
                <ListItemIcon>
                    <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Declined/expired bookings" />
            </ListItem>
        </>
    );
};

export default ExportExpiredBookings;
