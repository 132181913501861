import React, { useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import {
    Paper,
    Grid,
    Button,
    IconButton,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from "@mui/material";
import { CustomTooltip, CustomBasicTooltip } from "../styles/CustomMuiStyles";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { ProviderLocationTable } from "../components/ProviderLocationTable";
import { removeLocationSchedules } from "../modules/ScheduleService";
import {
    userHasProviderRole,
    userHasProviderOnlyRole,
    getUserFromCache
} from "../user/UserCommon";
import { auditProviderLocDelete } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { OUTLET_PAGE } from "../context/reducers";
import { useNavigate } from "react-router-dom";
export const ProviderLocationForm = (props) => {
    const navigate = useNavigate();
    const { state, actions } = React.useContext(StoreContext);
    const [selected, setSelected] = useState([]);
    const [values, setValues] = React.useState({
        // active: true,
        searchTerms: ""
    });
    const [rows, setRows] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [providerId, setProviderId] = useState();
    const [provider, setProvider] = useState();

    const [providerName, setProviderName] = useState("");

    // snackbar
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [user, setUser] = useState({});

    const [searchedFor, setSearchedFor] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem("search") === "true") setSearchedFor(true);
        setProviderId(state.provider.id);
        const authuser = getUserFromCache();
        setUser(authuser);
        //setProviderName(localStorage.getItem("providerName"));
        //localStorage.getItem("providerName");
        async function getData() {
            await getLocations();
            //  await getProvider(state.id);
        }
        getData();
        setProvider(state.provider);
        setProviderName(
            state.provider.firstname + " " + state.provider.lastname
        );
    }, []);

    const getLocations = async () => {
        const filter = {
            and: [{ active: { ne: false } }, { deleted: { ne: true } }]
        };

        let locations = await execReadByPK({
            opname: "locationByProvider",
            op: queries.locationByProvider,
            id: { providerId: state.provider.id },
            filter,
            sortDirection: "DESC"
        });

        // const locations = await graphql(
        //     graphqlOperation(queries.listProviderLocations, {
        //         filter,
        //         limit: process.env.REACT_APP_LISTLIMIT
        //     })
        // );

        // const locations = await graphql(
        //   graphqlOperation(queries.locationByProvider, {
        //     providerId: localStorage.getItem("providerId"),
        //     sortDirection: "DESC",
        //     limit: process.env.REACT_APP_LISTLIMIT
        //   })
        // );
        // sort locations
        locations.items.sort((p1, p2) => {
            const psq1 = p1.name.toUpperCase();
            const psq2 = p2.name.toUpperCase();
            if (psq1 === psq2) {
                return p1.quantity - p2.quantity;
            }
            if (psq1 < psq2) return -1;
            if (psq1 > psq2) return 1;
            return 0;
        });

        setRows(locations.items);
    };

    const classes = usePromotionsStyles();

    const handleProviderAddClick = () => {
        if (!!localStorage.getItem("providerId")) {
            actions.setMode("Add");
            actions.setPage("ProviderLocationAddEditForm");
            actions.setProvider(provider);
        } else if (state.provider) {
            actions.setMode("Add");
            actions.setPage("ProviderLocationAddEditForm");
        } else {
            setSnackMsg("Please select provider from header first.");
            setMsgOpen(true);
        }
    };

    const _handleDeleteDialogDecline = () => {
        setDeleteDialog(false);
    };

    const _handleCompanyLocationChange = ({ target: { name, value } }) => {
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value
        }));
    };

    const deleteProviderLocation = async (id) => {
        const loggedInUser = getUserFromCache();
        const input = {
            id: id,
            deleted: true
        };
        try {
            let deleteProviderLocation = await graphql(
                graphqlOperation(mutations.updateProviderLocation, { input })
            );
            if (
                !!deleteProviderLocation.error &&
                deleteProviderLocation.error.length > 0
            ) {
                setSnackMsg("Unable to delete provider location.");
                setMsgOpen(true);
            } else {
                await auditProviderLocDelete(
                    loggedInUser,
                    deleteProviderLocation.data.updateProviderLocation
                );
                await removeLocationSchedules(id, providerId, user.companyId);
                await getLocations();
                setSelected([]);
                actions.setPage("ProviderLocationForm");
            }
        } catch (err) {
            console.log(
                "Unable to delete provider location. Error was " +
                    JSON.stringify(err)
            );
            console.log(err);
            setSnackMsg("Unable to delete provider location.");
            setMsgOpen(true);
        }
    };
    const _handleDeleteDialogAccept = (e) => {
        setDeleteDialog(false);
        if (selected && selected.length > 0) {
            selected.map((item) => {
                deleteProviderLocation(item);
            });
        } else {
            setSnackMsg("Please select entry for performing action..");
            setMsgOpen(true);
        }
    };

    const handleMsgClose = (event, reason) => {
        setMsgOpen(false);
    };

    const _handleLocationCancel = () => {
        sessionStorage.setItem("back", false);
        sessionStorage.setItem("search", false);
        actions.setMode("Add");
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
    };

    const _handleReturnToSearch = () => {
        sessionStorage.setItem("back", true);
        actions.setMode("Add");
        actions.setPage("ProviderForm");
    };

    const _handleBack = () => {
        sessionStorage.setItem("back", true);
        sessionStorage.setItem("search", false);
        actions.setId(state.id);
        actions.setPage(OUTLET_PAGE);
        navigate("/users");
    };

    function displayReturnButton() {
        const loggedInUser = getUserFromCache();
        if (!userHasProviderRole()) {
            return (
                <Grid item>
                    <CustomBasicTooltip title="Returns to the page with the providers matching your search">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleReturnToSearch}
                        >
                            Return to Search
                        </Button>
                    </CustomBasicTooltip>
                </Grid>
            );
        }
    }

    function displayBackButton() {
        const loggedInUser = getUserFromCache();
        if (!userHasProviderOnlyRole()) {
            return (
                <Grid item>
                    <CustomBasicTooltip title="Returns to only the provider you selected">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleBack}
                        >
                            Back
                        </Button>
                    </CustomBasicTooltip>
                </Grid>
            );
        }
    }

    function displayCancelButton() {
        const loggedInUser = getUserFromCache();
        if (!userHasProviderOnlyRole()) {
            return (
                <Grid item>
                    <CustomBasicTooltip title="Returns back to the main provider page">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleLocationCancel}
                        >
                            Cancel
                        </Button>
                    </CustomBasicTooltip>
                </Grid>
            );
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog title="deleteLocation" open={deleteDialog}>
                <DialogTitle id="delete-provider-location-dialog-title">
                    Are you sure you wish to delete the selected provider
                    locations?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting this location will prevent future bookings on
                        any associated schedules. Any existing appointments will
                        not be affected.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={_handleDeleteDialogDecline}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={_handleDeleteDialogAccept}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <FormHeading
                title={"Travel Zones for " + (providerName ? providerName : "")}
                classes={classes}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleProviderAddClick}
                        >
                            <AddIcon className={classes.leftIcon} />
                            &nbsp;Add
                        </Button>
                        <CustomTooltip title="Only add travel zones here. You will be able to add availability related to Company Locations under the Schedules section.">
                            <span>
                                <IconButton aria-label="helpIcon" size="large">
                                    <HelpOutlineIcon />
                                </IconButton>
                            </span>
                        </CustomTooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <EnhancedToolbar
                                numSelected={selected.length}
                                handleDelete={() => setDeleteDialog(true)}
                            />
                            <ProviderLocationTable
                                rows={rows}
                                provider={provider}
                                selectedRow={(selected) =>
                                    setSelected(selected)
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    {searchedFor && displayReturnButton()}
                    &nbsp;
                    {displayBackButton()}
                    &nbsp;
                    {displayCancelButton()}
                </Grid>
            </Paper>
        </>
    );
};
