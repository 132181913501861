import { whiteLabelledCompanies } from "./companyData";

//this will validate based on the id given to the function and return checked being true if the domain is validated for white labeling
//if it is validated for white labeling it will also return the subdomain along
const validateCompany = (id) => {
    //console.log("Is validating running ?");
    let filter = whiteLabelledCompanies.filter((item) => item.id === id);

    if (filter.length > 0) {
        //console.log("Company Validated");
        return { checked: true, subdomain: filter[0].subdomain };
    } else {
        //console.log("Company Not Validated");
        return { checked: false };
    }
};

export default validateCompany;
