// custom listBookingsQuery
// needed to add second level of nesting for user.lastname, user.firstname
export const listBookingsQuery = `query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        desc
        totalAddChg
        location
        orderType
        virtualMeetingInfo
        isVirtual
        servicetype {
          id
          name
          desc
        }
        provider {
          id
          firstname
          lastname
          emailaddress
          ratingstarsavg
        }
        providerId
        client {
          id
          userId
          stripeCustomerId
          user {
            firstname
            lastname
            mobilephone
            homephone
            workphone
            emailaddress  
            role  
            registered          
          }
        }
        clientId
        startdate
        minutes
        company {
          id
          name
          emailaddress
          currency
          countrycode3166alpha2
          replyemailaddress
          primaryColor
          logoUrl
          collectpayment
        }
        order {
          id
          orderNo
          companyId
          bookingAddress
          total
          currency
        }
        companyId
        status
        timezone
        TimeDisplayInfo
        timeblockid
        cancelledAt
        cancelledBy {
          id
          username
          emailaddress
        }
        noshowAt
        noshowBy {
          id
          username
          emailaddress
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
  `;

export const bookingByCompany = /* GraphQL */ `
    query BookingByCompany(
        $companyId: String
        $startdateTimeblockid: ModelBookingByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingByCompany(
            companyId: $companyId
            startdateTimeblockid: $startdateTimeblockid
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderId
                providerId
                clientId
                startdate
                minutes
                totalAddChg
                location
                companyId
                status
                timeblockid
                cancelledAt
                noshowAt
                virtualMeetingInfo
                orderType
                isVirtual
                timezone
                TimeDisplayInfo
                Notes
                createdAt
                updatedAt
                cancelledBy {
                    id
                    username
                    emailaddress
                }
                noshowBy {
                    id
                    username
                    emailaddress
                }
                company {
                    id
                    name
                    emailaddress
                    currency
                    logoUrl
                    primaryColor
                    countrycode3166alpha2
                    replyemailaddress
                    collectpayment
                    stripeAccount
                    stripeConnectEnabled
                    bookingIncrement
                    travelTime
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    ratingstarsavg
                    numberofratings
                    vmlink
                    AcceptanceRatios
                    permalink
                }
                servicetype {
                    id
                    name
                    desc
                    minutes
                    price
                }
                client {
                    id
                    userId
                    stripeCustomerId
                    Notes
                    user {
                        firstname
                        lastname
                        mobilephone
                        homephone
                        workphone
                        phonepref
                        emailaddress
                        role
                        registered
                        contactconsent
                    }
                }
                order {
                    id
                    orderNo
                    companyId
                    total
                    bookingAddress
                    currency
                    Notes
                }
                rating {
                    id
                    providerId
                    ratingstars
                    ratingtext
                    ratinguserId
                    active
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;

export const bookingByProviderFilter = /* GraphQL */ `
    query BookingByProvider(
        $providerId: String
        $startdateTimeblockid: ModelBookingByProviderCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingByProvider(
            providerId: $providerId
            startdateTimeblockid: $startdateTimeblockid
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderId
                providerId
                clientId
                startdate
                minutes
                totalAddChg
                location
                companyId
                status
                timeblockid
                cancelledAt
                noshowAt
                virtualMeetingInfo
                orderType
                isVirtual
                timezone
                TimeDisplayInfo
                Notes
                createdAt
                updatedAt
                cancelledBy {
                    id
                    username
                    emailaddress
                }
                noshowBy {
                    id
                    username
                    emailaddress
                }
                company {
                    id
                    name
                    emailaddress
                    currency
                    logoUrl
                    primaryColor
                    countrycode3166alpha2
                    replyemailaddress
                    collectpayment
                    stripeAccount
                    stripeConnectEnabled
                    bookingIncrement
                    travelTime
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    ratingstarsavg
                    vmlink
                    permalink
                }
                servicetype {
                    id
                    name
                    desc
                    minutes
                    price
                }
                client {
                    id
                    userId
                    stripeCustomerId
                    Notes
                    user {
                        firstname
                        lastname
                        mobilephone
                        homephone
                        workphone
                        phonepref
                        emailaddress
                        role
                        registered
                        contactconsent
                    }
                }
                order {
                    id
                    orderNo
                    companyId
                    total
                    bookingAddress
                    currency
                    Notes
                }
                rating {
                    id
                    providerId
                    ratingstars
                    ratingtext
                    ratinguserId
                    active
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;

export const bookingByProvider = /* GraphQL */ `
    query BookingByProvider(
        $providerId: String
        $startdateTimeblockid: ModelBookingByProviderCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingByProvider(
            providerId: $providerId
            startdateTimeblockid: $startdateTimeblockid
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                location
                servicetype {
                    id
                    name
                    minutes
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                }
                providerId
                client {
                    id
                    userId
                    stripeCustomerId
                    user {
                        firstname
                        lastname
                        mobilephone
                        emailaddress
                        registered
                        contactconsent
                        role
                    }
                }
                clientId
                startdate
                minutes
                order {
                    id
                    currency
                    Notes
                }
                companyId
                company {
                    id
                    name
                    emailaddress
                    currency
                    logoUrl
                    primaryColor
                    countrycode3166alpha2
                    replyemailaddress
                    collectpayment
                    stripeAccount
                    stripeConnectEnabled
                    bookingIncrement
                    travelTime
                }
                status
                timeblockid
                cancelledAt
                cancelledBy {
                    id
                    username
                    emailaddress
                }
                noshowAt
                noshowBy {
                    id
                    username
                    emailaddress
                }
                status
                totalAddChg
                timezone
                TimeDisplayInfo
                Notes
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const companyLocationByCompany = /* GraphQL */ `
    query CompanyLocationByCompany(
        $companyId: String
        $locationnameCreatedAt: ModelCompanyLocationByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCompanyLocationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        companyLocationByCompany(
            companyId: $companyId
            locationnameCreatedAt: $locationnameCreatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                locationname
                latitude
                longitude
            }
            nextToken
        }
    }
`;
/*
export const listOrdersQuery =  `
    query ListOrders(
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                desc
                orderNo
                company {
                    id
                    name
                    contactname
                    emailaddress
                    currency
                }
                companyId
                client {
                    id
                    userId
                    user {
                        firstname
                        lastname
                    }
                }
                clientId
                bookings {
                    nextToken
                }
                clientpackage {
                    id
                    clientId
                    userId
                    servicetypeId
                    packageId
                    initialQuantity
                    usedQuantity
                    status
                    active
                    createdAt
                    updatedAt
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    pictures3key
                }
                providerId
                subtotal
                servicechargeamt
                taxamt
                total
                providerPayAmt
                status
                orderSummary
                orderReceipt
                taxrate
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
*/
export const ordersByCompany = /* GraphQL */ `
    query OrdersByCompany(
        $companyId: String
        $sortDirection: ModelSortDirection
        $filter: ModelCompanyLocationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ordersByCompany(
            companyId: $companyId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderNo
                currency
                company {
                    id
                    name
                    contactname
                    emailaddress
                    currency
                    stripeAccount
                    stripeConnectEnabled
                }
                companyId
                client {
                    id
                    userId
                    user {
                        firstname
                        lastname
                        addressoneline
                        registered
                    }
                }
                clientId
                bookings {
                    nextToken
                }
                clientpackage {
                    id
                    clientId
                    userId
                    servicetypeId
                    packageId
                    initialQuantity
                    usedQuantity
                    status
                    active
                    createdAt
                    updatedAt
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    pictures3key
                }
                providerId
                subtotal
                servicechargeamt
                taxamt
                total
                providerPayAmt
                status
                orderSummary
                taxrate
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const ordersByClientCreatedAt = /* GraphQL */ `
    query OrdersByClientCreatedAt(
        $clientId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ordersByClientCreatedAt(
            clientId: $clientId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderNo
                currency
                bookingAddress
                company {
                    id
                    name
                    contactname
                    emailaddress
                    currency
                    stripeAccount
                    stripeConnectEnabled
                }
                companyId
                client {
                    id
                    userId
                    user {
                        firstname
                        lastname
                        addressoneline
                        homephone
                        workphone
                        mobilephone
                        emailaddress
                        phonepref
                        registered
                    }
                }
                clientId
                bookings {
                    nextToken
                }
                clientpackage {
                    id
                    clientId
                    userId
                    servicetypeId
                    packageId
                    initialQuantity
                    usedQuantity
                    status
                    active
                    createdAt
                    updatedAt
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    pictures3key
                }
                providerId
                subtotal
                servicechargeamt
                taxamt
                total
                providerPayAmt
                status
                orderSummary
                taxrate
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const ordersByProviderCreatedAt = /* GraphQL */ `
    query OrdersByProviderCreatedAt(
        $providerId: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ordersByProviderCreatedAt(
            providerId: $providerId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderNo
                currency
                bookingAddress
                company {
                    id
                    name
                    contactname
                    emailaddress
                    currency
                    stripeAccount
                    stripeConnectEnabled
                }
                companyId
                client {
                    id
                    userId
                    user {
                        firstname
                        lastname
                        addressoneline
                        homephone
                        workphone
                        mobilephone
                        emailaddress
                        phonepref
                        registered
                    }
                }
                clientId
                bookings {
                    nextToken
                }
                clientpackage {
                    id
                    clientId
                    userId
                    servicetypeId
                    packageId
                    initialQuantity
                    usedQuantity
                    status
                    active
                    createdAt
                    updatedAt
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    pictures3key
                }
                providerId
                subtotal
                servicechargeamt
                taxamt
                total
                providerPayAmt
                status
                orderSummary
                taxrate
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const ordersByCompanyOrderNo = /* GraphQL */ `
    query OrdersByCompanyOrderNo(
        $companyId: String
        $orderNo: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ordersByCompanyOrderNo(
            companyId: $companyId
            orderNo: $orderNo
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderNo
                currency
                bookingAddress
                company {
                    id
                    name
                    contactname
                    emailaddress
                    currency
                    stripeAccount
                    stripeConnectEnabled
                }
                companyId
                client {
                    id
                    userId
                    user {
                        firstname
                        lastname
                        addressoneline
                        homephone
                        workphone
                        mobilephone
                        emailaddress
                        phonepref
                        registered
                    }
                }
                clientId
                bookings {
                    nextToken
                }
                clientpackage {
                    id
                    clientId
                    userId
                    servicetypeId
                    packageId
                    initialQuantity
                    usedQuantity
                    status
                    active
                    createdAt
                    updatedAt
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    phone
                    addressoneline
                    street
                    city
                    state
                    country
                    postalcode
                    pictures3key
                }
                providerId
                subtotal
                servicechargeamt
                taxamt
                total
                providerPayAmt
                status
                orderSummary
                taxrate
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const searchByName = /* GraphQL */ `
    query SearchByName(
        $companyId: String
        $lc_name: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        searchByName(
            companyId: $companyId
            lc_name: $lc_name
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                addressoneline
                emailaddress
                firstname
                lastname
            }
            nextToken
        }
    }
`;
export const searchByLastName = /* GraphQL */ `
    query SearchByLastName(
        $companyId: String
        $lc_ln: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        searchByLastName(
            companyId: $companyId
            lc_ln: $lc_ln
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                emailaddress
                firstname
                lastname
            }
            nextToken
        }
    }
`;
export const clientByUserId = /* GraphQL */ `
    query ClientByUserId(
        $userId: String
        $id: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelClientFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientByUserId(
            userId: $userId
            id: $id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                userId
                Notes
            }
            nextToken
        }
    }
`;

export const userByCompany = /* GraphQL */ `
    query UserByCompany(
        $companyId: String
        $roleEmailaddress: ModelUserByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userByCompany(
            companyId: $companyId
            roleEmailaddress: $roleEmailaddress
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                addressoneline
                emailaddress
                firstname
                lastname
                active
                deleted
                registered
            }
            nextToken
        }
    }
`;
export const bookingByClientFilter = /* GraphQL */ `
    query BookingByClient(
        $clientId: String
        $startdateTimeblockid: ModelBookingByClientCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingByClient(
            clientId: $clientId
            startdateTimeblockid: $startdateTimeblockid
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                desc
                orderId
                providerId
                clientId
                startdate
                minutes
                totalAddChg
                location
                companyId
                status
                timeblockid
                cancelledAt
                noshowAt
                virtualMeetingInfo
                orderType
                isVirtual
                timezone
                TimeDisplayInfo
                Notes
                createdAt
                updatedAt
                cancelledBy {
                    id
                    username
                    emailaddress
                }
                noshowBy {
                    id
                    username
                    emailaddress
                }
                company {
                    id
                    name
                    emailaddress
                    currency
                    logoUrl
                    primaryColor
                    countrycode3166alpha2
                    replyemailaddress
                    stripeAccount
                    stripeConnectEnabled
                    bookingIncrement
                    travelTime
                }
                provider {
                    id
                    firstname
                    lastname
                    emailaddress
                    ratingstarsavg
                    vmlink
                    permalink
                }
                servicetype {
                    id
                    name
                    desc
                    minutes
                    price
                }
                client {
                    id
                    userId
                    stripeCustomerId
                    Notes
                    user {
                        firstname
                        lastname
                        mobilephone
                        homephone
                        workphone
                        phonepref
                        emailaddress
                        role
                        registered
                    }
                }
                order {
                    id
                    orderNo
                    companyId
                    total
                    bookingAddress
                    currency
                    Notes
                }
                rating {
                    id
                    providerId
                    ratingstars
                    ratingtext
                    ratinguserId
                    active
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const providerByCompany = /* GraphQL */ `
    query ProviderByCompany(
        $companyId: String
        $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        providerByCompany(
            companyId: $companyId
            firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstname
                lastname
                emailaddress
                active
                deleted
            }
            nextToken
        }
    }
`;

export const searchProviderByLastName = /* GraphQL */ `
    query SearchProviderByLastName(
        $companyId: String
        $lc_ln: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        searchProviderByLastName(
            companyId: $companyId
            lc_ln: $lc_ln
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstname
                lastname
                emailaddress
                active
                deleted
            }
            nextToken
        }
    }
`;

export const searchByFirstName = /* GraphQL */ `
    query SearchByFirstName(
        $companyId: String
        $lc_fn: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProviderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        searchByFirstName(
            companyId: $companyId
            lc_fn: $lc_fn
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstname
                lastname
                emailaddress
                active
                deleted
            }
            nextToken
        }
    }
`;

export const listBookingStatuss = /* GraphQL */ `
    query ListBookingStatuss(
        $companyId: ID
        $status: ModelStringKeyConditionInput
        $filter: ModelBookingStatusFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listBookingStatuss(
            companyId: $companyId
            status: $status
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                companyId
                status
                bookingId
                createdAt
                updatedAt
                booking {
                    id
                    desc
                    orderId
                    providerId
                    clientId
                    startdate
                    minutes
                    totalAddChg
                    location
                    companyId
                    status
                    timeblockid
                    cancelledAt
                    noshowAt
                    virtualMeetingInfo
                    timezone
                    TimeDisplayInfo
                    orderType
                    isVirtual
                    manualBooking
                    servicetype {
                        id
                        name
                        desc
                        minutes
                        price
                        active
                        deleted
                    }
                    Notes
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
