import { CloseRounded } from "@mui/icons-material";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Snackbar,
    Typography
} from "@mui/material";
import {
    graphql,
    graphqlOperation,
    postApi,
    getJsonApi
} from "../../modules/AmplifyServices";
import { getCurrentUser } from "aws-amplify/auth";
import axios from "axios";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

import React, { useEffect, useState } from "react";
import BookingTab from "./BookingTab";
import BrandingTab from "./BrandingTab";
import DomainTab from "./DomainTab";
import {
    getUserFromCache,
    updateCompanyObjectInCachedUser
} from "../../user/UserCommon";
import { auditCompanyUpdate } from "../../modules/Audit";
import validateCompany from "../../utils/WhiteLabelling";

import { useAtom } from "jotai";
import { brandingStatesAtom, domainStatesAtom, bookingStatesAtom, desiredSubdomainAtom, bookingFlowSettingsFilesAtom } from "../../atoms/bookingFlowCustomization";
import { defaultBookingStates, defaultBrandingStates, defaultDomainStates } from "../../utils/Constants";

const styles = {
    textField: {
        backgroundColor: "white",
        border: "2px solid #d4d4d4",
        borderRadius: "4px",
        "&:hover": { backgroundColor: "white" },
        "& .MuiFilledInput-root": {
            "&:hover": { backgroundColor: "white" },
            backgroundColor: "white",
            "&.Mui-focused": {
                backgroundColor: "white"
            },
            "& input[type='color']": {
                width: "4rem"
            },
            "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
                marginTop: "0px !important"
            },
            "& .MuiFilledInput-input.MuiInputBase-input": {
                paddingTop: "14px !important",
                paddingBottom: "14px !important"
            }
        },
        " & .MuiInputLabel-root": { display: "none" },
        "& fieldset": { top: 0 }
    },
    selectField: {
        backgroundColor: "white",
        border: "2px solid #d4d4d4",
        borderRadius: "4px",
        "&:hover": {
            backgroundColor: "white"
        },

        "&.Mui-focused": {
            backgroundColor: "white",
            "& .MuiSelect-select": {
                backgroundColor: "white"
            }
        },
        " & label": { display: "none" },
        "& fieldset": { top: 0 },
        "& .MuiSelect-select.MuiSelect-filled.MuiFilledInput-input": {
            paddingTop: "14px !important",
            paddingBottom: "14px !important"
        }
    },
    heading: {
        fontWeight: 700,
        fontSize: "0.875rem",
        color: "rgba(0,0,0,0.75)"
    },
    subHeading: {
        fontWeight: 400,
        fontSize: "0.875rem",
        color: "rgba(0,0,0,0.75)"
    },
    subHeadingInfo: {
        fontWeight: 400,
        fontSize: "0.75rem",
        color: "rgba(0,0,0,0.5)"
    },
    uploadLinks: {
        color: "primary.main",
        fontWeight: 700,
        fontSize: "1rem",
        cursor: "pointer"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%"
    },
    subContainer: {
        display: "flex",
        gap: "1rem",
        width: "100%"
    },
    topRow: {
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
    }
};

const fontNames = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lato",
    "Lobster",
    "Merriweather Sans",
    "Nunito",
    "Open Sans",
    "Pacifico",
    "Raleway",
    "Roboto",
    "Source Sans Pro",
    "Tahoma",
    "Times New Roman",
    "Titillium Web",
    "Ubuntu",
    "Verdana",
    "Work Sans"
];

function BookingFlowSettings(props) {
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    //tabValues: branding, domain, booking
    const [tabValue, setTabValue] = useState("branding");
    const [loading, setLoading] = useState(false);
    const [logoFile, setLogoFile] = useState("");
    const [backgroundFile, setBackgroundFile] = useState("");
    const [currentTosFile, setCurrentTosFile] = useState(null);
    const [publicInputId, setPublicInputId] = useState("");
    const [companyData, setCompanyData] = useState(null);
    const [desiredSubdomain, setDesiredSubdomain] = useAtom(desiredSubdomainAtom);
    const [oldSubdomain, setOldSubdomain] = useState("");
    const [showTos, setShowTos] = useState(false);
    const [oldTosURL, setOldTosUrl] = useState("");

    const [brandingStates, setBrandingStates] = useAtom(brandingStatesAtom);
    const [domainStates, setDomainStates] = useAtom(domainStatesAtom);
    const [bookingStates, setBookingStates] = useAtom(bookingStatesAtom);
    const [bookingFlowSettingsFiles, setBookingFlowSettingsFiles] = useAtom(bookingFlowSettingsFilesAtom);

    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const loggedInUser = getUserFromCache();

    const [oldInfo, setOldInfo] = useState([{}]);

    useEffect(() => {
        //get data from company table and public site table
        (async () => {
            try {
                await getPublicSiteData();
            } catch (err) {
                console.log("Use Effect getPublicSite Data Error", err);
            }
            try {
                await getCompanyData();
            } catch (err) {
                console.log("Use Effect getCompanyData Error", err);
            }
        })();
    }, []);

    useEffect(() => {
        console.log("Domain States", domainStates);
        if (domainStates.subdomainError || domainStates.taglineError) {
            setDisableSaveButton(true);
        } else {
            setDisableSaveButton(false);
        }
    }, [domainStates]);

    useEffect(() => {
        console.log("Booking Flow Setting Props", props);
    }, [props]);

    const fromDashboard = () => {
        if (props?.originPath === "DashboardForm") {
            return true;
        }

        return false;
    };

    const resetStates = (arg) => {
        if (arg === "onlyBranding") {
            setBrandingStates((prevState) => {
                return {
                    ...defaultBrandingStates,
                    logoURL: prevState.logoURL,
                    backgroundURL: prevState.backgroundURL
                };
            });
            return;
        }
        setBrandingStates((prevState) => {
            return {
                ...defaultBrandingStates,
                logoURL: prevState.logoURL,
                backgroundURL: prevState.backgroundURL
            };
        });

        setDomainStates(defaultDomainStates);
        setBookingStates(defaultBookingStates);
        setOldSubdomain("");
        setDesiredSubdomain("");
        setCurrentTosFile(null);
        setOldTosUrl("");
    };

    const getCompanyData = async () => {
        const input = { id: props.compId };
        const publicInput = { companyId: props.compId };

        const result = await graphql(
            graphqlOperation(queries.getCompany, input)
        );

        const {
            data: {
                publicsiteByCompany: { items: [publicSiteData = {}] = [] } = {}
            } = {}
        } = await graphql(
            graphqlOperation(queries.publicsiteByCompany, publicInput)
        );

        setCompanyData(result.data.getCompany);
        //checking for validation
        validateCompany(result.data.getCompany.id);
    };

    const getPublicSiteData = async () => {
        const {
            data: {
                publicsiteByCompany: { items: [publicSiteData = {}] = [] } = {}
            } = {}
        } = await graphql(
            graphqlOperation(queries.publicsiteByCompany, {
                companyId: props.compId
            })
        );

        setPublicInputId(publicSiteData.id);
    };

    //const loggedInUser = getUserFromCache();
    function handleMsgClose(event, reason) {
        setMsgOpen(false);
    }

    async function reSaveCompanyInfoForLandingPage(company) {
        try {
            let companyInfo = {};

            for (let prop in company) {
                if (
                    "DashboardInfo" !== prop &&
                    "createdAt" !== prop &&
                    "updatedAt" !== prop
                )
                    companyInfo[prop] = company[prop];
            }
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `domain|${company.subdomain}`,
                    refName: "data-company"
                })
            );
            // console.log("currency", result);
            let refVal = exists.data.getRefData
                ? exists.data.getRefData.refValue
                : null;
            if (refVal)
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
            else
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `domain|${company.subdomain}`,
                            refName: "data-company",
                            refValue: "company",
                            overrideValue: JSON.stringify(companyInfo)
                        }
                    })
                );
        } catch (e) {
            console.log("saving company info to refdata failed");
        }
    }
    async function reSaveCompanyPublicSiteInfoForLandingPage(publicSite) {
        try {
            const result = await graphql(
                graphqlOperation(queries.getCompany, {
                    id: publicSite.companyId
                })
            );

            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `domain|${result.data.getCompany.subdomain}`,
                    refName: "data-publicSite"
                })
            );
            // console.log("currency", result);
            let refVal = exists.data.getRefData
                ? exists.data.getRefData.refValue
                : null;
            let overrideValue = {
                backgroundImageUrl: publicSite.backgroundImageUrl,
                heading1Text: publicSite.heading1Text,
                heading2Text: publicSite.heading2Text,
                textColor: publicSite.textColor,
                buttonColor: publicSite.buttonColor
            };
            if (
                publicSite.StyleCustomization &&
                publicSite.StyleCustomization !== null
            )
                overrideValue.StyleCustomization =
                    publicSite.StyleCustomization;
            if (refVal) {
                await graphql(
                    graphqlOperation(mutations.updateRefData, {
                        input: {
                            refType: `domain|${result.data.getCompany.subdomain}`,
                            refName: "data-publicSite",
                            refValue: "publicSite",
                            overrideValue: JSON.stringify(overrideValue)
                        }
                    })
                );
            } else {
                await graphql(
                    graphqlOperation(mutations.createRefData, {
                        input: {
                            refType: `domain|${result.data.getCompany.subdomain}`,
                            refName: "data-publicSite",
                            refValue: "publicSite",
                            overrideValue: JSON.stringify(overrideValue)
                        }
                    })
                );
            }
        } catch (e) {
            console.log("saving company public site info to refdata failed");
        }
    }

    const brandingStatesChangeHandler = async ({
        target: { name, value, type, files, checked }
    }) => {
        if (type === "checkbox") {
            value = checked;
        }
        if (
            type === "file" &&
            (name === "logoURL" || name === "backgroundURL") &&
            files?.length &&
            files[0]
        ) {
            let file_url = files[0];
            if (name === "logoURL") {
                if (props.mode === "Add") {
                    setBookingFlowSettingsFiles((prevState) => {
                        return {
                            ...prevState,
                            logoFile: file_url
                        }
                    })

                    setBrandingStates ((prevState) => {
                        return { ...prevState, logoURL: URL.createObjectURL(file_url)}
                    })
                } else {
                    let uploadLogoURL = await uploadFile(file_url, "logo", props.compId);
                    if (uploadLogoURL) {
                        setBrandingStates ((prevState) => {
                            return { ...prevState, logoURL: uploadLogoURL}
                        })
                    }
                } 
            }
            if (name === "backgroundURL") {
                if (props.mode === "Add") {
                    setBookingFlowSettingsFiles((prevState) => {
                        return {
                            ...prevState,
                            backgroundFile: file_url
                        }
                    })

                    setBrandingStates ((prevState) => {
                        return { ...prevState, backgroundURL: URL.createObjectURL(file_url)}
                    })
                } else {
                    let uploadBackgroundURL = await uploadFile(
                        file_url,
                        "backgroundImage",
                        props.compId
                    );
    
                    if (uploadBackgroundURL) {
                        setBrandingStates ((prevState) => {
                            return { ...prevState, backgroundURL: uploadBackgroundURL}
                        })
                    }
                }   
            }
        } else {
            setBrandingStates((prevState) => {
                return { ...prevState, [name]: value };
            });
        }
    };

    const domainStatesChangeHandler = (e) => {
        //let aquaMobileCompanyID = "c455bbc3-5147-4c37-9019-bc35eb2c26eb";
        let { name, value } = e.target;
        let subDerror = domainStates.subdomainError;
        let tagError = domainStates.taglineError;
        if (name === "subdomain") {
            value = value.toLowerCase();
            let valuePrefixed = `${value}.gomarketbox.com`;
            //checking for whitelabelling
            let checkingForWL = validateCompany(companyData?.id);
            if (checkingForWL?.checked) {
                //validateCompany(companyData.id);
                setDesiredSubdomain(checkingForWL.subdomain);
            } else {
                if (isValidHostname(valuePrefixed)) {
                    setDesiredSubdomain(
                        (prevState) => `${value}.gomarketbox.com`
                    );

                    setDomainStates((prevState) => {
                        return {
                            ...prevState,
                            [name]: value,
                            subdomainError: false
                        };
                    });
                } else {
                    if (value.trim().length <= 0 && props.mode === "Add") {
                        setDomainStates((prevState) => {
                            return {
                                ...prevState,
                                [name]: value,
                                subdomainError: false
                            };
                        });
                        setDesiredSubdomain("");
                    } else {
                        setDomainStates((prevState) => {
                            return {
                                ...prevState,
                                [name]: value,
                                subdomainError: true,
                                subdomainErrorMessage:
                                    'Subdomain cannot contain special character, except "-"'
                            };
                        });
                    }   
                }

                return;
            }
        }

        if (name === "tagline") {
            if (value.trim().length <= 0) {
                console.log("I am in the tagline whitespace", value);
                setDomainStates((prevState) => {
                    return {
                        ...prevState,
                        [name]: value,
                        taglineError: (props.mode !== "Add") && true,
                        taglineErrorMessage: (props.mode !== "Add") && "Title Tag cannot be empty"
                    };
                });
            } else {
                setDomainStates((prevState) => {
                    return {
                        ...prevState,
                        [name]: value,
                        taglineError: false,
                    };
                });
            }
            
            return
        }

        setDomainStates((prevState) => {
            return {
                ...prevState,
                [name]: value,
                subdomainError: subDerror,
                taglineError: tagError
            };
        });
        console.log("Domain States", name, value, domainStates);
    };

    const bookingStatesChangeHandler = async ({
        target: { name, value, type, files, checked }
    }) => {
        if (type === "checkbox") {
            value = checked;
        }

        if (type === "file" && name === "tosURL" && files?.length && files[0]) {
            const file = files[0];
            if (file.type === "application/pdf") {
                setCurrentTosFile(file);

                if (props.mode === "Add") {
                    setBookingFlowSettingsFiles((prevState) => {
                        return {
                            ...prevState,
                            tosFile: file
                        }
                    })
                } else {
                    const newTOSURL = await uploadFile(
                        file,
                        "tos",
                        props.compId
                    );
                    if (newTOSURL) {
                        setOldTosUrl(newTOSURL);
                        setBookingStates ((prevState) => {
                            return { ...prevState, tosURL: newTOSURL}
                        })
                    }
                }
            }
        } else {
            setBookingStates((prevState) => {
                return { ...prevState, [name]: value };
            });
        }
        
    };

    const openCloseTosHandler = (arg) => {
        if (arg === "open") {
            setShowTos(true);
            return;
        }
        setShowTos(false);
    };

    async function uploadFile(file, fileType, companyIdValue) {
        // console.log("Uploading", file, fileType);
        try {
            let fileName = "";
            if (fileType === "logo") {
                const fileExtension = file.name.substring(
                    file.name.lastIndexOf(".") + 1
                );
                fileName = `${companyIdValue.toString()}-${Date.now()}-logo.${fileExtension}`;
            } else if (fileType === "tos") {
                fileName = companyIdValue.toString() + "-tos.pdf";
            } else if (fileType === "backgroundImage") {
                const fileExtension = file.name.substring(
                    file.name.lastIndexOf(".") + 1
                );
                fileName = `${companyIdValue.toString()}-${Date.now()}-backgroundImage.${fileExtension}`;
            } else throw new Error("Unsupported file type.");
            const response = await postApi("s3filemgmt", "/upload", {
                body: {
                    companyId: props.compId,
                    fileName: fileName,
                    fileType: fileType
                }
            });

            var options = {
                headers: {
                    "Content-Type": file.type,
                    "x-amz-acl": "public-read"
                }
            };
            const result = await axios.put(response.uploadURL, file, options);
            const url = response.uploadURL.split("?");

            return url[0];
        } catch (err) {
            console.log("File upload error", err);
        }
    }

    async function isSubdomainProvisioned(subdomain) {
        //get secvalue
        console.log("subdomain provision", subdomain);
        const yesno = await getJsonApi("adminapi", `/subdomain`, {
            queryParams: {
                subdomain
            }
        });
        return yesno && yesno.value;
    }

    async function createScheduleIndex(companyId) {
        const result = await getJsonApi("searchapi", `/index/${companyId}`);
        if (result.success && !result.exists) {
            await postApi("searchapi", "/index", {
                body: {
                    companyId
                }
            });
        }
    }

    function isValidHostname(value) {
        if (typeof value !== "string") return false;

        const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
        if (!validHostnameChars.test(value)) {
            return false;
        }

        if (value.endsWith(".")) {
            value = value.slice(0, value.length - 1);
        }

        if (value.length > 253) {
            return false;
        }

        const labels = value.split(".");

        const isValid = labels.every(function (label) {
            const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

            const validLabel =
                validLabelChars.test(label) &&
                label.length < 64 &&
                !label.startsWith("-") &&
                !label.endsWith("-");

            return validLabel;
        });

        return isValid;
    }
    function getSubdomainPrefix(subdomain) {
        //.gomarketbox.com
        return subdomain.substring(
            0,
            subdomain.lastIndexOf(".gomarketbox.com")
        );
    }

    const addCompany = async () => {
        let response, publicResponse;
        // let aquaMobileCompanyID = "c455bbc3-5147-4c37-9019-bc35eb2c26eb";
        let checkingForWL = validateCompany(companyData.id);

        //let compID = props.compId;
        let newInfo = [
            {
                subdomain: "No Change",
                tagline: "No Change",
                heading1Text: "No Change",
                heading2Text: "No Change",
                noProviderMsg: "No Change",
                providerRatios: "No Change"
            }
        ];

        let tabValue = 3;
        let input = {
            logoUrl: "",
            homepage: ""
        };
        console.log("Input to company table ln 704", input);

        input.logoUrl = brandingStates.logoURL;

        input.homepage = domainStates.homepageURL;

        if (brandingStates.companyTagline != oldInfo[0].heading1text) {
            newInfo.map((item) => {
                item.heading1Text = brandingStates.companyTagline;
            });
        }
        if (brandingStates.companyDescription != oldInfo[0].heading2text) {
            newInfo.map((item) => {
                item.heading2Text = brandingStates.companyDescription;
            });
        }

        console.log("company save branding state: ", brandingStates);
        console.log("company save domain state: ", domainStates);
        console.log("company save booking state: ", bookingStates);
        console.log("company save old info: ", oldInfo);
        console.log("company save new info: ", newInfo);

        //setOldInfo(updateOldInfo);

        console.log("Input to company table ln 728", input);
        try {
            let compID = props.compId;
            response = await graphql(
                graphqlOperation(mutations.updateCompany, {
                    input: { id: compID, ...input }
                })
            );
            console.log("Response from saving logoUrl", response);
            await updateCompanyObjectInCachedUser(
                response?.data?.updateCompany
            );

            newInfo = Object.fromEntries(
                Object.entries(newInfo[0]).filter(([_, v]) => v !== "No Change")
            );

            if (Object.keys(newInfo).length !== 0) {
                await auditCompanyUpdate(
                    loggedInUser,
                    response.data.updateCompany,
                    [newInfo],
                    tabValue
                );
            }

            if (response) {
                await reSaveCompanyInfoForLandingPage(
                    response.data.updateCompany
                );
            }
        } catch (err) {
            console.log("Add Company error", err);
        }

        //booking flow / public site settings input
        let publicInput = {
            heading1Text: brandingStates.companyTagline,
            heading2Text: brandingStates.companyDescription,
            buttonColor: brandingStates.buttonColor,
            textColor: brandingStates.headerFontColor,
            companyId: props.compId,
            id: publicInputId,
            //backgroundImageUrl: "" //brandingStates.backgroundURL
            backgroundImageUrl: brandingStates.backgroundURL
        };

        let customValues = {
            bookingFlowCustomizations: {
                mainHeading: brandingStates.companyName,
                backgroundColor: brandingStates.backgroundColor,
                opacity: brandingStates.headerTransparency,
                fontTypeForAllHeading: brandingStates.headerFontType,
                fontSizeHeader1: brandingStates.companyNameFontSize,
                fontSizeHeader2: brandingStates.companyTaglineFontSize,
                fontSizeHeader3: brandingStates.companyDescriptionFontSize,
                showHeadings: brandingStates.showHeaderText,
                showLogo: brandingStates.showLogo,
                showBackgroundImage: brandingStates.showBackgroundImage,
                circularLogo: brandingStates.logoAsCircle,
                initialSetup: true,
                showHeader: brandingStates.showHeader,
                categoryDisplay: bookingStates.categoryDisplay,
                servicesDisplay: bookingStates.servicesDisplay,
                checkoutOption: bookingStates.checkoutOption,
                showHeadingOne: brandingStates.showHeadingOne,
                showHeadingTwo: brandingStates.showHeadingTwo,
                showHeadingThree: brandingStates.showHeadingThree,
                buttonTextColor: brandingStates.buttonTextColor
            }
        };

        //attach booking flow customizations with publicInput

        publicInput.StyleCustomization = JSON.stringify(customValues);

        //public input try catch block

        try {
            publicResponse = await graphql(
                graphqlOperation(mutations.updateCompanyPublicSite, {
                    input: publicInput
                })
            );

            console.log(
                "Response after saving public site settings",
                publicResponse
            );
            await reSaveCompanyPublicSiteInfoForLandingPage(publicInput);
        } catch (err) {
            console.log("Public Input Saving Error", err);
        }

        console.log("Input and Public Input", input, publicInput);

        const updateOldInfo = Object.assign(oldInfo[0], newInfo);
        setOldInfo([updateOldInfo]);

        if (response && response.data) {
            await createScheduleIndex(response.data.updateCompany.id);
            return true;
        } else {
            console.log("Unable to save the company information ln836");
            return false;
        }
    };
    const saveBookingFlowSettings = async () => {
        console.log(
            "Branding States onSave BookingFlowSettings",
            brandingStates
        );

        // props.loadingON();
        setLoading(true);
       
        let result = await addCompany();
        if (result) {
            //to update 'loggedInMbUser' in local storage upon saving company
            const loggedInUser = await getCurrentUser();
            const { username } = loggedInUser;
            const user = await graphql(
                graphqlOperation(queries.getUser, {
                    id: username
                })
            );
            localStorage.setItem(
                "loggedInMbUser",
                JSON.stringify(user.data.getUser)
            );
            setLoading(false);
            setSnackMsg("Success! Your booking flow settings have been saved.");
            setMsgOpen(true);
            if (props.originPath === "DashboardForm") {
                props.doneTodoHandler("bookingFlow");
                props.close();
            } else {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={handleMsgClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    sx={{
                        color: "rgba(0,0,0,0.75)",
                        fontWeight: 400,
                        fontSize: "24px",
                        marginBottom: "2rem"
                    }}
                >
                    Customize your booking page
                </Typography>
                {props.showClose && (
                    <CloseRounded
                        sx={{
                            width: "2rem",
                            color: "rgba(0,0,0,0.5)",
                            fontWeight: 700,
                            cursor: "pointer"
                        }}
                        onClick={props.close}
                    />
                )}
            </div>

            <Box sx={{ width: "100%" }}>
                {loading && (
                    <Backdrop
                        sx={{ zIndex: 3000, color: "#fff" }}
                        open={loading}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                )}

                <BrandingTab
                    styles={styles}
                    fontNames={fontNames}
                    brandingStates={brandingStates}
                    brandingStatesChangeHandler={brandingStatesChangeHandler}
                    domainStates={domainStates}
                    domainStatesChangeHandler={domainStatesChangeHandler}
                    fromDashboard={fromDashboard}
                />
                {!fromDashboard() && (
                    <Divider sx={{ width: "100%", margin: "1.5rem 0px" }} />
                )}

                {!fromDashboard() && (
                    <DomainTab
                        styles={styles}
                        domainStates={domainStates}
                        domainStatesChangeHandler={domainStatesChangeHandler}
                        companyData={companyData}
                        mode={props.mode}
                    />
                )}
                {!fromDashboard() && (
                    <Divider sx={{ width: "100%", margin: "1.5rem 0px" }} />
                )}

                {!fromDashboard() && (
                    <BookingTab
                        styles={styles}
                        bookingStates={bookingStates}
                        bookingStatesChangeHandler={bookingStatesChangeHandler}
                        bookingFlowSettingsFiles={bookingFlowSettingsFiles}
                        openCloseTosHandler={openCloseTosHandler}
                        showTos={showTos}
                        oldTosURL={oldTosURL}
                        currentTosFile={currentTosFile}
                        mode={props.mode}
                    />
                )}
            </Box>
            <Divider
                sx={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    width: "100%"
                }}
            />

            <Box sx={{ display: "flex", gap: "1rem" }}>
                {
                fromDashboard() && (
                    <Button
                        sx={{ textTransform: "unset" }}
                        variant="contained"
                        onClick={() => saveBookingFlowSettings()}
                        disabled={disableSaveButton}
                    >
                        {props.saveAndClose ? "Save & close" : "Save"}
                    </Button>
                    )
                }
                
                {tabValue === "branding" && (
                    <Button
                        sx={{ textTransform: "unset" }}
                        variant="text"
                        onClick={() => resetStates("onlyBranding")}
                    >
                        Reset to default
                    </Button>
                )}
            </Box>
            {!fromDashboard() && (
                <Divider
                    sx={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        width: "100%"
                    }}
                />
            )}
        </>
    );
}

export default BookingFlowSettings;
