import React from "react";
import { useState, useEffect } from "react";

import {
    FormControl,
    TextField,
    Checkbox,
    Grid,
    FormLabel,
    FormGroup,
    Switch,
    FormControlLabel,
    Paper,
    Button,
    Typography,
    IconButton
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const daysStyleFunction = (val) => {
    return {
        color: val ? "primary.dark" : "rgba(0,0,0,0.75)",
        fontWeight: 700,
        fontSize: "14px",
        borderColor: val ? "primary.dark" : "#5A5A5A",
        borderStyle: "solid",
        borderWidth: "2px",
        padding: "6px 10px",
        width: "32px",
        height: "32px",
        backgroundColor: val ? "primary.light" : "#f4f4f4"
    };
};

export const TimeBlockDisplay = (props) => {
    const [checkedDays, setCheckedDays] = useState(new Array(7).fill(false));
    async function saveAvailability(mode) {
        await props.saveAvailability(props.tb, mode);
    }

    console.log("***Props From TimeBLockDisplay");
    console.log(props);

    useEffect(() => {
        const checkstate_split = [];
        if (props.checkstate && props.checkstate.length > 0) {
            checkedDays.forEach((item, i) => {
                if (props.checkstate.includes(i)) {
                    checkstate_split.push(true);
                } else {
                    checkstate_split.push(false);
                }
            });
            setCheckedDays(checkstate_split);
        }
        console.log("Props CheckState", checkstate_split);
    }, [props.checkstate]);

    function getSelectedDays(val) {
        let checkedDaysArray = [...checkedDays];

        checkedDaysArray[val] = checkedDaysArray[val] ? false : true;
        setCheckedDays((prevState) => checkedDaysArray);
        props.handleChangeModified(val, props.tb, checkedDaysArray[val]);

        // console.log(checkedDays);
        //console.log(props.checkstate);
    }
    return (
        <>
            <Grid
                container
                sx={{
                    backgroundColor: "#ffffff",
                    //padding: "1.75rem",
                    borderRadius: "8px",

                    marginTop: "1.5rem"
                }}
            >
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={customLocale}
                >
                    {!(
                        props.editmode === "AddTb" ||
                        props.editmode === "EditTb"
                    ) && (
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700
                                }}
                            >
                                Set availability
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            paddingRight: "1rem",
                            marginTop: "1rem"
                        }}
                        item
                        xs={6}
                        lg={6}
                    >
                        <DatePicker
                            sx={{ position: "relative" }}
                            label="Start date"
                            format="MMM dd, yyyy"
                            minDateMessage={"Please select a start date."}
                            value={props.dateValues.startDate}
                            onChange={(e) =>
                                props._handleDateChange(
                                    "startDate",
                                    props.tb,
                                    e
                                )
                            }
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                            PopperProps={{
                                disablePortal: true,
                                placement: "bottom-end"
                            }}
                        />

                        <TimePicker
                            sx={{ position: "relative" }}
                            label="Start time"
                            format="hh:mm a"
                            minDateMessage={"Please select a start time."}
                            value={props.timeValues.startTime}
                            disabled={props.allDayState}
                            onChange={(e) =>
                                props._handleTimeChange(
                                    "startTime",
                                    props.tb,
                                    e
                                )
                            }
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                            PopperProps={{
                                disablePortal: true,
                                placement: "bottom-end"
                            }}
                        />
                    </Grid>

                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            paddingLeft: "1rem",
                            marginTop: "1rem"
                        }}
                        item
                        xs={6}
                        lg={6}
                    >
                        <DatePicker
                            sx={{ position: "relative" }}
                            label="End date"
                            format="MMM dd, yyyy"
                            // minDate={props.dateValues.startDate}
                            minDate={
                                new Date(props.dateValues.startDate).valueOf() +
                                1
                            }
                            minDateMessage={
                                "Please select an end date greater than or equal to the start date."
                            }
                            value={props.dateValues.endDate}
                            onChange={(e) =>
                                props._handleDateChange("endDate", props.tb, e)
                            }
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                            PopperProps={{
                                disablePortal: true,
                                placement: "bottom-end"
                            }}
                        />

                        <TimePicker
                            sx={{ position: "relative" }}
                            label="End time"
                            format="hh:mm a"
                            disabled={props.allDayState}
                            minDateMessage={
                                "Please select an end time greater than start time."
                            }
                            value={props.timeValues.endTime}
                            onChange={(e) =>
                                props._handleTimeChange("endTime", props.tb, e)
                            }
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                            PopperProps={{
                                disablePortal: true,
                                placement: "bottom-end"
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={{
                            display: "flex",
                            gap: "0.75rem",
                            marginTop: "1rem"
                        }}
                    >
                        <Typography variant="body2">
                            Repeating on selected days
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            display: "flex",
                            gap: "0.75rem",
                            marginTop: "0.75rem"
                        }}
                        item
                        xs={12}
                        lg={8}
                    >
                        <IconButton
                            sx={daysStyleFunction(checkedDays[0])}
                            onClick={() => getSelectedDays(0)}
                        >
                            S
                        </IconButton>
                        <IconButton
                            sx={daysStyleFunction(checkedDays[1])}
                            onClick={() => getSelectedDays(1)}
                        >
                            M
                        </IconButton>
                        <IconButton
                            sx={daysStyleFunction(checkedDays[2])}
                            onClick={() => getSelectedDays(2)}
                        >
                            T
                        </IconButton>
                        <IconButton
                            sx={daysStyleFunction(checkedDays[3])}
                            onClick={() => getSelectedDays(3)}
                        >
                            W
                        </IconButton>
                        <IconButton
                            sx={daysStyleFunction(checkedDays[4])}
                            onClick={() => getSelectedDays(4)}
                        >
                            T
                        </IconButton>
                        <IconButton
                            sx={daysStyleFunction(checkedDays[5])}
                            onClick={() => getSelectedDays(5)}
                        >
                            F
                        </IconButton>
                        <IconButton
                            sx={daysStyleFunction(checkedDays[6])}
                            onClick={() => getSelectedDays(6)}
                        >
                            S
                        </IconButton>
                    </Grid>

                    {!props.fromDashboard && (
                        <Grid item xs={12} lg={8} sx={{ marginTop: "1rem" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={props.tbactive}
                                        onChange={(event) =>
                                            props.setTbActive(
                                                event.target.checked,
                                                props.tb
                                            )
                                        }
                                        value="active"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "primary checkbox"
                                        }}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                    )}
                </LocalizationProvider>
            </Grid>
            {(props.editmode === "AddTb" || props.editmode === "EditTb") && (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button
                            style={{ marginTop: "1rem" }}
                            disabled={!props.tb.dirty}
                            variant="contained"
                            color="primary"
                            onClick={async () =>
                                await saveAvailability(props.editmode)
                            }
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
