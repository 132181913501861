import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";

function SmallSizedModal(props) {
    return (
        <Dialog
            open={props.open}
            sx={{
                margin: "0 auto",
                //width: "450px",
                maxWidth: "450px",
                "& .MuiDialog-paper": {
                    margin: 0,
                    width: "100%"
                }
            }}
            onClose={props.onClose}
        >
            <DialogContent
                sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    padding: "2rem 2.25rem 2.25rem 2.25rem"
                }}
            >
                <Box width={"100%"} sx={{ display: "flex", gap: "8px" }}>
                    {props.headingIcon && (
                        <img
                            src={props.headingIcon}
                            alt="Warning Triangle Icon"
                            style={{ height: "24px", width: "24px" }}
                        />
                    )}
                    <Typography
                        sx={{
                            fontSize: "1.5rem",
                            lineHeight: "1.5rem",
                            display: "flex",
                            alignItems: "center"
                        }}
                        fontWeight={400}
                    >
                        {props.headingText}
                    </Typography>
                </Box>
                {props.children}
            </DialogContent>
        </Dialog>
    );
}

export default SmallSizedModal;
