import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Paper, Checkbox, IconButton, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, Tooltip, Button, Snackbar, Avatar } from '@mui/material';
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { getUrl } from "aws-amplify/storage";
import { useStyles } from "../styles/MainNavbarStyles";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import EditIcon from "@mui/icons-material/Create";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export const ProviderAddiotionalPhotos = (props) => {
    const { actions, state } = useContext(StoreContext);
    const [msgOpen, setMsgOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState();
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [searchedFor, setSearchedFor] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem("search") === "true") setSearchedFor(true);
        fetchData();
    }, []);

    async function fetchData() {
        let arr = [];
        try {
            let dataList = await execReadByPK({
                opname: "providerPhotosByProvider",
                op: queries.providerPhotosByProvider,
                id: { providerId: state.provider.id },
                sortDirection: "DESC"
            });

            if (!!dataList && !!dataList.items) {
                if (!!dataList.items && dataList.items.length > 0) {
                    const loop = async () => {
                        for (let item of dataList.items) {
                            const urlResult = await getUrl({ key: item.s3key });
                            if (!!urlResult) {
                                item.s3Image = urlResult?.url;
                                arr.concat(item);
                            }
                            arr.concat(item);
                        }
                        return dataList.items;
                    };
                    const finalData = await loop();
                    setRows(finalData);
                }
            }
        } catch (error) {
            console.log("error => ", error);
        }
    }
    function handleProviderAddClick() {
        actions.setMode("Add");
        actions.setPage("ProviderAdditionalPhotosAddEdit");
    }

    function handleEditClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("Edit");
        actions.setPage("ProviderAdditionalPhotosAddEdit");
        actions.setId(rowId);
    }

    function handleViewClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("View");
        actions.setPage("ProviderAdditionalPhotosAddEdit");
        actions.setId(rowId);
    }

    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        console.log("val", event.target.value, parseInt(event.target.value));
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    function _handleDeleteDialogAccept() {
        // proceed to delete providers
        selected.map((item) => {
            deleteProvider(item);
        });
    }

    function deleteProvider(providerId) {
        async function deleteGraphQLProviders(providerId) {
            //   const providerTobeDeleted = rows.filter(p => p.id === providerId);

            const input = {
                id: providerId
            };
            const deletedProvider = await graphql(
                graphqlOperation(mutations.deleteProviderPhotos, { input })
            );
            if (!!deleteProvider) {
                setSnackMsg(
                    "The selected provider photo(s) have been successfully deleted."
                );
                setMsgOpen(true);
                setDeleteDialog(false);
                actions.setPage("ProviderAdditionalPhotos");
                // clear table... cannot do refresh right away or we will get stale data ()
                const empty = [];
                // setRows(empty);
                setSelected(empty);
            }
        }

        deleteGraphQLProviders(providerId);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }

    function displayReturnButton() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser.role != "PROVIDER") {
            return (
                <Grid item>
                    <Tooltip title="Returns to the page with the providers matching your search">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleReturnToSearch}
                        >
                            Return to Search
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    function displayBackButton() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser.role != "PROVIDER") {
            return (
                <Grid item>
                    <Tooltip title="Returns to only the provider you selected">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={_handleBack}
                        >
                            Back
                        </Button>
                    </Tooltip>
                </Grid>
            );
        }
    }

    function displayCancelButton() {
        const loggedInUser = getUserFromCache();
        if (loggedInUser.role != "PROVIDER") {
            return (
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={_handlePhotoCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
            );
        }
    }

    const _handleReturnToSearch = () => {
        sessionStorage.setItem("back", true);
        actions.setPage("ProviderForm");
    };

    const _handleBack = () => {
        sessionStorage.setItem("back", true);
        sessionStorage.setItem("search", false);
        actions.setId(state.id);
        actions.setPage("ProviderForm");
    };

    const _handlePhotoCancel = () => {
        sessionStorage.setItem("back", false);
        sessionStorage.setItem("search", false);
        actions.setPage("ProviderForm");
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={3000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <DeleteDialog
                title="providers photos"
                open={deleteDialog}
                onDecline={() => setDeleteDialog(false)}
                onConfirm={_handleDeleteDialogAccept}
            />
            <FormHeading
                title={"Additional Photos for " + state.provider.name}
                classes={classes}
            />
            <Paper rounded="true" className={classes.root}>
                <Grid container spacing={10}>
                    <Grid item xs={10} />
                    <Grid item xs={2} align="right">
                        <Tooltip title="Add a new Additional Photos">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleProviderAddClick}
                            >
                                {/* <AddIcon className={classes.leftIcon} /> */}
                                &nbsp;Add
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <EnhancedToolbar
                        numSelected={selected.length}
                        handleDelete={() => setDeleteDialog(true)}
                    />
                    <Table
                        size="small"
                        aria-label="Providers"
                        className={classes.table}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectAll}
                                        onClick={handleSelectAllClick}
                                    />
                                </TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="left">Image</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, i) => (
                                    <TableRow
                                        key={`${row.id}-${i}`}
                                        hover
                                        onClick={(event) =>
                                            handleRowClick(event, row.id)
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selected.indexOf(row.id) !==
                                                    -1
                                                }
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.desc}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Avatar
                                                alt="Profile Picture"
                                                src={
                                                    !!row.s3Image && row.s3Image
                                                }
                                                className={classes.bigAvatar}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Edit Provider Photos">
                                                <EditIcon
                                                    onClick={(event) =>
                                                        handleEditClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={6}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "Rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>

                    <Grid item xs={8} className="forcarousel">
                        {!!rows && rows.length > 0 ? (
                            <>
                                <Carousel
                                    autoPlay={false}
                                    showArrows={true}
                                    infiniteLoop={true}
                                >
                                    {rows
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((item, i) => {
                                            // !!item.url &&
                                            return (
                                                <div key={i}>
                                                    <img src={item.s3Image} />
                                                    {!!item.desc ? (
                                                        <p className="legend">
                                                            {item.desc}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            );
                                        })}
                                </Carousel>
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
                &nbsp;
                <Grid
                    container
                    alignItems="center"
                    className={classes.marginBottom}
                >
                    <Grid item xs></Grid>
                    {searchedFor && displayReturnButton()}
                    &nbsp;
                    {displayBackButton()}
                    &nbsp;
                    {displayCancelButton()}
                </Grid>
            </Paper>
        </>
    );
};
