import React, { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { Grid, TextField, FormControlLabel, Popper } from "@mui/material";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { useStyles } from "../styles/MainContainerStyles";
import { companyLocationByCompany } from "../graphql/queries";
import { getUserFromCache } from "../user/UserCommon";

const limit = process.env.REACT_APP_LISTLIMIT;

export const LocationSelector = (props) => {
    const classes = useStyles();
    const [companyLocations, setCompanyLocations] = useState([]);
    const [filteredCompanyLocations, setFilteredCompanyLocations] = useState(
        []
    );
    const globalUser = getUserFromCache();

    useEffect(() => {
        async function fetchCompanyLocations() {
            await getCompanyLocations();
        }
        fetchCompanyLocations();
    }, []);

    useEffect(() => {
        if (props.filteredLocations && props.filteredLocations.length > 0) {
            setFilteredCompanyLocations(props.filteredLocations);
        } else {
            setFilteredCompanyLocations([]);
        }
    }, [props.filteredLocations]);

    const PopperMy = function (props) {
        const { anchorEl, ...otherProps } = props;

        return (
            <Popper
                {...otherProps}
                anchorEl={anchorEl}
                placement="bottom"
                modifiers={[
                    {
                        name: "flip",
                        options: {
                            fallbackPlacements: ["bottom-start"]
                        }
                    }
                ]}
            />
        );
    };

    const getCompanyLocations = async () => {
        const filter = {
            and: [{ active: { ne: false } }, { deleted: { ne: true } }]
        };

        const response = await graphql(
            graphqlOperation(companyLocationByCompany, {
                companyId: globalUser.company.id,
                filter,
                limit
            })
        );
        let result = await response.data.companyLocationByCompany.items;
        // add "My Address to the beginning of the array"
        if (globalUser.company.offersRemoteServices) {
            result.unshift({
                id: "0",
                addressoneline: "Client address",
                locationname: "Client Location"
            });
        }
        setCompanyLocations(result);
        if (props.setCompanyLocations) {
            props.setCompanyLocations(result);
        }
    };

    async function handleLocationChange(event, newValue) {
        if (newValue) {
            props.setShowAddr("0" === newValue.id);
            if ("0" !== newValue.id) {
                let filtered_loc = companyLocations.filter(
                    (cl) => cl.id === newValue.id
                )[0];
                props.setLocationChanged(true);
                props.setLocation(filtered_loc);

                if (props.changingLocationHandler) {
                    props.changingLocationHandler(filtered_loc.id);
                }
            } else {
                props.setPlaceId(null);
                if (props.changingLocationHandler) {
                    props.changingLocationHandler("0");
                }
                props.setLocation({
                    id: "0",
                    addressoneline: "Client address",
                    locationname: "Client Location"
                });
            }
        } else {
            props.setLocation(null);
        }
    }
    function getLocationObj(id) {
        let lo = companyLocations
            ? companyLocations.filter((cl) => cl.id === id)
            : [];
        return lo[0]
            ? lo[0]
            : props.initialLocation
              ? {
                    id: "0",
                    addressoneline: props.initialLocation,
                    locationname: props.initialLocation
                }
              : null;
    }

    return (
        <Grid container spacing={2}>
            <Grid
                item
                container
                xs={props.xs ? props.xs : 10}
                style={{ paddingLeft: props.overrideModalDisplay ? "8px" : "" }}
            >
                <FormControlLabel
                    labelPlacement="top"
                    style={{
                        width: props.fullWidth
                            ? "100%"
                            : props.overrideModalDisplay
                              ? "542px"
                              : "300px",
                        margin: props.margin ? props.margin : "8px"
                    }}
                    control={
                        <Autocomplete
                            id="service-location"
                            disableClearable
                            value={getLocationObj(props.locationId)}
                            loading={props.loading}
                            loadingText="Loading..."
                            noOptionsText="No Locations Available"
                            options={
                                filteredCompanyLocations &&
                                filteredCompanyLocations.length > 0
                                    ? filteredCompanyLocations
                                    : companyLocations
                            }
                            autoSelect={
                                props.autoSelect == false ? false : true
                            }
                            getOptionLabel={(option) => option.locationname}
                            style={{
                                width: props.fullWidth
                                    ? "100%"
                                    : props.overrideModalDisplay
                                      ? "100%"
                                      : "300px"
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        classes: {
                                            notchedOutline:
                                                classes.notchedOutlineProviderDir
                                        }
                                    }}
                                    label="Booking location"
                                    InputLabelProps={{
                                        ...params.InputLabelProps
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    required={
                                        props.overrideModalDisplay
                                            ? false
                                            : true
                                    }
                                />
                            )}
                            onChange={handleLocationChange}
                            disabled={props.disableElements}
                            ListboxProps={{
                                style: {
                                    maxHeight: props.overrideModalDisplay
                                        ? "150px"
                                        : "250px",
                                    overflowY: "auto"
                                }
                            }}
                            PopperComponent={(popperProps) => (
                                <PopperMy
                                    {...popperProps}
                                    anchorEl={popperProps.anchorEl}
                                />
                            )}
                        />
                    }
                />
            </Grid>
        </Grid>
    );
};
