import moment from "moment";
export const dateFormatter = (d) => {
    const timezone_short = new Date(d)
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
    const moment_date = moment(d).format("lll");

    const formatted_date = moment_date + " " + timezone_short;

    //console.log(formatted_date);
    return formatted_date;
};

/**
 * Converts a date string to the format "YYYY-MM-DD HH:MM:SS AM/PM".
 *
 * @param {string} dateString - The date string to be converted.
 * @returns {string} The formatted date string in the format "YYYY-MM-DD HH:MM:SS AM/PM".
 */
export function convertTo12HourFormat(dateString) {
    let date = new Date(dateString);

    if (isNaN(date)) {
        throw new Error("Invalid date string");
    }

    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
}
