import { atom } from "jotai";
import _ from "lodash";
import { mbxUserAtom } from "./atoms";
import { selectedProviderIdAtom } from "./usersTable";
import uuid from "uuid/v4";
import { WEEK_DAYS_ABBREVIATED } from "../utils/Constants";
import { jsonParse, tableDateFormat, twentyFourHourTimeToAmPm } from "../utils";

export const allBlockedTimesAtom = atom([]);
export const openBlockedTimeDialogAtom = atom(false);
export const groupedBlockedTimeRowsAtom = atom((get) =>
    _.groupBy(
        get(allBlockedTimesAtom).filter(({ scheduleinfo }) =>
            scheduleinfo.startsWith("BUT")
        ),
        ({ scheduleinfo }) => scheduleinfo.split("|")[3]
    )
);

export const allBusAtom = atom((get) =>
    get(allBlockedTimesAtom).filter(({ scheduleinfo }) =>
        scheduleinfo.startsWith("BU|")
    )
);

const buByBuIdAtom = (buId) =>
    atom((get) =>
        get(allBusAtom).find(
            ({ scheduleinfo }) => scheduleinfo === `BU|${buId}`
        )
    );

export const displayBlockedTimesAtom = atom((get) => {
    return _.map(get(groupedBlockedTimeRowsAtom), (buts, buId) => ({
        buId,
        weekDays: buts[0].weekDays
            .split("")
            .sort()
            .map((numDay) => WEEK_DAYS_ABBREVIATED[numDay])
            .join(", "),
        dateRange: `${tableDateFormat(buts[0].startDate)} - ${tableDateFormat(buts[0].endDate)}`,
        timeRange: buts?.reduce(
            (acc, { startTime, endTime }) =>
                `${acc} ${twentyFourHourTimeToAmPm(startTime)} - ${twentyFourHourTimeToAmPm(endTime)}`,
            ""
        ),
        notes:
            jsonParse(
                get(buByBuIdAtom(buId))?.notes,
                [""],
                "Error parsing blocked time notes"
            )?.[0] || ""
    }));
});
export const selectedBuId = atom();
export const blockedTimeFormDataAtom = atom((get) => {
    const buId = get(selectedBuId);
    if (buId) {
        const timeBlocks = get(groupedBlockedTimeRowsAtom)[buId];
        return {
            buId,
            weekDays: timeBlocks[0].weekDays,
            weeksToRepeat: timeBlocks[0].weeksToRepeat,
            startDate: timeBlocks[0].startDate,
            endDate: timeBlocks[0].endDate,
            timeBlocks: timeBlocks.map(({ startTime, endTime, ...rest }) => ({
                startTime: Date.prototype.setHours.apply(
                    new Date(),
                    startTime.split(":")
                ),
                endTime: Date.prototype.setHours.apply(
                    new Date(),
                    endTime.split(":")
                ),
                ...rest
            })),
            notes:
                jsonParse(
                    get(buByBuIdAtom(buId))?.notes,
                    [""],
                    "Error parsing blocked time notes"
                )?.[0] || ""
        };
    } else return undefined;
});
export const recordIdAtom = atom((get) => {
    const { companyId } = get(mbxUserAtom);
    const providerId = get(selectedProviderIdAtom);
    return `C-${companyId}|P-${providerId}`;
});
export const scheduleInfoAtom = atom((get) => {
    const startTime = new Date().setHours(9, 0, 0, 0);
    const endTime = new Date().setHours(17, 0, 0, 0);
    return () =>
        `BUT|${startTime} ${endTime}|${uuid()}|BU|${get(selectedBuId)}`;
});
