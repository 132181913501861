import {
    Box,
    InputAdornment,
    Link,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import validateCompany from "../../utils/WhiteLabelling";
import { userHasMarketboxAdminRole } from "../../user/UserCommon";

function DomainTab({
    styles,
    domainStates,
    domainStatesChangeHandler,
    companyData,
    mode
}) {
    return (
        <>
            <Box sx={{ ...styles.container, gap: "2rem" }}>
                {/* <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        Homepage URL{" "}
                        { <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip title="HomepageURL" placement="right">
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        sx={styles.textField}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment
                                    sx={{
                                        color: "rgba(0,0,0,0.47)",
                                        fontFamily: "Roboto"
                                    }}
                                    position="start"
                                >
                                    <span
                                        style={{
                                            color: "rgba(0,0,0,0.47)",
                                            fontFamily: "Roboto"
                                        }}
                                    >
                                        https://
                                    </span>
                                </InputAdornment>
                            )
                        }}
                        label="Homepage URL"
                        name={"homepageURL"}
                        value={domainStates.homepageURL}
                        onChange={domainStatesChangeHandler}
                    />

                    <Typography
                        sx={{
                            color: "rgba(0,0,0,0.6)",
                            fontSize: "0.75rem",
                            marginLeft: "0.3rem"
                        }}
                    >
                        Link company logo to your website
                    </Typography>
                </Box> */}

                <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        Personalized booking URL{" "}
                        {/* <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip title="Subdomain" placement="right">
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span> */}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        sx={styles.textField}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment
                                    sx={{
                                        color: "rgba(0,0,0,0.47)",
                                        fontFamily: "Roboto"
                                    }}
                                    position="start"
                                >
                                    <span
                                        style={{
                                            color: "rgba(0,0,0,0.47)",
                                            fontFamily: "Roboto"
                                        }}
                                    >
                                        https://
                                    </span>
                                </InputAdornment>
                            ),
                            endAdornment: ((!validateCompany(companyData?.id).checked) || mode === "Add") && (
                                    <InputAdornment
                                        sx={{
                                            color: "rgba(0,0,0,0.47)",
                                            fontFamily: "Roboto"
                                        }}
                                        position="end"
                                    >
                                        <span
                                            style={{
                                                color: "rgba(0,0,0,0.47)",
                                                fontFamily: "Roboto"
                                            }}
                                        >
                                            .gomarketbox.com
                                        </span>
                                    </InputAdornment>
                                )
                        }}
                        label="Personalized Booking URL"
                        name={"subdomain"}
                        value={
                            companyData &&
                            validateCompany(companyData.id).checked
                                ? validateCompany(companyData.id).subdomain
                                : domainStates.subdomain
                        }
                        onChange={domainStatesChangeHandler}
                        disabled={!userHasMarketboxAdminRole()}        
                    />
                    {!domainStates.subdomainError ? (
                        <Typography
                            sx={{
                                color: "rgba(0,0,0,0.6)",
                                fontSize: "0.75rem",
                                marginLeft: "0.3rem"
                            }}
                        >
                            To remove MarketBox subdomain from your booking flow
                            link, contact{" "}
                            <Link
                                href="mailto:support@gomarketbox.com"
                                underline="always"
                                sx={{
                                    color: "primary.main",
                                    fontFamily: "Roboto"
                                }}
                            >
                                support@gomarketbox.com
                            </Link>
                        </Typography>
                    ) : (
                        <Typography
                            sx={{
                                color: "error.main",
                                fontSize: "0.75rem",
                                marginLeft: "0.3rem"
                            }}
                        >
                            {domainStates.subdomainErrorMessage}
                        </Typography>
                    )}
                </Box>

                {/*  <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        Masked Domain{" "}
                        <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            (Optional)
                            <Tooltip title="maskedDomain" placement="right">
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        sx={styles.textField}
                        InputProps={{
                            disableUnderline: true
                        }}
                        label="Masked Domain"
                        name={"maskedDomain"}
                        value={domainStates.maskedDomain}
                        onChange={domainStatesChangeHandler}
                    />
                </Box> */}

                <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        Title tag{" "}
                        {/* <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip title="Tagline" placement="right">
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip> 
                        </span>*/}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        sx={styles.textField}
                        InputProps={{
                            disableUnderline: true
                        }}
                        label="Title Tag"
                        name={"tagline"}
                        value={domainStates.tagline}
                        onChange={domainStatesChangeHandler}
                    />
                    {domainStates.taglineError ? (
                        <Typography
                            sx={{
                                color: "error.main",
                                fontSize: "0.75rem",
                                marginLeft: "0.3rem"
                            }}
                        >
                            {domainStates.taglineErrorMessage}
                        </Typography>
                    ) : (
                        <Typography
                            sx={{
                                color: "rgba(0,0,0,0.6)",
                                fontSize: "0.75rem",
                                marginLeft: "0.3rem"
                            }}
                        >
                            Description in the browser tab for your booking page
                        </Typography>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default DomainTab;
