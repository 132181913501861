import {
    Tooltip,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    InputAdornment
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useState } from "react";
import { getCurrencySymbol } from "../utils";
import StarsIcon from "@mui/icons-material/Stars";
import { userHasMarketboxAdminRole, userHasCompanyAdminRole } from "../user/UserCommon";
const StyledTooltip = withStyles({
    tooltip: {
        fontSize: "15px"
    }
})(Tooltip);

const StyledTextField = withStyles({
    fontSize: "500px"
})(TextField);

const CustomTooltip = ({ placement, title, children }) => {
    const [clicked, setClicked] = useState(false);
    const [isHover, setIsHover] = useState(false);
    return (
        <div
            style={{ display: "inline-block" }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <StyledTooltip
                placement={placement}
                title={title}
                open={clicked || isHover}
                onClick={() => setClicked((prevClicked) => !prevClicked)}
            >
                {children}
            </StyledTooltip>
        </div>
    );
};

const Tooltipedited = ({ placement, title, children }) => {
    const [clicked, setClicked] = useState(false);
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            style={{ display: "block" }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <StyledTooltip
                placement={placement}
                title={title}
                open={isHover}
                onClick={() => setClicked(!clicked)}
            >
                {children}
            </StyledTooltip>
        </div>
    );
};

const CustomBasicTooltip = ({ placement, title, children }) => {
    return (
        <StyledTooltip placement={placement} title={title}>
            {children}
        </StyledTooltip>
    );
};

const CustomListItem = ({
    title,
    id,
    onClick,
    selected,
    children,
    className,
    hoverContentClassName,
    open,
    isFeatureGated
}) => {
    const userIsAdmin = userHasMarketboxAdminRole() || userHasCompanyAdminRole();
    return open ? (
        <ListItem
            id={id}
            onClick={onClick}
            selected={selected}
            style={
                className
                    ? {}
                    : {
                          paddingTop: "3px",
                          paddingBottom: "3px"
                          //fontWeight: selected ? "bolder" : "normal"
                      }
            }
            className={className}
            disabled={isFeatureGated}
        >
            {children && (
                <ListItemIcon style={userIsAdmin ? {} : { minWidth: open ? "26px" : "38px" }}>
                    {children}
                </ListItemIcon>
            )}
            {<ListItemText
                className={hoverContentClassName}
                primaryTypographyProps={{
                    style: {
                        fontSize: "1rem",
                        fontWeight: (userIsAdmin) ? ("bolder") : (selected ? "bolder" : "normal")
                    }
                }}
                primary={
                    title === "Regional Pricing" ? (
                        <>
                            Regional
                            <br />
                            Pricing
                        </>
                    ) : (
                        title
                    )
                }
            />}
        </ListItem>
    ) : (
        <Tooltip placement="right" title={title}>
            <ListItem
                button
                id={id}
                onClick={onClick}
                selected={selected}
                style={
                    className
                        ? {}
                        : {
                              paddingTop: "3px",
                              paddingBottom: "3px"
                              //fontWeight: selected ? "bolder" : "normal"
                          }
                }
                className={className}
            >
                {children && (
                    <ListItemIcon style={{ minWidth: open ? "26px" : "38px" }}>
                        {children}
                    </ListItemIcon>
                )}
                {<ListItemText
                    primaryTypographyProps={{
                        style: {
                            fontSize: "1rem",
                            fontWeight: selected ? "bolder" : "normal"
                        }
                    }}
                    primary={
                        title === "Regional Pricing" ? (
                            <>
                                Regional
                                <br />
                                Pricing
                            </>
                        ) : (
                            title
                        )
                    }
                />}
            </ListItem>
        </Tooltip>
    );
};

const AmountInputField = ({
    placement,
    title,
    children,
    error,
    value,
    setValue
}) => {
    function onChange(e) {
        if (isNaN(e.target.value)) {
            error = true;
            if (isNaN(value)) setValue("");
            return;
        }
        if (!isNaN(e.target.value)) {
            if (e.target.value != "") {
                let val = e.target.value;
                let l = val.length;
                if (val.includes(".")) {
                    let dotI = val.lastIndexOf(".");
                    let num =
                        Math.round(parseFloat(e.target.value) * 100) / 100;
                    if (l - dotI === 1) {
                        setValue(val);
                    } else if (l - dotI === 2) {
                        setValue(num.toFixed(1));
                    } else setValue(num.toFixed(2));
                } else setValue(val);
            } else {
                setValue(0);
            }
        }
    }
    return (
        <StyledTextField
            autoFocus
            required
            type="text"
            margin="dense"
            id="amount"
            label="Amount"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {getCurrencySymbol()}
                    </InputAdornment>
                ),
                inputMode: "numeric",
                pattern: "[0-9]*"
            }}
            onChange={onChange}
            error={error}
            value={value === 0 ? "" : value}
            setValue={setValue}
        >
            {children}
        </StyledTextField>
    );
};

export {
    CustomTooltip,
    CustomBasicTooltip,
    CustomListItem,
    AmountInputField,
    Tooltipedited
};
