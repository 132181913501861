import React, { useEffect, useState } from "react";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
// prettier-ignore
import { Typography, Grid } from "@mui/material";
import { useStyles } from "../styles/MainContainerStyles";

function EnvtForm(props) {
    const classes = useStyles();
    const [envt, setEnvt] = useState("");

    useEffect(() => {
        const filter = {
            filter: {
                id: { eq: "default" }
            }
        };
        fetchEnvt(filter);
    }, []);

    // fetch envt to display
    const fetchEnvt = async (filter) => {
        const result = await graphql(
            graphqlOperation(queries.listEnvts, filter)
        );
        setEnvt(result.data.listEnvts.items[0].name);
    };

    return (
        <>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Typography className={classes.title} variant="h4" noWrap>
                        Current Environment
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={10}>
                    <Typography variant="body1" noWrap>
                        The current environment is: <b>{envt}</b>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default EnvtForm;
