import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Button, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Create";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import {
    graphql,
    graphqlOperation,
    subscribe
} from "../modules/AmplifyServices";
import { useStyles } from "../styles/MainContainerStyles";
import { serviceStyles } from "../styles/ServiceFormStyles";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { EnhancedToolbar } from "../utils/CommonComonents/EnhancedToolbar";
import { DeleteDialog } from "../utils/CommonComonents/DeleteDialog";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { auditSkillsDelete } from "../modules/Audit";
import { execReadByPK } from "../modules/DBService";
import { getUserFromCache } from "../user/UserCommon";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export const SkillsForm = (props) => {
    const { state, dispatch, actions } = useContext(StoreContext);

    const classes = useStyles();
    const serviceClasses = serviceStyles();

    const [rows, setRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("skillsTable")
    );
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [statusFilter, setStatusFilter] = useState(true);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        if (getTableRowsSession("skillsTable")) {
            setTableRowsSession("skillsTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    async function refreshSkills() {
        // refresh services
        console.log("REFRESHING", statusFilter);
        const user = getUserFromCache();

        const result = await execReadByPK({
            opname: "skillByCompany",
            op: queries.skillByCompany,
            id: { companyId: user.company.id },
            filter: {
                and: [
                    { active: { eq: statusFilter } },
                    { deleted: { ne: true } }
                ]
            },
            sortDirection: "DESC"
        });

        if (result && result.items) {
            result.items.sort((p1, p2) => {
                const psq1 = p1.name.toUpperCase();
                const psq2 = p2.name.toUpperCase();
                if (psq1 < psq2) return -1;
                if (psq1 > psq2) return 1;
                return 0;
            });
            setRows(result.items);
        } else setRows([]);
    }

    useEffect(() => {
        const skillSub = subscribe(subscriptions.onCreateSkill, refreshSkills);
        const skillUpdateSub = subscribe(
            subscriptions.onUpdateSkill,
            refreshSkills
        );

        const loadSkills = async () => {
            await refreshSkills();
        };

        loadSkills();

        const cleanup = () => {
            skillSub.unsubscribe();
            skillUpdateSub.unsubscribe();
        };
        return cleanup;
    }, []);

    useEffect(() => {
        const loadSkills = async () => {
            await refreshSkills();
        };

        loadSkills();
    }, [statusFilter]);

    const handleStatusChange = (event, status) => {
        console.log("handleStatusChange", status);
        if (status != null) {
            setStatusFilter(status);
        }
    };

    function handleEditClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("Edit");
        actions.setPage("SkillsAddEditForm");
        actions.setId(rowId);
    }
    function handleViewClick(event, rowId) {
        // set context and pass to ProviderAddEdit screen
        actions.setMode("View");
        actions.setPage("SkillsAddEditForm");
        actions.setId(rowId);
    }

    function _handleDeleteDialogDecline() {
        setDeleteDialog(false);
    }

    async function _handleDeleteDialogAccept() {
        let deletePromises = selected.map(
            async (item) => await deleteSkill(item)
        );
        let result = await Promise.all(deletePromises);
        setSnackMsg("The selected skill(s) have been successfully deleted.");
        setMsgOpen(true);
        setDeleteDialog(false);
        // clear table... cannot do refresh right away or we will get stale data ()
        const empty = [];
        setSelected(empty);
        await refreshSkills();
    }

    async function deleteSkill(skillId) {
        const loggedInUser = getUserFromCache();
        const input = {
            id: skillId,
            deleted: true
        };
        const deletedSkill = await graphql(
            graphqlOperation(mutations.updateSkill, { input })
        );

        await auditSkillsDelete(loggedInUser, deletedSkill.data.updateSkill);

        if (!!deletedSkill && !!deletedSkill.data) {
            await refreshSkills();
        }
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        } else {
            setSelectAll(false);
        }
        setSelected([]);
    }

    function handleRowClick(event, id) {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    }

    return (
        <>
            <FormHeading id="MBSkillsForm" title={"Skills"} classes={classes} />
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                    <DeleteDialog
                        title="skills"
                        open={deleteDialog}
                        onDecline={_handleDeleteDialogDecline}
                        onConfirm={_handleDeleteDialogAccept}
                    />
                    <Paper rounded="true" className={serviceClasses.paper}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    size="small"
                                    value={statusFilter}
                                    exclusive
                                    onChange={handleStatusChange}
                                >
                                    <ToggleButton key={1} value={true}>
                                        Active
                                    </ToggleButton>
                                    <ToggleButton key={2} value={false}>
                                        Inactive
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        textAlign: "right",
                                        float: "right"
                                    }}
                                    onClick={() => {
                                        actions.setPage("SkillsAddEditForm");
                                        actions.setMode("Add");
                                    }}
                                >
                                    &nbsp;Add Skill
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <EnhancedToolbar
                                    numSelected={selected.length}
                                    handleDelete={() => setDeleteDialog(true)}
                                />
                                <Table size="small" className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={selectAll}
                                                    onClick={
                                                        handleSelectAllClick
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>Name</TableCell>
                                            {/*<TableCell align="center">
                                            Importance
                                            </TableCell>*/}
                                            <TableCell align="center">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .filter(
                                                (row) =>
                                                    row.active === statusFilter
                                            )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    hover
                                                    onClick={(event) =>
                                                        handleRowClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    row.id
                                                                ) !== -1
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                    {/*<TableCell align="center">
                                                    {row.importance}
                                                    </TableCell>*/}
                                                    <TableCell align="center">
                                                        <Tooltip title="Edit Skill">
                                                            <EditIcon
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleEditClick(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={6}
                                                count={
                                                    rows.filter(
                                                        (row) =>
                                                            row.active ==
                                                            statusFilter
                                                    ).length
                                                }
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label":
                                                            "Rows per page"
                                                    },
                                                    native: true
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={
                                                    handleChangeRowsPerPage
                                                }
                                                ActionsComponent={
                                                    TablePaginationActions
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
