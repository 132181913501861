import React from "react";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Grid, Button, Chip } from "@mui/material";

function IntegrationSetupCard(props) {
    const handleClick = () => {
        props.handleClick(props.data);
    };

    return (
        <Card sx={{ maxWidth: "400px" }}>
            <CardContent
                sx={{
                    padding: "16px 16px",
                    "&:last-child": {
                        paddingBottom: "12px"
                    }
                }}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <img
                        style={{ height: "60px", paddingBottom: "12px" }}
                        src={props.data.image}
                        alt="Zapier"
                    />
                    {props.enabled && (
                        <Chip
                            label="Enabled"
                            sx={{
                                bgcolor: "#36A635",
                                color: "white",
                                borderRadius: "8px",
                                width: "85px"
                            }}
                        />
                    )}
                </Grid>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                    {props.data.title}
                </Typography>
                <Grid container style={{ gap: "8px", marginBottom: "12px" }}>
                    <Typography variant="body2" color="black" component="p">
                        {props.data.description}
                    </Typography>
                </Grid>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#231515",
                        padding: "4px 12px",
                        color: "#ffffff"
                    }}
                    onClick={handleClick}
                >
                    Setup
                </Button>
            </CardContent>
        </Card>
    );
}

export default IntegrationSetupCard;
