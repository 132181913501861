export const getServiceListStyle = (isDraggingOver, theme) => ({
    background: isDraggingOver ? "lightblue" : theme.palette.primary.main,
    color: "black", //theme.palette.primary.contrastText,
    padding: 4,
    width: 300
});
const grid = 6;
export const getCategoryItemStyle = (isDragging, draggableStyle, theme) => {
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : theme.palette.primary.light,
        color: "black", //theme.palette.primary.contrastText,

        // styles we need to apply on draggables
        ...draggableStyle
    };
};

export const getServiceItemStyle = (isDragging, draggableStyle, theme) => {
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        textAlign: "right",

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        color: "black", // theme.palette.primary.main,

        // styles we need to apply on draggables
        ...draggableStyle
    };
};

export const getCategoryListStyle = (isDraggingOver, theme) => ({
    background: isDraggingOver ? "lightblue" : theme.palette.primary.main,
    color: "black", //theme.palette.primary.contrastText,
    padding: 8,
    width: 450
});
