import React, { Fragment, useContext, useState } from "react";
import { postApi } from "../../../modules/AmplifyServices";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import UserInputContext from "../../context/userInput/userInputContext";
import PhoneNumberField from "../custom/PhoneNumberField";
import {
    createPendingCompany,
    createCompanyAndPublicSite,
    createScheduleIndex
} from "../../modules/CreateCompany";
import {
    validateEmailAddress,
    validatePhoneNumber,
    validateUniqueEmailAndSubdomain
} from "../../modules/ValidityCheck";
import { validateUniqueCompanyName } from "../../../pages/checkout/modules/ValidityCheck";
import {
    Typography,
    TextField,
    Grid,
    Paper,
    Button,
    InputAdornment,
    CircularProgress,
    Link
} from "@mui/material";
import { makeStyles } from "@mui/styles";

/* styles used in free trial form */
const useStyles = makeStyles({
    paper: {
        width: "80%",
        padding: "14px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        borderRadius: "48px",

        "& *": {
            fontFamily: "Poppins, sans-serif"
        }
    },
    paper_mobile: {
        width: "95%",
        padding: "14px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        borderRadius: "48px",
        maxWidth: "550px",
        "& *": {
            fontFamily: "Poppins, sans-serif"
        }
    },
    papertxt: {
        flexDirection: "column",
        alignContent: "center"
    },
    paperheading: {
        fontSize: "21px",
        fontWeight: "bold",
        color: "#0087ee"
    },
    papersubheading: {
        textAlign: "center",
        fontSize: "14px",
        color: "#0087ee",
        paddingBottom: "8px"
    },
    doublefield: {
        justifyContent: "space-between",
        padding: "0px 42px"
    },
    doublefield_mobile: {
        justifyContent: "space-between",
        padding: "0px 42px",
        flexDirection: "column"
    },
    singlefield: {
        padding: "0px 42px"
    },
    button: {
        backgroundColor: "#FF681D",
        color: "white",
        width: "67%",
        height: "2rem",
        borderRadius: "12px",
        "&:hover": {
            backgroundColor: "#E64C00"
        }
    },
    field: {
        width: "46%"
    },
    field_mobile: {
        width: "100%"
    },
    input: {
        "&:before": {
            borderBottom: "2px solid #E6E6E6"
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: "2px solid blue"
        },
        "&:after": {
            borderBottom: "2px solid black"
        }
    },
    inputlabel: {
        "&:focused": {
            color: "red"
        }
    }
});

const FreeTrialForm = (props) => {
    console.log("freeTrialForm Loaded");
    const userInputContext = useContext(UserInputContext);
    const query = new URLSearchParams(window.location.search);
    const {
        userInputs: state,
        hubspotContact,
        setHubspotContact
    } = userInputContext;
    console.log("userInputContext from freeTrialForm", userInputContext);

    const classes = useStyles();
    const [disableGetStarted, setDisableGetStarted] = useState(false);
    const [subdomainValue, setSubdomainValue] = useState(
        state.desiredSubdomain
    );
    const isValidChars = (str) => /^[A-Za-z0-9_-]*$/.test(str);

    /* state for error handling */
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [emailAddressError, setEmailAddressError] = useState(false);
    const [existingEmailAddressError, setExistingEmailAddressError] =
        useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [invalidPhoneNumberError, setInvalidPhoneNumberError] =
        useState(false);
    const [desiredSubdomainError, setDesiredSubdomainError] = useState(false);
    const [existingDesiredSubdomainError, setExistingDesiredSubdomainError] =
        useState(false);
    const [emptyDesiredSubdomainError, setEmptyDesiredSubdomainError] =
        useState(false);
    const [existingCompanyNameError, setExistingCompanyNameError] =
        useState(false);

    /* functions to handle user input state changes in userInputContext*/
    function setFirstNameInput(firstNameInput) {
        state.firstNameInput = firstNameInput;
    }
    function setLastNameInput(lastNameInput) {
        state.lastNameInput = lastNameInput;
    }
    function setEmailAddress(emailAddress) {
        state.emailAddress = emailAddress.toLowerCase();
    }
    function setPhoneNumber(phoneNumber) {
        state.phoneNumber = phoneNumber;
    }
    function setCompanyNameInput(companyNameInput) {
        state.companyNameInput = companyNameInput;
    }
    function setDesiredSubdomain(desiredSubdomain) {
        state.desiredSubdomain = desiredSubdomain;
    }

    /* functions to handle user inputs */
    function handleFirstName(value) {
        setFirstNameError(false);
        setFirstNameInput(value.trim());
    }
    function handleLastName(value) {
        setLastNameError(false);
        setLastNameInput(value.trim());
    }
    function handleEmailAddress(value) {
        state.emailAddressError = false;
        setEmailAddressError(false);
        setExistingEmailAddressError(false);
        setEmailAddress(value);
        state.sentVerificationCode = false;
    }
    function handlePhoneNumber(value) {
        state.phoneNumberError = false;
        setPhoneNumberError(false);
        setInvalidPhoneNumberError(false);
        setPhoneNumber(value);
    }
    function handleCompanyName(value) {
        setCompanyNameError(false);
        setExistingCompanyNameError(false);
        setCompanyNameInput(value);
        console.log("state", state);
    }
    function handleDesiredSubdomain(value) {
        if (isValidChars(value)) {
            state.subdomainError = false;
            setEmptyDesiredSubdomainError(false);
            setDesiredSubdomainError(false);
            setExistingDesiredSubdomainError(false);
            handleSubdomainValue(value);
            setDesiredSubdomain(value.toLowerCase());
        } else {
            handleSubdomainValue(value.slice(0, -1));
            setEmptyDesiredSubdomainError(false);
            state.subdomainError = true;
            setDesiredSubdomainError(true);
        }
    }
    function handleSubdomainValue(value) {
        setSubdomainValue(value);
    }

    async function SignInAsGuest() {
        let userAuthenticated = false;
        let user = null;
        try {
            // check if user signed in
            await fetchAuthSession({ bypassCache: true })
                .then((user) => {
                    console.log(
                        "*** User is authenticated, user = " +
                            JSON.stringify(user)
                    );
                    if (
                        user &&
                        user.tokens &&
                        user.tokens.idToken &&
                        user.tokens.idToken.payload
                    ) {
                        let groups =
                            user.tokens.idToken.payload["cognito:groups"];
                        if (groups && groups.length) {
                            userAuthenticated = true;
                        }
                    }
                })
                .catch((err) =>
                    console.log("*** Error: User is not authenticated - ", err)
                );

            if (!userAuthenticated) {
                console.log("*** User is NOT authenticated");
                await signIn({
                    username: process.env.REACT_APP_MB_GUEST_EMAIL,
                    password: process.env.REACT_APP_MB_GUEST_PWD
                });
            } else {
                console.log("Found logged in user:", JSON.stringify(user));
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === "PasswordResetRequiredException") {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === "NotAuthorizedException") {
                // The error happens when the incorrect password is provided
            } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(
                    "An error occurred attempting to login. Error was: " +
                        JSON.stringify(err)
                );
            }
        }
    }

    /*function to clean a phone number before validation 
      @return cleanphone ; a phone number with spaces, parenthesis, and dashes removed*/
    function cleanPhone(phonenumber) {
        console.log("from cleanPhone ->", phonenumber);
        let cleanphone = phonenumber.replace(/[- )(]/g, "");
        return cleanphone;
    }

    async function createCompany() {
        //console.log("company is created");
        //first: create the pending company, return pending company ID
        let result = await createPendingCompany(state);
        if (result) {
            state.companyId = result;
            state.companyExists = true;
            state.subscriptionLevel =
                query.get("mbsl") === "Bx5i"
                    ? "BASIC"
                    : query.get("mbsl") == "9oFe5510"
                      ? "PROFESSIONAL"
                      : "FREE_TRIAL_BASIC"; //mbsl -> marketbox subscription level.
        }

        //second: convert pending company to official company and create public site, return official company ID
        let companyId = await createCompanyAndPublicSite(state);
        if (companyId) {
            state.done = true;
            state.companyId = companyId;
        }

        //createScheduleIndex
        await createScheduleIndex(companyId);

        //third: submit HubSpot contact
        try {
            let hubspotContactResponse;
            let body = {
                companyName: state.companyNameInput,
                lastname: state.lastNameInput,
                firstname: state.firstNameInput,
                emailaddress: state.emailAddress,
                phoneNumber: state.cleanPhone,
                numberOfProviders: state.numProvidersSelected.hsvalue
                    ? state.numProvidersSelected.hsvalue
                    : "1",
                freeTrialSignUp: props.freeTrial ? true : false,
                mbSignUp: props.freeTrial ? false : true
            };
            if (hubspotContact) body = { ...body, id: hubspotContact.id }; //update existing hubspot contact
            hubspotContactResponse = await postApi("hubspotapi", "/contacts", {
                body
            });

            if (hubspotContactResponse && hubspotContactResponse.data)
                setHubspotContact(hubspotContactResponse.data);
        } catch (e) {
            console.log("error while creating hubspot contact", e);
        }

        props.renderActivateAccountForm(true, state);
    }

    /*function to validate free trial form before the company and public site are created.
    The company and public sie are created using CreateCompany.js*/
    async function validateForm(e) {
        setDisableGetStarted(true);
        console.log("validate was clicked here", state);
        await SignInAsGuest();

        if (!state.firstNameInput) {
            setFirstNameError(true);
        }
        if (!state.lastNameInput) {
            setLastNameError(true);
        }
        if (!state.companyNameInput) {
            setCompanyNameError(true);
        }
        if (!state.emailAddress) {
            state.emailAddressError = true;
            setEmailAddressError(true);
        }
        if (!state.phoneNumber) {
            state.phoneNumberError = true;
            setPhoneNumberError(true);
        }
        if (!state.desiredSubdomain) {
            state.subdomainError = true;
            setEmptyDesiredSubdomainError(true);
        }
        //check to see if the email is in valid format
        if (state.emailAddress) {
            if (validateEmailAddress(state.emailAddress)) {
                console.log("this is a valid email");
                state.emailAddressError = false;
                setEmailAddressError(false);
            } else {
                console.log("this is not a valid email");
                state.emailAddressError = true;
                setEmailAddressError(true);
            }
            //check to see if the email or subdomain are already in use
            if (!state.emailAddressError || !state.subdomainError) {
                const result = await validateUniqueEmailAndSubdomain(
                    state.emailAddress,
                    state.desiredSubdomain + ".gomarketbox.com"
                );
                console.log("result", result);
                console.log(
                    "uniqueEmail",
                    result[0],
                    "uniqueSubdomain",
                    result[1]
                );
                let isEmailUnique = result[0];
                let isSubdomainUnique = result[1];
                if (!isEmailUnique) {
                    state.emailAddressError = true;
                    setExistingEmailAddressError(true);
                }
                if (!isSubdomainUnique) {
                    state.subdomainError = true;
                    setExistingDesiredSubdomainError(true);
                }
            }
        }
        //check to see if the company name is already in use
        const isUniqueCompanyName = await validateUniqueCompanyName(
            state.companyNameInput
        );
        if (!isUniqueCompanyName) {
            setExistingCompanyNameError(true);
        }

        //validate and clean phone number
        if (state.phoneNumber) {
            let cleanphone = await cleanPhone(state.phoneNumber);
            console.log("from before validate phone ->", cleanphone);
            let result = await validatePhoneNumber(cleanphone);
            if (!result) {
                state.phoneNumberError = true;
                setInvalidPhoneNumberError(true);
            } else {
                state.cleanPhone = cleanphone;
            }
        }
        console.log("validate was clicked here", state);
        if (
            !state.firstNameInput ||
            !state.lastNameInput ||
            !state.companyNameInput ||
            state.phoneNumberError ||
            state.emailAddressError ||
            state.subdomainError ||
            !isUniqueCompanyName
        ) {
            console.log(
                "[ERROR]: FreeTrialForm : invalid form - company was not created"
            );
            setDisableGetStarted(false);
            return;
        } else {
            await createCompany();
            setDisableGetStarted(false);
        }
        e.preventDefault();
    }

    return (
        <Fragment>
            {/*Free Trial Form*/}
            <Paper
                className={
                    props.width < 1115 ? classes.paper_mobile : classes.paper
                }
            >
                <Grid container className={classes.papertxt}>
                    {props.freeTrial ? (
                        <Typography className={classes.paperheading}>
                            Create your free account
                        </Typography>
                    ) : (
                        <Typography className={classes.paperheading}>
                            Create your MarketBox account
                        </Typography>
                    )}

                    {props.freeTrial && (
                        <Typography className={classes.papersubheading}>
                            <i>No credit card required</i>
                        </Typography>
                    )}
                </Grid>

                <Grid
                    container
                    className={
                        props.width < 1115
                            ? classes.doublefield_mobile
                            : classes.doublefield
                    }
                >
                    <TextField
                        id="first-name-input"
                        label="First name"
                        className={
                            props.width < 1115
                                ? classes.field_mobile
                                : classes.field
                        }
                        variant="standard"
                        error={firstNameError}
                        helperText={" "}
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleFirstName(e.target.value)}
                    />
                    <TextField
                        id="last-name-input"
                        label="Last name"
                        className={
                            props.width < 1115
                                ? classes.field_mobile
                                : classes.field
                        }
                        variant="standard"
                        error={lastNameError}
                        helperText={" "}
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleLastName(e.target.value)}
                    />
                </Grid>

                <Grid
                    container
                    className={
                        props.width < 1115
                            ? classes.doublefield_mobile
                            : classes.doublefield
                    }
                >
                    <TextField
                        id="email-input"
                        label="Email address"
                        className={
                            props.width < 1115
                                ? classes.field_mobile
                                : classes.field
                        }
                        variant="standard"
                        error={emailAddressError || existingEmailAddressError}
                        helperText={
                            existingEmailAddressError ? (
                                <p
                                    style={{
                                        fontSize: "11px",
                                        lineHeight: "14px"
                                    }}
                                >
                                    An account linked to this email already
                                    exists
                                </p>
                            ) : (
                                " "
                            )
                        }
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleEmailAddress(e.target.value)}
                    />
                    <PhoneNumberField
                        phoneNumberError={phoneNumberError}
                        invalidPhoneNumberError={invalidPhoneNumberError}
                        handlePhoneNumber={handlePhoneNumber}
                        width={props.width}
                    />
                </Grid>

                <Grid container className={classes.singlefield}>
                    <TextField
                        id="company-name"
                        label="Company name"
                        variant="standard"
                        error={companyNameError || existingCompanyNameError}
                        helperText={
                            existingCompanyNameError
                                ? "A company with this name already exists in our system"
                                : " "
                        }
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        style={{ minWidth: "100%" }}
                        onChange={(e) => handleCompanyName(e.target.value)}
                    />
                </Grid>

                <Grid container className={classes.singlefield}>
                    <TextField
                        id="company-subdomain"
                        label=" "
                        variant="standard"
                        value={subdomainValue}
                        defaultValue={
                            state.desiredSubdomain
                                ? state.desiredSubdomain
                                : null
                        }
                        error={
                            desiredSubdomainError ||
                            existingDesiredSubdomainError ||
                            emptyDesiredSubdomainError
                        }
                        helperText={
                            desiredSubdomainError ? (
                                "Your booking URL cannot contain special characters"
                            ) : existingDesiredSubdomainError ? (
                                "An account with this subdomain already exists"
                            ) : (
                                <i>Select your booking page name</i>
                            )
                        }
                        style={{ minWidth: "100%" }}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography>.gomarketbox.com</Typography>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleDesiredSubdomain(e.target.value)}
                    />
                </Grid>

                <Grid
                    container
                    style={{ justifyContent: "center", paddingTop: "22px" }}
                >
                    <Button
                        className={classes.button}
                        disabled={disableGetStarted}
                        onClick={(e) => {
                            validateForm(e);
                        }}
                    >
                        {disableGetStarted ? (
                            <CircularProgress
                                size={24}
                                style={{ color: "white" }}
                            />
                        ) : (
                            <Typography
                                style={{
                                    fontSize:
                                        props.width < 369 ? "19px" : "21px",

                                    textTransform: "none",
                                    fontWeight: "bold"
                                }}
                            >
                                Get started
                            </Typography>
                        )}
                    </Button>
                    <Typography
                        style={{ fontSize: "12px", padding: "8px 0px" }}
                    >
                        {
                            <i>
                                By signing up, you agree to our
                                <Link
                                    href="https://www.gomarketbox.com/privacy"
                                    underline="hover"
                                    target="_blank"
                                >
                                    {" privacy policy"}
                                </Link>
                            </i>
                        }
                    </Typography>
                </Grid>
            </Paper>
        </Fragment>
    );
};

export default FreeTrialForm;
