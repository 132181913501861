import React, { Fragment, useContext, useState, useEffect } from "react";
import { fetchAuthSession, signIn } from "aws-amplify/auth";
import UserInputContext from "../../context/userInput/userInputContext";
import PhoneNumberField from "../custom/PhoneNumberField";
import {
    validateEmailAddress,
    validatePhoneNumber,
    validateUniqueEmailAndSubdomain,
    validateUniqueCompanyName
} from "../../modules/ValidityCheck";
import { Typography, TextField, Grid, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mbxUserAtom } from "../../../../atoms/atoms";
import { useAtom } from "jotai";
import * as Sentry from "@sentry/react";

/* styles used in free trial form */
const useStyles = makeStyles({
    paper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        borderRadius: "48px",
        "& *": {
            fontFamily: "Poppins, sans-serif",
            marginLeft: "0px"
        }
    },
    paper_mobile: {
        width: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        borderRadius: "48px",
        maxWidth: "550px",
        "& *": {
            fontFamily: "Poppins, sans-serif",
            marginLeft: "0px"
        }
    },
    papertxt: {
        flexDirection: "column",
        alignContent: "center",
        marginBottom: "18px"
    },
    paperheading: {
        fontSize: "25px",
        color: "#134A55"
    },
    papersubheading: {
        textAlign: "center",
        fontSize: "14px",
        color: "#0087ee",
        paddingBottom: "8px"
    },
    doublefield: {
        justifyContent: "space-between",
        padding: "0px 42px",
        display: "flex",
        flexWrap: "nowrap",
        gap: "18px"
    },
    doublefield_mobile: {
        justifyContent: "space-between",
        padding: "0px 20px",
        flexDirection: "column"
    },
    singlefield: {
        padding: "0px 42px"
    },
    singlefield_mob: {
        padding: "0px 20px"
    },
    button: {
        backgroundColor: "#FF681D",
        color: "white",
        width: "67%",
        height: "2rem",
        borderRadius: "12px",
        "&:hover": {
            backgroundColor: "#E64C00"
        }
    },
    field: {
        width: "50%"
    },
    field_mobile: {
        width: "100%"
    },
    input: {
        "&:before": {
            borderBottom: "1px solid #E6E6E6"
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: "1px solid blue"
        },
        "&:after": {
            borderBottom: "1px solid black"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(0,0,0,0.67)"
        },
        "&.MuiInputLabel-root": {
            display: "none"
        },
        "& fieldset": {
            "& legend": {
                width: "unset"
            }
        }
    },
    inputlabel: {
        "&:focused": {
            color: "red"
        },
        color: "rgb(0,0,0,0.67)",
        "&.MuiFormLabel-root.Mui-focused": {
            color: "black"
        }
    }
});

const CheckoutForm = (props) => {
    const userInputContext = useContext(UserInputContext);
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);
    const {
        userInputs: state,
        hubspotContact,
        setHubspotContact
    } = userInputContext;
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);
    const [subdomainValue, setSubdomainValue] = useState(
        props.reactivate
            ? mbxUser?.company?.subdomain.split(".")[0]
            : state.desiredSubdomain
    );
    const [firstNameValue, setFirstNameValue] = useState(
        props.reactivate ? mbxUser.firstname : state.firstNameInput
    );
    const [lastNameValue, setLastNameValue] = useState(
        props.reactivate ? mbxUser.lastname : state.lastNameInput
    );
    const [emailValue, setEmailValue] = useState(
        props.reactivate ? mbxUser?.company?.emailaddress : state.emailAddress
    );
    const [phoneNumberValue, setPhoneNumberValue] = useState(
        props.reactivate ? mbxUser.mobilephone : state.phoneNumber
    );
    const [companyNameValue, setCompanyNameValue] = useState(
        props.reactivate ? mbxUser?.company?.name : state.companyNameInput
    );
    const isValidChars = (str) => /^[A-Za-z0-9_-]*$/.test(str);
    const [checkoutSuccess, setCheckoutSuccess] = useState(
        query.get("success")
    ); //boolean variable

    /* state for error handling */
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [emailAddressError, setEmailAddressError] = useState(false);
    const [existingEmailAddressError, setExistingEmailAddressError] =
        useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [invalidPhoneNumberError, setInvalidPhoneNumberError] =
        useState(false);
    const [desiredSubdomainError, setDesiredSubdomainError] = useState(false);
    const [existingDesiredSubdomainError, setExistingDesiredSubdomainError] =
        useState(false);
    const [emptyDesiredSubdomainError, setEmptyDesiredSubdomainError] =
        useState(false);
    const [existingCompanyNameError, setExistingCompanyNameError] =
        useState(false);

    useEffect(() => {
        if (props.validate) {
            validateForm(state);
        }
    }, [props.validate]);

    useEffect(() => {
        !state.firstNameInput && setFirstNameInput(mbxUser?.firstname);
        !state.lastNameInput && setLastNameInput(mbxUser?.lastname);
        !state.emailAddress && setEmailAddress(mbxUser?.company?.emailaddress);
        !state.phoneNumber && setPhoneNumber(mbxUser?.mobilephone);
        !state.companyNameInput && setCompanyNameInput(mbxUser?.company?.name);
        !state.desiredSubdomain &&
            setDesiredSubdomain(mbxUser?.company?.subdomain.split(".")[0]);
    }, [props.reactivate]);

    /* functions to handle user input state changes in userInputContext*/
    function setFirstNameInput(firstNameInput = "") {
        state.firstNameInput = firstNameInput;
    }
    function setLastNameInput(lastNameInput = "") {
        state.lastNameInput = lastNameInput;
    }
    function setEmailAddress(emailAddress = "") {
        state.emailAddress = emailAddress.toLowerCase();
    }
    function setPhoneNumber(phoneNumber = "") {
        state.phoneNumber = phoneNumber;
    }
    function setCompanyNameInput(companyNameInput = "") {
        state.companyNameInput = companyNameInput;
    }
    function setDesiredSubdomain(desiredSubdomain = "") {
        state.desiredSubdomain = desiredSubdomain;
    }

    /* functions to handle user inputs */
    function handleFirstName(value) {
        setFirstNameValue(value);
        setFirstNameError(false);
        setFirstNameInput(value.trim());
    }
    function handleLastName(value) {
        setLastNameValue(value);
        setLastNameError(false);
        setLastNameInput(value.trim());
    }
    function handleEmailAddress(value) {
        state.emailAddressError = false;
        setEmailValue(value);
        setEmailAddressError(false);
        setExistingEmailAddressError(false);
        setEmailAddress(value);
        props.onEmailUpdate(value);
        state.sentVerificationCode = false;
    }
    function handlePhoneNumber(value) {
        setPhoneNumberValue(value);
        state.phoneNumberError = false;
        setPhoneNumberError(false);
        setInvalidPhoneNumberError(false);
        setPhoneNumber(value);
    }
    function handleCompanyName(value) {
        setCompanyNameValue(value);
        setCompanyNameError(false);
        setExistingCompanyNameError(false);
        setCompanyNameInput(value);
    }
    function handleDesiredSubdomain(value) {
        if (isValidChars(value)) {
            state.subdomainError = false;
            setEmptyDesiredSubdomainError(false);
            setDesiredSubdomainError(false);
            setExistingDesiredSubdomainError(false);
            handleSubdomainValue(value);
            setDesiredSubdomain(value.toLowerCase());
        } else {
            handleSubdomainValue(value.slice(0, -1));
            setEmptyDesiredSubdomainError(false);
            state.subdomainError = true;
            setDesiredSubdomainError(true);
        }
    }
    function handleSubdomainValue(value) {
        setSubdomainValue(value);
    }

    /*function to clean a phone number before validation 
      @return cleanphone ; a phone number with spaces, parenthesis, and dashes removed*/
    function cleanPhone(phonenumber) {
        let cleanphone = phonenumber.replace(/[- )(]/g, "");
        state.cleanPhone = cleanphone;
        return cleanphone;
    }

    async function SignInAsGuest() {
        let userAuthenticated = false;
        let user = null;
        try {
            // check if user signed in
            await fetchAuthSession({ bypassCache: true })
                .then((user) => {
                    if (
                        user &&
                        user.tokens &&
                        user.tokens.idToken &&
                        user.tokens.idToken.payload
                    ) {
                        let groups =
                            user.tokens.idToken.payload["cognito:groups"];
                        if (groups && groups.length) {
                            userAuthenticated = true;
                        }
                    }
                })
                .catch((err) => {
                    console.log("*** Error: User is not authenticated - ", err);
                    Sentry.captureException(err);
                });

            if (!userAuthenticated) {
                try {
                    const signedInDetails = await signIn({
                        username: process.env.REACT_APP_MB_GUEST_EMAIL,
                        password: process.env.REACT_APP_MB_GUEST_PWD
                    });
                    console.log("isSignedIn, nextStep", signedInDetails);
                } catch (e) {
                    console.log(
                        "checkout signin error",
                        process.env.REACT_APP_MB_GUEST_EMAIL,
                        e
                    );
                }
            } else {
                console.log("Found logged in user 1:", JSON.stringify(user));
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === "PasswordResetRequiredException") {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === "NotAuthorizedException") {
                // The error happens when the incorrect password is provided
            } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(
                    "An error occurred attempting to login. Error was: " +
                        JSON.stringify(err)
                );
                Sentry.captureException(err);
            }
        }
    }

    /*function to validate free trial form before the company and public site are created.
    The company and public sie are created using CreateCompany.js*/
    async function validateForm(e) {
        props.onValidateUpdate(false);
        if (!props.reactivate) {
            await SignInAsGuest();
        }

        if (!state.firstNameInput) {
            setFirstNameError(true);
        }
        if (!state.lastNameInput) {
            setLastNameError(true);
        }
        if (!state.companyNameInput) {
            setCompanyNameError(true);
        }
        if (!state.emailAddress) {
            state.emailAddressError = true;
            setEmailAddressError(true);
        }
        if (!state.phoneNumber) {
            state.phoneNumberError = true;
            setPhoneNumberError(true);
        }
        if (!state.desiredSubdomain) {
            state.subdomainError = true;
            setEmptyDesiredSubdomainError(true);
        }
        //check to see if the email is in valid format
        if (state.emailAddress) {
            if (validateEmailAddress(state.emailAddress)) {
                state.emailAddressError = false;
                setEmailAddressError(false);
            } else {
                state.emailAddressError = true;
                setEmailAddressError(true);
            }
            //check to see if the email or subdomain are already in use
            if (!state.emailAddressError || !state.subdomainError) {
                const result = await validateUniqueEmailAndSubdomain(
                    state.emailAddress,
                    state.desiredSubdomain + ".gomarketbox.com"
                );
                let isEmailUnique = result[0];
                let isSubdomainUnique = result[1];
                if (!props.reactivate) {
                    if (!isEmailUnique) {
                        state.emailAddressError = true;
                        setExistingEmailAddressError(true);
                    }
                    if (!isSubdomainUnique) {
                        state.subdomainError = true;
                        setExistingDesiredSubdomainError(true);
                    }
                }
            }
        }
        //check to see if the company name is already in use
        const isUniqueCompanyName = await validateUniqueCompanyName(
            state.companyNameInput
        );
        if (!isUniqueCompanyName) {
            setExistingCompanyNameError(true);
        }

        //validate and clean phone number
        if (state.phoneNumber) {
            let cleanphone = await cleanPhone(state.phoneNumber);
            let result = await validatePhoneNumber(cleanphone);
            if (!result) {
                state.phoneNumberError = true;
                setInvalidPhoneNumberError(true);
            } else {
                state.cleanPhone = cleanphone;
            }
        }
        if (
            !state.firstNameInput ||
            !state.lastNameInput ||
            !state.companyNameInput ||
            state.phoneNumberError ||
            state.emailAddressError ||
            state.subdomainError ||
            !isUniqueCompanyName
        ) {
            sessionStorage.removeItem("validating");
            props.onIsValid(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        } else {
            props.onIsValid(true);
        }
    }

    return (
        <Fragment>
            {/*Free Trial Form*/}
            <Grid
                className={
                    props.width < 1115 ? classes.paper_mobile : classes.paper
                }
            >
                <Grid container className={classes.papertxt}>
                    <Typography className={classes.paperheading}>
                        {checkoutSuccess
                            ? "Thank you for your purchase"
                            : "Tell us about yourself"}
                    </Typography>
                </Grid>

                <Grid
                    container
                    className={
                        props.width < 1115
                            ? classes.doublefield_mobile
                            : classes.doublefield
                    }
                >
                    <TextField
                        id="first-name-input"
                        label="First name"
                        className={
                            props.width < 1115
                                ? classes.field_mobile
                                : classes.field
                        }
                        value={
                            firstNameValue
                                ? firstNameValue
                                : state.firstNameInput
                        }
                        disabled={checkoutSuccess}
                        variant="outlined"
                        error={firstNameError}
                        helperText={" "}
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleFirstName(e.target.value)}
                    />
                    <TextField
                        id="last-name-input"
                        label="Last name"
                        className={
                            props.width < 1115
                                ? classes.field_mobile
                                : classes.field
                        }
                        value={
                            lastNameValue ? lastNameValue : state.lastNameInput
                        }
                        disabled={checkoutSuccess}
                        variant="outlined"
                        error={lastNameError}
                        helperText={" "}
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleLastName(e.target.value)}
                    />
                </Grid>

                <Grid
                    item
                    className={
                        props.width < 1115
                            ? classes.doublefield_mobile
                            : classes.doublefield
                    }
                >
                    <TextField
                        id="email-input"
                        label="Email address"
                        className={
                            props.width < 1115
                                ? classes.field_mobile
                                : classes.field
                        }
                        value={emailValue ? emailValue : state.emailAddress}
                        disabled={checkoutSuccess}
                        variant="outlined"
                        error={emailAddressError || existingEmailAddressError}
                        helperText={
                            existingEmailAddressError ? (
                                <p
                                    style={{
                                        fontSize: "11px",
                                        lineHeight: "14px"
                                    }}
                                >
                                    An account linked to this email already
                                    exists
                                </p>
                            ) : (
                                " "
                            )
                        }
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleEmailAddress(e.target.value)}
                    />
                    <PhoneNumberField
                        phoneNumberError={phoneNumberError}
                        invalidPhoneNumberError={invalidPhoneNumberError}
                        phoneNumberValue={
                            phoneNumberValue
                                ? phoneNumberValue
                                : state.phoneNumber
                        }
                        handlePhoneNumber={handlePhoneNumber}
                        checkoutSuccess={checkoutSuccess}
                        width={props.width}
                    />
                </Grid>

                <Grid
                    container
                    className={
                        props.width > 1115
                            ? classes.singlefield
                            : classes.singlefield_mob
                    }
                >
                    <TextField
                        id="company-name"
                        label="Company name"
                        disabled={checkoutSuccess}
                        variant="outlined"
                        error={companyNameError || existingCompanyNameError}
                        helperText={
                            existingCompanyNameError
                                ? "A company with this name already exists in our system"
                                : " "
                        }
                        value={
                            companyNameValue
                                ? companyNameValue
                                : state.companyNameInput
                        }
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        style={{ minWidth: "100%" }}
                        onChange={(e) => handleCompanyName(e.target.value)}
                    />
                </Grid>

                <Grid
                    container
                    className={
                        props.width > 1115
                            ? classes.singlefield
                            : classes.singlefield_mob
                    }
                >
                    <TextField
                        id="company-subdomain"
                        label=""
                        disabled={checkoutSuccess || props.reactivate}
                        variant="outlined"
                        value={
                            subdomainValue
                                ? subdomainValue
                                : state.desiredSubdomain
                        }
                        error={
                            desiredSubdomainError ||
                            existingDesiredSubdomainError ||
                            emptyDesiredSubdomainError
                        }
                        helperText={
                            desiredSubdomainError ? (
                                "Your booking URL cannot contain special characters"
                            ) : existingDesiredSubdomainError ? (
                                "An account with this subdomain already exists"
                            ) : (
                                <i>Select your booking page name</i>
                            )
                        }
                        style={{ minWidth: "100%" }}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography
                                        style={{
                                            fontSize:
                                                props.width < 450 ? "14px" : ""
                                        }}
                                    >
                                        .gomarketbox.com
                                    </Typography>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            className: classes.inputlabel
                        }}
                        onChange={(e) => handleDesiredSubdomain(e.target.value)}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default CheckoutForm;
