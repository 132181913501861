import React, { useContext } from "react";
import Table from "@mui/material/Table";
import { StoreContext } from "../context/StoreContext";
// prettier-ignore
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, IconButton, Tooltip } from "@mui/material";
// prettier-ignore
import { Paper, Checkbox } from "@mui/material";
// prettier-ignore
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import EditIcon from "@mui/icons-material/Create";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useStyles, useStyles1 } from "./PromotionsTable";
import CurrencyFormat from "react-currency-format";
import { getCurrencySymbol } from "../utils";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../utils/Common/TableRowsSession";
import { useNavigate } from "react-router-dom";

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
                size="large"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};
export const RegionTable = ({ selectedRow, getRegionList, listRegions }) => {

    const navigate = useNavigate();

    const [selected, setSelected] = React.useState([]);
    const { state, actions } = useContext(StoreContext);
    const classes = useStyles();
    const [selectAll, setSelectAll] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        getTableRowsSession("regPricingTable")
    );
    const postalCodeStyle = { maxWidth: "250px" };

    const _handleRegionAction = (id, page) => {
        actions.setMode(page);
        navigate("edit");
        actions.setId(id);
    };

    const _handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelecteds = listRegions.map((n) => n.id);
            setSelected(newSelecteds);
            selectedRow(newSelecteds);
            return;
        } else {
            setSelectAll(false);
            selectedRow([]);
        }
        setSelected([]);
    };
    const _handleRowClick = (event, id) => {
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedRow(newSelected);
    };

    const _handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const _handleChangeRowsPerPage = (event) => {
        if (getTableRowsSession("regPricingTable")) {
            setTableRowsSession("regPricingTable", event.target.value);
        }
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function displayPostalCodes(postalCodes) {
        return postalCodes.length > 40
            ? postalCodes.substring(0, 40) + "..."
            : postalCodes;
    }

    return (
        <>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectAll}
                                            onClick={_handleSelectAllClick}
                                        />
                                    </TableCell>

                                    <TableCell align="left">
                                        Service Type
                                    </TableCell>
                                    <TableCell align="left">
                                        Pricing Type
                                    </TableCell>
                                    <TableCell align="center">
                                        Country
                                    </TableCell>
                                    <TableCell align="center">
                                        Province/State
                                    </TableCell>
                                    <TableCell align="left">
                                        Postal Codes
                                    </TableCell>
                                    <TableCell align="center">Price</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listRegions &&
                                    listRegions.length > 0 &&
                                    listRegions
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((item, i) => {
                                            return (
                                                <TableRow
                                                    key={item.id}
                                                    hover
                                                    onClick={(event) =>
                                                        _handleRowClick(
                                                            event,
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    item.id
                                                                ) !== -1 ||
                                                                selectAll
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {item.servicetype
                                                            .name &&
                                                            item.servicetype
                                                                .name}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {item.pricingtype &&
                                                            item.pricingtype}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.country &&
                                                            item.country}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.province &&
                                                            item.province}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={postalCodeStyle}
                                                    >
                                                        {item.postalcodes &&
                                                            item.postalcodes
                                                                .length &&
                                                            displayPostalCodes(
                                                                item.postalcodes.join()
                                                            )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CurrencyFormat
                                                            value={item.price}
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={getCurrencySymbol()}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title="Edit">
                                                            <EditIcon
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    _handleRegionAction(
                                                                        item.id,
                                                                        "Edit"
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>
                                                        {/* <Tooltip title="View">
                                                        <ViewIcon
                                                            onClick={(
                                                                event
                                                            ) =>
                                                                _handleRegionAction(
                                                                    item.id,
                                                                    "View"
                                                                )
                                                            }
                                                        />
                                                    </Tooltip> */}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={6}
                                        count={listRegions.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page"
                                            },
                                            native: true
                                        }}
                                        onPageChange={_handleChangePage}
                                        onRowsPerPageChange={
                                            _handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div>
        </>
    );
};
