import { alpha } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    paper: {
        padding: 2
    },
    root: {
        padding: theme.spacing(3, 2)
    }
}));
