import React, { createContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const GoogleMapsContext = createContext();

const GoogleMapsProvider = ({ children }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-maps",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        preventGoogleFontsLoading: false,
        language: "en",
        region: "US",
        libraries: ["places", "drawing"],
        version: "3"
    });

    return (
        <GoogleMapsContext.Provider value={{ isLoaded }}>
            {children}
        </GoogleMapsContext.Provider>
    );
};

export { GoogleMapsProvider, GoogleMapsContext };
