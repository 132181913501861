import makeStyles from '@mui/styles/makeStyles';

export const useProviderDirectoryStyles = makeStyles(theme => ({
  menu: {
    fontSize: 200
    //boxShadow: "0px 1px 2px #ccc",
    //position: "absolute",
    //marginTop: "5px",
    //backgroundColor: "#fff",
    //zIndex: "9"
  }
}));
