import { CloseRounded } from "@mui/icons-material";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    Alert
} from "@mui/material";
import React, { useState, useEffect } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customLocale } from "../utils/CalendarFunctions/CalendarConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";
import { getUserFromCache } from "../user/UserCommon";
import { graphql, graphqlOperation, postApi } from "../modules/AmplifyServices";
import { execReadBySortkey } from "../modules/DBService";
import * as scheduleQueries from "../modules/ScheduleQueries";
import * as mutations from "../graphql/mutations";
import { getBookingDateDescription } from "../modules/TimeService";
import { auditOverrideBooking } from "../modules/Audit";
import {
    isBlockedTime,
    checkProviderAvailabilityForRechedule
} from "../modules/CheckAvailability";
import {
    getCompanyLocation,
    getCompanyLocationId,
    getProviderLocation,
    getProviderLocationId
} from "../modules/ReschedulingFunctions";
import { mbxUserAtom } from "../atoms/atoms";
import { useAtom } from "jotai";
import { createProviderScheduleBK } from "../modules/ScheduleService";
import { extractLocationIdFromScheduleinfo } from "../utils/providerLocationUtils";

const timeMapping = {
    "12:00 AM": "24:00",
    "12:15 AM": "00:15",
    "12:30 AM": "00:30",
    "12:45 AM": "00:45",
    "1:00 AM": "01:00",
    "1:15 AM": "01:15",
    "1:30 AM": "01:30",
    "1:45 AM": "01:45",
    "2:00 AM": "02:00",
    "2:15 AM": "02:15",
    "2:30 AM": "02:30",
    "2:45 AM": "02:45",
    "3:00 AM": "03:00",
    "3:15 AM": "03:15",
    "3:30 AM": "03:30",
    "3:45 AM": "03:45",
    "4:00 AM": "04:00",
    "4:15 AM": "04:15",
    "4:30 AM": "04:30",
    "4:45 AM": "04:45",
    "5:00 AM": "05:00",
    "5:15 AM": "05:15",
    "5:30 AM": "05:30",
    "5:45 AM": "05:45",
    "6:00 AM": "06:00",
    "6:15 AM": "06:15",
    "6:30 AM": "06:30",
    "6:45 AM": "06:45",
    "7:00 AM": "07:00",
    "7:15 AM": "07:15",
    "7:30 AM": "07:30",
    "7:45 AM": "07:45",
    "8:00 AM": "08:00",
    "8:15 AM": "08:15",
    "8:30 AM": "08:30",
    "8:45 AM": "08:45",
    "9:00 AM": "09:00",
    "9:15 AM": "09:15",
    "9:30 AM": "09:30",
    "9:45 AM": "09:45",
    "10:00 AM": "10:00",
    "10:15 AM": "10:15",
    "10:30 AM": "10:30",
    "10:45 AM": "10:45",
    "11:00 AM": "11:00",
    "11:15 AM": "11:15",
    "11:30 AM": "11:30",
    "11:45 AM": "11:45",
    "12:00 PM": "12:00",
    "12:15 PM": "12:15",
    "12:30 PM": "12:30",
    "12:45 PM": "12:45",
    "1:00 PM": "13:00",
    "1:15 PM": "13:15",
    "1:30 PM": "13:30",
    "1:45 PM": "13:45",
    "2:00 PM": "14:00",
    "2:15 PM": "14:15",
    "2:30 PM": "14:30",
    "2:45 PM": "14:45",
    "3:00 PM": "15:00",
    "3:15 PM": "15:15",
    "3:30 PM": "15:30",
    "3:45 PM": "15:45",
    "4:00 PM": "16:00",
    "4:15 PM": "16:15",
    "4:30 PM": "16:30",
    "4:45 PM": "16:45",
    "5:00 PM": "17:00",
    "5:15 PM": "17:15",
    "5:30 PM": "17:30",
    "5:45 PM": "17:45",
    "6:00 PM": "18:00",
    "6:15 PM": "18:15",
    "6:30 PM": "18:30",
    "6:45 PM": "18:45",
    "7:00 PM": "19:00",
    "7:15 PM": "19:15",
    "7:30 PM": "19:30",
    "7:45 PM": "19:45",
    "8:00 PM": "20:00",
    "8:15 PM": "20:15",
    "8:30 PM": "20:30",
    "8:45 PM": "20:45",
    "9:00 PM": "21:00",
    "9:15 PM": "21:15",
    "9:30 PM": "21:30",
    "9:45 PM": "21:45",
    "10:00 PM": "22:00",
    "10:15 PM": "22:15",
    "10:30 PM": "22:30",
    "10:45 PM": "22:45",
    "11:00 PM": "23:00",
    "11:15 PM": "23:15",
    "11:30 PM": "23:30",
    "11:45 PM": "23:45"
};

function ProviderRescheduleModal(props) {
    const [dateValue, setDateValue] = useState(new Date());
    const [timeValue, setTimeValue] = useState("8:00 AM");
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [disableCalendar, setDisableCalendar] = useState(false);
    const [showCircular, setShowCircular] = useState(false);
    const [timeZone, setTimeZone] = useState("");
    const [origDate, setOrigDate] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorType, setErrorType] = useState("error");
    const [showCloseBtn, setShowCloseBtn] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [unavailableTimeBlocks, setUnavailableTimeBlocks] = useState([]);
    const SCHEDPKSKSPLITAT = "::";
    const [mbxUser] = useAtom(mbxUserAtom);

    /* useEffect is only called once when the reschduleModal Dialogue opens */
    useEffect(() => {
        async function getUnavailableTimeblocks() {
            try {
                const unavailableTimeblocksList = await execReadBySortkey({
                    opname: "listProviderSchedules",
                    op: scheduleQueries.listProviderSchedules,
                    id: {
                        id: `C-${mbxUser.company.id}|P-${mbxUser.provider.id}`
                    },
                    skey: { scheduleinfo: { beginsWith: "BUT" } },
                    filter: { active: { ne: false } },
                    limit: 500
                });
                setUnavailableTimeBlocks(unavailableTimeblocksList.items);
            } catch (e) {
                console.log("error in getting ref data", e);
            }
        }
        if (props.rescheduleModal) {
            getUnavailableTimeblocks();
        }
    }, [props.rescheduleModal]);

    // const timeBlocks = createTimeList(15);
    useEffect(() => {
        console.log("Selected Row Data | Reschedule Modal", props);
        console.log("Disable Submit", disableSubmit);

        if (props?.selectedRowData) {
            console.log("logging from here 1 ");
            let bookedDate = new Date(props.selectedRowData.startdate);
            if (new Date() !== bookedDate) {
                console.log("logging from here 2");
                let user_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let check_t = new Date(
                    props.selectedRowData.startdate
                ).toLocaleString("en", {
                    timeZone: props.selectedRowData.timezone,
                    timeZoneName: "short"
                });
                console.log("Check T", check_t);
                console.log("User TZ", user_tz);

                if (user_tz === props.selectedRowData.timezone) {
                    let t = bookedDate.toLocaleTimeString("en-US").split(":");
                    let am_pm = t[t.length - 1].split(" ")[1];
                    console.log("Date Value bookedDate useEffect", bookedDate);
                    setDateValue(bookedDate);
                    setTimeValue(`${t[0]}:${t[1]} ${am_pm}`);
                    setOrigDate(bookedDate.toLocaleString("en-US"));
                    setErrorType("error");
                    setErrorMessage("");
                    setError(false);
                } else {
                    console.log("TimeZone Different");
                    let t = new Date(
                        props.selectedRowData.startdate
                    ).toLocaleString("en", {
                        timeZone: props.selectedRowData.timezone,
                        timeZoneName: "short"
                    });
                    let d_split = t.split(" ");
                    let t_split = d_split[1].split(":");
                    let am_pm = d_split[2];

                    let date_val = new Date(d_split[0].split(",")[0]);
                    // changeTime(date_val, t_split[0], t_split[1] + " " + am_pm);
                    console.log("Date Value useEffect", date_val);
                    setDateValue(date_val);
                    setTimeValue(`${t_split[0]}:${t_split[1]} ${am_pm}`);
                    setOrigDate(date_val.toLocaleString("en-US"));
                    console.log(
                        "Correct TIme",
                        t + " | " + d_split,
                        +" | " +
                            t_split +
                            " | " +
                            `${t_split[0]}:${t_split[1]} ${am_pm}`
                    );
                }
            }

            console.log("BookedDate to UTC", bookedDate);

            console.log("timezone", props.selectedRowData.timezone);
            console.log(
                "extract tz",
                props.selectedRowData.startDateDesc.split(" ").slice(-2)[0]
            );

            const tz_parse = JSON.parse(props.selectedRowData.TimeDisplayInfo);

            setTimeZone(tz_parse.tz_abbr_disp);
        }

        console.log("Date Value Check", dateValue);
    }, [
        props.selectedRowData,
        props.rescheduleModal,
        props.providerReScheduleModal
    ]);

    useEffect(() => {
        console.log(
            "date matches",
            dateValue.toLocaleString("en-US"),
            origDate
        );
        if (dateValue.toLocaleString("en-US") === origDate) {
            console.log(
                "date matches if",
                dateValue.toLocaleString("en-US"),
                origDate
            );
            // setShowCircular(false);
            //setDisableCalendar(false);
            setDisableSubmit(true);
        }
    }, [dateValue]);

    const formatTimeToListValue = (d) => {
        let t = d.toLocaleTimeString("en-US").split(":");
        let am_pm = t[t.length - 1].split(" ")[1];

        return `${t[0]}:${t[1]} ${am_pm}`;
    };

    function decideSlotSearchDateRange() {
        // console.log("toDate State", toDate);
        //console.log("fromDate State", fromDate);
        let toDate = new Date(Date.now() + 86400000 * 365);
        let fromDate = new Date(); //dateValue <- change to this for start getting dates from the booking date;
        let diff = toDate.getTime() - fromDate.getTime();
        //let smallerThan365 = diff < 86400000 * 365;
        let diffNumOfDays = diff / 86400000;
        if (diffNumOfDays <= 30) {
            return {
                startDate: fromDate,
                numOfDays: 30
            };
        } else {
            const td = moment(toDate).endOf("month");
            // setToDate(td.toDate());
            return {
                startDate: fromDate,
                numOfDays: td.diff(moment(fromDate), "days")
            };
        }
    }

    async function checkIsDuringBlockedTime(selectedDateTime) {
        //format of selectedDateTime must be like this "Fri Aug 04 2023 10:00:10 GMT-0400"

        const selectedDate = new Date(selectedDateTime)
            .toISOString()
            .split("T")[0];
        const selectedTime = new Date(selectedDateTime).toLocaleTimeString(
            "en-US",
            {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit"
            }
        );
        let isDuringBlockedTime = await isBlockedTime(
            unavailableTimeBlocks,
            selectedDate,
            selectedTime
        );
        if (isDuringBlockedTime) {
            setErrorType("warning");
            setErrorMessage(
                "The date/time you selected is during your blocked time"
            );
            setError(true);
        }
    }

    function makeProviderScheduled(bookingDataTimeblockid) {
        const timeblock = bookingDataTimeblockid.split(SCHEDPKSKSPLITAT);
        let scheduleinfoParts = timeblock[1].split("|");
        console.log(
            "Make Provider Schedule ID ",
            `${timeblock[0]}${SCHEDPKSKSPLITAT}${scheduleinfoParts[2]}|${scheduleinfoParts[3]}|${scheduleinfoParts[4]}`
        );
        return `${timeblock[0]}${SCHEDPKSKSPLITAT}${scheduleinfoParts[2]}|${scheduleinfoParts[3]}|${scheduleinfoParts[4]}`;
    }

    const submitReschedulingHandler = async (data) => {
        try {
            setShowCircular(true);
            setDisableSubmit(true);
            setDisableCalendar(true);
            setShowCloseBtn(false);
            setShowBackdrop(true);

            if (dateValue.toISOString() === origDate) {
                console.log("date matches");
                setShowCircular(false);
                setDisableCalendar(false);
                setShowCloseBtn(true);
                setShowBackdrop(false);
                //setDisableSubmit(true);
                return;
            }
            const user = getUserFromCache();
            let locationData;
            let companyLocationId;
            let providerLocationId;
            const providerScheduleId = makeProviderScheduled(data.timeblockid);

            if (data.timeblockid.includes("CL-")) {
                companyLocationId = await getCompanyLocationId(
                    data.timeblockid
                );

                locationData = await getCompanyLocation(companyLocationId);
            }

            if (data.timeblockid.includes("PL-")) {
                providerLocationId = await getProviderLocationId(
                    data.timeblockid
                );
                locationData = await getProviderLocation(providerLocationId);
                // isRemoteLoc = true;
            }
            let coords = {
                lat: locationData.latitude,
                lng: locationData.longitude
            };
            let newDate = `${moment(dateValue).format("ll")} at ${moment(
                dateValue
            ).format("LT")}`;

            console.log("LoggedInUser", user);
            console.log("Selected Row Data", data);
            console.log("Provider Schedule ID", providerScheduleId);

            // Delete old timeblocks and create new ones
            const timeblock = data.timeblockid.split(SCHEDPKSKSPLITAT);
            const input = {
                id: timeblock[0],
                scheduleinfo: timeblock[1]
            };

            //if the provider is same and booking date and time is same, no need to check availability

            let skipavailabilitycheck = false;
            if (
                providerScheduleId &&
                providerScheduleId.includes(data.providerId)
            ) {
                const currentdatetime = new Date(data.startdate);
                const selecteddatetime = new Date(dateValue);
                if (currentdatetime && selecteddatetime) {
                    if (
                        currentdatetime.valueOf() === selecteddatetime.valueOf()
                    ) {
                        skipavailabilitycheck = true;
                    }
                }
            }
            console.log("Skip Availabily Check", skipavailabilitycheck);

            const startDate = moment(dateValue).format("YYYY-MM-DD");
            const startTime = moment(dateValue).format("HH:mm");
            const endTime = moment(dateValue)
                .add(data.minutes, "minutes")
                .format("HH:mm");

            //if not available check
            if (!skipavailabilitycheck) {
                let isBookedTimeAvailable =
                    await checkProviderAvailabilityForRechedule({
                        timezone: locationData.timezone,
                        startdate: startDate,
                        startTime,
                        endTime,
                        geoLoc: coords,
                        overriddenbookedslot: input,
                        bookingIncrement: data.company?.bookingIncrement
                            ? data.company?.bookingIncrement
                            : 15 //does not have impact of checking availability but for the sake of consistency with the other similar functions.
                    });

                if (!isBookedTimeAvailable) {
                    setShowCircular(false);
                    setDisableSubmit(false);
                    setDisableCalendar(false);
                    setShowCloseBtn(true);
                    setShowBackdrop(false);
                    setErrorType("error");
                    setErrorMessage(
                        "This time conflicts with an existing booking. Please select another time."
                    );
                    setError(true);
                    return;
                }

                await graphql(
                    graphqlOperation(mutations.deleteProviderSchedule, {
                        input
                    })
                );

                let timezone = locationData.timezone;
                let dateInfo = await getBookingDateDescription(
                    dateValue,
                    timezone,
                    data.minutes
                );

                let bookedtimeblockData = {
                    companyId: data.companyId,
                    startDate: startDate,
                    endDate: startDate,
                    startTime,
                    endTime,
                    type: "BOOKED",
                    providerId: data.provider.id,
                    status: "CONFIRMED",
                    tz: timezone,
                    sdtutc: dateInfo.dtstamp_str,
                    locationId: extractLocationIdFromScheduleinfo(
                        input.scheduleinfo
                    ),
                    latitude: locationData.latitude,
                    longitude: locationData.longitude
                };

                const newTb =
                    await createProviderScheduleBK(bookedtimeblockData);

                // Update booking
                const bookingInput = {
                    id: data.id,
                    startdate: dateInfo.dtstamp_str, // moment(selectedDate).format(),
                    bookingProviderId: data.provider.id,
                    providerId: data.provider.id,
                    timeblockid: newTb.id,
                    timezone,
                    TimeDisplayInfo: JSON.stringify(dateInfo)
                };

                if (
                    !data.isVirtual &&
                    locationData.locationType === "CL" &&
                    locationData.addressoneline
                ) {
                    bookingInput.location = locationData.addressoneline;
                }
                const result = await graphql(
                    graphqlOperation(mutations.updateBooking, {
                        input: bookingInput
                    })
                );

                await auditOverrideBooking(
                    user,
                    data,
                    result.data.updateBooking,
                    "OVERRIDE"
                );

                let old_booking_datetime = "";
                if (data.TimeDisplayInfo) {
                    let old_dateInfo = JSON.parse(data.TimeDisplayInfo);
                    old_booking_datetime = `${old_dateInfo.dt_full_disp}`;
                } else {
                    old_booking_datetime = moment(data.startdate).format(
                        "MMM DD, YYYY [at] h:mm a"
                    );
                }
                //SEnd Emails

                if (result && result.data) {
                    const dataObj = {
                        client_firstname: data.client.user.firstname,
                        client_lastname: data.client.user.lastname,
                        servicetype_name: data.servicetype.name,
                        old_booking_datetime,
                        changedto_booking_datetime: `${dateInfo.dt_long_disp}`,
                        changedto_booking_time: `${dateInfo.tm_st_disp}`,
                        changedto_provider_firstname: data.provider.firstname,
                        changedto_provider_lastname: data.provider.lastname,
                        changedto_booking_location: bookingInput.location
                            ? bookingInput.location
                            : data.location,
                        booking_service: data.serviceName,
                        company_email: data.company.emailaddress,
                        company_name: data.company.name,
                        isRegisteredUser: data.client.user.registered
                    };
                    let ccAddresses = [data.provider.emailaddress];
                    if (data.provider && data.provider.emailaddress) {
                        ccAddresses = ccAddresses.concat([
                            data.provider.emailaddress
                        ]);
                    }
                    const result = await postApi(
                        "sendtwilioemail",
                        "/sendtwilioemailtemplate",
                        {
                            body: {
                                templateName: "appt-change.html",
                                subject: `${data.client.user.firstname}, your upcoming appointment has changed.`,
                                body: null,
                                toAddresses: [data.client.user.emailaddress],
                                ccAddresses: ccAddresses,
                                replyTo: data.company.replyemailaddress
                                    ? data.company.replyemailaddress
                                    : data.company.emailaddress,
                                companyName: data.company.name,
                                companyId: data.company.id,
                                dataObj: dataObj,
                                companyLogoUrl: data.company.logoUrl,
                                companyColor: data.company.primaryColor,
                                authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL
                            }
                        }
                    );

                    console.log("Email Result", result);
                }
                //setDisableSubmit(false);
                setDisableCalendar(false);
                setShowCircular(false);
                setShowCloseBtn(true);
                setShowBackdrop(false);
                //close reschdeule modal
                props.refreshTable();
                props.closeRescheduleModal();
                props.openSnackbar(
                    "Your booking has been rescheduled for " + newDate
                );
            }
        } catch (err) {
            setShowCircular(false);
            setDisableSubmit(false);
            setDisableCalendar(false);
            setShowCloseBtn(true);
            setShowBackdrop(false);
            console.log("Error from submitReschedulingHandler", err);
        }
    };

    const formatDate = (d) => {
        let date = moment(d).format("ll");
        let day = moment(d).format("dddd");
        return day + ", " + date;
    };
    return (
        <>
            {showBackdrop && (
                <Backdrop
                    open={showBackdrop}
                    sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
            )}
            {/* Reschedule Modal */}
            <Dialog
                open={props.rescheduleModal}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={props.closeRescheduleModal}
            >
                <DialogContent sx={{ padding: "1.5rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            paddingLeft: "1.25rem"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, color: "rgba(0,0,0,0.8)" }}
                        >
                            Reschedule booking
                        </Typography>
                        {showCloseBtn && (
                            <CloseRounded
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    setError(false);
                                    props.closeRescheduleModal();
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{ marginBottom: "1rem", paddingLeft: "1.25rem" }}
                    >
                        <Typography sx={{ color: "rgba(0,0,0,0.5)" }}>
                            {props?.selectedRowData
                                ? props.selectedRowData.serviceName
                                : ""}
                        </Typography>
                    </div>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    "& .MuiPickerStaticWrapper-content": {
                                        "& div": {
                                            marginLeft: 0
                                        }
                                    },
                                    "& .PrivatePickersSlideTransition-root": {
                                        minHeight: "230px",
                                        marginBottom: 0,
                                        overflowY: "hidden"
                                    }
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={customLocale}
                                >
                                    <StaticDatePicker
                                        sx={{
                                            marginLeft: "0px"
                                        }}
                                        disabled={disableCalendar}
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        value={dateValue}
                                        onChange={async (newValue) => {
                                            let actualDateTime = newValue;
                                            if (new Date() !== newValue) {
                                                actualDateTime.setHours(8);
                                                actualDateTime.setMinutes(0);
                                                actualDateTime.setMilliseconds(
                                                    0
                                                );
                                            }
                                            setError(false);
                                            setDateValue(actualDateTime);
                                            setTimeValue(
                                                formatTimeToListValue(
                                                    actualDateTime
                                                )
                                            );

                                            let obj =
                                                await getBookingDateDescription(
                                                    new Date(newValue),
                                                    props.selectedRowData
                                                        .timezone,
                                                    30
                                                );
                                            setTimeZone(obj.tz_abbr_disp);
                                            if (disableSubmit) {
                                                setDisableSubmit(false);
                                            }
                                            checkIsDuringBlockedTime(
                                                actualDateTime
                                            );
                                            console.log(
                                                "DatePicker Value",
                                                newValue,
                                                actualDateTime
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        minDate={new Date()}
                                        PopperProps={{
                                            disablePortal: true,
                                            placement: "bottom-end"
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                paddingTop: "2rem",
                                paddingLeft: "4rem"
                            }}
                        >
                            <Typography
                                sx={{ fontWeight: 700, fontSize: "1rem" }}
                            >
                                {formatDate(dateValue)}
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: "2rem",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem",
                                    width: "175px"
                                }}
                            >
                                <div>
                                    <Typography
                                        sx={{
                                            fontSize: "0.875rem",
                                            color: "rgba(0,0,0,0.75)"
                                        }}
                                    >
                                        Select time
                                    </Typography>
                                </div>
                                <Select
                                    disabled={disableCalendar}
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 }
                                    }}
                                    value={timeValue}
                                    onChange={(e) => {
                                        if (error) {
                                            setError(false);
                                        }
                                        let t = new Date(dateValue);
                                        let actual_time =
                                            timeMapping[e.target.value];
                                        t.setHours(actual_time.split(":")[0]);
                                        t.setMinutes(actual_time.split(":")[1]);
                                        t.setMilliseconds(0);
                                        //make isBlocked call here
                                        checkIsDuringBlockedTime(t);
                                        setDateValue(t);
                                        setTimeValue(formatTimeToListValue(t));
                                        if (
                                            t.toLocaleString("en-US") ===
                                            origDate
                                        ) {
                                            console.log("it matched");
                                            setDisableSubmit(true);
                                        } else {
                                            console.log("it not matched");
                                            setDisableSubmit(false);
                                        }
                                    }}
                                >
                                    {Object.keys(timeMapping).map((item) => (
                                        <MenuItem
                                            key={timeMapping[item]}
                                            value={item}
                                        >
                                            {item + " " + timeZone}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Button
                                disabled={disableSubmit}
                                sx={{
                                    marginTop: "2rem",
                                    width: "83%",
                                    textTransform: "uppercase"
                                }}
                                variant="contained"
                                onClick={() => {
                                    submitReschedulingHandler(
                                        props.selectedRowData
                                    );
                                }}
                            >
                                {disableSubmit && showCircular ? (
                                    <CircularProgress size={"1rem"} />
                                ) : (
                                    "Reschedule"
                                )}
                            </Button>
                            {error && (
                                <Alert
                                    severity={errorType}
                                    sx={{
                                        padding: "0px 8px 0px 12px",
                                        marginTop: "14px",
                                        "& .MuiAlert-icon": {
                                            alignSelf: "center"
                                        }
                                    }}
                                    variant="outlined"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            fontSize: "12px"
                                        }}
                                    >
                                        {errorMessage}
                                    </Box>
                                </Alert>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ProviderRescheduleModal;
