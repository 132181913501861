import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import DrawerItems from "../menu/DrawerItems";
import MainNavBar from "./MainNavbar";
import { useStyles } from "../styles/MainContainerStyles";
import PageController from "../components/PageController";
import {
    getUserRole,
    getUserFromCache,
    userHasProviderRole
} from "../user/UserCommon";
import { useTheme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as queries from "../graphql/queries";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import { Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import { mbxUserAtom, dashboardInfoAtom } from "../atoms/atoms";
import {
    userHasMarketboxAdminRole,
    userHasCompanyAdminRole
} from "../user/UserCommon";
import { OUTLET_PAGE } from "../context/reducers";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import {
    conformationDialogOpenAtom,
    conformationDialogContentAtom,
    confirmationFunctionAtom,
    conformationDialogTitleAtom
} from "../atoms/atoms";
export default function MainContainer(props) {
    const { state } = useContext(StoreContext);
    const [role, setRole] = useState();
    const [loggedInUser, setLoggedInUser] = useState();
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    });
    const open = props.open;
    const handleDrawerAction = () => {
        props.handleDrawerAction();
    };
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useAtom(
        conformationDialogOpenAtom
    );
    const [confirmationFunction] = useAtom(confirmationFunctionAtom);
    const [conformationDialogContent] = useAtom(conformationDialogContentAtom);
    const [conformationDialogTitle] = useAtom(conformationDialogTitleAtom);
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);
    const [dashboardInfo, setDashboardInfo] = useAtom(dashboardInfoAtom);

    useEffect(() => {
        async function getUserAndRole() {
            const r = await getUserRole();
            const user = await getUserFromCache();
            setLoggedInUser(user);
            setRole(r);
        }
        getUserAndRole();
    }, []);
    // fecthes companyData every 1 hour
    useEffect(() => {
        const fetchCompanyData = async () => {
            // Fetch companyData
            const result = await graphql(
                graphqlOperation(queries.getCompany, { id: mbxUser.companyId })
            );

            // Update dashboardInfo for Dashboard Analytics
            setDashboardInfo(
                JSON.parse(result?.data?.getCompany?.DashboardInfo)
            );

            // Handle expired mbxUser
            if (
                result &&
                result?.data?.getCompany &&
                !result?.data?.getCompany?.active
            ) {
                setMbxUser({
                    ...mbxUser,
                    company: {
                        ...mbxUser.company,
                        active: false
                    }
                });
            }
        };

        fetchCompanyData();
        const intervalId = setInterval(fetchCompanyData, 3600000);

        // clean up
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MainNavBar
                handleSignout={null}
                handleDrawerAction={handleDrawerAction}
            />
            <Drawer
                variant={!isDesktop ? "persistent" : "permanent"}
                classes={{
                    paper: clsx(
                        userHasMarketboxAdminRole() ||
                            userHasCompanyAdminRole() ||
                            userHasProviderRole()
                            ? classes.drawerPaperAdmin
                            : classes.drawerPaper,
                        !open && classes.drawerPaperClose
                    )
                }}
                open={open}
            >
                <List
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "90%",
                        paddingTop: "0px"
                    }}
                >
                    <DrawerItems
                        handleDrawerAction={handleDrawerAction}
                        open={open}
                        subscriptionLevel={
                            loggedInUser?.company?.subscriptionLevel
                        }
                    />
                </List>
            </Drawer>
            <div className={classes.appBarSpacer} />
            <Container
                maxWidth={false}
                className={
                    open ? classes.containeropen : classes.containerclose
                }
                sx={{
                    maxWidth: open && role === "PROVIDER" ? "1111px" : "1440px"
                }}
            >
                {state.page === OUTLET_PAGE ? (
                    <Outlet />
                ) : (
                    <PageController newPage={state.page} />
                )}

                <Grid
                    container
                    item
                    alignItems="center"
                    flexDirection={"column"}
                >
                    <Typography>Powered by</Typography>
                    <img
                        onClick={() => {
                            window.open(
                                "https://www.gomarketbox.com/",
                                "_blank"
                            );
                        }}
                        style={{ marginRight: "-1rem", cursor: "pointer" }}
                        src="https://marketbox-prod-booknow.s3.amazonaws.com/public/marketbox/logo/MB-2.png"
                        height="13px"
                        width="100px"
                        alt="MarketBox"
                    />
                </Grid>
            </Container>
            <Dialog open={confirmationDialogOpen}>
                <DialogTitle>{conformationDialogTitle}</DialogTitle>
                <DialogContent>{conformationDialogContent}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={confirmationFunction.fn}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <main className={classes.content} />
        </div>
    );
}
