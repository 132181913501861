import React, { useState, useEffect } from "react";
import {
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel,
    InputAdornment,
    Button,
    Typography,
    Autocomplete,
    FormHelperText,
    Avatar
} from "@mui/material";
import { getUrl } from "aws-amplify/storage";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiPhoneNumber from "material-ui-phone-number";
import UploadPhotoModal from "../../components/UploadPhotoModal";
import { useForm, Controller } from "react-hook-form";
import moment from "moment-timezone";
import { useAtom } from "jotai";
import { selectedUserAtom, userPageSnackbarAtom } from "../../atoms/usersTable";
import {
    allActiveServicesAtom,
    allActiveSkillsAtom,
    allUsersAtom,
    mbxUserAtom,
    updateUserInAllUsersAtom,
    deleteUserInAllUsersAtom
} from "../../atoms/atoms";
import { delApi, postApi, putApi } from "../../modules/AmplifyServices";
import { isUniqueEmailAddress } from "../UserCommon";
import { EMAIL_REGEX } from "../../utils/Constants";
import SmallSizedModal from "../../utils/UI/SmallSizedModal";
import WarningTriangleIcon from "../../images/WarningTriangleIcon.svg";
import { Box } from "@mui/system";

const ProfileTab = ({ onClose }) => {
    const [mbxUser] = useAtom(mbxUserAtom);
    const [selectedUser] = useAtom(selectedUserAtom);
    const [allActiveServices] = useAtom(allActiveServicesAtom);
    const [allActiveSkills] = useAtom(allActiveSkillsAtom);
    const [allUsers, setAllUsers] = useAtom(allUsersAtom);
    const [, setUserPageSnackbar] = useAtom(userPageSnackbarAtom);
    const [, updateUserInAllUsers] = useAtom(updateUserInAllUsersAtom);
    const [, deleteUserInAllUsers] = useAtom(deleteUserInAllUsersAtom);
    const [openUploadPhotoDialog, setOpenUploadPhotoDialog] = useState(false);
    const [openEmailWarningModal, setOpenEmailWarningModal] = useState(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [s3Link, setS3Link] = useState("");
    const timezoneMenuItems = moment.tz.names();

    const {
        active = true,
        firstname: firstName = "",
        lastname: lastName = "",
        emailaddress: email = "",
        mobilephone: phone = "",
        role = "",
        provider = {},
        company: { travelTime: companyTravelTime = 0 } = {},
        contactconsent: sendDailyAgendaMail = false
    } = selectedUser || {};
    const {
        timezone = "",
        offersMobileServices = false,
        offersVirtualServices = false,
        vmlink = "",
        bio = "",
        locations: { items: locationsItems = [] } = {},
        maxTravelTime: providerMaxTravelTime = 0,
        servicetypes: { items: providerServices = [] } = {},
        skills: { items: providerSkills = [] } = {},
        pictures3key = null
    } = provider || {};
    const form = useForm({
        defaultValues: {
            active,
            firstName,
            lastName,
            email,
            phone,
            role,
            timezone,
            availableForInHome:
                offersMobileServices || locationsItems.length > 0,
            maxTravelTime: providerMaxTravelTime ?? companyTravelTime,
            availableForVirtual: offersVirtualServices,
            personalMeetingLink: vmlink?.replace("https://", ""),
            services: providerServices.map((item) => item.servicetype.id),
            skills: providerSkills.map((item) => item.skill.id),
            bio,
            sendDailyAgendaMail
        },
        mode: "onSubmit",
        reValidateMode: "onSubmit"
    });
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        setError,
        watch,
        control,
        formState
    } = form;
    const { errors, dirtyFields, isDirty } = formState;
    const availableForVirtual = watch("availableForVirtual");
    const availableForInHome = watch("availableForInHome");
    register("phone", { required: "Please enter your phone number" });

    useEffect(() => {
        const getS3Url = async () => {
            if (pictures3key) {
                const data = await getUrl({
                    key: pictures3key
                });
                setS3Link(data?.url);
            }
        };
        getS3Url();
    }, [pictures3key]);

    const handleOpenUploadPhotoDialog = () => {
        setOpenUploadPhotoDialog(true);
    };

    const handleCloseUploadPhotoDialog = () => {
        setOpenUploadPhotoDialog(false);
    };

    const handlePhoneChange = (value) => {
        setValue("phone", value, { shouldDirty: true });
        clearErrors("phone");
    };

    const handleValidateUniqueEmail = async (email) => {
        if (!dirtyFields.email) return true; //no need to validate email if it hasnt been changed
        try {
            const isUniqueEmail = await isUniqueEmailAddress(email);
            if (!isUniqueEmail) {
                setError("email", {
                    type: "focus",
                    message: "Email is already in use by another user"
                });
            }
            return isUniqueEmail;
        } catch (error) {
            console.log("Error while validating email", error);
        }
    };

    const handleCloseEmailWarningModal = async (validateEmail) => {
        if (validateEmail) {
            const isUniqueEmail = await handleValidateUniqueEmail(
                getValues("email")
            );
            if (isUniqueEmail) onSubmit(getValues(), true);
        }
        setOpenEmailWarningModal(false);
    };

    const handleCloseDeleteUserModal = async (deleteUser) => {
        if (deleteUser) {
            await handleDeleteUser();
        }
        setOpenDeleteUserModal(false);
    };

    const handleDeleteUser = async () => {
        setIsDeleting(true);
        let queryParams = {
            providerId: selectedUser.id,
            companyId: mbxUser.company.id
        };
        try {
            const { status = null, message = "" } = await delApi(
                "userManagementService",
                `/user/profile/${selectedUser.id}`,
                queryParams
            );

            if (status >= 200 && status < 300) {
                setUserPageSnackbar({
                    open: true,
                    message: "User has been successfully deleted",
                    severity: "success"
                });
                deleteUserInAllUsers(selectedUser.id);
                onClose();
            } else {
                setUserPageSnackbar({
                    open: true,
                    message: `Unexpected error while deleteing user ${status}`,
                    severity: "error"
                });
                throw new Error(
                    `Error deleting user profile. Error: ${message}`
                );
            }
        } catch (error) {
            console.log("Error deleting user:", error);
            setIsDeleting(false);
        }
    };

    const onSubmit = async (data, emailChangeAcknowledged) => {
        //Only open the email warning modal when the user is editing an existing user, the email field has been changed, and the email change warning has not been acknowledged
        if (
            selectedUser &&
            dirtyFields.email &&
            emailChangeAcknowledged !== true
        ) {
            setOpenEmailWarningModal(true);
            return;
        }
        setIsSubmitting(true);
        try {
            //append additional data for API use
            data = {
                ...data,
                companyId: mbxUser.companyId,
                useAnonymousPermalink: mbxUser.company.useAnonymousPermalink
            };
            if (!selectedUser) {
                //create route
                const {
                    status = null,
                    newUserRecord = null,
                    message = ""
                } = await postApi("userManagementService", "/user/profile", {
                    body: data
                });

                if (newUserRecord && status >= 200 && status < 300) {
                    onSuccess(
                        newUserRecord,
                        "User has been successfully created"
                    );
                } else {
                    onFailure(
                        status,
                        "Unexpected error: The user account was not created"
                    );
                    throw new Error(
                        `Failed to create new user profile. Error: ${message}`
                    );
                }
            } else {
                //edit route
                const {
                    status = null,
                    updatedUser = null,
                    message = ""
                } = await putApi(
                    "userManagementService",
                    `/user/profile/${selectedUser.id}`,
                    {
                        body: data
                    }
                );

                if (updatedUser && status >= 200 && status < 300) {
                    onSuccess(
                        updatedUser,
                        "User has been successfully updated"
                    );
                } else {
                    onFailure(
                        status,
                        "Unexpected error: The user account was not updated"
                    );
                    throw new Error(
                        `Failed to update user profile. Error: ${message}`
                    );
                }
            }
        } catch (error) {
            console.log("Error submitting form data:", error);
            setIsSubmitting(false);
        }
    };

    const onSuccess = async (userData, successMessage) => {
        if (!selectedUser) {
            //create route
            setAllUsers([
                {
                    LoginInfo: null,
                    active: userData.active,
                    company: mbxUser.company,
                    contactconsent: userData.contactconsent,
                    createdAt: userData.createdAt,
                    deleted: userData.deleted,
                    emailaddress: userData.emailaddress,
                    firstname: userData.firstname,
                    homephone: userData.lastname,
                    id: userData.id,
                    lastname: userData.lastname,
                    mobilephone: userData.mobilephone,
                    phonepref: userData.phonepref,
                    provider: userData.provider,
                    providerId: userData.providerId,
                    registered: userData.registered,
                    role: userData.role,
                    schedules: [],
                    workphone: null
                },
                ...allUsers
            ]);
        } else {
            //edit route
            updateUserInAllUsers(userData);
        }
        setUserPageSnackbar({
            open: true,
            message: successMessage,
            severity: "success"
        });
        setIsSubmitting(false);
        onClose();
    };

    const onFailure = (status, errorMessage) => {
        setUserPageSnackbar({
            open: true,
            message: `${errorMessage} ${status ? `(${status})` : ""}`,
            severity: "error"
        });
    };

    return (
        <>
            <EmailChangeWarningModal
                open={openEmailWarningModal}
                onClose={handleCloseEmailWarningModal}
            />
            <DeleteUserWarningModal
                open={openDeleteUserModal}
                onClose={handleCloseDeleteUserModal}
                isDeleting={isDeleting}
            />
            <UploadPhotoModal
                open={openUploadPhotoDialog}
                onClose={handleCloseUploadPhotoDialog}
                setS3Link={setS3Link}
            />
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <DialogContent>
                    <Grid container spacing={2.25} sx={{ marginTop: "0px" }}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="active"
                                control={control}
                                render={({
                                    field: { onChange: onChangeFormHook, value }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id="active"
                                                name="active"
                                                disabled={isSubmitting}
                                                checked={value}
                                                value={value}
                                                onChange={onChangeFormHook}
                                            />
                                        }
                                        label="Active"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="firstname"
                                name="firstName"
                                type="text"
                                size="small"
                                label="First name"
                                disabled={isSubmitting}
                                fullWidth
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "58px"
                                        }
                                    }
                                }}
                                {...register("firstName", {
                                    required: "Please enter your first name",
                                    onChange: () => clearErrors("firstName")
                                })}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="lastname"
                                name="lastName"
                                type="text"
                                size="small"
                                label="Last name"
                                disabled={isSubmitting}
                                fullWidth
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "58px"
                                        }
                                    }
                                }}
                                {...register("lastName", {
                                    required: "Please enter your last name",
                                    onChange: () => clearErrors("lastName")
                                })}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                name="email"
                                type="email"
                                size="small"
                                label="Email"
                                disabled={isSubmitting}
                                fullWidth
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "36px"
                                        }
                                    }
                                }}
                                {...register("email", {
                                    required: "Please enter your email",
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: "Invalid email format"
                                    },
                                    validate: {
                                        validateUniqueEmail: async (
                                            fieldValue
                                        ) =>
                                            selectedUser && dirtyFields.email
                                                ? true //true is only passed here to bypass form validation, the EmailChangeWarningModal will be triggered for email validation in this scenerio
                                                : (await handleValidateUniqueEmail(
                                                      fieldValue
                                                  )) ||
                                                  "Email is already in use by another user"
                                    },
                                    onChange: () => clearErrors("email")
                                })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiPhoneNumber
                                id="phone"
                                variant="outlined"
                                label="Phone"
                                disabled={isSubmitting}
                                sx={{
                                    display: "flex",
                                    "& fieldset": {
                                        "& legend": {
                                            width: "39px"
                                        }
                                    }
                                }}
                                size="small"
                                fullWidth
                                defaultCountry={"ca"}
                                value={getValues("phone")}
                                onChange={handlePhoneChange}
                                error={!!errors.phone && !getValues("phone")}
                                helperText={
                                    !getValues("phone") && errors.phone?.message
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!errors.role}>
                                <InputLabel id="role-label" size="small">
                                    Role
                                </InputLabel>
                                <Controller
                                    name="role"
                                    control={control}
                                    rules={{ required: "Please select a role" }}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value
                                        }
                                    }) => (
                                        <Select
                                            id="role"
                                            name="role"
                                            labelId="role-label"
                                            label="Role"
                                            disabled={isSubmitting}
                                            sx={{
                                                "& fieldset": {
                                                    "& legend": {
                                                        width: "30px"
                                                    }
                                                }
                                            }}
                                            value={value}
                                            onChange={(value) => {
                                                onChangeFormHook(value);
                                                clearErrors("role");
                                            }}
                                        >
                                            <MenuItem value={"PROVIDER"}>
                                                Provider
                                            </MenuItem>
                                            <MenuItem
                                                value={"COMPANY_ADMIN_PROVIDER"}
                                            >
                                                Admin
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                                {!!errors.role && (
                                    <FormHelperText>
                                        {errors?.role.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!errors.timezone}>
                                <Controller
                                    name="timezone"
                                    control={control}
                                    rules={{
                                        required: "Please select a timezone"
                                    }}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value
                                        }
                                    }) => (
                                        <Autocomplete
                                            id="timezone"
                                            name="timezone"
                                            size="small"
                                            options={timezoneMenuItems}
                                            disabled={isSubmitting}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Timezone"
                                                    error={!!errors.timezone}
                                                />
                                            )}
                                            value={value}
                                            onChange={(e) => {
                                                onChangeFormHook(
                                                    e.target.textContent
                                                );
                                                clearErrors("timezone");
                                            }}
                                        />
                                    )}
                                />
                                {!!errors.timezone && (
                                    <FormHelperText>
                                        {errors?.timezone.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="availableForInHome"
                                control={control}
                                render={({
                                    field: { onChange: onChangeFormHook, value }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id="availableForInHome"
                                                name="availableForInHome"
                                                checked={value}
                                                disabled={isSubmitting}
                                                value={value}
                                                onChange={onChangeFormHook}
                                            />
                                        }
                                        label="Available for in-home appointments"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                error={!!errors.maxTravelTime}
                            >
                                <InputLabel id="traveltime-label" size="small">
                                    Max travel time between bookings (mins)
                                </InputLabel>
                                <Controller
                                    name="maxTravelTime"
                                    control={control}
                                    rules={{
                                        required: availableForInHome
                                            ? "Please select a max travel time"
                                            : false
                                    }}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value
                                        }
                                    }) => (
                                        <Select
                                            labelId="traveltime-label"
                                            id="traveltime"
                                            name="maxTravelTime"
                                            label="Max travel time between bookings (mins)"
                                            disabled={isSubmitting}
                                            sx={{
                                                "& fieldset": {
                                                    "& legend": {
                                                        width: "203px"
                                                    }
                                                }
                                            }}
                                            value={value}
                                            onChange={onChangeFormHook}
                                        >
                                            <MenuItem value={0}>0</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={45}>45</MenuItem>
                                            <MenuItem value={60}>60</MenuItem>
                                        </Select>
                                    )}
                                />
                                {!!errors.maxTravelTime && (
                                    <FormHelperText>
                                        {errors?.maxTravelTime.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="availableForVirtual"
                                control={control}
                                render={({
                                    field: { onChange: onChangeFormHook, value }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id="availableForVirtual"
                                                name="availableForVirtual"
                                                disabled={isSubmitting}
                                                checked={value}
                                                value={value}
                                                onChange={onChangeFormHook}
                                            />
                                        }
                                        label="Available for virtual appointments"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="url"
                                name="personalMeetingLink"
                                size="small"
                                label="Personal meeting link"
                                disabled={isSubmitting}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            https://
                                        </InputAdornment>
                                    )
                                }}
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "109px"
                                        }
                                    }
                                }}
                                {...register("personalMeetingLink", {
                                    required: availableForVirtual
                                        ? "Please enter your personal meeting link"
                                        : false
                                })}
                                error={!!errors.personalMeetingLink}
                                helperText={errors.personalMeetingLink?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth error={!!errors.services}>
                                <InputLabel id="services-label" size="small">
                                    Services
                                </InputLabel>
                                <Controller
                                    name="services"
                                    control={control}
                                    rules={{
                                        required:
                                            "Please select atleast one service"
                                    }}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value
                                        }
                                    }) => (
                                        <Select
                                            labelId="services-label"
                                            id="services"
                                            name="services"
                                            label="Services"
                                            disabled={isSubmitting}
                                            multiple
                                            sx={{
                                                "& fieldset": {
                                                    "& legend": {
                                                        width: "49px"
                                                    }
                                                }
                                            }}
                                            value={value}
                                            onChange={(value) => {
                                                onChangeFormHook(value);
                                                clearErrors("services");
                                            }}
                                        >
                                            {allActiveServices.map(
                                                (service) => (
                                                    <MenuItem
                                                        value={service.id}
                                                    >
                                                        {service.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    )}
                                />
                                {!!errors.services && (
                                    <FormHelperText>
                                        {errors?.services.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth error={!!errors.skills}>
                                <InputLabel id="skills-label" size="small">
                                    Skills
                                </InputLabel>
                                <Controller
                                    name="skills"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange: onChangeFormHook,
                                            value
                                        }
                                    }) => (
                                        <Select
                                            labelId="skills-label"
                                            id="skills"
                                            name="skills"
                                            label="Skills"
                                            disabled={isSubmitting}
                                            multiple
                                            sx={{
                                                "& fieldset": {
                                                    "& legend": {
                                                        width: "33px"
                                                    }
                                                }
                                            }}
                                            value={value}
                                            onChange={onChangeFormHook}
                                        >
                                            {allActiveSkills.map((skill) => (
                                                <MenuItem value={skill.id}>
                                                    {skill.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {!!errors.skills && (
                                    <FormHelperText>
                                        {errors?.skills.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="bio"
                                name="bio"
                                label="Bio"
                                size="small"
                                disabled={isSubmitting}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows={3}
                                sx={{
                                    "& fieldset": {
                                        "& legend": {
                                            width: "23px"
                                        }
                                    }
                                }}
                                {...register("bio")}
                                error={!!errors.bio}
                                helperText={errors.bio?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="sendDailyAgendaMail"
                                control={control}
                                render={({
                                    field: { onChange: onChangeFormHook, value }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id="sendDailyAgendaMail"
                                                name="sendDailyAgendaMail"
                                                disabled={isSubmitting}
                                                checked={value}
                                                value={value}
                                                onChange={onChangeFormHook}
                                            />
                                        }
                                        label="Send me daily agenda mail"
                                    />
                                )}
                            />
                        </Grid>
                        {selectedUser && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                sx={{ paddingTop: "36px", paddingLeft: "18px" }}
                            >
                                <Button variant="text">
                                    <Typography
                                        variant="button"
                                        sx={{
                                            textTransform: "none",
                                            lineHeight: "16px"
                                        }}
                                    >
                                        {`Reset password`}
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                        {selectedUser && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                sx={{ paddingTop: "36px", paddingLeft: "18px" }}
                            >
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => setOpenDeleteUserModal(true)}
                                >
                                    <Typography
                                        variant="button"
                                        sx={{
                                            textTransform: "none",
                                            lineHeight: "16px"
                                        }}
                                    >
                                        {`Delete user`}
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Grid container sx={{ gap: "18px" }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleOpenUploadPhotoDialog}
                        >
                            Upload Photo
                        </Button>
                        {s3Link && (
                            <Avatar
                                variant="rounded"
                                src={s3Link}
                                sx={{
                                    width: 36,
                                    height: 36,
                                    borderRadius: "50%"
                                }}
                                alt="User"
                            />
                        )}
                    </Grid>

                    <Grid display={"flex"} gap={"18px"}>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            type="submit"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </DialogActions>
            </form>
        </>
    );
};

const EmailChangeWarningModal = ({ open, onClose }) => {
    return (
        <SmallSizedModal
            open={open}
            onClose={() => {
                onClose(false);
            }}
            headingIcon={WarningTriangleIcon}
            headingText={"Change email"}
        >
            <Box>
                <Typography variant="body2">
                    Changing this users email address will update their unique
                    identifier in the system and will affect all scheduled email
                    notifications to this person. Are you sure you wish to
                    continue?
                </Typography>
            </Box>
            <Box
                mt={2}
                gap={"1.25rem"}
                display={"flex"}
                justifyContent={"flex-end"}
            >
                <Button
                    variant="outlined"
                    onClick={() => {
                        onClose(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onClose(true);
                    }}
                >
                    Continue
                </Button>
            </Box>
        </SmallSizedModal>
    );
};

const DeleteUserWarningModal = ({ open, onClose, isDeleting }) => {
    return (
        <SmallSizedModal
            open={open}
            onClose={() => {
                onClose(false);
            }}
            headingText={"Delete user"}
        >
            <Box>
                <Typography variant="body2" marginBottom="16px">
                    Are you sure you wish to delete this user?
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                    This cannot be undone
                </Typography>
            </Box>
            <Box
                mt={2}
                gap={"1.25rem"}
                display={"flex"}
                justifyContent={"flex-end"}
            >
                <Button
                    variant="outlined"
                    onClick={() => {
                        onClose(false);
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    loading={isDeleting}
                    onClick={() => {
                        onClose(true);
                    }}
                >
                    Delete
                </LoadingButton>
            </Box>
        </SmallSizedModal>
    );
};

export default ProfileTab;
