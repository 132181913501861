import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    counterContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "16px 0px 16px 16px",
        padding: "6px 0px",
        border: "1px solid rgb(0,0,0,0.23)",
        borderRadius: "4px",
        height: "46px",
        width: "166px",
        [theme.breakpoints.down("sm")]: {
            width: "155px"
        },
        maxWidth: "166px"
    },
    countContainer: {
        width: "100%",
        minWidth: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    countText: {
        fontSize: "20px",
        padding: theme.spacing(0, 2),
        color: "rgb(0,0,0,0.87)",
        fontFamily: "Poppins, sans-serif",
        width: "63.53px",
        display: "flex",
        justifyContent: "center"
    },
    inputField: {
        width: "63.53px",
        fontSize: "20px",
        textAlign: "center",
        color: "rgb(0,0,0,0.87)",
        padding: theme.spacing(0, 2),
        border: "none",
        fontFamily: "Poppins, sans-serif",
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        "&::-moz-appearance": "textfield"
    },
    button: {
        padding: "2px 18px",
        minWidth: "12px",
        fontSize: "22px",
        color: "rgb(0,0,0,0.67)",
        fontFamily: "Poppins, sans-serif"
    }
}));

const UsersCounter = (props) => {
    const classes = useStyles();
    const [count, setCount] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleIncrement = () => {
        const newCount = count + 1;
        setCount(newCount);
        console.log(`Count incremented: ${newCount}`);
        props.onQuantityUpdate(newCount);
    };

    const handleDecrement = () => {
        if (count > 1) {
            const newCount = count - 1;
            setCount(newCount);
            console.log(`Count decremented: ${newCount}`);
            props.onQuantityUpdate(newCount);
        }
    };

    const handleCountClick = () => {
        setIsEditing(true);
        setInputValue(count.toString());
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputBlur = () => {
        setIsEditing(false);
        const newCount = parseInt(inputValue);
        if (!isNaN(newCount) && newCount !== count) {
            setCount(newCount);
            console.log(`Count updated: ${newCount}`);
            props.onQuantityUpdate(newCount);
        }
    };

    return (
        <Box className={classes.counterContainer}>
            <Button
                variant="text"
                className={classes.button}
                onClick={handleDecrement}
                disabled={count === 1}
            >
                -
            </Button>
            <Box className={classes.countContainer}>
                {isEditing ? (
                    <input
                        type="number"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        autoFocus
                        className={classes.inputField}
                        inputMode="numeric"
                        min="0"
                        max="100"
                    />
                ) : (
                    <span
                        className={classes.countText}
                        onClick={handleCountClick}
                    >
                        {count}
                    </span>
                )}
            </Box>
            <Button
                variant="text"
                className={classes.button}
                onClick={handleIncrement}
                disabled={count === 100}
            >
                +
            </Button>
        </Box>
    );
};

export default UsersCounter;
