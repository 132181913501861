import { types, OUTLET_PAGE } from "./reducers";
import { createBrowserHistory } from "history";
let history = createBrowserHistory();

export const useActions = (state, dispatch) => {
    function addTechIfNotInList(newTech) {
        const techIndex = state.techList.indexOf(newTech);
        if (techIndex !== -1) {
            alert("Tech is defined in list");
        } else {
            dispatch({ type: types.ADD_TO_TECH_LIST, payload: newTech });
        }
    }

    function setPage(newPage) {
        console.log("newPage = " + newPage);
        console.log("state.pageList = " + state.pageList);
        const pageIndex = state.pageList.indexOf(newPage);
        newPage !== OUTLET_PAGE && history.replace("/", { newPage });
        if (pageIndex === -1) {
            alert(
                "Page is not defined in action.js. pageIndex = " +
                    pageIndex +
                    " and newPage = " +
                    newPage +
                    " and pageList =  " +
                    state.pageList
            );
        } else {
            dispatch({ type: types.SET_PAGE, payload: newPage });
        }
    }

    function setMode(newMode) {
        console.log("newMode = " + newMode);
        const modeIndex = state.modes.indexOf(newMode);
        if (modeIndex === -1) {
            alert(
                "Mode is not defined in action.js. modeIndex = " +
                    modeIndex +
                    " and newMode = " +
                    newMode +
                    " and modes =  " +
                    state.modes
            );
        } else {
            dispatch({ type: types.SET_MODE, payload: newMode });
        }
    }

    function setId(newId) {
        console.log("*** newId = " + newId);
        dispatch({ type: types.SET_ID, payload: newId });
    }

    function setClientForPackageDialog(client) {
        dispatch({
            type: types.SET_CLIENT_FOR_PACKAGE_DIALOG,
            payload: client
        });
    }

    function setBookingRequest(br) {
        dispatch({ type: types.SET_BR, payload: br });
    }
    function setBookingsTab(tab) {
        dispatch({ type: types.SET_BOOKINGS_TAB, payload: tab });
    }

    // this stores the logged in user for global access
    // ex. user.company.id
    function setUser(user) {
        console.log("*** user = " + user);
        dispatch({ type: types.SET_USER, payload: user });
    }

    function setProvider(provider) {
        console.log("*** provider = ", provider);
        dispatch({ type: types.SET_PROVIDER, payload: provider });
    }

    function setProviderScheduleViewType(viewType) {
        dispatch({
            type: types.SET_PROVIDER_SCHEDULE_VIEW_TYPE,
            payload: viewType
        });
    }
    // this stores the booking state selected in the booking flow
    function setBookingState(bookingState) {
        console.log("bookingState = " + bookingState);
        dispatch({ type: types.SET_BOOKING_STATE, payload: bookingState });
    }

    function setContextCompanyId(companyId) {
        console.log("*** contextCompanyId = " + companyId);
        dispatch({ type: types.SET_CONTEXT_COMPANY, payload: companyId });
    }

    return {
        addTechIfNotInList,
        setPage,
        setMode,
        setId,
        setUser,
        setBookingState,
        setContextCompanyId,
        setProvider,
        setBookingRequest,
        setBookingsTab,
        setProviderScheduleViewType,
        setClientForPackageDialog
    };
};
