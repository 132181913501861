import React, { useEffect, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import {
    Box,
    Button,
    Grid,
    FormControl,
    Typography,
    Snackbar,
    Select,
    MenuItem,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    CircularProgress,
    FormHelperText,
    Checkbox,
    Divider,
    IconButton,
    useMediaQuery
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import { TimeBlockDisplay } from "../components/TimeBlockDisplay";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../styles/MainNavbarStyles";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import { FormHeading } from "../utils/CommonComonents/FormHeading";
import {
    createSchedule,
    updateSchedule,
    activateSchedule,
    deactivateSchedule,
    createTimeblock,
    updateTimeblock,
    getProviderDataForSchedules,
    getScheduleData,
    SCHEDPKSKSPLITAT,
    getTimeblocksbyScheduleIdNoCache,
    checkServiceHasExistingSchedule,
    awsDateToJsDate
} from "../modules/ScheduleService";
import moment from "moment";
import { getUserFromCache } from "../user/UserCommon";
import BusinessIcon from "@mui/icons-material/Business";
import WifiIcon from "@mui/icons-material/Wifi";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
    auditProviderSchedCreate,
    auditProviderSchedDelete,
    auditProviderSchedActivate,
    auditProviderSchedAddtb,
    auditProviderSchedEdittb
} from "../modules/Audit";
import { execReadBySortkey } from "../modules/DBService";
import * as scheduleQueries from "../modules/ScheduleQueries";
import { usePromotionsStyles } from "../styles/PromotionsFormStyles";
import TravelZoneModal from "./TravelZoneModal";
import { useNavigate } from "react-router-dom";
import {
    Add,
    ArrowDropDown,
    CancelRounded,
    CloseRounded
} from "@mui/icons-material";
import { useWindowSize } from "react-use";
import { manageFilteredServicesByLocation } from "../modules/FilterServicesAndLocations";

const defaultDate = new Date();
const useBackdropStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const capSentence = (str) => {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

const daysStyleFunction = (str, val) => {
    if (str && str.includes(val))
        return {
            color: str.includes(val) ? "#0087ee" : "rgba(0,0,0,0.75)",
            fontWeight: 700,
            fontSize: "14px",
            borderColor: str.includes(val) ? "#0087ee" : "#5A5A5A",
            borderStyle: "solid",
            borderWidth: "2px",
            padding: "6px 10px",
            width: "32px",
            height: "32px",
            backgroundColor: str.includes(val) ? "#61b3f2" : "#f4f4f4"
        };

    return {
        color: "rgba(0,0,0,0.75)",
        fontWeight: 700,
        fontSize: "14px",
        borderColor: "#5A5A5A",
        borderStyle: "solid",
        borderWidth: "2px",
        padding: "6px 10px",
        width: "32px",
        height: "32px",
        backgroundColor: "#f4f4f4"
    };
};

const dateAndTimeFormatting = (val, type) => {
    let date = moment(val, "");
    if (type === "time") {
        return moment(val).format("LT");
    }

    return moment(val).format("ll");
};

const TableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};
const ProviderScheduleAddEditForm = (props) => {
    let { width } = useWindowSize(); //grabs the width of the users screen
    const classes1 = useBackdropStyles();
    const { state, dispatch, actions } = React.useContext(StoreContext);
    // snackbar
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();
    //fields

    const [locations, setLocations] = useState();
    const [locnames, setLocnames] = useState();
    const [active, setActive] = React.useState(true);
    const [providerServices, setProviderServices] = React.useState([]);
    const [providerLocations, setProviderLocations] = useState([]);
    const [selectedServices, setSelectedServices] = React.useState([]);
    const [originalServices, setOriginalServices] = React.useState([]);
    const [oldInfo, setOldInfo] = useState([]);
    const [company, setCompany] = React.useState(getUserFromCache()?.company);
    const [showServicesError, setShowServicesError] = useState(false);
    const [missingServices, setMissingServices] = useState();
    const classes = useStyles();
    //type
    const [locationId, setLocationId] = useState("AddTravelZone");
    const [saveclicked, setSaveclicked] = useState(false);
    const inputLabel = React.useRef(null);
    const loggedInUser = getUserFromCache();
    const ids = state.id.split(",");
    const editType = ids[0];
    const schCompositeId = ids[1];

    const [deactivateSchDialog, setDeactivateSchDialog] = useState(false);
    const [activateSchDialog, setActivateSchDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const classesnew = usePromotionsStyles();
    const [newAvailObj, setNewAvailObj] = useState();
    const [avaTbs, setAvaTbs] = useState([]);
    const [newAvail, setNewAvail] = useState(false);
    const [servicesUpdated, setServicesUpdated] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [locationAddress, setLocationAddress] = useState("");
    const [virtualLocation, setVirtualLocation] = useState(false);
    const [displayTravelZoneForm, setDisplayTravelZoneForm] = useState(false);
    const [showTimeBlockDisplay, setShowTimeBlockDisplay] = useState(false);
    const [showTimeBlockDisplayAdd, setShowTimeBlockDisplayAdd] =
        useState(false);

    const [selectedAvail, setSelectedAvail] = useState({});
    const [initialAvail, setInitialAvail] = useState(true);
    const history = useNavigate();

    const [openMenu, setOpenMenu] = useState(false);

    //filtered services & locations state
    const [filteredServices, setFilteredServices] = useState([]);

    let week;
    let locname;

    const smallerScreen = useMediaQuery("(max-width:500px)");

    const handleOpenSelect = () => {
        setOpenMenu(true);
    };

    const handleCloseSelect = () => {
        setOpenMenu(false);
    };

    const closeTravelZonePortal = () => {
        setDisplayTravelZoneForm(false);
    };
    const filterAndSelectAvail = (id) => {
        if (id) {
            let arr = avaTbs.filter((item) => item.id === id);
            setSelectedAvail((prevState) => arr[0]);
            setShowTimeBlockDisplay(true);
            return;
        }
    };

    const fromDashboard = () => {
        if (props.path === "DashboardForm") {
            return true;
        }
        return false;
    };
    const removeAndReplaceAvail = (arr) => {
        let arr_replaced = [...arr];
        if (!arr[0].id) {
            arr_replaced.shift();
        }
        return arr_replaced;
    };

    const handleLocationChange = ({ target: { value } }) => {
        setLocationId(value);
    };

    const updateTravelZoneValue = (id) => {
        setLocationId(plid(id));
    };

    const _fetchFormData = async () => {
        const locationData = await graphql(
            graphqlOperation(queries.companyLocationByCompany, {
                companyId: loggedInUser.companyId,
                filter: { deleted: { ne: true } }
            })
        );
        if (locationData?.data?.companyLocationByCompany?.items) {
            setLocations(locationData.data.companyLocationByCompany.items);
            if (
                companyHasOnPremiseServices() &&
                locationData?.data?.companyLocationByCompany?.items?.length > 0
            ) {
                setLocationId(
                    clid(locationData.data.companyLocationByCompany.items[0].id)
                );
            }
        }

        let pd = await getProviderDataForSchedules(
            fromDashboard()
                ? loggedInUser && loggedInUser.providerId
                    ? loggedInUser.providerId
                    : state.provider.id
                : state.provider.id
        );
        if (pd && pd.servicetypes) {
            const psts = pd.servicetypes.map((st) => {
                return {
                    id: st.servicetype.id,
                    name: st.servicetype.name,
                    locations: st.servicetype.locations
                };
            });

            setProviderServices(psts);
            if (psts.length > 0) {
                setSelectedServices((prevState) => [psts[0].id]);
            }
        }
        if (pd && pd.locations) {
            setProviderLocations(pd.locations);
            if (companyHasRemoteServices() && pd?.locations?.length > 0) {
                setLocationId(plid(pd.locations[0].id));
            }
        }

        if (
            state.mode === "Edit" &&
            state.provider &&
            Object.keys(state.provider).length > 0 &&
            !fromDashboard()
        ) {
            let scheduleData = await getScheduleData(schCompositeId);

            if (!!scheduleData) {
                setLocationName(scheduleData.location?.locationname);
                if (scheduleData.location?.addressoneline) {
                    setLocationAddress(scheduleData.location?.addressoneline);
                    setVirtualLocation(scheduleData.location?.virtual);
                }
                setLocationId(
                    scheduleData.scheduleinfo.includes("|PL-")
                        ? "PL-" + scheduleData.locationId
                        : "CL-" + scheduleData.locationId
                );
                setSelectedServices(
                    scheduleData.selectedServices.map((s) => s.servicetypeId)
                );
                setOriginalServices(scheduleData.selectedServices);
                setActive(scheduleData.active);
                // setInternal(scheduleData.internal);
                oldInfo.push({
                    services: scheduleData.selectedServices.map(
                        (s) => s.servicetypeId
                    ),
                    otherData: scheduleData
                });
                let locAddTb =
                    locationData.data.companyLocationByCompany.items.filter(
                        (item) => {
                            return item.id === scheduleData.locationId;
                        }
                    );
                if (locAddTb.length == 0) {
                    locAddTb = pd.locations.filter((item) => {
                        return item.id === scheduleData.locationId;
                    });
                }
                setLocnames(locAddTb);
            }
        }
        setLoading(false);
    };

    function prepareScheduleInput() {
        let providerId = fromDashboard()
            ? loggedInUser && loggedInUser.providerId
                ? loggedInUser.providerId
                : state.provider.id
            : state.provider.id;
        return {
            user: loggedInUser.id,
            providerId: providerId,
            locationId: locationId.slice(3),
            locationType: getLocationType(locationId),
            companyId: loggedInUser.companyId,
            //servicetypeId: servicetypeid,
            services: selectedServices,
            active,
            //  internal,
            slotStartStep: 15 //Default 15, unused field
            // instructions,
            // postBookingInstructions
        };
    }

    function isEqual(array1, array2) {
        if (array1.length != array2.length) {
            return false;
        } else {
            array1.sort();
            array2.sort();
            for (var i = 0; i < array1.length; i++) {
                if (array1[i] != array2[i]) return false;
            }
            return true;
        }
    }

    let newInfo;
    function scheduleInputValidation() {
        newInfo = [
            {
                services: "No Change"
            }
        ];
        if (selectedServices.length === 0) {
            setSnackMsg("Please select at least one service");
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        } else if (!locationId || locationId === "AddTravelZone") {
            setSnackMsg(
                locationId === "AddTravelZone"
                    ? "Please add a travel zone"
                    : "Please select a location"
            );
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        }

        if (!addScMode()) {
            if (!isEqual(oldInfo[0].services, selectedServices)) {
                if (selectedServices.length > oldInfo[0].services.length) {
                    let newServices =
                        selectedServices.length - oldInfo[0].services.length;
                    newInfo.map((item) => {
                        item.services = `${newServices} service(s) have been added, please see provider schedule for info`;
                    });
                } else if (
                    selectedServices.length < oldInfo[0].services.length
                ) {
                    let newServices =
                        oldInfo[0].services.length - selectedServices.length;
                    newInfo.map((item) => {
                        item.services = `${newServices} service(s) have been removed, please see provider schedule for info`;
                    });
                } else {
                    newInfo.map((item) => {
                        item.services = `at least one service has been changed, please see provider schedule for info`;
                    });
                }
            }
        }
        return true;
    }

    function tbInputValidation(tb) {
        let { startDate, endDate } = tb.dateValues;
        let { startTime, endTime } = tb.timeValues;
        const startMin = startTime.getMinutes();
        const endMin = endTime.getMinutes();
        if (startDate > endDate) {
            setSnackMsg(
                "End date should be greater than or equal to the start date ."
            );
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        } else if (!startDate && !endDate) {
            setSnackMsg("Please select start date and end date.");
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        } else if (!startTime && !endTime) {
            setSnackMsg("Please enter start time and end time.");
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        } else if (startMin % 5 != 0) {
            setSnackMsg(
                "Please enter a start time that appears in 5 minute intervals. Ex: 10:05, 10:10 etc."
            );
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        } else if (endMin % 5 != 0) {
            setSnackMsg(
                "Please enter an end time that appears in 5 minute intervals Ex: 10:05, 10:10 etc."
            );
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        }

        newInfo = [
            {
                startDate: startDate,
                startTime: startTime,
                endTime: endTime,
                locname: locnames
            }
        ];
        return true;
    }

    async function validateScheduleServices() {
        //only check for newly included services
        let checkTheseServices = [];
        let providerId = fromDashboard()
            ? loggedInUser && loggedInUser.providerId
                ? loggedInUser.providerId
                : state.provider.id
            : state.provider.id;
        for (let ssrid of selectedServices) {
            let found = false;
            for (let osr of originalServices) {
                if (osr.servicetypeId === ssrid) {
                    found = true;
                    break;
                }
            }
            if (!found) checkTheseServices.push(ssrid);
        }
        const result = await checkServiceHasExistingSchedule(
            checkTheseServices,
            loggedInUser.companyId,
            providerId,
            locationId,
            schCompositeId
        );
        if (result && result.length > 0) {
            let srNames = [];
            let srIds = [];
            for (let srid of result) {
                for (let prsr of providerServices) {
                    if (prsr.id === srid) {
                        srNames.push(prsr.name);
                        srIds.push(srid);
                    }
                }
            }
            let afterremovingErroredServices = selectedServices?.filter(
                (srid) => {
                    let found = false;
                    for (let fssrid of srIds) {
                        if (fssrid === srid) {
                            found = true;
                            break;
                        }
                    }
                    return !found;
                }
            );

            setSelectedServices(afterremovingErroredServices);
            setSnackMsg(
                `These services have one other or more already existing schedule(s): ${srNames.join(
                    ","
                )}.`
            );
            setMsgOpen(true);
            setSaveclicked(false);
            return false;
        } else return true;
    }

    const _handleSaveSchedule = async (e) => {
        // only for new schedule
        const loggedInUser = getUserFromCache();
        setSaveclicked(true);
        setShowLoading(true);
        if (
            (state.mode === "AddSc" ||
                !state.mode === "AddSc" ||
                fromDashboard()) &&
            scheduleInputValidation() &&
            tbInputValidation(avaTbs[0])
        ) {
            const serviceValidation = await validateScheduleServices();
            if (!serviceValidation) {
                setSaveclicked(false);
                setShowLoading(false);
                return;
            }
            const input = prepareScheduleInput();
            let res = await createSchedule(input);
            if (res && fromDashboard()) {
                props.doneTodoHandler("availability");
            }
            locname = locations.filter((item) => {
                return item.id === res.locationId;
            });
            if (locname.length === 0)
                locname = providerLocations.filter((item) => {
                    return item.id === res.locationId;
                });
            await auditProviderSchedCreate(loggedInUser, res, locname);

            if (!!res && res["error"]) {
                setSnackMsg("Could not save schedule information.");
                setMsgOpen(true);
                setSaveclicked(false);
                setShowLoading(true);
                return;
            }
            if (!!res && !res["error"]) {
                res = await _handleCreateTimeBlock(res.id, avaTbs[0]);
                if (!!res && res["error"]) {
                    setSnackMsg("Could not save availability information.");
                    setMsgOpen(true);
                    setSaveclicked(false);
                    setShowLoading(true);
                    return;
                }
            }
            if (fromDashboard()) {
                props.saveAndNextHandler("customizations");
                props.closeAvailabilityPortal();
            } else {
                returnToManageAvailability();
            }
        }
        setSaveclicked(false);
        setShowLoading(false);
    };

    const _handleCreateTimeBlock = async (schId, tb) => {
        const { startDate, endDate } = tb.dateValues;
        const { startTime, endTime } = tb.timeValues;
        if (tb.checkAllstate || tb?.checkstate === "") {
            week = [0, 1, 2, 3, 4, 5, 6];
        } else {
            week = tb.checkstate.split("");
        }
        const loggedInUser = getUserFromCache();
        const locTz = getLocationTimeZone(locationId);
        const body = {
            user: loggedInUser.id,
            companyId:
                loggedInUser && loggedInUser.companyId
                    ? loggedInUser.companyId
                    : state.provider.companyId,
            locationId: locationId,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            startTime: moment(startTime).format("HH:mm"),
            endTime: moment(endTime).format("HH:mm"),
            weeksToRepeat: 1,
            weekDays: week,
            type: "AVAILABLE", //type,
            active: tb.tbActive,
            tz: locTz
        };
        let res;
        if (tb.id) {
            body.id = tb.id;
            body.scheduleId = schId;
            res = await updateTimeblock(body);
            await auditProviderSchedEdittb(loggedInUser, res, newInfo);
        } else {
            body.scheduleId = schId;
            res = await createTimeblock(body);
            await auditProviderSchedAddtb(loggedInUser, res, newInfo);
        }
        return res;
    };

    function getLocationTimeZone(locationId) {
        let locType = getLocationType(locationId);
        let locId = locationId.slice(3);
        if (locType === "CL") {
            let compLoc = locations.filter((l) => l.id === locId);
            if (compLoc && compLoc.length) {
                return compLoc[0].timezone;
            }
        } else {
            let provLoc = providerLocations.filter((l) => l.id === locId);
            if (provLoc && provLoc.length) {
                return provLoc[0].timezone;
            }
        }
        //default return browser timezone
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    function getScheduleTimeblockId(cid, pid) {
        return `C-${cid}|P-${pid}`;
    }

    async function getNameOfMissingService(serId, errorServiceNamesArr) {
        let response = await graphql(
            graphqlOperation(queries.getServiceType, { id: serId })
        );
        if (response && response.data && response.data.getServiceType) {
            let name = response.data.getServiceType.name;
            errorServiceNamesArr.push(name);
            return name;
        } else {
            console.log("asdf error getting service name");
        }
    }

    function returnToManageAvailability() {
        setSnackMsg("Schedule successfully saved.");
        setMsgOpen(true);
        if (props.path) {
            actions.setPage(props.path);
            if (props.closeAvailabilityPortal) {
                //props.saveAndNextHandler("customizations");
                props.closeAvailabilityPortal();
            }
            return;
        }
        setTimeout(() => {
            actions.setPage("ProviderSchedule");
            actions.setProviderScheduleViewType("manage_availability");
            actions.setId(state.provider.id);
        }, 100);
    }
    function cancelToAvailability() {
        if (props.path) {
            actions.setPage(props.path);
            if (props.closeAvailabilityPortal) {
                props.closeAvailabilityPortal();
            }
            return;
        }
        actions.setPage("ProviderSchedule");
        actions.setProviderScheduleViewType("manage_availability");
        actions.setId(state.provider.id);
    }
    async function handleDeactivateSchedule() {
        let res = await deactivateSchedule(schCompositeId);
        await auditProviderSchedDelete(loggedInUser, res, locnames);
        returnToManageAvailability();
    }
    async function handleActivateSchedule() {
        const pksk = schCompositeId.split("::");
        const scheduleId = pksk[1];
        let providerId = fromDashboard()
            ? loggedInUser && loggedInUser.providerId
                ? loggedInUser.providerId
                : state.provider.id
            : state.provider.id;

        //Get service types. (They associate themselves with schedules)
        let serviceTypeSchedules = await execReadBySortkey({
            opname: "listScheduleTimeblocks",
            op: scheduleQueries.listAllScheduleItems,
            id: { id: getScheduleTimeblockId(company.id, providerId) },
            skey: { scheduleinfo: { beginsWith: "SR|" } },
            filter: { active: { eq: true } },
            limit: process.env.REACT_APP_LISTLIMIT
        });

        let errorServicesArr = [];
        for (let schedule of serviceTypeSchedules.items) {
            let serviceTypeAssociatedSchedulesId =
                "SC" + schedule.scheduleinfo.split("|SC")[1];
            if (serviceTypeAssociatedSchedulesId === scheduleId) {
                //Service Type Schedule associated with this schedule.
                //Filter to get the service types that aren't on provider profile
                let matchingOriginalServices = originalServices.filter(
                    (ser) =>
                        schedule.servicetypeId === ser.servicetypeId &&
                        ser.name === null
                );
                matchingOriginalServices.map((x) => errorServicesArr.push(x));
            }
        }
        if (errorServicesArr.length > 0) {
            //If there exist services outside provider profile, get their name to report to user.
            let errorServiceNamesArr = [];
            for (const element of errorServicesArr) {
                let response = await getNameOfMissingService(
                    element.servicetypeId,
                    errorServiceNamesArr
                );
            }

            let missingServicesString =
                errorServiceNamesArr.length > 1
                    ? errorServiceNamesArr.join(", ")
                    : errorServiceNamesArr[0];
            setMissingServices(missingServicesString);
            setShowServicesError(true);
            setActivateSchDialog(false);
            return;
        }

        let res = await activateSchedule(schCompositeId);
        await auditProviderSchedActivate(loggedInUser, res, locnames);
        returnToManageAvailability();
    }

    function handleServicesChange(event) {
        setSelectedServices(event.target.value);
        setServicesUpdated(true);
    }

    const addScMode = () => {
        if (
            state.mode === "AddSc" ||
            !state.mode === "AddSc" ||
            fromDashboard()
        ) {
            return true;
        }
    };

    function renderServiceValues(serviceIds) {
        const servicesNames = [];
        serviceIds.map((id) =>
            providerServices.filter((item) =>
                item.id === id ? servicesNames.push(item.name) : ""
            )
        );
        return servicesNames.length === 1
            ? "1 service selected"
            : `${servicesNames.length} services selected`;
    }

    useEffect(() => {
        if (
            !locationId?.includes("AddTravelZone") &&
            !locationId?.startsWith("PL")
        ) {
            let cleanLocationId = locationId.substring(
                locationId.indexOf("-") + 1
            );
            let locationObject = locations.find(
                (item) => item.id === cleanLocationId
            );
            const filteredServicesList = manageFilteredServicesByLocation(
                locationObject,
                providerServices,
                () => {}
            );
            setFilteredServices(filteredServicesList);
            if (state.mode !== "Edit") {
                if (filteredServicesList?.length) {
                    const firstFilteredService = filteredServicesList.find(
                        (s) => !s.filteredByLocation
                    );
                    if (firstFilteredService) {
                        setSelectedServices([firstFilteredService.id]);
                    } else {
                        setSelectedServices([]);
                    }
                } else {
                    const firstProviderServiceId =
                        providerServices[0] && providerServices[0].id;
                    setSelectedServices([firstProviderServiceId]);
                }
            }
        } else if (locationId?.startsWith("PL") && state.mode !== "Edit") {
            setFilteredServices([]);
        }
    }, [locationId]);

    useEffect(() => {
        async function updateScheduleService() {
            const serviceValidation = await validateScheduleServices();
            if (!serviceValidation) {
                return;
            }
            const input = prepareScheduleInput(schCompositeId);
            input.id = schCompositeId;
            input.originalServices = originalServices;
            let res = await updateSchedule(input);
            if (!!res && res["error"]) {
                setSnackMsg("Could not save schedule information.");
                setMsgOpen(true);
                setSaveclicked(false);
                return;
            }
        }
        if (
            state.mode === "Edit" &&
            servicesUpdated &&
            state.provider &&
            Object.keys(state.provider).length > 0
        ) {
            setServicesUpdated(false);
            updateScheduleService();
        }
    }, [servicesUpdated]);

    function companyHasOnPremiseServices() {
        return company.offersOnPremiseServices;
    }
    function companyHasRemoteServices() {
        return company.offersRemoteServices;
    }

    function getLocationType(locationid) {
        if (locationid.startsWith("PL-")) return "PL";
        else return "CL";
    }
    function plid(id) {
        return "PL-" + id;
    }
    function clid(id) {
        return "CL-" + id;
    }

    /**************** TIMEBLOCK CODE***********************/

    React.useEffect(() => {
        async function dowork() {
            const tbs = await getTimeblocksbyScheduleIdNoCache(schCompositeId);
            for (let avatb of tbs) {
                avatb.timeValues = {
                    startTime: new Date(
                        moment().format("YYYY"),
                        moment().format("MM"),
                        moment().format("DD"),
                        avatb.startTime.split(":")[0],
                        avatb.startTime.split(":")[1]
                    ),
                    endTime: new Date(
                        moment().format("YYYY"),
                        moment().format("MM"),
                        moment().format("DD"),
                        avatb.endTime.split(":")[0],
                        avatb.endTime.split(":")[1]
                    )
                };

                avatb.dateValues = {
                    startDate: awsDateToJsDate(avatb.startDate),
                    endDate: awsDateToJsDate(avatb.endDate)
                };
                avatb.checkEndDate = avatb.endDate !== "2099-12-31";
                avatb.checkstate = avatb && avatb.weekDays;
                avatb.checkAllstate = isAllWeek(avatb.weekDays);
                avatb.tbActive = avatb.active;
                avatb.id = `${avatb.id}${SCHEDPKSKSPLITAT}${avatb.scheduleinfo}`;
            }
            setAvaTbs(tbs);
        }
        if (
            state.mode !== "AddSc" &&
            state.provider &&
            Object.keys(state.provider).length > 0
        ) {
            dowork();
        }
    }, []);

    React.useEffect(() => {
        async function dowork() {
            await _fetchFormData();
        }
        dowork();
    }, [displayTravelZoneForm]);

    useEffect(() => {
        if (
            state.mode === "AddSc" ||
            !state.mode === "AddSc" ||
            fromDashboard()
        ) {
            const currdate = new Date();
            let sdate = new Date(
                currdate.getFullYear(),
                currdate.getMonth(),
                currdate.getDate()
            );
            let edate = new Date(2099, currdate.getMonth(), currdate.getDate());

            const avaObj = {
                checkstate: "",
                checkEndDate: false,
                checkAllstate: false,
                allDayState: false,
                tbActive: true,
                timeValues: {
                    startTime: new Date(
                        moment().format("YYYY"),
                        moment().format("MM"),
                        moment().format("DD"),
                        8,
                        0
                    ),
                    endTime: new Date(
                        moment().format("YYYY"),
                        moment().format("MM"),
                        moment().format("DD"),
                        22,
                        0
                    )
                },
                dateValues: {
                    startDate: sdate,
                    endDate: edate
                }
            };
            setNewAvailObj(avaObj);
            setAvaTbs([...avaTbs, avaObj]);
        }
    }, []);

    function isAllWeek(weekdays) {
        if (weekdays && weekdays === "0123456") {
            return true;
        }
        return false;
    }

    const handleCheckAllStateChange = (name, tb, event) => {
        tb.checkAllstate = event.target.checked;
        if (event.target.checked) tb.checkstate = "0123456";
        else tb.checkstate = "";
        tb.dirty = true;
        setAvaTbs([...avaTbs]);
    };

    const handleChange = (name, tb, event) => {
        let arr = !!tb.checkstate ? tb.checkstate.split("") : [];
        // if (event.target.checked) arr.push(name.toString());
        if (event.target.checked) arr.push(name.toString());
        else
            arr = arr.filter((item) => {
                return item != name;
            });
        tb.checkstate = arr.join("");
        tb.dirty = true;
        setAvaTbs([...avaTbs]);
        arr = [];
    };

    const handleChangeModified = (name, tb, checked) => {
        let arr = !!tb.checkstate ? tb.checkstate.split("") : [];
        // if (event.target.checked) arr.push(name.toString());
        if (checked) arr.push(name.toString());
        else
            arr = arr.filter((item) => {
                return item != name;
            });
        tb.checkstate = arr.join("");
        tb.dirty = true;
        setAvaTbs([...avaTbs]);
        arr = [];
    };

    const _handleTimeChange = (name, tb, value) => {
        let curVal = tb.dateValues[name]?.valueOf();
        let newVal = value?.valueOf();
        if (curVal !== newVal) tb.dirty = true;
        tb.timeValues = {
            ...tb.timeValues,
            [name]: value
        };
        setAvaTbs([...avaTbs]);
    };

    function _handleDateChange(name, tb, value) {
        let curVal = tb.dateValues[name]?.valueOf();
        let newVal = value?.valueOf();
        if (curVal !== newVal) tb.dirty = true;
        tb.dateValues = {
            ...tb.dateValues,
            [name]: value
        };
        setAvaTbs([...avaTbs]);
    }

    const setTimeBlockActive = (checked, tb) => {
        tb.tbActive = checked;
        tb.dirty = true;
        setAvaTbs([...avaTbs]);
    };

    function getModeDesc() {
        if (addScMode()) {
            return "Add";
        }
        if (state.mode === "AddTb") {
            return "Add Availability";
        }
        if (state.mode === "Edit" && editType === "sc") {
            return "Edit";
        }
        if (state.mode === "Edit" && editType === "tb") {
            return "Edit Availability";
        }
    }

    function showCreateAvailForm() {
        const currdate = new Date();
        let sdate = new Date(
            currdate.getFullYear(),
            currdate.getMonth(),
            currdate.getDate()
        );
        let edate = new Date(2099, currdate.getMonth(), currdate.getDate());
        const avaObj = {
            checkstate: "",
            checkEndDate: false,
            checkAllstate: false,
            allDayState: false,
            tbActive: true,
            timeValues: {
                startTime: new Date(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    8,
                    0
                ),
                endTime: new Date(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    22,
                    0
                )
            },
            dateValues: {
                startDate: sdate,
                endDate: edate
            }
        };
        if (!newAvail) {
            setNewAvailObj(avaObj);
            setAvaTbs([avaObj, ...avaTbs]);
            //setNewAvail(true);
            setShowTimeBlockDisplayAdd(true);
        } else {
            setShowTimeBlockDisplayAdd(true);
        }
    }
    async function saveAvailability(tb, mode) {
        //save only the edited SB record of the SC
        // which id to pass to _handleCreateTimeblock?
        if (!tbInputValidation(tb)) return;
        setShowLoading(true);
        let res = await _handleCreateTimeBlock(schCompositeId, tb);
        setShowLoading(false);
        tb.id = res.id;
        tb.scheduleinfo = res.scheduleinfo;
        if (!!res && res["error"]) {
            setSnackMsg("Could not save availability information.");
            setMsgOpen(true);
            setSaveclicked(false);
            return;
        } else {
            setSnackMsg("Availability has been successfully saved");
            setMsgOpen(true);
        }
        tb.dirty = false;

        if (mode === "EditTb") {
            let not_edited = avaTbs.filter((item) => item.id !== res.id);
            let edit_arr = avaTbs.filter((item) => item.id === res.id);
            edit_arr[0].startDate = res.startDate;
            edit_arr[0].startTime = res.startTime;
            edit_arr[0].endDate = res.endDate;
            edit_arr[0].endTime = res.endTime;
            edit_arr[0].weekDays = res.weekDays;

            setAvaTbs([...edit_arr, ...not_edited]);
        } else {
            setAvaTbs([...avaTbs]);
        }

        if (showTimeBlockDisplay) {
            setShowTimeBlockDisplay((prevState) => !prevState);
        }
        if (showTimeBlockDisplayAdd) {
            setShowTimeBlockDisplayAdd((prevState) => !prevState);
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={5000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <Dialog
                open={deactivateSchDialog}
                onClose={() => setDeactivateSchDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deactivating Schedule"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to deactivate the schedule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeactivateSchedule} color="primary">
                        Yes
                    </Button>
                    <Button
                        onClick={() => setDeactivateSchDialog(false)}
                        color="primary"
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showServicesError}>
                <DialogTitle id="alert-dialog-title">
                    Unable to activate Schedule!
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        There is at least one service associated with this
                        schedule that your provider profile does not offer.
                        Please add {missingServices} to your offered services on
                        the provider profile page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowServicesError(false)}
                        color="primary"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={activateSchDialog}
                onClose={() => setActivateSchDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Activating Schedule"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to activate the schedule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleActivateSchedule} color="primary">
                        Yes
                    </Button>
                    <Button
                        onClick={() => setActivateSchDialog(false)}
                        color="primary"
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showTimeBlockDisplay}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogContent>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "24px", color: "rgba(0,0,0,0.75)" }}
                        >
                            Edit availability
                        </Typography>
                        <IconButton
                            onClick={() => {
                                /* setNewAvail((prevState) =>
                                    prevState ? false : true
                                ); */
                                if (!avaTbs[0].id) {
                                    setAvaTbs((prevState) =>
                                        removeAndReplaceAvail(avaTbs)
                                    );
                                }
                                setShowTimeBlockDisplay(
                                    (prevState) => !prevState
                                );
                            }}
                        >
                            <CancelRounded sx={{ color: "rgba(0,0,0,0.5)" }} />
                        </IconButton>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: "rgba(0,0,0,0.75)",
                            marginTop: "1.5px"
                        }}
                    >
                        {`Set the hours for when you are available to take appointments `}
                    </Typography>
                    <TimeBlockDisplay
                        key={selectedAvail.scheduleinfo}
                        tb={selectedAvail}
                        dateValues={selectedAvail.dateValues}
                        timeValues={selectedAvail.timeValues}
                        allDayState={selectedAvail.allDayState}
                        checkstate={selectedAvail.checkstate}
                        checkAllstate={selectedAvail.checkAllstate}
                        checkEndDate={selectedAvail.checkEndDate}
                        tbactive={selectedAvail.tbActive}
                        _handleDateChange={_handleDateChange}
                        _handleTimeChange={_handleTimeChange}
                        handleChange={handleChange}
                        handleChangeModified={handleChangeModified}
                        handleCheckAllStateChange={handleCheckAllStateChange}
                        setTbActive={setTimeBlockActive}
                        saveAvailability={saveAvailability}
                        editmode="EditTb"
                        fromDashboard={fromDashboard()}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showTimeBlockDisplayAdd}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogContent>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "24px", color: "rgba(0,0,0,0.75)" }}
                        >
                            Add Availability
                        </Typography>
                        <IconButton
                            onClick={() => {
                                /* setNewAvail((prevState) =>
                                    prevState ? false : true
                                ); */
                                if (!avaTbs[0].id) {
                                    setAvaTbs((prevState) =>
                                        removeAndReplaceAvail(avaTbs)
                                    );
                                }
                                setShowTimeBlockDisplayAdd(
                                    (prevState) => !prevState
                                );
                            }}
                        >
                            <CancelRounded sx={{ color: "rgba(0,0,0,0.5)" }} />
                        </IconButton>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: "rgba(0,0,0,0.75)",
                            marginTop: "1.5px"
                        }}
                    >
                        {`Set the hours for when you are available to take appointments`}
                    </Typography>
                    <TimeBlockDisplay
                        key={newAvailObj?.scheduleinfo}
                        tb={newAvailObj}
                        dateValues={newAvailObj?.dateValues}
                        timeValues={newAvailObj?.timeValues}
                        allDayState={newAvailObj?.allDayState}
                        checkstate={newAvailObj?.checkstate}
                        checkAllstate={newAvailObj?.checkAllstate}
                        checkEndDate={newAvailObj?.checkEndDate}
                        tbactive={newAvailObj?.tbActive}
                        _handleDateChange={_handleDateChange}
                        _handleTimeChange={_handleTimeChange}
                        handleChange={handleChange}
                        handleChangeModified={handleChangeModified}
                        handleCheckAllStateChange={handleCheckAllStateChange}
                        setTbActive={setTimeBlockActive}
                        editmode={"AddTb"}
                        saveAvailability={saveAvailability}
                        fromDashboard={fromDashboard()}
                    />
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={_handleSaveSchedule}
                        disabled={saveclicked}
                    >
                        Save
                    </Button> */}
                </DialogContent>
            </Dialog>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {props.path === "DashboardForm" ? (
                    <Typography className={classes.title} variant="h4" noWrap>
                        Add availability
                    </Typography>
                ) : (
                    <FormHeading
                        title={`Schedule / ${getModeDesc()}`}
                        classes={classes}
                    />
                )}
                {props.path === "DashboardForm" && (
                    <CloseRounded
                        sx={{
                            width: "2rem",
                            color: "rgba(0,0,0,0.5)",
                            fontWeight: 700,
                            cursor: "pointer"
                        }}
                        onClick={props.closeAvailabilityPortal}
                    />
                )}
            </div>
            {fromDashboard() && (
                <Grid
                    container
                    item
                    xs={12}
                    sx={{ marginTop: "8px", marginBottom: "18px" }}
                >
                    <Typography
                        sx={{
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "rgba(0,0,0,0.75)"
                        }}
                    >
                        {`Create a new schedule so that your clients can book `}
                        <b>{`${
                            Object.keys(state.provider).length > 0
                                ? state.provider.firstname
                                      .charAt(0)
                                      .toUpperCase() +
                                  state.provider.firstname.slice(1)
                                : loggedInUser
                                  ? loggedInUser.firstname
                                        .charAt(0)
                                        .toUpperCase() +
                                    loggedInUser.firstname.slice(1)
                                  : ""
                        } ${
                            Object.keys(state.provider).length > 0
                                ? state.provider.lastname
                                      .charAt(0)
                                      .toUpperCase() +
                                  state.provider.lastname.slice(1)
                                : loggedInUser
                                  ? loggedInUser.lastname
                                        .charAt(0)
                                        .toUpperCase() +
                                    loggedInUser.lastname.slice(1)
                                  : ""
                        }`}</b>
                        {` from the booking page`}
                    </Typography>
                </Grid>
            )}

            <Backdrop className={classes1.backdrop} open={showLoading}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Grid
                container
                sx={{ gap: "0.5rem", width: width > 900 ? "99%" : "100%" }}
            >
                <Grid
                    item
                    xs={fromDashboard() ? 12 : 4}
                    sx={{
                        backgroundColor: "#ffffff",
                        padding: fromDashboard() ? "0" : "1.5rem",
                        borderRadius: "8px"
                    }}
                >
                    {state.mode === "Edit" && locationName ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700
                                }}
                            >
                                Your schedule at
                            </Typography>
                            <Grid conatiner>
                                <Typography
                                    sx={{
                                        fontSize: "18px",
                                        color: "rgba(0,0,0,0.50)",
                                        fontWeight: 900
                                    }}
                                >
                                    {locationName}
                                </Typography>
                                {locationAddress && !virtualLocation && (
                                    <Grid
                                        item
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px"
                                        }}
                                    >
                                        <LocationOnIcon
                                            sx={{
                                                width: "13px",
                                                height: "13px",
                                                color: "rgba(0,0,0,0.50)"
                                            }}
                                        ></LocationOnIcon>
                                        <Typography
                                            sx={{
                                                fontSize: "13px",
                                                color: "rgba(0,0,0,0.75)"
                                            }}
                                        >
                                            {locationAddress}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700
                                }}
                            >
                                Select a location
                            </Typography>
                            <FormControl fullWidth required>
                                {locations?.length > 0 ||
                                providerLocations?.length > 0 ? (
                                    <Select
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 }
                                        }}
                                        labelId="select-location-label"
                                        label="Choose Location"
                                        id="select-location"
                                        value={locationId}
                                        onChange={handleLocationChange}
                                        name="locationId"
                                        disabled={
                                            state.mode === "Edit" &&
                                            state.provider &&
                                            Object.keys(state.provider).length >
                                                0
                                        }
                                        open={openMenu}
                                        onOpen={handleOpenSelect}
                                        onClose={handleCloseSelect}
                                        IconComponent={ArrowDropDown} // Use the custom expand icon component
                                        endAdornment={
                                            <React.Fragment>
                                                <Tooltip
                                                    title="This is the geographical area that Provider will be available to service"
                                                    placement="bottom"
                                                    style={{
                                                        color: "#a2a2a2",
                                                        fontSize: "20px",
                                                        marginRight: "1rem"
                                                    }}
                                                >
                                                    <InfoIcon
                                                        color="disabled"
                                                        sx={{
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            right: "16px", // Adjust the positioning as needed
                                                            transform:
                                                                "translateY(-50%)"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </React.Fragment>
                                        }
                                    >
                                        {providerLocations &&
                                            providerLocations.length <= 0 &&
                                            !companyHasRemoteServices() &&
                                            !companyHasOnPremiseServices() &&
                                            locations &&
                                            locations.length <= 0 && (
                                                <MenuItem>
                                                    No locations found
                                                </MenuItem>
                                            )}
                                        {companyHasRemoteServices() &&
                                            providerLocations &&
                                            providerLocations.length > 0 &&
                                            providerLocations.map((item, i) => {
                                                return (
                                                    <MenuItem
                                                        value={plid(item.id)}
                                                        key={item.id}
                                                    >
                                                        <TransferWithinAStationIcon></TransferWithinAStationIcon>

                                                        {capSentence(item.name)}
                                                    </MenuItem>
                                                );
                                            })}
                                        {companyHasOnPremiseServices() &&
                                            locations &&
                                            locations.length > 0 &&
                                            locations.map((item, i) => {
                                                return (
                                                        <MenuItem
                                                            value={clid(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.virtual ? (
                                                                <WifiIcon style={{width: "21px !important", height: "21px!important", margin: "0 !important"}}></WifiIcon>
                                                            ) : (
                                                                <BusinessIcon style={{width: "21px !important", height: "21px!important", margin: "0 !important"}}></BusinessIcon>
                                                            )}

                                                            {capSentence(
                                                                item.locationname
                                                            )}
                                                        </MenuItem>
                                                );
                                            })}
                                        <MenuItem>
                                            <Divider />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={(e) => {
                                                setDisplayTravelZoneForm(true);
                                                e.stopPropagation();
                                                //props.closeAvailabilityPortal();
                                            }}
                                            value={"AddTravelZone"}
                                            sx={{
                                                color: "primary.main",
                                                fontWeight: 700
                                            }}
                                        >
                                            <Add /> Add travel zone
                                        </MenuItem>
                                    </Select>
                                ) : (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            border: "1px solid rgba(0,0,0,0.3)",
                                            borderRadius: "4px",
                                            padding: "16.5px 14px",
                                            cursor: "pointer",
                                            display: "flex",
                                            gap: "0.5rem",
                                            color: "primary.main"
                                        }}
                                        onClick={(e) => {
                                            setDisplayTravelZoneForm(true);
                                            e.stopPropagation();
                                            //props.closeAvailabilityPortal();
                                        }}
                                    >
                                        <Add />
                                        <Typography
                                            sx={{
                                                color: "primary.main",
                                                fontWeight: 700
                                            }}
                                        >
                                            Add travel zone
                                        </Typography>
                                    </Box>
                                )}
                            </FormControl>
                        </Box>
                    )}
                </Grid>
                <Grid
                    item
                    xs={fromDashboard() ? 12 : 4}
                    sx={{
                        marginTop:
                            fromDashboard() || smallerScreen ? "1rem" : "0px",
                        backgroundColor: "#ffffff",
                        padding: fromDashboard() ? "0" : "1.5rem",
                        borderRadius: "8px"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px"
                        }}
                    >
                        {fromDashboard() ? (
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700
                                }}
                            >
                                Select services offered
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700
                                }}
                            >
                                Select service types
                            </Typography>
                        )}

                        <FormControl required fullWidth>
                            {/* <InputLabel htmlFor="selected-services-label">
                                Choose Services
                            </InputLabel> */}
                            <Select
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 }
                                }}
                                id="selected-services"
                                label="Choose Service"
                                labelId="selected-services-label"
                                name="selected-services"
                                multiple
                                value={selectedServices}
                                onChange={handleServicesChange}
                                renderValue={(selected) =>
                                    renderServiceValues(selected)
                                }
                            >
                                {providerServices.length <= 0 && (
                                    <Grid
                                        container
                                        sx={{
                                            flexDirection: "column",
                                            alignItems: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        <Grid item>
                                            <MenuItem
                                                disabled
                                                style={{ opacity: 0.75 }}
                                            >
                                                No services found
                                            </MenuItem>
                                        </Grid>
                                        <MenuItem
                                            disabled
                                            style={{ opacity: "0.75" }}
                                        >
                                            Go to your profile and choose your
                                            services to get started.
                                        </MenuItem>
                                    </Grid>
                                )}
                                {providerServices &&
                                    providerServices.length > 0 &&
                                    (filteredServices &&
                                    filteredServices.length > 0
                                        ? filteredServices
                                        : providerServices
                                    ).map((item, i) => {
                                        return item.filteredByLocation ? (
                                            <Tooltip
                                                title={
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px"
                                                        }}
                                                    >
                                                        This service is
                                                        unavailable at this
                                                        location
                                                    </Typography>
                                                }
                                            >
                                                <div>
                                                    <MenuItem
                                                        value={item.id}
                                                        key={item.id}
                                                        disabled={true}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedServices.indexOf(
                                                                    item.id
                                                                ) > -1
                                                            }
                                                        />
                                                        {item.name}
                                                    </MenuItem>
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                <Checkbox
                                                    checked={
                                                        selectedServices.indexOf(
                                                            item.id
                                                        ) > -1
                                                    }
                                                />
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {false && (
                                <FormHelperText
                                    sx={{
                                        fontZise: "12px",
                                        color: "success.main"
                                    }}
                                >
                                    Services Updated
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            {(state.mode === "AddSc" ||
                !state.mode === "AddSc" ||
                fromDashboard()) &&
                newAvailObj &&
                initialAvail && (
                    <Grid
                        sx={{
                            backgroundColor: "#fff",
                            padding: fromDashboard() ? "0" : "1.5rem",
                            marginTop: fromDashboard() ? "0" : "1rem",
                            borderRadius: "8px"
                        }}
                        container
                        item
                        xs={fromDashboard() ? 12 : 8}
                    >
                        <TimeBlockDisplay
                            key={newAvailObj?.scheduleinfo}
                            tb={newAvailObj}
                            dateValues={newAvailObj?.dateValues}
                            timeValues={newAvailObj?.timeValues}
                            allDayState={newAvailObj?.allDayState}
                            checkstate={newAvailObj?.checkstate}
                            checkAllstate={newAvailObj?.checkAllstate}
                            checkEndDate={newAvailObj?.checkEndDate}
                            tbactive={newAvailObj?.tbActive}
                            _handleDateChange={_handleDateChange}
                            _handleTimeChange={_handleTimeChange}
                            handleChange={handleChange}
                            handleChangeModified={handleChangeModified}
                            handleCheckAllStateChange={
                                handleCheckAllStateChange
                            }
                            setTbActive={setTimeBlockActive}
                            editmode={"AddSc"}
                            saveAvailability={saveAvailability}
                            fromDashboard={fromDashboard()}
                        />
                    </Grid>
                )}

            {avaTbs &&
                avaTbs.length &&
                state.mode !== "AddSc" &&
                !fromDashboard() && (
                    <Grid
                        sx={{
                            marginTop: "1rem",
                            backgroundColor: "#ffffff",
                            padding: "1.5rem",
                            borderRadius: "8px"
                        }}
                        container
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={8}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                                width: "100%"
                            }}
                        >
                            <Typography
                                component={"div"}
                                sx={{
                                    fontSize: "16px",
                                    color: "rgba(0,0,0,0.75)",
                                    fontWeight: 700
                                }}
                            >
                                Current availability
                            </Typography>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => showCreateAvailForm()}
                            >
                                Add availability
                            </Button>
                        </Box>

                        {/* first map out all of the active availability timeblocks     */}
                        {avaTbs &&
                            avaTbs.length > 0 &&
                            avaTbs.map((row, index) => {
                                return (row.id &&
                                    (row.active || row.tbActive)) ||
                                    (row.id &&
                                        row.dateValues &&
                                        row.timeValues &&
                                        row.checkstate &&
                                        (row.active || row.tbActive)) ? (
                                    <Grid
                                        sx={{
                                            marginTop: "1rem",
                                            backgroundColor: "primary.light",
                                            border: 2,
                                            borderColor: "primary.dark",
                                            padding: smallerScreen
                                                ? "1.5rem 0.5rem"
                                                : "1.5rem",
                                            borderRadius: "8px",
                                            alignItems: "center"
                                        }}
                                        container
                                    >
                                        <Grid
                                            container
                                            item
                                            lg={11}
                                            sm={12}
                                            sx={{ gap: "2rem" }}
                                            alignItems={"center"}
                                        >
                                            {/*Start Dates Box */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        Start date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.startDate
                                                            ? dateAndTimeFormatting(
                                                                  row.startDate,
                                                                  "date"
                                                              )
                                                            : row.dateValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .dateValues
                                                                        .startDate,
                                                                    "date"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        Start time
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.startTime
                                                            ? moment(
                                                                  row.startTime,
                                                                  "h:mm:ss A"
                                                              ).format("LT")
                                                            : row.timeValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .timeValues
                                                                        .startTime,
                                                                    "time"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/*EndDates Box */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        End date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.endDate
                                                            ? dateAndTimeFormatting(
                                                                  row.endDate,
                                                                  "date"
                                                              )
                                                            : row.dateValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .dateValues
                                                                        .endDate,
                                                                    "date"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        End time
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.endTime
                                                            ? moment(
                                                                  row.endTime,
                                                                  "h:mm:ss A"
                                                              ).format("LT")
                                                            : row.timeValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .timeValues
                                                                        .endTime,
                                                                    "time"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {smallerScreen && (
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            color: "primary.main",
                                                            textTransform:
                                                                "uppercase",
                                                            fontWeight: 600,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() =>
                                                            filterAndSelectAvail(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Typography>
                                                </Box>
                                            )}

                                            {/*Repeating Days */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.5rem"
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    Repeating on selected days
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: "0.5rem"
                                                    }}
                                                >
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            0
                                                        )}
                                                    >
                                                        S
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            1
                                                        )}
                                                    >
                                                        M
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            2
                                                        )}
                                                    >
                                                        T
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            3
                                                        )}
                                                    >
                                                        W
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            4
                                                        )}
                                                    >
                                                        T
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            5
                                                        )}
                                                    >
                                                        F
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            6
                                                        )}
                                                    >
                                                        S
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {!smallerScreen && (
                                            <Grid
                                                item
                                                lg={1}
                                                sm={12}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    marginTop: "1rem"
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        textTransform:
                                                            "uppercase",
                                                        fontWeight: 600,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() =>
                                                        filterAndSelectAvail(
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Typography>
                                                {!row?.active &&
                                                    !row?.tbActive && (
                                                        <Typography
                                                            sx={{
                                                                color: "#6c6c6c",
                                                                fontWeight: 100,
                                                                fontSize: "14px"
                                                            }}
                                                        >
                                                            inactive
                                                        </Typography>
                                                    )}
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}

                        {/* then map out all the inactive availability time blocks */}
                        {avaTbs &&
                            avaTbs.length > 0 &&
                            avaTbs.map((row, index) => {
                                return (row.id &&
                                    !row?.active &&
                                    !row?.tbActive) ||
                                    (row.id &&
                                        row.dateValues &&
                                        row.timeValues &&
                                        row.checkstate &&
                                        !row?.active &&
                                        !row?.tbActive) ? (
                                    <Grid
                                        sx={{
                                            marginTop: "1rem",
                                            backgroundColor: row?.active
                                                ? "primary.light"
                                                : "#EFEFEF",
                                            border: 2,
                                            borderColor: row?.active
                                                ? "primary.dark"
                                                : "#FFFFFF",
                                            padding: smallerScreen
                                                ? "1.5rem 0.5rem"
                                                : "1.5rem",
                                            borderRadius: "8px",
                                            alignItems: "center"
                                        }}
                                        container
                                    >
                                        <Grid
                                            container
                                            item
                                            lg={11}
                                            sm={12}
                                            sx={{ gap: "2rem" }}
                                            alignItems={"center"}
                                        >
                                            {/*Start Dates Box */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        Start date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.startDate
                                                            ? dateAndTimeFormatting(
                                                                  row.startDate,
                                                                  "date"
                                                              )
                                                            : row.dateValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .dateValues
                                                                        .startDate,
                                                                    "date"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        Start time
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.startTime
                                                            ? moment(
                                                                  row.startTime,
                                                                  "h:mm:ss A"
                                                              ).format("LT")
                                                            : row.timeValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .timeValues
                                                                        .startTime,
                                                                    "time"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/*EndDates Box */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        End date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.endDate
                                                            ? dateAndTimeFormatting(
                                                                  row.endDate,
                                                                  "date"
                                                              )
                                                            : row.dateValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .dateValues
                                                                        .endDate,
                                                                    "date"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        End time
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0,0,0,0.8)"
                                                        }}
                                                    >
                                                        {row.endTime
                                                            ? moment(
                                                                  row.endTime,
                                                                  "h:mm:ss A"
                                                              ).format("LT")
                                                            : row.timeValues
                                                              ? dateAndTimeFormatting(
                                                                    row
                                                                        .timeValues
                                                                        .endTime,
                                                                    "time"
                                                                )
                                                              : ""}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {smallerScreen && (
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            color: "primary.main",
                                                            textTransform:
                                                                "uppercase",
                                                            fontWeight: 600,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() =>
                                                            filterAndSelectAvail(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Typography>
                                                    {!row?.active && (
                                                        <Typography
                                                            sx={{
                                                                color: "#6c6c6c",
                                                                fontWeight: 100,
                                                                fontSize: "14px"
                                                            }}
                                                        >
                                                            inactive
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}

                                            {/*Repeating Days */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.5rem"
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    Repeating on selected days
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: "0.5rem"
                                                    }}
                                                >
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            0
                                                        )}
                                                    >
                                                        S
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            1
                                                        )}
                                                    >
                                                        M
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            2
                                                        )}
                                                    >
                                                        T
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            3
                                                        )}
                                                    >
                                                        W
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            4
                                                        )}
                                                    >
                                                        T
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            5
                                                        )}
                                                    >
                                                        F
                                                    </IconButton>
                                                    <IconButton
                                                        sx={daysStyleFunction(
                                                            row.weekDays
                                                                ? row.weekDays
                                                                : row.checkstate,
                                                            6
                                                        )}
                                                    >
                                                        S
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {!smallerScreen && (
                                            <Grid
                                                item
                                                lg={1}
                                                sm={12}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    marginTop: "1rem"
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        textTransform:
                                                            "uppercase",
                                                        fontWeight: 600,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() =>
                                                        filterAndSelectAvail(
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Typography>
                                                {!row?.active && (
                                                    <Typography
                                                        sx={{
                                                            color: "#6c6c6c",
                                                            fontWeight: 100,
                                                            fontSize: "14px"
                                                        }}
                                                    >
                                                        inactive
                                                    </Typography>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                    </Grid>
                )}
            <Grid container sx={{ marginTop: "2rem" }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{ display: "flex", gap: "1rem" }}
                >
                    {(state.mode === "AddSc" ||
                        !state.mode === "AddSc" ||
                        fromDashboard()) && (
                        <Box component="span">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleSaveSchedule}
                                disabled={
                                    saveclicked ||
                                    locationId === "AddTravelZone" ||
                                    selectedServices.length <= 0
                                }
                            >
                                {fromDashboard()
                                    ? "Save & next"
                                    : "Save schedule"}
                            </Button>
                        </Box>
                    )}
                    {!fromDashboard() && (
                        <Box component="span">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    cancelToAvailability();
                                }}
                            >
                                {addScMode() ? "Cancel" : "Back"}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <TravelZoneModal
                displayTravelZoneForm={displayTravelZoneForm}
                pathname={
                    props.path ? props.path : "ProviderScheduleAddEditForm"
                }
                returnTo={props.returnTo}
                closeTravelZonePortal={closeTravelZonePortal}
                closeAvailabilityPortal={props.closeAvailabilityPortal}
                updateTravelZoneValue={updateTravelZoneValue}
            />
        </>
    );
};

export default ProviderScheduleAddEditForm;
