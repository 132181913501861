import React, { useContext, useEffect } from "react";
import {
    Table,
    TableRow,
    TablePagination,
    Button,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    Tooltip,
    tooltipClasses,
    styled,
    DialogTitle,
    DialogContent,
    Dialog,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    IconButton
} from "@mui/material";
//import promoCodesData from "./promoCodesData";
import { useState } from "react";
import moment from "moment";
import { listPromos } from "../../graphql/queries";
import { execReadBySortkey } from "../../modules/DBService";
import { maxWidth, style } from "@mui/system";
import { Cancel, ContentCopy, MoreHoriz } from "@mui/icons-material";
import { StoreContext } from "../../context/StoreContext";
import {
    getTableRowsSession,
    setTableRowsSession
} from "../../utils/Common/TableRowsSession";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
}));

const PromoTableTheme = {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    //Table Head
    "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                color: "rgba(0,0,0,0.5)",
                paddingBottom: "0.5rem",
                fontWeight: 400
            }
        }
    },

    //Table Body
    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            "&:hover": {
                backgroundColor: "primary.light"
            },
            "& .MuiTableCell-root": {
                fontSize: "0.875rem",

                "& .MuiTypography-root": {
                    fontSize: "0.875rem"
                }
            }
        },
        "& .MuiTableRow-root td:first-of-type": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
        },

        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
        }
    },

    //MuiTableFooter  :
    "& .MuiTableFooter-root": {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                "& .MuiToolbar-root": {
                    "& .MuiInputBase-root": {
                        fontWeight: 700
                    },
                    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                        {
                            color: "rgba(0,0,0,0.5)"
                        },
                    "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "0.5rem",
                        "& button": {
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: "4px"
                        },

                        "& button:hover": {
                            backgroundColor: "primary.light"
                        }
                    }
                }
            }
        }
    }
};

function PromoDropDown(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleThreeDotsClose = () => {
        setAnchorEl(null);
        //setSelectedOrder('');
    };
    return (
        <div>
            <MoreHoriz
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
            />

            <Menu
                PaperProps={{
                    style: {
                        width: 150,
                        border: "1px solid #e5e5e5",
                        boxShadow: "1px 1px #e5e5e5"
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleThreeDotsClose}
            >
                <MenuItem
                    sx={{ fontWeight: 700, color: "#0087EE" }}
                    onClick={(e) => {
                        //console.log(props.index);

                        props.editPromoCode(props.row.id);

                        handleThreeDotsClose();
                        /* props.getIndex(
              e,
              props.providerId,
              props.providerName,
              props.index
            ); */
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    sx={{ fontWeight: 700, color: "#FF681D" }}
                    onClick={(e) => {
                        props.setOpenUsageModal(true);
                        props.fetchPromoRedemptions(props.row.id);
                        handleThreeDotsClose();
                        /* props.getIndex(
              e,
              props.providerId,
              props.providerName,
              props.index
            ); */
                    }}
                >
                    Usage Report
                </MenuItem>
            </Menu>
        </div>
    );
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, onHandleClearClick } =
        props;
    /* const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      }; */

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox" sx={{ paddingTop: 0 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all promoCodes"
                        }}
                    />
                </TableCell> */}
                <TableCell sx={{ paddingTop: 0 }}>
                    {numSelected > 0 ? (
                        <Typography
                            sx={{
                                flex: "1 1 100%",
                                fontWeight: 700,
                                color: "primary.main"
                            }}
                        >
                            {numSelected + " selected "}
                            <span style={{ color: "rgba(0,0,0,0.75)" }}>(</span>
                            <span
                                style={{
                                    color: "#FF681D",
                                    fontWeight: 700,
                                    cursor: "pointer"
                                }}
                                onClick={(event) => onHandleClearClick(event)}
                            >
                                Clear
                            </span>
                            <span style={{ color: "rgba(0,0,0,0.75)" }}>)</span>
                        </Typography>
                    ) : (
                        "Status"
                    )}
                </TableCell>

                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Number of Uses</TableCell>
                <TableCell>Applies to</TableCell>
                <TableCell>Start Date</TableCell>
            </TableRow>
        </TableHead>
    );
}

function PromoCodesTable(props) {
    const { state, actions } = useContext(StoreContext);
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        getTableRowsSession("promoTable")
    );
    const [rows, setRows] = useState([]);
    const [openUsageModal, setOpenUsageModal] = useState(false);
    const [usageModalZeroRedemptions, setUsageModalZeroRedemptions] =
        useState(false);
    const [redemptionList, setRedemptionList] = useState([]);
    let usageModalTotal = 0;

    //set data to rows state in this useEffect
    useEffect(() => {
        setRows(props.promoCodesData);
    }, [rows, props.promoCodesData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        if (getTableRowsSession("promoTable")) {
            setTableRowsSession("promoTable", event.target.value);
        }
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    /*  const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = promoCodesData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }; */

    /* function handleRowClick(event, id) {
        // setSelectedProvider("");
        setSelectAll(false);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    } */

    /* const handleClearClick = (event) => {
        setSelected([]);
    }; */
    const getStatusType = (row) => {
        let obj_style = {
            label: "Paused",
            background: "rgba(0, 0, 0, 0.2)",
            color: "#878787"
        };
        let currentDate = new Date().valueOf();
        let startDate = new Date(row.startDate).valueOf();
        if (startDate > currentDate && row.active) {
            obj_style.label = "Scheduled";
            obj_style.background = "rgba(194, 21, 21, 0.2)";
            obj_style.color = "#BA0808";
        } else if (row.hasExpiryDate) {
            let endDate = new Date(row.endDate).valueOf();
            if (endDate <= currentDate) {
                obj_style.label = "Expired";
                obj_style.background = "rgba(194, 21, 21, 0.2)";
                obj_style.color = "#BA0808";
            } else if (
                row.maxNumOfRedemptions !== 0 &&
                row.maxNumOfRedemptions === row.numOfRedemptions
            ) {
                obj_style.label = "Done";
                obj_style.background = "rgba(0, 135, 238, 0.2)";
                obj_style.color = "#0087EE";
            } else {
                obj_style.label = row.active ? "Active" : "Paused";
                obj_style.background = row.active
                    ? "rgba(67, 182, 91, 0.2)"
                    : "rgba(0, 0, 0, 0.2)";
                obj_style.color = row.active
                    ? "rgba(67, 182, 91, 1)"
                    : "#878787";
            }
        } else {
            if (
                row.maxNumOfRedemptions !== 0 &&
                row.maxNumOfRedemptions === row.numOfRedemptions
            ) {
                obj_style.label = "Done";
                obj_style.background = "rgba(0, 135, 238, 0.2)";
                obj_style.color = "#0087EE";
            } else {
                obj_style.label = row.active ? "Active" : "Paused";
                obj_style.background = row.active
                    ? "rgba(67, 182, 91, 0.2)"
                    : "rgba(0, 0, 0, 0.2)";
                obj_style.color = row.active
                    ? "rgba(67, 182, 91, 1)"
                    : "#878787";
            }
        }

        return obj_style;
    };

    const getDiscountType = (dValue, dType) => {
        if (dType === "DOLLAR") {
            return `$${dValue}`;
        }
        return `${dValue}%`;
    };

    const fetchPromoRedemptions = async (rowId) => {
        try {
            let promos = await execReadBySortkey({
                opname: "listPromos",
                op: listPromos,
                id: {
                    id: rowId
                },
                sortDirection: "DESC",
                skey: {
                    recordType: {
                        beginsWith: "REDEMPTION|"
                    }
                }
            });

            redemptionData(JSON.parse(JSON.stringify(promos)));
        } catch (error) {
            console.log("error with fetchPromoRedemptions", error);
        }
    };

    const redemptionData = (redemptions) => {
        let rows = [];

        if (redemptions.items && redemptions.items.length > 0) {
            redemptions.items.map(async (item) => {
                rows.push(JSON.parse(item.Details));
            });
        } else {
            setUsageModalZeroRedemptions(true);
        }

        if (rows && rows.length > 0) {
            rows.map(async (item) => {
                if (item.order) {
                    item.order.orderSummary = JSON.parse(
                        item.order.orderSummary
                    );
                }
            });
        }
        console.log("rows", rows);

        setRedemptionList(rows);
    };

    /* const isSelected = (id) => selected.indexOf(id) !== -1; */
    if (!props.isLoading) {
        return (
            <div>
                <TableContainer>
                    <Table sx={PromoTableTheme}>
                        {/* <EnhancedTableHead
                        numSelected={selected.length}
                        rowCount={promoCodesData.length}
                        onSelectAllClick={handleSelectAllClick}
                        onHandleClearClick={handleClearClick}
                    /> */}
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                    Code
                                </TableCell>
                                <TableCell>Discount</TableCell>
                                <TableCell>Usage</TableCell>
                                <TableCell>Applies to</TableCell>
                                <TableCell>Start Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.length > 0 &&
                                rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        //const isItemSelected = isSelected(row.id);
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    backgroundColor: "white"
                                                }}
                                            >
                                                {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                onClick={(event) =>
                                                    handleRowClick(
                                                        event,
                                                        row.id
                                                    )
                                                }
                                            />
                                        </TableCell> */}
                                                <TableCell>
                                                    <Typography
                                                        sx={{
                                                            background:
                                                                getStatusType(
                                                                    row
                                                                ).background,
                                                            color: getStatusType(
                                                                row
                                                            ).color,
                                                            textTransform:
                                                                "uppercase",
                                                            borderRadius: "4px",
                                                            fontSize: "8px",
                                                            maxWidth: "100px",
                                                            textAlign: "center",
                                                            fontWeight: 600,
                                                            padding: "2px 4px",
                                                            margin: "0 auto"
                                                        }}
                                                    >
                                                        {
                                                            getStatusType(row)
                                                                .label
                                                        }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        sx={{
                                                            textTransform:
                                                                "uppercase"
                                                        }}
                                                    >
                                                        <span>
                                                            <Tooltip
                                                                title={`Copy ${row.promocode}`}
                                                            >
                                                                <ContentCopy
                                                                    style={{
                                                                        fontSize:
                                                                            "16px",
                                                                        color: "rgba(0,0,0,0.5)",
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "5px"
                                                                    }}
                                                                    onClick={() =>
                                                                        navigator.clipboard.writeText(
                                                                            row.promocode
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                        {row.promocode}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {getDiscountType(
                                                            row.discountValue,
                                                            row.discountType
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            minWidth: "53px",
                                                            padding: "0px",
                                                            borderColor:
                                                                "#d5d5d5"
                                                        }}
                                                        onClick={() => {
                                                            setOpenUsageModal(
                                                                true
                                                            );
                                                            fetchPromoRedemptions(
                                                                row.id
                                                            );
                                                        }}
                                                    >
                                                        <Typography>
                                                            {row.maxNumOfRedemptions ===
                                                            0
                                                                ? "Unlimited"
                                                                : row.numOfRedemptions}
                                                            <br />
                                                            {row.maxNumOfRedemptions >
                                                                0 && (
                                                                <span
                                                                    style={{
                                                                        fontFamily:
                                                                            "Roboto",
                                                                        color: "rgba(0,0,0,0.5)"
                                                                    }}
                                                                >
                                                                    {"/" +
                                                                        row.maxNumOfRedemptions}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.allServices
                                                            ? "All Services"
                                                            : row.services
                                                              ? row.services
                                                                    .slice(0, 3)
                                                                    .map(
                                                                        (s) =>
                                                                            s.name
                                                                    )
                                                                    .join(
                                                                        ", "
                                                                    ) + " "
                                                              : ""}
                                                        {row.services?.length >
                                                            3 && (
                                                            <HtmlTooltip
                                                                placement={
                                                                    "bottom"
                                                                }
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography>
                                                                            Applies
                                                                            to
                                                                        </Typography>
                                                                        <div>
                                                                            {row.services
                                                                                .slice(
                                                                                    0,
                                                                                    row
                                                                                        .services
                                                                                        .length
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        s,
                                                                                        i
                                                                                    ) => (
                                                                                        <li
                                                                                            key={
                                                                                                i
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                s.name
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <Button
                                                                    variant="text"
                                                                    sx={{
                                                                        padding:
                                                                            "0px",
                                                                        fontWeight:
                                                                            "100",
                                                                        fontSize:
                                                                            "14px",
                                                                        lineHeight:
                                                                            "0px",
                                                                        minWidth:
                                                                            "0px",
                                                                        textTransform:
                                                                            "unset"
                                                                    }}
                                                                >
                                                                    {"("}
                                                                    {row
                                                                        .services
                                                                        .length -
                                                                        3}
                                                                    {
                                                                        " more services)"
                                                                    }
                                                                </Button>
                                                            </HtmlTooltip>
                                                        )}
                                                        {row.allPackages
                                                            ? "All Packages"
                                                            : row.packages
                                                              ? row.packages
                                                                    .slice(0, 3)
                                                                    .map(
                                                                        (s) =>
                                                                            s.name
                                                                    )
                                                                    .join(
                                                                        ", "
                                                                    ) + " "
                                                              : ""}
                                                        {row.packages?.length >
                                                            3 && (
                                                            <HtmlTooltip
                                                                placement={
                                                                    "bottom"
                                                                }
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography>
                                                                            Applies
                                                                            to
                                                                        </Typography>
                                                                        <div>
                                                                            {row.packages
                                                                                .slice(
                                                                                    0,
                                                                                    row
                                                                                        .packages
                                                                                        .length
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        s,
                                                                                        i
                                                                                    ) => (
                                                                                        <li
                                                                                            key={
                                                                                                i
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                s.name
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <Button
                                                                    variant="text"
                                                                    sx={{
                                                                        padding:
                                                                            "0px",
                                                                        fontWeight:
                                                                            "100",
                                                                        fontSize:
                                                                            "14px",
                                                                        lineHeight:
                                                                            "0px",
                                                                        minWidth:
                                                                            "0px",
                                                                        textTransform:
                                                                            "unset"
                                                                    }}
                                                                >
                                                                    {"("}
                                                                    {row
                                                                        .packages
                                                                        ?.length -
                                                                        3}
                                                                    {
                                                                        " more packages)"
                                                                    }
                                                                </Button>
                                                            </HtmlTooltip>
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {moment(
                                                            row.startDate
                                                        ).format("YYYY-MM-DD")}
                                                        <br />
                                                        {row.hasExpiryDate && (
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        "Roboto",
                                                                    color: "rgba(0,0,0,0.5)"
                                                                }}
                                                            >
                                                                {"Expires: " +
                                                                    moment(
                                                                        row.endDate
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    )}
                                                            </span>
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                {/* <TableCell>
                                                <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        fontWeight: "700",
                                                        textTransform:
                                                            "uppercase",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() =>
                                                        props.editPromoCode(
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Typography>
                                            </TableCell> */}
                                                <TableCell>
                                                    <PromoDropDown
                                                        row={row}
                                                        editPromoCode={
                                                            props.editPromoCode
                                                        }
                                                        setOpenUsageModal={
                                                            setOpenUsageModal
                                                        }
                                                        fetchPromoRedemptions={
                                                            fetchPromoRedemptions
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                    {openUsageModal && (
                        <Dialog
                            open={openUsageModal}
                            onClose={() => {
                                setOpenUsageModal(false);
                                setRedemptionList([]);
                                setUsageModalZeroRedemptions(false);
                            }}
                            fullWidth={true}
                            PaperProps={{
                                sx: {
                                    maxWidth: "819px",
                                    overflow: "hidden"
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    padding: "1rem",
                                    paddingBottom: "0px"
                                }}
                            >
                                <DialogTitle
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: 400,
                                        padding: "0px 0px 0px 8px"
                                    }}
                                >
                                    Promo Code Usage
                                </DialogTitle>
                                <IconButton
                                    onClick={() => {
                                        setOpenUsageModal(false);
                                        setRedemptionList([]);
                                        setUsageModalZeroRedemptions(false);
                                    }}
                                >
                                    <Cancel
                                        sx={{ width: "32px", height: "32px" }}
                                    />
                                </IconButton>
                            </div>

                            <DialogContent
                                sx={{
                                    overflow: "hidden",
                                    paddingTop: "0px"
                                }}
                            >
                                {(redemptionList &&
                                    redemptionList.length > 0) ||
                                usageModalZeroRedemptions ? (
                                    <TableContainer sx={{ maxHeight: "365px" }}>
                                        <Typography
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                textDecorationLine: "underline"
                                            }}
                                        >
                                            {redemptionList &&
                                                redemptionList.length > 0 &&
                                                redemptionList[0].order
                                                    ?.orderSummary.promoData
                                                    .name}
                                        </Typography>
                                        <Table
                                            sx={PromoTableTheme}
                                            stickyHeader={true}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Order Num.
                                                    </TableCell>
                                                    <TableCell>
                                                        Client Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Service
                                                    </TableCell>
                                                    <TableCell>
                                                        Discount
                                                    </TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Total</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {redemptionList &&
                                                    redemptionList.length > 0 &&
                                                    redemptionList.map(
                                                        (row, index) => {
                                                            usageModalTotal +=
                                                                row.order
                                                                    ?.total;
                                                            return (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{
                                                                        backgroundColor:
                                                                            ""
                                                                    }}
                                                                >
                                                                    <TableCell>
                                                                        {row
                                                                            ? row
                                                                                  .order
                                                                                  ?.orderNo
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row
                                                                            ? row
                                                                                  .bookingUser
                                                                                  ?.firstname +
                                                                              " " +
                                                                              row
                                                                                  .bookingUser
                                                                                  ?.lastname
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row
                                                                            ? row
                                                                                  .order
                                                                                  ?.orderSummary
                                                                                  .rows[0]
                                                                                  .service
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row
                                                                            ? row
                                                                                  .order
                                                                                  ?.orderSummary
                                                                                  .discount
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row
                                                                            ? moment(
                                                                                  row
                                                                                      .order
                                                                                      ?.createdAt
                                                                              ).format(
                                                                                  "YYYY-MM-DD"
                                                                              )
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row
                                                                            ? row
                                                                                  .order
                                                                                  ?.orderSummary
                                                                                  .total
                                                                            : ""}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center"
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                )}
                                {usageModalZeroRedemptions && (
                                    <Grid
                                        container
                                        sx={{ justifyContent: "center" }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "rgba(0,0,0,0.7)",
                                                padding: "38px",
                                                paddingBottom: "22px",
                                                fontSize: "20px"
                                            }}
                                        >
                                            No Redemptions Yet
                                        </Typography>
                                    </Grid>
                                )}
                                {usageModalTotal > 0 && (
                                    <Typography
                                        sx={{
                                            textAlign: "end",
                                            fontWeight: "bold",
                                            paddingTop: "14px",
                                            paddingRight: "16px"
                                        }}
                                    >
                                        Total Sales: $
                                        {usageModalTotal.toFixed(2)}
                                    </Typography>
                                )}
                            </DialogContent>
                        </Dialog>
                    )}
                </TableContainer>
                <TablePagination
                    sx={{
                        marginLeft: "auto",
                        "& .MuiToolbar-root": {
                            alignItems: "center",
                            "& .MuiInputBase-root": {
                                fontWeight: 700
                            },
                            "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                                {
                                    color: "rgba(0,0,0,0.5)",
                                    marginBottom: 0
                                },
                            "& .MuiTablePagination-actions": {
                                display: "flex",
                                gap: "0.5rem",
                                "& button": {
                                    border: "1px solid rgba(0,0,0,0.2)",
                                    borderRadius: "4px"
                                },

                                "& button:hover": {
                                    backgroundColor: "primary.light"
                                }
                            }
                        }
                    }}
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        );
    } else {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <CircularProgress />
            </div>
        );
    }
}

export default PromoCodesTable;
