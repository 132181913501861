import React from "react";
import { Typography, Box, Chip } from "@mui/material";
import moment from "moment";
import { TRAVEL_BUFFER } from "../utils/Constants";

export const SuggestedTime = ({
    suggestedTime,
    travelTime,
    isSelected,
    onSelect,
    slot
}) => {
    return (
        <DayOrTimeSuggestion
            displayDayOrTime={moment(suggestedTime).format(
                "ddd, MMM D – h:mm A"
            )}
            travelTimeText={
                slot.wasEmptyDay
                    ? "Empty day, first booking"
                    : travelTime === 0
                      ? "Same location"
                      : `${moment.duration(TRAVEL_BUFFER + travelTime, "minutes").humanize()} travel time`
            }
            isSelected={isSelected}
            onSelect={() => onSelect(slot)}
        />
    );
};
export const SuggestedDay = ({
    suggestedDay,
    isSelected,
    onSelect,
    fullDay
}) => {
    return (
        <DayOrTimeSuggestion
            displayDayOrTime={
                isSelected.isSelectedDay && isSelected.fullDaySelectedTime
                    ? moment(isSelected.fullDaySelectedTime).format(
                          "ddd, MMM D – h:mm A"
                      )
                    : moment(suggestedDay).format("ddd, MMM D")
            }
            travelTimeText={
                isSelected.isSelectedDay && isSelected.fullDaySelectedTime
                    ? "First booking"
                    : "Empty day, book any time"
            }
            isSelected={isSelected.isSelectedDay}
            onSelect={() => onSelect(fullDay)}
        />
    );
};
export const DayOrTimeSuggestion = ({
    displayDayOrTime,
    travelTimeText,
    onSelect
}) => {
    return (
        <Chip
            onClick={onSelect}
            variant={"contained"}
            label={
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center"
                    }}
                >
                    <Typography variant="body2" gutterBottom={false}>
                        {displayDayOrTime}
                    </Typography>
                    <Typography
                        variant="helperText"
                        sx={{
                            marginTop: "-4px"
                        }}
                    >
                        {travelTimeText}
                    </Typography>
                </Box>
            }
        />
    );
};
