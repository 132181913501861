import React, { useState } from "react";
import { delApi, postApi } from "../modules/AmplifyServices";
import { Button, TextField, Grid, Typography, Box } from "@mui/material";
import { mbxUserAtom, integrationRefDataAtom } from "../atoms/atoms";
import { useAtom } from "jotai";
import SmallSizedModal from "../utils/UI/SmallSizedModal";
import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";

const customDialogButtonStyles = {
    borderColor: "primary.main",
    height: "2.25rem",
    boxShadow: "none",
    minWidth: "5.625rem",
    alignItems: "center",
    lineHeight: "inherit",
    "&:hover": {
        boxShadow: "none"
    }
};

const customDialogButtonTextStyles = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: "0.875rem",
    fontWeight: 500,
    fontFamily: "Roboto",
    paddingTop: "0.1rem"
};

const GoogleTagManagerSetupSection = () => {
    const [integrationRefData, setIntegrationRefData] = useAtom(
        integrationRefDataAtom
    );
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);
    const [gtmId, setGtmId] = useState(
        integrationRefData?.hooks?.["google-tag-manager"] !== undefined &&
            integrationRefData?.hooks?.["google-tag-manager"].length > 0
            ? integrationRefData.hooks["google-tag-manager"][0].id
            : ""
    );
    const [gtmExists, setGtmExists] = useState(
        integrationRefData?.hooks?.["google-tag-manager"] !== undefined &&
            integrationRefData?.hooks?.["google-tag-manager"].length > 0
    );
    const [idInputError, setIdInputError] = useState("");
    const [showRemoveIdModal, setShowRemoveIdModal] = useState(false);

    const handleSaveGtmId = async () => {
        if (gtmId.trim() === "") {
            setIdInputError("Please enter an ID");
            return;
        }
        setGtmExists(true);
        try {
            const date = new Date();
            const dateString = date.toISOString();
            let body = {
                hookUrl: gtmId,
                clientId: mbxUser.company.id,
                secretKey: integrationRefData.secretKey,
                name: "google-tag-manager",
                hook: {
                    id: gtmId,
                    createdAt: dateString
                }
            };
            setIntegrationRefData({
                ...integrationRefData,
                hooks: {
                    ...integrationRefData.hooks,
                    "google-tag-manager": [
                        ...(integrationRefData.hooks?.["google-tag-manager"] ??
                            []),
                        {
                            id: gtmId,
                            createdAt: dateString
                        }
                    ]
                }
            });
            await postApi("integrationapi", "/subscription", {
                body
            });
        } catch (e) {
            console.log("Error: Unable to save GTM ID in refData", e);
        }
    };

    const handleRemoveGtmId = async () => {
        try {
            setShowRemoveIdModal(false);
            setGtmExists(false);
            setGtmId("");
            let queryParams = {
                hookUrl: "",
                clientId: mbxUser.company.id,
                name: "google-tag-manager",
                hookId: gtmId
            };
            setIntegrationRefData({
                ...integrationRefData,
                hooks: {
                    ...integrationRefData.hooks,
                    "google-tag-manager": []
                }
            });
            await delApi("integrationapi", "/subscription", {
                queryParams
            });
        } catch (e) {
            console.log("Error: Unable to remove GTM ID from refData", e);
        }
    };

    return (
        <>
            {/** Remove GTM Warning Modal **/}
            <SmallSizedModal
                open={showRemoveIdModal}
                onClose={() => {
                    setShowRemoveIdModal(false);
                }}
                headingIcon={WarningTriangleIcon}
                headingText={"Remove ID"}
            >
                <Box>
                    <Typography variant="body2">
                        This will remove the Google Tag Manager ID from all
                        pages on your online booking engine and we will no
                        longer be tracking conversions on your behalf. Are you
                        sure you wish to continue?
                    </Typography>
                </Box>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1.25rem"
                    }}
                >
                    <Button
                        sx={{
                            ...customDialogButtonStyles
                        }}
                        onClick={() => {
                            setShowRemoveIdModal(false);
                        }}
                        variant="outlined"
                    >
                        <div
                            style={{
                                ...customDialogButtonTextStyles
                            }}
                        >
                            Cancel
                        </div>
                    </Button>
                    <Button
                        sx={{
                            ...customDialogButtonStyles
                        }}
                        variant="contained"
                        onClick={handleRemoveGtmId}
                    >
                        <div
                            style={{
                                ...customDialogButtonTextStyles
                            }}
                        >
                            Remove
                        </div>
                    </Button>
                </Box>
            </SmallSizedModal>
            <Grid container sx={{ flexDirection: "column", gap: "16px" }}>
                <TextField
                    label="GTM ID"
                    variant="outlined"
                    value={gtmId}
                    onChange={(e) => {
                        setGtmId(e.target.value);
                        setIdInputError("");
                    }}
                    sx={{
                        "& input::placeholder": {
                            fontSize: "14px"
                        }
                    }}
                    size="small"
                    placeholder="GTM-########"
                    disabled={gtmExists}
                    error={idInputError}
                    helperText={idInputError}
                />
                <Button
                    variant="contained"
                    sx={{ width: "80px" }}
                    onClick={() => {
                        if (gtmExists) {
                            setShowRemoveIdModal(true);
                        } else {
                            handleSaveGtmId();
                        }
                    }}
                >
                    {gtmExists ? "Remove" : "Add"}
                </Button>
            </Grid>
        </>
    );
};

export default GoogleTagManagerSetupSection;
