import React from "react";
import PersonLookup from "./PersonLookup";
import Snackbar from "@mui/material/Snackbar";
import {
    providerByCompany,
    searchProviderByLastName,
    searchByFirstName
} from "../queries/ListBookingsQueries";
import { execReadBySortkey } from "../modules/DBService";
export const ProviderLookup = (props) => {
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState();

    function isEmail(input) {
        return input && input.includes("@");
    }
    async function searchProviders(input) {
        let providers;
        let providernameinput = input.input;
        if (providernameinput) {
            if (isEmail(providernameinput)) {
                providers = await execReadBySortkey({
                    opname: "providerByCompany",
                    op: providerByCompany,
                    id: {
                        companyId: props.companyId
                    },
                    filter: {
                        and: [
                            { emailaddress: { beginsWith: providernameinput } },
                            { active: { ne: false } },
                            { deleted: { ne: true } }
                        ]
                    }
                });
                if (
                    providers &&
                    !providers.error &&
                    providers.items &&
                    providers.items.length
                ) {
                    providers.items.map((p) => {
                        p.name = `${p.firstname ? p.firstname : ""} ${
                            p.lastname ? p.lastname : ""
                        }`;
                    });
                    return providers.items;
                } else {
                    setSnackMsg("Provider is not found.");
                    setMsgOpen(true);
                    return [];
                }
            } else {
                let inputparts = providernameinput.split(" ");
                if (inputparts.length <= 2) {
                    let provByfn = await execReadBySortkey({
                        opname: "searchByFirstName",
                        op: searchByFirstName,
                        id: {
                            companyId: props.companyId
                        },
                        skey: {
                            lc_fn: {
                                beginsWith: inputparts[0]
                                    ? inputparts[0].toLowerCase()
                                    : ""
                            }
                        },
                        filter: {
                            and: [
                                { active: { ne: false } },
                                { deleted: { ne: true } }
                            ]
                        }
                    });
                    let provByLn = await execReadBySortkey({
                        opname: "searchProviderByLastName",
                        op: searchProviderByLastName,
                        id: {
                            companyId: props.companyId
                        },
                        skey: {
                            lc_ln: {
                                beginsWith: inputparts[0]
                                    ? inputparts[0].toLowerCase()
                                    : ""
                            }
                        },
                        filter: {
                            and: [
                                { active: { ne: false } },
                                { deleted: { ne: true } }
                            ]
                        }
                    });
                    let providerSet;
                    if (
                        provByfn &&
                        !provByfn.error &&
                        provByfn.items &&
                        provByfn.items.length
                    )
                        providerSet = new Set(
                            provByfn.items.map((p) => JSON.stringify(p))
                        );
                    //    console.log("ProviderSet1", providerSet);
                    if (providerSet) {
                        if (
                            provByLn &&
                            !provByLn.error &&
                            provByLn.items &&
                            provByLn.items.length
                        )
                            provByLn.items.forEach((p) => {
                                let pJson = JSON.stringify(p);
                                if (!providerSet.has(pJson)) {
                                    providerSet.add(pJson);
                                }
                            });
                    } else {
                        if (
                            provByLn &&
                            !provByLn.error &&
                            provByLn.items &&
                            provByLn.items.length
                        )
                            providerSet = new Set(
                                provByLn.items.map((p) => JSON.stringify(p))
                            );
                    }
                    //  console.log("ProviderSet2", providerSet);

                    if (providerSet) {
                        let jsonProviders = [...providerSet];
                        //    console.log("jsonProviders", jsonProviders);

                        providers = jsonProviders.map((pjson) =>
                            JSON.parse(pjson)
                        );
                    }
                    //   console.log("providers", providers);
                    if (providers && providers.length) {
                        providers.map((p) => {
                            p.name = `${p.firstname ? p.firstname : ""} ${
                                p.lastname ? p.lastname : ""
                            }`;
                        });
                        return providers;
                    } else {
                        setSnackMsg("Provider is not found.");
                        setMsgOpen(true);
                        return [];
                    }
                }
            }
        }
    }

    function setSelectedValue(prov) {
        props.setSelectedProvider(prov);
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={msgOpen}
                autoHideDuration={1000}
                onClose={() => setMsgOpen(false)}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{snackMsg}</span>}
            />
            <PersonLookup
                headTextFieldName="name"
                subTextFieldName="emailaddress"
                searchService={searchProviders}
                setSelectedValue={setSelectedValue}
                placeholder="Search Provider"
            />
        </>
    );
};
