import { FEATURE_NAME_CLUSTERING } from "../utils/Constants";

//the gated features for each subscription level
const gatedFeatures = {
    BASIC: [
        /* "Packages",
        "RecurringAppointments",
        "DisableSingleAppointments", */
        "RegionalPricing",
        "ProviderAcceptance",
        "Integrations",
        "PriceByLocation",
        FEATURE_NAME_CLUSTERING
    ]
    //add more subscription levels and their gated features here
};

// Function to check if a feature is gated based on subscription level
function isFeatureGated(subscriptionLevel, featureName) {
    const subscriptionFeatures = gatedFeatures[subscriptionLevel];
    return subscriptionFeatures
        ? subscriptionFeatures.includes(featureName)
        : false;
}

export const isOpenForClusters = (companyId) => {
    if (process.env.REACT_APP_OPENCLUSTER_FOR) {
        if (process.env.REACT_APP_OPENCLUSTER_FOR.includes(companyId))
            return true;
        else return false;
    }
    return true;
};
export default isFeatureGated;
