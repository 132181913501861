import React, { Fragment } from "react";
import classes from "../../../../onboarding/styles/Navbar.module.css";
import logo from "../../../../onboarding/assets/mblogowhite.svg";
import { Button } from "@mui/material";

const Navbar = (props) => {
    return (
        <Fragment>
            <header
                className={
                    props.width < 900 ? classes.header_mobile : classes.header
                }
            >
                <img
                    className={classes.logo}
                    src={logo}
                    alt="MarketBox Onboarding"
                />
                <Button
                    style={{
                        background: "rgba(0,0,0,0.5)",
                        marginRight: "28px"
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                        (window.location = process.env.REACT_APP_AUTH_SITE_URL)
                    } // "http://localhost:3000";}
                >
                    Log In
                </Button>
            </header>
        </Fragment>
    );
};

export default Navbar;
