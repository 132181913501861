import { useState, useEffect } from "react";
import { promoSettingsByCompany } from "../../graphql/queries";
import { execReadBySortkey } from "../../modules/DBService";
export function usePromosList(initialList, companyId) {
    const [promoList, setPromoList] = useState(initialList);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [uniquePromoCodes, setUniquePromoCodes] = useState();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchPromos();
    }, []);
    useEffect(() => {
        if (refresh) {
            setRefresh(false);
            fetchPromos();
        }
    }, [refresh]);
    const fetchPromos = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            let promos = await execReadBySortkey({
                opname: "promoSettingsByCompany",
                op: promoSettingsByCompany,
                id: {
                    companyId: companyId
                },
                skey: {
                    recordTypeId: {
                        beginsWith: {
                            recordType: "SETTINGS"
                        }
                    }
                },
                filter: {
                    terminated: {
                        ne: true
                    }
                }
            });
            const uniquePromoCodesSet = new Set();
            for (let pr of promos.items) {
                uniquePromoCodesSet.add(pr.promocode);
                let details = JSON.parse(pr.Details);
                if (details) for (let pro in details) pr[pro] = details[pro];
                console.log(pr, details);
            }
            if (promos?.items && promos.items.length) {
                promos.items = promos.items.sort((p1, p2) => {
                    if (p1.createdAt < p2.createdAt) return 1;
                    else return -1;
                });
            }
            setPromoList(promos.items);
            setUniquePromoCodes(uniquePromoCodesSet);
        } catch (error) {
            setIsError(true);
        }

        setIsLoading(false);
    };
    return { promoList, isLoading, isError, uniquePromoCodes, setRefresh };
}
