import { atom } from "jotai";
import { defaultBrandingStates, defaultDomainStates, defaultBookingStates, defaultBookingFlowSettingsFiles } from "../utils/Constants";

export const brandingStatesAtom = atom(defaultBrandingStates);
export const bookingStatesAtom = atom(defaultBookingStates);
export const domainStatesAtom = atom(defaultDomainStates);

export const desiredSubdomainAtom = atom("");

//atom to know which initial tab to display when company settings is loaded (for users coming from dashboard to booking flow settings tab)
export const CompanySettingsInitialTabAtom = atom(0);

export const bookingFlowSettingsFilesAtom = atom(defaultBookingFlowSettingsFiles);