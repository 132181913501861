import { useState, useEffect } from "react";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { graphql, graphqlOperation, postApi } from "../modules/AmplifyServices";
import { userHasMarketboxAdminRole } from "../user/UserCommon";
import "./../styles/IntegrationPageStyles.css";
import { Button } from "@mui/material";
import UserProfileModal from "../user/components/UserProfileModal";
import { ThemeProvider } from "@mui/material/styles";
import { DialogTheme } from "../styles/DialogTheme";
import { mbxUserAtom } from "../atoms/atoms";
import { useAtom } from "jotai";

function IntegrationsPage() {
    const [mbxUser, setMbxUser] = useAtom(mbxUserAtom);
    const [clients, setClients] = useState([]);
    const [key, setKey] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [overrideValue, setOverrideValue] = useState("");
    const [formData, setFormData] = useState({
        firstName: "Jow3",
        lastName: "Swanson3",
        email: "omar+stripe256T3@gomarketbox.com",
        phone: "14168360759",
        companyName: "Stripe Test Company 3",
        subdomain: "stripecomp112.gomarketbox.com"
    });
    const [customer, setCustomer] = useState("");

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: "stripe"
    };

    let [message, setMessage] = useState("");
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setSuccess(true);
            setSessionId(query.get("session_id"));
        }

        if (query.get("canceled")) {
            setSuccess(false);
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, [sessionId]);
    //const stripe = Stripe("pk_test_FS3LzD3Sj2kkDFuTyMFx0B5p005Fm1iYqp");

    const Logo = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="14px"
            height="16px"
            viewBox="0 0 14 16"
            version="1.1"
        >
            <defs />
            <g
                id="Flow"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="0-Default"
                    transform="translate(-121.000000, -40.000000)"
                    fill="#E184DF"
                >
                    <path
                        d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
                        id="Pilcrow"
                    />
                </g>
            </g>
        </svg>
    );

    const ProductDisplay = () => (
        <section>
            <div>
                <Logo />
                <div>
                    <h3>Professional plan</h3>
                    <h5>$49.00 / month</h5>
                </div>
            </div>
            <form onSubmit={handleSubmitSubscription} method="POST">
                {/* Add a hidden field with the lookup_key of your Price */}
                {/* <input
                    type="hidden"
                    name="lookup_key"
                    value="professional-plan-test"
                /> */}
                <button id="stripe-create-subscription" type="submit">
                    Checkout
                </button>
            </form>
        </section>
    );

    const SuccessDisplay = ({ sessionId }) => {
        return (
            <section>
                <div>
                    <Logo />
                    <div>
                        <h3>Subscription to starter plan successful!</h3>
                    </div>
                </div>
                <form action="/checkout-and-portal-button" method="POST">
                    <input
                        type="hidden"
                        id="session-id"
                        name="session_id"
                        value={sessionId}
                    />
                    <button id="checkout-and-portal-button" type="submit">
                        Manage your billing information
                    </button>
                </form>
            </section>
        );
    };

    const Message = ({ message }) => (
        <section>
            <p>{message}</p>
        </section>
    );

    //FOR STRIPE PAYMENT ELEMENT
    /* useEffect(() => {
        setStripePromise(
            loadStripe("pk_test_FS3LzD3Sj2kkDFuTyMFx0B5p005Fm1iYqp")
        );
    }, []); */

    //FOR STRIPE PAYMENT ELEMENT
    // function CheckoutForm() {
    //     const stripe = useStripe();
    //     const elements = useElements();

    //     const [message, setMessage] = useState(null);
    //     const [isProcessing, setIsProcessing] = useState(false);

    //     const handleSubmit = async (e) => {
    //         e.preventDefault();

    //         if (!stripe || !elements) {
    //             // Stripe.js has not yet loaded.
    //             // Make sure to disable form submission until Stripe.js has loaded.
    //             return;
    //         }

    //         setIsProcessing(true);

    //         const { error } = await stripe.confirmPayment({
    //             elements,
    //             confirmParams: {
    //                 // Make sure to change this to your payment completion page
    //                 return_url: `http://localhost:3000/zap`
    //             }
    //         });

    //         if (
    //             error.type === "card_error" ||
    //             error.type === "validation_error"
    //         ) {
    //             setMessage(error.message);
    //         } else {
    //             setMessage("An unexpected error occured.");
    //         }

    //         setIsProcessing(false);
    //     };

    //     return (
    //         <form
    //             id="payment-form"
    //             onSubmit={handleSubmit}
    //             style={{ maxWidth: "530px" }}
    //         >
    //             <PaymentElement
    //                 id="payment-element"
    //                 className="custom-payment-element"
    //             />
    //             <button
    //                 disabled={isProcessing || !stripe || !elements}
    //                 id="submit"
    //             >
    //                 <span id="button-text">
    //                     {isProcessing ? "Processing ... " : "Pay now"}
    //                 </span>
    //             </button>
    //             {/* Show any error or success messages */}
    //             {message && <div id="payment-message">{message}</div>}
    //         </form>
    //     );
    // }

    /*  "https://hooks.zapier.com/hooks/standard/14881745/de3c3b3dd1054e41b77b1060e10149db/" */
    function invokeTrigger(client) {
        window
            .fetch(
                "https://hooks.zapier.com/hooks/standard/14881745/0eb35149567b4323a85ca77a2d0cd3e1/",
                {
                    mode: "no-cors",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(client)
                }
            )
            .then((response) => {
                console.log("Performed hook for client", client);
                setClients((prevClients) => [...prevClients, client]);
            })
            .catch((error) => {
                console.error(
                    "Error performing hook for client",
                    client,
                    error
                );
            });
    }

    function triggerManager() {
        const client = {
            id: 122,
            firstname: "Omar122",
            lastname: "Tahir122",
            email: "example122@example.com",
            createdAt: "04/12/2023"
        };
        invokeTrigger(client);
    }

    //(1) generate a unique secret key
    //(2) createRefData record for company/client_id
    async function handleGenerateSecretKey() {
        let key = "";
        const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        for (let i = 0; i < 15; i++) {
            if (i % 5 === 0 && i !== 0) {
                key += "-";
            }
            const randomIndex = Math.floor(Math.random() * characters.length);
            key += characters[randomIndex];
        }
        //console.log("key: ", key);
        setKey(key);

        //let refVal = exists.data.getRefData ? exists.data.getRefData.refValue : null;
        //if (refVal == null) {
    }

    async function createRefDataRecord() {
        try {
            await graphql(
                graphqlOperation(mutations.createRefData, {
                    input: {
                        refType: `integration|2486c729-f4d1-48b0-90cd-794bd5ecd9ce`,
                        refName: "data-integration",
                        refValue: "integration",
                        overrideValue: JSON.stringify({
                            secretkey: "FGDOA-FK68H-UDSAH"
                        })
                    }
                })
            );
            console.log("successfully created ref data");
            setErrorMessage("successfully created RefData");
        } catch (e) {
            console.log("error in creating ref data", e);
            setErrorMessage("ERROR: couldnt created RefData");
        }
    }

    async function getRefDataRecord() {
        try {
            const exists = await graphql(
                graphqlOperation(queries.getRefData, {
                    refType: `integration|c6fd1dc0-7128-4f09-a13f-d8dee564feae`,
                    refName: "data-integration"
                })
            );
            console.log("exists", exists);
            setOverrideValue(JSON.parse(exists.data.getRefData.overrideValue));
            console.log("overrideValue", overrideValue);
        } catch (e) {
            console.log("error in getting ref data", e);
        }
    }

    async function updateRefDataRecord() {
        /* const newOverrideValue = {
            secretkey: "12345-67891-23456xxx",
            hooks: {
                "new-client-zapier": [
                    {
                        url: "123123123123123123",
                        createdAt: "2022-01-03T20:27:07.093Z"
                    },
                    {
                        url: "456456456456456456",
                        createdAt: "2022-01-03T20:27:07.093Z"
                    }
                ],
                "new-booking-zapier": [
                    {
                        url: "78978978978789789",
                        createdAt: "2022-01-03T20:27:07.093Z"
                    },
                    {
                        url: "147147147147147147",
                        createdAt: "2022-01-03T20:27:07.093Z"
                    }
                ]
            }
        }; */

        const newOverrideValue = {
            secretkey: "FGDOA-FK68H-UDSAH",
            hooks: {}
        };

        /* const newOverrideValue = {
            secretkey: overrideValue.secretkey,
            hooks: overrideValue.hooks
        }; */

        try {
            await graphql(
                graphqlOperation(mutations.updateRefData, {
                    input: {
                        refType: `integration|2486c729-f4d1-48b0-90cd-794bd5ecd9ce`,
                        refName: "data-integration",
                        refValue: "integration",
                        overrideValue: JSON.stringify(newOverrideValue)
                    }
                })
            );
            console.log("successfully updated refData");
            console.log("overrideValue", overrideValue);
        } catch (e) {
            console.log("error in updating ref data", e);
        }
    }

    async function addHooks() {
        let hook_name = "new-client-zapier";

        //check to see if a hook with the hook_name exists
        if (overrideValue.hooks && overrideValue.hooks[hook_name]) {
            console.log(
                "found hook name!",
                hook_name,
                overrideValue.hooks[hook_name]
            );
            const newHook = {
                url: "2222222222222222222222222",
                createdAt: "2023-04-27T12:00:00.000Z"
            };
            overrideValue.hooks[hook_name].push(newHook);
            console.log(overrideValue.hooks[hook_name]);

            updateRefDataRecord();
        } else {
            //if not then create new hook object with hook_name and add url
            console.log("unable to find hook", hook_name);
            overrideValue.hooks[hook_name] = [];
            const newHook = {
                url: "1111111111111111111111111",
                createdAt: "2023-04-27T12:00:00.000Z"
            };
            overrideValue.hooks[hook_name].push(newHook);
            console.log(overrideValue.hooks[hook_name]);

            updateRefDataRecord();
        }
    }

    async function removeHooks() {
        let hook_name = "new-client-zapier";
        let hook_url = "1111111111111111111111111";

        //check to see if a hook with the hook_name exists
        if (overrideValue.hooks && overrideValue.hooks[hook_name]) {
            console.log(
                "found hook name!",
                hook_name,
                overrideValue.hooks[hook_name]
            );
            let hook = overrideValue.hooks[hook_name];
            for (let i = 0; i < hook.length; i++) {
                if (hook[i].url === hook_url) {
                    hook.splice(i, 1);
                    break;
                }
                if (i == hook.length - 1) {
                    console.log("url does not exist for hook ", hook_name);
                    return;
                }
            }
            console.log(overrideValue.hooks[hook_name]);
            updateRefDataRecord();
        } else {
            //if not then create new hook object with hook_name and add url
            console.log("unable to find hook", hook_name);
        }
    }

    async function callHubspotAPI() {
        try {
            let hubspotContactResponse;
            let body = {
                companyName: "localhostCompany1",
                lastname: "Bill",
                firstname: "Burr",
                emailaddress: "omar+dw395t2@gomarketbox.com",
                phoneNumber: "+14168360759",
                numberOfProviders: "1"
            };
            //if (hubspotContact) body = { ...body, id: hubspotContact.id }; //update existing hubspot contact
            hubspotContactResponse = await postApi("hubspotapi", "/contacts", {
                body
            });

            if (hubspotContactResponse && hubspotContactResponse.data)
                //setHubspotContact(hubspotContactResponse.data);
                console.log(
                    "Hubspot Contact response data",
                    hubspotContactResponse.data
                );
        } catch (e) {
            console.log("error while creating hubspot contact", e);
        }
    }

    //FOR STRIPE PAYMENT ELEMENT
    // async function handleInputChange(event) {
    //     setFormData({
    //         ...formData,
    //         [event.target.name]: event.target.value
    //     });
    // }

    async function handleSubmitSubscription(event) {
        event.preventDefault();
        // Call your function or API endpoint here with formData
        //console.log(formData);

        try {
            let stripeClientResponse;
            let stripeSubscriptionResponse;
            let body = {
                type: "hidden",
                name: "lookup_key",
                value: "professional-plan-test"
            };

            stripeClientResponse = await postApi(
                "stripesavecard",
                "/stripe-create-subscription",
                {
                    body
                }
            );

            console.log(
                "Stripe response data",
                stripeClientResponse,
                stripeClientResponse.response.stripeUrl
            );

            window.location.href = stripeClientResponse.response.stripeUrl;
        } catch (e) {
            console.log("error while creating mb stripe customer", e);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        // Call your function or API endpoint here with formData
        //console.log(formData);

        try {
            let stripeClientResponse;
            let stripeSubscriptionResponse;
            let body = {
                /* firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                companyName: formData.companyName */
                type: "hidden",
                name: "lookup_key",
                value: "professional-plan-test"
            };
            /* stripeClientResponse = await postApi(
                "stripesavecard",
                "/stripe-create-customer",
                {
                    body
                }
            ); */
            stripeClientResponse = await postApi(
                "stripesavecard",
                "/stripe-create-subscription",
                {
                    body
                }
            );

            //FOR STRIPE PAYMENT ELEMENT
            /* if (stripeClientResponse && stripeClientResponse.data) */
            //setHubspotContact(stripeClientResponse.data);
            console.log(
                "Stripe response data",
                stripeClientResponse,
                stripeClientResponse.response
            );

            window.location.href = stripeClientResponse.response.stripeUrl;
            /* if (
                JSON.parse(stripeClientResponse.response.body).customer.length >
                0
            ) {
                setCustomer(
                    JSON.parse(stripeClientResponse.response.body).customer[0]
                );
            } else {
                setCustomer(
                    JSON.parse(stripeClientResponse.response.body).customer
                );
            } */
            //if the user is already a Stripe customer, then pass through .customer[0].id for the most recent customer profile
            //if its a new customer then pass through .customer.id
            /* stripeSubscriptionResponse = await postApi(
                "stripesavecard",
                "/stripe-create-subscription",
                {
                    body: {
                        priceId: "price_1NQE1nKe64FlZuVoMm09t4zl",
                        customerId:
                            JSON.parse(stripeClientResponse.response.body)
                                .customer.length > 0
                                ? JSON.parse(stripeClientResponse.response.body)
                                      .customer[0].id
                                : JSON.parse(stripeClientResponse.response.body)
                                      .customer.id
                    }
                }
            ); */
            /* setClientSecret(
                JSON.parse(stripeSubscriptionResponse.response.body)
                    .clientSecret
            );
            console.log(
                "Stripe response subscription data",
                stripeSubscriptionResponse,
                JSON.parse(stripeSubscriptionResponse.response.body)
            ); */
        } catch (e) {
            console.log("error while creating mb stripe customer", e);
        }
    }

    async function handleCreateRecurringBookings() {
        try {
            /* let body = {
                type: "hidden",
                name: "lookup_key",
                value: props.plan,
                planType: props.planType,
                quantity: props.quantity,
                email: sessionStorage.getItem("email")
                    ? sessionStorage.getItem("email")
                    : props.email,
                companyData: JSON.stringify(companyData),
                MBSessionId: userInputContext.sessionId
            }; */
            let recurBookingsResponse;
            let body = {
                message: "Oh I would like some recurring bookings plz"
            };

            recurBookingsResponse = await postApi(
                "recurringBookingsApi",
                "/create-recurring-bookings",
                {
                    body
                }
            );

            console.log("Stripe response data", recurBookingsResponse);
        } catch (e) {
            console.log("error while creating recurring bookings", e);
        }
    }
    const [openUserDialog, setOpenUserDialog] = useState(false);

    const handleOpenUserDialog = () => {
        setOpenUserDialog(true);
    };

    const handleCloseUserDialog = () => {
        setOpenUserDialog(false);
    };

    return (
        <div>
            {/* {userHasMarketboxAdminRole() && ( */}
            <div>
                <h2>Integration refData stuff</h2>
                <button onClick={triggerManager}>Trigger Zapier Hook</button>
                <div>
                    clientID: 2486c729-f4d1-48b0-90cd-794bd5ecd9ce <br />
                </div>
                <div>Generated Secret key: {key} FGDOA-FK68H-UDSAH </div>
                <button onClick={handleGenerateSecretKey}>
                    Generate Secret
                </button>
                <button onClick={createRefDataRecord}>Create Ref Data</button>
                <button onClick={getRefDataRecord}>Get Ref Data</button>
                <button onClick={updateRefDataRecord}>Update Ref Data</button>
                <button onClick={addHooks}>Subscribe Hook</button>
                <button onClick={removeHooks}>Unsubscribe Hook</button>
                {clients.length > 0 && (
                    <ul>
                        {clients.map((client, index) => (
                            <li key={index}>{JSON.stringify(client)}</li>
                        ))}
                    </ul>
                )}
                <div>{errorMessage}</div>
                <div>{overrideValue.secretkey}</div>
                <br />
                <br />
                <h2>Hubspot API stuff</h2>
                <button onClick={callHubspotAPI}>Call Hubspot API</button>

                <br />
                <br />
                <br />
                <h2>MB Selfserve/Stripe API stuff</h2>
                {!success && message === "" && <ProductDisplay />}
                {success && sessionId !== "" && (
                    <SuccessDisplay sessionId={sessionId} />
                )}
                <Message message={message} />

                <br />
                <br />
                <br />
                <h2>Recurring Bookings API</h2>
                <button onClick={handleCreateRecurringBookings}>
                    /create-recurring-bookings
                </button>

                <br />
                <br />
                <br />
                <h2>{`New User Profile Dialog (MBX-25)`}</h2>
                <Button variant="outlined" onClick={handleOpenUserDialog}>
                    Add User
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        handleOpenUserDialog();
                    }}
                >
                    Edit User
                </Button>
                <ThemeProvider theme={DialogTheme}>
                    <UserProfileModal
                        open={openUserDialog}
                        onClose={handleCloseUserDialog}
                    />
                </ThemeProvider>

                {/* FOR STRIPE PAYMENT ELEMENT */}
                {/* <form onSubmit={handleSubmit}>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gridGap: "12px",
                                width: "600px"
                            }}
                        >
                            <TextField
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Email Address"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Phone Number"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                            <TextField
                                label="Company Name"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                            <TextField
                                label="Subdomain"
                                name="subdomain"
                                value={formData.subdomain}
                                onChange={handleInputChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                        </div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>
                    </form>
                    {stripePromise && clientSecret && (
                        <div>
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    clientSecret,
                                    appearance,
                                    style: { height: "100%" }
                                }}
                            >
                                <CheckoutForm />
                            </Elements>
                        </div>
                    )} */}
            </div>
            {/* )}
            {!userHasMarketboxAdminRole() && <div>private page</div>} */}
        </div>
    );
}

export default IntegrationsPage;
