import {
    Box,
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import bookingFlowAcctRatio from "../../images/bookingFLowAcceptRatio.png";
import ImageTooltip from "../../utils/UI/ImageTooltip";

const bookingIncrementsOptions = [5, 10, 15, 20, 30, 45, 60];

function BookingTab({
    styles,
    bookingStates,
    bookingStatesChangeHandler,
    bookingFlowSettingsFiles,
    showTos,
    oldTosURL,
    openCloseTosHandler,
    currentTosFile,
    mode
}) {
    useEffect(() => {
        console.log("Old TOS URL useEffect", oldTosURL);
    }, [oldTosURL]);

    const uniqueQuery = `?t=${Date.now()}`; // or use any other unique identifier
    const pdfUrlWithQuery = bookingStates.tosURL + uniqueQuery;

    const openNewTab = () => {
        window.open(
            "https://www.gomarketbox.com/help-articles/how-to-change-services-page",
            "_blank"
        );
    };
    return (
        <>
            <Dialog fullScreen open={showTos} maxWidth="md">
                <CardMedia
                    component="iframe"
                    style={{ height: "100vh", scrolling: "no" }}
                    src={(mode === "Add" && bookingFlowSettingsFiles.tosFile) ? URL.createObjectURL(bookingFlowSettingsFiles.tosFile) : pdfUrlWithQuery}
                    scrolling="no"
                ></CardMedia>
                <DialogActions>
                    <Button
                        onClick={(e) => {
                            openCloseTosHandler("close");
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                <Typography sx={styles.heading}>
                    Category display{" "}
                    <span
                        style={{
                            color: "rgba(0,0,0,0.5)",
                            fontFamily: "Roboto"
                        }}
                    >
                        <Tooltip
                            title="
                                Choose how you would like your categories displayed on your booking page.
                                "
                            placement="right"
                        >
                            <InfoIcon
                                sx={{
                                    marginLeft: "0.5rem",
                                    height: "1.5rem",
                                    color: "rgba(0,0,0,0.5)"
                                }}
                            />
                        </Tooltip>
                    </span>
                </Typography>

                <Select
                    sx={{ ...styles.selectField }}
                    id="category-display-field"
                    label="Category Display"
                    variant="filled"
                    disableUnderline
                    fullWidth
                    name={"categoryDisplay"}
                    value={bookingStates.categoryDisplay}
                    InputProps={{ shrink: false }}
                    onChange={bookingStatesChangeHandler}
                >
                    <MenuItem
                        key={"ALLOPEN"}
                        value={"ALLOPEN"}
                    >{`All categories open`}</MenuItem>

                    <MenuItem
                        key={"FIRSTOPEN"}
                        value={"FIRSTOPEN"}
                    >{`Only first category open`}</MenuItem>

                    <MenuItem
                        key={"ALLCLOSED"}
                        value={"ALLCLOSED"}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}
                    >
                        <Typography>All categories closed</Typography>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: "rgba(0, 0, 0, 0.6)"
                            }}
                        >
                            We recommend turning this on for businesses offering
                            more than 20 services across multiple categories
                        </Typography>
                    </MenuItem>
                </Select>
            </Box>
            <Box sx={{ ...styles.container, gap: "2rem" }}>
                <Box
                    sx={{
                        ...styles.container,
                        gap: "0.5rem",
                        marginTop: "2rem"
                    }}
                >
                    <Typography sx={styles.heading}>
                        Services display{" "}
                        <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip
                                title="
                                Choose how to visually display your services on your booking page.
                                "
                                placement="right"
                            >
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </Typography>

                    <Select
                        sx={{ ...styles.selectField }}
                        id="services-display-field"
                        label="Services Display"
                        variant="filled"
                        disableUnderline
                        fullWidth
                        name={"servicesDisplay"}
                        value={bookingStates.servicesDisplay}
                        onChange={bookingStatesChangeHandler}
                        InputProps={{ shrink: false }}
                    >
                        <MenuItem key={"LIST"} value={"LIST"}>
                            List style (see example&nbsp;
                            <span
                                style={{
                                    fontFamily: "Roboto",
                                    textDecoration: "underline"
                                }}
                                onClick={openNewTab}
                            >
                                here
                            </span>
                            )
                        </MenuItem>

                        <MenuItem key={"CARD"} value={"CARD"}>
                            Card style (see example&nbsp;
                            <span
                                style={{
                                    fontFamily: "Roboto",
                                    textDecoration: "underline"
                                }}
                                onClick={openNewTab}
                            >
                                here
                            </span>
                            )
                        </MenuItem>
                    </Select>
                </Box>

                <Box sx={{ ...styles.container, gap: "2rem" }}>
                    <Box
                        sx={{
                            ...styles.container,
                            gap: "0.5rem"
                        }}
                    >
                        <Typography sx={styles.heading}>
                            Service prices{" "}
                            <span
                                style={{
                                    color: "rgba(0,0,0,0.5)",
                                    fontFamily: "Roboto"
                                }}
                            >
                                {/*  <Tooltip
                                    title="
                                Choose to show the prices of each of your services.
                                "
                                    placement="right"
                                >
                                    <InfoIcon
                                        sx={{
                                            marginLeft: "0.5rem",
                                            height: "1.5rem",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    />
                                </Tooltip> */}
                            </span>
                        </Typography>

                        <Select
                            sx={{ ...styles.selectField }}
                            id="services-prices-field"
                            label="Service Price"
                            variant="filled"
                            disableUnderline
                            fullWidth
                            name={"displayServicePrices"}
                            value={bookingStates.displayServicePrices}
                            onChange={bookingStatesChangeHandler}
                            InputProps={{ shrink: false }}
                        >
                            <MenuItem key={"show"} value={true}>
                                Show prices on step 1 of booking flow
                            </MenuItem>
                            <MenuItem key={"d-show"} value={false}>
                                Don’t show prices on step 1 of booking flow
                            </MenuItem>
                        </Select>
                    </Box>
                </Box>

                <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        Customer accounts
                        <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip
                                title="
                                How would you like your customers to checkout when completing their purchase?
                                "
                                placement="right"
                            >
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </Typography>

                    <Select
                        sx={{ ...styles.selectField }}
                        id="customer-accounts-field"
                        label="Customer accounts"
                        variant="filled"
                        disableUnderline
                        fullWidth
                        name={"checkoutOption"}
                        value={bookingStates.checkoutOption}
                        onChange={bookingStatesChangeHandler}
                        InputProps={{ shrink: false }}
                    >
                        <MenuItem
                            key={"ACCOUNT_OR_GUEST"}
                            value={"ACCOUNT_OR_GUEST"}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start"
                            }}
                        >
                            <Typography>Accounts are optional</Typography>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: "rgba(0, 0, 0, 0.6)"
                                }}
                            >
                                Customers will have the option to checkout as a
                                guest or with a customer account. Guests can
                                also convert to a customer account
                                post-transaction.
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            key={"MUST_CREATE_ACCOUNT"}
                            value={"MUST_CREATE_ACCOUNT"}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start"
                            }}
                        >
                            <Typography>Accounts are required</Typography>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: "rgba(0, 0, 0, 0.6)"
                                }}
                            >
                                Customers will only be able to check out if they
                                have a customer account.
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            key={"GUEST_ONLY"}
                            value={"GUEST_ONLY"}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start"
                            }}
                        >
                            <Typography>Accounts are disabled</Typography>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: "rgba(0, 0, 0, 0.6)"
                                }}
                            >
                                Customers will only be able to check out as
                                guests. This option is not recommended if you
                                sell packages.
                            </Typography>
                        </MenuItem>
                    </Select>
                </Box>

                <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        Booking increments (minutes){" "}
                        <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip
                                title="
                                Set up the increments for available appointment times.
                                "
                                placement="right"
                            >
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </Typography>

                    <Select
                        sx={{ ...styles.selectField }}
                        id="booking-increment-field"
                        label="Booking Increments (minutes)"
                        variant="filled"
                        disableUnderline
                        fullWidth
                        name={"bookingIncrements"}
                        value={bookingStates.bookingIncrements}
                        onChange={bookingStatesChangeHandler}
                        InputProps={{ shrink: false }}
                    >
                        {bookingIncrementsOptions.map((minutes) => (
                            <MenuItem
                                key={minutes}
                                value={+minutes}
                            >{`${minutes}`}</MenuItem>
                        ))}
                    </Select>

                    {/* <TextField
                        fullWidth
                        variant="filled"
                        sx={styles.textField}
                        InputProps={{
                            disableUnderline: true
                        }}
                        label="Booking Increments (minutes)"
                    /> */}
                </Box>

                <Box sx={{ ...styles.container, gap: "0.5rem" }}>
                    <Typography sx={styles.heading}>
                        No available provider message{" "}
                        <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip
                                title="This message will be appear to your clients on your booking flow when there are no available provider in their area."
                                placement="right"
                            >
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        sx={{
                            ...styles.textField,
                            "& .MuiFilledInput-root.MuiInputBase-root": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        InputProps={{
                            disableUnderline: true
                        }}
                        multiline
                        rows={2}
                        label="No Available Provider Message"
                        name={"noProviderMessage"}
                        value={bookingStates.noProviderMessage}
                        onChange={bookingStatesChangeHandler}
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center"
                    }}
                >
                    <Typography sx={styles.heading}>
                        Display provider acceptance ratio
                    </Typography>
                    <Switch
                        checked={bookingStates.showProviderAcceptance}
                        name={"showProviderAcceptance"}
                        onChange={bookingStatesChangeHandler}
                    />
                    <ImageTooltip
                        content={
                            <img
                                src={bookingFlowAcctRatio}
                                width={200}
                                height={350}
                                alt={"Acceptance"}
                            />
                        }
                    >
                        <InfoIcon
                            sx={{
                                cursor: "pointer",

                                height: "1.5rem",
                                color: "rgba(0,0,0,0.5)"
                            }}
                        />
                    </ImageTooltip>
                </Box>

                <Divider sx={{ width: "100%" }} />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                    }}
                >
                    <Typography sx={styles.heading}>
                        Terms of service{" "}
                        <span
                            style={{
                                color: "rgba(0,0,0,0.5)",
                                fontFamily: "Roboto"
                            }}
                        >
                            <Tooltip
                                title="Upload your terms of service to your booking flow."
                                placement="right"
                            >
                                <InfoIcon
                                    sx={{
                                        marginLeft: "0.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0,0,0,0.5)"
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </Typography>

                    {/* <Typography sx={styles.uploadLinks}>Upload</Typography> */}
                    <div
                        style={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                            alignContent: "flex-start"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "baseline"
                            }}
                        >
                            <input
                                accept=".pdf"
                                id="tosURL"
                                style={{ display: "none" }}
                                type="file"
                                onChange={bookingStatesChangeHandler}
                                name="tosURL"
                            />
                            <label htmlFor="tosURL">
                                <FormControl>
                                    <Button
                                        sx={{
                                            padding: 0,
                                            //backgroundColor: "white",
                                            ...styles.uploadLinks,
                                            textTransform: "capitalize",
                                            "&:hover": {
                                                backgroundColor: "#f4f4f4"
                                            }
                                        }}
                                        variant="text"
                                        color="primary"
                                        component="span"
                                    >
                                        {bookingStates.tosURL > 0
                                            ? "Upload new TOS"
                                            : "Upload"}
                                    </Button>
                                </FormControl>
                            </label>

                            {currentTosFile && currentTosFile.name && (
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        color: "rgba(0,0,0,0.7)"
                                    }}
                                >
                                    {currentTosFile.name}
                                </Typography>
                            )}
                        </div>

                        {((mode === "Edit" && bookingStates.tosURL?.length > 0) || (mode === "Add" && bookingFlowSettingsFiles.tosFile)) && (
                            <Typography
                                sx={{
                                    padding: 0,
                                    backgroundColor: "#F4F4F4",
                                    textTransform: "capitalize",
                                    fontFamily: "Roboto",
                                    fontSize: "1rem",
                                    fontWeight: 700,
                                    cursor: "pointer"
                                }}
                                variant="text"
                                color="primary"
                                onClick={() => openCloseTosHandler("open")}
                            >
                                View current TOS
                            </Typography>
                        )}
                    </div>
                </Box>
            </Box>
        </>
    );
}

export default BookingTab;
