import React, { Component } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getServiceItemStyle, getServiceListStyle } from "./msoStyles";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));
const Services = (props) => {
    const { category, categoryNum } = props;
    const classes = useStyles();

    const theme = useTheme();

    return (
        <Droppable
            droppableId={`droppable${category.id}`}
            type={`${categoryNum}`}
        >
            {(provided, snapshot) => (
                <>
                    <div
                        ref={provided.innerRef}
                        style={getServiceListStyle(
                            snapshot.isDraggingOver,
                            theme
                        )}
                    >
                        {category.services.map((service, index) => {
                            return (
                                <Draggable
                                    key={`${categoryNum}${index}`}
                                    draggableId={`${categoryNum}${index}`}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getServiceItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                theme
                                            )}
                                        >
                                            <Typography
                                                className={classes.heading}
                                            >
                                                {service.name}
                                            </Typography>
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                </>
            )}
        </Droppable>
    );
};

export default Services;
