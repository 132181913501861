import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import throttle from "lodash/throttle";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { ChevronLeft } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "theme.palette.text.secondary",
        marginRight: theme.spacing(2)
    },
    autocomplete: {
        width: 320,
        [theme.breakpoints.down("sm")]: {
            width: 250
        }
    },
    inputRoot: (theme) => ({
        color: "#000"
    })
}));

export default function MBLookup(props) {
    const inputRef = React.useRef();
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const fetch = React.useMemo(
        () =>
            throttle(async (request, callback) => {
                let result = await props.searchService(request);
                callback(result);
            }, 1000),
        []
    );

    const customInput = {
        height: "56px"
    };

    React.useEffect(() => {
        inputRef.current.autocomplete = "off";
    }, []);

    React.useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputReady(inputValue) && !value)
            fetch({ input: inputValue }, (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    function inputReady(inp) {
        if (inp) {
            inp = inp.trimStart();
            if (inp.length > 3) return true;
        }
        return false;
    }

    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) => option[props.headTextFieldName]}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            openOnFocus
            freeSolo
            value={value}
            onChange={(event, newValue) => {
                console.log("New Value");
                console.log(newValue);
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                props.setSelectedValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                console.log(newInputValue);
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search by name or email"
                    variant="outlined"
                    fullWidth
                    placeholder={props.placeholder}
                    margin="normal"
                    inputRef={inputRef}
                    InputProps={{
                        ...params.InputProps,
                        style: customInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            )}
            renderOption={(propsOp, option) => {
                console.log("option");
                console.log(option);
                //console.log("state");
                //console.log(state);
                console.log("props");
                console.log(propsOp);
                return (
                    <li {...propsOp}>
                        <Grid key={option.id} container alignItems="center">
                            <Grid item>
                                <PersonIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                <span
                                    style={{
                                        fontWeight: 700
                                    }}
                                >
                                    {option[props.headTextFieldName]}
                                </span>

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {option[props.subTextFieldName]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
