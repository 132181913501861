import { graphql, graphqlOperation } from "./AmplifyServices";
import * as scheduleQueries from "./ScheduleQueries";
import { execReadBySortkey } from "./DBService";
import { utcToZonedTime, format } from "date-fns-tz";
import moment from "moment";
import {
    parseRRule,
    prepareBookingsDateTimeListForReschedule
} from "./RecurringBookingsUtilityFunctions";

const SLOTSINADAY = 288;
const SLOTUNITMINS = 5;
const SLOTSINHOUR = 12;
const SCHEDPKSKSPLITAT = "::";
const NOTRAVELTIMERADIUS = 1000; //in meters
const EARTHRADIUS = 6371e3; //in meters
const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DEFAULT_STARTTIME = "00:00";
const DEFAULT_ENDTIME = "23:55";
const DEFAULT_WEEKDAYS = "0123456";

function getEndingDate(sd, nd) {
    const ed = awsDateToJsDate(sd);
    ed.setDate(ed.getDate() + nd);
    return getAWSDate(ed);
}

async function getLocationTimezone(scheduleinfo) {
    const parts = scheduleinfo.split("|");
    const locationId = parts[1].slice(3);
    if (scheduleinfo && scheduleinfo.includes("|PL-")) {
        const providerLocation = await getProviderLocation(locationId);
        if (providerLocation) {
            return providerLocation.timezone;
        }
    }
    if (scheduleinfo && scheduleinfo.includes("|CL-")) {
        const companyLocation = await getCompanyLocation(locationId);
        if (companyLocation) {
            return companyLocation.timezone;
        }
    }
    return BROWSER_TZ;
}

const getProviderLocation = async (id) => {
    const data = await graphql(
        graphqlOperation(
            `query GetProviderLocation($id: ID!) {
        getProviderLocation(id: $id) {
          id
          name 
          timezone
        }
      }`,
            {
                id
            }
        )
    );
    return data.data.getProviderLocation;
};

const getCompanyLocation = async (id) => {
    const data = await graphql(
        graphqlOperation(
            `query GetCompanyLocation($id: ID!) {
          getCompanyLocation(id: $id) {
          id
          locationname 
          timezone
        }
      }`,
            {
                id
            }
        )
    );
    return data.data.getCompanyLocation;
};

/* Checks if the selectedDate & selectedTime is within a list of unavailable timeblocks*/
/* returns true if selectedDate & selectedTime is during a blocked slot */
async function isBlockedTime(timeblockList, selectedDate, selectedTime) {
    // console.log(
    //     "omar this is the selectedDate and selectedTime",
    //     selectedDate,
    //     selectedTime
    // );
    try {
        const unavailableSlots = timeblockList;
        const selectedDateISO = new Date(selectedDate);

        for (let i = 0; i < unavailableSlots.length; i++) {
            if (
                //first, check if the selected date is within any of the unavailable time blocks dates
                selectedDate >= unavailableSlots[i].startDate &&
                selectedDate <= unavailableSlots[i].endDate
            ) {
                //console.log("date IS WITHIN the block");
                if (
                    //second, check if the selected time is within the unavailable time block times
                    selectedTime >= unavailableSlots[i].startTime &&
                    selectedTime <= unavailableSlots[i].endTime
                ) {
                    //console.log("YES this is WITHIN THE TIME");

                    if (unavailableSlots[i].weekDays) {
                        let dayOfWeek = selectedDateISO.getDay();
                        /* if (dayOfWeek === 6) {
                            dayOfWeek = 0;
                        } else {
                            dayOfWeek++;
                        } */
                        if (
                            //third, check if the date selected day is a selected repeated day in the unavailable timeblock
                            unavailableSlots[i].weekDays.includes(
                                String(dayOfWeek)
                            )
                        ) {
                            //console.log("YES this is ON a selected day");
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }
        }
        return false;
    } catch (e) {
        console.log("ERROR isBlockTime: ", e);
    }
}

async function checkProviderAvailabilityForRecurringReschedule(
    bookingData,
    newBookingDateTimeDetails,
    originalRrule,
    rrule,
    rruleChanged,
    xoccurChanged
) {
    try {
        const pksk =
            bookingData.timeblockid &&
            bookingData.timeblockid.split(SCHEDPKSKSPLITAT);
        const bookingStartTime = moment(
            newBookingDateTimeDetails.tm_st_disp,
            "h:mm A"
        ).format("HH:mm");
        const bookingEndTime = moment(
            newBookingDateTimeDetails.tm_et_disp,
            "h:mm A"
        ).format("HH:mm");
        /* console.log(
            "omar this is from checkProviderAvailabilityForrecurring",
            bookingData,
            newBookingDateTimeDetails,
            originalRrule,
            rrule,
            rruleChanged,
            xoccurChanged,
            bookingStartTime,
            bookingEndTime
        ); */

        //Before we start, lets get all the remaining bookings for the order, this is used in multiple places so its better to get it now and so we only have to make the query once
        let apptsByOrder = await execReadBySortkey({
            opname: "bookingByOrder",
            op: bookingByOrder,
            id: { orderId: bookingData.order.id },
            skey: {
                startdateTimeblockid: {
                    ge: { startdate: bookingData.startdate }
                }
            },
            filter: { status: { eq: "SCHEDULED" } }
        });

        apptsByOrder = apptsByOrder.items;

        //First, get a list of all the booking dates based on the rrule, for the appropriate number of future months
        let bookingsDateTimeList =
            await prepareBookingsDateTimeListForReschedule(
                apptsByOrder,
                bookingData,
                newBookingDateTimeDetails.dtstamp_str,
                parseRRule(originalRrule).until,
                parseRRule(rrule).until,
                parseRRule(rrule),
                rruleChanged,
                xoccurChanged
            );
        //Second, fetch the provider ProviderSchedule BK records, and SBUs & SBs
        let bkRecordList = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: pksk[0] },
            skey: {
                scheduleinfo: {
                    between: [
                        `BK|${bookingsDateTimeList[0].dt_disp}`,
                        `BK|${
                            bookingsDateTimeList[
                                bookingsDateTimeList.length - 1
                            ].dt_disp
                        }`
                    ]
                }
            },
            filter: {
                status: { ne: "CANCELLED" }
            },
            limit: 500
        });
        bkRecordList = bkRecordList.items;

        /*We need to remove the bks from the bkRecordList , that pertain to this specific order, as those bk's should not be considered anymore*/
        const bookingScheduleIds = apptsByOrder.map(
            (booking) => booking.timeblockid.split("::")[1]
        );
        bkRecordList = bkRecordList.filter((bkRecord) => {
            const bkRecordScheduleId = bkRecord.scheduleinfo;
            return !bookingScheduleIds.includes(bkRecordScheduleId);
        });

        /*Get all provider availability and blocked times */
        let sbRecordList = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: pksk[0] },
            skey: { scheduleinfo: { beginsWith: "SB" } },
            filter: {
                active: { ne: false },
                deleted: { ne: true },
                endDate: { ge: moment().format("YYYY-MM-DD") }
            },
            limit: 500
        });
        sbRecordList = sbRecordList.items;

        let sbuRecordList = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: pksk[0] },
            skey: { scheduleinfo: { beginsWith: "BUT" } },
            filter: { active: { ne: false }, deleted: { ne: true } },
            limit: 500
        });
        sbuRecordList = sbuRecordList.items;

        //Third, compare the BK record startdate & starttime/endtime to each booking startdate & starttime/endtime
        //if any bookings are unavailable return false, otherwise return true
        if (bkRecordList) {
            for (const bookingDateObj of bookingsDateTimeList) {
                // Check if there are existing bookings on the same date
                const existingBookingsOnDate = bkRecordList.filter(
                    (bkRecord) => bkRecord.startDate === bookingDateObj.dt_disp
                );

                if (existingBookingsOnDate.length > 0) {
                    // Iterate through existing bookings on the same date
                    for (const existingBooking of existingBookingsOnDate) {
                        const existingStartTime = existingBooking.startTime;
                        const existingEndTime = existingBooking.endTime;

                        // Check for time overlap
                        if (
                            (bookingStartTime >= existingStartTime &&
                                bookingStartTime < existingEndTime) ||
                            (bookingEndTime > existingStartTime &&
                                bookingEndTime <= existingEndTime) ||
                            (bookingStartTime <= existingStartTime &&
                                bookingEndTime >= existingEndTime)
                        ) {
                            // Time overlap found, return false (booking not available)
                            return false;
                        }
                    }
                }
            }
        }

        //Fourth, check if all the booking dates land within the providers set availabilities (SBs)
        if (sbRecordList) {
            for (const bookingDateObj of bookingsDateTimeList) {
                const bookingDate = bookingDateObj.dt_disp;

                // Find the provider availability that matches the booking date
                const matchingAvailability = sbRecordList.find((sbRecord) => {
                    const startDate = moment(sbRecord.startDate);
                    const endDate = moment(sbRecord.endDate);
                    const bookingDateTime = moment(
                        `${bookingDate}T${bookingStartTime}`,
                        "YYYY-MM-DDTHH:mm"
                    );
                    const startTime = moment(
                        `${sbRecord.startDate}T${sbRecord.startTime}`,
                        "YYYY-MM-DDTHH:mm"
                    );
                    const endTime = moment(
                        `${sbRecord.endDate}T${sbRecord.endTime}`,
                        "YYYY-MM-DDTHH:mm"
                    );

                    // Check if the booking date is within the availability date range
                    const isDateWithinRange = bookingDateTime.isBetween(
                        startDate,
                        endDate,
                        null,
                        "[]"
                    );

                    // Check if the booking day is included in the provider's weekdays
                    const isWeekdayIncluded =
                        sbRecord.weekDays === "" ||
                        sbRecord.weekDays.includes(
                            bookingDateTime.day().toString()
                        );

                    // Check if the booking time is within the availability time range
                    const isTimeWithinRange =
                        bookingDateTime.isSameOrAfter(startTime) &&
                        bookingDateTime.isSameOrBefore(endTime);
                    return (
                        isDateWithinRange &&
                        isWeekdayIncluded &&
                        isTimeWithinRange
                    );
                });

                // If no matching availability is found, return false (booking not within provider availability)
                if (!matchingAvailability) {
                    return false;
                }
            }
        } else {
            //If there are no sbRecords then this means the provider has no set availability
            return false;
        }

        if (sbuRecordList) {
            //Fifth, check if all the booking dates land inside any provider blocked times
            for (const bookingDateObj of bookingsDateTimeList) {
                const bookingDate = bookingDateObj.dt_disp;

                // Find the provider blocked time that matches the booking date
                const matchingBlockedTime = sbuRecordList.find((sbuRecord) => {
                    const startDate = moment(sbuRecord.startDate);
                    const endDate = moment(sbuRecord.endDate);
                    const bookingDateTime = moment(
                        `${bookingDate}T${bookingStartTime}`,
                        "YYYY-MM-DDTHH:mm"
                    );
                    const startTime = moment(
                        `${sbuRecord.startDate}T${sbuRecord.startTime}`,
                        "YYYY-MM-DDTHH:mm"
                    );
                    const endTime = moment(
                        `${sbuRecord.endDate}T${sbuRecord.endTime}`,
                        "YYYY-MM-DDTHH:mm"
                    );

                    // Check if the booking date is within the blocked time date range
                    let isDateWithinRange = bookingDateTime.isBetween(
                        startDate,
                        endDate,
                        null,
                        sbuRecord.endDate === startDate ? "[)" : "[]"
                    );
                    if (
                        sbuRecord.startDate === sbuRecord.endDate &&
                        bookingDate === sbuRecord.startDate
                    ) {
                        isDateWithinRange = true;
                    }

                    // Check if the booking day is included in the provider's blocked weekdays
                    const isWeekdayIncluded =
                        sbuRecord.weekDays === "" ||
                        sbuRecord.weekDays.includes(
                            bookingDateTime.day().toString()
                        );

                    // Check if the booking time is within the blocked time range
                    const isTimeWithinRange =
                        bookingDateTime.isSameOrAfter(startTime) &&
                        bookingDateTime.isSameOrBefore(endTime);

                    /* console.log(
                        "omar this is a bunch of sbudata",
                        startDate,
                        endDate,
                        bookingDateTime,
                        startTime,
                        endTime,
                        isDateWithinRange,
                        isWeekdayIncluded,
                        isTimeWithinRange
                    ); */

                    return (
                        isDateWithinRange &&
                        isWeekdayIncluded &&
                        isTimeWithinRange
                    );
                });

                // If matching blocked time is found, return false (booking within provider blocked time)
                if (matchingBlockedTime) {
                    return false;
                }
            }
        }

        // If no interference is found, return true (booking available)
        return true;
    } catch (e) {
        console.log(
            "error while checking availability for rescheduling recurring bookings",
            e
        );
    }
}

const checkProviderAvailabilityForRechedule = async ({
    timezone,
    scheduleid,
    startdate,
    startTime,
    endTime,
    overriddenbookedslot
}) => {
    try {
        const pksk = !!scheduleid && scheduleid.split(SCHEDPKSKSPLITAT);
        const startingDate = startdate ? startdate : getNowDate();
        const locationTz = timezone;
        const bookedResp = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: pksk[0] },
            skey: {
                scheduleinfo: {
                    beginsWith: `BK|${startingDate}`
                }
            },
            filter: { status: { ne: "CANCELLED" } },
            limit: 500
        });

        if (bookedResp && bookedResp.items) {
            //eliminate the BOOKED slot that is being overridden, if present
            if (overriddenbookedslot)
                bookedResp.items = bookedResp.items.filter((booked) => {
                    if (
                        booked.id === overriddenbookedslot.id &&
                        booked.scheduleinfo ===
                            overriddenbookedslot.scheduleinfo
                    )
                        return false;
                    return true;
                });
            if (bookedResp.items?.length) {
                adjustGlobalBookingTimesForBookingLocationTimezone(
                    locationTz,
                    bookedResp.items
                );
                const daySlotMap = new Array(SLOTSINADAY).fill(1);
                //mark unavailability
                for (let b of bookedResp.items) {
                    const si = getIndexByTime(b.startTime);
                    const ei = getIndexByTime(b.endTime);
                    for (let s = si; s <= ei - 1; s++) {
                        daySlotMap[s] = 0;
                    }
                }
                const startIndex = getIndexByTime(startTime);
                const endIndex = getIndexByTime(endTime);
                let isAvailable = false;
                isAvailable = daySlotMap
                    .slice(startIndex, endIndex)
                    .every((v) => v === 1);
                return isAvailable;
            }
        }

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
};

function adjustGlobalBookingTimesForBookingLocationTimezone(
    locationTz,
    bookings
) {
    for (let b of bookings) {
        if (b.sdtutc && b.startTime && b.endTime) {
            const bookedMinutes = calculateBookedMinutes(
                b.startTime,
                b.endTime
            );
            const startDateUTC = new Date(b.sdtutc);
            const startDateInLocZone = utcToZonedTime(startDateUTC, locationTz);
            b.startDate = format(startDateInLocZone, "yyyy-MM-dd", {
                timeZone: locationTz
            });
            b.startTime = format(startDateInLocZone, "HH:mm", {
                timeZone: locationTz
            });
            const endDateUTC = new Date(startDateInLocZone);
            endDateUTC.setMinutes(endDateUTC.getMinutes() + bookedMinutes);
            b.endTime = format(endDateUTC, "HH:mm", locationTz);
        }
    }
}

const checkAvailabilityForOverride = async ({
    companyId,
    providerId,
    locationTz,
    startdate,
    startTime,
    endTime,
    travelTime,
    geoLoc,
    bookingIncrement,
    overriddenbookedslot
}) => {
    travelTime = travelTime ? travelTime : 0;
    const startingDate = startdate ? startdate : getNowDate();
    const endingDate = getEndingDate(startingDate, 1);
    const primaryKey = `C-${companyId}|P-${providerId}`;
    let scheduleData = {};
    let tbs = [
        {
            startDate: startdate,
            endDate: startdate,
            startTime: DEFAULT_STARTTIME,
            endTime: DEFAULT_ENDTIME,
            weekDays: DEFAULT_WEEKDAYS,
            weeksToRepeat: 1,
            type: "AVAILABLE"
        }
    ];

    const blockedTimeResp = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: {
            id: primaryKey
        },
        skey: { scheduleinfo: { beginsWith: "BUT" } },
        filter: { active: { ne: false }, deleted: { ne: true } },
        limit: 500
    });
    if (blockedTimeResp && blockedTimeResp.items) {
        blockedTimeResp.items.map((blockedTime) => tbs.push(blockedTime));
    }
    const bookedResp = await execReadBySortkey({
        opname: "listProviderSchedules",
        op: scheduleQueries.listProviderSchedules,
        id: {
            id: primaryKey
        },
        skey: {
            scheduleinfo: {
                between: [`BK|${startingDate}`, `BK|${endingDate}`]
            }
        },
        filter: { status: { ne: "CANCELLED" } },
        limit: 500
    });

    if (bookedResp && bookedResp.items) {
        //eliminate the BOOKED slot that is being overridden, if present
        if (overriddenbookedslot)
            bookedResp.items = bookedResp.items.filter((booked) => {
                if (
                    booked.id === overriddenbookedslot.id &&
                    booked.scheduleinfo === overriddenbookedslot.scheduleinfo
                )
                    return false;
                return true;
            });

        adjustGlobalBookingTimesForBookingLocationTimezone(
            locationTz,
            bookedResp.items
        );
        scheduleData = { ...scheduleData, booked: bookedResp.items };
    }

    const unreadableSlots = computeAvailableSlots(
        tbs,
        scheduleData.booked,
        startingDate,
        1,
        bookingIncrement,
        travelTime,
        geoLoc
    );

    const slotsinfo = [{ unreadableSlots: unreadableSlots }];

    const startIndex = getIndexByTime(startTime);
    const endIndex = getIndexByTime(endTime);
    let isAvaileble = false;
    for (let day of slotsinfo[0].unreadableSlots) {
        if (day.hasavail) {
            isAvaileble = day.slotmap
                .slice(startIndex, endIndex)
                .every((v) => v === 1);
        }
    }

    return isAvaileble;
};

function calculateBookedMinutes(bkstartTime, bkendTime) {
    //this works only if start and end times are within the same date
    //Jan 10th 11pm to Jan 11th 1am won't work.
    const startHHMM = bkstartTime.split(":");
    const endHHMM = bkendTime.split(":");
    const startTime = new Date(2000, 1, 1, startHHMM[0], startHHMM[1]);
    const endTime = new Date(2000, 1, 1, endHHMM[0], endHHMM[1]);
    const diff = endTime.valueOf() - startTime.valueOf();
    const bookedMinutes = diff / (1000 * 60);
    console.log("bookedMinutes", bookedMinutes);
    return bookedMinutes;
}

function computeAvailableSlots(
    tb,
    booked,
    sd,
    nd,
    bookingIncrement,
    travelTime,
    geoLoc
) {
    travelTime = travelTime ? travelTime : 0;
    const sdate = awsDateToJsDate(sd); //let it be local browser date
    //Create the placeholder array of by-date slots objects for asked numdays
    //For earch day create slots array based on step (15,30,60 mins)
    let slotMapArray = new Array(nd).fill(null).map((a, b, c) => ({
        date: new Date(
            sdate.getFullYear(),
            sdate.getMonth(),
            sdate.getDate() + b
        ),
        stiEtiArr: [],
        hasavail: false,
        slotmap: new Array(SLOTSINADAY) // initSlotMap(step)
    }));
    // console.log("slotMapArray", JSON.stringify(slotMapArray));
    //Check availability templates
    //iterate over the AVAILABLE timeblocks
    //  for each AVAILABLE timeblock
    //    based on startTime and endTime (and step), calculate start index and stop index to be marked as 1 (meaning available) [TBD: it is possible to have this number higher than 1 indicating that multiple booking is possible but need to design the feature end-to-end]
    //     Once start and stop indices are calculated, for each day in the slotMapArray which falls within the AVAILABLE timeblock's range and it is the workingday of the provider, fill the availability in each day's slotmap

    tb.filter((timeblock) => timeblock.type === "AVAILABLE").forEach(
        (timeblock) => {
            // console.log(
            //   `available start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            // console.log(
            //   `available start date: ${timeblock.startDate} end date: ${timeblock.endDate}`
            // );
            slotMapArray
                .filter((slotmap) => {
                    // console.log(
                    //   "slotmap.date",
                    //   slotmap.date,
                    //   awsDateToJsDate(timeblock.startDate),
                    //   awsDateToJsDate(timeblock.endDate)
                    // );
                    return (
                        slotmap.date >= awsDateToJsDate(timeblock.startDate) &&
                        slotmap.date <= awsDateToJsDate(timeblock.endDate) &&
                        worksonday(slotmap.date, timeblock.weekDays)
                    );
                })
                .forEach((slotmap) => {
                    slotmap.hasavail = true;
                    const sti = getIndexByTime(timeblock.startTime);
                    const eti = getIndexByTime(timeblock.endTime);
                    slotmap.stiEtiArr.push({ sti, eti });
                    markAvailability(slotmap.slotmap, sti, eti);
                });
        }
    );
    // console.log("AVAILABLE slotMapArray", JSON.stringify(slotMapArray));

    //console.log('u');//not doing anything for unavailable slots
    //TODO: do we need to check for UNAVAILABLE?
    tb.filter((timeblock) => timeblock.type === "UNAVAILABLE").forEach(
        (timeblock) => {
            // console.log(
            //     `unavailable date: ${timeblock.startDate}  start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.hasavail &&
                        hasUnavailability(
                            slotmap.date,
                            awsDateToJsDate(timeblock.startDate),
                            awsDateToJsDate(timeblock.endDate),
                            timeblock.weekDays
                        )
                )
                .forEach((slotmap) => {
                    markUnAvailability(
                        slotmap.slotmap,
                        getIndexByTime(timeblock.startTime),
                        getIndexByTime(timeblock.endTime),
                        0, //Travel timee zero for
                        null,
                        null,
                        bookingIncrement
                    );
                });
        }
    );
    // iterate over BOOKED blocks (appointments already booked)
    // For each booked appointment,
    //    compute start and stop index based on the start and end time of the appointment
    //    get the date of the appointment
    //    find the day in slotMapArray that matches the appointment date
    //    for the matched day in the slotMapArray, mark the slotmap's elements as booked (i.e. unavailable)
    booked
        .filter((timeblock) => timeblock.type === "BOOKED")
        .forEach((timeblock) => {
            // console.log(
            //     `booked date: ${timeblock.startDate}  start time: ${timeblock.startTime} end time: ${timeblock.endTime}`
            // );
            slotMapArray
                .filter(
                    (slotmap) =>
                        slotmap.hasavail &&
                        slotmap.date.getTime() ===
                            awsDateToJsDate(timeblock.startDate).getTime()
                )
                .forEach((slotmap) => {
                    markUnAvailability(
                        slotmap.slotmap,
                        getIndexByTime(timeblock.startTime),
                        getIndexByTime(timeblock.endTime),
                        travelTime,
                        timeblock,
                        geoLoc,
                        bookingIncrement
                    );
                });
        });
    return slotMapArray;
}
function getIndexByTime(t) {
    const parts = t.split(":");
    const h = parseInt(parts[0]);
    const m = parseInt(parts[1]);
    const totalMins = h * 60 + m;
    const index = (h * 60 + m) / SLOTUNITMINS;
    // const index1 = ((h*60)+m) % SLOTSINADAY;
    return index;
}
function hasUnavailability(
    slotmapDate,
    unavStartDate,
    unavEndDate,
    unavWeekDays
) {
    // if SBU block has weekdays, weekday must match
    // otherwise only date match is required

    if (
        slotmapDate.getTime() >= unavStartDate.getTime() &&
        slotmapDate.getTime() <= unavEndDate.getTime()
    ) {
        if (unavWeekDays && unavWeekDays.length) {
            let unavaOnWeekday =
                unavWeekDays.indexOf(slotmapDate.getDay()) !== -1
                    ? true
                    : false;
            if (!unavaOnWeekday) return false;
        }
        // console.log(
        //   "has unavailability for ",
        //   slotmapDate,
        //   unavStartDate,
        //   unavEndDate
        // );
        return true;
    } else return false;
}
function markAvailability(sm, si, ei) {
    for (let s = si; s <= ei - 1; s++) {
        sm[s] = 1;
    }
}

function markUnAvailability(
    sm,
    si,
    ei,
    travelTime,
    tb,
    geoLoc,
    bookingIncrement
) {
    // console.log("markUnAvailability1", travelTime, si, ei, tb, geoLoc);
    // console.log(tb, geoLoc);
    if (tb && tb.locationId && geoLoc) {
        if (!includeTravelTime(tb, geoLoc)) travelTime = 0;
    }
    if (travelTime && travelTime > 0) {
        let ttIndLen = travelTime / SLOTUNITMINS;
        si = si - ttIndLen;
        ei = ei + ttIndLen;
        //console.log("markUnAvailability2", travelTime, si, ei);
        let clawbackInd = ei % (bookingIncrement / SLOTUNITMINS);
        if (clawbackInd <= 1) {
            //10 mins into the next half an hour like 10:10 or 10:40
            ei = ei - clawbackInd;
        }
        // console.log(
        //     "markUnAvailability3",
        //     travelTime,
        //     clawbackInd,
        //     ttIndLen,
        //     si,
        //     ei
        // );
    }

    for (let s = si; s <= ei - 1; s++) {
        sm[s] = 0;
    }
}

function includeTravelTime(tb, geoLoc) {
    if (tb && tb.locationId && geoLoc) {
        let tbll = tb.locationId.split("|");
        if (!tbll || !tbll.length === 2) return true;
        const tbLat = Number(tbll[0]);
        const tbLng = Number(tbll[1]);
        const bookLat = Number(geoLoc.lat);
        const bookLng = Number(geoLoc.lng);
        const lat = tbLat - bookLat;
        const lng = tbLng - bookLng;
        const dLat = (lat * Math.PI) / 180;
        const dLng = (lng * Math.PI) / 180;
        const bookLatRad = (bookLat * Math.PI) / 180;
        const tbLatRad = (tbLat * Math.PI) / 180;

        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(bookLatRad) *
                Math.cos(tbLatRad) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EARTHRADIUS * c;
        // console.log("distance", d);
        if (d < NOTRAVELTIMERADIUS) return false;
    }
    return true;
}
function getNowDate() {
    return getAWSDate(new Date()); //local datetime
}

function getAWSDate(date) {
    let oy = { year: "numeric" };
    let YYYY = date.toLocaleDateString("en-US", oy);
    let om = { month: "2-digit" };
    let MM = date.toLocaleDateString("en-US", om);
    let od = { day: "2-digit" };
    let DD = date.toLocaleDateString("en-US", od);
    return `${YYYY}-${MM}-${DD}`;
}

function awsDateToJsDate(awsDate) {
    // from YYYY-MM-DD to local timezone
    const dateparts = awsDate.split("-");
    return new Date(
        parseInt(dateparts[0]),
        parseInt(dateparts[1] - 1),
        parseInt(dateparts[2])
    ); //yes, local time
}

function worksonday(sample, range) {
    if (range && sample) {
        // console.log("worksonday:" + sample.getDay() + " " + range);
        return range.indexOf(sample.getDay()) !== -1 ? true : false;
    } else return true; //no range, so weekday does not matter.
}

const getGeoLocOfBooking = async (scheduleid) => {
    let latlng;
    try {
        const pksk = scheduleid.split(SCHEDPKSKSPLITAT);
        const blocks = await execReadBySortkey({
            opname: "listProviderSchedules",
            op: scheduleQueries.listProviderSchedules,
            id: { id: pksk[0] },
            skey: { scheduleinfo: pksk[1] }
        });
        //we are getting specific record so take first
        if (blocks.items?.length) {
            let loc = blocks.items[0].locationId;
            if (loc.includes("|")) {
                let splitloc = loc.split("|");
                latlng.lat = Number(splitloc[0]);
                latlng.lng = Number(splitloc[1]);
            }
        }
    } catch (e) {
        console.log("error in getGeoLocOfBooking", e);
    }

    return latlng;
};

const bookingByOrder = `query BookingByOrder(
    $orderId: String
    $startdateTimeblockid: ModelBookingByOrderCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
) {
    bookingByOrder(
    orderId: $orderId
    startdateTimeblockid: $startdateTimeblockid
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    ) {
    items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        orderType
        isVirtual
        createdAt
        updatedAt
        cancelledBy {
        id
        username
        emailaddress
        firstname
        lastname
        }
        company {
        id
        name
        contactname
        emailaddress
        replyemailaddress
        }
        provider {
        id
        firstname
        lastname
        emailaddress
        phone
        }
        servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        }
        client {
        id
        userId
        }
        order {
        id
        }
    }
    nextToken
    }
}
`;

export {
    getGeoLocOfBooking,
    isBlockedTime,
    checkProviderAvailabilityForRechedule,
    checkProviderAvailabilityForRecurringReschedule,
    checkAvailabilityForOverride
};
