import React, { useContext, useState, useEffect } from "react";
import { graphql, graphqlOperation } from "../modules/AmplifyServices";
import * as queries from "../graphql/queries";
import { StoreContext } from "../context/StoreContext";
import { FlagsContext } from "../context/flags";
import makeStyles from "@mui/styles/makeStyles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import AlarmIcon from "@mui/icons-material/Alarm";
import SettingsIcon from "@mui/icons-material/Settings";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BlockIcon from "@mui/icons-material/Block";
import OrderIcon from "@mui/icons-material/Assignment";
import UsersIcon from "@mui/icons-material/Person";
import UsersIconAdmin from "@mui/icons-material/PeopleAlt";
import ReportsIcon from "@mui/icons-material/BarChart";
import IntegrationsIcon from "@mui/icons-material/Share";
import LaunchBookingFlowIcon from "@mui/icons-material/ArrowOutward";
import ServicesIcon from "@mui/icons-material/HomeRepairService";
import CompanyIcon from "@mui/icons-material/AccountBalance";
import ClientsIcon from "../utils/CommonComponents/ClientsIcon";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { getUserFromCache, companyIsSoleOperator } from "../user/UserCommon";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import LocationIcon from "@mui/icons-material/TransferWithinAStationSharp";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { getProviderExtended } from "../queries/ProviderAddEditQueries";
import { getCompany } from "../graphql/queries";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CustomListItem } from "../styles/CustomMuiStyles";
import { useLocation, useNavigate } from "react-router-dom";
import {
    userHasMarketboxAdminRole,
    userHasCompanyAdminRole,
    userHasClientRole,
    userHasProviderOnlyRole
} from "../user/UserCommon";
import chroma from "chroma-js";
import LaunchIcon from "@mui/icons-material/Launch";
import {
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    Dialog,
    DialogContent,
    Button,
    Grid,
    SvgIcon
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useMediaQuery from "@mui/material/useMediaQuery";
import isFeatureGated from "../modules/FeatureGatingModule";
import FeatureGateDialog from "../components/FeatureGateDialog";
import { OUTLET_PAGE } from "../context/reducers";
import { width } from "@mui/system";
import { useAtom } from "jotai";
import { openUserDialogAtom, userModalTabValue } from "../atoms/usersTable";
import UserProfileModal from "../user/components/UserProfileModal";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { DialogTheme } from "../styles/DialogTheme";

function DrawerItems(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { state, actions } = useContext(StoreContext);
    const featureFlags = useContext(FlagsContext);
    const { providerAvailabilityFlag } = featureFlags || {};
    const [adminDropDown, setAdminDropDown] = useState(false);
    const [myProfileDropDown, setMyProfileDropDown] = useState(false);
    const [providerScheduleDropDown, setProviderScheduleDropDown] =
        useState(false);
    const [openDisabledBookingFlowModal, setOpenDisabledBookingFlowModal] =
        useState(false);
    const [displayData, setDisplayData] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
    const [providerAnchorEl, setProviderAnchorEl] = React.useState(null);
    const [clientAnchorEl, setClientAnchorEl] = React.useState(null);
    const [bookNowDropDown, setBookNowDropDown] = useState(false);
    const [showFeatureGateDialog, setShowFeatureGateDialog] = useState(false);
    const servicesRef = React.useRef();
    const [openUserDialog, setOpenUserDialog] = useAtom(openUserDialogAtom);
    const [tabValue, setTabValue] = useAtom(userModalTabValue);
    const theme = useTheme();

    const Pages = {
        dashboard: "Dashboard",
        provider: "Service Provider",
        providerDirectory: "ProviderDirectory",
        customer: "Customer",
        bookings: "Bookings",
        orders: "Orders",
        billing: "Billing",
        services: "Services",
        envt: "EnvtForm",
        company: "Company",
        companyReminder: "Company Reminder",
        admin: "Admin",
        users: "Users",
        admincolor: "ColorForm",
        bookingsList: "BookingsList",
        bookNowLandingPage: "BookNowLandingPage",
        schedule: "ProviderScheduleDevEx",
        myCompany: "MyCompany",
        addCharges: "AdditionalCharges",
        report: "ReportForm",
        billingInfo: "BillingInfo",
        userprofile: "My Profile",
        blockedTime: "ProviderBlockTime",
        providerAddEdit: "ProviderAddEdit",
        providerSchedule: "ProviderSchedule",
        providerLocation: "ProviderLocationForm",
        integration: "IntegrationPage",
        updateEmail: "UpdateEmailForm"
    };

    const Titles = {
        dashboard: "Dashboard",
        providers: "Users",
        customers: "Clients",
        orders: displayData.ordersTitle,
        billing: displayData.billingTitle,
        bookings: displayData.bookingsTitle,
        availability: displayData.availabilityTitle,
        services: "Services",
        users: "Users",
        misc: "Misc. Billing",
        booknow: displayData.booknowTitle,
        schedule: "Schedule",
        myCompany: "Settings",
        addCharges: "Additional Charges",
        admin: "Admin",
        report: "Reports",
        companies: "Companies",
        profile: "Profile",
        billingInfo: "Billing Info",
        locations: "My Locations",
        providerSchedule: "My Schedules",
        blockedTime: "Block Time",
        integration: "Integrations",
        updateEmail: "Update Email",
        settings: "Settings"
    };

    const handleClick = (event, val) => {
        console.log("Handle CLick");
        console.log(event);
        if (val === "services") {
            setAnchorEl(event.currentTarget);
            //handleMenuListClick(Pages.services);
        }

        if (val === "admin") {
            setAdminAnchorEl(event.currentTarget);
        }
        if (val === "provider") {
            setProviderAnchorEl(event.currentTarget);
        }
        if (val === "client") {
            setClientAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (val) => {
        if (val === "services") {
            setAnchorEl(null);
        }
        if (val === "admin") {
            setAdminAnchorEl(null);
        }
        if (val === "provider") {
            setProviderAnchorEl(null);
        }
        if (val === "client") {
            setProviderAnchorEl(null);
        }
    };

    const open = Boolean(anchorEl);
    const open_admin = Boolean(adminAnchorEl);
    const open_provider = Boolean(providerAnchorEl);
    const open_client = Boolean(clientAnchorEl);
    const id = open ? "simple-popover" : undefined;
    const ROLE = userHasMarketboxAdminRole()
        ? "MBAdmin"
        : userHasCompanyAdminRole()
          ? "CompanyAdmin"
          : userHasProviderOnlyRole()
            ? "Provider"
            : userHasClientRole()
              ? "Client"
              : "";

    const prepData = () => {
        let tempDisplayData = {};
        if (ROLE === "MBAdmin") {
            tempDisplayData.chipLabel = props.open ? "MB Admin" : "MB";
        } else if (ROLE === "CompanyAdmin") {
            tempDisplayData.chipLabel = "Admin";
        } else if (ROLE === "Provider") {
            tempDisplayData.chipLabel = "Provider";
        } else if (ROLE === "Client") {
            tempDisplayData.chipLabel = "Client";
        }
        if (ROLE === "MBAdmin" || ROLE === "CompanyAdmin") {
            tempDisplayData.ordersTitle = "Orders";
            tempDisplayData.billingTitle = "Billing";
            tempDisplayData.bookingsTitle = "Bookings";
            tempDisplayData.booknowTitle = "Online booking";
        } else if (ROLE === "Client" || ROLE === "Provider") {
            tempDisplayData.ordersTitle = "My Orders";
            tempDisplayData.billingTitle = "Billing Charges";
            tempDisplayData.bookingsTitle = "Bookings";
            tempDisplayData.booknowTitle = "Book Now";
            tempDisplayData.availabilityTitle = "Availability";
        }
        setDisplayData({ ...tempDisplayData });
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper
        },
        nested: {
            paddingLeft: props.open ? theme.spacing(6) : theme.spacing(7),
            paddingTop: "3px",
            paddingBottom: "3px"
        },
        chip: {
            paddingLeft: theme.spacing(2)
        },
        selectedTab: {
            backgroundColor: "red",
            "&:hover": {
                backgroundColor: "red"
            }
        },
        itemIconSizeAdmin: {
            fontSize: "1.5rem",
            marginRight: "16px",
            marginLeft: "36px"
        },
        itemIconSize: {
            fontSize: "18px"
        },
        itemIconSpacing: {
            minWidth: props.open ? "26px" : "38px"
        },
        tabSpacing: {
            paddingTop: "3px",
            paddingBottom: "3px"
        },
        chipLabel: {
            [theme.breakpoints.down("xl")]: {
                paddingLeft: "4px",
                paddingRight: "4px",
                fontSize: ROLE === "MBAdmin" ? "13px" : "8px"
            }
        },
        itemsContainerAdmin: {
            display: "flex",
            flexDirection: "column",
            width: "97px",
            alignSelf: "left",
            marginTop: "2rem",
            marginBottom: "2rem",
            flex: "1"
        },
        itemsContainer: {
            display: "flex",
            flexDirection: "column",
            rowGap: "0.5rem",
            width: "100%",
            alignSelf: "center",
            marginTop: "2rem"
        },

        drawerItemAdmin: {
            width: "100%",
            display: "flex",
            flex: "1 1 auto",
            justifyContent: "space-between",
            padding: "0",
            margin: "0",
            alignContent: "center",
            fontFamily: "Roboto",
            borderRadius: "0",
            cursor: "pointer",
            "&.Mui-selected": {
                backgroundColor: "inherit"
            },
            "&.Mui-selected $itemIconSizeAdmin": {
                color: theme.palette.primary.main
            },
            "&:hover": {
                cursor: "pointer",
                width: "max-content",
                borderRadius: "0 10px 10px 0",
                backgroundColor: chroma(theme.palette.primary.main).hex()
            },
            "&:hover $drawerItemHoverContent": {
                visibility: "visible",
                color: "white"
            },
            "&:hover $itemIconSizeAdmin": {
                color: "white"
            },
            maxHeight: "54px"
        },

        drawerItemAdminDisabled: {
            "&:hover": {
                opacity: 1,
                backgroundColor: "#e0e0e0"
            }
        },

        drawerItem: {
            fontFamily: "Roboto",
            "&:hover": {
                backgroundColor: chroma(theme.palette.primary.light)
                    .alpha(0.3)
                    .hex()
            },
            "&.Mui-selected": {
                backgroundColor: chroma(theme.palette.primary.light)
                    .alpha(0.3)
                    .hex(),
                color: theme.palette.primary.dark,
                fontWeight: theme.typography.fontWeightBold
            }
        },

        drawerItemHoverContent: {
            visibility: "hidden",
            paddingRight: "36px",
            transform: "translateY(1px)"
        }
    }));
    const classes = useStyles();
    const smallScreen = useMediaQuery("(max-width:960px)");

    useEffect(() => {
        setAdminDropDown(
            state.page === "AdminForm" ||
                state.page === "UsersForm" ||
                state.page === "ReportForm" ||
                state.page === "IntegrationForm" ||
                state.page === "UpdateEmailForm" ||
                state.page === "MiscBillingTransaction" ||
                state.page === "CompanyLocationForm" ||
                state.page === "CompanyAddEditForm" ||
                state.page === "BFSettings"
                ? true
                : false
        );
        setMyProfileDropDown(
            state.page === "UsersEditViewForm" ||
                state.page === "ClientBillingInfo"
                ? true
                : false
        );
        setProviderScheduleDropDown(
            state.page === "ProviderSchedule" ||
                state.page === "ProviderBlockTime"
                ? true
                : false
        );
        prepData();
    }, []);

    async function handleMenuListClick(nextPage) {
        // clear calendar view state from local storage which switching from Schedule page to Users page
        localStorage.removeItem("displayCalendarAs");
        if (nextPage === Pages.dashboard) actions.setPage("DashboardForm");
        else if (nextPage === Pages.provider) {
            if (ROLE === "MBAdmin" || ROLE === "CompanyAdmin") {
                sessionStorage.setItem("search", false);
                sessionStorage.setItem("back", false);
            }
            actions.setPage("ProviderForm");
        } else if (nextPage === Pages.providerDirectory)
            actions.setPage("ProviderDirectory");
        else if (nextPage === Pages.customer) actions.setPage("CustomerForm");
        else if (nextPage === Pages.bookings) actions.setPage("BookingForm");
        else if (nextPage === Pages.orders) actions.setPage("OrderForm");
        else if (nextPage === Pages.billing) actions.setPage("BillingForm");
        else if (nextPage === Pages.services) actions.setPage("ServiceForm");
        else if (nextPage === Pages.company) actions.setPage("CompanyForm");
        else if (nextPage === Pages.companyReminder)
            actions.setPage("CompanyReminderForm");
        else if (nextPage === Pages.admin) {
            actions.setPage("AdminForm");
        } else if (nextPage === Pages.users) actions.setPage("UsersForm");
        else if (nextPage === Pages.integration)
            actions.setPage("IntegrationForm");
        else if (nextPage === Pages.updateEmail)
            actions.setPage("UpdateEmailForm");
        else if (nextPage === Pages.admincolor) actions.setPage("ColorForm");
        else if (nextPage === Pages.userprofile) {
            actions.setMode("Client");
            actions.setPage("UsersEditViewForm");
            actions.setId(state.id);
        } else if (nextPage === Pages.bookingsList) {
            actions.setBookingsTab(0);
            actions.setPage("BookingsListForm");
        } else if (nextPage === Pages.billingInfo) {
            actions.setMode("Client");
            actions.setPage("ClientBillingInfo");
            actions.setId(state.id);
        } else if (nextPage === Pages.bookNowLandingPage)
            actions.setPage("BookNowLandingPage");
        else if (nextPage === Pages.envt) actions.setPage("EnvtForm");
        else if (nextPage === Pages.schedule)
            actions.setPage("ProviderScheduleDevEx");
        else if (nextPage === Pages.myCompany) {
            const user = getUserFromCache();
            actions.setMode("Edit");
            actions.setId(user.companyId);
            actions.setPage("CompanyAddEditForm");
        } else if (nextPage === Pages.addCharges)
            actions.setPage("AdditionalChargesForm");
        else if (nextPage === Pages.report) actions.setPage("ReportForm");
        else if (nextPage === Pages.providerSchedule) {
            const user = getUserFromCache();
            if (user && user.providerId) {
                actions.setId(user.providerId);
                const provider = await getProvider(user.providerId);
                actions.setProvider(provider);
                actions.setPage("ProviderSchedule");
            }
        } else if (nextPage === Pages.providerLocation) {
            const user = getUserFromCache();
            if (user && user.providerId) {
                actions.setId(user.providerId);
                const provider = await getProvider(user.providerId);
                actions.setProvider(provider);
                actions.setPage("ProviderLocationForm");
            }
        } else if (nextPage === Pages.providerAddEdit) {
            const user = getUserFromCache();
            if (user && user.providerId) {
                actions.setId(user.providerId);
                const companyAccept = await getCompanyData(user.company.id);
                actions.setMode("Edit");
                actions.setPage("ProviderAddEdit");
                actions.setContextCompanyId(companyAccept);
            } else {
                console.error(
                    "WARNING: user.providerId not set for user with role of provider."
                );
                if (user) {
                    console.error("user = " + JSON.stringify(user));
                }
            }
        } else if (nextPage === Pages.blockedTime) {
            const user = getUserFromCache();
            if (user && user.providerId) {
                actions.setId(user.providerId);
                const provider = await getProvider(user.providerId);
                actions.setProvider(provider);
                actions.setPage("ProviderBlockTime");
            }
        }
    }

    async function getProvider(id) {
        const result = await graphql(
            //graphqlOperation(queries.getProvider, input)
            // need to use custom query to get servicetypes and skills
            graphqlOperation(getProviderExtended, { id })
        );
        return result.data.getProvider;
    }

    async function getCompanyData(id) {
        const result = await graphql(graphqlOperation(getCompany, { id }));
        if (result && result.data.getCompany) {
            return result.data.getCompany.providerMustAcceptAppt;
        }
    }

    const fetchPublicSiteData = async (loggedInUser) => {
        const companyId = loggedInUser.companyId;
        if (loggedInUser.id) {
            let publicSiteData;
            console.log("loggedInUser from fetchPublicSiteData ", loggedInUser);
            try {
                publicSiteData = await graphql(
                    graphqlOperation(queries.publicsiteByCompany, {
                        companyId: companyId
                    })
                );
            } catch (err) {
                console.log(
                    "Dashboard Form | fetchPublicSiteData - Error in getting PublicSiteData"
                );
            }
            console.log(
                "this is the publicSiteData from drawer items",
                publicSiteData
            );
            return publicSiteData;
        } else {
            console.log(
                "Dashboard Form | fetchPublicSiteData - Logged In User Does not exist yet",
                loggedInUser
            );
        }
    };

    async function launchBookingHandler(loggedInUser, url) {
        let company = loggedInUser.company;
        if (
            !company?.hasOwnProperty("setupCompleted") ||
            company?.setupCompleted === null
        ) {
            window.open(url, "_blank").focus();
        } else if (company?.setupCompleted) {
            window.open(url, "_blank").focus();
        } else {
            console.log("fetched public site data from drawer");
            const publicResult = await fetchPublicSiteData(loggedInUser);
            if (
                publicResult?.data?.publicsiteByCompany?.items[0]
                    ?.StyleCustomization
            ) {
                window.open(url, "_blank").focus();
            } else {
                setOpenDisabledBookingFlowModal(true);
            }
        }
    }

    const customizeBookingHandler = () => {
        actions.setPage("BFSettings");
    };

    function DisplayBookNow() {
        const loggedInUser = getUserFromCache();
        let url;
        if (loggedInUser && loggedInUser.company) {
            url = "https://" + loggedInUser.company.subdomain;
        }

        return ROLE === "MBAdmin" || ROLE === "CompanyAdmin" ? (
            <CustomListItem
                title={Titles.booknow}
                id={"Launch Booking Flow"}
                onClick={() => launchBookingHandler(loggedInUser, url)}
                className={`${classes.drawerItemAdmin} `}
                open={props.open}
                hoverContentClassName={`${classes.drawerItemHoverContent}`}
            >
                <LaunchBookingFlowIcon
                    sx={{ transform: "scale(1.2)" }}
                    className={classes.itemIconSizeAdmin}
                />
            </CustomListItem>
        ) : props.open ? (
            <>
                {openDisabledBookingFlowModal && (
                    <Dialog
                        open={openDisabledBookingFlowModal}
                        onClose={() => {
                            setOpenDisabledBookingFlowModal(false);
                        }}
                        maxWidth={"sm"}
                    >
                        <DialogContent
                            sx={{
                                padding: "1.5rem 0px",
                                maxWidth: "404px"
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    justifyContent: "center",
                                    gap: "13px"
                                }}
                            >
                                <Typography style={{ fontSize: "22px" }}>
                                    Action Required
                                </Typography>
                                <Typography
                                    style={{
                                        textAlign: "center",
                                        maxWidth: "84%"
                                    }}
                                >
                                    Please complete setting up your booking flow
                                    before launching.
                                </Typography>
                                <Grid>
                                    <Button
                                        variant="filled"
                                        onClick={customizeBookingHandler}
                                    >
                                        Go to Settings
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            setOpenDisabledBookingFlowModal(
                                                false
                                            )
                                        }
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                )}
                <ListItem
                    onClick={() =>
                        setBookNowDropDown((prevState) => !prevState)
                    }
                    button
                    className={`${classes.drawerItem} ${classes.tabSpacing}`}
                >
                    <ListItemIcon className={classes.itemIconSpacing}>
                        <AlarmIcon className={classes.itemIconSize} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            style: { fontSize: "1rem" }
                        }}
                        primary={Titles.booknow}
                    />
                    {props.open && (
                        <ListItemSecondaryAction
                            onClick={() =>
                                setBookNowDropDown((prevState) => !prevState)
                            }
                        >
                            <IconButton edge="end" size="large">
                                {bookNowDropDown ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
                {bookNowDropDown && (
                    <div
                        style={{
                            gap: "0.5rem",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <CustomListItem
                            title={"Launch"}
                            id={"Launch Booking Flow"}
                            onClick={() =>
                                launchBookingHandler(loggedInUser, url)
                            }
                            className={`${
                                props.open ? classes.drawerItem : ""
                            } ${props.open ? classes.nested : ""}`}
                            open={props.open}
                        />
                    </div>
                )}
            </>
        ) : (
            <Tooltip placement="right" title={"Booking Flow"}>
                <ListItem
                    onClick={() => window.open(url, "_blank").focus()}
                    button
                    className={`${classes.drawerItem} ${classes.tabSpacing}`}
                >
                    <ListItemIcon className={classes.itemIconSpacing}>
                        <AlarmIcon className={classes.itemIconSize} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            style: { fontSize: "1rem" }
                        }}
                        primary={Titles.booknow}
                    />
                    <ListItemIcon className={classes.itemIconSpacing}>
                        <LaunchIcon className={classes.itemIconSize} />
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
        );
    }

    const DisplayDashboard = () => (
        <CustomListItem
            title={Titles.dashboard}
            id={"Dashboard"}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/dashboard");
            }}
            selected={location.pathname.includes("dashboard")}
            open={props.open}
            className={`${classes.drawerItemAdmin} `}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <DashboardIcon
                aria-label={Pages.provider}
                className={classes.itemIconSizeAdmin}
            />
        </CustomListItem>
    );

    const DisplayOrders = () => (
        <CustomListItem
            title={Titles.orders}
            id={"Orders"}
            onClick={() => {
                if (userHasMarketboxAdminRole() || userHasCompanyAdminRole()) {
                    actions.setPage(OUTLET_PAGE);
                    navigate("/orders");
                } else {
                    handleMenuListClick(Pages.orders);
                }
            }}
            selected={
                ROLE === "MBAdmin" || ROLE === "CompanyAdmin"
                    ? location.pathname.includes("orders")
                    : state.page === "OrderForm"
            }
            open={props.open}
            className={
                ROLE === "MBAdmin" || ROLE === "CompanyAdmin"
                    ? `${classes.drawerItemAdmin} `
                    : `${classes.drawerItem} `
            }
            hoverContentClassName={
                (ROLE === "MBAdmin" || ROLE === "CompanyAdmin") &&
                `${classes.drawerItemHoverContent}`
            }
        >
            <ShoppingCartIcon
                className={
                    userHasMarketboxAdminRole() || userHasCompanyAdminRole()
                        ? classes.itemIconSizeAdmin
                        : classes.itemIconSize
                }
            />
        </CustomListItem>
    );

    const DisplayBookings = () => (
        <CustomListItem
            title={Titles.bookings}
            id={"BookingList"}
            onClick={() => {
                if (ROLE === "MBAdmin" || ROLE === "CompanyAdmin") {
                    actions.setPage(OUTLET_PAGE);
                    navigate("/bookings");
                } else {
                    handleMenuListClick(Pages.bookingsList);
                }
            }}
            selected={
                ROLE === "MBAdmin" || ROLE === "CompanyAdmin"
                    ? location.pathname.includes("bookings")
                    : state.page === "BookingsListForm"
            }
            open={props.open}
            className={
                ROLE === "MBAdmin" || ROLE === "CompanyAdmin"
                    ? `${classes.drawerItemAdmin} `
                    : `${classes.drawerItem} `
            }
            hoverContentClassName={
                (ROLE === "MBAdmin" || ROLE === "CompanyAdmin") &&
                `${classes.drawerItemHoverContent}`
            }
        >
            <OrderIcon
                className={
                    ROLE === "MBAdmin" || ROLE === "CompanyAdmin"
                        ? classes.itemIconSizeAdmin
                        : classes.itemIconSize
                }
            />
        </CustomListItem>
    );

    const DisplayProfile = props.open ? (
        <ListItem
            button
            id={ROLE === "Client" ? "Users" : "Provider"}
            onClick={() =>
                handleMenuListClick(
                    ROLE === "Client"
                        ? Pages.userprofile
                        : Pages.providerAddEdit
                )
            }
            selected={
                state.page === "ProviderAddEdit" ||
                state.page === "UsersEditViewForm"
            }
            className={`${classes.drawerItem} ${classes.tabSpacing}`}
        >
            <ListItemIcon className={classes.itemIconSpacing}>
                <UsersIcon className={classes.itemIconSize} />
            </ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ style: { fontSize: "1rem" } }}
                primary={Titles.profile}
            />
            {ROLE === "Client" && props.open && (
                <ListItemSecondaryAction
                    onClick={() =>
                        setMyProfileDropDown((prevState) => !prevState)
                    }
                >
                    <IconButton edge="end" size="large">
                        {myProfileDropDown ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    ) : (
        <Tooltip placement="right" title={Titles.profile}>
            <ListItem
                button
                id={ROLE === "Client" ? "Users" : "Provider"}
                onClick={() =>
                    handleMenuListClick(
                        ROLE === "Client"
                            ? Pages.userprofile
                            : Pages.providerAddEdit
                    )
                }
                selected={
                    state.page === "ProviderAddEdit" ||
                    state.page === "UsersEditViewForm"
                }
                className={`${classes.drawerItem} ${classes.tabSpacing}`}
            >
                <ListItemIcon className={classes.itemIconSpacing}>
                    <UsersIcon className={classes.itemIconSize} />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ style: { fontSize: "1rem" } }}
                    primary={Titles.profile}
                />
                {ROLE === "Client" && props.open && (
                    <ListItemSecondaryAction
                        onClick={() =>
                            setMyProfileDropDown((prevState) => !prevState)
                        }
                    >
                        <IconButton edge="end" size="large">
                            {myProfileDropDown ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        </Tooltip>
    );

    const DisplayBillingInfo = (
        <CustomListItem
            title={Titles.billingInfo}
            id={"billingInfo"}
            onClick={() => handleMenuListClick(Pages.billingInfo)}
            selected={state.page === "ClientBillingInfo"}
            className={`${classes.drawerItem} ${classes.nested}`}
            open={props.open}
        ></CustomListItem>
    );

    const DisplayProviders = () => (
        <CustomListItem
            title={Titles.providers}
            id={"Provider"}
            onClick={() => {
                sessionStorage.setItem("search", false);
                sessionStorage.setItem("back", false);
                actions.setPage(OUTLET_PAGE);
                navigate("/users");
            }}
            selected={location.pathname.includes("/users")}
            open={props.open}
            className={`${classes.drawerItemAdmin}`}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <UsersIconAdmin className={classes.itemIconSizeAdmin} />
        </CustomListItem>
    );
    const DisplayUsers = () => (
        // TODO: switch to Link
        <CustomListItem
            title={"new Users"}
            id={"User"}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/new-users");
            }}
            selected={location.pathname.includes("new-users")}
            open={props.open}
            className={`${classes.drawerItemAdmin}`}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <UsersIconAdmin className={classes.itemIconSizeAdmin} />
        </CustomListItem>
    );
    const DisplayCustomers = () => (
        <CustomListItem
            title={Titles.customers}
            id={"Customer"}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/clients");
            }}
            selected={location.pathname.includes("clients")}
            open={props.open}
            className={`${classes.drawerItemAdmin}`}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <ClientsIcon className={classes.itemIconSizeAdmin} />
        </CustomListItem>
    );

    const DisplaySchedule = () => (
        <CustomListItem
            title={Titles.schedule}
            id={"Schedule"}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/schedule");
            }}
            selected={location.pathname.includes("schedule")}
            open={props.open}
            className={`${classes.drawerItemAdmin}`}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <InsertInvitationIcon className={classes.itemIconSizeAdmin} />
        </CustomListItem>
    );

    const DisplayServices = () => (
        <CustomListItem
            button
            id="Services"
            title={Titles.services}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/services");
            }}
            selected={location.pathname.includes("services")}
            className={`${classes.drawerItemAdmin} `}
            open={props.open}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <ServicesIcon
                sx={{ transform: "scaleY(1.1)" }}
                className={classes.itemIconSizeAdmin}
            />
        </CustomListItem>
    );

    const DisplaySettings = () => (
        <CustomListItem
            button
            id="Admin"
            title={Titles.settings}
            onClick={() => {
                const user = getUserFromCache();
                actions.setMode("Edit");
                actions.setId(user.companyId);
                actions.setPage(OUTLET_PAGE);
                navigate("/settings");
            }}
            selected={location.pathname.includes("settings")}
            className={`${classes.drawerItemAdmin} `}
            open={props.open}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <SettingsIcon className={classes.itemIconSizeAdmin} />
        </CustomListItem>
    );

    const DisplayReport = () => (
        <CustomListItem
            title={Titles.report}
            id={"Report"}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/reports");
            }}
            className={`${classes.drawerItemAdmin} `}
            selected={location.pathname.includes("reports")}
            open={props.open}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <ReportsIcon
                aria-label={Titles.report}
                className={classes.itemIconSizeAdmin}
            />
        </CustomListItem>
    );

    const DisplayIntegration = () => (
        <CustomListItem
            title={Titles.integration}
            id={"Integration"}
            onClick={() => {
                if (isFeatureGated(props.subscriptionLevel, "Integrations")) {
                    setShowFeatureGateDialog(true);
                } else {
                    actions.setPage(OUTLET_PAGE);
                    navigate("/integrations");
                }
            }}
            className={`${classes.drawerItemAdmin} ${
                isFeatureGated(props.subscriptionLevel, "Integrations")
                    ? classes.drawerItemAdminDisabled
                    : ""
            }`}
            selected={location.pathname.includes("integrations")}
            open={props.open}
            isFeatureGated={isFeatureGated(
                props.subscriptionLevel,
                "Integrations"
            )}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <IntegrationsIcon
                aria-label={Titles.integration}
                className={classes.itemIconSizeAdmin}
            />
        </CustomListItem>
    );

    const DisplayCompanies = () => (
        <CustomListItem
            title={Titles.companies}
            id={"Companies"}
            onClick={() => {
                actions.setPage(OUTLET_PAGE);
                navigate("/companies");
            }}
            selected={location.pathname.includes("companies")}
            open={props.open}
            className={`${classes.drawerItemAdmin}`}
            hoverContentClassName={`${classes.drawerItemHoverContent}`}
        >
            <CompanyIcon className={classes.itemIconSizeAdmin} />
        </CustomListItem>
    );

    const AdminView = () => (
        <>
            <FeatureGateDialog
                open={showFeatureGateDialog}
                handleOnClose={handleOnClose}
            />
            <DisplayDashboard />
            <DisplayOrders />
            <DisplayBookings />
            <DisplaySchedule />
            <DisplayServices />
            <DisplayProviders />
            {providerAvailabilityFlag && <DisplayUsers />}
            <DisplayCustomers />
            <DisplayReport />
            <DisplayIntegration />
            {props.open && <DisplaySettings />}
            {DisplayBookNow()}
            {ROLE === "MBAdmin" && <DisplayCompanies />}
        </>
    );

    const ProviderView = () => (
        <>
            <CustomListItem
                title={Titles.bookings}
                id={"BookingList"}
                onClick={() => {
                    actions.setPage(OUTLET_PAGE);
                    navigate("/bookings");
                }}
                selected={location.pathname.includes("bookings")}
                open={props.open}
                className={classes.drawerItemAdmin}
                hoverContentClassName={classes.drawerItemHoverContent}
            >
                <OrderIcon
                    aria-label={Pages.provider}
                    className={classes.itemIconSizeAdmin}
                />
            </CustomListItem>
            <CustomListItem
                title={Titles.schedule}
                id={"Schedule"}
                selected={location.pathname.includes("schedule")}
                onClick={() => {
                    actions.setPage(OUTLET_PAGE);
                    navigate("/schedule");
                }}
                open={props.open}
                className={`${classes.drawerItemAdmin} `}
                hoverContentClassName={`${classes.drawerItemHoverContent}`}
            >
                <InsertInvitationIcon
                    aria-label={Pages.provider}
                    className={classes.itemIconSizeAdmin}
                />
            </CustomListItem>
            <CustomListItem
                title={Titles.profile}
                id={"Provider"}
                onClick={() => {
                    setTabValue(0);
                    setOpenUserDialog(true);
                }}
                selected={openUserDialog && tabValue === 0}
                open={props.open}
                className={`${classes.drawerItemAdmin} `}
                hoverContentClassName={`${classes.drawerItemHoverContent}`}
            >
                <UsersIcon
                    aria-label={Pages.provider}
                    className={classes.itemIconSizeAdmin}
                />
            </CustomListItem>
            <CustomListItem
                title={Titles.availability}
                id={"Availability"}
                onClick={() => {
                    setTabValue(1);
                    setOpenUserDialog(true);
                }}
                selected={openUserDialog && tabValue === 1}
                open={props.open}
                className={`${classes.drawerItemAdmin} `}
                hoverContentClassName={`${classes.drawerItemHoverContent}`}
            >
                <ScheduleIcon
                    aria-label={Pages.provider}
                    className={classes.itemIconSizeAdmin}
                />
            </CustomListItem>
            <CustomListItem
                title={Titles.blockedTime}
                id={"BlockedTime"}
                onClick={() => {
                    setTabValue(2);
                    setOpenUserDialog(true);
                }}
                selected={openUserDialog && tabValue === 2}
                open={props.open}
                className={`${classes.drawerItemAdmin} `}
                hoverContentClassName={`${classes.drawerItemHoverContent}`}
            >
                <BlockIcon
                    aria-label={Pages.provider}
                    className={classes.itemIconSizeAdmin}
                />
            </CustomListItem>
        </>
    );

    const ClientView = () => (
        <>
            {DisplayBookNow()}
            <DisplayBookings />
            <DisplayOrders />
            {props.open && DisplayProfile}
            {props.open && myProfileDropDown && DisplayBillingInfo}
            {!props.open && clientPopover()}
        </>
    );

    const handleOnClose = () => {
        setShowFeatureGateDialog(false);
    };

    const clientPopover = () => {
        return (
            <div>
                <Tooltip placement="right" title={Titles.profile}>
                    <ListItem
                        button
                        id={"Users"}
                        onClick={(e) => handleClick(e, "client")}
                        selected={
                            state.page === "ProviderAddEdit" ||
                            state.page === "UsersEditViewForm"
                        }
                        className={`${classes.drawerItem} ${classes.tabSpacing}`}
                    >
                        <ListItemIcon className={classes.itemIconSpacing}>
                            <UsersIcon className={classes.itemIconSize} />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: { fontSize: "1rem" }
                            }}
                            primary={Titles.profile}
                        />
                    </ListItem>
                </Tooltip>
                <Menu
                    id="basic-menu"
                    anchorEl={clientAnchorEl}
                    open={open_client}
                    onClose={() => handleClose("client")}
                    MenuListProps={{
                        "aria-labelledby": "basic-button"
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose("client");
                            handleMenuListClick(Pages.userprofile);
                        }}
                    >
                        {Titles.profile}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose("client");
                            handleMenuListClick(Pages.billingInfo);
                        }}
                    >
                        {Titles.billingInfo}
                    </MenuItem>
                </Menu>
            </div>
        );
    };
    return (
        <>
            <div
                className={
                    ROLE === "MBAdmin" ||
                    ROLE === "CompanyAdmin" ||
                    ROLE === "Provider"
                        ? classes.itemsContainerAdmin
                        : classes.itemsContainer
                }
            >
                {ROLE === "Client" && <ClientView />}
                {ROLE === "Provider" && (
                    <>
                        <ProviderView />
                        <ThemeProvider theme={DialogTheme(theme)}>
                            <UserProfileModal
                                open={openUserDialog}
                                onClose={() => setOpenUserDialog(false)}
                            />
                        </ThemeProvider>
                    </>
                )}
                {(ROLE === "CompanyAdmin" || ROLE === "MBAdmin") && (
                    <AdminView />
                )}
            </div>
        </>
    );
}

export default DrawerItems;
